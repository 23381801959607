import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import * as _ from 'underscore'; import * as _l from 'lodash-es';
import { Principal } from '@coreModels/principal';
import { SummaryInfoManagerService } from '@coreServices/summary-info-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';

@Injectable({ providedIn: 'root' })
export class LandingPageService {

  private requiresInitialization: boolean = false;
  private _landingPageReconfig$: Subject<Principal> = new Subject<Principal>();
  public landingPageReconfig$ = this._landingPageReconfig$.asObservable();

  constructor(private summaryInfoManagerService: SummaryInfoManagerService, private userManagerService: UserManagerService) {
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.logout$.subscribe(currentPrincipal => this.onUserLogout(currentPrincipal));
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
  }

  public initialize(): Observable<Object> {
    if (this.requiresInitialization) {
      let currentPrincipal = this.userManagerService.getCurrentPrincipal();
      this.addInfosToLandingPage(currentPrincipal);
      this.requiresInitialization = false;
    }

    return of(null);
  }

  private onUserLogin(principal: Principal) {
    this.requiresInitialization = true;
  }

  private onUserLogout(principal: Principal) {
    this.removeInfosFromLandingPage();
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.removeInfosFromLandingPage();
    this.requiresInitialization = true;
  }

  private addInfosToLandingPage(principal: Principal) {
    this.raiseLandingPageReconfig();
  }

  private removeInfosFromLandingPage() {
    this.summaryInfoManagerService.clear();
  }

  private raiseLandingPageReconfig() {
    this._landingPageReconfig$.next();
  }
}
