import { Injectable, Inject, NgZone } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap, map, catchError } from 'rxjs/operators';

import { AuthorizationService } from '../authorization.service';
import { Principal } from '@coreModels/principal';

let headerServiceFactory = (authorizationService: AuthorizationService, router: Router, zone: NgZone) => {
  let svc = new AuthorizationHeaderInterceptor(authorizationService, router, zone);

  return svc;
};

/**
 * HTTP Interceptor for adding an Authorization Header to outgoing HTTP requests.
 */
@Injectable({ providedIn: 'root', useFactory: headerServiceFactory, deps: [AuthorizationService] })
export class AuthorizationHeaderInterceptor implements HttpInterceptor {

  constructor(
      private authorizationService: AuthorizationService,
    private router: Router,
    private zone: NgZone
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.authorizationService.getToken();
    let newRequest = request;
    if (token) {
      const clonedRequest = request.clone({ headers: request.headers.set('Authorization', "token " + token) });
      return next.handle(clonedRequest);
    }

    let handleReq = next.handle(request);
    handleReq.pipe(
      map(() => { return request; }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }

          console.log('Server returned a 401 response. Token expired.');
          this.zone.run(() => this.router.navigate(['/login']));
          return of(false);
        }
        else if (err == 'Not authenticated') {
          console.log('Server returned 401. Token expired.');
          this.zone.run(() => this.router.navigate(['/login']));
          return of(false);
        }
      }));
      //.subscribe();

    return handleReq;
  }
}
