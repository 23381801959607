import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';
import { SystemSettingDto } from '@coreShared/core-shared.module';

/**
 * Data service for accessing system settings.
 */
@Injectable({ providedIn: "root" })
export class SystemSettingsService extends ReachHttpClientService {
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }

  /**
   * Returns all system settings.
   * @returns an array of all SystemSetting objects.
   */
  public getAll(): Observable<SystemSettingDto[]> {
    return this.get<SystemSettingDto[]>(`${this.apiRootUri}/systemSetting/`);
  }
}
