import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { WizardInjector, WIZARD_INJECTOR_TOKEN } from 'src/app/core/index-models';
import { CheckboxContentService, ConstantsService, CONSTANTS_SERVICE_TOKEN, ValidationManagerService } from 'src/app/core/index-services';
import { CvaHostWizardStepComponent } from '../../wizard/wizard-step.component';

// Constants
export const InformationAcknowledgementComponentSelector: string = 'information-acknowledgement';
export const InformationAcknowledgementComponentKey: string = 'informationAcknowledgement';

@Component({
  selector: InformationAcknowledgementComponentSelector,
  templateUrl: './information-acknowledgement.component.html',
  styleUrls: ['./information-acknowledgement.component.scss']
})
export class InformationAcknowledgementComponent extends CvaHostWizardStepComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  constructor(
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    super(constantsService, validationManagerService, wizardInjector, InformationAcknowledgementComponent.name, checkboxContentService, elementRef, changeDetectorRef);

    // Initialize form.
    this.modelToForm();
  }

  ngOnInit(): void {

    // Base.
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // Forces validation to run when the form group is set.
    this.stepForm.updateValueAndValidity();
  }

  /**
   * Map model to form
   */
  protected modelToForm() {
    super.modelToForm();
  }

}

// Register component for dynamic loading
// Example: Add the following with first parameter (specific scenario) in each wizard that requires this step
//registerDynamicComponent({Wizard Scenario Here}, InformationAcknowledgementComponentKey, InformationAcknowledgementComponent, InformationAcknowledgementComponentSelector);
