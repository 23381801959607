import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'survey-redirect',
  templateUrl: 'survey-redirect.component.html',
})
export class SurveyRedirectComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
