import { ValidationErrors } from '@angular/forms';
import { ValidationFailureDto } from '@coreShared/core-shared.module';

export class ReachValidationError {
  public errorType: number;
  constructor(
    public key: string,
    public detail: string,
    public ruleName: string,
    public error: ValidationFailureDto | ReachValidationRuleError
  ) {
  }

  public static fromValidationError(controlName: string, ruleName: string, errors: ValidationErrors, friendlyControlName: string): ReachValidationError[] {
    let ruleErr = new ReachValidationRuleError(controlName, ruleName, errors, friendlyControlName);

    let resultItems = [];

    resultItems.push(new ReachValidationError(controlName, ruleErr.messageDetails, ruleName, ruleErr));

    //let result = new ReachValidationError(controlName, ruleErr.detail, ruleName, ruleErr);
    return resultItems;
  }

  public static fromValidationFailureDto(error: ValidationFailureDto): ReachValidationError {
    let result = new ReachValidationError(error.PropertyName, error.ErrorMessage, '', error);
    return result;
  }
}

export class ReachValidationRuleError {

  // FIELDS
  public friendlyFieldName: string;
  public messageDetails: string;
  constructor(
    public controlName: string,
    public ruleName: string,
    public validationErrorsForControl: ValidationErrors,
    public friendlyNames: any = null) {
    this.friendlyFieldName = this.friendlyNames ? this.friendlyNames[this.controlName] : null;
    this.toDetail();
  }

  /**
   * Gives the error text that we write to the prime ng message service for the target (e.g., application validation summary component on navbar)
   */
  private toDetail() {
    let fieldName = this.friendlyFieldName || this.controlName;

    let ruleErr = this.validationErrorsForControl[this.ruleName];
    this.formatErrorMessage(this.ruleName, fieldName, ruleErr);
  }

  /**
   * Angular errors do not have a uniform format.
   * Format the message specific to the validation rule.
   * @param ruleName 
   * @param fieldName 
   * @param ruleValue 
   */
  protected formatErrorMessage(ruleName: string, fieldName: string, ruleValue: any) {
    switch (ruleName) {
      case 'required':
        this.messageDetails = `'${fieldName}' is required.`;
        break;
      case 'dateOrder':
        let from = this.friendlyNames ? this.friendlyNames[ruleValue.startKey] : ruleValue.startKey;
        let to = this.friendlyNames ? this.friendlyNames[ruleValue.endKey] : ruleValue.endKey;
        this.messageDetails = `'${from}' must be earlier than or equal to '${to}'.`;
        break;
      case 'invalidForm':
        let detail = ruleValue.message || '';
        if (fieldName) {
          detail = `${fieldName} - ${detail}.`;
        }
        this.messageDetails = detail;
        break;
      case 'max':
        this.messageDetails = `'${fieldName} cannot exceed ${ruleValue.max}. Actual: ${ruleValue.actual}.`;
        break;
      case 'min':
        this.messageDetails = `'${fieldName}' cannot be less than ${ruleValue.min}. Actual: ${ruleValue.actual}.`;
        break;
      case 'email':
        this.messageDetails = `'${fieldName}' is not a valid email address.`;
        break;
      case 'maxlength':
        this.messageDetails = `'${fieldName}' must be at most ${ruleValue.requiredLength} characters. Actual length: ${ruleValue.actualLength}.`;
        break;
      case 'minlength':
        this.messageDetails = `'${fieldName}' must be at least ${ruleValue.requiredLength} characters. Actual length: ${ruleValue.actualLength}.`;
        break;
      case 'url':
        this.messageDetails = `'${fieldName}' is not a valid web address.`;
        break;
      case 'pattern':
        let regExMessage = '';
        switch (ruleValue.requiredPattern) {
          case '^[0-9]{4}$':
            regExMessage = ` must be four digits`; // Short SSN
            break;
          case '^[0-9]{9}$':
            regExMessage = ` must be nine digits`;
            break;
          case "^(\\d{2}\\-?\\d{7}|)$":
            regExMessage = ` must match the format 99-9999999`; // EIN
            break;
          case "^(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)$":
            regExMessage = ` must match the format 999-99-9999`; // SSN
            break;
          default:
            regExMessage = ` must match the format ${ruleValue.requiredPattern}`;
        }
        this.messageDetails = `'${fieldName}' ${regExMessage}. Actual: ${ruleValue.actualValue}.`;
        break;
      case 'dob':
        this.messageDetails = `'${fieldName}'  lies outside the valid range. Dates must be in the past.`;
        break;
      case 'errorMessage':
        this.messageDetails = ruleValue;
        break;
      case 'reachCvaError':
        let friendlyName = ruleValue.friendlyName ?? ruleValue.fieldName;
        this.formatErrorMessage(ruleValue.ruleName, friendlyName, ruleValue.error);
        break;
      case 'passwordMatch':
        this.messageDetails = 'Passwords do not match';
        break;
      default:
        this.messageDetails = 'Unspecified error.';
        break;
    }
  }
}
