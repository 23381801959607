import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Command, CommandService, DialogSettings, ReachScenarios, UtilitiesService } from '@core/core.module';
import { LicenseSelectorFieldEditorModel } from './editor/license-selector-field-editor-model';
import { LicenseSelectorFieldEditorComponent, LicenseSelectorFieldEditorComponentKey } from './editor/license-selector-field-editor.component';
import { LicenseSelectorFieldConfiguration } from './license-selector-field-configuration';
import { LicenseSelectorFieldModel } from './license-selector-field-model';

@Component({
  selector: 'license-selector-field',
  templateUrl: './license-selector-field.component.html',
  styleUrls: ['./license-selector-field.component.scss']
})
export class LicenseSelectorFieldComponent implements OnInit, OnChanges {

  @Input() model: LicenseSelectorFieldModel;
  @Input() config: LicenseSelectorFieldConfiguration;
  @Output() selectionChanged: EventEmitter<LicenseSelectorFieldModel> = new EventEmitter<LicenseSelectorFieldModel>();

  dialogTitle: string = "License Search";
  selectorLabel: string = "License";
  searchCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings;
  contentForm: FormGroup;

  constructor(private commandService: CommandService, private utilitiesService: UtilitiesService) {
    this.modelToForm();
  }

  modelToForm() {
    this.contentForm = new FormGroup({ LicenseeName: new FormControl(null, [Validators.required, Validators.maxLength(100)]) });
  }

  ngOnInit(): void {
    this.searchCommand = this.commandService.create(() => !this.model.isReadonly, this.searchCommandExecuted);
    this.okCommand = this.commandService.create(() => true, this.okCommandExecuted);

    this.dialogTitle = this?.config?.dialogTitle ?? this.dialogTitle;
    this.selectorLabel = this?.config?.selectorLabel ?? this.selectorLabel;
  }

  searchCommandExecuted = () => {
    this.dialogSettings = new DialogSettings(null
      , ReachScenarios.Default
      , LicenseSelectorFieldEditorComponent
      , LicenseSelectorFieldEditorComponentKey
      , this.dialogTitle
      , new LicenseSelectorFieldEditorModel()
      , false
      , false
      , true);

    this.dialogSettings.initializationData.config = this.config;
    this.dialogSettings.okCommand = this.okCommand;
    this.dialogSettings.isOpen = true;
  }

  ngOnChanges(): void {
    if (this.model.licenseNumber && this.model.selectedLicenseeName) this.contentForm.get("LicenseeName").setValue(`${this.model.licenseNumber} - ${this.model.selectedLicenseeName}`);
    this.utilitiesService.enableDisable(this.contentForm.get("LicenseeName"), !this.model.isReadonly);
  }

  okCommandExecuted = (licenseSelectorFieldEditorModel: LicenseSelectorFieldEditorModel) => {
    const onlineLicenseSearchResultItemDto = licenseSelectorFieldEditorModel.searchResult;

    // If manual entry was requested, clear the model.
    if (licenseSelectorFieldEditorModel.manualEntryRequested) {
      Object.keys(this.model).forEach(key => {
        this.model[key] = null;
      });

      this.model.manualEntryRequested = licenseSelectorFieldEditorModel.manualEntryRequested;
      this.contentForm.get("LicenseeName").setValue(null);
    }
    else {
      this.model.licenseNumber = onlineLicenseSearchResultItemDto.LicenseNumber;
      this.model.licenseId = onlineLicenseSearchResultItemDto.LicenseId;
      this.model.selectedLicenseeName = onlineLicenseSearchResultItemDto.FirstName
        ? `${onlineLicenseSearchResultItemDto.FirstName} ${onlineLicenseSearchResultItemDto.LastName}`
        : `${onlineLicenseSearchResultItemDto.LastName}`;
      this.model.licenseeEntityId = onlineLicenseSearchResultItemDto.EntityId;
      this.model.city = onlineLicenseSearchResultItemDto.City;
      this.contentForm.get("LicenseeName").setValue(`${this.model.licenseNumber} - ${this.model.selectedLicenseeName}`);
    }

    this.selectionChanged.emit(this.model);
  }
}
