import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeScheduledCourseSubmissionDto,
ICeSponsorshipScheduledCourseDto } from './licensure-shared-dto';

export class CeScheduledCourseSubmissionDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICeScheduledCourseSubmissionDto {
	CeSponsorId: number;
	CeSponsorshipId: number;
	CeSponsorCourseId: number;
	SponsorshipStartDate: Date;
	SponsorshipEndDate: Date;
	Course: ICeSponsorshipScheduledCourseDto;

}
