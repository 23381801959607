import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _l from 'lodash-es';
import { CommandService } from "@coreServices/command.service";
import { OnlineUserService } from "@coreServices/online-user.service";
import { ValidationManagerService } from "@coreServices/validation-manager.service";
import { IChallengeInfoDto, IOnlineUserDto } from '@coreShared/core-shared.module';
import { LandingComponentKeys } from '@core/index-constants';
import { Command, RouteInfoRegistry } from '@core/index-models';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ReachControlValidators } from '@core/components/validators/reach-control-validators'
import {
  AuthorizationConfigurationProviderService,
  UserManagerService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  RouteConfigurationProviderService,
  BusyManagerService,
  DynamicContentManagerService,
  ReachApplicationService,
  DefaultRouteResolverService,
  DynamicContentConfigurationProviderService,
  BootstrapperService,
  RouteConfiguration
} from '@core/index-services';
import { on } from 'events';

@Component({
  selector: 'password-token-reset',
  templateUrl: './password-token-reset.component.html',
  styleUrls: ['./password-token-reset.component.scss']
})
export class PasswordTokenResetComponent implements OnInit {

  public friendlyNames: any = {};
  public contentForm: FormGroup;
  public onlineUser: IOnlineUserDto;
  public tokenValid: boolean = true;
  public token: string;
  public challenge: IChallengeInfoDto;
  public saveCommand: Command;
  public loaded: boolean = false;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private validationManagerService: ValidationManagerService
    , private commandService: CommandService
    , private onlineUserService: OnlineUserService
    , private router: Router
    , private route: ActivatedRoute
    , private busyManagerService: BusyManagerService) {

    this.route.params.subscribe(params => {
      this.token = params.passwordVerificationToken;
    });
  }

  ngOnInit(): void {
    this.onlineUserService.findByPasswordVerificationToken(this.token).pipe(
      map((onlineUser: IOnlineUserDto) => {
        this.onlineUser = onlineUser;
        this.tokenValid = this.onlineUser.Id > 0;
        return this.tokenValid;
      })
      , switchMap((isTokenValid: boolean) => {
        return isTokenValid ? this.onlineUserService.initializeChallenge(this.onlineUser) : of(null);
      })
      , map((challenge: IChallengeInfoDto) => {
        if (challenge != null) {
          this.challenge = challenge;
          this.challenge.ChallengeTypeId = 3;
          this.challenge.PasswordVerificationToken = this.token;
          this.friendlyNames.NewPassword = "New Password";
          this.friendlyNames.ConfirmPassword = "Confirm Password";
        }
      })
    ).subscribe(() => {
      this.loaded = true;
      this.initCommands();
      this.modelToForm();
      this.checkValidationErrors();
      this.initializeEventHandlers();
    });
  }

  protected modelToForm() {
    this.contentForm = new FormGroup({});
    this.contentForm.addControl('NewPassword', new FormControl(null, [Validators.required, Validators.maxLength(20)]));
    this.contentForm.addControl('ConfirmPassword', new FormControl(null, [Validators.required, Validators.maxLength(20), ReachControlValidators.passwordMatchValidator(this.contentForm.get('NewPassword') as FormControl)]));
  }

  protected formToModel = () => {
    this.challenge.UserPassword = this.contentForm.get('NewPassword').value as string;
    this.challenge.ConfirmUserPassword = this.contentForm.get('ConfirmPassword').value as string;
  }

  protected initializeEventHandlers(): void {
    this.contentForm.valueChanges.subscribe(() => {
      this.formToModel();
      this.checkValidationErrors();
    });
  }

  private checkValidationErrors() {
    this.validationManagerService.addFormErrors(this.contentForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames);
  }

  private initCommands() {
    this.saveCommand = this.commandService.create(this.canSaveCommandExecute, this.saveCommandExecute);
  }

  protected canSaveCommandExecute = (): boolean => {
    return true;
  }

  protected saveCommandExecute = () => {
    this.onlineUser.UserAccount.ValidationMode = this.constantsService.VALIDATION_MODES.WEB_PASSWORD_CHANGE;
    this.validationManagerService.clearServerValidationMessagesForKey(this.validationManagerService.validationScopeApplication);

    const doSave = async (): Promise<any> => {

      // Execute password change.
      let x = await this.onlineUserService.challenge(this.challenge).toPromise();
      await this.onlineUserService.resetPassword(x).toPromise();

      return this.validationManagerService.hasServerApplicationValidationMessages
        ? of(false).toPromise()
        : this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);

    };

    return this.busyManagerService.resolve(from(doSave()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PasswordTokenResetRouteResolverService extends DefaultRouteResolverService {
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService) {
    super(
      constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
   * Override in subclass to return the RouteConfiguration data to be used for this resolver.
   */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(
      false, // Require profile refresh
      this.dynamicContentConfigurationProviderService.getConfigurationData(false, this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.PASSWORD_CHANGE), // Dynamic Content Configuration
      this.authorizationConfigurationProviderService.getConfigurationData(true)); // Authorization configuration
  }

}
