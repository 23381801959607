import { IActivityDocumentSubmissionDto,
IDocumentDto,
IOnlineActivityInfoDto,
IRelatedToBaseDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ActivityDocumentSubmissionDto extends BaseDto implements IActivityDocumentSubmissionDto {
	ActivityInfo: IOnlineActivityInfoDto;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	EntityId?: number;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;

}
