<!-- Section Heading -->
<div class="reach-search-result-detail-section-header">
    <span class="fa fa-clock-o p-mr-2"></span>
    <span>Coursework Hours</span>
</div>

<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="courseworkItems" [responsive]="true"
    responsiveLayout="stack">

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th width="25%">
                <span>Knowledge Area</span><br />
                <span>(required hours)</span>
            </th>
            <th>Degree Program</th>
            <th>Other Higher Education</th>
            <th>Continuing Education</th>
            <th>Total</th>
        </tr>
    </ng-template>

    <!-- Body -->
    <ng-template [formGroup]="stepForm" pTemplate="body" let-item>
        <tr>
            <td>
                <span>{{item.ApplicationCourseworkKnowledgeAreaRule.ApplicationCourseworkKnowledgeArea.Description}}<br /></span>
                <span>({{item.ApplicationCourseworkKnowledgeAreaRule.RequiredTotalCourseworkHours}} hours)</span>
            </td>
            <td><span class="p-column-title">Degree Program</span><input formControlName="{{item.Id}}DegreeProgramHours" type="number" pInputText></td>
            <td><span class="p-column-title">Other Higher Education</span><input formControlName="{{item.Id}}OtherHigherEducationHours" type="number" pInputText></td>
            <td><span class="p-column-title">Continuing Education</span><input formControlName="{{item.Id}}CeHours" type="number" pInputText></td>
            <td><span class="p-column-title">Total</span><b>{{getItemTotalHours(item)}}</b></td>
        </tr>
    </ng-template>

    <!-- Footer -->
    <ng-template pTemplate="footer">
        <tr>
            <td class="control-label"><b>Total</b></td>
            <td class="text-right"><b>{{totalDegreeProgramHours}}</b></td>
            <td class="text-right"><b>{{totalOtherHigherEducationHours}}</b></td>
            <td class="text-right"><b>{{totalCeHours}}</b></td>
            <td class="text-right"><b>{{grandTotalHours}}</b></td>
        </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="3">
                <span class="reach-text-muted"><em>No items to display</em></span>
            </td>
        </tr>
    </ng-template>

</p-table>