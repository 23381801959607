
/**
 * Enumerates the severity states for notification messages.
 * Note: Angular CLI/TS understands string enums, VS2017 does not in current config.
 */
export enum MessageSeverities {
  error = "error",
  info = "info",
  warn = "warn",
  success = "success"
}
