import { IDomainAuditDto,
IDomainChecklistItemDto,
IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ICeSponsorshipInfoDto,
ICeSponsorshipStatusDto,
ICeSponsorshipTypeDto } from './licensure-shared-dto';

export class CeSponsorshipInfoDto extends BaseDto implements ICeSponsorshipInfoDto {
	DomainId: number;
	DomainKeyId: number;
	TemporaryDomainKeyId: any;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	EntityId?: number;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	ReferenceValue: string;
	CeSponsorId: number;
	StartDate?: Date;
	EndDate?: Date;
	CeSponsorshipTypeId?: number;
	CeSponsorshipStatusId?: number;
	IndividualCount?: number;
	AnnualCourseCount: number;
	Status: ICeSponsorshipStatusDto;
	Type: ICeSponsorshipTypeDto;
	ScheduledCourses: any[];
	DomainAudits: IDomainAuditDto[];
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	StaffAssigned: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
