export class LicenseSelectorFieldModel {
  constructor(public licenseNumber: number
    , public selectedLicenseeName: string
    , public licenseeEntityId: number
    , public city: string = null
    , public licenseId: number = null
    , public manualEntryRequested: boolean = false
    , public isReadonly: boolean = false) {
  }
}
