import { ElementRef } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Subscription } from 'rxjs';
import { ReachMessageClickedEventArgs } from '@coreModels/reach-message-clicked-event';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { ValidationFailureDto } from '@coreShared/core-shared.module';

export class FormValidationManagerService {
  private validationMessageClickedSubscription: Subscription = null;
  public tooltipMap: Map<string, string>;

  constructor(private elementRef: ElementRef
    , private friendlyNames: any
    , private hostForm: FormGroup
    , private validationManagerService: ValidationManagerService
    , private validationScope: string
  ) {
    this.validationMessageClickedSubscription = this.validationManagerService.validationMessageClicked$.subscribe(args => {
      this.onValidationMessageClicked(args);
    });

    this.tooltipMap = this.validationManagerService.getTooltipScopeMap(this.validationScope);
  }

  public unsubscribe() {
    if (this.validationMessageClickedSubscription) {
      this.validationMessageClickedSubscription.unsubscribe();
    }

    this.validationManagerService.clearValidationMessages(this.validationScope);
  }

  public checkValidationErrors() {
    this.validationManagerService.addFormErrors(this.hostForm, this.validationScope, this.friendlyNames);
  }

  public tooltip(key: string): string {
    let msg = '';
    let form = this.hostForm.get('ValidationForm');
    if (form) {
      let validationForKey = form.get(key);
      if (validationForKey) {
        msg = validationForKey.value;
      }
    }

    return msg;
  }

  public addCustomValidationFailureError(message: string, propertyName: string) {
    let error = {} as ValidationFailureDto;
    error.ErrorMessage = message;
    error.PropertyName = propertyName;
    this.validationManagerService.addValidationFailure(error, this.validationScope);
  }

  public setForm(form: FormGroup) {
    this.hostForm = form;
  }

  private onValidationMessageClicked(args: ReachMessageClickedEventArgs) {
    if (args && args.messageScope === this.validationScope) {
      this.setFocusToFormControl(args.message.formControlName);
    }
  }

  /**
   * Sets the focus to the control on this form with the matching formControlName value.
   * @param formControlName the target formControlName attribute value.
   */
  private setFocusToFormControl(formControlName: string) {
    const queryString = `[formcontrolname=${formControlName}]`;
    let numberOfRecursions = 0;
    let targetElement = this.elementRef.nativeElement;
    if (!targetElement.querySelector) {
      return;
    }

    try {
      const targetControl = this.elementRef.nativeElement.querySelector(queryString);
      if (targetControl) {
        targetControl.focus();
        if (this.elementRef.nativeElement.ownerDocument.activeElement != targetControl) {
          // recurse
          this.setFocusToFormControlChild(formControlName, targetControl, queryString, numberOfRecursions);
        }
      }
    } catch {
    }
  }

  /**
   * Sets the focus to the control on this form's child control with the matching formControlName value.
   * @param formControlName the target formControlName attribute value.
   * @param hostElement the host element of the child control.
   * @param queryString the query string to locate the control.
   * @param numberOfRecursions the depth of the current recursion to control the maximum depth.
   */
  private setFocusToFormControlChild(formControlName: string, hostElement: any, queryString: string, numberOfRecursions: number) {
    let success: boolean = false;
    if (hostElement && numberOfRecursions++ < 12) {
      if (hostElement.childNodes) {
        hostElement.childNodes.forEach(n => {
          if (!n.querySelector) {
            return;
          }
          const childTargetControl = n.querySelector(queryString);
          if (childTargetControl) {
            childTargetControl.focus();
            if (hostElement.ownerDocument.activeElement != childTargetControl) {
              // recurse
              success = this.setFocusToFormControlChild(formControlName, n, queryString, numberOfRecursions);
            } else {
              success = true;
            }
          } else {
            success = this.setFocusToChildInput(n, numberOfRecursions);
          }

          return success;
        });
      }
    }

    return success;
  }

  /**
   * Sets the focus to the first input tag found under the host element.
   * @param hostElement the host element.
   * @param recursion the number of layers deep currently recursed. Limits how deep the recursion goes in searching for the input.
   */
  private setFocusToChildInput(hostElement: any, recursion: number): boolean {
    let success: boolean = false;
    if (hostElement && hostElement.tagName === "INPUT") {
      hostElement.focus();
      success = true;
    } else {
      if (hostElement && hostElement.childNodes && recursion++ < 12) {
        hostElement.childNodes.forEach(n => {
          success = this.setFocusToChildInput(n, recursion);
          return success;
        });

      }
    }

    return success;
  }
}
