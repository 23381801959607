import { Subject, Observable } from "rxjs";

import * as _l from 'lodash-es';

import { RangeModel } from './range-model';

export class ObservableRangeModel {
  private _selectedRangeSubject = new Subject<any>();
  public selectedRangeChanged$ = this._selectedRangeSubject.asObservable();

  constructor(private model: RangeModel) {

  }

  public updateModel(to, from) {
    this.model.to = to;
    this.model.from = from;
    this._selectedRangeSubject.next({ to: to, from: from });
  }

  public getModel() {
    return this.model;
  }

  public cloneModel() {
    return _l.clone(this.model);
  } 
}
