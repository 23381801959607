<div [formGroup]="contentForm">

    <!-- Form Array -->
    <div formArrayName="Questions">

        <div *ngIf="dataLoaded">

            <!-- Questions -->
            <domain-questionnaire-question *ngFor="let item of model.Answers | filterImpure: filterFunction; let $index=index"
                [formControlName]="$index" [model]="item" [possibleAnswers]="possibleAnswers"
                [friendlyNames]="friendlyNames">
            </domain-questionnaire-question>

        </div>

    </div>

</div>