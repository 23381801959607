import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import {
  AuthGuard,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DynamicRoutesService,
  LandingPageDetailRouteResolverService,
  LandingPageService, OnlineServiceLinkManagerService, OnlineServiceMenuService, Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  UserManagerService
} from '@core/core.module';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { ILicenseInfoDto, ILicensureUserProfileInfoDto } from '@licensureShared/licensure-shared.module';
import * as _l from 'lodash-es';
import { IFeatureConfigurationService } from "src/app/core/services/configuration/feature-configuration.service";
import { IProfileSummaryInfoDto } from "src/app/coreShared/core-shared.module";

export const LicenseLandingCardComponentKey = 'licenseLandingCardComponent';

@Injectable({
  providedIn: 'root'
})
export class LicenseFeatureConfigurationService implements IFeatureConfigurationService {

  private isInitialized = false;
  private licenseConstantsService: ILicenseConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , protected dynamicRoutesService: DynamicRoutesService
    , protected landingPageService: LandingPageService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , protected onlineServiceMenuService: OnlineServiceMenuService
    , protected router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , protected userManagerService: UserManagerService) {

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize(): void {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  public configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, LicenseConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, LicenseConstantsService.Mergers.FUNCTION_TYPES);
    this.licenseConstantsService = this.constantsService as ILicenseConstantsService;
  }

  public configureRoutes() {
    const routes: Routes = [
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.LicenseDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.AssociatedLicenseDetailLazy).path + "/:id/:EntityId",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.AssociatedLicenseListLazy).path + "/:id/:LicenseType",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseListLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public addInfosToLandingPage(principal: Principal) {
    if (!this.isInitialized) {
      this.initialize();
    }

    const profile = principal?.user?.UserAccount?.profile as ILicensureUserProfileInfoDto;
    if (profile != null) {

      // Handle Licenses
      const licenses = profile.Licenses;
      if (licenses && licenses.length > 0) {
        const infos: SummaryComponentInfo[] = [];
        const summaryDetailConfiguration = new SummaryDetailConfiguration(
          LicensureLandingCategories.License,
          LicensureLandingComponentKeys.LicenseSummaryLazy,
          LicensureLandingComponentKeys.LicenseDetailLazy,
          SummaryAggregationType.Singleton
        );

        const licenseInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "License", summaryDetailConfiguration);
        licenses.forEach((license: ILicenseInfoDto, index: number) => {
          const suffix = license.LicenseType.ProfessionalSuffix || license.LicenseType.Code;
          const licenseTitle = `${license.LicenseType.LicenseCardNumberType} - ${license.LicenseType.Description} (${suffix})`;
          licenseInfoBuilder.addItem(
            index,
            licenseTitle,
            license,
            this.licenseConstantsService.DOMAIN_IDS.LICENSE
          );
        });

        this.summaryInfoManagerService.mergeInfos(infos);
      }
    }

  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}

export class LicenseConstantsService extends ConstantsService {
  public static Mergers = {
    DOMAIN_IDS: {
      LICENSE: 10,
      APPLICATION: 4
    },
    WEB_SERVICE_PROCESS_TYPES: {
      LICENSE_STATUS_CHANGE: LicensureOnlineServiceProcessTypeKeys.LicenseStatusChange
    },
    FUNCTION_TYPES: {
      LICENSE: 2,
    }
  };

  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), LicenseConstantsService.Mergers.DOMAIN_IDS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), LicenseConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), LicenseConstantsService.Mergers.FUNCTION_TYPES);
}

export interface ILicenseConstantsService extends LicenseConstantsService {
}
