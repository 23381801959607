import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DynamicRoutesService, FunctionTypeService, OnlineServiceRegistry, OnlineServiceRegistryItem, Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { Observable, from } from "rxjs";
import { ApplicationConstantsService } from "./application-feature-configuration.service";
import { IApplicationDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({ providedIn: 'root' })
export class ApplicationFinalFeeWizardFeatureConfigurationService {

  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private applicationfinalfeeConstantsService: IApplicationFinalFeeConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private functionTypeService: FunctionTypeService
    , private wizardTagsService: WizardTagsService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ApplicationLicenseFee);
  }

  /* Called from FeatureManagerService */
  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, ApplicationFinalFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, ApplicationFinalFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.applicationfinalfeeConstantsService = this.constantsService as IApplicationFinalFeeConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/applicationFinalFeeWizard/application-final-fee-wizard.module').then(m => m.ApplicationFinalFeeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/applicationFinalFeeWizard/application-final-fee-wizard.module').then(m => m.ApplicationFinalFeeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/applicationFinalFeeWizard/application-final-fee-wizard.module').then(m => m.ApplicationFinalFeeWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  getWizard(application: IApplicationDto, webServiceHistoryId: number): Observable<Wizard> {

    const functionType = this.functionTypeService.create((this.constantsService as ApplicationConstantsService).FUNCTION_TYPES.APPLICATION, application.Id);
    const wizardTags = this.wizardTagsService.create(application.ApplicationTypeId, application.BasisId);
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.applicationfinalfeeConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION_LICENSE_FEE, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      const wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (webServiceHistoryId) return wizard;
      return await this.wizardService.startWizard(wizard, functionType, application.ApplicationTypeId).toPromise();
    };

    return from(initializeWizard());
  };
}

export class ApplicationFinalFeeConstantsService extends ConstantsService {
  
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: { APPLICATION_FINAL_FEE_WIZARD: 'Application.Final.Fee.Wizard', },
    WEB_SERVICE_PROCESS_TYPES: { APPLICATION_LICENSE_FEE: LicensureOnlineServiceProcessTypeKeys.ApplicationLicenseFee, },
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), ApplicationFinalFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), ApplicationFinalFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
}

export interface IApplicationFinalFeeConstantsService extends ApplicationFinalFeeConstantsService {
}
