import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeProviderTypeDto,
IContinuingEducationBasicDto } from './licensure-shared-dto';

export class ContinuingEducationBasicDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IContinuingEducationBasicDto {
	LicenseId: number;
	Organization: string;
	Title: string;
	CeProviderTypeId?: number;
	CeProviderType: ICeProviderTypeDto;
	Hours?: number;
	CompleteDate?: Date;
	Notes: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
