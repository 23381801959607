<p-panel styleClass="reach-landing-panel">
  <!-- Buttons -->
  <ng-template pTemplate="icons">
    <button pButton pRipple type="button" class="p-button p-button-text p-button-secondary reach-button-muted-icon"
      label="More" icon="fa fa-chevron-right" iconPos="right" (click)="onDetail()"></button>
  </ng-template>
  <!-- Header -->
  <p-header>
    {{readonlyInfo.title}}
  </p-header>
  <!-- Grid -->
  <div class="p-grid p-nogutter">
    <!-- Information -->
    <div class="p-lg-7 p-xl-8 p-grid">
      <!-- Reference # -->
      <div class="p-col-12 p-field p-grid p-nogutter">
        <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Reference #</label>
        <span class="p-col p-mb-0">{{readonlyInfo.id}}</span>
      </div>
      <!-- Description -->
      <div class="p-col-12 p-field p-grid p-nogutter">
        <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Description</label>
          <span class="p-col p-mb-0">{{readonlyInfo.description}}</span>
      </div>
      <!-- Amount -->
      <div class="p-col-12 p-field p-grid p-nogutter">
        <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Amount</label>
        <span class="p-col p-mb-0">{{readonlyInfo.amount | currency}}</span>
      </div>
    </div>

    <!-- Links -->
    <div class="p-lg-5 p-xl-4">
      <online-service-link caption="Actions" [model]="onlineServiceLinkModel"></online-service-link>
    </div>
  </div>
</p-panel>