import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { IOnlineEntitySearchCriteriaDto } from './licensure-shared-dto';

export class OnlineEntitySearchCriteriaDto extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, IOnlineEntitySearchCriteriaDto {
	EntityId?: number;
	LastName: string;
	FirstName: string;
	LicenseType: string;
	City: string;
	Zip: string;
	CountyId?: number;
	CertificationTypeId?: number;
	CertificationSource: string;
	CertificationBoard: string;
	LicenseNumber?: number;
	IncludeAlternateNames: boolean;
	IncludeNameHistory: boolean;
	BirthDate?: Date;
	IsLicensingSupervisor?: boolean;
	SupervisorCity: string;
	SupervisorZip: string;
	LicenseStatus: string;
	ExcludeEntitiesWithHolds: boolean;

}
