import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import * as _l from 'lodash-es';

import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from "@core/core.module";
import { IHttpResponseContent } from "@coreShared/core-shared.module";
import { EducationProgramDto } from "@licensureShared/licensure-shared.module";


@Injectable({
  providedIn: 'root'
})
export class EducationProgramService extends ReachHttpClientService {
  private uri: string;
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/licensure/EducationProgram`;
  }

  /**
   * Gets an initialized application.
   * @param entityId 
   */
  public initializeApplication(entityId) {

    // TODO: convert this over to RxJs
    const createNewApplication = (): Observable<EducationProgramDto> => {
      return this.get<EducationProgramDto>(`${this.uri}/initialize`);
    };

    // API call.
    const saveApplication = (val: EducationProgramDto): Observable<EducationProgramDto> => {
      //application.EntityId = entityId;
      val.Id = entityId;
      return this.post<EducationProgramDto>(`${this.uri}/initialize/item`, val);
    };

    let res = createNewApplication().pipe(mergeMap((val: EducationProgramDto) => saveApplication(val)));
    return res;
  }

  /**
   * Get the application with the supplied ID.
   * @param id 
   */
  public getById(id): Observable<EducationProgramDto> {
    return this.get<EducationProgramDto>(`${this.uri}/${id}`);
  }

  /**
   * Saves the application.
   * @param val 
   */
  public save(val: EducationProgramDto): Observable<EducationProgramDto> {

    // Updating.
    if (val.Id) {
      return this.put<EducationProgramDto>(this.uri, val);
    }

    // Creating.
    else {
      return this.post<EducationProgramDto>(this.uri, val);
    }
  }

  /**
   * Wraps the specified in an HttpResponseContent.
   * @param application EducationProgramApplicationDto in an IHttpResponseContent<EducationProgramApplicationDto>.
   */
  private asResponseContent(application: EducationProgramDto): IHttpResponseContent<EducationProgramDto> {
    return this.utilitiesService.asResponseContent(application);
  }
}
