import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDocumentHistoryItem, IDocumentHistorySearchCriteriaDto, IOnlineActivityDto } from 'src/app/coreShared/core-shared.module';
import { Command, OnlineServiceLinkSubscription, OnlineServiceProcessTypes } from '../../index-models';
import { CommandService, DocumentService, OnlineActivityService, OnlineServiceHistoryService } from '../../index-services';
import { id } from 'date-fns/locale';
import { OnlineServiceLinkManagerService, OnlineServiceLinkModel } from '../../services/online-service-link-manager.service';

/**
 * A landing page style view accessed via related-activity-list component.
 * Provides maximum activity details and related checklist. Uses online-only controllers and service providers.
 */
@Component({
  selector: 'activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss']
})
export class ActivityDetailComponent implements OnInit {

  activity: IOnlineActivityDto;
  historyItems: IDocumentHistoryItem[];
  downloadCommand: Command;
  onlineServiceLinkModel: OnlineServiceLinkModel;

  constructor(private route: ActivatedRoute
    , private activityService: OnlineActivityService
    , private commandService: CommandService
    , private onlineServiceHistoryService: OnlineServiceHistoryService
    , private docService: DocumentService
    , private onlineServiceLinkManagerService: OnlineServiceLinkManagerService) {
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('Id'));

    this.initCommands();
    this.initSubscriptions(id);

    this.activityService.getById(id)
      .subscribe((result) => {
        this.activity = result;

        const documentSearchCriteria = {
          RelatedTo: [this.docService.createRelatedToSearchCriterion(this.activity.FunctionTypeId, this.activity.FunctionNumber, this.activity.ProfessionTypeCode)]
        } as IDocumentHistorySearchCriteriaDto;

        this.docService.getHistory(documentSearchCriteria).subscribe((historyItems: IDocumentHistoryItem[]) => { this.historyItems = historyItems; });

      });
  }
  
  initSubscriptions(id: number) {
    const subscriptions: OnlineServiceLinkSubscription[] = [];
    subscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Activity, () => { return id; }));
    this.onlineServiceLinkModel = this.onlineServiceLinkManagerService.getOnlineServiceLinksForSubscriptions(subscriptions);
  }

  initCommands() {
    this.downloadCommand = this.commandService.create(() => true, (item: IDocumentHistoryItem) => {
      this.onlineServiceHistoryService.downloadConfirmationDocument(item.DocumentId);
      (item as any).IsDownloaded = true;
    });
  }

  getIcon(item: any): string {
    return item.IsDownloaded ? "fa fa-check" : "fa fa-download";
  }

  getTooltip(item: any): string {
    return item.IsDownloaded ? "This document was downloaded in the current session. Click to download again." : "View/download review document";
  }

}
