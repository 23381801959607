import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { BusyManagerService, CheckboxContentService, Command, CommandService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DialogSettings, DomainChecklistItemService, DomainChecklistItemStatusTypeService, ReachScenarios, registerDynamicComponent, UtilitiesService, ValidationManagerService, WIZARD_INJECTOR_TOKEN, WizardInjector, WizardStepComponent } from '@core/core.module';
import { ICertificationChecklistItemDto, ICertificationDto } from '@licensureShared/licensure-shared.module';
import { ConfirmationService } from 'primeng/api';
import { from, of } from 'rxjs';
import { IDomainChecklistDtoHost } from 'src/app/coreShared/core-shared.module';
import { LicensureConstantsProviderService } from '../../licensure-core.module';
import { ProfessionalProfileService } from '../../services/professional-profile.service';
import { ProfileCertificationsEditorComponent } from './editor/profile-certifications-editor.component';

@Component({
  selector: 'profile-certifications',
  templateUrl: './profile-certifications.component.html',
  styleUrls: ['./profile-certifications.component.scss']
})
export class ProfileCertificationsComponent extends WizardStepComponent implements OnInit, AfterViewInit, OnDestroy {

  removeItemCommand: Command;
  presentItemEditorCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings;
  applicableItems: ICertificationDto[] = [];

  licensureConstantsService: LicensureConstantsProviderService;
  checklistItemHost: IDomainChecklistDtoHost;
  templateId: number;

  constructor(
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    private commandService: CommandService,
    private utilitiesService: UtilitiesService,
    private confirmationService: ConfirmationService,
    private domainChecklistItemService: DomainChecklistItemService,
    private domainChecklistItemStatusTypeService: DomainChecklistItemStatusTypeService,
    private busyManagerService: BusyManagerService,
    private professionalProfileService: ProfessionalProfileService,
  ) {

    super(constantsService, validationManagerService, wizardInjector, ProfileCertificationsComponent.name, checkboxContentService, elementRef);

    this.licensureConstantsService = this.constantsService as LicensureConstantsProviderService;

    this.modelToForm();
  }

  override ngOnInit(): void {

    if (this.wizard.model.renewal) {
      this.templateId = (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.RENEWAL_CERTIFICATION_CHECKLIST_TEMPLATE_ITEM_ID;
      this.checklistItemHost = this.wizard.model.renewal;
    }
    else if (this.wizard.model.application) {
      this.templateId = (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_CERTIFICATION_CHECKLIST_TEMPLATE_ITEM_ID;
      this.checklistItemHost = this.wizard.model.application;
    }
    else {
      console.log('Something went wrong in profile-certifications-component. No checklistItemHost was found. As a result, no checklist item was added.')
      return;
    };

    // IMPORTANT: non-standard pattern. Becauase readonly data from the license is reflected on the ProfessionalProfile, 
    // some data may not be current on the ProfessionalProfile object unless it is reloaded here.
    // The most common case for this would be adding a certification, moving forward a step, and then moving back to this step.
    // This solution allows us to use this step in various services without having to modify the saving procedure of each one.
    this.getLatestProfessionalProfile();

    this.okCommand = this.commandService.create(() => true, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create((x: ICertificationDto) => this.canPresentEditorCommandExecute(x), this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create((x: ICertificationDto) => this.canRemoveItemCommandExecute(x), this.removeItemCommandExecute);

    this.filterChecklistItems();
    super.ngOnInit();
  }

  private getLatestProfessionalProfile(): void {

    const doInit = async (): Promise<any> => {

      this.wizard.model.professionalProfile = await this.professionalProfileService.getById(this.wizard.model.professionalProfile.Id).toPromise();
      this.filterChecklistItems();

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  protected filterChecklistItems(): void {
    this.applicableItems = this.wizard.model.professionalProfile.Certifications.filter(item => !(item.StatusId == this.licensureConstantsService.CERTIFICATION.STATUSES.NOT_APPLICABLE));
    this.applicableItems.forEach(c => { if (c.CertificationType?.IsSubcertification && !(c.CertificationType.Description.indexOf("[Sub Certificate]") > -1)) c.CertificationType.Description = `[Sub Certificate] ${c.CertificationType?.Description}`; })
  }

  // ==================================== COMMANDS ====================================

  protected okCommandExecute = (item: ICertificationDto) => {
    item.IsLicenseeVerified = true;

    this.utilitiesService.addOrReplace(this.wizard.model.professionalProfile.Certifications, item);

    var relatedCli = this.checklistItemHost.DomainChecklist.find(x => x.ChecklistItemTemplateId == this.templateId && ((x as ICertificationChecklistItemDto).Certification.LocalId == item.LocalId || x.ChildDomainKeyId == item.Id));
    if (relatedCli) {
      (relatedCli as ICertificationChecklistItemDto).Certification = item;
      this.filterChecklistItems();
    }
    else this.createCertificationChecklistItem(item);
  }

  protected createCertificationChecklistItem(item: ICertificationDto): void {

    const createCli = async (): Promise<any> => {

      var cli: ICertificationChecklistItemDto = (await this.domainChecklistItemService.initializeItem(this.checklistItemHost, this.templateId).toPromise()).DomainChecklistItem as ICertificationChecklistItemDto;
      cli.Certification = item;
      this.utilitiesService.addOrReplace(this.checklistItemHost.DomainChecklist, cli);
      this.filterChecklistItems();

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(createCli()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  protected canPresentEditorCommandExecute = (item: ICertificationDto): boolean => {
    return item == null || item.StatusId == this.licensureConstantsService?.CERTIFICATION?.STATUSES?.NEW;
  }

  protected presentEditorCommandExecute = (element: ICertificationDto) => {

    if (element) element.IsDirty = true;

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doPresent = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ProfileCertificationsEditorComponent, // Content type
        'ProfileCertificationsEditorComponent', // Content key
        element ? "Certification" : "Certification - {new}", // Title
        element ?? {
          Id: 0,
          IsNew: true,
          IsDeleted: false,
          LocalId: this.utilitiesService.guid(),
          EntityId: this.wizard.model.professionalProfile.Id,
          StatusId: this.licensureConstantsService.CERTIFICATION.STATUSES.NEW,
          ExpireTypeId: this.licensureConstantsService.CERTIFICATION.EXPIRE_TYPES.LIMITED
        } as ICertificationDto, // Model
      );

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      return of(true).toPromise();
    }

    return from(doPresent());
  }

  protected canRemoveItemCommandExecute = (item: ICertificationDto): boolean => {
    return item.StatusId == this.licensureConstantsService?.CERTIFICATION?.STATUSES?.NEW;
  }

  protected removeItemCommandExecute = (item: ICertificationDto) => {

    var derivedDescription: string = item.DerivedEffectiveDate ? item.DerivedDescription : "An unsaved new item";

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `${derivedDescription} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      // If accepted...
      accept: () => {

        if (item.LocalId) {
          this.checklistItemHost.DomainChecklist.splice(this.checklistItemHost.DomainChecklist.findIndex(x => x.LocalId == item.LocalId), 1); // Unsaved new CLI.
          this.applicableItems.splice(this.applicableItems.findIndex(x => x.LocalId == item.LocalId), 1); // Entry in ProfessionalProfile.Certifications
        }
        else {
          var relatedCli = this.checklistItemHost.DomainChecklist.find(x => x.ChecklistItemTemplateId == this.templateId && x.ChildDomainKeyId == item.Id);
          if (relatedCli) {
            this.domainChecklistItemStatusTypeService.setStatusForAspectStatus(relatedCli, this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.NOT_APPLICABLE);
            (relatedCli as ICertificationChecklistItemDto).Certification.StatusId = this.licensureConstantsService.CERTIFICATION.STATUSES.NOT_APPLICABLE;
            (relatedCli as ICertificationChecklistItemDto).Certification.IsDirty = true;
          }
        }

        item.StatusId = this.licensureConstantsService.CERTIFICATION.STATUSES.NOT_APPLICABLE;
        this.filterChecklistItems();
      }

    });
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, 'profileCertifications', ProfileCertificationsComponent, 'profile-certifications');
registerDynamicComponent(ReachScenarios.ProfessionalProfileWizard, 'profileCertifications', ProfileCertificationsComponent, 'profile-certifications');
registerDynamicComponent(ReachScenarios.ApplicationWizard, 'profileCertifications', ProfileCertificationsComponent, 'profile-certifications');
