<div [formGroup]="stepForm">

    <!-- Info -->
    <div style="display: flex;">
        <div class="p-mr-2 p-mb-2 reach-primary-color">Exemption</div>
        <div *ngIf="exemptionDescription" style="font-weight: bold;">{{exemptionDescription}}</div>
        <div *ngIf="!exemptionDescription" style="color: grey; font-style: italic; font-weight: bold;">none</div>
    </div>

    <!-- RadioContentItems -->
    <radio-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey"
        [defaultMinorKey]="defaultDynamicContentMinorKey" [bag]="wizard.selectedStep.bag"></radio-content-items>

    <!-- File Upload -->
    <checklist-file-upload-items #checklistFileUploadItems [uploadableChecklistItemIds]="templateIds"
        [domainChecklistDtoHost]="wizard.model.DomainChecklistDtoHost"></checklist-file-upload-items>

</div>
