import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { IApplicationTypeDto } from './licensure-shared-dto';

export class ApplicationTypeDto extends LookupDto implements IApplicationTypeDto {
	Type: string;
	Hierarchy?: number;
	IsOnlineApplicationSupported: boolean;
	IsPaperApplicationSupported: boolean;
	SchoolsEntityGroupId?: number;
	OtherSchoolsEntityGroupId?: number;
	IsIndividual?: boolean;
	IsExcludedOnline: boolean;
	ProfessionTypeId: number;
	RequiresCriminalBackgroundCheck: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
  SupervisionVerificationsSupported: boolean;
  SupervisionPlansSupported: boolean;
}
