import { Inject, Component, EventEmitter, Output, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import * as _l from 'lodash-es';
import * as _ from 'underscore';

// Reach Imports
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { LoginComponentKeys, LandingComponentKeys } from '@core/index-constants';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { MenuItem } from 'primeng/api';
import { DynamicContentManagerService } from 'src/app/core/index-services';
import { DynamicContentChangedEventArgument } from 'src/app/core/models/eventArgs/dynamic-content-changed-event-argument';
import { MenuGroup } from 'src/app/core/models/menu-group';

@Component({
    selector: 'reach-app-topbar',
    templateUrl: './reach-app-topbar.component.html',
    styleUrls: ['./reach-app-topbar.component.scss'],
    animations: [
        trigger('overlayMenuAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ReachAppTopbarComponent implements OnInit, OnDestroy {

    @Output() menuButtonClick: EventEmitter<any> = new EventEmitter();
    @ViewChild('topbarMenu') topbarMenu: ElementRef;

    activeMenuIndex: number;
    outsideClickListener: any;
    subscription: Subscription;

    // Reach properties
    private isAppInit: boolean = false;
    imagePath: string;

    public ASSETS;
    public VALIDATION_ERROR_SCOPES;
    public validationErrorScope: string;
    public notificationScope: string;
    public SYSTEM_SETTING_KEYS;
    public BUSY_MANAGER_BUSY_TYPES;
    public DYNAMIC_CONTENT_MAJOR_KEYS;
    public DYNAMIC_CONTENT_LOCATION_TYPES;
    public backgroundHeaderImageStyle: SafeStyle;
    public logoImage: string = "";
    public brand;
    public userImage: string = "";
    public printLogo: string = "";
    public showBreadcrumbs = false;
    public majorHelpKey: string = "";
    public minorHelpKey: string = "Navbar.Help";

    // HELP MENU
    helpMenuItems: MenuItem[] = [];
    helpMenuGroups: MenuGroup[] = [];
    private dynamicContentChangedSubscription: Subscription;

    // CTOR
    constructor(
        @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
        private commandService: CommandService,
        @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
        private sanitizer: DomSanitizer,
        private onlineServiceMenuService: OnlineServiceMenuService,
        public reachApplicationService: ReachApplicationService,
        private router: Router,
        private systemSettingsManagerService: SystemSettingsManagerService,
        private userManagerService: UserManagerService,
        private dynamicContentManagerService: DynamicContentManagerService
    ) {
    }

    /**
    * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
    * Define an ngOnInit() method to handle any additional initialization tasks.
    */
    ngOnInit() {

        this.imagePath = this.defaultProviderConfigurationService.customProviderAssetRoot;
        this.validationErrorScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
        this.notificationScope = this.constantsService.NOTIFICATION_SCOPES.APPLICATION;
        this.reachApplicationService.applicationInitialized$.subscribe(args => this.onApplicationInitialized(args));

        this.ASSETS = this.constantsService.ASSETS;
        this.VALIDATION_ERROR_SCOPES = this.constantsService.VALIDATION_ERROR_SCOPES;
        this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
        this.BUSY_MANAGER_BUSY_TYPES = this.constantsService.BUSY_MANAGER_BUSY_TYPES;
        this.DYNAMIC_CONTENT_MAJOR_KEYS = this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS;
        this.DYNAMIC_CONTENT_LOCATION_TYPES = this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES;
        this.majorHelpKey = this.DYNAMIC_CONTENT_MAJOR_KEYS.SHARED_CONTENT;
        this.minorHelpKey = 'Navbar.Help';

        // Listen for changes
        this.dynamicContentChangedSubscription = this.dynamicContentManagerService.contenthanged$.subscribe(
            (changedEventArgument: DynamicContentChangedEventArgument) => this.buildHelpMenu(changedEventArgument.dynamicContentEntries));

        this.doInit();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.activeMenuIndex = null;
            }
        });
    }

    private buildHelpMenu(dynamicContentEntries) {
        this.helpMenuItems = [];
        this.helpMenuGroups = [];

        let dynamicHelpEntries = this.getDynamicHelpEntries(dynamicContentEntries);

        let groupedNavigationBarHelpItems = _l.groupBy(_l.sortBy(dynamicHelpEntries,
            (item) => {
                return item.DisplayOrder;
            }),
            (navBarHelpItem) => {
                return navBarHelpItem.GroupName;
            });

        _.map(_.keys(groupedNavigationBarHelpItems),
            (groupKey) => {
                var menuGroup = new MenuGroup(groupKey);

                // For each item in each group, we add the menu items with commands
                _.map(groupedNavigationBarHelpItems[groupKey],
                    (item) => {

                        if (item.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.MODAL) {
                            menuGroup.addMenuItem(this.dynamicContentManagerService.createModalContentCommand(item));
                        } else if (item.PresentationTypeId ===
                            this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.EXTERNAL_URL) {
                            menuGroup.addMenuItem(this.dynamicContentManagerService.createExternalContentCommand(item));
                        } else {
                            throw "presentation type not supported in menuHelp directive.";
                        }
                    });

                this.helpMenuGroups.push(menuGroup);
            });

        this.helpMenuItems = _l.flatten(_.pluck(this.helpMenuGroups, "items"));
    }

    private getDynamicHelpEntries(dynamicContentEntries) {
        return _l.filter(dynamicContentEntries,
            (entry) => {
                return (entry.MajorKey === this.majorHelpKey) &&
                    entry.Content.length > 0 &&
                    entry.LocationTypeId === this.DYNAMIC_CONTENT_LOCATION_TYPES.NAVBAR_MENU && entry.MinorKey === this.minorHelpKey;
            });
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick.emit();
        event.preventDefault();
    }

    changeTheme(event: Event, theme: string, dark: boolean) {
        this.activeMenuIndex = null;
        event.preventDefault();
    }

    bindOutsideClickListener() {
        if (!this.outsideClickListener) {
            this.outsideClickListener = (event) => {
                if (this.isOutsideTopbarMenuClicked(event)) {
                    this.activeMenuIndex = null;
                }
            };

            document.addEventListener('click', this.outsideClickListener);
        }
    }

    unbindOutsideClickListener() {
        if (this.outsideClickListener) {
            document.removeEventListener('click', this.outsideClickListener);
            this.outsideClickListener = null;
        }
    }

    toggleMenu(event: Event, index: number) {
        this.activeMenuIndex = this.activeMenuIndex === index ? null : index;
        event.preventDefault();
    }

    isOutsideTopbarMenuClicked(event): boolean {
        return !(this.topbarMenu.nativeElement.isSameNode(event.target) || this.topbarMenu.nativeElement.contains(event.target));
    }

    onOverlayMenuEnter(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.bindOutsideClickListener();
                break;

            case 'void':
                this.unbindOutsideClickListener();
                break;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.dynamicContentChangedSubscription.unsubscribe();
    }

    public get isUserLoggedIn(): boolean {
        return this.userManagerService.isLoggedIn;
    }

    // Reach methods
    /**
     * Initialize the component properties.
     */
    private doInit() {
        var backgroundHeaderImageUrl: string = this.defaultProviderConfigurationService.customProviderAssetRoot + "/headerBackgroundSlice.png";
        this.backgroundHeaderImageStyle = this.sanitizer.bypassSecurityTrustStyle('url(' + backgroundHeaderImageUrl + ')');

        // TODO:
        //this.logoImage = this.defaultProviderConfigurationService.customProviderAssetRoot + "/" + this.ASSETS.IMAGES.AGENCY_LOGO;
        this.logoImage = this.defaultProviderConfigurationService.coreAssetRoot + "/img/mn-logo-blue-footer.png";
        this.userImage = this.defaultProviderConfigurationService.coreAssetRoot + '/img/' + this.ASSETS.IMAGES.USER_GENERIC;
        this.printLogo = this.defaultProviderConfigurationService.customProviderAssetRoot + "/logo_white_bg.png";

        if (this.isAppInit || this.reachApplicationService.isApplicationInInitialized) {
            this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
        }

        //this.onApplicationInitialized(this.bootstrapperService.isInitialized);
    }

    /**
    * Handler for the "bootstrapper.applicationInitialized" event.
    * @param the event args.
    */
    private onApplicationInitialized(isInitialized: boolean) {
        this.isAppInit = isInitialized;
        if (isInitialized) {
            this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
        }
    }


    // Home Command - return to landing page if logged in; otherwise to the login page
    homeCommand = this.commandService.createButtonCommand(
        () => { return this.userManagerService.getCurrentPrincipal() != null },
        () => {
            if (this.userManagerService.getCurrentPrincipal()) {
                // Navigate to landing page
                this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
            } else {
                // Navigate to login page
                this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
            }
        }
    );

    onlineServicesCommands = this.onlineServiceMenuService.commands;
}
