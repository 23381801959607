import { Inject, Injectable } from "@angular/core";
import {
  Router, Routes
} from '@angular/router';
import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DynamicRoutesService, LandingPageDetailRouteResolverService, LandingPageService,
  OnlineServiceMenuService,
  OnlineServiceRegistry,
  Principal,
  ReachScenarios,
  RouteInfoRegistry, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService, UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { from } from "rxjs";
import { ICeSponsorInfoDto, ILicensureUserProfileInfoDto } from "src/app/licensureShared/dto-gen/licensure-shared-dto";
import { CeSponsorshipLandingModel } from "../../landingPage/ceSponsorship/models";

@Injectable({ providedIn: 'root' })
export class CeSponsorshipFeatureConfigurationService {

  private isInitialized = false;
  private ceSponsorshipConstantsService: CeSponsorshipConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService
    , private landingPageService: LandingPageService
    , private onlineServiceMenuService: OnlineServiceMenuService
    , private router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private userManagerService: UserManagerService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
  ) {
    this.ceSponsorshipConstantsService = this.constantsService as CeSponsorshipConstantsService;
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CeSponsorship).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/ceSponsorship/ce-sponsorship-wizard.module').then(m => m.CeSponsorshipWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CeSponsorship).selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/ceSponsorship/ce-sponsorship-wizard-selector.module').then(m => m.CeSponsorshipWizardSelectorModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.CeSponsorshipDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeSponsorshipDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      },
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private configureConstants() {

    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, CeSponsorshipConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, CeSponsorshipConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR.ONLINE_LOOKUP, CeSponsorshipConstantsService.Mergers.SYSTEM_SETTING_KEYS.CE_SPONSOR.ONLINE_LOOKUP);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS.CE_SPONSORSHIP, CeSponsorshipConstantsService.Mergers.SYSTEM_SETTING_KEYS.CE_SPONSORSHIP);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, CeSponsorshipConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, CeSponsorshipConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, CeSponsorshipConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.CARD_DISPLAY_ORDER, CeSponsorshipConstantsService.Mergers.CARD_DISPLAY_ORDER);
    this.ceSponsorshipConstantsService = this.constantsService as ICeSponsorshipConstantsService;
  }

  private configureDecorators() {

  }

  public getWizard(sponsorshipTypeId: number, webServiceHistoryId: number) {
    let wizardTags = this.wizardTagsService.create(null, null, null, null, null, sponsorshipTypeId, null);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.ceSponsorshipConstantsService.WEB_SERVICE_PROCESS_TYPES.SPONSORSHIP, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      return wizard;
    };

    return from(initializeWizard());
  }

  private addInfosToLandingPage(principal: Principal): void {

    const sponsors: ICeSponsorInfoDto[] = (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).Sponsors;
    if (sponsors == null || sponsors.length != 1 || sponsors[0]?.Sponsorships == null) {
      return;
    }

    const ceSponsorShipSummaryConfiguration = new SummaryDetailConfiguration(
      LicensureLandingCategories.CeSponsorship,
      LicensureLandingComponentKeys.CeSponsorshipSummaryLazy,
      LicensureLandingComponentKeys.CeSponsorshipDetailLazy,
      SummaryAggregationType.Singleton);

    let infos: SummaryComponentInfo[] = [];
    let infoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "CE Sponsorship", ceSponsorShipSummaryConfiguration);

    sponsors[0].Sponsorships.forEach((sponsorship) => {
      let model = new CeSponsorshipLandingModel()
      model.sponsor = sponsors[0];
      model.sponsorship = sponsorship;
      model.Id = sponsorship.Id;

      infoBuilder.addItem(sponsorship.Id, 'Sponsorship', model, this.ceSponsorshipConstantsService.DOMAIN_IDS.CE_SPONSORSHIP, this.ceSponsorshipConstantsService.CARD_DISPLAY_ORDER.CE_SPONSORSHIP);
    });

    this.summaryInfoManagerService.mergeInfos(infos);
    this.addOnlineServiceMenuOption();
  }

  private addOnlineServiceMenuOption() {
    const canExecuteFunction = () => {
      let currentPrincipal = this.userManagerService.getCurrentPrincipal();
      let isLoggedIn = currentPrincipal && this.userManagerService.hasSessionToken();
      let isLoggedInUserAnIndividual = isLoggedIn && currentPrincipal.user.UserAccount.profile.IsIndividual;
      let hideMenu = isLoggedInUserAnIndividual;

      if (hideMenu) {
        return false;
      }

      return true;
    };

    this.onlineServiceMenuService.addOnlineServiceMenuItem(this.ceSponsorshipConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR.ONLINE_LOOKUP.FEATURE_CE_SPONSORSHIP_ONLINE_SERVICE_MENU_GROUP
      , this.ceSponsorshipConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR.ONLINE_LOOKUP.FEATURE_CE_SPONSORSHIP_ONLINE_SERVICE_MENU_GROUP_ORDER
      , "Submit a CE Provider Application"
      , OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CeSponsorship).selectorPath
      , this.ceSponsorshipConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSORSHIP.ENABLED
      , false
      , canExecuteFunction
    );
  }
}


/**
 * CeSponsorship setting constants to merge into ConstantsService.
 */
export class CeSponsorshipConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      CE_SPONSORSHIP_WIZARD: 'CeSponsorship.Wizard',
      CE_SPONSORSHIP_WIZARD_SELECTOR: 'CeSponsorship.Wizard.Selector',
    },
    CARD_DISPLAY_ORDER: {
      CE_SPONSORSHIP: 210
    },
    FEATURE_CONSTANTS: {
      SPONSORSHIP_WIZARD: {
        COURSE_STATUS_IDS: {
          NEW: 1,
          IN_PROCESS: 2,
          APPROVED: 3,
          DENIED: 4
        },
        SPONSORSHIP_TYPE: {
          INDIVIDUAL: 1,
          ANNUAL: 2
        }
      },
      CE_SPONSORSHIP_STATUSES: {
        ACTIVE: 1,
        EXPIRED: 2,
        STARTED_NOT_PAID: 3,
        REVOKED: 4
      },
      CE_SPONSOR_COURSE_STATUSES: {
        NEW: 1,
        IN_PROCESS: 2,
        APPROVED: 3,
        DENIED: 4
      }
    },
    SYSTEM_SETTING_KEYS: {
      CE_SPONSOR: {
        ONLINE_LOOKUP: {
          FEATURE_CE_SPONSORSHIP_ONLINE_SERVICE_MENU_GROUP: "Feature.ContinuingEducationSponsorship.OnlineServiceMenu.Group",
          FEATURE_CE_SPONSORSHIP_ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.ContinuingEducationSponsorship.OnlineServiceMenu.GroupOrder"
        }
      },
      CE_SPONSORSHIP: {
        COURSES: {
          ENABLED: "Feature.ContinuingEducationSponsorship.Courses.Enabled",
          ONLINE_SUBMISSION: {
            ENABLED: "Feature.ContinuingEducationSponsorship.Courses.OnlineSubmission.Enabled"
          },
        },
        ONLINE_DEFAULT_END_DAY: "Feature.ContinuingEducationSponsorship.OnlineDefaultEndDay",
        SPONSOR_LABEL: "Feature.ContinuingEducationSponsorship.Sponsor.Label",
        SPONSORSHIP_LABEL: "Feature.ContinuingEducationSponsorship.Sponsorship.Label"
      }
    },
    FUNCTION_TYPES: {
      CE_SPONSOR: 20,
      CE_SPONSORSHIP: 21,
    },
    DOMAIN_IDS: {
      CE_SPONSORSHIP: 18
    },
    WEB_SERVICE_PROCESS_TYPES: {
      SPONSORSHIP: LicensureOnlineServiceProcessTypeKeys.CeSponsorship
    },
  };

  CARD_DISPLAY_ORDER = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).CARD_DISPLAY_ORDER), CeSponsorshipConstantsService.Mergers.CARD_DISPLAY_ORDER);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), CeSponsorshipConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), CeSponsorshipConstantsService.Mergers.FEATURE_CONSTANTS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), CeSponsorshipConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), CeSponsorshipConstantsService.Mergers.DOMAIN_IDS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), CeSponsorshipConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), CeSponsorshipConstantsService.Mergers.FUNCTION_TYPES);
}

export interface ICeSponsorshipConstantsService extends CeSponsorshipConstantsService {
}

