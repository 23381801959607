import { Injectable, Inject } from '@angular/core';
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import * as _l from 'lodash-es';

import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService,
  DocumentService
} from "@core/core.module";
import { EducationProgramApplicationDto } from "@licensureShared/licensure-shared.module";


@Injectable({
  providedIn: 'root'
})
export class EducationProgramApplicationService extends ReachHttpClientService {
  private uri: string;
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService,
    private documentService: DocumentService
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = `${this.apiRootUri}/licensure/EducationProgramApplication`;
  }

  /**
   * Gets an initialized application.
   * @param entityId 
   */
  public initializeApplication(entityId) {

    // TODO: convert this over to RxJs
    const createNewApplication = (): Observable<EducationProgramApplicationDto> => {
      return this.get<EducationProgramApplicationDto>(`${this.uri}/initialize`);
    };

    // API call.
    const saveApplication = (application: EducationProgramApplicationDto): Observable<EducationProgramApplicationDto> => {
      //application.EntityId = entityId;
      application.Id = entityId;
      return this.post<EducationProgramApplicationDto>(`${this.uri}/initialize/item`, application);
    };

    let res = createNewApplication().pipe(mergeMap((val: EducationProgramApplicationDto) => saveApplication(val)));
    return res;
  }

  /**
 * Get all EducationProgramApplications associated with staff/directors.
 */
  public getAll(): Observable<EducationProgramApplicationDto[]> {
    return this.get<EducationProgramApplicationDto[]>(`${this.uri}`);
  }

  /**
   * Get the application with the supplied ID.
   * @param id 
   */
  public getById(id): Observable<EducationProgramApplicationDto> {
    return this.get<EducationProgramApplicationDto>(`${this.uri}/${id}`);
  }

  /**
   * Saves the application.
   * @param element 
   */
  public save(element: EducationProgramApplicationDto): Observable<EducationProgramApplicationDto> {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doSave = async (): Promise<EducationProgramApplicationDto> => {

      // First, upload documents.
      let fileUploadDtoHost = await this.documentService.uploadDocuments(element);
      if (!(fileUploadDtoHost as any).IsValid) return;
      let savedItem: EducationProgramApplicationDto;

      if (element.Id) savedItem = await this.put<EducationProgramApplicationDto>(this.uri, fileUploadDtoHost).toPromise(); // Updating.
      else savedItem = await this.post<EducationProgramApplicationDto>(this.uri, fileUploadDtoHost).toPromise(); // Creating.

      // If any documents exist, we need to make sure they are added back to the model here.
      if (fileUploadDtoHost.Documents.length > 0) savedItem.Documents = fileUploadDtoHost.Documents;

      // Finally, return the item.
      return savedItem;
    }

    // Kick off the function defined above.
    return from(doSave());
  }
}