import { Inject, Injectable } from '@angular/core';
import { CheckboxContentChangedEventArgument } from '@coreModels/eventArgs/checkbox-content-changed-event-arguments';
import { Subject } from "rxjs";
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DynamicContentManagerService } from './dynamic-content-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CheckboxContentService {

  private _checkboxContentChanged$: Subject<CheckboxContentChangedEventArgument> = new Subject<CheckboxContentChangedEventArgument>();
  public checkboxContentChanged$ = this._checkboxContentChanged$.asObservable();

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService, protected dynamicContentManagerService: DynamicContentManagerService) { }

  public create(majorKey: string, minorKey: string, friendlyName: string, displayOrder: number = 0, isRequired: boolean = true, displayHeading: boolean = false) {
    return new CheckboxContent(majorKey, minorKey, friendlyName, displayOrder, isRequired, displayHeading);
  }

  /**
  * Raises the "checkboxContent.checkedChanged"" event.
  */
  public raiseCheckboxContentChanged$(args: CheckboxContentChangedEventArgument) {
    this._checkboxContentChanged$.next(args);
  }

  public verifiedDynamicContent(element: CheckboxContent): boolean {
    let entries = this.dynamicContentManagerService.getEntries();
    for (let i = 0; i < entries.length; i++) {
      let entry = entries[i];
      if (entry.MajorKey === element.majorKey &&
        entry.MinorKey === element.minorKey &&
        entry.CategoryId === this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT &&
        entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE &&
        entry.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT) {
        return true;
      }
    }
    return false;
  }

  /**
  * Retrieves checkbox contents by specified keys and criteria.
  * 
  * @param {string} majorKey - The major key to filter checkbox contents.
  * @param {string} minorKey - The minor key to filter checkbox contents.
  * @param {boolean} [includeHeading=false] - Indicates whether to include headings.
  * @returns {CheckboxContent[]} An array of checkbox content items filtered by the specified keys and criteria.
  */
  getCheckboxContentsByKeys(majorKey: string, minorKey: string, includeHeading: boolean = false): CheckboxContent[] {

    const checkboxContentItems = [];
    const minorKeyBase = `${minorKey}.Checkbox`;
    const dynamicContentEntries = this.dynamicContentManagerService.getEntries().sort((a, b) => a.DisplayOrder - b.DisplayOrder);

    dynamicContentEntries.forEach(dynamicContentDto => {

      if (dynamicContentDto.MajorKey == majorKey &&
        dynamicContentDto.MinorKey.startsWith(minorKeyBase) &&
        dynamicContentDto.CategoryId == this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT &&
        dynamicContentDto.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE &&
        dynamicContentDto.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT) {

        checkboxContentItems.push(this.create(dynamicContentDto.MajorKey, dynamicContentDto.MinorKey, dynamicContentDto.GroupName || 'Acknowledgement', dynamicContentDto.DisplayOrder, !dynamicContentDto.MinorKey.includes('.Optional'), includeHeading))     
        includeHeading = false; // Clear flag
      }

    });

    return checkboxContentItems;
  }
}

export class CheckboxContent {
  public checked: boolean = false;
  public formControlName: string;
  constructor(public majorKey: string, public minorKey: string, public friendlyName: string, public displayOrder: number = 0, public isRequired: boolean, public displayHeading: boolean = false) {

  }
}

