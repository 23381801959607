import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SystemSettingDto } from '@coreShared/core-shared.module';
import { Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from "underscore";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "./constants-provider.service";
import { SystemSettingsService } from "./system-settings.service";

@Injectable({ providedIn: "root" })
export class SystemSettingsManagerService {
  private _settingsInitialized$: Subject<SystemSettingDto[]> = new Subject<SystemSettingDto[]>();
  public settingsInitialized$ = this._settingsInitialized$.asObservable();

  // Cached Settings
  private settings: SystemSettingDto[] = [];
  public get isInitialized(): boolean {
    return this.settings && this.settings.length > 0;
  }

  constructor(
    private http: HttpClient,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private systemSettingsService: SystemSettingsService
  ) { }

  public initialize(): Observable<void> {
    return this.systemSettingsService.getAll().pipe(
      map((settingsResponse: SystemSettingDto[]) => {
        this.settings = settingsResponse;
        this.raiseSettingsInit();
      })
    );
  }

  public get(key: string, isRequired: boolean = false): SystemSettingDto {
    let settingValue: SystemSettingDto = _.first(_.filter(this.settings, (item: SystemSettingDto) => {
      return item.Key === key;
    })) as SystemSettingDto;

    if (!settingValue && isRequired) {
      throw `Unable to find the System Setting with the supplied key [${key}]. Please check the configuration.`;
    }

    return settingValue;
  }

  // The feature parameter should be a constant string.
  // Ex: constantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH.ENABLED
  public asBoolean(settingKey: string, isRequired: boolean = false): boolean {
    var enabled = this.get(settingKey, isRequired);
    if (enabled) {
      return enabled.Value === this.constantsService.SYSTEM_CONSTANTS.TRUE_STRING;
    }

    return false;
  }

  // The feature parameter should be a constant string.
  // Ex: constantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH.MODE
  public asInt(settingKey: string, isRequired: boolean = false): number {
    var intAsString = this.get(settingKey, isRequired);
    if (intAsString != null) {
      return parseInt(intAsString.Value);
    }
    //throw "Call to systemSettingsManager.asInt(" + settingKey + ") failed";
    return null;
  }

  public asString(settingKey: string, isRequired: boolean = false): string {
    var setting = this.get(settingKey, isRequired);
    if (setting) {
      return setting.Value;
    }

    return null;
  }

  /**
   * Raises the "systemSettingsManager.settingsInit" event.
   */
  private raiseSettingsInit() {
    //this.userProfileRefreshed$.emit(this.currentPrincipal);
    this._settingsInitialized$.next(this.settings);
  }
}
