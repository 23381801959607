import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';

import * as _l from 'lodash-es';

import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService,
  IUserRegistrationConstantsService,
  OnlineServiceLinkManagerService,
  OnlineServiceMenuService, OnlineServiceProcessTypeKeys, OnlineServiceRegistry, OnlineServiceRegistryItem,
  RegistrationWizardFeatureConfigurationService, SystemSettingsManagerService, UserManagerService,
  UserRegistrationConstantsService
} from '@core/core.module';

@Injectable({
  providedIn: 'root'
})
export class LicensureRegistrationWizardFeatureConfigurationService extends RegistrationWizardFeatureConfigurationService {
  private licensureUserRegistrationConstantsService: ILicensureUserRegistrationConstantsService;
  private serviceRegistryItem: OnlineServiceRegistryItem;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    dynamicRoutesService: DynamicRoutesService,
    onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    onlineServiceMenuService: OnlineServiceMenuService,
    router: Router,
    userManagerService: UserManagerService,
    protected systemSettingsManagerService: SystemSettingsManagerService
  ) {
    super(
      constantsService,
      defaultProviderConfigurationService,
      dynamicRoutesService,
      onlineServiceLinkManagerService,
      onlineServiceMenuService,
      router,
      userManagerService,
      systemSettingsManagerService
    );

    this.licensureUserRegistrationConstantsService = this.constantsService as ILicensureUserRegistrationConstantsService;
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.UserRegistration);
  }

  /**
* Decorate the ConstantsService with UserRegistration-specific constants.
*/
  protected configureConstants() {
    super.configureConstants();
    UserRegistrationConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, LicensureUserRegistrationConstantsService.LicensureMergers.FEATURE_CONSTANTS);
    UserRegistrationConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, LicensureUserRegistrationConstantsService.LicensureMergers.SYSTEM_SETTING_KEYS);
    this.userRegistrationConstantsService = this.constantsService as IUserRegistrationConstantsService;
  }

  protected configureDecorators() {
    super.configureDecorators();
  }

  /**
   * Configure dynamic UserRegistration routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  protected configureRoutes() {

    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        loadChildren: () => import('src/app/core/features/registrationWizard/registration-wizard-selection.module')
          .then(m => m.RegistrationWizardSelectionModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        loadChildren: () => import('src/app/licensureCore/features/registrationWizard/licensure-registration-wizard.module')
          .then(m => m.LicensureRegistrationWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class LicensureUserRegistrationConstantsService extends UserRegistrationConstantsService {
  public static LicensureMergers = {

    SYSTEM_SETTING_KEYS: {
      REGISTRATION_WIZARD_FEATURE_SYSTEM_SETTING_KEYS: {
        LICENSEE_ORGANIZATION_ENABLED: "Feature.Registration.LicenseeOrganization.Enabled",
        APPLICANT_ENABLED: "Feature.Registration.Applicant.Enabled",
        PROFESSIONAL_FIRM_ENABLED: "Feature.Registration.ProfessionalFirm.Enabled",
        REGISTRATION_CODE_ENABLED: "Feature.Registration.RegistrationCode.Enabled",
        CE_PROVIDERS_ENABLED: "Feature.Registration.CeProviders.Enabled",
        EDUCATION_PROGRAM_STAFF_ENABLED: "Feature.Registration.EducationProgramStaff.Enabled"
      }
    },

    FEATURE_CONSTANTS: {
      REGISTRATION_WIZARD_FEATURE: {
        REGISTRATION_TYPES: {
          LICENSEE: { NAME: "Licensee", PROCESS: 101 },
          APPLICANT: { NAME: "Applicant", PROCESS: 102 },
          CONTINUING_EDUCATION_PROVIDER: { NAME: "ContinuingEducationProvider", PROCESS: 105 },
          EDUCATION_PROGRAM_STAFF: { NAME: "EducationProgramStaff", PROCESS: 106 },
          LICENSEE_ORGANIZATION: { NAME: "LicenseeOrganization", PROCESS: 0 }
        },
        NEW_USER_TYPES: {
          ORGANIZATION: 1,
          INDIVIDUAL: 2
        }
      }
    }
  };

  SYSTEM_SETTING_KEYS = UserRegistrationConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
    LicensureUserRegistrationConstantsService.LicensureMergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = UserRegistrationConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
    LicensureUserRegistrationConstantsService.LicensureMergers.FEATURE_CONSTANTS);
}

export interface ILicensureUserRegistrationConstantsService extends LicensureUserRegistrationConstantsService {
}
