import { } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IOnlineAssociatedLicenseSearchResultItemDto } from './licensure-shared-dto';

export class OnlineAssociatedLicenseSearchResultItemDto extends SearchResultItemDto implements IOnlineAssociatedLicenseSearchResultItemDto {
	IsOpiate: boolean;
	LicenseSubtypeDescription: string;
	AuthorizedUserRole: string;
	ProfessionalInChargeManaged: boolean;
	Id: number;
	EntityId: number;
	LastName: string;
	LicenseType: string;
	LicenseTypeDescription: string;
	LicenseNumber: number;
	LicenseStatus: string;
	LicenseStatusDescription: string;
	EmployeeLastName: string;
	EmployeeFirstName: string;
	Address: string;
	State: string;
	City: string;
	Zip: string;
	ExpireDate?: Date;
}
