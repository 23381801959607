import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { forkJoin, from, of } from "rxjs";
import { ReachScenarios } from "src/app/core/index-constants";
import { IReachRegionDynamicComponent, ReachModelAwareComponent } from "src/app/core/index-directives";
import { Command, DialogSettings, registerDynamicComponent } from "src/app/core/index-models";
import { BusyManagerService, CommandService, ConstantsService, CONSTANTS_SERVICE_TOKEN, ListService, ReachModelConfigurationInjector, REGION_MODEL_INJECTOR_TOKEN, UserManagerService, UtilitiesService } from "src/app/core/index-services";
import { IEntityAssociateDto, IEntityGroupMemberDto } from "src/app/coreShared/core-shared.module";
import { ProfileLandingRegionModel } from "src/app/licensureCore/landingPage/profile/profile-landing-region-model";
import { EntityAssociationEditorComponent } from "./editor/entity-association-editor.component";
import { ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";

export const EntityAssociationComponentSelector: string = "entity-association";
export const EntityAssociationComponentKey: string = "entityAssociation";

@Component({
  selector: EntityAssociationComponentSelector,
  templateUrl: './entity-association.component.html',
  styleUrls: ['./entity-association.component.scss']
})
export class EntityAssociationComponent extends ReachModelAwareComponent implements OnInit, IReachRegionDynamicComponent {

  public contentForm: FormGroup;
  public dialogSettings: DialogSettings;
  public possibleFacilities: IEntityGroupMemberDto[];
  public isVisible: boolean;

  public okCommand: Command;
  public presentItemEditorCommand: Command;
  public removeItemCommand: Command;

  protected groupMembersGroupId: number;
  protected associationTypeId: number;
  protected licenseTypeCode: string;
  public title: string;

  public get regionModel(): ProfileLandingRegionModel { return this.model as ProfileLandingRegionModel; }
  public get associateList(): IEntityAssociateDto[] { return (this.model.OnlineUser.Associates as IEntityAssociateDto[]).filter(associate => associate.AssociateTypeId == this.associationTypeId); }

  constructor(
    @Inject(REGION_MODEL_INJECTOR_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,
    private busyManagerService: BusyManagerService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private listService: ListService,
    private utilitiesService: UtilitiesService,
    private confirmationService: ConfirmationService,
    private userManagerService: UserManagerService,
  ) {
    super(reachModelConfigurationInjector);
  }

  protected configure() { // To be overriden if necessary.
  }

  ngOnInit(): void {

    this.configure(); // To be overriden if necessary.

    this.isVisible = (this.userManagerService.getCurrentPrincipal().user.UserAccount.profile as ILicensureUserProfileInfoDto).Licenses.find(license => (license.LicenseType.Code === this.licenseTypeCode || license.LicenseType.Code === "HS") && license.LicenseStatus.Id === "LA") != null;

    this.initCommands();
    this.loadLookupLists();
  }

  private initCommands(): void {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected loadLookupLists(): void {
    const doInit = async (): Promise<any> => {

      const responseCollection = await forkJoin([this.listService.getEntityGroupMembers(this.groupMembersGroupId)]).toPromise();
      this.possibleFacilities = this.listService.filterInactiveItems(responseCollection[0]) as IEntityGroupMemberDto[];

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT); // Display busy status while resolving.
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (entityAssociate: IEntityAssociateDto) => {
    entityAssociate.IsDirty = true;
    this.model.OnlineUser.Associates = this.utilitiesService.addOrReplace(this.model.OnlineUser.Associates, entityAssociate);

    this.regionModel.modelChanged$.next(entityAssociate);
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (item: any) => {
    const doPresent = async (): Promise<any> => {

      let selectedItem: IEntityAssociateDto = item ??
        {
          Id: 0,
          IsNew: true,
          IsDeleted: false,
          LocalId: this.utilitiesService.guid(),
          EntityId: this.userManagerService.getCurrentPrincipal().user.UserAccount.EntityId,
          AssociateEntityId: 0,
          AssociateTypeId: this.associationTypeId,
        } as IEntityAssociateDto;
      let dialogTitle = item ? `${this.title} Of Record` : `${this.title} Of Record - {new}`;

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.ProfileLanding, // Scenario key
        EntityAssociationEditorComponent, // Content type
        EntityAssociationEditorComponent.name, // Content key
        dialogTitle, // Title
        selectedItem, // Model
      );

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;
      this.dialogSettings.initializationData.possibleFacilities = this.possibleFacilities;

      return of(true).toPromise();
    }

    return from(doPresent());
  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (entityAssociate: IEntityAssociateDto) => {
    this.confirmationService.confirm({
      message: `${entityAssociate.AssociatedEntityInfo.FullName} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      accept: () => {
        entityAssociate.IsDeleted = true;
        this.regionModel.modelChanged$.next(entityAssociate);
      }

    });
  }

}

registerDynamicComponent(ReachScenarios.ProfileLanding, EntityAssociationComponentKey, EntityAssociationComponent, EntityAssociationComponentSelector);
