import { IDomainReasonForChangeDtoHost, IEntityAddressDto, IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEmploymentDto, IEmploymentPositionTitleDto, IEmploymentTypeDto, ILicenseInfoDto } from './licensure-shared-dto';

export class EmploymentDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEmploymentDto {
	IndividualLicenseId?: number;
	SettingId?: number;
	SectorId?: number;
	IndividualLicenseInfo: ILicenseInfoDto;
	AddressTypeId: number;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	EntityId?: number;
	EntityName: string;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	ReferenceValue: string;
	EmployerName: string;
	PositionTitle: string;
	BeginDate?: Date;
	EndDate?: Date;
	PositionTitleId?: number;
	TypeId?: number;
	EmployerLicenseId?: number;
	EmployerLicenseInfo: ILicenseInfoDto;
	EmploymentPositionTitle: IEmploymentPositionTitleDto;
	EmploymentType: IEmploymentTypeDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
	Addresses: IEntityAddressDto[];
	AddressMailing: IEntityAddressDto;
	AddressPublic: IEntityAddressDto;
}
