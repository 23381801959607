<div *ngIf="isVisible">
    <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="associatesList" [responsive]="true"
        responsiveLayout="stack">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <div class="p-text-left reach-list-header">{{title}}</div>
                <div>
                    <button pButton pRipple class="p-button-text" icon="fa fa-plus"
                        [command-button]="presentItemEditorCommand" tool-tip="Add a new item"></button>
                </div>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>

                <th>Name</th>
                <th>Relationship</th>
                <th>Start</th>
                <th>End</th>
                <th>Comment</th>
                <th style="width: 8em;"></th>

            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Name -->
                <td><span class="p-column-title">Name</span>{{item.AssociatedEntityInfo.FullName}}</td>

                <!-- Relationship -->
                <td><span class="p-column-title">Relationship</span>{{item.AssociateType.Description}}</td>

                <!-- Start Date -->
                <td> <span class="p-column-title">Start</span>{{item.BeginDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}
                </td>

                <!-- End Date -->
                <td> <span class="p-column-title">End</span>{{item.EndDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}</td>

                <!-- Comment -->
                <td>
                    <checklist-comment [message]="item.Comment" header="Comment"></checklist-comment>
                </td>

                <!-- Buttons -->
                <td class="p-text-right" style="height: 45px;">
                    <span class="p-text-nowrap">

                        <!-- Edit -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                            [command-button]="presentItemEditorCommand" [command-parameter]="item"
                            tool-tip="Edit this item" *ngIf="item.AssociateTypeId == associationTypeId"></button>

                        <!-- Delete -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                            [command-button]="removeItemCommand" [command-parameter]="item" tool-tip="Delete this item"
                            *ngIf="item.AssociateTypeId == associationTypeId"></button>

                    </span>
                </td>

            </tr>
        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="5">
                    <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
</div>