import { DatePipe } from "@angular/common";

export class RangeModel {
  from = null;
  to = null;

  constructor(public rangeType: string, public friendlyName: string) {
  }

  public clearSelection = () => {
    this.from = null;
    this.to = null;
  }

  public toString = (includeFriendlyName: boolean) => {

    // Bail immediately if incomplete
    if (!this.from || !this.to) return null;

    let datePipe = new DatePipe('en-US');
    
    return includeFriendlyName
      ? `${this.friendlyName}: between ${(this.from) instanceof Date ? datePipe.transform(this.from, 'MM/dd/yyyy') : this.from} and ${(this.to) instanceof Date ? datePipe.transform(this.to, 'MM/dd/yyyy') : this.to}`
      : `between ${(this.from) instanceof Date ? datePipe.transform(this.from, 'MM/dd/yyyy') : this.from} and ${(this.to) instanceof Date ? datePipe.transform(this.to, 'MM/dd/yyyy') : this.to}`;
  }
}