import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AppConfigService } from '@coreServices/app-config.service';
import { ArgumentExceptionService } from '@coreServices/argument-exception.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import {
  ExceptionDto, IncidentDto
} from '@coreShared/core-shared.module';
import { BusyManagerService } from './busy-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UserManagerService } from './user-manager.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends ReachHttpClientService {
  uri: string;

  constructor(private appConfigService: AppConfigService
    , private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , private location: Location
    , private userManagerService: UserManagerService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/incident/`;
  }

  public error(error: Error, referenceGuid: string, browser: string) {
    const principal = this.userManagerService ? this.userManagerService.getCurrentPrincipal() : null;
    const username = principal ? principal.user.UserAccount.UserName : "";
    const fullName = principal ? principal.displayName : "";
    const angularRoute = this.location.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '') + ' Browser: ' + browser;
    const exceptionDto = new ExceptionDto();
    exceptionDto.Message = error.message;
    exceptionDto.Type = "WebException";
    exceptionDto.Source = "Reach.Client.Web.Host";
    exceptionDto.StackTraceInfo = error.stack;
    exceptionDto.IsNew = true;

    let incidentDto = new IncidentDto();
    incidentDto.IsNew = true;
    incidentDto.ReferenceGuid = referenceGuid;
    incidentDto.FullName = fullName;
    incidentDto.WindowsIdentity = username;
    incidentDto.AppDomainName = this.appConfigService.clientProviderConfiguration.provider.toUpperCase();
    incidentDto.MachineName = domainAndApp;
    incidentDto.Exceptions = [exceptionDto];
    try {
      this.create(incidentDto);
    } catch (err) {
      console.error(err);
    }
  }
  public create(incidentDto: IncidentDto) {
    // Must have a cart item
    if (!incidentDto) {
      throw this.argumentExceptionService.create("incidentDto").log();
    }

    if (this.uri) {
      this.http.post<IncidentDto>(`${this.uri}`, incidentDto).subscribe();
    }
  }
}




