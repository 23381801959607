<div [formGroup]="contentForm" class="p-grid p-formgrid">
    <div class="p-col-12 p-field">
        <label for="clientPopulation">Client population and the range of presenting issues/diagnoses</label>
        <textarea id="clientPopulation" [readonly]="isReadOnly" formControlName="NarrativeOfPopulation" [rows]="5" style="width:100%" pInputTextarea
            autoResize="autoResize"></textarea>
    </div>
    <div class="p-col-12 p-field">
        <label for="narrativeModalities">Clinical modalities commonly utilized</label>
        <textarea id="narrativeModalities" [readonly]="isReadOnly" formControlName="NarrativeOfModalities" [rows]="5" style="width:100%" pInputTextarea
            autoResize="autoResize"></textarea>
    </div>
    <div class="p-col-12 p-field">
        <label for="diagnosticProcess">Diagnostic process</label>
        <textarea id="diagnosticProcess" [readonly]="isReadOnly" formControlName="NarrativeOfProcess" [rows]="5" style="width:100%" pInputTextarea
            autoResize="autoResize"></textarea>
    </div>
</div>