import { Injectable } from '@angular/core';
import * as _l from 'lodash-es';
import { SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration } from '@coreModels/summary-component-info';
import { ArgumentExceptionService } from "./argument-exception.service";
import { UtilitiesService } from "./utilities.service";

/**
 * Service used to build SummaryInfo items that map to tabs and cards. Used by LandingPageService and features to add their summary items to the landing page and other
 * summary-detail based features.
 */
@Injectable({
  providedIn: 'root'
})
export class SummaryInfoBuilderService {

  public createBuilder(
    targetInfos: SummaryComponentInfo[],
    index: number,
    title: string,
    configuration: SummaryDetailConfiguration,
    tabOrder = 0
  ) {
    return new SummaryInfoBuilder(
      targetInfos,
      index,
      title,
      configuration,
      tabOrder
    );
  }
}

/**
 * Manages the construction of a set of SummaryComponentInfo objects for the tabs/cards for a given feature.
 */
export class SummaryInfoBuilder {
  public aggregateInfo: SummaryComponentInfo;

  constructor(
    private targetInfos: SummaryComponentInfo[],
    private index: number,
    private title: string,
    private configuration: SummaryDetailConfiguration,
    private tabOrder = 0
  ) {
  }

  /**
   * Adds an info item to the builder with the specified properties.
   * @param index the index of the item.
   * @param title the default title.
   * @param model the model.
   * @param tabOrder the tab order.
   */
  public addItem(
    index: number,
    title: string,
    model,
    domainId: number,
    tabOrder = 0
  ) {
    let info = new SummaryComponentInfo(
      index,
      title,
      model,
      this.configuration,
      domainId,
      tabOrder
    );

    if (this.configuration.aggregationType === SummaryAggregationType.Singleton) {
      this.targetInfos.push(info);
      let infoItem = _l.clone(info);
      info.items.push(infoItem);
    } else if (this.configuration.aggregationType === SummaryAggregationType.Aggregate) {
      if (!this.aggregateInfo) {
        this.aggregateInfo = _l.clone(info);
        this.targetInfos.push(this.aggregateInfo);
      }

      this.aggregateInfo.items.push(info);
    }
  }
}
