<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <div class="p-grid p-formgrid">

        <!-- pickListModel.friendlyName -->
        <div class="p-field p-col-12 p-sm-6">
            <label [for]="pickListModel.friendlyName">{{pickListModel.friendlyName}}</label>
            <p-dropdown [inputId]="pickListModel.friendlyName" [formControlName]="pickListModel.friendlyName"
                [options]="filteredPossibleItems" appendTo="body" optionLabel="value"
                dataKey="Id" [showClear]="false" placeholder="&nbsp;" baseZIndex="1">
            </p-dropdown>
        </div>

    </div>

    <!-- PrimeNG Table -->
    <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="pickListModel.selectedItems">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <div class="p-text-left reach-list-header">Selected Filters</div>
            </div>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Type -->
                <td *ngIf="displayKey">{{item.key}} - {{item.value}}</td>
                <td *ngIf="!displayKey">{{item.value}}</td>

                <!-- Buttons -->
                <td class="p-text-right">
                    <span class="p-text-nowrap">

                        <!-- Delete -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                            [command-button]="removeItemCommand" [command-parameter]="item"
                            tool-tip="Delete this item"></button>

                    </span>
                </td>

            </tr>
        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td>
                    <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>