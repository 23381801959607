import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService, CheckboxContentService } from 'src/app/core/index-services';

export const UpdateEntityAssociatedWithComponentSelector: string = 'update-entity-associated-with';
export const UpdateEntityAssociatedWithComponentKey: string = 'updateEntityAssociatedWith';

@Component({
  selector: UpdateEntityAssociatedWithComponentSelector,
  templateUrl: './update-entity-associated-with.component.html',
  styleUrls: ['./update-entity-associated-with.component.scss']
})
export class UpdateEntityAssociatedWithComponent extends WizardStepComponent {

  config: any;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,) {
    super(constantsService, validationManagerService, wizardInjector, UpdateEntityAssociatedWithComponent.name, checkboxContentService, elementRef);

    this.config = { associationTypeId: this.wizard.selectedStep?.Parameters?.["associationTypeId"], title: this.wizard.selectedStep?.Parameters?.["title"] }
  }

}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, UpdateEntityAssociatedWithComponentKey, UpdateEntityAssociatedWithComponent, UpdateEntityAssociatedWithComponentSelector);
registerDynamicComponent(ReachScenarios.ApplicationWizard, UpdateEntityAssociatedWithComponentKey, UpdateEntityAssociatedWithComponent, UpdateEntityAssociatedWithComponentSelector);