import { IDomainAuditDto,
IDomainChecklistItemDto,
IDomainReasonForChangeDtoHost,
IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeSponsorshipDto,
ICeSponsorshipScheduledCourseDto,
ICeSponsorshipStatusDto,
ICeSponsorshipTypeDto } from './licensure-shared-dto';

export class CeSponsorshipDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICeSponsorshipDto {
	CeSponsorshipTypeConstants: any;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	EntityId?: number;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	ReferenceValue: string;
	CeSponsorId: number;
	StartDate?: Date;
	EndDate?: Date;
	CeSponsorshipTypeId?: number;
	CeSponsorshipStatusId?: number;
	IndividualCount?: number;
	Status: ICeSponsorshipStatusDto;
	Type: ICeSponsorshipTypeDto;
	ScheduledCourses: ICeSponsorshipScheduledCourseDto[];
	DomainAudits: IDomainAuditDto[];
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	IsCountApplicable: boolean;

}
