
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import {
  AppErrorComponent,
  AuthGuard,
  ErrorComponentKeys,
  LandingComponentKeys,
  LoginComponent,
  DefaultLoginRouteResolverService,
  CartRouteResolverService,
  LoginComponentKeys,
  NoPageFoundComponent,
  OnlineServiceRegistry,
  OnlineServiceProcessTypeKeys,
  RouteInfoRegistry,
  PasswordTokenResetComponent,
  PasswordTokenResetRouteResolverService
} from '@core/core.module';

import { CartComponent } from '@core/features/cart/cart.module';
import { SurveyRedirectRouteResolverService } from './core/services/routeResolvers/survey-redirect-route-resolver.service';
import { SurveyRedirectComponent } from './core/components/surveyRedirect/survey-redirect.component';
import { SurveyCallbackComponent } from './core/components/surveyCallback/survey-callback.component';
import { SurveyCallbackRouteResolverService } from './licensureCore/services/SurveyCallbackRouteResolverService';

const routes: Routes =
  [
    {
      path: 'services/cr/2/:passwordVerificationToken',
      component: PasswordTokenResetComponent,
      resolve: { routeConfiguration: PasswordTokenResetRouteResolverService }
    },
    {
      path: 'surveyredirect',
      component: SurveyRedirectComponent,
      resolve: { routeConfiguration: SurveyRedirectRouteResolverService }
    },
    {
      path: 'surveyCallback',
      component: SurveyCallbackComponent,
      resolve: { routeData: SurveyCallbackRouteResolverService }
    },
    {
      path: '',
      redirectTo: '/' + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path,
      pathMatch: 'full'
    },
    {
      path: RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path,
      component: LoginComponent,
      resolve: { routeConfiguration: DefaultLoginRouteResolverService }
    },
    {
      path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Cart).onlineServicePath,
      canActivate: [AuthGuard],
      component: CartComponent, resolve: { routeConfiguration: CartRouteResolverService }
    },
    {
      path: RouteInfoRegistry.getItemByRegistryTypeKey(ErrorComponentKeys.Error).path,
      component: AppErrorComponent
    },
    {
      path: '**',
      component: NoPageFoundComponent
    }
  ];

@NgModule({

  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
