import { registerOnlineService, OnlineServiceProcessTypes } from '@core/core.module';

export enum LicensureOnlineServiceProcessTypeKeys {
  DataRequestSelector = -3,
  Application = 1,
  LicenseRenewal = 2,
  DuplicateCard = 6,
  LicenseVerification = 7,
  ApplicationLicenseFee = 8,
  ApplicationExamRetake = 9,
  CeSponsorship = 10,
  SuperviseePlan = 13,
  SupervisionVerification = 14,
  SupervisorPlan = 15,
  SupervisorSupervisionVerification = 16,
  LicenseStatusChange = 17,
  EducationProgramCompletion = 20,
  LicenseReinstatement = 21,// a.k.a. reactivation, reregistration
  AutoVerficationSubscription = 22,
  SupervisorAttestation = 23,
  PreBuiltDataRequest = 24,// a.k.a. 'canned'
  AdHocDataRequest = 25,
  StatisticalDataRequest = 26,
  LicenseRenewalWithStatusChange = 27,
  LicenseRenewalFinalFee = 60,
  LicenseRenewalFinalFeeFirstInGroup = 61,
  CriminalBackgroundCheckFee = 32,
  Jurisprudence = 34,
  OnlineComplaint = 35,
  CeAudit = 36,
  EducationProgram = 39,
  EducationProgramApplication = 40,
  Activity = 41,
  SecondLicenseCard = 42,
  OrganizationalPayor = 43,
  EducationProgramAnnualCompliance = 44,
  ProfessionalProfile = 46,
  LicenseRenewalIlc = 47,
  ManageDelegatedAccess = 49,
  DuplicateWallCertificate = 500,
  CreateDelegationToken = 1002,
  ApplyDelegationToken = 1003,
  ModifyDelegate = 1004,
  ManagePicAccess = 1005,

  // UI-defined OnlineServices
  EntitySearch = 2000,
  Cart = 2001,
  CeSponsorSearch = 2002,
  CourseSearch = 2003,
}

export class LicensureOnlineServiceProcessTypes extends OnlineServiceProcessTypes {
  public static EntitySearch: string = LicensureOnlineServiceProcessTypeKeys.EntitySearch.toString();
  public static Application: string = LicensureOnlineServiceProcessTypeKeys.Application.toString();
  public static ApplicationExamRetake: string = LicensureOnlineServiceProcessTypeKeys.ApplicationExamRetake.toString();
  public static LicenseRenewal: string = LicensureOnlineServiceProcessTypeKeys.LicenseRenewal.toString();
  public static DuplicateCard: string = LicensureOnlineServiceProcessTypeKeys.DuplicateCard.toString();
  public static DuplicateWallCertificate: string = LicensureOnlineServiceProcessTypeKeys.DuplicateWallCertificate.toString();
  public static ManageDelegatedAccess: string = LicensureOnlineServiceProcessTypeKeys.ManageDelegatedAccess.toString();
  public static CreateDelegationToken: string = LicensureOnlineServiceProcessTypeKeys.CreateDelegationToken.toString();
  public static ApplyDelegationToken: string = LicensureOnlineServiceProcessTypeKeys.ApplyDelegationToken.toString();
  public static ModifyDelegate: string = LicensureOnlineServiceProcessTypeKeys.ModifyDelegate.toString();
  public static ManagePicAccess: string = LicensureOnlineServiceProcessTypeKeys.ManagePicAccess.toString();
  public static EducationProgramCompletion: string = LicensureOnlineServiceProcessTypeKeys.EducationProgramCompletion.toString();
  public static LicenseReinstatement: string = LicensureOnlineServiceProcessTypeKeys.LicenseReinstatement.toString();
  public static AutoVerficationSubscription: string = LicensureOnlineServiceProcessTypeKeys.AutoVerficationSubscription.toString();
  public static LicenseRenewalWithStatusChange: string = LicensureOnlineServiceProcessTypeKeys.LicenseRenewalWithStatusChange.toString();
  public static LicenseRenewalFinalFee: string = LicensureOnlineServiceProcessTypeKeys.LicenseRenewalFinalFee.toString();
  public static LicenseRenewalFinalFeeFirstInGroup: string = LicensureOnlineServiceProcessTypeKeys.LicenseRenewalFinalFeeFirstInGroup.toString();
  public static LicenseStatusChange: string = LicensureOnlineServiceProcessTypeKeys.LicenseStatusChange.toString();
  public static CeAudit: string = LicensureOnlineServiceProcessTypeKeys.CeAudit.toString();
  public static CeSponsorship: string = LicensureOnlineServiceProcessTypeKeys.CeSponsorship.toString();
  public static SecondLicenseCard: string = LicensureOnlineServiceProcessTypeKeys.SecondLicenseCard.toString();
  public static EducationProgramApplication: string = LicensureOnlineServiceProcessTypeKeys.EducationProgramApplication.toString();
  public static OrganizationalPayor: string = LicensureOnlineServiceProcessTypeKeys.OrganizationalPayor.toString();
  public static EducationProgramAnnualCompliance: string = LicensureOnlineServiceProcessTypeKeys.EducationProgramAnnualCompliance.toString();
  public static ProfessionalProfile: string = LicensureOnlineServiceProcessTypeKeys.ProfessionalProfile.toString();
  public static CriminalBackgroundCheckFee: string = LicensureOnlineServiceProcessTypeKeys.CriminalBackgroundCheckFee.toString();
  public static OnlineComplaint: string = LicensureOnlineServiceProcessTypeKeys.OnlineComplaint.toString();
  public static LicenseVerification: string = LicensureOnlineServiceProcessTypeKeys.LicenseVerification.toString();
  public static Jurisprudence: string = LicensureOnlineServiceProcessTypeKeys.Jurisprudence.toString();
  public static DataRequestSelector: string = LicensureOnlineServiceProcessTypeKeys.DataRequestSelector.toString();
  public static PreBuiltDataRequest: string = LicensureOnlineServiceProcessTypeKeys.PreBuiltDataRequest.toString();
  public static AdHocDataRequest: string = LicensureOnlineServiceProcessTypeKeys.AdHocDataRequest.toString();
  public static StatisticalDataRequest: string = LicensureOnlineServiceProcessTypeKeys.StatisticalDataRequest.toString();
  public static ApplicationLicenseFee: string = LicensureOnlineServiceProcessTypeKeys.ApplicationLicenseFee.toString();
  public static SuperviseePlan: string = LicensureOnlineServiceProcessTypeKeys.SuperviseePlan.toString();
  public static SupervisorAttestation: string = LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation.toString();
  public static SupervisionVerification: string = LicensureOnlineServiceProcessTypeKeys.SupervisionVerification.toString();
  public static SupervisorPlan: string = LicensureOnlineServiceProcessTypeKeys.SupervisorPlan.toString();
  public static SupervisorSupervisionVerification: string = LicensureOnlineServiceProcessTypeKeys.SupervisorSupervisionVerification.toString();
  public static LicenseRenewalIlc: string = LicensureOnlineServiceProcessTypeKeys.LicenseRenewalIlc.toString();
  public static CeSponsorSearch: string = LicensureOnlineServiceProcessTypeKeys.CeSponsorSearch.toString();
  public static CourseSearch: string = LicensureOnlineServiceProcessTypeKeys.CourseSearch.toString();

  private static _initializeLicensureRoutes = (() => {

    registerOnlineService(LicensureOnlineServiceProcessTypes.Application, 'Application', 'applicationWizard', 'applicationWizardSelector', '/:applicationId/:applicationTypeId/:applicationBasisCode/:processTypeId/:webServiceHistoryId', '/:entityId/:applicationId/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.EntitySearch, 'EntitySearch', 'onlineEntitySearch', 'onlineEntitySearch', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.CeAudit, 'CeAudit', 'ceAuditWizard', 'ceAuditWizardSelector', '/:processTypeId/:domainId/:domainKeyId/:auditTypeId/:webServiceHistoryId?', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.CeSponsorship, 'CeSponsorship', 'ceSponsorshipWizard', 'ceSponsorshipWizardSelector', '/:sponsorshipId/:sponsorshipTypeId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.DuplicateCard, 'DuplicateCard', 'duplicateCardWizard', 'duplicateCardWizardSelector', '/:entityId/:licenseId/:processTypeId/:selectorTitle', '/:entityId/:licenseId/:processTypeId/:selectorTitle');
    registerOnlineService(LicensureOnlineServiceProcessTypes.DuplicateWallCertificate, 'DuplicateWallCertificate', 'duplicateCardWizard', 'duplicateCardWizardSelector', '/:entityId/:licenseId/:processTypeId/:selectorTitle', '/:entityId/:licenseId/:processTypeId/:selectorTitle');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ManageDelegatedAccess, 'ManageDelegatedAccess', 'delegated-administration/manage', '', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ApplyDelegationToken, 'ApplyDelegationToken', 'delegated-administration/apply', null, '/:token', null);
    registerOnlineService(LicensureOnlineServiceProcessTypes.CreateDelegationToken, 'CreateDelegationToken', 'delegated-administration/create', '', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ManagePicAccess, 'ManagePicAccess', 'delegated-administration/pic', '', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ModifyDelegate, 'ModifyDelegate', 'delegated-administration/modify', null, '/:delegateeEntityId', null);
    registerOnlineService(LicensureOnlineServiceProcessTypes.AutoVerficationSubscription, 'AutoVerficationSubscriptionWizard', 'autoVerficationWizard', 'autoVerficationWizardSelector', '/:autoVerificationSubscriberId/:autoVerificationSubscriptionId/:webServiceHistoryId?', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.EducationProgramApplication, 'EducationProgramApplication', 'educationProgramApplicationWizard', 'educationProgramApplicationWizardSelector', '/:processId/:serviceId/:activityId/:domainId/:domainKeyId/:webServiceHistoryId', '/:processTypeId/:webServiceHistoryId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.EducationProgramCompletion, 'EducationProgramCompletion', 'educationProgramCompletionWizard', 'educationProgramCompletionWizard', '/:educationProgramApprovalId', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseRenewal, 'LicenseRenewal', 'licenseRenewalWizard', 'licenseRenewalWizardSelector', '/:licenseeEntityId/:licenseId/:licenseTypeId/:processTypeId/:licenseStatusIdFrom/:licenseStatusIdTo/:isIndividual/:webServiceHistoryId', '/:licenseId/:processTypeId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseRenewalWithStatusChange, 'LicenseRenewalWithStatusChange', 'licenseRenewalWizard', 'licenseRenewalWizardSelector', '/:licenseId/:licenseTypeId/:processTypeId/:licenseStatusIdFrom/:licenseStatusIdTo/:webServiceHistoryId', '/:licenseId/:processTypeId');

    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseRenewalFinalFee, 'LicenseRenewalFinalFee', 'licenseRenewalFinalFeeWizard', 'licenseRenewalFinalFeeWizardSelector', '/:licenseId/:processTypeId/:licenseeEntityId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseRenewalFinalFeeFirstInGroup, 'LicenseRenewalFinalFee', 'licenseRenewalFinalFeeWizard', 'licenseRenewalFinalFeeWizardSelector', '/:licenseId/:processTypeId/:licenseeEntityId/:webServiceHistoryId', '');

    registerOnlineService(LicensureOnlineServiceProcessTypes.SecondLicenseCard, 'SecondLicenseCard', 'secondLicenseCardWizard', 'secondLicenseWizardSelector', '/:licenseId', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.OrganizationalPayor, 'OrganizationalPayor', 'organizationalPayorWizard', 'organizationalPayorWizardSelector', '/:licenseId', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.EducationProgramAnnualCompliance, 'EducationProgramAnnualCompliance', 'annualComplianceWizard', 'annualComplianceWizardSelector', '/:processId/:serviceId/:processTypeId/:educationProgramId/:complianceId/:domainId/:domainKeyId/:functionTypeId/:webServiceHistoryId', '/:processTypeId/:webServiceHistoryId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ProfessionalProfile, 'ProfessionalProfile', 'professionalProfileWizard', 'professionalProfileWizardSelector', '/:licenseId/:processTypeId/:entityId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseReinstatement, 'LicenseReinstatement', 'licenseReinstatementWizard', 'licenseReinstatementWizardSelector', '/:processId/:serviceId/:processTypeId/:licenseId/:licenseTypeId/:licenseStatusChangeTransitionId/:licenseStatusChangeApplicationId/:webServiceHistoryId', '/:processTypeId/:licenseId/:webServiceHistoryId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ApplicationExamRetake, 'ApplicationExamRetake', 'examRetakeWizard', 'examRetakeWizardSelector', '/:applicationId/:applicationTypeId/:applicationBasisCode/:processTypeId/:entityId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.CriminalBackgroundCheckFee, 'CriminalBackgroundCheckFee', 'criminalBackgroundCheckFeeWizard', 'criminalBackgroundCheckFeeWizardSelector', '/:processTypeId/:criminalBackgroundCheckId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseVerification, 'LicenseVerification', 'licenseVerificationWizard', 'licenseVerificationWizardSelector', '/:processId/:processTypeId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.Jurisprudence, 'Jurisprudence', 'jurisprudenceWizard', '', '/:domainId/:domainKeyId/:domainFormId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.DataRequestSelector, 'DataRequestSelector', 'dataRequestWizardSelector', 'dataRequestWizardSelector', '/:domainId/:domainKeyId/:webServiceHistoryId?', '/:webServiceHistoryId?');
    registerOnlineService(LicensureOnlineServiceProcessTypes.PreBuiltDataRequest, 'PreBuiltDataRequest', 'cannedDataRequestWizard', 'cannedDataRequestWizardSelector', '/:domainId/:domainKeyId/:masterCorrespondenceId/:webServiceHistoryId?', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.AdHocDataRequest, 'AdHocDataRequest', 'adHocDataRequestWizard', 'adHocDataRequestWizardSelector', '/:domainId/:domainKeyId/:webServiceHistoryId?', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.StatisticalDataRequest, 'StatisticalDataRequest', 'statisticalDataRequestWizard', 'statisticalDataRequestWizardSelector', '/:domainId/:domainKeyId/:webServiceHistoryId?', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.ApplicationLicenseFee, 'ApplicationLicenseFee', 'applicationLicenseFeeWizard', 'applicationLicenseFeeWizardSelector', '/:processTypeId/:domainId/:domainKeyId/:entityId/:applicationId/:webServiceHistoryId?', '/:webServiceHistoryId/');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseRenewalIlc, 'LicenseRenewalIlc', 'LicenseRenewalIlcWizard', '', '/:licenseId/:licenseTypeId/:processTypeId/:webServiceHistoryId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.CeSponsorSearch, 'CeSponsorSearch', 'onlineCeSponsorSearch', 'onlineCeSponsorSearch', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.CourseSearch, 'CourseSearch', 'onlineCourseSearch', 'onlineCourseSearch', '', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.SupervisorAttestation, 'SupervisorAttestation', 'supervisorAttestationWizard', 'supervisorAttestationServiceSelector', '/:entityId/:licenseType/:supervisorDesignationFormId/:supervisorDesignationTypeId', '/:entityId/:licenseType');
    registerOnlineService(LicensureOnlineServiceProcessTypes.SupervisorPlan, 'SupervisorPlan', 'supervisorPlanWizard', '', '/:supervisionPlanId/:superviseeEntityId/:webServiceHistoryId?', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.SuperviseePlan, 'SuperviseePlan', 'superviseePlanWizard', 'superviseePlanWizardSelector', '/:entityId/:domainId/:domainKeyId/:planId:/:webServiceHistoryId?', '/:domainId/:domainKeyId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.SupervisionVerification, 'SupervisionVerification', 'superviseeVerificationWizard', 'superviseeVerificationServiceSelector', '/:entityId/:supervisionVerificationId/:webServiceHistoryId', '/:entityId/:domainId/:domainKeyId/:planId');
    registerOnlineService(LicensureOnlineServiceProcessTypes.SupervisorSupervisionVerification, 'SupervisionVerification', 'supervisorVerificationWizard', '', '/:superviseeEntityId/:supervisionVerificationId/:supervisionPlanId?/:webServiceHistoryId?', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.OnlineComplaint, 'OnlineComplaint', 'complaintWizard', '', '/:processTypeId', '');
    registerOnlineService(LicensureOnlineServiceProcessTypes.LicenseStatusChange, 'LicenseStatusChange', 'licenseStatusChangeWizard', 'licenseStatusChangeWizardSelector', '/:licenseId/:licenseTypeId/:processTypeId/:processId/:licenseStatusTransitionId/:licenseStatusTo/:licenseStatusChangeApplicationId/:webServiceHistoryId', '/:licenseId/:processTypeId');
  })();
}
