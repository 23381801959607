import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachControlValidators, WizardStepComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, EntityGroupMemberService, ValidationManagerService } from 'src/app/core/index-services';
import { EntityGroupMemberSearchResultItemDto, ICityDto, IEntityGroupMemberSearchCriteriaDto, IEntityGroupMemberSearchResultItemDto, SearchResultListDto } from 'src/app/coreShared/core-shared.module';
import { ApplicationConstantsService, LicensureListService } from '../../licensure-core.module';
import { forkJoin } from 'rxjs';

export const ResidencyExtensionComponentSelector: string = "residency-extension";
export const ResidencyExtensionComponentKey: string = "residencyExtension";

@Component({
  selector: ResidencyExtensionComponentSelector,
  templateUrl: './residency-extension.component.html',
  styleUrls: ['./residency-extension.component.scss']
})
export class ResidencyExtensionComponent extends WizardStepComponent implements OnInit {

  constructor(
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    protected entityGroupMemberService: EntityGroupMemberService,
    protected listService: LicensureListService,
    protected busyManagerService: BusyManagerService) {
    super(constantsService, validationManagerService, wizardInjector, ResidencyExtensionComponent.name, checkboxContentService, elementRef);

    this.modelToForm();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.wizard.model.application.ValidationMode = (this.constantsService as ApplicationConstantsService).VALIDATION_MODES.APPLICATION_WIZARD.WEB_NEW_APPLICATION_RESIDENCY_PROGRAM;
    
    this.loadLookupLists();
  }

  protected modelToForm(): void {

    this.friendlyNames.ResidencyProgram = "Residency Program";
    this.friendlyNames.SpecialtyArea = "Specialty Area";
    this.friendlyNames.PeriodStartDate = "Start Date";
    this.friendlyNames.PeriodEndDate = "End Date";

    this.stepForm.addControl('ResidencyProgram', new FormControl(null, [Validators.required]));
    this.stepForm.addControl('SpecialtyArea', new FormControl(this.wizard.model.application.Program, [Validators.required, Validators.maxLength(200)]));
    this.stepForm.addControl('City', new FormControl(this.wizard.model.application.City, [Validators.required, Validators.maxLength(200)]));
    this.stepForm.addControl('PeriodStartDate', new FormControl(this.wizard.model.application.PeriodStartDate ? new Date(this.wizard.model.application.PeriodStartDate) : null, [Validators.required, ReachControlValidators.futureDateOnlyValidator('Start Date')]));
    this.stepForm.addControl('PeriodEndDate', new FormControl(this.wizard.model.application.PeriodEndDate ? new Date(this.wizard.model.application.PeriodEndDate) : null, [Validators.required]));

    this.stepForm.addValidators(ReachControlValidators.dateRangeValidator(this.stepForm.get('PeriodStartDate'), this.stepForm.get('PeriodEndDate'), "Start Date", "End Date" ))

    super.modelToForm();
  }

  protected formToModel(): void {

    if (!this.dataLoaded) return;

    this.wizard.model.application.FacilityEntityId = this.stepForm.get('ResidencyProgram').value?.Id;
    this.wizard.model.application.FacilityName = this.stepForm.get('ResidencyProgram').value?.FullName;
    this.wizard.model.application.Program = this.stepForm.get('SpecialtyArea').value;
    this.wizard.model.application.City = this.stepForm.get('City').value?.Description;

    if (this.stepForm.get('PeriodStartDate').value) this.wizard.model.application.PeriodStartDate = formatDate(this.stepForm.get('PeriodStartDate').value, 'MM/dd/yyyy', 'en_US');
    if (this.stepForm.get('PeriodEndDate').value) this.wizard.model.application.PeriodEndDate = formatDate(this.stepForm.get('PeriodEndDate').value, 'MM/dd/yyyy', 'en_US');
  }

  possibleCities: ICityDto[];
  groupMembers: IEntityGroupMemberSearchResultItemDto[];
  dataLoaded: boolean = false;

  protected loadLookupLists() {

    const groupId = this.wizard.selectedStep?.Parameters?.["groupId"];
    if (!groupId) throw new Error("ResidencyExtensionComponent requires 'groupId' parameter! Please check configuration.");

    const load = forkJoin([this.entityGroupMemberService.search({ GroupId: groupId } as IEntityGroupMemberSearchCriteriaDto), this.listService.getCities()])
      .subscribe(([entityGroupMembers, cities]: [SearchResultListDto<EntityGroupMemberSearchResultItemDto>, ICityDto[]]) => {

        this.groupMembers = entityGroupMembers.Results;
        this.possibleCities = this.listService.filterInactiveItems(cities);

        if (this.wizard.model.application.FacilityEntityId) this.stepForm.get('ResidencyProgram').setValue(this.groupMembers.find(item => item.Id == this.wizard.model.application.FacilityEntityId));
        if (this.wizard.model.application.City) this.stepForm.get('City').setValue(this.possibleCities.find(item => item.Description == this.wizard.model.application.City));

        this.dataLoaded = true;
      });

    this.busyManagerService.resolve(load, this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

}

registerDynamicComponent(ReachScenarios.ApplicationWizard, ResidencyExtensionComponentKey, ResidencyExtensionComponent, ResidencyExtensionComponentSelector);
