<div style="padding-bottom: .5rem;" *ngFor="let keywordGroup of keywordGroups">

    <!-- Heading -->
    <div class="reach-section-header">{{keywordGroup.groupName}}</div>

    <!-- Items -->
    <ul>
        <li *ngFor="let definition of keywordGroup.definitions">{{ definition }}</li>
    </ul>

</div>

<div [formGroup]="stepForm">
    
    <!-- RadioContentItems -->
    <radio-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey" [defaultMinorKey]="defaultDynamicContentMinorKey" [bag]="wizard.selectedStep.bag"></radio-content-items>

    <!-- File Upload -->
    <checklist-file-upload-items #checklistFileUploadItems [uploadableChecklistItemIds]="templateIds" [domainChecklistDtoHost]="wizard.model.DomainChecklistDtoHost"></checklist-file-upload-items>

</div>