import { Component, Inject, Injector, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { noop, Observable, of, from } from 'rxjs';

import * as _l from 'lodash-es';

import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DialogSettings, DialogData } from '@coreModels/dialog-settings';
import { DialogSettingsService } from '@coreServices/dialog-settings.service';
import { ReachComponentFactoryService, ReachDynamicComponentInfo } from '@coreServices/reach-component-factory.service';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { SimpleChanges } from "node_modules/@angular/core/core";

/**
 * REACH generic Edit dialog Component with Ok and Cancel Command buttons in the footer, and a placeholder for rendering a dialog content child
 * Component in the dialog body area.
 */
@Component({
  selector: 'reach-dialog',
  templateUrl: './reach-dialog.component.html',
  styleUrls: ['./reach-dialog.component.scss']
})
export class ReachDialogComponent implements OnInit, OnChanges, OnDestroy {
  public title: string;
  public isOpen: boolean = false;
  public wrappedOkCommand: Command;
  public wrappedCancelCommand: Command;
  public contentComponentInfo: ReachDynamicComponentInfo = null;
  public validationScope: string;
  public dialogForm: FormGroup = null;
  private dialogData: DialogData = null;
  private readonly defaultOkCommand: Command;
  private readonly defaultCancelCommand: Command;

  @Input()
  dialogSettings: DialogSettings;

  constructor(private commandService: CommandService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dialogSettingsService: DialogSettingsService
    , private formBuilder: FormBuilder
    , private injector: Injector
    , private reachComponentFactoryService: ReachComponentFactoryService
    , private reachMessageService: ReachMessageService
    , private validationManagerService: ValidationManagerService) {
    this.dialogSettings = new DialogSettings(this);
    this.generateDialogValidationScopeKey();

    // Initialize dialogForm with single child control 'Content' that represents the dialog content component.
    this.dialogForm = this.formBuilder.group({
      Content: [[Validators.required]]
    });

    this.defaultOkCommand = this.commandService.create(() => true, () => of(noop));
    this.defaultCancelCommand = this.commandService.create(() => true, () => of(noop));
    this.updateOkCommand();
    this.updateCancelCommand();
  }

  ngOnInit(): void { }

  /**
   * OnChanges event handler that is fired when one or more of the @Input parameters passed from the host component changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    let openStateChanged = false;
    for (const propName in changes) {

      if (changes.hasOwnProperty(propName)) {
        let change = changes[propName];
        switch (propName) {
          case 'dialogSettings':
            {
              this.dialogSettings = change.currentValue;
              openStateChanged = change.currentValue.isOpen != this.isOpen;
              this.title = this.dialogSettings.title;
              this.dialogSettings.validationScope = this.validationScope;
              this.updateOkCommand();
              this.updateCancelCommand();
              break;
            }
        }
      }

      if (openStateChanged) {
        this.isOpen = this.dialogSettings.isOpen;
        if (this.isOpen) {
          this.dialogSettingsService.pushDialog(this.dialogSettings);
        } else {
          this.dialogSettingsService.popDialog();
        }
      }

      this.loadDynamicContentComponent();
    }
  }

  ngOnDestroy(): void {
    //this.clearValidationErrors();
  }

  /**
   * Gets a value indicating whether this dialog instance should display a validation
   * error message-summary child component.
   * @returns true if enabled.
   */
  public get canShowValidationSummary() {
    return this.dialogSettings.showValidationSummary;
  }

  /**
   * Loads the dynamic content component specified on the DialogSettings into the content area <reach-container> in the dialog template.
   */
  private loadDynamicContentComponent() {
    let injectorData = this.dialogSettings.getInjector(this.injector);
    injectorData.dialogData.validationScope = this.validationScope;
    injectorData.dialogData.dialogForm = this.dialogForm;
    this.dialogData = injectorData.dialogData;
    if (this.dialogSettings && this.dialogSettings.model) {
      this.contentComponentInfo = this.reachComponentFactoryService.dynamicComponentInfo(
        this.dialogSettings.scenarioKey,
        this.dialogSettings.contentComponentTypeKey,
        injectorData.injectorService.injector
      );
    }
  }

  /**
   * Updates the command bound to the Ok button with a wrapper command around the preferred version of the Ok command (host v content v default).
   */
  private updateOkCommand() {
    const ok = this.dialogSettings.okCommand || this.defaultOkCommand;
    this.wrappedOkCommand = this.wrapOkCommand(ok);
  }

  /**
   * Updates the command bound to the Ok button with a wrapper command around the preferred version of the Cancel command (host v content v default).
   */
  private updateCancelCommand() {
    const cancel = this.dialogSettings.cancelCommand || this.defaultCancelCommand;
    this.wrappedCancelCommand = this.wrapCancelCommand(cancel);
  }

  /**
   * Wraps the okCommand with dialog behaviors.
   * @param command the Ok Command object.
   * @param commandText the Ok button text.
   */
  private wrapOkCommand(command: Command, commandText: string = "Ok") {

    const canExecute = (): boolean => {
      return !this.dialogSettings.disableOkCommand && command.canExecute(this.dialogData.outputModel);
    };

    const doExecute = async (): Promise<void> => {
      try {
        if (canExecute()) {

          // First check if there are any local validation errors.
          if (this.validationManagerService.hasClientDialogValidationMessages(this.validationScope)) {

            // Force them to display
            this.validationManagerService.showValidationSummary(this.validationScope);

            // Pop toast messages for each
            this.validationManagerService.popToastersForMessages(this.validationManagerService.getClientDialogValidationMessages(this.validationScope))

            // Exit
            return of(null).toPromise();
          }

          this.clearValidationErrors();
          let waitable: Promise<any> = null;

          // Ok command takes the output model as the input parameter to the execute function
          let execRes = command.execute(this.dialogData.outputModel);
          if (execRes instanceof Observable) {
            waitable = execRes.toPromise();
          } else if (execRes instanceof Promise) {
            waitable = execRes;
          } else {
            waitable = of(execRes).toPromise();
          }

          let res = await waitable;

          //let res = await from(command.execute(this.dialogData.outputModel)).toPromise();
          let hasValidationErrors: boolean = res && (res as any).HasErrors;
          if (!this.dialogSettings.okCommandDoesNotCloseDialog && !hasValidationErrors) {
            // If there were no validation errors to review on the dialog, and if this dialog instance was not specifically flagged to not close on OK, then close.
            this.dialogSettings.isOpen = false;
            this.isOpen = false;
            this.dialogSettingsService.popDialog();
          }
          else {
            this.validationManagerService.showValidationSummary(this.validationScope);
          }

          return of(res).toPromise();
        }
      } catch (err) {
        console.log(err);
      }

      return of(null).toPromise();
    };

    const execute = (): Observable<void> => {
      return from(doExecute());
    };

    var wrappedCommand = this.commandService.create(canExecute, execute);
    wrappedCommand.commandText = commandText;
    return wrappedCommand;
  }

  /**
   * Wraps the cancelCommand with dialog behaviors.
   * @param command the Cancel Command object.
   * @param commandText the Cancel button text.
   */
  private wrapCancelCommand(
    command: Command,
    commandText: string = "Cancel") {
    const canExecute = (): boolean => {
      return command.canExecute(this.dialogData.outputModel);
    }

    const doExecute = async (): Promise<void> => {
      try {
        if (canExecute()) {
          let waitable = command.execute(this.dialogData.outputModel);
          if (waitable instanceof Observable) {
          } else if (waitable instanceof Promise) {
          } else {
          }

          this.clearValidationErrors();
          this.dialogSettings.isOpen = false;
          this.isOpen = false;
          this.dialogSettingsService.popDialog();
        }
      } catch (err) {
        // TODO - define default handling
        console.error(err);
      }
    };

    const execute = (): Observable<void> => {
      return from(doExecute());
    };

    var wrappedCommand: Command = this.commandService.create(canExecute, execute);
    wrappedCommand.commandText = commandText;
    return wrappedCommand;
  }

  /**
   * Clears the validation messages associated with this dialog instance's scope.
   */
  private clearValidationErrors() {
    if (this.canShowValidationSummary) {
      this.reachMessageService.clear(this.validationScope);
    }
  }

  /**
   * Generates a unique validation scope key to represent this dialog instance. Validation error messages
   * associated with this dialog instance will be routed with this key.
   */
  private generateDialogValidationScopeKey(): string {
    let dialogKey = this.constantsService.VALIDATION_ERROR_SCOPES.DIALOG.toString();
    let instanceKey = (new Date()).valueOf().toString();
    let key = `${dialogKey}_${instanceKey}`;
    this.validationScope = key;
    return key;
  }
}

