import { Component, Inject, OnInit, OnChanges, Optional, SimpleChanges } from '@angular/core';
import * as _l from 'lodash-es';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "@coreServices/constants-provider.service";
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { PageSummaryService } from '@coreServices/page-summary.service';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';
import { ReachModelConfigurationInjector, MODEL_CONFIG_INJECTION_TOKEN } from '@coreServices/reach-component-factory.service';
import { DynamicContentDto } from '@coreShared/core-shared.module';
import { ReachApplicationService } from '@coreServices/reach-application.service';

@Component({
  selector: 'page-summary-text',
  templateUrl: 'pageSummaryText.component.html',
  styleUrls: ['./page-summary-text.component.scss']
})
export class PageSummaryTextComponent extends ReachModelAwareComponent implements OnInit, OnChanges {
  public entry: DynamicContentDto;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicContentManagerService: DynamicContentManagerService
    , private pageSummaryService: PageSummaryService
    , public reachApplicationService: ReachApplicationService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
  ) {
    super(reachModelConfigurationInjector);
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    super.onInputChanges(changes);
    this.entry = this.getEntry();
    this.raisePageSummaryChanged();
  }

  private raisePageSummaryChanged() {
    this.pageSummaryService.raisePageSummaryContentChanged$({
      dynamicContentEntry: this.entry
    });
  }

  private getEntry() {
    return _l.find(this.dynamicContentManagerService.getEntries(),
      (entry: DynamicContentDto) => {
        return entry.MajorKey === this.majorKey &&
          entry.MinorKey === this.minorKey &&
          entry.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT &&
          entry.CategoryId === this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT &&
          entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE
          && entry.Content.length > 0;
      });
  }
}
