import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { EntityGroupMemberSearchResultItemDto, IEntityGroupMemberSearchCriteriaDto, SearchResultListDto } from "@coreShared/core-shared.module";
import { Observable } from "rxjs";
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({ providedIn: 'root' })
export class EntityGroupMemberSearchService extends ReachHttpClientService {

  private uri: string;

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    private argumentExceptionService: ArgumentExceptionService,
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = `${this.apiRootUri}/EntityGroupMemberSearch`;
  }

  public search(criteria: IEntityGroupMemberSearchCriteriaDto): Observable<SearchResultListDto<EntityGroupMemberSearchResultItemDto>> {
    if (!criteria) this.argumentExceptionService.create("criteria").log();
    return this.get<SearchResultListDto<EntityGroupMemberSearchResultItemDto>>(`${this.uri}/search`, { params: criteria as any });
  }

}
