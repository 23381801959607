<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="currentSupervisorDesignations" [responsive]="true"
  responsiveLayout="stack">

  <!-- Caption -->
  <ng-template pTemplate="caption"> 
    <div class="p-d-flex p-jc-between">
      <span class="p-text-left reach-list-header">Licensing Supervisor</span>
      <div>
        <button *ngIf="presentAttestationCommand.enabled" pButton pRipple class="p-button-text" icon="fa fa-plus"
          [command-button]="presentAttestationCommand" tool-tip="Attest"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="designationFormEnabled" pSortableColumn="Form.Description">Form<p-sortIcon field="Form.Description"></p-sortIcon>
      </th>
      <th pSortableColumn="Type.Description">Type<p-sortIcon field="Type.Description"></p-sortIcon>
      </th>
      <th pSortableColumn="Status.Description">Status<p-sortIcon field="Status.Description"></p-sortIcon>
      </th>
      <th pSortableColumn="EffectiveDate">Effective Date<p-sortIcon field="EffectiveDate"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <!-- Body -->
  <ng-template pTemplate="body" let-item>
    <tr>
      <td *ngIf="designationFormEnabled"><span class="p-column-title">Form</span>{{item.Form.Description}}</td>
      <td><span class="p-column-title">Type</span>{{item.Type.Description}}</td>
      <td><span class="p-column-title">Status</span>{{item.Status.Description}}</td>
      <td><span class="p-column-title">Effective Date</span>{{item.EffectiveDate | date:'MM/dd/yyyy'}}</td>
    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="colSpan">
        <span class="reach-text-muted"><em>No attestations on record</em></span>
      </td>
    </tr>
  </ng-template>

</p-table>
