import { IDocumentRelatedToKeysDto,
IDocumentSearchCriteriaDto,
IDomainReasonForChangeDtoHost } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DocumentSearchCriteriaDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDocumentSearchCriteriaDto {
	LetterTypeId?: number;
	UnitId?: number;
	ActivityTypeId?: number;
	ActivitySubtypeId?: number;
	RelatedTo: IDocumentRelatedToKeysDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
