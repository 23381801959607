/**
 * Enumerates the RouteTypes for the Search feature.
 */
export enum SearchTypes {
  Entity = 400,
  EntityLazy,
  License,
  LicenseLazy,
  CeSponsor,
  CeSponsorLazy,
  Course,
  CourseLazy
}

export class SearchComponentKeys {
  public static readonly Entity: string = SearchTypes[SearchTypes.Entity];
  public static readonly EntityLazy: string = SearchTypes[SearchTypes.EntityLazy];
  public static readonly License: string = SearchTypes[SearchTypes.License];
  public static readonly LicenseLazy: string = SearchTypes[SearchTypes.LicenseLazy];
  public static readonly CeSponsor: string = SearchTypes[SearchTypes.CeSponsor];
  public static readonly CeSponsorLazy: string = SearchTypes[SearchTypes.CeSponsorLazy];
  public static readonly Course: string = SearchTypes[SearchTypes.Course];
  public static readonly CourseLazy: string = SearchTypes[SearchTypes.CourseLazy];
}
