// Framework
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { forkJoin, noop } from 'rxjs';

// LODASH
import * as _l from 'lodash-es';

// CORE
import {
  CheckboxContent,
  CheckboxContentService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  ReachScenarios,
  registerDynamicComponent,
  ValidationManagerService,
  WizardInjector,
  WIZARD_INJECTOR_TOKEN,
  WizardStepComponent,
  UserManagerService,
  OnlineUserService
} from '@core/core.module';

import {
  IEducationProgramApplicationConstantsService
} from '@licensureCore/licensure-core.module';


// SHARED
import { IEntityDto, IEntityPhoneDto } from '@coreShared/core-shared.module';
import { EducationProgramApplicationDto, EducationProgramApplicationInfoDto } from '@licensureShared/licensure-shared.module';
import { ActivityWizardModel } from 'src/app/core/features/activityWizard/models/activity-wizard-model';

// CONSTANTS
export const ProgramInfoComponentSelector: string = 'program-info';
export const ProgramInfoComponentKey: string = 'programInformation'; // Match to [dbo].[lookup_web_service_view].[component_name]

/**
 * User Program Contact wizard step detail component for registration account detail.
 */
@Component({
  selector: ProgramInfoComponentSelector,
  templateUrl: './program-info.component.html',
  styleUrls: ['./program-info.component.scss']
})
export class ProgramInfoComponent extends WizardStepComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  // Fields
  checkboxContents = [];
  educationProgramApplication: EducationProgramApplicationDto;
  educationProgramApplicationInfo: EducationProgramApplicationInfoDto;
  programContactEntity: IEntityDto;
  programDirectorEntity: IEntityDto;
  educationProgramApplicationConstantsService: IEducationProgramApplicationConstantsService;

  // CTOR
  constructor(
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected userManagerService: UserManagerService,
    protected onlineUserService: OnlineUserService) {

    // Base.
    super(constantsService, validationManagerService, wizardInjector, ProgramInfoComponent.name, checkboxContentService, elementRef);
    this.educationProgramApplicationConstantsService = this.constantsService as IEducationProgramApplicationConstantsService;

    // Construct form.
    this.modelToForm();

    this.wizard.model.validationMode = null;
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    // Friendly names.
    this.friendlyNames.Email = 'Email Address';
    this.friendlyNames.Acknowledgement = 'Acknowledgement';
    this.friendlyNames.Addresses = 'Addresses';
    this.friendlyNames.PhoneNumbers = 'Phone Numbers';

    // Initialize checkbox content.
    this.initCheckboxes();

    // Base.
    super.ngOnInit();
  }

  /**
 * A lifecycle hook that is called after Angular has fully initialized a component's view.
 */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }


  /**
 * A lifecycle hook that is called after the default change detector has completed checking a component's view for changes.
 */
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * OnDestroy Ng lifecycle event handler. Cleanup of validation errors, observable subscriptions, and anything else that needs to be disposed of when
   * this wizard step exited.
   */
  ngOnDestroy(): void { super.ngOnDestroy(); }

  public get programName(): string {
    return this.educationProgramApplication && this.educationProgramApplication.Entity ? this.educationProgramApplication.Entity.FMLName : '';
  }

  public get contactEntityId(): number {
    return this.programContactEntity ? this.programContactEntity.Id : 0;
  }

  public get directorName(): string {
    return this.programDirectorEntity ? this.programDirectorEntity.FMLName : '';
  }

  public get directorPrimaryPhoneNumber(): string {
    let primaryPhone: IEntityPhoneDto = null;
    if (this.programDirectorEntity && this.programDirectorEntity.PhoneNumbers) {
      primaryPhone = _l.find(this.programDirectorEntity.PhoneNumbers, (p: IEntityPhoneDto) => { return p.IsPrimary; });
    }

    return primaryPhone ? primaryPhone.Number : '';
  }

  public get directorEmail(): string {
    return this.programDirectorEntity ? this.programDirectorEntity.Email : '';
  }

  public get directorCredentials(): string {
    return this.programDirectorEntity ? this.programDirectorEntity.ProfessionalSuffix : '';
  }

  public get functionNumber() {
    return this.educationProgramApplication ? this.educationProgramApplication.FunctionNumber : 0;
  }

  public save = () => {
    return noop();
  }

  /**
   * Overrides the base class method to provide specified mapping from the input model (wizard model) to
   * the reactive stepForm: FormGroup for this instance.
   */
  protected modelToForm() {

    let modelInfo: EducationProgramApplicationDto;

    this.educationProgramApplication = (modelInfo ?? this.wizard.model.educationProgramApplication) as EducationProgramApplicationDto;
    this.educationProgramApplicationInfo = (modelInfo ?? this.wizard.model.educationProgramApplication) as EducationProgramApplicationInfoDto;
    this.programContactEntity = this.educationProgramApplication.Entity;
    this.programDirectorEntity = this.educationProgramApplication.DirectorEntity;

    // Construct form.
    this.stepForm.addControl('Email', new FormControl(this.programContactEntity.Email || '', [Validators.required, Validators.maxLength(80)]));
    this.stepForm.addControl('PhoneNumbers', new FormControl(this.programContactEntity.PhoneNumbers));

    // Base.
    super.modelToForm();
  }

  /**
 * Maps this instance's stepForm FormGroup to the wizard model.
 */
  protected formToModel() {
    this.programContactEntity.Email = this.stepForm.get('Email').value;
  }

  /**
* Initialize data for this instance. Major/Minor keys, friendly names, etc.
*/
  protected initCheckboxes(): void {
    let contactAcknowledgementMinorKey = `${this.stepMinorKey}.Acknowledgement`;
    this.checkboxContents = [
      this.checkboxContentService.create(
        this.wizard.dynamicContentConfiguration.majorKey,
        contactAcknowledgementMinorKey,
        'Current Contact Info Acknowledgement')
    ];

    this.checkboxContents = this.createCheckboxControls(this.checkboxContents);
  }

  public get hasCheckboxes(): boolean {
    return this.checkboxContents && this.checkboxContents.length > 0;
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.EducationProgramApplicationWizard, ProgramInfoComponentKey, ProgramInfoComponent, ProgramInfoComponentSelector);

