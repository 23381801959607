// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import * as _ from 'underscore';

import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';
import { CaptchaCredentialDto, CredentialType, IHttpResponseMessage, ISessionDto, UsernamePasswordCredentialDto  } from '@coreShared/core-shared.module';
import Captcha = CredentialType.Captcha;

/**
 * Service for initiating a user session.
 */
@Injectable({ providedIn: 'root' })
export class SessionService extends ReachHttpClientService{
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }

  /**
   * Creates a user session from the specified username and password credentials.
   * @param userName
   * The user name string.
   * @param password
   * The user password string.
   * @returns The session object.
   */
  public createFromUsernamePasswordCredential(userName: string, password: string): Observable<ISessionDto> {
    let cred = new UsernamePasswordCredentialDto(userName, password);
    var url = `${this.apiRootUri}/session`;
    var result = this.post<ISessionDto>(url, cred);
    return result;
  }

  /**
   * Creates a user session from a Captcha Response credential.
   * @param captchaResponse
   * The Captcha response string.
   * @returns The session object.
   */
  public createFromCaptchaCredential(captchaResponse: string) {
      let cred = new CaptchaCredentialDto(captchaResponse);
      return this.post <ISessionDto>(`${this.apiRootUri}/session`, cred);
  }
}
