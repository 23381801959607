import { ReachScenarios } from "src/app/core/index-constants";

export class LicensureScenarios extends ReachScenarios {
  public static readonly SupervisorAttestationWizard: string = 'supervisor-attestation-wizard';
  public static readonly SuperviseePlan: string = 'supervisee-plan';
  public static readonly SuperviseeVerification: string = 'supervisee-verification';
  public static readonly SupervisorPlan: string = 'supervisor-plan';
  public static readonly SupervisorVerification: string = 'supervisor-verification';
  public static readonly LicenseLanding: string = 'license-landing';
  public static readonly DelegatedAdministration: string = 'delegated-administration';
  public static readonly CreateDelegationToken: string = 'create-delegation-token';
  public static readonly ModifyDelegate: string = 'modify-delegate';
  public static readonly ManagePicAccess: string = 'manage-pic-access';
  public static readonly ApplyDelegationToken: 'apply-delegation-token';
}
