<div class="p-fluid" [formGroup]="stepForm">

    <div class="p-grid p-formgrid">

        <!-- ResidencyProgram -->
        <div class="p-field p-col-12 p-lg-4">
            <label for="ResidencyProgram">Residency Program</label>
            <p-dropdown *ngIf="dataLoaded" inputId="ResidencyProgram" formControlName="ResidencyProgram"
                [options]="groupMembers" appendTo="body" optionLabel="FullName" dataKey="Id" [showClear]="true"
                placeholder="&nbsp;" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- SpecialtyArea -->
        <div class="p-field p-col-12 p-lg-8">
            <label for="SpecialtyArea">Specialty Area</label>
            <input id="SpecialtyArea" type="text" formControlName="SpecialtyArea" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-lg-4">
            <label for="City">City</label>
            <p-dropdown *ngIf="dataLoaded" [options]="possibleCities" inputId="City" formControlName="City"
                optionLabel="Description" [virtualScroll]="true" itemSize="25" [autoDisplayFirst]="false"
                [showClear]="true" appendTo="body" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- PeriodStartDate -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="PeriodStartDate">Start</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="PeriodStartDate"
                formControlName="PeriodStartDate" appendTo="body" baseZIndex="1">
            </p-calendar>
        </div>

        <!-- PeriodEndDate -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="PeriodEndDate">End</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="PeriodEndDate"
                formControlName="PeriodEndDate" [showIcon]="false" yearNavigator="true" monthNavigator="true"
                defaultDate="" appendTo="body" baseZIndex="1">
            </p-calendar>
        </div>

    </div>

</div>