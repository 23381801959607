import { MessageService, Message } from 'primeng/api';
import { MessageSeverities } from '@coreModels/message-severities';

export class ReachMessage implements Message {
  severity?: string;
  summary?: string;
  detail?: string;
  id?: any;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
  formControlName: string;

  /**
 * Creates a new Message with the specified properties.
 * @param severity 
 * @param summary
 * @param detail
 * @param key
 * @param life
 * @param closable
 * @param sticky
 * @param data
 * @param id
 */
  constructor(severity?: MessageSeverities, summary?: string, detail?: string, key?: string, life?: number, sticky?: boolean, closable?: boolean, data?: any, id?: any) {
    // PrimeNg is very picky about undefined v null, so leave the property off the Message object if NA
    if (severity) {
      this.severity = severity;
    }
    if (summary) {
      this.summary = summary;
    }
    if (detail) {
      this.detail = detail;
    }
    if (key) {
      this.key = key;
    }
    if (life) {
      this.life = life;
    }
    if (closable) {
      this.closable = closable;
    }
    if (sticky) {
      this.sticky = sticky;
    }
    if (data) {
      this.data = data;
    }
    if (id) {
      this.id = id;
    }
  }

  static fromMessage(message: Message): ReachMessage {
    let messageSeverity = MessageSeverities[message.severity];
    let msg = new ReachMessage(
      messageSeverity,
      message.summary,
      message.detail,
      message.key,
      message.life,
      message.sticky,
      message.closable,
      message.data,
      message.id);

    return msg;
  }
}
