import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { DocumentHistoryComponent } from './document-history.component';

export {
    DocumentHistoryComponent
};

@NgModule({
    declarations: [
        DocumentHistoryComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule
    ],
    exports: [
        DocumentHistoryComponent,
    ]
})
export class DocumentHistoryModule { }
