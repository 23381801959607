import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { Subscription } from "rxjs";

import {
  AuthGuard,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DynamicRoutesService,
  LandingPageDetailRouteResolverService,
  LandingPageService,
  OnlineServiceLinkManagerService,
  OnlineServiceMenuService,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  UserManagerService
} from '@core/core.module';

import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
export const LicenseLandingCardComponentKey = 'licenseLandingCardComponent';
export const ProfileLandingCardComponentSelector = 'profile-landing-card';

/**
 * Service that supports the shared features like the GeneralInfo card.
 */
@Injectable({
  providedIn: 'root'
})
export class ProfileConfigurationService {
  serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  subscriptionLogin: Subscription;
  subscriptionUserProfileRefreshed: Subscription;
  subscriptionLandingPageReconfig: Subscription;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , protected dynamicRoutesService: DynamicRoutesService
    , protected landingPageService: LandingPageService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , protected onlineServiceMenuService: OnlineServiceMenuService
    , protected router: Router
    , protected summaryInfoBuilderService: SummaryInfoBuilderService
    , protected summaryInfoManagerService: SummaryInfoManagerService
    , protected userManagerService: UserManagerService) {
    this.subscriptionLogin = this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.subscriptionUserProfileRefreshed = this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.subscriptionLandingPageReconfig = this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  protected configureConstants() { }
  protected configureDecorators() { }

  protected configureRoutes() {
    const routes: Routes = [
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.ProfileDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ProfileDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addInfosToLandingPage(principal) {
    let infos: SummaryComponentInfo[] = [];
    if (!this.isInitialized) {
      this.initialize();
    }
    if (principal && principal.user.UserAccount) {
      const summaryDetailConfiguration = new SummaryDetailConfiguration(LicensureLandingCategories.Profile, LicensureLandingComponentKeys.ProfileSummaryLazy, LicensureLandingComponentKeys.ProfileDetailLazy, SummaryAggregationType.Singleton)
      const profileInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Profile", summaryDetailConfiguration);
      profileInfoBuilder.addItem(1, "Profile", principal.user, this.constantsService.DOMAIN_IDS.PROFILE, this.constantsService.CARD_DISPLAY_ORDER.PROFILE);
      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}

