import { IWebCartItemDto,
IWebServiceHistoryDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebCartItemDto extends BaseDto implements IWebCartItemDto {
	CartId: number;
	ServiceHistoryId: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	Total: number;
	ServiceHistoryItem: IWebServiceHistoryDto;

}
