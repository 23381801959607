// Angular
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { from } from 'rxjs';
import * as _ from 'underscore';

import {
  AuthGuard, ConstantsService, CONSTANTS_SERVICE_TOKEN, DynamicRoutesService,
  FunctionTypeService, LandingComponentKeys, LandingPageDetailRouteResolverService,
  LandingPageService, OnlineServiceHistoryService, OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  SystemSettingsManagerService,
  UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService,
  DomainChecklistItemStatusTypeService
} from '@core/core.module';
import { CanCancelWebServiceResponseDto, IDomainChecklistDtoHost, WebServiceHistoryDto } from '@coreShared/core-shared.module';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { ConfirmationService } from "primeng/api";
import { IApplicationInfoDto, IEducationChecklistItemDto } from "src/app/licensureShared/licensure-shared.module";
import { LicensureConstantsProviderService } from "../licensure-constants-provider.service";

@Injectable({ providedIn: 'root' })
export class ApplicationFeatureConfigurationService {

  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private applicationConstantsService: IApplicationConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private landingPageService: LandingPageService
    , private onlineServiceHistoryService: OnlineServiceHistoryService
    , private router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private userManagerService: UserManagerService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService
    , private confirmationService: ConfirmationService) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.Application);
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  directiveConfiguration = {
    ApplicationOccupationalEducationEcfmgComponentConfiguration: {
      isEcfmgRequired: (domainChecklistHost: IDomainChecklistDtoHost, domainChecklistItemStatusTypeService: DomainChecklistItemStatusTypeService): boolean => {
        const USA: string = "USA";
        const CANADA: string = "CANADA";
        //
        const ecfmgRequired = domainChecklistHost.DomainChecklist.some((item) => {
          if (item.ChecklistItemTemplateId == (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.OCCUPATIONAL_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID && !domainChecklistItemStatusTypeService.hasAspectStatus(item, this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.NOT_APPLICABLE)) {
            const educationChecklistItemDto = item as IEducationChecklistItemDto;
            const country = ((educationChecklistItemDto.Education?.EducationProgram?.Country ?? educationChecklistItemDto.Education.SchoolCountry) ?? USA).toUpperCase();
            return (country != USA && country != CANADA);
          }
        });

        return ecfmgRequired;
      }
    },
    applicationEducationChecklistItemDirectiveConfiguration: {
      educationType: null,
      degreeRequired: false,
      lookupDegreeFilterFunction: (degreeCollection, applicationTypeId): any => {
        return degreeCollection;
      },
      isManualEntryAllowed: (application): boolean => {
        return true;
      },
      manualEntryCountryFilterFunction: (countryCollection, application): any => {
        return countryCollection;
      },
      endDateRequired: (educationItem): boolean => {
        return true;
      }
    },

    practiceLocationConfiguration: {
      getMode: function () {
        return this.systemSettingsManagerService.asInt(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_WIZARD.PRACTICE_LOCATION_MODE);
      },
      isRequired: function () {
        return false;
      }
    }
  }

  wizardPageConfiguration = {
    applicationWizardSelector: {
      applicationTypeFilter: (currentPrincipal, allApplicationTypes) => {
        return _.filter(allApplicationTypes,
          function (applicationType) {
            if (applicationType.IsOnlineApplicationSupported) {
              return applicationType.IsIndividual == null || applicationType.IsIndividual === currentPrincipal.user.UserAccount.profile.IsIndividual;
            }
            return false;
          });
      }
    },
  }


  public canContinueApplication(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo == null) {
      return false;
    }

    if (!this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_CONTINUATION.ENABLED)) {
      return false;
    }

    if (applicationInfo.ApplicationStatusType.Id !== this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_CONTINUATION.APPLICATION_STATUS)) {
      return false;
    }

    let webServiceHistoryInfoItem = this.getRelatedWebServiceHistoryInfoItem(applicationInfo);
    return webServiceHistoryInfoItem != null && webServiceHistoryInfoItem.ServiceInProgress;
  }

  public canCancelApplication(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo == null) {
      return false;
    }

    if (applicationInfo.ApplicationStatusType.Id !== this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_CONTINUATION.APPLICATION_STATUS)) {
      return false;
    }

    let webServiceHistoryInfoItem = this.getRelatedWebServiceHistoryInfoItem(applicationInfo);

    return webServiceHistoryInfoItem != null && webServiceHistoryInfoItem.ServiceInProgress && webServiceHistoryInfoItem.IsCancellationAllowed;
  }

  public async cancelApplication(applicationInfo: IApplicationInfoDto) {

    let prompt = "Are you sure you want to cancel this process?";
    let webServiceHistoryId = this.getRelatedWebServiceHistoryInfoItem(applicationInfo).WebServiceHistoryId

    let canCancelResponse: CanCancelWebServiceResponseDto = await this.onlineServiceHistoryService.canCancel(webServiceHistoryId).toPromise();

    if (canCancelResponse.Result === true) {
      if (canCancelResponse.TransactionExistsConfirmationDoesNotExist === true) {
        prompt = "If you recently completed payment for this process. The system will remove it once processing is complete. Cancellation at this time may result in a delay processing your request. \n\nAre you sure you want to cancel this process?";
      }

      this.confirmationService.confirm({
        message: `${prompt}`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Ok',

        // If accepted...
        accept: () => {
          this.onlineServiceHistoryService.cancel(webServiceHistoryId).toPromise().then((webServiceHistoryDto: WebServiceHistoryDto) => {
            if (webServiceHistoryDto.IsValid === true) {
              this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
            }
          })
        }
      });
    }
  }


  public presentApplicationWizard(applicationInfo: IApplicationInfoDto) {
    let webServiceHistoryInfoItem = _l.find(applicationInfo.WebServiceHistoryInfoItems,
      (item) => {
        return item.ProcessTypeId === this.applicationConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION;
      });

    let webServiceHistoryId = null;
    if (webServiceHistoryInfoItem) {
      webServiceHistoryId = webServiceHistoryInfoItem.WebServiceHistoryId;
    }

    let selectorRequired = !webServiceHistoryId;
    if (selectorRequired) {
      return this.navigateToSelector(applicationInfo.EntityId, applicationInfo.Id, webServiceHistoryId);
    }
    else {
      return this.navigateToWizard(applicationInfo.Id, applicationInfo.ApplicationType.Type, applicationInfo.ApplicationBasisType.Code, webServiceHistoryId);
    }
  }

  public navigateToWizard(applicationId: number, applicationType: string, basisTypeCode: string, webServiceHistoryId: number) {
    let patternUrl = this.dynamicRoutesService.buildRoute(
      this.serviceRegistryItem.onlineServicePathTemplate,
      applicationId,
      applicationType,
      basisTypeCode,
      this.applicationConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION,
      webServiceHistoryId);
    return this.router.navigate(patternUrl);
  }

  public navigateToSelector(entityId: number, applicationId: number, webServiceHistoryId: number) {
    let patternUrl = this.dynamicRoutesService.buildRoute(
      this.serviceRegistryItem.onlineServicePathTemplate,
      entityId,
      applicationId,
      webServiceHistoryId);
    return this.router.navigate(patternUrl);
  }

  public getWizard = (applicationTypeId, applicationId, applicationBasisCode, webServiceHistoryId: number) => {
    let functionType = this.functionTypeService.create(this.applicationConstantsService.FUNCTION_TYPES.APPLICATION, applicationId);
    let wizardTags = this.wizardTagsService.create(applicationTypeId, applicationBasisCode, null, null, null, null, null);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId,
      this.applicationConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION,
      wizardTags);

    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, applicationTypeId).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };

  public getRelatedWebServiceHistoryInfoItem(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo != null) {
      return _l.find(applicationInfo.WebServiceHistoryInfoItems,
        (item) => {
          return item.ProcessTypeId === this.applicationConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION;
        });
    }

    return null;
  }

  public canDownloadConfirmationDocument(applicationInfo) {
    return applicationInfo != null && applicationInfo.OnlineConfirmationDocumentId > 0;
  }

  public downloadConfirmationDocument(applicationInfo) {
    if (this.canDownloadConfirmationDocument(applicationInfo)) {
      this.onlineServiceHistoryService.downloadConfirmationDocument(applicationInfo.OnlineConfirmationDocumentId);
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, ApplicationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, ApplicationConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, ApplicationConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, ApplicationConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, ApplicationConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, ApplicationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, ApplicationConstantsService.Mergers.FUNCTION_TYPES);
    this.applicationConstantsService = this.constantsService as IApplicationConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/application/applicationWizardSelector/application-wizard-selector.module').then(m => m.ApplicationWizardSelectorModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/application/applicationWizard/application-wizard.module').then(m => m.ApplicationWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/application/applicationWizard/application-wizard.module')
          .then(m => m.ApplicationWizardModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.ApplicationDetailLazy).path + "/:id", //RouteTargetConstants.Landing.Licensure.Application.Detail,
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ApplicationDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  private addInfosToLandingPage(principal) {
    let isFeatureEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.ENABLED);

    if (isFeatureEnabled &&
      principal?.user?.UserAccount?.profile?.Applications &&
      principal.user.UserAccount.profile.Applications.length > 0) {

      if (!this.isInitialized) {
        this.initialize();
      }

      let configuration = new SummaryDetailConfiguration(
        LicensureLandingCategories.Application,
        LicensureLandingComponentKeys.ApplicationSummaryLazy,
        LicensureLandingComponentKeys.ApplicationDetailLazy,
        SummaryAggregationType.Singleton);
      let infos: SummaryComponentInfo[] = [];

      let applicationInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Application", configuration);
      principal.user.UserAccount.profile.Applications.forEach((application, index: number) => {
        applicationInfoBuilder.addItem(
          index,
          `Application (${application.ApplicationType.Type})`,
          application,
          this.applicationConstantsService.DOMAIN_IDS.APPLICATION
        );
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }
}

export class ApplicationConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      APPLICATION_WIZARD: 'Application.Wizard',
      APPLICATION_WIZARD_SELECTOR: 'Application.Wizard.Selector',
    },
    SYSTEM_SETTING_KEYS: {
      APPLICATION: {
        SPECIAL_ACCOMMODATIONS: {
          EXAM_RELEASE_ENABLED: "Feature.Exam.ExamRelease.Enabled"
        },
        OCCUPATIONAL_EDUCATION: {
          ALLOW_OTHER_SCHOOL_ENTRY: "Feature.Application.OccupationalEducation.AllowOtherSchoolEntry",
          DEGREE_DATE_TYPE: "Feature.Application.AdditionalEducation.DegreeDateType"

        },
        ADDITIONAL_EDUCATION: {
          ALLOW_OTHER_SCHOOL_ENTRY: "Feature.Application.AdditionalEducation.AllowOtherSchoolEntry",
          REQUIRED: "Feature.Application.AdditionalEducation.Required",
          DEGREE_DATE_TYPE: "Feature.Application.OccupationalEducation.DegreeDateType"
        },
        PRACTICE_LOCATIONS: {
          INCLUDE_NONE_INDICATOR: "Feature.Application.PracticeLocations.IncludeNoneIndicator"
        },
        APPLICATION_WIZARD: {
          COLLECT_APPLICANT_DRIVERS_LICENSE_NUMBER: "Feature.ApplicationWizard.CollectApplicantDriversLicenseNumber",
          COLLECT_APPLICANT_DRIVERS_LICENSE_STATE: "Feature.ApplicationWizard.CollectApplicantDriversLicenseState",
          PRACTICE_LOCATION_MODE: "Feature.ApplicationWizard.PracticeLocationMode",
          PRACTICE_LOCATION_INCLUDE_FROM_AND_TO_DATE: "Feature.ApplicationWizard.PracticeLocationIncludeFromAndToDates",

          // Application education checklist items
          EDUCATION_DIRECTIVE_TYPE: "Feature.Education.Directive.Type",
          EDUCATION_DIRECTIVE_TYPE_OPTIONS: { LIST: "applicationEducationList", LIST_WITH_SEARCH: "applicationEducationListWithSearch" },
          EDUCATION_DIRECTIVE_EDUCATION_TYPES: {
            OCCUPATIONAL_EDUCATION: "Licensure",
            OTHER_EDUCATION: "OtherEducation"
          },
          EDUCATION_DIRECTIVE_INCLUDE_MAJOR: "Feature.Education.Directive.IncludeMajor",
          EDUCATION_DIRECTIVE_INCLUDE_OCCUPATIONAL_EDUCATION_ATTENDANCE_DATES: 'Feature.Application.OccupationalEducation.IncludeAttendanceDates',
          EDUCATION_DIRECTIVE_INCLUDE_ADDITIONAL_EDUCATION_ATTENDANCE_DATES: 'Feature.Application.AdditionalEducation.IncludeAttendanceDates'
        }
      }
    },

    FEATURE_CONSTANTS: {
      APPLICATION_WIZARD: {
        PRACTICE_QUESTIONS_ANSWER_IDS: {
          YES: 1,
          NO: 2,
          UNANSWERED: 3,
          XBLANK: 4,
          ANSWERED_LAST_YEAR: 5
        },
        PRACTICE_LOCATION_MODES: {
          DEFAULT: 1,
          WITH_REFERENCES: 2,
          WITH_LICENSED_AS: 3,
          WITH_SUPERVISOR: 4,
          WITH_FEDERAL_FACILITY_DEFAULT: 5
        },
        PRACTICE_LOCATION_TRAINING_CODE: "P",
        OTHER_MEMBERSHIPS_TRAINING_CODE: "T"
      }
    },

    VALIDATION_MODES: {
      APPLICATION_WIZARD: {
        WEB_NEW_APPLICATION_APPLICANT_INFORMATION: "Web.NewApplication.ApplicantInfo",
        WEB_NEW_APPLICATION_CONTACT_INFORMATION: "Web.NewApplication.ContactInfo",
        WEB_NEW_APPLICATION_OCCUPATIONAL_EDUCATION: "Web.NewApplication.OccupationalEducation",
        WEB_NEW_APPLICATION_OTHER_EDUCATION: "Web.NewApplication.OtherEducation",
        WEB_NEW_APPLICATION_PRACTICE_QUESTIONS: "Web.NewApplication.PracticeQuestions",
        WEB_NEW_APPLICATION_OTHER_LICENSES: "Web.NewApplication.OtherLicenses",
        WEB_NEW_APPLICATION_EXAMS: "Web.NewApplication.Exams",
        WEB_NEW_APPLICATION_PRACTICE_LOCATIONS: "Web.NewApplication.PracticeLocations",
        WEB_NEW_APPLICATION_SPECIALIZED_TRAININGS: "Web.NewApplication.SpecializedTrainings",
        WEB_NEW_APPLICATION_REVIEW: "Web.NewApplication.Review",
        WEB_NEW_APPLICATION_RESIDENCY_PROGRAM: "Web.NewApplication.ResidencyProgram"
      }
    },
    DOMAIN_IDS: {
      APPLICATION: 4,
      APPLICATION_COURSEWORK: 21,
    },
    WEB_SERVICE_PROCESS_TYPES: {
      APPLICATION: LicensureOnlineServiceProcessTypeKeys.Application
    },
    FUNCTION_TYPES: {
      APPLICATION: 1,
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), ApplicationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), ApplicationConstantsService.Mergers.VALIDATION_MODES);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), ApplicationConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), ApplicationConstantsService.Mergers.FEATURE_CONSTANTS);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), ApplicationConstantsService.Mergers.DOMAIN_IDS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), ApplicationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), ApplicationConstantsService.Mergers.FUNCTION_TYPES);
}

export interface IApplicationConstantsService extends ApplicationConstantsService { }
