import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { ICeSponsorshipTypeDto } from './licensure-shared-dto';

export class CeSponsorshipTypeDto extends LookupDto implements ICeSponsorshipTypeDto {
	Code: string;
	Hierarchy: number;
	FixedPrice: boolean;

}
