
// Framework
import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';

// Core
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@core/components/reach-dialog/reach-dialog-content.component';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

// Shared
import { IEntityAddressDtoHost } from 'src/app/coreShared/core-shared.module';
import { SystemSettingsManagerService } from 'src/app/core/services/system-settings-manager.service';
import { ReachScenarios } from 'src/app/core/index-constants';
import { registerDynamicComponent } from 'src/app/core/index-models';

@Component({
  selector: 'landing-page-entity-address-editor',
  templateUrl: './landing-page-entity-address-editor.component.html',
  styleUrls: ['./landing-page-entity-address-editor.component.scss']
})
export class LandingPageEntityAddressEditorComponent extends ReachDialogContentComponent<IEntityAddressDtoHost> implements OnInit, IReachDialogContentComponent {

  // FIELDS
  dataLoaded: boolean;
  enableNoBusinessAddressCheckbox: boolean;

  // PROPERTIES
  public get isNotInWorkforceHost() { return this.hostDialogData.initializationData.isNotInWorkforceHost; }

  // CTOR
  constructor(

    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    private systemSettingsManagerService: SystemSettingsManagerService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // Declarations.
    this.enableNoBusinessAddressCheckbox = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.ENTITY_ADDRESS_DIRECTIVE_BUSINESS_ADDRESS_REQUIRED_RULE_ENABLED);

    // Base.
    await super.ngOnInit();
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'LandingPageEntityAddressEditorComponent', LandingPageEntityAddressEditorComponent, 'landing-page-entity-address-editor');