import { Inject, Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';

import * as _l from 'lodash-es';
import * as _ from "underscore";

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { MessageSeverities } from '@coreModels/message-severities';
import { ReachMessageService } from './reach-message.service';
import { ReachMessage } from '@coreModels/reach-message';
import { ReachValidationError } from '@coreModels/reach-validation-error';
import { ValidationTooltipManagerService } from './validation-tooltip-manager.service';
import { ValidationFailureDto } from '@coreShared/core-shared.module';
import { Observable } from 'rxjs';
import { ReachMessageClickedEventArgs } from '../models/reach-message-clicked-event';

/**
 *  Data service that manages the handling of validation errors.
 *  -- Maps API validation errors to standard ReachMessage reporting format and publishes them to the ReachMessageService to be routed to the appropriate subscribers.
 *  -- Provides Get/Clear access to validation messages.
 *  -- Provides access to the errors behind validation messages.
 *  -- Show/Hide control of validation summary display.
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationManagerService {
  private asyncServerValidationErrorsPending: boolean = false;
  public validationScopeApplication: string;
  public validationScopeDialogDefault: string;
  public validationScopeToaster: string;
  public validationErrorTypeClient: number;
  public validationErrorTypeServer: number;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    public reachMessageService: ReachMessageService,
    private validationTooltipManagerService: ValidationTooltipManagerService
  ) {
    this.validationScopeApplication = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.validationScopeDialogDefault = this.constantsService.VALIDATION_ERROR_SCOPES.DIALOG;
    this.validationScopeToaster = this.constantsService.VALIDATION_ERROR_SCOPES.TOAST;
    this.validationErrorTypeClient = this.constantsService.VALIDATION_ERROR_TYPES.CLIENT;
    this.validationErrorTypeServer = this.constantsService.VALIDATION_ERROR_TYPES.SERVER;
  }

  public get validationMessageClicked$(): Observable<ReachMessageClickedEventArgs> {
    return this.reachMessageService.messageClicked$;
  }

  /**
   * Gets the ReachMessage array associated with the Application Validation key.
   * @returns the target message array.
   */
  public getApplicationValidationMessages(): ReachMessage[] {
    return this.getValidationMessages(this.validationScopeApplication);
  }

  /**
   * Gets the ReachMessage array associated with the specified Validation Scope Key.
   * @returns the target message array.
   */
  public getValidationMessages(key: string): ReachMessage[] {
    key = key ? key : this.validationScopeDialogDefault;
    return this.reachMessageService.get(key);
  }

  /**
   * Gets the error array associated with the Application Validation Scope Key.
   * @returns the target error array.
   */
  public getApplicationValidationErrors(): ReachValidationError[] {
    return this.getValidationErrors(this.validationScopeApplication);
  }

  /**
   * Gets the error array associated with the specified Validation Scope Key. The errors are
   * the original ValidtionFailureDtos, etc., hung off the ReachMessage.data property of the messages.
   * @returns the target error array.
   */
  public getValidationErrors(key: string): ReachValidationError[] {
    let errors: any[] = [];
    let msgs = this.getValidationMessages(key);
    if (msgs) {
      msgs.forEach(m => {
        if (m.data) {
          errors.push(m.data);
        }
      });
    }

    return errors;
  }

  /**
   * Returns the current cache of ReachMessages associated with the specified key and error type.
   * @param key the target Key for the messages.
   * @param errorType the target error type (client v server).
   */
  public getValidationMessagesByType(key: string, errorType: number): ReachMessage[] {
    let allMsgsForKey = this.getValidationMessages(key);
    let clientMsgs = _l.filter(allMsgsForKey,
      (m: ReachMessage) => {
        let errData = (m.data as ReachValidationError);
        let errType = errData ? errData.errorType : null;
        return errType === errorType;
      });
    return clientMsgs;
  }

  /**
   * Returns the current cache of ReachMessages associated with the specified key and NOT the specified error type.
   * @param key the target Key for the messages.
   * @param errorType the target error type (client v server).
   */
  public getValidationMessagesByNotType(key: string, errorType: number): ReachMessage[] {
    let allMsgsForKey = this.getValidationMessages(key);
    let clientMsgs = _l.filter(allMsgsForKey,
      (m: ReachMessage) => {
        let errData = (m.data as ReachValidationError);
        let errType = errData ? errData.errorType : null;
        return errType !== errorType;
      });
    return clientMsgs;
  }

  /**
   * Returns the client-side validation messages associated with the Application Scope Key.
   */
  public getClientApplicationValidationMessages(): ReachMessage[] {
    return this.getValidationMessagesByType(this.validationScopeApplication, this.validationErrorTypeClient);
  }

  /**
   * Returns the client-side validation messages associated with the Dialog Scope Key.
   */
  public getClientDialogValidationMessages(key: string): ReachMessage[] {
    return this.getValidationMessagesByType(key, this.validationErrorTypeClient);
  }

  /**
   * Returns the server-side validation messages associated with the Application Scope Key.
   */
  public getServerApplicationValidationMessages(): ReachMessage[] {
    return this.getValidationMessagesByType(this.validationScopeApplication, this.validationErrorTypeServer);
  }

  /**
   * Returns the server-side validation messages associated with the Dialog Scope Key.
   */
  public getServerDialogValidationMessages(key: string): ReachMessage[] {
    return this.getValidationMessagesByType(key, this.validationErrorTypeServer);
  }

  public getTooltipScopeMap(scopeKey: string) {
    return this.validationTooltipManagerService.getScopeMap(scopeKey);
  }

  /**
   * Returns the validation messages associated with the specified Scope Key and control.
   */
  public getValidationMessagesForControl(key: string, formControlName: string): ReachMessage[] {
    let scopeMessages = this.getValidationMessages(key);
    let messagesForControl = _l.filter(scopeMessages, (m: ReachMessage) => {
      let result = false;
      if (m && m.data && m.data.key === formControlName) {
        result = true;
      }

      return result;
    });

    return messagesForControl;
  }

  /**
 * Returns the validation messages associated with the specified Scope Key and control.
 */
  public getValidationTooltipForControl(key: string, formControlName: string): string {
    let tooltip = '';
    let messages = this.getValidationMessagesForControl(key, formControlName);
    if (messages && messages.length > 0) {
      messages.forEach((m: ReachMessage) => {
        tooltip += m.detail;
      });
    }

    return tooltip;
  }

  /**
   * Returns true if the current cache contains server-side validation error messages for Application scope.
   */
  public get hasServerApplicationValidationMessages(): boolean {
    let msgs = this.getServerApplicationValidationMessages();
    return (msgs && _.any(msgs)) || this.asyncServerValidationErrorsPending;
  }

  /**
   * Returns true if the current cache contains client-side validation error messages for Application scope.
   */
  public get hasClientApplicationValidationMessages(): boolean {
    let msgs = this.getClientApplicationValidationMessages();
    return msgs && _.any(msgs);
  }

  /**
   * Returns true if the current cache contains client-side validation error messages for Dialog scope.
   */
  public hasClientDialogValidationMessages(key: string): boolean {
    let msgs = this.getClientDialogValidationMessages(key);
    return msgs && _.any(msgs);
  }

  /**
   * Updates the client messages with the updated array. The key of the first message item in the array is used.
   * @param updatedClientMessages the updated array of client-side validation ReachMessage.
   */
  public updateClientMessages(updatedClientMessages: ReachMessage[], key: string = null) {
    if (!key && updatedClientMessages && updatedClientMessages.length > 0) {
      key = updatedClientMessages[0].key;
    }

    let existingNonClientMessages: ReachMessage[] =
      this.getValidationMessagesByNotType(key, this.validationErrorTypeClient);
    existingNonClientMessages = existingNonClientMessages ? existingNonClientMessages : [];
    let updatedMessages: ReachMessage[] = [...existingNonClientMessages, ...updatedClientMessages];
    this.reachMessageService.replaceMessages(key, updatedMessages);
  }

  /**
   * Updates the client messages with the updated array. The key of the first message item in the array is used.
   * @param updatedClientMessages the updated array of client-side validation ReachMessage.
   */
  public updateServerMessages(updatedServerMessages: ReachMessage[], key: string = null) {
    if (!key && updatedServerMessages && updatedServerMessages.length > 0) {
      key = updatedServerMessages[0].key;
    }

    let existingNonClientMessages: ReachMessage[] =
      this.getValidationMessagesByNotType(key, this.validationErrorTypeClient);
    existingNonClientMessages = existingNonClientMessages ? existingNonClientMessages : [];
    let updatedMessages: ReachMessage[] = [...existingNonClientMessages, ...updatedServerMessages];
    this.reachMessageService.replaceMessages(key, updatedMessages);
  }

  clearClientValidationMessagesForKey(key: string) {
    this.updateClientMessages([], key);
  }

  clearServerValidationMessagesForKey(key: string) {
    let existingNonServerMessages: ReachMessage[] =
      this.getValidationMessagesByNotType(key, this.validationErrorTypeServer);
    let updatedMessages: ReachMessage[] = [...existingNonServerMessages];
    this.reachMessageService.replaceMessages(key, updatedMessages);
  }

  /**
   * Clears the validation errors associated with the Application Validation Error Scope.
   */
  public clearApplicationValidationMessages(): void {
    this.clearValidationMessages(this.validationScopeApplication);
  }

  public clearApplicationServerValidationMessages(): void {
    this.clearServerValidationMessagesForKey(this.validationScopeApplication);
  }

  /**
   * Clears the validation errors associated with the specified Validation Scope Key.
   */
  public clearValidationMessages(key: string): void {
    this.reachMessageService.clear(key);
    this.validationTooltipManagerService.clearForScope(key);
  }

  /**
   * Clears the validation errors associated with the Application Validation Error Scope.
   */
  public clearApplicationValidationErrors(): void {
    this.clearValidationMessages(this.validationScopeApplication);
  }

  /**
   * Adds a set of ReachMessages to the pool mapped from the specified ValidationFailureDto.
   * @param error the ValidationFailureDto.
   * @param key the key with which to associate the messages.
   */
  public addValidationFailure(error: ValidationFailureDto, key: string = null) {
    let msgs = this.createValidationMessageSetFromFailureDto(error, key);
    this.reachMessageService.addAll(msgs);
    this.showValidationSummary(key);
  }

  /**
   * Adds a validation error for the specified data.
   * @param errorMessage the error text.
   * @param propertyName the name of the associated property.
   * @param key the key with which to associate the messages.
   */
  public addValidationFailureMessage(errorMessage: string, propertyName: string, key: string = null) {
    let failure = { PropertyName: propertyName, ErrorMessage: errorMessage, CustomState: null } as ValidationFailureDto;
    return this.addValidationFailure(failure, key);
  }

  /**
 * Adds a validation error for the specified data.
 * @param errorMessage the error text.
 * @param propertyName the name of the associated property.
 * @param key the key with which to associate the messages.
 */
  public addServerValidationFailureMessageAsync(errorMessage: string, propertyName: string, key: string = null) {
    let failures = [{ PropertyName: propertyName, ErrorMessage: errorMessage, CustomState: null }] as ValidationFailureDto[];
    return this.addServerValidationFailuresAsync(failures, key);
  }

  /**
   * Adds a set of ReachMessages to the pool mapped from the specified set of ValidationFailureDtos.
   * @param errors source ValidationFailureDtos.
   * @param key the key with which to associate the messages.
   */
  public addAllValidationFailures(errors: ValidationFailureDto[], key: string) {
    let msgs: ReachMessage[] = [];
    errors.forEach(err => msgs.push(...this.createValidationMessageSetFromFailureDto(err, key)));
    this.reachMessageService.addAll(msgs);
  }

  /**
 * Adds a set of server validation errors to the pool on a separate thread to avoid digest cycle issues.
 * @param error source ValidationFailureDtos.
 * @param key the key with which to associate the messages.
 */
  public addServerValidationFailuresAsync(errors: ValidationFailureDto[], key: string) {
    this.asyncServerValidationErrorsPending = true;
    setTimeout(() => {
      this.addAllValidationFailures(errors, key);
      this.asyncServerValidationErrorsPending = false;
      this.showValidationSummary(key);
    },
      1);
  }

  /**
   * Extracts all ValidationErrors from the specified Form, publishes the
   * errors to the ReachMessageService, and returns the resulting array
   * of ReachValidationErrors.
   * Usage:
   *  In the Component being validated, add the following to ngOnInit().
   *     this.form.valueChanges.subscribe(() => {
   *        this.errors = this.validationManagerService.addFormErrors(this.form);
   *      });
   * @param form the FormGroup or FormArray to be analyzed.
   * @param scopeKey the validation scope key with which the messages will be routed.
   * @param friendlyNames an object with properties representing the mapping from FormControl key to friendly name.
   * @returns the array of extracted ReachValidationError.
   */
  public addFormErrors(form: FormGroup | FormArray, scopeKey: string, friendlyNames: any = null): ReachValidationError[] {
    let errors: ReachValidationError[] = [];
    let messages: ReachMessage[] = [];
    this.validationTooltipManagerService.clearForScope(scopeKey);

    const mapErrToMessage = (ve: ReachValidationError): ReachMessage => {
      let msg = new ReachMessage(
        MessageSeverities.error,
        '',
        ve.detail,
        scopeKey,
        null,
        true,
        true,
        ve
      );

      msg.formControlName = ve.key ? ve.key : null;
      return msg;
    };

    /**
     * After all errors from the form have been aggregated, they are sent here.
     * @param controlErrors All form errors.
     * @param field
     */
    const mapControlErrors = (controlErrors, field: string = null) => {
      if (controlErrors !== null) {

        // Handle one or more errors coming in on from controlErrors.
        Object.keys(controlErrors).forEach(keyError => {
          let nextRuleName = keyError;
          let nextRuleValue = controlErrors;

          // Is the value part of an array?
          if (!isNaN(Number(keyError))) {
            nextRuleValue = controlErrors[Number(keyError)];
            nextRuleName = Object.keys(nextRuleValue)[0];
          }

          // Collect form errors from the reach validation error collection.
          let formErrors = ReachValidationError.fromValidationError(
            field,
            nextRuleName,
            nextRuleValue,
            friendlyNames
          );

          // Print a message for each form error.
          formErrors.forEach(element => {
            element.errorType = this.constantsService.VALIDATION_ERROR_TYPES.CLIENT;
            errors.push(element);
            let msg = mapErrToMessage(element);
            messages.push(msg);
            this.validationTooltipManagerService.addFromMessage(scopeKey, msg);
          })
        });
      }
    };

    const removeDuplicates = (errors: ReachValidationError[]) => {
      return errors.filter((error: ReachValidationError, index, self) => self.findIndex(t => {
        return t.key === error.key && t.ruleName === error.ruleName;
      }) === index);
    };

    /**
     * Facilitate presentation of all errors for a given form.
     * @param currentForm
     */
    const calculateErrors = (currentForm: FormGroup | FormArray, field: string = null) => {

      // Errors from the form itself.
      if (currentForm.errors) {
        mapControlErrors(currentForm.errors, field);
      }

      // Errors from controls embedded on this form.
      Object.keys(currentForm.controls).forEach(field => {
        const control = currentForm.get(field);
        if (control instanceof FormGroup || control instanceof FormArray) {
          errors = errors.concat(calculateErrors(control, field));
          return;
        }

        const controlErrors: ValidationErrors = control.errors;
        mapControlErrors(controlErrors, field);
      });

      errors = removeDuplicates(errors);
      return errors;
    };

    calculateErrors(form);

    if (!errors || errors.length === 0) {
      this.updateClientMessages([], scopeKey);
    } else {
      this.updateClientMessages(messages);
    }

    return errors;
  }

  /**
   * Enables display of the validation summary for the Application Scope.
   */
  public showApplicationValidationSummary() {
    this.showValidationSummary(this.validationScopeApplication);
  }

  /**
   * Hides the display of the validation summary for the Application Scope.
   */
  public hideApplicationValidationSummary() {
    this.hideValidationSummary(this.validationScopeApplication);
  }

  /**
   * Enables display of the validation summary for the specified Scope Key.
   */
  public showValidationSummary(key: string) {
    this.reachMessageService.setToggleState(key, true);
  }

  public popToastersForMessages(msgs: ReachMessage[]) {
    let toasterMsgs: ReachMessage[] = [];

    // Create toaster message
    let toasterLife = 3000;
    let toasterKey = this.validationScopeToaster;

    msgs.forEach(msg => {
      let toaster = new ReachMessage(MessageSeverities.error, 'Attention', msg.detail, toasterKey, toasterLife);
      toasterMsgs.push(toaster);
    });

    this.reachMessageService.addAll(toasterMsgs);
  }

  public popToastersForCurrentClientApplicationValidationMessages() {
    this.popToastersForMessages(this.getClientApplicationValidationMessages());
  }

  /**
   * Hides the display of the validation summary for the specified Scope Key.
   */
  public hideValidationSummary(key: string) {
    this.reachMessageService.setToggleState(key, false);
  }

  /**
   * Creates a set of message-summary and toaster messages from the specified ValidationFailureDto server-side validation error. The validation error message
   * is posted to they message summary instance specified by the key parameter and to the application toaster (for instant notification with timeout).
   * @param error the server-side ValidationFailureDto validation error.
   * @param validationSummaryKey the key that identifies the target component of the message-summary message (application, dialog, etc.). Defaults to
   * the application (AppComponent) instance of the validation control.
   */
  private createValidationMessageSetFromFailureDto(error: ValidationFailureDto, validationSummaryKey?: string): ReachMessage[] {
    let msgs: ReachMessage[] = [];

    // Create message-summary message
    validationSummaryKey = validationSummaryKey
      ? validationSummaryKey
      : this.validationScopeApplication;
    let errorData = ReachValidationError.fromValidationFailureDto(error);
    errorData.errorType = this.constantsService.VALIDATION_ERROR_TYPES.SERVER;
    let valdiationSummary = new ReachMessage(MessageSeverities.error, null, error.ErrorMessage, validationSummaryKey, undefined, true, true, errorData);
    msgs.push(valdiationSummary);

    // Create toaster message
    let toasterLife = 3000;
    let toasterKey = this.validationScopeToaster;
    let toaster = new ReachMessage(MessageSeverities.error, 'Attention', error.ErrorMessage, toasterKey, toasterLife);
    msgs.push(toaster);

    return msgs;
  }
}
