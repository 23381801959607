import { Injectable, Inject } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { from } from 'rxjs';
import * as _l from 'lodash-es';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  WizardService,
  WizardSearchCriteriaService,
  WizardTagsService
} from '@core/core.module';


@Injectable({
  providedIn: 'root'
})
export class LicenseVerificationWizardFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  public licenseVerificationConstantsService: ILicenseVerificationConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService
  ) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseVerification);
    this.licenseVerificationConstantsService = this.constantsService as ILicenseVerificationConstantsService;
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, LicenseVerificationConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, LicenseVerificationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseVerificationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, LicenseVerificationConstantsService.Mergers.FUNCTION_TYPES);
    this.licenseVerificationConstantsService = this.constantsService as ILicenseVerificationConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseVerificationWizard/license-verification-wizard.module').then(x => x.LicenseVerificationWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseVerificationWizard/license-verification-wizard.module').then(m => m.LicenseVerificationWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public getWizard(webServiceHistoryId: number, processId: number) {
    let functionType = this.functionTypeService.create(this.licenseVerificationConstantsService.FUNCTION_TYPES.VERIFICATION, null);
    let wizardTags = this.wizardTagsService.create();
    wizardTags.processId = processId;
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.licenseVerificationConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_VERIFICATION, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();

      if (!webServiceHistoryId) {
        wizard.onlineService.IsHistoryTracked = false;
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else return wizard;
    };

    // Return as observable.
    return from(initializeWizard());
  };

}

/**
 * SecondLicenseCard setting constants to merge into ConstantsService.
 */
export class LicenseVerificationConstantsService extends ConstantsService {

  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      LICENSE_VERIFICATION_WIZARD: "License.Verification.Wizard",
    },

    FEATURE_CONSTANTS: {
      STATUS_CHANGE_APPLICATION_STATUS_CONSTANTS: {
        NEW: 1,
        IN_PROCESS: 2,
        APPROVED: 3,
        DENIED: 4
      }
    },

    WEB_SERVICE_PROCESS_TYPES: {
      LICENSE_VERIFICATION: LicensureOnlineServiceProcessTypeKeys.LicenseVerification
    },

    FUNCTION_TYPES: {
      VERIFICATION: 16,
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    LicenseVerificationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
    LicenseVerificationConstantsService.Mergers.FEATURE_CONSTANTS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    LicenseVerificationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES),
    LicenseVerificationConstantsService.Mergers.FUNCTION_TYPES);
}

export interface ILicenseVerificationConstantsService extends LicenseVerificationConstantsService {
}
