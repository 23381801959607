import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


import * as _l from 'lodash-es';
import * as _ from 'underscore';

import { LandingComponentKeys, LoginComponentKeys } from '@core/index-constants';
import { AppConfigService } from '@coreServices/app-config.service';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { HelpMenuService } from '@coreServices/help-menu.service';
import { MenuService } from '@coreServices/menu.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';

import { RouteInfoRegistry } from '@core/index-models';
import { Command, NavigationMenuCommand } from '@coreModels/command';
import { DynamicContentChangedEventArgument } from '@coreModels/eventArgs/dynamic-content-changed-event-argument';
import { ReachMenuGroup } from '@coreModels/reach-menu-group';
import { ReachMenuItem } from '@coreModels/reach-menu-item';

@Component({
  selector: 'reach-navbar',
  templateUrl: './reach-navbar.component.html',
  styleUrls: ['./reach-navbar.component.scss']
})
export class ReachNavbarComponent implements OnInit {
  private subscriptionLogin: Subscription;
  private subscriptionLogout: Subscription;
  private applicationInitialized: Subscription;
  private dynamicContentChangedSubscription: Subscription;

  // Verona
  menuSourceSubscription: Subscription;
  menuResetSubscription: Subscription;
  key: string;
  active: boolean = false;

  readonly systemMenuGroupName: string = "SystemMenuGroup";
  menuItems: ReachMenuItem[] = [];
  selectedmenuItem: ReachMenuItem;

  private isAppInit: boolean = false;
  public brand;
  public SYSTEM_SETTING_KEYS;
  public validationSummaryScope: string;
  public notificationSummaryScope: string;

  constructor(
    private appConfigService: AppConfigService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicContentManagerService: DynamicContentManagerService,
    private helpMenuSrvice: HelpMenuService,
    private menuService: MenuService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private reachApplicationService: ReachApplicationService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private userManagerService: UserManagerService
  ) {
    this.subscriptionLogin = this.userManagerService.login$
      .subscribe(currentPrincipal => this.onUserLogin(currentPrincipal));
    this.subscriptionLogout = this.userManagerService.logout$
      .subscribe(currentPrincipal => this.onUserLogout());
    this.applicationInitialized = this.reachApplicationService.applicationInitialized$
      .subscribe(args => this.onApplicationInitialized(args));
    this.dynamicContentChangedSubscription = this.dynamicContentManagerService.contenthanged$.subscribe(
      (changedEventArgument: DynamicContentChangedEventArgument) => this.onDynamicContentChanged(changedEventArgument));

    this.validationSummaryScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.notificationSummaryScope = this.constantsService.NOTIFICATION_SCOPES.APPLICATION;

    // Verona
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
    });
    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });
  }

  //private _isPanelMenuVisible = false;

  ngOnInit(): void {
    // Note: Rebuild menu on init, app initialized, login and logout so the proper menu items are included in dynamic
    // menus such as the Online Services menu.
    this.buildMenu();

    this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
  }

  ngOnDestroy(): void {
    this.subscriptionLogin.unsubscribe();
    this.subscriptionLogout.unsubscribe();
    this.applicationInitialized.unsubscribe();
    this.dynamicContentChangedSubscription.unsubscribe();
  }

  public get menuActive() {
    return this.reachApplicationService.menuActive;
  }

  /**
 * Handles the "userManager.login" event.
 * @param event
 * @param argument
 */
  private onUserLogin(currentPrincipal) {
    this.buildMenu();
  }

  /**
   * Handles the "userManager.logout" event.
   * @param event
   * @param argument
   */
  private onUserLogout() {
    this.buildMenu();
  };

  /**
 * Handler for the "bootstrapper.applicationInitialized" event.
 * @param the event args.
 */
  private onApplicationInitialized(isInitialized: boolean) {
    this.isAppInit = isInitialized;
    if (isInitialized) {
      this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
    }

    this.buildMenu();
  }

  private onDynamicContentChanged(args: DynamicContentChangedEventArgument) {
    this.buildMenu();
  }

  private buildMenu() {
    // Initialize menu array
    this.menuItems = [];

    // Login
    this.menuItems.push(new ReachMenuItem({ commandModel: this.loginCommand, isInvisibleWhenDisabled: true }));

    // Home
    this.menuItems.push(new ReachMenuItem({
      commandModel: this.homeCommand,
      icon: "fa fa-home",
      isInvisibleWhenDisabled: true
    }));

    // Online Services
    let onlineServicesMenu = new ReachMenuItem({
      commandModel: this.onlineServicesCommand,
      icon: "fa fa-link",
      isInvisibleWhenDisabled: true
    });
    onlineServicesMenu.items = this.buildOnlineServiceMenu();
    this.menuItems.push(onlineServicesMenu);

    // Help
    let helpMenu = new ReachMenuItem({
      commandModel: this.helpMenuCommand,
      icon: "fa fa-question-circle",
      isInvisibleWhenDisabled: true
    });
    helpMenu.items = this.helpMenuSrvice.buildMenu(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.SHARED_CONTENT, "Navbar.Help");
    this.menuItems.push(helpMenu);

    // Settings
    let settingsMenu = new ReachMenuItem({
      commandModel: this.settingsMenuCommand,
      icon: "fa fa-gear"
    });
    settingsMenu.items = this.buildSettingsMenu();
    this.menuItems.push(settingsMenu);

    // Cart
    // TODO
  }

  private buildOnlineServiceMenu() {

    var menuGroups = [];

    // Menu group that the menu manages
    // Create the System Menu Group and add a 'services unavailable' menu option to be displayed if there are no other options
    var systemMenuGroup = new ReachMenuGroup(this.systemMenuGroupName);
    var servicesUnavailableCommand = this.createServicesUnavailableCommand();
    systemMenuGroup.addMenuItem(servicesUnavailableCommand);
    menuGroups.push(systemMenuGroup);

    // Get the set of prepared menu commands. Sort commands first by group order, then group by menu group name
    var menuItemGroups = _.groupBy(_.sortBy(this.onlineServiceMenuService.getCommands(),
      (command: NavigationMenuCommand) => {
        return command.menuGroupOrder;
      }),
      (command: NavigationMenuCommand) => {
        return command.menuGroupName;
      });

    // Here we take each group of menu items and flatten them in to menuGroup objects. Each menuGroup object has a collection of menu items. The first item in each group is the menu header.
    // This structure makes binding the menu easier to deal with.
    _.map(_.keys(menuItemGroups),
      (groupKey) => {

        // Create a new menuGroup for each group key
        var menuGroup = new ReachMenuGroup(groupKey);
        var headerItem = menuGroup.items[0];

        // For each item in each group, we add the menu items with commands
        _.map(menuItemGroups[groupKey],
          (item) => {
            menuGroup.addMenuItem(item);
          });

        headerItem.hasActiveChildren = () => {
          let firstActiveChild = _.find(menuGroup.items,
            (item: ReachMenuItem) => {
              return !item.isHeader && item.isEnabled;
            });

          return firstActiveChild ? true : false;
        }

        // add the group
        menuGroups.push(menuGroup);
      });

    return _l.flatten(_.pluck(menuGroups, "items"));
  }

  private buildHelpMenu() {
    // TODO: Consider creating a service to build help menus from majorKey/minorKey since they are needed
    // also on wizard pages and perhaps elsewhere.
  }

  private buildSettingsMenu() {
    let settingsMenu = [];
    // Change Security Questions
    settingsMenu.push(new ReachMenuItem({ commandModel: this.changeSecurityQuestionsCommand, isInvisibleWhenDisabled: true }));
    // Change Password
    settingsMenu.push(new ReachMenuItem({ commandModel: this.passwordChangeCommand, isInvisibleWhenDisabled: true }));
    // Separator
    settingsMenu.push({ separator: true });
    // Log In
    settingsMenu.push(new ReachMenuItem({ commandModel: this.loginCommand, isInvisibleWhenDisabled: true }));
    // Log Out
    settingsMenu.push(new ReachMenuItem({ commandModel: this.logoutCommand, isInvisibleWhenDisabled: true }));

    return settingsMenu;
  }

  public get hasActiveOnlineServiceMenuItems(): boolean {
    let onlineServicesMenu = _.find(this.menuItems,
      (menuItem, ReachMenuItem) => {
        return menuItem.commandModel === this.onlineServicesCommand;
      });

    if (onlineServicesMenu) {
      let firstFoundActiveItem = _.find(onlineServicesMenu.items,
        (menuItem: ReachMenuItem) => {
          return this.systemMenuGroupName !== menuItem.menuGroupName &&
            !menuItem.isHeader &&
            menuItem.isEnabled;
        });

      return firstFoundActiveItem ? true : false;
    }

    return false;
  }

  private createServicesUnavailableCommand(): Command {
    let servicesUnavailableCommand = new Command(
      this.userManagerService.getCurrentPrincipal(),
      () => {
        return !this.hasActiveOnlineServiceMenuItems; // canExecute is true if the current set of menu items has no active items.
      },
      () => {
        // Execution function for this command is noop
      },
      "Services not available");

    return servicesUnavailableCommand;
  }

  // Home Command - return to landing page if logged in; otherwise to the login page
  homeCommand = this.commandService.createButtonCommand(
    () => {
      return this.userManagerService.getCurrentPrincipal() != null;
    },
    () => {
      this.menuService.reset();

      // Navigate to landing page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
    },
    "Home");

  helpMenuCommand = this.commandService.createButtonCommand(
    () => {
      return true;
    },
    () => {
      return;
    },
    "Help");

  settingsMenuCommand = this.commandService.createButtonCommand(
    () => {
      return true;
    },
    () => {
      return;
    },
    "Settings");

  onlineServicesCommand = this.commandService.createButtonCommand(
    () => {
      return true;
    },
    () => {
      return;
    },
    "Online Services");

  // Login Command
  loginCommand = this.commandService.createButtonCommand(
    () => {
      return !this.userManagerService.getCurrentPrincipal();
    },
    () => {
      this.menuService.reset();

      // Navigate to login page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    },
    "Log in"
  );

  // Logout Command
  logoutCommand = this.commandService.createButtonCommand(
    () => { return this.userManagerService.getCurrentPrincipal() != null; },
    () => {
      this.menuService.reset();

      // Logout
      this.userManagerService.logout();

      // Navigate to login page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    },
    "Log out"
  );

  passwordChangeCommand = this.commandService.createButtonCommand(
    () => {
      return this.userManagerService.getCurrentPrincipal() != null;
    },
    () => {
      this.menuService.reset();

      // Navigate to pw page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Password).path]);
    },
    "Change your password");

  changeSecurityQuestionsCommand = this.commandService.createButtonCommand(
    () => {
      return this.userManagerService.getCurrentPrincipal() != null;
    },
    () => {
      this.menuService.reset();

      // Navigate to pw page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.SecurityQuestions).path]);
    },
    "Change your security questions");

  // Verona
  onMenuClick() {
    this.menuService.raiseNavMenuClick();
  }
}
