<!-- Question Form -->
<div class="p-fluid p-formgrid p-grid p-field" *ngIf="formReady && questionForm.enabled" [formGroup]="questionForm">

    <!-- Question Number -->
    <div class="p-col-fixed">
        <strong>{{model.Question.QuestionNumber}}</strong>
    </div>

    <div class="p-col p-grid p-formgrid">

        <!-- Question  -->
        <div class="p-col-12 p-sm-8 p-md-9 p-xl-10 p-field">
            <div [innerHTML]="model.Question.Question"></div>
        </div>

        <!-- Answer -->
        <div class="p-col-12 p-sm-4 p-md-3 p-xl-2 p-field">
            <p-dropdown *ngIf="dataLoaded" [formControlName]="answerControlName" [options]="filteredAnswers"
                appendTo="body" optionLabel="Description" dataKey="Id" [showClear]="false" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Response -->
        <div class="p-col-12 p-field" *ngIf="requireResponse">
            <label for="float-input">{{model.Question.ResponseTitle ? model.Question.ResponseTitle : "Explain"}}</label><br />
            <textarea id="float-input" [formControlName]="responseControlName" [rows]="5" style="width:100%" pInputTextarea autoResize="autoResize"></textarea>
        </div>

    </div>
</div>