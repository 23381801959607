import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, SystemSettingsManagerService, UtilitiesService, ValidationManagerService, WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from '@core/core.module';
import { of } from 'rxjs';
import { CvaHostWizardStepComponent } from 'src/app/core/components/wizard/wizard-step.component';
import { LicensureScenarios } from 'src/app/licensureCore/constants/licensure-scenarios';
import { EntityEmploymentService } from 'src/app/licensureCore/licensure-core.module';
import { IEntityEmploymentListDto } from 'src/app/licensureShared/licensure-shared.module';
import { EmploymentListConfiguration } from '../../employmentList/model/employment-list-configuration';
import { EmploymentListModel } from '../../employmentList/model/employment-list-model';

export const EmploymentComponentSelector = "licensure-employment";
export const EmploymentComponentKey = "Employment";

@Component({
  selector: EmploymentComponentSelector,
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent extends CvaHostWizardStepComponent implements OnInit {
  public employmentListModel: EmploymentListModel;
  employmentListConfiguration: EmploymentListConfiguration;

  constructor(checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    changeDetectorRef: ChangeDetectorRef,
    protected systemSettingsManagerService: SystemSettingsManagerService,
    protected formBuilder: FormBuilder,
    protected entityEmploymentService: EntityEmploymentService,
    protected utilitiesService: UtilitiesService) {

    super(constantsService, validationManagerService, wizardInjector, EmploymentComponent.name, checkboxContentService, elementRef, changeDetectorRef);
    this.modelToForm();
  }

  ngOnInit(): void {
    this.createEmploymentListModel();
    this.createEmploymentListConfiguration()
    super.ngOnInit();
  }

  protected createEmploymentListModel(): void {
    this.employmentListModel = new EmploymentListModel();
    this.employmentListModel.entityId = this.wizard.model.entity.Id;
    this.employmentListModel.licenseId = this.wizard.model?.license?.Id;
    this.employmentListModel.licenseTypeId = this.wizard.model?.license?.LicenseTypeId;
    this.employmentListModel.entityEmploymentList = this.wizard.model.entityEmployment;
  }

  protected createEmploymentListConfiguration(): void {
    this.employmentListConfiguration = new EmploymentListConfiguration(this.systemSettingsManagerService, this.constantsService);
    this.employmentListConfiguration.save = (employmentList: IEntityEmploymentListDto) => { return of(employmentList); } // Replace the save method with a mock, the the wizard handle the save.
  }

}

registerDynamicComponent(LicensureScenarios.ProfessionalProfileWizard, EmploymentComponentKey, EmploymentComponent, EmploymentComponentSelector);
