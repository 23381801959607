import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { ReachScenarios } from 'src/app/core/index-constants';
import { AnonymousGuard } from 'src/app/core/index-directives';
import { OnlineServiceRegistry, RouteInfoRegistry } from 'src/app/core/index-models';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService, OnlineServiceLinkManagerService, OnlineServiceMenuService, SystemSettingsManagerService } from 'src/app/core/index-services';
import { WebLinkInfoDto } from 'src/app/coreShared';
import { LicensureOnlineServiceProcessTypeKeys, SearchComponentKeys } from '../../licensure-core.module';

@Injectable({
  providedIn: 'root'
})
export class CourseSearchFeatureConfigurationService {

  private isInitialized = false;
  private courseSearchConstantsService: ICourseSearchConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private dynamicRoutesService: DynamicRoutesService,
    private onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) { }

  public initialize() {
    if (this.isInitialized) return;

    this.courseSearchConstantsService = this.constantsService as ICourseSearchConstantsService;
    this.configureConstants();
    this.configureDecorators();
    this.configureRoutes();
    this.isInitialized = true;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CourseSearch).onlineServicePath,
        canActivate: [AnonymousGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.CourseSearch, SearchComponentKeys.CourseLazy)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, CourseSearchConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, CourseSearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.ONLINE_SERVICE_SORT, CourseSearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, CourseSearchConstantsService.Mergers.FEATURE_CONSTANTS);
  }

  private configureDecorators() {
    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Search for a CE Course";
    linkInfo.DisplayOrder = this.systemSettingsManagerService.asInt(this.courseSearchConstantsService.SYSTEM_SETTING_KEYS.COURSE_SEARCH.ONLINE_SERVICE_MENU_GROUP_ORDER);
    linkInfo.ProcessTypeId = LicensureOnlineServiceProcessTypeKeys.CourseSearch;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false);

    this.onlineServiceMenuService.addOnlineServiceMenuItemFromOnlineLink(
      onlineLink,
      this.courseSearchConstantsService.SYSTEM_SETTING_KEYS.COURSE_SEARCH.ONLINE_SERVICE_MENU_GROUP,
      this.courseSearchConstantsService.SYSTEM_SETTING_KEYS.COURSE_SEARCH.ONLINE_SERVICE_MENU_GROUP_ORDER,
      this.courseSearchConstantsService.SYSTEM_SETTING_KEYS.COURSE_SEARCH.ENABLED,
      true
    );
  }
}

export interface ICourseSearchConstantsService extends CourseSearchConstantsService {
}
export class CourseSearchConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      CE_COURSE_SEARCH: {
        MAJOR_KEY: "CourseSearch"
      }
    },

    SYSTEM_SETTING_KEYS: {
      COURSE_SEARCH: {
        ONLINE_SERVICE_MENU_GROUP: "Feature.CourseSearch.OnlineServiceMenu.Group",
        ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.CourseSearch.OnlineServiceMenu.GroupOrder",
        CRITERIA_COMPONENT_NAME: "Feature.Online.CourseSearch.Criteria.ComponentName",
        ENABLED: "Feature.Online.CourseSearch.Enabled",
        SEARCH_RESULTS_COMPONENT_NAME: "Feature.Online.CourseSearch.Results.ComponentName",
        RESULTS_DETAIL_ITEMS_COMPONENT_NAMES: "Feature.Online.CourseSearch.Results.Detail.Items.ComponentNames",
      }
    },
    FEATURE_CONSTANTS: {
      CE_COURSE_SEARCH: {
        COURSE_STATUSES: {
          APPROVED: 3
        }
      }
    },
    ONLINE_SERVICE_SORT: {
      CE_COURSE_SEARCH: 2001
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), CourseSearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), CourseSearchConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  ONLINE_SERVICE_SORT = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).ONLINE_SERVICE_SORT), CourseSearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), CourseSearchConstantsService.Mergers.FEATURE_CONSTANTS);
}
