import { } from "@angular/core";
import { noop, Observable } from "rxjs";
import { IInformationFileDto } from "src/app/coreShared/core-shared.module";
import { Principal } from './principal';
import { ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";

export type CanExecuteFunction = (param?: any) => boolean;
export type ExecuteFunction = (param?: any) => Observable<any>;
export class Command {
  public execute: Function;
  public canExecute: CanExecuteFunction;
  public description: string = "";

  constructor(private currentPrincipal: Principal
    , canExecuteFunction: CanExecuteFunction
    , executeFunction: Function
    , public commandText: string
    , public commandBlocksInvalid: boolean = false
    , public holdsEnabled: boolean = false
    , public routeUrl: string = null) {

    this.canExecute = canExecuteFunction;
    this.execute = executeFunction || noop;
  }

  public commandBlocksWhenHolds() {

    // If holds are enabled, check for holds and return false if there are any
    const originalCanExecute = this.canExecute;
    if (this.holdsEnabled) {

      this.canExecute = (param: any): boolean => {

        //Check for holds and return false if there are any
        if (this.currentPrincipal != null && this.currentPrincipal.user.UserAccount.profile.HasInformationFileHolds) {
          return false;
        }

        // If there are no holds, check the original canExecute function
        if (originalCanExecute) {
          return originalCanExecute(param);
        } else {
          return true;
        }
      }
    }

    return this;
  }

  public get enabled(): boolean {
    let isEnabled = this.canExecute && this.canExecute();
    return isEnabled;
  }
}

export class ButtonCommand extends Command {
  constructor(currentPrincipal: Principal,
    canExecuteFunction: CanExecuteFunction,
    executeFunction: Function,
    commandText: string,
    commandBlocksInvalid: boolean = false,
    holdsEnabled: boolean = false,
    routeUrl: string = null) {
    super(currentPrincipal,
      canExecuteFunction,
      executeFunction,
      commandText,
      commandBlocksInvalid,
      holdsEnabled,
      routeUrl
    );
  }

  public get enabled(): boolean {
    let isEnabled = this.canExecute && this.canExecute();
    isEnabled = isEnabled && (this.canExecute !== noop);
    return isEnabled;
  }
}

export class NavigationMenuCommand extends Command {
  constructor(currentPrincipal: Principal,
    public canExecuteFunction: CanExecuteFunction,
    public executeFunction: Function,
    public commandText: string,
    public commandBlocksInvalid: boolean = false,
    public holdsEnabled: boolean = false,
    public routeUrl: string = null,
    public menuGroupName: string = "Other",
    public menuGroupOrder: number = 100,
    public featureEnabledSystemSettingKey: string = "false") {
    super(currentPrincipal,
      canExecuteFunction,
      executeFunction,
      commandText,
      commandBlocksInvalid,
      holdsEnabled,
      routeUrl);
  }
}
