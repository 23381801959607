import { Pipe, PipeTransform } from '@angular/core';

import * as _l from 'lodash-es';

/**
 * Pipe transforms a Address object into a formatted city-state string.
 */
@Pipe({
    name: 'mailingAddress'
})
export class MailingAddressPipe {
  /**
   * Transforms the Address list object passed in as the value param into a formatted address string.
   * @param value
   */
    transform(addresses): string {
    var mailingAddress = "";
    if (addresses) {
        let mailingAddressObject = _l.first(_l.filter(addresses, (item) => {
          return item.IsMailing === true;
        }));
      if (mailingAddressObject) {
          mailingAddress = mailingAddressObject.AddressFull;
      }
    }
    return mailingAddress;
  }
}
