// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';

import { OnlineServiceRegistry } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { OnlineServiceLinkManagerService } from '@coreServices/online-service-link-manager.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { UserManagerService } from '@coreServices/user-manager.service';

import { WebLinkInfoDto } from '@coreShared/core-shared.module';
import { SystemSettingsManagerService } from "../system-settings-manager.service";

/**
 * Service that supports the Forgot Username/Password Wizard.
 */
@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordFeatureConfigurationService {
  private isInitialized = false;
  protected forgotPasswordConstantsService: IForgotPasswordConstantsService;
  public wizardPageConfiguration = {
    registrationVerifyUser: {
      domainIdentifiersDirectiveConfiguration: null,
      domainIdentifierFilterFunction: (licenseTypeId, domainIdentifiers) => {
        return [];
      }
    }
  };

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected router: Router,
    protected userManagerService: UserManagerService,
    protected systemSettingsManagerService: SystemSettingsManagerService
  ) {
  }

  public initialize() {
    if (!this.isInitialized) {
      // configure constants
      this.configureConstants();

      // Decorate
      this.configureDecorators();

      // Routes
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
 * Decorate the ConstantsService with UserRegistration-specific constants.
 */
  protected configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      ForgotPasswordConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS,
      ForgotPasswordConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS,
      ForgotPasswordConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      ForgotPasswordConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.forgotPasswordConstantsService = this.constantsService as IForgotPasswordConstantsService;
  }

  protected configureDecorators() {    
    let canExecute = () => {
      return this.userManagerService.getCurrentPrincipal() == null &&
      this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.LOGIN.ENABLED) == true;
    };

    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Forgot Username/Password";
    linkInfo.DisplayOrder = this.constantsService.ONLINE_SERVICE_SORT.FORGOT_PW;
    linkInfo.ProcessTypeId = OnlineServiceProcessTypeKeys.ForgotPassword;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false);
    onlineLink.canExecute = canExecute;
  }

  /**
   * Configure dynamic ForgotPassword routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  protected configureRoutes() {

    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.ForgotPassword).selectorPath,
        loadChildren: () => import('src/app/core/features/forgotPasswordWizard/forgot-password-wizard-selection.module')
          .then(m => m.ForgotPasswordWizardSelectionModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.ForgotPassword).onlineServicePathTemplate,
        loadChildren: () => import('src/app/core/features/forgotPasswordWizard/forgot-password-wizard.module')
          .then(m => m.ForgotPasswordWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class ForgotPasswordConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      FORGOT_PASSWORD_WIZARD_FEATURE: {
        FORGOT_PASSWORD: "ForgotPassword.Wizard",
        FORGOT_PASSWORD_WIZARD_SELECTOR: 'ForgotPassword.Wizard.Selector',
      }
    },

    SYSTEM_SETTING_KEYS: {
      FORGOT_PASSWORD_WIZARD_FEATURE_SYSTEM_SETTING_KEYS: {
        REGISTRATION_CODE_ENABLED: "Feature.ForgotPassword.RegistrationCode.Enabled"
      }
    },

    FEATURE_CONSTANTS: {
      FORGOT_PASSWORD_WIZARD_FEATURE: {
        CREDENTIAL_TYPES: {
          REGISTRATION_CODE: { NAME: "RegistrationCode", PROCESS: 110 }
        }
      }
    },

    VALIDATION_MODES: {
      FORGOT_PASSWORD: {
        WEB_PASSWORD_RESET: "Web.ResetPassword"
      }
    },

    WEB_SERVICE_PROCESS_TYPES: {
      FORGOT_PASSWORD_CARD: OnlineServiceProcessTypeKeys.ForgotPassword
    },
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    ForgotPasswordConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
    ForgotPasswordConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
    ForgotPasswordConstantsService.Mergers.FEATURE_CONSTANTS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES),
    ForgotPasswordConstantsService.Mergers.VALIDATION_MODES);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    ForgotPasswordConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface IForgotPasswordConstantsService extends ForgotPasswordConstantsService {
}
