<div *ngIf="Config.isVisible()">

  <page-summary-text majorKey="Landing" minorKey="Employment.SummaryTextBlock"></page-summary-text>

  <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="filteredEmploymentItems"
    [responsive]="true" responsiveLayout="stack">
  
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <span class="p-text-left reach-list-header">Employment</span>
        <div>
          <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="createCommand"
            tool-tip="Add a new item" *ngIf="!Config.isReadOnly"></button>
        </div>
      </div>
    </ng-template>
  
    <ng-template pTemplate="header">
      <tr>
  
        <th *ngIf="employeeAsLicenseIdEnabled">License</th>
        <th>Employer</th>
        <th *ngIf="employerAsFacilityLicenseIdEnabled">HFID</th>
        <th *ngIf="Config.employmentTypeEnabled">Type</th>
        <th *ngIf="Config.isPositionColumnVisible">Position</th>
        <th *ngIf="Config.isAddressColumnVisible">Address</th>
        <th>Start</th>
        <th>End</th>
        <th style="width: 8em;" *ngIf="!Config.isReadOnly"></th>
  
      </tr>
    </ng-template>
  
    <ng-template pTemplate="body" let-item>
      <tr>
  
        <!-- Individual License -->
        <td *ngIf="employeeAsLicenseIdEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">License</div>
            <div>
              {{item.IndividualLicenseTypeCode | whenEmpty: '-'}} {{item.IndividualLicenseNumber}}</div>
          </div>
        </td>
  
        <!-- Employer -->
        <td>
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Employer</div>
            <div>{{item.EmployerName | whenEmpty: '-'}}</div>
          </div>
        </td>
  
        <!-- Employer -->
        <td *ngIf="employerAsFacilityLicenseIdEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">HFID</div>
            <div>
              {{item.EmployerLicenseNumber}}
            </div>
          </div>
        </td>
  
        <!-- Employment Type -->
        <td *ngIf="Config.employmentTypeEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Type</div>
            <div>{{item?.EmploymentType?.Description | whenEmpty: '-'}}</div>
          </div>
        </td>
  
        <!-- PositionTitleType -->
        <td *ngIf="Config.isPositionColumnVisible && Config.positionPickListEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Position</div>
            <div>{{item?.EmploymentPositionTitle?.Description | whenEmpty: '-'}}</div>
          </div>
        </td>
  
        <!-- PositionTitle -->
        <td *ngIf="Config.isPositionColumnVisible && !Config.positionPickListEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Position</div>
            <div>{{item.PositionTitle | whenEmpty: '-'}}</div>
          </div>
        </td>
  
        <!-- Address -->
        <td *ngIf="Config.isAddressColumnVisible">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Address</div>
            <span [innerHtml]="item.Addresses[0] | displayAddress | whenEmpty: '-'"></span>
          </div>
        </td>
  
        <!-- Dates -->
        <td><span class="p-column-title">Start</span>{{item.BeginDate | date: 'MM/dd/yyyy' | whenEmpty: '-'}}</td>
        <td><span class="p-column-title">End</span>{{item.EndDate | date: 'MM/dd/yyyy' | whenEmpty: '-'}}</td>
  
        <!-- Buttons -->
        <td class="p-text-right" *ngIf="!Config.isReadOnly">
  
          <!-- Edit -->
          <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="presentItemEditorCommand"
            [command-parameter]="item" tool-tip="Edit this item"></button>
  
          <!-- Delete -->
          <button *ngIf="RemoveButtonVisible" pButton pRipple class="p-button-text" icon="fa fa-trash-o"
            [command-button]="removeItemCommand" [command-parameter]="item" tool-tip="Delete this item"></button>
        </td>
  
      </tr>
  
    </ng-template>
  
    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="ColumnCount">
          <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
        </td>
      </tr>
    </ng-template>
  
  </p-table>
  
  <div class="p-mt-4 p-mb-2" [formGroup]="contentForm">
    <p-checkbox inputId="includeHistoric" formControlName="IncludeHistoric" label="Include Historic" [binary]="true"></p-checkbox>
  </div>

</div>

<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
