import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Router, Routes } from '@angular/router';

import * as _l from 'lodash-es';

import {
  AuthGuard,
  CheckboxContent,
  CheckboxContentService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  WizardService,
  WizardSearchCriteriaService,
  WizardTagsService
} from '@core/core.module';
import { ILicenseDto } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';

@Injectable({
  providedIn: 'root'
})
export class LicenseRenewalIlcFeatureConfigurationService {
  public readonly featureRoot: string = null;
  public readonly directiveConfiguration;
  public readonly wizardPageConfiguration;
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private licenseRenewalIlcConstantsService: ILicenseRenewalIlcConstantsService;

  constructor(
    private checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private dynamicRoutesService: DynamicRoutesService,
    private functionTypeService: FunctionTypeService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
  ) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseRenewalIlc);
    this.featureRoot = this.defaultProviderConfigurationService.applicationRootUri + "/Client/scripts/features/licenseRenewalIlcWizard";

    this.wizardPageConfiguration = {
      licenseRenewalIlcContactInfo: {
        domainIdentifierFilterFunction: (licenseTypeId, domainIdentifiers) => {
          return [];
        }
      },
      licenseRenewalIlcPmpRegistration: {
        // Provider function used to map our license type to the required PMP license type format.
        mapToPmpLicenseType: (licenseType) => {
          throw "Not Implemented: Provider must supply this function.";
        }
      },
      licenseRenewalIlcReview: {
        getCheckboxContentModels: (license, dynamicContentConfiguration): CheckboxContent[] => {
          var checkboxContents: CheckboxContent[] = [];
          checkboxContents.push(this.checkboxContentService.create(dynamicContentConfiguration.majorKey, "LicenseRenewalIlcReview.StatutesRead", "General Affidavits"));
          checkboxContents.push(this.checkboxContentService.create(dynamicContentConfiguration.majorKey, "LicenseRenewalIlcReview.InformationCorrect", "Cooperation Affidavits"));
          return checkboxContents;
        }
      }
    }
  }

  public isLicenseInactive(license: ILicenseDto) {
    if (!license) {
      return false;
    }

    return license.Status.Id === "LI";
  }

  public getWizard = (activeRenewalId: number, licenseTypeId: string, webServiceHistoryId: number) => {

    let functionType = this.functionTypeService.create(this.licenseRenewalIlcConstantsService.FUNCTION_TYPES.RENEWAL, activeRenewalId);
    let wizardTags = this.wizardTagsService.create(licenseTypeId, null, null, null, null, null, null);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.licenseRenewalIlcConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_ILC, wizardTags);

    // Get the wizard instance and start it if required.
    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria, functionType).toPromise();
      if (!wizard.onlineServiceHistory.Id) {
        // Ask the wizard manager to start the wizard (supply your function type please)
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, licenseTypeId).toPromise();
        return startedWizard;
      }
      else return wizard;
    };

    return from(initializeWizard());
  };

  public blockingReasonCodeFilterFunction = (informationFiles) => {
    // block all reason codes by default
    return informationFiles;
  };

  /**
   * Configure LicenseRenwal-specific constants and routes.
   */
  public initialize() {
    this.configureConstants();
    this.configureRoutes();
  }

  /**
   * Configure dynamic LicenseRenewalIlc routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  private configureRoutes() {

    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/licenseRenewalIlc/licenseRenewalIlcWizard/license-renewal-ilc-wizard.module')
          .then(m => m.LicenseRenewalIlcWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features//licenseRenewalIlc/licenseRenewalIlcWizard/license-renewal-ilc-wizard.module').then(m => m.LicenseRenewalIlcWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  /**
 * Decorate the ConstantsService with LicenseRenewalIlc-specific constants.
 */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      LicenseRenewalIlcConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS,
      LicenseRenewalIlcConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS,
      LicenseRenewalIlcConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES,
      LicenseRenewalIlcConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      LicenseRenewalIlcConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES,
      LicenseRenewalIlcConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS,
      LicenseRenewalIlcConstantsService.Mergers.FEATURE_CONSTANTS);
    this.licenseRenewalIlcConstantsService = this.constantsService as ILicenseRenewalIlcConstantsService;
  }
}

/**
 * LicenseRenewalIlc setting constants to merge into ConstantsService.
 */
export class LicenseRenewalIlcConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      LICENSE_RENEWAL_WIZARD: 'License.Renewal.Wizard',
      LICENSE_RENEWAL_WIZARD_SELECTOR: 'License.Renewal.Wizard.Selector',
      LICENSE_STATUS_CHANGE_WIZARD_SELECTOR: 'License.Status.Change.Wizard.Selector',
      LICENSE_STATUS_CHANGE_WIZARD: 'License.Status.Change.Wizard',
    },
    SYSTEM_SETTING_KEYS: {
      LICENSE: {
        RENEWAL: {
          RENEWAL_WIZARD: {
            SURVEY_COMPLETE_CALLBACK_URI: "Feature.OnlineRenewal.SurveyCompleteCallbackUri",
            EXTERNAL_SURVEY_URI: "Feature.OnlineRenewal.ExternalSurveyUri",
            SURVEY_STATUS: {
              INCOMPLETE: null,
              COMPLETE: 1,
              BYPASS: 2
            }
          },
        }
      }
    },
    VALIDATION_MODES: {
      RENEWAL_WIZARD: {
        WEB_LICENSE_RENEWAL_TENNESSEN: "Web.LicenseRenewalIlc.Tennessen",
        WEB_LICENSE_RENEWAL_CONTACT_INFORMATION: "Web.LicenseRenewal.ContactInfo",
        WEB_LICENSE_RENEWAL_CONTINUING_EDUCATION: "Web.LicenseRenewalIlc.ContinuingEducation",
        WEB_LICENSE_RENEWAL_PRACTICE_QUESTIONS: "Web.LicenseRenewalIlc.PracticeQuestions"
      }
    },

    DOMAIN_IDS: {
      LICENSE_RENEWAL: 19,
    },

    WEB_SERVICE_PROCESS_TYPES: {
      LICENSE_RENEWAL_ILC: LicensureOnlineServiceProcessTypeKeys.LicenseRenewalIlc,
    },

    FUNCTION_TYPES: {
      RENEWAL: 17,
    },

    FEATURE_CONSTANTS: {

      LICENSE_CERTIFICATION: {
        CERTIFICATION_STATUS: {
          ACTIVE: 1,
          EXPIRED: 2,
          WITHDRAWN: 3
        }
      }
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    LicenseRenewalIlcConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
    LicenseRenewalIlcConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES),
    LicenseRenewalIlcConstantsService.Mergers.VALIDATION_MODES);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    LicenseRenewalIlcConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS),
    LicenseRenewalIlcConstantsService.Mergers.DOMAIN_IDS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES),
    LicenseRenewalIlcConstantsService.Mergers.FUNCTION_TYPES);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
    LicenseRenewalIlcConstantsService.Mergers.FEATURE_CONSTANTS);
}

export interface ILicenseRenewalIlcConstantsService extends LicenseRenewalIlcConstantsService {
}

