import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

import { PageSummaryContentChangedEventArgument } from '@coreModels/eventArgs/page-summary-content-changed-event-argument';

@Injectable({
  providedIn: 'root'
})
export class PageSummaryService {
  private _pageSummaryContentChanged$: Subject<PageSummaryContentChangedEventArgument> = new Subject<PageSummaryContentChangedEventArgument>();
  public pageSummaryContentChanged$ = this._pageSummaryContentChanged$.asObservable();

  constructor() { }

  /**
   * Raises the "pageSummaryText.contentChanged" event.
   */
  public raisePageSummaryContentChanged$(args: PageSummaryContentChangedEventArgument) {
    this._pageSummaryContentChanged$.next(args);
  }
}
