<div class="reach-layout-topbar topbar d-print-none">

  <div class="topbar-main">

    <!-- Menu Button -->
    <a id="menu-button" (click)="menuButtonClick($event)">
      <span class="fa fa-bars"></span>
    </a>

    <!-- Logo -->
    <a [command-button]="homeCommand" command-description="NavbarHome" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND">
      <img [src]="logoImage">
    </a>
    
    <!-- App Title -->
    <div class="app-name">
      <!-- Brand -->
      <a [command-button]="homeCommand" command-description="NavbarHome" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" class="p-d-none p-d-lg-block">{{brand}}</a>
      <!-- Brand - Extra small layout-->
      <a [command-button]="homeCommand" command-description="NavbarHome" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" class="p-d-none p-d-md-block p-d-lg-none"><small>{{brand}}</small></a>
    </div>
    <a id="user-display">
      <span *ngIf="userDisplayName" class="username">
        Welcome <b>{{userDisplayName}}</b>!
      </span>
      <span class="userrole">
        <a [command-button]="logoutCommand" command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" [hidden]="!isUserLoggedIn">(Log out)</a>
        <img [src]="userImage" />
      </span>
    </a>
  </div>

  <!-- Navigation Bar/Menu -->
  <reach-navbar></reach-navbar>

</div>
