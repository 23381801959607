<div class="p-fluid" [formGroup]="contentForm">
  <div class="p-grid p-formgrid">

    <!-- Licensee -->
    <license-selector-field class="p-field p-col-12" [model]="licenseSelectorComponentModel"
      [config]="licenseSelectorFieldConfig" (selectionChanged)="licenseSelectorFieldSelectionChanged($event)" *ngIf="model.IsNew"></license-selector-field>

    <!-- Employment Type -->
    <div *ngIf="config.employmentTypeEnabled" class="p-field p-col-12 p-md-6">
      <label for="EmploymentType">Type</label>
      <p-dropdown *ngIf="isLoaded" inputId="EmploymentType" formControlName="EmploymentType"
        [options]="config.possibleEmploymentTypes" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- PositionTitle -->
    <div *ngIf="!config.positionPickListEnabled" class="p-field p-col-12 p-md-6">
      <label for="PositionTitle">Position</label>
      <input id="PositionTitle" type="text" formControlName="PositionTitle" pInputText>
    </div>

    <!-- PositionTitle -->
    <div *ngIf="config.positionPickListEnabled" class="p-field p-col-12 p-md-6">
      <label for="PositionTitle">Position</label>
      <p-dropdown *ngIf="isLoaded" inputId="PositionTitle" formControlName="PositionTitle"
        [options]="config.possiblePositionTitles" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- EmploymentSetting -->
    <div *ngIf="config.isSettingEnabled" class="p-field p-col-12 p-md-6">
      <label for="state">Setting</label>
      <p-dropdown *ngIf="isLoaded" inputId="EmploymentSetting" formControlName="EmploymentSetting"
        [options]="config.possibleEmploymentSettings" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- EmploymentSector -->
    <div *ngIf="config.isSectorEnabled" class="p-field p-col-12 p-md-6">
      <label for="state">Sector</label>
      <p-dropdown *ngIf="isLoaded" inputId="EmploymentSector" formControlName="EmploymentSector"
        [options]="config.possibleEmploymentSectors" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- BeginDate -->
    <div class="p-field p-col-12 p-md-6 p-xl-3">
      <label for="BeginDate">From</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="BeginDate" formControlName="BeginDate"
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>

    <!-- EndDate -->
    <div class="p-field p-col-12 p-md-6 p-xl-3">
      <label for="EndDate">To</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="EndDate" formControlName="EndDate"
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>
  </div>
</div>

<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>