import { Pipe } from '@angular/core';
import { FilterListPipe } from './filter-list.pipe';

@Pipe({
  name: 'filterImpure',
  pure: false
})
export class FilterListImpurePipe extends FilterListPipe {

}
