import { IBaseDto,
IExceptionDto,
IIncidentDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class IncidentDto extends BaseDto implements IBaseDto, IIncidentDto {
	Exceptions: IExceptionDto[];
	AppDomainName: string;
	MachineName: string;
	WindowsIdentity: string;
	FullName: string;
	UserComment: string;
	ReferenceGuid: any;

}
