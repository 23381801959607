<!-- Render as Links -->
<div class="reach-navigation-links-wrapper" *ngIf="menuItems.length > 0">
  <div class="reach-navigation-links-header">Navigate</div>
  <div *ngFor="let item of onlineServiceCommands">
    <button pButton pRipple type="button" label="{{item.commandText}}" [command-button]="item"
      class="p-button-text p-text-left"></button>
  </div>
</div>

<p-dialog header="Notice" [(visible)]="showDialog" modal [closable]="false" [style]="{width: '50vw'}">
  <div style="display: flex; align-items: center;">
    <i class="pi pi-exclamation-triangle">&nbsp;</i>
    <span [innerHTML]="alertMessage"></span>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Ok" (click)="showDialog=false"></button>
  </p-footer>
</p-dialog>
