import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { IOnlineLicenseSearchCriteria } from './licensure-shared-dto';

export class OnlineLicenseSearchCriteria extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, IOnlineLicenseSearchCriteria {
	LastName: string;
	FirstName: string;
	BirthDate?: Date;
	LicenseType: string;
	LicenseTypes: string;
	City: string;
	Zip: string;
	CountyId?: number;
	LicenseNumber?: number;
	IncludeEntityVerification: boolean;
	IncludeAlternateNames: boolean;
	IncludeNameHistory: boolean;
	IsLicensingSupervisor: boolean;
	IsLicenseVerification: boolean;
	IsIndividual?: boolean;
	LicenseStatus: string;
	ExcludeEntitiesWithHolds: boolean;

}
