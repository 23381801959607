import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { retry, catchError, tap, map } from "rxjs/operators";

import {
  AdHocDataRequestRequestDto,
  AdHocStatisticalDataRequestRequestDto,
  DataRequestRequestDto,
  IAdHocDataRequestRequestDto,
  IAdHocStatisticalDataRequestRequestDto,
  IDataRequestRequestDto,
  IDataRequestResponseDto,
  IHttpResponseMessage,
  IHttpResponseContent,
  IPreBuiltDataRequestRequestDto,
  DataRequestResponseDto,
} from '@coreShared/core-shared.module';

import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UserDto } from '@coreShared/core-shared.module';
import { ValidationManagerService } from './validation-manager.service';

/**
 * Data service for API calls for Sponsor data.
 */
@Injectable({
  providedIn: 'root'
})
export class DataRequestService extends ReachHttpClientService {
  uri: string;

  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/dataRequest`;
  }

  /**
  * Returns the Sponsor instance associated with the specified Id.
  * @param id the target Sponsor Id.
  */
  public initializeAdHocStatisticalDataRequestRequest(dataSourceId): Observable<AdHocStatisticalDataRequestRequestDto> {
    // Must have a dataSourceId
    if (!dataSourceId) {
      this.argumentExceptionService.create("dataSourceId").log();
    }

    let url = `${this.uri}/initialize/AdHocStatistical/${dataSourceId}`;
    return this.get<AdHocStatisticalDataRequestRequestDto>(url);
  }

  public initializeAdHocDataRequestRequest(dataSourceId): Observable<AdHocDataRequestRequestDto> {
    // Must have a dataSourceId
    if (!dataSourceId) {
      this.argumentExceptionService.create("dataSourceId").log();
    }

    let url = `${this.uri}/initialize/AdHoc/${dataSourceId}`;
    return this.get<AdHocDataRequestRequestDto>(url);
  }

  public initializePreBuiltDataRequestRequest(dataSourceId): Observable<DataRequestRequestDto> {
    // Must have a dataSourceId
    if (!dataSourceId) {
      this.argumentExceptionService.create("dataSourceId").log();
    }

    let url = `${this.uri}/initialize/PreBuilt/${dataSourceId}`;
    return this.get<DataRequestRequestDto>(url);
  }

  public query(queryRequest: IAdHocDataRequestRequestDto): Observable<DataRequestResponseDto> {
    let url = `${this.uri}/query`;
    return this.post<DataRequestResponseDto>(url, queryRequest);
  }

  public invoke(dataRequestRequest: IDataRequestRequestDto): Observable<DataRequestResponseDto> {
    let url = `${this.uri}/invoke`;
    return this.post<DataRequestResponseDto>(url, dataRequestRequest);
  }
}
