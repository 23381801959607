import { IDocumentRelatedToKeysDto,
IDomainReasonForChangeDtoHost } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DocumentRelatedToKeysDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDocumentRelatedToKeysDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	Description: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;

}
