import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IAutoVerificationSubscriberLicenseSearchCriteriaDto } from './licensure-shared-dto';

export class AutoVerificationSubscriberLicenseSearchCriteriaDto extends BaseDto implements IBaseDto, IAutoVerificationSubscriberLicenseSearchCriteriaDto {
	FirstName: string;
	LastName: string;
	LicenseType: string;
	LicenseNumber?: number;
	LicenseStatus: string;
	ExpireDate?: Date;

}
