import { IDocumentDto, IDomainChecklistItemDto,
IDomainChecklistItemStatusDto,
IDomainReasonForChangeDtoHost,
ILookupDomainChecklistItemDto, 
IRelatedToBaseDto} from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DomainChecklistItemDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDomainChecklistItemDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	DomainChecklistItemStatusAspectStatusConstants: any;
	ParentDomainId?: number;
	ParentDomainKeyId?: number;
	TemporaryParentDomainKeyId?: any;
	ChildDomainId?: number;
	ChildDomainKeyId?: number;
	TemporaryChildDomainKeyId?: any;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DueDate?: Date;
	SentDate?: Date;
	ReceivedDate?: Date;
	Comment: string;
	OnlineComment: string;
	StatusId?: number;
	StatusDate?: Date;
	ChecklistItemTemplateId: number;
	Status: IDomainChecklistItemStatusDto;
	StaffAssigned: string;
	UploadCorrespondenceId?: number;
	IsComplete: boolean;
	GroupName: string;
	GroupDisplayOrder: number;
	Description: string;
	Summary: string;
	DerivedDescription: string;
	ChecklistItemTemplate: ILookupDomainChecklistItemDto;
	
	EntityId?: number;
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
}
