import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, CommandService, ConstantsService, DomainChecklistItemService, DomainChecklistItemStatusTypeService, UtilitiesService, ValidationManagerService, WizardModel } from 'src/app/core/index-services';
import { IDomainChecklistItemDto } from 'src/app/coreShared/core-shared.module';
import { ChecklistFileUploadItemsComponent } from '../checklistFileUploadItems/checklist-file-upload-items.component';
import { CvaHostWizardStepComponent } from '../wizard/wizard-step.component';

export const ChecklistFileUploadComponentSelector: string = 'checklist-file-upload';
export const ChecklistFileUploadComponentKey: string = 'ChecklistFileUpload';

@Component({
  selector: ChecklistFileUploadComponentSelector,
  templateUrl: './checklist-file-upload.component.html',
  styleUrls: ['./checklist-file-upload.component.scss']
})
export class ChecklistFileUploadComponent extends CvaHostWizardStepComponent implements OnInit {

  @ViewChild('checklistFileUploadItems') checklistFileUploadItemsComponent: ChecklistFileUploadItemsComponent;

  baseTemplateIds: number[] = [];
  templateIds: number[] = [];
  relationships: { TemplateId: number; MinorKey: string; }[] = [];

  checklist: IDomainChecklistItemDto[];
  NOT_APPLICABLE: number;
  defaultDynamicContentMinorKey: string;

  constructor(checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , changeDetectorRef: ChangeDetectorRef
    , protected commandService: CommandService
    , protected utilitiesService: UtilitiesService
    , protected domainChecklistItemService: DomainChecklistItemService
    , protected statusTypeService: DomainChecklistItemStatusTypeService) {

    super(constantsService, validationManagerService, wizardInjector, ChecklistFileUploadComponent.name, checkboxContentService, elementRef, changeDetectorRef);

    this.checklist = this.wizard.model.DomainChecklistDtoHost.DomainChecklist as IDomainChecklistItemDto[];
    this.NOT_APPLICABLE = constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.NOT_APPLICABLE;

    this.parseStepParameters();
    this.modelToForm();
  }

  ngOnInit(): void {

    this.determineSelectedRadio();

    this.wizard.selectedStep.onStepForwardStart(this.onNext);
    this.wizard.selectedStep.onStepBackStart(this.onNext);
    super.ngOnInit();
  }

  determineSelectedRadio() {

    const groupedByMinorKey = this.groupByMinorKey(this.relationships);

    // Iterate through each minor key group
    for (const minorKeyGroup of Object.values(groupedByMinorKey)) {

      // Check if all TemplateIds from the group exist in the checklist
      const allTemplateIdsExist = minorKeyGroup.every(rel =>
        this.checklist.some(cli =>
          (cli.ChecklistItemTemplateId === rel.TemplateId) &&
          !cli.Status.AspectStatuses.some(aspect => aspect.AspectStatusId === this.NOT_APPLICABLE)
        )
      );

      // If all TemplateIds exist in the checklist, set minorKeySuggestion and break out of the loop
      if (allTemplateIdsExist) {
        this.defaultDynamicContentMinorKey = minorKeyGroup[0].MinorKey; // Assuming all objects in the group have the same MinorKey
        break;
      }
    }
  }

  groupByMinorKey(relationships: { TemplateId: number; MinorKey: string; }[]): { [key: string]: { TemplateId: number; MinorKey: string; }[] } {
    const grouped: { [key: string]: { TemplateId: number; MinorKey: string; }[] } = {};

    relationships.forEach(relationship => {
      const { MinorKey } = relationship;
      if (grouped[MinorKey]) grouped[MinorKey].push(relationship);
      else grouped[MinorKey] = [relationship];
    });

    return grouped;
  }

  protected override formToModel(): void {
    super.formToModel();

    this.templateIds = [];
    this.baseTemplateIds.forEach(id => { this.templateIds.push(id); });

    const relationships = this.getDynamicContentTemplateIdRelationships();
    if (!relationships.length) {
      this.refresh();
      return;
    }

    relationships.forEach(rel => {

      // Look for a checklist item that has a matching templateId and is NOT marked not applicable.
      const existingCli = this.checklist.find(item =>
        (item.ChecklistItemTemplateId === rel.TemplateId) &&
        !item.Status.AspectStatuses.some(aspect => aspect.AspectStatusId === this.NOT_APPLICABLE));

      if (existingCli) {
        this.push(existingCli);
      }
      else {
        this.domainChecklistItemService.initializeItem(this.wizard.model.DomainChecklistDtoHost, rel.TemplateId).subscribe(responseDto => {
          (responseDto.DomainChecklistItem as any).LocalId = this.utilitiesService.guid();
          this.utilitiesService.addOrReplace(this.wizard.model.DomainChecklistDtoHost.DomainChecklist, responseDto.DomainChecklistItem)
          this.push(responseDto.DomainChecklistItem);
        });
      }

    });

  }

  getDynamicContentTemplateIdRelationships(): { TemplateId: number; MinorKey: string; }[] {
    const radioContentFormGroup = this.stepForm.get('RadioContentItems') as FormGroup;
    const selectedminorKey = radioContentFormGroup?.controls[Object.keys(radioContentFormGroup.controls)[0]]?.value; // LIMITATION: only looks at the first RadioContentItems control.

    return this.relationships.filter(item => item.MinorKey === selectedminorKey);
  }

  push(dto: IDomainChecklistItemDto) {
    this.templateIds.push(dto.ChecklistItemTemplateId);
    this.refresh();
  }

  refresh(): void {
    if (this.checklistFileUploadItemsComponent) this.checklistFileUploadItemsComponent.refresh(this.templateIds);
  }

  protected onNext = (e: any): Observable<boolean> => {

    this.prune();

    (this.wizard.model as WizardModel).ChecklistItemsWithDocuments = (this.wizard.model as WizardModel).DomainChecklistDtoHost.DomainChecklist
      .filter(checklistItem => this.templateIds
        .some(templateId => templateId == checklistItem.ChecklistItemTemplateId))

    return of(true);
  }

  /**
   * Removes items from the DomainChecklistDtoHost.DomainChecklist that were created and then abandoned.
   * Splices newly items from the collection. Marks persisted items as not applicable.
   * Necessary when a user selects a radio button that adds a checklist item, and then selects another radio button.
   */
  prune(): void {

    // For each possible add-on...
    this.relationships.forEach(element => {

      // Look for a checklist item that has a matching templateId and is NOT marked not applicable.
      const cli = this.checklist.find(item =>
        (item.ChecklistItemTemplateId === element.TemplateId) &&
        !item.Status.AspectStatuses.some(aspect => aspect.AspectStatusId === this.NOT_APPLICABLE));

      // If such a checklist item exists in the collection but was not presented to the user, remove.
      if (cli && !this.templateIds.some(id => (id === element.TemplateId))) {
        if (cli.IsNew) {
          this.checklist.splice(this.checklist.findIndex(cli => cli.IsNew && (cli.ChecklistItemTemplateId === element.TemplateId)), 1); // delete
        }
        else {
          this.statusTypeService.setStatusForAspectStatus(cli, this.NOT_APPLICABLE); // mark not applicable
        }
      }
    });
  }

  /**
   * Parse JSON parameters into usable data.
   * Parameters may or may not be provided.
   */
  parseStepParameters() {
    const baseParameter = this.wizard.selectedStep?.Parameters?.["uploadableChecklistItemIds"];
    const relParameter = this.wizard.selectedStep?.Parameters?.["dynamicContentChecklistTemplateIds"];
    this.baseTemplateIds = (baseParameter) ? JSON.parse(baseParameter) : [];
    this.relationships = (relParameter) ? JSON.parse(relParameter).map(obj => ({ MinorKey: obj.minorKey, TemplateId: JSON.parse(obj.templateId) })) : [];
  }
}
