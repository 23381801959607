import { Inject, Injectable } from "@angular/core";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN, UtilitiesService, ReachHttpClientService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, BusyManagerService, ValidationManagerService } from '@core/core.module';
import { Observable } from "rxjs";
import { IDomainFormDto, IDomainFormDtoHost } from "src/app/coreShared/core-shared.module";
import { ILicenseDto } from "src/app/licensureShared/dto-gen/licensure-shared-dto";
import { ApplicationService, ILicenseConstantsService } from "../licensure-core.module";
import { LicenseService } from "./license.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class JurisprudenceService extends ReachHttpClientService {
  private licensureConstants: ILicenseConstantsService;
  private domainFormUri: string;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , busyManagerService: BusyManagerService
    , private licenseService: LicenseService
    , private applicationService: ApplicationService
    , private utilitiesService: UtilitiesService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.licensureConstants = constantsService as ILicenseConstantsService;
    this.domainFormUri = utilitiesService.stringFormatArgs("{0}/OnlineDomainForm", this.apiRootUri);
  }


  public saveForm(domainFormDto: IDomainFormDto): Observable<IDomainFormDto> {
    if (domainFormDto.Id) {
      return this.put<IDomainFormDto>(this.domainFormUri, domainFormDto);
    } else {
      return this.post<IDomainFormDto>(this.domainFormUri, domainFormDto);
    }
  }


  public getExamHost(domainId: number, domainKeyId: number): Observable<IDomainFormDtoHost | ILicenseDto> {

    // Renewal as a host - this a bit odd. License (10) is not actually a host of DomainForms.
    // However our access to the actual host (InProcessRenewal | ActiveRenewal) can only be fetched through the license.
    // In order to keep route params simple we pass the license domainKeyId and domainId to load the license.
    // Once loaded we can work with the InProcessRenewal property of the License.
    if (domainId == this.licensureConstants.DOMAIN_IDS.LICENSE) {
      return this.licenseService.getById(domainKeyId);
    }

    if (domainId == this.licensureConstants.DOMAIN_IDS.APPLICATION) {
      return this.applicationService.getById(domainKeyId);
    }

    throw "unsupported jurisprudence host";
  }
}
