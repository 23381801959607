import { IDomainReasonForChangeDtoHost,
IEntityCommentDto,
IInvoiceDto,
IInvoiceFeeDto,
IInvoiceStatusDto,
IProcessDto,
IRelatedToBaseDto } from './core-shared-dto';
import { RelatedToBaseDto } from './related-to-base-dto';

export class InvoiceDto extends RelatedToBaseDto implements IDomainReasonForChangeDtoHost, IRelatedToBaseDto, IInvoiceDto {
	ProcessId?: number;
	StatusId?: number;
	EntityId?: number;
	Amount: number;
	TotalFees: number;
	Description: string;
	Fees: IInvoiceFeeDto[];
	Status: IInvoiceStatusDto;
	Process: IProcessDto;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];

}
