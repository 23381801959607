<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">
  <div class="p-grid p-formgrid">

    <!-- Dynamic Content -->
    <div class="p-col-12">
      <page-summary-text majorKey="Shared.Content" minorKey="EmploymentListEditor.SummaryTextBlock"></page-summary-text>
    </div>

    <!-- EmployerName -->
    <div *ngIf="!Config.employerAsFacilityLicenseIdEnabled || isAddressFormPresent()" class="p-field p-col-12 p-md-6">
      <label for="EmployerName">Employer</label>
      <input id="EmployerName" type="text" formControlName="EmployerName" pInputText>
    </div>

    <!-- Employer as Licensed Facility -->
    <license-selector-field class="p-field p-col-12 p-md-6"
      *ngIf="Config.employerAsFacilityLicenseIdEnabled && !isAddressFormPresent()"
      [model]="licenseSelectorComponentModel" 
      [config]="licenseSelectorFieldConfig"
      (selectionChanged)="licenseSelectorFieldSelectionChanged($event)">
    </license-selector-field>

    <!-- Empty Space -->
    <div class="p-col" *ngIf="Config.employerAsFacilityLicenseIdEnabled && !isAddressFormPresent()">
    </div>

    <!-- Unemployed -->
    <div class="p-col-12 p-sm-6 p-field-checkbox p-mt-sm-5 p-pt-sm-2"
      *ngIf="!Config.employerAsFacilityLicenseIdEnabled || isAddressFormPresent()">
      <p-checkbox inputId="Unemployed" formControlName="Unemployed" binary="true"></p-checkbox>
      <label for="Unemployed">Unemployed</label>
    </div>

    <!-- Employment Type -->
    <div *ngIf="Config.employmentTypeEnabled" class="p-field p-col-12 p-md-6">
      <label for="EmploymentType">Type</label>
      <p-dropdown *ngIf="dataLoaded" inputId="EmploymentType" formControlName="EmploymentType"
        [options]="possibleEmploymentTypes" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- PositionTitle -->
    <div *ngIf="!Config.positionPickListEnabled" class="p-field p-col-12 p-md-6">
      <label for="PositionTitle">Position</label>
      <input id="PositionTitle" type="text" formControlName="PositionTitle" pInputText>
    </div>

    <!-- PositionTitle -->
    <div *ngIf="Config.positionPickListEnabled" class="p-field p-col-12 p-md-6">
      <label for="PositionTitle">Position</label>
      <p-dropdown *ngIf="dataLoaded" inputId="PositionTitle" formControlName="PositionTitle"
        [options]="possiblePositionTitles" optionLabel="Description" dataKey="Id" [showClear]="true"
        placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- EmploymentSetting -->
    <div *ngIf="isSettingEnabled" class="p-field p-col-12 p-md-6">
        <label for="state">Setting</label>
        <p-dropdown *ngIf="dataLoaded" inputId="EmploymentSetting" formControlName="EmploymentSetting"
            [options]="possibleEmploymentSettings" optionLabel="Description" dataKey="Id" [showClear]="true"
            placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- EmploymentSector -->
    <div *ngIf="isSectorEnabled" class="p-field p-col-12 p-md-6">
        <label for="state">Sector</label>
        <p-dropdown *ngIf="dataLoaded" inputId="EmploymentSector" formControlName="EmploymentSector"
            [options]="possibleEmploymentSectors" optionLabel="Description" dataKey="Id" [showClear]="true"
            placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- BeginDate -->
    <div class="p-field p-col-12 p-md-6 p-xl-3">
      <label for="BeginDate">From</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="BeginDate" formControlName="BeginDate"
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>

    <!-- EndDate -->
    <div class="p-field p-col-12 p-md-6 p-xl-3">
      <label for="EndDate">To</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="EndDate" formControlName="EndDate"
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>
  </div>

  <div class="p-grid p-formgrid" *ngIf="isAddressFormPresent()" formGroupName="AddressForm">

    <!-- Location -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="LocationType">Address Type</label>
      <p-dropdown *ngIf="dataLoaded" inputId="LocationType" formControlName="LocationType"
        [options]="possibleLocationTypes" optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;"
        appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- Line 1 -->
    <div class="p-field p-col-12">
      <label for="line1">Address</label>
      <input id="line1" type="text" formControlName="Line1" pInputText>
    </div>

    <!-- Line 2 -->
    <div class="p-field p-col-12">
      <input id="line2" type="text" formControlName="Line2" pInputText>
    </div>

    <!-- Line 3 -->
    <div class="p-field p-col-12">
      <input id="line3" type="text" formControlName="Line3" pInputText>
    </div>

    <!-- City -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
      <label for="city">City</label>
      <input id="city" type="text" formControlName="City" pInputText>
    </div>

    <!-- State -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3" [hidden]="!enableMailingState">
      <label for="state">State</label>
      <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="possibleStates"
        optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
        baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- County -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3" [hidden]="!enableMailingCounty">
      <label for="county">County</label>
      <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
        optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- Zip -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
      <label for="zip">Zip</label>
      <input id="zip" type="text" formControlName="Zip" pInputText>
    </div>

  </div>
</div>
