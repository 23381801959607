<!-- Grid -->
<div class="p-fluid p-formgrid p-grid" [formGroup]="stepForm">

    <!-- Primary Form -->
    <div class="reach-section-header">Primary</div>
    <div class="p-fluid p-formgrid p-grid" formGroupName="primaryForm" *ngIf="presentPrimaryForm">

        <!-- Facility -->
        <div class="p-field p-col-12">
            <label for="Facility">Agency, Company, or Facility Name</label>
            <input id="Facility" type="text" formControlName="Facility" pInputText>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Address</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
            <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="state">State</label>
            <p-dropdown inputId="state" formControlName="State" [options]="possibleStates"
                appendTo="body" optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="county">County</label>
            <p-dropdown inputId="county" formControlName="County" [options]="possibleCounties"
                appendTo="body" optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Country -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Phone -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="Phone">Phone</label>
            <p-inputMask mask="(999) 999-9999" inputId="Phone" formControlName="Phone" placeholder="(999) 999-9999">
            </p-inputMask>
        </div>

    </div>

    <!-- Checkbox -->
    <div class="p-field-checkbox p-col-12">
        <p-checkbox inputId="binary" formControlName="HasNoPrimaryAddress" binary="true"></p-checkbox>
        <label for="binary">I certify that I do not currently have a primary practice address.</label>
    </div>

    <!-- Secondary Form -->
    <div class="reach-section-header">Secondary</div>
    <div class="p-fluid p-formgrid p-grid" formGroupName="secondaryForm" *ngIf="presentSecondaryForm">

        <!-- Facility -->
        <div class="p-field p-col-12">
            <label for="Facility">Agency, Company, or Facility Name</label>
            <input id="Facility" type="text" formControlName="Facility" pInputText>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Address</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
            <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="state">State</label>
            <p-dropdown inputId="state" formControlName="State" [options]="possibleStates"
                appendTo="body" optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="county">County</label>
            <p-dropdown inputId="county" formControlName="County" [options]="possibleCounties"
                appendTo="body" optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Country -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;">
            </p-dropdown>
        </div>

        <!-- Phone -->
        <div class="p-field p-col-12 p-sm-6 p-lg-3">
            <label for="Phone">Phone</label>
            <p-inputMask mask="(999) 999-9999" inputId="Phone" formControlName="Phone" placeholder="(999) 999-9999">
            </p-inputMask>
        </div>

    </div>

    <!-- Checkbox -->
    <div class="p-field-checkbox p-col-12">
        <p-checkbox inputId="binary" formControlName="HasNoSecondaryAddress" binary="true"></p-checkbox>
        <label for="binary">I certify that I do not currently have a secondary practice address.</label>
    </div>

</div>