import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, OnlineServiceRegistry, OnlineServiceRegistryItem, Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { Observable, from } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ComplaintWizardFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized: boolean = false;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private functionTypeService: FunctionTypeService
    , private wizardTagsService: WizardTagsService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.OnlineComplaint);
  }

  public initialize() {
    if (!this.isInitialized) {

      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  public getWizard(): Observable<Wizard> {
    const functionType = this.functionTypeService.create((this.constantsService as ComplaintConstantsService).FUNCTION_TYPES.COMPLAINT, 100);
    const wizardTags = this.wizardTagsService.create();
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(null, (this.constantsService as ComplaintConstantsService).WEB_SERVICE_PROCESS_TYPES.ONLINE_COMPLAINT, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      const wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      return await this.wizardService.startWizard(wizard, functionType).toPromise();
    };

    return from(initializeWizard());
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, ComplaintConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, ComplaintConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, ComplaintConstantsService.Mergers.FUNCTION_TYPES);
  }

  private configureDecorators() {
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/complaintWizard/complaint-wizard.module').then(m => m.ComplaintWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/complaintWizard/complaint-wizard.module').then(m => m.ComplaintWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

export class ComplaintConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: { ONLINE_COMPLAINT_WIZARD: "Complaint.Wizard" },
    WEB_SERVICE_PROCESS_TYPES: { ONLINE_COMPLAINT: LicensureOnlineServiceProcessTypeKeys.OnlineComplaint },
    FUNCTION_TYPES: { COMPLAINT: 5, }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), ComplaintConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), ComplaintConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), ComplaintConstantsService.Mergers.FUNCTION_TYPES);
}

export interface IComplaintConstantsService extends ComplaintConstantsService {
}
