import { Injectable, Inject, InjectionToken, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap, map } from "rxjs/operators";

import * as _ from 'underscore';
import * as _l from 'lodash-es';

import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UtilitiesService } from "@coreServices/utilities.service";
import { ValidationManagerService } from './validation-manager.service';
import {
  AddressLocationTypeDto,
  AddressTypeDto,
  AnswerDto,
  CountryDto,
  CountyDto,
  DataRequestSourceDto,
  DomainIdentifierTypeDto,
  DomainItemDto,
  EntityGroupMemberDto,
  EthnicGroupDto,
  GenderDto,
  LookupDto,
  PhoneTypeDto,
  ProcessDto,
  SecurityQuestionDto,
  StateDto,
  DomainChecklistItemStatusDto,
  TimeCommitmentDto,
  ICityDto,
  CityDto,
  ActivitySubactivityDocumentTypeDto,
  IActivitySubactivityDto,
  ActivitySubactivityDto,
  MilitaryResponseTypeDto,
  SuffixDto,
  TitleDto,
  DocumentExtensionDto,
  IAddressLocationTypeDto,
  IEntityAssociateTypeDto,
  EntityAssociateTypeDto
} from '@coreShared/core-shared.module';
import { SharedConfigurationService } from "../index-services";

/**
 * InjectionToken for ListService.
 */
export const listServiceTokenName = 'LIST_SERVICE';
export const LIST_SERVICE_TOKEN =
  new InjectionToken<ListService>(
    listServiceTokenName,
    {
      providedIn: 'root',
      factory: () => listServiceFactory(inject(SharedConfigurationService))

    });
const listServiceFactory = (shared: SharedConfigurationService) => {
  return shared.listService;
};

@Injectable({
  providedIn: 'root'
})
export class ListService extends ReachHttpClientService {
  protected lookupCache: any[] = [];

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    protected utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }

  public filterInactiveItems(collection) {
    return _.filter(collection,
      (item: LookupDto) => {
        return !item.IsInactive;
      });
  }

  public getEntityAssociateTypes(): Observable<IEntityAssociateTypeDto[]> {
    return this.getLookupType<IEntityAssociateTypeDto>("{0}/EntityAssociateType", this.utilitiesService.classNameFromType(EntityAssociateTypeDto));
  }

  public getSuffixes(): Observable<SuffixDto[]> {
    return this.getLookupType<SuffixDto>("{0}/Suffix", this.utilitiesService.classNameFromType(SuffixDto));
  }

  public getTitles(): Observable<TitleDto[]> {
    return this.getLookupType<TitleDto>("{0}/Title", this.utilitiesService.classNameFromType(TitleDto));
  }

  public getGenders(): Observable<GenderDto[]> {
    return this.getLookupType<GenderDto>("{0}/Gender", this.utilitiesService.classNameFromType(GenderDto));
  }

  public getDomainIdentifierTypes(domainId) {
    let uriTemplate = "{0}/" + `${domainId}/DomainIdentifierType`;
    return this.getLookupType<DomainIdentifierTypeDto>(uriTemplate, this.utilitiesService.classNameFromType(DomainIdentifierTypeDto));
  }

  public getDomainChecklistItemStatusTypes(): Observable<DomainChecklistItemStatusDto[]> {
    return this.getLookupType<DomainChecklistItemStatusDto>("{0}/DomainChecklistItemStatusType", this.utilitiesService.classNameFromType(DomainChecklistItemStatusDto));
  }

  public getEthnicGroups() {
    return this.getLookupType<EthnicGroupDto>("{0}/EthnicGroup", this.utilitiesService.classNameFromType(EthnicGroupDto));
  }

  public getAnswers() {
    return this.getLookupType<AnswerDto>("{0}/Answer", this.utilitiesService.classNameFromType(AnswerDto));
  }

  // Gets a list of possible states
  public getStates(): Observable<StateDto[]> {
    return this.getLookupType<StateDto>("{0}/State", this.utilitiesService.classNameFromType(StateDto));
  }

  public getCountries(): Observable<CountryDto[]> {
    return this.getLookupType<CountryDto>("{0}/Country", this.utilitiesService.classNameFromType(CountryDto));
  }

  public getCounties(): Observable<CountyDto[]> {
    return this.getLookupType<CountyDto>("{0}/County", this.utilitiesService.classNameFromType(CountyDto));
  }

  public getCities(): Observable<ICityDto[]> {
    return this.getLookupType<ICityDto>("{0}/City", this.utilitiesService.classNameFromType(CityDto));
  }

  public getPhoneTypes(): Observable<PhoneTypeDto[]> {
    return this.getLookupType<PhoneTypeDto>("{0}/PhoneType", this.utilitiesService.classNameFromType(PhoneTypeDto));
  }

  public getMilitaryResponseTypes(): Observable<MilitaryResponseTypeDto[]> {
    return this.getLookupType<MilitaryResponseTypeDto>("{0}/MilitaryResponseType", this.utilitiesService.classNameFromType(MilitaryResponseTypeDto));
  }

  public getSecurityQuestions() {
    return this.getLookupType<SecurityQuestionDto>("{0}/SecurityQuestion", this.utilitiesService.classNameFromType(SecurityQuestionDto));
  }

  public getActivitySubactivityDocumentTypes() {
    return this.getLookupType<ActivitySubactivityDocumentTypeDto>("{0}/ActivitySubactivityDocumentType", this.utilitiesService.classNameFromType(ActivitySubactivityDocumentTypeDto));
  }

  public getActivitySubactivities() {
    return this.getLookupType<IActivitySubactivityDto>("{0}/ActivitySubactivity", this.utilitiesService.classNameFromType(ActivitySubactivityDto));
  }

  // Entity group members
  public getEntityGroupMembers(groupId) {
    let uriTemplate = "{0}/" + `EntityGroupMember/${groupId}`;
    return this.getLookupType<EntityGroupMemberDto>(uriTemplate, this.utilitiesService.classNameFromType(EntityGroupMemberDto));
  }

  // Gets a list of address locations
  public getAddressLocationTypes(): Observable<IAddressLocationTypeDto[]> {
    return this.getLookupType<AddressLocationTypeDto>("{0}/AddressLocationType", this.utilitiesService.classNameFromType(AddressLocationTypeDto));
  }

  // Gets a list of address types
  public getAddressTypes() {
    return this.getLookupType<AddressTypeDto>("{0}/AddressType", this.utilitiesService.classNameFromType(AddressTypeDto));
  }

  public getDataRequestSources() {
    return this.getLookupType<DataRequestSourceDto>("{0}/DataRequestSource",
      this.utilitiesService.classNameFromType(DataRequestSourceDto));
  }

  // Entity group members
  public getUserRegistrationProcesses() {
    let uriTemplate = '{0}/process/userRegistration';
    return this.getLookupType<ProcessDto>(uriTemplate, this.utilitiesService.classNameFromType(ProcessDto) + '_userRegister');
  }

  public getForgotPasswordProcesses() {
    let uriTemplate = '{0}/process/forgotPassword';
    return this.getLookupType<ProcessDto>(uriTemplate, this.utilitiesService.classNameFromType(ProcessDto) + '_forgotPw');
  }


  public getDomainItems() {
    let uriTemplate = '{0}/DomainItem';
    return this.getLookupType<DomainItemDto>(uriTemplate, this.utilitiesService.classNameFromType(DomainItemDto));
  }

  public getTimeCommitments() {
    return this.getLookupType<TimeCommitmentDto>("{0}/TimeCommitment",
      this.utilitiesService.classNameFromType(TimeCommitmentDto));
  }

  public getDocumentExtensions() {
    return this.getLookupType<DocumentExtensionDto>("{0}/DocumentExtension",
      this.utilitiesService.classNameFromType(DocumentExtensionDto));
  }

  /**
   * Helper function for retrieving lookup items. Caches retrieved item by class name. Uses cached items if found, otherwise
   * invokes the lookup api to fetch them from the server.
   * @param urlTemplate the url template for final segment of the url.
   * @param cacheKey the cache key.
   */
  protected getLookupType<TLookup>(urlTemplate: string, cacheKey: string, forceRefresh: boolean = false) {
    let cachedItems = this.lookupCache[cacheKey];
    if (!forceRefresh && cachedItems && cachedItems.length > 0) {
      return of(cachedItems);
    }

    let url = this.utilitiesService.stringFormatArgs(urlTemplate, this.apiRootUri);
    return this.get<TLookup[]>(url)
      .pipe(tap(res => this.lookupCache[cacheKey] = res));
  }

  /**
   * Find the LookupDto type matching the specified Id and Type.
   * @param id the Id of the specific instance.
   * @param urlTemplate the url template for API fetches of the lookup type.
   * @param cacheKey the key associated with the Type.
   */
  //protected getLookupTypeById<TLookup>(id, urlTemplate: string, cacheKey: string) {
  //  let cachedItems = this.getLookupType<TLookup>(urlTemplate, cacheKey, true);
  //  let match = _l.find(cachedItems,
  //    (item) => {
  //      let lookupItem = item as LookupDto;
  //      if (lookupItem && lookupItem.Id === id) {
  //        match = item;
  //      }
  //    });

  //  return match;
  //}
}
