// Angular
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, OnlineServiceMenuService, OnlineServiceRegistry, OnlineServiceRegistryItem, UserManagerService, SystemSettingsManagerService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { ILicenseDto, ILicenseInfoDto, ILicensureUserProfileInfoDto } from "src/app/licensureShared/dto-gen/licensure-shared-dto";
import { DuplcateCardSelectorWizardRouteResolver } from "../../features/duplicateCardWizard/duplicateCardWizardSelector/duplicate-card-wizard-selector.component";
import { LicenseInfoDto } from "src/app/licensureShared";

@Injectable({ providedIn: 'root' })
export class DuplicateCardFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private duplicateCardConstantsService: IDuplicateCardConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private router: Router,
    private userManagerService: UserManagerService,
    private systemSettingsManagerService: SystemSettingsManagerService) {
    this.duplicateCardConstantsService = this.constantsService as IDuplicateCardConstantsService;
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.DuplicateCard);
  }

  public initialize() {
    if (!this.isInitialized) {

      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  public presentDuplicateCardWizard(license: ILicenseDto = null, processTypeId: number = null): void {

    let possibleSubjects: ILicenseInfoDto[] = [];

    if (!license) { // if a license was not supplied we attempt to derive one from the profile
      possibleSubjects = this.getLicensesThatSupportDuplicateCards(null, processTypeId);
      if (!possibleSubjects) return;

      if (possibleSubjects.length != 1) {
        this.router.navigate(['/', this.serviceRegistryItem.selectorPath]);
        return;
      }
    }

    if (!license && !possibleSubjects[0]) throw "Unable to start service, please ensure a license has been supplied or one can be derived.";
    this.router.navigate(this.dynamicRoutesService.buildRoute(this.serviceRegistryItem.onlineServicePathTemplate, license?.EntityId, license?.Id ?? possibleSubjects[0].Id, processTypeId));
  }

  /**
   * Returns the set of licenses that support duplicate card issue. The licenses on the user profile are filtered by the specified filter-by-license-type
   * filter function, or a default function.
   * @param filterFunction
   */
  public getLicensesThatSupportDuplicateCards(filterFunction = null, processTypeId: number = null): ILicenseInfoDto[] {
    let currentPrincipal = this.userManagerService.getCurrentPrincipal();
    if (!currentPrincipal) {
      return null;
    }

    if (!filterFunction) {
      filterFunction = (license: LicenseInfoDto) => { 
        // Ensure the license types setting is either asterisk (*) or contains the license type
        var licenseTypesKey = processTypeId == (this.duplicateCardConstantsService).WEB_SERVICE_PROCESS_TYPES.DUPLICATE_WALL_CERTIFICATE
          ? this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_WALL_CERTIFICATE_LICENSE_TYPE_CODES
          : this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_CARD_LICENSE_TYPE_CODES;

        var configuredLicenseTypeArray = this.systemSettingsManagerService.asString(licenseTypesKey, true).split(',').map(value => value.trim());
        if (!(configuredLicenseTypeArray.includes('*') || configuredLicenseTypeArray.includes(license.LicenseType.Code))) return false;

        // Ensure the status codes setting contains either asterisk (*) or the status code
        var licenseStatusCodesKey = processTypeId == (this.duplicateCardConstantsService).WEB_SERVICE_PROCESS_TYPES.DUPLICATE_WALL_CERTIFICATE
          ? this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_WALL_CERTIFICATE_LICENSE_STATUS_CODES
          : this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_CARD_LICENSE_STATUS_CODES;

        var configuredStatusArray = this.systemSettingsManagerService.asString(licenseStatusCodesKey, true).split(',').map(value => value.trim());
        if (!(configuredStatusArray.includes('*') || configuredStatusArray.includes(license.LicenseStatus.Id))) return false;

        return true;
      }
    }

    return (currentPrincipal.user.UserAccount.profile as ILicensureUserProfileInfoDto).Licenses.filter(filterFunction);
  }

  /**
 * Decorate the ConstantsService with Application-specific constants.
 */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, DuplicateCardConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, DuplicateCardConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, DuplicateCardConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, DuplicateCardConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.duplicateCardConstantsService = this.constantsService as IDuplicateCardConstantsService;
  }

  private configureDecorators() {
    this.addOnlineServiceMenuOption();
  }

  /**
   * Configure dynamic Application routes.
   */
  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/duplicateCardWizard/duplicate-card-wizard-selector.module').then(m => m.DuplicateCardWizardSelectorModule),
        resolve: { routeConfiguration: DuplcateCardSelectorWizardRouteResolver }
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/duplicateCardWizard/duplicate-card-wizard.module')
          .then(m => m.DuplicateCardWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/duplicateCardWizard/duplicate-card-wizard.module')
          .then(m => m.DuplicateCardWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addOnlineServiceMenuOption() {
    this.onlineServiceMenuService.addOnlineServiceMenuItem(
      this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.LICENSE.DUPLICATE_LICENSE_CARD.FEATURE_DUPLICATE_LICENSE_CARD_ONLINE_SERVICE_MENU_GROUP,
      this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.LICENSE.DUPLICATE_LICENSE_CARD.FEATURE_DUPLICATE_LICENSE_CARD_ONLINE_SERVICE_MENU_GROUP_ORDER,
      "Request a Duplicate License Card",
      this.serviceRegistryItem.onlineServicePath,
      this.duplicateCardConstantsService.SYSTEM_SETTING_KEYS.LICENSE.DUPLICATE_LICENSE_CARD.ENABLED,
      false,
      () => {
        let licenses = this.getLicensesThatSupportDuplicateCards();
        return licenses && licenses.length > 0;
      },
      () => {
        this.presentDuplicateCardWizard();
      }
    );
  }
}

/**
 * DuplicateCard setting constants to merge into ConstantsService.
 */
export class DuplicateCardConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      DUPLICATE_CARD_WIZARD_SELECTOR: 'DuplicateCard.Wizard.Selector',
      DUPLICATE_CARD_WIZARD: 'DuplicateCard.Wizard',
      DUPLICATE_WALL_CERTIFICATE_WIZARD: 'DuplicateWallCertificate.Wizard',
    },

    SYSTEM_SETTING_KEYS: {
      LICENSE: {
        DUPLICATE_LICENSE_CARD: {
          FEATURE_DUPLICATE_LICENSE_CARD_ONLINE_SERVICE_MENU_GROUP: "Feature.DuplicateCard.OnlineServiceMenu.Group",
          FEATURE_DUPLICATE_LICENSE_CARD_ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.DuplicateCard.OnlineServiceMenu.GroupOrder"
        }
      }
    },

    WEB_SERVICE_PROCESS_TYPES: {
      DUPLICATE_CARD: LicensureOnlineServiceProcessTypeKeys.DuplicateCard,
      DUPLICATE_WALL_CERTIFICATE: LicensureOnlineServiceProcessTypeKeys.DuplicateWallCertificate
    },
    VALIDATION_MODES: {
      DUPLICATE_CARD_WIZARD: {
        WEB_DUPLICATE_CARD_CONTACT_INFO: "Web.DuplicateCard.ContactInfo",
      }
    },
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), DuplicateCardConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), DuplicateCardConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), DuplicateCardConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), DuplicateCardConstantsService.Mergers.VALIDATION_MODES);
}

export interface IDuplicateCardConstantsService extends DuplicateCardConstantsService {
}
