import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

export class TooltipAttributes {
  constructor(public text: string = "", public title: string = "") {}
};

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  constructor(private renderer: Renderer2, el: ElementRef<HTMLElement>) {
      var qtipContent = {
          content: new TooltipAttributes(this.tooltipText, this.tooltipTitle),
      show: {
        delay: 500
      }
    };

    //element.nativeElement.qtip(qtipContent);
    //this.renderer.invokeElementMethod(el, qtipContent);
    }

  @Input() tooltipTitle: string;
    @Input('tooltip') tooltipText: string;
}
