<p-dialog [keepInViewport]="true" [(visible)]="isOpen" [baseZIndex]="10000" [closable]="false" [blockScroll]="true"
  [modal]="true" [draggable]="true" [resizable]="true" styleClass="reach-dialog" id="reachDialog" [focusTrap]="true"
  appendTo="body">

  <ng-template pTemplate="header">
    <div class="reach-dialog-header-container p-d-flex p-jc-between">
      <!-- Title -->
      <div class="reach-dialog-title">{{title}}</div>

      <!-- Validation Toggle -->
      <div>
        <message-toggle-button [messageScope]="validationScope" [hidden]="!canShowValidationSummary">
        </message-toggle-button>
      </div>
    </div>
  </ng-template>

  <!-- Validation Messages -->
  <div>
    <message-summary [messageScope]="validationScope" labelText="Attention" severity="error"
      [hidden]="!canShowValidationSummary" style="margin: 0px;"></message-summary>
  </div>

  <!-- Content Area -->
  <ng-template pTemplate="content">
    <reach-container [componentInfo]="contentComponentInfo"></reach-container>
  </ng-template>

  <!-- Footer -->
  <ng-template pTemplate="footer">
    <div class="reach-dialog-footer-container">
      <button type="button" pButton icon="pi pi-check" [command-button]="wrappedOkCommand"
        command-description="DialogOk" label="Ok" class="p-ml-2 p-mr-0"></button>
      <button type="button" pButton icon="pi pi-times" [command-button]="wrappedCancelCommand"
        command-description="DialogCancel" label="Cancel" class="p-button-secondary" class="p-ml-2 p-mr-0"></button>
    </div>
  </ng-template>

</p-dialog>
