import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { IEducationProgramTypeDto } from './licensure-shared-dto';

export class EducationProgramTypeDto extends LookupDto implements IEducationProgramTypeDto {
	CreatedBy: string;
	CreatedDate: Date;
	IsOccupational: boolean;

}
