import { Inject, Injectable } from '@angular/core';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "./constants-provider.service";

export class DynamicContentConfiguration {
  constructor(public enabled: boolean,
    public majorKey: string,
    public DYNAMIC_CONTENT_CATEGORIES,
    public DYNAMIC_CONTENT_LOCATION_TYPES,
    public DYNAMIC_CONTENT_PRESENTATION_TYPES) {
  }

  public static create(enabled: boolean, majorKey: string, constantsService) {
    return new DynamicContentConfiguration(enabled, majorKey, constantsService.DYNAMIC_CONTENT_CATEGORIES, constantsService.DYNAMIC_CONTENT_LOCATION_TYPE, constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES);
  }
}


@Injectable({
  providedIn: 'root'
})
export class DynamicContentConfigurationProviderService {
  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService) { }

  public getConfigurationData(enabled: boolean, majorKey: string): DynamicContentConfiguration {
    return new DynamicContentConfiguration(enabled,
      majorKey,
      this.constantsService.DYNAMIC_CONTENT_CATEGORIES,
      this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES,
      this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES);
  }
}

