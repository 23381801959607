// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';

// Core
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@coreComponents/reach-dialog/reach-dialog-content.component';
import { PicklistModel } from '../../models/pick-list-model';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { FormControl } from '@angular/forms';
import { CommandService } from 'src/app/core/index-services';
import { Command } from 'src/app/core/index-models';

export const PickListEditorComponentSelector = 'pick-list-editor';
export const PickListEditorComponentKey = 'pickListEditor';

@Component({
  selector: 'pick-list-editor',
  templateUrl: './pick-list-editor.component.html',
  styleUrls: ['./pick-list-editor.component.scss']
})
export class PickListEditorComponent extends ReachDialogContentComponent<PicklistModel> implements IReachDialogContentComponent {

  // FIELDS
  pickListModel: PicklistModel;
  dataLoaded: boolean = false;
  removeItemCommand: Command;

  // Properties
  public get displayKey(): boolean { return this.hostDialogData.initializationData.displayKey; }
  public get filteredPossibleItems(): any[] { return this.pickListModel.possibleItems.filter(x => !x.picked); }

  // CTOR
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,

    protected commandService: CommandService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {
    this.pickListModel = this.outputModel as PicklistModel;

    this.contentForm.addControl(this.pickListModel.friendlyName, new FormControl(null));

    this.dataLoaded = true;
    this.initCommands();

    super.modelToForm();
  }

  /**
   * Map from the FormGroup to the output model.
   */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    let x = this.contentForm.get(this.pickListModel.friendlyName).value;
    if (x){
      this.pickListModel.addItem(x);
      this.contentForm.get(this.pickListModel.friendlyName).setValue(null);
    }    

  }

  /**
  * Initializes commands for this instance.
  */
  override initCommands() {
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return this.pickListModel.selectedItems.length > 0;
  }

  protected removeItemCommandExecute = (item: any) => {
    if (item) this.pickListModel.removeItem(item);
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.DataRequestAdHocWizard, PickListEditorComponentKey, PickListEditorComponent, PickListEditorComponentSelector);
registerDynamicComponent(ReachScenarios.Default, PickListEditorComponentKey, PickListEditorComponent, PickListEditorComponentSelector);
