
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as _l from 'lodash-es';
import { CommandService } from '@coreServices/command.service';
import { Command } from '@coreModels/command';
import { OnlineServiceLink } from '@coreModels/online-service-link';
import { ReachMenuItem } from '@coreModels/reach-menu-item';
import { OnlineServiceLinkModel } from '../../services/online-service-link-manager.service';

@Component({
  selector: 'online-service-link',
  templateUrl: './online-service-link.component.html',
  styleUrls: ['./online-service-link.component.scss']
})
export class OnlineServiceLinkComponent implements OnInit, OnChanges {

  @Input()
  model: OnlineServiceLinkModel;
  @Input()
  caption: string;

  public showDialog: boolean;
  public serviceLinks: OnlineServiceLink[] = [];
  public onlineServiceCommands: Command[] = [];
  public menuItems: ReachMenuItem[] = [];
  public alertMessage: string;

  constructor(private commandService: CommandService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.initializeServiceLinks();
    }
  }

  ngOnInit(): void { }

  private initializeServiceLinks() {
    if (this.model && this.model.links) {
      this.serviceLinks = this.model.links as OnlineServiceLink[];
      this.serviceLinks = _l.sortBy(this.serviceLinks, (e: OnlineServiceLink) => {
        return e.webLinkInfo.DisplayOrder;
      });
    }

    this.serviceLinks.forEach((l: OnlineServiceLink) => {
      let isExecutable = l.canExecute
        ? l.canExecute()
        : true;
      if (!isExecutable) {
        return;
      }

      let nextCommand = this.commandService.create(() => {
        return l.canExecute ? l.canExecute() : true;
      }, () => {
        if (l.webLinkInfo.AlertMessage?.length) {
          this.showDialog = true;
          this.alertMessage = l.webLinkInfo.AlertMessage;
        }
        else {
          this.router.navigate(l.targetRoute());
        }
      }, l.displayText);

      this.onlineServiceCommands.push(nextCommand);
    });

    this.buildMenu();
  }

  /**
   * Add commands to menu object.
   */
  private buildMenu() {

    // Initialize menu array
    this.menuItems = [];

    // Add a menu entry for each service link
    this.onlineServiceCommands.forEach((item: Command) => {
      this.menuItems.push(new ReachMenuItem({ commandModel: item }));
    });
  }
}
