import { IDomainChecklistItemStatusAspectStatusDto,
IDomainChecklistItemStatusDto,
IDomainChecklistItemStatusGroupDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainChecklistItemStatusDto extends LookupDto implements IDomainChecklistItemStatusDto {
	StatusGroupId?: number;
	LookupDomainChecklistItemStatusGroup: IDomainChecklistItemStatusGroupDto;
	AspectStatuses: IDomainChecklistItemStatusAspectStatusDto[];

}
