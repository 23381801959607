import {  } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ICeStudyTypeDto } from './licensure-shared-dto';

export class CeStudyTypeDto extends BaseDto implements ICeStudyTypeDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	Code: string;
	Heirarchy: number;

}
