import { ReachModelAwareComponentModel } from "src/app/core/components/reachModelAware/reach-model-aware-component-model";
import { CheckboxContent } from "src/app/core/index-services";
import { IEntityEmploymentListDto } from "src/app/licensureShared/licensure-shared.module";

export class EmploymentListModel extends ReachModelAwareComponentModel {
  checkboxContents: CheckboxContent[];
  entityEmploymentList: IEntityEmploymentListDto;
  entityId: number;
  licenseId: number;
  licenseTypeId: string;

  constructor(
    checkboxContents: CheckboxContent[] = [],
    entityEmploymentList: IEntityEmploymentListDto = {} as IEntityEmploymentListDto,
    entityId: number = 0,
    licenseId: number = null,
    licenseTypeId: string = null
  ) {
    super(); // Call the constructor of the base class
    this.checkboxContents = checkboxContents;
    this.entityEmploymentList = entityEmploymentList;
    this.entityId = entityId;
    this.licenseId = licenseId;
    this.licenseTypeId = licenseTypeId;
  }
}
