<!-- Display with adornment setting toggled via Alt-V + Alt-A keypress -->
<div *ngIf="reachApplicationService.showDynamicContentKeyAdornments">
    <div class="p-d-flex p-jc-end">
        <span class="p-tag p-tag-warning">{{majorKey}} - {{minorKey}}</span>
    </div>
    <div class="reach-adornment-container">
        <p *ngIf="entry" [innerHtml]="entry.Content" class="summary-text-overflow"></p>
    </div>
</div>

<!-- Normal display -->
<div *ngIf="!reachApplicationService.showDynamicContentKeyAdornments">
    <p *ngIf="entry" [innerHtml]="entry.Content" class="summary-text-overflow"></p>
</div>