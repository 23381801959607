<div class="p-fluid" [formGroup]="stepForm">

    <!-- Readonly Information -->
    <div class="p-grid p-formgrid">

        <!-- Reference # -->
        <div class="p-col-12 p-field" *ngIf="showReferenceNumber">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Reference&nbsp;#</label>
            <span class="p-col p-mb-0">{{referenceNumber}}</span>
        </div>

        <!-- Download Confirmation -->
        <p-button label="Download Confirmation" icon="fa fa-download" styleClass="p-button-sm p-ml-2 p-button-secondary"
            [command-button]="confirmationDocumentCommand" tool-tip="View/download review document">
        </p-button>

    </div>

    <!-- Affirmation section -->
    <div class="p-grid p-formgrid">

        <!-- Confirmation Checkboxes -->
        <div class="p-col-12">
            <!-- DynamicContent Checkboxes -->
            <checkbox-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey"></checkbox-content-items>
        </div>

    </div>

</div>
