import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LandingComponentKeys, LoginComponentKeys, ReachScenarios } from '@core/index-constants';
import { Command, OnlineServiceLink, OnlineServiceLinkSubscription, OnlineServiceProcessTypes, registerDynamicComponent, RouteInfoRegistry } from '@core/index-models';
import { AuthorizationConfigurationProviderService, BootstrapperService, BusyManagerService, CommandService, ConstantsService, CONSTANTS_SERVICE_TOKEN, DynamicContentConfigurationProviderService, DynamicContentManagerService, LoginRouteResolverService, OnlineServiceLinkManagerService, ReachApplicationService, RouteConfiguration, RouteConfigurationProviderService, SystemSettingsManagerService, UserManagerService, ValidationManagerService } from '@core/index-services';
import { first } from 'rxjs/operators';

export const LoginComponentSelector = 'login';
@Component({
  selector: LoginComponentSelector,
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  public onlineServiceLinks: OnlineServiceLink[] = [];
  public onlineServiceLinkModel: any;
  protected onlineServiceLinkSubscriptions: OnlineServiceLinkSubscription[] = [];
  private friendlyNames: any = {};

  public loginCommand: Command;
  public loginForm: FormGroup;

  public get loginEnabled(): boolean { return this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.LOGIN.ENABLED) == true; }

  constructor(private formBuilder: FormBuilder
    , private onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , private router: Router
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private userManagerService: UserManagerService
    , private validationManagerService: ValidationManagerService
    , protected commandService: CommandService
  ) {
    this.modelToForm();
  }

  protected modelToForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(() => { this.validationManagerService.addFormErrors(this.loginForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames); });
  }

  ngOnInit() {
    if (this.userManagerService.currentUser) { this.router.navigate([RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]); }
    this.onlineServiceLinkSubscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Any, () => { return 0; }));

    this.loginCommand = this.commandService.create(this.canLoginCommandExecute, this.loginCommandExecute);

    this.friendlyNames.username = "Username";
    this.friendlyNames.password = "Password";

    this.validationManagerService.addFormErrors(this.loginForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames);
    this.onlineServiceLinkModel = this.onlineServiceLinkManagerService.getOnlineServiceLinksForSubscriptions(this.onlineServiceLinkSubscriptions);
  }

  protected canLoginCommandExecute = (): boolean => {
    return this.loginEnabled;
  }

  protected loginCommandExecute = () => {
    if (this.validationManagerService.hasClientApplicationValidationMessages || this.loginForm.invalid) return;

    this.userManagerService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
      .pipe(first())
      .subscribe({ next: () => { 
        if (this.userManagerService.intendedDestination) {
          this.router.navigateByUrl(this.userManagerService.intendedDestination);
          this.userManagerService.intendedDestination = null; // Reset the intended destination after navigation
        }
        else this.router.navigate([RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path], { queryParams: { abortProfileRefresh: true } }); } 
      });
  }
}

registerDynamicComponent(ReachScenarios.Default, LoginComponentKeys.Login, LoginComponent, LoginComponentSelector);

@Injectable({
  providedIn: 'root'
})
export class DefaultLoginRouteResolverService extends LoginRouteResolverService {
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService) {
    super(
      constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
   * Override in subclass to return the RouteConfiguration data to be used for this resolver.
   */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false, this.dynamicContentConfigurationProviderService.getConfigurationData(true, this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.LOGIN), this.authorizationConfigurationProviderService.getConfigurationData(true));
  }
}

