import { Inject, Injectable } from '@angular/core';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { from, of } from 'rxjs';
import { BusyManagerService } from './busy-manager.service';
import { FeatureManagerService, FEATURE_MANAGER_SERVICE_TOKEN } from './configuration/feature-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { ReachApplicationService } from './reach-application.service';
import { SystemSettingsManagerService } from './system-settings-manager.service';

@Injectable({ providedIn: 'root' })
export class BootstrapperService {
  private _isInitialized: boolean = false;
  private _isInitializing: boolean = false;

  constructor(private reachApplicationService: ReachApplicationService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(FEATURE_MANAGER_SERVICE_TOKEN) private featureManagerService: FeatureManagerService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private busyManagerService: BusyManagerService) {
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public bootstrapApplication() {
    if (this._isInitialized || this._isInitializing) {
      return of(this._isInitialized as any);
    }

    const doInit = async (): Promise<any> => {
      this._isInitializing = true;
      await this.systemSettingsManagerService.initialize().toPromise();
      await this.featureManagerService.initialize().toPromise();
      this._isInitialized = true;
      this._isInitializing = false;
      this.reachApplicationService.raiseApplicationInitializedEvent(this._isInitialized);
      return of(this._isInitialized).toPromise();
    };

    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.APPLICATION_INIT);
  }
}

