import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IOnlineEntitySearchResultItemDto } from './licensure-shared-dto';

export class OnlineEntitySearchResultItemDto extends SearchResultItemDto implements IOnlineEntitySearchResultItemDto {
	FirstName: string;
	MiddleName: string;
	LastName: string;
	City: string;
	EntityId: number;
	LicenseTypeProfessionalSuffixes: string;
	LicenseTypes: string;
	NameMatchOnHistoricName: boolean;
	DateOfBirth?: Date;
	EntityHasActiveCertifications: boolean;
	EntityHasExpiredCertifications: boolean;
	IsActiveSupervisor: boolean;
	ActiveSupervisorDesignations: string;
	SupervisorCity: string;
	SupervisorZip: string;

}
