import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appWizardStepDetail]'
})
export class WizardStepDetailDirective {

    constructor(public viewContainerRef: ViewContainerRef) { }

}
