<div class="reach-page-header">My Documents</div>

<p-table *ngIf="!groupByCategory" styleClass="p-datatable-sm reach-responsive-table" [value]="historyItems"
    [responsive]="true" responsiveLayout="stack">

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th>Type</th>
            <th>Subtype</th>
            <th style="width: 50%;">Document</th>
            <th>Date</th>
            <th class="p-text-right" style="width: 6em;">Download</th>
        </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-item>
        <tr style="width: 100%;">

            <!-- Type -->
            <td>
                <span class="p-column-title">Type</span>{{item.ActivityTypeDescription | whenEmpty: '-'}}
            </td>

            <!-- Subtype -->
            <td>
                <span class="p-column-title">Subtype</span>{{item.ActivitySubtypeDescription | whenEmpty: '-'}}
            </td>

            <!-- Document -->
            <td style="width: 50%;">
                <span class="p-column-title">Document</span>{{item.DocumentDisplayName | whenEmpty: '-'}}
            </td>

            <!-- Received -->
            <td>
                <span class="p-column-title">Date</span>{{item.CreatedDate | date:'MM/dd/yyyy' | whenEmpty: '-'}}
             </td>

            <!-- Buttons -->
            <td class="p-text-right">
                <span class="p-text-nowrap">

                    <!-- Download -->
                    <button pButton pRipple class="p-button-text" [icon]="getIcon(item)"
                        [command-button]="downloadCommand" [command-parameter]="item"
                        [pTooltip]="getTooltip(item)"></button>
                </span>
            </td>

        </tr>

    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="5">
                <span class="reach-text-muted"><em>No items</em></span>
            </td>
        </tr>
    </ng-template>

</p-table>


<p-treeTable *ngIf="groupByCategory" [value]="documentHistoryItems" [scrollable]="true"
    [tableStyle]="{'min-width':'50rem'}">

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th>Category</th>
            <th>Document</th>
            <th>Date</th>
            <th class="p-text-right">Download</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
            <!-- ActivityType -->
            <td *ngIf="rowNode.level === 0" colspan="4">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{ rowData.description }}
            </td>

            <!-- ActivitySubtype -->
            <td *ngIf="rowNode.level === 1" colspan="4">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{ rowData.description }}
            </td>

            <!-- Content -->
            <td *ngIf="rowNode.level === 2"></td> <!-- Empty cell for alignment -->
            <td *ngIf="rowNode.level === 2">
                {{ rowData.DocumentDisplayName }}
            </td>
            <td *ngIf="rowNode.level === 2">
                {{rowData.CreatedDate | date:'MM/dd/yyyy' | whenEmpty: '-'}}
            </td>
            <td *ngIf="rowNode.level === 2" class="p-text-right">
                <span class="p-text-nowrap">
                    <!-- Download -->
                    <button pButton pRipple class="p-button-text" [icon]="getIcon(rowData)"
                        [command-button]="downloadCommand" [command-parameter]="rowData"
                        [pTooltip]="getTooltip(rowData)"></button>
                </span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<!-- GroupByCategory -->
<div class="p-mt-4" [formGroup]="contentForm">
    <p-checkbox inputId="groupByCategory" formControlName="groupByCategory" label="Group By Category"
        [binary]="true"></p-checkbox>
</div>