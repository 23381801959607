import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ActivityDocumentSubmissionDto, IActivityDocumentSubmissionDto, IOnlineActivityDto, IOnlineActivityInfoDto } from '@coreShared/core-shared.module';
import { Observable, from } from "rxjs";
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DocumentService } from './document.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({ providedIn: 'root' })
export class OnlineActivityService extends ReachHttpClientService {
  uri: string = '';
  private uriActivityDocument: string;

  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    private documentService: DocumentService
  ) {

    // Base
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = `${this.apiRootUri}/OnlineActivityInfo`;
    this.uriActivityDocument = `${this.apiRootUri}/ActivityDocument`;
  }

  public getById(id: number): Observable<IOnlineActivityDto> {
    return this.get<IOnlineActivityDto>(`${this.apiRootUri}/OnlineActivity/${id}`);
  }

  public getInfoById(id) {
    if (!id) { throw this.argumentExceptionService.create("id").log(); }
    return this.get<IOnlineActivityInfoDto>(`${this.uri}/${id}`);
  }

  /**
  * API for correspondence-document submission.
  */
  public submitDocuments(submissionDto: IActivityDocumentSubmissionDto): Observable<IActivityDocumentSubmissionDto> {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doSave = async (): Promise<IActivityDocumentSubmissionDto> => {

      // First, upload documents.
      // Uploads new documents and then puts them back onto the IFileUploadDtoHost and returns it.
      let fileUploadHost = await this.documentService.uploadDocuments(submissionDto);
      if (!(fileUploadHost as any).IsValid) return;

      // Submit (AKA save) the FileUploadDtoHost if any documents were added.
      if (fileUploadHost.DocumentIds.length > 0) {
        submissionDto.DocumentIds = fileUploadHost.DocumentIds;
        return await this.post<IActivityDocumentSubmissionDto>(`${this.uriActivityDocument}/Submit`, submissionDto).toPromise();
      }
      else return submissionDto; // Unchanged.

    }

    // Kick off the function defined above. 
    return from(doSave());
  }

  /**
  * API for correspondence-document submission.
  */
  public cancelActivityCorrespondence(dto: IActivityDocumentSubmissionDto) {
    let url = `${this.uriActivityDocument}/Cancel`;
    return this.post<ActivityDocumentSubmissionDto>(url, dto);
  }
}
