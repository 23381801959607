import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import * as _l from 'lodash-es';

import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from "@core/core.module";
import { IHttpResponseContent, SearchResultListDto } from "@coreShared/core-shared.module";
import { EducationProgramDto, IEducationProgramFacultyDto, IEducationProgramFacultySearchCriteriaDto } from "@licensureShared/licensure-shared.module";


@Injectable({
  providedIn: 'root'
})
export class EducationProgramFacultyService extends ReachHttpClientService {
  private uri: string;
  private searchUri: string;
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/licensure/EducationProgramFaculty`;
    this.searchUri = `${this.apiRootUri}/licensure/EducationProgramFacultySearch`;   
  }

  /**
 * Search for licenses.
 * @param criteria IEducationProgramFacultySearchCriteriaDto
 * @returns SearchResultListDto<>
 */
  public search(criteria: IEducationProgramFacultySearchCriteriaDto) {
    return this.get<SearchResultListDto<any>>(`${this.searchUri}`, { params: criteria as any });
  }

  /**
   * Gets an initialized application.
   * @param entityId 
   */
  public initializeApplication(entityId) {

    // TODO: convert this over to RxJs
    const createNewProgramFaculty = (): Observable<IEducationProgramFacultyDto> => {
      return this.get<IEducationProgramFacultyDto>(`${this.uri}/initialize`);
    };

    // API call.
    const saveProgramFaculty = (programFaculty: IEducationProgramFacultyDto): Observable<IEducationProgramFacultyDto> => {
      //application.EntityId = entityId;
      programFaculty.Id = entityId;
      return this.post<IEducationProgramFacultyDto>(`${this.uri}/initialize/item`, programFaculty);
    };

    let res = createNewProgramFaculty().pipe(mergeMap((val: IEducationProgramFacultyDto) => saveProgramFaculty(val)));
    return res;
  }

  /**
   * Get the application with the supplied ID.
   * @param id 
   */
  public getById(id): Observable<IEducationProgramFacultyDto> {
    return this.get<IEducationProgramFacultyDto>(`${this.uri}/${id}`);
  }

  /**
   * Saves the program-faculty.
   * @param programFacultyToSave
   */
  public save(programFacultyToSave: IEducationProgramFacultyDto): Observable<IEducationProgramFacultyDto> {

    // Updating.
    if (programFacultyToSave.Id) {
      return this.put<IEducationProgramFacultyDto>(this.uri, programFacultyToSave);
    }

    // Creating.
    else {
      return this.post<IEducationProgramFacultyDto>(this.uri, programFacultyToSave);
    }
  }

  /**
   * Wraps the specified in an HttpResponseContent.
   * @param application EducationProgramApplicationDto in an IHttpResponseContent<EducationProgramApplicationDto>.
   */
  private asResponseContent(programFaculty: IEducationProgramFacultyDto): IHttpResponseContent<IEducationProgramFacultyDto> {
    return this.utilitiesService.asResponseContent(programFaculty);
  }
}
