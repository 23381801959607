import { Component, Inject, OnChanges, OnInit, Optional, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import * as _l from 'lodash-es';
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { ReachScenarios } from "src/app/core/index-constants";
import { ReachModelAwareComponent } from "src/app/core/index-directives";
import { Command, DialogSettings, registerDynamicComponent } from "src/app/core/index-models";
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, REGION_MODEL_INJECTOR_TOKEN, ReachModelConfigurationInjector, SystemSettingsManagerService, UtilitiesService } from "src/app/core/index-services";
import { AddressTypeDto, EntityAddressDto, IAddressLocationTypeDto, ICountyDto, IEntityAddressDto, ILookupDto, IStateDto } from "src/app/coreShared/core-shared.module";
import { EntityEmploymentListDto, IEmploymentDto, IEmploymentPositionTitleDto, IEmploymentTypeDto } from "src/app/licensureShared/licensure-shared.module";
import { EntityEmploymentService, LicensureListService } from "../../licensure-core.module";
import { ILicensureConstantsProviderService } from "../../services/licensure-constants-provider.service";
import { EmploymentListEditorComponent } from "./editor/employment-list-editor.component";
import { EmploymentListConfiguration } from "./model/employment-list-configuration";
import { EmploymentListModel } from "./model/employment-list-model";

export const EmploymentListComponentKey: string = "employmentList";
export const EmploymentListComponentSelector: string = "employment-list";

@Component({
  selector: EmploymentListComponentSelector,
  templateUrl: './employment-list.component.html',
  styleUrls: ['./employment-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmploymentListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmploymentListComponent),
      multi: true
    }
  ]
})
export class EmploymentListComponent extends ReachModelAwareComponent implements OnInit, ControlValueAccessor {

  get Model(): EmploymentListModel { return this.model as EmploymentListModel; }
  get Config(): EmploymentListConfiguration { return this.config as EmploymentListConfiguration; }
  get RemoveButtonVisible(): boolean { return this.filteredEmploymentItems.some(item => item.Id == 0); }

  get ColumnCount(): number {
    let count = 4;
    if (this.Config?.employmentTypeEnabled) count += 1;
    if (!this.Config.isAddressColumnVisible) count += 1;
    if (this.Config.isPositionColumnVisible && this.Config.positionPickListEnabled) count += 1;
    if (this.Config.isPositionColumnVisible && !this.Config.positionPickListEnabled) count += 1
    if (this.filteredEmploymentItems.length > 0) count += 1;
    return count;
  }

  public okCommand: Command;
  public createCommand: Command;
  public presentItemEditorCommand: Command;
  public removeItemCommand: Command;

  public contentForm: FormGroup = new FormGroup({});
  public friendlyNames: any;
  public dialogSettings: DialogSettings;

  public possibleStates: IStateDto[];
  public possibleCounties: ICountyDto[];
  public possibleAddressLocationTypes: IAddressLocationTypeDto[];
  public possiblePositionTitles: IEmploymentPositionTitleDto[];
  public possibleEmploymentTypes: IEmploymentTypeDto[];
  public possibleEmploymentSettings: ILookupDto[];
  public possibleEmploymentSectors: ILookupDto[];

  filteredEmploymentItems: IEmploymentDto[] = [];
  employeeAsLicenseIdEnabled: boolean;
  employerAsFacilityLicenseIdEnabled: boolean;

  constructor(@Optional() @Inject(REGION_MODEL_INJECTOR_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
    , protected commandService: CommandService
    , protected utilitiesService: UtilitiesService
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , protected busyManagerService: BusyManagerService
    , protected licensureListService: LicensureListService
    , protected entityEmploymentService: EntityEmploymentService
    , protected systemSettingsManagerService: SystemSettingsManagerService) {

    super(reachModelConfigurationInjector);

    this.modelToForm();
  }

  modelToForm(): void {
    this.contentForm = new FormGroup({ IncludeHistoric: new FormControl() });
    this.contentForm.get('IncludeHistoric').valueChanges.subscribe(() => this.filter())
  }

  ngOnInit(): void {
    this.employeeAsLicenseIdEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYEE_AS_LICENSE_ID_ENABLED);
    this.employerAsFacilityLicenseIdEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYER_AS_FACILITY_LICENSE_ID_ENABLED);

    this.filter();

    this.Model.entityEmploymentList.ValidationMode = this.constantsService.VALIDATION_MODES.WEB_EMPLOYMENT_LIST;

    this.initCommands();
    this.loadLookupLists();
  }

  filter(): void {
    this.filteredEmploymentItems = this.Model.entityEmploymentList.EmploymentList.filter(employmentDto => !employmentDto.IsDeleted);
    if (this.employeeAsLicenseIdEnabled) this.filteredEmploymentItems = this.filteredEmploymentItems.filter(employmentDto => (this.Model?.licenseId == null || employmentDto.IndividualLicenseId == this.Model.licenseId));
    if (!this.contentForm.get('IncludeHistoric').value) this.filteredEmploymentItems = this.filteredEmploymentItems.filter(item => (!item.EndDate) || (new Date(item.EndDate) > new Date()));
  }

  protected loadPositionTitles(): Observable<IEmploymentPositionTitleDto[]> {
    if (this.Config.positionPickListEnabled) { return this.licensureListService.getEmploymentPositionTitles(); }
    return of([]);
  }

  protected loadEmploymentTypes(): Observable<IEmploymentTypeDto[]> {
    if (this.Config.employmentTypeEnabled) { return this.licensureListService.getEmploymentTypes(); }
    return of([]);
  }

  protected loadLookupLists(): void {
    forkJoin([
      this.licensureListService.getStates(),
      this.licensureListService.getCounties(),
      this.licensureListService.getAddressLocationTypes(),
      this.loadPositionTitles(),
      this.loadEmploymentTypes(),
      this.licensureListService.getEmploymentSettings(),
      this.licensureListService.getEmploymentSectors(),
    ]).pipe(
      map(([states, counties, locationTypes, positionTitles, employmentTypes, settings, sectors]) => {
        this.possibleStates = states;
        this.possibleCounties = counties;
        this.possibleAddressLocationTypes = locationTypes;
        this.possiblePositionTitles = positionTitles.filter(item => !item.ApplicableProfessionTypeCodes || (item as IEmploymentPositionTitleDto).ApplicableProfessionTypeCodes.includes(this.Model.licenseTypeId));
        this.possibleEmploymentTypes = employmentTypes;
        this.possibleEmploymentSettings = settings;
        this.possibleEmploymentSectors = sectors;
      })
    ).subscribe();
  }

  private initCommands(): void {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.createCommand = this.commandService.create(this.canCreateCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (employmentItem: IEmploymentDto) => {
    this.mapAddressInformation(employmentItem);
    this.Model.entityEmploymentList.EmploymentList = this.utilitiesService.addOrReplace(this.Model.entityEmploymentList.EmploymentList, employmentItem);
    this.filter();
    this.save();
  }

  private mapAddressInformation(employmentItem: IEmploymentDto) {
    const address = employmentItem.Addresses[0];

    if (address.AddressLocationType == null) {
      address.AddressLocationType = { Description: "Business", Id: 2, IsNew: false } as IAddressLocationTypeDto;
    }

    if (!address.Line1) {
      address.Line1 = "* blank *";
    }

    address.IsDirty = true;
  }

  protected save(): void {
    if (this.Config.save === null) {
      this.entityEmploymentService.update(this.Model.entityEmploymentList).pipe(
        map((entityEmploymentList: EntityEmploymentListDto) => {
          this.Model.entityEmploymentList = entityEmploymentList;
        }),
      ).subscribe();
    } else {
      this.Config.save(this.Model.entityEmploymentList).subscribe();
    }
  }

  protected canCreateCommandExecute = (): boolean => {

    if (!this.Config.positionPickListEnabled) return true;
    else if (this.possiblePositionTitles && this.possiblePositionTitles.find(item => item.IsAddableOnline)) return true;

    return false;
  }

  protected canPresentEditorCommandExecute = (employmentItem: IEmploymentDto): boolean => {
    return !this.Config.positionPickListEnabled || employmentItem?.EmploymentPositionTitle?.IsEditableOnline;
  }
  
  protected presentEditorCommandExecute = (employmentItem: IEmploymentDto) => {
    this.dialogSettings = this.getDialogSettings(employmentItem);
    this.dialogSettings.isOpen = true;
  }

  public getDialogSettings(employmentItem: IEmploymentDto): DialogSettings {
    let dialogSettings = new DialogSettings(null
      , ReachScenarios.Default
      , EmploymentListEditorComponent
      , EmploymentListEditorComponent.name
      , this.getDialogTitle(employmentItem)
      , this.getDialogModel(employmentItem)
      , false
      , false
      , true);

    dialogSettings.initializationData.config = this.Config;
    dialogSettings.initializationData.possibleStates = this.possibleStates;
    dialogSettings.initializationData.possibleCounties = this.possibleCounties;
    dialogSettings.initializationData.possibleLocationTypes = this.possibleAddressLocationTypes;
    dialogSettings.initializationData.possiblePositionTitles = this.possiblePositionTitles;
    dialogSettings.initializationData.possibleEmploymentTypes = this.possibleEmploymentTypes;
    dialogSettings.initializationData.possibleEmploymentSettings = this.possibleEmploymentSettings;
    dialogSettings.initializationData.possibleEmploymentSectors = this.possibleEmploymentSectors;
    dialogSettings.okCommand = this.okCommand;
    return dialogSettings;
  }

  protected getDialogTitle(employmentItem: IEmploymentDto): string {
    return employmentItem ? "Employment for Employer - " + employmentItem.EmployerName : "Employment - {new}"
  }

  protected getDialogModel(employmentItem: IEmploymentDto): IEmploymentDto {
    const model = employmentItem
      ? _l.cloneDeep(employmentItem)
      : this.utilitiesService.withLocalId({
        Id: 0,
        IsNew: true,
        LocalId: this.utilitiesService.guid(),
        EntityId: this.Model.entityId,
        IndividualLicenseId: this.employeeAsLicenseIdEnabled ? this.Model.licenseId : null,
        Addresses: []
      });

    if (model?.Addresses?.length === 0) {
      model.Addresses.push(this.createNewAddress());
    }

    return model;
  }

  protected createNewAddress(): IEntityAddressDto {
    let entityAddress = new EntityAddressDto();
    entityAddress.AddressLocationType = null;
    entityAddress.IsCurrent = true;
    entityAddress.EntityId = this.Model.entityId;
    entityAddress.FunctionNumber = "0";
    entityAddress.ProfessionTypeCode = "  "
    entityAddress.AddressType = new AddressTypeDto();
    entityAddress.AddressType.Id = this.constantsService.ADDRESS_TYPES.EMPLOYMENT;
    entityAddress.AddressType.Description = "Employment";
    return entityAddress;
  }

  protected canRemoveItemCommandExecute = (employmentItem: IEmploymentDto): boolean => employmentItem.Id == 0;

  protected removeItemCommandExecute = (employmentItem: IEmploymentDto) => {
    if (!employmentItem.LocalId) return;

    const index = this.Model.entityEmploymentList.EmploymentList.findIndex(x => x.LocalId === employmentItem.LocalId);
    if (index !== -1) this.Model.entityEmploymentList.EmploymentList.splice(index, 1);

    this.filter();
  }

  // ==================================== CVA ====================================

  validate(): ValidationErrors | null {
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames);
    return this.contentForm.valid ? null : errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void { this.onTouched = fn; }
  writeValue() { }
  setDisabledState?(isDisabled: boolean): void { isDisabled ? this.contentForm.disable() : this.contentForm.enable(); }
}

registerDynamicComponent(ReachScenarios.ProfileLanding, EmploymentListComponentKey, EmploymentListComponent, EmploymentListComponentSelector);
