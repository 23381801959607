import { ISourceCodeDto,
IWebCartFeeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebCartFeeDto extends BaseDto implements IWebCartFeeDto {
	SourceCode: string;
	Value?: number;
	IsPercent?: boolean;
	Description: string;
	DisplayOrder: number;
	IsInactive: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	SourceCodeItem: ISourceCodeDto;

}
