import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IApplicationEcfmgDto } from './licensure-shared-dto';

export class ApplicationEcfmgDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IApplicationEcfmgDto {
	ApplicationNbr: number;
	EcfmgNbr: string;
	EcfmgDate: string;
	CsaDate: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
