import { IDomainReasonForChangeDtoHost,
IEntityGroupMemberSearchCriteriaDto } from './core-shared-dto';
import { SearchCriteriaDto } from './search-criteria-dto';

export class EntityGroupMemberSearchCriteriaDto extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, IEntityGroupMemberSearchCriteriaDto {
	City: string;
	GroupId?: number;
	SchoolName: string;
	State: string;
	Country: string;
	Zip: string;
	SearchCityInName?: boolean;

}
