<div id="topBar" class="layout-topbar reach-layout-topbar">

    <div class="p-d-flex p-flex-nowrap p-ai-center">

    <!-- Menu Button -->
    <div id="menuButton">
      <a class="menu-button" (click)="onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>
    </div>

    <!-- Logo / Agency Name-->
    <div id="agencyName">
      <a [routerLink]="['/']" class="logo reach-logo">
        <!-- Display logo and agency name aligned bottom for branding compliance -->
        <div class="p-d-flex p-flex-nowrap p-ai-end">
          <div>
            <img alt="logo" [src]="logoImage">
          </div>
          <div class="p-mx-2">
            <span class="reach-agency-name">{{brand}}</span>
          </div>
        </div>
      </a>
    </div>

    <!-- Notification Toggle Buttons -->
    <div id="notificationToggles" class="p-d-flex">
      <div class="p-ml-3">
        <!-- Validation Messages Toggle -->
        <message-toggle-button class="nav navbar-nav" [messageScope]="validationErrorScope" labelText=""
          iconClass="fa fa-exclamation" badgeSeverity="danger"></message-toggle-button>
      </div>
      <div class="p-ml-3">
        <!-- Notification Messages Toggle -->
        <message-toggle-button class="nav navbar-nav" [messageScope]="notificationScope" labelText=""
          iconClass="fa fa-info" badgeSeverity="info"></message-toggle-button>
      </div>
    </div>

  </div>

  <ul #topbarMenu class="topbar-menu p-jc-end p-pr-2">

    <!-- Overview/Home -->
    <li *ngIf="homeCommand.enabled" class="p-px-2">
      <a [command-button]="homeCommand" command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
        [hidden]="!reachApplicationService.logoutCommand.enabled">
        <i class="fa fa-home p-mr-1"></i>
      </a>
    </li>

    <!-- Login/Logout -->
    <li *ngIf="reachApplicationService.loginCommand.enabled" class="p-px-2">
      <a *ngIf="reachApplicationService.loginCommand.enabled" [command-button]="reachApplicationService.loginCommand"
        command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
        [hidden]="!reachApplicationService.loginCommand.enabled" class="p-text-nowrap">
        <i class="fa fa-sign-in p-mr-1"></i>
        Log In
      </a>
    </li>

    <!-- ==================== Help Menu ==================== -->
    <li class="topbar-submenu p-px-2">

      <!-- Button -->
      <a *ngIf="reachApplicationService.logoutCommand.enabled" (click)="toggleMenu($event, 2)">
        <i class="fa fa-question-circle p-mr-1"></i>
      </a>

      <ul [@overlayMenuAnimation]="'visible'" *ngIf="activeMenuIndex === 2"
        (@overlayMenuAnimation.start)="onOverlayMenuEnter($event)">

        <li *ngFor="let menuItem of helpMenuItems | sort:'DisplayOrder'"
          [ngClass]="{'dropdown-header' : menuItem.isHeader, 'dropdown-item' : !menuItem.isHeader}">
          <span *ngIf="menuItem.isHeader">
            <i class="fa fa-question"></i>&nbsp;&nbsp;{{menuItem.menuGroupName}}
          </span>
          <a *ngIf="!menuItem.isHeader && menuItem.command.enabled"
            [command-button]="menuItem.command">{{menuItem.command.commandText}}</a>
        </li>

      </ul>
    </li>

    <!-- ======================= Cart ======================= -->
    <li cart-summary class="p-px-2">
    </li>


    <!-- ======================= User ======================= -->
    <li class="topbar-submenu p-px-2">
      <a *ngIf="reachApplicationService.logoutCommand.enabled" tabindex="0" (click)="toggleMenu($event, 1)">
        <i class="fa fa-user p-mr-1"></i>
        {{reachApplicationService.userDisplayName}}
      </a>

      <!-- User - Child Menu -->
      <ul [@overlayMenuAnimation]="'visible'" *ngIf="activeMenuIndex === 1"
        (@overlayMenuAnimation.start)="onOverlayMenuEnter($event)">
        <li>
          <a *ngIf="reachApplicationService.logoutCommand.enabled"
            [command-button]="reachApplicationService.logoutCommand" command-description="NavbarLogout"
            [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" [hidden]="!reachApplicationService.logoutCommand.enabled">
            <i class="fa fa-sign-out p-mr-2"></i>
            Log Out
          </a>
        </li>
        <li>
          <a *ngIf="reachApplicationService.passwordChangeCommand.enabled"
            [command-button]="reachApplicationService.passwordChangeCommand" command-description="NavbarPasswordChange"
            [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
            [hidden]="!reachApplicationService.passwordChangeCommand.enabled">
            <i class="fa fa-gear p-mr-2"></i>
            Change Password
          </a>
        </li>
        <li>
          <a *ngIf="reachApplicationService.changeSecurityQuestionsCommand.enabled"
            [command-button]="reachApplicationService.changeSecurityQuestionsCommand"
            command-description="NavbarSecurityQuestionsChange" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
            [hidden]="!reachApplicationService.changeSecurityQuestionsCommand.enabled">
            <i class="fa fa-gear p-mr-2"></i>
            Change Security Questions
          </a>
        </li>
      </ul>
    </li>


  </ul>

</div>