import { IStepDataDto,
IWebServiceHistoryDetailDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebServiceHistoryDetailDto extends BaseDto implements IWebServiceHistoryDetailDto {
	ServiceHistoryId: number;
	StepOrder: number;
	ServiceViewId: number;
	StepDate: Date;
	StepData: IStepDataDto;
	StepContent: string;
	ErrorCount: number;
	StepErrors: string;
	StepTitle: string;
	StepAction: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
