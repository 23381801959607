import { IDomainIdentifierTypeDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainIdentifierTypeDto extends LookupDto implements IDomainIdentifierTypeDto {
	IsCredentialVerification: boolean;
	DisplayOnline: boolean;
	EditableOnline: boolean;
	DomainId: number;
	FormatRegex: string;
	FormatMessage: string;
	ViewRight: string;
	CreateRight: string;
	UpdateRight: string;
	DeleteRight: string;
}
