<!--Top-level Toaster control for messages with no key specified.-->
<p-toast [key]="toasterErrorScope" position="bottom-right" [baseZIndex]="10000"></p-toast>

<!-- Busy Indicator/Overlay -->
<p-blockUI [blocked]="showLoadingOverlay" [baseZIndex]="10000"
  [styleClass]="reachApplicationService.isApplicationInInitialized ? 'reach-blockui-transparent' : 'reach-blockui-opaque'">

  <div>
    <div class="busy-indicator">
      <!-- State Logo -->
      <img style="height: 15px; margin-left: 30px; margin-top: 40px;" [src]="logoImage">

      <!-- Pulse Animation -->
      <div class="signal"></div>

      <!-- Welcome (while initializing provider) -->
      <div *ngIf="!reachApplicationService.isApplicationInInitialized" class="busy-indicator-welcome" >
        Welcome!
      </div>
    </div>

  </div>


</p-blockUI>

<!-- div used with ngPrint.-->
<div id="print-section" class="visible-print"></div>

<!-- Layout -->
<div class="layout-wrapper reach-layout-wrapper p-input-filled">

  <!-- Application Top Bar -->
  <reach-app-topbar (menuButtonClick)="onMenuButtonClick()"></reach-app-topbar>

  <!-- Side Menu (mobile only) -->
  <reach-app-menu [active]="menuActive"></reach-app-menu>

  <!-- Overlay to prevent interaction while side menu is open -->
  <div class="layout-mask" [ngClass]="{'layout-mask-active': menuActive}" (click)="onMaskClick()"></div>

  <!-- Validation and notification message banners -->
  <message-summary [messageScope]="validationSummaryScope" severity="error" labelText="Attention"></message-summary>
  <message-summary [messageScope]="notificationSummaryScope" severity="info" labelText="Notifications"></message-summary>

  <!-- Content Area -->
  <div class="layout-content reach-layout-content">
    <router-outlet></router-outlet>

    <!-- Provider specific (if any) -->
    <ng-template custom-provider-host></ng-template>
  </div>

  <!-- Footer -->
  <reach-app-footer></reach-app-footer>

</div>

<!-- CONFIRMATION DIALOG (global instance - DO NOT ADD elsewhere) -->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" global="true" [closable]="false" appendTo="body"></p-confirmDialog>
