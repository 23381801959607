import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEducationProgramFacultyCredentialDto } from './licensure-shared-dto';

export class EducationProgramFacultyCredentialDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEducationProgramFacultyCredentialDto {
	EducationProgramFacultyId: number;
	Degree: string;
	DegreeYear?: number;
	MajorSpecialty: string;
	SchoolName: string;
	SchoolCity: string;
	SchoolState: string;
	SchoolCountry: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
