/**
 * Scope key for various Reach features/scenarios. Defines a scope for the set of components belonging to a given feature.
 */
export class ReachScenarios {
  public static readonly Default = 'default';
  public static readonly ActivityWizard: string = 'activity-wizard';
  public static readonly InvoiceWizard: string = 'invoice-wizard';
  public static readonly AutoVerificationWizard: string = 'auto-verification-wizard';
  public static readonly CeSponsorshipWizard: string = 'ce-sponsorship-wizard';
  public static readonly CeAuditWizard: string = 'ce-audit-wizard';
  public static readonly LicenseRenewalWizard: string = 'license-renewal-wizard';
  public static readonly LicenseRenewalFinalFeeWizard: string = 'license-renewal-final-fee-wizard';
  public static readonly ApplicationWizard: string = 'application-wizard';
  public static readonly ProfessionalProfileWizard: string = 'professional-profile-wizard';
  public static readonly LandingPage: string = 'landing-page';
  public static readonly OnlineEntitySearch: string = 'online-entity-search';
  public static readonly RegistrationWizard: string = 'registration-wizard';
  public static readonly ForgotPasswordWizard: string = 'forgot-password-wizard';
  public static readonly DuplicateCardWizard: string = 'duplicate-card-wizard';
  public static readonly SecondLicenseCardWizard: string = 'second-license-card-wizard';
  public static readonly EducationProgramCompletionWizard: string = 'education-program-completion';
  public static readonly EducationProgramApplicationWizard: string = 'education-program-application-wizard';
  public static readonly AnnualComplianceWizard: string = 'annual-compliance-wizard';
  public static readonly LicenseReinstatementWizard: string = 'license-reinstatement-wizard';
  public static readonly ExamRetakeWizard: string = 'exam-retake-wizard';
  public static readonly CriminalBackgroundFeeWizard: string = 'criminal-background-fee-wizard';
  public static readonly LicenseVerificationWizard: string = 'license-verification-wizard';
  public static readonly DataRequestWizardSelector: string = 'data-request-wizard-selector';
  public static readonly DataRequestCannedWizard: string = 'data-request-canned-wizard';
  public static readonly DataRequestAdHocWizard: string = 'data-request-adhoc-wizard';
  public static readonly DataRequestStatisticalWizard: string = 'data-request-statistical-wizard';
  public static readonly JurisprudenceWizard: string = 'jurisprudence-wizard';
  public static readonly ApplicationFinalFee: string = 'application-final-fee';
  public static readonly OnlineComplaint: string = 'online-complaint';
  public static readonly LicenseRenewalIlcWizard: string = 'license-renewal-ilc-wizard';
  public static readonly CeSponsorSearch: string = 'ce-sponsor-search';
  public static readonly CourseSearch: string = 'course-search';
  public static readonly ProfileLanding: string = 'profile-landing';
  public static readonly LicenseStatusChangeWizard: string = 'license-status-change-wizard';
}