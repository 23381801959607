import { IDomainReasonForChangeDtoHost,
ISearchCriteriaDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class SearchCriteriaDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISearchCriteriaDto {
	MinimumFilterRequirementsMessage: string;
	TopResults?: number;
	IncludeAllResults: boolean;
	IncludeQuery: boolean;

}
