import { IDomainFormAnswerDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainFormAnswerDto extends LookupDto implements IDomainFormAnswerDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	IsExplanationRequired: boolean;
	IsAnswer: boolean;
	SectionQuestionId: number;

}
