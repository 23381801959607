import { Injectable, Inject } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { IOnlineCeAuditRecordsSubmissionDto } from '@licensureShared/licensure-shared.module';
import {
  ArgumentExceptionService,
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService,
  DocumentService
} from '@core/core.module';

/**
 * Data service for API calls for License data.
 */
@Injectable({
  providedIn: 'root'
})
export class CeAuditRecordsSubmissionService extends ReachHttpClientService {

  // FIELDS
  uri: string = null;

  // CTOR
  constructor(

    // ReachHttpClientService
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,

    // Custom
    utilitiesService: UtilitiesService,
    private argumentExceptionService: ArgumentExceptionService,
    private documentService: DocumentService
  ) {

    // Base
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/OnlineCeAuditRecordsSubmission", this.apiRootUri);
  }

  public initialize(domainAuditId: number): Observable<IOnlineCeAuditRecordsSubmissionDto> {
    return this.post<IOnlineCeAuditRecordsSubmissionDto>(`${this.uri}/Initialize/DomainAuditId/${domainAuditId}`, null);
  }

  public save(submissionDto: IOnlineCeAuditRecordsSubmissionDto) {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doSave = async (): Promise<any> => {

      if (submissionDto.Documents.filter(item => item.IsNew).length > 0) {
        let documentResults = (await this.documentService.postDocumentData(submissionDto.Documents.filter(item => item.IsNew)).toPromise()).UploadedDocuments;
        submissionDto.DocumentIds = documentResults.map(item => item.Id);
      }

      return await this.post<IOnlineCeAuditRecordsSubmissionDto>(`${this.uri}/Create`, submissionDto).toPromise();
    }

    // Kick off the doCreateEducationItem() function defined above.
    return from(doSave());
  }

  public save2(submission: IOnlineCeAuditRecordsSubmissionDto): Observable<IOnlineCeAuditRecordsSubmissionDto> {

    // Must have a submission
    if (!submission) {
      this.argumentExceptionService.create("submission").log();
    }

    return this.post<IOnlineCeAuditRecordsSubmissionDto>(`${this.uri}/Create`, submission);

  }
}
