import { IChallengeInfoDto,
IDomainReasonForChangeDtoHost,
IOnlineAccountTypeDto,
IUserAccountDto, 
IUserProfileInfoDto} from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class UserAccountDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IUserAccountDto {
	EntityId: number;
	UserName: string;
	UserPassword: string;
	ConfirmUserPassword: string;
	IsSupportPasswordSet: boolean;
	SupportPassword: string;
	SecurityQuestion1: string;
	SecurityAnswer1: string;
	SecurityQuestion2: string;
	SecurityAnswer2: string;
	PasswordResetVerificationTokenExpireDate?: Date;
	OnlineAccountTypeId?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	Challenge: IChallengeInfoDto;
	OnlineAccountType: IOnlineAccountTypeDto;
	profile: IUserProfileInfoDto;
}
