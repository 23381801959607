import { IBaseDto,
IDomainIdentifierDtoHost,
IOnlineUserSearchCriteriaDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class OnlineUserSearchCriteriaDto extends BaseDto implements IBaseDto, IOnlineUserSearchCriteriaDto {
	LicenseReferenceValue: string;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	BirthDate?: Date;
	Ssn: string;
	FirmNumber: string;
	CepNumber: string;
	EmailAddress: string;
	SecurityCode: string;
	IsIndividual: boolean;
	OnlineAccountTypeId: number;
	ProcessTypeId?: number;
	DomainIdentifierHost: IDomainIdentifierDtoHost;
	PasswordVerificationToken: string;

}
