<!-- Dynamic Content -->
<div *ngIf="!hasZeroBalance">
  <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
    [minorKey]="wizard.selectedStep.MinorKey + '.WithFee'"></page-summary-text>
</div>
<div *ngIf="hasZeroBalance">
  <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
    [minorKey]="wizard.selectedStep.MinorKey + '.NoFee'"></page-summary-text>
</div>

<!-- PrimeNG Table -->
<p-table *ngIf="adjustmentsExist" styleClass="p-datatable-sm" [value]="wizard.onlineServiceHistory.Process.Fees"
  [responsive]="true">

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>

      <th width="50%">Payment Information</th>
      <th width="25%" class="text-right">Amount</th>

    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- Information -->
      <td>{{item.SourceCodeItem.Description}}</td>

      <!-- Amount -->
      <td class="text-right">{{item.Amount | currency}}</td>

    </tr>
  </ng-template>

  <!-- Footer -->
  <ng-template pTemplate="footer" let-sale>
    <tr>

      <td><b>Total Amount</b></td>
      <td class="text-right"><b>{{wizard.onlineServiceHistory.TotalFees | currency}}</b></td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="5">
        <span class="reach-text-muted"><em>No entries.</em></span>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- PrimeNG Table -->
<p-table *ngIf="!adjustmentsExist" styleClass="p-datatable-sm" [value]="wizard.onlineServiceHistory.Process.Fees"
  [responsive]="true">

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>

      <th width="70%">Payment Information</th>
      <th width="30%" class="text-right">Amount</th>

    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- Information -->
      <td>{{item.SourceCodeItem.Description}}</td>

      <!-- Amount -->
      <td class="text-right">{{item.Amount | currency}}</td>

    </tr>
  </ng-template>

  <!-- Footer -->
  <ng-template pTemplate="footer" let-sale>
    <tr>

      <td><b>Total Amount</b></td>
      <td class="text-right"><b>{{wizard.onlineServiceHistory.TotalFees | currency}}</b></td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="5">
        <span class="reach-text-muted"><em>No entries.</em></span>
      </td>
    </tr>
  </ng-template>
</p-table>