import { IDomainReasonForChangeDtoHost,
IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEmploymentDto,
ISupervisionPlanDto,
ISupervisionPlanFormDto,
ISupervisionPlanHoursDto,
ISupervisionPlanSupervisorDto,
ISupervisionVerificationDto } from './licensure-shared-dto';

export class SupervisionPlanDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisionPlanDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	EntityId?: number;
	LicenseId?: number;
	ApplicationNbr?: number;
	ReceivedDate?: Date;
	FormId?: number;
	StatusId?: number;
	EmploymentId?: number;
	BeginDate?: Date;
	EndDate?: Date;
	HoursPerWeek?: number;
	DirectClinicalPercent?: number;
	PracticeLocation: string;
	NarrativeOfPopulation: string;
	NarrativeOfModalities: string;
	NarrativeOfProcess: string;
	IsNarrativeOnFile?: boolean;
	Form: ISupervisionPlanFormDto;
	ReferenceValue: string;
	Status: any;
	Employment: IEmploymentDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	Supervisors: ISupervisionPlanSupervisorDto[];
	SupervisionPlanHours: ISupervisionPlanHoursDto[];
	Verifications: ISupervisionVerificationDto[];

}
