import { IDomainFormSectionDto,
ILookupDomainFormDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class LookupDomainFormDto extends LookupDto implements ILookupDomainFormDto {
	DomainFormTypeId?: number;
	PassPercentage?: number;
	IsAutoScored: boolean;
	Sections: IDomainFormSectionDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
