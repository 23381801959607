import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, noop, Observable, of, throwError } from 'rxjs';
import { AuthorizationConfigurationProviderService } from '../authorization-configuration-provider.service';
import { BootstrapperService } from '../bootstrapper.service';
import { DynamicContentConfigurationProviderService } from '../dynamic-content-configuration-provider.service';
import { RouteConfiguration, RouteConfigurationProviderService } from '../route-configuration-provider.service';
import { BusyManagerService } from './../busy-manager.service';
import { ConstantsService } from './../constants-provider.service';
import { DynamicContentManagerService } from './../dynamic-content-manager.service';
import { ReachApplicationService } from './../reach-application.service';
import { UserManagerService } from './../user-manager.service';

/**
 * Base class for Reach Route Resolvers. These services pre-fetch data needed by the target component when the route is resolved during navigation. Generic type TClass is the type of route data returned in the observable from the resolve function.
 */
export abstract class RouteResolverService<TClass> {
  protected routeData: any;
  protected routeConfiguration: RouteConfiguration;

  constructor(protected constantsService: ConstantsService
    , protected userManagerService: UserManagerService
    , protected busyManagerService: BusyManagerService
    , protected bootstrapperService: BootstrapperService
    , protected dynamicContentManagerService: DynamicContentManagerService
    , protected reachApplicationService: ReachApplicationService
    , protected router: Router
    , protected routeConfigurationProviderService: RouteConfigurationProviderService
    , protected dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService
    , protected authorizationConfigurationProviderService: AuthorizationConfigurationProviderService) {
    this.routeConfiguration = this.initializeRouteConfigurationData();
    this.routeData = {
      dynamicContentConfiguration: this.routeConfiguration.dynamicContentConfiguration
    };
  }

  /**
   * Resolve.resolve() interface implementation.
   * @param route the activated route snapshot.
   * @param state the router state snapshot.
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TClass> | Promise<TClass> | TClass {
    return this.innerResolve(route, state);
  }

  /**
   * Override in subclass to return the RouteConfiguration data to be used for this resolver.
   */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false,
      this.dynamicContentConfigurationProviderService.getConfigurationData(true, ""),
      this.authorizationConfigurationProviderService.getConfigurationData(false));
  }

  /**
   * Default implementation of the inner resolve. Override this in subclasses to perform route-specific resolution.
   */
  protected innerResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TClass> | Promise<TClass> | TClass {
    return this.resolveRouteData(route, state);
  }

  /**
   * Noop base implementation of the inner resolve for fetching context/route-specific route data. Override this in subclasses to perform route-specific resolution.
   */
  protected contextSpecificResolveRouteData(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return of(noop);
  }

  /**
   * Default resolution of pre-fetch dependencies for the target component of the route. Override in subclasses to perform route-specific operations.
   */
  protected resolveRouteData(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TClass> | Promise<TClass> | TClass {
    const routeDataOrchestrationObservable = new Observable<TClass>((orchestrationObserver) => {
      const doInit = () => {
        return this.bootstrapperService.bootstrapApplication().toPromise()
          .then(() => {
            let principal = this.userManagerService.getCurrentPrincipal();
            if (!this.routeConfiguration.authorizationConfiguration.allowAnonymous && !principal) {
              return this.notAuthorized(); // Safety check. AuthGuard/RouteGuard should not let us get here.
            }
            return forkJoin([this.getDynamicContentForRoute(), this.contextSpecificResolveRouteData(route, state)])
              .subscribe(responseCollection => {
                if (responseCollection.length > 1 && responseCollection[1]) {
                  Object.assign(this.routeData, responseCollection[1]);
                }

                return this.refreshProfile(route).toPromise()
                  .then(() => {
                    orchestrationObserver.next(this.routeData);
                    orchestrationObserver.complete();
                  });
              });
          });
      };

      this.busyManagerService.resolve(doInit(), this.constantsService.BUSY_MANAGER_BUSY_TYPES.ROUTE_RESOLVE);
    });
    return routeDataOrchestrationObservable;
  }

  /**
   * Helper method to throw an error if the navigated route is not authorized.
   */
  private notAuthorized() {
    return throwError(this.constantsService.SYSTEM_CONSTANTS.NOT_AUTHORIZED);
  }

  /**
   * Helper method to refetch/refesh the user profile data.
   */
  private refreshProfile(route: ActivatedRouteSnapshot) {
    let abortRefreshValue = route.queryParamMap.get('abortProfileRefresh') || 'false';;
    let abortRefresh = JSON.parse(abortRefreshValue);
    if (this.routeConfiguration.requiresProfileRefresh && !abortRefresh) {
      return this.userManagerService.refreshProfile();
    }

    return of(noop);
  }

  private getDynamicContentForRoute() {
    if (this.routeConfiguration.dynamicContentConfiguration.enabled) {
      if (this.routeConfiguration.dynamicContentConfiguration.majorKey) {
        return this.dynamicContentManagerService.loadEntriesByMajorKey(this.routeConfiguration.dynamicContentConfiguration.majorKey);
      }
    }

    return of(noop);
  }
}
