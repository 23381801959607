import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

///<summary>Service for sharing data. Replaces $Scope from the Angular1 app.</summary>
@Injectable({ providedIn: 'root' })
export class CommonScopeService {
    private data = new BehaviorSubject('');
    currentData = this.data.asObservable();

    constructor() {
    }

    updateMessage(item: any) {
        this.data.next(item);
    }  
}