<p-panel styleClass="reach-landing-panel">
  <ng-template pTemplate="icons">
    <!--<button pButton pRipple type="button" class="p-button p-button-text p-button-secondary reach-button-muted-icon" label="More" icon="fa fa-chevron-right" iconPos="right" (click)="onDetail()"></button>-->
    <button pButton pRipple type="button" class="p-button p-button-text p-button-secondary reach-button-muted-icon" [command-button]="actionCommand" [label]="actionName" icon="fa fa-chevron-right" iconPos="right" ></button>
  </ng-template>
  <p-header>
    {{title}}
  </p-header>
  <h6 class="card-subtitle p-mb-2 reach-text-muted">{{subTitle}}</h6>
  <div>
    <!-- Card Content -->
    <reach-container [componentInfo]="detailComponentInfo"></reach-container>
  </div>
</p-panel>
