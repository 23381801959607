import { Component, ElementRef, Inject, OnInit, OnDestroy } from '@angular/core';
import { of, from } from 'rxjs';
import * as _ from 'lodash-es';
import { IEntityTrainingDto, IEntityTrainingChecklistItemDto } from '@licensureShared/licensure-shared.module';
import { LicensureConstantsProviderService } from '../../licensure-core.module';
import { IDomainChecklistDtoHost } from 'src/app/coreShared/core-shared.module';
import { ProfessionalProfileService } from '../../services/professional-profile.service';
import { ProfilePostgraduateTrainingEditorComponent } from './editor/profile-postgraduate-training-editor.component';
import { ConfirmationService } from 'primeng/api';
import {
  CheckboxContentService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  ValidationManagerService,
  WIZARD_INJECTOR_TOKEN,
  WizardStepComponent,
  ReachScenarios,
  registerDynamicComponent,
  WizardInjector,
  DomainChecklistItemService,
  DomainChecklistItemStatusTypeService,
  BusyManagerService,
  Command,
  DialogSettings,
  CommandService,
  UtilitiesService
} from '@core/core.module';

@Component({
  selector: 'profile-postgraduate-training',
  templateUrl: './profile-postgraduate-training.component.html',
  styleUrls: ['./profile-postgraduate-training.component.scss']
})
export class ProfilePostgraduateTrainingComponent extends WizardStepComponent implements OnInit, OnDestroy {
  presentEditorCommand: Command;
  removeItemCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings = null;
  applicableItems: IEntityTrainingDto[] = [];

  licensureConstantsService: LicensureConstantsProviderService;
  checklistItemHost: IDomainChecklistDtoHost;
  templateId: number;

  constructor(
    // Base.
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,

    // Custom
    private domainChecklistItemStatusTypeService: DomainChecklistItemStatusTypeService,
    private busyManagerService: BusyManagerService,
    private professionalProfileService: ProfessionalProfileService,
    private commandService: CommandService,
    private utilitiesService: UtilitiesService,
    private confirmationService: ConfirmationService,
    private domainChecklistItemService: DomainChecklistItemService
  ) {

    super(constantsService, validationManagerService, wizardInjector, ProfilePostgraduateTrainingComponent.name, checkboxContentService, elementRef);

    this.licensureConstantsService = this.constantsService as LicensureConstantsProviderService;
    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    if (this.wizard.model.renewal) {
      this.templateId = (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.RENEWAL_ENTITY_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID;
      this.checklistItemHost = this.wizard.model.renewal;
    }
    else if (this.wizard.model.application) {
      this.templateId = (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_ENTITY_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID;
      this.checklistItemHost = this.wizard.model.application;
    }
    else {
      console.log('Something went wrong in profile-postgraduate-training-component. No checklistItemHost was found. As a result, no checklist item was added.')
      return;
    };

    // IMPORTANT: non-standard pattern. Becauase readonly data from the license is reflected on the ProfessionalProfile, 
    // some data may not be current on the ProfessionalProfile object unless it is reloaded here.
    // The most common case for this would be adding a post graduate training entry, moving forward a step, and then moving back to this step.
    // This solution allows us to use this step in various services without having to modify the saving procedure of each one.
    this.getLatestProfessionalProfile();

    this.initCommands();
    this.filterChecklistItems();
    super.ngOnInit();
  }

  private getLatestProfessionalProfile(): void {

    const doInit = async (): Promise<any> => {

      this.wizard.model.professionalProfile = await this.professionalProfileService.getById(this.wizard.model.professionalProfile.Id).toPromise();
      this.filterChecklistItems();

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  /** 
  * A lifecycle hook that is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed.
  */
  ngOnDestroy(): void { }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {

    // CRITICAL
    super.modelToForm();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel = () => {
  }

  /**
  * Handles initialization of commands for this component.
  */
  protected initCommands(): void {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  /**
  * Get a filtered list of education checklist items from the DomainChecklistItems list.
  */
  protected filterChecklistItems(): void {
    this.applicableItems = this.wizard.model.professionalProfile.EntityTrainings.filter(item => !(item.IsDeleted || item.StatusId == this.licensureConstantsService.ENTITY_TRAINING.STATUSES.NEW || item.StatusId == this.licensureConstantsService.ENTITY_TRAINING.STATUSES.NOT_APPLICABLE));
  }

  /**
   * Dedtermine if item editor can be presented
   */
  protected canPresentEditorCommandExecute = (item: IEntityTrainingDto): boolean => {
    return item == null || item.IsNew;
  }

  /**
   * Present item editor
   */
  protected presentEditorCommandExecute = (element: IEntityTrainingDto) => {

    if (element) element.IsDirty = true;

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doPresent = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ProfilePostgraduateTrainingEditorComponent, // Content type
        'ProfilePostgraduateTrainingEditorComponent', // Content key
        element ? "Postgraduate Training" : "Postgraduate Training - {new}", // Title
        element ?? {
          Id: 0,
          IsNew: true,
          IsDeleted: false,
          LocalId: this.utilitiesService.guid(),
          EntityId: this.wizard.model.professionalProfile.Id,
          StatusId: this.licensureConstantsService.ENTITY_TRAINING.STATUSES.NEW
        } as IEntityTrainingDto // Model
      );

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      return of(true).toPromise();
    }

    return from(doPresent());
  }

  /**
   * Determine if the specified item can be removed
   */
  protected canRemoveItemCommandExecute = (item: IEntityTrainingDto): boolean => {
    return true;
  }

  /**
   * Remove the specified item
   */
  protected removeItemCommandExecute = (item: IEntityTrainingDto) => {

    var derivedDescription: string = item.Program ? item.Program : "An unsaved new item";

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `${derivedDescription} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      accept: () => {

        if (item.LocalId) {
          this.checklistItemHost.DomainChecklist.splice(this.checklistItemHost.DomainChecklist.findIndex(x => x.LocalId == item.LocalId), 1); // Unsaved new CLI.
          this.applicableItems.splice(this.applicableItems.findIndex(x => x.LocalId == item.LocalId), 1); // Entry in ProfessionalProfile.EntityTraining
        }
        else item.IsDeleted = true;

        this.filterChecklistItems();
      }

    });
  }

  /**
  * Allows the OK command to execute.
  */
  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  /**
  * Presents the editor for the selected item.
  */
  protected okCommandExecute = (item: IEntityTrainingDto) => {
    this.utilitiesService.addOrReplace(this.wizard.model.professionalProfile.EntityTrainings, item);

    var relatedCli = this.checklistItemHost.DomainChecklist.find(x => x.ChecklistItemTemplateId == this.templateId && ((x as IEntityTrainingChecklistItemDto).EntityTraining.LocalId == item.LocalId || x.ChildDomainKeyId == item.Id));
    if (relatedCli) {
      (relatedCli as IEntityTrainingChecklistItemDto).EntityTraining = item;
      this.filterChecklistItems();
    }
    else this.createEntityTrainingChecklistItem(item);
  }

  protected createEntityTrainingChecklistItem(item: IEntityTrainingDto): void {

    const createCli = async (): Promise<any> => {

      var cli: IEntityTrainingChecklistItemDto = (await this.domainChecklistItemService.initializeItem(this.checklistItemHost, this.templateId).toPromise()).DomainChecklistItem as IEntityTrainingChecklistItemDto;
      cli.EntityTraining = item;
      this.utilitiesService.addOrReplace(this.checklistItemHost.DomainChecklist, cli);
      this.filterChecklistItems();

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(createCli()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  /**
   * Create a new object to be used as the dialog model.
   * @returns 
   */
  private createDialogModel(): IEntityTrainingDto {
    return {
      Id: 0,
      IsNew: true,
      IsDeleted: false,
      LocalId: this.utilitiesService.guid(),
      EntityId: this.wizard.model.entityId,

    } as IEntityTrainingDto;
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, 'profilePostgraduateTraining', ProfilePostgraduateTrainingComponent, 'profile-postgraduate-training');
