import { Component, Inject, Injectable, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot, Router, RouterStateSnapshot
} from '@angular/router';
import { Observable, from, of } from 'rxjs';

import { LandingComponentKeys, ReachScenarios } from '@core/index-constants';
import { registerDynamicComponent } from '@core/index-models';
import { SummaryInfoSet } from '@coreModels/summary-component-info';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { SummaryInfoManagerService } from '@coreServices/summary-info-manager.service';
import { filter, map, tap } from 'rxjs/operators';
import { AuthorizationConfigurationProviderService } from '../../services/authorization-configuration-provider.service';
import { BootstrapperService } from '../../services/bootstrapper.service';
import { BusyManagerService } from '../../services/busy-manager.service';
import { DynamicContentConfiguration, DynamicContentConfigurationProviderService } from '../../services/dynamic-content-configuration-provider.service';
import { DynamicContentManagerService } from '../../services/dynamic-content-manager.service';
import { LandingPageService } from '../../services/landing-page.service';
import { ReachApplicationService } from '../../services/reach-application.service';
import { RouteConfiguration, RouteConfigurationProviderService } from '../../services/route-configuration-provider.service';
import { DefaultRouteResolverService } from '../../services/routeResolvers/default-route-resolver.service';
import { UserManagerService } from '../../services/user-manager.service';

export const LandingComponentSelector = 'landing-page';

@Component({
  selector: LandingComponentSelector,
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  private dynamicContentConfiguration: DynamicContentConfiguration;
  public summaryInfoSet: SummaryInfoSet = null;
  public majorKey: string;
  public minorKey: string = "Landing.SummaryTextBlock";

  constructor(private activatedRoute: ActivatedRoute, private summaryInfoManagerService: SummaryInfoManagerService) { }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      map((data: { routeConfiguration: RouteConfiguration }) => data.routeConfiguration),
      filter(routeConfiguration => routeConfiguration?.dynamicContentConfiguration != null),
      tap(routeConfiguration => {
        this.dynamicContentConfiguration = routeConfiguration.dynamicContentConfiguration;
        this.majorKey = this.dynamicContentConfiguration.majorKey;
        this.summaryInfoSet = this.summaryInfoManagerService.createInfoSet();
        this.summaryInfoSet.dynamicContentConfiguration = this.dynamicContentConfiguration;
      })
    ).subscribe();
  }
}

registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.Landing, LandingPageComponent, LandingComponentSelector);

@Injectable({
  providedIn: 'root'
})
export class LandingPageRouteResolverService extends DefaultRouteResolverService {
  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , userManagerService: UserManagerService, busyManagerService: BusyManagerService
    , bootstrapperService: BootstrapperService
    , dynamicContentManagerService: DynamicContentManagerService
    , private landingPageService: LandingPageService
    , reachApplicationService: ReachApplicationService
    , router: Router
    , routeConfigurationProviderService: RouteConfigurationProviderService
    , dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService
    , authorizationConfigurationProviderService: AuthorizationConfigurationProviderService
    , private summaryInfoManagerService: SummaryInfoManagerService) {

    super(constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(true
      , this.dynamicContentConfigurationProviderService.getConfigurationData(true, (this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS as any).LANDING)
      , this.authorizationConfigurationProviderService.getConfigurationData(true));
  }

  innerResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Object> {
    const doResolve = async (): Promise<any> => {
     //await this.userManagerService.refreshUserAndProfile().toPromise();
      let routeData = await this.resolveRouteData(route, state).toPromise();
      await this.landingPageService.initialize().toPromise();
      await this.summaryInfoManagerService.setDisplayOrder().toPromise();
      return of(routeData).toPromise();
    };

    return from(doResolve());
  }
}
