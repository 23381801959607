// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { StepForwardEventArguments, StepBackEventArguments } from '@coreModels/wizard-event-args';
import { WizardNavigationHandler } from '@coreModels/wizard-navigation-handler';
import { IBagDto, IWebServiceViewDto } from '@coreShared/core-shared.module';

/**
* Wraps an IWebServiceViewDto with navigation events.
**/
export class WizardStep implements IWebServiceViewDto {
  public onStepForwardStart: WizardNavigationHandler<StepForwardEventArguments>;
  public onStepBackStart: WizardNavigationHandler<StepBackEventArguments>;

  public bag: IBagDto = null;
  public additionalCommands = [];

  public hideForward: boolean = false;
  public hidePrevious: boolean = false;

  constructor(public step: IWebServiceViewDto) {
    this.onStepBackStart = new WizardNavigationHandler(null);
    this.onStepForwardStart = new WizardNavigationHandler(null);
  }
  public get Parameters(): string[]{ return this.step.Parameters; }
  public get Id(): number { return this.step.Id; }
  public get StepOrder(): number { return this.step.StepOrder; }
  public get ShortTitle(): string { return this.step.ShortTitle; }
  public get LongTitle(): string { return this.step.LongTitle; }
  public get IsVisible(): boolean { return this.step.IsVisible; }
  public get IsSaved(): boolean { return this.step.IsSaved; }
  public get ServiceId(): number { return this.step.ServiceId; }
  public get IsActive(): boolean { return this.step.IsActive; }
  public get FilePath(): string { return this.step.FilePath; }
  public get ComponentName(): string { return this.step.ComponentName; }
  public get TemplatePath(): string { return this.step.TemplatePath; }
  public get Icon(): string { return this.step.Icon; }
  public get MinorKey(): string { return this.step.MinorKey; }

  public get CreatedBy(): string { return this.step.CreatedBy; }
  public get CreatedDate(): Date { return this.step.CreatedDate; }
  public get ModifiedBy(): string { return this.step.ModifiedBy; }
  public get ModifiedDate(): Date { return this.step.ModifiedDate; }
}
