import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent, IReachDialogContentComponent } from 'src/app/core/index-directives';
import { DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IStateDto } from 'src/app/coreShared/core-shared.module';
import { IEntityHospitalPrivilegeDto } from 'src/app/licensureShared/licensure-shared.module';

export const ProfileHospitalPrivilegesEditorComponentSelector: string = 'profile-hospital-privileges-editor';
export const ProfileHospitalPrivilegesEditorComponentKey: string = 'profileHospitalPrivilegesEditor';

@Component({
  selector: ProfileHospitalPrivilegesEditorComponentSelector,
  templateUrl: './profile-hospital-privileges-editor.component.html',
  styleUrls: ['./profile-hospital-privileges-editor.component.scss']
})
export class ProfileHospitalPrivilegesEditorComponent extends ReachDialogContentComponent<IEntityHospitalPrivilegeDto> implements OnInit, IReachDialogContentComponent {

  possibleStates: IStateDto[];

  constructor(
    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  public override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  protected override modelToForm(): void {

    this.contentForm = new FormGroup({});
    this.contentForm.addControl('Privilege', new FormControl(this.inputModel.Privilege || "", [Validators.required, Validators.maxLength(128)]));
    this.contentForm.addControl('Facility', new FormControl(this.inputModel.Facility || "", [Validators.required, Validators.maxLength(128)]));
    this.contentForm.addControl('City', new FormControl(this.inputModel.City || "", [Validators.required, Validators.maxLength(100)]));
    this.contentForm.addControl('State', new FormControl(null, [Validators.required, Validators.maxLength(2)]));

    this.possibleStates = this.hostDialogData.initializationData.possibleStates;

    super.modelToForm();
  }

  protected override initDropdowns(): void {
    
    if (this.outputModel.State) this.contentForm.get('State').setValue(this.possibleStates.find(item => item.StateCode == this.outputModel.State));

    this.dataLoaded = true;
  }

  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    let dto = this.outputModel;
    dto.Privilege = this.contentForm.get('Privilege').value;
    dto.Facility = this.contentForm.get('Facility').value;
    dto.City = this.contentForm.get('City').value;
    dto.State = this.contentForm.get('State').value?.StateCode;

    super.formToModel();
  }

}

registerDynamicComponent(ReachScenarios.Default, ProfileHospitalPrivilegesEditorComponentKey, ProfileHospitalPrivilegesEditorComponent, ProfileHospitalPrivilegesEditorComponentSelector);