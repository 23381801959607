import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, OnlineServiceRegistry, OnlineServiceRegistryItem, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { from } from 'rxjs';
import { ILicenseStatusChangeApplicationDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({ providedIn: 'root' })
export class LicenseReinstatementFeatureConfigurationService {

  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  public licenseReinstatementConstantsService: ILicenseReinstatementConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService,
  ) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseReinstatement);

    this.licenseReinstatementConstantsService = this.constantsService as ILicenseReinstatementConstantsService;
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, LicenseReinstatementConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, LicenseReinstatementConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseReinstatementConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, LicenseReinstatementConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, LicenseReinstatementConstantsService.Mergers.DOMAIN_IDS);

    this.licenseReinstatementConstantsService = this.constantsService as ILicenseReinstatementConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseReinstatementWizard/license-reinstatement-wizard.module').then(x => x.LicenseReinstatementWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseReinstatementWizard/license-reinstatement-wizard.module').then(m => m.LicenseReinstatementWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  /**
   * Returns a fully initialized instance of the appropriate wizard.
   * @param statusChangeApplication 
   * @param webServiceHistoryId 
   * @returns 
   */
  public getWizard = (statusChangeApplication: ILicenseStatusChangeApplicationDto, webServiceHistoryId: number, processId: number) => {

    // Declarations.
    const functionType = this.functionTypeService.create(this.licenseReinstatementConstantsService.FUNCTION_TYPES.LICENSE_STATUS_CHANGE_APPLICATION, statusChangeApplication.Id);
    const wizardTags = this.wizardTagsService.create(statusChangeApplication.LicenseTypeId, null, statusChangeApplication.LicenseStatusTransition.StatusFromCode, null, null, null, statusChangeApplication.LicenseStatusTransition.StatusToCode, processId);
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.licenseReinstatementConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_REINSTATEMENT, wizardTags);

    // Get the wizard instance and START IT.
    const initializeWizard = async (): Promise<any> => {

      // Create the wizard using the wizardService.
      const wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();

      // If there is no webServiceHistoryId for this wizard, we'll need to start one.
      if (!webServiceHistoryId) {

        // Ask the wizard manager to start the wizard (supply your function type please).
        const startedWizard = await this.wizardService.startWizard(wizard, functionType, statusChangeApplication.LicenseTypeId).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    // Return as observable.
    return from(initializeWizard());
  };

}

export class LicenseReinstatementConstantsService extends ConstantsService {

  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      LICENSE_REINSTATEMENT_WIZARD: "License.Reactivation.Wizard",
      LICENSE_REINSTATEMENT_WIZARD_SELECTOR: 'License.Reactivation.Wizard.Selector',
      REINSTATEMENT_WIZARD: 'Registration.Wizard',
    },
    WEB_SERVICE_PROCESS_TYPES: { LICENSE_REINSTATEMENT: LicensureOnlineServiceProcessTypeKeys.LicenseReinstatement, },
    FEATURE_CONSTANTS: { STATUS_CHANGE_APPLICATION_STATUS_CONSTANTS: { NEW: 1, IN_PROCESS: 2, APPROVED: 3, DENIED: 4 } },
    FUNCTION_TYPES: { LICENSE_STATUS_CHANGE_APPLICATION: 26 },
    DOMAIN_IDS: { LICENSE_STATUS_CHANGE: 30, },
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), LicenseReinstatementConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), LicenseReinstatementConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), LicenseReinstatementConstantsService.Mergers.FEATURE_CONSTANTS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), LicenseReinstatementConstantsService.Mergers.FUNCTION_TYPES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), LicenseReinstatementConstantsService.Mergers.DOMAIN_IDS);
}

export interface ILicenseReinstatementConstantsService extends LicenseReinstatementConstantsService {
}
