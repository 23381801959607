<!-- Summary Text -->
<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="documents" [responsive]="true"
  responsiveLayout="stack">

  <!-- Caption -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <div class="p-text-left reach-list-header">Documents</div>
      <div>
        <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentItemCreateCommand"
          tool-tip="Add a new item"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>
      <th>File Name</th>
      <th>Name</th>
      <th>Date</th>
      <th style="width: 8em;"></th>
    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- File Name -->
      <td><span class="p-column-title">File Name</span>{{item.OriginalFileName}}</td>

      <!-- DisplayName -->
      <td><span class="p-column-title">Name</span>{{item.DisplayName}}</td>

      <!-- Date -->
      <td>
        <span class="p-column-title">Date</span>
        {{item.CreatedDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}
      </td>

      <!-- Buttons -->
      <td class="p-text-right">
        <span class="p-text-nowrap">

          <!-- Edit -->
          <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="presentItemEditorCommand"
            [command-parameter]="item" tool-tip="Edit this item"></button>

          <!-- Delete -->
          <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeItemCommand"
            [command-parameter]="item" tool-tip="Delete this item"></button>

        </span>
      </td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="4">
        <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
      </td>
    </tr>
  </ng-template>
</p-table>

<div [formGroup]="contentForm">

  <!-- Affirmation Checkbox -->
  <div class="p-field-checkbox p-mt-3" *ngIf="affirmationControl.enabled">
    <p-checkbox inputId="Affirmation" formControlName="Affirmation" binary="true"></p-checkbox>
    <label for="Affirmation">I do not have any documents to submit at this time.</label>
  </div>

</div>


<!-- CONFIRMATION DIALOG (global on reach-app.component) -->

<!-- EDITOR DIALOG -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>