<!-- Checklist -->
<div *ngIf="showChecklistItemList">

    <!-- Table -->
    <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="filteredChecklistItems" [responsive]="true"
        responsiveLayout="stack">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <span class="p-text-left reach-list-header">{{headerText}}</span>
                <div class="p-text-right reach-list-header-secondary" *ngIf="headerTextSecondary">
                    {{headerTextSecondary}}</div>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th width="20%">Received</th>
                <th width="20%">Status</th>
                <th width="40%">Description</th> 
                <th width="10%">Message</th>
                <th *ngIf="showUploadColumn" width="10%">Upload</th>
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Received -->
                <td>
                    <span class="p-column-title">Received</span>{{item.ReceivedDate | date:'MM/dd/yyyy' | whenEmpty:
                    '-'}}
                </td>

                <!-- Status -->
                <td>
                    <span class="p-column-title">Status</span>{{item.Status.Description | whenEmpty: '-'}}
                </td>

                <!-- Description -->
                <td>
                    <span class="p-column-title">Description</span>{{item.Summary | whenEmpty: '-'}}
                </td>

                <!-- Comment -->
                <td>
                    <span class="p-column-title">Message</span>
                    <checklist-comment [message]="item.OnlineComment"></checklist-comment>
                </td>

                <!-- FileUpload -->
                <td *ngIf="showUploadColumn">
                    <span class="p-column-title">Upload</span>
                    <checklist-file-upload-button [domainChecklistItemDto]="item" [fileUploadDtoHost]="domainChecklistHost"></checklist-file-upload-button>
                </td>

            </tr>

        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columnSpan">
                    <span class="reach-text-muted"><em>No checklist items specified.</em></span>
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
