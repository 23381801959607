import {  } from '@coreShared/dto-gen/core-shared-dto';
import { ISupervisorDesignationInfoDto } from './licensure-shared-dto';

export class SupervisorDesignationInfoDto implements ISupervisorDesignationInfoDto {
	TypeId?: number;
	TypeDescription: string;
	ExternalCredentialDescription: string;
	StatusId?: number;
	StatusDescription: string;
	EffectiveDate?: Date;
	ExpireDate?: Date;

}
