import { IDomainReasonForChangeDtoHost,
IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeSponsorDto } from './licensure-shared-dto';

export class CeSponsorDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICeSponsorDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	EntityId?: number;
	ReferenceValue: string;
	ContactFirstName: string;
	ContactLastName: string;
	ContactPhone: string;
	ContactEmail: string;
	ContactFullName: string;
	SponsorshipCount: number;
	CourseTemplates: any[];

}
