import { IBaseDto,
IEntityInfoDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IEducationProgramApplicationInfoDto } from './licensure-shared-dto';

export class EducationProgramApplicationInfoDto extends BaseDto implements IBaseDto, IEducationProgramApplicationInfoDto {
	EntityId: number;
	DirectorEntityId: number;
	StatusId: number;
	PlannedBeginDate?: Date;
	EducationProgramLevelDescription: string;
	ChecklistSummary: string;
	Status: any;
	EntityInfo: IEntityInfoDto;
	DirectorEntityInfo: IEntityInfoDto;

}
