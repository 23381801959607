import { IProcessDto,
IProcessFeeDto,
IProcessTypeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ProcessDto extends BaseDto implements IProcessDto {
	Description: string;
	ProcessTypeId: number;
	LicenseTypeCode: string;
	Basis: string;
	StatusCode: string;
	StatusCodeTo: string;
	SupervisorDesignationFormId?: number;
	IsOnline?: boolean;
	WebServiceId?: number;
	UsBankProductCode: string;
	UsBankProductDescription: string;
	HasSupervisionPlan: boolean;
	FormTypeId?: number;
	FeesWaivedForActiveMilitary: boolean;
	DisplayOrder: number;
	IsCriminalBackgroundCheckRequired: boolean;
	CriminalBackgroundCheckApprovalRuleId?: number;
	CriminalBackgroundCheckDefaultStatusId?: number;
	IsInactive: boolean;
	OnlineComment: string;
	LicenseSubtypeId?: number;
	UsBankPaymentType: string;
	IsOpiate?: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
	Fees: IProcessFeeDto[];
	ProcessType: IProcessTypeDto;

}
