import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { from, of } from "rxjs";
import * as _l from 'lodash-es';
import { SummaryAggregationType, SummaryComponentInfo, SummaryInfoSet } from '@coreModels/summary-component-info';
import { ListService } from '@coreServices/list.service';
import { ArgumentExceptionService } from "./argument-exception.service";
import { DynamicRoutesService } from './dynamic-routes.service';
import { DomainItemDto } from '@coreShared/core-shared.module';

/**
 * Manages the sets of summary info objects created by landing page and features services to drive summary tab-card components and their corresponding detail.
 */
@Injectable({
  providedIn: 'root'
})
export class SummaryInfoManagerService {
  public infos: SummaryComponentInfo[] = [];

  constructor(private argumentExceptionService: ArgumentExceptionService
    , private dynamicRoutesService: DynamicRoutesService
    , private listService: ListService) {
  }

  /**
   * Creates a new SummaryInfoSet instance from the SummaryComponentInfos the manager has cached for the specified FeatureNames.
   * @param feature the target FeatureNames.
   */
  public createInfoSet() {
    if (!this.infos) {
      this.argumentExceptionService.create("infos").log();
    }

    _l.each(this.infos,
      (info: SummaryComponentInfo, index: number) => {
        info.index = index;
      });

    return new SummaryInfoSet(this.infos);
  }

  /**
   * Clears all the current set of SummaryComponentInfos.
   */
  public clear() {
    this.infos = [];
  }

  /**
   * Gets the SummaryComponentInfo matching the specified Id.
   * @param id the target SummaryComponentInfo.Id.
   */
  public getById(id: string): SummaryComponentInfo {
    let match = _l.find(this.infos, (info: SummaryComponentInfo) => { return info.id === id; });
    return match as SummaryComponentInfo;
  }

  /**
   * Gets the SummaryComponentInfo associated with the specified Route path.
   * @param feature the target FeatureNames.
   */
  public getByRoute(route: string) {
    let match: SummaryComponentInfo =
      _l.find(this.infos, (info: SummaryComponentInfo) => { return info.detailRoute === route; }) as
      SummaryComponentInfo;
    return match;
  }

  /**
* Gets the SummaryComponentInfo associated with the specified DetailRoute path.
* @param detailRoute the route.
*/
  public getByDomainAndModelId(domainId: number, modelId: number) {
    let match: SummaryComponentInfo =
      _l.find(this.infos, (info: SummaryComponentInfo) => { return info.domainId === domainId && info.model.Id === modelId; }) as
      SummaryComponentInfo;
    return match;
  }

  /**
 * Gets the SummaryComponentInfo associated with the specified DetailRoute path.
 * @param detailRoute the route.
 */
  public getByDetailRoute(detailRoute: string) {
    let match: SummaryComponentInfo =
      _l.find(this.infos, (info: SummaryComponentInfo) => { return info.detailRoute === detailRoute; }) as
      SummaryComponentInfo;
    return match;
  }

  /**
   * Gets the SummaryComponentInfo associated with the specified FeatureNames and RouteTypes values.
   * @param routeType the target RouteTypes.
   */
  public getByRouteType(configKey: number) {
    let match: SummaryComponentInfo =
      _l.find(this.infos, (info: SummaryComponentInfo) => { return info.configuration.configKey === configKey; }) as
      SummaryComponentInfo;
    return match;
  }

  public getByOutletName(configKey: number, outletName: string) {
    let match: SummaryComponentInfo =
      _l.find(this.infos, (info: SummaryComponentInfo) => {
        return info.configuration.configKey === configKey
          && info.outletName === outletName;
      }) as
      SummaryComponentInfo;
    return match;
  }

  /**
   * Merges in the specified set of SummaryComponentInfo to the manager and associates them with the specified FeatureNames key.
   * @param feature the FeatureNames.
   * @param newInfos the set of SummaryComponentInfos to be added for the FeatureNames.
   */
  public mergeInfos(newInfos: SummaryComponentInfo[]) {
    if (!this.infos || this.infos.length < 1) {
      this.infos = newInfos;
      return;
    }

    newInfos.forEach((item: SummaryComponentInfo) => {
      var idx = _l.findIndex(this.infos,
        (existingItem: SummaryComponentInfo) => {
          return existingItem.configuration.configKey === item.configuration.configKey
            && ((item.configuration.aggregationType === SummaryAggregationType.Aggregate) || (existingItem.id === item.id));
        });

      if (idx >= 0) {
        this.infos.splice(idx, 1, item);
      } else {
        this.infos.push(item);
      }
    });
  }

  /**
   * Adds a new Route as a child of the specified parent Route.
   * @param routeConfig The array of Routes for the target module. The parentRoutePath points at one of these routes.
   * @param parentRoutePath the route path of the target parent Route.
   */
  public addDynamicRoutes(routeConfig: Array<Route>, parentRoutePath: string) {
    return this.dynamicRoutesService.infoToRoute(routeConfig, parentRoutePath, this.infos);
  }

  /**
   * Sets the display order on the info items based on the DomainItem display order (if applicable);
   * */
  public setDisplayOrder() {
    const doSetDisplayOrder = async (): Promise<boolean> => {
      const domainInfos: DomainItemDto[] = await this.listService.getDomainItems().toPromise();
      this.infos.forEach(infoItem => {
        if (infoItem.domainId) {
          let match = _l.find(domainInfos, (i: DomainItemDto) => i.Id == infoItem.domainId);
          if (match) {
            let displayOrder = match.DisplayOrder
            infoItem.tabOrder = displayOrder;
          }
        }
      });

      return of(true).toPromise();
    }

    return from(doSetDisplayOrder());
  }
}
