import { IDomainReasonForChangeDtoHost,
IEntityPhoneDto,
IPhoneTypeDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class EntityPhoneDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityPhoneDto {
	EntityId: number;
	PhoneTypeId: number;
	PhoneType: IPhoneTypeDto;
	Number: string;
	Extension: string;
	IsPublic: boolean;
	IsPrimary: boolean;
	IsVoicemail: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
