import { Command } from './command';
import { MenuItem as PrimeNgMenuItem } from 'primeng/api';

export interface ConstructorParams {
  commandModel?: Command;
  icon?: string;
  isHeader?: boolean;
  menuGroupName?: string;
  hasActiveChildren?: () => boolean;
  routerLink?: any;
  target?: string;
  items?: ReachMenuItem[];
  label?: string;
  isInvisibleWhenDisabled? : boolean;
}

export class ReachMenuItem implements PrimeNgMenuItem {
  constructor(private params: ConstructorParams) {
    if (this.params) {
      if (this.params.commandModel) {
        this.commandModel = this.params.commandModel;
        this.command = (event?: any) => this.params.commandModel.execute();
      }

      this.icon = params.icon;

      this.isHeader = params.isHeader ? params.isHeader : false;
      this.menuGroupName = params.menuGroupName;
      this.hasActiveChildren = params.hasActiveChildren;
      this.routerLink = params.routerLink;
      this.target = params.target;
      this.items = params.items;
      this.isInvisibleWhenDisabled = params.isInvisibleWhenDisabled ? params.isInvisibleWhenDisabled : false;
    } else {
      this.params = {};
    }
  }
  
  public commandModel: Command;
  public command: (event?: any) => void;
  public icon: string;
  public isHeader: boolean = false;
  public menuGroupName: string;
  public hasActiveChildren: Function;
  public routerLink: any;
  public target: string;
  public items: ReachMenuItem[];
  public isInvisibleWhenDisabled: boolean;

  public get commandText() {
    return this.commandModel ? this.commandModel.commandText : this.menuGroupName;
  }

  public get routeUrl(): string {
    return this.commandModel ? (this.commandModel.routeUrl
      ? this.commandModel.routeUrl : "")
      : "";
  }

  public get isEnabled(): boolean {
    return this.commandModel && this.commandModel.canExecute
      ? this.commandModel.canExecute()
      : true;
  }

  public get isVisible(): boolean {

    if (this.isInvisibleWhenDisabled) {
      var isCurrentlyEnabled = this.isHeader
        ? (this.hasActiveChildren ? this.hasActiveChildren() : false)
        : this.isEnabled;

      return isCurrentlyEnabled;
    }

    return true;
  }

  // Reach to PrimeNG members
  // label
  public get label() {
    return this.params.label
      ? this.params.label :
      this.commandText;
  }

  // visible
  public get visible() {
    return this.isVisible ? this.isVisible : false;
  }

  // disabled
  public get disabled() {
    return !this.isEnabled;
  }
}
