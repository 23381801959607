import { Component, Inject, Input, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UtilitiesService } from '@coreServices/utilities.service';
import { forkJoin, from, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IAddressLocationTypeDto, IAddressTypeDto, ICountryDto, ICountyDto, IEntityAddressDto, IEntityAddressDtoHost, IStateDto } from 'src/app/coreShared/core-shared.module';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ListService, ValidationManagerService } from '../../index-services';

@Component({
  selector: 'entity-address-facility',
  templateUrl: './entity-address-facility.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAddressFacilityComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EntityAddressFacilityComponent),
      multi: true
    }
  ]
})
export class EntityAddressFacilityComponent implements OnChanges, ControlValueAccessor {

  @Input()
  addressDtoHost: IEntityAddressDtoHost;
  @Input()
  friendlyNames: any;

  public STATE: string = "State";
  public PROVINCE: string = "Province";
  public USA: string = "USA";
  public CANADA: string = "CANADA";
  public MN: string = "MN";
  public formReady: boolean;
  public dataLoaded: boolean = false;
  public contentForm: FormGroup;
  public possibleCounties: ICountyDto[];
  public possibleAddressLocationTypes: IAddressLocationTypeDto[];
  public possibleAddressTypes: IAddressTypeDto[];
  public possibleCountries: ICountryDto[];
  public possibleStates: IStateDto[];
  public possibleProvinces: IStateDto[];
  public mailingSameAsPhysical: boolean;
  public get mailingAddressForm(): FormGroup { return this.contentForm.get('mailingAddressForm') as FormGroup }
  public get physicalAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.PHYSICAL && address.IsPublic && address.IsCurrent && !address.IsDeleted); }
  public get mailingAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER && address.IsCurrent && !address.IsPublic); }

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private listService: ListService,
    private busyManagerService: BusyManagerService,
    protected validationManagerService: ValidationManagerService,
    private utilitiesService: UtilitiesService,
    private formBuilder: FormBuilder) {
  }

  ngOnChanges(): void {
    this.mailingSameAsPhysical = this.physicalAddress && this.physicalAddress.IsPublic && this.physicalAddress.IsMailing;

    if (!this.mailingSameAsPhysical && !this.mailingAddress) {
      this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.OTHER, false, true));
    }

    this.modelToForm();
    this.loadLookupLists();
  }

  private modelToForm() {
    this.contentForm = this.formBuilder.group({
      mailingAddressForm: this.buildFormShared(this.mailingAddress),
      IsMailingSameAsPhysical: [this.mailingSameAsPhysical],
    });

    this.initializeEventHandlers();
    this.formReady = true;
  }

  protected initializeEventHandlers(): void {

    // Event handler for when this form is updated.
    this.mailingAddressForm.valueChanges.pipe(debounceTime(10)).subscribe(() => this.sharedFormToModel(this.mailingAddressForm, this.mailingAddress));

    // Country -> State -> County
    this.sharedInitializeEventHandlers(this.mailingAddress, this.mailingAddressForm);

    this.contentForm.get('IsMailingSameAsPhysical').valueChanges.subscribe(x => this.mailingSameAsPhysicalChanged(x));
  }

  protected mailingSameAsPhysicalChanged(checked: boolean): void {

    var address: IEntityAddressDto = this.mailingAddress;
    var form: FormGroup = this.mailingAddressForm;

    if (checked) { // Mailing Same as Physical is being turned on
      if (address) {
        address.IsMailing = false;
        address.IsDeleted = true;
      }

      this.utilitiesService.enableDisable(form, false);
      this.mailingSameAsPhysical = true;

      if (this.physicalAddress) {
        this.physicalAddress.IsMailing = true;
        this.physicalAddress.IsDirty = true;
      }
    }
    else if (!checked && address && address.IsDeleted) { // Mailing Same as Physical is being turned off and there is a recently deleted address
      address.IsDeleted = false;
      address.IsMailing = true;

      this.utilitiesService.enableDisable(form, true);
      this.mailingSameAsPhysical = false;

      if (this.physicalAddress) {
        this.physicalAddress.IsMailing = false;
        this.physicalAddress.IsDirty = true;
      }
    }
    else if (!checked && !address) { // SAD is being turned off and there is no recently deleted address (occurs when values were previously saved)

      this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.OTHER, false, true));

      this.initDropdowns();
      this.utilitiesService.enableDisable(form, true);

      if (this.physicalAddress) {
        this.physicalAddress.IsMailing = false;
        this.physicalAddress.IsDirty = true;
      }
    }
  }


  protected buildFormShared(x: IEntityAddressDto): FormGroup {
    var formGroup = this.formBuilder.group({
      Line1: [x?.Line1, [Validators.required, Validators.maxLength(60)]],
      Line2: [x?.Line2, [Validators.maxLength(60)]],
      Line3: [x?.Line3, [Validators.maxLength(60)]],
      City: [x?.City, [Validators.required, Validators.maxLength(40)]],
      Zip: [x?.Zip, [Validators.required, Validators.maxLength(10)]],
      State: [null, [Validators.required]],
      Country: [null],
      County: [{ value: null, disabled: true }, [Validators.required]],
    });

    if (!x) formGroup.disable({ emitEvent: false }); // Disable the formgroup if the address is unset

    return formGroup;
  }

  protected sharedInitializeEventHandlers(address: IEntityAddressDto, formGroup: FormGroup): void {

    // Mark the form dirty when any form element is changed.
    formGroup.valueChanges.subscribe(() => { if (address) address.IsDirty = true; });

    // State
    formGroup.get('State').valueChanges.subscribe(x => {
      x?.StateCode == this.MN
        ? this.utilitiesService.enableDisable(formGroup.get('County'), true)
        : this.utilitiesService.enableDisable(formGroup.get('County'), false, true);
    });

    // Country
    formGroup.get('Country').valueChanges.subscribe(x => {

      // Check for enabled status of the country FormControl is intentional and necessary.
      // Else the state FormControl may not be disabled properly.
      formGroup.get('Country').enabled && (x?.Description == this.USA || !x?.Description || x?.Description == this.CANADA)
        ? this.utilitiesService.enableDisable(formGroup.get('State'), true)
        : this.utilitiesService.enableDisable(formGroup.get('State'), false, true);
    });
  }

  protected sharedFormToModel(formGroup: FormGroup, address: IEntityAddressDto): void {

    if (!this.dataLoaded || !address || address.IsDeleted) return;

    address.Line1 = formGroup.get('Line1').value;
    address.Line2 = formGroup.get('Line2').value;
    address.Line3 = formGroup.get('Line3').value;
    address.City = formGroup.get('City').value;
    address.State = formGroup.get('State').value?.StateCode ?? "";
    address.Country = formGroup.get('Country').value?.Description ?? "";
    address.Zip = formGroup.get('Zip').value;
    address.CountyId = formGroup.get('County').value?.Id;
  }

  private loadLookupLists(): void {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCounties(), this.listService.getAddressLocationTypes(), this.listService.getAddressTypes(), this.listService.getCountries()]).toPromise();
      this.possibleProvinces = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.CANADA) as IStateDto[];
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.USA) as IStateDto[];
      this.possibleCounties = this.listService.filterInactiveItems(responseCollection[1]) as ICountyDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[4]) as ICountryDto[];
      this.possibleAddressLocationTypes = this.listService.filterInactiveItems(responseCollection[2]) as IAddressLocationTypeDto[];
      this.possibleAddressTypes = this.listService.filterInactiveItems(responseCollection[3]) as IAddressTypeDto[];

      // Populate dropdown FormControl elements.
      this.initDropdowns();

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  protected initDropdowns(): void {
    if (this.mailingAddress) {
      this.sharedInitDropdowns(this.mailingAddress, this.mailingAddressForm);
    }
    this.dataLoaded = true;
  }

  protected sharedInitDropdowns(address: IEntityAddressDto, formGroup: FormGroup): void {

    if (!address.Country) {
      address.Country = this.USA;
    }

    formGroup.get('Country').setValue(this.possibleCountries.find(item => item.Description == address.Country));

    if (address.State) {
      formGroup.get('State').setValue((address?.Country == this.CANADA ? this.possibleProvinces : this.possibleStates).find(item => item.StateCode == address.State));
    }

    if (address.CountyId) {
      formGroup.get('County').setValue(this.possibleCounties.find(item => item.Id == address.CountyId));
    }
  }

  protected createAddress(addressLocationType: number, isPublic: boolean = false, isMailing: boolean = false): IEntityAddressDto {

    // Create a new EntityAddressDto.
    return {

      // Assign basic fields.
      Id: 0,
      EntityId: this.addressDtoHost.EntityId,
      ProfessionTypeCode: this.addressDtoHost.ProfessionTypeCode,
      IsCurrent: true,
      IsDeleted: false,
      IsNew: true,
      LocalId: this.utilitiesService.guid(),
      FunctionNumber: this.addressDtoHost.FunctionNumber,
      Country: this.USA,
      AddressType: { Id: this.addressDtoHost.AddressTypeId } as IAddressTypeDto,
      AddressLocationType: { Id: addressLocationType } as IAddressLocationTypeDto,
      IsPublic: isPublic,
      IsMailing: isMailing

    } as IEntityAddressDto;
  }

  validate(): ValidationErrors | null {
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames); // Combine errors from this component...
    return this.contentForm.valid ? null : errors; // ... and pass them to the parent.
  }

  // ==================================== CVA ====================================

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }
}
