// Angular
import { Injectable, Inject } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _ from 'underscore';
import * as _l from 'lodash-es';

import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  WizardSearchCriteriaService,
  WizardService,
  WizardTagsService
} from '@core/core.module';

import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { from } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CriminalBackgroundCheckFeeWizardFeatureConfigurationService {

  // Fields
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private criminalBackgroundCheckFeeConstantsService: ICriminalBackgroundCheckFeeConstantsService;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService
  ) {

    // Define service registry item.
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CriminalBackgroundCheckFee);
  }

  /**
  * Perform initialization tasks.
  * Called from feature-manager.service.ts
   */
  public initialize() {
    if (!this.isInitialized) {

      // Constants.
      this.configureConstants();

      // Routes.
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
* Decorate the ConstantsService with CriminalBackgroundCheckFeespecific constants.
*/
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      CriminalBackgroundCheckFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      CriminalBackgroundCheckFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES,
      CriminalBackgroundCheckFeeConstantsService.Mergers.FUNCTION_TYPES);
    this.criminalBackgroundCheckFeeConstantsService = this.constantsService as ICriminalBackgroundCheckFeeConstantsService;
  }

  /**
   * Configure dynamic Application routes.
   */
  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/criminalBackgroundCheckFeeWizard/criminal-background-check-fee-wizard.module').then(m => m.CriminalBackgroundCheckFeeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/criminalBackgroundCheckFeeWizard/criminal-background-check-fee-wizard.module').then(m => m.CriminalBackgroundCheckFeeWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  /**
   * Returns a fully initialized instance of the appropriate wizard.
   * @param statusChangeApplication 
   * @param webServiceHistoryId 
   * @returns 
   */
  public getWizard = (criminalBackgroundCheckId: number, webServiceHistoryId: number) => {

    // Declarations.
    var functionType = this.functionTypeService.create(this.criminalBackgroundCheckFeeConstantsService.FUNCTION_TYPES.CRIMINAL_BACKGROUND_CHECK, criminalBackgroundCheckId);
    let wizardTags = this.wizardTagsService.create(null);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.criminalBackgroundCheckFeeConstantsService.WEB_SERVICE_PROCESS_TYPES.CRIMINAL_BACKGROUND_CHECK_FEE, wizardTags);

    // Get the wizard instance and START IT.
    const initializeWizard = async (): Promise<any> => {

      // Create the wizard using the wizardService.
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();

      // If there is no webServiceHistoryId for this wizard, we'll need to start one.
      if (!webServiceHistoryId) {

        // Ask the wizard manager to start the wizard (supply your function type please).
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    // Return as observable.
    return from(initializeWizard());
  };

}

/**
 * SecondLicenseCard setting constants to merge into ConstantsService.
 */
export class CriminalBackgroundCheckFeeConstantsService extends ConstantsService {

  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      CRIMINAL_BACKGROUND_CHECK_FEE_WIZARD: "CriminalBackgroundCheck.Fee.Wizard"
    },
    WEB_SERVICE_PROCESS_TYPES: {
      CRIMINAL_BACKGROUND_CHECK_FEE: LicensureOnlineServiceProcessTypeKeys.CriminalBackgroundCheckFee
    },
    FUNCTION_TYPES: {
      CRIMINAL_BACKGROUND_CHECK: 31
    }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    CriminalBackgroundCheckFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    CriminalBackgroundCheckFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES),
    CriminalBackgroundCheckFeeConstantsService.Mergers.FUNCTION_TYPES);
}

export interface ICriminalBackgroundCheckFeeConstantsService extends CriminalBackgroundCheckFeeConstantsService {
}
