import { IProfileSummaryInfoDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ProfileSummaryInfoDto extends BaseDto implements IProfileSummaryInfoDto {
	Type: number;
	MajorKey: string;
	MinorKey: string;
	Title: string;
	Description: string;
	Count: number;
	Parameters: string[];

}
