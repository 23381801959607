import { Injectable, Injector } from '@angular/core';
import { ReachDynamicComponentRegistry } from '@coreModels/reach-dynamic-component-registry';
import { ReachComponentFactoryService } from '@coreServices/reach-component-factory.service';
import { ReachRegionConfiguration, ReachRegionModel } from '../components/reach-region/reach-region.component';

export const REGION_MODEL_INJECTOR_TOKEN = 'REGION_MODEL_INJECTOR_TOKEN';

export class RegionComponentRegistration {
  constructor(public scenarioKey: string, public componentTypeKey: string, public modelResolver: (model: ReachRegionModel) => any = null, public configurationResolver: (configuration: ReachRegionConfiguration) => any = null) { }
}

@Injectable({
  providedIn: 'root'
})
export class RegionManagerService {
  private regionComponentMap = new Map<string, RegionComponentRegistration[]>();

  constructor(private reachComponentFactoryService: ReachComponentFactoryService) { }

  public registerRegionItemWithRegion(regionName: string, scenarioKey: string, componentTypeKey: string, modelResolver: (model: ReachRegionModel) => any = null, configurationResolver: (configuration: ReachRegionConfiguration) => any = null) {
    if (!ReachDynamicComponentRegistry.getComponent(scenarioKey, componentTypeKey)) {
      throw `Error creating region component association. No component was registered matching category ${scenarioKey} and component name ${componentTypeKey}`;
    }

    if (!this.regionComponentMap.get(regionName)) {
      this.regionComponentMap.set(regionName, []);
    }

    this.regionComponentMap.get(regionName).push(new RegionComponentRegistration(scenarioKey, componentTypeKey, modelResolver, configurationResolver));
  }

  public getRegionComponentRegistrationInfo(regionName: string): RegionComponentRegistration[] {
    return this.regionComponentMap.get(regionName);
  }

  public getModelInjector(model: ReachRegionModel, configuration: any, parentInjector: Injector) {
    return this.reachComponentFactoryService.dynamicModelConfigurationInjector(
      REGION_MODEL_INJECTOR_TOKEN,
      model,
      configuration,
      parentInjector
    );
  }
}
