<!-- Summary Text -->
<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="fileUploadDtoHostDocuments" [responsive]="true"
  responsiveLayout="stack">

  <!-- Caption -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <div class="p-text-left reach-list-header">Documents</div>
      <div>
        <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentItemEditorCommand"
          tool-tip="Manage documents"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>
      <th>File Name</th>
      <th>Name</th>
      <th>Date</th>
    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- File Name -->
      <td><span class="p-column-title">File Name</span>{{item.OriginalFileName}}</td>

      <!-- DisplayName -->
      <td><span class="p-column-title">Name</span>{{item.DisplayName}}</td>

      <!-- Date -->
      <td>
        <span class="p-column-title">Date</span>
        {{item.CreatedDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}
      </td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="3">
        <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- IMPORTANT -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
