<div *ngIf="this.settings.isEmploymentVisible">
  <div>
    <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
      [minorKey]="'LicenseUpdateEmployees.IsInState'"></page-summary-text>
  </div>
  <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="data" [responsive]="true"
    responsiveLayout="stack">

    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <span class="p-text-left reach-list-header">Employees</span>
        <div>
          <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="createCommand"
            tool-tip="Add a new item"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>

        <th>Name</th>
        <th *ngIf="settings.employmentTypeEnabled">Type</th>
        <th *ngIf="settings.isPositionColumnVisible">Position</th>
        <th *ngIf="settings.isAddressColumnVisible">Address</th>
        <th>Start</th>
        <th>End</th>
        <th style="width: 8em;"></th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>

        <!-- Name -->
        <td>
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Entity Name</div>
            <div>{{item.EntityName | whenEmpty: '-'}}</div>
          </div>
        </td>

        <!-- Employment Type -->
        <td *ngIf="settings.employmentTypeEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Type</div>
            <div>{{item?.EmploymentType?.Description | whenEmpty: '-'}}</div>
          </div>
        </td>

        <!-- PositionTitleType -->
        <td *ngIf="settings.isPositionColumnVisible && settings.positionPickListEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Position</div>
            <div>{{item?.EmploymentPositionTitle?.Description | whenEmpty: '-'}}</div>
          </div>
        </td>

        <!-- PositionTitle -->
        <td *ngIf="settings.isPositionColumnVisible && !settings.positionPickListEnabled">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Position</div>
            <div>{{item.PositionTitle | whenEmpty: '-'}}</div>
          </div>
        </td>

        <!-- Address -->
        <td *ngIf="settings.isAddressColumnVisible">
          <div class="p-d-flex">
            <div class="p-column-title reach-col-fixed-100">Address</div>
            <span [innerHtml]="item.Addresses[0] | displayAddress | whenEmpty: '-'"></span>
          </div>
        </td>

        <!-- Dates -->
        <td><span class="p-column-title">Start</span>{{item.BeginDate | date: 'MM/dd/yyyy' | whenEmpty: '-'}}</td>
        <td><span class="p-column-title">End</span>{{item.EndDate | date: 'MM/dd/yyyy' | whenEmpty: '-'}}</td>

        <!-- Buttons -->
        <td class="p-text-right">

          <!-- Edit -->
          <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="editorCommand"
            [command-parameter]="item" tool-tip="Edit this item"></button>

          <!-- Delete -->
          <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeCommand"
            [command-parameter]="item" tool-tip="Remove this item"></button>
        </td>

      </tr>

    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columnCount">
          <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
        </td>
      </tr>
    </ng-template>

  </p-table>
</div>
<div *ngIf="this.settings.isEmploymentVisible">
  <div class="p-mt-4" [formGroup]="stepForm">
    <p-checkbox inputId="includeHistoric" formControlName="IncludeHistoric" label="Include Historic"
      [binary]="true"></p-checkbox>
  </div>
</div>
<div *ngIf="!this.settings.isEmploymentVisible">
  <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
    [minorKey]="'LicenseUpdateEmployees.IsOutOfState'"></page-summary-text>
</div>
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
