import { Type } from '@angular/core';
import { CoreLandingTypes } from '@core/index-constants';
import { ReachDynamicComponentRegistry, ReachDynamicComponentScenarioEntry } from './reach-dynamic-component-registry';

/**
 * Registry item that associates route info with a specified page key.
 */
export class RouteInfoRegistryItem {
  constructor(
    public routeTypeKey: string,
    public path: string,
    public scenarioKey: string,
    public componentKey: string,
    public componentRegistryEntry: ReachDynamicComponentScenarioEntry) {
  }
}

/**
 * Registry that associates route info with a key.
 */
export class RouteInfoRegistry {
  private static registry = new Map<string, RouteInfoRegistryItem>();

  public static pages = { ...CoreLandingTypes };

  /**
   * Adds the specified item to the registry.
   * @param item
   */
  public static registerItem(item: RouteInfoRegistryItem) {
    RouteInfoRegistry.registry.set(item.routeTypeKey, item);
  }

  /**
   * Registers the specified page route info against the key.
   * @param routeTypeKey the key that uniquely identifies the page.
   * @param path the route path..
   */
  public static register(
    routeTypeKey: string,
    path: string,
    scenarioKey: string,
    componentKey: string) {
    let componentRegistryEntry = ReachDynamicComponentRegistry.getComponentEntry(scenarioKey, componentKey);
    let item = new RouteInfoRegistryItem(
      routeTypeKey,
      path,
      scenarioKey,
      componentKey,
      componentRegistryEntry
    );

    RouteInfoRegistry.registerItem(item);
  }

  /**
  * Gets the OnlineServiceRegistryItem metadata item matching the specified onlineServiceTypeId from the registry.
  * @param registryType
  */
  public static getItemByRegistryType(registryType: number): RouteInfoRegistryItem {
    return this.getItemByRegistryTypeKey(registryType.toString());
  }

  private static findRouteInfoRegistryItemByKeyCaseInsensitive(map: Map<string, RouteInfoRegistryItem>, key: string): RouteInfoRegistryItem {
    for (const [k, v] of map) {
      if (k.toLowerCase() === key.toLowerCase()) {
        return v;
      }
    }
    return undefined;
  }

  /**
   * Gets the WizardRegistryItem metadata item matching the specified wizardTypeKey from the registry.
   * @param registryTypeKey
   */
  public static getItemByRegistryTypeKey(registryTypeKey: string, caseInsensitiveItemLookup: boolean = false): RouteInfoRegistryItem {
    let item: RouteInfoRegistryItem = null;

    if (!caseInsensitiveItemLookup)
      item = RouteInfoRegistry.registry.get(registryTypeKey);
    else if (caseInsensitiveItemLookup)
      item = RouteInfoRegistry.findRouteInfoRegistryItemByKeyCaseInsensitive(RouteInfoRegistry.registry, registryTypeKey);

    if (!item) {
      throw `No page component was registered for the key '${registryTypeKey}', caseInsensitiveItemLookup ${caseInsensitiveItemLookup}.`;
    }

    return item;
  }

  public static getPath(registryTypeKey: string): string {
    return RouteInfoRegistry.getItemByRegistryTypeKey(registryTypeKey).path;
  }

  public static getComponent(scenarioKey: string, registryTypeKey: string): Type<any> {
    return ReachDynamicComponentRegistry.getComponent(scenarioKey, registryTypeKey);
  }

  public static getLazyComponent(scenarioKey: string, registryTypeKey: string): Promise<Type<any>> {
    return ReachDynamicComponentRegistry.getLazyComponent(scenarioKey, registryTypeKey);
  }
}

/**
 * Registers the specified landing page against the specified key.
 * @param routeTypeKey the key that uniquely identifies the landing page.
 * @param path the path to the summary card.
 * @param detailPath the path to the detail page.
 */
export const registerRouteInfo = (
  routeTypeKey: string,
  path: string,
  scenarioKey: string,
  componentKey: string): void => {
  RouteInfoRegistry.register(
    routeTypeKey,
    path,
    scenarioKey,
    componentKey
  );
}

