import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, ReachHttpClientService, ValidationManagerService } from '@core/core.module';
import { SearchResultListDto } from "src/app/coreShared";
import { DomainDelegateSearchResultItemDto } from "src/app/licensureShared/dto-gen/domain-delegate-search-result-item-dto";
import { IDomainDelegateSearchCriteriaDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({ providedIn: 'root' })
export class DomainDelegateSearchService extends ReachHttpClientService {
  private uri: string;

  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/DomainDelegateSearch`;
  }

  public searchByDelegator(criteria: IDomainDelegateSearchCriteriaDto) {
    return this.get<SearchResultListDto<DomainDelegateSearchResultItemDto>>(`${this.uri}/delegator`, { params: criteria as any });
  }
}


