import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedRuleService {

  constructor() {
  }

  // Service Implementation (Hoisted Methods see style notes above)
  public static dateGreaterThan(dateToEvaluate, compareToDate) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    result.isValid = new Date(dateToEvaluate) > new Date(compareToDate);
    if (!result.isValid) {
      result.errorMessage = "Start date must be before end date.";
    }

    return result;
  }

  public static dateLessThan(dateToEvaluate, compareToDate) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    if (!dateToEvaluate && !compareToDate) {
      return result;
    }

    result.isValid = new Date(dateToEvaluate).setHours(0, 0, 0, 0) < new Date(compareToDate).setHours(0, 0, 0, 0);
    if (!result.isValid) {
      result.errorMessage = "Start date must be before end date.";
    }

    return result;
  }

  public static numberGreaterThan(numberToEvaluate, compareToNumber) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    result.isValid = numberToEvaluate > compareToNumber;
    if (!result.isValid) {
      result.errorMessage = "First number must be less than second number.";
    }

    return result;
  }

  public static numberLessThan(numberToEvaluate, compareToNumber) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    result.isValid = numberToEvaluate < compareToNumber;
    if (!result.isValid) {
      result.errorMessage = "First number must be less than second number.";
    }

    return result;
  }

  public static validateNumericOnly(value) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    // Evaluate rule (ignore empty)
    if ((value != undefined) && (value != "") && !(/^\d+$/.test(value))) {
      result.isValid = false;
      result.errorMessage = "{0} must be numeric.";
    }

    return result;
  }

  public static validateEmailAddress(value) {
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var result = {
      isValid: true,
      errorMessage: ""
    }

    // Evaluate rule (ignore empty)
    if (value && !emailRegex.test(value)) {
      result.isValid = false;
      result.errorMessage = "{0} is not a valid email address.";
    }

    return result;
  }

  public static validateDecimalOnly(value) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    // Evaluate rule (ignore empty)
    if ((value != undefined) && (value != "") && !(/^\d*\.?\d*$/.test(value))) {
      result.isValid = false;
      result.errorMessage = "{0} must be a whole or decimal numeric value.";
    }

    return result;
  }
}
