import { Component, ComponentFactoryResolver, Inject, Injectable, OnInit, OnDestroy, AfterContentInit, Type } from '@angular/core';
import { from, Observable, of, forkJoin, noop } from 'rxjs';

/**
 * Represents an entry in the ReachDynamicComponentRegistry.
 */
export class ReachDynamicComponentScenarioEntry {
  /**
   * 
   * @param componentType the class Type of the registered component.
   * @param selector the selector property of the component's @Component decorator.
   * @param precedenceLevel the precedence level rank of the registered Type v other Types that might be registered against the same keys. Only the highest rank
   * entry will exist in the registry.
   * @param lazyComponent a function that returns a Promise with a component Type from a lazy import path. This path can be a lazy module path or an Ivy lazy component path.
   */
  constructor(
    public componentType: Type<any>,
    public selector: string,
    public precedenceLevel: number = 0,
    public lazyComponent: Promise<any>
  ) {
  }

  public get classFactory(): Promise<any> {
    if (this.lazyComponent) {
      return this.lazyComponent;
    } else {
      return of(this.componentType).toPromise();
    }
  }
}

/**
 * Maps a component type key string to a component type.
 */
export class ReachDynamicComponentScenario {
  private components = new Map<string, ReachDynamicComponentScenarioEntry>();

  private findEntryCaseInsensitive(map: Map<string, ReachDynamicComponentScenarioEntry>, key: string): ReachDynamicComponentScenarioEntry {
    for (const [k, v] of map) {
      if (k.toLowerCase() === key.toLowerCase()) {
        return v;
      }
    }
    return undefined;
  }
  /**
   * Gets the Component of the specified name.
   * @param componentTypeKey the key associated with a given component Type or hierarchy.
   */
  getComponent(componentTypeKey: string): Type<any> | undefined {
    const entry = this.findEntryCaseInsensitive(this.components, componentTypeKey);
    return entry.componentType;
  }

  /**
   * Gets the Lazy Component dynamic import Promise of the specified component.
   * @param componentTypeKey the key associated with a given component Type or hierarchy.
   */
  getLazyComponent(componentTypeKey: string): Promise<Type<any>> | undefined {
    const entry = this.findEntryCaseInsensitive(this.components, componentTypeKey);
    return entry.lazyComponent;
  }

  /**
 * Gets the Component of the specified name.
 * @param componentTypeKey the key associated with a given component Type or hierarchy.
 */
  getComponentEntry(componentTypeKey: string): ReachDynamicComponentScenarioEntry | undefined {
    return this.findEntryCaseInsensitive(this.components, componentTypeKey);
  }

  /**
   * Adds/updates the component of the specified name.
   * @param componentTypeKey the metadata key with which the component will be associated.
   * @param componentType
   * @param selector the selector from the @Component decorator.
   * @param precedenceLevel the precedence level for overriding. Defaults to (lowest precedence). If this method is invoked multiple times for the same componentTypeKey, they invocation
   * with the highest (caller-configured) precedence level wins. Facilitates registration for class hierarchies, base class with default precedence, derived class with precedence n > 0, derived class with precedence m > n (wins).
   * @param lazyComponent the Ivy component lazy load import Promise: import(`./foo/foo.component`).then(({ FooComponent }) => FooComponent); This returns a Promise<Type<FooComponent>>.
   */
  setComponent(componentTypeKey: string, componentType: Type<any>, selector: string, precedenceLevel: number = 0, lazyComponent: Promise<Type<any>> = null): void {
    let existing = this.components.get(componentTypeKey);
    if (!existing || (precedenceLevel > existing.precedenceLevel)) {
      let entry = new ReachDynamicComponentScenarioEntry(componentType, selector, precedenceLevel, lazyComponent);
      this.components.set(componentTypeKey, entry);
    }
  }
}

/**
 * Registers component type/class keyed by class name string and category.
 */
export class ReachDynamicComponentRegistry {
  private static registry = new Map<string, ReachDynamicComponentScenario>();

  /**
   * Sets the NameComponentMap map for the specified category name.
   * @param scenarioKey the scenario metadata key.
   * @param value the NameComponentMap for the category.
   */
  static setScenario(scenarioKey: string, value: ReachDynamicComponentScenario) {
    this.registry.set(scenarioKey, value);
  }

  /**
   * Gets the NameComponentMap map for the specified category name.
   * @param scenarioKey the scenario metadata key.
   */
  static getScenario(scenarioKey: string): ReachDynamicComponentScenario | undefined {
    return this.registry.get(scenarioKey);
  }

  /**
   * Gets the Component for the specified componentName within the scope of the specified category.
   * @param scenarioKey the scenario metadata key.
   * @param componentTypeKey the component type metadata key.
   * @returns the component Type.
   */
  static getComponent(scenarioKey: string, componentTypeKey: string): Type<any> {
    let mapForScenario = this.registry.get(scenarioKey);
    if (mapForScenario) {
      return mapForScenario.getComponent(componentTypeKey);
    }

    return null;
  }

  /**
 * Gets the Component for the specified componentName within the scope of the specified category.
 * @param scenarioKey the scenario metadata key.
 * @param componentTypeKey the component type metadata key.
 * @returns the Promise for the lazy component.
 */
  static getLazyComponent(scenarioKey: string, componentTypeKey: string): Promise<Type<any>> {
    let mapForScenario = this.registry.get(scenarioKey);
    if (mapForScenario) {
      return mapForScenario.getLazyComponent(componentTypeKey);
    }

    return null;
  }

  /**
   * Gets the Component for the specified componentName within the scope of the specified category.
   * @param scenarioKey the scenario metadata key.
   * @param componentTypeKey the component type metadata key.
   * @returns the component Type.
   */
  static getComponentEntry(scenarioKey: string, componentTypeKey: string): ReachDynamicComponentScenarioEntry {
    let mapForScenario = this.registry.get(scenarioKey);
    if (mapForScenario) {
      return mapForScenario.getComponentEntry(componentTypeKey);
    }

    return null;
  }

  /**
   * Adds/updates the Component in the scope of the specified category.
   * @param scenarioKey the scenario metadata key.
   * @param componentTypeKey the component type metadata key.
   * @param componentType the Type of the component.
   * @param selector the selector from the @Component decorator.
   * @param precedenceLevel the precedence level for overriding. Defaults to (lowest precedence). If this method is invoked multiple times for the same componentTypeKey, they invocation
   * with the highest (caller-configured) precedence level wins. Faciliates registration for class hierarchies, base class with default precedence, derived class with precedence n > 0, derived class with precedence m > n (wins).
   * @param lazyComponent the Ivy component lazy load import Promise: import(`./foo/foo.component`).then(({ FooComponent }) => FooComponent); This returns a Promise<Type<FooComponent>>.
   */
  static setComponent(scenarioKey: string, componentTypeKey: string, componentType: Type<any>, selector: string, precedenceLevel: number = 0, lazyComponent: Promise<Type<any>> = null) {
    let mapForScenario = this.registry.get(scenarioKey);
    if (!mapForScenario) {
      mapForScenario = new ReachDynamicComponentScenario();
      ReachDynamicComponentRegistry.setScenario(scenarioKey, mapForScenario);
    }

    mapForScenario.setComponent(componentTypeKey, componentType, selector, precedenceLevel, lazyComponent);
  }
}

/**
 * Decorator function to add decorated Component class to the ReachDynamicComponentRegistry, scoped to the specified scenario key.
 * @param scenarioKey the scenario metadata key (feature area, category, ...).
 * @param componentTypeKey the component type metadata key (associated with class, class hierarchy, class group...)
 *  * @param selector the selector from the @Component decorator.
* @param precedenceLevel the precedence level for overriding. Defaults to (lowest precedence). If this method is invoked multiple times for the same componentTypeKey, they invocation
* with the highest (caller-configured) precedence level wins. Faciliates registration for class hierarchies, base class with default precedence, derived class with precedence n > 0, derived class with precedence m > n (wins).
 */
export const DynamicComponent = (scenarioKey: string, componentTypeKey: string, selector: string, precedenceLevel: number = 0): any => {
  return (cls) => {
    ReachDynamicComponentRegistry.setComponent(scenarioKey, componentTypeKey, cls, selector);
  };
};

/**
 * Registers the specified component type against the specified metadata keys.
 * @param scenarioKey the scenario metadata key (feature area, category, ...).
 * @param componentTypeKey the component type metadata key (associated with class, class hierarchy, class group...)
 * @param componentType the Type of the registered class (e.g., FooComponent).
 * @param selector the selector from the @Component decorator.
 * @param precedenceLevel the precedence level for overriding. Defaults to (lowest precedence). If this method is invoked multiple times for the same componentTypeKey, they invocation
 * with the highest (caller-configured) precedence level wins. Faciliates registration for class hierarchies, base class with default precedence, derived class with precedence n > 0, derived class with precedence m > n (wins).
 * @param lazyComponent the Ivy component lazy load import Promise: import(`./foo/foo.component`).then(({ FooComponent }) => FooComponent); This returns a Promise<Type<FooComponent>>.
 * same metadata keys, the component with the highest precedence replaces an already registered component of lower precedence. Allows derived class components to override base class components,
 * for example.
 */
export const registerDynamicComponent = (scenarioKey: string, componentTypeKey: string, componentType: Type<any>, selector: string, precedenceLevel: number = 0, lazyComponent: Promise<Type<any>> = null): void => {
  ReachDynamicComponentRegistry.setComponent(scenarioKey, componentTypeKey, componentType, selector, precedenceLevel, lazyComponent);
}
