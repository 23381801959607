import { Command } from './command';
export class MenuItem {
    constructor(public command: Command = null, public isHeader: boolean = false, public menuGroupName: string = null, public hasActiveChildren: Function = null) { }

    public get commandText() {
        return this.command ? this.command.commandText : "";
    }

    public get routeUrl(): string {
        return this.command ? (this.command.routeUrl ? this.command.routeUrl : "") : "";
    }

    public get isEnabled(): boolean {
        return this.command && this.command.canExecute ? this.command.canExecute() : false;
    }

    public get isVisible(): boolean {
      return this.isHeader
        ? (this.hasActiveChildren ? this.hasActiveChildren() : false)
        :  this.isEnabled;
    }
}
