export class LicenseSelectorFieldConfiguration {
  constructor(
    public selectorLabel: string, 
    public dialogTitle: string, 
    public searchCriteria: LicenseSelectorFieldConfigurationSearchCriteria,
    public enableLicenseNotFoundCheckbox: boolean = true) {
  }
}

export class LicenseSelectorFieldConfigurationSearchCriteria {
  constructor(public mode: "Individual" | "Organization" = "Individual", public possibleLicenseTypes: string[] = []) {
  }
}