// FRAMEWORK
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// CORE
import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  ValidationManagerService} from '@core/core.module';

@Injectable({
  providedIn: 'root'
})
export class OrganizationalPayorService extends ReachHttpClientService {

  // FIELDS

  // CTOR
  constructor(
    // Base.
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
  ) {

    // Base.
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }
}
