import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { of, from } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { Command, DialogSettings, registerDynamicComponent, WizardInjector, WIZARD_INJECTOR_TOKEN } from 'src/app/core/index-models';
import { CheckboxContentService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService, CommandService, UtilitiesService, ListService, BusyManagerService } from 'src/app/core/index-services';
import { IStateDto } from 'src/app/coreShared/core-shared.module';
import { IEntityHospitalPrivilegeDto } from 'src/app/licensureShared/licensure-shared.module';
import { ProfessionalProfileService } from '../../services/professional-profile.service';
import { ProfileHospitalPrivilegesEditorComponent, ProfileHospitalPrivilegesEditorComponentKey } from './editor/profile-hospital-privileges-editor.component';

export const ProfileHospitalPrivilegesComponentSelector: string = 'profile-hospital-privileges';
export const ProfileHospitalPrivilegesComponentKey: string = 'profileHospitalPrivileges';

@Component({
  selector: ProfileHospitalPrivilegesComponentSelector,
  templateUrl: './profile-hospital-privileges.component.html',
  styleUrls: ['./profile-hospital-privileges.component.scss']
})
export class ProfileHospitalPrivilegesComponent extends WizardStepComponent implements OnInit {

  presentEditorCommand: Command;
  removeItemCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings = null;

  possibleStates: IStateDto[];

  public get hospitalPrivileges(): IEntityHospitalPrivilegeDto[] { return this.wizard.model.professionalProfile.EntityHospitalPrivileges.filter(item => !item.IsDeleted) }

  constructor(
    checkboxContentService: CheckboxContentService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector

    // Custom
    , private commandService: CommandService
    , private utilitiesService: UtilitiesService
    , private confirmationService: ConfirmationService
    , private professionalProfileService: ProfessionalProfileService
    , private listService: ListService
    , private busyManagerService: BusyManagerService
  ) {
    super(constantsService, validationManagerService, wizardInjector, ProfileHospitalPrivilegesComponent.name, checkboxContentService, elementRef);
  }

  ngOnInit(): void {
    this.loadLookupLists();
    this.initCommands();
  }

  protected loadLookupLists(): void {

    const doInit = async (): Promise<any> => {
      this.possibleStates = this.listService.filterInactiveItems(await (this.listService.getStates()).toPromise()) as IStateDto[];
      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (item: IEntityHospitalPrivilegeDto) => {
    if (!item.IsNew) item.IsDirty = true;
    this.utilitiesService.addOrReplace(this.wizard.model.professionalProfile.EntityHospitalPrivileges, item);
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (selectedItem: IEntityHospitalPrivilegeDto) => {

    const initDialog = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ProfileHospitalPrivilegesEditorComponent, // Content type
        ProfileHospitalPrivilegesEditorComponentKey, // Content key
        selectedItem ? "Hospital Privilege" : "Hospital Privilege - {new}", // Title
        selectedItem ?? { Id: 0, IsNew: true, IsDeleted: false, LocalId: this.utilitiesService.guid(), EntityId: this.wizard.model.professionalProfile.Id } as IEntityHospitalPrivilegeDto, // Model
        null, // OK command does not close dialog
        true); // Use model reference

      this.dialogSettings.initializationData.possibleStates = this.possibleStates;
      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      return of(true).toPromise();
    }

    return from(initDialog());
  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (item: IEntityHospitalPrivilegeDto) => {

    this.confirmationService.confirm({
      message: `${item.Privilege} privilege at ${item.Facility} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',
      accept: () => {

        const doSave = async (): Promise<any> => {
          item.IsDeleted = true;

          if (item.LocalId) {
            let idx = this.wizard.model.professionalProfile.EntityHospitalPrivileges.findIndex(i => i.LocalId ? item.LocalId === i.LocalId : item.Id === i.Id);
            this.wizard.model.professionalProfile.EntityHospitalPrivileges.splice(idx, 1);
          }
          else {
            this.wizard.model.professionalProfile = await this.professionalProfileService.save(this.wizard.model.professionalProfile).toPromise();
          }

          return of(true);
        };

        return from(doSave());
      }
    });
  }

}

registerDynamicComponent(ReachScenarios.ProfessionalProfileWizard, ProfileHospitalPrivilegesComponentKey, ProfileHospitalPrivilegesComponent, ProfileHospitalPrivilegesComponentSelector);