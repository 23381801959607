/**
 * Enumerates the component types for the Core LandingPage.
 */
export enum LandingCategories {
  General,
  Cart,
  Invoice
}

/**
 * Enumerates the component types for the Core LandingPage.
 */
export enum CoreLandingTypes {
  Landing,
  GeneralSummary,
  GeneralDetail,
  CartSummary,
  CartDetail,
  InvoiceDetail,
  InvoiceDetailLazy,
  InvoiceSummary,
  InvoiceSummaryLazy,
  ActivityDetail
}

/**
 * Component keys for Core Landing Page components.
 */
export class LandingComponentKeys {
  public static readonly Landing: string = CoreLandingTypes[CoreLandingTypes.Landing];
  public static readonly GeneralSummary: string = CoreLandingTypes[CoreLandingTypes.GeneralSummary];
  public static readonly GeneralDetail: string = CoreLandingTypes[CoreLandingTypes.GeneralDetail];
  public static readonly CartSummary: string = CoreLandingTypes[CoreLandingTypes.CartSummary];
  public static readonly CartDetail: string = CoreLandingTypes[CoreLandingTypes.CartDetail];
  public static readonly InvoiceSummary: string = CoreLandingTypes[CoreLandingTypes.InvoiceSummary];
  public static readonly InvoiceSummaryLazy: string = CoreLandingTypes[CoreLandingTypes.InvoiceSummaryLazy];
  public static readonly InvoiceDetail: string = CoreLandingTypes[CoreLandingTypes.InvoiceDetail];
  public static readonly InvoiceDetailLazy: string = CoreLandingTypes[CoreLandingTypes.InvoiceDetailLazy];
  public static readonly ActivityDetail: string = CoreLandingTypes[CoreLandingTypes.ActivityDetail];
}

