import { IAddressTypeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class AddressTypeDto extends BaseDto implements IAddressTypeDto {
	Description: string;
	IsInactive: boolean;
	Hierarchy: number;
	FullDescription: string;
	FunctionTypeId?: number;
	IsPublic?: boolean;
	ParentAddressTypeId?: number;
	Parent: IAddressTypeDto;

}
