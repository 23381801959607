import { IDocumentDto,
IDomainAuditDto,
IDomainReasonForChangeDtoHost,
IEntityAddressDto,
IEntityCommentDto,
IRelatedToBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ILicensureEntityInfoDto,
ISupervisorDesignationDto,
ISupervisorDto } from './licensure-shared-dto';

export class SupervisorDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisorDto {
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	EntityId?: number;
	ProfessionTypeCode: string;
	FunctionTypeId?: number;
	FunctionNumber: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DomainAudits: IDomainAuditDto[];
	Designations: ISupervisorDesignationDto[];
	Comments: IEntityCommentDto[];
	PlansCount: number;
	VerificationsCount: number;
	AllDesignations: string;
	EntityInfo: ILicensureEntityInfoDto;
	AddressTypeId: number;
	Addresses: IEntityAddressDto[];
	AddressMailing: IEntityAddressDto;
	AddressPublic: IEntityAddressDto;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;

}
