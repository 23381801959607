import { InjectionToken, Injectable } from '@angular/core';
import { DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ConstantsService } from '../constants-provider.service';
import { FeatureManagerService } from './feature-manager.service';
import { ListService } from '@core/core.module';

/**
 * Container service for various shared services.
 */
@Injectable({ providedIn: 'root' })
export class SharedConfigurationService {
  public defaultProvider: DefaultProviderConfigurationService;
  public constantsService: ConstantsService;
  public featureManagerService: FeatureManagerService;
  public listService: ListService;
  constructor() { }
}
