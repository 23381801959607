import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe transforms a raw SSN into a formatted SSN string.
 */
@Pipe({
    name: 'displaySsn'
})
export class DisplaySsnPipe {
  /**
   * Transforms the raw SSN string passed in as the value param into a formatted SSN string.
   * @param value
   */
  transform(rawSSN: string): string {
      if (rawSSN) {
      var formattedSsn = "***-**-";

      if (String(rawSSN).length < 4)
          formattedSsn += rawSSN;
      else {
          var ssnLength = String(rawSSN).length;
          formattedSsn += String(rawSSN).substring(ssnLength, ssnLength - 4);
      }

      return formattedSsn;
    }

    return "";
  }
}
