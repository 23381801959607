// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponentKeys } from '@core/index-constants';
import { RouteInfoRegistry } from '@core/index-models';
import { Principal } from '@coreModels/principal';
import { OnlineUserDto } from '@coreShared/core-shared.module';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { LocalStorageService } from './local-storage.service';
import { OnlineServiceLinkManagerService } from './online-service-link-manager.service';
import { OnlineUserService } from './online-user.service';
import { SessionService } from './session.service';
import { UserProfileService } from './user-profile.service';
import { ValidationManagerService } from './validation-manager.service';

/**
 * Manages user Login/Logout operations and the current user principal.
 */
@Injectable({ providedIn: 'root' })
export class UserManagerService {

  // PRIVATE FIELDS
  private currentPrincipal: Principal = null;
  private _login$: BehaviorSubject<Principal> = new BehaviorSubject<Principal>(this.currentPrincipal);
  private _logout$: Subject<Principal> = new Subject<Principal>();
  private _userProfileRefreshed$: Subject<Principal> = new Subject<Principal>();
  private sessionTimer = null;
  private sessionTimeoutIntervalMs: number = 60000 * 29; // default to 29 min -- get from sys setting

  // PUBLIC FIELDS
  public intendedDestination: string | null = null;
  public login$ = this._login$.asObservable();
  public logout$ = this._logout$.asObservable();
  public userProfileRefreshed$ = this._userProfileRefreshed$.asObservable();
  public currentUser: Observable<OnlineUserDto>;
  public currentUserDto: OnlineUserDto;

  public get isLoggedIn(): boolean { return this.currentPrincipal ? true : false; }

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private userProfileService: UserProfileService,
    private sessionService: SessionService,
    private onlineUserService: OnlineUserService,
    private onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private validationManagerService: ValidationManagerService
  ) {
  }

  public getCurrentPrincipal() {
    return this.currentPrincipal;
  }

  public getCurrentPrincipalDisplayName() {
    return this.currentPrincipal == null ? "" : this.currentPrincipal.displayName;
  }

  public hasSessionToken() {
    return this.localStorageService.retrieve(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID) != null; // True if an existing session token is cached.
  }

  /**
 * Refreshes the current user profile data from the server.
 */
  public refreshUserAndProfile() {
    const doRefresh = async (): Promise<any> => {
      let username = this.currentPrincipal.user.UserAccount.UserName;
      if (username) {
        let user = await this.onlineUserService.getByUserName(this.currentPrincipal.user.UserAccount.UserName, this.currentPrincipal.token).toPromise();
        this.currentPrincipal.user = user;
        await this.refreshProfile().toPromise();
      }

      return of(true).toPromise();
    }

    return from(doRefresh());
  }

  /**
   * Refreshes the current user profile data from the server.
   */
  public refreshProfile() {

    const doRefresh = async (): Promise<any> => {
      try {
        this.currentPrincipal.user.UserAccount.profile = await this.userProfileService.getById(this.currentPrincipal.user.UserAccountId, this.currentPrincipal.token).toPromise();
        this.localStorageService.save(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID, this.currentPrincipal);

        // Update the web link
        this.onlineServiceLinkManagerService.removeProfileLinks();
        this.mapProfileWebLinks(this.currentPrincipal);

        // notify
        this.raiseUserProfileRefreshed();

        return of(true).toPromise();
      }
      catch (profileError) {
        this.validationManagerService.addServerValidationFailureMessageAsync("Invalid User/Profile", "login", this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION);
        return this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
      }
    };

    return from(doRefresh());
  }

  /**
   * Logs the user onto the system using the specified credentials.
   * Raises the "userManager.login$" event.
   */
  public login(userName: string, password: string): Observable<Principal> {
    const principal = new Principal();
    this.validationManagerService.clearApplicationValidationErrors();

    return Observable.create(observer => {
      // Attempt to create a session with the supplied credentials
      this.sessionService.createFromUsernamePasswordCredential(userName, password)
        .subscribe(sessionCreateResponse => {
          // Set token
          principal.token = sessionCreateResponse.Token;

          // Restart the session timer
          this.restartPrincipalSession(principal);

          // Now get the user
          this.onlineUserService.getByUserName(userName, principal.token)
            .subscribe(getByUserNameResponse => {
              // add user to principal
              principal.user = getByUserNameResponse;

              // Derive a display name for the user
              principal.displayName = principal.user.IsIndividual
                ? principal.user.FirstName + ' ' + principal.user.LastName
                : principal.user.LastName;

              // Get the user's profile
              this.userProfileService.getById(principal.user.UserAccountId, principal.token)
                .subscribe(
                  getByIdResponse => {
                    // Add profile to principal
                    principal.user.UserAccount.profile = getByIdResponse;
                    this.mapProfileWebLinks(principal);

                    // Place Principal in local
                    this.updatePrincipalCache(principal);

                    // Set current Principal property
                    this.currentPrincipal = principal;

                    //this.login$.emit(this.currentPrincipal);
                    this._login$.next(this.currentPrincipal);
                    observer.next(this.currentPrincipal);
                    observer.complete();
                  },
                  profileError => {
                    this.validationManagerService.addServerValidationFailureMessageAsync("Invalid User/Profile", "login", this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION);
                  });
            });
        },
          error => {
            this.validationManagerService.addServerValidationFailureMessageAsync("Invalid Username or Password", "login", this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION);
          });
    });
  }

  /**
   * Logs the user onto the system using an existing principal from local storage.
   * Raises the "userManager.login$" event.
   */
  public loginFromLocalStorage() {
    const principalObservable = new Observable((observer) => {
      if (!this.currentPrincipal) {

        // check to see if we have one in local storage
        var principal: Principal =
          this.localStorageService.retrieve(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID);

        if (principal && principal.user != null && principal.user.UserAccountId != null) {
          return this.userProfileService.getById(principal.user.UserAccountId, principal.token)
            .subscribe(getProfileResponse => {

              // Add current profile to the cached principal
              principal.user.UserAccount.profile = getProfileResponse;

              // Place Principal in local
              this.updatePrincipalCache(principal);

              // Set current Principal property
              this.currentPrincipal = principal;
              this.mapProfileWebLinks(principal);

              // Restart the session timer
              this.restartPrincipalSession(principal);

              //this.login$.emit(this.currentPrincipal);
              this._login$.next(this.currentPrincipal);

              observer.next(this.currentPrincipal);
              observer.complete();
            },
              error => {
                // Stale token
                this.currentPrincipal = null;
                observer.next(this.currentPrincipal);
                observer.complete();
              });
        } else {
          observer.next(this.currentPrincipal);
          observer.complete();
        }
      } else {
        observer.next(this.currentPrincipal);
        observer.complete();
      }
    });

    return principalObservable;
  }

  /**
   * Logs the user onto the system using the specified CaptchaResponse.
   * Raises the "userManager.login$" event.
   */
  public captchaLogin(captchaResponse) {
    // Create a new principal
    var principal: Principal = new Principal(); //this.principalService.create();

    // Attempt to create a session with the supplied credentials
    return this.sessionService.createFromCaptchaCredential(captchaResponse).subscribe(sessionCreateResponse => {

      // Set token
      principal.token = sessionCreateResponse.Token;

      // Place Principal in local
      this.updatePrincipalCache(principal);

      // Set current Principal property
      this.currentPrincipal = principal;

      // Restart the session timer
      this.restartPrincipalSession(principal);

      //this.login$.emit(this.currentPrincipal);
      this._login$.next(this.currentPrincipal);
    });
  }

  /**
   * Logs the user off the system.
   * Raises the "userManager.logout$" event.
   */
  public logout() {
    this.clearPrincipalCache();
    this.clearPrincipalSession(this.currentPrincipal);
    this.currentPrincipal = null;
    this.onlineServiceLinkManagerService.removeProfileLinks();
    this._logout$.next(this.currentPrincipal);
  }

  // Helpers
  /**
  * Starts the session timer to the specified timeout interval or the configured interval.
  * @param timeoutIntervalMs the timeout interval in ms. Uses the configured value if the specified value is null.
  * */
  private startSessionTimer(timeoutIntervalMs: number = null) {
    this.clearSessionTimer();

    if (!this.sessionTimerEnabled) {
      return;
    }

    let intervalToNextTimeout = timeoutIntervalMs || this.sessionTimeoutIntervalMs;
    const doNavToLogin = () => {
      this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    };

    this.sessionTimer = setTimeout(
      doNavToLogin,
      intervalToNextTimeout
    );
  }

  /**
   * Indicates whether the system timer feature should be enabled.
   * */
  private get sessionTimerEnabled(): boolean {
    return false; // system setting - disabled for now
  }

  /**
   * Clears invocation of the session timer.
   * */
  private clearSessionTimer() {
    if (this.sessionTimer) {
      clearTimeout(this.sessionTimer);
      this.sessionTimer = null;
    }
  }

  /**
  * Restarts the session start time tracking and the session timer.
  * */
  private restartPrincipalSession(principal: Principal) {
    let msToTimeout = this.getMsToSessionTimeout(principal);
    if (msToTimeout) {
      this.startSessionTimer(msToTimeout);
    }
    else {
      this.setPrincipalSessionStartTime(principal);
      this.startSessionTimer();
    }
  }

  /**
  * Clears the session start time tracking and the session timer.
  * */
  private clearPrincipalSession(principal: Principal) {
    if (principal) {
      (principal as any).sessionStartTime = null;
    }

    this.clearSessionTimer();
  }

  /**
  * Sets the session start time on the specified principal. (Gets serialized with the principal on caching.)
  * */
  private setPrincipalSessionStartTime(principal: Principal) {
    (principal as any).sessionStartTime = (new Date()).getTime();
  }

  /**
   * Gets the start time (ms) of the session represented by the current principal.
   * @param principal
   */
  private getPrincipalSessionStartTime(principal: Principal): number {
    return (principal as any)?.sessionStartTime;
  }

  /**
  * Gets expected number of ms to the session token timeout.
  * @param principal
  */
  private getMsToSessionTimeout(principal: Principal): number {
    let msToSessionTimeout: number = null;
    let sessionStartTime = this.getPrincipalSessionStartTime(principal);
    if (sessionStartTime) {
      let currentTime = (new Date()).getTime();
      msToSessionTimeout = currentTime - sessionStartTime;
    }

    return msToSessionTimeout;
  }

  /**
   * Raises the "userManager.userProfileRefreshed$" event.
   */
  private raiseUserProfileRefreshed() {
    this._userProfileRefreshed$.next(this.currentPrincipal);
  }

  private updatePrincipalCache(principal: Principal) {
    this.localStorageService.removeItem(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID);
    this.localStorageService.save(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID,
      principal);
  }

  private clearPrincipalCache() {
    this.localStorageService.removeItem(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID);
  }

  private mapProfileWebLinks(principal: Principal) {
    if (principal.user.UserAccount.profile && principal.user.UserAccount.profile.WebLinks) {
      this.onlineServiceLinkManagerService.addLinks(principal.user.UserAccount.profile.WebLinks, true);
    }
  }
}
