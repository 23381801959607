import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEntityTrainingDto,
IEntityTrainingStatusDto } from './licensure-shared-dto';

export class EntityTrainingDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityTrainingDto {
	EntityId?: number;
	Program: string;
	Specialty: string;
	StartDate?: Date;
	EndDate?: Date;
	StatusId?: number;
	City: string;
	State: string;
	Country: string;
	EntityTrainingStatus: IEntityTrainingStatusDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DerivedDescription: string;

}
