import { IDomainQuestionnaireDto,
IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ISupervisorDesignationFormDto,
ISupervisorDesignationDto,
ISupervisorDesignationStatusDto,
ISupervisorDesignationTypeDto } from './licensure-shared-dto';

export class SupervisorDesignationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisorDesignationDto {
	SupervisorId: number;
	StatusId?: number;
	TypeId?: number;
	FormId?: number;
	EffectiveDate?: Date;
	ExpireDate?: Date;
	ApplicationReceivedDate?: Date;
	ExternalCredentialDescription: string;
	Status: ISupervisorDesignationStatusDto;
	Type: ISupervisorDesignationTypeDto;
	Form: ISupervisorDesignationFormDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	StrongestQuestionnaire: IDomainQuestionnaireDto;
	DomainQuestionnaires: IDomainQuestionnaireDto[];

}
