import { ITimeCommitmentDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class TimeCommitmentDto extends LookupDto implements ITimeCommitmentDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
