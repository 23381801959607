// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import * as _ from 'lodash-es';
import { forkJoin, from, of } from 'rxjs';

// Core
import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN,
  ListService, LIST_SERVICE_TOKEN,
  ReachScenarios,
  IReachDialogContentComponent,
  ReachDialogContentComponent,
  ValidationManagerService,
  registerDynamicComponent,
  BusyManagerService,
  UtilitiesService
} from '@core/core.module';

// Shared
import { ISpecialtyTrainingCheckListItemDto } from 'src/app/licensureShared/licensure-shared.module';
import { IStateDto, ICountryDto } from '@coreShared/core-shared.module';

// Constants
export const ApplicationOtherMembershipsEditorComponentSelector: string = 'application-other-memberships-editor';
export const ApplicationOtherMembershipsEditorComponentComponentKey: string = 'applicationOtherMembershipsEditor';
const USA: string = "USA";
const CANADA: string = "CANADA";
const STATE: string = "State";
const PROVINCE: string = "Province";

@Component({
  selector: ApplicationOtherMembershipsEditorComponentSelector,
  templateUrl: './application-other-memberships-editor.component.html',
  styleUrls: ['./application-other-memberships-editor.component.scss']
})
export class ApplicationOtherMembershipsEditorComponent extends ReachDialogContentComponent<ISpecialtyTrainingCheckListItemDto> implements OnInit, IReachDialogContentComponent {

  // FIELDS
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];

  // PROPERTIES
  public get filteredPossibleStates(): IStateDto[] { return this.possibleStates.filter(x => x?.Country?.toUpperCase() == this.outputModel?.ApplicationTraining?.Country?.toUpperCase()); }
  public get stateLabel(): string { return this.contentForm.get("Country").value?.Description == CANADA ? PROVINCE : STATE; }
  public get stateVisible(): boolean { return this.outputModel.ApplicationTraining.Country == USA || this.outputModel.ApplicationTraining.Country == CANADA; }
  public get standardNumber(): boolean { return !(this.contentForm.get("IrregularNumber").value as boolean) }

  // CTOR
  constructor(

    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(LIST_SERVICE_TOKEN) private listService: ListService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,

    // Custom
    private formBuilder: FormBuilder,
    private busyManagerService: BusyManagerService,
    private utilitiesService: UtilitiesService,
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";

    // Base.
    await super.ngOnInit();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    var x = this.outputModel.ApplicationTraining;

    this.contentForm = this.formBuilder.group({
      Facility: [x.Facility, [Validators.required, Validators.maxLength(100)]],
      Address: [x.Address, [Validators.required, Validators.maxLength(100)]],
      City: [x.City, [Validators.required, Validators.maxLength(40)]],
      State: [null, [Validators.required]],
      Country: [null, [Validators.required, Validators.maxLength(60)]],
      Phone: [x.Phone, [Validators.required, Validators.maxLength(20)]],
      StartDate: [x.StartDate, [Validators.required, Validators.maxLength(11)]],
      EndDate: [x.EndDate, [Validators.required, Validators.maxLength(11)]],
      Specialty: [x.Specialty, [Validators.required, Validators.maxLength(80)]],
      IrregularNumber: [false],
    });

    // EXTRA STEP: Determine if the phone number inputMask can be used.
    this.InspectPhoneNumber();

    super.modelToForm();
  }

  /**
  * Determine if the phone number inputMask can be used.
  */
  protected InspectPhoneNumber(): void {

    // Use the input mask by default for new items.
    if (this.outputModel.IsNew) {
      this.contentForm.get('IrregularNumber').setValue(false);
      return;
    }

    // Otherwise, determine if the input mask should be used.
    // The input mask should be used if the outputModel.Number has 10 digits in it.
    let val: string = this.outputModel.ApplicationTraining.Phone ?? "";
    let digitsOnly: string = val.replace(/\D/g, '');

    digitsOnly.length == 10
      ? this.contentForm.get('IrregularNumber').setValue(false)
      : this.contentForm.get('IrregularNumber').setValue(true);
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;
    var x = this.outputModel.ApplicationTraining;

    x.Facility = this.contentForm.get('Facility').value;
    x.Address = this.contentForm.get('Address').value;
    x.City = this.contentForm.get('City').value;
    x.State = this.contentForm.get('State').value?.StateCode ?? "";
    x.Country = this.contentForm.get('Country').value?.Description ?? "";
    x.Phone = this.contentForm.get('Phone').value;
    x.StartDate = this.contentForm.get('StartDate').value;
    x.EndDate = this.contentForm.get('EndDate').value;
    x.Specialty = this.contentForm.get('Specialty').value;

    super.formToModel();
  }

  /**
  * Wires event handlers for the content form.
  */
  protected override initializeEventHandlers(): void {

    // Country
    this.contentForm.get('Country').valueChanges.subscribe(x => {
      let selectedCountry = x?.Description;

      // Bail immediately if...
      if (!selectedCountry) return;

      selectedCountry == USA || selectedCountry == CANADA
        ? this.utilitiesService.enableDisable(this.contentForm.get('State'), true, true)
        : this.utilitiesService.enableDisable(this.contentForm.get('State'), false, true);

    });

    // Base.
    super.initializeEventHandlers();
  }

  /**
   * Loads required lookup data.
   */
  protected override async loadLookupLists(): Promise<any> {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCountries()]).toPromise();

      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]) as IStateDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[1]) as ICountryDto[];

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT).toPromise();
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  protected override initDropdowns(): void {

    // If the model is new, we need to assign some default values.
    this.assignDefaults();

    var x = this.outputModel.ApplicationTraining;
    if (x.Country) this.contentForm.get('Country').setValue(this.possibleCountries.find(item => item.Description == x.Country));
    if (x.State) this.contentForm.get('State').setValue(this.possibleStates.find(item => item.StateCode == x.State));

    // Base.
    super.initDropdowns();
  }

  /**
   * If the model is new, we need to assign some default values.
   */
  protected assignDefaults(): void {
    if (this.outputModel.IsNew) this.outputModel.ApplicationTraining.Country = USA;
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, ApplicationOtherMembershipsEditorComponentComponentKey, ApplicationOtherMembershipsEditorComponent, ApplicationOtherMembershipsEditorComponentSelector);