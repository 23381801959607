import {
	IDocumentDto, IDomainChecklistItemDto,
	IDomainCriminalBackgroundCheckDto,
	IDomainReasonForChangeDtoHost,
	IRelatedToBaseDto
} from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import {
	ILicenseInfoDto,
	ILicenseStatusChangeApplicationDto,
	ILicenseStatusChangeApplicationStatusDto,
	ILicenseStatusDto,
	ILicenseStatusTransitionDto,
	ILicenseTypeDto
} from './licensure-shared-dto';

export class LicenseStatusChangeApplicationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ILicenseStatusChangeApplicationDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	LicenseInfo: ILicenseInfoDto;
	LicenseTypeId: string;
	LicenseType: ILicenseTypeDto;
	LicenseNumber?: number;
	Reviewer: string;
	ReviewDate?: Date;
	LicenseStatusCodeFrom: string;
	LicenseStatusId: string;
	LicenseStatusFrom: ILicenseStatusDto;
	LicenseStatus: ILicenseStatusDto;
	GrantDate?: Date;
	EffectiveDate?: Date;
	IsPrinted: boolean;
	IsProcessed: boolean;
	IsStatusChanged: boolean;
	StatusId?: number;
	PersistedStatus: ILicenseStatusChangeApplicationStatusDto;
	Status: ILicenseStatusChangeApplicationStatusDto;
	LicenseStatusTransitionId?: number;
	TemporaryPermitRequestedWithApplication: boolean;
	LicenseStatusTransition: ILicenseStatusTransitionDto;
	IsFuture?: boolean;
	RetirementDate?: Date;
	TemporaryId?: any;
	LicenseId: number;
	StatusOrder: number;
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	IsCriminalBackgroundCheckRequired?: boolean;
	ReferenceValue: string;
	CriminalBackgroundChecks: IDomainCriminalBackgroundCheckDto[];
	RowVersion: number[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

	EntityId?: number;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
}
