import { Injectable, Inject } from '@angular/core';

import * as _l from 'lodash-es';
import * as _ from 'underscore';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { CommandService } from '@coreServices/command.service';

import { ReachMenuItem } from '@coreModels/reach-menu-item';
import { ReachMenuGroup } from '@coreModels/reach-menu-group';
import { Command, NavigationMenuCommand } from '@coreModels/command';

@Injectable({
  providedIn: 'root'
})
export class HelpMenuService {
  DYNAMIC_CONTENT_LOCATION_TYPES;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicContentManagerService: DynamicContentManagerService,
    private commandService: CommandService
  ) {
    this.DYNAMIC_CONTENT_LOCATION_TYPES = this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES;
  }

  public buildMenu(majorKey, minorKey) {

    let dynamicHelpEntries = this.getDynamicHelpEntries(this.dynamicContentManagerService.getEntries(), majorKey, minorKey);


    let groupedNavigationBarHelpItems = _l.groupBy(_l.sortBy(dynamicHelpEntries,
        (item) => {
          return item.DisplayOrder;
        }),
      (navBarHelpItem) => {
        return navBarHelpItem.GroupName;
      });

    let menuGroups = [];
    let helpMenu = [];

    _.map(_.keys(groupedNavigationBarHelpItems),
      (groupKey) => {
        var menuGroup = new ReachMenuGroup(groupKey);
        var headerItem = menuGroup.items[0];

        // For each item in each group, we add the menu items with commands
        _.map(groupedNavigationBarHelpItems[groupKey],
          (item) => {

            if (item.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.MODAL) {
              menuGroup.addMenuItem(this.dynamicContentManagerService.createModalContentCommand(item));
            } else if (item.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.EXTERNAL_URL) {
              menuGroup.addMenuItem(this.dynamicContentManagerService.createExternalContentCommand(item));
            } else {
              throw "Presentation type" + item.PresentationTypeId + " not supported.";
            }
          });

        headerItem.hasActiveChildren = () => {
          let firstActiveChild = _.find(menuGroup.items,
            (item: ReachMenuItem) => {
              return !item.isHeader && item.isEnabled;
            });

          return firstActiveChild ? true : false;
        }

        menuGroups.push(menuGroup);
      });

    helpMenu = _l.flatten(_.pluck(menuGroups, "items"));

    return helpMenu;
  }

  private getDynamicHelpEntries(dynamicContentEntries, majorKey, minorKey) {
    return _l.filter(dynamicContentEntries,
      (entry) => {
        return (entry.MajorKey === majorKey) &&
          entry.Content.length > 0 &&
          entry.LocationTypeId === this.DYNAMIC_CONTENT_LOCATION_TYPES.NAVBAR_MENU &&
          (minorKey == null || entry.MinorKey === minorKey);
      });
  }
}
