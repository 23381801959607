import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IExamDto,
IExamTypeDto } from './licensure-shared-dto';

export class ExamDto extends ValidatableDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IExamDto {
	ApplicationId: number;
	IsPassedConditional?: boolean;
	IsPassed?: boolean;
	AttemptNumber?: number;
	PassingScoreLabel: string;
	Score: string;
	State: string;
	Series: string;
	ExamTypeId?: number;
	ExamDate?: Date;
	Notes: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DerivedDescription: string;
	DerivedDescriptionAlt1: string;
	ParentApplicationExamId?: number;
	ExamReleased?: boolean;
	RegisterDate?: Date;
	EligibleDate?: Date;
	AuthorizeDate?: Date;
	AppointmentDate?: Date;
	ScoreHoldDate?: Date;
	ScoreReleaseDate?: Date;
	ScoreCancelDate?: Date;
	IsNotTaken?: boolean;
	CertificationNumber: string;
	CsaDate: string;
	NarmLevelId?: number;
	NataLevelId?: number;
	CertificationIssueDate?: Date;
	CertificationExpireDate?: Date;
	NarmLevel: any;
	NataLevel: any;
	ExamType: IExamTypeDto;
	Sections: IExamDto[];
	AdditionalExamScores: IExamDto[];

}
