import { IDomainQuestionnaireDto,
IDomainReasonForChangeDtoHost,
IEntityCommentDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEmploymentDto,
ISupervisionVerificationDto,
ISupervisionVerificationFormDto,
ISupervisionVerificationHoursDto,
ISupervisorDto } from './licensure-shared-dto';

export class SupervisionVerificationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisionVerificationDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	EntityId?: number;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	StrongestQuestionnaire: IDomainQuestionnaireDto;
	DomainQuestionnaires: IDomainQuestionnaireDto[];
	IsHoursEntryPerWeekEnabled: boolean;
	LicenseId?: number;
	EstimatedNumberOfWeeks: number;
	EstimatedNumberOfMonths: number;
	ApplicationNbr?: number;
	ReferenceValue: string;
	ReceivedDate?: Date;
	SupervisorEntityId?: number;
	SupervisorId?: number;
	OtherSupervisorName: string;
	FormId?: number;
	StatusId?: number;
	StatusDate?: Date;
	BeginDate?: Date;
	EndDate?: Date;
	HoursPerWeek?: number;
	EmploymentId?: number;
	SupervisionPlanId?: number;
	SupervisorStatusId?: number;
	PracticeLocation: string;
	NarrativeOfPopulation: string;
	NarrativeOfModalities: string;
	NarrativeOfProcess: string;
	IsNarrativeOnFile?: boolean;
	DirectClinicalPercent?: number;
	ApprovedPracticeHours?: number;
	PracticeHours?: number;
	DirectClinicalPracticeHours?: number;
	Form: ISupervisionVerificationFormDto;
	Status: any;
	SupervisorStatus: any;
	Employment: IEmploymentDto;
	Supervisor: ISupervisorDto;
	SupervisionVerificationHours: ISupervisionVerificationHoursDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
