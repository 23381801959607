import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { UserDto, UserProfileInfoDto, WebLinkInfoDto } from '@coreShared/core-shared.module';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService extends ReachHttpClientService {
  private currentUserSubject: BehaviorSubject<UserDto>;
  public currentUser: Observable<UserDto>;

  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.currentUserSubject = new BehaviorSubject<UserDto>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getAssociatedWebLinks(domainId: number, domainKeyId: number): Observable<WebLinkInfoDto[]> {
    return this.get<WebLinkInfoDto[]>(`${this.apiRootUri}/userprofileinfo/AssociatedLinks/${domainId}/${domainKeyId}`);
  }

  public getById(id: number, token: string): Observable<UserProfileInfoDto> {
    if (token) {
      return this.get<UserProfileInfoDto>(`${this.apiRootUri}/userprofileinfo/${id}?token=${encodeURIComponent(token)}`);
    } else {
      return this.get<UserProfileInfoDto>(`${this.apiRootUri}/userprofileinfo/${id}`);
    }
  }
}
