<div class="p-fluid" [formGroup]="stepForm">

    <div class="p-formgrid p-grid">

        <!-- Preceptor Registration # -->
        <div class="p-field p-col-10 p-lg-4 p-xl-3">
            <label for="PreceptorLicenseNumber">Preceptor Registration #</label>
            <input id="PreceptorLicenseNumber" type="number" formControlName="PreceptorLicenseNumber" pInputText>
        </div>

        <!-- Validate Button -->
        <div class="p-col-2 p-mt-4 p-pt-1">
            <button pButton pRipple type="button" label="Validate" [command-button]="okCommand" class="p-button-outlined" style="width: auto;" command-key-binding="Enter"></button>
        </div>

    </div>

    <!-- Readonly Information -->
    <div class="p-grid p-formgrid" *ngIf="preceptorVerificationResponse">

        <!-- Preceptor Name -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label>Preceptor Name</label><br />
            <span>{{preceptorVerificationResponse.PreceptorName}}</span>
        </div>

        <!-- Preceptorship Period -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label>Preceptorship Period</label><br />
            <span>{{preceptorVerificationResponse.PreceptorPeriod}}</span>
        </div>

    </div>

</div>