// style notes: (https://github.com/johnpapa/angular-styleguide#style-y030)

import { Component, Inject, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as _l from 'lodash-es';

import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DialogSettings } from '@coreModels/dialog-settings';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { DynamicContentModalComponent, DynamicContentModalComponentKey } from '../dynamicContentModal/dynamic-content-modal.component';

import { DynamicContentDto } from '@coreShared/core-shared.module';
import { Command } from '../../../models/command';

/**
 * Renders DynamicContent-based wizard help for the specified major/minor keys.
 * */
@Component({
  selector: 'wizard-help',
  templateUrl: './wizard-help.component.html',
  styleUrls: ['./wizard-help.component.scss']
})
export class WizardHelpComponent implements OnInit, OnChanges {
  private _entry: DynamicContentDto;
  @Input()
  majorKey: string;
  @Input()
  minorKey: string;
  dialogSettings: DialogSettings = null;
  wizardPageEntries = [];
  wizardEntries = [];
  EXTERNAL_URL: number = null;
  MODAL: number = null;
  selectedHelp;
  showHelp: boolean = false;

  constructor(
    private commandService: CommandService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicContentManagerService: DynamicContentManagerService
  ) {
    this.EXTERNAL_URL = this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.EXTERNAL_URL;
    this.MODAL = this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.MODAL;
  }

  ngOnInit() {

  }

  ngOnChanges() {

    this.wizardPageEntries = _l.sortBy(this.getWizardPageEntries(), (item) => {
      return item.DisplayOrder;
    });

    this.wizardEntries = _l.sortBy(this.getWizardEntries(), (item) => {
      return item.DisplayOrder;
    });
  }

  public toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  public get allEntries() {
    let results = [];
    this.wizardEntries.forEach(e => {
      results.push({
        presentationTypeId: e.PresentationTypeId,
        content: e.Content,
        command: this.presentModalContentCommand,
        commandParam: e,
        description: e.Description,
        entryType: 'wizardEntry',
        displayOrder: e.DisplayOrder
      });
    });

    this.wizardPageEntries.forEach(e => {
      results.push({
        presentationTypeId: e.PresentationTypeId,
        content: e.Content,
        command: this.presentModalContentCommand,
        commandParam: e,
        description: e.Description,
        entryType: 'wizardPageEntry',
        displayOrder: e.DisplayOrder
      });
    });

    return results;
  }

  // Commands
  public presentModalContentCommand = this.commandService.create(() => {
      return true;
    },
    (item) => {
       // Define editor dialog properties.
       let dialogTitle = item.Description;
       let dialogModel = item;

       // Create dialog settings.
       // Dialog settings represent a new modal dialog instance.
       this.dialogSettings = new DialogSettings(
         null, // Component instance
         ReachScenarios.Default, // Scenario key
         DynamicContentModalComponent, // Content type
         DynamicContentModalComponentKey, // Content key
         dialogTitle, // Title
         dialogModel, // Model
         null, // OK command does not close dialog
         true); // Use model reference

        // Present the editor dialog.
        this.dialogSettings.isOpen = true;

        this.dialogSettings.isOpen = true;
    });

  public getWizardPageEntries() {
    let wizardPageHelpMinorKey = null;
    if (this.minorKey) {
      let wizardHelpMinorKeyParts = this.minorKey.split(".");
      // replace last part with WizardPageHelp
      wizardHelpMinorKeyParts[wizardHelpMinorKeyParts.length] = "WizardPageHelp";
      wizardPageHelpMinorKey = wizardHelpMinorKeyParts.join(".");
    }

    return _l.filter(this.dynamicContentManagerService.getEntries(),
      (entry) => {
        return entry.MajorKey === this.majorKey && entry.MinorKey === wizardPageHelpMinorKey && entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.WIZARDPAGE_MENU;
      });
  }

  public isModal(entry) {
    return entry.presentationTypeId == this.MODAL;
  }

  public isExternalUrl(entry) {
    return entry.presentationTypeId == this.EXTERNAL_URL;
  }

  /**
   * Finds the DynamicContentDto matching this instance's major/minor key.
   * */
  private getWizardEntries() {
    return _l.filter(this.dynamicContentManagerService.getEntries(),
      (entry: DynamicContentDto) => {
        let found = entry.MajorKey === this.majorKey &&
          entry.MinorKey === this.minorKey &&
          entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.WIZARD_MENU;
        if (found
          && entry.PresentationTypeId !== this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.EXTERNAL_URL
          && entry.PresentationTypeId !== this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.MODAL) {
          //$log.info("Incorrect Presentation Type for Wizard Help Entry: [" + entry.Id + "]");
          console.log(`Incorrect Presentation Type for Wizard Help Entry: [ ${entry.Id} ]`);
          found = false;
        }
        return found;
      });
  }
}

