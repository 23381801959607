// Framework
import { Component, OnInit, OnChanges, Input, Inject, forwardRef, AfterViewInit } from '@angular/core';
import { forkJoin, from, of } from "rxjs";
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, ValidationErrors } from '@angular/forms';

// Core
import { ListService } from '../../services/list.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '../../services/constants-provider.service';
import { BusyManagerService } from '../../services/busy-manager.service';
import { UtilitiesService } from '@coreServices/utilities.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

// Core shared
import { IAnswerDto, IDomainQuestionnaireDto } from 'src/app/coreShared/core-shared.module';
import { debounceTime } from 'rxjs/operators';

/**
 * Represents a Domain Questionnaire.
 * Expects to be imbedded onto other components.
 */
@Component({
  selector: "domain-questionnaire",
  templateUrl: './domain-questionnaire.component.html',
  styleUrls: ['./domain-questionnaire.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainQuestionnaireComponent),
      multi: true
    },
  ]
})
export class DomainQuestionnaireComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  parent: FormGroup;

  // Input
  @Input()
  model: IDomainQuestionnaireDto;
  @Input()
  filterFunction: any = () => { return true; };
  @Input()
  validationFunction: any = () => { return null; };

  // Fields
  possibleAnswers: IAnswerDto[] = [];
  friendlyNames: any = {}; // Contains friendly names for this component's forms.

  // Forms
  public contentForm: FormGroup = new FormGroup({
    Questions: this.formBuilder.array([], Validators.required)
  });


  // CTOR.
  constructor(
    private listService: ListService,
    private busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private controlContainer: ControlContainer,
    private utilitiesService: UtilitiesService,
    protected validationManagerService: ValidationManagerService,
    private formBuilder: FormBuilder) {

    this.initializeEventHandlers();
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // When anything in this contentForm changes, update the model.
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.validate(this.contentForm);
    });
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    // Include this component on the parent component's form group for validating.
    this.parent = this.controlContainer.control as FormGroup;
    this.parent.addControl('questionaireContentForm', this.contentForm);

    // Load lookup data.
    this.loadLookupLists();
  }

  /**
  * AfterViewInit Ng lifecycle event handler.
  */
  ngAfterViewInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  *
  * SimpleChanges: A hashtable of changes represented by SimpleChange objects stored at the declared property name they belong to on a Directive or Component.
  * This is the type passed to the ngOnChanges hook.
  */
  ngOnChanges(): void {
    // We must have a model.
    if (!this.model) return;

    this.model.IsDirty = true;

    // To support HTML elements generated using ngFor, we use the FormArray.
    let formArray: FormArray = this.contentForm.get('Questions') as FormArray;
    if (formArray) {
      // First, add new items to the FormArray
      this.model.Answers.forEach(i => {
        // Check if the item already exists in the FormArray
        if (!formArray.controls.some(control => control.value === i)) {
          // If the item does not exist, push it into the FormArray
          formArray.push(new FormControl(i));
        }
      });

      // Then, remove items from the FormArray that are not in model.Answers
      formArray.controls.forEach((control, index) => {
        if (!this.model.Answers.includes(control.value)) {
          formArray.removeAt(index);
        }
      });
    }
  }

  /**
   * Loads required lookup data.
   */
  protected loadLookupLists(): void {
    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Filter lookup lists before returning.
      const responseCollection = await forkJoin([this.listService.getAnswers()]).toPromise();
      this.possibleAnswers = this.listService.filterInactiveItems(responseCollection[0]) as IAnswerDto[];

      // Populate dropdown FormControl elements.
      this.initDropdowns();

      // Exit.
      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  dataLoaded: boolean = false;
  protected initDropdowns(): void {

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

  /**
  * Aggregates errors and passes them to the parent.
  * @param c The parent form control.
  */
  validate(c: AbstractControl): ValidationErrors | null {

    // Run the custom validation function provided by the host.
    this.contentForm.setErrors(this.validationFunction(this.model));

    // Combine errors from this component...
    let questionForm = this.contentForm.get('Questions') as FormArray;
    let errors = this.utilitiesService.aggregateCvaError(questionForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.contentForm.valid ? null : errors;
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }

}
