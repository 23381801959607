import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IFileUploadDtoHost } from 'src/app/coreShared/core-shared.module';

@Component({
  selector: 'landing-page-file-upload-editor',
  templateUrl: './landing-page-file-upload-editor.component.html',
  styleUrls: ['./landing-page-file-upload-editor.component.scss']
})
export class LandingPageFileUploadEditorComponent extends ReachDialogContentComponent<IFileUploadDtoHost> implements OnInit {

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }
}

registerDynamicComponent(ReachScenarios.Default, 'LandingPageFileUploadEditorComponent', LandingPageFileUploadEditorComponent, 'landing-page-file-upload-editor');
