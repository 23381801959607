
import { OnlineServiceRegistry, OnlineServiceRegistryItem } from '@coreModels/online-service-registry';
import { IWebLinkInfoDto } from '@coreShared/core-shared.module';

export class OnlineServiceLink {
  public onlineServiceRegistryInfo: OnlineServiceRegistryItem;
  public canExecute: () => boolean;

  public get onlineServiceTypeKey() { return this.webLinkInfo.ProcessTypeId.toString(); }
  public get displayText() { return this.webLinkInfo.DisplayName; }

  public constructor(public webLinkInfo: IWebLinkInfoDto
    , public onlineServiceRoute: any[]
    , public selectorRoute: any[]
    , public isProfileLink: boolean = false) {

    this.onlineServiceRegistryInfo = OnlineServiceRegistry.getItemByOnlineServiceTypeId(webLinkInfo.ProcessTypeId);
    if (!this.onlineServiceRegistryInfo) {
      throw `No online service/wizard route info has been registered for service key ${webLinkInfo.ProcessTypeId}`;
    }
  }

  public defaultSelectorFn = (): boolean => {
    return (this.webLinkInfo.ProcessId && this.webLinkInfo.ServiceId ? false : true);
  };

  public targetRoute(selectorStateFn = null): any[] {
    const isSelector = selectorStateFn ? selectorStateFn() : this.defaultSelectorFn();
    return isSelector ? this.selectorRoute : this.onlineServiceRoute;
  }
}
