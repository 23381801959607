<div [formGroup]="stepForm">

    <!-- RadioContentItems -->
    <radio-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey" [defaultMinorKey]="defaultDynamicContentMinorKey" [bag]="wizard.selectedStep.bag"></radio-content-items>

    <!-- File Upload -->
    <checklist-file-upload-items #checklistFileUploadItems [uploadableChecklistItemIds]="templateIds" [domainChecklistDtoHost]="wizard.model.DomainChecklistDtoHost"></checklist-file-upload-items>

</div>

