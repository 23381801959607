import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEmploymentDto,
IEntityEmploymentListDto } from './licensure-shared-dto';

export class EntityEmploymentListDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityEmploymentListDto {
	EntityId?: number;
	EmploymentList: IEmploymentDto[];

}
