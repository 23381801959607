import { Component, Inject, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "@coreServices/constants-provider.service";
import { ReachMessage } from '@coreModels/reach-message';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { MessageSeverities } from '@coreModels/message-severities';

@Component({
  selector: 'message-summary',
  templateUrl: './message-summary.component.html',
  styleUrls: ['./message-summary.component.scss']
})
export class MessageSummaryComponent implements OnInit, OnChanges {
  private subscriptionMessageChanged: Subscription;
  @Input() messageScope: string;
  @Input() labelText: string;
  @Input() severity: MessageSeverities;
  public messageList: ReachMessage[] = [];
  public itemTooltipContent = 'Resubmit after correcting this issue to clear this message';
  public itemTooltipDescription = 'Resubmit after correcting this issue to clear this message';
  public serverValidationErrorType;
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private reachMessageService: ReachMessageService
  ) {
    this.serverValidationErrorType = this.constantsService.VALIDATION_ERROR_TYPES.SERVER;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.messageScope) {
      this.subscriptionMessageChanged = this.reachMessageService.get$(this.messageScope)
        .subscribe((arg: ReachMessage[]) => this.onMessageUpdates(arg));
    }
  }

  public get isVisible(): boolean {
    let visible = false;
    if (this.messageScope) {
      visible = this.reachMessageService.getToggleState(this.messageScope);
    }

    visible = visible && this.messageList && this.messageList.length > 0;
    return visible;
  }

  onMessageUpdates(messages: ReachMessage[]) {
    this.messageList = messages;
  }

  onMessageClicked($event, msg: ReachMessage) {
    this.reachMessageService.raiseMessageClicked(msg, this.messageScope);
  }
}
