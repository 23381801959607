import { IProcessFeeDto,
IWebServiceDto,
IWebServiceViewDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebServiceDto extends BaseDto implements IWebServiceDto {
	Name: string;
	Title: string;
	Description: string;
	DisplayOrder: number;
	IsLoginRequired: boolean;
	IsHistoryTracked: boolean;
	IsCancellationAllowed: boolean;
	IsActive: boolean;
	TotalFees: number;
	ProcessId?: number;
	MajorKey: string;
	LinkCaption: string;
	ContinueLinkCaption: string;
	FeesWaivedForActiveMilitary: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
	Steps: IWebServiceViewDto[];
	Fees: IProcessFeeDto[];

}
