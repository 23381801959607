import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachModelAwareComponent } from 'src/app/core/index-directives';
import { Command, DialogSettings } from 'src/app/core/index-models';
import { CommandService, ConstantsService, CONSTANTS_SERVICE_TOKEN, DialogSettingsService, MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, SystemSettingsManagerService, UtilitiesService } from 'src/app/core/index-services';
import { BagItemDto, IBagItemDto } from 'src/app/coreShared/core-shared.module';
import { ISupervisionFeatureConstantsService, SupervisionService } from 'src/app/licensureCore/licensure-core.module';
import { ISupervisionPlanDto, ISupervisionPlanSupervisorDto, ISupervisionVerificationDto, ISupervisorSearchResultItemDto, LicenseInfoDto, LicenseTypeDto, LicensureEntityInfoDto, SupervisionPlanSupervisorDto } from 'src/app/licensureShared/licensure-shared.module';
import { SupervisorLookupModel } from '../../models/supervisor-lookup-model';
import { SupervisorLookupComponent } from '../supervisorLookup/supervisor-lookup.component';

export const SupervisorListComponentSelector: string = "supervisor-list";
export const SupervisorListComponenttKey: string = "supervisorList";

@Component({
  selector: SupervisorListComponentSelector,
  templateUrl: './supervisor-list.component.html',
  styleUrls: ['./supervisor-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupervisorListComponent),
      multi: true
    }
  ]
})
export class SupervisorListComponent extends ReachModelAwareComponent implements OnInit, OnChanges {

  @Input() public SupervisionPlan: ISupervisionPlanDto;
  @Input() public SupervisionVerification: ISupervisionVerificationDto;
  public get filteredSupervisionPlanSupervisors(): ISupervisionPlanSupervisorDto[] { return this.SupervisionPlan.Supervisors.filter(i => !i.IsDeleted) }


  @Output()
  bagItemsChanged: EventEmitter<IBagItemDto> = new EventEmitter<IBagItemDto>();

  public contentForm: FormGroup;
  public supervisorSearchDialogSettings: DialogSettings;
  public presentSearchCommand: Command;
  public removeItemCommand: Command;

  constructor(private controlContainer: ControlContainer
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
    , protected commandService: CommandService
    , protected confirmationService: ConfirmationService
    , protected supervisionService: SupervisionService
    , protected dialogSettingsService: DialogSettingsService
    , protected utilitiesService: UtilitiesService
    , protected systemSettingsManagerService: SystemSettingsManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , protected formBuilder: FormBuilder) {
    super(reachModelConfigurationInjector);
    this.contentForm = this.formBuilder.group({ Supervisor: [this.SupervisionVerification?.Supervisor?.EntityInfo?.FullName, [Validators.required]] })
  }

  ngOnInit(): void {
    this.initCommands();

    let parent = this.controlContainer.control as FormGroup;
    parent.addControl('SupervisionList', this.contentForm); // Include this component on the parent component's form group for validating.
  }

  ngOnChanges(): void {
    this.utilitiesService.enableDisable(this.contentForm, (this.SupervisionVerification != null));
    this.updateBagItems();
  }

  private initCommands(): void {
    this.presentSearchCommand = this.commandService.create(this.canSearchCommandExecute, this.searchCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected updateBagItems() {
    const bagItem = new BagItemDto();
    if (this.SupervisionPlan != null) {
      bagItem.Key = "supervisors";
      bagItem.BagItemType = this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT;
      bagItem.Values = [];
      this.filteredSupervisionPlanSupervisors.forEach((item: ISupervisionPlanSupervisorDto) => {
        bagItem.Values.push(`${item.SupervisorEntityInfo.FullName} | ${(<LicensureEntityInfoDto>item.SupervisorEntityInfo)?.StrongLicense?.LicenseType?.ProfessionalSuffix || 'N/A'} | ${(<LicensureEntityInfoDto>item.SupervisorEntityInfo)?.StrongLicense?.LicenseNumber || 'N/A'}`);
      });
    }
    else if (this.SupervisionVerification != null) {

    }
    this.bagItemsChanged.emit(bagItem);
  }

  protected canSearchCommandExecute = (): boolean => {
    return true;
  }

  protected searchCommandExecute = () => {
    let lookupModel = new SupervisorLookupModel();
    this.presentSupervisorSearch(lookupModel);
  }

  public presentSupervisorSearch(supervisorLookupModel: SupervisorLookupModel) {
    this.supervisorSearchDialogSettings = this.getSupervisorSearchDialogSettings(supervisorLookupModel);
    this.supervisorSearchDialogSettings.isOpen = true;
  }

  public getSupervisorSearchDialogSettings(supervisorLookupModel: SupervisorLookupModel): DialogSettings {
    let okCommand = this.commandService.create(() => {
      return true;
    }, (supervisorLookupModel: SupervisorLookupModel) => {
      if (supervisorLookupModel.supervisorSearchResults != null && supervisorLookupModel.supervisorSearchResults.length > 0) {
        const result = supervisorLookupModel.supervisorSearchResults[0];
        this.update(result);
      }
    });

    const ds = new DialogSettings(null
      , ReachScenarios.Default
      , SupervisorLookupComponent
      , SupervisorLookupComponent.name
      , "Supervisor Search"
      , supervisorLookupModel
      , false
      , false
      , true);

    ds.okCommand = okCommand;
    return ds;
  }

  protected update(supervisorSearchResultItem: ISupervisorSearchResultItemDto): void {
    if (this.SupervisionPlan != null) {
      let existingItem = this.SupervisionPlan.Supervisors.find((item: SupervisionPlanSupervisorDto) => {
        return item.SupervisorId == supervisorSearchResultItem.Id;
      });

      if (existingItem != null) {
        existingItem.IsDeleted = false;
      }
      else if (existingItem == null) {
        this.SupervisionPlan.Supervisors.push(this.initializeSupervisionPlanSupervisor(supervisorSearchResultItem));
      }
    }
    else if (this.SupervisionVerification != null) {

    }

    this.updateBagItems();
  }

  protected initializeSupervisionPlanSupervisor(supervisorSearchResultItem: ISupervisorSearchResultItemDto): ISupervisionPlanSupervisorDto {
    // Create plan supervisor
    const supervisionPlanSupervisorDto = new SupervisionPlanSupervisorDto();
    supervisionPlanSupervisorDto.IsDeleted = false;
    supervisionPlanSupervisorDto.SupervisorId = supervisorSearchResultItem.Id;
    supervisionPlanSupervisorDto.StatusId = (this.constantsService as ISupervisionFeatureConstantsService).FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_SUPERVISOR_STATUS_CONSTANTS.NEW;
    supervisionPlanSupervisorDto.SupervisionPlanId = this.SupervisionPlan.Id;

    // Create entity info
    const entityInfo = new LicensureEntityInfoDto();
    entityInfo.FullName = supervisorSearchResultItem.FullName;
    entityInfo.ProfessionalSuffix = supervisorSearchResultItem.ProfessionalSuffix;

    // Create license
    const strongLicense = new LicenseInfoDto();
    strongLicense.LicenseType = new LicenseTypeDto();
    strongLicense.LicenseType.ProfessionalSuffix = supervisorSearchResultItem.ProfessionalSuffix
    strongLicense.LicenseNumber = supervisorSearchResultItem.LicenseNumber;
    strongLicense.LicenseType.Code = supervisorSearchResultItem.LicenseTypeCode;
    entityInfo.StrongLicense = strongLicense;
    supervisionPlanSupervisorDto.SupervisorEntityInfo = entityInfo;

    return supervisionPlanSupervisorDto;
  }

  protected canRemoveItemCommandExecute = (item: SupervisionPlanSupervisorDto): boolean => {
    return this.SupervisionPlan != null && this.SupervisionPlan.StatusId === (this.constantsService as ISupervisionFeatureConstantsService).FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_STATUS.STARTED;
  }

  protected removeItemCommandExecute = (item: SupervisionPlanSupervisorDto) => {
    this.confirmationService.confirm({
      message: `Are you sure you want to remove ${item.SupervisorEntityInfo.FullName} from your supervision plan?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      accept: () => {
        item.IsDeleted = true;
      }
    });
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    // Unused
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }
}
