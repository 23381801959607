import { ICaptchaCredentialDto } from './core-shared-dto';
import { CredentialDto } from './credential-dto';
import { CredentialType } from './credential-type';

export class CaptchaCredentialDto extends CredentialDto implements ICaptchaCredentialDto {
	Response: string;


	public constructor(captchaResponse: string) { super(CredentialType.Captcha); this.Response = captchaResponse; }

}
