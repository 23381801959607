import { Injectable, Inject, Type } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';

/**
 * Wraps a generic key-value Map with Observable behavior to allow subscribers to be notified of changes.
 */
export class ObservableMap<TValue> {
  private itemMap: Map<string, TValue> = new Map<string, TValue>();
  private subjectMap$: Map<string, Subject<TValue>> = new Map<string, Subject<TValue>>();
  private observableMap$: Map<string, Observable<TValue>> = new Map<string, Observable<TValue>>();

  /**
   * Function that returns a default instance for the value. Returns null by default;
   * @returns {} 
   */
  private defaultT: () => TValue;

  /**
   * Initializes a new instance of the ObservableMap class.
   * @param defaultTFunction an optional function to use when creating default TDefault item instances. If omitted, items default to null.
   */
  constructor(defaultTFunction: () => TValue = null) {
      if (defaultTFunction) {
        this.defaultT = defaultTFunction;
      } else {
          this.defaultT = (): TValue => {
            return null as TValue;
          }
      }
  }

  /**
   * Gets the item of Type TValue associated with the specified Key.
   * @param key the Key.
   * @returns the item of Type TValue.
   */
  public get(key: string): TValue {
      return this.itemMap.get(key);
  }

  /**
   * Gets the Observable associated with the specified key and Type. Subcribers to this Observable will
   * be notified whenever the Value changes, with each event passing the current value.
   * @param key the Key.
   * @returns an Observable<TValue>.
   */
  public get$(key: string): Observable<TValue> {
    return this.getObservableForKey(key);
  }

  /**
   * Stores the specified Key-Value pair.
   * @param key the Key associated with the Value.
   * @param value the Value associated with the Key.
   */
  public set(key: string, value: TValue): void {
      this.itemMap.set(key, value);
      this.raiseUpdate(key, value);
  }

  /**
   * Merges the values for the specified Key-Value pair. If TValue is an array, the new values are merged with the existing values for the Key.
   * If TValue is not an array, the new value replaces the existing value for the Key (equivalent to the 'set' method).
   * @param key the Key associated with the Value.
   * @param value the Value associated with the Key.
   */
  public merge(key: string, value: TValue): void {
    let existing = this.itemMap.get(key);
    if (existing && value instanceof Array) {
      (value as any).forEach(i => (existing as any).push(i));
      this.set(key, existing);
    } else {
        this.set(key, value);
    }
  }

  /**
   * Clears the item for the specified key (sets it to default but does not remove the key).
   * @param key
   */
  public clear(key: string) {
    this.set(key, this.defaultT());
  }

  /**
   * Gets the Observable associated with the specified key and Type.
   * @param key
   */
  private getObservableForKey(key: string): Observable<TValue> {
      let observableForKey = this.observableMap$.get(key);
      if (!observableForKey) {
          let subjectForKey = this.getSubjectForKey$(key);
          observableForKey = subjectForKey.asObservable();
          this.observableMap$.set(key, observableForKey);
      }

      return observableForKey;
  }

  /**
   * Gets the Subject associated with the specified key and Type.
   * @param key the key.
   */
  private getSubjectForKey$(key: string): Subject<TValue> {
    let subjectForKey = this.subjectMap$.get(key);
    if (!subjectForKey) {
        subjectForKey = new Subject<TValue>();
        this.subjectMap$.set(key, subjectForKey);
    }

    return this.subjectMap$.get(key);
  }

  /**
   * Raises the update event for the specified key with the specified value.
   * @param key the key for the updated item.
   * @param value the new value of the updated item.
   */
  private raiseUpdate(key: string, value: TValue) {
    let subject = this.getSubjectForKey$(key);
    subject.next(value);
  }
}