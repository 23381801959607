import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { ICeSponsorshipScheduledCourseSearchCriteriaDto } from './licensure-shared-dto';

export class CeSponsorshipScheduledCourseSearchCriteriaDto extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, ICeSponsorshipScheduledCourseSearchCriteriaDto {
	SponsorshipId?: number;
	SponsorId?: number;
	StatusId?: number;
	SponsorName: string;
	CourseName: string;
	CeSponsorshipTypeId?: number;
	SponsorshipYear?: number;
	StartDate?: Date;
	EndDate?: Date;
	City: string;
	CreditTypeId?: number;
	StudyTypeId?: number;
	ReferenceValue?: number;

}
