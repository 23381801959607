import { IDomainFormSectionDto,
IDomainFormSectionQuestionDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainFormSectionDto extends LookupDto implements IDomainFormSectionDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	SectionQuestions: IDomainFormSectionQuestionDto[];

}
