<!-- Grid -->
<div class="p-fluid" [formGroup]="stepForm">

  <div class="p-grid p-formgrid">

    <!-- Planned Start Date -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="PlannedBeginDate">Planned Start Date</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="PlannedBeginDate" formControlName="PlannedBeginDate" [showIcon]="false" yearNavigator="true"
        monthNavigator="true" [yearRange]="startDateYearRange" [defaultDate]="" appendTo="body">
      </p-calendar>
    </div>

    <!-- PlannedFirstGradDate -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="plannedFirstGradDate">Planned Graduation Date</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="plannedFirstGradDate" formControlName="PlannedFirstGradDate" [showIcon]="false"
        yearNavigator="true" monthNavigator="true" [yearRange]="startDateYearRange" appendTo="body">
      </p-calendar>
    </div>

  </div>

  <div class="p-grid p-formgrid">

    <!-- Number of Students to be Admitted -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="AdmittedStudentNbr">Number of students to be admitted</label>
      <input id="AdmittedStudentNbr" type="number" formControlName="AdmittedStudentNbr" pInputText>
    </div>

  </div>

  <div class="p-grid p-formgrid">

    <!--  Number of Times Students would be Admitted each year -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="TimesStudentAdmittedNbr">Number of Times Students would be Admitted each year</label>
      <input id="TimesStudentAdmittedNbr" type="number" formControlName="TimesStudentAdmittedNbr" pInputText>
    </div>

  </div>

  <div class="p-grid p-formgrid">

    <!--  MaximumStudentEnrolledNbr -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="MaximumStudentEnrolledNbr">Maximum number of students who could be enrolled at any one time</label>
      <input id="MaximumStudentEnrolledNbr" type="number" formControlName="MaximumStudentEnrolledNbr" pInputText>
    </div>

  </div>

  <div class="p-grid p-formgrid">

    <!--  ProgramTermLength -->
    <div class="p-field p-col-12 p-lg-6 p-xl-4">
      <label for="ProgramTermLength">Length of proposed program in terms</label>
      <input id="ProgramTermLength" type="number" formControlName="ProgramTermLength" pInputText>
    </div>

  </div>

</div>