<!-- Grid -->
<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- Dynamic Content -->
    <div class="p-col-12">
        <page-summary-text majorKey="Shared.Content" minorKey="SchoolLookupDialog.SummaryTextBlock"></page-summary-text>
    </div>

    <!-- Search Criteria -->
    <div class="p-grid p-formgrid">

        <!-- Country -->
        <div class="p-field p-col-12 p-sm-6">
            <label for="country">Country</label>
            <p-dropdown inputId="country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;" baseZIndex="1">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>

        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-sm-6">
            <label for="state">{{stateLabel}}</label>
            <p-dropdown inputId="state" formControlName="State" [options]="filteredStates" appendTo="body"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Buttons -->
        <div class="p-col-12 reach-bottom-actions-container">
            <div class="p-d-flex p-jc-end">

                <!-- Search -->
                <div class="p-pl-2 p-pt-2">
                    <button pButton pRipple label="Search" [command-button]="searchCommand"
                        [command-blocks-invalid]="true" command-key-binding="Enter" class="p-button-secondary"></button>
                </div>

            </div>
        </div>

    </div>

    <!-- Search Results -->
    <div class="p-grid p-formgrid">

        <!-- Results Table -->
        <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="searchResults" selectionMode="single"
            dataKey="FullName" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
            [responsive]="true" responsiveLayout="stack">

            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="position: sticky;" class="p-col-6">School</th>
                    <th style="position: sticky;" class="p-col-3">City</th>
                    <th style="position: sticky;" class="p-col-3">State</th>
                </tr>
            </ng-template>

            <!-- Body -->
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item">
                    <td>
                        <div class="p-d-flex">
                            <div class="p-column-title reach-col-fixed-100">School</div>
                            <div>{{item.FullName | whenEmpty: '-'}}</div>
                        </div>
                    </td>
                    <td><span class="p-column-title">City</span>{{item.City}}</td>
                    <td><span class="p-column-title">State</span>{{item.State}}</td>
                </tr>
            </ng-template>

            <!-- Message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="5">
                        <span class="reach-text-muted"><em>No items to display</em></span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!-- School not found -->
        <div class="p-field-checkbox p-col-12 p-ai-start p-mt-3">
            <p-checkbox inputId="binary" formControlName="SchoolNotFound" binary="true"></p-checkbox>
            <label class="p-pt-0" for="binary">My school was not found in the results. Allow me to enter my school
                information.<field-help majorKey="Shared.Content"
                    minorKey="SchoolLookupDialog.SchoolNotFound"></field-help>
            </label>
        </div>

    </div>

</div>
