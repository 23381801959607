import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ISupervisionHoursTypeDto,
ISupervisionVerificationDto,
ISupervisionVerificationHoursDto } from './licensure-shared-dto';

export class SupervisionVerificationHoursDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisionVerificationHoursDto {
	SupervisionVerificationId: number;
	EstimatedTotalHours?: number;
	EstimatedTotalHoursMessage: string;
	SupervisionHoursTypeId?: number;
	SupervisionVerification: ISupervisionVerificationDto;
	SupervisionHoursType: ISupervisionHoursTypeDto;
	Hours?: number;
	ApprovedHours?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
