import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentKeys } from '@coreConstants/landing-component-constants';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { Wizard } from '@coreModels/wizard';
import { CanCancelWebServiceResponseDto, IFunctionalItemKeysDto, StepDataDto, WebServiceDto, WebServiceHistoryDetailDto, WebServiceHistoryDto } from '@coreShared/core-shared.module';
import { ConfirmationService } from 'primeng/api';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import * as _ from "underscore";
import { WizardTags } from '../index-services';
import { ReachValidationError } from '../models/reach-validation-error';
import { INavigationActionEventArguments } from '../models/wizard-event-args';
import { ArgumentExceptionService } from './argument-exception.service';
import { BagService } from './bag.service';
import { BrowserDetectionService } from './browser-detection.service';
import { CartManagerService } from './cart-manager.service';
import { CommandService } from './command.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from "./constants-provider.service";
import { IncidentService } from './incident.service';
import { OnlineServiceHistoryService } from './online-service-history.service';
import { OnlineServiceService } from './online-service.service';
import { SystemSettingsManagerService } from './system-settings-manager.service';
import { UserManagerService } from './user-manager.service';
import { UtilitiesService } from './utilities.service';
import { ValidationManagerService } from './validation-manager.service';
import { WizardSearchCriteria } from './wizard-search-criteria.service';


@Injectable({
  providedIn: 'root'
})
export class WizardService {
  constructor(private activatedRoute: ActivatedRoute
    , private argumentExceptionService: ArgumentExceptionService
    , private bagService: BagService
    , private cartManagerService: CartManagerService
    , private commandService: CommandService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private location: Location
    , private onlineServiceHistoryService: OnlineServiceHistoryService
    , private onlineServiceService: OnlineServiceService
    , private router: Router
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private userManagerService: UserManagerService
    , private validationManagerService: ValidationManagerService
    , private confirmationService: ConfirmationService
    , private incidentService: IncidentService
    , private browserDetectionService: BrowserDetectionService
    , private utilitiesService: UtilitiesService
  ) {

  }

  public create(wizardSearchCriteria: WizardSearchCriteria
    , webServiceDto: WebServiceDto
    , webServiceHistoryDto: WebServiceHistoryDto
    , saveWizardStepsFunction: (wizard: Wizard, eventArguments: INavigationActionEventArguments) => Observable<WebServiceHistoryDto>) {

    if (!wizardSearchCriteria) {
      this.argumentExceptionService.create("wizardSearchCriteria").log();
    }

    if (!webServiceDto) {
      this.argumentExceptionService.create("webServiceDto").log();
    }

    return new Wizard(wizardSearchCriteria
      , webServiceDto
      , webServiceHistoryDto
      , this.bagService
      , this.cartManagerService
      , this.commandService
      , this.constantsService
      , this.router
      , this.systemSettingsManagerService
      , saveWizardStepsFunction
      , this.validationManagerService
      , this.confirmationService
      , this.userManagerService
      , this.incidentService
      , this.browserDetectionService
      , this.utilitiesService);
  }

  public startWizard(wizard: Wizard, functionType: IFunctionalItemKeysDto, licenseTypeId: string = null): Observable<Wizard> {
    const doStartWizard = async (): Promise<Wizard> => {

      // Must have a configuration
      if (!wizard) {
        this.argumentExceptionService.create("wizard").log();
      }

      // if this wizard logs service history we need to commit that record and associate it to the subject
      if (wizard.onlineService.IsHistoryTracked) {

        if (!functionType) {
          this.argumentExceptionService.create("functionType").log();
        }

        if (functionType) {
          wizard.onlineServiceHistory.FunctionTypeId = functionType.FunctionTypeId;
          wizard.onlineServiceHistory.FunctionNumber = functionType.FunctionNumber;
        }

        if (licenseTypeId) {
          wizard.onlineServiceHistory.ProfessionTypeCode = licenseTypeId;
        }

        let onlineServiceHistorySaveResponse =
          await this.onlineServiceHistoryService.save(wizard.onlineServiceHistory).toPromise();
        wizard.onlineServiceHistory = onlineServiceHistorySaveResponse;

        const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: { webServiceHistoryId: onlineServiceHistorySaveResponse.Id } }).toString();
        this.location.go(url);
      }

      return of(wizard).toPromise();
    }

    return from(doStartWizard());
  }

  public finishWizard(wizard: Wizard): Observable<Wizard> {
    if (!wizard) {
      this.argumentExceptionService.create("wizard").log();
    }

    return this.onlineServiceHistoryService.finish(wizard.onlineServiceHistory)
      .pipe(map(
        (response: WebServiceHistoryDto) => {
          wizard.onlineServiceHistory = response;
          return wizard;
        }));
  }

  public async cancelWizard(wizard: Wizard) {

    let prompt = `Are you sure you want to cancel this ${wizard.onlineService.Title} service?`;
    let webServiceHistoryId = wizard.onlineServiceHistory.Id;

    if (webServiceHistoryId > 0) {
      let canCancelResponse: CanCancelWebServiceResponseDto = await this.onlineServiceHistoryService.canCancel(webServiceHistoryId).toPromise();
      if (canCancelResponse.Result === true) {
        if (canCancelResponse.TransactionExistsConfirmationDoesNotExist === true) {
          prompt = "If you recently completed payment for this process. The system will remove it once processing is complete. Cancellation at this time may result in a delay processing your request. \n\nAre you sure you want to cancel this process?";
        }
      }
    }

    this.confirmationService.confirm({
      message: `${prompt}`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',
      accept: () => {
        if (webServiceHistoryId > 0) {
          this.onlineServiceHistoryService.cancel(webServiceHistoryId).toPromise().then((webServiceHistoryDto: WebServiceHistoryDto) => {
            if (webServiceHistoryDto.IsValid === true) {
              this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
            }
          })
        } else {
          this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
        }
      }
    });
  }


  /**
   * Retrive Application level validation errors and convert them to a string[].
   */
  private getValidationErrorMessages(): string[] {
    let validationErrors = this.validationManagerService.getApplicationValidationErrors(); // Collects both server and client errors.
    let flatErrors: ReachValidationError[] = _.flatten(validationErrors);
    let details: string[] = _.pluck(flatErrors, "detail");
    return details;
  }

  /**
   * Create and save StepData.
   */
  public logWizardHistory = (wizard: Wizard, eventArguments: INavigationActionEventArguments): Observable<WebServiceHistoryDto> => {

    // Create Step Data
    let errorEntries = this.getValidationErrorMessages();
    let stepData: StepDataDto = wizard.model.createStepData(eventArguments, wizard.onlineService.Fees, wizard.selectedStep, errorEntries);

    // Create Step Details
    let stepDetails = new WebServiceHistoryDetailDto();
    stepDetails.StepData = stepData;
    stepDetails.ServiceHistoryId = wizard.onlineServiceHistory.Id;
    stepDetails.StepOrder = wizard.selectedStep.StepOrder;

    if (errorEntries.length > 0 || wizard.isWizardOnLastStep()) {
      wizard.onlineServiceHistory.LastStepIndex = wizard.selectedIndex;
    }
    else {
      switch (stepData.Action) {
        case this.constantsService.WIZARD_ACTIONS.FORWARD:
          wizard.onlineServiceHistory.LastStepIndex = wizard.selectedIndex + 1;
          break;
        case this.constantsService.WIZARD_ACTIONS.BACKWARD:
          wizard.onlineServiceHistory.LastStepIndex = wizard.selectedIndex - 1;
          break;
      }
    }

    // Add to history collection
    wizard.onlineServiceHistory.StepDetails.push(stepDetails);

    const doSaveStepDetails = async (wizard: Wizard): Promise<WebServiceHistoryDto> => {
      let historyResponse: WebServiceHistoryDto =
        await this.onlineServiceHistoryService.save(wizard.onlineServiceHistory).toPromise();

      if (wizard.selectedStep.IsSaved) {
        historyResponse =
          await this.onlineServiceHistoryService.createConfirmationDocument(historyResponse.Id).toPromise();
      }

      wizard.onlineServiceHistory = historyResponse;
      return of(historyResponse).toPromise();
    }

    // Save step details
    return from(doSaveStepDetails(wizard));
  }

  public loadWebServiceHistory(onlineService: WebServiceDto, webServiceHistoryId: number, functionType: IFunctionalItemKeysDto = null): Observable<WebServiceHistoryDto> {

    if (!onlineService.IsHistoryTracked) {
      return of(null as WebServiceHistoryDto);
    }

    // If a history id is supplied in the wizard config we will load the existing history item
    if (webServiceHistoryId) {
      return this.onlineServiceHistoryService.getById(webServiceHistoryId);
    }
    // If a function type is supplied we will load the history item based upon the function type
    else if (functionType !== null && onlineService?.ProcessId > 0) {
      return this.onlineServiceHistoryService.getByFunctionalInformation(onlineService.ProcessId, functionType.FunctionTypeId, functionType.FunctionNumber)
        .pipe(switchMap((wsh: WebServiceHistoryDto) => {
          if (wsh) {
            return of(wsh);
          }
          return this.onlineServiceHistoryService.initialize();
        }));
    }
    else {
      return this.onlineServiceHistoryService.initialize();
    }
  }

  public getWizard(wizardSearchCriteria: WizardSearchCriteria, functionType: IFunctionalItemKeysDto = null): Observable<Wizard> {
    const doGetWizard = async (wizardSearchCriteria: WizardSearchCriteria): Promise<Wizard> => {

      // Must have a configuration
      if (!wizardSearchCriteria) {
        this.argumentExceptionService.create("wizardSearchCriteria").log();
      }

      // Build up query
      var query = `ProcessTypeId=${wizardSearchCriteria.processTypeId}&IsOnline=true`;

      // add any querystring params that may be required based upon the supplied tags
      if (wizardSearchCriteria.wizardTags) {
        query += this.convertTagsToQuery(wizardSearchCriteria.wizardTags);
      }

      // load entity and service history if applicable
      let webServiceHistory = null;
      let webServiceDto: WebServiceDto = await this.onlineServiceService.search(query).toPromise();
      if (webServiceDto) {
        webServiceHistory = await this.loadWebServiceHistory(webServiceDto, wizardSearchCriteria.webServiceHistoryId, functionType).toPromise();
        if (webServiceHistory) {
          // Tack on web service id
          webServiceHistory.ServiceId = webServiceDto.Id;

          // Tack on process id
          webServiceHistory.ProcessId = webServiceDto.ProcessId;

          // Tack on user account Id if a user exists
          var currentPrincipal = this.userManagerService.getCurrentPrincipal();
          if (currentPrincipal) {
            webServiceHistory.UserId = currentPrincipal.user.UserAccount.Id;
          }
        }
      }

      // Create the wizard object
      let wizard: Wizard = this.create(wizardSearchCriteria, webServiceDto, webServiceHistory, this.logWizardHistory);

      // If cancellation is supported add a handler
      if (wizard.onlineService.IsCancellationAllowed) {
        wizard.onWizardCanceled((eventArguments) => {
          return this.cancelWizard(wizard);
        });
      }

      // return the wizard
      return of(wizard).toPromise();
    }

    return from(doGetWizard(wizardSearchCriteria));
  }

  private appendQueryKeyAndValue(key, value) {
    return `&${key}=${value}`;
  }

  private convertTagsToQuery(wizardTags: WizardTags) {
    // Query to return
    var queryKeyValues = "";

    if (wizardTags.applicationTypeId) {
      // currently licenseTypeId and applicationTypeId are all treated as a 'LicenseType' on the server.
      // once we move to tags this will go away. For now it just looks odd.
      queryKeyValues += this.appendQueryKeyAndValue("LicenseTypeCode", wizardTags.applicationTypeId);
    }

    if (wizardTags.licenseTypeId) {
      queryKeyValues += this.appendQueryKeyAndValue("LicenseTypeCode", wizardTags.licenseTypeId);
    }

    if (wizardTags.licenseStatusId) {
      queryKeyValues += this.appendQueryKeyAndValue("StatusCode", wizardTags.licenseStatusId);
    }

    if (wizardTags.licenseStatusIdTo) {
      queryKeyValues += this.appendQueryKeyAndValue("StatusCodeTo", wizardTags.licenseStatusIdTo);
    }

    if (wizardTags.applicationBasisId) {
      queryKeyValues += this.appendQueryKeyAndValue("BasisCode", wizardTags.applicationBasisId);
    }

    if (wizardTags.formTypeId) {
      queryKeyValues += this.appendQueryKeyAndValue("FormTypeId", wizardTags.formTypeId);
    }

    if (wizardTags.hasSupervisionPlan != null) {
      queryKeyValues += this.appendQueryKeyAndValue("HasSupervisionPlan", wizardTags.hasSupervisionPlan);
    }

    if (wizardTags.isOpiate != null) {
      queryKeyValues += this.appendQueryKeyAndValue("IsOpiate", wizardTags.isOpiate);
    }

    if (wizardTags.isOutOfState != null) {
      queryKeyValues += this.appendQueryKeyAndValue("IsOutOfState", wizardTags.isOutOfState);
    }

    if (wizardTags.licenseSubtypeId != null) {
      queryKeyValues += this.appendQueryKeyAndValue("LicenseSubtypeId", wizardTags.licenseSubtypeId);
    }

    if (wizardTags.sponsorshipTypeId) {
      queryKeyValues += this.appendQueryKeyAndValue("CeSponsorshipTypeId", wizardTags.sponsorshipTypeId);
    }

    if (wizardTags.processId) {
      queryKeyValues += this.appendQueryKeyAndValue("ProcessId", wizardTags.processId);
    }

    if (wizardTags.supervisorDesignationFormId) {
      queryKeyValues += this.appendQueryKeyAndValue("SupervisorDesignationFormId", wizardTags.supervisorDesignationFormId);
    }

    return queryKeyValues;
  }
}
