<div class="p-grid p-jc-center" *ngIf="loaded" [formGroup]="contentForm">

    <!-- INVALID TOKEN -->
    <div *ngIf="!tokenValid"><span>This change password reset request is no longer valid. Please go through the forgot
            password wizard again generate a new password reset request.</span></div>

    <!-- VALID TOKEN -->
    <div class="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-3" *ngIf="tokenValid">

        <!-- Heading -->
        <div class="reach-page-header">Change your Password</div>

        <!-- Body -->
        <div class="p-fluid">

            <!-- Username -->
            <div class="p-field">
                <label for="username">Username</label>
                <span class="p-ml-2">{{onlineUser?.UserAccount?.UserName}}</span>
            </div>

            <!-- New Password -->
            <div class="p-field">
                <label for="newPassword">New Password</label>
                <div>
                    <input id="newPassword" pInputText type="password" formControlName="NewPassword" />
                    <field-help majorKey="Login" minorKey="Login.Password"></field-help>
                </div>
            </div>

            <!-- Confirm Password -->
            <div class="p-field">
                <label for="confirmPassword">Confirm Password</label>
                <div>
                    <input id="confirmPassword" pInputText type="password" formControlName="ConfirmPassword" />
                    <field-help majorKey="Login" minorKey="Login.Password"></field-help>
                </div>
            </div>

        </div>

        <!-- Buttons -->
        <div class="reach-bottom-actions-container">
            <div class="p-d-flex p-jc-end">

                <!-- Save -->
                <div class="p-pl-2">
                    <button pButton pRipple type="button" label="Save" class="p-button-primary"
                        [command-button]="saveCommand"></button>
                </div>

            </div>
        </div>

    </div>

</div>
