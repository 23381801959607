// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'underscore';
import * as _l from 'lodash-es';

import { HttpResponseContent, IValidatableDto, IBaseDto, IHttpResponseContent } from '@coreShared/core-shared.module';
import { CheckboxContent, CheckboxContentService } from './checkbox-content.service';
import { is } from 'date-fns/locale';

// type IBaseOrLocalObject = IBaseDto | ILocalIdObject;

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  responseCollectionContainsBadRequest(responseCollection) {
    return _.any(responseCollection,
      (response: IValidatableDto) => {
        return this.responseHasValidationErrors(response);
      });
  }

  groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  public responseHasValidationErrors<TContent extends IValidatableDto>(response: TContent) {
    return response && this.dtoHasValidationErrors(response);
  }

  /**
   * Returns true if the specified DTO has validation errors.
   * @param dto
   * The Dto to check.
   */
  public dtoHasValidationErrors(dto: IValidatableDto) {
    return dto != null && ((dto.Errors != null && dto.Errors.length) || (dto.AdditionalErrors != null && dto.AdditionalErrors.length));
  }

  /**
   * Returns true if the specified object implements the specified interface.
   * @param item
   */
  public objectIsInstanceOf<T>(item: Object): boolean {
    return (item as T) !== undefined;
  }

  /**
   * Inserts the specified item into the specified collection, or replaces a matching collection item if it exists. Matches on the LocalId or Id.
   * @param list
   * The list containing the item to be added.
   * @param item
   * The item to add/replace.
   */
  public addOrReplace<T extends IBaseDto>(list: T[], item: T): T[] {
    const idx = _.findIndex(list,
      (listItem: any) => {
        return this.hasLocalId(item) ? listItem.LocalId === item.LocalId : listItem.Id === item.Id;
      });

    if (idx >= 0) {
      list.splice(idx, 1, item);
    } else {
      list.push(item);
    }

    return list;
  }

  /**
   * Marks the list item that matches the specified item as deleted. . Matches on the LocalId or Id.
   * @param list
   * The list containing the item to be deleted.
   * @param item
   * The item to match off.
   */
  public markDeletedObject<T extends IBaseDto>(list: T[], item: T): T[] {
    const idx = _.findIndex(list,
      (listItem: any) => {
        return this.hasLocalId(item) ? listItem.LocalId === item.LocalId : listItem.Id === item.Id;
      });

    if (idx >= 0) {
      list[idx].IsDeleted = true;
    }

    return list;
  }

  /**
   * Removes the specified item for the specified list.
   * @param list
   * The list of objects of type T.
   * @param item
   * The item of type T to be removed from the list.
   * @returns the list with the target item removed.
   */
  public remove<T extends IBaseDto>(list: T[], item: T): T[] {
    return _.without(list, item);
  }

  /**
   * Decorates the specified object with a LocalId GUID property/value.
   * @param subject
   */
  public withLocalId(subject: any): any {
    subject.LocalId = this.guid();
    return subject;
  }

  /**
   * Returns true if the specified Object has a "LocalId" property.
   * @param subject
   */
  public hasLocalId(subject: any): boolean {
    return subject != null && subject.hasOwnProperty('LocalId');
  }

  /**
   * Substitutes the values in the specified args list into the specified format string.
   * @param format
   * The route format template string.
   * @arguments
   * The list of format parameters.
     * @returns the string with parameters substituted.
   */
  public stringFormat(format: string): string {
    const args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g,
      function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
  }

  /**
   * Substitutes the values in the specified args list into the specified format string.
   * @param format
   * The route format template string.
   * @param args
   * The list of format parameters.
   * @returns the string with parameters substituted.
   */
  public stringFormatArgs(format: string, ...args: any[]): string {
    // var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g,
      function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
  }

  /**
   * Substitutes the values in the specified args list into the specified route format string.
   * @param format
   * The route format template string.
   * @arguments
   * The list of format parameters.
   */
  public routeFormat(format, ...args): string {

    // First param is the format. Get all others.
    //const args = Array.prototype.slice.call(arguments, 1);

    const hasMultipleOptional = format.split('?').length > 1;

    // Track matches so we know where we are
    let matchCount = 0;

    return format.replace(/\/:(\w+\??)/g,
      (matchedValue) => {

        // if optional param and not defined skip it.
        if (!args[matchCount]) {
          if (matchedValue.indexOf('?') > -1) {
            matchCount++;
            return hasMultipleOptional ? '/' : '';
          } else {
            throw new Error('Parameter not found while parsing route. Make the parameter optional or supply a value.');
          }
        }

        return '/' + args[matchCount++];
      });
  }

  public addTrailingSegmentDelimiterToRoute(url: string): string {
    if (url.length > 0 && url.substr(-1) != '/') { url += '/'; }
    return url;
  }

  public buildChildOutletUrl(baseUrl: string, hostRoute: string, outletName: string) {
    baseUrl = this.addTrailingSegmentDelimiterToRoute(baseUrl);
    const targetUrl = this.stringFormatArgs('{0}({1})', baseUrl, this.buildOutletUri(hostRoute, outletName));
    return targetUrl;
  }

  public buildOutletUri(routerOutletName: string, componentSelector: string) {
    const uri = this.stringFormatArgs('{0}:{1}', routerOutletName, componentSelector);
    return uri;
  }

  public getRouteBuilder(primaryRoute: string): IRouteBuilder {
    return new RouteBuilder(primaryRoute);
  }

  /**
   * Returns a new GUID string.
   */
  public guid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }

  /**
   * Gets the class name from its type. Example: classNameFromType(Foo) returns 'Foo'.
   * @param c the class type.
   */
  public classNameFromType<T>(c: { new(): T }): string {
    let x = new c();
    return x.constructor.name;
  }

  /**
   * Legacy method for created Http response payloads.
   * @param content
   * The content object of type T.
   */
  asResponseContent<TContent>(content: TContent): IHttpResponseContent<TContent> {
    return new HttpResponseContent(content);
  }

  /**
   * Legacy method for created Http response payloads.
   * @param content
   * The content Object.
   */
  asResponseContentObject(content: Object): IHttpResponseContent<Object> {
    return this.asResponseContent<Object>(content);
  }

  /**
   * Returns an observable/subject that signals when a specified property on a class changes value. Can be used to watch
   * for changes on an input parameter, for example, in complex nested scenarios where the ngOnChanges event does not fire.
   * @Input()
   * public num: number;
   * numChanges$ = observeProperty(this as MyComponent, 'num');
   * @param target
   * @param key
   */
  observeProperty<T, K extends keyof T>(target: T, key: K) {
    const subject = new BehaviorSubject<T[K]>(target[key]);
    Object.defineProperty(target, key, {
      get(): T[K] { return subject.getValue(); },
      set(newValue: T[K]): void {
        if (newValue !== subject.getValue()) {
          subject.next(newValue);
        }
      }
    });
    return subject;
  }

  /**
   * Combine multiple errors from a CVA component to be passed along to the parent.
   * @param formGroup The fromgroup from the CVA component.
   */
  public aggregateCvaError(formGroup: FormGroup | FormArray, friendlyNames: any) {

    // To be sent the the parent.
    let errors = [];

    if (formGroup.errors) {

      // ... add any errors to the errors[] to be sent to the parent.
      Object.keys(formGroup.errors).forEach(keyError => {
        let nextRuleName = keyError;
        let nextRuleValue = formGroup.errors;

        // Is the value part of an array?
        if (!isNaN(Number(keyError))) {
          nextRuleValue = formGroup.errors[Number(keyError)];
          nextRuleName = Object.keys(nextRuleValue)[0];
        }
        else {
          nextRuleValue = nextRuleValue[keyError];
        }

        let error = { reachCvaError: { ruleName: nextRuleName, fieldName: 'FormGroup', error: nextRuleValue, friendlyName: null } };
        errors.push(error);
      });
    }

    // For each form control key in the form group...
    Object.keys(formGroup.controls).forEach(fieldName => {

      // Locate the related form control.
      let formControl = formGroup.controls[fieldName];

      // Recursive condition.
      if ((formControl as any).controls) {
        let innerErrors = this.aggregateCvaError(formControl, friendlyNames);
        innerErrors.forEach(item => errors.push(item));
      }

      // If the form control is not valid...
      else if (!formControl.valid && formControl.errors) {

        // ... add any errors to the errors[] to be sent to the parent.
        Object.keys(formControl.errors).forEach(keyError => {
          let nextRuleName = keyError;
          let nextRuleValue = formControl.errors;

          // Is the value part of an array?
          if (!isNaN(Number(keyError))) {
            nextRuleValue = formControl.errors[Number(keyError)];
            nextRuleName = Object.keys(nextRuleValue)[0];
          }
          else {
            nextRuleValue = nextRuleValue[keyError];
          }

          let friendlyName = friendlyNames ? friendlyNames[fieldName] : null;
          let error = { reachCvaError: { ruleName: nextRuleName, fieldName: fieldName, error: nextRuleValue, friendlyName: friendlyName } };
          errors.push(error);
        });
      }
    })

    return errors;
  }

  /**
  * Creates checkbox form controls based on provided checkbox contents and attaches them to the host form group.
  * 
  * @param {CheckboxContent[]} checkboxContents - The array of checkbox content items.
  * @param {FormGroup} hostFormGroup - The form group to which the checkbox form controls will be added.
  * @param {any} friendlyNames - An object containing friendly names for the form controls.
  * @param {CheckboxContentService} checkboxContentService - The service used to verify dynamic content.
  * @returns {any[]} An array containing objects representing the created checkbox form controls.
  */
  public createCheckboxControls(checkboxContents: CheckboxContent[], hostFormGroup: FormGroup, friendlyNames: any, checkboxContentService: CheckboxContentService): any[] {

    const output: any[] = [];
    if (!checkboxContents || !checkboxContents.length) return output;

    checkboxContents = checkboxContents.filter(item => checkboxContentService.verifiedDynamicContent(item)); // Filter out checkbox contents from the original list that don't have matching dynamic content.

    checkboxContents.forEach((checkboxContent: CheckboxContent) => {

      // If there are multiple checkbox contents with the same formControlName, only the one with the highest displayOrder will have its displayHeading property set to true
      checkboxContent.formControlName = checkboxContent.friendlyName.replace(/\s/g, "");
      if (checkboxContents.find(x => x.formControlName == checkboxContent.formControlName && x.displayOrder < checkboxContent.displayOrder)) {
        checkboxContent.displayHeading = false;
      }

      const formControl: FormControl = checkboxContent.isRequired
        ? new FormControl(false, [Validators.required])
        : new FormControl(false);

      const item = {
        model: checkboxContent,
        form: formControl,
        formControlName: checkboxContent.formControlName,
        visibilityStyle: ""
      };

      hostFormGroup.addControl(checkboxContent.formControlName, formControl);
      output.push(item);
      friendlyNames[checkboxContent.formControlName] = checkboxContent.friendlyName;
    });

    return output;
  }

  /**
   * Clear and disable the AbstractControl element provided.
   * @param element May be a FormControl or a Formgroup.
   * @param enabledStatusTo True for enabled, false for disabled.
   * @param clearValues If true, sets value of FormControl(s) to null.
   */
  public enableDisable(element: AbstractControl, enabledStatusTo: boolean, clearValues: boolean = false, opts: any = {}): void {
    if (clearValues) element.setValue(null, opts);

    if (enabledStatusTo) element.enable(opts);
    else element.disable(opts);
  }
}

export interface IRouteBuilder {
  addChildOutletRoute(outletName: string, route: string);
  getRouteObj();
}

class RouteBuilder implements IRouteBuilder {
  outletObject = {};
  hasOutlets = false;

  constructor(public primaryRoute: string) { }

  public addChildOutletRoute(outletName: string, route: string) {
    this.outletObject[outletName] = [route];
    this.hasOutlets = true;
  }

  public getRouteObj() {
    return this.buildRouteWithChildOutlets();
  }

  private buildChildOutlets() {
    const routeOutlet = { outlets: this.outletObject };
    return routeOutlet;
  }

  private buildRouteWithChildOutlets() {
    let route = [];
    let primarySegments = this.primaryRoute.split('/');
    primarySegments = ['/'].concat(primarySegments);

    if (this.hasOutlets) {
      route = [...primarySegments, this.buildChildOutlets()];
    } else {
      route = [...primarySegments];
    }
    // if (this.hasOutlets) {
    //  route = ['/' + this.primaryRoute, this.buildChildOutlets()];
    // } else {
    //    route = ['/' + this.primaryRoute];
    // }
    return route;
  }

}
