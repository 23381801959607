// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';

import { OnlineServiceRegistry } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { OnlineServiceLinkManagerService } from '@coreServices/online-service-link-manager.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { UserManagerService } from '@coreServices/user-manager.service';

import { WebLinkInfoDto } from '@coreShared/core-shared.module';
import { SystemSettingsManagerService } from "../system-settings-manager.service";
// Lazy Components
//import { OnlineEntitySearchEntryComponent } from '@features/onlineEntitySearch/components/online-entity-search-entry/online-entity-search-entry.component';

/**
 * Service that supports the User Registration Wizard.
 */
@Injectable({
  providedIn: 'root'
})
export class RegistrationWizardFeatureConfigurationService {
  private isInitialized = false;
  protected userRegistrationConstantsService: IUserRegistrationConstantsService;
  public wizardPageConfiguration = {
    registrationVerifyUser: {
      domainIdentifiersDirectiveConfiguration: null,
      domainIdentifierFilterFunction: (licenseTypeId, domainIdentifiers) => {
        return [];
      }
    }
  };

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected router: Router,
    protected userManagerService: UserManagerService,
    protected systemSettingsManagerService: SystemSettingsManagerService
  ) {
  }

  public initialize() {
    if (!this.isInitialized) {
      // configure constants
      this.configureConstants();

      // Decorate
      this.configureDecorators();

      // Routes
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
 * Decorate the ConstantsService with UserRegistration-specific constants.
 */
  protected configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, UserRegistrationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, UserRegistrationConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, UserRegistrationConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, UserRegistrationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.userRegistrationConstantsService = this.constantsService as IUserRegistrationConstantsService;
  }

  protected configureDecorators() {
    // Only show user registration link if not logged in
    let canExecute = () => {
      return this.userManagerService.getCurrentPrincipal() == null &&
        this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.LOGIN.ENABLED) == true;
    };

    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Register to Access Site";
    linkInfo.ProcessTypeId = OnlineServiceProcessTypeKeys.UserRegistration;
    linkInfo.DisplayOrder = this.constantsService.ONLINE_SERVICE_SORT.USER_REGISTRATION;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false);
    onlineLink.canExecute = canExecute;
  }

  /**
   * Configure dynamic UserRegistration routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  protected configureRoutes() {

    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.UserRegistration).selectorPath,
        loadChildren: () => import('src/app/core/features/registrationWizard/registration-wizard-selection.module')
          .then(m => m.RegistrationWizardSelectionModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.UserRegistration).onlineServicePathTemplate,
        loadChildren: () => import('src/app/core/features/registrationWizard/registration-wizard.module')
          .then(m => m.RegistrationWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class UserRegistrationConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      REGISTRATION_WIZARD_FEATURE: {
        REGISTRATION_WIZARD: "Registration.Wizard",
        REGISTRATION_WIZARD_SELECTOR: "Registration.Wizard.Selector"
      }
    },

    SYSTEM_SETTING_KEYS: {
      REGISTRATION_WIZARD_FEATURE_SYSTEM_SETTING_KEYS: {
        NEW_ORGANIZATION_ENABLED: "Feature.Registration.NewOrganization.Enabled",
        NEW_USER_ENABLED: "Feature.Registration.NewUser.Enabled",
        PROFESSIONAL_FIRM_ENABLED: "Feature.Registration.ProfessionalFirm.Enabled",
        REGISTRATION_CODE_ENABLED: "Feature.Registration.RegistrationCode.Enabled",
        SUPERVISOR_ENABLED: "Feature.Registration.Supervisor.Enabled",
        OPTIONAL_SSN_ENABLED: "Feature.Registration.OptionalSsn.Enabled",
        SECURITY_CODE_REQUIRED_ENABLED: "Feature.Registration.SecurityCodeRequired.Enabled"
      }
    },

    FEATURE_CONSTANTS: {
      REGISTRATION_WIZARD_FEATURE: {
        REGISTRATION_TYPES: {
          PROFESSIONAL_FIRM: { NAME: "ProfessionalFirm", PROCESS: 0 },
          SUPERVISOR: { NAME: "Supervisor", PROCESS: 0 },
          REGISTRATION_CODE: { NAME: "RegistrationCode", PROCESS: 104 },
          NEW_USER: { NAME: "NewUser", PROCESS: 103 },
          ORGANIZATION: { NAME: "Organization", PROCESS: 107 },
        },
        NEW_USER_TYPES: {
          ORGANIZATION: 1,
          INDIVIDUAL: 2
        }
      }
    },

    WEB_SERVICE_PROCESS_TYPES: {
      USER_REGISTRATION_CARD: OnlineServiceProcessTypeKeys.UserRegistration
    },
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), UserRegistrationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), UserRegistrationConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), UserRegistrationConstantsService.Mergers.FEATURE_CONSTANTS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), UserRegistrationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface IUserRegistrationConstantsService extends UserRegistrationConstantsService {
}

