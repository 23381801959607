import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Router } from '@angular/router';
import { ErrorComponentKeys, LoginComponentKeys } from '@core/index-constants';
import { Command } from '@coreModels/command';
import { RouteInfoRegistry } from '@coreModels/route-registry'; ///index-models';
import { AddToCartAndContinueShoppingEventArguments, BackEventArguments, CancelEventArguments, FinishEventArguments, ForwardEventArguments, INavigationActionEventArguments, StepBackEventArguments, StepForwardEventArguments } from '@coreModels/wizard-event-args';
import { BagService } from '@coreServices/bag.service';
import { CartManagerService } from '@coreServices/cart-manager.service';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService } from "@coreServices/constants-provider.service";
import { ReachInjectorService } from '@coreServices/reach-injector.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { WebServiceDto, WebServiceHistoryDto } from '@coreShared/core-shared.module';
import * as _l from 'lodash-es';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { first, map } from "rxjs/operators";
import * as _ from "underscore";
import { BrowserDetectionService, IncidentService, SystemSettingsManagerService, UserManagerService, UtilitiesService, WizardModel, WizardSearchCriteria } from '../core.module';

export const WIZARD_INJECTOR_TOKEN: string = 'WIZARD_INJECTOR_TOKEN';

@Injectable()
export class WizardInjector { public wizard: Wizard; }
export class Wizard {

  selectedIndex: number = 0;
  onNavigationCanceledHandler;
  onErrorHandler;
  onForwardStartHandler: (eventArgs: ForwardEventArguments) => Observable<INavigationActionEventArguments>;
  onForwardCompleteHandler: (eventArgs: ForwardEventArguments) => Observable<INavigationActionEventArguments>;
  onBackStartHandler: (eventArgs: BackEventArguments) => Observable<INavigationActionEventArguments>;
  onBackCompleteHandler: (eventArgs: BackEventArguments) => Observable<INavigationActionEventArguments>;
  onWizardFinishedHandler: (eventArgs: FinishEventArguments) => Observable<INavigationActionEventArguments>;
  onWizardCanceledHandler: (eventArgs: CancelEventArguments) => Observable<INavigationActionEventArguments>;
  map: Map<string, any> = null;
  originalModel = null;
  model = null;
  continueShoppingCommandText: string = "Add to Cart and Return to Overview";
  finishCommandText: string = "Finish";
  cancelCommandText: string = "Cancel";
  steps: any; // IWebServiceViewDto[]
  selectedStep: any; //: IWebServiceViewDto;
  dynamicContentConfiguration = null;
  changeTrackingEnabled = false;
  excludeContinueShoppingCommand = false;
  additionalCommands: Command[] = [];
  backCommand: Command;
  forwardCommand: Command;
  finishCommand: Command;
  cancelCommand: Command;
  hideCancelButton: boolean = false;
  hideNavigationButtons: boolean = false;
  hidePreviousButton: boolean = false;
  isContentValid: boolean = false;

  private _selectedStepChanged$: Subject<number> = new Subject<number>();
  public selectedStepChanged$ = this._selectedStepChanged$.asObservable();

  /**
  * The root FormGroup for this dialog instance.
  */
  public wizardForm: FormGroup = null;

  constructor(public wizardSearchCriteria: WizardSearchCriteria,
    public onlineService: WebServiceDto,
    public onlineServiceHistory: WebServiceHistoryDto,
    private bagService: BagService,
    private cartManagerService: CartManagerService,
    private commandService: CommandService,
    private constantsService: ConstantsService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private saveWizardStepsFunction: (wizard: Wizard, eventArguments: INavigationActionEventArguments) => Observable<WebServiceHistoryDto>,
    private validationManagerService: ValidationManagerService,
    private confirmationService: ConfirmationService,
    private userManagerService: UserManagerService,
    private incidentService: IncidentService,
    private browserDetectionService: BrowserDetectionService,
    private utilitiesService: UtilitiesService,
  ) {
    this.map = new Map<string, any>();
    this.steps = this.decorateSteps(_l.sortBy(onlineService.Steps, "StepOrder"));

    const attemptResumeAtLastStep = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_WEBSERVICEHISTORY_RESUMEATLASTSTEPINDEX);

    this.selectedIndex = 0;
    this.selectedStep = this.steps[this.selectedIndex];

    // If lastStepIndex is within the valid range, set selectedIndex to lastStepIndex. Otherwise, set selectedIndex to 0.
    const lastIndex = (attemptResumeAtLastStep && onlineServiceHistory?.LastStepIndex >= 0 && onlineServiceHistory?.LastStepIndex < this.steps.length)
      ? onlineServiceHistory?.LastStepIndex
      : 0;

    if (lastIndex > 0) {
      this.steps[this.selectedIndex].stepComplete = true;
      this.setSelectedStep(lastIndex);
    }

    this.onNavigationCanceledHandler = this.navigationCanceledHandler;
    this.onErrorHandler = this.errorHandler;
    this.onForwardStartHandler = this.defaultHandler;
    this.onForwardCompleteHandler = this.defaultHandler;
    this.onBackStartHandler = this.defaultHandler;
    this.onBackCompleteHandler = this.defaultHandler;
    this.onWizardFinishedHandler = this.defaultHandler;
    this.onWizardCanceledHandler = this.defaultHandler;

    // Commands
    this.backCommand = this.commandService.create(this.canBackExecute, this.backExecuted);
    this.backCommand.description = "WizardBackCommand";
    this.forwardCommand = this.commandService.create(this.canForwardExecute, this.forwardExecuted);
    this.forwardCommand.description = "WizardForwardCommand";
    this.finishCommand = this.commandService.create(this.canFinishExecute, this.finishExecuted);
    this.finishCommand.description = "WizardFinishCommand";
    this.cancelCommand = this.commandService.create(this.canCancelExecute, this.cancelExecuted);
    this.cancelCommand.description = "WizardCancelCommand";
  }

  /**
   * Sets the specific model as the current data model for the wizard.
   * @param wizardModel the current data model.
   */
  public setModel(wizardModel) {
    if (this.changeTrackingEnabled) {
      this.originalModel = _l.cloneDeep(wizardModel);
    }
    this.model = wizardModel;
  };

  public getInjector(parentInjector: Injector): ReachInjectorService {
    let injectorService = new ReachInjectorService(parentInjector);
    let wizardInjector = new WizardInjector();
    wizardInjector.wizard = this;
    injectorService.addItem(WIZARD_INJECTOR_TOKEN, wizardInjector);
    return injectorService;
  }

  public checkContentValid() {
    this.isContentValid = this.wizardForm.valid;
  }

  /**
   * Returns true if the wizard is on the last configured step.
   */
  public isWizardOnLastStep(): boolean {
    return this.selectedIndex === this.steps.length - 1;
  }

  public isWizardOnFirstStep(): boolean {
    return this.selectedIndex === 0;
  }


  /**
   * Specifies a non-default handler function for the Wizard Navigate Forward Start event.
   * @param handler the handler function.
   */
  public onForwardStart(handler: (eventArgs: ForwardEventArguments) => Observable<INavigationActionEventArguments>): void {
    this.onForwardStartHandler = (eventArgs: ForwardEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    }
  }

  /**
   * Specifies a non-default handler function for the Wizard Forward Navigate Complete event.
   * @param handler the handler function.
   */
  public onForwardComplete(handler): void {
    this.onForwardCompleteHandler = (eventArgs: ForwardEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    };
  }

  /**
   * Specifies a non-default handler function for the Wizard Navigate Backward Start event.
   * @param handler the handler function.
   */
  public onBackStart(handler): void {
    this.onBackStartHandler = (eventArgs: BackEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    };
  }

  /**
   * Specifies a non-default handler function for the Wizard Backward Navigate Complete event.
   * @param handler the handler function.
   */
  public onBackComplete(handler): void {
    this.onBackCompleteHandler = (eventArgs: BackEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    };
  }

  /**
   * Specifies a non-default handler function for the Wizard Finished event. This specifies what to do next when all configured pages of the wizard are complete.
   * @param handler the handler function.
   */
  public onWizardFinished(handler): void {
    this.onWizardFinishedHandler = (eventArgs: FinishEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    };
  }

  /**
   * Specifies a non-default handler function for the Wizard Canceled event.
   * @param handler the handler function.
   */
  public onWizardCanceled(handler): void {
    this.onWizardCanceledHandler = (eventArgs: CancelEventArguments) => {
      return this.wrapHandler(handler, eventArgs);
    };
  }

  /**
   * Returns true if the Wizard Forward Navigate operation can run.
   */
  public canForwardExecute = (): boolean => {
    return this.selectedStep.canForwardExecute() && this.selectedIndex < this.steps.length;
  }

  /**
   * Invokes the Wizard Forward Navigate operation.
   */
  public forwardExecuted = (): Observable<any> => {

    if (this.changeTrackingEnabled) {
      // Mark the wizard model as dirty or not
      this.model.isDirty = !_l.isEqual(this.model, this.originalModel);
    }

    // create event arguments
    var stepForwardStartEventArgs = this.stepForwardEventArguments();
    var wizardForwardEventArgs = this.forwardEventArguments();

    const doStepForward = async (): Promise<any> => {

      // Clear any existing "server" validation errors.
      this.validationManagerService.clearApplicationServerValidationMessages();

      let stepForwardResponse = await this.selectedStep.onStepForwardStartHandler(stepForwardStartEventArgs).toPromise();

      // HANDLE CANCELLATION (catches client side errors)
      if (stepForwardStartEventArgs.cancel) {
        return this.onNavigationCanceledHandler(stepForwardStartEventArgs).toPromise();
      }

      let stepForwardCompleteHandler = this.selectedStep.onStepForwardCompleteHandler(stepForwardStartEventArgs).toPromise();
      return stepForwardCompleteHandler;
    };

    const doWizardForwardStart = (): Observable<any> => {

      // Clear any existing server validation errors.
      this.validationManagerService.clearApplicationValidationErrors();

      return this.onForwardStartHandler(wizardForwardEventArgs);
    };

    const doLogAction = (): Observable<any> => {
      return wizardForwardEventArgs.logAction
        ? this.saveStepHistoryAction(wizardForwardEventArgs)
        : of(wizardForwardEventArgs);
    };

    const doWizardForwardComplete = (): Observable<any> => {
      return this.onForwardCompleteHandler(wizardForwardEventArgs);
    };

    const doForwardExecute = async (): Promise<any> => {
      try {
        let stepForwardResponse = await doStepForward();
        let wizardForwardStartResponse = await doWizardForwardStart().toPromise();
        let wizardForwardCompleteResponse = await doWizardForwardComplete().toPromise();

        // HANDLE CANCELLATION (catches server side errors)
        if (wizardForwardEventArgs.cancel) {
          let wizardLogActionResponse = await doLogAction().toPromise(); // Log action last so we catch server side errors. TODO: THIS PART DOESN'T WORK YET. NO SERVER VALIDATION MESSAGES ARE FOUND WHEN THIS CALL IS MADE.
          return this.onNavigationCanceledHandler(wizardForwardEventArgs);
        }

        let wizardLogActionResponse = await doLogAction().toPromise(); // Log action last so we catch server side errors.
        this.validationManagerService.clearApplicationValidationErrors();

        // Mark the current step complete
        this.steps[this.selectedIndex].stepComplete = true;

        // Nav forward to the next step
        return of(this.setSelectedStep(this.selectedIndex + 1)).toPromise();
      } catch (reason) {
        return of(this.onErrorHandler(reason)).toPromise();
      }
    };

    const forwardExec = from(doForwardExecute());
    forwardExec.pipe(first()).subscribe();
    return forwardExec;
  }

  /**
   * Returns true if the Wizard Backward Navigate operation can run.
   */
  public canBackExecute = (): boolean => {
    return this.selectedStep.canBackExecute() && this.selectedIndex !== 0;
  }

  /**
   * Invokes the Wizard Backward Navigate operation.
   */
  public backExecuted = (): Observable<any> => {
    // dirty
    if (this.changeTrackingEnabled) {
      this.model.isDirty = !_l.isEqual(this.model, this.originalModel);
    }

    // args
    var stepBackEventArgs = this.stepBackEventArguments();
    var wizardBackEventArgs = this.backEventArguments();

    const doStepBackStart = (): Observable<any> => {
      if (stepBackEventArgs.cancel) {
        return this.onNavigationCanceledHandler(stepBackEventArgs);
      }

      if (this.changeTrackingEnabled) {
        this.setModel(this.originalModel);
      }

      this.validationManagerService.clearApplicationValidationErrors();
      this.validationManagerService.clearApplicationServerValidationMessages();
      return this.selectedStep.onStepBackStartHandler(stepBackEventArgs);
    };

    const doStepBackComplete = (): Observable<any> => {
      if (stepBackEventArgs.cancel) {
        return this.onNavigationCanceledHandler(stepBackEventArgs);
      }

      return this.selectedStep.onStepBackCompleteHandler(stepBackEventArgs);
    };

    const doBackStart = (): Observable<any> => {
      if (stepBackEventArgs.cancel) {
        return this.onNavigationCanceledHandler(stepBackEventArgs);
      }

      return this.onBackStartHandler(wizardBackEventArgs);
    };

    const doWizardBack = (): Observable<any> => {
      // Get Out
      if (wizardBackEventArgs.cancel) {
        return this.onNavigationCanceledHandler(wizardBackEventArgs);
      }

      return wizardBackEventArgs.logAction ? this.saveStepHistoryAction(wizardBackEventArgs) : of(wizardBackEventArgs);
    };

    const doBackComplete = (): Observable<any> => {
      if (wizardBackEventArgs.cancel) {
        return this.onNavigationCanceledHandler(wizardBackEventArgs);
      }

      return this.onBackCompleteHandler(wizardBackEventArgs);
    };

    const doBackExecuted = async (): Promise<any> => {
      try {
        let promptResponse = await of(this.promptForUnsavedChanges(stepBackEventArgs)).toPromise();
        let stepStartResponse = await doStepBackStart().toPromise();
        let stepCompleteResponse = await doStepBackComplete().toPromise();
        let backStartResponse = await doBackStart().toPromise();
        let wizardBackResponse = await doWizardBack().toPromise();
        let backCompleteResponse = await doBackComplete().toPromise();

        // Get Out
        if (wizardBackEventArgs.cancel) {
          return this.onNavigationCanceledHandler(wizardBackEventArgs);
        }

        // Don't allow the step we're backing up towards to sync the model;
        // That would cause changes on the current step to be lost.
        (this.model as WizardModel).doBackExecuted = true;

        this.steps[this.selectedIndex].stepComplete = true;
        return of(this.setSelectedStep(this.selectedIndex - 1)).toPromise();
      } catch (reason) {
        return of(this.onErrorHandler(reason)).toPromise();
      }
    };

    const backExec = from(doBackExecuted());
    backExec.subscribe();
    return backExec;
  }

  public canFinishExecute = (): boolean => {
    return this.selectedIndex === this.steps.length - 1;
  }

  public finishExecuted = (): Observable<any> => {

    const finishExecutedEventArgs = this.finishEventArguments();

    const doFinish = async (): Promise<any> => {
      try {

        await this.onWizardFinishedHandler(finishExecutedEventArgs).toPromise();
        if (finishExecutedEventArgs.cancel) return this.onNavigationCanceledHandler(finishExecutedEventArgs).toPromise();

        return finishExecutedEventArgs.logAction
          ? this.saveStepHistoryAction(finishExecutedEventArgs).toPromise()
          : of(finishExecutedEventArgs).toPromise();

      } catch (reason) {
        return of(this.onErrorHandler(reason)).toPromise();
      }
    };

    const result = from(doFinish());
    result.subscribe();
    return result;
  }

  /**
   * Returns true if the Wizard Cancel operation can run.
   */
  public canCancelExecute = (): boolean => {
    return this.onlineService.IsCancellationAllowed && this.onlineServiceHistory != null;
  }

  /**
   * Invokes the Wizard Cancel operation.
   */
  public cancelExecuted = (): Observable<any> => {
    var cancelEventArguments = this.cancelEventArguments();
    const doCancel = async (): Promise<any> => {
      try {
        let wizardCanceledResponse = await this.onWizardCanceledHandler(cancelEventArguments).toPromise();

        return cancelEventArguments.logAction
          ? this.saveStepHistoryAction(cancelEventArguments).toPromise()
          : of(cancelEventArguments).toPromise();
      } catch (reason) {
        return of(this.onErrorHandler(reason)).toPromise();
      }
    };

    const result = from(doCancel());
    result.subscribe();
    return result;
  }

  /**
   * Returns true if the navigation from the current step to the specified step (index) is allowed.
   */
  public canNavigateToStep = (index): boolean => {
    if (index == this.selectedIndex || index >= this.steps.length) return false;
    if (index == 0 || this.steps[this.selectedIndex].stepComplete) return true;

    return false;
  }

  protected continueShoppingCommandExecute = () => {

    if (this.validationManagerService.hasClientApplicationValidationMessages) {
      this.validationManagerService.showApplicationValidationSummary();
      return;
    }

    this.saveStepHistoryAction(this.addToCartAndContinueShoppingEventArguments())
      .subscribe(async () => {
        await this.cartManagerService.addCartItem(this.onlineServiceHistory);
        this.continueShoppingNavigation();
      });
  }

  public continueShoppingNavigation(): void {
    this.router.navigate(['/', 'Landing']);
  }

  /**
   * Sets the currently selected Wizard Step to the specified index.
   * @param index the index of the target Wizard Step.
   */
  public setSelectedStep(index: number) {
    if (this.canNavigateToStep(index)) {
      this.selectedStep.additionalCommands = [];
      this.selectedStep.hideForward = false;
      this.selectedStep.onStepForwardStartHandler = this.defaultOnForwardStartHandler;
      this.selectedStep.onStepForwardComplete = this.defaultHandler;
      this.selectedStep.onStepBackStart = this.defaultHandler;
      this.selectedStep.canBackExecute = () => { return true; }
      this.selectedStep.canForwardExecute = () => { return true; }

      this.selectedIndex = index;
      this.selectedStep = this.steps[index];

      // If this is the last step and there are fees involved we add a continue shopping button.
      if (!this.excludeContinueShoppingCommand && this.isWizardOnLastStep() && this.onlineService.Fees != null && this.onlineService.Fees.length) {

        const continueShopping = this.commandService.create(() => true, this.continueShoppingCommandExecute);
        continueShopping.commandText = "Add to Cart and Continue Shopping";

        const mdhSurveySystemSettingIsTurnedOn: boolean =
          this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.SURVEY_ENABLED)
          && this.model?.license?.LicenseType?.MdhSurveyPath
          && (this.model?.license?.InProcessRenewal?.SurveyStatusId == null || this.model?.license?.InProcessRenewal?.SurveyStatusId == this.constantsService.SURVEY_STATUSES.STARTED);

        if (mdhSurveySystemSettingIsTurnedOn) {
          this.continueShoppingNavigation = () => {
            this.router.navigate(['/', 'surveyredirect'], { queryParams: { action: "Landing", licenseNumber: this.model.license.LicenseNumber, mdhSurveyPath: this.model.license.LicenseType.MdhSurveyPath, renewalId: this.model.license.InProcessRenewal.Id } });
          };
        }

        this.selectedStep.additionalCommands.push(continueShopping);
      }

      this.clearValidationModes();
      this.clearStepCompleteOnStepsBeyond(index);
    }

    this.raiseSelectedStepChanged(index);
    return this.selectedStep;
  }

  /**
   * Default error handler function for all Wizard operations.
   * Internal Server Error: redirect to error page.
   * Other Errors (where user can react to error message): return reason object.
   */
  private errorHandler = (error: Error | HttpErrorResponse): Observable<any> => {
    if (error instanceof HttpErrorResponse) {
      if (error.status == HttpStatusCode.Unauthorized) {
        this.userManagerService.logout();
        let loginComponentRegistryItem = RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login);
        let loginPath = loginComponentRegistryItem.path;
        this.router.navigate(['/', loginPath]);
      }
      else {
        let referenceNumber = this.utilitiesService.guid();
        try {
          this.incidentService.error(error, referenceNumber, this.browserDetectionService.getBrowserVersion());
        } catch (ex) {
          console.error('Failed to log incident.')
        }
        this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(ErrorComponentKeys.Error).path, { referenceNumber: referenceNumber, error: error?.message }]);
      }
    }
    return of(error);
  }

  /**
   * Default handler for navigation cancelation.
   */
  private navigationCanceledHandler = (eventArgs) => {
    throw throwError(eventArgs);
  }

  /**
   * Default (non-noop) handler for Forward Start navigation operation.
   */
  private defaultOnForwardStartHandler = (eventArgs: ForwardEventArguments): Observable<ForwardEventArguments> => {
    var clientErrorsExist: boolean = this.validationManagerService.hasClientApplicationValidationMessages;

    if (clientErrorsExist) {
      // Show validation summary
      this.validationManagerService.showApplicationValidationSummary();

      // Pop toaster messages for each error
      this.validationManagerService.popToastersForCurrentClientApplicationValidationMessages();

      if (eventArgs.logAction != false) {
        return this.saveStepHistoryAction(eventArgs).pipe(
          map(() => {
            eventArgs.cancel = true;
            return eventArgs;
          }));
      }
      else {
        return of(eventArgs)
      }
    } else {
      return of(eventArgs);
    }
  }

  /**
   * Wraps the specified wizard operation handler function with a function that invokes the custom handler, then returns the default handler result.
   * @param handler
   * @param eventArgs
   */
  private wrapHandler(handler, eventArgs: INavigationActionEventArguments): Observable<INavigationActionEventArguments> {
    return handler(eventArgs).pipe(map(res => this.defaultHandler(eventArgs)));
  }

  /**
   * Defines a default noop handler function that simply returns the specified event arguments. Serves as a default operation implementation for steps that have no
   * custom operation defined.
   */
  private defaultHandler = (eventArgs: INavigationActionEventArguments): Observable<INavigationActionEventArguments> => {
    return of(eventArgs);
  }

  /**
   * Factory function for ForwardEventArguments objects.
   */
  private forwardEventArguments(): ForwardEventArguments {
    return new ForwardEventArguments(this.constantsService.WIZARD_ACTIONS.FORWARD);
  }

  /**
   * Factory function for BackEventArguments objects.
   */
  private backEventArguments(): BackEventArguments {
    return new BackEventArguments(this.constantsService.WIZARD_ACTIONS.BACKWARD);
  }

  /**
   * Factory function for FinishEventArguments objects.
   */
  private finishEventArguments(): FinishEventArguments {
    return new FinishEventArguments(this.constantsService.WIZARD_ACTIONS.FINISH);
  }

  /**
   * Factory function for CancelEventArguments objects.
   */
  private cancelEventArguments(): CancelEventArguments {
    return new CancelEventArguments(this.constantsService.WIZARD_ACTIONS.CANCEL);
  }

  /**
   * Factory function for AddToCartAndContinueShoppingEventArguments objects.
   */
  private addToCartAndContinueShoppingEventArguments(): AddToCartAndContinueShoppingEventArguments {
    return new AddToCartAndContinueShoppingEventArguments(this.constantsService.WIZARD_ACTIONS
      .ADD_TO_CART_AND_CONTINUE_SHOPPING);
  }

  /**
   * Factory function for StepForwardEventArguments objects.
   */
  private stepForwardEventArguments(): StepForwardEventArguments {
    return new StepForwardEventArguments(this.constantsService.WIZARD_ACTIONS.STEP_FORWARD);
  }

  /**
   * Factory function for StepBackEventArguments objects.
   */
  private stepBackEventArguments(): StepBackEventArguments {
    return new StepBackEventArguments(this.constantsService.WIZARD_ACTIONS.STEP_BACKWORD);
  }

  /**
   * Helper function that saves the current wizard steps.
   * @param eventArguments
   */
  public saveStepHistoryAction(eventArguments: INavigationActionEventArguments): Observable<INavigationActionEventArguments> {
    // we may not log history here based on the wizard configuration
    const doSaveAction = async (eventArguments: INavigationActionEventArguments): Promise<INavigationActionEventArguments> => {
      if (this.onlineService.IsHistoryTracked) {
        let saveResult = await this.saveWizardStepsFunction(this, eventArguments).toPromise();
      }

      return of(eventArguments).toPromise();
    }
    return from(doSaveAction(eventArguments));
  }

  /**
   * Re-initializes the steps beyond the specified index to not-complete.
   * @param stepIndex the index beyond which the steps will be re-initialized.
   */
  private clearStepCompleteOnStepsBeyond(stepIndex) {
    _l.map(this.steps,
      (step) => {
        if (_l.indexOf(this.steps, step) >= stepIndex) {
          step.stepComplete = false;
        }

        return step;
      });
  }

  /**
   * Initialization for the configured Wizard Steps.
   * @param steps
   */
  private decorateSteps(steps) {

    var decoratedSteps = _l.map(steps,
      (step) => {

        var rawMinorKey = step.MinorKey;

        if (step.MinorKey && step.MinorKey.indexOf(".SummaryTextBlock") >= 0) {
          rawMinorKey = step.MinorKey.replace(".SummaryTextBlock", "");
        }

        step.regionName = this.onlineService.MajorKey + "." + rawMinorKey;
        step.pageSummaryTextMinorKey = rawMinorKey + ".SummaryTextBlock";
        step.additionalHelpTextMinorKey = rawMinorKey + ".AdditionalHelpText";

        step.bag = this.bagService.create(step.Id);
        step.onStepForwardStartHandler = this.defaultOnForwardStartHandler;
        step.onStepForwardCompleteHandler = this.defaultHandler;
        step.onStepBackCompleteHandler = this.defaultHandler;
        step.onStepBackStartHandler = this.defaultHandler;
        step.stepComplete = false;
        step.additionalCommands = [];

        step.canBackExecute = () => {
          return true;
        }

        step.canForwardExecute = () => {
          return true;
        }

        // Methods to add handlers
        step.onStepForwardStart = (handler: (eventArgs) => Observable<any>) => {
          step.onStepForwardStartHandler = (eventArgs) => {
            const doForward = async (eventArgs): Promise<any> => {
              await handler(eventArgs).toPromise();
              return this.defaultOnForwardStartHandler(eventArgs).toPromise();
            };

            return from(doForward(eventArgs));
          }
        }

        step.onStepForwardComplete = (handler) => {
          step.onStepForwardCompleteHandler = (eventArgs) => {
            return this.wrapHandler(handler, eventArgs);
          }
        }

        step.onStepBackStart = (handler) => {
          step.onStepBackStartHandler = (eventArgs) => {
            return this.wrapHandler(handler, eventArgs);
          }
        }

        step.onStepBackComplete = (handler) => {
          step.onStepBackCompleteHandler = (eventArgs) => {
            return this.wrapHandler(handler, eventArgs);
          }
        }

        return step;
      });

    return decoratedSteps;
  }

  /**
   * Handler for loss of data on navigation warning.
   * @param eventArgs
   */
  private promptForUnsavedChanges(eventArgs) {

    if (this.model.isDirty) {

      // Use the PrimeNG confirmation dialog.
      this.confirmationService.confirm({
        message: `This step has unsaved changes. Navigating will result in data loss for this step.`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Ok',

        // If accepted...
        accept: () => {
          // Nothing happens.
        },
        reject: () => {
          eventArgs.cancel = true;
        }

      });
    }

    return eventArgs;
  }

  /**
   * Initializes the validation mode setting on the wizard model.
   */
  private clearValidationModes() {

    if (this.model?.application) {
      this.model.application.ValidationMode = null;
    }

    if (this.model?.entityEmployment) {
      this.model.entityEmployment.ValidationMode = null;
    }

    if (this.model?.license) {
      this.model.license.ValidationMode = null;
    }

    if (this.model?.verification) {
      this.model.verification.ValidationMode = null;
    }

    if (this.model?.verificationInfo) {
      this.model.verificationInfo.ValidationMode = null;
    }

    if (this.model?.entity) {
      this.model.entity.ValidationMode = null;
    }

    if (this.model?.supervisionPlan) {
      this.model.supervisionPlan.ValidationMode = null;
    }
  }

  /**
  * Raises the "selectedStepChanged$" event.
  */
  private raiseSelectedStepChanged(newStep: number) {
    this._selectedStepChanged$.next(newStep);
  }
}
