import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'yes-no-dropdown',
  templateUrl: './yes-no-dropdown.component.html',
  styleUrls: ['./yes-no-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => YesNoDropdownComponent),
      multi: true
    }
  ]
})
export class YesNoDropdownComponent implements OnInit, OnChanges, ControlValueAccessor {
  /**
   * Id for the null/unselected dropdown option.
   */
  public static readonly nullId = 0;
  /**
  * Id for the Yes/True dropdown option.
  */
  public static readonly yesId = 1;
  /**
  * Id for the No/False dropdown option.
  */
  public static readonly noId = 2;

  /**
  * Text to display for the 'True/Yes' option. Defaults to 'Yes'.
  */
  @Input() trueDisplayValue: string = 'Yes';
  /**
  * Text to display for the 'False/No' option. Defaults to 'No'.
  */
  @Input() falseDisplayValue: string = 'No';
  /**
  * Text to display for the 'Null/Unselected' option. Defaults to 'Please Select'
  */
  @Input() nullDisplayValue: string = 'Please Select';
  /**
  * Optional label for control.
  */
  @Input() label: string = ""; // Option label for control

  contentForm: FormGroup = new FormGroup({
    Answer: new FormControl(''),
  });

  possibleOptions = [];
  dataLoaded = false;
  currentValue: boolean = null;
  
  constructor() {
    this.possibleOptions = [
      { Id: YesNoDropdownComponent.nullId, Description: this.nullDisplayValue },
      { Id: YesNoDropdownComponent.yesId, Description: this.trueDisplayValue },
      { Id: YesNoDropdownComponent.noId, Description: this.falseDisplayValue }
    ];

    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  */
  ngOnChanges(): void {
  }

  /**
* Provide specified mapping from the input model to
* the reactive FormGroup for this instance.
*/
  protected modelToForm() {
    // Initialize the contentForm.
    this.contentForm = new FormGroup({
      Answer: new FormControl(null),
    });

    this.initSelectedOption();

    // Wire event handlers for the content form.
    this.initializeEventHandlers();

    this.dataLoaded = true;
  }

  /**
* Retrieve data from the form and apply it to the model.
*/
  protected formToModel = () => {
    this.currentValue = null;
    let currentOption = this.contentForm.get('Answer').value;
    if (currentOption) {
      switch (currentOption.Id) {
        case YesNoDropdownComponent.nullId:
          this.currentValue = null;
          break;
        case YesNoDropdownComponent.yesId:
          this.currentValue = true;
          break;
        case YesNoDropdownComponent.noId:
          this.currentValue = false;
          break;
      }
    }

    this.onChange(this.currentValue);
    this.onTouched();
  }

  protected initSelectedOption() {
    let initialOption = this.currentValue === true ? this.possibleOptions[1] : (this.currentValue === false ? this.possibleOptions[2] : this.possibleOptions[0]);
    this.contentForm.get('Answer').setValue(initialOption);
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {
    // When anything in this contentForm changes, update the model.    
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });
  }

  // ==================================== CVA ====================================

  /**
  * Aggregates errors and passes them to the parent.
  * @param c The parent form control.
  */
  validate(c: AbstractControl): ValidationErrors | null {
    // ... and pass them to the parent.
    return this.contentForm.valid ? null : this.contentForm.errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    //this.contentForm.get('Answer').valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
    this.currentValue = value as boolean;
    this.initSelectedOption();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }
}
