import { INavigationActionEventArguments, WizardModel } from '@core/core.module';
import {
  IDomainAuditDto,
  IDomainFormDtoHost,
  IDomainQuestionnaireDtoHost,
  IFileUploadDtoHost,
  IInvoiceDto,
  IOnlineUserDto,
  IOnlineUserSearchCriteriaDto,
  IProcessFeeDto,
  IWebServiceViewDto
} from '@coreShared/core-shared.module';

import {
  IApplicationDto, IAutoVerificationSubscriberDto, IAutoVerificationSubscriptionDto, ICeSponsorDto,
  IDomainDelegationTokenSearchResultItemDto,
  IEntityEmploymentListDto,
  ILicenseDto, ILicenseStatusChangeApplicationDto, ILicenseVerificationDto, ILicenseVerificationRequestDto, ILicensureStepDataDto,
  IOnlineComplaintSubmissionDto, IOnlineLicenseSearchResultItemDto, IProfessionalProfileDto,
  IRenewalDto,
  ISupervisionPlanDto,
  ISupervisionVerificationDto,
  ISupervisorDto
} from '@licensureShared/licensure-shared.module';

export abstract class LicensureWizardModel extends WizardModel {
  public educationProgramApproval = null;
  abstract get ReferenceNumber(): string;
  public supervisor: ISupervisorDto = null;
  constructor(entity: IOnlineUserDto = null,
    public application: IApplicationDto = null,
    entityEmployment: IEntityEmploymentListDto = null,
    public license: ILicenseDto = null,
    public verification: ILicenseVerificationDto = null,
    public verificationInfo: IOnlineLicenseSearchResultItemDto = null,
    public supervisionPlan: ISupervisionPlanDto = null,
    public supervisionVerification: ISupervisionVerificationDto = null,
    onlineUser: IOnlineUserDto = null,
    public sponsor: ICeSponsorDto = null,
    public licenseStatusChangeApplication: ILicenseStatusChangeApplicationDto = null,
    public autoVerificationSubscriber: IAutoVerificationSubscriberDto = null,
    public autoVerificationSubscription: IAutoVerificationSubscriptionDto = null,
    public onlineComplaintSubmission: IOnlineComplaintSubmissionDto = null,
    public domainAudit: IDomainAuditDto = null,
    public criminalBackgroundCheckId: number = null,
    public verificationRequest: ILicenseVerificationRequestDto = null,
    onlineUserSearchCriteria: IOnlineUserSearchCriteriaDto = null,
    fileUploadHost: IFileUploadDtoHost = null,
    public professionalProfile: IProfessionalProfileDto = null,
    public renewal: IRenewalDto = null,
    invoice: IInvoiceDto = null,
    domainFormHost: IDomainFormDtoHost = null,
    domainQuestionnaireHost: IDomainQuestionnaireDtoHost = null,
    public domainDelegationTokens: IDomainDelegationTokenSearchResultItemDto[] = null
    ) {

    super(entity, entityEmployment, onlineUser, onlineUserSearchCriteria, fileUploadHost, invoice, domainFormHost, domainQuestionnaireHost);
  }

  public override createStepData(eventArguments: INavigationActionEventArguments, fees: IProcessFeeDto[], selectedStep: IWebServiceViewDto, errorEntries: string[]): ILicensureStepDataDto {
    let stepData = super.createStepData(eventArguments, fees, selectedStep, errorEntries) as ILicensureStepDataDto;
    stepData.Application = this.application || null;
    stepData.ProfessionalProfile = this.professionalProfile || null;
    stepData.EntityEmploymentList = this.entityEmployment || null;
    stepData.OnlineComplaintSubmission = this.onlineComplaintSubmission || null;
    stepData.License = this.license || null;
    stepData.Renewal = this.renewal || null;
    stepData.LicenseStatusChangeApplication = this.licenseStatusChangeApplication || null;
    stepData.Verification = this.verification || null;
    stepData.Info = this.verificationInfo || null;
    stepData.EducationProgramApproval = this.educationProgramApproval || null;
    stepData.DomainAudit = this.domainAudit || null;
    stepData.Supervisor = this.supervisor || null;
    stepData.DomainDelegationTokens = this.domainDelegationTokens || null;
    return stepData;
  }

  protected synchronize(): void { }
}
