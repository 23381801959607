/**
 * Enumerates the component types for Login pages.
 */
export enum ErrorTypes {
  Error
}

/**
 * Component keys for Core Login components.
 */
export class ErrorComponentKeys {
  public static readonly Error: string = ErrorTypes[ErrorTypes.Error];
}
