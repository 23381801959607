import { Component, forwardRef, Inject, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { CheckboxContent, CheckboxContentService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { ReachModelAwareComponent } from '../../reachModelAware/reach-model-aware.component';

@Component({
  selector: 'checkbox-content-items',
  templateUrl: './checkbox-content-items.component.html',
  styleUrls: ['./checkbox-content-items.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxContentItemsComponent),
      multi: true
    }
  ]
})
export class CheckboxContentItemsComponent extends ReachModelAwareComponent implements OnInit, OnChanges, ControlValueAccessor {

  parent: FormGroup;
  checkboxContents: CheckboxContent[] = [];
  public contentForm: FormGroup = new FormGroup({});
  friendlyNames: any = {};

  public get hasCheckboxes(): boolean { return this.checkboxContents && this.checkboxContents.length > 0; }

  constructor(
    private controlContainer: ControlContainer,
    protected validationManagerService: ValidationManagerService,
    protected checkboxContentService: CheckboxContentService,
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,
    protected utilitiesService: UtilitiesService,
  ) {
    super(reachModelConfigurationInjector);

    this.contentForm.addControl('CheckboxContentItems', new FormControl()); // ModelToForm
  }

  ngOnInit(): void {
    this.parent = this.controlContainer.control as FormGroup;
    this.parent.addControl('CheckboxesContentForm', this.contentForm); // Include this component on the parent component's form group for validating.
  }

  ngOnChanges(changes: SimpleChanges): void {

    super.onInputChanges(changes);

    this.checkboxContents = this.utilitiesService.createCheckboxControls(
      this.checkboxContentService.getCheckboxContentsByKeys(this.majorKey, this.minorKey, true),
      this.contentForm,
      this.friendlyNames,
      this.checkboxContentService);
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    // Unused
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }

}
