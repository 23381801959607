import { IProcessFeeDto,
ISourceCodeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ProcessFeeDto extends BaseDto implements IProcessFeeDto {
	ProcessId: number;
	SourceCode: string;
	Amount?: number;
	DisplayOrder?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
	SourceCodeItem: ISourceCodeDto;

}
