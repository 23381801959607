// style notes: (https://github.com/johnpapa/angular-styleguide#style-y030)

import { Component, Inject, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as _ from 'underscore';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';

import { DynamicContentDto } from '@coreShared/core-shared.module';
import { ReachApplicationService } from '@coreServices/reach-application.service';

/**
 * Renders DynamicContent-based field help for the specified major/minor keys.
 * */
@Component({
  selector: 'field-help',
  templateUrl: './field-help.component.html',
  styleUrls: ['./field-help.component.scss']
})
export class FieldHelpComponent implements OnInit, OnChanges {
  private _entry: DynamicContentDto;
  @Input()
  majorKey: string;
  @Input()
  minorKey: string;

  constructor(
    public reachApplicationService: ReachApplicationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicContentManagerService: DynamicContentManagerService
  ) {
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }


  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  */
  ngOnChanges(): void {
    this._entry = this.getEntry();
  }

  /**
  * Gets a value indicating whether html embedded in the DynamicContentDto.Conent should be escaped.
  * */
  public get escapeContent(): boolean {
    return false;
  }

  /**
  * Gets a value indicating whether this instance has a matching DynamicContentDto with the help data.
  * */
  public get hasEntry(): boolean {
    return this._entry?.Content?.trim()?.length ? true : false;
  }

  /**
   * The text that will render in the field help tooltip. If the escapeContent property returns true, any html embedded in the DynamicContentDto.Conent will be escaped. If the escapeContent
   * property returns false, html embedded in the DynamicContentDto.Conent will be rendered as html.
   * */
  public get tooltipText(): string {
    return this.helpText;
  }

  /**
  * Gets the help text from the DynamicContentDto with the help data.
  * */
  public get helpText(): string {
    return this._entry ? this._entry.Content : '';
  }

  /**
  * Gets the help title from the DynamicContentDto with the help data.
  * */
  public get title(): string {
    return this._entry ? this._entry.Description : '';
  }

  /**
   * Finds the DynamicContentDto matching this instance's major/minor key.
   * */
  private getEntry() {
    let entries = this.dynamicContentManagerService.getEntries();
    return _.find(entries,
      (entry: DynamicContentDto) => {
        return entry.MajorKey === this.majorKey &&
          entry.MinorKey === this.minorKey &&
          entry.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TOOLTIP &&
          entry.CategoryId === this.constantsService.DYNAMIC_CONTENT_CATEGORIES.HELP &&
          entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE
          && !entry.IsInactive
          && entry.Content.length > 0;
      });
  }
}

