<ng-container>
  <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="item.visible && (!item.routerLink || item.items)" (mouseenter)="onMouseEnter()"
     (keydown.enter)="itemClick($event)" [attr.target]="item.target" [attr.tabindex]="0">
    <i [ngClass]="item.icon"></i>
    <span>{{item.label}}</span>
    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    <span class="menuitem-badge" *ngIf="item.badge" [ngClass]="item.badgeStyleClass">{{item.badge}}</span>
  </a>
  <a (click)="itemClick($event)" (mouseenter)="onMouseEnter()" *ngIf="item.visible && (item.routerLink && !item.items)"
     [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
     [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
    <i [ngClass]="item.icon"></i>
    <span>{{item.label}}</span>
    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    <span class="menuitem-badge" *ngIf="item.badge" [ngClass]="item.badgeStyleClass">{{item.badge}}</span>
  </a>
  <ul *ngIf="item.visible && item.items && active" [@children]="(active ? 'visibleAnimated' : 'hiddenAnimated')">
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li menu-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    </ng-template>
  </ul>
</ng-container>
