<div class="p-grid">

  <!-- Heading -->
  <div class="p-col-12 reach-page-header">
    <div class="p-d-flex p-ai-center p-jc-between">

      <!-- Title -->
      <div>
        {{title}}
      </div>

      <div class="p-d-flex p-ai-center">
        <!-- Notifications -->
        <message-alert [hidden]="!isVisible" [messageScope]="messageScope" [notifications]="notifications"
          (click)="onToggle()"></message-alert>

        <!-- < Overview -->
        <button pButton pRipple type="button" class="p-button p-button-text p-button-secondary reach-button-muted-icon"
          label="Overview" icon="fa fa-level-up" iconPos="right" (click)="onOverview()"></button>
      </div>

    </div>
  </div>

  <!-- Notifications -->
  <div class="p-col-12" [hidden]="!showMessages || !isVisible">
    <p-messages [severity]="severity" [enableService]="false">
      <ng-template pTemplate>
        <div>
          <span class="reach-notification-list-header">Notifications</span>
          <ul>
            <li *ngFor="let notification of notifications">
              <span *ngIf="notification.Message">{{notification.Message}}</span>
            </li>
          </ul>
        </div>
      </ng-template>
    </p-messages>
  </div>

</div>