import { IBaseDto,
ICanRemoveItemFromCartResponseDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class CanRemoveItemFromCartResponseDto extends BaseDto implements IBaseDto, ICanRemoveItemFromCartResponseDto {
	Result: boolean;
	TransactionExistsConfirmationDoesNotExist: boolean;
	CartId?: number;
	CartItemId?: number;
	WebServiceHistoryId?: number;

}
