import { IDomainQuestionnaireDto,
IDomainReasonForChangeDtoHost,
IEntityInfoDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ISupervisionPlanSupervisorDto,
ISupervisorDto } from './licensure-shared-dto';

export class SupervisionPlanSupervisorDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisionPlanSupervisorDto {
	SupervisionPlanId?: number;
	SupervisorId?: number;
	StatusId?: number;
	SupervisorEntityInfo: IEntityInfoDto;
	Supervisor: ISupervisorDto;
	Status: any;
	StrongestQuestionnaire: IDomainQuestionnaireDto;
	DomainQuestionnaires: IDomainQuestionnaireDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
