<!--<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="#">Library</a></li>
    <li class="breadcrumb-item active" aria-current="page">Data</li>
  </ol>
</nav>-->
<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
  </li>
</ol>
