import {  } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IAutoVerificationSubscriptionDto,
IAutoVerificationSubscriptionTypeDto } from './licensure-shared-dto';

export class AutoVerificationSubscriptionDto extends BaseDto implements IAutoVerificationSubscriptionDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	AutoVerificationSubscriberId: number;
	StartDate?: Date;
	EndDate?: Date;
	TypeId: number;
	StatusId: number;
	SubscriptionType: IAutoVerificationSubscriptionTypeDto;

}
