import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, ReachHttpClientService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { IPreceptorVerificationRequestDto, IPreceptorVerificationResponseDto } from '../../board-providers/chiProvider/shared/chi-shared-dto';

@Injectable({ providedIn: 'root' })
export class PreceptorVerificationService extends ReachHttpClientService {
  uri: string = null;

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    utilitiesService: UtilitiesService,
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/PreceptorVerification", this.apiRootUri);
  }

  public verify(preceptorVerificationRequest: IPreceptorVerificationRequestDto): Observable<IPreceptorVerificationResponseDto> {
    return this.get<IPreceptorVerificationResponseDto>(`${this.uri}/verify`, { params: preceptorVerificationRequest as any });
  }

  public getByLicenseId(licenseId: number): Observable<IPreceptorVerificationResponseDto> {
    return this.get<IPreceptorVerificationResponseDto>(`${this.uri}/${licenseId}`);
  }
}
