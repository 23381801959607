import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';

import * as _l from 'lodash-es';

import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService, ForgotPasswordConstantsService, ForgotPasswordFeatureConfigurationService, OnlineServiceLinkManagerService,
  OnlineServiceMenuService,
  OnlineServiceProcessTypeKeys,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem, SystemSettingsManagerService, UserManagerService
} from '@core/core.module';

@Injectable({
  providedIn: 'root'
})
export class LicensureForgotPasswordFeatureConfigurationService extends ForgotPasswordFeatureConfigurationService {
  private licensureForgotPasswordConstantsService: ILicensureForgotPasswordConstantsService;
  private serviceRegistryItem: OnlineServiceRegistryItem;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    dynamicRoutesService: DynamicRoutesService,
    onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    onlineServiceMenuService: OnlineServiceMenuService,
    router: Router,
    userManagerService: UserManagerService,
    protected systemSettingsManagerService: SystemSettingsManagerService
  ) {
    super(
      constantsService,
      defaultProviderConfigurationService,
      dynamicRoutesService,
      onlineServiceLinkManagerService,
      onlineServiceMenuService,
      router,
      userManagerService,
      systemSettingsManagerService
    );

    this.licensureForgotPasswordConstantsService = this.constantsService as ILicensureForgotPasswordConstantsService;
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.ForgotPassword);
  }

  /**
  * Decorate the ConstantsService with UserRegistration-specific constants.
  */
  protected configureConstants() {
    super.configureConstants();
    ForgotPasswordConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS,
      LicensureForgotPasswordConstantsService.LicensureMergers.FEATURE_CONSTANTS);
    ForgotPasswordConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS,
      LicensureForgotPasswordConstantsService.LicensureMergers.SYSTEM_SETTING_KEYS);
    this.forgotPasswordConstantsService = this.constantsService as ILicensureForgotPasswordConstantsService;
  }

  protected configureDecorators() {
    super.configureDecorators();
  }

  /**
 * Configure dynamic ForgotPassword routes.
 * @param $routeProvider
 * @param routeConfigurationProvider
 * @param dynamicContentConfigurationProvider
 * @param authorizationConfigurationProvider
 * @param constantsServiceProvider
 */
  protected configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        loadChildren: () => import('src/app/core/features/forgotPasswordWizard/forgot-password-wizard-selection.module')
          .then(m => m.ForgotPasswordWizardSelectionModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        loadChildren: () => import('src/app/licensureCore/features/forgotPasswordWizard/licensure-forgot-password-wizard.module')
          .then(m => m.LicensureForgotPasswordWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class LicensureForgotPasswordConstantsService extends ForgotPasswordConstantsService {
  public static LicensureMergers = {
    SYSTEM_SETTING_KEYS: {
      FORGOT_PASSWORD_WIZARD_FEATURE_SYSTEM_SETTING_KEYS: {
        APPLICANT_ENABLED: "Feature.ForgotPassword.Applicant.Enabled",
        EDUCATION_PROGRAM_STAFF_ENABLED: "Feature.ForgotPassword.EducationProgramStaff.Enabled"
      }
    },

    FEATURE_CONSTANTS: {
      FORGOT_PASSWORD_WIZARD_FEATURE: {
        CREDENTIAL_TYPES: {
          LICENSEE: { NAME: "Licensee", PROCESS: 108 },
          APPLICANT: { NAME: "Applicant", PROCESS: 109 },
          EDUCATION_PROGRAM_STAFF: { NAME: "EducationProgramStaff", PROCESS: 111 }
        }
      }
    }
  };

  SYSTEM_SETTING_KEYS = ForgotPasswordConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
    LicensureForgotPasswordConstantsService.LicensureMergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ForgotPasswordConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
    LicensureForgotPasswordConstantsService.LicensureMergers.FEATURE_CONSTANTS);
}

export interface ILicensureForgotPasswordConstantsService extends LicensureForgotPasswordConstantsService {
}
