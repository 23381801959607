<div [formGroup]="questionForm" *ngIf="formReady">
  <div class="p-fluid p-formgrid p-grid p-field">

    <!-- Question Number -->
    <div class="p-col-fixed" *ngIf="!isQuestionNumberHidden">
      <strong>{{questionNumber}}</strong>
    </div>

    <div class="p-col p-grid p-formgrid">

      <!-- Question  -->
      <div class="p-col-12 p-sm-8 p-md-9 p-xl-10 p-field">
        <div [innerHTML]="questionText">></div>
      </div>

      <!-- ================================= Answer ================================= -->

      <!-- YesNo  -->
      <div class="p-col-12 p-sm-4 p-md-3 p-xl-2 p-field" *ngIf="answerControlType == questionTypes.YesNo">
        <yes-no-dropdown inputId="answerControlName" [formControlName]="answerControlName"></yes-no-dropdown>
      </div>

      <!-- Numeric  -->
      <div class="p-col-12 p-sm-4 p-md-3 p-xl-2 p-field" *ngIf="answerControlType == questionTypes.Numeric">
        <input inputId="answerControlName" [formControlName]="answerControlName" type="number" pInputText>
      </div>

      <!-- Text  -->
      <div class="p-col-12 p-sm-4 p-md-3 p-xl-2 p-field" *ngIf="answerControlType == questionTypes.Text">
        <input inputId="answerControlName" [formControlName]="answerControlName" pInputText>
      </div>

      <!-- Checkbox  -->
      <div class="p-col-12 p-sm-4 p-md-3 p-xl-2 p-field" *ngIf="answerControlType == questionTypes.Checkbox">
        <p-checkbox inputId="answerControlName" [formControl]="answerControl" binary="true"></p-checkbox>
      </div>
    </div>

    <!-- TextArea -->
    <div class="p-col-12 p-field" *ngIf="answerControlType == questionTypes.TextArea">
      <textarea id="answerControlName" [formControlName]="answerControlName" [rows]="5" style="width:100%" pInputTextarea
        autoResize="autoResize"></textarea>
    </div>

  </div>

</div>