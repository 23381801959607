<div class="p-fluid p-grid p-nogutter" [formGroup]="contentForm">
  
  <!-- Header -->
  <div class="p-col-12 reach-section-header" *ngIf="config?.headerText">{{config?.headerText}}</div>

  <!-- Dynamic Content -->
  <div class="p-col-12" *ngIf="config?.majorKey && config?.minorKey">
    <page-summary-text [majorKey]="config?.majorKey" [minorKey]="config?.minorKey"></page-summary-text>
  </div>

  <!-- Input -->
  <div *ngFor="let item of filteredDomainIdentifiers" class="p-field p-col-12 p-sm-6 p-xl-3 p-pr-sm-2">
    <label for="item{{item.Id}}">{{item.DomainIdentifierType.Description}}</label>
    <input id="item{{item.Id}}" type="text" formControlName="{{item.DomainIdentifierType.Description}}" [readonly]="!item.DomainIdentifierType.EditableOnline" pInputText>
  </div>

</div>
