<!-- Form Group -->
<div class="p-fluid" [formGroup]="contentForm">

  <!-- Grid -->
  <div class="p-grid p-formgrid" *ngIf="isDate">

    <!-- From -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="From">From</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="From" formControlName="From"
        [showIcon]="false" yearNavigator="true" monthNavigator="true" [yearRange]="yearRange" [defaultDate]=""
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>

    <!-- To -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="To">To</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="To" formControlName="To" [showIcon]="false"
        yearNavigator="true" monthNavigator="true" [yearRange]="yearRange" [defaultDate]="" appendTo="body"
        baseZIndex="1">
      </p-calendar>
    </div>

  </div>

  <!-- Grid -->
  <div class="p-grid p-formgrid" *ngIf="isNumber">

    <!-- From -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="From">From</label>
      <input inputId="From" formControlName="From" type="number" pInputText>
    </div>

    <!-- To -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="To">To</label>
      <input inputId="To" formControlName="To" type="number" pInputText>
    </div>

  </div>

</div>