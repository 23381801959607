import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Command, WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, CommandService, ConstantsService, OnlineServiceHistoryService, ValidationManagerService } from 'src/app/core/index-services';
import { CvaHostWizardStepComponent } from '../../wizard/wizard-step.component';

export const ReviewComponentSelector: string = 'review';
export const ReviewComponentKey: string = 'review';

@Component({
  selector: ReviewComponentSelector,
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent extends CvaHostWizardStepComponent implements OnInit, AfterViewChecked {

  confirmationDocumentCommand: Command;
  showReferenceNumber: boolean;
  public get referenceNumber(): string { return this.wizard.model.ReferenceNumber; }

  constructor(checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , changeDetectorRef: ChangeDetectorRef
    , private commandService: CommandService
    , private onlineServiceHistoryService: OnlineServiceHistoryService
  ) {
    super(constantsService, validationManagerService, wizardInjector, ReviewComponent.name, checkboxContentService, elementRef, changeDetectorRef);
    this.modelToForm();
  }

  ngOnInit(): void {

    this.showReferenceNumber = this.wizard.selectedStep?.Parameters?.["ShowReferenceNumber"] != "false";

    this.confirmationDocumentCommand = this.commandService.create(() => true, this.downloadCommandExecute);
    this.friendlyNames.ReviewConfirmation = 'Affirmation Statement';

    super.ngOnInit();
  }

  protected modelToForm() {
    this.wizard.selectedStep.bag.addOrReplaceItem("reference", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.referenceNumber]);
    super.modelToForm();
  }

  protected downloadCommandExecute = () => {
    this.onlineServiceHistoryService.downloadConfirmationDocument(this.wizard.onlineServiceHistory.ConfirmationDocumentId);
  }
}

// Register component for dynamic loading
// Example: Add the following with first parameter (specific scenario) in each wizard that requires this step
// registerDynamicComponent({Wizard Scenario Here}, ReviewComponentKey, ReviewComponent, ReviewComponentSelector);
