import { Injectable, Inject } from "@angular/core";
import { from, Subscription } from "rxjs";
import { Routes, Router } from '@angular/router';
import * as _ from 'underscore';
import * as _l from 'lodash-es';
import {
  AuthGuard,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  LandingPageDetailRouteResolverService,
  LandingPageService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryComponentInfo,
  SummaryAggregationType,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  UserManagerService,
  WizardService,
  WizardSearchCriteriaService,
  WizardTagsService
} from '@core/core.module';

import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
export const EducationProgramLandingCardComponentSelector: string = 'education-program-landing-card';

@Injectable({
  providedIn: 'root'
})
export class EducationProgramCompletionFeatureConfigurationService {
  private isInitialized = false;
  private educationProgramCompletionServiceRegistryItem: OnlineServiceRegistryItem;
  private complianceServiceRegistryItem: OnlineServiceRegistryItem;
  private educationProgramConstantsService: IEducationProgramConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private functionTypeService: FunctionTypeService
    , private landingPageService: LandingPageService
    , private router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private userManagerService: UserManagerService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService) {

    this.educationProgramCompletionServiceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.EducationProgramCompletion);
    this.complianceServiceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.EducationProgramAnnualCompliance);
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(() => this.onLandingPageReconfig());
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, EducationProgramConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, EducationProgramConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, EducationProgramConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, EducationProgramConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, EducationProgramConstantsService.Mergers.VALIDATION_MODES);
    this.educationProgramConstantsService = this.constantsService as IEducationProgramConstantsService;
  }

  private addInfosToLandingPage(principal) {
    let isFeatureEnabled = true;
    if (!this.isInitialized) {
      this.initialize();
    }

    if (isFeatureEnabled &&
      principal?.user?.UserAccount?.profile?.EducationProgramApprovals &&
      principal.user.UserAccount.profile.EducationProgramApprovals.length > 0) {

      let summaryDetailConfiguration = new SummaryDetailConfiguration(
        LicensureLandingCategories.EducationProgram,
        LicensureLandingComponentKeys.EducationProgramSummaryLazy,
        LicensureLandingComponentKeys.EducationProgramDetailLazy,
        SummaryAggregationType.Singleton);
      let infos: SummaryComponentInfo[] = [];
      let infoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Education Programs", summaryDetailConfiguration);
      principal.user.UserAccount.profile.EducationProgramApprovals.forEach((educationProgramApproval, index: number) => {
        let programTitle = educationProgramApproval.EducationProgram.Entity.LastName;
        infoBuilder.addItem(
          index,
          programTitle,
          educationProgramApproval,
          this.educationProgramConstantsService.DOMAIN_IDS.EDUCATION_PROGRAM
        );
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.educationProgramCompletionServiceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/educationProgramCompletion/education-program-completion-wizard.module')
          .then(m => m.EducationProgramCompletionWizardModule)
      },
      {
        path: this.educationProgramCompletionServiceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/educationProgramCompletion/education-program-completion-wizard.module')
          .then(m => m.EducationProgramCompletionWizardModule)
      },
      {
        path: this.complianceServiceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/annualCompliance/education-program-annual-compliance-wizard.module')
          .then(m => m.EducationProgramAnnualComplianceWizardModule)
      },
      {
        path: this.complianceServiceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/annualCompliance/education-program-annual-compliance-wizard.module')
          .then(m => m.EducationProgramAnnualComplianceWizardModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.EducationProgramDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }


  public getComplianceWizard = (complianceId, webServiceHistoryId: number) => {
    let complianceConstantsService = this.constantsService as IEducationProgramConstantsService;
    let functionType = this.functionTypeService.create(complianceConstantsService.FUNCTION_TYPES.EDUCATION_PROGRAM_ANNUAL_COMPLIANCE, complianceId);
    let wizardTags = this.wizardTagsService.create(null, null, null, null, null, null, null);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.educationProgramConstantsService.WEB_SERVICE_PROCESS_TYPES.EDUCATION_PROGRAM_ANNUAL_COMPLIANCE, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };
}

export class EducationProgramConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      EDUCATION_PROGRAM_COMPLETION_WIZARD: "EducationProgramCompletion.Wizard",
      EDUCATION_PROGRAM_ANNUAL_COMPLIANCE_WIZARD: "EducationProgramAnnualCompliance.Wizard"
    },
    DOMAIN_IDS: {
      EDUCATION_PROGRAM: 56,
      EDUCATION_PROGRAM_ANNUAL_COMPLIANCE: 60
    },
    WEB_SERVICE_PROCESS_TYPES: {
      EDUCATION_PROGRAM_COMPLETION_WIZARD: LicensureOnlineServiceProcessTypeKeys.EducationProgramCompletion,
      EDUCATION_PROGRAM_ANNUAL_COMPLIANCE: LicensureOnlineServiceProcessTypeKeys.EducationProgramAnnualCompliance
    },
    FUNCTION_TYPES: {
      COMPLIANCE: 15,
      EDUCATION_PROGRAM_ANNUAL_COMPLIANCE: 41
    },
    VALIDATION_MODES: {
      EDUCATION_PROGRAM_ANNUAL_COMPLIANCE: {
        NEW_COMPLIANCE_CONTACT_INFO: 'Web.NewCompliance.ContactInfo'
      }
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), EducationProgramConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), EducationProgramConstantsService.Mergers.DOMAIN_IDS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), EducationProgramConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), EducationProgramConstantsService.Mergers.FUNCTION_TYPES);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), EducationProgramConstantsService.Mergers.VALIDATION_MODES);
}
export interface IEducationProgramConstantsService extends EducationProgramConstantsService { }
