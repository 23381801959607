import { IDomainReasonForChangeDtoHost,
IFunctionTypeDto,
IRelatedToBaseDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class RelatedToBaseDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IRelatedToBaseDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	RelatedToDescription: string;
	FunctionType: IFunctionTypeDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
