import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _l from "lodash-es";
import { Observable, of } from 'rxjs';
import { Command, DialogSettings, registerDynamicComponent } from 'src/app/core/index-models';
import { CommandService, UtilitiesService } from 'src/app/core/index-services';
import { IDomainChecklistDtoHost, IDomainChecklistItemDto } from 'src/app/coreShared/dto-gen/core-shared-dto';
import { ReachScenarios } from '../../index-constants';
import { ChecklistFileUploadItemsEditorComponent, ChecklistFileUploadItemsEditorComponentKey } from './editor/checklist-file-upload-items-editor.component';

export const ChecklistFileUploadItemsComponentSelector: string = 'checklist-file-upload-items';
export const ChecklistFileUploadItemsComponentKey: string = 'ChecklistFileUploadItems';

@Component({
  selector: ChecklistFileUploadItemsComponentSelector,
  templateUrl: './checklist-file-upload-items.component.html',
  styleUrls: ['./checklist-file-upload-items.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChecklistFileUploadItemsComponent),
      multi: true
    }
  ]
})
export class ChecklistFileUploadItemsComponent implements OnInit {

  @Input() uploadableChecklistItemIds: number[] = [];
  @Input() domainChecklistDtoHost: IDomainChecklistDtoHost;

  private okCommand: Command;
  uploadCommand: Command;

  dialogSettings: DialogSettings;
  filteredChecklistItems: IDomainChecklistItemDto[] = [];
  contentForm: FormGroup = new FormGroup({});
  selectedDomainChecklistItemDto: IDomainChecklistItemDto;

  constructor(protected commandService: CommandService, protected utilitiesService: UtilitiesService) {
  }

  ngOnInit(): void {

    this.filteredChecklistItems = (this.domainChecklistDtoHost.DomainChecklist as IDomainChecklistItemDto[])
      .filter(cli => this.uploadableChecklistItemIds.some(uploadableId => cli.ChecklistItemTemplateId == uploadableId));

    this.uploadCommand = this.commandService.create(this.canUploadCommandExecute, this.uploadCommandExecute);
    this.okCommand = this.commandService.create(() => true, this.okCommandExecute);
  }

  public getIcon(dto: IDomainChecklistItemDto): string {
    return (dto.Documents.length || dto.UploadCorrespondenceId) ? "fa fa-check" : "fa fa-upload";
  }

  public getToolTip(dto: IDomainChecklistItemDto): string {
    return dto.UploadCorrespondenceId ? "Document submitted" : "Upload a document related to this checklist item";
  }

  protected canUploadCommandExecute = (dto: IDomainChecklistItemDto): boolean => {
    return !dto.UploadCorrespondenceId || dto.UploadCorrespondenceId == 0;
  }

  protected uploadCommandExecute = (dto: IDomainChecklistItemDto): Observable<any> => {

    this.selectedDomainChecklistItemDto = dto;

    this.dialogSettings = new DialogSettings(
      null, // Component instance
      ReachScenarios.Default, // Scenario key
      ChecklistFileUploadItemsEditorComponent, // Content type
      ChecklistFileUploadItemsEditorComponentKey, // Component type key
      "Upload Documents", // Title
      _l.cloneDeep(dto), // Model
      null, // OK command does not close dialog
      true); // Use model reference

    this.dialogSettings.okCommand = this.okCommand;
    this.dialogSettings.isOpen = true;

    return of(dto);
  }

  protected okCommandExecute = (fileUploadDtoHost: IDomainChecklistItemDto) => {
    fileUploadDtoHost.IsDirty = true;
    fileUploadDtoHost.CreatedDate = new Date(); // For viewing purposes before the item is saved.

    fileUploadDtoHost.Documents.forEach(element => {
      this.selectedDomainChecklistItemDto.Documents = this.utilitiesService.addOrReplace(this.selectedDomainChecklistItemDto.Documents, element);
    });
  }

  /**
   * Call from ChecklistFileUploadComponent once checklistHost.DomainChecklist has been updated.
   */
  public refresh(uploadableChecklistItemIds: number[]): void {
    this.filteredChecklistItems = (this.domainChecklistDtoHost.DomainChecklist as IDomainChecklistItemDto[])
      .filter(cli => 
        uploadableChecklistItemIds.some(uploadableId => cli.ChecklistItemTemplateId == uploadableId) &&
        cli.ChecklistItemTemplate.AllowOnlineUpload);
  }

}

registerDynamicComponent(ReachScenarios.Default, ChecklistFileUploadItemsComponentKey, ChecklistFileUploadItemsComponent, ChecklistFileUploadItemsComponentSelector);