import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  Router
} from '@angular/router';
import { from, of } from 'rxjs';

import { FormGroup } from '@angular/forms';
import {
  CONSTANTS_SERVICE_TOKEN,
  CartManagerService,
  Command, CommandService,
  ConstantsService,
  DynamicContentManagerService,
  LandingComponentKeys,
  RouteInfoRegistry,
  UserManagerService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { ICanSubmitCartResponseDto } from 'src/app/coreShared/core-shared.module';
import { de } from 'date-fns/locale';

enum WebTransactionCreationStrategy {
  None = "none",
  Message = "message",
  MessageWithCheckbox = "messagewithcheckbox",
  MessageAndBlock = "messageandblock",
}

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, AfterViewChecked {

  public cartForm: FormGroup;
  public dataLoaded: boolean;
  public proceedToPaymentCommand: Command;
  public removeItemCommand: Command;
  public canSubmitCartResponse: ICanSubmitCartResponseDto;
  private friendlyNames: any = {};

  constructor(public cartManagerService: CartManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private commandService: CommandService
    , private router: Router
    , private userManagerService: UserManagerService
    , private utilitiesService: UtilitiesService
    , private confirmationService: ConfirmationService
    , private dynamicContentManager: DynamicContentManagerService
    , private validationManagerService: ValidationManagerService
    , protected changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.cartForm = new FormGroup({});
    this.cartForm.valueChanges.subscribe(() => {
      this.validationManagerService.addFormErrors(this.cartForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames);
    });
    this.initCommands();

    // Lets ensure this cart can be submitted.
    this.cartManagerService.canSubmitCart(this.cartManagerService.cart)
      .subscribe((response: ICanSubmitCartResponseDto) => {
        this.canSubmitCartResponse = response;
        this.dataLoaded = true;
      });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
    this.validationManagerService.addFormErrors(this.cartForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames);
  }

  private initCommands() {
    this.proceedToPaymentCommand = this.commandService.create(this.canProceedToPaymentCommandExecute, this.proceedToPaymentCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  public showProceedToPaymentButton(): boolean {

    // The only time we hide the button is if the block strategy is in use and the result is false.
    const buttonHidden = this.canSubmitCartResponse?.WebTransactionCreationStrategy?.toLowerCase() === WebTransactionCreationStrategy.MessageAndBlock && this.canSubmitCartResponse?.Result == false;
    return !this.cartManagerService.cartEmpty && !buttonHidden;
  }

  public getProceedToPaymentLabel(): string {
    return this.cartManagerService.cartHasZeroBalance ? "Finish" : "Proceed to Payment";
  }

  public shouldDisplayCanCartSubmitSummary(): boolean {
    return !this.cartManagerService.cartEmpty
      && this.canSubmitCartResponse
      && !this.canSubmitCartResponse?.Result;
  }

  public showMessageWithCheckboxAcknowledgement(): boolean {
    return !this.cartManagerService.cartEmpty
      && this.canSubmitCartResponse
      && this.canSubmitCartResponse?.WebTransactionCreationStrategy?.toLowerCase() === WebTransactionCreationStrategy.MessageWithCheckbox
      && !this.canSubmitCartResponse?.Result;
  }

  protected canProceedToPaymentCommandExecute = (): boolean => {
    return true;
  }

  protected async handleNoneStrategy() {
    await this.submitCart();
  }

  protected async handleMessageWithCheckboxStrategy() {
    if (this.canSubmitCartResponse.Result) {
      await this.submitCart();
      return;
    }

    if (this.validationManagerService.hasClientApplicationValidationMessages || this.cartForm.invalid) {
      this.validationManagerService.addFormErrors(this.cartForm, this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION, this.friendlyNames);
      this.validationManagerService.showApplicationValidationSummary();
      return;
    }

    await this.submitCart();
  }

  protected async handleMessageAndBlockStrategy() {
    if (!this.canSubmitCartResponse.Result) {
      return;
    }

    await this.submitCart();
  }

  protected async handleMessageStrategy() {
    if (this.canSubmitCartResponse.Result) {
      await this.submitCart();
      return;
    }

    const entry = this.dynamicContentManager.getEntries().find(x => x.MinorKey === this.canSubmitCartResponse.MinorKey);
    this.confirmationService.confirm({
      message: entry.Content,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',
      accept: async () => {
        await this.submitCart();
      }
    });
  }


  protected proceedToPaymentCommandExecute = () => {
    const doSubmit = async (): Promise<any> => {
      const strategy = this.canSubmitCartResponse.WebTransactionCreationStrategy.toLowerCase();
      switch (strategy) {
        case WebTransactionCreationStrategy.None:
          await this.handleNoneStrategy();
          break;
        case WebTransactionCreationStrategy.Message:
          await this.handleMessageStrategy();
          break;
        case WebTransactionCreationStrategy.MessageWithCheckbox:
          await this.handleMessageWithCheckboxStrategy();
          break;
        case WebTransactionCreationStrategy.MessageAndBlock:
          await this.handleMessageAndBlockStrategy();
          break;
        default:
          await this.handleNoneStrategy();
          break;
      }
    };

    return from(doSubmit());
  }

  protected submitCart = async (): Promise<any> => {
    let responseUrl = await this.cartManagerService.submitCart();
    if (responseUrl && !this.utilitiesService.responseHasValidationErrors(responseUrl)) {
      this.userManagerService.logout();
      window.location.href = responseUrl;
    } else {
      this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
    }
    return of(true).toPromise();
  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (item) => {
    const x = async (): Promise<any> => {
      let prompt = (await this.cartManagerService.canRemoveItem(item))?.TransactionExistsConfirmationDoesNotExist
        ? "If you recently completed payment for this item please leave it in the cart. The system will remove it once processed. Removing it may delay processing of your request. \n\nAre you sure you want to remove this item from your cart?"
        : "Are you sure you want to remove this item from your cart?";

      // Use the PrimeNG confirmation dialog.
      this.confirmationService.confirm({
        message: `${prompt}`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Ok',

        // If accepted...
        accept: async () => {
          await this.cartManagerService.removeCartItem(item);
        }
      });
    }

    from(x());
  }
}
