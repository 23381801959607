import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReachScenarios } from '@core/constants/reach-scenarios';
import { Command } from '@core/models/command';
import { DialogSettings } from '@core/models/dialog-settings';
import { CommandService } from '@coreServices/command.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import * as _l from 'lodash-es';
import { Observable } from 'rxjs';
import { EntityAddressDto } from 'src/app/coreShared';
import { IEntityAddressDto, IEntityAddressDtoHost } from 'src/app/coreShared/core-shared.module';
import { SystemSettingsManagerService, ValidationManagerService } from '../../index-services';
import { LandingPageEntityAddressEditorComponent } from './editor/landing-page-entity-address-editor.component';

@Component({
  selector: 'landing-page-entity-address',
  templateUrl: './landing-page-entity-address.component.html',
  styleUrls: ['./landing-page-entity-address.component.scss']
})
export class LandingPageEntityAddressComponent implements OnInit {

  @Input()
  addressDtoHost: IEntityAddressDtoHost;
  @Input()
  isOrganization: boolean = false;
  @Input()
  save: () => Observable<any>;

  presentItemEditorCommand: Command;
  mailingAndBusinessMode: boolean;
  dbpMode: boolean;
  dialogSettings: DialogSettings;
  addressEditable: boolean;
  orgPhysicalAddressLocationTypeId: number;

  public get dAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.IsPublic && address.IsCurrent && !address.IsDeleted) }
  public get bAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS && address.IsCurrent && !address.IsPublic); }
  public get pAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER && address.IsMailing && address.IsCurrent && !address.IsDeleted && !address.IsPublic); }
  public get orgPhysicalAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.orgPhysicalAddressLocationTypeId && address.IsPublic && address.IsCurrent); }
  public get orgMailingAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER && address.IsCurrent && !address.IsPublic); }

  constructor(
    private systemSettingsManagerService: SystemSettingsManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) public constantsService: ConstantsService,
    private commandService: CommandService,
    private validationManagerService: ValidationManagerService) {

    this.orgPhysicalAddressLocationTypeId = this.systemSettingsManagerService.asInt(this.constantsService.SYSTEM_SETTING_KEYS.ONLINE_LANDING.LICENSE.ORG_PHYSICAL_ADDRESS_LOCATION_TYPE_ID);
    this.addressEditable = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.ONLINE_LANDING.LICENSE.ADDRESS_EDITABLE);
    this.mailingAndBusinessMode = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.ENTITY_ADDRESS_DIRECTIVE_TYPE) == "mailingAndBusiness";
    this.dbpMode = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.ENTITY_ADDRESS_DIRECTIVE_TYPE) == "designatedBusinessPrivate";

  }

  ngOnInit(): void {
    this.initCommands();
  }

  // PROPERTIES
  public get hasNoBusinessAddress(): boolean {
    return (this.addressDtoHost as any).IsNotInWorkforce;
  }
  public get businessAddress(): IEntityAddressDto {

    if (!this.addressDtoHost?.Addresses) return null;

    let businessAddress = this.addressDtoHost.Addresses.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS && address.IsCurrent);
    return businessAddress;
  }
  public get mailingAddress(): IEntityAddressDto {

    if (!this.addressDtoHost?.Addresses) return null;

    let mailingAddress = this.addressDtoHost.Addresses.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.HOME && address.IsCurrent);
    return mailingAddress;
  }
  public get filteredAddressCollection(): IEntityAddressDto[] {

    if (!this.addressDtoHost?.Addresses) return [];

    let filteredAddresses: IEntityAddressDto[] = _l.filter(this.addressDtoHost.Addresses,
      (address: EntityAddressDto) => {
        return (!this.addressDtoHost.AddressTypeId || (address.AddressType.Id == this.addressDtoHost.AddressTypeId)) &&
          address.FunctionNumber === this.addressDtoHost.FunctionNumber &&
          !address.IsDeleted;
      });

    return filteredAddresses;
  }

  /**
  * Initializes commands for this instance.
  */
  private initCommands() {

    // Confirmation document command.
    this.presentItemEditorCommand = this.commandService.create(this.canPresentItemEditorCommandExecute, this.presentItemEditorCommandExecute);
  }

  protected canPresentItemEditorCommandExecute = (): boolean => {
    return this.addressEditable;
  }

  protected presentItemEditorCommandExecute = () => {

    // Define a custom OK command to be passed along to the dialog.
    let okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);

    // Create dialog settings.
    // Dialog settings represent a new modal dialog instance.
    this.dialogSettings = new DialogSettings(
      null, // Component instance
      ReachScenarios.Default, // Scenario key
      LandingPageEntityAddressEditorComponent, // Content type
      'LandingPageEntityAddressEditorComponent', // Content key
      "Addresses", // Title
      _l.cloneDeep(this.addressDtoHost), // Model
      null, // OK command does not close dialog
      true); // Use model reference

    // Hand the editor the okCommand we created earlier.
    // Present the editor dialog.
    this.dialogSettings.okCommand = okCommand;
    this.dialogSettings.isOpen = true;
  }

  /**
  * Allow the ok command to execute?
  */
  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  /**
   * The command called from the 'OK' button on the dialog.
   */
  protected okCommandExecute = (output: IEntityAddressDtoHost) => {

    // Add or replace the incoming item in the collection.
    this.addressDtoHost.Addresses = output.Addresses;
    (this.addressDtoHost as any).IsNotInWorkforce = (output as any).IsNotInWorkforce;
    (this.addressDtoHost as any).IsDirty = true;

    // Clear errors so we don't get blocked if we previously got a server-side validation error.
    (this.addressDtoHost as any).Errors = [];
    (this.addressDtoHost as any).IsValid = true;
    this.validationManagerService.clearApplicationValidationErrors();

    this.save();
  }

}
