import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe transforms a PhoneNumber object into a formatted phone number string.
 */
@Pipe({
    name: 'displayPhoneNumber'
})
export class DisplayPhoneNumberPipe {
  /**
   * Transforms the PhoneNumber object passed in as the value param into a formatted phone# string.
   * @param value
   */
  transform(phone): string {
    var formattedPhoneNumber = "";

    if (phone) {
        formattedPhoneNumber = phone.Number;

        if (phone.Extension) {
            formattedPhoneNumber += " ext" + phone.Extension;
      }
    }

    return formattedPhoneNumber;
  }
}
