import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe transforms a Address object into a formatted city-state string.
 */
@Pipe({
    name: 'displayCitySt'
})
export class DisplayCityStatePipe {
  /**
   * Transforms the Address object passed in as the value param into a formatted city-state string.
   * @param value
   */
    transform(address): string {
    var formattedCitySt = "";

    if (!address) {
      return "";
    }

    if (address.City) {
        formattedCitySt += address.City;
    }

    if (address.State) {
        formattedCitySt += ", " + address.State;
    }

    return formattedCitySt;
  }
}
