import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, EMPTY, of } from "rxjs";
import { catchError, map, finalize } from "rxjs/operators";

import { IHttpResponseMessage, ValidationFailureDto, ValidatableDto } from '@coreShared/core-shared.module';

import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ValidationManagerService } from './validation-manager.service';

/**
 * Base data service class with methods that wrap HttpClient calls to strip the HttpResponseMessage wrapper from Reach API response payloads and return the actual
 * Dto data from the HttpResponseMessage.Content property. The wrapped HttpClient calls also invoke the BusyManagerService to add an item to the busy stack while
 * the call is in progress.
 *
 * Note: the same code that extracts the HttpResponseMessage.Content and invokes the BusyManagerService could also be hosted in an HttpInterceptor. That would
 * allow a single implementation of that functionality to apply to all calls (and http verb or overload) that return an HttpResponseMessage. The
 * disadvantage is that certain HtpClient operations (e.g., switchMap) might strand the busy stack in the busy state, and transformation of the response payload
 * could break http handling of other interceptors in the stack (so maintaining the correct order is crucial) and cause issues that may initially elude devs
 * who are unaware the interceptor is in the pipeline. Also, interceptors are a bit quirkier in regards to injection of other services (dependency order/timing).
 * So for the time being, we settled on the approach of hosting this functionality in a data service base class in methods that wrap the HttpClient calls.
 */
@Injectable({
  providedIn: 'root'
})
export class ReachHttpClientService {
  constructor(
    protected busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
    protected http: HttpClient,
    protected validationManagerService: ValidationManagerService
  ) {
  }

  /**
   * Gets the configured root Uri for API services.
   * @returns {} 
   */
  public get apiRootUri() {
    return this.defaultProviderConfigurationService.apiRootUri;
  }

  /**
   * Wraps the HttpClient.get<TResponse>(url) method to pipe map the Content property from the HttpResponseMessage returned as the HttpResponse.body.
   * @param url the url of the target endpoint.
   * @returns the Observable<TResponse> with the result.
   */
  public get<TResponse>(
    url: string,
    options?: { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: "body"; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: "json"; withCredentials?: boolean; }
  ): Observable<TResponse> {
    let handle = this.busyManagerService.push(this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND);
    let response = this.http.get<IHttpResponseMessage<TResponse>>(url, options)
      .pipe(
        map(res => (res as IHttpResponseMessage<TResponse>).Content),
        finalize(() => {
          this.busyManagerService.pop(handle);
        }));

    return response;
  }

  /**
   * Wraps the HttpClient.post<TResponse>(url, body) method to pipe map the Content property from the HttpResponseMessage returned as the HttpResponse.body.
   * @param url the url of the target endpoint.
   * @param body the request body.
   * @returns the Observable<TResponse> with the result.
   */
  public post<TResponse>(
    url: string,
    body: any,
    validationErrorScope?: string,
    options?: { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: "body"; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: "json"; withCredentials?: boolean; }
  ): Observable<TResponse> {
    //options = options ? options : { observe: "body", responseType: "json" };
    let handle = this.busyManagerService.push(this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND);
    return this.http.post<IHttpResponseMessage<TResponse>>(url, body, options)
      .pipe(
        map(res => (res as IHttpResponseMessage<TResponse>).Content),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error, validationErrorScope);
        }),
        finalize(() => {
          this.busyManagerService.pop(handle);
        }));
  }

  /**
   * Wraps the HttpClient.put<TResponse>(url, body) method to pipe map the Content property from the HttpResponseMessage returned as the HttpResponse.body.
   * @param url the url of the target endpoint.
   * @param body the request body.
   * @returns the Observable<TResponse> with the result.
   */
  public put<TResponse>(url: string
    , body: any
    , validationErrorScope?: string
    , options?: { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: "body"; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: "json"; withCredentials?: boolean; }): Observable<TResponse> {

    let handle = this.busyManagerService.push(this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND);
    return this.http.put<IHttpResponseMessage<TResponse>>(url, body, options)
      .pipe(map(res => (res as IHttpResponseMessage<TResponse>).Content),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error, validationErrorScope);
        }),
        finalize(() => {
          this.busyManagerService.pop(handle);
        }));
  }

  /**
   * Wraps the HttpClient.delete<TResponse>(url, body) method to pipe map the Content property from the HttpResponseMessage returned as the HttpResponse.body.
   * @param url the url of the target endpoint.
   * @returns the Observable<TResponse> with the result.
   */
  public delete<TResponse>(
    url: string,
    options?: { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: "body"; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: "json"; withCredentials?: boolean; }
  ): Observable<TResponse> {
    let handle = this.busyManagerService.push(this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND);
    return this.http.delete<IHttpResponseMessage<TResponse>>(url)
      .pipe(
        map(res => (res as IHttpResponseMessage<TResponse>).Content),
        finalize(() => {
          this.busyManagerService.pop(handle);
        }));
  }

  handleError(response: HttpErrorResponse, validationErrorScope?: string): Observable<any | never> {
    if (response.status === 400
      && response.error
      && response.error.Content
      && this.handleServerValidationErrors(response.error.Content, validationErrorScope)) {
      return of(response.error.Content);
    }

    return throwError(response);
  }

  protected handleServerValidationErrors(response, validationErrorScope?: string): boolean {
    let failures: ValidationFailureDto[] = [];
    if (response && response.hasOwnProperty("Errors") && response.Errors && response.Errors.length > 0) {
      failures.push(...response.Errors);
    }
    if (response && response.hasOwnProperty("AdditionalErrors") && response.AdditionalErrors && response.AdditionalErrors.length > 0) {
      failures.push(...response.AdditionalErrors);
    }

    let contextKey = validationErrorScope ? validationErrorScope : this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.validationManagerService.clearServerValidationMessagesForKey(contextKey);
    this.validationManagerService.addServerValidationFailuresAsync(failures, contextKey);
    return failures.length > 0;
  }
}
