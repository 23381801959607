import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";

import {
  BusyManagerService, ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DocumentService, ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import { IFileUploadDtoHost, IOnlineUserDto, SearchResultListDto } from '@coreShared/core-shared.module';
import {
  ISupervisionHoursTypeDto,
  ISupervisionPlanDto,
  ISupervisionPlanFormDto,
  ISupervisionSummaryDto,
  ISupervisionVerificationDto,
  ISupervisionVerificationFormDto,
  ISupervisorDesignationDto,
  ISupervisorDesignationFormDto,
  ISupervisorDesignationTypeDto,
  ISupervisorDto,
  ISupervisorSearchCriteriaDto,
  ISupervisorSearchResultItemDto
} from '@licensureShared/licensure-shared.module';
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SupervisionService extends ReachHttpClientService {
  uri: string;
  supervisorListUri: string;
  formTypeListUri: string;
  supervisedPlanUri: string;
  verificationUri: string;
  verificationFormUri: string;
  supervisionHoursUri: string;
  supervisorUri: string;
  supervisorDesignationUri: string;
  supervisorDesignationFormUri: string;
  supervisorDesignationTypeByFormIdUri: string;
  supervisorDesignationTypeByIdUri: string;

  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , private utilitiesService: UtilitiesService
    , private documentService: DocumentService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = `${this.apiRootUri}/licensure/SupervisionPlan`;
    this.supervisorListUri = `${this.apiRootUri}/licensure/SupervisorSearch`;
    this.formTypeListUri = `${this.apiRootUri}/licensure/SupervisionPlanForm`;
    this.supervisedPlanUri = `${this.apiRootUri}/licensure/Supervisor/SupervisionPlan`;
    this.verificationUri = `${this.apiRootUri}/licensure/SupervisionVerification`;
    this.verificationFormUri = `${this.apiRootUri}/licensure/SupervisionVerificationForm`;
    this.supervisionHoursUri = `${this.apiRootUri}/licensure/SupervisionHoursType`;
    this.supervisorUri = `${this.apiRootUri}/licensure/supervisor`;
    this.supervisorDesignationUri = `${this.apiRootUri}/licensure/supervisorDesignation`;
    this.supervisorDesignationTypeByFormIdUri = `${this.apiRootUri}/licensure/SupervisorDesignationType/form`;
    this.supervisorDesignationTypeByIdUri = `${this.apiRootUri}/licensure/SupervisorDesignationType`;
    this.supervisorDesignationFormUri = `${this.apiRootUri}/licensure/SupervisorDesignationForm`;

  }

  public getSuperviseeFromSupervisionPlan(supervisorEntityId: number, supervisionPlanId: number, superviseeEntityId: number): Observable<IOnlineUserDto> {
    return this.get<IOnlineUserDto>(`${this.apiRootUri}/licensure/Supervisor/${supervisorEntityId}/SupervisionPlan/${supervisionPlanId}/Supervisee/${superviseeEntityId}`);
  }

  public getSuperviseeFromSupervisionVerification(supervisorEntityId: number, supervisionVerificationId: number, superviseeEntityId: number): Observable<IOnlineUserDto> {
    return this.get<IOnlineUserDto>(`${this.apiRootUri}/licensure/Supervisor/${supervisorEntityId}/SupervisionVerification/${supervisionVerificationId}/Supervisee/${superviseeEntityId}`);
  }

  public getSupervisorById(supervisorId: number): Observable<ISupervisorDto> {
    return this.get<ISupervisorDto>(`${this.apiRootUri}/licensure/supervisor/${supervisorId}`);
  }

  public getSupervisionHoursTypes(): Observable<ISupervisionHoursTypeDto[]> {
    return this.get<ISupervisionHoursTypeDto[]>(this.supervisionHoursUri);
  }

  public getSupervisionPlanById(id: number): Observable<ISupervisionPlanDto> {
    return this.get<ISupervisionPlanDto>(`${this.uri}/${id}`);
  }

  public getSupervisionVerificationFormTypes(): Observable<ISupervisionVerificationFormDto[]> {
    return this.get<ISupervisionVerificationFormDto[]>(this.verificationFormUri);
  }

  public getSupervisionPlanFormTypes(): Observable<ISupervisionPlanFormDto[]> {
    return this.get<ISupervisionPlanFormDto[]>(this.formTypeListUri);
  }

  public getSupervisedSupervisionPlanById(id: number): Observable<ISupervisionPlanDto> {
    return this.get<ISupervisionPlanDto>(`${this.supervisedPlanUri}/${id}`);
  }

  public getSupervisionVerificationById(id: number): Observable<ISupervisionVerificationDto> {
    return this.get<ISupervisionVerificationDto>(`${this.verificationUri}/${id}`);
  }

  public saveSupervisionPlan(supervisionPlan: ISupervisionPlanDto): Observable<ISupervisionPlanDto> {
    if (supervisionPlan.Id) {
      return this.put<ISupervisionPlanDto>(this.uri, supervisionPlan);
    } else {
      return this.post<ISupervisionPlanDto>(this.uri, supervisionPlan);
    }
  }

  public saveSupervisor(supervisor: ISupervisorDto): Observable<ISupervisorDto> {
    return from(this.documentService.uploadDocuments(supervisor))
      .pipe(

        map((fileUploadHost: IFileUploadDtoHost) => {
          return fileUploadHost as ISupervisorDto;
        }),

        switchMap((s: ISupervisorDto) => {
          if (!s.IsValid) {
            return of(supervisor);
          } else {
            return supervisor.Id
              ? this.put<ISupervisorDto>(this.supervisorUri, supervisor)
              : this.post<ISupervisorDto>(this.supervisorUri, supervisor);
          }
        })
      )

  }

  public saveSupervisionVerification(supervisionVerification: ISupervisionVerificationDto): Observable<ISupervisionVerificationDto> {
    if (supervisionVerification.Id) {
      return this.put<ISupervisionVerificationDto>(this.verificationUri, supervisionVerification);
    } else {
      return this.post<ISupervisionVerificationDto>(this.verificationUri, supervisionVerification);
    }
  }

  public saveSupervisedSupervisionPlan(supervisionPlan: ISupervisionPlanDto): Observable<ISupervisionPlanDto> {
    if (supervisionPlan.Id) {
      return this.put<ISupervisionPlanDto>(this.supervisedPlanUri, supervisionPlan);
    } else {
      return this.post<ISupervisionPlanDto>(this.supervisedPlanUri, supervisionPlan);
    }
  }

  public saveSupervisorDesignation(supervisorDesignation: ISupervisorDesignationDto): Observable<ISupervisorDesignationDto> {
    if (!supervisorDesignation.Id) {
      return this.post<ISupervisorDesignationDto>(this.supervisorDesignationUri, supervisorDesignation);
    } else {
      return this.put<ISupervisorDesignationDto>(this.supervisorDesignationUri, supervisorDesignation);
    }
  }

  public initializeSupervisionPlan(): Observable<ISupervisionPlanDto> {
    return this.get<ISupervisionPlanDto>(this.uri);
  }

  public initializeSupervisor(entityId: number): Observable<ISupervisorDto> {
    return this.get<ISupervisorDto>(`${this.supervisorUri}/initialize/${entityId}`);
  }

  public getSupervisionDesignationForms(): Observable<ISupervisorDesignationFormDto[]> {
    return this.get<ISupervisorDesignationFormDto[]>(this.supervisorDesignationFormUri);
  }

  public getSupervisionDesignationTypesByFormId(formId: number): Observable<ISupervisorDesignationTypeDto[]> {
    return this.get<ISupervisorDesignationTypeDto[]>(`${this.supervisorDesignationTypeByFormIdUri}/${formId}`);
  }

  public getSupervisionDesignationTypeById(id: number): Observable<ISupervisorDesignationTypeDto> {
    return this.get<ISupervisorDesignationTypeDto>(`${this.supervisorDesignationTypeByIdUri}/${id}`);
  }

  public initializeSupervisorDesignation(supervisorDesignation: ISupervisorDesignationDto): Observable<ISupervisorDesignationDto> {
    return this.post<ISupervisorDesignationDto>(`${this.supervisorDesignationUri}/initialize/item`, supervisorDesignation);
  }

  public initializeSupervisionVerification(): Observable<ISupervisionVerificationDto> {
    return this.get<ISupervisionVerificationDto>(this.verificationUri);
  }

  public searchSupervisors(criteria: ISupervisorSearchCriteriaDto): Observable<SearchResultListDto<ISupervisorSearchResultItemDto>> {
    return this.get<SearchResultListDto<ISupervisorSearchResultItemDto>>(this.supervisorListUri, { params: <any>criteria });
  }

  public getSummaryByLicenseId(id: number): Observable<ISupervisionSummaryDto> {
    return this.get<ISupervisionSummaryDto>(`${this.verificationUri}/getSummaryByLicenseId/${id}`);
  }

  public getSummaryByApplicationId(id: number): Observable<ISupervisionSummaryDto> {
    return this.get<ISupervisionSummaryDto>(`${this.verificationUri}/getSummaryByApplicationId/${id}`);
  }
}
