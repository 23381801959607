import { Component, forwardRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Command } from '@core/models/command';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { from } from 'rxjs';
import { DynamicRoutesService, SystemSettingsManagerService } from 'src/app/core/index-services';
import { OnlineServiceRegistry } from 'src/app/core/models/online-service-registry';
import { ISupervisionFeatureConstantsService, LicensureOnlineServiceProcessTypeKeys } from 'src/app/licensureCore/licensure-core.module';
import { ILicensureConstantsProviderService } from 'src/app/licensureCore/services/licensure-constants-provider.service';
import { IApplicationInfoDto, ILicenseInfoDto, ISupervisionVerificationSearchResultItemDto } from 'src/app/licensureShared/licensure-shared.module';

@Component({
  selector: 'supervisee-verification-list',
  templateUrl: './supervisee-verification-list.component.html',
  styleUrls: ['./supervisee-verification-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SuperviseeVerificationListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SuperviseeVerificationListComponent),
      multi: true
    }
  ]
})
export class SuperviseeVerificationListComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input()
  licenseInfo: ILicenseInfoDto;
  @Input()
  applicationInfo: IApplicationInfoDto;
  @Input()
  verifications: ISupervisionVerificationSearchResultItemDto[];

  newVerificationWizardCommand: Command;
  continueVerificationWizardCommand: Command;
  dataLoaded: boolean = false;
  licenseeAttestationAvailable: boolean = false;
  currentSupervisorDesignations: any = [];

  isPracticeLocationEnabled: boolean;
  isEmploymentEnabled: boolean;

  public get subject(): ILicenseInfoDto | IApplicationInfoDto {
    return this.licenseInfo ?? this.applicationInfo;
  }

  public get columnLength(): number {
    let columns = 9;

    if (!this.isPracticeLocationEnabled) columns--;
    if (!this.isEmploymentEnabled) columns--;

    return columns
  }

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private router: Router
    , private dynamicRouteService: DynamicRoutesService
    , private commandService: CommandService) {

    this.isPracticeLocationEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_VERIFICATIONS.PRACTICE_LOCATION_TEXT_ENABLED);
    this.isEmploymentEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.ENABLED);
  }

  ngOnInit(): void {
    this.initCommands();
  }

  ngOnChanges() {
    this.dataLoaded = true;
  }

  protected initCommands(): void {
    this.newVerificationWizardCommand = this.commandService.create(this.canNewVerificationWizardCommand, this.newVerificationWizardCommandExecuted);
    this.continueVerificationWizardCommand = this.commandService.create((item: ISupervisionVerificationSearchResultItemDto) => this.canContinueVerificationWizardCommand(item), this.continueVerificationWizardCommandExecuted);
  }

  protected canNewVerificationWizardCommand = (): boolean => {
    return true;
  }

  protected newVerificationWizardCommandExecuted = () => {

    if (this.applicationInfo) {
      let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).selectorPathTemplate
        , this.subject.EntityId
        , this.constantsService.DOMAIN_IDS.APPLICATION
        , this.applicationInfo.Id
        , null);

      return from(this.router.navigate(patternUrl));
    }
    else if (this.licenseInfo) {
      let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).selectorPathTemplate
        , this.licenseInfo.EntityId
        , this.constantsService.DOMAIN_IDS.LICENSE
        , this.licenseInfo.Id
        , null);

      return from(this.router.navigate(patternUrl));
    }
  }

  protected canContinueVerificationWizardCommand = (verification: ISupervisionVerificationSearchResultItemDto): boolean => {
    if (!verification)
      return false;
    if (!this.systemSettingsManagerService.asBoolean((<ISupervisionFeatureConstantsService>this.constantsService).SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_VERIFICATIONS.SUPERVISEE_ENABLED))
      return false;
    return (<ISupervisionFeatureConstantsService>this.constantsService).FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_VERIFICATION_STATUS.STARTED == verification.StatusId;
  }

  protected continueVerificationWizardCommandExecuted = (verification: ISupervisionVerificationSearchResultItemDto) => {

    let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).onlineServicePathTemplate
      , this.subject.EntityId
      , verification.Id
      , verification.WebServiceHistoryId);

    return from(this.router.navigate(patternUrl));
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  validate(): ValidationErrors | null { return null; }
  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }
  writeValue(obj: any) { }
  setDisabledState?(isDisabled: boolean): void { }
}


