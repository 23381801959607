import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ISupervisionHoursTypeDto,
ISupervisionPlanHoursDto,
ISupervisionPlanSupervisorDto } from './licensure-shared-dto';

export class SupervisionPlanHoursDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ISupervisionPlanHoursDto {
	SupervisionPlanId: number;
	SupervisionPlanSupervisorId?: number;
	SupervisionPlanSupervisor: ISupervisionPlanSupervisorDto;
	SupervisionHoursTypeId?: number;
	SupervisionHoursType: ISupervisionHoursTypeDto;
	Hours?: number;
	ApprovedHours?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
