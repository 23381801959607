// style notes: (https://github.com/johnpapa/angular-styleguide#style-y030)

import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RouterLink, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import * as _ from 'underscore';
import * as _l from 'lodash-es';

import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '../../services/constants-provider.service';
import { SystemSettingsManagerService } from '../../services/system-settings-manager.service';
import { CommandService } from '../../services/command.service';
import { OnlineServiceMenuService } from '../../services/online-service-menu.service';
import { UserManagerService } from '../../services/user-manager.service';
import { MenuItem } from '../../models/menu-item';
import { MenuGroup } from '../../models/menu-group';
import { Command, NavigationMenuCommand } from '../../models/command';
import { Principal } from '@coreModels/principal';

@Component({
  selector: 'online-service-menu',
  templateUrl: './online-service-menu.component.html'
})
export class OnlineServiceMenuComponent implements OnInit {
  readonly systemMenuGroupName: string = "SystemMenuGroup";
  menuItems: MenuItem[] = [];
  selectedmenuItem: MenuItem;
  private subscriptionLogin: Subscription;

  constructor(
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private commandService: CommandService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private userManagerService: UserManagerService,
    private router: Router
    //private systemSettingsManagerService: SystemSettingsManagerService,
    //onlineServiceMenuService, menuBuilderService, commandService
  ) {
    this.subscriptionLogin = this.userManagerService.login$
      .subscribe(currentPrincipal => this.onUserLogin(currentPrincipal));
  }

  ngOnInit() {
      this.initializeMenu();
  }

  onHidden(): void {
    console.log('Dropdown is hidden');
  }

  onShown(): void {
    console.log('Dropdown is shown');
  }

  isOpenChange(): void {
    console.log('Dropdown state is changed');
  }

  private initializeComponent() {

  }

  private initializeMenu() {
    this.menuItems = this.buildMenu();
  }

  private onUserLogin(principal: Principal) {
    this.initializeMenu();
  }

  private buildMenu() {

    var menuGroups = [];

    // Menu group that the menu manages
    // Create the System Menu Group and add a 'services unavailable' menu option to be displayed if there are no other options
    var systemMenuGroup = new MenuGroup(this.systemMenuGroupName);
    var servicesUnavailableCommand = this.createServicesUnavailableCommand();
    systemMenuGroup.addMenuItem(servicesUnavailableCommand);
    menuGroups.push(systemMenuGroup);

    // Get the set of prepared menu commands. Sort commands first by group order, then group by menu group name
    var menuItemGroups = _.groupBy(_.sortBy(this.onlineServiceMenuService.getCommands(),
        (command: NavigationMenuCommand) => {
          return command.menuGroupOrder;
        }),
        (command: NavigationMenuCommand) => {
        return command.menuGroupName;
      });

    // Here we take each group of menu items and flatten them in to menuGroup objects. Each menuGroup object has a collection of menu items. The first item in each group is the menu header.
    // This structure makes binding the menu easier to deal with.
    _.map(_.keys(menuItemGroups),
      (groupKey) => {

        // Create a new menuGroup for each group key
        var menuGroup = new MenuGroup(groupKey);
        var headerItem = menuGroup.items[0];

        // For each item in each group, we add the menu items with commands
        _.map(menuItemGroups[groupKey],
          (item) => {
            menuGroup.addMenuItem(item);
          });

        headerItem.hasActiveChildren = () => {
          return _.find(menuGroup.items,
            (item: MenuItem) => {
              return !item.isHeader && item.isEnabled;
            });
        }

        // add the group
        menuGroups.push(menuGroup);
      });

    return _l.flatten(_.pluck(menuGroups, "items"));
  }

  public get hasActiveMenuItems(): boolean {
    let hasItems = false;
    let firstFoundActiveItem = _.find(this.menuItems,
      (menuItem: MenuItem) => {
          return this.systemMenuGroupName !== menuItem.menuGroupName &&
          menuItem.command !== null &&
          menuItem.command.enabled;
          });
    hasItems = firstFoundActiveItem ? true : false;
    return hasItems;
  }

  private createServicesUnavailableCommand(): Command {
    let servicesUnavailableCommand = new Command(
      this.userManagerService.getCurrentPrincipal(),
      () => {
        return !this.hasActiveMenuItems; // canExecute is true if the current set of menu items has no active items.
      },
      () => {
          // Execution function for this command is noop
      },
      "Services not available");

    return servicesUnavailableCommand;
  }
}

