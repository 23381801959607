import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { ICeSponsorshipScheduledCourseCreditDto,
ICeSponsorshipScheduledCourseSearchResultItemDto } from './licensure-shared-dto';

export class CeSponsorshipScheduledCourseSearchResultItemDto extends SearchResultItemDto implements ICeSponsorshipScheduledCourseSearchResultItemDto {
	Id: number;
	SponsorshipId: number;
	SponsorId: number;
	SponsorEntityId: number;
	SponsorName: string;
	Name: string;
	SubName: string;
	StatusId?: number;
	StatusDescription: string;
	FullName: string;
	StartDate?: Date;
	EndDate?: Date;
	City: string;
	StateCode: string;
	TotalCreditAmount?: number;
	SponsorContactFirstName: string;
	SponsorContactLastName: string;
	SponsorContactPhone: string;
	SponsorContactEmail: string;
	CourseCredits: ICeSponsorshipScheduledCourseCreditDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
