<div class="p-fluid">

  <!-- Section Header -->
  <div class="p-col-12 reach-section-header">Documents</div>

  <!-- Download links -->
  <div class="reach-navigation-links-wrapper" *ngIf="documentTargets.length > 0">

    <div *ngFor="let item of documentTargets">
      <button pButton pRipple type="button" label="{{item.commandText}}" [command-button]="item"
        class="p-button-text p-text-left"></button>
    </div>

  </div>

  <!-- Empty -->
  <div *ngIf="documentTargets.length < 1">
    <span class="reach-text-muted"><em>No entries.</em></span>
  </div>

</div>