import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import { AutoVerificationSubscriberDto } from '@licensureShared/licensure-shared.module';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AutoVerificationSubscriberService extends ReachHttpClientService {
  uri: string = null;

  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , utilitiesService: UtilitiesService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/AutoVerificationSubscriber", this.apiRootUri);
  }

  public getById(id: number): Observable<AutoVerificationSubscriberDto> {
    return this.get<AutoVerificationSubscriberDto>(`${this.uri}/${id}`);
  }

  public save(subscriber: AutoVerificationSubscriberDto): Observable<AutoVerificationSubscriberDto> {
    if (subscriber.Id) {
      return this.put(this.uri, subscriber);
    } else {
      return this.post(this.uri, subscriber);
    }
  }
}
