import { Component, ElementRef, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, Command, CommandService, ConstantsService, DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, ReachDialogContentComponentV2, ReachScenarios, UtilitiesService, ValidationManagerService, registerDynamicComponent } from 'src/app/core/core.module';
import { SearchResultListDto } from 'src/app/coreShared';
import { OnlineLicenseSearchCriteria, OnlineLicenseSearchResultItemDto } from 'src/app/licensureShared';
import { ILicenseTypeDto, IOnlineLicenseSearchCriteria } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureListService, OnlineLicenseSearchService } from '../../licensure-core.module';
import { LicenseSelectorFieldConfiguration } from '../license-selector-field-configuration';
import { LicenseSelectorFieldEditorModel } from './license-selector-field-editor-model';

export const LicenseSelectorFieldEditorComponentSelector: string = "license-selector-field-editor";
export const LicenseSelectorFieldEditorComponentKey: string = "LicenseSelectorFieldEditor";

@Component({
  selector: LicenseSelectorFieldEditorComponentSelector,
  templateUrl: './license-selector-field-editor.component.html'
})
export class LicenseSelectorFieldEditorComponent extends ReachDialogContentComponentV2<LicenseSelectorFieldEditorModel> {

  searchCommand: Command;
  possibleLicenseTypes: ILicenseTypeDto[];
  config: LicenseSelectorFieldConfiguration;
  searchResults: OnlineLicenseSearchResultItemDto[] = [];

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , protected onlineLicenseSearchService: OnlineLicenseSearchService
    , protected commandService: CommandService
    , protected licensureListService: LicensureListService
    , protected busyManagerService: BusyManagerService
    , protected utilitiesService: UtilitiesService) {
    super(constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.searchCommand = this.commandService.create(this.canSearchCommandExecute, this.searchCommandExecute);

    this.loadLookupLists();
  }

  loadLookupLists() {
    this.busyManagerService.resolve(this.licensureListService.getLicenseTypes()
      .pipe(map((licenseTypes) => {
        this.possibleLicenseTypes = this.licensureListService.filterInactiveItems(licenseTypes);
        this.initDropdowns();
      })),
      this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  override modelToForm(): void {

    this.config = this.dialogDataInjector.dialogData.initializationData.config;

    this.friendlyNames.LastName = this.config.searchCriteria.mode == "Individual" ? "Last Name" : "Organization Name";
    this.friendlyNames.LicenseNumber = "License Number";
    this.friendlyNames.LicenseType = "License Type";
    this.friendlyNames.City = "City";

    this.contentForm.addControl("LicenseType", new FormControl(null));
    this.contentForm.addControl("LastName", new FormControl(null, [Validators.maxLength(200)]));
    this.contentForm.addControl("City", new FormControl(null, [Validators.maxLength(200)]));
    this.contentForm.addControl("LicenseNumber", new FormControl(null, [Validators.maxLength(10)]));

    if (this.config.enableLicenseNotFoundCheckbox) {
      this.contentForm.addControl("LicenseNotFound", new FormControl({ value: null, disabled: true }));
      this.contentForm.get("LicenseNotFound").valueChanges.subscribe((value: boolean) => { this.model.manualEntryRequested = value; });
    }

    super.modelToForm();
  }

  initDropdowns(): void {
    if (this.config.searchCriteria.possibleLicenseTypes.length == 0) {
      this.utilitiesService.enableDisable(this.contentForm.get("LicenseType"), true);
      this.config.searchCriteria.mode == "Organization"
        ? this.possibleLicenseTypes = this.possibleLicenseTypes.filter(item => !item.IsIndividual)
        : this.possibleLicenseTypes = this.possibleLicenseTypes.filter(item => item.IsIndividual)
    }
    else if (this.config.searchCriteria.possibleLicenseTypes.length == 1) {
      this.contentForm.get("LicenseType").setValue(this.possibleLicenseTypes.find(type => type.Code == this.config.searchCriteria.possibleLicenseTypes[0]));
      this.utilitiesService.enableDisable(this.contentForm.get("LicenseType"), false, false);
    }
    else if (this.config.searchCriteria.possibleLicenseTypes.length > 1) {
      this.utilitiesService.enableDisable(this.contentForm.get("LicenseType"), true);
      this.possibleLicenseTypes = this.possibleLicenseTypes.filter(type => this.config.searchCriteria.possibleLicenseTypes.includes(type.Code));
    }
  }

  onRowSelect(event: { data: OnlineLicenseSearchResultItemDto }) { this.handleSelect(event.data); }
  onRowUnselect() { this.handleUnselect(); }
  handleSelect(result: OnlineLicenseSearchResultItemDto): void { this.model.searchResult = result; }
  handleUnselect(): void { this.model.searchResult = null; }

  protected canSearchCommandExecute = (): boolean => { return this.contentForm.valid; }

  protected getSearchCriteria = (): IOnlineLicenseSearchCriteria => {
    const searchCriteria = new OnlineLicenseSearchCriteria();
    const licenseType = this.contentForm.get("LicenseType")?.value;
    searchCriteria.LastName = this.contentForm.get("LastName")?.value;
    searchCriteria.LicenseType = licenseType?.Code ?? null; // Pass null to avoid serialization issues.
    searchCriteria.LicenseTypes = searchCriteria.LicenseType ? null : this.config.searchCriteria.possibleLicenseTypes.join(",");
    searchCriteria.City = this.contentForm.get("City")?.value;
    searchCriteria.IsIndividual = licenseType?.IsIndividual ?? this.config.searchCriteria.mode == "Individual";
    searchCriteria.LicenseNumber = this.contentForm.get("LicenseNumber")?.value;
    return searchCriteria;
  }

  protected searchCommandExecute = (): void => {
    const searchCriteria = this.getSearchCriteria();
    const search = this.onlineLicenseSearchService.search(searchCriteria)
      .pipe(
        map((results: SearchResultListDto<OnlineLicenseSearchResultItemDto>) => {
          this.searchResults = results.Results;

          if (this.config.enableLicenseNotFoundCheckbox) {
            this.contentForm.get("LicenseNotFound").setValue(null);
            this.contentForm.get("LicenseNotFound").enable();
          }

        })
      );

    this.busyManagerService.resolve(search, this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND);
  }
}

registerDynamicComponent(ReachScenarios.Default, LicenseSelectorFieldEditorComponentKey, LicenseSelectorFieldEditorComponent, LicenseSelectorFieldEditorComponentSelector);
