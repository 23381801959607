import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { concatMap, delay, retryWhen } from "rxjs/operators";
import { AppConfigService } from "../app-config.service";


export const retryCount = 2;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class AppHttpErrorInterceptor implements HttpInterceptor {
  constructor(private appConfigService: AppConfigService) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (this.appConfigService.clientProviderConfiguration.httpRetryEnabled
              && count <= this.appConfigService.clientProviderConfiguration.httpRetryConfig.retryCount
              && this.appConfigService.clientProviderConfiguration.httpRetryConfig.retryStatuses.indexOf(error.status) != -1) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    )
  }
}

