import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { CheckboxContentService, ConstantsService, CONSTANTS_SERVICE_TOKEN, ValidationManagerService } from 'src/app/core/index-services';
import { FileUploadListModeEnum } from '../../fileUploadList/file-upload-list.component';
import { FormControl } from '@angular/forms';
import { CvaHostWizardStepComponent } from '../../wizard/wizard-step.component';

// Constants
export const OptionalFileUploadComponentSelector: string = 'optional-file-upload';
export const OptionalFileUploadComponentKey: string = 'optionalFileUpload';

  @Component({
    selector: 'optional-file-upload',
    templateUrl: './optional-file-upload.component.html',
    styleUrls: ['./optional-file-upload.component.scss']
  })
  export class OptionalFileUploadComponent extends CvaHostWizardStepComponent implements AfterViewChecked {

  // CTOR
  constructor(
    checkboxContentService: CheckboxContentService,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
  ) {
    // Base.
    super(constantsService, validationManagerService, wizardInjector, OptionalFileUploadComponent.name, checkboxContentService, elementRef, changeDetectorRef);

    // Initialize form fields.
    this.modelToForm();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {
    this.stepForm.addControl('FileUploadList', new FormControl());
    super.modelToForm();
  }

  public get ValidationMode(): number {
    return FileUploadListModeEnum.IsOptional;
  }

}

// Register component for dynamic loading
// Example: Add the following with first parameter (specific scenario) in each wizard that requires this step
// registerDynamicComponent({Wizard Scenario Here}, OptionalFileUploadComponentKey, OptionalFileUploadComponent, OptionalFileUploadComponentSelector);
