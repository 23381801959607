<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <div class="p-grid p-formgrid">

        <!-- Source -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="source">Source</label>
            <p-dropdown *ngIf="dataLoaded" inputId="source" formControlName="Source" [options]="possibleSources"
                appendTo="body" [showClear]="true" placeholder="&nbsp;"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Board -->
        <div class="p-field p-col-12 p-sm-6 p-lg-8">
            <label for="board">Board</label>
            <p-dropdown *ngIf="dataLoaded" inputId="board" formControlName="Board" [options]="possibleBoards"
                appendTo="body" [showClear]="true" placeholder="&nbsp;"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Certification -->
        <div class="p-field p-col-12">
            <label for="certification">Certification</label>
            <p-dropdown *ngIf="dataLoaded" inputId="certification" formControlName="Certification"
                [options]="filteredCertifications" appendTo="body" optionLabel="Description" dataKey="Id"
                [showClear]="true" placeholder="&nbsp;" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Effective Date -->
        <!-- <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="EffectiveDate">Effective Date</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="EffectiveDate" formControlName="EffectiveDate" [showIcon]="false" yearNavigator="true"
                monthNavigator="true" defaultDate="" appendTo="body" baseZIndex="1">
            </p-calendar>
        </div> -->

        <!-- Expire Date -->
        <!-- <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="ExpireDate">Expire Date</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="ExpireDate" formControlName="ExpireDate" [showIcon]="false" yearNavigator="true"
                monthNavigator="true" defaultDate="" appendTo="body" baseZIndex="1">
            </p-calendar>
        </div> -->

    </div>

</div>