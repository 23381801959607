<div [formGroup]="contentForm">

  <!-- TODO: is the friendlyNames property necessary? -->
  <!-- Mailing & Business Mode -->
  <entity-address-mb *ngIf="mailingAndBusinessMode" formControlName="AddressList" [addressDtoHost]="addressDtoHost"
    [friendlyNames]="friendlyNames"></entity-address-mb>

  <!-- Designated, Business, and Private Mode -->
  <entity-address-dbp *ngIf="dbpMode" formControlName="AddressList" [addressDtoHost]="addressDtoHost"
    [friendlyNames]="friendlyNames"></entity-address-dbp>

  <!-- List Mode -->
  <entity-address-list *ngIf="!mailingAndBusinessMode && !dbpMode" formControlName="AddressList" [addressDtoHost]="addressDtoHost"
    [enableNoBusinessAddressCheckbox]="enableNoBusinessAddressCheckbox">
  </entity-address-list>

</div>