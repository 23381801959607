import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { ActivityConstantsService, ActivityFeatureConfigurationService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DynamicRoutesService, OnlineServiceMenuService, OnlineServiceProcessTypeKeys, OnlineServiceRegistry, OnlineServiceRegistryItem, ReachScenarios, registerDynamicComponent } from '@core/core.module';
import { ProgramInfoComponent, ProgramInfoComponentKey, ProgramInfoComponentSelector } from '../../features/educationProgramApplicationWizard/programInfo/program-info.component';
import { DescriptiveDataComponent, DescriptiveDataComponentKey, DescriptiveDataComponentSelector } from '../../features/educationProgramApplicationWizard/descriptiveData/descriptive-data.component';

registerDynamicComponent(ReachScenarios.ActivityWizard, ProgramInfoComponentKey, ProgramInfoComponent, ProgramInfoComponentSelector);
registerDynamicComponent(ReachScenarios.ActivityWizard, DescriptiveDataComponentKey, DescriptiveDataComponent, DescriptiveDataComponentSelector);

@Injectable({ providedIn: 'root' })
export class LicensureActivityFeatureConfigurationService extends ActivityFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    dynamicRoutesService: DynamicRoutesService,
    onlineServiceMenuService: OnlineServiceMenuService,
    router: Router
  ) {
    super(
      constantsService,
      defaultProviderConfigurationService,
      dynamicRoutesService,
      onlineServiceMenuService,
      router
    );

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Activity);
  }

  protected configureConstants() {
    super.configureConstants();
  }

  protected configureDecorators() {
    super.configureDecorators();
  }

  /**
 * Configure dynamic ForgotPassword routes.
 * @param $routeProvider
 * @param routeConfigurationProvider
 * @param dynamicContentConfigurationProvider
 * @param authorizationConfigurationProvider
 * @param constantsServiceProvider
 */
  protected configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        loadChildren: () => import('src/app/core/features/activityWizard/activity-wizard-selector.module')
          .then(m => m.ActivityWizardSelectorModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        loadChildren: () => import('src/app/licensureCore/features/activityWizard/licensure-activity-wizard.module')
          .then(m => m.LicensureActivityWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        loadChildren: () => import('src/app/licensureCore/features/activityWizard/licensure-activity-wizard.module')
          .then(m => m.LicensureActivityWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
//export class LicensureActivityConstantsService extends ForgotPasswordConstantsService {
export class LicensureActivityConstantsService extends ActivityConstantsService {
}

export interface ILicensureActivityConstantsService extends LicensureActivityConstantsService {
}
