<!-- Grid -->
<div class="p-fluid p-formgrid p-grid p-col-12" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- License Type -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="LicenseType">License Type</label>
        <p-dropdown inputId="LicenseType" formControlName="LicenseType" [options]="possibleLicenseTypes"
            optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
            baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- License Number -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="LicenseNumber">License Number</label>
        <input id="LicenseNumber" type="number" formControlName="LicenseNumber" pInputText>
    </div>

    <!-- Birth Date -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="BirthDate">Birth Date</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="BirthDate" formControlName="BirthDate" [showIcon]="false" yearNavigator="true"
            monthNavigator="true" defaultDate="" appendTo="body" baseZIndex="1">
        </p-calendar>
    </div>

    <!-- Buttons -->
    <div class="p-field p-col-12">
        <div class="p-d-flex p-jc-start">
            <div class="p-mr-2">

                <!-- Validate -->
                <button pButton pRipple type="button" label="Validate" [command-button]="searchCommand"
                    [command-blocks-invalid]="true"></button>

            </div>
        </div>
    </div>

    <!-- Search Response -->
    <div class="p-field p-col-12">
        {{searchResponse}}
    </div>

    <!-- =================== Readonly Information =================== -->

    <!-- Licensee Name -->
    <div *ngIf="licenseeName" class="p-field p-grid p-col-12">
        <label class="p-col-6 p-md-3 p-lg-2">Name</label>
        <span class="p-col-6 p-md-3 p-lg-2">{{licenseeName}}</span>
    </div>

    <!-- Licensee Birth Date -->
    <div *ngIf="licenseeBirthDate" class="p-field p-grid p-col-12">
        <label class="p-col-6 p-md-3 p-lg-2">Birth Date</label>
        <span class="p-col-6 p-md-3 p-lg-2">{{licenseeBirthDate}}</span>
    </div>

    <!-- License Status -->
    <div *ngIf="licenseeLicenseStatus" class="p-field p-grid p-col-12">
        <label class="p-col-6 p-md-3 p-lg-2">License Status</label>
        <span class="p-col-6 p-md-3 p-lg-2">{{licenseeLicenseStatus}}</span>
    </div>

</div>
