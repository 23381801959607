import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CheckboxContentService, ValidationManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService } from 'src/app/core/index-services';
import { ResidencyExtensionComponent } from '../residencyExtension/residency-extension.component';
import { IApplicationCourseworkDto, ICourseworkChecklistItemDto, IExamChecklistItemDto } from 'src/app/licensureShared/licensure-shared.module';
import { IDomainChecklistItemDto } from 'src/app/coreShared/core-shared.module';
import { LicensureConstantsProviderService } from '../../licensure-core.module';
import { forEach } from 'lodash';
import { FormControl, Validators } from '@angular/forms';

export const ApplicationCourseworkComponentSelector: string = "application-coursework";
export const ApplicationCourseworkComponentKey: string = "applicationCoursework";

@Component({
  selector: ApplicationCourseworkComponentSelector,
  templateUrl: './application-coursework.component.html',
  styleUrls: ['./application-coursework.component.scss']
})
export class ApplicationCourseworkComponent extends WizardStepComponent {

  public courseworkItems: IApplicationCourseworkDto[] = [];

  public totalDegreeProgramHours: number;
  public totalOtherHigherEducationHours: number;
  public totalCeHours: number;
  public grandTotalHours: number;

  constructor(
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector) {
    super(constantsService, validationManagerService, wizardInjector, ResidencyExtensionComponent.name, checkboxContentService, elementRef);

    this.modelToForm();
  }

  protected override modelToForm(): void {

    var courseworkChecklistItems: ICourseworkChecklistItemDto[] = (this.wizard.model.application.DomainChecklist as IDomainChecklistItemDto[]).filter(item => item.ChecklistItemTemplate?.ChildDomainId == (this.constantsService as LicensureConstantsProviderService).DOMAIN_IDS.APPLICATION_COURSEWORK) as ICourseworkChecklistItemDto[];
    this.courseworkItems = [].concat(...courseworkChecklistItems.map(item => item.Courseworks));

    this.courseworkItems.forEach((i: IApplicationCourseworkDto) => {
      this.stepForm.addControl(`${i.Id}DegreeProgramHours`, new FormControl(i.DegreeProgramHours, [Validators.min(0), Validators.max(1000)]));
      this.stepForm.addControl(`${i.Id}OtherHigherEducationHours`, new FormControl(i.OtherHigherEducationHours, [Validators.min(0), Validators.max(1000)]));
      this.stepForm.addControl(`${i.Id}CeHours`, new FormControl(i.CeHours, [Validators.min(0), Validators.max(1000)]));

      this.friendlyNames[`${i.Id}DegreeProgramHours`] = "Degree Program Hours";
      this.friendlyNames[`${i.Id}OtherHigherEducationHours`] = "Other Higher Education Hours";
      this.friendlyNames[`${i.Id}CeHours`] = "Continuing Education Hours";
    });
    
    this.updateTotals();

    super.modelToForm();
  }

  protected override formToModel(): void {
    this.courseworkItems.forEach((i: IApplicationCourseworkDto) => {
      i.DegreeProgramHours = this.stepForm.get(`${i.Id}DegreeProgramHours`).value;
      i.OtherHigherEducationHours = this.stepForm.get(`${i.Id}OtherHigherEducationHours`).value;
      i.CeHours = this.stepForm.get(`${i.Id}CeHours`).value;
    });

    this.updateTotals();
  }

  protected updateTotals(): void {
    this.totalDegreeProgramHours = this.courseworkItems.reduce((sum, item) => sum + item.DegreeProgramHours, 0);
    this.totalOtherHigherEducationHours = this.courseworkItems.reduce((sum, item) => sum + item.OtherHigherEducationHours, 0);
    this.totalCeHours = this.courseworkItems.reduce((sum, item) => sum + item.CeHours, 0);
    this.grandTotalHours = this.totalDegreeProgramHours + this.totalOtherHigherEducationHours + this.totalCeHours;
  }

  public getItemTotalHours(item: IApplicationCourseworkDto): number { return (item.DegreeProgramHours ?? 0) + (item.OtherHigherEducationHours ?? 0) + (item.CeHours ?? 0); }

}

registerDynamicComponent(ReachScenarios.ApplicationWizard, ApplicationCourseworkComponentKey, ApplicationCourseworkComponent, ApplicationCourseworkComponentSelector);
