import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IFunctionalItemSupport, IOnlineRelatedActivitySearchCriteriaDto, IOnlineRelatedActivitySearchResultItemDto } from 'src/app/coreShared/core-shared.module';
import { LandingComponentKeys } from '../../index-constants';
import { Command, RouteInfoRegistry } from '../../index-models';
import { CommandService, RelatedActivityListService } from '../../index-services';

@Component({
  selector: 'related-activity-list',
  templateUrl: './related-activity-list.component.html',
  styleUrls: ['./related-activity-list.component.scss']
})
export class RelatedActivityListComponent implements OnChanges {

  @Input() host: IFunctionalItemSupport;
  activityList: IOnlineRelatedActivitySearchResultItemDto[] = [];

  activityDetailCommand: Command;

  constructor(protected relatedActivityListService: RelatedActivityListService, protected router: Router, protected commandService: CommandService) {
  }

  ngOnChanges(): void {
    if (this.host == null) return;

    this.activityDetailCommand = this.commandService.create(
      () => true,
      (activitySearchResultItem: any) => { this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.ActivityDetail).path, activitySearchResultItem.Id]) });

    this.relatedActivityListService.search({ FunctionNumber: this.host.FunctionNumber, FunctionTypeId: this.host.FunctionTypeId, ProfessionTypeCode: this.host.ProfessionTypeCode } as IOnlineRelatedActivitySearchCriteriaDto)
      .subscribe((result) => { this.activityList = result.Results; });
  }

} 
