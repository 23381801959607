import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { CvaHostWizardStepComponent } from '../../wizard/wizard-step.component';

export const FileUploadComponentSelector: string = 'file-upload';
export const FileUploadComponentKey: string = 'fileUpload';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends CvaHostWizardStepComponent {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
  ) {

    super(constantsService, validationManagerService, wizardInjector, FileUploadComponent.name, checkboxContentService, elementRef, changeDetectorRef);
    this.modelToForm();
  }

  protected modelToForm() {
    this.stepForm.addControl('FileUploadList', new FormControl());
    super.modelToForm();
  }
}