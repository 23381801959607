<!-- PrimeNG Table -->
<p-table styleClass="p-datatable-sm" [value]="workHistory" [responsive]="true">

    <!-- Caption -->
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
            <span class="p-text-left reach-list-header">Work History</span>
            <div>
                <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentEditorCommand"
                    tool-tip="Add a new item"></button>
            </div>
        </div>
    </ng-template>

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th>Business Setting</th>
            <th>Facility</th>
            <th>City</th>
            <th>State</th>
            <th style="width: 8em;">
        </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-item>
        <tr>

            <!-- BusinessSetting -->
            <td>{{item.BusinessSetting}}</td>

            <!-- Facility -->
            <td>{{item.Facility}}</td>

            <!-- City -->
            <td>{{item.City}}</td>

            <!-- State -->
            <td>{{item.State}}</td>

            <!-- Buttons -->
            <td class="p-text-right">
                <span class="p-text-nowrap">

                    <!-- Edit -->
                    <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                        [command-button]="presentEditorCommand" [command-parameter]="item"
                        tool-tip="Edit this item"></button>

                    <!-- Delete -->
                    <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                        [command-button]="removeItemCommand" [command-parameter]="item"
                        tool-tip="Delete this item"></button>
                </span>
            </td>

        </tr>
    </ng-template>

    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="5">
                <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
            </td>
        </tr>
    </ng-template>

</p-table>


<div style="margin-top:2em" [formGroup]="stepForm">

    <page-summary-text [majorKey]="majorKey" [minorKey]="acknowledgmentDynamicContentMinorKey"></page-summary-text>

    <!-- Acknowledgment -->
    <div class="p-field-checkbox p-pt-3">
      <p-checkbox inputId="Acknowledgment" formControlName="Acknowledgment" binary="true"></p-checkbox>
      <label for="Acknowledgment"><span>{{acknowledgementLabel}}</span></label>
    </div>
  
</div>

<!-- IMPORTANT -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
