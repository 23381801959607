import { IDomainReasonForChangeDtoHost,
IEntityDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IAutoVerificationSubscriberDto,
IAutoVerificationSubscriberLicenseDto,
IAutoVerificationSubscriptionDto } from './licensure-shared-dto';

export class AutoVerificationSubscriberDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IAutoVerificationSubscriberDto {
	EntityId?: number;
	Entity: IEntityDto;
	ContactFirstName: string;
	ContactLastName: string;
	ContactPhone: string;
	ContactEmail: string;
	ContactFullName: string;
	SubscriptionSummaryInfo: any[];
	Subscriptions: IAutoVerificationSubscriptionDto[];
	Licenses: IAutoVerificationSubscriberLicenseDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
