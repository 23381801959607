import { Component, Inject, Input, OnChanges, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { CheckboxContentChangedEventArgument } from '@coreModels/eventArgs/checkbox-content-changed-event-arguments';
import { CheckboxContent, CheckboxContentService } from '@coreServices/checkbox-content.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import * as _l from 'lodash-es';
import { SimpleChanges } from "node_modules/@angular/core/core";
import { DynamicContentDto } from 'src/app/coreShared';

@Component({
  selector: 'checkbox-content',
  templateUrl: './checkbox-content.component.html',
  styleUrls: ['./checkbox-content.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxContentComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxContentComponent),
      multi: true
    }
  ]
})
export class CheckboxContentComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() item: CheckboxContent;
  @Input() requiredWhen;
  entry: DynamicContentDto = null;
  onChange: any = () => { };
  onTouched: any = () => { };

  /**
   * FormGroup for the editable values for this component.
   */
  public checkboxContentForm: FormGroup = new FormGroup({
    Check: new FormControl(false)
  });

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private checkboxContentService: CheckboxContentService,
    private dynamicContentManagerService: DynamicContentManagerService,
    public reachApplicationService: ReachApplicationService
  ) {
  }

  ngOnInit(): void {
    //this.getEntry();
    this.raiseCheckedChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getEntry();

    let check = this.checkboxContentForm.get('Check');

    // Dynamically add required validator to the check control based on @Input data.
    if (this.item.isRequired && this.isEnabled) {
      check.setValidators([Validators.requiredTrue]);
    }

    check.setValue(this.item.checked);
    check.updateValueAndValidity();

    check.valueChanges.subscribe(val => {
      this.item.checked = val;
      this.raiseCheckedChanged();
    });
  }

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.checkboxContentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
    value && this.checkboxContentForm.setValue(value, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.checkboxContentForm.disable() : this.checkboxContentForm.enable();
  }

  // NG_VALIDATORS interface implementation.
  validate(c: AbstractControl): ValidationErrors | null {
    return this.checkboxContentForm.valid ? null : { required: false };
  }

  public get displayHeading() {
    return this.item.displayHeading;
  }

  public get headingText() {
    return this.entry.GroupName !== undefined && this.entry.GroupName !== '' ? this.entry.GroupName : 'Acknowledgement';
  }

  public get isRequired() {
    if (this.requiredWhen != null) {
      return this.requiredWhen;
    }

    return true;
  }

  public get entryId() {
    return this.entry ? this.entry.Id : 0;
  }

  public get entryIdAttributeValue() {
    return `dynamicContent::${this.entryId}`;
  }

  public get friendlyName() {
    return this.item ? `::${this.item.friendlyName}` : "";
  }

  public get entryContent() {
    return this.entry ? this.entry.Content : "";
  }

  public get isEnabled(): boolean {
    return this.entry && this.entry.Content && (this.entry.Content.length > 0);
  }

  private getEntry(): DynamicContentDto {
    this.entry = _l.find(this.dynamicContentManagerService.getEntries(), (entry) => {
      return entry.MajorKey === this.item.majorKey
        && entry.MinorKey === this.item.minorKey
        && entry.CategoryId === this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT
        && entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE
        && entry.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT;
    });

    return this.entry;
  }

  private raiseCheckedChanged() {
    // "checkboxContent.checkedChanged" event
    let args = new CheckboxContentChangedEventArgument(
      this.entry,
      this.item.friendlyName,
      this.item.checked || false
    );

    this.checkboxContentService.raiseCheckboxContentChanged$(args);
  }
}
