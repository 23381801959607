import { IDomainAuditDto,
IDomainAuditStatusDto,
IDomainAuditTypeDto,
IDomainChecklistItemDto,
IDomainReasonForChangeDtoHost } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DomainAuditDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDomainAuditDto {
	ParentDomainId?: number;
	ParentDomainKeyId?: number;
	TemporaryParentDomainKeyId?: any;
	IsExempt: boolean;
	ParentFunctionTypeId?: number;
	ParentFunctionNumber: string;
	ParentProfessionTypeCode: string;
	ItemReferenceValue: string;
	EntityId?: number;
	StatusId?: number;
	StatusModifiedDate?: Date;
	StatusModifiedBy: string;
	TypeId?: number;
	DueDate?: Date;
	ParentDomainDescription: string;
	Status: IDomainAuditStatusDto;
	Type: IDomainAuditTypeDto;
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;

}
