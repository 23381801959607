import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorComponentKeys } from '@coreConstants/error-component-constants';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { LandingComponentKeys } from '../../core.module';
export const NoPageFoundComponentSelector = 'no-page-found';

@Component({
  selector: NoPageFoundComponentSelector,
  templateUrl: './no-page-found.component.html'
})
export class NoPageFoundComponent implements OnInit {
  landingUrl: string;
  shouldRender: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.landingUrl = '/' + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path;
  }

  ngOnInit(): void {
    this.tryCaseInsensitive();
  }

  protected tryCaseInsensitive() {
    // Code will attempt to find the route using a case sensitive key.
    let url = this.route.snapshot.url[0].path.split('?')[0];
    if (url) {
      try {
        const item = RouteInfoRegistry.getItemByRegistryTypeKey(url, true);
        if (item) {
          this.router.navigate(['/' + item.path]);
        }
      } catch {
        this.shouldRender = true;
      }
    }
  }
}

registerDynamicComponent(ReachScenarios.Default, ErrorComponentKeys.Error, NoPageFoundComponent, NoPageFoundComponentSelector);