import { Injectable } from '@angular/core';

import { ArgumentExceptionService } from './argument-exception.service';
import { WizardTags } from '@coreModels/wizard-tags';

@Injectable({
  providedIn: 'root'
})
export class WizardSearchCriteriaService {

  constructor(private argumentExceptionService: ArgumentExceptionService) {}

  public create(webServiceHistoryId: number, processTypeId, wizardTags: WizardTags): WizardSearchCriteria {
    if (!processTypeId) {
      this.argumentExceptionService.create("processTypeId").log();
    }

    if (!wizardTags) {
      this.argumentExceptionService.create("wizardTags").log();
    }

    return new WizardSearchCriteria(webServiceHistoryId, processTypeId, wizardTags);
  }
}

export class WizardSearchCriteria {
    constructor(public webServiceHistoryId: number, public processTypeId, public wizardTags: WizardTags) {
  }
}