import { ReachMessage } from './reach-message';

/**
 * Represents the event arguments for the click event on a ReachMessage item list.
 * */
export class ReachMessageClickedEventArgs {
  constructor(public message: ReachMessage, public messageScope: string) {   
  }

  /**
   * Gets the formControlName of the control associated with this message instance (else null).
   */
  public get formControlName(): string {
    return this.message.formControlName;
  }
}
