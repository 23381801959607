import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import * as _l from 'lodash-es';

import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from "@core/core.module";
import { IHttpResponseContent, SearchResultItemDto, SearchResultListDto } from "@coreShared/core-shared.module";
import { EducationProgramDto } from "@licensureShared/licensure-shared.module";


@Injectable({
  providedIn: 'root'
})
export class DataRequestMasterSearchService extends ReachHttpClientService {
  private uri: string;
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/DataRequestMaster`;
  }

  /**
   * Gets the data requests.
   */
  public search(): Observable<SearchResultListDto<SearchResultItemDto>> {
    return this.get<SearchResultListDto<SearchResultItemDto>>(`${this.uri}/Search`);
  }
}
