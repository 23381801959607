import { IBaseDto,
IDomainAuditDto } from '@coreShared/dto-gen/core-shared-dto';
import { StepDataDto } from '@coreShared/dto-gen/step-data-dto';
import { IEntityEmploymentListDto,
IProfessionalProfileDto,
IApplicationDto,
IEducationProgramApprovalListDto,
ILicenseDto,
ILicenseStatusChangeApplicationDto,
ILicenseVerificationDto,
ILicensureStepDataDto,
IOnlineCeAuditRecordsSubmissionDto,
IOnlineComplaintSubmissionDto,
IOnlineLicenseSearchResultItemDto,
IRenewalDto,
ISupervisorDto,
IDomainDelegationTokenSearchResultItemDto} from './licensure-shared-dto';

export class LicensureStepDataDto extends StepDataDto implements IBaseDto, ILicensureStepDataDto {
	DomainDelegationTokens: IDomainDelegationTokenSearchResultItemDto[];
	EntityEmploymentList: IEntityEmploymentListDto;
	Application: IApplicationDto;
	ProfessionalProfile: IProfessionalProfileDto;
	License: ILicenseDto;
	Renewal: IRenewalDto;
	EducationProgramApproval: IEducationProgramApprovalListDto;
	Verification: ILicenseVerificationDto;
	Info: IOnlineLicenseSearchResultItemDto;
	LicenseStatusChangeApplication: ILicenseStatusChangeApplicationDto;
	OnlineComplaintSubmission: IOnlineComplaintSubmissionDto;
	OnlineCeAuditRecordsSubmission: IOnlineCeAuditRecordsSubmissionDto;
	DomainAudit: IDomainAuditDto;
	Supervisor: ISupervisorDto;
}
