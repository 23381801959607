import { IBaseDto,
IEntityGroupMemberDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class EntityGroupMemberDto extends BaseDto implements IBaseDto, IEntityGroupMemberDto {
	IsIndividual: boolean;
	FirstName: string;
	LastName: string;
	MiddleName: string;
	City: string;
	State: string;
	Zip: string;
	Country: string;
	LFMName: string;
	FullName: string;

}
