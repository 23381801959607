<!-- Summary Text -->
<page-summary-text [majorKey]="pageSummaryMajorKey" [minorKey]="pageSummaryMinorKey"></page-summary-text>

<!-- PrimeNG Table -->
<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="filteredPhoneNumbers" [responsive]="true"
  responsiveLayout="stack">

  <!-- Caption -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <div class="p-text-left reach-list-header">Phone Numbers</div>
      <div>
        <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentItemEditorCommand"
          tool-tip="Add a new item"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>

      <th>Type</th>
      <th>Number</th>
      <th class="p-text-center" *ngIf="isPublicEnabled">{{isPublicLabel}}</th>
      <th class="p-text-center" *ngIf="isPrimaryEnabled">Primary</th>
      <th style="width: 8em;">
        <!--Edit/Delete-->
      </th>

    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- Type -->
      <td><span class="p-column-title">Type</span>{{item.PhoneType.Description}}</td>

      <!-- Number -->
      <td><span class="p-column-title">Number</span>{{item | displayPhoneNumber}}</td>

      <!-- IsPublic (normal) -->
      <td class="p-d-none reach-d-md-table-cell p-text-center" *ngIf="isPublicEnabled">
        <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
      </td>

      <!-- IsPublic (mobile) -->
      <td class="p-d-md-none p-sm-text-center" *ngIf="isPublicEnabled">
        <span class="p-column-title">{{isPublicLabel}}</span>
        <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
      </td>

      <!-- IsPrimary (normal) -->
      <td class="p-d-none reach-d-md-table-cell p-text-center" *ngIf="isPrimaryEnabled">
        <span [innerHtml]="item.IsPrimary | boolToCheckmarkGlyph"></span>
      </td>

      <!-- IsPrimary (mobile) -->
      <td class="p-d-md-none p-sm-text-center" *ngIf="isPrimaryEnabled">
        <span class="p-column-title">Primary</span>
        <span [innerHtml]="item.IsPrimary | boolToCheckmarkGlyph"></span>
      </td>

      <!-- Buttons -->
      <td class="p-text-right">
        <span class="p-text-nowrap">

          <!-- Edit -->
          <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="presentItemEditorCommand"
            [command-parameter]="item" tool-tip="Edit this item"></button>

          <!-- Delete -->
          <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeItemCommand"
            [command-parameter]="item" tool-tip="Delete this item"></button>

        </span>
      </td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columnCount">
        <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
      </td>
    </tr>
  </ng-template>
</p-table>
<reach-dialog *ngIf="updatePhoneDialogSettings" [dialogSettings]="updatePhoneDialogSettings"></reach-dialog>

<!-- CONFIRMATION DIALOG (global on reach-app.component) -->
