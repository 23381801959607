import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, Command, CommandService, ConstantsService, DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, IReachDialogContentComponent, ListService, ReachControlValidators, ReachDialogContentComponent, ReachScenarios, ValidationManagerService, registerDynamicComponent } from '@core/core.module';
import { ILicenseeInfo } from '@licensureCore/models/licensee-info';
import { LicensureListService } from '@licensureCore/services/licensure-list.service';
import { ILicenseTypeDto, IOnlineLicenseSearchCriteria, IOnlineLicenseSearchResultItemDto, OnlineLicenseSearchResultItemDto } from '@licensureShared/licensure-shared.module';
import { forkJoin, from, of } from 'rxjs';
import { OnlineLicenseSearchService } from '../../services/online-license-search.service';

export const LicenseeLookupComponentSelector: string = 'licensee-lookup';
export const LicenseeLookupComponentKey: string = 'LicenseeLookupComponent';

@Component({
  selector: LicenseeLookupComponentSelector,
  templateUrl: './licensee-lookup.component.html',
  styleUrls: ['./licensee-lookup.component.scss']
})
export class LicenseeLookupComponent extends ReachDialogContentComponent<IOnlineLicenseSearchResultItemDto> implements OnInit, IReachDialogContentComponent {
  possibleLicenseTypes: ILicenseTypeDto[];
  searchCommand: Command;
  searchResponse: string;
  licenseeId: number;
  licenseeName: string;
  licenseeBirthDate: string
  licenseeLicenseStatus: string;
  contentForm: FormGroup;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    private formBuilder: FormBuilder,
    private busyManagerService: BusyManagerService,
    private listService: ListService,
    private commandService: CommandService,
    private licensureListService: LicensureListService,
    private onlineLicenseSearchService: OnlineLicenseSearchService,
  ) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);

    this.modelToForm();
  }

  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    this.friendlyNames.LicenseType = "License Type";
    this.friendlyNames.LicenseNumber = "License Number";

    // Base.
    await super.ngOnInit();
  }

  protected override modelToForm(): void {

    this.contentForm = this.formBuilder.group({
      LicenseType: [null, [Validators.required]],
      LicenseNumber: [null, [Validators.required]],
      BirthDate: [null, [ReachControlValidators.pastDateValidator('Birth Date')]],
    });

    super.modelToForm();
  }

  protected override async loadLookupLists(): Promise<any> {
    const doInit = async (): Promise<any> => {
      const responseCollection = await forkJoin([this.licensureListService.getLicenseTypes()]).toPromise();
      this.possibleLicenseTypes = this.listService.filterInactiveItems(responseCollection[0]) as ILicenseTypeDto[];

      return of(true).toPromise();
    }

    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT).toPromise();
  }

  protected override initCommands(): void {
    this.searchCommand = this.commandService.create(this.canSearchCommandExecute, this.searchCommandExecute);
    super.initCommands();
  }

  protected override async mapInputToOutputModel(): Promise<any> {
    this.outputModel = new OnlineLicenseSearchResultItemDto();
    return of(this.outputModel).toPromise();
  }

  protected override initDropdowns(): void {
    this.dataLoaded = true;
  }

  protected canSearchCommandExecute = (): boolean => {
    return this.contentForm.valid;
  }

  protected searchCommandExecute = () => {

    this.searchResponse = "";

    const searchCriteria: IOnlineLicenseSearchCriteria = {} as IOnlineLicenseSearchCriteria;
    searchCriteria.LicenseType = this.contentForm.get('LicenseType').value?.Code;
    searchCriteria.LicenseNumber = this.contentForm.get('LicenseNumber').value;
    searchCriteria.BirthDate = this.contentForm.get('BirthDate').value;

    this.executeSearch(searchCriteria);
  }

  protected executeSearch(searchCriteria: IOnlineLicenseSearchCriteria): void {
    // Remove any properties that have null values.
    // Attempting to pass null values as query string parameters results in errors.
    for (var property in searchCriteria) {
      if (searchCriteria[property] === null || searchCriteria[property] === undefined) delete searchCriteria[property];
    }

    // Define a function to be resolved by the busy manager service.
    const execute = async (): Promise<any> => {

      // Clear previous search results.
      this.licenseeName = null;
      this.licenseeBirthDate = null;
      this.licenseeLicenseStatus = null;
      //this.outputModel.LicenseId = null;
      this.licenseeId = null;

      // Execute search.
      await this.onlineLicenseSearchService.search(searchCriteria)
        .subscribe((searchResult) => {

          // Ensure exactly one search result was found.
          if (!searchResult.Results?.length || searchResult.Results.length == 0) {
            this.searchResponse = "No licensees were found with the information provided."
            return;
          }
          else if (searchResult.Results.length > 1) {
            this.searchResponse = "Multiple licensees were found with the information provided."
            return;
          }

          // Ensure the license isn't already included.
          if (this.hostDialogData.initializationData.currentLicensees.find((item: ILicenseeInfo) =>
            item.LicenseNumber == searchCriteria.LicenseNumber &&
            item.LicenseTypeCode == searchCriteria.LicenseType)) {

            // If it is, bail.
            this.searchResponse = "This licensee is already included on your staff."
            return;
          }

          // Report successful verification.
          this.searchResponse = "Licensee verified. Information is shown below. Please review the information and then click 'OK' to complete the process.";

          // Get the OnlineLicenseSearchResultItem and use it to set properties on the output model.
          let searchResultItem = searchResult.Results.pop();
          Object.assign(this.outputModel, searchResultItem);

          // Set readonly field information.      
          if (searchResultItem.MiddleName) this.licenseeName = `${searchResultItem.FirstName ?? ""} ${searchResultItem.MiddleName ?? ""} ${searchResultItem.LastName ?? ""}`;
          else this.licenseeName = `${searchResultItem.FirstName ?? ""} ${searchResultItem.LastName ?? ""}`;
          this.licenseeBirthDate = formatDate(searchResultItem.DateOfBirth, 'MM-dd-yyyy', 'en_US');
          this.licenseeLicenseStatus = `${searchResultItem.LicenseStatus}`;

        },
          (response) => {

            if (response === this.constantsService.SYSTEM_CONSTANTS.NOT_FOUND) {
            }
          });

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    this.busyManagerService.resolve(from(execute()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }
}

registerDynamicComponent(ReachScenarios.Default, LicenseeLookupComponentKey, LicenseeLookupComponent, LicenseeLookupComponentSelector);
