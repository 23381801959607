import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, LandingPageDetailRouteResolverService, LandingPageService, OnlineServiceRegistry, OnlineServiceRegistryItem, Principal, ReachScenarios, RouteInfoRegistry, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration, SummaryInfoBuilderService, SummaryInfoManagerService, SystemSettingsManagerService, UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { from } from 'rxjs';
import { ILicenseStatusChangeApplicationDto, ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";
import { LicensureLandingCategories, LicensureLandingComponentKeys } from "../../index-constants";
import { ILicenseReinstatementConstantsService } from "./license-reinstatement-wizard-feature-configuration.service";

@Injectable({ providedIn: 'root' })
export class LicenseStatusChangeFeatureConfigurationService {
  private isInitialized = false;
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private constants: ILicenseStatusChangeConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private summaryInfoBuilderService: SummaryInfoBuilderService,
    private summaryInfoManagerService: SummaryInfoManagerService,
    private userManagerService: UserManagerService,
    private landingPageService: LandingPageService) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseStatusChange);
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, LicenseStatusChangeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseStatusChangeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, LicenseStatusChangeConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, LicenseStatusChangeConstantsService.Mergers.DOMAIN_IDS);

    this.constants = this.constantsService as ILicenseStatusChangeConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/licenseStatusChange/licenseStatusChangeWizard/license-status-change-wizard.module').then(m => m.LicenseStatusChangeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/licenseStatusChange/licenseStatusChangeWizard/license-status-change-wizard.module').then(m => m.LicenseStatusChangeWizardModule)
      },
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/licenseStatusChange/selector/license-status-change-wizard-selector.module').then(m => m.LicenseStatusChangeWizardSelectorModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public getWizard = (statusChangeApplication: ILicenseStatusChangeApplicationDto, webServiceHistoryId: number, processId: number) => {

    const functionType = this.functionTypeService.create(this.constants.FUNCTION_TYPES.LICENSE_STATUS_CHANGE_APPLICATION, statusChangeApplication.Id);
    const wizardTags = this.wizardTagsService.create(statusChangeApplication.LicenseTypeId, null, statusChangeApplication.LicenseStatusTransition.StatusFromCode, null, null, null, statusChangeApplication.LicenseStatusTransition.StatusToCode, processId);
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.constants.WEB_SERVICE_PROCESS_TYPES.LICENSE_STATUS_CHANGE, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      const wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) return await this.wizardService.startWizard(wizard, functionType, statusChangeApplication.LicenseTypeId).toPromise();
      else return wizard;
    };

    return from(initializeWizard());
  };

  private onUserLogin(principal: Principal) { this.addInfosToLandingPage(principal); }
  private onUserProfileRefreshed(principal: Principal) { this.addInfosToLandingPage(principal); }

  private onLandingPageReconfig() {
    const principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  private addInfosToLandingPage(principal: Principal) {
    const isFeatureEnabled = true;//this.systemSettingsManagerService.asBoolean((this.constantsService as LicensureConstantsProviderService).SYSTEM_SETTING_KEYS.LICENSESTATUSCHANGE.ENABLED);

    if (!this.isInitialized) { this.initialize(); }

    if (isFeatureEnabled &&
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).LicenseStatusChanges &&
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).LicenseStatusChanges.length > 0) {
      const configuration = new SummaryDetailConfiguration(LicensureLandingCategories.LicenseStatusChange, LicensureLandingComponentKeys.LicenseStatusChangeSummaryLazy, LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy, SummaryAggregationType.Singleton);
      const infos: SummaryComponentInfo[] = [];

      const summaryInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "", configuration);
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).LicenseStatusChanges.forEach((statusChange, index: number) => {
        summaryInfoBuilder.addItem(
          index,
          `${statusChange.LicenseStatusTransitionMechanismDescription} - ${statusChange.LicenseType} ${statusChange.LicenseNumber}`, // title
          statusChange, // model
          (this.constantsService as ILicenseStatusChangeConstantsService).DOMAIN_IDS.LICENSE_STATUS_CHANGE
        );
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

}
export class LicenseStatusChangeConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: { LICENSE_STATUS_CHANGE_WIZARD_SELECTOR: 'License.Status.Change.Wizard.Selector', LICENSE_STATUS_CHANGE_WIZARD: 'License.Status.Change.Wizard', },
    VALIDATION_MODES: { LICENSE_STATUS_CHANGE_WIZARD: { WEB_STATUS_CHANGE_APPLICATION_PRACTICE_QUESTIONS: "Web.StatusChangeApplication.PracticeQuestions" } },
    WEB_SERVICE_PROCESS_TYPES: { LICENSE_STATUS_CHANGE: LicensureOnlineServiceProcessTypeKeys.LicenseStatusChange },
    FUNCTION_TYPES: { LICENSE_STATUS_CHANGE_APPLICATION: 26, },
    FEATURE_CONSTANTS: { STATUS_CHANGE_APPLICATION_STATUS_CONSTANTS: { NEW: 1, IN_PROCESS: 2, APPROVED: 3, DENIED: 4 } },
    DOMAIN_IDS: { LICENSE_STATUS_CHANGE_APPLICATION: 30, },
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), LicenseStatusChangeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), LicenseStatusChangeConstantsService.Mergers.VALIDATION_MODES);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), LicenseStatusChangeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), LicenseStatusChangeConstantsService.Mergers.FUNCTION_TYPES);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), LicenseStatusChangeConstantsService.Mergers.FEATURE_CONSTANTS);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), LicenseStatusChangeConstantsService.Mergers.DOMAIN_IDS);
}

export interface ILicenseStatusChangeConstantsService extends LicenseStatusChangeConstantsService {
}

