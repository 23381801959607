<!-- Grid -->
<div class="p-fluid p-formgrid p-grid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- Program -->
    <div class="p-field p-col-12">
        <label for="Program">Program</label>
        <input id="Program" type="text" formControlName="Program" pInputText>
    </div>

    <!-- Country -->
    <div class="p-field p-col-12 p-md-6">
        <label for="Country">Country</label>
        <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
            optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;" baseZIndex="1"
            [virtualScroll]="true" itemSize="25">

            <!-- Selected Item -->
            <ng-template let-country pTemplate="selectedItem">
                <div class="p-fluid p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

            <!-- List Item -->
            <ng-template let-country pTemplate="item">
                <div class="p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

        </p-dropdown>
    </div>

    <!-- City -->
    <div class="p-field p-col-12 p-md-6 p-xl-3">
        <label for="city">City</label>
        <input id="city" type="text" formControlName="City" pInputText>
    </div>

    <!-- State -->
    <div class="p-field p-col-12 p-md-6 p-xl-3" [hidden]="!(outputModel?.Country == USA || outputModel?.Country == CANADA)">
        <label for="state">{{outputModel?.Country == CANADA ? PROVINCE : STATE}}</label>
        <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
            [options]="outputModel?.Country == CANADA ? possibleProvinces : possibleStates" optionLabel="StateCode"
            dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- Specialty -->
    <div class="p-field p-col-12">
        <label for="Specialty">Specialty</label>
        <input id="Specialty" type="text" formControlName="Specialty" pInputText>
    </div>

    <!-- Completion Status -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="CompletionStatus">Status</label>
        <p-dropdown *ngIf="dataLoaded" inputId="CompletionStatus" formControlName="CompletionStatus"
            [options]="possibleEntityTrainingStatuses" optionLabel="Description" dataKey="Id" [showClear]="true"
            placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- Start Date -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="StartDate">Start Date</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="StartDate" formControlName="StartDate" [showIcon]="false"
            yearNavigator="true" monthNavigator="true" defaultDate="" appendTo="body"
            baseZIndex="1">
        </p-calendar>
    </div>

    <!-- End Date -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="EndDate">End Date</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="EndDate" formControlName="EndDate" [showIcon]="false" yearNavigator="true"
            monthNavigator="true" defaultDate="" appendTo="body" baseZIndex="1">
        </p-calendar>
    </div>

</div>