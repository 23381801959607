import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _l from 'lodash-es';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { ObservableMap } from '@coreModels/observable-map';
import { ReachMessage } from '@coreModels/reach-message';

/**
 * Data service for managing the tooltips associated with scopes and controls.
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationTooltipManagerService {
  private tooltipScopeMap = new Map<string, Map<string, string>>();

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
  ) {
  }

  /**
   * Gets the tooltip map associated with the specified scope key.
   * @param scopeKey the target Key.
   * @returns the tootip map for the target scope.
   */
  public getScopeMap(scopeKey: string): Map<string, string> {
    let scopeMap = this.tooltipScopeMap.get(scopeKey);
    if (!scopeMap) {
      scopeMap = new Map<string, string>();
      this.tooltipScopeMap.set(scopeKey, scopeMap);
    }

    return scopeMap;
  }

  /**
   * Gets the tooltip associated with the specified scope key.
   * @param scopeKey the target Key.
   * @param formControlName the name of the from control associated with the tooltip.
   * @returns the tootip for the target control.
   */
  public getTooltipsForControl(scopeKey: string, formControlName: string): string {
    let scopeMap = this.getScopeMap(scopeKey);
    let tooltipForControl = scopeMap.get(formControlName);
    return tooltipForControl;
  }

  /**
  * Adds tooltips built from the specified ReachMessage.
  * @param scopeKey the scope key for the component hosting the controls associated with the validation messages.
  * @param message the new ReachMessages.
  */
  public addFromMessage(scopeKey: string, message: ReachMessage) {
    let scopeMap = this.getScopeMap(scopeKey);

    if (message) {
      let formControlName = message.data.key;
      let tipText = scopeMap.get(formControlName);
      if (tipText) {
        tipText += message.detail;
      }
      else {
        tipText = message.detail;
      }
      scopeMap.set(formControlName, tipText);
    }
  }

  /**
  * Adds tooltips built from the specified array of ReachMessages.
  * @param scopeKey the scope key for the component hosting the controls associated with the validation messages.
  * @param messages the array of new ReachMessages.
  */
  public addFromMessages(scopeKey: string, messages: ReachMessage[]) {
    if (!messages) {
      return;
    }

    messages.forEach((m: ReachMessage) => {
      this.addFromMessage(scopeKey, m);
    });
  }

  /**
   * Clears/initializes the validation tooltips associated with the specified scope key.
   * @param key the Key.
   */
  public clearForScope(scopeKey: string): void {
    let scopeMap = this.getScopeMap(scopeKey);
    scopeMap.clear();
  }
}
