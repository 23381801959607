import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { IOrganizationalPayorLicenseeSearchCriteria } from './licensure-shared-dto';

export class OrganizationalPayorLicenseeSearchCriteria extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, IOrganizationalPayorLicenseeSearchCriteria {
	OrganizationalPayorId?: number;
	EffectiveOnly?: boolean;
	Due?: boolean;
	Overdue?: boolean;
	ExpiryDate?: Date;
	LicenseNumber?: number;
	LastName: string;
	FirstName: string;
	LicenseType: string;

}
