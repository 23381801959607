import { ErrorComponentKeys } from '@coreConstants/error-component-constants';
import { LandingComponentKeys } from '@coreConstants/landing-component-constants';
import { LoginComponentKeys } from '@coreConstants/login-component-constants';
import { registerRouteInfo } from './route-registry';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { registerDynamicComponent } from './reach-dynamic-component-registry';

export class ComponentRegistrationManager {
  private static _initialize = (() => {
    ComponentRegistrationManager.initializeCoreLazyComponents();
    ComponentRegistrationManager.initializeCoreLandingRoutes();
    ComponentRegistrationManager.initializeAppRoutes();
  })();

  private static initializeCoreLazyComponents() {
    registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.InvoiceDetailLazy, null, null, null, import('@core/components/landingPage/invoiceLandingCard/invoice-landing.module').then(mod => mod.InvoiceLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.InvoiceSummaryLazy, null, null, null, import('@core/components/landingPage/invoiceLandingCard/invoice-landing.module').then(mod => mod.InvoiceLandingModule));
  }

  private static initializeCoreLandingRoutes() {
    registerRouteInfo(LandingComponentKeys.Landing, LandingComponentKeys.Landing, ReachScenarios.LandingPage, LandingComponentKeys.Landing);
    registerRouteInfo(LandingComponentKeys.GeneralDetail, LandingComponentKeys.GeneralDetail, ReachScenarios.LandingPage, LandingComponentKeys.GeneralDetail);
    registerRouteInfo(LandingComponentKeys.CartDetail, LandingComponentKeys.CartDetail, ReachScenarios.LandingPage, LandingComponentKeys.CartDetail);
    registerRouteInfo(LandingComponentKeys.InvoiceDetail, LandingComponentKeys.InvoiceDetail, ReachScenarios.LandingPage, LandingComponentKeys.InvoiceDetail);
    registerRouteInfo(LandingComponentKeys.InvoiceDetailLazy, LandingComponentKeys.InvoiceDetail, ReachScenarios.LandingPage, LandingComponentKeys.InvoiceDetailLazy);
    registerRouteInfo(LandingComponentKeys.ActivityDetail, LandingComponentKeys.ActivityDetail, ReachScenarios.LandingPage, LandingComponentKeys.ActivityDetail);
  }

  private static initializeAppRoutes() {
    registerRouteInfo(ErrorComponentKeys.Error, ErrorComponentKeys.Error, ReachScenarios.Default, ErrorComponentKeys.Error);
    registerRouteInfo(LoginComponentKeys.Login, LoginComponentKeys.Login, ReachScenarios.Default, LoginComponentKeys.Login);
    registerRouteInfo(LoginComponentKeys.Password, LoginComponentKeys.Password, ReachScenarios.Default, LoginComponentKeys.Password);
    registerRouteInfo(LoginComponentKeys.SecurityQuestions, LoginComponentKeys.SecurityQuestions, ReachScenarios.Default, LoginComponentKeys.SecurityQuestions);
  }
}
