import {
  IConcurrencySupport,
  IDomainItemSupport,
  IFunctionalItemSupport,
  IAddressInfoDto,
  IAddressLocationTypeDto,
  IAuditCreate,
  IAuditModifications,
  IBaseDto,
  ICashMasterDto,
  ICommentsDtoHost,
  IDocumentInfoDto,
  IDomainAuditDto,
  IDomainAuditDtoHost,
  IDomainChecklistDtoHost,
  IDomainChecklistItemDto,
  IDomainCriminalBackgroundCheckDtoHost,
  IDomainFormDtoHost,
  IDomainInspectionDtoHost,
  IDomainKeywordDtoHost,
  IDomainQuestionnaireDtoHost,
  IDto,
  IEntityAddressDto,
  IEntityAddressDtoHost,
  IEntityAddressSearchResultItemDto,
  IEntityDto,
  IEntityId,
  IEntityInfoDto,
  IEntityPhoneInfoDto,
  IExtendedProperties,
  IFileUploadDtoHost,
  ILookupDto,
  IOnlineUserDto,
  IOnlineUserSearchCriteriaDto,
  IPhoneTypeDto,
  IProfessionTypeDto,
  ISearchCriteriaDto,
  ISearchResultItemDto,
  IStateDto,
  IStepDataDto,
  ITimeCommitmentDto,
  IUnpaidDomainCriminalBackgroundInfoDto,
  IUserProfileInfoDto,
  IValidatableDto,
  IWebServiceHistoryInfoDto
} from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from 'src/app/coreShared';

export interface IApplicationCourseworkDto extends IBaseDto {
  ApplicationNbr: number;
  CourseworkKnowledgeAreaRuleId: number;
  DegreeProgramHours?: number | null;
  OtherHigherEducationHours?: number | null;
  CeHours?: number | null;
  TotalHours?: number | null;
}

export interface ICourseworkChecklistItemDto extends IDomainChecklistItemDto {
  Courseworks: IApplicationCourseworkDto[];
}

export interface ILicenseStatusChangeInfoDto extends IBaseDto {
  LicenseStatusTransitionMechanismId?: number | null;
  LicenseNumber: number;
  ChecklistSummary: string;
  StatusDescription: string;
  StatusFromDescription: string;
  StatusToDescription: string;
  LicenseStatusTransitionMechanismDescription: string;
  LicenseType: string;
}
export interface IActionDisciplineTypeInfoDto {
  ActionId?: number;
  DisciplineTypeId?: number;
  ResultingLicenseStatusCode: string;
  ResultingLicenseStatusIfExpiredCode: string;
  DisplayOrder: number;
  EndDate?: Date;
  DisciplineType: IActionDisciplineTypeDto;
}
export interface IActionStatutoryCitationInfoDto extends IDto, IAuditCreate, IAuditModifications {
  ActionId?: number;
  StatutoryCitationTypeId?: number;
  RemovedByActionId?: number;
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
  StatutoryCitationType: IActionStatutoryCitationTypeDto;

}
export interface IApplicationEcfmgDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ApplicationNbr: number;
  EcfmgNbr: string;
  EcfmgDate: string;
  CsaDate: string;

}
export interface ICertificationChecklistItemDto extends IDomainChecklistItemDto {
  Certification: ICertificationDto;

}
export interface ICeScheduledCourseSubmissionDto extends IValidatableDto {
  CeSponsorId: number;
  CeSponsorshipId: number;
  CeSponsorCourseId: number;
  SponsorshipStartDate: Date;
  SponsorshipEndDate: Date;
  Course: ICeSponsorshipScheduledCourseDto;

}
export interface ICeDomainAuditInfoDto extends IDto {
  Id: number;
  ItemReferenceValue: string;
  EntityId: number;
  TypeId: number;
  TypeDescription: string;
  StatusId: number;
  StatusDescription: string;
  PostOnlineRecordsSubmissionStatusId?: number;
  IsOnlineRecordsSubmissionAllowed?: boolean;
  LicenseId: number;
  LicenseNumber: number;
  LicenseTypeCode: string;
  LicenseTypeDescription: string;
  LicenseCardNumberType: string;
  LicenseTypeProfessionalSuffix: string;
  LicenseRenewalId: number;
  RenewalEffectiveDate?: Date;
  RenewalExpireDate?: Date;
  CePeriodStartDate?: Date;
  CePeriodEndDate?: Date;
  CeHoursNeeded?: number;
  CmeRequiredHoursMessage: string;
  Documents: IDocumentInfoDto[];

}
export interface ICeSponsorCourseStatusDto extends ILookupDto {
  AspectStatuses: any[];

}
export interface ICeSponsorInfoDto extends IBaseDto, ICommentsDtoHost {
  ReferenceValue: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactEmail: string;
  ContactFullName: string;
  EntityId?: number;
  Sponsorships: ICeSponsorshipInfoDto[];
  CourseTemplates: any[];
  entity: IOnlineUserDto;


}
export interface ICeSponsorSearchResultItemDetailDto extends IDto {

}
export interface ICeSponsorshipInfoDto extends ICommentsDtoHost, IDomainChecklistDtoHost, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  CeSponsorId: number;
  StartDate?: Date;
  EndDate?: Date;
  CeSponsorshipTypeId?: number;
  CeSponsorshipStatusId?: number;
  IndividualCount?: number;
  AnnualCourseCount: number;
  Status: ICeSponsorshipStatusDto;
  Type: ICeSponsorshipTypeDto;
  ScheduledCourses: any[];

}
export interface ICeSponsorshipScheduledCourseSearchResultItemDetailDto extends IDto {

}
export interface ICompetencyExamChecklistItemDto extends IDomainChecklistItemDto {
  CompetencyExam: any;

}
export interface IEcfmgChecklistItemDto extends IDomainChecklistItemDto {
  ApplicationEcfmg: IApplicationEcfmgDto;

}
export interface IEducationProgramApplicationDto extends IValidatableDto, IDomainChecklistDtoHost, IEntityAddressDtoHost, ICommentsDtoHost, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  DirectorEntityId?: number;
  StatusId?: number;
  PlannedBeginDate?: Date;
  EducationProgramLevelId?: number;
  PlannedFirstGradDate?: Date;
  AdmittedStudentNbr?: number;
  TimesStudentAdmittedNbr?: number;
  MaximumStudentEnrolledNbr?: number;
  ProgramTermLength?: number;
  DirectorEntity: IEntityDto;
  Entity: IEntityDto;
  EducationProgramLevel: IEducationProgramLevelDto;
  Status: any;

}
export interface IEducationProgramApplicationInfoDto extends IDto {
  Id: number;
  EntityId: number;
  DirectorEntityId: number;
  StatusId: number;
  PlannedBeginDate?: Date;
  EducationProgramLevelDescription: string;
  ChecklistSummary: string;
  EntityInfo: IEntityInfoDto;
  DirectorEntityInfo: IEntityInfoDto;
  Status: any;

}
export interface IEducationProgramComplianceDocumentSubmissionDto extends IFileUploadDtoHost {
  ComplianceId: number;
  EducationProgramId: number;

}
export interface IEducationProgramComplianceDto extends IValidatableDto, IDomainChecklistDtoHost, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  ProgramName: string;
  EducationProgramId: number;
  StatusId?: number;
  StatusModifiedBy: string;
  StatusModifiedDate?: Date;
  CompletedDate?: Date;
  EffectiveDate?: Date;
  IsProgramOrganizationChange?: boolean;
  ProgramOrganizationChangeType: string;
  DirectorFiveYearCnt?: number;
  IsDirectorAdminResp?: boolean;
  IsDirectorAssist?: boolean;
  IsDirectorAdminSupport?: boolean;
  DirectorResponse: string;
  IsFacultyOrientation?: boolean;
  IsFacultyMentoring?: boolean;
  FacultyDataResponse: string;
  IsSimulationFacultyCertified?: boolean;
  IsSimulationLabAccredited?: boolean;
  IsClinicalNursingCourse?: boolean;
  IsClinicalStudentRecord?: boolean;
  ClinicalLearningHours?: number;
  ClinicalSimulationHours?: number;
  IsClinicalOtherState?: boolean;
  ClinicalOtherStateText: string;
  StudentSlotsNewCnt?: number;
  AdmissionSlotsNewCnt?: number;
  AdmissionSlotsAvailableCnt?: number;
  AdmissionSlotsFilledCnt?: number;
  AdmissionNotAcceptedCnt?: number;
  AdmissionDataResponse: string;
  CompletedLpnNotHeldCnt?: number;
  CompletedLpnHeldCnt?: number;
  CompletedRnOnlyCnt?: number;
  CompletedRnLpnHeldCnt?: number;
  Status: any;

}
export interface IEducationProgramFacultyCredentialDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EducationProgramFacultyId: number;
  Degree: string;
  DegreeYear?: number;
  MajorSpecialty: string;
  SchoolName: string;
  SchoolCity: string;
  SchoolState: string;
  SchoolCountry: string;

}
export interface IEducationProgramFacultyDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EducationProgramId: number;
  LicenseId: number;
  CollegeTimeCommitmentId?: number;
  ProgramTimeCommitmentId?: number;
  EducationCredentials: string;
  EducationProgramLevelId?: number;
  TerminalEducationTypeId?: number;
  HighestEducationTypeId?: number;
  EndDate?: Date;
  LicenseInfo: ILicenseInfoDto;
  HighestEducationType: IFacultyEducationTypeDto;
  TerminalEducationType: IFacultyEducationTypeDto;
  EducationProgramLevel: IEducationProgramLevelDto;
  CollegeTimeCommitment: ITimeCommitmentDto;
  ProgramTimeCommitment: ITimeCommitmentDto;
  CredentialCount: number;
  LatestCredential: string;
  Credentials: IEducationProgramFacultyCredentialDto[];

}
export interface IEducationProgramFacultySearchCriteriaDto extends ISearchCriteriaDto {
  EntityId?: number;
  EducationProgramId?: number;
  LicenseNumber?: number;
  LicenseStatus: string;
  LicenseType: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EndDateFrom?: Date;
  EndDateTo?: Date;
  CollegeTimeCommitmentId?: number;
  ProgramTimeCommitmentId?: number;
  EducationProgramLevelId?: number;
  TerminalEducationTypeId?: number;
  HighestEducationTypeId?: number;

}
export interface IEmploymentDto extends IValidatableDto, ICommentsDtoHost, IEntityAddressDtoHost, IAuditCreate, IAuditModifications {
  EntityId?: number;
  EntityName: string;
  ReferenceValue: string;
  EmployerName: string;
  PositionTitle: string;
  BeginDate?: Date;
  EndDate?: Date;
  PositionTitleId?: number;
  TypeId?: number;
  EmployerLicenseId?: number;
  EmployerLicenseInfo: ILicenseInfoDto;
  EmploymentPositionTitle: IEmploymentPositionTitleDto;
  EmploymentType: IEmploymentTypeDto;
  IndividualLicenseId?: number;
  SettingId?: number;
  SectorId?: number;

  IndividualLicenseInfo: ILicenseInfoDto;
}
export interface IEmploymentPositionTitleDto extends ILookupDto, IAuditCreate, IAuditModifications {
  IsAddableOnline: boolean;
  IsEditableOnline: boolean;
  IsDisplayedInEntitySearchDetails: boolean;
  ApplicableProfessionTypeCodes: string;
}
export interface IEmploymentTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IEmploymentSettingDto extends ILookupDto, IAuditCreate, IAuditModifications {
}

export class EmploymentSettingDto extends LookupDto implements IEmploymentSettingDto {
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
}

export interface IEmploymentSectorDto extends ILookupDto, IAuditCreate, IAuditModifications {
}

export class EmploymentSectorDto extends LookupDto implements IEmploymentSectorDto {
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
}

export interface IEntityConvictionDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  ConvictionTypeId?: number;
  ConvictionDate?: Date;
  CourtCountyId?: number;
  CrimeDescription: string;
  CourtCity: string;
  CourtStateId: string;
  CourtCountry: string;
  CourtSentence: string;
  ConvictionType: IConvictionTypeDto;

}
export interface IConvictionTypeDto extends ILookupDto {

}
export interface IEntityEmploymentListDto extends IValidatableDto {
  EntityId?: number;
  EmploymentList: IEmploymentDto[];

}
export interface IEntityHospitalPrivilegeDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  Facility: string;
  City: string;
  State: string;
  Privilege: string;

}
export interface IEntityTrainingChecklistItemDto extends IDomainChecklistItemDto {
  EntityTraining: IEntityTrainingDto;

}
export interface IEntityTrainingDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  Program: string;
  Specialty: string;
  StartDate?: Date;
  EndDate?: Date;
  StatusId?: number;
  City: string;
  State: string;
  Country: string;
  DerivedDescription: string;
  EntityTrainingStatus: IEntityTrainingStatusDto;

}
export interface IEntityTrainingStatusDto extends ILookupDto {

}
export interface IEntityWorkHistoryDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  Facility: string;
  City: string;
  State: string;
  BusinessSetting: string;
  AverageHours?: number;
  StartDate?: Date;
  EndDate?: Date;

}
export interface IExamBasisDto extends ILookupDto {
  ExamId: number;
  HostChecklistItemId: number;
  Basis: string;

}
export interface IFacultyEducationTypeDto extends ILookupDto {

}
export interface IEducationProgramLevelDto extends ILookupDto {

}
export interface IOnlineAssociatedLicenseSearchCriteria extends ISearchCriteriaDto {
  LicenseId?: number;
}
export interface IDomainDelegateSearchCriteriaDto extends ISearchCriteriaDto {
  domainDelegationTokenId?: number;
  entityId?: number;
  delegatedDomainId?: number;
  delegatedDomainKeyId?: number;
}

export interface IDomainDelegationTokenSearchCriteriaDto extends ISearchCriteriaDto {
  DelegationTokenId?: number;
  domainDelegationTokenId?: number;
  entityId?: number;
  delegatedDomainId?: number;
  delegatedDomainKeyId?: number;
}

export interface IDelegationTokenDto extends IBaseDto, IValidatableDto, IAuditCreate, IAuditModifications {
  IssuedByEntityId?: number;
  Token: string;
  EffectiveStartDate?: Date;
  EffectiveEndDate?: Date;
  StatusId?: number;
  Status: IDelegationTokenStatusDto;
  DomainDelegationTokens: IDomainDelegationTokenDto[];
}


export interface IDomainDelegateDto extends IBaseDto, IValidatableDto, IAuditCreate, IAuditModifications {
  domainDelegationTokenId?: number;
  entityId?: number;
  delegatedDomainId?: number;
  delegatedDomainKeyId?: number;

  delegateeEntityId?: number;
  effectiveStartDate?: Date;
  effectiveEndDate?: Date;
}

export interface IDelegationTokenStatusDto extends ILookupDto {

}

export interface IDomainDelegationTokenDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  DelegationTokenId?: number;
  DelegatedDomainId?: number;
  DelegatedDomainKeyId?: number;
}

export interface IDelegationTokenSearchCriteriaDto extends ISearchCriteriaDto {
  Token: string;
}

export interface IOnlineAssociatedLicenseSearchResultItemDto extends ISearchResultItemDto {
  Id: number;
  EntityId: number;
  LastName: string;
  LicenseType: string;
  LicenseTypeDescription: string;
  LicenseNumber: number;
  LicenseStatus: string;
  LicenseStatusDescription: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  Address: string;
  State: string;
  City: string;
  Zip: string;
  ExpireDate?: Date;
  ProfessionalInChargeManaged: boolean;
  AuthorizedUserRole: string;
  IsOpiate: boolean;
  LicenseSubtypeDescription: string;
}
export interface IOrganizationalPayorInfoDto extends IBaseDto {
  ContactName: string;
  ContactPhone: string;
  ContactEmail: string;
  OrganizationName: string;
  LicenseeCount: number;
  DueCount: number;
  OverdueCount: number;

}

export interface IDelegationTokenSearchResultItemDto extends ISearchResultItemDto {
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
  StatusId: number;
  Id: number;
  IssuedByEntityId: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Token: string;
  StatusDescription: string;
  DomainDescription: string;
  DomainItemDescription: string;
  EffectiveStartDate: Date;
  EffectiveEndDate: Date;
}


export interface IDomainDelegationTokenSearchResultItemDto extends ISearchResultItemDto {
  Id: number;
  DelegatedDomainId: number;
  DelegatedDomainKeyId: number;

  // Token Related
  TokenId: number;
  Token: string;
  TokenIssuedByEntityId: number;
  TokenIssuedByFirstName: string;
  TokenIssuedByLastName: string;
  TokenIssuedByIsIndividual: boolean;
  TokenStatus: string;
  TokenEffectiveStartDate: Date;
  TokenEffectiveEndDate: Date;
  TokenCreatedBy: string;
  TokenCreatedDate: Date;
  TokenModifiedBy: string;
  TokenModifiedDate: Date;

  // Generalized info about the related domain (application/license)
  Type: string;
  Number: string;
  Location: string;
  StatusDescription: string;

  // Entity Related to the domain item (application/license)
  DomainEntityId?: number;
  DomainEntityFirstName: string;
  DomainEntityLastName: string;
  DomainEntityIsIndividual: boolean;
}

export interface IDomainDelegateSearchResultItemDto extends ISearchResultItemDto {
  Id?: number;
  DomainDelegationTokenId?: number;
  EntityId?: number;
  EntityFirstName?: string;
  EntityLastName?: string;
  EntityIsIndividual?: boolean;
  DelegateeEntityId?: number;
  DelegateeFirstName?: string;
  DelegateeLastName?: string;
  DelegateeIsIndividual?: boolean;
  DelegatedDomainId?: number;
  DelegatedDomainKeyId?: number;
  Type?: string;
  Number?: string;
  Location?: string;
  DomainEntityId?: number;
  DomainEntityFirstName?: string;
  DomainEntityLastName?: string;
  DomainEntityIsIndividual?: boolean;
  CreatedBy?: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy?: string;
}

export interface IOrganizationalPayorLicenseeDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  OrganizationalPayorId?: number;
  LicenseId?: number;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;
  IsPay?: boolean;

}
export interface IOrganizationalPayorLicenseeSearchCriteria extends ISearchCriteriaDto {
  OrganizationalPayorId?: number;
  EffectiveOnly?: boolean;
  ExpiryDate?: Date;
  Due?: boolean;
  Overdue?: boolean;
  LicenseNumber?: number;
  LastName: string;
  FirstName: string;
  LicenseType: string;

}
export interface IOrganizationalPayorLicenseeSearchResultItemDto extends ISearchResultItemDto {
  OrganizationalPayorLicenseeId: number;
  EntityId: number;
  OrganizationalPayorId: number;
  IsPay: boolean;
  FirstName: string;
  LastName: string;
  LicenseType: string;
  LicenseNumber: number;
  BirthDate?: Date;
  ExpireDate?: Date;
  CmeDueDate?: Date;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;

}
export interface IProfessionalProfileDto extends IValidatableDto {
  Certifications: ICertificationDto[];
  EntityMalpractices: any[];
  EntityConvictions: IEntityConvictionDto[];
  EntityHospitalPrivileges: IEntityHospitalPrivilegeDto[];
  EntityWorkHistory: IEntityWorkHistoryDto[];
  EntityTrainings: IEntityTrainingDto[];
  EducationHistory: IEducationHistorySearchResultItemDto[];

}
export interface ISupervisionVerificationDtoHost extends IDomainItemSupport, IFunctionalItemSupport, IValidatableDto, IEntityId {
  SupervisionCompleteDate?: Date;
  IsSuspendSupervisionDetail?: boolean;

}
export interface ISupervisorDesignationFormDto extends ILookupDto {
  IsOnline: boolean;

}
export interface ISupervisorDesignationInfoDto extends IDto {
  TypeId?: number;
  TypeDescription: string;
  ExternalCredentialDescription: string;
  StatusId?: number;
  StatusDescription: string;
  EffectiveDate?: Date;
  ExpireDate?: Date;

}
export interface IActionDisciplineTypeDto extends ILookupDto {

}
export interface IActionProfessionTypeStatutoryCitationTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ProfessionTypeId?: number;
  ActionStatutoryCitationTypeId?: number;
  ProfessionType: IProfessionTypeDto;

}
export interface IActionRequirementOccurrenceSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  StatusId: number;
  ActionId: number;
  ActionRequirementId: number;
  EntityId: number;
  ReportTypeId?: number;
  ActionRequirementTypeId?: number;
  RequirementExtendedDescription: string;
  StatusDescription: string;
  ReportTypeDescription: string;
  AnalystActionComment: string;
  ActionRequirementTypeDescription: string;
  Comment: string;
  Analyst: string;
  StatusModifiedBy: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  StatusModifiedDate?: Date;
  DueDate?: Date;
  CompletedDate?: Date;
  AnalystActionDueDate?: Date;
  AnalystActionCompletedDate?: Date;

}
export interface IActionStatutoryCitationTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ReferenceNumber: string;
  AllProfessionTypes: string;
  ProfessionTypeStatutoryCitationTypes: IActionProfessionTypeStatutoryCitationTypeDto[];

}
export interface IApplicationBasisTypeDto extends ILookupDto {
  Code: string;
  ProfessionTypeCode: string;
  TennessenMinorKeyExtension: string;
  IsOnline: boolean;
  IsOtherLicenseRequired: boolean;
  TemporaryPermitApplicationBasisId?: number;
  IsOtherLicOriginalLicenseApplicable: boolean;
  IsOtherLicBasisApplicable: boolean;
  IsOtherLicDescProvidedApplicable: boolean;
  IsOtherLicPhotoProvidedApplicable: boolean;
  IsOtherLicVerifiedApplicable: boolean;
  IsExamRequired: boolean;
  DefaultStaffAssigned: string;

}
export interface IApplicationDto extends IExtendedProperties, ICommentsDtoHost, IDomainCriminalBackgroundCheckDtoHost, IDomainChecklistDtoHost, ITemporaryPermitDomainChecklistItemDtoHost, IEntityAddressDtoHost, IConcurrencySupport, IAuditCreate, IAuditModifications, ISupervisionVerificationDtoHost, IFileUploadDtoHost, IDomainKeywordDtoHost, IDomainFormDtoHost {
  HasNoPracticeLocations: boolean;
  HasNoOtherLicenses: boolean;
  HasNoExams: boolean;
  HasNoSpecializedTrainings: boolean;
  ApplicationTypeId: string;
  ApplicationStatusId: string;
  StatusModifiedDate?: Date;
  StatusModifiedBy: string;
  PersistedApplicationStatusId: string;
  ApplicationDate?: Date;
  GrantedDate?: Date;
  BoardIssueDate?: Date;
  Interviewer: string;
  InterviewDate?: Date;
  CharterState: string;
  CharterDate?: Date;
  BasisId: string;
  LicenseNumber?: number;
  IsNotInWorkforce?: boolean;
  PeriodStartDate?: Date;
  PeriodEndDate?: Date;
  IsTrainingWaived: boolean;
  StaffAssigned: string;
  FacilityApplicantEntityId?: number;
  FacilityApplicantName: string;
  DoingBusinessAs: string;
  City: string;
  FacilityEntityId?: number;
  FacilityName: string;
  Program: string;
  DegreeCodes: string;
  ExamDescription: string;
  ApplicationType: IApplicationTypeDto;
  Basis: IApplicationBasisTypeDto;
  Cash: ICashMasterDto[];
  ApplicationTrainings: IApplicationTrainingDto[];
  BasicTemporaryPermits: any[];
}
export interface IApplicationInfoDto extends IDto, IUnpaidDomainCriminalBackgroundInfoDto {
  Id: number;
  EntityId: number;
  ApplicationDate?: Date;
  IsOnline: boolean;
  EntityInfo: IEntityInfoDto;
  MailingAddress: IAddressInfoDto;
  PublicAddress: IAddressInfoDto;
  ApplicationType: IApplicationTypeDto;
  ApplicationBasisType: IApplicationBasisTypeDto;
  ApplicationStatusType: IApplicationStatusTypeDto;
  OnlineConfirmationDocumentId?: number;
  OnlineServiceInProgress: boolean;
  IsOnlineApplicationSupported: boolean;
  Education: string;
  ChecklistSummary: string;
  ChecklistItemCount?: number;
  WebServiceHistoryInfoItems: IWebServiceHistoryInfoDto[];

}
export interface IApplicationStatusTypeDto extends IDto {
  Id: string;
  Description: string;
  IsInactive: boolean;
  DisplayOrder: number;

}
export interface IApplicationTrainingDto extends IBaseDto, IAuditCreate, IAuditModifications {
  ApplicationNumber: number;
  EntityId?: number;
  Facility: string;
  Address: string;
  City: string;
  State: string;
  Country: string;
  TraningCodeId: string;
  StartDate: string;
  EndDate: string;
  Specialty: string;
  Listing: string;
  Description: string;
  Phone: string;
  UnitNumber: string;
  LicensedAs: string;
  ReferenceName: string;
  ReferenceAddress: string;
  ReferenceCity: string;
  ReferenceState: string;
  ReferencePhone: string;
  Reference2Name: string;
  Reference2Address: string;
  Reference2City: string;
  Reference2State: string;
  Reference2Phone: string;
  TrainingCode: ITrainingCodeDto;
  DerivedDescription: string;
  IsFederalFacility?: boolean;

}
export interface IApplicationTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ProfessionTypeId: number;
  Type: string;
  Hierarchy?: number;
  IsOnlineApplicationSupported: boolean;
  IsPaperApplicationSupported: boolean;
  SchoolsEntityGroupId?: number;
  OtherSchoolsEntityGroupId?: number;
  IsIndividual?: boolean;
  IsExcludedOnline: boolean;
  RequiresCriminalBackgroundCheck: boolean;
  SupervisionVerificationsSupported: boolean;
  SupervisionPlansSupported: boolean;
}
export interface IAutoVerificationSubscriberDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactEmail: string;
  ContactFullName: string;
  Entity: IEntityDto;
  SubscriptionSummaryInfo: any[];
  Subscriptions: IAutoVerificationSubscriptionDto[];
  Licenses: IAutoVerificationSubscriberLicenseDto[];

}
export interface IAutoVerificationSubscriberLicenseDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  LicenseId?: number;
  AutoVerificationSubscriberId: number;
  StartDate?: Date;
  EndDate?: Date;
  License: ILicenseDto;

}
export interface IAutoVerificationSubscriberLicenseListItemDto extends IDto {
  LicenseId: number;
  EntityId: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  City: string;
  LicenseType: string;
  LicenseTypeDescription: string;
  LicenseNumber: number;
  LicenseStatus: string;
  LicenseStatusDescription: string;
  ReferenceValue: string;

}
export interface IAutoVerificationSubscriberLicenseSearchCriteriaDto extends IDto {
  FirstName: string;
  LastName: string;
  LicenseType: string;
  LicenseNumber?: number;
  LicenseStatus: string;
  ExpireDate?: Date;

}
export interface IAutoVerificationSubscriptionDto extends IBaseDto, IAuditCreate, IAuditModifications {
  AutoVerificationSubscriberId: number;
  TypeId: number;
  StatusId: number;
  StartDate?: Date;
  EndDate?: Date;
  SubscriptionType: IAutoVerificationSubscriptionTypeDto;

}
export interface IAutoVerificationSubscriptionTypeDto extends ILookupDto {
  Price: number;
  MaxItems: number;

}
export interface ICeCreditTypeDto extends ILookupDto {
  Code: string;
  Heirarchy: number;

}
export interface ICeProviderTypeDto extends ILookupDto {
  AllowsUpdate: boolean;

}
export interface ICeResponseTypeDto extends ILookupDto {
  AutoExtensionDays?: number;

}
export interface ICertificationDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  CertificationTypeId?: number;
  ExpireTypeId?: number;
  StatusId?: number;
  EffectiveDate?: Date;
  ExpireDate?: Date;
  EffectiveDateDescription: string;
  ExpireDateDescription: string;
  IsLicenseeVerified?: boolean;
  IsStaffVerified?: boolean;
  IsCurrent?: boolean;
  IsLicenseeViewed?: boolean;
  IsMocParticipation?: boolean;
  Comment: string;
  DerivedEffectiveDate?: Date;
  DerivedExpireDate?: Date;
  DerivedDescription: string;
  CertificationType: ICertificationTypeDto;
  Status: any;
  ExpireType: any;

}
export interface ICertificationOrganizationDto extends ILookupDto {
  Code: string;
  Name: string;
  PrimaryCareIndicator?: boolean;
  CertificationSourceId: number;
  CertificationSource: ICertificationSourceDto;

}
export interface ICertificationSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  StatusId?: number;
  StatusDescription: string;
  Type: string;
  Description: string;
  LicenseType: string;
  LicenseTypeDescription: string;
  Organization: string;
  ExpireDateDescription: string;
  EffectiveDateDescription: string;
  SourceShortName: string;
  SourceDescription: string;
  IsLicenseeVerified?: boolean;
  IsStaffVerified?: boolean;
  IsLicenseeViewed?: boolean;
  IsMocParticipation?: boolean;
  IsSubCertification?: boolean;
  ExpireDate?: Date;
  EffectiveDate?: Date;

}
export interface ICertificationSourceDto extends ILookupDto {
  ShortName: string;

}
export interface ICertificationTypeDto extends ILookupDto {
  CertificationCode: string;
  IsSubcertification: boolean;
  LicenseTypeCode: string;
  LicenseType: ILicenseTypeDto;
  CertificationOrganizationId: number;
  CertificationOrganization: ICertificationOrganizationDto;
  SpecialtyPracticeId?: number;
  SpecialtyPractice: any;

}
export interface ICeSponsorDto extends IValidatableDto, ICommentsDtoHost, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactEmail: string;
  ContactFullName: string;
  SponsorshipCount: number;
  CourseTemplates: any[];

}
export interface ICeSponsorSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  ReferenceValue: string;
  EntityId: number;
  LastName: string;
  FirstName: string;
  FullName: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactFullName: string;
  ContactPhone: string;
  ContactEmail: string;
  Status: string;
  StartDate?: Date;
  EndDate?: Date;
  Address: IEntityAddressDto;

}
export interface ICeSponsorSearchCriteriaDto extends ISearchCriteriaDto {
  LastName: string;
  FirstName: string;
  ContactLastName: string;
  ContactFirstName: string;
  ContactPhone: string;
  ContactEmail: string;
  City: string;
  Zip: string;
  StatusId?: number;
  CountyId?: number;
  EntityId?: number;

}
export interface ICeSponsorshipDto extends IValidatableDto, IDomainChecklistDtoHost, ICommentsDtoHost, IDomainAuditDtoHost, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  CeSponsorId: number;
  StartDate?: Date;
  EndDate?: Date;
  CeSponsorshipTypeId?: number;
  CeSponsorshipStatusId?: number;
  IndividualCount?: number;
  IsCountApplicable: boolean;
  Status: ICeSponsorshipStatusDto;
  Type: ICeSponsorshipTypeDto;
  ScheduledCourses: ICeSponsorshipScheduledCourseDto[];

}
export interface ICeSponsorshipScheduledCourseCreditDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  CeSponsorshipScheduledCourseId: number;
  CeCreditTypeId?: number;
  CeStudyTypeId?: number;
  CreditAmount?: number;
  IsInactive: boolean;
  CreatedBy: string;
  CreatedDate: Date;
  CreditType: ICeCreditTypeDto;
  StudyType: ICeStudyTypeDto;

}
export interface ICeSponsorshipScheduledCourseDto extends IValidatableDto, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  CeSponsorshipId?: number;
  CeSponsorCourseId?: number;
  Name: string;
  SubName: string;
  FullName: string;
  StartDate?: Date;
  EndDate?: Date;
  City: string;
  State: string;
  Comments: string;
  InactiveInd: boolean;
  LegacyMbceId?: number;
  StatusId?: number;
  Status: ICeSponsorCourseStatusDto;
  Credits: ICeSponsorshipScheduledCourseCreditDto[];
  TotalCreditAmount?: number;

}
export interface ICeSponsorshipScheduledCourseSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  SponsorshipId: number;
  SponsorId: number;
  SponsorEntityId: number;
  StatusId?: number;
  StatusDescription: string;
  SponsorName: string;
  Name: string;
  SubName: string;
  FullName: string;
  StartDate?: Date;
  EndDate?: Date;
  City: string;
  StateCode: string;
  TotalCreditAmount?: number;
  SponsorContactFirstName: string;
  SponsorContactLastName: string;
  SponsorContactPhone: string;
  SponsorContactEmail: string;
  CourseCredits: ICeSponsorshipScheduledCourseCreditDto[];

}
export interface ICeSponsorshipScheduledCourseSearchCriteriaDto extends ISearchCriteriaDto {
  ReferenceValue?: number;
  SponsorshipId?: number;
  SponsorId?: number;
  SponsorName: string;
  CourseName: string;
  CeSponsorshipTypeId?: number;
  SponsorshipYear?: number;
  StatusId?: number;
  StartDate?: Date;
  EndDate?: Date;
  City: string;
  CreditTypeId?: number;
  StudyTypeId?: number;

}
export interface ICeSponsorshipStatusDto extends ILookupDto {
  Code: string;
  Heirarchy: number;

}
export interface ICeSponsorshipTypeDto extends ILookupDto {
  Code: string;
  Hierarchy: number;
  FixedPrice: boolean;

}
export interface ICeStudyTypeDto extends ILookupDto {
  Code: string;
  Heirarchy: number;

}
export interface IContinuingEducationBasicDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  CeProviderTypeId?: number;
  CeProviderType: ICeProviderTypeDto;
  LicenseId: number;
  Organization: string;
  Title: string;
  Hours?: number;
  CompleteDate?: Date;
  Notes: string;

}
export interface IDegreeDto extends ILookupDto {
  Degree: string;
  ApplicationTypeId: string;
  IsDefault: boolean;
  DescriptionWithDegreeCode: string;

}
export interface IEducationChecklistItemDto extends IDomainChecklistItemDto {
  Education: IEducationDto;

}
export interface IEducationDto extends IBaseDto, IAuditCreate, IAuditModifications {
  TemporaryId: any;
  ApplicationId: number;
  SchoolEntityId?: number;
  DegreeId?: number;
  MajorId?: number;
  EducationProgramId?: number;
  EducationProgramTypeId?: number;
  SchoolName: string;
  SchoolCity: string;
  SchoolState: string;
  SchoolCountry: string;
  StartDate: string;
  EndDate: string;
  DegreeDateDescription: string;
  DegreeDate?: Date;
  IsUnlisted: boolean;
  DerivedDescription: string;
  ProgramCode: string;
  Major: IMajorDto;
  Degree: IDegreeDto;
  EducationProgramType: IEducationProgramTypeDto;
  EducationProgram: IEducationProgramDto;
  ApplicationInfo: IApplicationInfoDto;

}
export interface IEducationHistorySearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  ApplicationTypeCode: string;
  ApplicationTypeDescription: string;
  ApplicationId: number;
  MajorId?: number;
  MajorDescription: string;
  DegreeId?: number;
  DegreeDescription: string;
  DegreeCode: string;
  DegreeDate: string;
  SchoolName: string;
  City: string;
  State: string;
  Country: string;
  DerivedDescription: string;

}
export interface IEducationProgramApprovalListDto extends IValidatableDto {
  EducationProgram: IEducationProgramDto;
  Education: IEducationDto[];

}
export interface IEducationProgramDto extends IValidatableDto, IDomainChecklistDtoHost, IEntityAddressDtoHost, ICommentsDtoHost, IAuditCreate, IAuditModifications {
  DirectorEntityId?: number;
  IsInactive: boolean;
  IsOnlinePhnProgramApproved: boolean;
  IsOnlineProgramApproved: boolean;
  IsPhnEducationAutoComplete: boolean;
  IsAccredited: boolean;
  StatusId?: number;
  StatusModifiedBy: string;
  StatusModifiedDate?: Date;
  EducationProgramApplicationId?: number;
  GrantDate?: Date;
  ScheduledSurveyDate?: Date;
  AccreditedExpireDate?: Date;
  StorageOfRecords: string;
  CopiesOfRecords: string;
  ProgramCode: string;
  EducationProgramLevelId?: number;
  City: string;
  State: string;
  Country: string;
  ProgramTypeCode: string;
  ReferenceValue: string;
  Entity: IEntityDto;
  DirectorEntity: IEntityDto;
  EducationProgramLevel: IEducationProgramLevelDto;
  Status: any;
  EducationItems: IEducationDto[];
  EducationProgramFaculties: IEducationProgramFacultyDto[];

}
export interface IEducationProgramTypeDto extends ILookupDto, IAuditCreate {
  IsOccupational: boolean;

}
export interface IExamChecklistItemDto extends IDomainChecklistItemDto {
  Exam: IExamDto;

}
export interface IExamDto extends IBaseDto, IAuditCreate, IAuditModifications {
  ApplicationId: number;
  IsPassed?: boolean;
  Score: string;
  State: string;
  Series: string;
  ExamTypeId?: number;
  ExamDate?: Date;
  Notes: string;
  ParentApplicationExamId?: number;
  ExamReleased?: boolean;
  RegisterDate?: Date;
  EligibleDate?: Date;
  AuthorizeDate?: Date;
  AppointmentDate?: Date;
  ScoreHoldDate?: Date;
  ScoreReleaseDate?: Date;
  ScoreCancelDate?: Date;
  IsNotTaken?: boolean;
  CertificationNumber: string;
  CsaDate: string;
  NarmLevelId?: number;
  NataLevelId?: number;
  CertificationIssueDate?: Date;
  CertificationExpireDate?: Date;
  AttemptNumber?: number;
  PassingScoreLabel: string;
  DerivedDescription: string;
  DerivedDescriptionAlt1: string;
  NarmLevel: any;
  NataLevel: any;
  ExamType: IExamTypeDto;
  Sections: IExamDto[];
  AdditionalExamScores: IExamDto[];

}
export interface IExamTypeDto extends ILookupDto {
  ParentExamId?: number;
  IsHistoric: boolean;
  MaxScore?: number;
  PassingScore?: number;
  ExamPosition: number;
  IsCertificationApplicable: boolean;
  IsNarmApplicable: boolean;
  IsNataApplicable: boolean;
  IsCertificationDatesApplicable: boolean;
  IsCsaDateApplicable: boolean;
  IsPassFailApplicable: boolean;
  IsScoreApplicable: boolean;
  IsDateApplicable: boolean;
  IsStateApplicable: boolean;
  AttemptNumberApplicable: boolean;
  Bases: IExamBasisDto[];
  Sections: IExamTypeDto[];

}
export interface ILicenseDto extends IExtendedProperties, ICommentsDtoHost, IDomainInspectionDtoHost, IEntityAddressDtoHost, IConcurrencySupport, IAuditCreate, IAuditModifications, ISupervisionVerificationDtoHost, IDomainCriminalBackgroundCheckDtoHost, IDomainKeywordDtoHost {
  PicName: string;
  ReferenceValue: string;
  CmeDueDate?: Date;
  LicenseTypeId: string;
  LicenseNumber: number;
  LicenseStatusId: string;
  LicenseStatusDate?: Date;
  GrantDate?: Date;
  ExpireDate?: Date;
  IsNotInWorkforce?: boolean;
  IsLicenseExpired: boolean;
  IsCmeDue: boolean;
  CurrentApplicationNbr?: number;
  StatusModifiedBy: string;
  LicenseCardInactiveText: string;
  City: string;
  FacilityEntityId?: number;
  FacilityName: string;
  Program: string;
  FacilityLicenseeEntityId?: number;
  FacilityLicenseeName: string;
  DoingBusinessAs: string;
  Status: ILicenseStatusDto;
  LicenseType: ILicenseTypeDto;
  InProcessRenewal: IRenewalDto;
  ActiveRenewal: IRenewalDto;
  EffectiveDateNext?: Date;
  ExpireDateNext?: Date;
  ExpireDateMonthBefore?: Date;
  CeReportingDateFrom?: Date;
  CeReportingDateTo?: Date;
  CeReportingDatePreviousPeriodFrom?: Date;
  CeReportingDatePreviousPeriodTo?: Date;
  RenewalFeeAmount?: number;
  RenewalLateFeeAmount?: number;
  RenewalFeeAdjustmentAmount?: number;
  RenewalTotalAmountDue?: number;
  ContinuingEducation: IContinuingEducationBasicDto[];
  ProfessionalInChargeManaged?: boolean;
}
export interface ILicenseInfoDto extends IDto, IUnpaidDomainCriminalBackgroundInfoDto {
  Id: number;
  LicenseNumber: number;
  EntityId: number;
  SupervisedPracticeHours: string;
  ReferenceValue: string;
  ExpireDate?: Date;
  GrantDate?: Date;
  CmeDueDate?: Date;
  CmeRequiredHoursMessage: string;
  LicenseStatusDate?: Date;
  PendingApprovedDate?: Date;
  SupervisionCompleteDate?: Date;
  PendingEffectiveDate?: Date;
  AllowRenewOnline: boolean;
  HasInProcessRenewal: boolean;
  IsSuspendSupervisionDetail?: boolean;
  StateOfPrincipleLicense: string;
  FacilityLabel: string;
  FacilityName: string;
  IsFacilityUsed: boolean;
  FacilityLicenseeName: string;
  DoingBusinessAs: string;
  PendingLicenseStatusChangeApplicationStatusTo: ILicenseStatusDto;
  PendingStatus: ILicenseStatusDto;
  EntityInfo: IEntityInfoDto;
  MailingAddress: IAddressInfoDto;
  PublicAddress: IAddressInfoDto;
  BusinessAddress: IAddressInfoDto;
  LicenseType: ILicenseTypeDto;
  LicenseStatus: ILicenseStatusDto;
  Certifications: ICertificationSearchResultItemDto[];
  PossibleLicenseStatusTransitions: ILicenseStatusTransitionDto[];
  WebServiceHistoryInfoItems: IWebServiceHistoryInfoDto[];

}
export interface ILicenseSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  LicenseNumber: number;
  EntityId: number;
  IsSuspendSupervisionDetail?: boolean;
  IsOpenForRenewal?: boolean;
  IsFacilityUsed: boolean;
  FacilityLabel: string;
  FacilityName: string;
  FacilityEntityId?: number;
  FacilityLicenseeEntityId?: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
  PrivateEmail: string;
  ReferenceValue: string;
  LicenseType: string;
  LicenseCardNumberType: string;
  LicenseCardDescription: string;
  LicenseTypeDescription: string;
  LicenseSubtypeDescription: string;
  LicenseTypeProfessionalSuffix: string;
  LicenseStatus: string;
  LicenseStatusDescription: string;
  OnlineDisplayAspectStatusId?: number;
  BasisCode: string;
  Location: string;
  GrantDate?: Date;
  ExpireDate?: Date;
  CeDueDate?: Date;
  BirthDate?: Date;
  LicenseStatusDate?: Date;
  SupervisionCompleteDate?: Date;

}
export interface ILicenseStatusChangeApplicationDto extends IFileUploadDtoHost, IValidatableDto, IDomainChecklistDtoHost, ITemporaryPermitDomainChecklistItemDtoHost, IDomainCriminalBackgroundCheckDtoHost, IFunctionalItemSupport, IConcurrencySupport, IAuditCreate, IAuditModifications {
  LicenseId: number;
  LicenseTypeId: string;
  LicenseNumber?: number;
  Reviewer: string;
  ReviewDate?: Date;
  LicenseStatusCodeFrom: string;
  LicenseStatusId: string;
  GrantDate?: Date;
  EffectiveDate?: Date;
  IsPrinted: boolean;
  IsProcessed: boolean;
  IsStatusChanged: boolean;
  StatusId?: number;
  IsFuture?: boolean;
  RetirementDate?: Date;
  TemporaryId?: any;
  LicenseStatusTransitionId?: number;
  StatusOrder: number;
  LicenseInfo: ILicenseInfoDto;
  LicenseType: ILicenseTypeDto;
  LicenseStatusFrom: ILicenseStatusDto;
  LicenseStatus: ILicenseStatusDto;
  PersistedStatus: ILicenseStatusChangeApplicationStatusDto;
  Status: ILicenseStatusChangeApplicationStatusDto;
  LicenseStatusTransition: ILicenseStatusTransitionDto;

}
export interface ILicenseStatusChangeApplicationStatusDto extends ILookupDto {

}
export interface ILicenseStatusChangeMechanismDto extends ILookupDto {

}
export interface ILicenseStatusDto {
  Id: string;
  IsOnlineRenewalAllowed: boolean;
  Description: string;
  IsInactive: boolean;
  DisplayOrder: number;
  IsNew: boolean;
  IsDeleted: boolean;

}
export interface ILicenseStatusTransitionDto extends ILookupDto {
  StatusFromCode: string;
  StatusToCode: string;
  IsAllowedOnline: boolean;
  AllowedSincePreviousStatusTimeIncrementId?: number;
  AllowedSincePreviousStatusTimeIncrementQuantity?: number;
  LicenseStatusChangeMechanismId: number;
  SecurityRight: string;
  FollowupLetterTypeId?: number;
  FutureEffectiveFollowupLetterTypeId?: number;
  FollowupLetterActivityTypeId?: number;
  FollowupLetterActivitySubtypeId?: number;
  IsAutoApproved: boolean;
  LicenseTypeId: string;
  StatusFrom: ILicenseStatusDto;
  StatusTo: ILicenseStatusDto;
  LicenseStatusChangeMechanism: ILicenseStatusChangeMechanismDto;
  AllowedSincePreviousStatusTimeIncrement: ITimeIncrementDto;

}
export interface ILicenseTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  Code: string;
  ProfessionalSuffix: string;
  LicenseCardNumberType: string;
  LicenseCardDescription: string;
  RenewCycleMonths?: number;
  CmeCycleMonths: number;
  InitialPeriodMinMonths?: number;
  LateRenewalMinMonths: number;
  ReactivationMinMonths: number;
  CmeHoursNeeded?: number;
  Hierarchy: number;
  AllowRenewOnline: boolean;
  AllowSearchOnline: boolean;
  AllowVerificationOnline: boolean;
  AllowMultiple: boolean;
  DaysLate?: number;
  MdhSurveyPath: string;
  IsIndividual?: boolean;
  TemporaryPermitApplicationBasisId?: number;
  IsLateRenewalPreDatedInGracePeriod?: boolean;
  ProfessionTypeId: number;
  IsExcludedOnline: boolean;
  SupervisionVerificationsSupported: boolean;
  SupervisionPlansSupported: boolean;
}
export interface ILicenseVerificationDto extends IValidatableDto, IDomainItemSupport, IFunctionalItemSupport, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  StatusId?: number;
  CashDetailId?: number;
  LicenseId?: number;
  RequestorEntityId?: number;
  LicenseeEntityId?: number;
  RecipientEntityId?: number;
  ProcessId?: number;
  LicenseType: string;
  RecipientName: string;
  RecipientAddressLine1: string;
  RecipientAddressLine2: string;
  RecipientAddressLine3: string;
  RecipientCity: string;
  RecipientState: string;
  RecipientZip: string;
  RecipientCountry: string;
  RecipientEmail: string;
  RecipientPhone: string;
  HasCorrectiveActionHistory: string;
  HasDisciplinaryActionHistory: string;

}
export interface ILicensureEntityInfoDto extends IEntityInfoDto {
  ActionsCount?: number;
  CeSponsorId?: number;
  SupervisorId?: number;
  EducationProgramApplicationId?: number;
  EducationProgramId?: number;
  StrongActionId?: number;
  StrongApplication: IApplicationInfoDto;
  StrongLicense: ILicenseInfoDto;
  StrongComplaint: any;

}
export interface ILicensureOnlineUserSearchCriteriaDto extends IOnlineUserSearchCriteriaDto {
  LicenseType: ILicenseTypeDto;
  ApplicationType: IApplicationTypeDto;
  EducationProgram: IEducationProgramDto;
  EducationProgramEntityId?: number;

}
export interface ILicensureStepDataDto extends IStepDataDto {
  EntityEmploymentList: IEntityEmploymentListDto;
  Application: IApplicationDto;
  ProfessionalProfile: IProfessionalProfileDto;
  License: ILicenseDto;
  Renewal: IRenewalDto;
  EducationProgramApproval: IEducationProgramApprovalListDto;
  Verification: ILicenseVerificationDto;
  Info: IOnlineLicenseSearchResultItemDto;
  LicenseStatusChangeApplication: ILicenseStatusChangeApplicationDto;
  OnlineComplaintSubmission: IOnlineComplaintSubmissionDto;
  OnlineCeAuditRecordsSubmission: IOnlineCeAuditRecordsSubmissionDto;
  DomainAudit: IDomainAuditDto;
  Supervisor: ISupervisorDto;
  DomainDelegationTokens: IDomainDelegationTokenSearchResultItemDto[];
}

export interface ILicensureUserProfileInfoDto extends IUserProfileInfoDto {
  Applications: IApplicationInfoDto[];
  Licenses: ILicenseInfoDto[];
  CeAudits: ICeDomainAuditInfoDto[];
  Sponsors: ICeSponsorInfoDto[];
  SuperviseeVerifications: ISupervisionVerificationSearchResultItemDto[];
  SupervisorVerifications: ISupervisionVerificationSearchResultItemDto[];
  SuperviseePlans: ISupervisionPlanSearchResultItemDto[];
  SupervisorPlans: ISupervisionPlanSearchResultItemDto[];
  EducationProgramApprovals: IEducationProgramApprovalListDto[];
  EducationProgramApplications: IEducationProgramApplicationInfoDto[];
  AutoVerificationSubscribers: IAutoVerificationSubscriberDto[];
  SupervisorDesignations: ISupervisorDesignationDto[];
  OrganizationalPayors: IOrganizationalPayorInfoDto[];
  LicenseStatusChanges: ILicenseStatusChangeInfoDto[];
}
export interface IMajorDto extends ILookupDto {

}
export interface IOnlineCeAuditRecordsSubmissionDto extends IValidatableDto, IFileUploadDtoHost {
  FunctionTypeId?: number;
  WebServiceHistoryId?: number;
  IsExempt: boolean;
  IsSubmittingLater: boolean;
  DomainAuditInfo: ICeDomainAuditInfoDto;
  Attachments: IDocumentInfoDto[];

}
export interface IOnlineComplaintComplainantData extends IValidatableDto {
  OnlineFirstName: string;
  OnlineMiddleName: string;
  OnlineLastName: string;
  OnlineAddressLocationTypeId?: number;
  OnlineAddressLocationType: IAddressLocationTypeDto;
  OnlineAddressLine1: string;
  OnlineAddressLine2: string;
  OnlineAddressLine3: string;
  OnlineCity: string;
  OnlineStateCode: string;
  OnlineState: IStateDto;
  OnlineZip: string;
  OnlineCountry: string;
  OnlinePrimaryPhoneTypeId?: number;
  OnlinePrimaryPhoneType: IPhoneTypeDto;
  OnlinePrimaryPhoneNumber: string;
  OnlinePrimaryPhoneExtension: string;
  OnlineOtherPhoneTypeId?: number;
  OnlineOtherPhoneType: IPhoneTypeDto;
  OnlineOtherPhoneNumber: string;
  OnlineOtherPhoneExtension: string;
  OnlineEmail: string;
  OnlineLicenseTypeCode: string;
  OnlineLicenseType: ILicenseTypeDto;
  OnlineLicenseNbr?: number;

}
export interface IOnlineComplaintComplainantDto extends IOnlineComplaintComplainantData {
  RoleId?: number;
  RoleDescription: string;

}
export interface IOnlineComplaintRespondentData {
  OnlineFirstName: string;
  OnlineMiddleName: string;
  OnlineLastName: string;
  OnlineAddressLocationTypeId?: number;
  OnlineAddressLocationType: IAddressLocationTypeDto;
  OnlineAddressLine1: string;
  OnlineAddressLine2: string;
  OnlineAddressLine3: string;
  OnlineCity: string;
  OnlineStateCode: string;
  OnlineState: IStateDto;
  OnlineZip: string;
  OnlineCountry: string;
  OnlinePrimaryPhoneTypeId?: number;
  OnlinePrimaryPhoneType: IPhoneTypeDto;
  OnlinePrimaryPhoneNumber: string;
  OnlinePrimaryPhoneExtension: string;
  OnlineOtherPhoneTypeId?: number;
  OnlineOtherPhoneType: IPhoneTypeDto;
  OnlineOtherPhoneNumber: string;
  OnlineOtherPhoneExtension: string;
  OnlineEmail: string;
  OnlineLicenseTypeCode: string;
  OnlineLicenseType: ILicenseTypeDto;
  OnlineLicenseNbr?: number;
  OnlineSynopsis: string;

}
export interface IOnlineComplaintRespondentDto extends IValidatableDto, IOnlineComplaintRespondentData {

}
export interface IOnlineComplaintSubmissionDto extends IValidatableDto {
  FunctionTypeId?: number;
  WebServiceHistoryId?: number;
  Synopsis: string;
  DocumentIds: number[];
  Complainants: IOnlineComplaintComplainantDto[];
  Respondents: IOnlineComplaintRespondentDto[];

}
export interface IOnlineEntitySearchCriteriaDto extends ISearchCriteriaDto {
  EntityId?: number;
  LastName: string;
  FirstName: string;
  LicenseType: string;
  LicenseStatus: string;
  City: string;
  Zip: string;
  CountyId?: number;
  CertificationTypeId?: number;
  CertificationSource: string;
  CertificationBoard: string;
  LicenseNumber?: number;
  ExcludeEntitiesWithHolds: boolean;
  IncludeAlternateNames: boolean;
  IncludeNameHistory: boolean;
  BirthDate?: Date;
  IsLicensingSupervisor?: boolean;
  SupervisorCity: string;
  SupervisorZip: string;

}
export interface IOnlineEntitySearchResultItemDetailDto extends IDto {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  IsIndividual: boolean;
  DateOfBirth?: Date;
  Gender: string;
  CorrectiveActionCount: number;
  DisciplinaryActionCount: number;
  BusinessAddress: IEntityAddressSearchResultItemDto;
  DesignatedAddress: string;
  PublicPhone: IEntityPhoneInfoDto;
  Licenses: ILicenseSearchResultItemDto[];
  Certifications: ICertificationSearchResultItemDto[];
  Registrations: ILicenseSearchResultItemDto[];
  TemporaryPermits: ILicenseSearchResultItemDto[];
  PublicActions: IPublicActionInfoDto[];
  Education: IEducationHistorySearchResultItemDto[];
  EntityConvictions: any[];
  EntityTraining: any[];
  Supervisors: IPublicSuperviseeSupervisorInfoDto[];
  DomainKeywords: string;
  Supervisor: ISupervisorSearchResultItemDto;
  PublicEmail: string;
}
export interface IOnlineEntitySearchResultItemDto extends ISearchResultItemDto {
  EntityId: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateOfBirth?: Date;
  City: string;
  LicenseTypeProfessionalSuffixes: string;
  LicenseTypes: string;
  NameMatchOnHistoricName: boolean;
  EntityHasActiveCertifications: boolean;
  EntityHasExpiredCertifications: boolean;
  IsActiveSupervisor: boolean;
  ActiveSupervisorDesignations: string;
  SupervisorCity: string;
  SupervisorZip: string;

}
export interface IOnlineLicenseSearchCriteria extends ISearchCriteriaDto {
  LastName: string;
  FirstName: string;
  BirthDate?: Date;
  LicenseType: string;
  LicenseTypes: string;
  LicenseStatus: string;
  City: string;
  Zip: string;
  CountyId?: number;
  LicenseNumber?: number;
  ExcludeEntitiesWithHolds: boolean;
  IncludeEntityVerification: boolean;
  IncludeAlternateNames: boolean;
  IncludeNameHistory: boolean;
  IsLicensingSupervisor: boolean;
  IsLicenseVerification: boolean;
  IsIndividual?: boolean;

}
export interface IOnlineLicenseSearchResultItemDto extends ISearchResultItemDto {
  LicenseId: number;
  IsIndividual: boolean;
  ReferenceValue: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  City: string;
  LicenseType: string;
  LicenseTypeDescription: string;
  LicenseNumber: number;
  LicenseStatus: string;
  EntityId: number;
  LicenseTypeProfessionalSuffix: string;
  NameMatchOnHistoricName: boolean;
  DateOfBirth?: Date;
  EntityHasActiveCertifications: boolean;
  EntityHasExpiredCertifications: boolean;
  LicenseHasRegistration: boolean;
  LicenseRegistrationNumber: number;
  LicenseRegistrationIssueDate?: Date;

}
export interface IOtherLicenseChecklistItemDto extends IDomainChecklistItemDto {
  OtherLicense: IOtherLicenseDto;

}
export interface IOtherLicenseDto extends IBaseDto, IAuditCreate, IAuditModifications {
  TemporaryId: any;
  ApplicationId?: number;
  EntityId: number;
  LicenseNumber: string;
  State: string;
  Country: string;
  IssuedDate: string;
  ExpirationDate: string;
  LicenseType: string;
  DerivedDescription: string;
  IsOriginalLicense?: boolean;
  Basis: string;
  IsDescProvided?: boolean;
  IsPhotoProvided?: boolean;
  IsVerified?: boolean;

}
export interface IPracticeLocationCheckListItemDto extends IDomainChecklistItemDto {
  ApplicationTraining: IApplicationTrainingDto;

}
export interface IPublicActionInfoDto {
  ActionId: number;
  ActionDate: Date;
  ActionType: string;
  DisciplineTypes: string;
  DisciplineTypeList: IActionDisciplineTypeInfoDto[];
  StatutoryCitations: string;
  ActionDocumentId?: number;
  EndDate?: Date;
  Documents: IDocumentInfoDto[];
  StatutoryCitationList: IActionStatutoryCitationInfoDto[];

}
export interface IRenewalDto extends IValidatableDto, IDomainAuditDtoHost, IDomainChecklistDtoHost, IDomainFormDtoHost, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  LicenseId: number;
  LicenseNumber: number;
  LicenseTypeCode: string;
  AmountDue?: number;
  AmountAdjustment: number;
  RenewalCompleteDate?: Date;
  IsIncomplete: boolean;
  IsCruReferred: boolean;
  IsCmeAudit?: boolean;
  CanDispense: string;
  CmeExtensionDate?: Date;
  IsCmeDue: boolean;
  CmeRequiredHoursMessage: string;
  CmeAuditCompleteDate?: Date;
  EffectiveDate?: Date;
  ExpireDate?: Date;
  IsWebRenewal: boolean;
  NotRenewingOnlineReasonId?: number;
  NotRenewingOnlineReasonOther: string;
  IsHold: boolean;
  HoldReason: string;
  SurveyStatusId?: number;
  TypeId: number;
  CeResponseTypeId?: number;
  CeHoursNeeded?: number;
  CeResponseDate?: Date;
  Level?: number;
  CeResponseType: ICeResponseTypeDto;
  SurveyStatus: any;
  Cash: ICashMasterDto[];

}
export interface ISpecialtyTrainingCheckListItemDto extends IDomainChecklistItemDto {
  ApplicationTraining: IApplicationTrainingDto;

}
export interface ISupervisionHoursDto extends IDto {
  SupervisionHoursTypeId?: number;
  SupervisionHoursType: ISupervisionHoursTypeDto;
  Hours?: number;
  ApprovedHours?: number;

}
export interface ISupervisionHoursTypeDto extends ILookupDto {
  SupervisionHoursCategoryId?: number;
  SupervisionHoursCategory: any;

}
export interface ISupervisionPlanDto extends IValidatableDto, ICommentsDtoHost, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  LicenseId?: number;
  ApplicationNbr?: number;
  ReceivedDate?: Date;
  FormId?: number;
  StatusId?: number;
  EmploymentId?: number;
  BeginDate?: Date;
  EndDate?: Date;
  HoursPerWeek?: number;
  DirectClinicalPercent?: number;
  PracticeLocation: string;
  NarrativeOfPopulation: string;
  NarrativeOfModalities: string;
  NarrativeOfProcess: string;
  IsNarrativeOnFile?: boolean;
  Form: ISupervisionPlanFormDto;
  Status: any;
  Employment: IEmploymentDto;
  Supervisors: ISupervisionPlanSupervisorDto[];
  SupervisionPlanHours: ISupervisionPlanHoursDto[];
  Verifications: ISupervisionVerificationDto[];

}
export interface ISupervisionPlanFormDto extends ILookupDto {
  IsOnlineSupported: boolean;
  IsClinical: boolean;
  VerificationFormId?: number;
  VerificationForm: ISupervisionVerificationFormDto;

}
export interface ISupervisionPlanHoursDto extends ISupervisionHoursDto, IValidatableDto, IAuditCreate, IAuditModifications {
  SupervisionPlanId: number;
  SupervisionPlanSupervisorId?: number;
  SupervisionPlanSupervisor: ISupervisionPlanSupervisorDto;

}
export interface ISupervisionPlanSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  ReferenceValue: string;
  LicenseId?: number;
  LicenseTypeCode: string;
  LicenseNumber?: number;
  ApplicationNbr?: number;
  FormId?: number;
  VerificationFormId?: number;
  FormDescription: string;
  StatusId?: number;
  StatusDescription: string;
  ReceivedDate?: Date;
  BeginDate?: Date;
  EndDate?: Date;
  SuperviseeEntityId?: number;
  SuperviseeLastName: string;
  SuperviseeFirstName: string;
  SuperviseeMiddleName: string;
  SuperviseeFullName: string;
  SupervisorFullNameList: string;
  PracticeLocation: string;
  EmployerName: string;
  PositionTitle: string;
  PlanHasMultipleSupervisors: boolean;
  SupervisionPlanSupervisors: ISupervisionPlanSupervisorDto[];
  SupervisorStatus: string;
  WebServiceHistoryId?: number;
  SupervisorStatusId?: number;

}
export interface ISupervisionPlanSupervisorDto extends IValidatableDto, IDomainQuestionnaireDtoHost, IAuditCreate, IAuditModifications {
  SupervisionPlanId?: number;
  SupervisorId?: number;
  StatusId?: number;
  SupervisorEntityInfo: IEntityInfoDto;
  Supervisor: ISupervisorDto;
  Status: any;

}
export interface ISupervisionSummaryDto extends IDto {
  PracticeHours?: number;
  DirectClinicalHours?: number;
  IncludesClinicalForms: boolean;
  Verifications: ISupervisionVerificationDto[];
  TotalHoursByType: any[];

}
export interface ISupervisionVerificationDto extends IValidatableDto, ICommentsDtoHost, IDomainQuestionnaireDtoHost, IAuditCreate, IAuditModifications {
  LicenseId?: number;
  ApplicationNbr?: number;
  ReferenceValue: string;
  EstimatedNumberOfMonths: number;
  ReceivedDate?: Date;
  SupervisorEntityId?: number;
  SupervisorId?: number;
  OtherSupervisorName: string;
  FormId?: number;
  StatusId?: number;
  StatusDate?: Date;
  BeginDate?: Date;
  EndDate?: Date;
  HoursPerWeek?: number;
  EmploymentId?: number;
  SupervisionPlanId?: number;
  SupervisorStatusId?: number;
  PracticeLocation: string;
  NarrativeOfPopulation: string;
  NarrativeOfModalities: string;
  NarrativeOfProcess: string;
  IsNarrativeOnFile?: boolean;
  PracticeHours?: number;
  DirectClinicalPracticeHours?: number;
  DirectClinicalPercent?: number;
  ApprovedPracticeHours?: number;
  Form: ISupervisionVerificationFormDto;
  Status: any;
  SupervisorStatus: any;
  Employment: IEmploymentDto;
  Supervisor: ISupervisorDto;
  SupervisionVerificationHours: ISupervisionVerificationHoursDto[];

}
export interface ISupervisionVerificationFormDto extends ILookupDto {
  IsOnlineSupported: boolean;
  IsClinical: boolean;

}
export interface ISupervisionVerificationHoursDto extends ISupervisionHoursDto, IValidatableDto, IAuditCreate, IAuditModifications {
  SupervisionVerification: ISupervisionVerificationDto;
  SupervisionVerificationId: number;
  EstimatedTotalHours?: number;
  EstimatedTotalHoursMessage: string;

}
export interface ISupervisionVerificationSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  ReferenceValue: string;
  PlanReferenceValue: string;
  LicenseId?: number;
  LicenseTypeCode: string;
  LicenseNumber?: number;
  ApplicationNbr?: number;
  FormId?: number;
  FormDescription: string;
  StatusId?: number;
  StatusDescription: string;
  SupervisorStatusId?: number;
  SupervisorStatus: string;
  SupervisorStatusDescription: string;
  ReceivedDate?: Date;
  BeginDate?: Date;
  EndDate?: Date;
  SupervisionPlanId?: number;
  SupervisorId?: number;
  SupervisorEntityId?: number;
  SupervisorName: string;
  SuperviseeEntityId?: number;
  SuperviseeLastName: string;
  SuperviseeFirstName: string;
  SuperviseeMiddleName: string;
  SuperviseeFullName: string;
  PracticeLocation: string;
  EmployerName: string;
  PositionTitle: string;
  ApprovedPracticeHours?: number;
  StatusDisplayOrder: number;
  WebServiceHistoryId?: number;

}
export interface ISupervisorDesignationDto extends IValidatableDto, IDomainQuestionnaireDtoHost, IAuditCreate, IAuditModifications {
  SupervisorId: number;
  StatusId?: number;
  TypeId?: number;
  FormId?: number;
  EffectiveDate?: Date;
  ExpireDate?: Date;
  ApplicationReceivedDate?: Date;
  ExternalCredentialDescription: string;
  Status: ISupervisorDesignationStatusDto;
  Type: ISupervisorDesignationTypeDto;
  Form: ISupervisorDesignationFormDto;

}
export interface ISupervisorDesignationStatusDto extends ILookupDto {
  AspectStatuses: any[];

}
export interface ISupervisorDesignationTypeDto extends ILookupDto {
  IsExternalCredential: boolean;
  IsOnline: boolean;

}
export interface ISupervisorDto extends IValidatableDto, ICommentsDtoHost, IDomainAuditDtoHost, IEntityAddressDtoHost, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  PlansCount: number;
  VerificationsCount: number;
  AllDesignations: string;
  EntityInfo: ILicensureEntityInfoDto;
  Designations: ISupervisorDesignationDto[];

}
export interface ISupervisorSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  EntityId: number;
  LicenseTypeCode: string;
  LicenseNumber?: number;
  CredentialDescription: string;
  LastName: string;
  FirstName: string;
  MiddleName: string;
  FullName: string;
  AllDesignations: string;
  ReferenceValue: string;
  ProfessionalSuffix: string;
  Email: string;
  PrivateEmail: string;
  PublicPhoneFull: string;
  PublicAddressFull: string;
  PublicPhone: IEntityPhoneInfoDto;
  PublicAddress: IAddressInfoDto;
  Designations: ISupervisorDesignationInfoDto[];

}
export interface ISupervisorSearchCriteriaDto extends ISearchCriteriaDto {
  EntityId?: number;
  LastName: string;
  FirstName: string;
  MiddleName: string;
  SupervisorDesignationStatusId?: number;
  LicenseTypeCode: string;
  LicenseNumber?: number;
  LicenseReferenceValue: string;
  SupervisorDesignationTypeId?: number;

}
export interface ITemporaryPermitDomainChecklistItemDtoHost {
  TemporaryPermitRequestedWithApplication: boolean;

}
export interface ITimeIncrementDto extends ILookupDto {

}
export interface ITrainingCodeDto extends ILookupDto {
  Code: string;

}
export interface ILicenseVerificationRequestDto extends IValidatableDto {
  Verifications: ILicenseVerificationDto[];
  selectedLicenses: IOnlineLicenseSearchResultItemDto[];


}
export interface IPublicSuperviseeSupervisorInfoDto extends IDto {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  FullName: string;
  Facility: string;
  StartDate?: Date;
  EndDate?: Date;

}

