import { IStateDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class StateDto extends BaseDto implements IStateDto {
	StateCode: string;
	Name: string;
	Country: string;
	IsInactive: boolean;
	DisplayOrder: number;

}
