<div class="p-fluid p-formgrid p-grid" [formGroup]="stepForm">

    <!-- Responses -->
    <div class="p-field p-col-12">

        <!-- Dynamic Content -->
        <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
            [minorKey]="'MilitaryStatus.HasBeenMilitary'"></page-summary-text>

        <div *ngFor="let item of militaryResponseTypes" class="p-field-radiobutton">

            <p-radioButton [inputId]="item.Id" name="MilitaryResponseType" [value]="item" formControlName="MilitaryResponseType"></p-radioButton>

                <!-- Adorned display -->
                <div *ngIf="reachApplicationService.showDynamicContentKeyAdornments">
                    <div class="p-d-flex p-jc-end">
                        <span class="p-tag p-tag-warning">{{wizard.dynamicContentConfiguration.majorKey}} - {{item.MinorKey}}</span>
                    </div>
                    <div class="reach-adornment-container">
                        <label [for]="item.Id">{{getEntry(wizard.dynamicContentConfiguration.majorKey, item.MinorKey)}}</label>
                    </div>
                </div>

                <!-- Normal display -->
                <div *ngIf="!reachApplicationService.showDynamicContentKeyAdornments">
                    <label class="p-pl-2" [for]="item.Id">{{getEntry(wizard.dynamicContentConfiguration.majorKey, item.MinorKey)}}</label>
                </div>
        </div>

    </div>

    <!-- Discharge Date -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4" *ngIf="selectedMilitaryResponseType?.DischargeDateApplicable">
        <label for="DischargeDate">Discharge Date</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="DischargeDate" formControlName="DischargeDate" [showIcon]="false"
            yearNavigator="true" monthNavigator="true" defaultDate="" appendTo="body"
            baseZIndex="1">
        </p-calendar>
    </div>

</div>