import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IOtherLicenseDto } from './licensure-shared-dto';

export class OtherLicenseDto extends ValidatableDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IOtherLicenseDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	TemporaryId: any;
	ApplicationId?: number;
	EntityId: number;
	LicenseNumber: string;
	State: string;
	Country: string;
	IssuedDate: string;
	ExpirationDate: string;
	LicenseType: string;
	DerivedDescription: string;
	IsOriginalLicense?: boolean;
	Basis: string;
	IsDescProvided?: boolean;
	IsPhotoProvided?: boolean;
	IsVerified?: boolean;

}
