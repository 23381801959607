
/**
Set of dynamic lazy load statements for board providers, keyed by provider type.
**/
export const dynamicProviderModules = {
  soc: {
    loadChildren: () => import('@boardProviders/socProvider/soc-provider-configuration.module').then(m => m.SocProviderConfigurationModule)
  },
  phy: {
    loadChildren: () => import('@boardProviders/phyProvider/phy-provider-configuration.module').then(m => m.PhyProviderConfigurationModule)
  },
  mbn: {
    loadChildren: () => import('@boardProviders/mbnProvider/mbn-provider-configuration.module').then(m => m.MbnProviderConfigurationModule)
  },
  nha: {
    loadChildren: () => import('@boardProviders/nhaProvider/nha-provider-configuration.module').then(m => m.NhaProviderConfigurationModule)
  },
  bbe: {
    loadChildren: () => import('@boardProviders/bbeProvider/bbe-provider-configuration.module').then(m => m.BbeProviderConfigurationModule)
  },
  vet: {
    loadChildren: () => import('@boardProviders/vetProvider/vet-provider-configuration.module').then(m => m.VetProviderConfigurationModule)
  },
  bmp: {
    loadChildren: () => import('@boardProviders/bmpProvider/bmp-provider-configuration.module').then(m => m.BmpProviderConfigurationModule)
  },
  bht: {
    loadChildren: () => import('@boardProviders/bhtProvider/bht-provider-configuration.module').then(m => m.BhtProviderConfigurationModule)
  },
  chi: {
    loadChildren: () => import('@boardProviders/chiProvider/chi-provider-configuration.module').then(m => m.ChiProviderConfigurationModule)
  },
  dnp: {
    loadChildren: () => import('@boardProviders/dnpProvider/dnp-provider-configuration.module').then(m => m.DnpProviderConfigurationModule)
  },
  otp: {
    loadChildren: () => import('@boardProviders/otpProvider/otp-provider-configuration.module').then(m => m.OtpProviderConfigurationModule)
  },
  opt: {
    loadChildren: () => import('@boardProviders/optProvider/opt-provider-configuration.module').then(m => m.OptProviderConfigurationModule)
  },
  mft: {
    loadChildren: () => import('@boardProviders/mftProvider/mft-provider-configuration.module').then(m => m.MftProviderConfigurationModule)
  },
  pha: {
    loadChildren: () => import('@boardProviders/phaProvider/pha-provider-configuration.module').then(m => m.PhaProviderConfigurationModule)
  },
  pod: {
    loadChildren: () => import('@boardProviders/podProvider/pod-provider-configuration.module').then(m => m.PodProviderConfigurationModule)
  }
}
