import { IEntityGroupMemberSearchResultItemDto } from './core-shared-dto';
import { SearchResultItemDto } from './search-result-item-dto';

export class EntityGroupMemberSearchResultItemDto extends SearchResultItemDto implements IEntityGroupMemberSearchResultItemDto {
	Id: number;
	IsIndividual: boolean;
	FirstName: string;
	LastName: string;
	MiddleName: string;
	City: string;
	State: string;
	Zip: string;
	Country: string;
	LFMName: string;
	FullName: string;

}
