import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ISupervisionSummaryDto,
ISupervisionVerificationDto } from './licensure-shared-dto';

export class SupervisionSummaryDto extends BaseDto implements IBaseDto, ISupervisionSummaryDto {
	PracticeHours?: number;
	DirectClinicalHours?: number;
	IncludesClinicalForms: boolean;
	Verifications: ISupervisionVerificationDto[];
	TotalHoursByType: any[];

}
