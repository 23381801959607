import {
  IDomainReasonForChangeDtoHost,
  IProcessDto,
  IWebCartItemDto,
  IWebServiceHistoryDetailDto,
  IWebServiceHistoryDto,
  IWebTransactionDto
} from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class WebServiceHistoryDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IWebServiceHistoryDto {
  OmitFeeManagerCalculations: boolean;
  UserId?: number;
  ServiceId: number;
  LastStepIndex: number;
  Description: string;
  StartDate: Date;
  CompleteDate?: Date;
  ConfirmationDocumentId?: number;
  ProcessId?: number;
  HasFees: boolean;
  TotalFees: number;
  IsCanceled: boolean;
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
  Process: IProcessDto;
  WebCartItems: IWebCartItemDto[];
  Transactions: IWebTransactionDto[];
  StepDetails: IWebServiceHistoryDetailDto[];
  FunctionTypeId?: number;
  FunctionNumber: string;
  ProfessionTypeCode: string;
}
