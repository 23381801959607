import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { IApplicationBasisTypeDto } from './licensure-shared-dto';

export class ApplicationBasisTypeDto extends LookupDto implements IApplicationBasisTypeDto {
	Code: string;
	ProfessionTypeCode: string;
	TennessenMinorKeyExtension: string;
	IsOnline: boolean;
	IsOtherLicenseRequired: boolean;
	TemporaryPermitApplicationBasisId?: number;
	IsOtherLicOriginalLicenseApplicable: boolean;
	IsOtherLicBasisApplicable: boolean;
	IsOtherLicDescProvidedApplicable: boolean;
	IsOtherLicPhotoProvidedApplicable: boolean;
	IsOtherLicVerifiedApplicable: boolean;
	IsExamRequired: boolean;
	DefaultStaffAssigned: string;

}
