// Angular
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { from } from "rxjs";

import * as _ from 'underscore';
import * as _l from 'lodash-es';

import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  WizardSearchCriteriaService,
  WizardService,
  WizardTagsService
} from '@core/core.module';

import { IApplicationConstantsService } from '@licensureCore/licensure-core.module';

import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';


@Injectable({
  providedIn: 'root'
})
export class ExamRetakeFeatureConfigurationService {

  // Fields
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private examRetakeConstantsService: IExamRetakeConstantsService;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService) {

    // Define service registry item.
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ApplicationExamRetake);
  }

  /**
 * Perform initialization tasks.
 * Called from feature-manager.service.ts
 */
  public initialize() {
    if (!this.isInitialized) {

      // Constants.
      this.configureConstants();

      // Routes.
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
  * Decorate the ConstantsService with ExamRetake-specific constants.
  */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      ExamRetakeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      ExamRetakeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.examRetakeConstantsService = this.constantsService as IExamRetakeConstantsService;
  }

  /**
   * Configure dynamic Application routes.
   */
  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/examRetakeWizard/exam-retake-wizard.module').then(x => x.ExamRetakeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/examRetakeWizard/exam-retake-wizard.module').then(m => m.ExamRetakeWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  /**
 * Returns a fully initialized exam retake wizard.
 * @param applicationTypeId 
 * @param applicationId 
 * @param applicationBasisCode
 * @param webServiceHistoryId 
 */
  public getWizard = (applicationTypeId, applicationId, applicationBasisCode, webServiceHistoryId: number) => {

    // Declarations.
    let functionType = this.functionTypeService.create((this.constantsService as IApplicationConstantsService).FUNCTION_TYPES.APPLICATION, applicationId);
    let wizardTags = this.wizardTagsService.create(applicationTypeId, applicationBasisCode);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.examRetakeConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLICATION_EXAM_RETAKE, wizardTags);

    // Get the wizard instance and START IT.
    const initializeWizard = async (): Promise<any> => {

      // Create the wizard using the wizardService.
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();

      // If there is no webServiceHistoryId for this wizard, we'll need to start one.
      if (!webServiceHistoryId) {

        // Ask the wizard manager to start the wizard (supply your function type please).
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, applicationTypeId).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    // Return as observable.
    return from(initializeWizard());
  };
}

/**
 *  ApplicationFinalFee setting constants to merge into ConstantsService.
 */
export class ExamRetakeConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      EXAM_RETAKE: 'ExamRetake.ExamRetake.Wizard',
    },
    WEB_SERVICE_PROCESS_TYPES: {
      APPLICATION_EXAM_RETAKE: LicensureOnlineServiceProcessTypeKeys.ApplicationExamRetake,
    },
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    ExamRetakeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    ExamRetakeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface IExamRetakeConstantsService extends ExamRetakeConstantsService {
}
