import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { IDomainKeywordDtoHost } from 'src/app/coreShared/core-shared.module';
import { ReachScenarios } from '../../index-constants';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from '../../index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, CommandService, ConstantsService, DomainChecklistItemService, DomainChecklistItemStatusTypeService, DynamicContentManagerService, UtilitiesService, ValidationManagerService } from '../../index-services';
import { ChecklistFileUploadComponent } from '../checklistFileUpload/checklist-file-upload.component';

export const KeywordInfoComponentSelector: string = 'keyword-info';
export const KeywordInfoComponentKey: string = 'KeywordInfo';

interface IGroupedKeywordInfo {
  groupName: string;
  definitions: string[];
}

@Component({
  selector: KeywordInfoComponentSelector,
  templateUrl: './keyword-info.component.html',
  styleUrls: ['./keyword-info.component.scss']
})
export class KeywordInfoComponent extends ChecklistFileUploadComponent implements OnInit {

  keywordGroups: IGroupedKeywordInfo[] = [];

  constructor(checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , changeDetectorRef: ChangeDetectorRef
    , protected commandService: CommandService
    , protected utilitiesService: UtilitiesService
    , protected domainChecklistItemService: DomainChecklistItemService
    , protected statusTypeService: DomainChecklistItemStatusTypeService
    , protected dynamicContentManagerService: DynamicContentManagerService) {

    super(checkboxContentService, elementRef, validationManagerService, constantsService, wizardInjector, changeDetectorRef, commandService, utilitiesService, domainChecklistItemService, statusTypeService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.wizard.selectedStep.onStepForwardStart(this.onNext);
    this.wizard.selectedStep.onStepBackStart(this.onNext);

    const keywordHost = (<IDomainKeywordDtoHost>this.wizard.model.license);
    const uniqueGroupNames: string[] = Array.from(new Set(keywordHost.DomainKeywords.map(element => element.LookupDomainKeywordItem.DomainKeywordGroup.Description)));

    uniqueGroupNames.forEach(groupName => {
      this.keywordGroups.push(
        {
          groupName: groupName,
          definitions: keywordHost.DomainKeywords
            .map(domainKeyword => domainKeyword.LookupDomainKeywordItem)
            .filter(lookupDomainKeyword => lookupDomainKeyword.DomainKeywordGroup.Description === groupName)
            .map(lookupDomainKeyword => lookupDomainKeyword.Definition)
        })
    });

  }

}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, KeywordInfoComponentKey, KeywordInfoComponent, KeywordInfoComponentSelector);
