<!-- Notifications Help Text (dynamic content) -->
<div>
  <page-summary-text [majorKey]="'Login'" [minorKey]="'Login.NotificationsTextBlock'"></page-summary-text>
</div>

<form [formGroup]="loginForm">
  <div class="login-panel">

    <!-- Heading -->
    <div class="reach-page-header">
      Login
    </div>

    <!-- Summary Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="'Login'" [minorKey]="'Login.SummaryTextBlock'"></page-summary-text>
    </div>

    <div class="p-grid p-fluid">

      <div class="p-col-12 p-sm-6">

        <!-- Username -->
        <div class="p-field">
          <label for="username">Username<field-help majorKey="Login" minorKey="Login.Username"></field-help></label>
          <div>
            <input pInputText type="text" formControlName="username" />
          </div>
        </div>

        <!-- Password -->
        <div class="p-field">
          <label for="password">Password<field-help majorKey="Login" minorKey="Login.Password"></field-help></label>
          <div>
            <input pInputText type="password" formControlName="password" />
          </div>
        </div>

        <!-- Buttons -->
        <div class="p-d-flex p-jc-end">
          <div>
            <button pButton pRipple type="button" label="Log In" [command-button]="loginCommand" style="width: auto;" command-key-binding="Enter"></button>
          </div>
        </div>


      </div>

      <!-- Navigation Links -->
      <div class="p-col-12 p-sm-6">
        <div class="p-jc-end p-ml-2">
          <online-service-link caption="More Options" [model]="onlineServiceLinkModel"></online-service-link>
        </div>
      </div>

    </div>
    
    <page-summary-text *ngIf="!loginEnabled" [majorKey]="'Login'" [minorKey]="'Login.DisabledText'"></page-summary-text>

    <!-- Additional Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="'Login'" [minorKey]="'Login.AdditionalHelpText'"></page-summary-text>
    </div>

  </div>
</form>