<p-panel [toggleable]="false" styleClass="reach-landing-panel reach-landing-detail-panel">

    <!-- Header -->
    <ng-template pTemplate="header">
        <div class="p-d-flex p-jc-between">
            <span class="p-text-left reach-list-header">Supervision Summary</span>
        </div>
    </ng-template>

        <!-- SupervisionCompleteDate -->
        <div class="p-mb-1">
            <label for="SupervisionCompleteDate">Supervision Complete Date</label>
            <span style="margin-left: 1rem;" id="SupervisionCompleteDate">{{subject.SupervisionCompleteDate | date:'MM/dd/yyyy' | whenEmpty: '-'}}</span>
        </div>

        <p-table *ngIf="supervisionTotalsByFormType" styleClass="p-datatable-sm reach-responsive-table" [value]="supervisionTotalsByFormType"
            [responsive]="true" responsiveLayout="stack">

            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>Category</th>
                    <th>Practice Hours</th>
                    <th *ngIf="!isSuspended">Supervised Hours</th>
                    <th *ngIf="!isSuspended">Clinical Contact Hours</th>
                </tr>
            </ng-template>

            <!-- Rows -->
            <ng-template pTemplate="body" let-item>
                <tr>

                    <!-- Category -->
                    <td>
                        <div class="p-d-flex">
                            <div class="p-column-title reach-col-fixed-100">Category</div>
                            <div>{{item.Form.Description | whenEmpty: '-'}}</div>
                        </div>
                    </td>

                    <!-- Practice Hours -->
                    <td>
                        <div class="p-d-flex">
                            <div class="p-column-title reach-col-fixed-100">Practice Hours</div>
                            <div>{{item.PracticeHours | whenEmpty: '-'}}</div>
                        </div>
                    </td>

                    <!-- Supervised Hours -->
                    <td *ngIf="!isSuspended">
                        <div class="p-d-flex" *ngIf="isClinical(item)">
                            <div class="p-column-title reach-col-fixed-100">Supervised Hours</div>
                            <div>{{item.Hours | whenEmpty: '-'}}</div>
                        </div>
                    </td>

                    <!-- Clinical Contact Hours -->
                    <td *ngIf="!isSuspended">
                        <div class="p-d-flex" *ngIf="isClinical(item)">
                            <div class="p-column-title reach-col-fixed-100">Clinical Contact Hours</div>
                            <div>{{item.DirectClinicalHours | whenEmpty: '-'}}</div>
                        </div>
                    </td>

                </tr>

            </ng-template>

            <!-- Empty Message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columLength">
                        <span class="reach-text-muted"><em>No entries.</em></span>
                    </td>
                </tr>
            </ng-template>

        </p-table>

</p-panel>
