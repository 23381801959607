// Directive to automatically set the autocomplete attribute to "off" on all <input> elements of any component that
// imports the directive. Doing so informs the browser to not utilize it's autocomplete behavior for the input.
//
// The behavior is disabled due to historic issues of unexpected name and address changes. Users don't always notice
// all the inputs the browser updates when using the functionality. It is also a fairly big issue on shared computers
// where multiple names and/or addresses are remembered by the browser from various users.
//
// See the following posts/examples: 
// https://stackblitz.com/edit/angular-autocomplete-off?file=src%2Fapp%2Fauto-complete.directive.ts
// https://stackoverflow.com/questions/60039825/how-to-extend-angular-form-directive-to-have-globally-autocomplete-off

import { Component, Directive, HostBinding, Input, Optional, SkipSelf } from '@angular/core';

@Directive({
  selector: 'input'
})
export class ReachAutocompleteOffDirective {

  @HostBinding('attr.autocomplete') autoComplete = 'one-time-code';
  constructor() {
  }
}
