import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IEducationChecklistItemDto,
IEducationDto } from './licensure-shared-dto';

export class EducationChecklistItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IEducationChecklistItemDto {
	Education: IEducationDto;

}
