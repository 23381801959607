import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemSettingsManagerService } from '../../core.module';
import { LandingCategories, LandingComponentKeys } from '../../index-constants';
import { Principal, SummaryComponentInfo, SummaryDetailConfiguration, SummaryAggregationType } from '../../index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../constants-provider.service';
import { DynamicRoutesService } from '../dynamic-routes.service';
import { LandingPageService } from '../landing-page.service';
import { OnlineServiceLinkManagerService } from '../online-service-link-manager.service';
import { OnlineServiceMenuService } from '../online-service-menu.service';
import { SummaryInfoBuilderService } from '../summary-info-builder.service';
import { SummaryInfoManagerService } from '../summary-info-manager.service';
import { UserManagerService } from '../user-manager.service';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from './default-provider-configuration.service';
import { IInvoiceDto } from 'src/app/coreShared/core-shared.module';

@Injectable({ providedIn: 'root' })
export class InvoiceLandingConfigurationService {

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , protected userManagerService: UserManagerService
    , protected landingPageService: LandingPageService
    , protected summaryInfoBuilderService: SummaryInfoBuilderService
    , protected summaryInfoManagerService: SummaryInfoManagerService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , protected onlineServiceMenuService: OnlineServiceMenuService
    , protected dynamicRoutesService: DynamicRoutesService
    , protected router: Router
  ) {

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserCartRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  private addInfosToLandingPage(principal: Principal): void {

    let isFeatureEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.INVOICE.ENABLED);
    if (isFeatureEnabled
      && principal?.user?.UserAccount) {
      let summaryDetailConfiguration = new SummaryDetailConfiguration(LandingCategories.Invoice
        , LandingComponentKeys.InvoiceSummary
        , LandingComponentKeys.InvoiceDetail
        , SummaryAggregationType.Singleton
      );

      let infos: SummaryComponentInfo[] = [];
      let infoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Invoice", summaryDetailConfiguration);

      principal.user.UserAccount.profile.Invoices.forEach((invoice: IInvoiceDto, index: number)=> {
        infoBuilder.addItem(index
          , "Invoice"
          , invoice
          , this.constantsService.DOMAIN_IDS.INVOICE
          , this.constantsService.CARD_DISPLAY_ORDER.INVOICE
        );
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserCartRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}
