import { IDomainChecklistItemDto,
IDomainReasonForChangeDtoHost,
IEntityAddressDto,
IEntityCommentDto,
IEntityDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEducationProgramFacultyDto,
IEducationProgramLevelDto,
IEducationDto,
IEducationProgramDto } from './licensure-shared-dto';

export class EducationProgramDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEducationProgramDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	DirectorEntityId?: number;
	DirectorEntity: IEntityDto;
	EntityId?: number;
	StatusId?: number;
	StatusModifiedBy: string;
	StatusModifiedDate?: Date;
	EducationProgramApplicationId?: number;
	GrantDate?: Date;
	ScheduledSurveyDate?: Date;
	IsAccredited: boolean;
	AccreditedExpireDate?: Date;
	ProgramCode: string;
	EducationProgramLevelId?: number;
	City: string;
	State: string;
	Country: string;
	ProgramTypeCode: string;
	Entity: IEntityDto;
	EducationProgramLevel: IEducationProgramLevelDto;
	Status: any;
	IsInactive: boolean;
	EducationItems: IEducationDto[];
	EducationProgramFaculties: IEducationProgramFacultyDto[];
	IsOnlinePhnProgramApproved: boolean;
	IsOnlineProgramApproved: boolean;
	IsPhnEducationAutoComplete: boolean;
	ReferenceValue: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	StorageOfRecords: string;
	CopiesOfRecords: string;
	AddressTypeId: number;
	Addresses: IEntityAddressDto[];
	AddressMailing: IEntityAddressDto;
	AddressPublic: IEntityAddressDto;
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;

}
