
import { Component, Inject, Optional, OnInit, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _l from 'lodash-es';
import * as _ from 'underscore';
import { ChangeDetectorRef } from '@angular/core';
import { ReachScenarios, LandingComponentKeys } from 'src/app/core/index-constants';
import { OnlineServiceLinkSubscription, OnlineServiceProcessTypes, registerDynamicComponent } from 'src/app/core/index-models';
import { UtilitiesService, ValidationManagerService, SystemSettingsManagerService, SummaryInfoManagerService, NotificationCenterManagerService, OnlineServiceLinkManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, BusyManagerService, InvoiceService } from 'src/app/core/index-services';
import { LandingDetailBaseComponent } from '../landingBase/landing-detail-base.component';
import { IInvoiceDto, InvoiceDto } from 'src/app/coreShared/core-shared.module';
import { forkJoin, from, of } from 'rxjs';

export const InvoiceLandingSelector = 'invoice-landing';
@Component({
  selector: InvoiceLandingSelector,
  templateUrl: './invoice-landing.component.html',
  styleUrls: ['./invoice-landing.component.scss']
})
export class InvoiceLandingComponent extends LandingDetailBaseComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  pageSummaryMajorKey: string;
  pageSummaryMinorKey: string;
  title: string;
  invoiceDto: IInvoiceDto = null;
  dataLoaded: boolean;

  constructor(private utilitiesService: UtilitiesService
    , private busyManagerService: BusyManagerService
    , private invoiceService: InvoiceService
    , private validationManagerService: ValidationManagerService
    , private readonly changeDetectorRef: ChangeDetectorRef
    , private systemSettingsManagerService: SystemSettingsManagerService
    , summaryInfoManagerService: SummaryInfoManagerService
    , activatedRoute: ActivatedRoute
    , notificationCenterManagerService: NotificationCenterManagerService
    , onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
  ) {

    super(constantsService
      , notificationCenterManagerService
      , onlineServiceLinkManagerService
      , reachModelConfigurationInjector
      , activatedRoute
      , summaryInfoManagerService
      , constantsService.DOMAIN_IDS.INVOICE);

    this.onlineServiceLinkSubscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Invoice, () => { return this.domainKeyId; }));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.pageSummaryMajorKey = this.majorKey;
    this.title = "Invoice Information";
    this.initCommands();

    const doInit = () => { return this.loadInvoice(); }
    this.busyManagerService.resolve(doInit(), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  private loadInvoice() {

    const doLoad = async (): Promise<any> => {
      let responseCollection = await forkJoin([this.invoiceService.getById(this.model.Id)]).toPromise();
      this.invoiceDto = responseCollection[0];
      this.dataLoaded = true;
      return of(true).toPromise();
    };

    return from(doLoad());
  }

  ngAfterViewInit(): void { }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void { }
  initCommands() { }
}

registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.InvoiceDetail, InvoiceLandingComponent, InvoiceLandingSelector);
