import {  } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IEmploymentPositionTitleDto } from './licensure-shared-dto';

export class EmploymentPositionTitleDto extends BaseDto implements IEmploymentPositionTitleDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;

	IsAddableOnline: boolean;
	IsEditableOnline: boolean;
	IsDisplayedInEntitySearchDetails: boolean;
	ApplicableProfessionTypeCodes: string;

	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
}
