import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as _l from 'lodash-es';
import * as _ from 'underscore';
import { BusyChanagedEventArgument, BusyEntry } from '@coreModels/busy';
import { ValidationManagerService } from './validation-manager.service';

/**
 * Provides synchronization services for sets of long running tasks.
 */
@Injectable({ providedIn: 'root' })
export class BusyManagerService {
  private inProcessRouteNavHandle = null;
  private busyStack: BusyEntry[] = [];
  private _busyChanged$: BehaviorSubject<BusyChanagedEventArgument> = new BehaviorSubject<BusyChanagedEventArgument>(new BusyChanagedEventArgument());
  // The event fired when the busy state changes.
  public busyChanged$ = this._busyChanged$.asObservable();

  constructor(
    private validationManagerService: ValidationManagerService
  ) {

  }

  /**
   * Clears the BusyStack.
   */
  public clear() {
    this.busyStack.length = 0;
    this.raiseBusyChangedEvent();
  }

  /**
   * Returns true if any entries of the specified BUSY_MANAGER_BUSY_TYPES exist on the BusyStack.
   * @param busyType the BusyType of the entries to seek. If null, all entries will be searched.
   */
  public isBusy(busyType?: number): boolean {
    if (!busyType) {
      return this.busyStack.length > 0;
    }

    return _.any(this.busyStack, (entry: BusyEntry) => {
      return entry.busyType === busyType;
    });
  }

  public isBusy$(busyType?: number): Observable<boolean> {
    return of(this.isBusy(busyType));
  }

  /**
   * Removes the specified BusyEntry from the stack.
   * @param busyEntry the entry to remove.
   */
  public pop(busyEntry: BusyEntry) {
    _l.remove(this.busyStack, (entry: BusyEntry) => {
      return entry.id === busyEntry.id;
    });

    this.raiseBusyChangedEvent();
  }

  /**
   * Creates a new BusyEntry of the specified BUSY_MANAGER_BUSY_TYPES and adds it to the BusyStack.
   * @param busyType the BUSY_MANAGER_BUSY_TYPES of entry.
   */
  public push(busyType: number) {
    var busyEntry = BusyEntry.create(busyType);
    this.busyStack.push(busyEntry);
    this.raiseBusyChangedEvent();
    return busyEntry;
  }

  /**
   * Resolves the busy promise for the specified busy type against the specified function.
   * @param resolveFunction the function to invoke against the resolution.
   * @param busyType the BUSY_MANAGER_BUSY_TYPES.
   */
  public resolve<T extends Subscription | Promise<unknown> | Observable<unknown>>(waitObject: T, busyType: number, subscriptionClosedHandler: () => void = null): T {
    let sub: Subscription | undefined;
    if (!waitObject) {
      return; // nothing to wait on
    }

    const doResolve = (handle: BusyEntry) => {
      if (handle != null) {
        this.pop(handle);
      }

      if (subscriptionClosedHandler != null) {
        subscriptionClosedHandler();
      }
    };

    // Get the Subscription for whatever type of input SyncObject we have
    if (waitObject instanceof Subscription) {
      sub = waitObject as Subscription;
    }
    else if (waitObject instanceof Promise) {
      const wrapped = from(waitObject);
      sub = wrapped.pipe(take(1)).subscribe();
    }
    else if (waitObject instanceof Observable) {
      sub = waitObject.pipe(take(1)).subscribe();
    }

    if (!sub.closed) {
      // If the Subscription is not already closed, push the associated BusyType onto the BusyStack to indicate that the system is busy because of this reason.
      var handle = this.push(busyType);

      // Add a child Subscription to the Subscription that will pop the entry off the BusyStack for the handle/BusyType when the operation completes.
      sub.add(() => doResolve(handle));
    } else {

      // If the Subscription is already closed, just resolve to execute subscriptionClosedHandler
      doResolve(null);
    }

    // Return the original sync object. This method is subscriber/watcher, not an wait, and only watches whether the operation is complete so it can clear the busy status.
    // It does not block.
    return waitObject;
  }

  /**
   * Fires the "busyManager.busyChanged" event to indicate BusyEntry objects have been added or removed from the BusyStack.
   */
  private raiseBusyChangedEvent() {
    setTimeout(() => {
      this._busyChanged$.next(new BusyChanagedEventArgument(this.busyStack));
    });
  }

  // Watches the router events for active route navigation and sets the busy stack accordingly.
  public watchForNavigation(router: Router, busyType: number) {
    router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          if (this.inProcessRouteNavHandle) {
            this.pop(this.inProcessRouteNavHandle);
          }
          this.inProcessRouteNavHandle = this.push(busyType);

          setTimeout(() => {
            this.validationManagerService.clearApplicationValidationErrors();
          }, 0);

          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        if (this.inProcessRouteNavHandle) {
          this.pop(this.inProcessRouteNavHandle);
        }
      });
  }
}

