/**
 * Notification toggle event arg object.
 */
export class ToggleNotificationsEventArgument {
  constructor(public show, public notificationScope) { }
}

/**
 * Notification toggle event arg object.
 */
export class NotificationsChangedEventArgument {
  constructor(public notificationEntries) { }
}

