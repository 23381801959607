import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { Command, DialogSettings, ReachModelAwareComponent, ReachScenarios } from 'src/app/core/core.module';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, UtilitiesService } from 'src/app/core/index-services';
import { SupervisionService } from 'src/app/licensureCore/licensure-core.module';
import { ISupervisorDto } from 'src/app/licensureShared/licensure-shared.module';
import { SupervisorLookupModel } from '../../models/supervisor-lookup-model';
import { SupervisorLookupComponent } from '../supervisorLookup/supervisor-lookup.component';

@Component({
    selector: 'supervisor-selector-field',
    templateUrl: './supervisor-selector-field.component.html',
    styleUrls: ['./supervisor-selector-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SupervisorSelectorFieldComponent),
            multi: true
        }
    ]
})
export class SupervisorSelectorFieldComponent extends ReachModelAwareComponent implements OnInit, OnChanges {
    private _supervisor: ISupervisorDto;

    @Input()
    public set supervisor(supervisor: ISupervisorDto) {
        this._supervisor = Object.assign({}, supervisor);
    }

    public get supervisor() {
        return this._supervisor;
    }

    @Output()
    supervisorChanged: EventEmitter<ISupervisorDto> = new EventEmitter<ISupervisorDto>();

    public searchCommand: Command;
    public dialogSettings: DialogSettings;
    public contentForm: FormGroup;

    constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
        , private controlContainer: ControlContainer
        , private utilitiesService: UtilitiesService
        , private commandService: CommandService
        , private supervisionService: SupervisionService
        , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector) {
        super(reachModelConfigurationInjector);
        this.contentForm = new FormGroup({ Supervisor: new FormControl(null, [Validators.required, Validators.maxLength(100)]) });
        let parent = this.controlContainer.control as FormGroup;
        parent.addControl('SupervisorSelectorField', this.contentForm);
    }

    ngOnInit(): void {
        this.initCommands();
    }

    ngOnChanges(): void {
        this.contentForm.get("Supervisor").setValue(this.supervisor?.EntityInfo?.FullName);
        this.utilitiesService.enableDisable(this.contentForm, (this.supervisor != null));
    }

    private initCommands(): void {
        this.searchCommand = this.commandService.create(() => {
            return this.canSearchCommandExecute();
        }, () => {
            let lookupModel = new SupervisorLookupModel();
            this.presentSearch(lookupModel);
        });
    }

    public canSearchCommandExecute(): boolean {
        return true;
    }

    public presentSearch(supervisorLookupModel: SupervisorLookupModel) {
        this.dialogSettings = this.getDialogSettings(supervisorLookupModel);
        this.dialogSettings.isOpen = true;
    }

    public getDialogSettings(supervisorLookupModel: SupervisorLookupModel): DialogSettings {
        let okCommand = this.commandService.create(() => {
            return true;
        }, async (supervisorLookupModel: SupervisorLookupModel) => {
            if (supervisorLookupModel.supervisorSearchResults != null && supervisorLookupModel.supervisorSearchResults.length > 0) {
                const result = supervisorLookupModel.supervisorSearchResults[0];
                const supervisor = await this.supervisionService.getSupervisorById(result.Id).toPromise();
                this.update(supervisor);
            }
        });

        let dialogSettings = new DialogSettings(null
            , ReachScenarios.Default
            , SupervisorLookupComponent
            , SupervisorLookupComponent.name
            , "Supervisor Search"
            , supervisorLookupModel
            , false
            , false
            , true);

        dialogSettings.okCommand = okCommand;
        return dialogSettings;
    }

    protected update(supervisor: ISupervisorDto): void {
        this.supervisorChanged.emit(supervisor);
    }

    // ==================================== CVA ====================================
    onChange: any = () => { };
    onTouched: any = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
        this.contentForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value): void { }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.contentForm.disable() : this.contentForm.enable();
    }
}


