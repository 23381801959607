import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreSharedModule } from '@coreShared/core-shared.module';

// Export DTOs
export * from './dto-gen/licensure-shared-dto';
export * from './index';

@NgModule({
  declarations: [],
  imports: [
      CommonModule,
      CoreSharedModule
  ],
  exports: [
    CoreSharedModule
  ]
})
export class LicensureSharedModule { }
