<div *ngIf="wizard.model.domainAudit.IsExempt">

    <!-- Dynamic Content -->
    <div class="p-col-12">
        <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
            [minorKey]="'FileUpload.SummaryTextBlock.IsExempt'"></page-summary-text>
    </div>

</div>

<div [formGroup]="stepForm" *ngIf="!wizard.model.domainAudit.IsExempt">

    <!-- Dynamic Content -->
    <div class="p-col-12">
        <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
            [minorKey]="'FileUpload.SummaryTextBlock'"></page-summary-text>
    </div>

    <!-- File Upload -->
    <file-upload-list formControlName="FileUploadList" [model]="wizard.model.fileUploadHost"></file-upload-list>

</div>