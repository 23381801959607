import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent } from 'src/app/core/index-directives';
import { Command, DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, EntityGroupMemberService, ValidationManagerService } from 'src/app/core/index-services';
import { EntityGroupMemberSearchResultItemDto, ICountryDto, IEntityGroupMemberSearchCriteriaDto, IStateDto } from 'src/app/coreShared/core-shared.module';
import { IEducationDto } from 'src/app/licensureShared/licensure-shared.module';

@Component({
  selector: 'school-lookup',
  templateUrl: './school-lookup.component.html',
  styleUrls: ['./school-lookup.component.scss']
})
export class SchoolLookupComponent extends ReachDialogContentComponent<IEducationDto> {

  USA: string = "USA";
  MN: string = "MN";
  CANADA: string = "CANADA";
  OTHER: string = "OTHER";

  contentForm: FormGroup;
  selectedSchoolId: number;
  searchResults: EntityGroupMemberSearchResultItemDto[] = [];
  searchCommand: Command;
  filteredStates: IStateDto[];

  protected get otherMode(): boolean { return this.contentForm.get('Country').value?.Description == this.OTHER; }
  public get stateLabel(): string { return this.contentForm.get('Country').value?.Description == this.CANADA ? "Province" : "State"; }
  public get possibleCountries(): ICountryDto[] { return this.hostDialogData.initializationData.possibleCountries; }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    private formBuilder: FormBuilder,
    private entityGroupMemberService: EntityGroupMemberService,
    private commandService: CommandService
  ) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  protected override modelToForm(): void {

    this.contentForm = this.formBuilder.group({
      Country: [null],
      State: [null],
      City: [null],
      SchoolNotFound: [{ value: null, disabled: true }],
      SelectedSchool: [null, Validators.required] // A formControl element that indicates if an item is selected from the list.
    });

    this.friendlyNames.SelectedSchool = "School Selection";

    super.modelToForm();
  }

  protected override initDropdowns(): void {

    this.filteredStates = this.hostDialogData.initializationData.possibleStates;

    if (this.outputModel.IsNew) {
      this.contentForm.get('Country').setValue(this.possibleCountries?.find(item => item.Description == this.USA));
      this.contentForm.get('State').setValue(this.filteredStates?.find(item => item.StateCode == this.MN));
    }

    super.initDropdowns();
  }

  protected override initCommands(): void {
    this.searchCommand = this.commandService.create(this.canSearchCommandExecute, this.searchCommandExecute);
    super.initCommands();
  }

  protected override initializeEventHandlers(): void {
    super.initializeEventHandlers();

    this.contentForm.get('Country').valueChanges.subscribe(country => {
      this.filteredStates = country?.Description === this.CANADA ? this.hostDialogData.initializationData.possibleProvinces : this.hostDialogData.initializationData.possibleStates;
    })

    this.contentForm.get("SchoolNotFound").valueChanges.subscribe(schoolNotFound => {

      if (schoolNotFound == true) {
        this.clearSearchResults();
        this.handleSchoolSelection(true);
        this.contentForm.get('SelectedSchool').disable();
      }
      else this.contentForm.get('SelectedSchool').enable();

    });
  }

  protected clearSearchResults(): void { this.searchResults = []; }

  onRowSelect(event) {
    this.contentForm.get('SchoolNotFound').setValue(null);

    this.selectedSchoolId = event.data.Id;
    this.contentForm.get("SelectedSchool").setValue(this.selectedSchoolId);

    this.handleSchoolSelection();
  }

  onRowUnselect(event) {
    this.selectedSchoolId = null;
    this.contentForm.get("SelectedSchool").enable();
    this.contentForm.get("SelectedSchool").setValue(null);
  }

  handleSchoolSelection(schoolNotFound: boolean = false): void {
    if (schoolNotFound) {

      this.outputModel.SchoolEntityId = null;
      this.outputModel.SchoolName = null;

      // Map Country/State that the user WAS searching for.
      this.outputModel.SchoolCountry = this.contentForm.get('Country').value?.Description;
      this.outputModel.SchoolState = this.contentForm.get('State').value?.StateCode;
    }

    let selectedSchool: EntityGroupMemberSearchResultItemDto = this.searchResults.find(item => item.Id == this.selectedSchoolId);
    if (!selectedSchool) return;
    this.outputModel.SchoolEntityId = this.selectedSchoolId;
    this.outputModel.SchoolName = selectedSchool.FullName;
    this.outputModel.SchoolCity = selectedSchool.City;
    this.outputModel.SchoolState = selectedSchool.State;
    this.outputModel.SchoolCountry = selectedSchool.Country;
  }

  protected canSearchCommandExecute = (): boolean => { return true; }

  protected getSearchCriteria = (): IEntityGroupMemberSearchCriteriaDto => {
    return {
      Country: this.contentForm.get('Country').value?.Description ?? "",
      State: this.contentForm.get('State').value?.StateCode ?? "",
      City: this.contentForm.get('City').value ?? "",
      GroupId: this.hostDialogData.initializationData.entityGroupId ?? null,
      SchoolName: "",
      Zip: ""
    } as IEntityGroupMemberSearchCriteriaDto;
  }

  protected searchCommandExecute = () => {
    const doSearch = async (): Promise<any> => {

      this.searchResults = (await this.entityGroupMemberService.search(this.getSearchCriteria()).toPromise()).Results;
      this.contentForm.get('SchoolNotFound').setValue(null);
      this.contentForm.get('SchoolNotFound').enable();

      return of(true);
    }

    return from(doSearch());
  }
}

registerDynamicComponent(ReachScenarios.Default, 'SchoolLookupComponent', SchoolLookupComponent, 'school-lookup');
