import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { retry, catchError, tap, map } from "rxjs/operators";

import { IHttpResponseMessage, IHttpResponseContent, WebServiceDto } from '@coreShared/core-shared.module';
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UtilitiesService } from './utilities.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineServiceService extends ReachHttpClientService {
    uri: string;
    constructor(
      private argumentExceptionService: ArgumentExceptionService,
      busyManagerService: BusyManagerService,
      @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
      @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
      http: HttpClient,
      private utilitiesService: UtilitiesService,
      validationManagerService: ValidationManagerService
    ) {
      super(
        busyManagerService,
        constantsService,
        defaultProviderConfigurationService,
        http,
        validationManagerService
      );

      this.uri = `${this.apiRootUri}/webService`;
    }

    public search(query) {
      // Must have a query
      if (!query) {
        this.argumentExceptionService.create("query").log();
      }

      return this.get<WebServiceDto>(`${this.uri}/search?${query}`);
    }
}
