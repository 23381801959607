import { Component, Inject, Input, OnInit, Optional, OnChanges, SimpleChanges } from '@angular/core';
import { of } from "rxjs";
import * as _l from 'lodash-es';
import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { DialogSettings } from '@coreModels/dialog-settings';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { PickListEditorComponent, PickListEditorComponentKey } from './editor/pick-list-editor.component';
import { ObservablePicklistModel } from '../models/observable-pick-list-model';

export const PickListComponentSelector = 'pick-list';
export const PickListComponentKey = 'pickList';

@Component({
  selector: PickListComponentSelector,
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss']
})
export class PickListComponent extends ReachModelAwareComponent implements OnInit, OnChanges {

  @Input() observableModel: ObservablePicklistModel;
  @Input() displayKey: boolean = false;

  filterSummary = null;
  pickListSettings: DialogSettings;

  okCommand: Command;
  presentItemEditorCommand: Command;
  removeItemCommand: Command;

  constructor(
    protected commandService: CommandService,
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
  ) {

    // Base.
    super(null);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  */
  ngOnChanges(changes: SimpleChanges) {
    this.model = this.observableModel.getModel();
    this.initCommands();
    // This method is invoked whenever the @Input-decorated variables have value changes (as they are initialized higher up the stack).
    super.onInputChanges(changes);
  }

  /**
  * Initializes commands for this instance.
  */
  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (item: any) => {
    this.filterSummary = item.toString();
    this.observableModel.updateSelections(item.selectedItems);
    return of(true);
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (item: any) => {

    let dialogModel = this.observableModel.cloneModel();
    // Title for dialog
    this.filterSummary = null;
    let settings = new DialogSettings(
      null,
      ReachScenarios.Default,
      PickListEditorComponent,
      PickListEditorComponentKey,
      this.model.friendlyName,
      dialogModel);

    settings.initializationData.displayKey = this.displayKey;

    settings.okCommand = this.okCommand;
    settings.isOpen = true;
    this.pickListSettings = settings; // make the new dialog settings instance the current instance and trigger an ngChanges for the dialog component

  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return this.model;
  }

  protected removeItemCommandExecute = (item: any) => {
    this.model.clearSelection();
    this.filterSummary = this.model.toString();
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.DataRequestAdHocWizard, PickListComponentKey, PickListComponent, PickListComponentSelector);

