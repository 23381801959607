import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SupervisionService } from "src/app/licensureCore/licensure-core.module";
import { IApplicationDto, ILicenseDto, ISupervisionSummaryDto } from "src/app/licensureShared/licensure-shared.module";

@Component({
  selector: 'supervision-summary',
  templateUrl: './supervision-summary.component.html',
  styleUrls: ['./supervision-summary.component.scss']
})
export class SupervisionSummaryComponent implements OnChanges {

  @Input()
  license: ILicenseDto;
  @Input()
  application: IApplicationDto;

  public isSuspended: boolean;
  public supervisionTotalsByFormType: any[];

  public get subject(): ILicenseDto | IApplicationDto {
    return this.license ?? this.application;
  }

  public get columLength(): number {
    if (this.isSuspended) {
      return 2;
    }
    return 4;
  }

  isClinical(item: any): boolean {
    return item.Form.IsClinical;
  }

  constructor(protected supervisionService: SupervisionService) { }

  ngOnChanges(): void {

    if (this.application) {
      this.isSuspended = this.application.IsSuspendSupervisionDetail ?? false;
      this.supervisionService.getSummaryByApplicationId(this.application.Id)
        .subscribe((supervisionSummary: ISupervisionSummaryDto) => {
          this.calculateTotals(supervisionSummary);
        });
    }
    else if (this.license) {
      this.isSuspended = this.license.IsSuspendSupervisionDetail ?? false;
      this.supervisionService.getSummaryByLicenseId(this.license.Id)
        .subscribe((supervisionSummary: ISupervisionSummaryDto) => {
          this.calculateTotals(supervisionSummary);
        });
    }
  }

  private calculateTotals(supervisionSummary: ISupervisionSummaryDto): void {

    this.supervisionTotalsByFormType = [];
    const groupedByFormId: Record<number, SummaryItem[]> = {};

    for (const x of supervisionSummary.TotalHoursByType) {
      // 'x' is an ISupervisionVerificationHoursSummaryDto
      if (!groupedByFormId[x.Form.Id]) {
        groupedByFormId[x.Form.Id] = []
      };

      groupedByFormId[x.Form.Id].push(x);
    }

    for (const formId in groupedByFormId) {
      if (groupedByFormId.hasOwnProperty(formId)) {
        const group = groupedByFormId[formId];
        let totalHours = 0;
        let totalPracticeHours = 0;
        let totalDirectClinicalHours = 0;

        for (const item of group) {
          totalHours += item.ApprovedHours;
          totalPracticeHours = item.PracticeHours;
          totalDirectClinicalHours = item.DirectClinicalHours;
        }

        if (!group[0].Form.IsClinical && totalPracticeHours > 4000) totalPracticeHours = 4000; // Cap Non-Clinical practice hours at 4000

        this.supervisionTotalsByFormType.push({
          Hours: totalHours,
          PracticeHours: totalPracticeHours,
          DirectClinicalHours: totalDirectClinicalHours,
          Form: group[0].Form,
        });
      }
    }
  }
}

interface SummaryItem {
  ApprovedHours: number;
  PracticeHours: number;
  DirectClinicalHours: number;
  Form: { Id: number; IsClinical: boolean; };
}
