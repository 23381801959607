import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whenEmpty'
})
export class WhenEmptyPipe {
    transform(value: string, args: string): string {
        return value ? value : args;
    }
}
