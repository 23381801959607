<div class="col-md-12" *ngIf="shouldRender">
  <div class="error-template">
    <h2>404 Not Found</h2>
    <div class="error-details mb-3">
      Sorry, the requested page was not found!
    </div>
    <div class="error-actions">
      <a [routerLink]="landingUrl" class="btn btn-danger btn-lg">
        Go Back to Home
      </a>
    </div>
  </div>
</div>
