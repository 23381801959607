import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DocumentService, ReachHttpClientService, ValidationManagerService } from "@core/core.module";
import { ApplicationDto, EducationProgramApprovalListDto, IApplicationDto } from "@licensureShared/licensure-shared.module";
import { Observable } from "rxjs";
import { concatMap, map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ApplicationService extends ReachHttpClientService {
  private uri: string;
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    private documentService: DocumentService
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService
    );

    this.uri = `${this.apiRootUri}/licensure/application`;
  }

  public commitEducationProgramApprovals(educationProgramApprovalList) {
    return this.put<EducationProgramApprovalListDto>(`${this.uri}/EducationProgramApprovalList/Commit`, educationProgramApprovalList);
  }

  public initializeApplication(entityId: number): Observable<IApplicationDto> {

    const createNewApplication = (): Observable<ApplicationDto> => { return this.get<ApplicationDto>(`${this.uri}/initialize`); };

    const saveApplication = (application: ApplicationDto): Observable<ApplicationDto> => {
      application.EntityId = entityId;
      return this.post<ApplicationDto>(`${this.uri}/initialize/item`, application);
    };

    return createNewApplication().pipe(mergeMap((val: ApplicationDto) => saveApplication(val)));
  }

  public getById(id: number): Observable<ApplicationDto> {
    return this.get<ApplicationDto>(`${this.uri}/${id}`);
  }

  save(application: IApplicationDto): Observable<IApplicationDto> {
    const firstObservable = this.documentService.uploadAllDocuments(application);
    const secondObservable = application.Id
      ? this.put<ApplicationDto>(this.uri, application)
      : this.post<ApplicationDto>(this.uri, application);

    const result: Observable<IApplicationDto> = firstObservable.pipe(concatMap(() => {
      return secondObservable.pipe(map(res => { return res; }))
    }));

    return result;
  }
}
