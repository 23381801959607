import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { ILicenseVerificationRequestDto, LicenseVerificationRequestDto } from '@licensureShared/licensure-shared.module';
import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';

@Injectable({ providedIn: 'root' })
export class LicenseVerificationService extends ReachHttpClientService {

  // FIELDS
  uri: string = null;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    busyManagerService: BusyManagerService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    utilitiesService: UtilitiesService
  ) {

    // Base
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/LicenseVerificationRequest", this.apiRootUri);
  }

  /**
 * Saves the verificationrequest.
 * @param verificationrequest
 */
  public submitVerificationRequest(verificationrequest: ILicenseVerificationRequestDto): Observable<ILicenseVerificationRequestDto> {

    // POST
    return this.post<LicenseVerificationRequestDto>(this.uri, verificationrequest);
  }
}
