<!-- Grid -->
<div [formGroup]="contentForm">

  <!-- SSN (Auto Focus) -->
  <div *ngIf="giveFocus" class="p-field p-mb-0">
    <label for="Ssn">{{dynamicLabel}}</label>
    <p-inputMask id="Ssn" [mask]="dynamicInputMask" [characterPattern]="dynamicRegExPattern" type="text"
      formControlName="Ssn" [disabled]="hasNoSsn" autofocus></p-inputMask>

    <!-- Has No SSN -->
    <div class="p-field-checkbox p-mt-2 p-mb-0" *ngIf="isSsnOptional">
      <p-checkbox inputId="HasNoSsn" formControlName="HasNoSsn" binary="true"></p-checkbox>
      <label for="HasNoSsn">I don't have a SSN</label>
    </div>

  </div>

  <!-- SSN (Standard Edition) -->
  <div *ngIf="!giveFocus" class="p-field p-mb-0">
    <label for="Ssn">{{dynamicLabel}}</label>
    <p-inputMask id="Ssn" [mask]="dynamicInputMask" [characterPattern]="dynamicRegExPattern" type="text"
      formControlName="Ssn" [disabled]="hasNoSsn"></p-inputMask>

    <!-- Has No SSN -->
    <div class="p-field-checkbox p-mt-2 p-mb-0" *ngIf="isSsnOptional">
      <p-checkbox inputId="HasNoSsn" formControlName="HasNoSsn" binary="true"></p-checkbox>
      <label for="HasNoSsn">I don't have a SSN</label>
    </div>

  </div>

</div>