import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { IExamBasisDto,
IExamTypeDto } from './licensure-shared-dto';

export class ExamTypeDto extends LookupDto implements IExamTypeDto {
	ExamPosition: number;
	IsCertificationApplicable: boolean;
	IsNarmApplicable: boolean;
	IsNataApplicable: boolean;
	IsCertificationDatesApplicable: boolean;
	IsCsaDateApplicable: boolean;
	IsPassFailApplicable: boolean;
	IsScoreApplicable: boolean;
	IsDateApplicable: boolean;
	IsStateApplicable: boolean;
	AttemptNumberApplicable: boolean;
	ParentExamId?: number;
	IsHistoric: boolean;
	MaxScore?: number;
	PassingScore?: number;
	Bases: IExamBasisDto[];
	Sections: IExamTypeDto[];

}
