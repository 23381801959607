import { IActivityRelatedToDto,
IActivityStatusDto,
IActivitySubactivityDto,
IBaseDto,
IOnlineActivityInfoDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class OnlineActivityInfoDto extends BaseDto implements IBaseDto, IOnlineActivityInfoDto {
	EntityId?: number;
	StartDate?: Date;
	EndDate?: Date;
	ExpectedDate?: Date;
	StatusId?: number;
	OnlineComment: string;
	Status: IActivityStatusDto;
	ActivitySubactivityId?: number;
	ActivitySubactivity: IActivitySubactivityDto;
	UnitId: number;
	Unit: any;
	RelatedTo: IActivityRelatedToDto[];

}
