import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IAutoVerificationSubscriberLicenseListItemDto } from './licensure-shared-dto';

export class AutoVerificationSubscriberLicenseListItemDto extends BaseDto implements IBaseDto, IAutoVerificationSubscriberLicenseListItemDto {
	LicenseId: number;
	EntityId: number;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	City: string;
	LicenseType: string;
	LicenseTypeDescription: string;
	LicenseNumber: number;
	LicenseStatus: string;
	LicenseStatusDescription: string;
	ReferenceValue: string;

}
