import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { of, forkJoin, from } from 'rxjs';

import * as _l from 'lodash-es';

import {
  CheckboxContentService,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  ValidationManagerService,
  WizardInjector,
  WIZARD_INJECTOR_TOKEN,
  WizardStepComponent,
  ReachControlValidators,
  ReachScenarios,
  registerDynamicComponent,
  BusyManagerService,
  ListService,
  DynamicContentManagerService,
  UtilitiesService,
  ReachApplicationService
} from '@core/core.module';
import { IMilitaryResponseTypeDto, IOnlineUserDto } from 'src/app/coreShared/core-shared.module';

// CONSTANTS
export const MilitaryStatusComponentSelector: string = 'military-status';

@Component({
  selector: MilitaryStatusComponentSelector,
  templateUrl: './military-status.component.html',
  styleUrls: ['./military-status.component.scss']
})
export class MilitaryStatusComponent extends WizardStepComponent implements OnInit, AfterViewInit, OnDestroy {

  // FIELDS
  hasBeenMilitary: boolean;
  protected isIgnoreAutoApply: boolean;
  militaryResponseTypes: IMilitaryResponseTypeDto[];

  // PROPERTIES
  public get selectedMilitaryResponseType(): IMilitaryResponseTypeDto { return this.stepForm.get('MilitaryResponseType').value }
  public get entity(): IOnlineUserDto { return this.wizard.model.entity as IOnlineUserDto }

  // CTOR
  constructor(
    // WizardStepComponent
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,

    // Custom
    protected listService: ListService,
    protected busyManagerService: BusyManagerService,
    protected dynamicContentManagerService: DynamicContentManagerService,
    protected utilitiesService: UtilitiesService,
    public reachApplicationService: ReachApplicationService) {

    // Base.
    super(constantsService, validationManagerService, wizardInjector, "Military", checkboxContentService, elementRef);

    // Initialize form fields from model properties.
    this.modelToForm();
  }

  public override ngOnInit(): void {

    // Friendly names
    this.friendlyNames.MilitaryResponseType = 'Response';
    this.friendlyNames.DischargeDate = 'Discharge Date';

    // Base.
    super.ngOnInit();

    this.loadLookupLists();
  }

  // Overrides the base class method to provide specified mapping from the input model (wizard model) to
  // the reactive stepForm: FormGroup for this instance.
  protected modelToForm() {

    // Add custom fields and validators to our stepform.        
    this.stepForm.addControl('MilitaryResponseType', new FormControl(null, [Validators.required, ReachControlValidators.militaryResponseTypeValidator(this.entity, this.isIgnoreAutoApply)]));
    this.stepForm.addControl('DischargeDate', new FormControl((this.entity.MilitaryDischargeDate ? new Date(this.entity.MilitaryDischargeDate) : null), [Validators.required, ReachControlValidators.futureDateValidator('Discharge Date'), ReachControlValidators.pastDateValidator('Discharge Date')]));

    // Wire event handlers for the content form.
    this.initializeEventHandlers();

    super.modelToForm();
  }

  // Maps this instance's stepForm FormGroup to the wizard model. 
  protected formToModel() {

    if (!this.dataLoaded) return;

    let x = this.selectedMilitaryResponseType;
    if (!this.isIgnoreAutoApply && x?.AutoApplyEntityMilitary) this.entity.IsMilitary = x?.IsMilitary;
    this.entity.MilitaryResponseTypeId = x?.Id;
    this.entity.MilitaryDischargeDate = this.stepForm.get('DischargeDate').value;

    this.updateBag();
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // Military Response Type
    this.stepForm.get('MilitaryResponseType').valueChanges.subscribe(x => {
      this.utilitiesService.enableDisable(this.stepForm.get('DischargeDate'), this.selectedMilitaryResponseType?.DischargeDateApplicable);
    });

  }

  dataLoaded: boolean = false;
  private loadLookupLists(): void {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getMilitaryResponseTypes()]).toPromise();
      this.militaryResponseTypes = (this.listService.filterInactiveItems(responseCollection[0]) as IMilitaryResponseTypeDto[]).sort(item => item.DisplayOrder);

      if (this.entity.MilitaryResponseTypeId) this.stepForm.get('MilitaryResponseType').setValue(this.militaryResponseTypes.find(item => item.Id == this.entity.MilitaryResponseTypeId));
      this.utilitiesService.enableDisable(this.stepForm.get('DischargeDate'), this.selectedMilitaryResponseType?.DischargeDateApplicable);
      this.dataLoaded = true;

      this.updateBag();

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  public getEntry(majorKey: string, minorKey: string): string {
    return _l.find(this.dynamicContentManagerService.getEntries(), (entry) => {
      return entry.MajorKey === majorKey
        && entry.MinorKey === minorKey
        && entry.CategoryId === this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT
        && entry.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE
        && entry.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT;
    })?.Content;
  }

  protected updateBag() {

    // Note: The UI sets the this.hasBeenMilitary flag
    // When the user moves forward, the IsMilitary flag on entity is updated, but only if
    // being cleared, not when being set. To set it, users must contact the board.
    this.militaryResponseTypes.forEach(element => {
      this.wizard.selectedStep.bag.addOrReplaceItem(
        this.deriveMinorKey(element),
        this.constantsService.BAG_ITEM_TYPES.RADIO_BUTTON_CONTENT,
        [element.MinorKey, this.entity.MilitaryResponseTypeId == element.Id, element.Description]);
    });

    this.wizard.selectedStep.bag.addOrReplaceItem("DischargeDateApplicable", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.selectedMilitaryResponseType?.DischargeDateApplicable]);
  }

  public deriveMinorKey(element: IMilitaryResponseTypeDto) {
    if (element && element.Description.length) {
      return this.stepMinorKey + ".ResponseOption." + element.Description;
    }
    throw "Military response type is required";
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.ApplicationWizard, 'militaryStatus', MilitaryStatusComponent, MilitaryStatusComponentSelector);
