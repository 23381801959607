import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private clientProviderConfigurationSection: ClientProviderConfiguration;
  constructor(private http: HttpClient) { }

  public loadAppConfig() {
    let appConfigFileName = environment.envName === 'local'
      ? `app.config.json`
      : `app.config.${environment.provider}.${environment.envName}.json`;

    let appConfigFullName = environment.appName && environment.appName.length > 0
      ? `/${environment.appName}/assets/config/${appConfigFileName}`
      : `/assets/config/${appConfigFileName}`;

    const doLoad = async (): Promise<any> => {
      try {
        let providerData = await this.http.get(appConfigFullName).toPromise();
        this.clientProviderConfigurationSection = new ClientProviderConfiguration(providerData);
        return of(true).toPromise();
      } catch (err) {
        throw `Could not load file '${appConfigFullName}': ${JSON.stringify(err)}`;
      }
    };

    return doLoad();
  }

  public get clientProviderConfiguration(): ClientProviderConfiguration {
    if (!this.clientProviderConfigurationSection) {
      throw Error('Config file does not contain a valid client provider section!');
    }
    return this.clientProviderConfigurationSection;
  }
}

/**
 * Represents the ClientProviderConfiguration of the app.config.json file
 */
class ClientProviderConfiguration {
  private appConfigSection: any;

  constructor(appConfig: any) {
    if (!appConfig) {
      throw Error('Invalid config file!');
    }

    if (!appConfig.clientProviderConfiguration) {
      throw Error('Invalid client provider config section!');
    }

    this.appConfigSection = appConfig.clientProviderConfiguration;
  }

  public get applicationRootUri() {
    return this.appConfigSection.applicationRootUri;
  }

  public get apiRootUri() {
    return this.appConfigSection.apiRootUri;
  }

  public get provider() {
    return environment.provider;
  }

  public get logIncidents(): boolean {
    let result = this.appConfigSection.logIncidents === "true";
    return result;
  }

  public get incidentLoggingConfig(): { log401: boolean; log0Unknown: boolean; logTimeout: boolean;} {
    return this.appConfigSection.incidentLoggingConfig;
  }

  public get errorHandlerConfig(): { suppress0UnknownError: boolean; } {
    return this.appConfigSection.errorHandlerConfig;
  }

  public get httpRetryEnabled(): boolean {
    let result = this.appConfigSection.httpRetryEnabled === "true";
    return result;
  }

  public get httpRetryConfig(): { retryCount: number; retryIntervalInMs: number, retryStatuses: number[] } {
    return this.appConfigSection.httpRetryConfig;
  }

}


