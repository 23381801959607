import { Pipe } from '@angular/core';


@Pipe({
  name: 'newlineToLineBreak'
})
export class NewlineToLineBreakPipe {

  transform(text: string): string {
    return text.replace(/\n/g, "<br/>");
  }

}
