import { IDomainReasonForChangeDtoHost,
IWebCartDto,
IWebCartFeeDto,
IWebCartItemDto,
IWebCartStatusDto,
IWebTransactionDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class WebCartDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IWebCartDto {
	UserId: number;
	CartStatusId: number;
	Transactions: IWebTransactionDto[];
	Items: IWebCartItemDto[];
	WebCartFees: IWebCartFeeDto[];
	Status: IWebCartStatusDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ItemsTotal: number;
	FixedCartFeesTotal: number;
	VariableCartFeesTotal: number;
	CartFeesTotal: number;
	Total: number;

}
