import { Injectable } from '@angular/core'; import * as _l from 'lodash-es';
import { BagItemDto } from 'src/app/coreShared';
import { IBagDto, IBagItemDto } from 'src/app/coreShared/dto-gen/core-shared-dto';
@Injectable({
  providedIn: 'root'
})
export class BagService {
  constructor() { }

  public create(key: string) {
    if (!key || key.length === 0) {
      throw "bagId is required when calling bagService.create";
    }
    return new Bag(key);
  }
}

export class Bag implements IBagDto {
  BagItems: IBagItemDto[] = [];

  constructor(public readonly Key: string) { }

  public addOrReplaceItem(bagItemId: string, bagItemType: number, values: string[]) {
    if (!bagItemId || bagItemId.length === 0) {
      throw "bagItemId is required when calling bag.addOrReplaceItem";
    }

    if (!bagItemType) {
      throw "bagItemType is required when calling bag.addOrReplaceItem";
    }

    // Search for existing BagItem in the Bag with matching key and type.
    let bagItem = this.BagItems.find(item => item.Key === bagItemId && item.BagItemType === bagItemType);

    // If no existing BagItem match was found in the Bag for the target key, create a new one.
    if (!bagItem) {
      bagItem = new BagItemDto();
      bagItem.Key = bagItemId;
      bagItem.BagItemType = bagItemType;
      bagItem.Values = values;
      this.BagItems.push(bagItem);
    }

    if (values) {
      bagItem.Values = values;
    }

    return bagItem;
  }
}

