import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEntityConvictionDto,
IEntityHospitalPrivilegeDto,
IEntityTrainingDto,
IEntityWorkHistoryDto,
IProfessionalProfileDto,
ICertificationDto,
IEducationHistorySearchResultItemDto } from './licensure-shared-dto';

export class ProfessionalProfileDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IProfessionalProfileDto {
	Certifications: ICertificationDto[];
	EntityMalpractices: any[];
	EntityConvictions: IEntityConvictionDto[];
	EntityHospitalPrivileges: IEntityHospitalPrivilegeDto[];
	EntityWorkHistory: IEntityWorkHistoryDto[];
	EntityTrainings: IEntityTrainingDto[];
	EducationHistory: IEducationHistorySearchResultItemDto[];

}
