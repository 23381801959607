import { Compiler, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ReachDynamicComponentRegistry, ReachDynamicComponentScenarioEntry } from '@coreModels/reach-dynamic-component-registry';
import { ReachInjectorService } from '@coreServices/reach-injector.service';

export const MODEL_CONFIG_INJECTION_TOKEN = 'MODEL_CONFIG_INJECTION_TOKEN';
export const INPUT_PARAMETER_INJECTION_TOKEN = 'INPUT_PARAMETER_INJECTION_TOKEN';

@Injectable()
export class ReachParameterInjectorData {
  public params: any = {};
  public isOptional: boolean = false;
  public injectionToken: string = INPUT_PARAMETER_INJECTION_TOKEN;
}

@Injectable()
export class ReachModelConfigurationInjector extends ReachParameterInjectorData {
  constructor() {
    super();
    this.injectionToken = MODEL_CONFIG_INJECTION_TOKEN;
  }

  public get model() {
    return this.params['model'];
  }

  public set model(value) {
    this.params['model'] = value;
  }

  public get configuration() {
    return this.params['configuration'];
  }

  public set configuration(value) {
    this.params['configuration'] = value;
  }

  public get config() {
    return this.configuration;
  }

  public set config(value) {
    this.configuration = value;
  }

  public get majorKey() {
    return this.params['majorKey'];
  }

  public set majorKey(value) {
    this.params['majorKey'] = value;
  }

  public get minorKey() {
    return this.params['minorKey'];
  }

  public set minorKey(value) {
    this.params['minorKey'] = value;
  }
}

/**
 * Extends Angular ComponentFactoryResolver, ComponentFactory and view containers to establish a standard pattern and process for Reach dynamic component loading.
 */
@Injectable({
  providedIn: 'root'
})
export class ReachComponentFactoryService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  /**
   * Extends the Angular ComponentFactoryResolver resolveComponentFactory to use the Reach ComponentLookupRegistry to find the target component by metadata keys.
   * @param scenarioKey the key representing the component scenario/category/featureArea.
   * @param componentKey the key associated with the component type. This key represents a class inheritance hierarchy, for example, where the registered member may be a base or derived member of that hierarchy.
   */
  public resolveComponentFactory(scenarioKey: string, componentTypeKey: string): Promise<ComponentFactory<any>> {
    const doResolveComponent = async (targetComponentEntry: ReachDynamicComponentScenarioEntry): Promise<any> => {
      let componentType = await targetComponentEntry.classFactory;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
      return of(componentFactory).toPromise();
    };

    let targetComponentEntry = ReachDynamicComponentRegistry.getComponentEntry(scenarioKey, componentTypeKey);
    if (!targetComponentEntry) {
      throw `No component has been registered for category ${scenarioKey} and key ${componentTypeKey}`;
    }

    return doResolveComponent(targetComponentEntry);
  }

  /**
   * Creates a component instance into the specified view container using the specified component factory.
   * @param viewContainerRef that target view container.
   * @param componentFactory the factory for the target component.
   */
  public createComponentInViewContainer(viewContainerRef: ViewContainerRef, componentFactory: ComponentFactory<any>): ComponentRef<any> {
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    return componentRef;
  }

  /**
   * Resolves the Type of the component matching the specified metadata keys and creates and instance of that component in the specified view container.
   * @param scenarioKey the scenario/category/featureArea metadata key for the target component type. Allows scenario-specific components to be registered. One
   * version of a component can be registered for one scenario, a different scenario may need a different version of the component.
   * @param componentTypeKey the target component type metadata key (the key associated with the target component class/Type, class hierarchy, or class set).
   * @param viewContainerRef the target view container.
   * @param dynamicData option object containing dynamic component Input data. The target component can use this as an @Input parameter, or implement dynamicData
   * with a setter that maps the various properties on dynamicData onto the same @Input properties that would be passed as inputs were the component embedded in a
   * host template instead of being dynamically created.
   */
  public resolveAndCreateComponentInViewContainer(scenarioKey: string, componentTypeKey: string, viewContainerRef: ViewContainerRef, dynamicData = null): Promise<ComponentRef<any>> {
    const doLoad = async (): Promise<any> => {
      let componentFactory = await this.resolveComponentFactory(scenarioKey, componentTypeKey);
      let componentRef = this.createComponentInViewContainer(viewContainerRef, componentFactory);
      (componentRef.instance).dynamicData = dynamicData;
      return of(componentRef).toPromise();
    };

    return doLoad();
  }

  /**
   * Resolves the overriding Type of the component matching the specified metadata keys and creates and instance of that component in the specified view container. If the overriding type matches the
   * base type, the override view is not initialized with a component and a null ComponentRef is returned.
   * @param scenarioKey the scenario/category/featureArea metadata key for the target component type. Allows scenario-specific components to be registered. One
   * version of a component can be registered for one scenario, a different scenario may need a different version of the component.
   * @param componentTypeKey the target component type metadata key (the key associated with the target component class/Type, class hierarchy, or class set).
   * @param baseComponentType the Type of the base component. If the overriding type equals the base type, the view container is not initialized.
   * @param overrideViewContainerRef the view container for the override.
   * @param dynamicData option object containing dynamic component Input data. The target component can use this as an @Input parameter, or implement dynamicData
   * with a setter that maps the various properties on dynamicData onto the same @Input properties that would be passed as inputs were the component embedded in a
   * host template instead of being dynamically created.
   */
  public overrideComponentInViewContainer(scenarioKey: string, componentTypeKey: string, baseComponentType: Type<any>, overrideViewContainerRef: ViewContainerRef, dynamicData = null) {
    const doLoad = async (): Promise<any> => {
      let componentFactory = await this.resolveComponentFactory(scenarioKey, componentTypeKey);
      let componentRef = this.createComponentInViewContainer(overrideViewContainerRef, componentFactory);
      (componentRef.instance).dynamicData = dynamicData;
      return of(componentRef).toPromise();
    };
    let overrideComponentType = ReachDynamicComponentRegistry.getComponent(scenarioKey, componentTypeKey);
    if (overrideComponentType === baseComponentType) {
      return null;
    }

    return doLoad();
  }

  /**
   * Returns a custom Injector for the specified data.
   * @param parameterInjectionTokenName
   * @param parameterObject
   * @param parentInjector
   */
  public dynamicParameterInjector(parameterInjectionTokenName: string, parameterObject, parentInjector: Injector): Injector {
    let dynamicInjector = Injector.create({
      providers: [
        {
          provide: parameterInjectionTokenName,
          useValue: parameterObject
        }
      ],
      parent: parentInjector
    });

    return dynamicInjector;
  }

  public dynamicModelConfigurationInjector(parameterInjectionTokenName: string = MODEL_CONFIG_INJECTION_TOKEN, model: any, configuration: any, parentInjector: Injector) {
    let modelInjector = new ReachModelConfigurationInjector();
    modelInjector.model = model;
    modelInjector.configuration = configuration;
    let paramInjector = this.dynamicInputParameterInjector(parameterInjectionTokenName, modelInjector, parentInjector);

    return {
      injectorService: paramInjector.injectorService,
      modelInjector: paramInjector.inputParameterInjector as ReachModelConfigurationInjector
    };
  }


  public dynamicComponentInfo(scenarioKey: string, componentTypeKey: string, inputDataInjector: Injector, compiler: Compiler = null): ReachDynamicComponentInfo {
    const componentTypeEntry = ReachDynamicComponentRegistry.getComponentEntry(scenarioKey, componentTypeKey);

    // Ensure component is registered
    if (!componentTypeEntry) {
      throw new Error(`Configuration Error: No component is registered for keys (${scenarioKey}, ${componentTypeKey}).`);
    }

    const importModule = async (): Promise<any> => {
      return componentTypeEntry.lazyComponent;
    };

    const getLazyComponent = async (): Promise<any> => {
      let importResult = await importModule();
      let targetComponent = null;
      if ("components" in importResult) {
        // Module import with components array on module class
        targetComponent = importResult.components[componentTypeKey];
        if (!targetComponent) {
          let summaryDetailKey = componentTypeKey.includes('Summary') ? 'card' : (componentTypeKey.includes('Detail') ? 'detail' : null);
          if (summaryDetailKey) {
            targetComponent = importResult.components[summaryDetailKey];
          }
        }

        let factory = await compiler.compileModuleAsync(importResult);
        factory.create(inputDataInjector);
      }
      else {
        // Ivy lazy component import
        targetComponent = importResult;
      }

      return of(targetComponent).toPromise();
    }

    let classFactory = componentTypeEntry.classFactory;
    if (componentTypeEntry.lazyComponent && compiler) {
      classFactory = getLazyComponent();
    }

    return new ReachDynamicComponentInfo(classFactory, inputDataInjector);
  }

  /**
   * Parses the comma-delimited list of component keys into an array of keys.
   * @param delimitedList the string containing the comma-delimited list of keys.
   * @returns a string array of keys.
   */
  public ParseDelimitedComponentKeys(delimitedList: string) {
    let componentKeys = delimitedList.split(",");

    // Remove leading or trailing spaces
    componentKeys = componentKeys.map(item => item.trim());

    return componentKeys;
  }

  /**
  * Loads the dynamic component matching the specified scenario and component keys, initialized with
  * the specified input parameter data. Supports hosting of dynamic components.
  * @param scenarioKey the key that represents the scenario under which the component is loaded.
  * @param componentKeyList the string-delimited list of keys representing component type/names.
  * @param inputParameterInjectorData the injector input parameter object.
  * @param parentInjector the injector of the parent component.
   * @returns an object with properties of type ReachDynamicComponentInfo representing
   * components to be dynamically rendered in the host.
  */
  public loadHostedDynamicComponentsFromKeys(
    scenarioKey: string,
    componentKeyList: string,
    inputParameterInjectorData: ReachParameterInjectorData,
    parentInjector: Injector) {
    let componentInfos: any = {};
    let componentNames = this.ParseDelimitedComponentKeys(componentKeyList);
    componentNames.forEach(n => {
      if (n && n.length > 0) {
        componentInfos[n] = this.loadHostedDynamicComponent(
          scenarioKey,
          n,
          inputParameterInjectorData,
          parentInjector
        );
      }
    });

    return componentInfos;
  }

  /**
 * Loads the dynamic component matching the specified scenario and component keys, initialized with
 * the specified input parameter data. Supports hosting of dynamic components.
 * @param scenarioKey the key that represents the scenario under which the component is loaded.
 * @param hostedComponentTypeKey the key representing the component type/name.
 * @param inputParameterInjectorData the injector input parameter object.
 * @param parentInjector the injector of the parent component.
 */
  public loadHostedDynamicComponent(
    scenarioKey: string,
    hostedComponentTypeKey: string,
    inputParameterInjectorData: ReachParameterInjectorData,
    parentInjector: Injector): ReachDynamicComponentInfo {

    // Determine if component key is enclosed in square brackets denoting optional
    if (hostedComponentTypeKey.startsWith("[")) {

      // Strip off the enclosing square brackets
      hostedComponentTypeKey = hostedComponentTypeKey.substring(1, hostedComponentTypeKey.length - 1);

      // Pass along the fact that the component is denoted as optional
      inputParameterInjectorData.isOptional = true;

    }

    let injectorData = this.dynamicInputParameterInjector(inputParameterInjectorData.injectionToken, inputParameterInjectorData, parentInjector);
    let hostedComponentInfo = this.dynamicComponentInfo(
      scenarioKey,
      hostedComponentTypeKey,
      injectorData.injectorService.injector
    );

    return hostedComponentInfo;
  }

  public dynamicInputParameterInjector(
    parameterInjectionTokenName: string,
    parameters: ReachParameterInjectorData,
    parentInjector: Injector
  ) {
    let injectorService = new ReachInjectorService(parentInjector);
    injectorService.addItem(parameterInjectionTokenName, parameters);
    return {
      injectorService: injectorService,
      inputParameterInjector: parameters
    };
  }
}


/**
 * Contains the data needed to dynamically instantiate a component (Ivy component loading) into an ng-container or ng-template with an *ngComponentOutlet directive.
 *  <ng-container *ngIf="foo"><ng-template [ngComponentOutletInjector]="info.inputDataInjector" [ngComponentOutlet]="info.componentClassFactory | async"></ng-template></ng-container>
 */
export class ReachDynamicComponentInfo {
  constructor(public componentClassFactory: Promise<Type<any>>, public inputDataInjector: Injector) {
  }
}
