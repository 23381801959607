<!-- Page Header -->
<div class="reach-page-header">{{wizard.onlineService.Title}}</div>

<!-- Stepper -->
<mat-vertical-stepper class="reach-vertical-stepper" #stepper [linear]="true" [selectedIndex]="wizard.selectedIndex"
  (selectionChange)="SelectedStepChanged($event.selectedIndex)">

  <!-- Step Template -->
  <mat-step *ngFor="let step of wizard.steps; let stepIndex = index" [completed]="stepIndex <= wizard.selectedIndex">
    <ng-template matStepLabel><i [ngClass]="step.Icon"></i>&nbsp;{{step.LongTitle}}</ng-template>

    <!-- Content -->
    <div class="p-pl-1" *ngIf="stepIndex == wizard.selectedIndex">
      <div>
        <div class="p-mb-1">

          <!-- Wizard Help -->
          <div class="p-mb-3">
            <div class="reach-float-right">
              <wizard-help [majorKey]="wizard.dynamicContentConfiguration.majorKey"
                [minorKey]="wizard.selectedStep.MinorKey">
              </wizard-help>
            </div>
            <div class="reach-clearfix"></div>
          </div>

          <div>
            <!-- Page Summary Top of Page -->
            <div *ngIf="wizard.selectedStep.pageSummaryTextMinorKey">
              <page-summary-text
                [majorKey]="wizard.dynamicContentConfiguration.majorKey"
                [minorKey]="wizard.selectedStep.pageSummaryTextMinorKey"></page-summary-text>
            </div>

            <!-- Page Content -->
            <reach-container [componentInfo]="setDetailComponentInfo"></reach-container>

            <!-- Page Summary Bottom of Page Before Navigation buttons-->
            <div class="p-mt-4" *ngIf="wizard.selectedStep.additionalHelpTextMinorKey">
              <page-summary-text
              [majorKey]="wizard.dynamicContentConfiguration.majorKey"
              [minorKey]="wizard.selectedStep.additionalHelpTextMinorKey"></page-summary-text>
            </div>
          </div>
        </div>

        <!-- Action buttons -->
        <div *ngIf="!wizard.hideNavigationButtons" class="p-grid reach-bottom-actions-container">
          <div class="p-col-12 p-sm-6 p-d-flex p-jc-end p-jc-sm-start p-pb-0">

            <!-- Additional Wizard Actions-->
            <p-button label="{{command.commandText}}" styleClass="p-button-sm"
              *ngFor="let command of wizard.additionalCommands" [command-button]="command"
              [busy-type]="busyTypes.COMMAND">
            </p-button>

            <!-- Additional Step Actions-->
            <p-button label="{{command.commandText}}" styleClass="p-button-sm"
              *ngFor="let command of wizard.selectedStep.additionalCommands" [command-button]="command"
              [command-blocks-invalid]="command.commandBlocksInvalid" [busy-type]="busyTypes.COMMAND">
            </p-button>

          </div>

          <!-- Wizard buttons -->
          <div class="p-col-12 p-sm-6 p-d-flex p-jc-end">

            <!-- Cancel -->
            <p-button label="{{wizard.cancelCommandText}}" icon="fa fa-ban" styleClass="p-button-sm"
              *ngIf="wizard.onlineService.IsCancellationAllowed && !wizard.hideCancelButton"
              [command-button]="wizard.cancelCommand" [busy-type]="busyTypes.COMMAND">
            </p-button>

            <!-- Previous -->
            <p-button label="Previous" icon="fa fa-chevron-left" styleClass="p-button-sm p-ml-2"
              *ngIf="!wizard.isWizardOnFirstStep() && !wizard.selectedStep.hidePrevious" [command-button]="wizard.backCommand"
              [busy-type]="busyTypes.COMMAND">
            </p-button>

            <!-- Next -->
            <p-button label="Next" icon="fa fa-chevron-right" iconPos="right" styleClass="p-button-sm p-ml-2"
              *ngIf="!wizard.isWizardOnLastStep() && !wizard.selectedStep.hideForward"
              [command-button]="wizard.forwardCommand" [busy-type]="busyTypes.COMMAND">
            </p-button>

            <!-- Finish -->
            <p-button label="{{wizard.finishCommandText}}" icon="fa fa-flag-checkered" styleClass="p-button-sm p-ml-2"
              *ngIf="wizard.isWizardOnLastStep() && !wizard.selectedStep.hideForward" [command-button]="wizard.finishCommand"
              [busy-type]="busyTypes.COMMAND" [command-blocks-invalid]="true">
            </p-button>

          </div>
        </div>
      </div>
    </div>

  </mat-step>

</mat-vertical-stepper>
