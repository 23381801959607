import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnChanges } from '@angular/core';
import { ArgumentExceptionService } from '@coreServices/argument-exception.service';
import { ReachComponentFactoryService, ReachDynamicComponentInfo } from '@coreServices/reach-component-factory.service';
import { RegionComponentRegistration, RegionManagerService } from '@coreServices/region-manager.service';
import * as _l from 'lodash-es';
import { Subject } from 'rxjs';

export class ReachRegionModel {
  public modelChanged$: Subject<any> = new Subject<any>();
}

export class ReachRegionConfiguration {

}

@Component({
  selector: 'reach-region',
  templateUrl: './reach-region.component.html',
  styleUrls: ['./reach-region.component.scss']
})
export class ReachRegionComponent implements AfterViewInit, OnChanges {

  @Input()
  model: ReachRegionModel = null;
  @Input()
  configuration: any = null;
  @Input()
  name: string = null;
  @Input()
  useModelReference = false;

  public reachDynamicComponentInfoArray: ReachDynamicComponentInfo[] = [];

  constructor(private argumentExceptionService: ArgumentExceptionService
    , private changeDetectorRef: ChangeDetectorRef
    , private injector: Injector
    , private reachComponentFactoryService: ReachComponentFactoryService
    , private regionManagerService: RegionManagerService) { }

  ngOnChanges(): void {
    if (!this.useModelReference) this.model = _l.cloneDeep(this.model);
  }

  ngAfterViewInit(): void {
    if (!this.name) this.argumentExceptionService.create("An input 'name' is required for a reach-region component directive.").log();
    if (!this.model) this.argumentExceptionService.create("An input 'model' is required for a reach-region component directive.").log();

    this.loadDynamicContentComponent();
    this.changeDetectorRef.detectChanges();
  }

  private loadDynamicContentComponent() {
    const regionComponentRegistrations: RegionComponentRegistration[] = this.regionManagerService.getRegionComponentRegistrationInfo(this.name);
    if (!this.model || !regionComponentRegistrations) return;


    regionComponentRegistrations.forEach(registration => {
      const customModel: ReachRegionModel = registration.modelResolver ? registration.modelResolver(this.model) : this.model; // if a model resolver was supplied lets run that against the current model this allows us to have a single model for the region and then have the model for the region component be a subset of the region model.
      const customConfiguration: any = registration.configurationResolver ? registration.configurationResolver(this.configuration) : this.configuration;
      const injectorData = this.regionManagerService.getModelInjector(customModel, customConfiguration, this.injector);

      this.reachDynamicComponentInfoArray.push(this.reachComponentFactoryService.dynamicComponentInfo(
        registration.scenarioKey,
        registration.componentTypeKey,
        injectorData.injectorService.injector
      ));

    })
  }
}
