import { Component, OnInit, Injectable, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { from, of } from 'rxjs';

import * as _l from 'lodash-es';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { CommandService } from "@coreServices/command.service";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "@coreServices/constants-provider.service";
import { ListService } from '@coreServices/list.service';
import { OnlineUserService } from "@coreServices/online-user.service";
import { SystemSettingsManagerService } from "@coreServices/system-settings-manager.service";
import { UserManagerService } from "@coreServices/user-manager.service";
import { ValidationManagerService } from "@coreServices/validation-manager.service";

import { IOnlineUserDto } from '@coreShared/core-shared.module';
import { LandingComponentKeys, LoginComponentKeys, ReachScenarios } from '@core/index-constants';
import { Command, RouteInfoRegistry, registerDynamicComponent } from '@core/index-models';
import { ReachCaptchaComponent } from '../reachCaptcha/reach-captcha.component';
import { DefaultRouteResolverService, AuthorizationConfigurationProviderService, BootstrapperService, BusyManagerService, DynamicContentConfigurationProviderService, DynamicContentManagerService, ReachApplicationService, RouteConfigurationProviderService, RouteConfiguration } from '../../index-services';
import { ReachControlValidators } from '../validators/reach-control-validators';


export const ChangeSecurityQuestionsSelector = 'change-security-questions';

@Component({
  selector: ChangeSecurityQuestionsSelector,
  templateUrl: './change-security-questions.component.html',
  styleUrls: ['./change-security-questions.component.scss']
})
export class ChangeSecurityQuestionsComponent implements OnInit {

  // FIELDS
  private friendlyNames: any = {};
  private validationScope: string = null;
  contentForm: FormGroup;
  saveCommand: Command;
  securityAnswer1: string = null;
  securityAnswer2: string = null;
  updatedOnlineUser: IOnlineUserDto = null;
  currentUser: IOnlineUserDto = null;
  recaptchaResponse;
  captchaPublicKey;
  possibleSecurityQuestions;
  dynamicContentConfiguration: any;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private commandService: CommandService,
    private formBuilder: FormBuilder,
    private router: Router,
    private listService: ListService,
    private onlineUserService: OnlineUserService,
    private userManagerService: UserManagerService,
    private validationManagerService: ValidationManagerService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.currentUser = this.userManagerService.getCurrentPrincipal().user;
    this.updatedOnlineUser = _l.cloneDeep(this.currentUser) as IOnlineUserDto;

    this.modelToForm();
  }

  ngOnInit() {

    // Get dynamicContentConfiguration.
    this.activatedRoute.data.subscribe((data: { routeData: any }) => {
      if (data && data.routeData) this.dynamicContentConfiguration = data.routeData.dynamicContentConfiguration;
    });

    this.validationScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.captchaPublicKey = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.CAPTCHA_KEY);

    this.initCommands();

    this.initData();
    this.checkValidationErrors();
  }

  protected modelToForm() {
    this.friendlyNames.SecurityAnswer1 = "Security Answer #1";
    this.friendlyNames.SecurityAnswer2 = "Security Answer #2";
    this.friendlyNames.SecurityQuestion1 = "Security Question #1";
    this.friendlyNames.SecurityQuestion2 = "Security Question #2";
    this.friendlyNames.ReachCaptcha = "Captcha";

    this.contentForm = this.formBuilder.group({
      SecurityAnswer1: [null, [Validators.required, Validators.maxLength(50)]],
      SecurityAnswer2: [null, [Validators.required, Validators.maxLength(50), ReachControlValidators.uniqueSecurityQuestionAnswerValidator(this.updatedOnlineUser.UserAccount)]],
      SecurityQuestion1: [null, [Validators.required, Validators.maxLength(100)]],
      SecurityQuestion2: [null, [Validators.required, Validators.maxLength(100), ReachControlValidators.uniqueSecurityQuestionValidator(this.updatedOnlineUser.UserAccount)]],
      ReachCaptcha: [null, [Validators.required]]
    });

    this.contentForm.valueChanges.subscribe(() => {
      this.formToModel();
    });

    this.checkValidationErrors();
  }

  protected formToModel() {
    this.updatedOnlineUser.UserAccount.SecurityAnswer1 = this.contentForm.get('SecurityAnswer1').value as string;
    this.updatedOnlineUser.UserAccount.SecurityAnswer2 = this.contentForm.get('SecurityAnswer2').value as string;
    this.updatedOnlineUser.UserAccount.SecurityQuestion1 = this.contentForm.get('SecurityQuestion1').value?.Description as string;
    this.updatedOnlineUser.UserAccount.SecurityQuestion2 = this.contentForm.get('SecurityQuestion2').value?.Description as string;
    this.recaptchaResponse = this.contentForm.get('ReachCaptcha').value?.Captcha;

    this.checkValidationErrors();
  }

  protected initCommands() {
    this.saveCommand = this.commandService.create(() => {
      return true;
    }, () => {
      this.updatedOnlineUser.UserAccount.ValidationMode = this.constantsService.VALIDATION_MODES.WEB_PASSWORD_CHANGE;

      const doChange = async (): Promise<any> => {
        let response = await this.onlineUserService.changeSecurityQuestions(this.updatedOnlineUser, this.recaptchaResponse).toPromise();
        this.resetCaptcha();
        if (this.validationManagerService.hasServerApplicationValidationMessages) {
          return of(false).toPromise();
        } else {

          // Navigate to landing page
          return this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
        }
      };

      return from(doChange());
    },
      "Save");
  }

  /**
  * Clear the reCaptcha element on this component instance.
  */
  @ViewChild(ReachCaptchaComponent) reCaptcha;
  resetCaptcha(): void {
    this.reCaptcha.resetCaptchaWidget();
  }

  protected initData() {
    const doLoadSecurityQuestions = async (): Promise<any> => {
      this.possibleSecurityQuestions = await this.listService.getSecurityQuestions().toPromise();
      return of(true).toPromise();
    }

    return from(doLoadSecurityQuestions());
  }

  /**
   * Performs a check for form validation errors and writes them to the message pipeline.
   */
  private checkValidationErrors() {
    this.validationManagerService.addFormErrors(this.contentForm, this.validationScope, this.friendlyNames);
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.Default, LoginComponentKeys.SecurityQuestions, ChangeSecurityQuestionsComponent, ChangeSecurityQuestionsSelector);

/**
 * The route resolver for the ChangeSecurityQuestions page.
 */
@Injectable({
  providedIn: 'root'
})
export class ChangeSecurityQuestionsRouteResolver extends DefaultRouteResolverService {

  constructor(
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    bootstrapperService: BootstrapperService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    userManagerService: UserManagerService
  ) {
    super(
      constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
  * Override in subclass to return the RouteConfiguration data to be used for this resolver.
  */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false,
      this.dynamicContentConfigurationProviderService.getConfigurationData(true,
        this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.CHANGE_SECURITY_QUESTIONS),
      this.authorizationConfigurationProviderService.getConfigurationData(false));
  }
}