<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <div class="p-grid p-formgrid">

        <!-- BusinessSetting -->
        <div class="p-field p-sm-12 p-lg-6">
            <label for="BusinessSetting">Business Setting</label>
            <input id="BusinessSetting" type="text" formControlName="BusinessSetting" pInputText>
        </div>

        <!-- Facility -->
        <div class="p-field p-sm-12 p-lg-6">
            <label for="Facility">Facility</label>
            <input id="Facility" type="text" formControlName="Facility" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-sm-6 p-lg-4">
            <label for="City">City</label>
            <input id="City" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-sm-6 p-lg-2">
            <label for="state">State</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="possibleStates"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- AverageHours -->
        <div class="p-field p-sm-6 p-lg-2">
            <label for="AverageHours">Average Hours per Week</label>
            <input id="AverageHours" type="number" formControlName="AverageHours" pInputText>
        </div>

    </div>

    <div class="p-grid p-formgrid">

        <!-- Start Date -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="StartDate">Start Date</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="StartDate" formControlName="StartDate"
                [showIcon]="false" yearNavigator="true" monthNavigator="true" defaultDate="" appendTo="body"
                baseZIndex="1">
            </p-calendar>
        </div>

        <!-- End Date -->
        <div class="p-field p-col-12 p-sm-6 p-lg-4">
            <label for="EndDate">End Date</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="EndDate" formControlName="EndDate"
                [showIcon]="false" yearNavigator="true" monthNavigator="true" defaultDate="" appendTo="body"
                baseZIndex="1">
            </p-calendar>
        </div>

    </div>

</div>
