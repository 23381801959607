import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, LandingPageDetailRouteResolverService, LandingPageService, OnlineServiceRegistry, Principal, ReachScenarios, RouteInfoRegistry, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration, SummaryInfoManagerService, UserManagerService, Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { from, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { SummaryInfoBuilder, SummaryInfoBuilderService } from "src/app/core/services/summary-info-builder.service";
import { ILicensureUserProfileInfoDto, ISupervisionPlanDto, SupervisorDesignationDto } from "src/app/licensureShared/licensure-shared.module";
import { ISupervisionPlanWizard } from "../../features/supervision/components/superviseePlanWizard/wizard/supervisee-plan-wizard.component";
import { ISupervisionVerificationWizard } from "../../features/supervision/components/superviseeVerificationWizard/wizard/supervisee-verification-wizard.component";
import { ISupervisorAttestationWizard } from "../../features/supervision/components/supervisorAttestationWizard/wizard/supervisor-attestation-wizard.component";
import { LicensureLandingCategories, LicensureLandingComponentKeys } from "../../index-constants";

@Injectable({ providedIn: 'root' })
export class SupervisionFeatureConfigurationService {
  private isInitialized = false;
  private supervisionFeatureConstantsService: ISupervisionFeatureConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService
    , protected userManagerService: UserManagerService
    , protected landingPageService: LandingPageService
    , protected summaryInfoBuilderService: SummaryInfoBuilderService
    , protected summaryInfoManagerService: SummaryInfoManagerService
  ) {

  }

  // Providers to override.
  public getSupervisorDesignationTypeForLicenseType(licenseType: string): number {
    throw new Error('Method not implemented.');
  }

  protected addSubscriptions(): void {
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.addInfosToLandingPage(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => { this.addInfosToLandingPage(currentPrincipal) });
    this.landingPageService.landingPageReconfig$.subscribe(() => { this.addInfosToLandingPage(this.userManagerService.getCurrentPrincipal()) });
  }

  protected addInfosToLandingPage(principal: Principal): void {

    if (!this.isInitialized) {
      return;
    }

    // Check for active designations
    if (!(principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).SupervisorDesignations.some((item: SupervisorDesignationDto) => item.StatusId === 1)) {
      return;
    }

    let summaryComponentInfoObjects: SummaryComponentInfo[] = [];
    let infoBuilder: SummaryInfoBuilder = this.summaryInfoBuilderService.createBuilder(summaryComponentInfoObjects
      , 0, "Supervision"
      , new SummaryDetailConfiguration(LicensureLandingCategories.Supervision, LicensureLandingComponentKeys.SupervisionSummaryLazy, LicensureLandingComponentKeys.SupervisionDetailLazy, SummaryAggregationType.Singleton));
    infoBuilder.addItem(0, "Supervision", { Entity: principal?.user, Profile: principal.user.UserAccount.profile, Id: principal?.user?.Id }, this.supervisionFeatureConstantsService.DOMAIN_IDS.SUPERVISION_PLAN);
    this.summaryInfoManagerService.mergeInfos(summaryComponentInfoObjects);
  }

  public initialize() {

    this.addSubscriptions();
    this.supervisionFeatureConstantsService = this.constantsService as ISupervisionFeatureConstantsService;

    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, SupervisionFeatureConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, SupervisionFeatureConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, SupervisionFeatureConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, SupervisionFeatureConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, SupervisionFeatureConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, SupervisionFeatureConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, SupervisionFeatureConstantsService.Mergers.DOMAIN_IDS);

    this.supervisionFeatureConstantsService = this.constantsService as ISupervisionFeatureConstantsService;
  }

  private configureDecorators() {

  }

  public getSuperviseeVerificationWizard(supervisionVerificationId?: number, supervisionVerificationFormId?: number, supervisionPlanId?: number, webServiceHistoryId?: number): Observable<ISupervisionVerificationWizard> {
    let functionType = this.functionTypeService.create(this.supervisionFeatureConstantsService.FUNCTION_TYPES.SUPERVISION_VERIFICATION, supervisionVerificationId);
    let wizardTags = this.wizardTagsService.create();
    wizardTags.hasSupervisionPlan = supervisionPlanId != null && supervisionPlanId > 0
    wizardTags.formTypeId = supervisionVerificationFormId;

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId
      , this.supervisionFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.SUPERVISION_VERIFICATION
      , wizardTags);

    const getWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(getWizard());
  }

  public getSupervisorVerificationWizard(supervisionVerificationId?: number, supervisionVerificationFormId?: number, supervisionPlanId?: number, webServiceHistoryId?: number): Observable<Wizard> {
    let functionType = this.functionTypeService.create(this.supervisionFeatureConstantsService.FUNCTION_TYPES.SUPERVISION_VERIFICATION, supervisionVerificationId);
    let wizardTags = this.wizardTagsService.create();
    wizardTags.hasSupervisionPlan = supervisionPlanId != null && supervisionPlanId > 0
    wizardTags.formTypeId = supervisionVerificationFormId;

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId
      , this.supervisionFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.SUPERVISOR_SUPERVISION_VERIFICATION
      , wizardTags);

    const getWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      return wizard;
    };

    return from(getWizard());
  }

  public getSupervisorAttestationWizard(supervisorDesignationId: number, supervisorDesignationFormId: number, licenseType: string, webServiceHistoryId?: number): Observable<ISupervisorAttestationWizard> {
    let functionType = this.functionTypeService.create(this.supervisionFeatureConstantsService.FUNCTION_TYPES.SUPERVISOR_DESIGNATION, supervisorDesignationId);
    let wizardTags = this.wizardTagsService.create();
    wizardTags.supervisorDesignationFormId = supervisorDesignationFormId;
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.supervisionFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.SUPERVISOR_ATTESTATION, wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(

      switchMap((wizard: Wizard) => {
        return !webServiceHistoryId
          ? this.wizardService.startWizard(wizard, functionType, licenseType)
          : of(wizard);
      }),

      map((wizard: Wizard) => {
        return wizard as ISupervisorAttestationWizard;
      }),

      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getSupervisorPlanWizard(supervisionPlan: ISupervisionPlanDto, webServiceHistoryId?: number): Observable<Wizard> {

    var wizardTags = this.wizardTagsService.create(null, null, null, supervisionPlan.FormId);
    var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, (this.constantsService as ISupervisionFeatureConstantsService).WEB_SERVICE_PROCESS_TYPES.SUPERVISOR_SUPERVISION_PLAN, wizardTags);
    let functionType = this.functionTypeService.create(this.supervisionFeatureConstantsService.FUNCTION_TYPES.SUPERVISION_PLAN, supervisionPlan.Id);

    const initializeWizard = async (): Promise<any> => {

      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) wizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();

      return wizard;
    };

    return from(initializeWizard());
  }

  public getSuperviseePlanWizard(supervisionPlan: ISupervisionPlanDto, licenseTypeId: string, planId: number, webServiceHistoryId: number): Observable<ISupervisionPlanWizard> {

    var wizardTags = this.wizardTagsService.create(null, null, null, supervisionPlan.Form.Id);
    var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, (this.constantsService as ISupervisionFeatureConstantsService).WEB_SERVICE_PROCESS_TYPES.SUPERVISION_PLAN, wizardTags);
    let functionType = this.functionTypeService.create(this.supervisionFeatureConstantsService.FUNCTION_TYPES.SUPERVISION_PLAN, planId);

    const initializeWizard = async (): Promise<any> => {

      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) wizard = await this.wizardService.startWizard(wizard, functionType, licenseTypeId).toPromise();

      return wizard;
    };

    return from(initializeWizard());
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/supervision/modules/supervisee-verification-service-selector.module').then(m => m.SuperviseeVerificationServiceSelectorModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisee-verification-wizard.module').then(m => m.SuperviseeVerificationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisee-verification-wizard.module').then(m => m.SuperviseeVerificationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorSupervisionVerification).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-verification-wizard.module').then(m => m.SupervisorVerificationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorSupervisionVerification).onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-verification-wizard.module').then(m => m.SupervisorVerificationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-attestation-wizard.module').then(m => m.SupervisorAttestationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation).selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/supervision/modules/supervisor-attestation-service-selector.module').then(m => m.SupervisorAttestationServiceSelectorModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation).onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-attestation-wizard.module').then(m => m.SupervisorAttestationWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorPlan).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-plan-wizard.module').then(m => m.SupervisorPlanWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorPlan).onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisor-plan-wizard.module').then(m => m.SupervisorPlanWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SuperviseePlan).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisee-plan-wizard.module').then(m => m.SuperviseePlanWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SuperviseePlan).onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/supervision/modules/supervisee-plan-wizard.module').then(m => m.SuperviseePlanWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SuperviseePlan).selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/supervision/modules/supervisee-plan-wizard-selector.module').then(m => m.SuperviseePlanWizardSelectorModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.SupervisionDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.SupervisionDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}
export class SupervisionFeatureConstantsService extends ConstantsService {
  public static Mergers = {
    SYSTEM_SETTING_KEYS: {
      SUPERVISION: {
        FEATURE_SUPERVISION_LICENSE_TAB_SUPERVISOR_ATTESTATION_SUMMARY_ENABLED: "Feature.Supervision.LicenseTab.SupervisorAttestationSummary.Enabled",
        FEATURE_SUPERVISION_LICENSE_TAB_SUPERVISION_SUMMARY_ENABLED: "Feature.Supervision.LicenseTab.SupervisionSummary.Enabled",
        FEATURE_SUPERVISION_ONLINE_ATTESTATION_ENABLED: "Feature.Supervision.OnlineAttestation.Enabled"
      }
    },
    FEATURE_CONSTANTS: {
      SUPERVISION: {
        SUPERVISION_PLAN_SUPERVISOR_STATUS_CONSTANTS: {
          NEW: 1,
          STARTED: 2,
          IN_PROCESS: 3,
          APPROVED: 4,
          DENIED: 5
        },
        SUPERVISION_VERIFICATION_SUPERVISOR_STATUS_CONSTANTS: {
          NEW: 1,
          STARTED: 2,
          IN_PROCESS: 3,
          APPROVED: 4,
          DENIED: 5
        },
        SUPERVISION_VERIFICATION_STATUS: {
          STARTED: 5,
          SUBMITTED: 6,
          IN_PROCESS: 1,
          APPROVED: 2,
          DEFICIENT: 3,
          DENIED: 4
        },
        SUPERVISOR_DESIGNATION_TYPES: {
          NON_CLINICAL: 1,
          CLINICAL_AND_NON_CLINICAL: 2
        },
        SUPERVISION_PLAN_STATUS: {
          STARTED: 5,
          SUBMITTED: 8,
          IN_PROCESS: 1,
          APPROVED: 2,
          DEFICIENT: 3,
          DENIED: 4
        },
        SUPERVISION_VERIFICATION_FORM: {
          NON_CLINICAL: 1,
          CLINICAL: 2,
          PROVISIONAL: 3
        }
      }
    },
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      SUPERVISION_VERIFICATION_WIZARD_SELECTOR: 'Supervision.Verification.Wizard.Selector',
      SUPERVISOR_ATTESTATION_WIZARD: "SupervisorAttestation.Wizard",
      SUPERVISOR_ATTESTATION_WIZARD_SELECTOR: "SupervisorAttestation.Wizard.Selector",
      SUPERVISOR_PLAN_WIZARD: "Supervisor.Plan.Wizard",
      SUPERVISOR_VERIFICATION_WIZARD: "Supervisor.Verification.Wizard",
      SUPERVISEE_VERIFICATION_WIZARD: "Supervisee.Verification.Wizard",
      SUPERVISEE_PLAN_WIZARD_SELECTOR: 'Supervisor.Plan.Wizard.Selector',
      SUPERVISEE_PLAN_WIZARD: "Supervisee.Plan.Wizard",
    },
    WEB_SERVICE_PROCESS_TYPES: {
      SUPERVISION_PLAN: LicensureOnlineServiceProcessTypeKeys.SuperviseePlan,
      SUPERVISION_VERIFICATION: LicensureOnlineServiceProcessTypeKeys.SupervisionVerification,
      SUPERVISOR_SUPERVISION_PLAN: LicensureOnlineServiceProcessTypeKeys.SupervisorPlan,
      SUPERVISOR_SUPERVISION_VERIFICATION: LicensureOnlineServiceProcessTypeKeys.SupervisorSupervisionVerification,
      SUPERVISOR_ATTESTATION: LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation,
    },
    FUNCTION_TYPES: {
      SUPERVISOR: 18,
      SUPERVISION_PLAN: 19,
      SUPERVISION_VERIFICATION: 22,
      SUPERVISOR_DESIGNATION: 28,
    },
    VALIDATION_MODES: {
      SUPERVISION: {
        CONTACT_INFO: "Web.NewSupervisionPlan.ContactInfo",
        PLAN_DETAILS: "Web.NewSupervisionPlan.SupervisionPlanDetails",
        EMPLOYMENT_INFO: "Web.NewSupervisionPlan.EmploymentInfo",
        WEB_NEW_SUPERVISION_PLAN: "Web.NewSupervisionPlan",
        WEB_NEW_SUPERVISION_PLAN_REVIEW: "Web.NewSupervisionPlan.Review",
        WEB_NEW_SUPERVISION_VERIFICATION: "Web.NewSupervisionVerification"
      }
    },
    DOMAIN_IDS: {
      LICENSE: 10,
      SUPERVISION_PLAN: 23,
    },


  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), SupervisionFeatureConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), SupervisionFeatureConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), SupervisionFeatureConstantsService.Mergers.FUNCTION_TYPES);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), SupervisionFeatureConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), SupervisionFeatureConstantsService.Mergers.FEATURE_CONSTANTS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), SupervisionFeatureConstantsService.Mergers.VALIDATION_MODES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), SupervisionFeatureConstantsService.Mergers.DOMAIN_IDS);
}

export interface ISupervisionFeatureConstantsService extends SupervisionFeatureConstantsService {

}


