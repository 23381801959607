import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, ReachHttpClientService, ValidationManagerService } from '@core/core.module';
import { SearchResultListDto } from "src/app/coreShared";
import { IOnlineLicenseSearchCriteria, OnlineLicenseSearchResultItemDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({ providedIn: 'root' })
export class OnlineLicenseSearchService extends ReachHttpClientService {
  private uri: string;

  constructor(
    // Base.
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/licensure/onlineLicenseSearch`;
  }

  public search(criteria: IOnlineLicenseSearchCriteria) {
    return this.get<SearchResultListDto<OnlineLicenseSearchResultItemDto>>(`${this.uri}/search`, { params: criteria as any });
  }
}