<div class="p-grid p-formgrid p-col-12" [formGroup]="stepForm">

    <p-table styleClass="p-datatable-sm" [value]="applicableItems" [responsive]="true" responsiveLayout="stack">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <span class="p-text-left reach-list-header">Certifications/Specialties</span>
                <div>
                    <!-- Add -->
                    <button pButton pRipple class="p-button-text" icon="fa fa-plus"
                        [command-button]="presentItemEditorCommand" tool-tip="Add a new item"></button>
                </div>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>Source</th>
                <th>Board</th>
                <th>Certificate</th>
                <th style="width: 8em;"> </th>
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <td><span class="p-column-title">Source</span>{{item.CertificationType?.CertificationOrganization?.CertificationSource?.ShortName}}</td>
                <td><span class="p-column-title">Board</span>{{item.CertificationType?.CertificationOrganization?.Description}}</td>
                <td><span class="p-column-title">Certificate</span>{{item.CertificationType?.Description}}</td>

                <!-- Buttons -->
                <td class="p-text-right">
                    <span class="p-text-nowrap">

                        <!-- Edit -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                            [command-button]="presentItemEditorCommand" [command-parameter]="item"
                            tool-tip="Edit this item"></button>

                        <!-- Delete -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                            [command-button]="removeItemCommand" [command-parameter]="item"
                            tool-tip="Delete this item"></button>

                    </span>
                </td>

            </tr>
        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="4">
                    <span class="reach-text-muted"><em>No entries.</em></span>
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>

<!-- DIALOG -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>