import { MenuItem } from './menu-item';

export class MenuGroup {
    constructor(public name: string, public items: MenuItem[] = []) {
      var groupHeaderMenuItem = new MenuItem(null);
      groupHeaderMenuItem.isHeader = true;
      groupHeaderMenuItem.menuGroupName = name;
      this.items.push(groupHeaderMenuItem);
    }

    public addMenuItem = (command) => {
      var item = new MenuItem(command);
      item.menuGroupName = this.name;
      this.items.push(item);
    }
}