import { Injectable } from "@angular/core";
import { IOnlineRelatedActivitySearchCriteriaDto, IOnlineRelatedActivitySearchResultItemDto, SearchResultListDto } from "src/app/coreShared/core-shared.module";
import { ReachHttpClientService } from "./reach-http-client.service";

@Injectable({ providedIn: 'root' })
export class RelatedActivityListService extends ReachHttpClientService {

    public search(criteria: IOnlineRelatedActivitySearchCriteriaDto) {
        return this.get<SearchResultListDto<IOnlineRelatedActivitySearchResultItemDto>>(`${this.apiRootUri}/OnlineRelatedActivitySearch/search`, { params: criteria as any });
    }
}