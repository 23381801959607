import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@core/components/reach-dialog/reach-dialog-content.component';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { IEntityPhoneDto, IPhoneTypeDto } from '@coreShared/core-shared.module';

export const EntityPhoneNumberEditorComponentSelector: string = 'entity-phone-number-editor';

@Component({
  selector: EntityPhoneNumberEditorComponentSelector,
  templateUrl: './entity-phone-number-editor.component.html',
  styleUrls: ['./entity-phone-number-editor.component.scss']
})
export class EntityPhoneNumberEditorComponent extends ReachDialogContentComponent<IEntityPhoneDto> implements OnInit, IReachDialogContentComponent {

  public isPrimaryEnabled: boolean = false;
  public isPublicEnabled: boolean = false;  
  public isPublicLabel: string;
  public standardNumber: boolean = true;

  public get possibleTypes() { return this.hostDialogData.initializationData.possiblePhoneTypes; }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private systemSettingsManagerService: SystemSettingsManagerService,
    validationManagerService: ValidationManagerService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }
  public override async ngOnInit(): Promise<void> {

    this.isPublicEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_ONE_PUBLIC_PHONE_ENFORCEMENT_ENABLED);
    this.isPrimaryEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_ONE_PRIMARY_PHONE_ENFORCEMENT_ENABLED);
    this.isPublicLabel = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_PUBLIC_PHONE_LABEL);

    this.friendlyNames.Type = "Type";
    this.friendlyNames.IsPublic = "Public";
    this.friendlyNames.IsPrimary = "Primary";
    this.friendlyNames.Number = "Number";
    this.friendlyNames.Extension = "Extension";

    await super.ngOnInit();
  }

  public customPatternValidator(pattern: RegExp, errorMessage: string): (control: AbstractControl) => { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var isIrregularNumber = control.get('IrregularNumber')?.value;
      var number = control.get('Number')?.value;

      // If irregular is selected do not run the pattern test.
      const isValid = isIrregularNumber ? true : pattern.test(number);
      return isValid ? null : { errorMessage: errorMessage };
    }
  }


  /**
* Provide specified mapping from the input model to
* the reactive FormGroup for this instance.
*/
  protected override modelToForm(): void {

    // Construct the contentForm.
    this.contentForm = this.formBuilder.group({
      Type: [null, [Validators.required]],
      IsPublic: [this.outputModel.IsPublic || false],
      IsPrimary: [this.outputModel.IsPrimary || false],
      Number: [this.outputModel.Number, [Validators.required]],
      IrregularNumber: [false],
      Extension: [this.outputModel.Extension, [Validators.max(99999)]]
    }, { validator: this.customPatternValidator(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number. Please use (111) 111-1111.') });

    // EXTRA STEP: Determine if the phone number inputMask can be used.
    this.InspectPhoneNumber();
    super.modelToForm();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    // Standard fields.
    this.outputModel.IsPublic = this.contentForm.get('IsPublic').value;
    this.outputModel.IsPrimary = this.contentForm.get('IsPrimary').value;
    this.outputModel.Number = this.contentForm.get('Number').value;
    this.outputModel.Extension = this.contentForm.get('Extension').value;

    // Dropdowns.
    let selectedType = this.contentForm.get('Type').value as IPhoneTypeDto;
    this.outputModel.PhoneTypeId = selectedType ? selectedType.Id : null;
    this.outputModel.PhoneType = selectedType?.Id ? this.possibleTypes.find(item => item.Id === selectedType.Id) : null;

    super.formToModel();
  }

  /**
  * Wires event handlers for the content form.
  */
  protected override initializeEventHandlers(): void {

    // Non-standard phone number checkbox.
    this.contentForm.get("IrregularNumber").valueChanges.subscribe(x => {

      // Clear the phone number field.
      this.contentForm.get("Number").setValue(null);

      if (x == true) this.standardNumber = false;
      else this.standardNumber = true;

    });

    super.initializeEventHandlers();
  }

  /**
   * Determine if the phone number inputMask can be used.
   */
  protected InspectPhoneNumber() {

    // Use the input mask by default for new items.
    if (this.outputModel.IsNew) {
      this.contentForm.get('IrregularNumber').setValue(false);
      return;
    }

    // Otherwise, determine if the input mask should be used.
    // The input mask should be used if the outputModel.Number has 10 digits in it.
    let val: string = this.outputModel.Number ?? "";
    let digitsOnly: string = val.replace(/\D/g, '');

    digitsOnly.length == 10
      ? this.contentForm.get('IrregularNumber').setValue(false)
      : this.contentForm.get('IrregularNumber').setValue(true);
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  protected override initDropdowns(): void {

    // Phone Type.
    if (this.outputModel.PhoneTypeId) this.contentForm.get('Type').setValue(this.possibleTypes.find(item => item.Id == this.outputModel.PhoneTypeId));

    // Allow UI elements to render.
    this.dataLoaded = true;
  }
}

registerDynamicComponent(ReachScenarios.Default, 'EntityPhoneNumberEditorComponent', EntityPhoneNumberEditorComponent, EntityPhoneNumberEditorComponentSelector);
