import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthorizationConfigurationProviderService } from '../authorization-configuration-provider.service';
import { BootstrapperService } from '../bootstrapper.service';
import { DynamicContentConfigurationProviderService } from '../dynamic-content-configuration-provider.service';
import { BusyManagerService } from '../busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../constants-provider.service';
import { DynamicContentManagerService } from '../dynamic-content-manager.service';
import { ReachApplicationService } from '../reach-application.service';
import { RouteConfiguration, RouteConfigurationProviderService } from '../route-configuration-provider.service';
import { UserManagerService } from '../user-manager.service';
import { RouteResolverService } from './route-resolver.service';

@Injectable({ providedIn: 'root' })
export class DocumentHistoryRouteResolverService extends RouteResolverService<any> implements Resolve<any> {
    
    constructor(@Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
        , userManagerService: UserManagerService
        , busyManagerService: BusyManagerService
        , bootstrapperService: BootstrapperService
        , dynamicContentManagerService: DynamicContentManagerService
        , reachApplicationService: ReachApplicationService
        , router: Router
        , routeConfigurationProviderService: RouteConfigurationProviderService
        , dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService
        , authorizationConfigurationProviderService: AuthorizationConfigurationProviderService) {
        super(constantsService, userManagerService, busyManagerService, bootstrapperService, dynamicContentManagerService, reachApplicationService, router, routeConfigurationProviderService, dynamicContentConfigurationProviderService, authorizationConfigurationProviderService);
    }

    protected initializeRouteConfigurationData(): RouteConfiguration {
        return this.routeConfigurationProviderService.getConfigurationData(false
            , this.dynamicContentConfigurationProviderService.getConfigurationData(true, this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.CART)
            , this.authorizationConfigurationProviderService.getConfigurationData(false));
    }
}
