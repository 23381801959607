import { IDomainFormAnswerDto,
IDomainFormQuestionTypeDto,
IDomainFormSectionQuestionDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainFormSectionQuestionDto extends LookupDto implements IDomainFormSectionQuestionDto {
	QuestionTypeId: number;
	Rational: string;
	QuestionType: IDomainFormQuestionTypeDto;
	PossibleAnswers: IDomainFormAnswerDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
