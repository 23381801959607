import { Inject, InjectionToken, inject } from '@angular/core';
import { from, of } from "rxjs";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '../constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from './default-provider-configuration.service';
import { SystemSettingsManagerService } from '../system-settings-manager.service';
import { SharedConfigurationService } from './shared-configuration.service';
import * as idxFeatureConfigServices from './index-configuration-services';


export const FEATURE_MANAGER_SERVICE_TOKEN = new InjectionToken<FeatureManagerService>('FEATURE_MANAGER_SERVICE', {
  providedIn: 'root',
  factory: () => featureManagerServiceFactory(inject(SharedConfigurationService))
});

const featureManagerServiceFactory = (shared: SharedConfigurationService) => {
  return shared.featureManagerService;
};

export class FeatureManagerService {

  constructor(
    private activityFeatureConfigurationService: idxFeatureConfigServices.ActivityFeatureConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected generalConfigurationService: idxFeatureConfigServices.GeneralConfigurationService,
    protected cartLandingConfigurationService: idxFeatureConfigServices.CartLandingConfigurationService,
    protected invoiceLandingConfigurationService: idxFeatureConfigServices.InvoiceLandingConfigurationService,
    protected invoiceFeatureConfigurationService: idxFeatureConfigServices.InvoiceFeatureConfigurationService,
    private forgotPasswordFeatureConfigurationService: idxFeatureConfigServices.ForgotPasswordFeatureConfigurationService,
    private registrationWizardFeatureConfigurationService: idxFeatureConfigServices.RegistrationWizardFeatureConfigurationService,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) { }

  public initialize() {
    console.log('Initializing FeatureManagerService...');
    const doInit = async (): Promise<boolean> => {
      await this.defaultProviderConfigurationService.initialize().toPromise();
      this.initializeDefaultConfiguration(); // TODO merge into defaultProviderConfigurationService
      this.initializeRegistrationWizard();
      this.initializeForgotPasswordWizard();
      this.initializeActivityWizard();
      this.initializeInvoiceWizard();
      return of(true).toPromise();
    };

    return from(doInit());
  }

  public get isGeneralConfigEnabled() {
    return true;
  }

  public get isRegistrationWizardEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.REGISTRATION_WIZARD.ENABLED);
  }

  public get isForgotPasswordWizardEnabled() {
    //return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.FORGOT_PASSWORD.ENABLED);
    return true;
  }

  public get isActivityWizardEnabled() {
    //return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.ACTIVITY.ENABLED);
    return true;
  }

  public get isInvoiceWizardEnabled() {
    //return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.INVOICE.ENABLED);
    return true;
  }

  protected initializeDefaultConfiguration() {
    if (this.isGeneralConfigEnabled) {
      this.generalConfigurationService.initialize();
    }
  }

  protected initializeRegistrationWizard() {
    if (this.isRegistrationWizardEnabled) {
      this.registrationWizardFeatureConfigurationService.initialize();
    }
  }

  protected initializeForgotPasswordWizard() {
    if (this.isForgotPasswordWizardEnabled) {
      this.forgotPasswordFeatureConfigurationService.initialize();
    }
  }

  protected initializeActivityWizard() {
    if (this.isActivityWizardEnabled) {
      this.activityFeatureConfigurationService.initialize();
    }
  }

  protected initializeInvoiceWizard() {
    if (this.isInvoiceWizardEnabled) {
      this.invoiceFeatureConfigurationService.initialize();
    }
  }

  protected featureEnabled(featureName: string) {
    return this.systemSettingsManagerService.asBoolean(featureName, true);
  }
}
