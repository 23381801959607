import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { ISupervisorSearchCriteriaDto } from './licensure-shared-dto';

export class SupervisorSearchCriteriaDto extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, ISupervisorSearchCriteriaDto {
	EntityId?: number;
	LastName: string;
	FirstName: string;
	MiddleName: string;
	SupervisorDesignationStatusId?: number;
	LicenseTypeCode: string;
	LicenseNumber?: number;
	LicenseReferenceValue: string;
	SupervisorDesignationTypeId?: number;

}
