import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { ICertificationOrganizationDto,
ICertificationSourceDto } from './licensure-shared-dto';

export class CertificationOrganizationDto extends LookupDto implements ICertificationOrganizationDto {
	Code: string;
	Name: string;
	PrimaryCareIndicator?: boolean;
	CertificationSourceId: number;
	CertificationSource: ICertificationSourceDto;

}
