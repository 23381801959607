import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IOrganizationalPayorLicenseeDto } from './licensure-shared-dto';

export class OrganizationalPayorLicenseeDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IOrganizationalPayorLicenseeDto {
	OrganizationalPayorId?: number;
	LicenseId?: number;
	EffectiveFrom?: Date;
	EffectiveTo?: Date;
	IsPay?: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
