import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IDomainIdentifierDto, IDomainIdentifierDtoHost } from 'src/app/coreShared/core-shared.module';
import { UtilitiesService } from '../../services/utilities.service';
import { DomainIdentifierComponentConfiguration } from './domain-identifier-component-configuration';

@Component({
  selector: 'domain-identifier',
  templateUrl: './domain-identifier.component.html',
  styleUrls: ['./domain-identifier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainIdentifierComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DomainIdentifierComponent),
      multi: true
    }
  ]
})
export class DomainIdentifierComponent implements OnChanges, ControlValueAccessor {

  @Input() model: IDomainIdentifierDtoHost;
  @Input() config: DomainIdentifierComponentConfiguration;

  public contentForm: FormGroup;
  public filteredDomainIdentifiers: IDomainIdentifierDto[];
  public friendlyNames: any = {};

  constructor(private utilitiesService: UtilitiesService) {
    this.modelToForm();
  }

  ngOnChanges(): void {
    this.filteredDomainIdentifiers = this.model.DomainIdentifiers.filter(item => item.DomainIdentifierType.DisplayOnline);
    this.filteredDomainIdentifiers.forEach((element: IDomainIdentifierDto) => { // Add a FormControl element for each domain identifier.
      this.contentForm.addControl(`${element.DomainIdentifierType.Description}`, new FormControl(element.Value, [Validators.pattern(element.DomainIdentifierType.FormatRegex), Validators.maxLength(50)]), { emitEvent: false });
    });
  }

  protected modelToForm() {
    this.contentForm = new FormGroup({});
    this.initializeEventHandlers();
  }

  protected formToModel = () => {
    this.filteredDomainIdentifiers.forEach((element: IDomainIdentifierDto) => {

      if (element.Value != this.contentForm.get(`${element.DomainIdentifierType.Description}`).value) {
        element.Value = this.contentForm.get(`${element.DomainIdentifierType.Description}`).value;
        element.IsDirty = true;
      }

    });
  }

  protected initializeEventHandlers(): void {
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });
  }

  // ==================================== CVA ====================================

  validate(): ValidationErrors | null {
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames);
    return this.contentForm.valid ? null : errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }

}
