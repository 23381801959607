import { Pipe } from '@angular/core';

@Pipe({
    name: 'boolToCheckmarkGlyph'
})
export class BoolToCheckmarkGlyphPipe {
  transform(value: string): string {
    if (value) {
        return "<i class=\"fa fa-check\"></i>";
      }

    return "";
  }
}
