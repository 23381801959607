/**
 * Interface that defines the structure of an API HttpResponseMessage payload.
 */
export interface IHttpResponseMessage<TContent> {
  Content: TContent;
}

/**
 * Interface that defines the structure of an API response payload.
 */
export interface IHttpResponseContent<TContent> {
    data: IHttpResponseMessage<TContent>;
}

/**
 * API response content payload.
 */
export class HttpResponseContent<TContent> implements IHttpResponseContent<TContent>  {
    data: IHttpResponseMessage<TContent>;

    constructor(content: TContent) { this.data = new HttpResponseMessage<TContent>(content); }
}

/**
 * API HttpResponseMessage payload.
 */
export class HttpResponseMessage<TContent> implements IHttpResponseMessage<TContent> {
    constructor(public Content: TContent) { }
}
