import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import {
  AuthGuard,
  CONSTANTS_SERVICE_TOKEN,
  CheckboxContent,
  CheckboxContentService,
  ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Wizard,
  WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { DynamicContentConfiguration } from 'src/app/core/services/dynamic-content-configuration-provider.service';
import { IDomainIdentifierDto, IWebServiceHistoryInfoDto } from 'src/app/coreShared/core-shared.module';
import { ILicenseDto, ILicenseInfoDto, ILicenseStatusTransitionDto, LicenseDto, LicenseInfoDto } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureConstantsProviderService } from '../../services/index-services';

@Injectable({
  providedIn: 'root'
})
export class LicenseRenewalFeatureConfigurationService {
  public readonly featureRoot: string = null;
  public readonly wizardPageConfiguration: any;
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private licenseRenewalConstantsService: ILicenseRenewalConstantsService;

  constructor(private checkboxContentService: CheckboxContentService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService, private functionTypeService: FunctionTypeService
    , private router: Router
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseRenewal);
    this.featureRoot = this.defaultProviderConfigurationService.applicationRootUri + "/Client/scripts/features/licenseRenewalWizard";

    this.wizardPageConfiguration = {
      licenseRenewalContactInfo: {
        domainIdentifierFilterFunction: (licenseType: string, domainIdentifiers: IDomainIdentifierDto[]) => {
          return [];
        }
      },
      licenseRenewalPmpRegistration: {
        mapToPmpLicenseType: (licenseType: string) => {
          throw "Not Implemented: Provider must supply this function.";
        }
      },
      licenseRenewalReview: {
        getCheckboxContentModels: (license: ILicenseDto, dynamicContentConfiguration: DynamicContentConfiguration): CheckboxContent[] => {
          var checkboxContents: CheckboxContent[] = [];
          checkboxContents.push(this.checkboxContentService.create(dynamicContentConfiguration.majorKey, "LicenseRenewalReview.StatutesRead", "General Affidavits"));
          checkboxContents.push(this.checkboxContentService.create(dynamicContentConfiguration.majorKey, "LicenseRenewalReview.InformationCorrect", "Cooperation Affidavits"));
          return checkboxContents;
        }
      }
    }
  }

  private getLicenseStatusTransitionsByMechanism(licenseInfo: ILicenseInfoDto, licenseStatusChangeMechanismsId: number): ILicenseStatusTransitionDto[] {
    return _l.filter(licenseInfo.PossibleLicenseStatusTransitions,
      (transition: ILicenseStatusTransitionDto) => {
        return transition.LicenseStatusChangeMechanismId === licenseStatusChangeMechanismsId;
      });
  }

  public canLicenseBeRenewed(licenseInfo: ILicenseInfoDto, license: ILicenseDto) {
    const licenseRenewalStatusTransitions = this.getLicenseStatusTransitionsByMechanism(licenseInfo, (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.RENEWAL);
    return licenseInfo.AllowRenewOnline &&
      licenseInfo.HasInProcessRenewal &&
      license != null &&
      license.Status != null &&
      licenseRenewalStatusTransitions.length &&
      licenseInfo.PendingStatus == null &&
      licenseInfo.PendingLicenseStatusChangeApplicationStatusTo == null &&
      license.InProcessRenewal != null &&
      !license.InProcessRenewal.HostHasIncompleteDomainForm;
  }

  public getLicenseRenewalWizard = (inProcessRenewalId: number, licenseTypeId: string, licenseStatusId: string, licenseStatusIdTo: string, webServiceHistoryId: number, isOpiate?: boolean, isOutOfState?: boolean, licenseSubtypeId?: number): Observable<Wizard> => {
    let functionType = this.functionTypeService.create(this.licenseRenewalConstantsService.FUNCTION_TYPES.RENEWAL, inProcessRenewalId);
    let wizardTags = this.wizardTagsService.create(licenseTypeId, null, licenseStatusId, null, null, null, licenseStatusIdTo, null, null, isOpiate, isOutOfState, licenseSubtypeId);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL, wizardTags);

    const initializeWizard = async (): Promise<Wizard> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria, functionType).toPromise();
      if (!wizard.onlineServiceHistory.Id) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, licenseTypeId).toPromise();
        return startedWizard;
      } else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };

  public getLicenseRenewalWithStatusChangeWizard = (inProcessRenewalId: number, licenseTypeCode: string, licenseStatusCode: string, licenseStatusCodeTo: string, webServiceHistoryId: number, isOpiate?: boolean, isOutOfState?: boolean, licenseSubtypeId?: number): Observable<Wizard> => {
    var functionType = this.functionTypeService.create(this.licenseRenewalConstantsService.FUNCTION_TYPES.RENEWAL, inProcessRenewalId);
    var wizardTags = this.wizardTagsService.create(licenseTypeCode, null, licenseStatusCode, null, null, null, licenseStatusCodeTo, null, null, isOpiate,isOutOfState, licenseSubtypeId);
    var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_AND_STATUS_CHANGE, wizardTags);

    const initializeWizard = async (): Promise<Wizard> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria, functionType).toPromise();
      if (!wizard.onlineServiceHistory.Id) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, licenseTypeCode).toPromise();
        return startedWizard;
      } else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };

  public presentLicenseRenewalWizard = (licenseeEntityId: number, licenseId: number, licenseTypeCode: string, licenseStatusIdFrom: string, licenseStatusIdTo: string, webServiceHistoryEntryId: number, licenseStatusSelectionRequired: boolean) => {

    if (licenseStatusSelectionRequired) {
      let url = this.dynamicRoutesService.buildRoute(this.serviceRegistryItem.selectorPath, licenseId, this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL);
      this.router.navigate(url);
    } else {
      let navCommands = this.dynamicRoutesService.buildRoute(this.serviceRegistryItem.onlineServicePathTemplate,
        licenseeEntityId,
        licenseId,
        licenseTypeCode,
        this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL,
        licenseStatusIdFrom,
        licenseStatusIdTo,
        webServiceHistoryEntryId);

      this.router.navigate(navCommands);
    }
  };

  public presentLicenseRenewalWithStatusChangeWizard = (licenseeEntityId: number, licenseId: number, licenseTypeId: string, licenseStatusIdFrom: string, licenseStatusIdTo: string, webServiceHistoryEntryId: number, licenseStatusSelectionRequired: boolean) => {
    if (licenseStatusSelectionRequired) {
      let navCommands = this.dynamicRoutesService.buildRoute(this.serviceRegistryItem.selectorPathTemplate, licenseId, this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_AND_STATUS_CHANGE);
      this.router.navigate(navCommands);
    } else {
      let navCommands = this.dynamicRoutesService.buildRoute(this.serviceRegistryItem.onlineServicePathTemplate,
        licenseeEntityId,
        licenseId,
        licenseTypeId,
        this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_AND_STATUS_CHANGE,
        licenseStatusIdFrom,
        licenseStatusIdTo,
        webServiceHistoryEntryId);
      this.router.navigate(navCommands);
    }
  };

  public presentWizard = (licenseInfo: LicenseInfoDto, license: LicenseDto) => {

    let webServiceHistoryInfoItem = _l.find(licenseInfo.WebServiceHistoryInfoItems, (item: IWebServiceHistoryInfoDto) => {
      return item.ProcessTypeId === this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL || item.ProcessTypeId === this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_AND_STATUS_CHANGE;
    });

    let webServiceHistoryId: number = null;

    // In the case of renewal with a status change we need to use the status on the service that resolved when service was first initiated
    let processStatusCode: string = licenseInfo.LicenseStatus.Id;
    let processTypeId: number = null;
    let processStatusCodeTo: string = null;
    if (webServiceHistoryInfoItem) {
      webServiceHistoryId = webServiceHistoryInfoItem.WebServiceHistoryId;
      processStatusCode = webServiceHistoryInfoItem.ProcessStatusCode;
      processStatusCodeTo = webServiceHistoryInfoItem.ProcessStatusCodeTo;
      processTypeId = webServiceHistoryInfoItem.ProcessTypeId;
    }

    let licenseRenewalAndStatusChangeStatusTransitions = this.getLicenseStatusTransitionsByMechanism(licenseInfo, (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.RENEWAL_AND_STATUS_CHANGE);

    // New
    if (!processTypeId) {
      const statusSelectionRequired: boolean = (!!webServiceHistoryId && licenseRenewalAndStatusChangeStatusTransitions.length > 0);
      if (statusSelectionRequired) {
        this.presentLicenseRenewalWithStatusChangeWizard(licenseInfo.EntityId, licenseInfo.Id, licenseInfo.LicenseType.Code, processStatusCode, processStatusCodeTo, webServiceHistoryId, statusSelectionRequired);
      } else {
        this.presentLicenseRenewalWizard(licenseInfo.EntityId, licenseInfo.Id, licenseInfo.LicenseType.Code, processStatusCode, processStatusCodeTo, webServiceHistoryId, statusSelectionRequired);
      }
    } else if (processTypeId === this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL_AND_STATUS_CHANGE) {
      // Existing Renewal With Status Change
      this.presentLicenseRenewalWithStatusChangeWizard(licenseInfo.EntityId, licenseInfo.Id, licenseInfo.LicenseType.Code, processStatusCode, processStatusCodeTo, webServiceHistoryId, false);
    } else if (processTypeId === this.licenseRenewalConstantsService.WEB_SERVICE_PROCESS_TYPES.LICENSE_RENEWAL) {
      // Existing Renewal
      this.presentLicenseRenewalWizard(licenseInfo.EntityId, licenseInfo.Id, licenseInfo.LicenseType.Code, processStatusCode, processStatusCodeTo, webServiceHistoryId, false);
    }
    return of();
  };

  public initialize() {
    this.configureConstants();
    this.configureRoutes();
  }

  /**
   * Configure dynamic LicenseRenewal routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  private configureRoutes() {

    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/licenseRenewal/licenseRenewalWizard/license-renewal-wizard.module')
          .then(m => m.LicenseRenewalWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features//licenseRenewal/licenseRenewalWizard/license-renewal-wizard.module').then(m => m.LicenseRenewalWizardModule)
      },
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import(
          'src/app/licensureCore/features//licenseRenewal/licenseRenewalWizardSelector/license-renewal-wizard-selector.module')
          .then(m => m.LicenseRenewalWizardSelectorModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, LicenseRenewalConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, LicenseRenewalConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, LicenseRenewalConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, LicenseRenewalConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseRenewalConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, LicenseRenewalConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, LicenseRenewalConstantsService.Mergers.FEATURE_CONSTANTS);
    this.licenseRenewalConstantsService = this.constantsService as ILicenseRenewalConstantsService;
  }
}


export class LicenseRenewalConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      LICENSE_RENEWAL_WIZARD: 'License.Renewal.Wizard',
      LICENSE_RENEWAL_WIZARD_SELECTOR: 'License.Renewal.Wizard.Selector',
      LICENSE_STATUS_CHANGE_WIZARD_SELECTOR: 'License.Status.Change.Wizard.Selector',
      LICENSE_STATUS_CHANGE_WIZARD: 'License.Status.Change.Wizard',
    },
    SYSTEM_SETTING_KEYS: {
      LICENSE: {
        RENEWAL: {
          RENEWAL_WIZARD: {
            SURVEY_COMPLETE_CALLBACK_URI: "Feature.OnlineRenewal.SurveyCompleteCallbackUri",
            EXTERNAL_SURVEY_URI: "Feature.OnlineRenewal.ExternalSurveyUri",
            SURVEY_STATUS: {
              INCOMPLETE: null,
              COMPLETE: 1,
              BYPASS: 2
            }
          },
        }
      }
    },
    VALIDATION_MODES: {
      RENEWAL_WIZARD: {
        WEB_LICENSE_RENEWAL_TENNESSEN: "Web.LicenseRenewal.Tennessen",
        WEB_LICENSE_RENEWAL_CONTACT_INFORMATION: "Web.LicenseRenewal.ContactInfo",
        WEB_LICENSE_RENEWAL_FACILITY_CONTACT_INFORMATION: "Web.LicenseRenewalFacility.ContactInfo",
        WEB_LICENSE_RENEWAL_CONTINUING_EDUCATION: "Web.LicenseRenewal.ContinuingEducation",
        WEB_LICENSE_RENEWAL_PRACTICE_QUESTIONS: "Web.LicenseRenewal.PracticeQuestions"
      }
    },

    DOMAIN_IDS: {
      LICENSE_RENEWAL: 19,
    },

    WEB_SERVICE_PROCESS_TYPES: {
      LICENSE_RENEWAL: LicensureOnlineServiceProcessTypeKeys.LicenseRenewal,
      LICENSE_RENEWAL_AND_STATUS_CHANGE: LicensureOnlineServiceProcessTypeKeys.LicenseRenewalWithStatusChange
    },

    FUNCTION_TYPES: {
      RENEWAL: 17,
    },

    FEATURE_CONSTANTS: {

      LICENSE_CERTIFICATION: {
        CERTIFICATION_STATUS: {
          ACTIVE: 1,
          EXPIRED: 2,
          WITHDRAWN: 3
        }
      }
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), LicenseRenewalConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), LicenseRenewalConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), LicenseRenewalConstantsService.Mergers.VALIDATION_MODES);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), LicenseRenewalConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), LicenseRenewalConstantsService.Mergers.DOMAIN_IDS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), LicenseRenewalConstantsService.Mergers.FUNCTION_TYPES);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), LicenseRenewalConstantsService.Mergers.FEATURE_CONSTANTS);
}
export interface ILicenseRenewalConstantsService extends LicenseRenewalConstantsService {
}

