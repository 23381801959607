import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';

import * as _l from 'lodash-es';

import { SummaryAggregationType, SummaryComponentInfo } from '@coreModels/summary-component-info';
import { IWebLinkSegment } from '@coreShared/core-shared.module';

@Injectable({
  providedIn: 'root'
})
export class DynamicRoutesService {

  constructor() { }

  public infoToRoute(routeConfig: Array<Route>, parentRoutePath: string, infos: SummaryComponentInfo[]):
    Promise<Array<Route>> {
    return new Promise((resolve, reject) => {
      let dynamicRoutes: Array<Route> = [];
      let parentRoute = routeConfig.find(routeItem => routeItem.path === parentRoutePath);
      if (parentRoute) {
        infos.forEach(info => {
          let childAggRoute = null;
          if (parentRoute.children) {
            childAggRoute = parentRoute.children.find(child => child.path === info.configuration.summaryRoute.path);
          }

          if (childAggRoute && info.items && info.configuration.aggregationType === SummaryAggregationType.Singleton) {
            info.items.forEach(item => {
              dynamicRoutes.push({
                path: item.summaryRoute,
                component: childAggRoute.component,
                outlet: info.outletName
              });
            });
          }
        });

        // Trigger change detection so _loadedConfig is available in router
        setTimeout(() => {
          let configIsChanged = false;
          if (parentRoute && parentRoute.data.addDynamicChild) {
            if (!parentRoute.children) {
              parentRoute.children = [];
            }
            dynamicRoutes.forEach(dynamicRoute => {
              const foundDynamicChild = parentRoute.children.find(child => child.path === dynamicRoute.path);
              if (!foundDynamicChild) {
                // Route does not already exist in module. Add it.
                parentRoute.children.push(dynamicRoute);
                configIsChanged = true;
              }
            });

          }

          if (configIsChanged) {
            resolve(routeConfig);
          }
          resolve(null);
        },
          0);
      } else {
        resolve(null);
      }
    });
  }

  public addDynamicRoutes(router: Router, routeConfig: Array<Route>, dynamicRoutes: Routes): Promise<Array<Route>> {
    return new Promise((resolve, reject) => {
      let configIsChanged = false;
      dynamicRoutes.forEach(dynamicRoute => {
        const foundDynamicChild = routeConfig.find(child => child.path === dynamicRoute.path);
        if (!foundDynamicChild) {
          // Route does not already exist in module. Add it.
          const wildIndexFunction = (element) => element.path === "**";
          let wildIndex = routeConfig.findIndex(wildIndexFunction);
          if (wildIndex > -1) {
            routeConfig.splice(wildIndex, 0, dynamicRoute);
          } else {
            routeConfig.push(dynamicRoute);
          }

          configIsChanged = true;
        }
      });

      if (configIsChanged) {
        router.resetConfig(routeConfig);
        resolve(routeConfig);
      } else {
        resolve(null);
      }
    });
  }

  public buildRoute(format, ...args) {
    if (!args) {
      return ['/', format];
    }

    const segmentArray = format.split('/');
    let matchCount = 0;
    let segmentsObject = {};
    let nav = ['/', segmentArray[0]];
    let x = format.replace(/\/:(\w+\??)/g,
      (matchedValue) => {
        let paramValue = args[matchCount++];
        let varname = matchedValue.replace(/[\/\\]/g, '');
        varname = varname.replace(':', '');
        varname = varname.replace('?', '');
        segmentsObject[varname] = paramValue;
      });

    nav.push(segmentsObject);
    return nav;
  }

  /**
  * Returns a path segment array for the navigation route for the specified base route path, route template pattern and
  * web link segment info.
  */
  public buildRouteFromWebLinkSegments(baseRoute: string, routePattern: string, routeParameters: IWebLinkSegment[]) {
    let navRoute: any[] = ['/'];

    if (baseRoute) {
      let baseSegments = baseRoute.split('/');
      navRoute = ['/', ...baseSegments];
    }

    let routeSegmentsObject = {};
    let segments = routePattern.split('/:');

    if (segments && segments.length > 0 && routeParameters && routeParameters.length > 0) {
      segments.forEach(segKey => {
        if (segKey && segKey.length > 0) {

          // Remove question mark suffix if one exists on the route definition for the segment. The API side
          // link generator does not consider if the parameter is designated as optional on the route definition.
          if (segKey.endsWith('?')) {
            segKey = segKey.slice(0, -1);
          }

          let webSeg = _l.find(routeParameters, (item: IWebLinkSegment) => {
            return item.Key === segKey;
          });

          // Only add the segment if it is found as a route parameter and the parameter isn't null
          if (webSeg && webSeg.Value != null){
            routeSegmentsObject[segKey] = webSeg && webSeg.Value ? webSeg.Value.toString() : '';
          }
        }
      });

      navRoute.push(routeSegmentsObject);
    }

    return navRoute;
  }
}
