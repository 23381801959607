import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent } from 'src/app/core/index-directives';
import { Command, DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { SupervisionService } from 'src/app/licensureCore/services/supervision.service';
import { ISupervisorSearchCriteriaDto, SupervisorSearchCriteriaDto, SupervisorSearchResultItemDto } from 'src/app/licensureShared/licensure-shared.module';
import { SupervisorLookupModel } from '../../models/supervisor-lookup-model';

@Component({
  selector: 'supervisor-lookup',
  templateUrl: './supervisor-lookup.component.html',
  styleUrls: ['./supervisor-lookup.component.scss']
})
export class SupervisorLookupComponent extends ReachDialogContentComponent<SupervisorLookupModel> {

  searchResults: SupervisorSearchResultItemDto[] = [];
  searchCommand: Command;
  constructor(changeDetectorRef: ChangeDetectorRef
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , private supervisionService: SupervisionService
    , private commandService: CommandService) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  protected override modelToForm(): void {
    this.friendlyNames.FirstName = "First Name";
    this.friendlyNames.LastName = "Last Name";
    this.friendlyNames.LicenseReferenceValue = "License Number";

    this.contentForm.addControl("FirstName", new FormControl(null, [Validators.maxLength(50)]));
    this.contentForm.addControl("LastName", new FormControl(null, [Validators.maxLength(200)]));
    this.contentForm.addControl("LicenseReferenceValue", new FormControl(null, [Validators.maxLength(10)]));
    super.modelToForm();
  }

  protected override initCommands(): void {
    this.searchCommand = this.commandService.create(this.canSearchCommandExecute, this.searchCommandExecute);
    super.initCommands();
  }

  protected clearSearchResults(): void {
    this.searchResults = [];
  }

  onRowSelect(event: { data: SupervisorSearchResultItemDto }) {
    this.handleSelect(event.data);
  }

  onRowUnselect(event: { data: SupervisorSearchResultItemDto }) {
    this.handleUnselect(event.data);
  }

  handleSelect(result: SupervisorSearchResultItemDto): void {
    this.outputModel.supervisorSearchResults[0] = result;
  }

  handleUnselect(result: SupervisorSearchResultItemDto): void {
    const idx = this.outputModel.supervisorSearchResults.findIndex((item: SupervisorSearchResultItemDto) => {
      return item.Id == result.Id;
    });

    if (idx >= 0) {
      this.outputModel.supervisorSearchResults.splice(idx, 1);
    }
  }

  protected canSearchCommandExecute = (): boolean => {
    const x = this.getSearchCriteria();
    return x.FirstName != "" || x.LastName != "" || x.LicenseReferenceValue != "";
  }

  protected getSearchCriteria = (): ISupervisorSearchCriteriaDto => {
    return {
      LastName: this.contentForm.get('LastName').value ?? "",
      FirstName: this.contentForm.get('FirstName').value ?? "",
      LicenseReferenceValue: this.contentForm.get('LicenseReferenceValue').value ?? ""
    } as ISupervisorSearchCriteriaDto;
  }

  protected searchCommandExecute = () => {
    const doSearch = async (): Promise<any> => {
      let searchCriteria = this.getSearchCriteria();
      this.searchResults = (await this.supervisionService.searchSupervisors(<SupervisorSearchCriteriaDto>searchCriteria).toPromise()).Results;
      return of(true);
    }
    return from(doSearch());
  }
}

registerDynamicComponent(ReachScenarios.Default, 'SupervisorLookupComponent', SupervisorLookupComponent, 'supervisor-lookup');
