import { Compiler, Component, Inject, Injector, Input, OnInit, Optional } from '@angular/core';

import { SummaryComponentInfo } from '@coreModels/summary-component-info';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachComponentFactoryService, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { ReachInjectorService } from '@coreServices/reach-injector.service';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';

@Component({
  selector: 'landing-card-host',
  templateUrl: './landing-card-host.component.html',
  styleUrls: ['./landing-card-host.component.scss']
})
export class LandingCardHostComponent extends ReachModelAwareComponent implements OnInit {
  @Input() summaryInfo: SummaryComponentInfo;
  public contentComponentInfo

  constructor(private compiler: Compiler, private injector: Injector
    , private reachComponentFactoryService: ReachComponentFactoryService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) reachModelConfigurationInjector: ReachModelConfigurationInjector) {
    super(reachModelConfigurationInjector);
  }

  ngOnInit(): void {
    this.initContentComponent();
  }

  public get accessModel() {
    return this.summaryInfo && this.summaryInfo.model ? this.summaryInfo.model : null;
  }

  private initContentComponent() {
    const getInjector = (parentInjector: Injector) => {
      return this.reachComponentFactoryService.dynamicModelConfigurationInjector(
        MODEL_CONFIG_INJECTION_TOKEN,
        this.summaryInfo,
        this.accessConfiguration,
        parentInjector);
    };

    let injectorData = this.reachModelConfigurationInjector ? {
      injectorService: new ReachInjectorService(this.injector),
      modelInjector: this.reachModelConfigurationInjector
    }
      : getInjector(this.injector);

    this.contentComponentInfo = this.reachComponentFactoryService.dynamicComponentInfo(
      this.summaryInfo.scenarioKey,
      this.summaryInfo.configuration.summaryKey,
      injectorData.injectorService.injector,
      this.compiler
    );
  }
}
