import { Component, Injectable, Type } from '@angular/core';
import { noop, Observable, of, from, Subject } from 'rxjs';

import * as _l from 'lodash-es';

import { Command } from '@coreModels/command';
import { CommandService } from './command.service';
import { DialogSettings } from '@coreModels/dialog-settings';

@Injectable({ providedIn: 'root' })
export class DialogSettingsService {
  private currentDialog: DialogSettings = null;
  private dialogStack: DialogSettings[] = [];


  constructor(
    private commandService: CommandService) {


  }

  public create(
    dialogComponentInstance,
    scenarioKey: string = "",
    contentComponentType: Type<any>,
    contentComponentTypeKey: string = "",
    model,
    title: string,
    okCommand: Command = null,
    cancelCommand: Command = null,
    okCommandDoesNotCloseDialog: boolean = false,
    useModelReference: boolean = false): DialogSettings {
    // If the caller specified an OK command, use it, otherwise use default.
    let ok: Command = okCommand ||
      this.commandService.create(() => {
          return true;
        },
        () => {
          return of(noop);
        });

    // If the caller specified a Cancel command, use it, otherwise use default.
    let cancel: Command = cancelCommand ||
      this.commandService.create(() => {
          return true;
        },
        () => {
          return of(noop);
        });

    if (!useModelReference) {
      model = _l.cloneDeep(model);
    }

    let dialogSettings = new DialogSettings(dialogComponentInstance, scenarioKey, contentComponentType, contentComponentTypeKey, title, model, okCommandDoesNotCloseDialog);
    dialogSettings.open = this.createOpenFunction(dialogSettings);
    dialogSettings.okCommand = this.wrapOkCommand(ok, ok.commandText || "Ok", model, dialogSettings);
    dialogSettings.cancelCommand =
      this.wrapCancelCommand(cancel, cancel.commandText || "Cancel", model, dialogSettings);
    return dialogSettings;
  }

  public pushDialog(newDialog: DialogSettings) {
    if (this.currentDialog) {
      this.dialogStack.push(this.currentDialog);
    }

    this.currentDialog = newDialog;
  }

  public popDialog() {
    this.currentDialog = null;
    if (this.dialogStack.length > 0) {
      this.currentDialog = this.dialogStack.pop();
    }
  }

  private wrapOkCommand(command: Command,
    commandText: string,
    model,
    dialogSettings: DialogSettings) {
    const canExecute = (): boolean => {
      return !dialogSettings.disableOkCommand && command.canExecute(model);
    };

    const doExecute = async (): Promise<void> => {
      try {
        if (canExecute()) {
          let res = await of(command.execute(model)).toPromise();
          if (!dialogSettings.okCommandDoesNotCloseDialog) {
            dialogSettings.isOpen = false;
            this.popDialog();
          }
        }
      } catch (err) {
        // Caller-defined okCommand should handle errors. This try/catch protects the modalStack from corruption, so the dialog can be closed and not orphan dialog instances on the stack.
      }
    };

    const execute = (): Observable<void> => {
      return from(doExecute());
    };

    var wrappedCommand = this.commandService.create(canExecute, execute);
    wrappedCommand.commandText = commandText;
    return wrappedCommand;
  }

  private wrapCancelCommand(
    command: Command,
    commandText: string,
    model,
    dialogSettings: DialogSettings) {
    const canExecute = (): boolean => {
      return command.canExecute(model);
    }

    const doExecute = async (): Promise<void> => {
      try {
        if (canExecute()) {
          let res = await of(command.execute(model)).toPromise();
          dialogSettings.isOpen = false;
          this.popDialog();
        }
      } catch (err) {
        // Caller-defined okCommand should handle errors. This try/catch protects the modalStack from corruption, so the dialog can be closed and not orphan dialog instances on the stack.
      }
    };

    const execute = (): Observable<void> => {
      return from(doExecute());
    };

    var wrappedCommand: Command = this.commandService.create(canExecute, execute);
    wrappedCommand.commandText = commandText;
    return wrappedCommand;
  }

  private createOpenFunction = (dialogSettings: DialogSettings) => {
    const doOpen = () => {
      dialogSettings.isOpen = true;
      this.pushDialog(dialogSettings);
    };

    return doOpen;
  }
}
