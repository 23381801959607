export class WizardTags {

  constructor(public applicationTypeId: string = null
    , public applicationBasisId: string = null
    , public licenseStatusId: string = null
    , public formTypeId: number = null
    , public hasSupervisionPlan: boolean = null
    , public sponsorshipTypeId: number = null
    , public licenseStatusIdTo: string = null
    , public processId: number = null
    , public supervisorDesignationFormId: number = null
    , public isOpiate: boolean = null
    , public isOutOfState: boolean = null
    , public licenseSubtypeId: number = null
    , public licenseTypeId: string = null
  ) {
  }
}
