import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { Subscription } from "rxjs";

import {
  AuthGuard,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService,
  DynamicRoutesService,
  LandingPageDetailRouteResolverService,
  LandingPageService,
  OnlineServiceLinkManagerService,
  OnlineServiceMenuService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  SystemSettingsManagerService,
  UserManagerService
} from '@core/core.module';

import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { AutoVerificationSubscriptionDto } from "src/app/licensureShared";
export const AutoverificationLandingCardComponentKey = 'autoverificationLandingCardComponent';

/**
 * Service that supports the General shared features.
 */
@Injectable({
  providedIn: 'root'
})
export class AutoverificationConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private subscriptionLogin: Subscription;
  private subscriptionUserProfileRefreshed: Subscription;
  private subscriptionLandingPageReconfig: Subscription;
  private autoVerificationConstantsService: IAutoVerificationConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected landingPageService: LandingPageService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected router: Router,
    private summaryInfoBuilderService: SummaryInfoBuilderService,
    private summaryInfoManagerService: SummaryInfoManagerService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    protected userManagerService: UserManagerService
  ) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.AutoVerficationSubscription);
    this.subscriptionLogin = this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.subscriptionUserProfileRefreshed = this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.subscriptionLandingPageReconfig = this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  /**
 * Decorate the ConstantsService with Application-specific constants.
 */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      AutoVerificationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS,
      AutoVerificationConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      AutoVerificationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES,
      AutoVerificationConstantsService.Mergers.FUNCTION_TYPES);

    this.autoVerificationConstantsService = this.constantsService as IAutoVerificationConstantsService;
  }

  private configureDecorators() {
    //this.onLandingPageReconfig();
  }

  /**
   * Configure dynamic Application routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  private configureRoutes() {
    const routes: Routes = [
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.AutoVerificationDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AutoVerificationDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/autoVerificationWizard/auto-verification-wizard.module')
          .then(m => m.AutoVerificationWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/autoVerificationWizard/auto-verification-wizard.module')
          .then(m => m.AutoVerificationWizardModule)
      },
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/autoVerificationWizard/auto-verification-wizard-selector.module')
          .then(m => m.AutoVerificationWizardSelectorModule)
      },
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addInfosToLandingPage(principal) {
    if (!this.isInitialized) {
      this.initialize();
    }

    const isFeatureEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.AUTO_VERIFICATION.ENABLED);
    if (isFeatureEnabled
      && principal?.user.UserAccount.profile.AutoVerificationSubscribers?.[0]?.Subscriptions.some((subscription: AutoVerificationSubscriptionDto) => subscription.StatusId != 1)) {
      let infos: SummaryComponentInfo[] = [];
      const summaryDetailConfiguration = new SummaryDetailConfiguration(LicensureLandingCategories.AutoVerification, LicensureLandingComponentKeys.AutoVerificationSummaryLazy, LicensureLandingComponentKeys.AutoVerificationDetailLazy, SummaryAggregationType.Singleton)
      let autoVerificationInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Auto Verification", summaryDetailConfiguration);
      autoVerificationInfoBuilder.addItem(1, "Auto Verification", principal.user.UserAccount.profile.AutoVerificationSubscribers[0], this.autoVerificationConstantsService.DOMAIN_IDS.AUTO_VERIFICATION_SUBSCRIPTION);
      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}

/**
 * AutoVerification setting constants to merge into ConstantsService.
 */
export class AutoVerificationConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      AUTO_VERIFICATION_WIZARD: 'Auto.Verification.Wizard',
      AUTO_VERIFICATION_WIZARD_SELECTOR: 'Auto.Verification.Wizard.Selector',
    },
    DOMAIN_IDS: {
      AUTO_VERIFICATION_SUBSCRIPTION: 34
    },
    WEB_SERVICE_PROCESS_TYPES: {
      AUTO_VERIFICATION: LicensureOnlineServiceProcessTypeKeys.AutoVerficationSubscription,
    },
    FUNCTION_TYPES: {
      AUTO_VERIFICATION_SUBSCRIPTION: 27,
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    AutoVerificationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS),
    AutoVerificationConstantsService.Mergers.DOMAIN_IDS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
    AutoVerificationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES),
    AutoVerificationConstantsService.Mergers.FUNCTION_TYPES);
}

export interface IAutoVerificationConstantsService extends AutoVerificationConstantsService {
}
