import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export * from './dto/http-response-message';
export * from './dto-gen/core-shared-dto';
export * from './index';

export { NotificationsChangedEventArgument, ToggleNotificationsEventArgument } from './eventArgs/notification-event-args';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreSharedModule { }
