import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { Observable, of } from 'rxjs';
import { IDomainChecklistDtoHost, IDomainChecklistItemDto, IInitializeChecklistItemResponseDto, InitializeChecklistItemResponseDto } from 'src/app/coreShared/core-shared.module';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

@Injectable({ providedIn: 'root' })
export class DomainChecklistItemService extends ReachHttpClientService {
  private uri: string;
  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/DomainChecklistItem`;
  }

  public initializeItem(domainChecklistDtoHost: IDomainChecklistDtoHost, lookupDomainChecklistItemId: number): Observable<IInitializeChecklistItemResponseDto> {
    return this.post<InitializeChecklistItemResponseDto>(`${this.uri}/Initialize`, { DomainChecklistDtoHost: domainChecklistDtoHost, LookupDomainChecklistItemId: lookupDomainChecklistItemId });
  }

  public refreshSummary(domainChecklistItem: IDomainChecklistItemDto): Observable<IDomainChecklistItemDto> {
    return this.post<IDomainChecklistItemDto>(`${this.uri}/RefreshSummary`, domainChecklistItem);
  }

  fileUpload(domainChecklistItem: IDomainChecklistItemDto): Observable<IDomainChecklistItemDto> {
    return this.put<IDomainChecklistItemDto>(`${this.uri}/FileUpload`, domainChecklistItem);
  }

}
