// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import * as _ from 'lodash-es';

// Core
import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN,
  ReachScenarios,
  IReachDialogContentComponent,
  ReachDialogContentComponent,
  ValidationManagerService,
  registerDynamicComponent,
  BusyManagerService,
  ListService,
  UtilitiesService,
  ReachControlValidators
} from '@core/core.module';

import { LicensureConstantsProviderService } from '@licensureCore/licensure-core.module';

// Shared
import { IEntityTrainingDto, IEntityTrainingStatusDto } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureListService } from '@licensureCore/services/licensure-list.service';
import { IAddressLocationTypeDto, IAddressTypeDto, ICountryDto, ICountyDto, IStateDto } from 'src/app/coreShared/core-shared.module';
import { forkJoin, of, from } from 'rxjs';

@Component({
  selector: 'profile-postgraduate-training-editor',
  templateUrl: './profile-postgraduate-training-editor.component.html',
  styleUrls: ['./profile-postgraduate-training-editor.component.scss']
})
export class ProfilePostgraduateTrainingEditorComponent extends ReachDialogContentComponent<IEntityTrainingDto> implements OnInit, IReachDialogContentComponent {

  // FIELDS
  STATE: string = "State";
  PROVINCE: string = "Province";
  USA: string = "USA";
  CANADA: string = "CANADA";
  MN: string = "MN";

  possibleEntityTrainingStatuses: IEntityTrainingStatusDto[];
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleProvinces: IStateDto[];

  licensureConstantsService: LicensureConstantsProviderService;

  // CTOR
  constructor(

    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,

    // Custom
    protected busyManagerService: BusyManagerService,
    protected listService: ListService,
    private licensureListService: LicensureListService,
    protected formBuilder: FormBuilder,
    protected utilitiesService: UtilitiesService
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    this.friendlyNames.CompletionStatus = 'Completion Status';
    this.friendlyNames.StartDate = 'Start Date';
    this.friendlyNames.EndDate = 'End Date';
    
    // Base.
    await super.ngOnInit();
  }

  protected override loadLookupLists(): Promise<any> {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

       var licensureConstantsService = this.constantsService as LicensureConstantsProviderService;
    
      // Perform loading and filter operations.
      const responseCollection = await forkJoin([
        this.listService.getStates(), 
        this.listService.getCountries(),
        this.licensureListService.getEntityTrainingStatuses()
      ]).toPromise();
      
      this.possibleProvinces = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.CANADA) as IStateDto[];
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.USA) as IStateDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[1]) as ICountryDto[];
      this.possibleEntityTrainingStatuses = this.listService
          .filterInactiveItems(responseCollection[2])
          .filter((item: IEntityTrainingStatusDto) => (item.Id != licensureConstantsService?.ENTITY_TRAINING?.STATUSES?.NEW && item.Id != licensureConstantsService?.ENTITY_TRAINING?.STATUSES?.NOT_APPLICABLE)) as IEntityTrainingStatusDto[];

      // Populate dropdown FormControl elements.
      this.initDropdowns();

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT).toPromise();
  }


  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    var x = this.outputModel;
    this.contentForm = this.formBuilder.group({
      Program: [x.Program, [Validators.required, Validators.maxLength(80)]],
      Specialty: [x.Specialty, [Validators.required, Validators.maxLength(80)]],
      City: [x?.City, [Validators.required, Validators.maxLength(40)]],
      State: [null, [Validators.required]],
      Country: [null, [Validators.required, Validators.maxLength(60)]],
    });

    // Initialize the content form. (Date fields)
    let startDate: Date = null;
    if (this.inputModel.StartDate) startDate = new Date(this.inputModel.StartDate);
    this.contentForm.addControl('StartDate', new FormControl(startDate, [Validators.required, ReachControlValidators.pastDateValidator('Start Date')]));

    let endDate: Date = null;
    if (this.inputModel.EndDate) endDate = new Date(this.inputModel.EndDate);
    this.contentForm.addControl('EndDate', new FormControl(endDate, [Validators.required, ReachControlValidators.pastDateValidator('End Date')]));

    this.contentForm.addValidators(ReachControlValidators.dateRangeValidator(this.contentForm.get('StartDate'), this.contentForm.get('EndDate'), "Start Date", "End Date" ));

    // Initialize the content form. (Dropdowns)
    this.contentForm.addControl('CompletionStatus', new FormControl(null, [Validators.required]));

    super.modelToForm();
  }

  protected override initializeEventHandlers(): void {
    super.initializeEventHandlers();

    // Country
    this.contentForm.get('Country').valueChanges.subscribe(x => {

      // Check for enabled status of the country FormControl is intentional and necessary.
      // Else the state FormControl may not be disabled properly.
      this.contentForm.get('Country').enabled && (x?.Description == this.USA || x?.Description == this.CANADA)
        ? this.utilitiesService.enableDisable(this.contentForm.get('State'), true)
        : this.utilitiesService.enableDisable(this.contentForm.get('State'), false, true);
    });

    // Completion Status
    this.contentForm.get('CompletionStatus').valueChanges.subscribe(x => {      
      if (x?.Id == 2) this.utilitiesService.enableDisable(this.contentForm.get('EndDate'), true);  // If status is 'In Progress', disable the end date field
      else this.utilitiesService.enableDisable(this.contentForm.get('EndDate'), false, true);
    });

  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    // Local reference to the output model.
    let training = this.outputModel;

    // Standard fields.
    training.Program = this.contentForm.get('Program').value;
    training.Specialty = this.contentForm.get('Specialty').value;
    training.City = this.contentForm.get('City').value;
    training.State = this.contentForm.get('State').value?.StateCode ?? "";
    training.Country = this.contentForm.get('Country').value?.Description ?? "";

    training.StartDate = this.contentForm.get('StartDate').value;
    training.EndDate = this.contentForm.get('EndDate').value;

    // Assign lookup items.    
    training.StatusId = this.contentForm.get('CompletionStatus').value?.Id;
    if (training.StatusId) training.EntityTrainingStatus = this.possibleEntityTrainingStatuses.find(item => item.Id == training.StatusId);

    super.formToModel();
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  protected override initDropdowns(): void {

    if (this.outputModel.StatusId) this.contentForm.get('CompletionStatus').setValue(this.possibleEntityTrainingStatuses.find(item => item.Id == this.outputModel.StatusId));
    if (this.outputModel.Country) this.contentForm.get('Country').setValue(this.possibleCountries.find(item => item.Description == this.outputModel.Country));
    if (this.outputModel.State) this.contentForm.get('State').setValue((this.outputModel?.Country == this.CANADA ? this.possibleProvinces : this.possibleStates).find(item => item.StateCode == this.outputModel.State));

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'ProfilePostgraduateTrainingEditorComponent', ProfilePostgraduateTrainingEditorComponent, 'profile-postgraduate-training-editor');
