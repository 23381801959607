import { IBaseDto,
IDataRequestRequestDto,
IDataRequestResponseDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class DataRequestResponseDto extends BaseDto implements IBaseDto, IDataRequestResponseDto {
	CorrespondenceId?: number;
	ItemCount?: number;
	DataRequestFee?: number;
	RequestSucceeded: boolean;
	FeedbackMessage: string;
	ElapsedMilliseconds?: number;
	Request: IDataRequestRequestDto;

}
