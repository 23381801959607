import { Injectable } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommandKeyBindingManagerService {
  commandKeyBindings: CommandKeyBinding[] = [];
  keyboardEventSubscription = new Subscription();

  constructor() {

    this.keyboardEventSubscription.add(
      fromEvent(window, 'keydown')
        .subscribe((x: KeyboardEvent) => {

          if (x.key === "Enter") {
            this.executeStrongestButton(x);
          }
          else if (x.key === "Escape") {
            this.executeStrongestButton(x);
          }
          else if ((x.altKey || x.metaKey) && x.key == "c") {
            this.executeStrongestButton(x)
          }
        }));
  }

  ngOnDestroy() {
    this.keyboardEventSubscription.unsubscribe();
  }

  /**
   * We may have multiple key bindings for a give key in memory.
   * Assume the highest indexed binding is the one we want to execute.
   * @param keyboardEvent 
   * @returns 
   */
  public executeStrongestButton(keyboardEvent: KeyboardEvent) {

    // if the target element is currently focused, and it is a button. The enter key will nativly raise the
    // click event. In these cases we do not need execute the command binding.
    if (keyboardEvent.target === document.activeElement && keyboardEvent.target instanceof HTMLButtonElement) {
      return;
    }

    // Check if a command is registered for the current key press
    let fullKey: string;

    if (keyboardEvent.altKey) {
      fullKey = "Alt+" + keyboardEvent.key.toUpperCase();
    }
    else if (keyboardEvent.ctrlKey) {
      fullKey = "Ctrl+" + keyboardEvent.key.toUpperCase();
    }
    else {
      fullKey = keyboardEvent.key;
    }

    const commandBindings: CommandKeyBinding[] = this.findKeyBindingsForKey(fullKey);

    // We may have multiple key bindings for a give key in memory, this is most likey the case
    // with dialogs. We will assume the highest indexed binding is the one we want to execute
    const strongestBinding: CommandKeyBinding = commandBindings[commandBindings.length - 1];

    // if we have a command execute it
    if (strongestBinding && strongestBinding.command && strongestBinding.command.canExecute()) {
      strongestBinding.command.execute();
    }

  }

  public findKeyBindingsForKey(key): CommandKeyBinding[] {
    return this.commandKeyBindings.filter(binding => binding.key === key);
  }

  public add(commandKeyBinding: CommandKeyBinding) {
    this.commandKeyBindings.push(commandKeyBinding);
  }

  public remove(command: any) {
    this.commandKeyBindings = this.commandKeyBindings.filter(binding => binding.command != command);
  }
}

export class CommandKeyBinding {
  constructor(public key, public command) {
  }
}
