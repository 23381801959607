import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { of } from 'rxjs';

import * as _l from 'lodash-es';

import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { OnlineServiceHistoryService } from '@coreServices/online-service-history.service';
import { SimpleChanges } from "node_modules/@angular/core/core";
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';

@Component({
  selector: 'domain-checklist-item',
  templateUrl: './domain-checklist-item.component.html',
  styleUrls: ['./domain-checklist-item.component.scss']
})
export class DomainChecklistItemComponent implements OnChanges, OnInit {
  // The DomainChecklistItem host
  @Input()
  domainChecklistHost;

  // If a specific DomainChecklistItem is not supplied. This Id will be used to locate the first item in the Host's DomainChecklistItem collection with the supplied template id.
  @Input()
  checklistItemTemplateId;

  // Support an optional DynamicContentConfiguration
  @Input()
  dynamicContentConfiguration;

  // If the DomainChecklistItem directive delegates to a Child Directive optional configuration information may be required
  @Input()
  directiveConfiguration;

  // If a specific DomainChecklistItem is supplied, the ChecklistItemTemplateId will be ignored.
  @Input()
  checklistItem;

  // If hosting a domain questionnaire use this function to filter questions
  @Input()
  questionnaireFilterQuestionsFunction;

  constructor(
  private busyManagerService: BusyManagerService,
  private commandService: CommandService,
  @Inject(CONSTANTS_SERVICE_TOKEN) public constantsService: ConstantsService,
  private onlineServiceHistoryService: OnlineServiceHistoryService,
  public systemSettingsManagerService: SystemSettingsManagerService) { }

  ngOnInit(): void {
    const doInit = () => {
      // if a checklistItemTemplateId item is not supplied, use the supplied checklistItemId to locate the instance.
      if (this.checklistItem == null) {
        this.checklistItem = _l.find(this.domainChecklistHost.DomainChecklist,
          (domainChecklistItem) => {
            return domainChecklistItem.ChecklistItemTemplateId === this.checklistItemTemplateId;
          });
      } else {
        this.checklistItemTemplateId = this.checklistItem.ChecklistItemTemplateId;
      }

      return of(this.checklistItemTemplateId);
    }

    this.busyManagerService.resolve(doInit(), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checklistItemTemplateId == null && this.checklistItem == null) {
      throw "directive domainChecklistItem requires either checklistItemTemplateId, or domainChecklistItem properties to be valued.";
    }

    this.checklistItemTemplateId = parseInt(this.checklistItemTemplateId);
  }

}
