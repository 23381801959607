import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IPublicSuperviseeSupervisorInfoDto } from './licensure-shared-dto';

export class PublicSuperviseeSupervisorInfoDto extends BaseDto implements IBaseDto, IPublicSuperviseeSupervisorInfoDto {
	FirstName: string;
	MiddleName: string;
	LastName: string;
	FullName: string;
	Facility: string;
	StartDate?: Date;
	EndDate?: Date;

}
