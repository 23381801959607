import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  ListService,
  SharedConfigurationService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import * as _ from 'underscore';

import {
  ApplicationBasisTypeDto,
  ApplicationTypeDto,
  AutoVerificationSubscriptionTypeDto,
  CeCreditTypeDto,
  CeResponseTypeDto,
  CeSponsorCourseStatusDto,
  CeSponsorshipStatusDto,
  CeSponsorshipTypeDto,
  CeStudyTypeDto,
  CertificationTypeDto,
  ConvictionTypeDto,
  DegreeDto,
  EducationProgramDto,
  EducationProgramLevelDto,
  EducationProgramTypeDto,
  EmploymentPositionTitleDto,
  EmploymentSectorDto,
  EmploymentSettingDto,
  EmploymentTypeDto,
  EntityTrainingStatusDto,
  ExamTypeDto,
  FacultyEducationTypeDto,
  IDegreeDto,
  IEmploymentPositionTitleDto,
  IEmploymentSectorDto,
  IEmploymentSettingDto,
  IEmploymentTypeDto,
  LicenseStatusDto,
  LicenseTypeDto,
  MajorDto,
  SupervisionHoursTypeDto
} from '@licensureShared/licensure-shared.module';
import { LookupDto } from "src/app/coreShared";

let licensureListServiceFactory = (container: SharedConfigurationService, busyManagerService: BusyManagerService, http: HttpClient, utilitiesService: UtilitiesService, validationManagerService: ValidationManagerService) => {
  var licensureListServiceFactory = new LicensureListService(busyManagerService, container.constantsService, container.defaultProvider, http, utilitiesService, validationManagerService);
  container.listService = licensureListServiceFactory;
  return licensureListServiceFactory;
};

@Injectable({
  providedIn: 'root', useFactory: licensureListServiceFactory, deps: [SharedConfigurationService, BusyManagerService, HttpClient, UtilitiesService, ValidationManagerService]
})
export class LicensureListService extends ListService {

  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , utilitiesService: UtilitiesService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, utilitiesService, validationManagerService);
  }

  public filterInactiveItems(collection) {
    return _.filter(collection,
      (item: LookupDto) => {
        return !item.IsInactive;
      });
  }
  
  public getEmploymentSettings() { return this.getLookupType<IEmploymentSettingDto>("{0}/licensure/EmploymentSetting", this.utilitiesService.classNameFromType(EmploymentSettingDto)); }
  public getEmploymentSectors() { return this.getLookupType<IEmploymentSectorDto>("{0}/licensure/EmploymentSector", this.utilitiesService.classNameFromType(EmploymentSectorDto)); }

  public getEmploymentTypes(): Observable<IEmploymentTypeDto[]> {
    return this.getLookupType<ConvictionTypeDto>("{0}/licensure/EmploymentType",
      this.utilitiesService.classNameFromType(EmploymentTypeDto));
  }

  public getEmploymentPositionTitles(): Observable<IEmploymentPositionTitleDto[]> {
    return this.getLookupType<ConvictionTypeDto>("{0}/licensure/EmploymentPositionTitle",
      this.utilitiesService.classNameFromType(EmploymentPositionTitleDto));
  }

  public getConvictionTypes(): Observable<ConvictionTypeDto[]> {
    return this.getLookupType<ConvictionTypeDto>("{0}/licensure/ConvictionType",
      this.utilitiesService.classNameFromType(ConvictionTypeDto));
  }

  public getSupervisionHoursTypes(): Observable<SupervisionHoursTypeDto[]> {
    return this.getLookupType<SupervisionHoursTypeDto>("{0}/licensure/SupervisionHoursType",
      this.utilitiesService.classNameFromType(SupervisionHoursTypeDto));
  }

  public getEntityTrainingStatuses(): Observable<EntityTrainingStatusDto[]> {
    return this.getLookupType<EntityTrainingStatusDto>("{0}/licensure/EntityTrainingStatus",
      this.utilitiesService.classNameFromType(EntityTrainingStatusDto));
  }

  public getApplicationTypes() {
    return this.getLookupType<ApplicationTypeDto>("{0}/licensure/ApplicationType",
      this.utilitiesService.classNameFromType(ApplicationTypeDto));
  }

  public getLicenseTypes(): Observable<LicenseTypeDto[]> {
    return this.getLookupType<LicenseTypeDto>("{0}/licensure/LicenseType",
      this.utilitiesService.classNameFromType(LicenseTypeDto));
  }

  public getLicenseStatusTypes(): Observable<LicenseStatusDto[]> {
    return this.getLookupType<LicenseStatusDto>("{0}/licensure/LicenseStatusType",
      this.utilitiesService.classNameFromType(LicenseStatusDto));
  }

  // Gets a list of supported application bases
  public getApplicationBases() {
    return this.getLookupType<ApplicationBasisTypeDto>("{0}/licensure/ApplicationBasisType",
      this.utilitiesService.classNameFromType(ApplicationBasisTypeDto));
  }

  // Gets a list of supported application exams
  public getExamTypes() {
    return this.getLookupType<ExamTypeDto>("{0}/licensure/ExamType",
      this.utilitiesService.classNameFromType(ExamTypeDto));
  }

  // Gets a list of possible EducationPrograms (ex. College, Foreign Education, Degree, Masters)
  public getEducationPrograms() {
    return this.getLookupType<EducationProgramDto>("{0}/licensure/EducationProgram",
      this.utilitiesService.classNameFromType(EducationProgramDto));
  }

  public getEducationProgramTypes() {
    return this.getLookupType<EducationProgramTypeDto>("{0}/licensure/EducationProgramType",
      this.utilitiesService.classNameFromType(EducationProgramTypeDto));
  }

  public getEducationProgramLevels() {
    return this.getLookupType<EducationProgramLevelDto>("{0}/licensure/EducationProgramLevel",
      this.utilitiesService.classNameFromType(EducationProgramLevelDto));
  }

  public getFacultyEducationTypes() {
    return this.getLookupType<FacultyEducationTypeDto>("{0}/licensure/FacultyEducationType",
      this.utilitiesService.classNameFromType(FacultyEducationTypeDto));
  }

  public getCertificationTypes() {
    return this.getLookupType<CertificationTypeDto>("{0}/licensure/CertificationType",
      this.utilitiesService.classNameFromType(CertificationTypeDto));
  }

  // Gets a list of possible Degrees (ex. Bachelors, Masters, etc.)
  public getDegrees() {
    return this.getLookupType<DegreeDto>("{0}/licensure/Degree",
      this.utilitiesService.classNameFromType(DegreeDto));
  }

  // Gets degrees filtered by type
  public filterDegreesByType(degreeArray: IDegreeDto[], typeCode: string) {

    return _.filter(degreeArray, item => {
      return item.ApplicationTypeId == typeCode || item.ApplicationTypeId.startsWith("*");
    });
  }

  // Gets a list of possible Majors (ex. Computer Science, Political Science, etc.)
  public getMajors() {
    return this.getLookupType<MajorDto>("{0}/licensure/Major",
      this.utilitiesService.classNameFromType(MajorDto));
  }

  // Gets a list of CE Response Types
  public getCeResponseTypes() {
    return this.getLookupType<CeResponseTypeDto>("{0}/licensure/CeResponseType",
      this.utilitiesService.classNameFromType(CeResponseTypeDto));
  }

  // Gets a list of Course Credit Types (ex. Abuse, Acupuncture, Animal, etc)
  public getCourseCreditTypes() {
    return this.getLookupType<CeCreditTypeDto>("{0}/licensure/CeCreditType",
      this.utilitiesService.classNameFromType(CeCreditTypeDto));
  }

  // Gets a list of Course Study Types (ex. Instructor Led, Self Study, Online, Unknown)
  public getCourseStudyTypes() {
    return this.getLookupType<CeStudyTypeDto>("{0}/licensure/CeStudyType",
      this.utilitiesService.classNameFromType(CeStudyTypeDto));
  }

  // Gets a list of Sponsorship Types (ex. 1 to 8 hours, 9 to 16 hours, etc)
  public getSponsorshipTypes() {
    return this.getLookupType<CeSponsorshipTypeDto>("{0}/licensure/CeSponsorshipType",
      this.utilitiesService.classNameFromType(CeSponsorshipTypeDto));
  }

  // Gets a list of CESponsorship Statuses (ex. Active, Expired, Pending, Revoked)
  public getCeSponsorshipStatusTypes() {
    return this.getLookupType<CeSponsorshipStatusDto>("{0}/licensure/CeSponsorshipStatus",
      this.utilitiesService.classNameFromType(CeSponsorshipStatusDto));
  }

  // Gets a list of CESponsorship Course Statuses (ex. New, In Process, Approved, Denied)
  public getCeSponsorCourseStatusTypes() {
    return this.getLookupType<CeSponsorCourseStatusDto>("{0}/licensure/CeSponsorCourseStatus",
      this.utilitiesService.classNameFromType(CeSponsorCourseStatusDto));
  }

  // Gets a list of Auto Verification Subscription Types (ex. 1-100 Licenses, 1-500 Licenses, 1-1000 Licenses, etc)
  public getAutoVerificationSubscriptionTypes(): Observable<AutoVerificationSubscriptionTypeDto[]> {
    return this.getLookupType<AutoVerificationSubscriptionTypeDto>("{0}/licensure/AutoVerificationSubscriptionType",
      this.utilitiesService.classNameFromType(AutoVerificationSubscriptionTypeDto));
  }
}
