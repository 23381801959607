import * as _l from 'lodash-es';

import { DynamicContentDto } from '@coreShared/core-shared.module';

export enum QuestionTypes {
  YesNo,
  Checkbox,
  Numeric,
  Text,
  TextArea
}

export class QuestionItem {
  questionText: string;
  questionNumber: number;
  questionType: QuestionTypes;
  isRequired: boolean = true;
  isQuestionNumberHidden: boolean = false;
  isDisabled: boolean = false;

  constructor(public majorKey: string, public minorKey: string) {
  }

  public get key(): string {
    return this.minorKey;
  }
}

export class Questionnaire<T> {
  public questions = new Map<string, QuestionItem>();
  public questionItems: QuestionItem[] = [];
  public questionLabels = new Map<string, string>();
  public answers = {};
  public questionText: DynamicContentDto[] = [];
  public questionKeys: string[] = [];
  public yesNoQuestionKeys: string[] = [];
  public checkboxQuestionKeys: string[] = [];
  public numericQuestionKeys: string[] = [];
  public textQuestionKeys: string[] = [];
  public textAreaQuestionKeys: string[] = [];

  constructor(public majorKey: string, protected questionKeyObject: T) {
    this.questionKeys = Object.keys(questionKeyObject);
    this.initializeQuestionText();
    this.questionKeys.forEach(k => {
      this.addQuestionItem(k)
    });
    this.initializeQuestionTypes();
  }

  public addQuestionItem(key: string) {
    let question = new QuestionItem(this.majorKey, key);
    this.questions.set(key, question);
    this.answers[key] = null;
    let textEntry: DynamicContentDto = _l.find(this.questionText, (entry: DynamicContentDto) => {
      return entry.MajorKey === question.majorKey
        && entry.MinorKey === question.minorKey;
    });

    question.questionText = textEntry.Description;
    question.questionNumber = textEntry.DisplayOrder;
  }

  protected addQuestionText(key: string, text: string, displayOrder: number) {
    let content = new DynamicContentDto();
    content.MajorKey = this.majorKey;
    content.MinorKey = key;
    content.Description = text;
    content.DisplayOrder = displayOrder;
    this.questionText.push(content);
    this.questionLabels.set(key, text);
  }

  protected initializeQuestionTypes() {
    this.questions.forEach(q => {
      switch (q.questionType) {
        case QuestionTypes.Checkbox:
          this.checkboxQuestionKeys.push(q.minorKey);
          break;
        case QuestionTypes.Numeric:
          this.numericQuestionKeys.push(q.minorKey);
          break;
        case QuestionTypes.Text:
          this.textQuestionKeys.push(q.minorKey);
          break;
        case QuestionTypes.YesNo:
          this.yesNoQuestionKeys.push(q.minorKey);
          break;
        case QuestionTypes.TextArea:
          this.textAreaQuestionKeys.push(q.minorKey);
          break;
      }

      this.questionItems.push(q);
    });
  }

  protected initializeQuestionText() {
  }
}

