import { Component, OnInit, ElementRef, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { from, Observable, of } from "rxjs";
import { ReachScenarios } from "src/app/core/index-constants";
import { WizardStepComponent } from "src/app/core/index-directives";
import { Command, WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from "src/app/core/index-models";
import { CheckboxContentService, ValidationManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, CommandService, BusyManagerService } from "src/app/core/index-services";
import { PreceptorVerificationService } from "../../services/preceptor-verification.service";
import { IPreceptorVerificationResponseDto, PreceptorVerificationRequestDto } from "../../../board-providers/chiProvider/shared/chi-shared-dto";

export const PreceptorshipComponentSelector: string = "preceptorship";

@Component({
  selector: PreceptorshipComponentSelector,
  templateUrl: './preceptorship.component.html',
  styleUrls: ['./preceptorship.component.scss']
})
export class PreceptorshipComponent extends WizardStepComponent implements OnInit {

  public okCommand: Command;
  public preceptorVerificationResponse: IPreceptorVerificationResponseDto;

  constructor(
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,

    // Custom imports
    protected commandService: CommandService,
    protected preceptorVerificationService: PreceptorVerificationService,
    private busyManagerService: BusyManagerService,
  ) {

    super(constantsService, validationManagerService, wizardInjector, PreceptorshipComponent.name, checkboxContentService, elementRef);

    this.modelToForm();
    this.getPreceptorByLicenseId();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initCommands();
    this.wizard.selectedStep.onStepForwardStart(this.onNext);
  }

  private initCommands(): void {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
  }

  protected getPreceptorByLicenseId(): void {
    const doInit = async (): Promise<any> => {
      
      if (this.wizard.model.application.PreceptorLicenseId) {
        
        var result = await this.preceptorVerificationService.getByLicenseId(this.wizard.model.application.PreceptorLicenseId).toPromise();
        if (result.IsValid) {
          this.preceptorVerificationResponse = result;          
          this.stepForm.get('PreceptorLicenseNumber').setValue(this.preceptorVerificationResponse?.LicenseNumber);
          
          this.updateBag();
        }
        else {
          result.AdditionalErrors.forEach(element => { this.validationManagerService.addValidationFailure(element); });
          this.validationManagerService.showApplicationValidationSummary();
        }

      }

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  protected override modelToForm() {
    this.friendlyNames.PreceptorLicenseNumber = "Preceptor Registration #";
    this.stepForm.addControl('PreceptorLicenseNumber', new FormControl(null, [Validators.required]));

    super.modelToForm();
  }

  protected override formToModel(): void {
    this.updateBag();
  }

  protected updateBag() {
    this.wizard.selectedStep.bag.addOrReplaceItem("my_key", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, 
    [
      this.preceptorVerificationResponse?.LicenseNumber, 
      this.preceptorVerificationResponse?.PreceptorName,
      this.preceptorVerificationResponse?.PreceptorPeriod
    ]);
  }

  protected onNext = (e): Observable<boolean> => {

    if (!this.stepForm.valid) {
      this.wizard.saveStepHistoryAction(e);
      e.cancel = true;
      this.validationManagerService.showApplicationValidationSummary();
    }
    else if (!this.preceptorVerificationResponse) {
      e.cancel = true;
      this.validationManagerService.addValidationFailureMessage("Validate preceptor credentials before continuing.", "");
      this.validationManagerService.showApplicationValidationSummary();
      this.wizard.saveStepHistoryAction(e);
    }

    this.wizard.model.application.PreceptorLicenseId = this.preceptorVerificationResponse.LicenseId;


    return of(true);
  }

  protected canOkCommandExecute = (): boolean => {
    return this.stepForm.valid && (!this.preceptorVerificationResponse || (this.preceptorVerificationResponse.LicenseNumber != this.stepForm.get('PreceptorLicenseNumber').value)) ;
  }

  protected okCommandExecute = () => {
    var preceptorVerificationRequest = new PreceptorVerificationRequestDto();
    preceptorVerificationRequest.LicenseNumber = this.stepForm.get('PreceptorLicenseNumber').value;

    const doInit = async (): Promise<any> => {

      this.preceptorVerificationResponse = null; // Clear existing (if any)

      var result = await this.preceptorVerificationService.verify(preceptorVerificationRequest).toPromise();
      if (result.IsValid) {
        this.preceptorVerificationResponse = result;
        
        this.updateBag();
      }
      else {
        result.AdditionalErrors.forEach(element => { this.validationManagerService.addValidationFailure(element); });
        this.validationManagerService.showApplicationValidationSummary();
      }

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }
}

registerDynamicComponent(ReachScenarios.ApplicationWizard, 'preceptorship', PreceptorshipComponent, PreceptorshipComponentSelector);