import { IActivitySubactivityDto,
IActivitySubtypeDto,
IActivityTypeDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class ActivitySubactivityDto extends LookupDto implements IActivitySubactivityDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	IsDisplayedOnClosingLetters: boolean;
	ClosingLetterDisplayPhrase: string;
	ClosingLetterDisplayHierarchy?: number;
	ViewCorrespondenceRight: string;
	CreateCorrespondenceRight: string;
	UpdateCorrespondenceRight: string;
	DeleteCorrespondenceRight: string;
	ViewActivityRight: string;
	CreateActivityRight: string;
	UpdateActivityRight: string;
	DeleteActivityRight: string;
	ActivitySubtype: IActivitySubtypeDto;
	ActivityType: IActivityTypeDto;
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;

}
