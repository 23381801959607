import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _l from 'lodash-es';
import { Observable, of } from 'rxjs';
import { IDocumentDto, IFileUploadDtoHost } from 'src/app/coreShared/core-shared.module';
import { ReachScenarios } from '../../index-constants';
import { LandingPageFileUploadEditorComponent } from '../../index-directives';
import { Command, DialogSettings } from '../../index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService } from '../../index-services';

@Component({
  selector: 'landing-page-file-upload',
  templateUrl: './landing-page-file-upload.component.html',
  styleUrls: ['./landing-page-file-upload.component.scss']
})
export class LandingPageFileUploadComponent implements OnInit {

  @Input() hideIfEmpty: boolean = false;
  @Input() fileUploadDtoHost: IFileUploadDtoHost;
  @Input() save: () => Observable<any>;

  presentItemEditorCommand: Command;
  okCommand: Command;
  removeItemCommand: Command;
  dialogSettings: DialogSettings;

  public get fileUploadDtoHostDocuments(): IDocumentDto[] { return this.fileUploadDtoHost?.Documents.filter(item => !item.IsDeleted); }

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    private commandService: CommandService) {
  }

  ngOnInit(): void {
    this.presentItemEditorCommand = this.commandService.create(() => true, this.presentItemEditorCommandExecute);
    this.okCommand = this.commandService.create(() => true, this.okCommandExecute);
  }

  protected presentItemEditorCommandExecute = (): Observable<any> => {

    this.dialogSettings = new DialogSettings(
      null, // Component instance
      ReachScenarios.Default, // Scenario key
      LandingPageFileUploadEditorComponent, // Content type
      'LandingPageFileUploadEditorComponent', // Content key
      "Manage Document Uploads", // Title
      _l.cloneDeep(this.fileUploadDtoHost), // Model
      null, // OK command does not close dialog
      true); // Use model reference

    // Hand the editor the okCommand we created earlier.    
    // Present the editor dialog.
    this.dialogSettings.okCommand = this.okCommand;
    this.dialogSettings.isOpen = true;

    return of(true);
  }

  protected okCommandExecute = (output: IFileUploadDtoHost) => {
    this.fileUploadDtoHost.DocumentIds = output.DocumentIds;
    this.fileUploadDtoHost.Documents = output.Documents;

    this.save();
  }

}
