// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";

// LODASH
import * as _l from 'lodash-es';

// Core
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@core/components/reach-dialog/reach-dialog-content.component';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

// Shared
import { IEntityDto } from '@coreShared/core-shared.module';

@Component({
  selector: 'entity-website-editor',
  templateUrl: './entity-website-editor.component.html',
  styleUrls: ['./entity-website-editor.component.scss']
})
export class EntityWebsiteEditorComponent extends ReachDialogContentComponent<IEntityDto> implements IReachDialogContentComponent {

  // CTOR
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    this.contentForm = new FormGroup({
      Website: new FormControl(this.outputModel.Website || 'http://', [Validators.required, Validators.maxLength(100)])
    });

    super.modelToForm();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    this.outputModel.Website = this.contentForm.get('Website').value;

    super.formToModel();
  }

}

// Register this component.
registerDynamicComponent(ReachScenarios.Default, 'EntityWebsiteEditorComponent', EntityWebsiteEditorComponent, 'entity-website-editor');
