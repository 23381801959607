import {
  IOnlineUserDto,
  UserDto
} from '@coreShared/core-shared.module';

export class Principal {
  id: number;
  username: string;
  token: string;
  user2: UserDto;
  user: IOnlineUserDto;
  userId: string;
  initials: string;
  displayName: string;
}






