import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent, IReachDialogContentComponent, ReachControlValidators } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService, UtilitiesService } from 'src/app/core/index-services';
import { IStateDto, ICountryDto } from 'src/app/coreShared/core-shared.module';
import { IApplicationBasisTypeDto, IOtherLicenseChecklistItemDto } from 'src/app/licensureShared/licensure-shared.module';

export const ApplicationOtherLicensesEditorComponentSelector: string = "application-other-licenses-editor";
export const ApplicationOtherLicensesEditorComponentKey: string = "ApplicationOtherLicensesEditor";

const USA: string = "USA";
const CANADA: string = "CANADA";

@Component({
  selector: ApplicationOtherLicensesEditorComponentSelector,
  templateUrl: './application-other-licenses-editor.component.html',
  styleUrls: ['./application-other-licenses-editor.component.scss']
})
export class ApplicationOtherLicensesEditorComponent extends ReachDialogContentComponent<IOtherLicenseChecklistItemDto> implements IReachDialogContentComponent {

  applicationBasis: IApplicationBasisTypeDto;
  public possibleStates: IStateDto[];
  public filteredPossibleStates: IStateDto[];
  public possibleCountries: ICountryDto[];

  public get stateLabel(): string { return this.contentForm.get("Country").value?.Description == CANADA ? "Province" : "State"; }
  public get stateVisible(): boolean { return this.outputModel.OtherLicense.Country == USA || this.outputModel.OtherLicense.Country == CANADA; }

  constructor(changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    protected utilitiesService: UtilitiesService
  ) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  protected override modelToForm(): void {

    this.possibleStates = this.input.initializationData.possibleStates;
    this.possibleCountries = this.input.initializationData.possibleCountries;
    this.applicationBasis = this.input.initializationData.applicationBasis;
    const otherLicenseDto = this.outputModel.OtherLicense;

    this.contentForm = new FormGroup({
      IsOriginalLicense: new FormControl(otherLicenseDto.IsOriginalLicense),
      State: new FormControl(null, [Validators.required]),
      Country: new FormControl(null, [Validators.required]),
      Type: new FormControl(otherLicenseDto.LicenseType || '', [Validators.required, Validators.maxLength(50)]),
      Number: new FormControl(otherLicenseDto.LicenseNumber || '', [Validators.required, Validators.maxLength(50)]),
      Issued: new FormControl((otherLicenseDto.IssuedDate ? new Date(otherLicenseDto.IssuedDate) : null), [Validators.required, Validators.maxLength(11), ReachControlValidators.pastDateValidator('Issued Date')]),
      Expiration: new FormControl((otherLicenseDto.ExpirationDate ? new Date(otherLicenseDto.ExpirationDate) : null), [Validators.required, Validators.maxLength(11), ReachControlValidators.pastDateValidator('Expiration Date')]),
    });

    this.contentForm.addValidators(ReachControlValidators.dateRangeValidator(this.contentForm.get('Issued'), this.contentForm.get('Expiration'), "Issued Date", "Expiration Date" ));

    super.modelToForm();
  }

  protected override formToModel(): void {

    if (!this.dataLoaded) return;
    if (this.applicationBasis.IsOtherLicOriginalLicenseApplicable) this.outputModel.OtherLicense.IsOriginalLicense = this.contentForm.get('IsOriginalLicense').value;
    this.outputModel.OtherLicense.State = this.contentForm.get('State').value?.StateCode;
    this.outputModel.OtherLicense.Country = this.contentForm.get('Country').value?.Description;
    this.outputModel.OtherLicense.LicenseType = this.contentForm.get('Type').value;
    this.outputModel.OtherLicense.LicenseNumber = this.contentForm.get('Number').value;

    const issued = this.contentForm.get('Issued').value;
    if (issued) this.outputModel.OtherLicense.IssuedDate = formatDate(issued, 'MM/dd/yyyy', 'en_US');
    const expiration = this.contentForm.get('Expiration').value;
    if (expiration) this.outputModel.OtherLicense.ExpirationDate = formatDate(expiration, 'MM/dd/yyyy', 'en_US');

    super.formToModel();
  }

  protected override initializeEventHandlers(): void {

    this.contentForm.get('Country').valueChanges.subscribe(x => {
      const selectedCountry = x?.Description;
      if (!selectedCountry) return;

      selectedCountry == USA || selectedCountry == CANADA
        ? this.utilitiesService.enableDisable(this.contentForm.get('State'), true, true)
        : this.utilitiesService.enableDisable(this.contentForm.get('State'), false, true);

      this.filteredPossibleStates = this.possibleStates.filter(item => item?.Country?.toUpperCase() == x.Description?.toUpperCase());
      this.contentForm.get('State').setValue(this.filteredPossibleStates.find(item => item.StateCode == this.outputModel.OtherLicense.State));
    });

    super.initializeEventHandlers();
  }

  protected override initDropdowns(): void {
    if (this.outputModel.IsNew) this.outputModel.OtherLicense.Country = USA;
    if (this.outputModel.OtherLicense.State) this.contentForm.get('State').setValue(this.possibleStates.find(item => item.StateCode == this.outputModel.OtherLicense.State));
    if (this.outputModel.OtherLicense.Country) this.contentForm.get('Country').setValue(this.possibleCountries.find(item => item.Description == this.outputModel.OtherLicense.Country));
    super.initDropdowns();
  }

}

registerDynamicComponent(ReachScenarios.Default, ApplicationOtherLicensesEditorComponentKey, ApplicationOtherLicensesEditorComponent, ApplicationOtherLicensesEditorComponentSelector);