import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { ICertificationChecklistItemDto,
ICertificationDto } from './licensure-shared-dto';

export class CertificationChecklistItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, ICertificationChecklistItemDto {
	Certification: ICertificationDto;

}
