

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ArgumentExceptionService, BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DocumentService, ReachHttpClientService, UtilitiesService, ValidationManagerService } from '@core/core.module';
import { ILicenseDto, IRenewalDto, LicenseDto, LicenseInfoDto } from '@licensureShared/licensure-shared.module';
import { Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class LicenseService extends ReachHttpClientService {
  uri: string = null;
  infoUri: string = null;

  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , utilitiesService: UtilitiesService
    , validationManagerService: ValidationManagerService
    , private documentService: DocumentService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/license", this.apiRootUri);
    this.infoUri = utilitiesService.stringFormatArgs("{0}/licensure/licenseInfo", this.apiRootUri);
  }

  public markSurveyComplete(renewalId: number) {
    return this.get<void>(`${this.uri}/MarkSurveyComplete/${renewalId}`);
  }

  public updatePicManaged(licenseItems: ILicenseDto[], captchaResponse: string) {
    return this.put<boolean>(`${this.uri}/PicManaged/items?captchaResponse=${captchaResponse}`, licenseItems);
  }

  public getLicenseInfo(id: number): Observable<LicenseInfoDto> {
    return this.get<LicenseInfoDto>(`${this.infoUri}/${id}`);
  }

  public getById(id: number): Observable<LicenseDto> {
    if (!id) { this.argumentExceptionService.create("id").log(); }
    return this.get<LicenseDto>(`${this.uri}/${id}`);
  }

  public save(license: ILicenseDto): Observable<ILicenseDto> {

    if (license.InProcessRenewal) license.InProcessRenewal.EntityId = license.EntityId;
    license.ActiveRenewal.EntityId = license.EntityId; // Assume we always have an ActiveRenewal.

    const firstObservable = this.documentService.uploadAllDocuments(license.InProcessRenewal);
    const secondObservable = this.documentService.uploadAllDocuments(license.ActiveRenewal);
    const thirdObservable = this.put<LicenseDto>(this.uri, license).pipe(map((license: LicenseDto) => { return license; }));

    // Using concatMap to execute the observables IN SEQUENCE and return an Observable<ILicenseDto>
    const result: Observable<ILicenseDto> = firstObservable.pipe(
      concatMap((inProcessRenewal: IRenewalDto) => {

        license.AdditionalErrors = license.AdditionalErrors.concat(inProcessRenewal?.AdditionalErrors);

        return secondObservable.pipe(
          concatMap((activeRenewal: IRenewalDto) => {

            license.AdditionalErrors = license.AdditionalErrors.concat(activeRenewal?.AdditionalErrors);

            return thirdObservable.pipe(
              map(licenseDto => {

                return licenseDto; // Return the result of the second operation

              })
            );
          })
        );
      })
    );

    return result;
  }
}
