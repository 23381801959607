
/**
 * Registry item that associates a online/wizard service key with route metadata.
 */
export class OnlineServiceRegistryItem {
  public onlineServicePath: string;
  public selectorPath: string;
  public onlineServicePathTemplate: string;
  public selectorPathTemplate: string;
  constructor(
    public onlineServiceTypeKey: string,
    public name: string,
    public onlineServiceBaseRoute: string,
    public selectorBaseRoute: string,
    public onlineServiceUrlSegmentPattern: string,
    public selectorUrlSegmentPattern: string) {
    this.onlineServicePath = onlineServiceBaseRoute;
    this.selectorPath = selectorBaseRoute;
    this.onlineServicePathTemplate = this.onlineServicePath + onlineServiceUrlSegmentPattern;
    this.selectorPathTemplate = this.selectorPath + selectorUrlSegmentPattern;
  }
}

/**
 * Registry that maintains online/wizard route metadata.
 */
export class OnlineServiceRegistry{
  private static registry = new Map<string, OnlineServiceRegistryItem>();

  /**
   * Adds the specified OnlineServiceRegistryItem to the online/wizard registry.
   * @param item
   */
  public static registerItem(item: OnlineServiceRegistryItem) {
    OnlineServiceRegistry.registry.set(item.onlineServiceTypeKey, item);
  }

/**
 * Registers the specified wizard against the service keys.
 * @param onlineServiceTypeKey the key that uniquely identifies the wizard service. Matches server-side key.
 * @param name the name of the wizard.
 * @param onlineServiceBaseRoute the base segment of the route to the wizard.
 * @param selectorBaseRoute the base segment of the route to the wizard selector.
 * @param onlineServiceUrlSegmentPattern the segment pattern to the wizard.
 * @param selectorUrlSegmentPattern the segment pattern to the wizard selector.
 */
  public static register(
    onlineServiceTypeKey: string,
    name: string,
    onlineServiceBaseRoute: string,
    selectorBaseRoute: string,
    onlineServiceUrlSegmentPattern: string,
    selectorUrlSegmentPattern: string) {
    let item = new OnlineServiceRegistryItem(
      onlineServiceTypeKey,
      name,
      onlineServiceBaseRoute,
      selectorBaseRoute,
      onlineServiceUrlSegmentPattern,
      selectorUrlSegmentPattern
    );

    OnlineServiceRegistry.registerItem(item);
  }

/**
* Gets the OnlineServiceRegistryItem metadata item matching the specified onlineServiceTypeId from the registry.
* @param onlineServiceTypeId
*/
  public static getItemByOnlineServiceTypeId(onlineServiceTypeId: number): OnlineServiceRegistryItem {
    return this.getItemByWizardTypeKey(onlineServiceTypeId.toString());
  }

  /**
   * Gets the WizardRegistryItem metadata item matching the specified wizardTypeKey from the registry.
   * @param wizardTypeKey
   */
  public static getItemByWizardTypeKey(wizardTypeKey: string): OnlineServiceRegistryItem {
    let item = OnlineServiceRegistry.registry.get(wizardTypeKey);
    if (!item) {
      throw `No service was registered for the key '${wizardTypeKey}'`;
    }

    return item;
  }
}

/**
 * Registers the specified OnlineService/Wizard against the service keys.
 * @param onlineServiceTypeKey the key that uniquely identifies the online service. Matches server-side key.
 * @param name the name of the wizard.
 * @param onlineServiceBaseRoute the base segment of the route to the wizard.
 * @param selectorBaseRoute the base segment of the route to the wizard selector.
 * @param onlineServiceUrlSegmentPattern the segment pattern to the wizard.
 * @param selectorUrlSegmentPattern the segment pattern to the wizard selector.
 */
export const registerOnlineService = (
  onlineServiceTypeKey: string,
  name: string,
  onlineServiceBaseRoute: string,
  selectorBaseRoute: string,
  onlineServiceUrlSegmentPattern: string,
  selectorUrlSegmentPattern: string): void => {
  OnlineServiceRegistry.register(
    onlineServiceTypeKey,
    name,
    onlineServiceBaseRoute,
    selectorBaseRoute,
    onlineServiceUrlSegmentPattern,
    selectorUrlSegmentPattern);
}
