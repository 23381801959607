import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { Observable, of } from "rxjs";
import { EntityEmploymentListDto } from "src/app/licensureShared";
import { IEntityEmploymentListDto } from "src/app/licensureShared/licensure-shared.module";
import { ArgumentExceptionService } from '../../core/services/argument-exception.service';
import { BusyManagerService } from '../../core/services/busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../../core/services/constants-provider.service';
import { ReachHttpClientService } from '../../core/services/reach-http-client.service';
import { ValidationManagerService } from '../../core/services/validation-manager.service';
import { ILicensureConstantsProviderService } from "./licensure-constants-provider.service";

@Injectable({ providedIn: 'root' })
export class EntityEmploymentService extends ReachHttpClientService {
  uri: string = null;
  licensureConstantsService: ILicensureConstantsProviderService;

  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {

    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/licensure/entityEmployment`;
    this.licensureConstantsService = constantsService as ILicensureConstantsProviderService;
  }

  public getByEntityId(entityId: number): Observable<EntityEmploymentListDto> {

    if (!this.licensureConstantsService.SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.ENABLED) return of(null);
    if (!entityId) throw this.argumentExceptionService.create("entityId").log();

    return this.get<EntityEmploymentListDto>(`${this.uri}/${entityId}`);
  }

  public getByEmployerLicenseId(licenseId: number): Observable<EntityEmploymentListDto> {

    if (!this.licensureConstantsService.SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.ENABLED) return of(null);
    return this.get<EntityEmploymentListDto>(`${this.uri}/GetByEmployerLicenseId/${licenseId}`);
  }

  public getByEmployeeLicenseId(licenseId: number): Observable<EntityEmploymentListDto> {
    return this.get<EntityEmploymentListDto>(`${this.uri}/GetByEmployeeLicenseId/${licenseId}`);
  }

  updateByEmployerLicenseId(entityEmploymentList: IEntityEmploymentListDto, validationScope: string = null): Observable<IEntityEmploymentListDto> {
    return this.put<EntityEmploymentListDto>(`${this.uri}/UpdateByEmployerLicenseId`, entityEmploymentList, validationScope);
  }

  public update(entityEmploymentList: IEntityEmploymentListDto, validationScope: string = null): Observable<IEntityEmploymentListDto> {
    return this.put<EntityEmploymentListDto>(this.uri, entityEmploymentList, validationScope);
  }
}
