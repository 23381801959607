import { Subject, Observable } from "rxjs";
import * as _l from 'lodash-es';
import { PicklistModel } from './pick-list-model';

export class ObservablePicklistModel {
  private _selectedItemsSubject$ = new Subject<any>();
  public selectedItemsChanged$ = this._selectedItemsSubject$.asObservable();

  constructor(private model: PicklistModel) {

  }

  public updateSelections(items) {
    this.model.selectedItems = items;
    this._selectedItemsSubject$.next(items);
  }

  public getModel() {
    return this.model;
  }

  public cloneModel() {
    return _l.clone(this.model);
  } 
}
