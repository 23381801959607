import { Injectable, Inject } from '@angular/core';
//import decode from 'jwt-decode';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { LocalStorageService } from './local-storage.service';
import { SharedConfigurationService } from './configuration/shared-configuration.service';
import { Principal } from '@coreModels/principal';

/**
 * Factory function for instantiating instances of AuthorizationService.
 * @param container the SharedConfigurationService that holds sharable service instances.
 * @param localStorageService the LocalStorageService
 */
let authorizationServiceFactory = (container: SharedConfigurationService, localStorageService: LocalStorageService) => {
  let constService = container.constantsService;
  let authService = new AuthorizationService(constService, localStorageService);

  return authService;
};

/**
 * Service to manage authorization tokens.
 */
//@Injectable({
//    providedIn: 'root',
//    deps: [CONSTANTS_SERVICE_TOKEN, LocalStorageService]
//})
@Injectable({ providedIn: 'root', useFactory: authorizationServiceFactory, deps: [SharedConfigurationService, LocalStorageService] })
export class AuthorizationService {
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private localStorageService: LocalStorageService) {

  }

  /**
   * Gets the user token from storage if it exists.
   */
  public getToken(): string {
    let principal: Principal = null;
      if (this.constantsService &&
      this.constantsService.SYSTEM_CONSTANTS &&
      this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID) {
        principal = this.localStorageService.retrieve(this.constantsService.SYSTEM_CONSTANTS.LOCAL_STORAGE_STORE_ID);
    } else {
        principal = this.localStorageService.retrieve("principal");
    }

      return principal ? principal.token : "";
  }

  /**
   * Returns True if the user is logged on with a non-expired authorization token.
   */
  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    //return tokenNotExpired(null, token);
    // TODO: use the JsonWebToken(JWT) service to check the expiry claim on the token, and implement framework to pop a warning of pending expiration.
    return token ? true : false;
  }
}
