import { LandingComponentKeys } from '@core/core.module';

/**
 * Enumerates the Licensure Categories for the LandingPage.
 */
export enum LicensureLandingCategories {
  Application,
  AutoVerification,
  CeAuditInfo,
  CeSponsorship,
  DataRequest,
  EducationProgram,
  EducationProgramApplication,
  License,
  OrganizationalPayor,
  Profile,
  Supervision,
  AssociatedLicense,
  LicenseStatusChange,
}

/**
 * Enumerates the Licensure Route/Page yypes for the LandingPage.
 */
export enum LicensureLandingTypes {

  ApplicationSummary,
  ApplicationSummaryLazy,
  ApplicationDetail,
  ApplicationDetailLazy,

  AutoVerificationSummary,
  AutoVerificationSummaryLazy,
  AutoVerificationDetail,
  AutoVerificationDetailLazy,

  CeAuditInfoSummary,
  CeAuditInfoSummaryLazy,
  CeAuditInfoDetail,
  CeAuditInfoDetailLazy,

  CeSponsorshipSummary,
  CeSponsorshipSummaryLazy,
  CeSponsorshipDetail,
  CeSponsorshipDetailLazy,

  DataRequestSummary,
  DataRequestSummaryLazy,
  DataRequestDetail,
  DataRequestDetailLazy,

  EducationProgramSummary,
  EducationProgramSummaryLazy,
  EducationProgramDetail,
  EducationProgramDetailLazy,

  EducationProgramApplicationSummary,
  EducationProgramApplicationSummaryLazy,
  EducationProgramApplicationDetail,
  EducationProgramApplicationDetailLazy,

  LicenseSummary,
  LicenseSummaryLazy,
  LicenseDetail,
  AssociatedLicenseDetail,
  LicenseDetailLazy,
  AssociatedLicenseDetailLazy,

  OrganizationalPayorSummary,
  OrganizationalPayorDetail,
  OrganizationalPayorSummaryLazy,
  OrganizationalPayorDetailLazy,

  ProfileSummary,
  ProfileDetail,
  ProfileSummaryLazy,
  ProfileDetailLazy,

  SupervisionSummary,
  SupervisionDetail,
  SupervisionSummaryLazy,
  SupervisionDetailLazy,

  AssociatedLicenseSummary,
  AssociatedLicenseList,
  AssociatedLicenseSummaryLazy,
  AssociatedLicenseListLazy,

  LicenseStatusChangeSummary,
  LicenseStatusChangeSummaryLazy,
  LicenseStatusChangeDetail,
  LicenseStatusChangeDetailLazy,
}
/**
 * Defines component names to use as component keys, registration keys, and route keys/params for Licensure Landing Page components.
 */
export class LicensureLandingComponentKeys extends LandingComponentKeys {

  public static readonly AssociatedLicenseSummary: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseSummary];
  public static readonly AssociatedLicenseList: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseList];
  public static readonly AssociatedLicenseSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseSummaryLazy];
  public static readonly AssociatedLicenseListLazy: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseListLazy];

  public static readonly LicenseStatusChangeSummary: string = LicensureLandingTypes[LicensureLandingTypes.LicenseStatusChangeSummary];
  public static readonly LicenseStatusChangeDetail: string = LicensureLandingTypes[LicensureLandingTypes.LicenseStatusChangeDetail];
  public static readonly LicenseStatusChangeSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.LicenseStatusChangeSummaryLazy];
  public static readonly LicenseStatusChangeDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.LicenseStatusChangeDetailLazy];

  public static readonly ApplicationSummary: string = LicensureLandingTypes[LicensureLandingTypes.ApplicationSummary];
  public static readonly ApplicationDetail: string = LicensureLandingTypes[LicensureLandingTypes.ApplicationDetail];
  public static readonly ApplicationSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.ApplicationSummaryLazy];
  public static readonly ApplicationDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.ApplicationDetailLazy];

  public static readonly AutoVerificationSummary: string = LicensureLandingTypes[LicensureLandingTypes.AutoVerificationSummary];
  public static readonly AutoVerificationDetail: string = LicensureLandingTypes[LicensureLandingTypes.AutoVerificationDetail];
  public static readonly AutoVerificationSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.AutoVerificationSummaryLazy];
  public static readonly AutoVerificationDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.AutoVerificationDetailLazy];

  public static readonly CeAuditInfoSummary: string = LicensureLandingTypes[LicensureLandingTypes.CeAuditInfoSummary];
  public static readonly CeAuditInfoDetail: string = LicensureLandingTypes[LicensureLandingTypes.CeAuditInfoDetail];
  public static readonly CeAuditInfoSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.CeAuditInfoSummaryLazy];
  public static readonly CeAuditInfoDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.CeAuditInfoDetailLazy];

  public static readonly CeSponsorshipSummary: string = LicensureLandingTypes[LicensureLandingTypes.CeSponsorshipSummary];
  public static readonly CeSponsorshipDetail: string = LicensureLandingTypes[LicensureLandingTypes.CeSponsorshipDetail];
  public static readonly CeSponsorshipSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.CeSponsorshipSummaryLazy];
  public static readonly CeSponsorshipDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.CeSponsorshipDetailLazy];

  public static readonly DataRequestSummary: string = LicensureLandingTypes[LicensureLandingTypes.DataRequestSummary];
  public static readonly DataRequestDetail: string = LicensureLandingTypes[LicensureLandingTypes.DataRequestDetail];
  public static readonly DataRequestSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.DataRequestSummaryLazy];
  public static readonly DataRequestDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.DataRequestDetailLazy];

  public static readonly EducationProgramSummary: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramSummary];
  public static readonly EducationProgramDetail: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramDetail];
  public static readonly EducationProgramSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramSummaryLazy];
  public static readonly EducationProgramDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramDetailLazy];

  public static readonly EducationProgramApplicationSummary: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramApplicationSummary];
  public static readonly EducationProgramApplicationDetail: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramApplicationDetail];
  public static readonly EducationProgramApplicationSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramApplicationSummaryLazy];
  public static readonly EducationProgramApplicationDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.EducationProgramApplicationDetailLazy];

  public static readonly LicenseSummary: string = LicensureLandingTypes[LicensureLandingTypes.LicenseSummary];
  public static readonly LicenseDetail: string = LicensureLandingTypes[LicensureLandingTypes.LicenseDetail];
  public static readonly LicenseSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.LicenseSummaryLazy];
  public static readonly LicenseDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.LicenseDetailLazy];
  public static readonly AssociatedLicenseDetail: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseDetail];
  public static readonly AssociatedLicenseDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.AssociatedLicenseDetailLazy];

  public static readonly OrganizationalPayorSummary: string = LicensureLandingTypes[LicensureLandingTypes.OrganizationalPayorSummary];
  public static readonly OrganizationalPayorDetail: string = LicensureLandingTypes[LicensureLandingTypes.OrganizationalPayorDetail];
  public static readonly OrganizationalPayorSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.OrganizationalPayorSummaryLazy];
  public static readonly OrganizationalPayorDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.OrganizationalPayorDetailLazy];

  public static readonly ProfileSummary: string = LicensureLandingTypes[LicensureLandingTypes.ProfileSummary];
  public static readonly ProfileDetail: string = LicensureLandingTypes[LicensureLandingTypes.ProfileDetail];
  public static readonly ProfileSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.ProfileSummaryLazy];
  public static readonly ProfileDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.ProfileDetailLazy];

  public static readonly SupervisionSummary: string = LicensureLandingTypes[LicensureLandingTypes.SupervisionSummary];
  public static readonly SupervisionDetail: string = LicensureLandingTypes[LicensureLandingTypes.SupervisionDetail];
  public static readonly SupervisionSummaryLazy: string = LicensureLandingTypes[LicensureLandingTypes.SupervisionSummaryLazy];
  public static readonly SupervisionDetailLazy: string = LicensureLandingTypes[LicensureLandingTypes.SupervisionDetailLazy];
}

