import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { ISupervisionVerificationSearchResultItemDto } from './licensure-shared-dto';

export class SupervisionVerificationSearchResultItemDto extends SearchResultItemDto implements ISupervisionVerificationSearchResultItemDto {
	Id: number;
	ReferenceValue: string;
	PlanReferenceValue: string;
	LicenseId?: number;
	LicenseTypeCode: string;
	LicenseNumber?: number;
	ApplicationNbr?: number;
	FormId?: number;
	FormDescription: string;
	StatusId?: number;
	StatusDescription: string;
	SupervisorStatusId?: number;
	SupervisorStatus: string;
	SupervisorStatusDescription: string;
	ReceivedDate?: Date;
	BeginDate?: Date;
	EndDate?: Date;
	SupervisionPlanId?: number;
	SupervisorId?: number;
	SupervisorEntityId?: number;
	SupervisorName: string;
	SuperviseeEntityId?: number;
	SuperviseeLastName: string;
	SuperviseeFirstName: string;
	SuperviseeMiddleName: string;
	SuperviseeFullName: string;
	PracticeLocation: string;
	EmployerName: string;
	PositionTitle: string;
	ApprovedPracticeHours?: number;
	StatusDisplayOrder: number;
	WebServiceHistoryId?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
