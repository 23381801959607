import { IEntityRelatedToItemSupport,
ICashMasterDto,
IDocumentDto,
IDomainChecklistItemDto,
IDomainCriminalBackgroundCheckDto,
IDomainReasonForChangeDtoHost,
IEntityAddressDto,
IEntityCommentDto,
IExtendedPropertyDto,
IRelatedToBaseDto,
IDomainFormDto} from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IApplicationBasisTypeDto,
IApplicationDto,
IApplicationTrainingDto,
IApplicationTypeDto } from './licensure-shared-dto';

export class ApplicationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IApplicationDto {
  KeywordProfessionTypeId?: number;
  HostHasIncompleteDomainForm?: boolean;
  DomainForms: IDomainFormDto[];
	DomainChecklistItemConstants: any;
	DomainChecklistItemStatusAspectStatusConstants: any;
	AddressTypeId: number;
	EntityId?: number;
	IsNotInWorkforce?: boolean;
	PeriodStartDate?: Date;
	PeriodEndDate?: Date;
	IsTrainingWaived: boolean;
	StaffAssigned: string;
	FacilityApplicantEntityId?: number;
	FacilityApplicantName: string;
	DoingBusinessAs: string;
	City: string;
	FacilityEntityId?: number;
	FacilityName: string;
	Program: string;
	SupervisionCompleteDate?: Date;
	IsSuspendSupervisionDetail?: boolean;
	TemporaryPermitRequestedWithApplication: boolean;
	HasNoPracticeLocations: boolean;
	HasNoOtherLicenses: boolean;
	HasNoExams: boolean;
	HasNoSpecializedTrainings: boolean;
	ApplicationTypeId: string;
	ApplicationStatusId: string;
	StatusModifiedDate?: Date;
	StatusModifiedBy: string;
	PersistedApplicationStatusId: string;
	ApplicationDate?: Date;
	GrantedDate?: Date;
	BoardIssueDate?: Date;
	Interviewer: string;
	InterviewDate?: Date;
	CharterState: string;
	CharterDate?: Date;
	BasisId: string;
	LicenseNumber?: number;
	DegreeCodes: string;
	ExamDescription: string;
	ApplicationType: IApplicationTypeDto;
	Basis: IApplicationBasisTypeDto;
	Addresses: IEntityAddressDto[];
	AddressMailing: IEntityAddressDto;
	AddressPublic: IEntityAddressDto;
	Cash: ICashMasterDto[];
	ApplicationTrainings: IApplicationTrainingDto[];
	BasicTemporaryPermits: any[];
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ExtendedProperties: IExtendedPropertyDto[];
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	IsCriminalBackgroundCheckRequired?: boolean;
	ReferenceValue: string;
	CriminalBackgroundChecks: IDomainCriminalBackgroundCheckDto[];
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	RowVersion: number[];
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	DocumentRelatedToItems: IEntityRelatedToItemSupport[];
	DomainKeywords: any[];
}


