<!-- Grid -->
<div class="p-fluid p-formgrid p-grid" [formGroup]="stepForm">

    <!-- PrimeNG Table -->
    <p-table styleClass="p-datatable-sm" [value]="applicableItems"
        [responsive]="true">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <span class="p-text-left reach-list-header">Postgraduate Training</span>
                <div>
                    <button pButton pRipple class="p-button-text" icon="fa fa-plus"
                        [command-button]="presentEditorCommand" tool-tip="Add a new item"></button>
                </div>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>Program</th>
                <th>Specialty</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th style="width: 8em;">
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Program -->
                <td>{{item.Program | whenEmpty : "-"}}</td>

                <!-- Specialty -->
                <td>{{item.Specialty | whenEmpty : "-"}}</td>

                <!-- Start Date -->
                <td>{{item.StartDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}</td>

                <!-- End Date -->
                <td>{{item.EndDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}</td>

                <!-- EntityTrainingStatus -->
                <td>{{item.EntityTrainingStatus == null ? "-" : item.EntityTrainingStatus.Description}}</td>

                <!-- Buttons -->
                <td class="p-text-right">
                    <span class="p-text-nowrap">

                        <!-- Edit -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                            [command-button]="presentEditorCommand" [command-parameter]="item"
                            tool-tip="Edit this item"></button>

                        <!-- Delete -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                            [command-button]="removeItemCommand" [command-parameter]="item"
                            tool-tip="Delete this item"></button>

                    </span>
                </td>

            </tr>
        </ng-template>

        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="6">
                    <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
                </td>
            </tr>
        </ng-template>

    </p-table>

</div>

<!-- IMPORTANT -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>