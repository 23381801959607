import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WizardStepComponent } from 'src/app/core/core.module';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';

export const CeAuditFileUploadComponentSelector: string = 'ce-audit-file-upload';
export const CeAuditFileUploadComponentKey: string = 'ceAuditFileUpload';

@Component({
  selector: CeAuditFileUploadComponentSelector,
  templateUrl: './ce-audit-file-upload.component.html',
  styleUrls: ['./ce-audit-file-upload.component.scss']
})
export class CeAuditFileUploadComponent extends WizardStepComponent implements OnInit {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef
  ) {
    super(constantsService, validationManagerService, wizardInjector, CeAuditFileUploadComponent.name, checkboxContentService, elementRef);
    this.modelToForm();
  }

  ngOnInit(): void {
    this.wizard.model.submission.IsExempt = this.wizard.model.domainAudit.IsExempt; // TODO: Why isn't the submission object updated somewhere else?
  }

  protected modelToForm() {
    this.stepForm.addControl('FileUploadList', new FormControl());
    super.modelToForm();
  }
}

registerDynamicComponent(ReachScenarios.CeAuditWizard, CeAuditFileUploadComponentKey, CeAuditFileUploadComponent, CeAuditFileUploadComponentSelector);