import * as _l from 'lodash-es';

import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { RouteInfoRegistry, RouteInfoRegistryItem } from './route-registry';

/**
 * Encapsulates the information required to build a summary tab or card.
 */
export class SummaryComponentInfo {
  public items: SummaryComponentInfo[] = [];
  public readonly id: string;
  public scenarioKey: string = ReachScenarios.LandingPage;

  //Obsolete
  //public detailRoute: string;
  public summaryRoute: string;
  public outletName: string;

  constructor(
    public index: number,
    public title: string,
    //public detailRoute: string,
    //public summaryRoute: string,
    //public outletName: string,
    public model,
    public configuration: SummaryDetailConfiguration,
    public domainId: number,
    public tabOrder: number = 0
  ) {
    this.id = SummaryComponentInfo.createId(this.configuration.detailKey, this.index);
  }

  public get detailRoute(): string {
    let route = this.configuration.detailRoute + `/${this.model.Id}`;
    return route;
  }

  public static createId(route: string, index: number) {
    return `${FeatureNames[FeatureNames.LandingPage]}.${route}.${index.toString()}`;
  }

  public static parseId(id: string) {
    let tokens = id.split(".");
    //let typedFeatureString = tokens[0] as keyof typeof FeatureNames;
    let feature = FeatureNames[FeatureNames.LandingPage];
    let index: number = +tokens[2];
    let idObject = { feature: feature, route: tokens[1], index: index };
    return idObject;
  }
}

/**
 * Encapsulates configuration data for SummaryComponentInfos and cardsets/tabsets.
 */
export class SummaryDetailConfiguration {
  detailRoute: RouteInfoRegistryItem;
  summaryDetailRoute: RouteInfoRegistryItem;
  summaryRoute: RouteInfoRegistryItem;
  feature: FeatureNames.LandingPage;

  constructor(public configKey: number, public summaryKey: string, public detailKey: string, public aggregationType: SummaryAggregationType, public summaryDetailKey: string = null) {
    this.detailRoute = RouteInfoRegistry.getItemByRegistryTypeKey(detailKey);
  }
}

/**
 * Manages a set of SummaryComponentInfos for a tab page or card page.
 */
export class SummaryInfoSet {
  selectedIndex: number = 0;
  model = null;
  dynamicContentConfiguration = null;

  constructor(public infos: SummaryComponentInfo[] = []) {
    this.sort();
  }

  public get selectedTab() {
    return this.infos[this.selectedIndex];
  }

  public setSelectedTab(index) {
    this.selectedIndex = index;
  }

  public sort(): void {
    this.infos = _l.sortBy(this.infos, i => i.tabOrder);
  }
}

/**
 * Enumerates the types of summary components.
 */
export enum SummaryComponentType {
  Tab,
  Card
}

/**
 * Enumerates the aggregation types for tabsets and cardsets.
 * Single - card and entity are 1:1
 * Aggregate - a single card aggregates multiple entities
 */
export enum SummaryAggregationType {
  Aggregate,
  Singleton
}

/**
 * Enumerates the names of supported features that use summary info.
 */
export enum FeatureNames {
  LandingPage,
  OnlineEntitySearch
}


