import { IAddressLocationTypeDto,
IAddressTypeDto,
ICountyDto,
IDomainReasonForChangeDtoHost,
IEntityAddressDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class EntityAddressDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityAddressDto {
	EntityId: number;
	AddressType: IAddressTypeDto;
	IsCurrent: boolean;
	IsPublic: boolean;
	IsMailing: boolean;
	Line1: string;
	Line2: string;
	Line3: string;
	City: string;
	State: string;
	Zip: string;
	CountyId?: number;
	County: ICountyDto;
	Country: string;
	AddressLocationType: IAddressLocationTypeDto;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	AddressFull: string;
	StreetLines: string;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;

}
