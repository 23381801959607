import { Component, Inject, OnInit } from '@angular/core';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';

import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';

@Component({
  selector: 'reach-print-header',
  templateUrl: './reach-print-header.component.html',
  styleUrls: ['./reach-print-header.component.scss']
})
export class ReachPrintHeaderComponent implements OnInit {
  public logoImage: string = "";
  public brand;

  constructor(
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private systemSettingsManagerService: SystemSettingsManagerService,
  ) {
    this.logoImage = this.defaultProviderConfigurationService.coreAssetRoot + "/img/mn-logo-blue-footer.png";
    this.brand = this.systemSettingsManagerService.get(this.constantsService.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
  }

  ngOnInit(): void {
  }

}
