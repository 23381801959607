import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReachScenarios, LandingComponentKeys } from 'src/app/core/index-constants';
import { OnlineServiceLinkSubscription, OnlineServiceProcessTypes, registerDynamicComponent } from 'src/app/core/index-models';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, NotificationCenterManagerService, OnlineServiceLinkManagerService, MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, SummaryInfoManagerService, UserManagerService, UtilitiesService } from 'src/app/core/index-services';
import { IInvoiceInfoDto } from 'src/app/coreShared/core-shared.module';
import { LandingCardBaseComponent } from '../../landingBase/card/landing-card-base.component';

export const InvoiceLandingCardComponentSelector = 'invoice-landing-card';
@Component({
  selector: 'app-invoice-landing-card',
  templateUrl: './invoice-landing-card.component.html',
  styleUrls: ['./invoice-landing-card.component.scss']
})
export class InvoiceLandingCardComponent extends LandingCardBaseComponent implements OnInit {

  readonlyInfo: any;
  public get isHidden(): boolean { return false; }

  constructor(activatedRoute: ActivatedRoute
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , injector: Injector
    , notificationCenterManagerService: NotificationCenterManagerService
    , onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
    , router: Router
    , summaryInfoManagerService: SummaryInfoManagerService
    , userManagerService: UserManagerService
    , utilitiesService: UtilitiesService
  ) {
    super(activatedRoute
      , busyManagerService
      , constantsService
      , injector
      , notificationCenterManagerService
      , onlineServiceLinkManagerService
      , reachModelConfigurationInjector
      , router
      , summaryInfoManagerService
      , userManagerService
      , utilitiesService
      , constantsService.DOMAIN_IDS.GENERAL
    );

    this.onlineServiceLinkSubscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Any, () => { return this.domainKeyId }));
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
    super.ngOnInit();

    if (this.info) {
      this.readonlyInfo = this.createReadonlyInfo(this.info.title, this.info.id, this.info.model);
    }
  }

  private createReadonlyInfo(title: string, id: string, invoiceInfo: IInvoiceInfoDto): any {
    return {
      title: `Invoice - #${invoiceInfo.Id}`,
      id: invoiceInfo.Id,
      amount: invoiceInfo.Amount,
      description: invoiceInfo.Description
    };
  }
}

registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.InvoiceSummary, InvoiceLandingCardComponent, InvoiceLandingCardComponentSelector);