import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { from, of } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { Command, DialogSettings, registerDynamicComponent, WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { BusyManagerService, CheckboxContentService, CommandService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ListService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { IStateDto } from 'src/app/coreShared/core-shared.module';
import { IEntityWorkHistoryDto } from 'src/app/licensureShared/licensure-shared.module';
import { ProfessionalProfileService } from '../../services/professional-profile.service';
import { ProfileWorkHistoryEditorComponent, ProfileWorkHistoryEditorComponentKey } from './editor/profile-work-history-editor.component';

export const ProfileWorkHistoryComponentSelector: string = 'profile-work-history';
export const ProfileWorkHistoryComponentKey: string = 'profileWorkHistory';
const ACKNOWLEDGMENT: string = "Acknowledgment";

@Component({
  selector: ProfileWorkHistoryComponentSelector,
  templateUrl: './profile-work-history.component.html',
  styleUrls: ['./profile-work-history.component.scss']
})
export class ProfileWorkHistoryComponent extends WizardStepComponent implements OnInit {

  presentEditorCommand: Command;
  removeItemCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings = null;
  readonly bagKey: string = "work-history-acknowledgment";
  acknowledgmentDynamicContentMinorKey: string;

  possibleStates: IStateDto[];

  public get workHistory(): IEntityWorkHistoryDto[] { return this.wizard.model.professionalProfile.EntityWorkHistory.filter(item => !item.IsDeleted) }

  constructor(
    checkboxContentService: CheckboxContentService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector

    // Custom
    , private commandService: CommandService
    , private utilitiesService: UtilitiesService
    , private confirmationService: ConfirmationService
    , private professionalProfileService: ProfessionalProfileService
    , private listService: ListService
    , private busyManagerService: BusyManagerService
  ) {
    super(constantsService, validationManagerService, wizardInjector, ProfileWorkHistoryComponent.name, checkboxContentService, elementRef);
    this.modelToForm();
  }

  protected modelToForm() {
    super.modelToForm();
    this.stepForm.addControl(ACKNOWLEDGMENT, new FormControl(false, Validators.requiredTrue))
  }

  protected formToModel(): void {
    this.wizard.selectedStep.bag.addOrReplaceItem(
      this.bagKey, // key
      this.constantsService.BAG_ITEM_TYPES.CHECKBOX_CONTENT, // bag item type
      ["", this.stepForm.get(ACKNOWLEDGMENT).value, this.acknowledgementLabel]); // bag item
  }

  ngOnInit(): void {
    this.loadLookupLists();
    this.initCommands();

    this.checkWorkHistoryCollection();
  }

  protected loadLookupLists(): void {

    const doInit = async (): Promise<any> => {
      this.possibleStates = this.listService.filterInactiveItems(await (this.listService.getStates()).toPromise()) as IStateDto[];
      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (item: IEntityWorkHistoryDto) => {
    if (!item.IsNew) item.IsDirty = true;
    this.utilitiesService.addOrReplace(this.wizard.model.professionalProfile.EntityWorkHistory, item);

    this.checkWorkHistoryCollection();
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (selectedItem: IEntityWorkHistoryDto) => {

    const initDialog = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ProfileWorkHistoryEditorComponent, // Content type
        ProfileWorkHistoryEditorComponentKey, // Content key
        selectedItem ? "Work History" : "Work History - {new}", // Title
        selectedItem ?? { Id: 0, IsNew: true, IsDeleted: false, LocalId: this.utilitiesService.guid(), EntityId: this.wizard.model.professionalProfile.Id } as IEntityWorkHistoryDto, // Model
        null, // OK command does not close dialog
        true); // Use model reference

      this.dialogSettings.initializationData.possibleStates = this.possibleStates;
      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      return of(true).toPromise();
    }

    return from(initDialog());
  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (item: IEntityWorkHistoryDto) => {

    this.confirmationService.confirm({
      message: `${item.BusinessSetting} work history at ${item.Facility} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',
      accept: () => {

        const doSave = async (): Promise<any> => {
          item.IsDeleted = true;

          if (item.LocalId) {
            let idx = this.wizard.model.professionalProfile.EntityWorkHistory.findIndex(i => i.LocalId ? item.LocalId === i.LocalId : item.Id === i.Id);
            this.wizard.model.professionalProfile.EntityWorkHistory.splice(idx, 1);
          }
          else {
            this.wizard.model.professionalProfile = await this.professionalProfileService.save(this.wizard.model.professionalProfile).toPromise();
          }

          this.checkWorkHistoryCollection();

          return of(true);
        };

        return from(doSave());
      }
    });

  }

  acknowledgementLabel: string;
  checkWorkHistoryCollection(): void {
    const noEntityWorkHistory: boolean = this.wizard.model.professionalProfile.EntityWorkHistory.length == 0;
    this.acknowledgementLabel = noEntityWorkHistory
      ? `I certify that I did not work as an ${this.wizard.model.license.LicenseType.Description} since my last renewal`
      : `I certify that I have a current copy of the Protocol Form on file at my primary employment site`;

    this.acknowledgmentDynamicContentMinorKey = `${this.stepMinorKey}.Acknowledge.${noEntityWorkHistory ? "No" : ""}EntityWorkHistory`

    this.stepForm.get(ACKNOWLEDGMENT).setValue(false);
  }

}

registerDynamicComponent(ReachScenarios.ProfessionalProfileWizard, ProfileWorkHistoryComponentKey, ProfileWorkHistoryComponent, ProfileWorkHistoryComponentSelector);
