import { IDomainFormDto,
IDomainFormQuestionAnswerDto,
IDomainFormStatusDto,
IDomainReasonForChangeDtoHost,
ILookupDomainFormDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DomainFormDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDomainFormDto {
	ParentDomainId?: number;
	ParentDomainKeyId?: number;
	TemporaryParentDomainKeyId?: any;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	FormId?: number;
	Form: ILookupDomainFormDto;
	StatusId?: number;
	Status: IDomainFormStatusDto;
	StatusModifiedBy: string;
	StatusModifiedDate: Date;
	IsReadyToScore: boolean;
	QuestionAnswers: IDomainFormQuestionAnswerDto[];
	RowVersion: number[];

}
