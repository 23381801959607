import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IApplicationEcfmgDto,
IEcfmgChecklistItemDto } from './licensure-shared-dto';

export class EcfmgChecklistItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IEcfmgChecklistItemDto {
	ApplicationEcfmg: IApplicationEcfmgDto;

}
