import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IFileUploadDtoHost } from 'src/app/coreShared/core-shared.module';
import { FileUploadListModeEnum } from '../../fileUploadList/file-upload-list.component';
import { ReachDialogContentComponent } from '../../reach-dialog/reach-dialog-content.component';

export const ChecklistFileUploadItemsEditorComponentSelector: string = 'checklist-file-upload-items-editor';
export const ChecklistFileUploadItemsEditorComponentKey: string = 'ChecklistFileUploadItemsEditor';

@Component({
  selector: ChecklistFileUploadItemsEditorComponentSelector,
  templateUrl: './checklist-file-upload-items-editor.component.html',
  styleUrls: ['./checklist-file-upload-items-editor.component.scss']
})
export class ChecklistFileUploadItemsEditorComponent extends ReachDialogContentComponent<IFileUploadDtoHost> {

  public get FileUploadListMode(): number { return FileUploadListModeEnum.Checklist; }
  
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService) {

    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

}

registerDynamicComponent(ReachScenarios.Default, ChecklistFileUploadItemsEditorComponentKey, ChecklistFileUploadItemsEditorComponent, ChecklistFileUploadItemsEditorComponentSelector);