<div [formGroup]="stepForm">

    <!-- File Upload -->
    <file-upload-list formControlName="FileUploadList" [model]="wizard.model.fileUploadHost" [bag]="wizard.selectedStep.bag" [mode]="ValidationMode">
    </file-upload-list>

    <!-- DynamicContent Checkboxes -->
    <checkbox-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey"></checkbox-content-items>

</div>
