import { Component, Injector, OnInit, Inject, Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OnlineServiceLinkSubscription, registerDynamicComponent, OnlineServiceProcessTypes, OnlineServiceProcessTypeKeys, OnlineServiceRegistry } from '@core/index-models';
import { LandingComponentKeys, ReachScenarios } from '@core/index-constants';
import { LandingCardBaseComponent } from '../landingBase/card/landing-card-base.component';
import * as _l from 'lodash-es';
import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  NotificationCenterManagerService,
  OnlineServiceLinkManagerService,
  ReachModelConfigurationInjector, MODEL_CONFIG_INJECTION_TOKEN,
  SummaryInfoManagerService,
  UserManagerService,
  UtilitiesService,
  CartManagerService
} from '@core/index-services';
import { of } from 'rxjs';

export const CartLandingCardComponentSelector = 'cart-landing-card';

@Component({
  selector: 'cart-landing-card',
  templateUrl: './cart-landing-card.component.html',
  styleUrls: ['./cart-landing-card.component.scss']
})
export class CartLandingCardComponent extends LandingCardBaseComponent implements OnInit {

  // FIELDS
  readonlyInfo: any; // Anonymous object to display readonly properties.

  // PROPERTIES
  public get cartItemCount() { return (_l.filter(this.cartManagerService.cart?.Items, (i) => { return !i.IsDeleted; }) as any[]).length; }
  public get isHidden(): boolean { return this.userManagerService.getCurrentPrincipal() && this.cartItemCount > 0 ? false : true; }

  constructor(
    activatedRoute: ActivatedRoute,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    injector: Injector,
    notificationCenterManagerService: NotificationCenterManagerService,
    onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,
    router: Router,
    summaryInfoManagerService: SummaryInfoManagerService,
    userManagerService: UserManagerService,
    utilitiesService: UtilitiesService,
    public cartManagerService: CartManagerService
  ) {
    super(
      activatedRoute,
      busyManagerService,
      constantsService,
      injector,
      notificationCenterManagerService,
      onlineServiceLinkManagerService,
      reachModelConfigurationInjector,
      router,
      summaryInfoManagerService,
      userManagerService,
      utilitiesService,
      constantsService.DOMAIN_IDS.GENERAL
    );

    this.onlineServiceLinkSubscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Any, () => { return this.constantsService.DOMAIN_KEY_IDS.Cart; }));
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    super.ngOnInit();

    // Construct info object to display readonly properties.
    if (this.info) this.readonlyInfo = this.createReadonlyInfo(this.info.title, this.info.id, this.info.model);

    this.title = "Your Cart";
    this.aggregateTitle = "Your Cart";
    this.domainId = this.constantsService.DOMAIN_IDS.PUBLIC;
  }

  /**
   * Map incoming information to an info object to display readonly properties.
   * @param title 
   * @param id 
   * @param infoDto 
   * @returns 
   */
  private createReadonlyInfo(title: string, id: string, infoDto: any) {

    // Declarations.
    return {
      title: this.title,
      id: id,
    };
  }

  /**
   * 'More' button click response.
   */
  public override onDetail() {
    return of(this.router.navigate(['/', OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Cart).onlineServicePath]));
  }

}

registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.CartSummary, CartLandingCardComponent, CartLandingCardComponentSelector);
