
<!-- Display with adornment setting toggled via Alt-V + Alt-A keypress -->
<div *ngIf="reachApplicationService.showDynamicContentKeyAdornments" class="p-d-inline-block">
    <div class="p-d-flex p-jc-end">
        <span class="p-tag p-tag-warning">{{majorKey}} - {{minorKey}}</span>
    </div>
    <div class="reach-adornment-container-inline">
        <i class="fa fa-question-circle-o p-pl-1 reach-field-help-icon" [pTooltip]="tooltipText" [tooltipZIndex]="99999" [escape]="escapeContent"></i>
    </div>
</div>

<!-- Normal display -->
<span *ngIf="!reachApplicationService.showDynamicContentKeyAdornments">
    <i *ngIf="hasEntry" class="fa fa-question-circle-o p-pl-1 reach-field-help-icon" [pTooltip]="tooltipText" [tooltipZIndex]="99999" [escape]="escapeContent"></i>
</span>