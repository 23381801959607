<div [formGroup]="contentForm">
  <!-- Question  -->
  <div class="p-col-12 p-sm-8 p-md-9 p-xl-10 p-field">
    <div>{{label}}</div>
  </div>

  <p-dropdown *ngIf="dataLoaded" formControlName="Answer" [options]="possibleOptions" appendTo="body"
              optionLabel="Description" dataKey="Id" [showClear]="false" placeholder="&nbsp;">
  </p-dropdown>
</div>

