<p-panel styleClass="reach-landing-panel">

  <!-- Buttons -->
  <ng-template pTemplate="icons">
  </ng-template>

  <!-- Header -->
  <p-header>
    {{title}}
  </p-header>

  <!-- Grid -->
  <div class="p-grid p-nogutter">

    <!-- Information -->
    <div class="p-col-12 p-lg-6 p-grid">

      <!-- Description -->
      <div class="p-col-12 p-field p-grid">
        <span class="p-col p-mb-0"><page-summary-text majorKey="Landing" minorKey="Landing.Card.General.Description"></page-summary-text></span>
      </div>

    </div>

    <!-- Links -->
    <div class="p-col-12 p-lg-6">
      <online-service-link caption="Actions" [model]="onlineServiceLinkModel"></online-service-link>
    </div>

  </div>

</p-panel>