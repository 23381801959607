import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent, IReachDialogContentComponent, ReachControlValidators } from 'src/app/core/index-directives';
import { DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IStateDto } from 'src/app/coreShared/core-shared.module';
import { IEntityWorkHistoryDto } from 'src/app/licensureShared/licensure-shared.module';

export const ProfileWorkHistoryEditorComponentSelector: string = 'profile-s-editor';
export const ProfileWorkHistoryEditorComponentKey: string = 'profileWorkHistoryEditor';

@Component({
  selector: ProfileWorkHistoryEditorComponentSelector,
  templateUrl: './profile-work-history-editor.component.html',
  styleUrls: ['./profile-work-history-editor.component.scss']
})
export class ProfileWorkHistoryEditorComponent extends ReachDialogContentComponent<IEntityWorkHistoryDto> implements OnInit, IReachDialogContentComponent {

  possibleStates: IStateDto[];

  constructor(
    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  public override async ngOnInit(): Promise<void> {

    this.friendlyNames.AverageHours = "Average Hours per Week";
    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";

    await super.ngOnInit();
  }

  protected override modelToForm(): void {

    this.contentForm = new FormGroup({});
    this.contentForm.addControl('BusinessSetting', new FormControl(this.inputModel.BusinessSetting || "", [Validators.required, Validators.maxLength(128)]));
    this.contentForm.addControl('Facility', new FormControl(this.inputModel.Facility || "", [Validators.required, Validators.maxLength(128)]));
    this.contentForm.addControl('City', new FormControl(this.inputModel.City || "", [Validators.required, Validators.maxLength(100)]));    
    this.contentForm.addControl('State', new FormControl(null, [Validators.required, Validators.maxLength(2)]));
    this.contentForm.addControl('AverageHours', new FormControl(this.inputModel.AverageHours));

    let startDate = this.inputModel.StartDate ? new Date(this.inputModel.StartDate) : null;
    let endDate = this.inputModel.EndDate ? new Date(this.inputModel.EndDate) : null;
    this.contentForm.addControl('EndDate', new FormControl(endDate, [ReachControlValidators.pastDateValidator('End Date')]));
    this.contentForm.addControl('StartDate', new FormControl(startDate, [Validators.required, ReachControlValidators.pastDateValidator('Start Date')]));

    this.possibleStates = this.hostDialogData.initializationData.possibleStates;

    super.modelToForm();
  }

  protected override initDropdowns(): void {
    
    if (this.outputModel.State) this.contentForm.get('State').setValue(this.possibleStates.find(item => item.StateCode == this.outputModel.State));

    this.dataLoaded = true;
  }

  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    let dto = this.outputModel;
    dto.BusinessSetting = this.contentForm.get('BusinessSetting').value;
    dto.Facility = this.contentForm.get('Facility').value;
    dto.City = this.contentForm.get('City').value;
    dto.State = this.contentForm.get('State').value?.StateCode;
    dto.AverageHours = this.contentForm.get('AverageHours').value;

    dto.StartDate = this.contentForm.get('StartDate').value ? this.contentForm.get('StartDate').value : null;
    dto.EndDate = this.contentForm.get('EndDate').value ? this.contentForm.get('EndDate').value : null;

    super.formToModel();
  }

}

registerDynamicComponent(ReachScenarios.Default, ProfileWorkHistoryEditorComponentKey, ProfileWorkHistoryEditorComponent, ProfileWorkHistoryEditorComponentSelector);
