import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LandingComponentKeys, LoginComponentKeys } from '@core/index-constants';
import { RouteInfoRegistry } from '@core/index-models';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CommandService } from '@coreServices/command.service';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { MenuService } from '@coreServices/menu.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reach-app-header',
  templateUrl: './reach-app-header.component.html',
  styleUrls: ['./reach-app-header.component.scss']
})
export class ReachAppHeaderComponent implements OnInit {
  private subscriptionLogin: Subscription;
  private subscriptionLogout: Subscription;
  private applicationInitialized: Subscription;
  private isAppInit: boolean = false;
  imagePath: string;
  public ASSETS;
  public VALIDATION_ERROR_SCOPES;
  public SYSTEM_SETTING_KEYS;
  public BUSY_MANAGER_BUSY_TYPES;
  public DYNAMIC_CONTENT_MAJOR_KEYS;
  public DYNAMIC_CONTENT_LOCATION_TYPES;
  public backgroundHeaderImageStyle: SafeStyle;
  public logoImage: string = "";
  public userImage: string = "";
  public brand = "Welcome!";
  public userDisplayName: string = null;
  public printLogo: string = "";
  public showBreadcrumbs = false;
  public majorHelpKey: string = "";
  public minorHelpKey: string = "Navbar.Help";

  constructor(
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private busyManagerService: BusyManagerService,
    private menuService: MenuService,
    private sanitizer: DomSanitizer,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private reachApplicationService: ReachApplicationService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private userManagerService: UserManagerService
  ) {
    this.imagePath = this.defaultProviderConfigurationService.customProviderAssetRoot;

    this.subscriptionLogin = this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.subscriptionLogout = this.userManagerService.logout$.subscribe(() => this.onUserLogout());
    this.applicationInitialized = this.reachApplicationService.applicationInitialized$.subscribe(args => this.onApplicationInitialized(args));
  }

  ngOnInit(): void {
    this.doInit();
  }

  ngOnDestroy(): void {
    this.subscriptionLogin.unsubscribe();
    this.subscriptionLogout.unsubscribe();
    this.applicationInitialized.unsubscribe();
  }

  public get isUserLoggedIn(): boolean {
    return this.userManagerService.isLoggedIn;
  }

  /**
   * Initialize the component properties.
   */
  private doInit() {
    this.ASSETS = this.constantsService.ASSETS;
    this.VALIDATION_ERROR_SCOPES = this.constantsService.VALIDATION_ERROR_SCOPES;
    this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
    this.BUSY_MANAGER_BUSY_TYPES = this.constantsService.BUSY_MANAGER_BUSY_TYPES;
    this.DYNAMIC_CONTENT_MAJOR_KEYS = this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS;
    this.DYNAMIC_CONTENT_LOCATION_TYPES = this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES;
    var backgroundHeaderImageUrl: string = this.defaultProviderConfigurationService.customProviderAssetRoot + "/headerBackgroundSlice.png";
    this.backgroundHeaderImageStyle = this.sanitizer.bypassSecurityTrustStyle('url(' + backgroundHeaderImageUrl + ')');
    this.logoImage = this.defaultProviderConfigurationService.customProviderAssetRoot + '/' + this.ASSETS.IMAGES.AGENCY_LOGO;
    this.userImage = this.defaultProviderConfigurationService.coreAssetRoot + '/img/' + this.ASSETS.IMAGES.USER_GENERIC;
    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();
    this.printLogo = this.defaultProviderConfigurationService.customProviderAssetRoot + "/logo_white_bg.png";
    this.majorHelpKey = this.DYNAMIC_CONTENT_MAJOR_KEYS.SHARED_CONTENT;

    //if (this.isAppInit) {
    //  this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
    //}

    //this.onApplicationInitialized(this.bootstrapperService.isInitialized);
  }

  /**
   * Handles the "userManager.login" event.
   * @param event
   * @param argument
   */
  private onUserLogin(currentPrincipal) {
    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();
  }

  /**
   * Handles the "userManager.logout" event.
   * @param event
   * @param argument
   */
  private onUserLogout() {
    this.userDisplayName = "";
  };

  /**
   * Handler for the "bootstrapper.applicationInitialized" event.
   * @param the event args.
   */
  private onApplicationInitialized(isInitialized: boolean) {
    this.isAppInit = isInitialized;
    if (isInitialized) {
      this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
    }
  }

  // Home Command - return to landing page if logged in; otherwise to the login page
  homeCommand = this.commandService.createButtonCommand(() => {
    return true;
  },
    () => {
      if (this.userManagerService.getCurrentPrincipal()) {
        // Navigate to landing page
        this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
      } else {
        // Navigate to login page 
        this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
      }

      this.menuService.reset();
    });

  // Logout Command
  logoutCommand = this.commandService.createButtonCommand(() => { return this.userManagerService.getCurrentPrincipal() != null; },
    () => {
      // Logout
      this.userManagerService.logout();

      // Navigate to login page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);

      this.menuService.reset();
    }
  );

  menuButtonClick(event) {
    this.menuService.raiseNavMenuButtonClick(event);
  }
}
