import { IWebTransactionConfirmationDto,
IWebTransactionDto,
IWebTransactionFeeDto,
IWebTransactionNotificationDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebTransactionDto extends BaseDto implements IWebTransactionDto {
	TransactionDate: Date;
	TransactionAmount: number;
	UserId: number;
	ProcessTypeId: number;
	WebCartId: number;
	HasProcessed: boolean;
	Confirmations: IWebTransactionConfirmationDto[];
	Events: IWebTransactionNotificationDto[];
	Fees: IWebTransactionFeeDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
