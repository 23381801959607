<div class="p-fluid" [formGroup]="contentForm">

    <!-- Uploader -->
    <div class="uploader-container">
        <p-fileUpload [auto]="true" [multiple]="false" [accept]="uploadAcceptHeaderInfo"
            [maxFileSize]="documentService.uploadMaxFileSize" name="file[]"
            customUpload="true" (uploadHandler)="onUpload($event)" [disabled]="!model.IsNew" #fileUploader>

            <ng-template pTemplate="content">
                <p *ngIf="!uploadedFile" class="content-message">Drag and drop files here</p>
            </ng-template>

            <ng-template let-file pTemplate="file">
                <div class="p-fileupload-row">
                    <div *ngIf="isImage()"><img [src]="file.objectURL" width="50" /></div>
                    <div>{{uploadedFile.name}}</div>
                    <div>{{formatFileSize(uploadedFile.size)}}</div>
                </div>
            </ng-template>

        </p-fileUpload>
    </div>

    <div class="p-grid p-formgrid">

        <!-- Display Name -->
        <div class="p-field p-col-12">
            <label for="DisplayName">Name</label>
            <p-dropdown [options]="possibleDocumentTypes" inputId="DisplayName" formControlName="DisplayName"
                [editable]="true" optionLabel="Name" appendTo="body" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Document Description -->
        <div class="p-col-12 p-field">
            <label for="Description">Description</label>
            <textarea id="Description" formControlName="Description" [rows]="4" style="width:100%" pInputTextarea
                autoResize="autoResize"></textarea>
        </div>

    </div>

</div>