import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { ArgumentExceptionService } from './argument-exception.service';
import { SystemSettingsManagerService } from './system-settings-manager.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFactoryService {

  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) {
  }

  public create(featureEnabledSystemSettingKey) {
      let canCall = this.systemSettingsManagerService.asBoolean(featureEnabledSystemSettingKey);
    if (!featureEnabledSystemSettingKey || featureEnabledSystemSettingKey.length === 0) {
      this.argumentExceptionService.create("featureEnabledSystemSettingKey");
    }

    return new FeatureService(featureEnabledSystemSettingKey, canCall);
  }
}

export class FeatureService {
  constructor(private featureEnabledSystemSettingKey: string, public canCall: boolean) {
  }

  public call (method) {
    if (!this.canCall) {
      //$log.info("Feature Disabled: [" + this.featureEnabledSystemSettingKey + "]");
        return of(null);
    }

    let result = method();
    return of(result);
  };
}
