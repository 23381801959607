import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IOtherLicenseChecklistItemDto,
IOtherLicenseDto } from './licensure-shared-dto';

export class OtherLicenseChecklistItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IOtherLicenseChecklistItemDto {
	OtherLicense: IOtherLicenseDto;

}
