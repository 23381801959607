import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  ArgumentExceptionService,
  BusyManagerService, ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, ReachHttpClientService, UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import { CeSponsorDto } from '@licensureShared/licensure-shared.module';

@Injectable({
  providedIn: 'root'
})
export class SponsorService extends ReachHttpClientService {
  uri: string;
  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/licensure/cesponsor`;
  }

  public getById(id: number) {
    return this.get<CeSponsorDto>(`${this.uri}/${id}`);
  }

  public initialize(entityId: number, contactEmail: string) {
    return this.get<CeSponsorDto>(`${this.uri}/initialize`)
      .pipe(map((sponsor: CeSponsorDto) => {
        sponsor.EntityId = entityId;
        sponsor.ContactEmail = contactEmail;
        return sponsor;
      }));
  }

  public save(sponsor: CeSponsorDto) {
    if (sponsor.Id) {
      return this.put<CeSponsorDto>(this.uri, sponsor);
    } else {
      return this.post<CeSponsorDto>(this.uri, sponsor);
    }
  }
}
