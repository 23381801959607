import { ISystemSettingDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class SystemSettingDto extends BaseDto implements ISystemSettingDto {
	Key: string;
	Value: string;
	Description: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
