import { AfterViewChecked, Component, Inject, Input, OnChanges, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from "@angular/forms";
import * as _ from 'lodash-es';
import { UtilitiesService } from '@coreServices/utilities.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../../services/constants-provider.service';
import { debounceTime } from 'rxjs/operators';
import { IAnswerDto, IDomainQuestionnaireAnswerDto } from 'src/app/coreShared/core-shared.module';
import { ReachControlValidators } from '../validators/reach-control-validators';

/**
 * Represents a single question from a domain questionnaire.
 */
@Component({
  selector: 'domain-questionnaire-question',
  templateUrl: './domain-questionnaire-question.component.html',
  styleUrls: ['./domain-questionnaire-question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainQuestionnaireQuestionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DomainQuestionnaireQuestionComponent),
      multi: true
    }
  ]
})
export class DomainQuestionnaireQuestionComponent implements OnInit, OnChanges, AfterViewChecked, ControlValueAccessor {

  @Input()
  public model: IDomainQuestionnaireAnswerDto;
  @Input()
  public possibleAnswers: IAnswerDto[];
  @Input()
  public friendlyNames: any;

  public requireResponse: boolean;
  private questionSequenceNumber: number;
  public filteredAnswers: IAnswerDto[];
  public blankItem: IAnswerDto;
  public questionForm: FormGroup;
  public formReady: boolean;
  public answerControlName: string;
  public responseControlName: string;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private utilitiesService: UtilitiesService
    , protected validationManagerService: ValidationManagerService) {
  }

  ngAfterViewChecked(): void { }
  ngOnInit(): void { }

  ngOnChanges(): void {

    // Assignments.
    this.questionSequenceNumber = this.model.Question.SequenceNumber;
    this.answerControlName = "Answer" + this.questionSequenceNumber;
    this.responseControlName = "Response" + this.questionSequenceNumber;
    this.requireResponse = this.model.AnswerId == this.model.Question.ReviewRequiredAnswerId;
    this.blankItem = this.possibleAnswers.find(item => item.Id == this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.XBLANK);

    // Friendly names.
    this.friendlyNames[this.answerControlName] = "Answer to question " + this.questionSequenceNumber;
    this.friendlyNames[this.responseControlName] = "Explanation for question " + this.questionSequenceNumber;

    // Construct form.
    // BE ADVISED: This is not the usual pattern. This pattern does not render any UI elements until we have a model.
    // Properties on the model are used to name properties on this component.
    if (!this.formReady) this.modelToForm();

    // Filter possible answers for this specific question.
    this.filterPossibleAnswers();
  }

  /**
  * Maps this instance's model to the FormGroup. test
  */
  protected modelToForm() {
    this.questionForm = new FormGroup({});
    this.questionForm.addControl(this.answerControlName, new FormControl(null, [Validators.required, ReachControlValidators.xBlankValidator(this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.XBLANK)]));
    this.questionForm.addControl(this.responseControlName, new FormControl(this.model.Response, [Validators.required, Validators.maxLength(2000)]));
    this.initDropdowns();
    this.initializeEventHandlers();
    this.formReady = true;
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // When anything in this contentForm changes, call formToModel.
    this.questionForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });

    // When the selected answer changes, decide if a response is required.
    this.questionForm.get(this.answerControlName).valueChanges.subscribe(x => {

      // A local reference to the response control.
      let responseControl = this.questionForm.get(this.responseControlName);

      if (this.model.Question.IsResponseRequired && this.model.Question.ReviewRequiredAnswerId && x.Id == this.model.Question.ReviewRequiredAnswerId) {
        this.requireResponse = true;
        responseControl.enable();
      }

      // Ensure the Response is cleared if not required.
      else {
        responseControl.disable();
        this.requireResponse = false;
        this.model.Response = "";
      }

    });

    this.questionForm.get(this.answerControlName).setValue(this.questionForm.get(this.answerControlName).value);
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  dataLoaded: boolean = false;
  protected initDropdowns(): void {

    // Answer.
    if (this.model.AnswerId) this.questionForm.get(this.answerControlName).setValue(this.possibleAnswers.find(item => item.Id == this.model.AnswerId) ?? this.blankItem);

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

  /**
 * Maps this instance's stepForm FormGroup to the wizard model.
 */
  protected formToModel() {

    // The model must have a value.
    if (!this?.model) return;
    if (!this.dataLoaded) return;

    this.model.Response = this.questionForm.get(this.responseControlName).value as string;
    this.model.IsDirty = true;

    // Dropdowns.
    // Assign the 'blank' constant to the model if no item is selected.
    let selectedAnswer = this.questionForm.get(this.answerControlName).value;
    this.model.AnswerId = selectedAnswer ? selectedAnswer.Id : "";
    this.model.Answer = selectedAnswer;
    this.model.IsResponseRequired = this.model.AnswerId == this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.YES;
    this.model.IsReviewRequired = this.model.IsResponseRequired == true && this.model.Question.ReviewRequiredAnswerId == this.model.AnswerId;
  }

  /**
  * Returns an array of possible answers for a given question.
  * Called from the html.
  * @param question
  */
  filterPossibleAnswers(): void {

    this.filteredAnswers = _.filter(this.possibleAnswers, item => {

      // Unanswered
      if (item.Id === this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.UNANSWERED) {
        item.Description = item.Description;
        item.DisplayOrder = 100;
        return this.model.Question.IsUnansweredAllowed;
      }

      // Yes
      if (item.Id === this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.YES) {
        item.Description = item.Description;
        item.DisplayOrder = 10;
        return this.model.Question.IsYesAllowed;
      }

      // No
      if (item.Id === this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.NO) {
        item.Description = item.Description;
        item.DisplayOrder = 20;
        return this.model.Question.IsNoAllowed;
      }

      // Answered Last Year
      if (item.Id === this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.ANSWERED_LAST_YEAR) {
        item.Description = item.Description;
        item.DisplayOrder = 30;
        return this.model.Question.IsAnsweredLastYearAllowed;
      }

      return false;
    });
  }

  /**
 * Aggregates errors and passes them to the parent.
 */
  validate(): ValidationErrors | null {

    // Avoid validating repeatedly before the form has finished loading.
    if (!this.formReady) return null;

    // Combine errors from this component...
    let errors = this.utilitiesService.aggregateCvaError(this.questionForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.questionForm.valid ? null : errors;
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.questionForm?.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.questionForm.disable() : this.questionForm.enable();
  }
}
