// Angular
import { ActivatedRoute, Router } from '@angular/router';

// Core
import { Component, Input, OnInit } from '@angular/core';
import { INotificationDto } from '../../../coreShared/core-shared.module';
import { RouteInfoRegistry } from '../../../core/models/route-registry';
import { LandingComponentKeys } from '../../../core/constants/landing-component-constants';
import { MessageSeverities } from '@coreModels/message-severities';

@Component({
  selector: 'landing-detail-header',
  templateUrl: './landing-detail-header.component.html',
  styleUrls: ['./landing-detail-header.component.scss']
})
export class LandingDetailHeaderComponent implements OnInit {
  severity: MessageSeverities = MessageSeverities.info;
  @Input() messageScope: string;
  @Input() notifications: INotificationDto[] = [];
  @Input() title: string;
  showMessages: boolean = true;
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public onToggle() {
    this.showMessages = !this.showMessages;
  }

  public onOverview() {
    let overviewUrl = "/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path;
    
    this.router.navigateByUrl(overviewUrl);
  }

  public get notificationCount(): number {
    return this.notifications ? this.notifications.length : 0;
  }

  public get isVisible(): boolean {
    return this.notifications && this.notifications.length > 0;
  }
}
