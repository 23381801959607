import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { ISupervisionPlanSearchResultItemDto,
ISupervisionPlanSupervisorDto } from './licensure-shared-dto';

export class SupervisionPlanSearchResultItemDto extends SearchResultItemDto implements ISupervisionPlanSearchResultItemDto {
	Id: number;
	ReferenceValue: string;
	LicenseId?: number;
	LicenseTypeCode: string;
	LicenseNumber?: number;
	ApplicationNbr?: number;
	FormId?: number;
	VerificationFormId?: number;
	FormDescription: string;
	StatusId?: number;
	StatusDescription: string;
	ReceivedDate?: Date;
	BeginDate?: Date;
	EndDate?: Date;
	SuperviseeEntityId?: number;
	SuperviseeLastName: string;
	SuperviseeMiddleName: string;
	SuperviseeFirstName: string;
	SupervisorFullNameList: string;
	PracticeLocation: string;
	EmployerName: string;
	PositionTitle: string;
	PlanHasMultipleSupervisors: boolean;
	SupervisionPlanSupervisors: ISupervisionPlanSupervisorDto[];
	SupervisorStatus: string;
	WebServiceHistoryId?: number;
	SupervisorStatusId?: number;
	SuperviseeFullName: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
