<!-- Form -->
<div class="p-fluid" [formGroup]="mainForm" *ngIf="formReady">

    <!-- Mailing Form -->
    <div class="p-grid p-formgrid" formGroupName="mailingForm">

        <!-- Header -->
        <div class="p-col-12 reach-section-header">Mailing Address</div>

        <!-- Country -->
        <div class="p-field p-col-12 p-md-6">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Address</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
            <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- Line 3 -->
        <div class="p-field p-col-12">
            <input id="line3" type="text" formControlName="Line3" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-md-6 p-xl-3"
            [hidden]="!(mailingAddress?.Country == USA || mailingAddress?.Country == CANADA)">
            <label for="state">{{mailingStateLabel}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
                [options]="mailingAddress?.Country == CANADA ? possibleProvinces : possibleStates"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-md-6 p-xl-3"
            [hidden]="!(mailingAddress?.Country == USA && mailingAddress?.State == MN)">
            <label for="county">County</label>
            <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
                optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

    </div>

    <!-- Header -->
    <div class="p-col-12 p-pl-0 reach-section-header">Business Address</div>

    <!-- Business Form -->
    <div [hidden]="mainForm.get('IsNotInWorkforce').value">
        <div class="p-grid p-formgrid" formGroupName="businessForm">

            <!-- Country -->
            <div class="p-field p-col-12 p-md-6">
                <label for="Country">Country</label>
                <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                    optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;"
                    baseZIndex="1" [virtualScroll]="true" itemSize="25">

                    <!-- Selected Item -->
                    <ng-template let-country pTemplate="selectedItem">
                        <div class="p-fluid p-grid p-nogutter">

                            <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                            </div>
                            <div class="p-col p-ml-2">{{country.Description}}</div>

                        </div>
                    </ng-template>

                    <!-- List Item -->
                    <ng-template let-country pTemplate="item">
                        <div class="p-grid p-nogutter">

                            <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                            </div>
                            <div class="p-col p-ml-2">{{country.Description}}</div>

                        </div>
                    </ng-template>

                </p-dropdown>
            </div>

            <!-- Line 1 -->
            <div class="p-field p-col-12">
                <label for="line1">Address</label>
                <input id="line1" type="text" formControlName="Line1" pInputText>
            </div>

            <!-- Line 2 -->
            <div class="p-field p-col-12">
                <input id="line2" type="text" formControlName="Line2" pInputText>
            </div>

            <!-- Line 3 -->
            <div class="p-field p-col-12">
                <input id="line3" type="text" formControlName="Line3" pInputText>
            </div>

            <!-- City -->
            <div class="p-field p-col-12 p-md-6 p-xl-3">
                <label for="city">City</label>
                <input id="city" type="text" formControlName="City" pInputText>
            </div>

            <!-- State -->
            <div class="p-field p-col-12 p-md-6 p-xl-3"
                [hidden]="!(businessAddress?.Country == USA || businessAddress?.Country == CANADA)">
                <label for="state">{{businessStateLabel}}</label>
                <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
                    [options]="businessAddress?.Country == CANADA ? possibleProvinces : possibleStates"
                    optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                    baseZIndex="1">
                </p-dropdown>
            </div>

            <!-- County -->
            <div class="p-field p-col-12 p-md-6 p-xl-3"
                [hidden]="!(businessAddress?.Country == USA && businessAddress?.State == MN)">
                <label for="county">County</label>
                <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
                    optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                    baseZIndex="1">
                </p-dropdown>
            </div>

            <!-- Zip -->
            <div class="p-field p-col-12 p-md-6 p-xl-3">
                <label for="zip">Zip</label>
                <input id="zip" type="text" formControlName="Zip" pInputText>
            </div>

        </div>
    </div>

    <!-- IsNotInWorkforce Checkbox -->
    <div class="p-col-12 p-field-checkbox p-pl-0">
        <p-checkbox inputId="IsNotInWorkforce" formControlName="IsNotInWorkforce" binary="true"></p-checkbox>
        <label for="IsNotInWorkforce">I certify that I am not currently in the workforce and I don't have a business
            address related to my practice.
        </label>
    </div>
</div>