import { Injectable } from '@angular/core';
import { OnlineServiceLink } from '@coreModels/online-service-link';
import { OnlineServiceLinkSubscription } from '@coreModels/online-service-link-subscription';
import { OnlineServiceRegistry, OnlineServiceRegistryItem } from '@coreModels/online-service-registry';
import { IWebLinkInfoDto } from '@coreShared/core-shared.module';
import * as _l from 'lodash-es';
import { OnlineServiceProcessTypes } from '../models/online-service-registry-types';
import { DynamicRoutesService } from './dynamic-routes.service';


export class OnlineServiceLinkModel {
  public links: OnlineServiceLink[]
}

@Injectable({
  providedIn: 'root'
})
export class OnlineServiceLinkManagerService {
  protected serviceLinks: OnlineServiceLink[] = [];
  constructor(private dynamicRoutesService: DynamicRoutesService) { }

  public addLinks(webLinkInfos: IWebLinkInfoDto[], isProfileLink: boolean) {
    webLinkInfos.forEach(i => {
      this.addLink(i, isProfileLink);
    });
  }

  public addLink(webLinkInfo: IWebLinkInfoDto, isProfileLink: boolean, replaceExistingAcrossDomain: boolean = false): OnlineServiceLink {
    let wizardRegistryInfo = this.getRegistryInfo(webLinkInfo);

    let wizardRoute = this.dynamicRoutesService.buildRouteFromWebLinkSegments(
      wizardRegistryInfo.onlineServiceBaseRoute,
      wizardRegistryInfo.onlineServiceUrlSegmentPattern,
      webLinkInfo.RouteParameters
    );

    let selectorRoute = this.dynamicRoutesService.buildRouteFromWebLinkSegments(
      wizardRegistryInfo.selectorBaseRoute,
      wizardRegistryInfo.selectorUrlSegmentPattern,
      webLinkInfo.RouteParameters
    );

    let link = new OnlineServiceLink(webLinkInfo, wizardRoute, selectorRoute, isProfileLink);
    let existingIndex = -1;
    if (replaceExistingAcrossDomain) {
      existingIndex = _l.findIndex(this.serviceLinks, { webLinkInfo: { DomainId: webLinkInfo.DomainId, DisplayName: webLinkInfo.DisplayName } });
    } else {
      existingIndex = _l.findIndex(this.serviceLinks, { webLinkInfo: { DomainKeyId: webLinkInfo.DomainKeyId, DomainId: webLinkInfo.DomainId, DisplayName: webLinkInfo.DisplayName } });
    }

    if (existingIndex > -1) {
      this.serviceLinks.splice(existingIndex, 1, link); // Last in wins
    }
    else {
      this.serviceLinks.push(link);
    }

    return link;
  }

  public removeLink(criteria: IWebLinkInfoDto) {
    let existingIndex = _l.findIndex(this.serviceLinks, { webLinkInfo: { DomainKeyId: criteria.DomainKeyId, DomainId: criteria.DomainId, DisplayName: criteria.DisplayName } });
    if (existingIndex > -1) {
      this.serviceLinks.splice(existingIndex, 1);
    }
  }

  public removeProfileLinks() {
    if (this.serviceLinks) {
      _l.remove(this.serviceLinks, (l: OnlineServiceLink) => {
        return l.isProfileLink;
      });
    }
  }

  public getRegistryInfo(webLinkInfo: IWebLinkInfoDto): OnlineServiceRegistryItem {
    let wizardRegistryInfo = OnlineServiceRegistry.getItemByOnlineServiceTypeId(webLinkInfo.ProcessTypeId);
    if (!wizardRegistryInfo) {
      throw `No online service route info has been registered for service key ${webLinkInfo.ProcessTypeId}`;
    }

    return wizardRegistryInfo;
  }

  public getLinksForOnlineServiceRegistryType(onlineServiceRegistryType: string): OnlineServiceLink[] {
    if (onlineServiceRegistryType === OnlineServiceProcessTypes.Any) {
      return this.serviceLinks;
    }

    let matches = this.serviceLinks.filter((i) => {
      return i.webLinkInfo.ProcessTypeId.toString() === onlineServiceRegistryType;
    });

    return matches;
  }

  public getOnlineServiceLinksForSubscriptions(onlineServiceLinkSubscriptions: OnlineServiceLinkSubscription[], filter: (l: OnlineServiceLink, s: OnlineServiceLinkSubscription) => boolean = null) {
    if (!filter) {
      filter = (l: OnlineServiceLink, s: OnlineServiceLinkSubscription) => { return l.webLinkInfo.DomainKeyId === s.getDomainKeyId() };
    }

    let onlineServiceLinks: OnlineServiceLink[] = [];
    let onlineServiceLinkModel = new OnlineServiceLinkModel();
    onlineServiceLinkModel.links = onlineServiceLinks;

    if (onlineServiceLinkSubscriptions && onlineServiceLinkSubscriptions.length > 0) {
      onlineServiceLinkSubscriptions.forEach((subscription: OnlineServiceLinkSubscription) => {
        let links = this.getLinksForOnlineServiceRegistryType(subscription.onlineServiceRegistryType);
        links = _l.filter(links, (l: OnlineServiceLink) => filter(l, subscription));
        onlineServiceLinks.push(...links);
      });
    }

    return onlineServiceLinkModel;
  }
}

