
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';

import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService,
  LandingPageRouteResolverService,
  LandingPageService, OnlineServiceLinkManagerService, OnlineServiceMenuService, Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  UserManagerService
} from '@core/core.module';

import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { IOrganizationalPayorInfoDto } from '@licensureShared/licensure-shared.module';
export const OrganizationalPayorLandingCardComponentKey = 'organizationalPayorLandingCardComponent';

@Injectable({
  providedIn: 'root'
})
export class OrganizationalPayorConfigurationService {

  private isInitialized = false;
  private organizationalPayorConstantsService: IOrganizationalPayorConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , protected dynamicRoutesService: DynamicRoutesService
    , protected landingPageService: LandingPageService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , protected onlineServiceMenuService: OnlineServiceMenuService
    , protected router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , protected userManagerService: UserManagerService) {

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, OrganizationalPayorConstantsService.Mergers.DOMAIN_IDS);
    this.organizationalPayorConstantsService = this.constantsService as IOrganizationalPayorConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.OrganizationalPayorDetailLazy).path + "/:id",
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.OrganizationalPayorDetailLazy),
        resolve: { routeConfiguration: LandingPageRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addInfosToLandingPage(principal) {
    if (!this.isInitialized) {
      this.initialize();
    }

    if (principal?.user?.UserAccount?.profile?.OrganizationalPayors && principal.user.UserAccount.profile.OrganizationalPayors.length > 0) {

      let infos: SummaryComponentInfo[] = [];
      const summaryDetailConfig = new SummaryDetailConfiguration(
        LicensureLandingCategories.OrganizationalPayor,
        LicensureLandingComponentKeys.OrganizationalPayorSummaryLazy,
        LicensureLandingComponentKeys.OrganizationalPayorDetailLazy,
        SummaryAggregationType.Singleton);

      let organizationalPayorInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Organizational Payor", summaryDetailConfig);

      principal.user.UserAccount.profile.OrganizationalPayors.forEach((organizationalPayor: IOrganizationalPayorInfoDto, index: number) => {
        let organizationalPayorTitle = `Payor (${organizationalPayor.OrganizationName})`;
        organizationalPayorInfoBuilder.addItem(index, organizationalPayorTitle, organizationalPayor, this.organizationalPayorConstantsService.DOMAIN_IDS.ORGANIZATIONAL_PAYOR);
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}

export class OrganizationalPayorConstantsService extends ConstantsService {
  public static Mergers = {
    DOMAIN_IDS: {
      ORGANIZATIONAL_PAYOR: 58,
    }
  };

  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), OrganizationalPayorConstantsService.Mergers.DOMAIN_IDS);
}

export interface IOrganizationalPayorConstantsService extends OrganizationalPayorConstantsService { }
