<p-table *ngIf="SupervisionPlan" styleClass="p-datatable-sm"
    [value]="filteredSupervisionPlanSupervisors" [responsive]="true">

    <!-- Caption -->
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
            <div class="p-text-left reach-list-header">Supervisors</div>
            <div>
                <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentSearchCommand"
                    tool-tip="Add a new item"></button>
            </div>
        </div>
    </ng-template>

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="SupervisorEntityInfo.FullName" width="40%">Name<p-sortIcon
                    field="SupervisorEntityInfo.FullName">
                </p-sortIcon>
            </th>
            <th pSortableColumn="SupervisorEntityInfo.StrongLicense.LicenseType.ProfessionalSuffix" width="30%">License
                Type
                <p-sortIcon field="SupervisorEntityInfo.StrongLicense.LicenseType.ProfessionalSuffix"></p-sortIcon>
            </th>
            <th pSortableColumn="SupervisorEntityInfo.StrongLicense.LicenseNumber" width="30%">License #
                <p-sortIcon field="SupervisorEntityInfo.StrongLicense.LicenseNumber"></p-sortIcon>
            </th>
            <th style="width: 8em;"></th>

        </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-item>
        <tr>

            <td>{{item.SupervisorEntityInfo.FullName}}</td>
            <td>{{item.SupervisorEntityInfo?.StrongLicense?.LicenseType?.ProfessionalSuffix || 'N/A'}}</td>
            <td>{{item.SupervisorEntityInfo?.StrongLicense?.LicenseNumber|| 'N/A'}}</td>

            <!-- Buttons -->
            <td class="p-text-right">
                <span class="p-text-nowrap">

                    <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                        [command-button]="removeItemCommand" [command-parameter]="item"
                        tool-tip="Delete this item"></button>

                </span>
            </td>

        </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="4">
                <span class="reach-text-muted"><em>No entries.</em></span>
            </td>
        </tr>
    </ng-template>

</p-table>

<!-- DIALOG -->
<reach-dialog *ngIf="supervisorSearchDialogSettings" [dialogSettings]="supervisorSearchDialogSettings"></reach-dialog>
