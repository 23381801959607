import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IOnlineLicenseSearchResultItemDto } from './licensure-shared-dto';

export class OnlineLicenseSearchResultItemDto extends SearchResultItemDto implements IOnlineLicenseSearchResultItemDto {
	LicenseId: number;
	IsIndividual: boolean;
	ReferenceValue: string;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	City: string;
	LicenseType: string;
	LicenseTypeDescription: string;
	LicenseNumber: number;
	LicenseStatus: string;
	EntityId: number;
	LicenseTypeProfessionalSuffix: string;
	NameMatchOnHistoricName: boolean;
	DateOfBirth?: Date;
	EntityHasActiveCertifications: boolean;
	EntityHasExpiredCertifications: boolean;
	LicenseHasRegistration: boolean;
	LicenseRegistrationNumber: number;
	LicenseRegistrationDescription: string;
	LicenseRegistrationIssueDate?: Date;

}
