<!-- Card -->
<p-panel *ngIf="!isHidden" styleClass="reach-landing-panel">

  <!-- Buttons -->
  <ng-template pTemplate="icons">
    <button pButton pRipple type="button" class="p-button p-button-text p-button-secondary reach-button-muted-icon"
      label="More" icon="fa fa-chevron-right" iconPos="right" (click)="onDetail()"></button>
  </ng-template>

  <!-- Header -->
  <p-header>
    {{title}}
    <message-alert [messageScope]="notificationScope" [notifications]="notifications" (click)="onDetail()">
    </message-alert>
  </p-header>

  <!-- Grid -->
  <div class="p-grid p-nogutter">

    <!-- Table Caption -->
    <p-table responsiveLayout="scroll" styleClass="p-datatable-sm"
      [value]="cartManagerService.cart?.Items | filter: {IsDeleted:false}">

      <!-- Column Headings -->
      <ng-template pTemplate="header">
        <tr>
          <th width="70%">Description</th>
          <th width="30%" class="p-text-right">Amount</th>
        </tr>
      </ng-template>

      <!-- Items -->
      <ng-template pTemplate="body" let-item>
        <tr>

          <!-- Item -->
          <td>{{item.ServiceHistoryItem.Process.Description}}</td>
          <!-- Amount -->
          <td class="p-text-right">{{item.Total | currency}}</td>

        </tr>
      </ng-template>

      <!-- Footer -->
      <ng-template pTemplate="footer" let-sale>
        <!-- Processing Fees -->
        <tr *ngIf="cartManagerService.cart && cartManagerService.cart.CartFeesTotal > 0">
          <td>Processing Fee (Credit Card)</td>
          <td class="p-text-right">{{cartManagerService.cart.CartFeesTotal | currency}}</td>
          <td>
            <!--n/a-->
          </td>
        </tr>
        <tr *ngIf="cartManagerService.cart">
          <td>Grand Total</td>
          <td class="p-text-right">{{cartManagerService.cart.Total | currency}}</td>
          <td>
            <!--n/a-->
          </td>
        </tr>
      </ng-template>

      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="2">
            <span class="reach-text-muted"><em>Your cart is empty.</em></span>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>

</p-panel>