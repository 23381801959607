import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
    providedIn: 'root'
})
export class BrowserDetectionService {

    constructor(private platform: Platform) { }

    getBrowserVersion() {
        if (this.platform.BLINK) {
            return 'Google Chrome';
        } else if (this.platform.TRIDENT) {
            return 'Microsoft Internet Explorer';
        } else if (this.platform.EDGE) {
            return 'Microsoft Edge';
        } else if (this.platform.FIREFOX) {
            return 'Mozilla Firefox';
        } else if (this.platform.SAFARI) {
            return 'Apple Safari';
        } else if (this.platform.IOS) {
            return 'IOS';
        } else if (this.platform.ANDROID) {
            return 'Android';
        }
        else {
            return 'Unknown browser';
        }
    }
}
