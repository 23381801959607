import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { LoginComponentKeys } from '@coreConstants/login-component-constants';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { UserManagerService } from '@coreServices/user-manager.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userManagerService: UserManagerService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    
    if (this.userManagerService.getCurrentPrincipal() || await this.userManagerService.loginFromLocalStorage().toPromise()) return of(true).toPromise();
    
    this.userManagerService.intendedDestination = state.url; // Record intented destination.
    this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    return of(false).toPromise();
  }
}
