import { IAddressInfoDto,
IBaseDto,
IEntityInfoDto,
IEntityPhoneInfoDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class EntityInfoDto extends BaseDto implements IBaseDto, IEntityInfoDto {
	FirstName: string;
	MiddleName: string;
	LastName: string;
	UsePrimaryNameAsProfessionalName: boolean;
	SecondaryFirstName: string;
	SecondaryMiddleName: string;
	SecondaryLastName: string;
	SecondaryTitle: string;
	SecondarySuffix: string;
	BirthDate?: Date;
	DeathDate?: Date;
	Title: string;
	Suffix: string;
	Ssn: string;
	Gender: string;
	GenderDescription: string;
	Phone: string;
	PhoneNumbers: IEntityPhoneInfoDto[];
	PublicAddressInfo: IAddressInfoDto;
	Email: string;
	PrivateEmail: string;
	Website: string;
	IsIndividual: boolean;
	IsOnlineUser: boolean;
	SecurityCode: string;
	LegacyHoldCount?: number;
	WatchCount?: number;
	AlertWatchCount?: number;
	CommentsCount?: number;
	AlertCommentsCount?: number;
	ProfessionalSuffix: string;
	HasAlert: boolean;
	FullName: string;
	FullSecondaryName: string;
	LFMName: string;
	FLName: string;
	SecondaryFLName: string;
	TLName: string;
	SecondaryTLName: string;
	TFLName: string;
	SecondaryTFLName: string;
	FMLName: string;
	SecondaryFMLName: string;
	FMLSName: string;
	SecondaryFMLSName: string;
	FMLSDName: string;
	SecondaryFMLSDName: string;
	FMILSDName: string;
	SecondaryFMILSDName: string;
	FMILName: string;
	SecondaryFMILName: string;
	FMILSName: string;
	SecondaryFMILSName: string;

}
