import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DocumentService, ReachHttpClientService, ValidationManagerService } from "@core/core.module";
import { EducationProgramComplianceDto } from "@licensureShared/licensure-shared.module";
import { Observable } from "rxjs";
import { concatMap, map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class EducationProgramAnnualComplianceService extends ReachHttpClientService {

  private uri: string;

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    private documentService: DocumentService
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);

    this.uri = `${this.apiRootUri}/licensure/EducationProgramCompliance`;
  }

  public initializeCompliance(entityId: number): Observable<EducationProgramComplianceDto> {

    // TODO: convert this over to RxJs
    const createNewCompliance = (): Observable<EducationProgramComplianceDto> => {
      return this.get<EducationProgramComplianceDto>(`${this.uri}/initialize`);
    };

    // API call.
    const saveCompliance = (compliance: EducationProgramComplianceDto): Observable<EducationProgramComplianceDto> => {
      compliance.EducationProgramId = entityId;
      return this.post<EducationProgramComplianceDto>(`${this.uri}/initialize/item`, compliance);
    };

    let res = createNewCompliance().pipe(mergeMap((val: EducationProgramComplianceDto) => saveCompliance(val)));
    return res;
  }

  public getById(id: number): Observable<EducationProgramComplianceDto> {
    return this.get<EducationProgramComplianceDto>(`${this.uri}/${id}`);
  }

  public save(dto: EducationProgramComplianceDto): Observable<EducationProgramComplianceDto> {

    const uploadDocuments = this.documentService.uploadAllDocuments(dto);
    const persistDto = dto.Id
      ? this.put<EducationProgramComplianceDto>(this.uri, dto)
      : this.post<EducationProgramComplianceDto>(this.uri, dto);

    const result: Observable<EducationProgramComplianceDto> = uploadDocuments.pipe(concatMap(() => {
      return persistDto.pipe(map(res => { return res; }))
    }));

    return result;
  }
}
