import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LandingPageComponent, LandingPageRouteResolverService } from '@core/index-directives';
import { LandingComponentKeys } from '@core/index-constants';
import { RouteInfoRegistry } from '@core/index-models';
import { ActivityDetailComponent } from './features/activity-detail/activity-detail.component';

const routes: Routes =
  [
    {
      path: RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path,
      component: LandingPageComponent,
      resolve: { routeConfiguration: LandingPageRouteResolverService },
      canActivate: [AuthGuard],
      data: {
        breadcrumb: 'Landing',
        addDynamicChild: true
      }
    },
    {
      path: `${RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.ActivityDetail).path}/:Id`,  
      component: ActivityDetailComponent
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
