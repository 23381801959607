// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';

import {
  AnonymousGuard,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DynamicRoutesService, OnlineServiceLinkManagerService,
  OnlineServiceMenuService,
  OnlineServiceRegistry,
  ReachScenarios,
  RouteInfoRegistry,
  SystemSettingsManagerService
} from '@core/core.module';
import { WebLinkInfoDto } from '@coreShared/core-shared.module';
import { SearchComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCore/models/licensure-online-service-registry-types';
import { LicensureConstantsProviderService } from "../licensure-constants-provider.service";

/**
 * Service that supports the Online Entity Search Feature.
 */
@Injectable({ providedIn: 'root' })
export class OnlineEntitySearchFeatureConfigurationService {
  private isInitialized = false;
  private onlineEntitySearchConstantsService: IOnlineEntitySearchConstantsService;
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private dynamicRoutesService: DynamicRoutesService,
    private onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) {
    this.onlineEntitySearchConstantsService = this.constantsService as IOnlineEntitySearchConstantsService;
  }

  public searchConfig: any = null;

  public initialize() {
    if (!this.isInitialized) {
      // configure constants
      this.configureConstants();

      // Decorate
      this.configureDecorators();

      // Routes
      this.configureRoutes();

      this.searchConfig = this.getSearchConfig();

      this.isInitialized = true;
    }
  }

  /**
   * Function for converting license types to suffixes.
   * */
  public licenseListLicenseTypeProfessionalSuffixConverter: (license) => string = (license) => {
    let customConverter = (this.defaultProviderConfigurationService as any).licenseListLicenseTypeProfessionalSuffixConverter;
    return customConverter ? customConverter(license) : license.LicenseTypeProfessionalSuffix;
  };

  public getStatusBlockDisplayStyleClass(onlineDisplayAspectStatusId: number) {
    if (onlineDisplayAspectStatusId == this.constantsService.ONLINE_DISPLAY_ASPECT_STATUSES.GREEN) {
      return 'reach-status-block reach-status-block-active';
    }

    if (onlineDisplayAspectStatusId == this.constantsService.ONLINE_DISPLAY_ASPECT_STATUSES.YELLOW) {
      return 'reach-status-block reach-status-block-warning';
    }

    if (onlineDisplayAspectStatusId == this.constantsService.ONLINE_DISPLAY_ASPECT_STATUSES.RED) {
      return 'reach-status-block reach-status-block-not-active';
    }

    if (onlineDisplayAspectStatusId == this.constantsService.ONLINE_DISPLAY_ASPECT_STATUSES.GRAY) {
      return 'reach-status-block reach-status-block-gray';
    }

    if (onlineDisplayAspectStatusId == this.constantsService.ONLINE_DISPLAY_ASPECT_STATUSES.BLUE) {
      return 'reach-status-block reach-status-block-neutral';
    }

    return 'reach-status-block';
  }

  public licenseeCountyFilterFunction = (item) => { return true; }
  public licenseTypeFilterFunction = (item) => { return true; }

  /**
   * Decorate the ConstantsService with OnlineEntitySearch-specific constants.
   */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      OnlineEntitySearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH,
      OnlineEntitySearchConstantsService.Mergers.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_ENTITY_SEARCH,
      OnlineEntitySearchConstantsService.Mergers.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_ENTITY_SEARCH);
    ConstantsService.MergeSettings(this.constantsService.ONLINE_SERVICE_SORT,
      OnlineEntitySearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
  }

  private configureDecorators() {
    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Search for a Licensee";
    linkInfo.DisplayOrder = (this.constantsService as LicensureConstantsProviderService).ONLINE_SERVICE_SORT.SEARCH_FOR_A_LICENSE;
    linkInfo.ProcessTypeId = LicensureOnlineServiceProcessTypeKeys.EntitySearch;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false);

    this.onlineServiceMenuService.addOnlineServiceMenuItemFromOnlineLink(
      onlineLink,
      this.onlineEntitySearchConstantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH.ONLINE_SERVICE_MENU_GROUP,
      this.onlineEntitySearchConstantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_LICENSE_SEARCH.ONLINE_SERVICE_MENU_GROUP_ORDER,
      this.onlineEntitySearchConstantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_ENTITY_SEARCH.ENABLED,
      true
    );
  }

  /**
   * Configure dynamic Application routes.
   */
  private configureRoutes() {
    const routes: Routes = [
      // First two routes are legacy redirects. Other systems link to the web2 search, this is to ensure they land in the right spot.
      {
        path: 'services/onlineLicenseSearch',
        canActivate: [AnonymousGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.OnlineEntitySearch, SearchComponentKeys.EntityLazy)
      },
      {
        path: 'services/onlineEntitySearch',
        canActivate: [AnonymousGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.OnlineEntitySearch, SearchComponentKeys.EntityLazy)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.EntitySearch).onlineServicePath,
        canActivate: [AnonymousGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.OnlineEntitySearch, SearchComponentKeys.EntityLazy)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public getSearchConfig = (): any => {
    return {};
  };
}

/**
 * Online Entity Search setting constants to merge into ConstantsService.
 */
export class OnlineEntitySearchConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      ONLINE_ENTITY_SEARCH: {
        MAJOR_KEY: "OnlineEntitySearch"
      }
    },

    SYSTEM_SETTING_KEYS: {
      LICENSE: {
        ONLINE_LICENSE_SEARCH: {
          SHOW_STATUS_DATE: "Feature.OnlineLicenseSearch.ShowStatusDate",
          ONLINE_SERVICE_MENU_GROUP: "Feature.OnlineLicenseSearch.OnlineServiceMenu.Group",
          ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.OnlineLicenseSearch.OnlineServiceMenu.GroupOrder",
        },

        ONLINE_ENTITY_SEARCH: {
          EXPIRE_DATE_THIRD_PARTY_JURISDICTION_EXCLUDED: 'Feature.OnlineEntitySearch.ExpireDate.ThirdPartyJurisdiction.Excluded',
          ISSUE_DATE_THIRD_PARTY_JURISDICTION_EXCLUDED: 'Feature.OnlineEntitySearch.IssueDate.ThirdPartyJurisdiction.Excluded',
          PUBLIC_ACTIONS_THIRD_PARTY_JURISDICTION_EXCLUDED: 'Feature.OnlineEntitySearch.PublicActions.ThirdPartyJurisdiction.Excluded',
          SCHOOL_NAME_LABEL: "Feature.Online.EntitySearch.SchoolNameLabel",
          ONLINE_SERVICE_MENU_GROUP: "Feature.OnlineEntitySearch.OnlineServiceMenu.Group",
          ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.OnlineEntitySearch.OnlineServiceMenu.GroupOrder",

          MAJOR_KEY: "Feature.Online.EntitySearch.MajorKey",
          MINOR_KEY: "Feature.Online.EntitySearch.MinorKey",

          FEATURE_ONLINE_LICENSE_SEARCH_INCLUDE_ALTERNATE_NAMES: "Feature.OnlineLicenseSearch.IncludeAlternateNames",
          FEATURE_ONLINE_LICENSE_SEARCH_INCLUDE_NAME_HISTORY: "Feature.OnlineLicenseSearch.IncludeNameHistory",
          INCLUDE_ENTITIES_WITHOUT_LICENSES: "Feature.Online.EntitySearch.IncludeEntitiesWithoutLicenses",
          EXCLUDED_CERTIFICATION_TYPES: "Feature.Online.EntitySearch.ExcludedCertificationTypes",
          EXCLUDED_TEMPORARY_PERMIT_STATUSES: "Feature.Online.EntitySearch.ExcludedTemporaryPermitStatuses",

          CRITERIA_COMPONENT_NAME: "Feature.Online.EntitySearch.Criteria.ComponentName",

          RESULTS_COMPONENT_NAME: "Feature.Online.EntitySearch.Results.ComponentName",

          RESULTS_DETAIL_COMPONENT_NAME: "Feature.Online.EntitySearch.Results.Detail.ComponentName",
          RESULTS_DETAIL_ITEMS_COMPONENT_NAMES: "Feature.Online.EntitySearch.Results.Detail.Items.ComponentNames",

          RESULTS_DETAIL_PERMIT_LIST_HEADERTEXT: "Feature.Online.EntitySearch.Results.Detail.PermitList.HeaderText",

          RESULTS_DETAIL_LICENSE_LIST_CERTIFICATION_LIST_COMPONENT_NAME: 'Feature.Online.EntitySearch.Results.Detail.LicenseList.CertificationList.ComponentName',

          RESULTS_DETAIL_LICENSEE_ACTIONS_LIST_ITEMS_COMPONENT_NAMES: "Feature.Online.EntitySearch.Results.Detail.LicenseeActionsList.Items.ComponentNames",
          RESULTS_DETAIL_ACTIONLIST_DISCIPLINE_TYPE_SUMMARY_ENABLE: "Feature.Online.EntitySearch.Results.Detail.ActionsList.DisciplineTypeSummary.Visible",
          LICENSE_STATUS_BLOCK_LICENSE_TYPE_DISPLAY_MODE: "Feature.Online.EntitySearch.LicenseStatusBlock.LicenseType.DisplayMode",
        }
      }
    },

    ONLINE_SERVICE_SORT: {
      ENTITY_SEARCH: 2000
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
    OnlineEntitySearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
    OnlineEntitySearchConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  ONLINE_SERVICE_SORT = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).ONLINE_SERVICE_SORT),
    OnlineEntitySearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
}

export interface IOnlineEntitySearchConstantsService extends OnlineEntitySearchConstantsService {
}


