import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { ILicenseTypeDto } from './licensure-shared-dto';

export class LicenseTypeDto extends LookupDto implements ILicenseTypeDto {
	Code: string;
	ProfessionTypeId: number;
	IsExcludedOnline: boolean;
	ProfessionalSuffix: string;
	LicenseCardNumberType: string;
	LicenseCardDescription: string;
	RenewCycleMonths?: number;
	CmeCycleMonths: number;
	InitialPeriodMinMonths?: number;
	LateRenewalMinMonths: number;
	ReactivationMinMonths: number;
	Hierarchy: number;
	AllowRenewOnline: boolean;
	AllowSearchOnline: boolean;
	AllowVerificationOnline: boolean;
	AllowMultiple: boolean;
	CmeHoursNeeded?: number;
	DaysLate?: number;
	MdhSurveyPath: string;
	IsIndividual?: boolean;
	TemporaryPermitApplicationBasisId?: number;
	IsLateRenewalPreDatedInGracePeriod?: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
  SupervisionVerificationsSupported: boolean;
  SupervisionPlansSupported: boolean;
}
