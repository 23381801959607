import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { IDegreeDto } from './licensure-shared-dto';

export class DegreeDto extends LookupDto implements IDegreeDto {
	Degree: string;
	ApplicationTypeId: string;
	IsDefault: boolean;
	DescriptionWithDegreeCode: string;

}
