
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { from, Observable, of } from 'rxjs';

import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService, LandingPageDetailRouteResolverService, LandingPageService, OnlineServiceLinkManagerService, OnlineServiceRegistry,
  OnlineServiceRegistryItem, Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService,
  SystemSettingsManagerService,
  UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';
import { IRequestedDataReportInfoDto, WebLinkInfoDto } from '@coreShared/core-shared.module';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';

@Injectable({
  providedIn: 'root'
})
export class DataRequestFeatureConfigurationService {
  private serviceRegistrySelector: OnlineServiceRegistryItem;
  private serviceRegistryItemAdHoc: OnlineServiceRegistryItem;
  private serviceRegistryItemCanned: OnlineServiceRegistryItem;
  private serviceRegistryItemStatistical: OnlineServiceRegistryItem;
  private isInitialized = false;
  private dataRequestConstantsService: IDataRequestConstantsService;
  public adHocDataRequestWizardConfiguration;
  public prebuiltDataRequestWizardConfiguration;
  public statisticalDataRequestWizardConfiguration;
  public presentDataRequestTypeSelector;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private landingPageService: LandingPageService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , private router: Router, private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private userManagerService: UserManagerService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService) {

    this.dataRequestConstantsService = this.constantsService as IDataRequestConstantsService;
    this.serviceRegistrySelector = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.DataRequestSelector);
    this.serviceRegistryItemAdHoc = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.AdHocDataRequest);
    this.serviceRegistryItemCanned = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.PreBuiltDataRequest);
    this.serviceRegistryItemStatistical = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.StatisticalDataRequest);

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.initWizardConfigurations();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, DataRequestConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, DataRequestConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, DataRequestConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.ONLINE_SERVICE_SORT, DataRequestConstantsService.Mergers.ONLINE_SERVICE_SORT);
    this.dataRequestConstantsService = this.constantsService as IDataRequestConstantsService;
  }

  private configureDecorators() { }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItemCanned.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/cannedDataRequestWizard/canned-data-request-wizard.module').then(m => m.CannedDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemCanned.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/cannedDataRequestWizard/canned-data-request-wizard.module').then(m => m.CannedDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemCanned.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/cannedDataRequestWizard/canned-data-request-wizard.module').then(m => m.CannedDataRequestWizardModule)
      },

      {
        path: this.serviceRegistryItemAdHoc.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/adHocDataRequestWizard/adHoc-data-request-wizard.module').then(m => m.AdHocDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemAdHoc.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/adHocDataRequestWizard/adHoc-data-request-wizard.module').then(m => m.AdHocDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemAdHoc.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/adHocDataRequestWizard/adHoc-data-request-wizard.module').then(m => m.AdHocDataRequestWizardModule)
      },

      {
        path: this.serviceRegistryItemStatistical.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/statisticalDataRequestWizard/statistical-data-request-wizard.module').then(m => m.StatisticalDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemStatistical.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/statisticalDataRequestWizard/statistical-data-request-wizard.module').then(m => m.StatisticalDataRequestWizardModule)
      },
      {
        path: this.serviceRegistryItemStatistical.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/statisticalDataRequestWizard/statistical-data-request-wizard.module').then(m => m.StatisticalDataRequestWizardModule)
      },
      {
        path: this.serviceRegistrySelector.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/dataRequestSelection/data-request-wizard-type-selection.module').then(m => m.DataRequestWizardTypeSelectionModule)
      },
      {
        path: this.serviceRegistrySelector.selectorPathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/dataRequestWizard/dataRequestSelection/data-request-wizard-type-selection.module').then(m => m.DataRequestWizardTypeSelectionModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.DataRequestDetailLazy).path,
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.DataRequestDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
    this.addDataRequestWeblink();
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
    this.addDataRequestWeblink();
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
    this.addDataRequestWeblink();
  }

  private addInfosToLandingPage(principal) {
    let isFeatureEnabled = true;
    if (!this.isInitialized) {
      this.initialize();
    }

    if (isFeatureEnabled &&
      principal?.user?.UserAccount?.profile?.DataRequests &&
      principal.user.UserAccount.profile.DataRequests.length > 0) {

      let summaryDetailConfiguration = new SummaryDetailConfiguration(
        LicensureLandingCategories.DataRequest,
        LicensureLandingComponentKeys.DataRequestSummaryLazy,
        LicensureLandingComponentKeys.DataRequestDetailLazy,
        SummaryAggregationType.Aggregate);
      let infos: SummaryComponentInfo[] = [];

      let infoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Data Request", summaryDetailConfiguration);
      principal.user.UserAccount.profile.DataRequests.forEach((dataRequest: IRequestedDataReportInfoDto, index: number) => {
        let programTitle = dataRequest.DocumentName;
        infoBuilder.addItem(index, programTitle, dataRequest, this.dataRequestConstantsService.DOMAIN_IDS.DATA_REQUEST);
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private addDataRequestWeblink() {
    let canExecute = () => {
      let isEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.DATA_REQUEST.ENABLED, false);
      return isEnabled && this.userManagerService.getCurrentPrincipal() !== null;
    };

    let principal = this.userManagerService.getCurrentPrincipal();
    let profile = principal?.user?.UserAccount?.profile;
    let hasDataRequestCard = profile?.DataRequests?.length > 0 ? true : false;

    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "New Data Request";
    linkInfo.DisplayOrder = this.dataRequestConstantsService.ONLINE_SERVICE_SORT.DATA_REQUEST;
    linkInfo.ProcessTypeId = LicensureOnlineServiceProcessTypeKeys.DataRequestSelector;
    linkInfo.DomainId = this.dataRequestConstantsService.DOMAIN_IDS.DATA_REQUEST;
    linkInfo.DomainKeyId = hasDataRequestCard ? this.constantsService.DOMAIN_KEY_IDS.DATA_REQUEST_AGGREGATE : this.constantsService.DOMAIN_KEY_IDS.DEFAULT;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false, true);
    onlineLink.canExecute = canExecute;
  }

  private initWizardConfigurations() {
    this.initAdhocConfiguration();
    this.initPrebuiltConfiguration();
    this.initStatisticalConfiguration();
    this.initSelectorConfiguration();
  }

  private initSelectorConfiguration() {
    this.presentDataRequestTypeSelector = () => {
      this.router.navigate([this.serviceRegistrySelector.selectorPath]);
      return of(true);
    };
  }

  private initAdhocConfiguration() {
    this.adHocDataRequestWizardConfiguration = {
      getAdHocDataRequestWizard: (webServiceHistoryId): Observable<any> => {

        const doPresentDataRequestWizard = async (): Promise<any> => {
          var wizardTags = this.wizardTagsService.create(null, null);
          var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.dataRequestConstantsService.WEB_SERVICE_PROCESS_TYPES.DATA_REQUEST_AD_HOC, wizardTags);
          let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
          wizard.model.isAggregate = false;
          return of(wizard).toPromise();
        };

        return from(doPresentDataRequestWizard());
      },
      presentAdHocDataRequestWizard: () => {
        const initWizard = async (): Promise<any> => {
          let functionType = this.functionTypeService.create(this.constantsService.FUNCTION_TYPES.CORRESPONDENCE, 0);
          let wizard = await this.adHocDataRequestWizardConfiguration.getAdHocDataRequestWizard().toPromse();
          wizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
          this.router.navigate([this.serviceRegistryItemAdHoc.onlineServicePath, wizard.webServiceHistory.Id]);
          return of(true).toPromise();
        };
      }
    }
  }

  private initPrebuiltConfiguration() {
    this.prebuiltDataRequestWizardConfiguration = {
      getPrebuiltDataRequestWizard: (webServiceHistoryId): Observable<any> => {
        const doPresentDataRequestWizard = (): Observable<any> => {
          var wizardTags = this.wizardTagsService.create(null, null);
          var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.dataRequestConstantsService.WEB_SERVICE_PROCESS_TYPES.DATA_REQUEST_CANNED, wizardTags);
          return this.wizardService.getWizard(wizardSearchCriteria);
        };

        return doPresentDataRequestWizard();
      },
      presentPrebuiltDataRequestWizard: (masterCorrespondenceId) => {
        const initWizard = async (): Promise<any> => {
          let functionType = this.functionTypeService.create(this.constantsService.FUNCTION_TYPES.CORRESPONDENCE, masterCorrespondenceId);
          let wizard = await this.prebuiltDataRequestWizardConfiguration.getPrebuiltDataRequestWizard().toPromse();
          wizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
          this.router.navigate([this.serviceRegistryItemCanned.onlineServicePath, wizard.webServiceHistory.Id, masterCorrespondenceId]);
          return of(true).toPromise();
        };
      }
    }
  }


  private initStatisticalConfiguration() {
    this.statisticalDataRequestWizardConfiguration = {
      getStatisticalDataRequestWizard: (webServiceHistoryId): Observable<any> => {
        const doPresentDataRequestWizard = async (): Promise<any> => {
          var wizardTags = this.wizardTagsService.create(null, null);
          var wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.dataRequestConstantsService.WEB_SERVICE_PROCESS_TYPES.DATA_REQUEST_STATISTICAL, wizardTags);
          let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
          wizard.model.isAggregate = true;
          return of(wizard).toPromise();
        };

        return from(doPresentDataRequestWizard());
      },
      presentStatisticalDataRequestWizard: (masterCorrespondenceId) => {
        const initWizard = async (): Promise<any> => {
          let functionType = this.functionTypeService.create(this.constantsService.FUNCTION_TYPES.CORRESPONDENCE, 0);
          let wizard = await this.statisticalDataRequestWizardConfiguration.getStatisticalDataRequestWizard().toPromse();
          wizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
          this.router.navigate([this.serviceRegistryItemStatistical.onlineServicePath, wizard.webServiceHistory.Id]);
          return of(true).toPromise();
        };
      }
    }
  }
}

export class DataRequestConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      DATA_REQUEST_CANNED_WIZARD: "DataRequest.Canned.Wizard",
      DATA_REQUEST_ADHOC_WIZARD: "DataRequest.AdHoc.Wizard",
      DATA_REQUEST_STATISTICAL_WIZARD: "DataRequest.Statistical.Wizard"
    },
    WEB_SERVICE_PROCESS_TYPES: {
      DATA_REQUEST_CANNED: LicensureOnlineServiceProcessTypeKeys.PreBuiltDataRequest,
      DATA_REQUEST_ADHOC: LicensureOnlineServiceProcessTypeKeys.AdHocDataRequest,
      DATA_REQUEST_STATISTICAL: LicensureOnlineServiceProcessTypeKeys.StatisticalDataRequest
    },
    SYSTEM_SETTING_KEYS: {
      DATA_REQUEST: {
        PRE_BUILT_REPORTS_ENABLED: "Feature.DataRequest.PrebuiltReports.Enabled",
        STATISTICAL_REPORTS_ENABLED: "Feature.DataRequest.StatisticalReports.Enabled",
        AD_HOC_REPORTS_ENABLED: "Feature.DataRequest.AdHocReports.Enabled",
        ONLINE_SERVICE_MENU_GROUP: "Feature.DataRequest.OnlineServiceMenu.Group",
        ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.DataRequest.OnlineServiceMenu.GroupOrder",
        PER_ROW_FEE_AMOUNT: "Feature.DataRequest.PerRowFeeAmount"
      }
    },
    ONLINE_SERVICE_SORT: {
      DATA_REQUEST: 2030
    }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), DataRequestConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), DataRequestConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), DataRequestConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  ONLINE_SERVICE_SORT = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).ONLINE_SERVICE_SORT), DataRequestConstantsService.Mergers.ONLINE_SERVICE_SORT);
}

export interface IDataRequestConstantsService extends DataRequestConstantsService { }
