<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- Grid -->
    <div class="p-grid p-formgrid">

        <!-- Facility -->
        <div class="p-field p-col-12">
            <label for="Facility">Facility</label>
            <input id="Facility" type="text" formControlName="Facility" pInputText>
        </div>

        <!-- Address -->
        <div class="p-field p-col-12">
            <label for="Address">Address</label>
            <input id="Address" type="text" formControlName="Address" pInputText>
        </div>

        <!-- Country -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-md-6 p-xl-4" [hidden]="!stateVisible">
            <label for="state">{{stateLabel}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="filteredPossibleStates"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

    </div>

    <!-- Grid -->
    <div class="p-grid p-formgrid">

        <!-- Phone Number -->
        <div class="p-field p-col-12 p-md-6 p-xl-4 p-pr-2">
            <label for="Phone">Phone</label>
            <p-inputMask inputId="Phone" mask="(999) 999-9999" formControlName="Phone" [autoClear]="false"
                *ngIf="standardNumber"></p-inputMask>
            <input id="Phone" type="text" formControlName="Phone" *ngIf="!standardNumber" pInputText>

            <!-- Checkbox -->
            <div class="p-field-checkbox p-mt-3 p-mb-0">
                <p-checkbox inputId="IrregularNumber" formControlName="IrregularNumber" binary="true"></p-checkbox>
                <label for="IrregularNumber">I don't have a standard US phone number.<field-help majorKey="Shared.Content" minorKey="EntityPhone.IrregularNumber"></field-help></label>
            </div>

        </div>

    </div>

    <!-- Grid -->
    <div class="p-grid p-formgrid">

        <!-- StartDate -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="StartDate">From</label>
            <input id="StartDate" type="text" formControlName="StartDate" pInputText>
        </div>

        <!-- EndDate -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="EndDate">To</label>
            <input id="EndDate" type="text" formControlName="EndDate" pInputText>
        </div>

        <!-- Certification Header -->
        <div class="p-col-12 reach-section-header">Certification</div>

        <!-- Specialty -->
        <div class="p-field p-col-12">
            <label for="Specialty">Type</label>
            <input id="Specialty" type="text" formControlName="Specialty" pInputText>
        </div>

    </div>

</div>