import * as _l from "lodash-es";

export class PicklistModel {

  // FIELDS
  possibleItems = [];
  selectedItems = [];

  constructor(public friendlyName: string) {
  }

  public addPickListOption = (key, value) => {
    this.possibleItems.push({ 'key': key, 'value': value, 'picked': false });
  }

  public clearSelection = () => {
    this.selectedItems.length = 0;
    this.possibleItems.forEach(item => item.picked = false);
  }

  /**
   * Add an item to the selected items collection.
   * @param x The item to be added.
   */
  public addItem(x): void {

    // Bail if this item is already selected.
    if (this.selectedItems.find(item => item.key === x?.key)) return;

    this.selectedItems.push(x);
    x.picked = true;
  }

  /**
   * Remove an item from the selected items collection.
   * @param x The item to be removed.
   */
  public removeItem(x): void {

    if (!this.selectedItems.find(item => item.key === x?.key)) return;

    this.selectedItems.forEach((value, index) => { if (value.key == x.key) this.selectedItems.splice(index, 1); });
    x.picked = false;
  }

  /**
   * Format selected items into a comma separated string.
   * @param includeFriendlyName 
   * @returns 
   */
  public toString = (includeFriendlyName: boolean) => {

    if (!this.selectedItems.length) return null;

    let x = _l.map(this.selectedItems, "value").join(", ");
    return includeFriendlyName
      ? `${this.friendlyName}: ${x}`
      : `${x}`;
  }
}
