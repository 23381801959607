import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { DynamicContentDto } from '@coreShared/core-shared.module';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import * as _ from 'underscore';
import { DynamicContentChangedEventArgument } from '../models/eventArgs/dynamic-content-changed-event-argument';
import { CommandService } from './command.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DynamicContentService } from './dynamic-content.service';

@Injectable({ providedIn: 'root' })
export class DynamicContentManagerService {
  private dynamicContentEntries: DynamicContentDto[] = [];
  private _contenthanged$: BehaviorSubject<DynamicContentChangedEventArgument> =
    new BehaviorSubject<DynamicContentChangedEventArgument>(new DynamicContentChangedEventArgument());
  public contenthanged$ = this._contenthanged$.asObservable();

  constructor(@Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private commandService: CommandService
    , private dynamicContentService: DynamicContentService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private sanitizer: DomSanitizer
    , private confirmationService: ConfirmationService,) { }

  public createExternalContentCommand(item: any) {
    return this.commandService.create(() => {
      return true;
    }, () => {
      window.open(item.Content);
    }, item.Description);
  }

  /**
   * Present DynamicContent entry in a dialog.
   * The confirmationService ConfirmationDialog is located on reach-app-component.html and is shared across the applicaiton.
   * @param item
   * @returns
   */
  public createModalContentCommand(item) {

    return this.commandService.create(() => { return true; }, () => {

      // Use the PrimeNG confirmation dialog.
      this.confirmationService.confirm({
        message: item.Content,
        header: item.Description,
        icon: 'pi pi-info-circle',
        acceptLabel: 'Close',
        rejectVisible: false,
      });
    }, item.Description);

  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (item: any) => {
  }

  /**
   * Returns the DynamicContent cache.
   */
  public getEntries(): DynamicContentDto[] {
    return this.dynamicContentEntries;
  }

  /**
   * Clears the DynamicContent cache.
   */
  public clearHelpEntries() {
    this.dynamicContentEntries.length = 0;
  }

  /**
   * Loads all DynamicContent entries associated with the supplied key.
   * @param majorKey
   */
  public loadEntriesByMajorKey(majorKey: string) {
    return forkJoin([this.loadContentForSuppliedKey(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.SHARED_CONTENT), this.loadContentForSuppliedKey(majorKey)])
      .pipe(
        map(responseCollection => {
          if (responseCollection.length > 0) {
            // Notify listeners
            this.raiseDynamicContentChanged();
          }
          return responseCollection.length > 0 ? responseCollection[0] : [];
        })
      );
  }

  private loadContentForSuppliedKey(majorKey: string) {
    const loadContentObservable = new Observable((observer) => {
      var routeContentExists = _.any(this.dynamicContentEntries,
        (entry: DynamicContentDto) => {
          return entry.MajorKey === majorKey;
        });

      // if exists get out
      if (routeContentExists) {
        observer.next(this.dynamicContentEntries);
        observer.complete();
      } else {
        this.dynamicContentService.getByMajorKey(majorKey)
          .toPromise()
          .then(searchResult => {
            observer.next(this.mapContent(searchResult));
            observer.complete();
          });
      }
    });

    return loadContentObservable;
  }

  private mapContent(searchResult) {
    _.forEach(searchResult.Results,
      (item: DynamicContentDto) => {
        if (item) {
          if (item.Content) {
            // Sanitize this and reassign
            (item as any).Data = this.sanitizer.bypassSecurityTrustHtml(item.Content);
          }

          // Add to cache
          this.dynamicContentEntries.push(item);
        }
      });

    return this.dynamicContentEntries;
  }

  /**
   * Raises the "userManager.userProfileRefreshed$" event.
   */
  private raiseDynamicContentChanged() {
    this._contenthanged$.next(new DynamicContentChangedEventArgument(this.dynamicContentEntries));
  }
}
