// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, Routes } from '@angular/router';
import { Observable } from "rxjs";
import { retry, catchError, tap, map } from "rxjs/operators";
import * as _ from 'underscore';
import * as _l from 'lodash-es';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { DynamicContentConfigurationProviderService } from '@coreServices/dynamic-content-configuration-provider.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { OnlineServiceRegistry, OnlineServiceRegistryItem } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';

/**
 * Service that supports the Activity Wizard.
 */
@Injectable({
  providedIn: 'root'
})
export class ActivityFeatureConfigurationService {
  private isInitialized = false;
  protected activityConstantsService: IActivityConstantsService;
  public wizardPageConfiguration = {
    registrationVerifyUser: {
      domainIdentifiersDirectiveConfiguration: null,
      domainIdentifierFilterFunction: (licenseTypeId, domainIdentifiers) => {
        return [];
      }
    }
  };

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected router: Router
  ) {
  }

  public initialize() {
    if (!this.isInitialized) {
      // configure constants
      this.configureConstants();

      // Decorate
      this.configureDecorators();

      // Routes
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
 * Decorate the ConstantsService with UserRegistration-specific constants.
 */
  protected configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      ActivityConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS,
      ActivityConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS,
      ActivityConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      ActivityConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.activityConstantsService = this.constantsService as IActivityConstantsService;
  }

  protected configureDecorators() {
  }

  /**
   * Configure dynamic Activity routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  protected configureRoutes() {

    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Activity).selectorPath,
        loadChildren: () => import('src/app/core/features/activityWizard/activity-wizard-selector.module')
          .then(m => m.ActivityWizardSelectorModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Activity).onlineServicePathTemplate,
        loadChildren: () => import('src/app/core/features/activityWizard/activity-wizard.module')
            .then(m => m.ActivityWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Activity).onlineServicePath,
        loadChildren: () => import('src/app/core/features/activityWizard/activity-wizard.module')
          .then(m => m.ActivityWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class ActivityConstantsService extends ConstantsService {
    public static Mergers = {
      DYNAMIC_CONTENT_MAJOR_KEYS: {
        ACTIVITY_WIZARD_FEATURE: {
          ACTIVITY_WIZARD: "ActvitySubmission.Wizard"
        }
      },

      SYSTEM_SETTING_KEYS: {
        ACTIVITY_WIZARD_FEATURE_SYSTEM_SETTING_KEYS: {
          ACTIVITY_ENABLED: "Feature.Activity.Enabled"
        }
      },

      FEATURE_CONSTANTS: {
        ACTIVITY_WIZARD_FEATURE: {
          CREDENTIAL_TYPES: {
            LETTER_OF_INTENT_CODE: { NAME: "LetterOfIntent", PROCESS: 111 }
          }
        }
      },

      VALIDATION_MODES: {
        ACTIVITY: {
          WEB_PASSWORD_RESET: "Web.ResetPassword"
        }
      },

      WEB_SERVICE_PROCESS_TYPES: {
        ACTIVITY: OnlineServiceProcessTypeKeys.Activity
      },
    };

    DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
      ActivityConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS),
      ActivityConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
        ActivityConstantsService.Mergers.FEATURE_CONSTANTS);
    VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES),
      ActivityConstantsService.Mergers.VALIDATION_MODES);
    WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
      ActivityConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface IActivityConstantsService extends ActivityConstantsService {
}
