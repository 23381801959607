import { IMilitaryResponseTypeDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class MilitaryResponseTypeDto extends LookupDto implements IMilitaryResponseTypeDto {
	MinorKey: string;
	IsMilitary?: boolean;
	AutoApplyEntityMilitary?: boolean;
	ApplicationChecklistItemId?: number;
	RenewalChecklistItemId?: number;
	DischargeDateApplicable?: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
