import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";


import {
  ArgumentExceptionService,
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from "@core/core.module";
import { IAutoVerificationSubscriberLicenseListItemDto, IAutoVerificationSubscriberLicenseSearchCriteriaDto } from "@licensureShared/licensure-shared.module";


@Injectable({
  providedIn: 'root'
})
export class AutoVerificationLicenseLookupService extends ReachHttpClientService {
  private uri: string;
  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/licensure/AutoVerificationSubscriberLicenseList`;
  }

  /**
   * Gets the data requests.
   */
  public search(criteria: IAutoVerificationSubscriberLicenseSearchCriteriaDto): Observable<IAutoVerificationSubscriberLicenseListItemDto[]> {
    return this.get<IAutoVerificationSubscriberLicenseListItemDto[]>(`${this.uri}/search`, { params: criteria as any });
  }
}
