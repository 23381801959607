import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IActionRequirementOccurrenceSearchResultItemDto } from './licensure-shared-dto';

export class ActionRequirementOccurrenceSearchResultItemDto extends SearchResultItemDto implements IActionRequirementOccurrenceSearchResultItemDto {
	Id: number;
	StatusId: number;
	ActionId: number;
	RequirementExtendedDescription: string;
	StatusDescription: string;
	ActionRequirementId: number;
	EntityId: number;
	ActionRequirementTypeId?: number;
	ActionRequirementTypeDescription: string;
	Comment: string;
	Analyst: string;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	StatusModifiedBy: string;
	StatusModifiedDate?: Date;
	DueDate?: Date;
	CompletedDate?: Date;
	AnalystActionDueDate?: Date;
	AnalystActionCompletedDate?: Date;
	ReportTypeId?: number;
	ReportTypeDescription: string;
	AnalystActionComment: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	CreatedBy: string;

}
