import { IDomainReasonForChangeDtoHost,
IUserDto,
IUserRightDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class UserDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IUserDto {
	UserId: string;
	FirstName: string;
	LastName: string;
	Initials: string;
	Phone: string;
	Title: string;
	Email: string;
	InternalEmail: string;
	UnitId?: number;
	EntityId?: number;
	Signature: number[];
	IsActive?: boolean;
	MiddleName: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
	UserPreferences: any[];
	UserRights: IUserRightDto[];
	UserRoles: any[];
	FLName: string;
	FMLName: string;
	FMILName: string;

}
