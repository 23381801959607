<div class="p-fluid p-formgrid p-grid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- License -->
    <license-selector-field class="p-field p-col-12 p-md-6" [model]="licenseSelectorComponentModel"
        [config]="licenseSelectorFieldConfig" (selectionChanged)="licenseSelectorFieldSelectionChanged($event)">
    </license-selector-field>

    <!-- StartDate -->
    <div class="p-field p-col-12 p-sm-3">
        <label for="StartDate">From</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="StartDate" formControlName="StartDate"
            appendTo="body" baseZIndex="1">
        </p-calendar>
    </div>

    <!-- EndDate -->
    <div class="p-field p-col-12 p-sm-3">
        <label for="EndDate">To</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="EndDate" formControlName="EndDate"
            appendTo="body" baseZIndex="1">
        </p-calendar>
    </div>

    <!-- Comments -->
    <div class="p-field p-col-12">
        <label for="Comments">Comments</label>
        <textarea pInputTextarea inputId="Comments" formControlName="Comments" placeholder="Your Message" [autoResize]="false" rows="3"></textarea>
    </div>

</div>