import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICertificationDto,
ICertificationTypeDto } from './licensure-shared-dto';

export class CertificationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICertificationDto {
	EntityId?: number;
	CertificationTypeId?: number;
	ExpireTypeId?: number;
	StatusId?: number;
	EffectiveDate?: Date;
	ExpireDate?: Date;
	EffectiveDateDescription: string;
	ExpireDateDescription: string;
	IsLicenseeVerified?: boolean;
	IsStaffVerified?: boolean;
	IsMocParticipation?: boolean;
	Comment: string;
	IsCurrent?: boolean;
	IsLicenseeViewed?: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ExpireType: any;
	Status: any;
	CertificationType: ICertificationTypeDto;
	DerivedEffectiveDate?: Date;
	DerivedExpireDate?: Date;
	DerivedDescription: string;

}
