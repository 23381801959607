import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN, registerDynamicComponent } from 'src/app/core/index-models';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN, ValidationManagerService } from 'src/app/core/index-services';
import { IEntityAssociateDto, IEntityGroupMemberDto, IEntityInfoDto } from 'src/app/coreShared/core-shared.module';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '../../reach-dialog/reach-dialog-content.component';
import { ReachControlValidators } from '../../validators/reach-control-validators';

export const EntityAssociationEditorComponentSelector: string = "entity-association-editor";
export const EntityAssociationEditorComponentKey: string = "EntityAssociationEditorComponent";

@Component({
  selector: EntityAssociationEditorComponentSelector,
  templateUrl: './entity-association-editor.component.html',
  styleUrls: ['./entity-association-editor.component.scss']
})
export class EntityAssociationEditorComponent extends ReachDialogContentComponent<IEntityAssociateDto> implements OnInit, IReachDialogContentComponent {

  public get possibleFacilities(): IEntityGroupMemberDto[] { return this.hostDialogData.initializationData.possibleFacilities }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    protected formBuilder: FormBuilder,) {

    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  public override async ngOnInit(): Promise<void> {

    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";

    await super.ngOnInit();
  }

  protected override modelToForm(): void {

    let startDate = this.outputModel.BeginDate ? new Date(this.outputModel.BeginDate) : null;
    let endDate = this.outputModel.EndDate ? new Date(this.outputModel.EndDate) : null;

    this.contentForm = this.formBuilder.group({
      Facility: [null, [Validators.required]],
      StartDate: [startDate, [Validators.required, ReachControlValidators.pastDateValidator('Start Date')]],
      EndDate: [endDate, [ReachControlValidators.pastDateValidator('End Date')]],
      Comments: [this.outputModel.Comment]
    });

    super.modelToForm();
  }

  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    this.outputModel.AssociateEntityId = this.contentForm.get('Facility').value?.Id;
    this.outputModel.AssociatedEntityInfo = { FullName: this.contentForm.get('Facility').value?.FullName, PublicAddressInfo: { City: this.contentForm.get('Facility').value?.City, State: this.contentForm.get('Facility').value?.State } } as unknown as IEntityInfoDto;
    this.outputModel.Comment = this.contentForm.get('Comments').value;
    this.outputModel.BeginDate = this.contentForm.get('StartDate').value;
    this.outputModel.EndDate = this.contentForm.get('EndDate').value;
  }

  protected override initDropdowns(): void {
    if (this.outputModel.AssociateEntityId) this.contentForm.get('Facility').setValue(this.possibleFacilities.find(item => item.Id == this.outputModel.AssociateEntityId));
    this.dataLoaded = true; // Allow UI elements to render.
  }


}

registerDynamicComponent(ReachScenarios.ProfileLanding, EntityAssociationEditorComponentKey, EntityAssociationEditorComponent, EntityAssociationEditorComponentSelector);