import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  ReachHttpClientService,
  ValidationManagerService
} from "@core/core.module";
import { ProfessionalProfileDto } from "src/app/licensureShared";

@Injectable({
  providedIn: 'root'
})
export class ProfessionalProfileService extends ReachHttpClientService {

  private uri: string;
  constructor(busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/licensure/ProfessionalProfile`;
  }

  public getById(id: number): Observable<ProfessionalProfileDto> {
    return this.get<ProfessionalProfileDto>(`${this.uri}/${id}`);
  }

  public save(item): Observable<ProfessionalProfileDto> {

    if (item.Id) {
      return this.put<ProfessionalProfileDto>(this.uri, item);
    }
    else {
      return this.post<ProfessionalProfileDto>(this.uri, item);
    }
  }
}
