import { WizardTags } from '@core/core.module';

export class ApplicationWizardTags extends WizardTags {
  constructor(applicationOrLicenseTypeId,
    applicationBasisId,
    hasSupervisionPlan: boolean) {
    super(applicationOrLicenseTypeId, applicationBasisId, null, null, hasSupervisionPlan);
  }
}

