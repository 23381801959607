
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { IDocumentHistoryItem } from 'src/app/coreShared/core-shared.module';
import { Command } from '../../index-models';
import { CommandService, DocumentService, OnlineServiceHistoryService } from '../../index-services';

@Component({
  selector: 'document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss']
})
export class DocumentHistoryComponent {
  groupByCategory: boolean;
  contentForm: FormGroup;
  downloadCommand: Command;
  documentHistoryItems: TreeNode<IDocumentHistoryItem>[] = [];
  historyItems: IDocumentHistoryItem[] = [];

  constructor(
    onlineServiceHistoryService: OnlineServiceHistoryService,
    commandService: CommandService,
    private docService: DocumentService) {
      
    this.fetchDocumentHistory();

    this.contentForm = new FormGroup({ groupByCategory: new FormControl(false) })
    this.contentForm.valueChanges.subscribe(() => { this.groupByCategory = this.contentForm.get('groupByCategory').value; });

    this.downloadCommand = commandService.create(() => true, (item: IDocumentHistoryItem) => {
      onlineServiceHistoryService.downloadConfirmationDocument(item.DocumentId);
      (item as any).IsDownloaded = true;
    });
  }

  fetchDocumentHistory() {
    this.docService.getHistory().subscribe((historyItems: IDocumentHistoryItem[]) => {
      this.historyItems = historyItems;
      this.documentHistoryItems = this.transformToTreeNodes(historyItems);
      this.expandAll(this.documentHistoryItems);  // Expand all nodes
    });
  }

  transformToTreeNodes(historyItems: IDocumentHistoryItem[]): TreeNode<any>[] {
    const groupedByType = this.groupByType(historyItems);
    return Object.keys(groupedByType).map(type => {
      const groupedBySubtype = this.groupBySubtype(groupedByType[type]);
      return {
        data: { description: type },
        children: Object.keys(groupedBySubtype).map(subtype => ({
          data: { description: subtype },
          children: groupedBySubtype[subtype].map(item => ({
            data: item
          }))
        }))
      } as TreeNode<any>;
    });
  }

  groupByType(historyItems: IDocumentHistoryItem[]): { [key: string]: IDocumentHistoryItem[] } {
    return historyItems.reduce((acc, item) => {
      if (!acc[item.ActivityTypeDescription]) {
        acc[item.ActivityTypeDescription] = [];
      }
      acc[item.ActivityTypeDescription].push(item);
      return acc;
    }, {});
  }

  groupBySubtype(items: IDocumentHistoryItem[]): { [key: string]: IDocumentHistoryItem[] } {
    return items.reduce((acc, item) => {
      if (!acc[item.ActivitySubtypeDescription]) {
        acc[item.ActivitySubtypeDescription] = [];
      }
      acc[item.ActivitySubtypeDescription].push(item);
      return acc;
    }, {});
  }

  expandAll(nodes: TreeNode<any>[]) {
    nodes.forEach(node => {
      node.expanded = true;
      if (node.children && node.children.length > 0) {
        this.expandAll(node.children);
      }
    });
  }

  getIcon(item: any): string {
    return item.IsDownloaded ? "fa fa-check" : "fa fa-download";
  }

  getTooltip(item: any): string {
    return item.IsDownloaded ? "This document was downloaded in the current session. Click to download again." : "View/download review document";
  }
}
