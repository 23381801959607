import { IDomainReasonForChangeDtoHost,
IDynamicContentCategoryDto,
IDynamicContentDto,
IDynamicContentLocationTypeDto,
IDynamicContentPresentationTypeDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DynamicContentDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDynamicContentDto {
	MajorKey: string;
	MinorKey: string;
	PresentationTypeId?: number;
	CategoryId?: number;
	IsInactive: boolean;
	DisplayOrder: number;
	LocationTypeId?: number;
	Content: string;
	PresentationType: IDynamicContentPresentationTypeDto;
	Category: IDynamicContentCategoryDto;
	LocationType: IDynamicContentLocationTypeDto;
	Description: string;
	GroupName: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
