import { registerOnlineService } from './online-service-registry';
export enum OnlineServiceProcessTypeKeys {
  SecurityQuestions = -11,
  Password = -10,
  Any = 0,
  UserRegistration = 37,
  ForgotPassword = 38,
  Activity = 41,
  Invoice = 45,

  // UI-defined OnlineServices
  Cart = 2001,
  DocumentHistory = 2030,
}

export class OnlineServiceProcessTypes {
  public static baseUrlSegment: string = 'baseUrlSegment';
  public static Any: string = OnlineServiceProcessTypeKeys.Any.toString();
  public static Cart: string = OnlineServiceProcessTypeKeys.Cart.toString();
  public static DocumentHistory: string = OnlineServiceProcessTypeKeys.DocumentHistory.toString();
  public static UserRegistration: string = OnlineServiceProcessTypeKeys.UserRegistration.toString();
  public static ForgotPassword: string = OnlineServiceProcessTypeKeys.ForgotPassword.toString();
  public static Activity: string = OnlineServiceProcessTypeKeys.Activity.toString();
  public static Password: string = OnlineServiceProcessTypeKeys.Password.toString();
  public static SecurityQuestions: string = OnlineServiceProcessTypeKeys.SecurityQuestions.toString();
  public static Invoice: string = OnlineServiceProcessTypeKeys.Invoice.toString();

  private static _initializeCoreRoutes = (() => {
    registerOnlineService(OnlineServiceProcessTypes.Activity, 'Activity', 'activityWizard', 'activityWizardSelector', '/:processId/:serviceId/:activityId/:domainId/:domainKeyId/:webDocumentHistoryId', '/:processTypeId/:webDocumentHistoryId');
    registerOnlineService(OnlineServiceProcessTypes.Cart, 'Cart', 'cart', 'cart', '', '');
    registerOnlineService(OnlineServiceProcessTypes.DocumentHistory, 'DocumentHistory', 'DocumentHistory', 'DocumentHistory', '', '');
    registerOnlineService(OnlineServiceProcessTypes.ForgotPassword, 'ForgotPassword', 'forgotPasswordWizard', 'forgotPasswordWizardSelector', '/:processId/:serviceId', '/:processTypeId');
    registerOnlineService(OnlineServiceProcessTypes.UserRegistration, 'UserRegistration', 'register', 'registrationWizardSelector', '/:processId/:serviceId', '/:processTypeId');
    registerOnlineService(OnlineServiceProcessTypes.Invoice, 'Invoice', 'invoiceWizard', 'invoiceWizardSelector', '/:processTypeId/:invoiceId/:webDocumentHistoryId', '');
    registerOnlineService(OnlineServiceProcessTypes.Password, 'password', 'password', 'password', '', '');
    registerOnlineService(OnlineServiceProcessTypes.SecurityQuestions, 'securityQuestions', 'securityQuestions', 'securityQuestions', '', '');
  })();
}