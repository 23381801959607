<p-table styleClass="p-datatable-sm" [value]="verifications" [responsive]="true">

    <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
            <span class="p-text-left reach-list-header"> Supervision Verifications</span>
            <div>
                <button *ngIf="newVerificationWizardCommand.enabled" pButton pRipple class="p-button-text"
                    icon="fa fa-plus" [command-button]="newVerificationWizardCommand"
                    pTooltip="Create a Supervision Verification"></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th *ngIf="isEmploymentEnabled" pSortableColumn="EmployerName">Agency / Position</th>
            <th *ngIf="isPracticeLocationEnabled" pSortableColumn="PracticeLocation">Practice Location</th>
            <th pSortableColumn="SupervisorName">Supervisor</th>
            <th pSortableColumn="BeginDate">Supervision Period</th>
            <th pSortableColumn="FormDescription">Type</th>
            <th pSortableColumn="ReceivedDate">Date Submitted</th>
            <th pSortableColumn="ReferenceValue">Verification / Plan</th>
            <th pSortableColumn="StatusDescription">Status</th>
            <th style="width: 6em;">
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td *ngIf="isEmploymentEnabled">
                <span class="p-column-title">
                Agency / Position</span>
                {{item.EmployerName | whenEmpty: "-"}} / {{item.PositionTitle| whenEmpty: "-"}}
            </td>
            <td *ngIf="isPracticeLocationEnabled">
                <span class="p-column-title">Practice Location</span>
                {{item.PracticeLocation | whenEmpty: "-"}}
            </td>
            <td>
                <span class="p-column-title">Supervisor</span>
                {{item.SupervisorName | whenEmpty: "-"}}
            </td>
            <td>
                <span class="p-column-title">Supervision Period</span>
                {{item.BeginDate | date:'MM/dd/yyyy'}} - {{item.EndDate | date:'MM/dd/yyyy'}}
            </td>
            <td>
                <span class="p-column-title">Type</span>
                {{item.FormDescription | whenEmpty: "-"}}
            </td>
            <td>
                <span class="p-column-title">Date Submitted</span>
                {{item.ReceivedDate | date:'MM/dd/yyyy' | whenEmpty: "-"}}
            </td>
            <td>
                <span class="p-column-title">Verification / Plan</span>
                {{item.ReferenceValue}} / {{item.PlanReferenceValue | whenEmpty: 'No Plan'}}
            </td>
            <td>
                <span class="p-column-title">Status</span>
                {{item.StatusDescription | whenEmpty: "-"}}
            </td>
            <td class="p-text-right">
                <span class="p-text-nowrap">
                    <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                        [command-button]="continueVerificationWizardCommand" [command-parameter]="item"
                        pTooltip="Edit/Submit this Supervision Verification"></button>
                </span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columnLength">
                <span class="reach-text-muted"><em>No items to display</em></span>
            </td>
        </tr>
    </ng-template>
</p-table>
