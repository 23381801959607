import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { LicenseStatusDto } from '@licensureShared/licensure-shared.module';
import { LicensureListService } from './licensure-list.service';
import {
    ArgumentExceptionService,
    BusyManagerService,
    CONSTANTS_SERVICE_TOKEN, ConstantsService,
    DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService,
    ReachHttpClientService,
    ValidationManagerService
} from '@core/core.module';

/**
 * Data service for API calls to fetch LicenseStatus lookups.
 */
@Injectable({
  providedIn: 'root'
})
export class LicenseStatusService extends ReachHttpClientService {
  uri: string = null;

  constructor(
      private argumentExceptionService: ArgumentExceptionService,
      busyManagerService: BusyManagerService,
      @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
      @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
      DefaultProviderConfigurationService,
      http: HttpClient,
      private licensureListService: LicensureListService,
      validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.apiRootUri}/licensure/LicenseStatusType`;
  }

  /**
   * Gets the LicenseStatusDto associated with the specified Id.
   * @param id the target id.
   */
  public getById(id): Observable<LicenseStatusDto> {

    // Must have an id
    if (!id) {
      this.argumentExceptionService.create("id").log();
    }

    return this.get<LicenseStatusDto>(`${this.uri}/${id}`);
  }

  /**
   * Gets an array of all LicenseStatus lookups.
   */
  public getAll(): Observable<LicenseStatusDto[]> {
    return this.licensureListService.getLicenseStatusTypes();
  }
}

