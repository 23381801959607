// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from "@angular/core";
import { Subscription } from 'rxjs';

import * as _l from 'lodash-es';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { ReachMessage } from '@coreModels/reach-message';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { MessageSeverities } from '@coreModels/message-severities';
import { UserManagerService } from "./user-manager.service";
import { INotificationDto } from '../../coreShared/core-shared.module';

/**
 *  Data service that manages the handling of validation errors.
 *  -- Maps Notification entries from the user profile to standard ReachMessage reporting format and publishes them to the ReachMessageService to be routed to the appropriate subscribers.
 *  -- Provides Get/Clear access to validation messages.
 *  -- Provides access to the Notification messages behind notification messages.
 *  -- Show/Hide control of validation summary display.
 */
@Injectable({ providedIn: 'root' })
export class NotificationCenterManagerService {
  private _notificationEntries = [];
  private subscriptionLogin: Subscription;
  private subscriptionLogout: Subscription;
  private notificationScopeApplication: string;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private reachMessageService: ReachMessageService,
    private userManagerService: UserManagerService) {
    this.notificationScopeApplication = this.constantsService.NOTIFICATION_SCOPES.APPLICATION.toString();
    this.subscriptionLogin = this.userManagerService.login$
      .subscribe(currentPrincipal => this.onUserLogin(currentPrincipal));
    this.subscriptionLogout = this.userManagerService.logout$
      .subscribe(currentPrincipal => this.onUserLogout());
  }

  /**
   * Gets the ReachMessage array associated with the Application Notification Scope.
   * @returns the target message array.
   */
  public getApplicationNotificationMessages(): ReachMessage[] {
    return this.reachMessageService.get(this.notificationScopeApplication);
  }

  /**
   * Clears the notification messages associated with the Application Notification Scope.
   */
  public clearApplicationNotificationMessages(): void {
    this.reachMessageService.clear(this.notificationScopeApplication);
  }

  /**
 * Clears the notification messages associated with the Application Notification Scope.
 */
  public clearNotificationMessages(scopeKey: string): void {
    this.reachMessageService.clear(scopeKey);
  }

  /**
   * Gets the list of active user notifications.
   */
  public getNotificationEntries() {
    return this._notificationEntries;
  }

  /**
   * Enables display of the notification summary for the Application Scope.
   */
  public showApplicationNotificationSummary() {
    this.reachMessageService.setToggleState(
        this.notificationScopeApplication,
      true);
  }

  /**
   * Hides the display of the notification summary for the Application Scope.
   */
  public hideApplicationNotificationSummary() {
    this.reachMessageService.setToggleState(
        this.notificationScopeApplication,
      false);
  }

  /**
   * Handles the "userManager.login" event.
   * @param event
   * @param argument
   */
  private onUserLogin(currentPrincipal) {

    if (currentPrincipal &&
      currentPrincipal.user &&
      currentPrincipal.user.UserAccount &&
      currentPrincipal.user.UserAccount.profile) {
        this._notificationEntries = currentPrincipal.user.UserAccount.profile.Notifications;
        this._notificationEntries = _l.filter(this._notificationEntries, (i) => i.Message); // Remove notifications with empty Message text
    } else {
      this._notificationEntries = [];
    }

    let applicationNotifications = _l.filter(this._notificationEntries, (i: INotificationDto) => { return i.DomainId ? false : true; });
    this.addNotifications(applicationNotifications, this.constantsService.NOTIFICATION_SCOPES.APPLICATION.toString());
  };

  /**
   * Adds a ReachMessages to the pool with the specified key and detail text.
   * @param detail text.
   * @param key the key with which to associate the message.
   */
  public addNotification(detail: string, key?: string) {
    key = key ? key : this.notificationScopeApplication;
    this.reachMessageService.addMessage(MessageSeverities.info, null, detail, key, null, true, true);
  }

  /**
   * Adds a ReachMessages to the pool with the specified key and detail text.
   * @param detail text.
   * @param key the key with which to associate the message.
   */
  public addNotifications(notifications: any[], key?: string) {
    key = key ? key : this.notificationScopeApplication;
    let msgs: ReachMessage[] = [];
    notifications.forEach(n => msgs.push(new ReachMessage(MessageSeverities.info,
      null,
      n.Message,
      key,
      null,
      true,
      true,
      n,
      n.Id)));
    this.reachMessageService.addAll(msgs);
  }

  public getAllNotifications(): INotificationDto[] {
    let notifications: INotificationDto[] = [];
    let currentPrincipal = this.userManagerService.getCurrentPrincipal();
    if (currentPrincipal &&
      currentPrincipal.user &&
      currentPrincipal.user.UserAccount &&
      currentPrincipal.user.UserAccount.profile &&
      currentPrincipal.user.UserAccount.profile.Notifications) {
      notifications = currentPrincipal.user.UserAccount.profile.Notifications;
    }

    return notifications;
  }

  public getNotificationsForScope(domainId: number, domainKeyId: number, targetMinorKeys: string[], targetScope: string): INotificationDto[] {
    const notificationFilter = (notification: INotificationDto): boolean => {
      let result: boolean = false;
      if (domainId && notification.DomainId == domainId && domainKeyId && notification.DomainKeyId == domainKeyId) {
        result = true;
      }

      if (notification.MinorKey && targetMinorKeys.includes(notification.MinorKey)) {
        result = true;
      }

      return result;
    };

    let allNotifications = this.getAllNotifications();
    let notificationsForScope = _l.filter(allNotifications, (i) => notificationFilter(i));
    if (targetScope) {
      this.addNotifications(notificationsForScope, targetScope);
    }

    return notificationsForScope;
  }

  /**
   * Handles the "userManager.logout" event.
   * @param event
   * @param argument
   */
  private onUserLogout() {
    this.reachMessageService.clear(this.constantsService.NOTIFICATION_SCOPES.APPLICATION.toString());
    this._notificationEntries = [];
  };
}
