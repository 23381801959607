<!-- PrimeNG Table -->
<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="listItems" [responsive]="true"
    responsiveLayout="stack">

    <!-- Caption -->
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
            <div class="p-text-left reach-list-header">Other Memberships</div>
            <div>
                <button pButton pRipple class="p-button-text" icon="fa fa-plus"
                    [command-button]="presentItemEditorCommand" tool-tip="Add a new item"></button>
            </div>
        </div>
    </ng-template>

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>

            <th>Facility</th>
            <th>From</th>
            <th>To</th>
            <th style="width: 8em;"></th>

        </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-item>
        <tr>
            <!-- 
            <td>
                {{item.ApplicationTraining.Facility }}<br />
                {{item.ApplicationTraining.Address }}<br />
                {{item.ApplicationTraining.City }}&nbsp;, {{item.ApplicationTraining.State}}<br />
                {{item.ApplicationTraining.Phone }}
            </td> -->

            <!-- Facility Description -->
            <td>
                <div class="p-d-flex">
                    <div class="p-column-title reach-col-fixed-100">School</div>
                    <div>{{item.ApplicationTraining.Facility | whenEmpty: '-'}}</div>
                </div>
            </td>

            <!-- Start Date -->
            <td>
                <span class="p-column-title">From</span>
                {{item.ApplicationTraining.StartDate | whenEmpty : "-"}}
            </td>

            <!-- End Date -->
            <td>
                <span class="p-column-title">To</span>
                {{item.ApplicationTraining.EndDate | whenEmpty : "-"}}
            </td>

            <!-- Buttons -->
            <td class="p-text-right">
                <span class="p-text-nowrap">

                    <!-- Edit -->
                    <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                        [command-button]="presentItemEditorCommand" [command-parameter]="item"
                        tool-tip="Edit this item"></button>

                    <!-- Delete -->
                    <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                        [command-button]="removeItemCommand" [command-parameter]="item"
                        tool-tip="Delete this item"></button>

                </span>
            </td>

        </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="4">
                <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- DIALOG -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>