import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { Command, DialogSettings, WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, CommandService, ConstantsService, SystemSettingsManagerService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { IEmploymentDto, IEmploymentPositionTitleDto, IEmploymentTypeDto, IEntityEmploymentListDto } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureListService } from '../../licensure-core.module';
import { EmploymentListConfiguration } from '../employmentList/model/employment-list-configuration';
import { EmployeeListEditorComponent, EmployeeListEditorComponentKey } from './editor/employee-list-editor.component';

export const EmployeeListComponentSelector: string = "employee-list";
export const EmployeeListComponentKey: string = "EmployeeList";

@Component({
  selector: EmployeeListComponentSelector,
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent extends WizardStepComponent implements OnInit {

  columnCount: number;
  data: IEmploymentDto[] = [];
  fullEmploymentList: IEmploymentDto[];
  dialogSettings: DialogSettings;

  settings: EmploymentListConfiguration;

  editorCommand: Command;
  okCommand: Command;
  removeCommand: Command;
  createCommand: Command;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    protected validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    protected checkboxContentService: CheckboxContentService,
    protected elementRef: ElementRef,
    protected commandService: CommandService,
    protected utilitiesService: UtilitiesService,
    protected systemSettingsManagerService: SystemSettingsManagerService,
    protected licensureListService: LicensureListService) {
    super(constantsService, validationManagerService, wizardInjector, EmployeeListComponent.name, checkboxContentService, elementRef);

    this.modelToForm();
  }

  protected override modelToForm(): void {
    this.stepForm.addControl("IncludeHistoric", new FormControl(false));

    super.modelToForm();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.settings = new EmploymentListConfiguration(this.systemSettingsManagerService, this.constantsService);

    this.settings.isEmploymentVisible = true;
    if (this.wizard.model?.license?.IsOutOfState != null) {
      this.settings.isEmploymentVisible = !this.wizard.model.license.IsOutOfState;
    }

    this.fullEmploymentList = (this.wizard.model.entityEmployment as IEntityEmploymentListDto).EmploymentList;
    this.filter();

    this.columnCount = 4;
    if (this.settings.employmentTypeEnabled) this.columnCount++;
    if (this.settings.isAddressColumnVisible) this.columnCount++
    if (this.settings.isPositionColumnVisible) this.columnCount++

    this.editorCommand = this.commandService.create(this.canEditorCommandExecute, this.presentEditorCommandExecute);
    this.okCommand = this.commandService.create((model: IEmploymentDto) => model?.EntityId != null, this.okCommandExecuted);
    this.removeCommand = this.commandService.create(this.canRemoveCommandExecute, this.removeCommandExecute);
    this.createCommand = this.commandService.create(() => true, this.presentEditorCommandExecute);

    this.loadLookupLists();
  }

  protected override formToModel(): void {
    this.filter();
  }

  protected loadPositionTitles(): Observable<IEmploymentPositionTitleDto[]> {
    if (this.settings.positionPickListEnabled) { return this.licensureListService.getEmploymentPositionTitles(); }
    return of([]);
  }

  protected loadEmploymentTypes(): Observable<IEmploymentTypeDto[]> {
    if (this.settings.employmentTypeEnabled) { return this.licensureListService.getEmploymentTypes(); }
    return of([]);
  }

  loadLookupLists() {
    forkJoin([
      this.licensureListService.getStates(),
      this.licensureListService.getCounties(),
      this.licensureListService.getAddressLocationTypes(),
      this.loadPositionTitles(),
      this.loadEmploymentTypes(),
      this.licensureListService.getEmploymentSettings(),
      this.licensureListService.getEmploymentSectors(),
    ]).pipe(
      map(([states, counties, locationTypes, positionTitles, employmentTypes, settings, sectors]) => {
        this.settings.possibleStates = states;
        this.settings.possibleCounties = counties;
        this.settings.possibleAddressLocationTypes = locationTypes;
        this.settings.possiblePositionTitles = positionTitles;
        this.settings.possibleEmploymentTypes = employmentTypes;
        this.settings.possibleEmploymentSettings = settings;
        this.settings.possibleEmploymentSectors = sectors;
      })
    ).subscribe();
  }

  filter(): void {
    const today = new Date();
    this.data = this.fullEmploymentList.filter(item => this.stepForm.get("IncludeHistoric").value ? true : (!item.EndDate) || (new Date(item.EndDate) > today));
  }

  protected canRemoveCommandExecute = (employmentItem: IEmploymentDto): boolean => {
    return employmentItem.Id == 0;
  }

  protected removeCommandExecute = (employmentItem: IEmploymentDto) => {
    if (!employmentItem.LocalId) return;

    const index = this.fullEmploymentList.findIndex(x => x.LocalId === employmentItem.LocalId);
    if (index !== -1) this.fullEmploymentList.splice(index, 1);

    this.filter();
  }

  protected canEditorCommandExecute = (employmentItem: IEmploymentDto): boolean => {
    return employmentItem?.EmploymentPositionTitle?.IsEditableOnline && (!employmentItem?.EndDate || employmentItem.IsDirty);
  }

  protected presentEditorCommandExecute = (employmentItem: IEmploymentDto) => {
    this.dialogSettings = new DialogSettings(null
      , ReachScenarios.Default
      , EmployeeListEditorComponent
      , EmployeeListEditorComponentKey
      , employmentItem ? `Employee - ${employmentItem.EntityName}` : "Employee - {new}"
      , employmentItem ?? this.utilitiesService.withLocalId({ IsNew: true, Id: 0, EmployerLicenseId: this.wizard.model.license.Id, EmployerName: this.wizard.model.entity.LastName, Addresses: [] } as IEmploymentDto)
      , false
      , false
      , true);

    this.dialogSettings.okCommand = this.okCommand;
    this.dialogSettings.initializationData.settings = this.settings;
    this.dialogSettings.isOpen = true;
  }

  protected okCommandExecuted = (employmentDto: IEmploymentDto) => {
    this.utilitiesService.addOrReplace(this.fullEmploymentList, employmentDto);
    employmentDto.IsDirty = true;
    this.filter();
  }

}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, EmployeeListComponentKey, EmployeeListComponent, EmployeeListComponentSelector);
