import { Component, Inject, Input, OnChanges } from '@angular/core';
import { IDomainChecklistDtoHost, IDomainChecklistItemDto } from 'src/app/coreShared/core-shared.module';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../../index-services';

@Component({
  selector: 'checklist-item-list',
  templateUrl: './checklist-item-list.component.html',
  styleUrls: ['./checklist-item-list.component.scss']
})
export class ChecklistItemListComponent implements OnChanges {

  @Input() domainChecklistHost: IDomainChecklistDtoHost;
  @Input() showChecklistItemList: boolean = true;
  @Input() headerText: string = "Checklist of Requirements";
  @Input() headerTextSecondary: string = null;

  public filteredChecklistItems: IDomainChecklistItemDto[] = [];
  public showUploadColumn: boolean;
  public columnSpan: number;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService) {
  }

  public ngOnChanges(): void {
    if (this.domainChecklistHost?.DomainChecklist) this.filteredChecklistItems = this.domainChecklistHost.DomainChecklist.filter(item => !item.ChecklistItemTemplate.IsExcludedFromOnlineList);

    this.showUploadColumn = this.filteredChecklistItems.find(cli => cli.ChecklistItemTemplate.AllowOnlineUpload && !this.CompleteWithoutUpload(cli)) != null;
    if (this.showUploadColumn && !(this.domainChecklistHost as any).Documents) { // If an item in the list claims to support upload but the domainChecklistHost is not an IFileUploadDtoHost, give a message but don't crash.
      console.log(`FEATURE DISABLED: An item in "${this.headerText}" is configured to support FileUpload, but the domainChecklistHost is not an IFileUploadDtoHost.`);
      this.showUploadColumn = false;
    }

    this.columnSpan = this.showUploadColumn ? 5 : 4;
  }

  protected CompleteWithoutUpload(cli: IDomainChecklistItemDto): boolean {
    var isCompleted: boolean = cli.Status.AspectStatuses.find(aspectStatus => aspectStatus.AspectStatusId == this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.COMPLETED) != null;
    var noFileUpload = cli.UploadCorrespondenceId == null;
    return isCompleted && noFileUpload;
  }

}
