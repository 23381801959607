<div *ngIf="isVisible">

    <!-- Section Heading -->
    <div class="reach-search-result-detail-section-header">
        <span>Category Of Licensure</span>
    </div>

    <!-- Summary Text -->
    <page-summary-text [majorKey]="pageSummaryMajorKey" [minorKey]="pageSummaryMinorKey"></page-summary-text>

    <p>{{keywords}}</p>

</div>