<page-summary-text majorKey="Shared.Content" minorKey="Panel.Addresses"></page-summary-text>

<!-- Panel -->
<p-panel [toggleable]="false" styleClass="reach-landing-panel reach-landing-detail-panel">

  <!-- Buttons -->
  <ng-template pTemplate="icons">

    <!-- Edit -->
    <button *ngIf="addressEditable" pButton pRipple class="p-button-text" icon="fa fa-pencil" tool-tip="Edit this item"
      [command-button]="presentItemEditorCommand"></button>

  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <div class="p-d-flex p-jc-between">
      <span class="p-text-left reach-list-header">Addresses</span>
    </div>
  </ng-template>

  <div *ngIf="isOrganization">

    <!-- Org Physical -->
    <div class="p-field p-col-12 p-sm-6">
      <label>Physical</label><br>
      <span *ngIf="orgPhysicalAddress" [innerHtml]="orgPhysicalAddress | displayAddress"></span>
      <span *ngIf="!orgPhysicalAddress">No physical address on file.</span>
    </div>

    <!-- Org Mailing -->
    <div class="p-field p-col-12 p-sm-6">
      <label>Mailing</label><br>
      <span *ngIf="orgMailingAddress" [innerHtml]="orgMailingAddress | displayAddress"></span>
      <span *ngIf="!orgMailingAddress">Same as physical address.</span>
    </div>

  </div>

  <!-- MB style content -->
  <div *ngIf="!isOrganization && mailingAndBusinessMode">

    <!-- Mailing -->
    <div class="p-field p-col-12 p-sm-6">
      <label>Mailing</label><br>
      <span [innerHtml]="mailingAddress | displayAddress"></span>
    </div>

    <!-- Business -->
    <div class="p-field p-col-12 p-sm-6">
      <label>Business</label><br>
      <span [innerHtml]="businessAddress | displayAddress" *ngIf="!hasNoBusinessAddress"></span>
      <span *ngIf="hasNoBusinessAddress">I am not currently in the workforce related to my practice.</span>
    </div>

  </div>

  <!-- DBP style content -->
  <div *ngIf="!isOrganization && dbpMode">

    <!-- Designated -->
    <div class="p-field p-col-12 p-sm-4">
      <label>Designated</label><br>
      <span [innerHtml]="dAddress | displayAddress"></span>
    </div>

    <!-- Business -->
    <div class="p-field p-col-12 p-sm-4">
      <label>Business</label><br>
      <span *ngIf="bAddress" [innerHtml]="bAddress | displayAddress"></span>
      <span *ngIf="hasNoBusinessAddress">I am not currently in the workforce related to my practice.</span>
      <span *ngIf="dAddress && dAddress.AddressLocationType.Id == constantsService.ADDRESS_LOCATION_TYPES.BUSINESS">Same
        as Designated Address</span>
    </div>

    <!-- Private -->
    <div class="p-field p-col-12 p-sm-4">
      <label>Private</label><br>
      <span *ngIf="pAddress" [innerHtml]="pAddress | displayAddress"></span>
      <span *ngIf="dAddress && dAddress.IsMailing == true">Not Applicable</span>
    </div>

  </div>

  <!-- Address-List style Content -->
  <div *ngIf="!isOrganization && !mailingAndBusinessMode && !dbpMode">
    <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="filteredAddressCollection" [responsive]="true"
      responsiveLayout="stack">

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>Location</th>
          <th>Address</th>
          <th class="p-text-center">Public</th>
          <th class="p-text-center">Mailing</th>
        </tr>
      </ng-template>

      <!-- Rows -->
      <ng-template pTemplate="body" let-item>
        <tr>

          <!-- Location -->
          <td>
            <div class="p-d-flex">
              <div class="p-column-title reach-col-fixed-100">Location</div>
              <div>{{item.AddressLocationType.Description | whenEmpty: '-'}}</div>
            </div>
          </td>

          <!-- Address -->
          <td>
            <div class="p-d-flex">
              <div class="p-column-title reach-col-fixed-100">Address</div>
              <span [innerHtml]="item | displayAddress | whenEmpty: '-'"></span>
            </div>
          </td>

          <!-- IsPublic (normal) -->
          <td class="p-d-none reach-d-md-table-cell p-text-center">
            <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
          </td>

          <!-- IsPublic (mobile) -->
          <td class="p-d-md-none p-sm-text-center">
            <div class="p-d-flex">
              <div class="p-column-title reach-col-fixed-100">Public</div>
              <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
            </div>
          </td>

          <!-- Mailing -->
          <td class="p-d-none reach-d-md-table-cell p-text-center">
            <span class="p-column-title">Mailing</span>
            <span [innerHtml]="item.IsMailing | boolToCheckmarkGlyph"></span>
          </td>

          <!-- IsMailing (mobile) -->
          <td class="p-d-md-none p-sm-text-center">
            <div class="p-d-flex">
              <div class="p-column-title reach-col-fixed-100">Mailing</div>
              <span [innerHtml]="item.IsMailing | boolToCheckmarkGlyph"></span>
            </div>
          </td>

        </tr>

      </ng-template>

      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4">
            <span class="reach-text-muted"><em>No entries.</em></span>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>

</p-panel>

<!-- IMPORTANT -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
