import { IWebTransactionNotificationDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebTransactionNotificationDto extends BaseDto implements IWebTransactionNotificationDto {
	TransactionId: number;
	BillerId: string;
	BillerProductCode: string;
	PaymentMethod: string;
	PaymentChannel: string;
	EventDate: Date;
	Description: string;
	PayerFirstName: string;
	PayerLastName: string;
	PayerAddress1: string;
	PayerAddress2: string;
	PayerCity: string;
	PayerState: string;
	PayerZip: string;
	PayerCountry: string;
	PayerPhone: string;
	PayerEmail: string;
	PayerFullName: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
