import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MessageService } from 'primeng/api';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './constants-provider.service';
import { MessageSeverities } from '@coreModels/message-severities';
import { ObservableMap } from '@coreModels/observable-map';
import { ReachMessage } from '@coreModels/reach-message';
import { ReachMessageClickedEventArgs } from '@coreModels/reach-message-clicked-event';

/**
 * Data service for routing messages (validation errors, notifications, ...) from messages sources (api calls, etc.) to
 * message targets (validation summary control, notification summary, etc.).
 */
@Injectable({
  providedIn: 'root'
})
export class ReachMessageService {
  private displayToggleState: ObservableMap<boolean> = new ObservableMap<boolean>((): boolean => { return false; });
  private messageMap = new ObservableMap<ReachMessage[]>((): ReachMessage[] => { return []; });
  private unmappedKeys: string[] = []; // Keys that are not added/removed from the messageMap (e.g., toaster keys managed by the PrimeNg MessageService).

  private _messageClicked$: Subject<ReachMessageClickedEventArgs> = new Subject<ReachMessageClickedEventArgs>();
  public messageClicked$ = this._messageClicked$.asObservable();

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    public messageService: MessageService
  ) {
    this.unmappedKeys.push(this.constantsService.VALIDATION_ERROR_SCOPES.TOAST);
  }

  /**
   * Gets the ReachMessage array associated with the specified key.
   * @param key the target Key.
   * @returns the target message array.
   */
  public get(key: string): ReachMessage[] {
    return this.messageMap.get(key);
  }

  /**
   * Gets the Observable<ReachMessage[]> associated with the specified key. The observable event fires
   * whenever the message array for the target key changes.
   * @param key the target Key.
   * @returns the Observable<ReachMessage[]> for the target key.
   */
  public get$(key: string): Observable<ReachMessage[]> {
    return this.messageMap.get$(key);
  }

  /**
   * Adds the specified ReachMessage to the message map/pool.
   * @param message the new ReachMessage.
   */
  public add(message: ReachMessage) {
      this.addAll([message]);
  }

  /**
   * Adds the specified array of ReachMessages to the message map/pool.
   * @param messages the array of new ReachMessages.
   */
  public addAll(messages: ReachMessage[]) {
    // Update PrimeNg MessageService for p-toast Toaster and/or any component instances listening to the PrimeNg MessageService (e.g., p-messages).
    this.messageService.addAll(messages);

    // Update the ObservableMap for any components subscribing to its observables (e.g., p-messages, toggle control...).
    if (messages && messages.length > 0) {
      let grouped = this.groupByKey(messages);
        for (let [key, value] of Object.entries(grouped)) {
          if (!this.unmappedKeys.find(x => x === key)) {
            //this.messageMap.set(key, value as ReachMessage[]);
            this.messageMap.merge(key, value as ReachMessage[]);
          }
        }
    }
  }

  /**
   * Adds a new ReachMessage to the pool with the specified settings.
   * @param severity
   * @param summary
   * @param detail
   * @param key
   * @param life
   * @param sticky
   * @param closable
   * @param data
   * @param id
   */
  public addMessage(severity?: MessageSeverities, summary?: string, detail?: string, key?: string, life?: number, sticky?: boolean, closable?: boolean, data?: any, id?: any) {
      let msg = new ReachMessage(severity, summary, detail, key, life, sticky, closable, data, id);
      this.add(msg);
  }

  /**
   * Replaces the messages associated with the specified Key with the updated array.
   * @param key
   * @param updatedMessages
   */
  public replaceMessages(key: string, updatedMessages: ReachMessage[]) {
      this.messageMap.set(key, updatedMessages);
  }

  /**
   * Clears/initializes array of ReachMessages associated with the specified key.
   * @param key the Key.
   */
  public clear(key?: string): void {
      this.messageService.clear(key);
      this.messageMap.clear(key);
      this.displayToggleState.clear(key);
  }

  /**
   * Gets the current display/toggle state for the specified message sink.
   * @param key the Key of the target message sink.
   * @returns true if the specified message sink is display-enabled.
   */
  public getToggleState(key: string) {
    return this.displayToggleState.get(key);
  }

  /**
   * Gets the current display/toggle state for the specified message sink.
   * @param key the Key of the target message sink.
   * @returns the Observable<boolean> that indicates the display-enabled state of the specified message sink.
   */
  public getToggleState$(key: string) {
    return this.displayToggleState.get$(key);
  }

  /**
   * Sets the display-enabled toggle state of the specified message sink.
   * @param key the Key of the target message sink.
   * @param value the display-enabled toggle state of the specified message sink.
   */
  public setToggleState(key: string, value: boolean): void {
      this.displayToggleState.set(key, value);
  }

  /**
 * Raises the message clicked event.
 */
  public raiseMessageClicked(message: ReachMessage, messageScope: string) {
    this._messageClicked$.next(new ReachMessageClickedEventArgs(message, messageScope));
  }

  /**
   * Groups the input array of ReachMessages by ReachMessage.key.
   * @param flatMessages the flat ReachMessage[] with one or more values or the key represented.
   * @returns an object with properties representing the various key values of the input ReachMessage[] and values
   * representing the ReachMessage[] for the respective key value.
   */
  private groupByKey = (flatMessages: ReachMessage[]) => {
      return this.groupBy(flatMessages, 'key');
  }

  /**
   * Groups the input array of ReachMessages by the specified property.
   * @param flatMessages the flat ReachMessage[] with one or more values or the target property represented.
   * @param targetProperty the property by which the input messages should be grouped.
   * @returns an object with properties representing the various key values of the input ReachMessage[] and values
   * representing the ReachMessage[] for the respective key value.
   */
  private groupBy = (flatMessages: ReachMessage[], targetProperty: string) => {
    return flatMessages.reduce((rv, x) => {
      (rv[x[targetProperty]] = rv[x[targetProperty]] || []).push(x);
      return rv;
    }, {});
  };
}
