import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { CanRemoveItemFromCartRequestDto, CanRemoveItemFromCartResponseDto, CanSubmitCartRequestDto, CanSubmitCartResponseDto, IUserAccountDto, IWebCartDto, IWebCartItemDto, WebCartDto, WebCartItemDto } from '@coreShared/core-shared.module';
import { Observable } from "rxjs";
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends ReachHttpClientService {
  public uri: string = null;
  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/webcart/`;
  }

  public create(user: IUserAccountDto): Observable<WebCartDto> {
    if (!user) {
      this.argumentExceptionService.create("user").log();
    }

    return this.post<WebCartDto>(`${this.uri}initialize`, user);
  }

  public addItem(cartItem: IWebCartItemDto): Observable<WebCartDto> {
    if (!cartItem) {
      this.argumentExceptionService.create("cartItem").log();
    }
    return this.post<WebCartDto>(`${this.uri}additem`, cartItem);
  }

  public canRemoveItem(request: CanRemoveItemFromCartRequestDto): Observable<CanRemoveItemFromCartResponseDto> {
    if (!request) {
      this.argumentExceptionService.create("request").log();
    }
    return this.post<CanRemoveItemFromCartResponseDto>(`${this.uri}CanRemoveItemFromCart`, request);
  }

  public canSubmitCart(request: CanSubmitCartRequestDto): Observable<CanSubmitCartResponseDto> {
    if (!request) {
      this.argumentExceptionService.create("cart").log();
    }
    return this.post<CanSubmitCartResponseDto>(`${this.uri}CanSubmitCart`, request);
  }

  public removeItem(cartItem: IWebCartItemDto): Observable<WebCartDto> {
    if (!cartItem) {
      this.argumentExceptionService.create("cartItem").log();
    }
    return this.post<WebCartDto>(`${this.uri}removeitem`, cartItem);
  }
}
