import { IEntityAssociateTypeDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class EntityAssociateTypeDto extends LookupDto implements IEntityAssociateTypeDto {
	CardinalityTypeId: number;
	EntityGroupId: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
