import { Inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { AnonymousGuard } from 'src/app/core/core.module';
import { ReachScenarios } from 'src/app/core/index-constants';
import { OnlineServiceRegistry, RouteInfoRegistry } from 'src/app/core/index-models';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService, OnlineServiceLinkManagerService, OnlineServiceMenuService, SystemSettingsManagerService } from 'src/app/core/index-services';
import { WebLinkInfoDto } from 'src/app/coreShared';
import { LicensureOnlineServiceProcessTypeKeys, SearchComponentKeys } from '../../licensure-core.module';

@Injectable({
  providedIn: 'root'
})
export class CeSponsorSearchFeatureConfigurationService {

  private isInitialized = false;
  private ceSponsorSearchConstantsService: ICeSponsorSearchConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private dynamicRoutesService: DynamicRoutesService,
    private onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) { }

  public initialize() {
    if (this.isInitialized) return;

    this.ceSponsorSearchConstantsService = this.constantsService as ICeSponsorSearchConstantsService;

    this.configureConstants();
    this.configureDecorators();
    this.configureRoutes();
    // this.searchConfig = this.getSearchConfig();

    this.isInitialized = true;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CeSponsorSearch).onlineServicePath,
        canActivate: [AnonymousGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.CeSponsorSearch, SearchComponentKeys.CeSponsorLazy)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, CeSponsorSearchConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, CeSponsorSearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.ONLINE_SERVICE_SORT, CeSponsorSearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
  }

  private configureDecorators() {
    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Search for a CE Sponsor";
    linkInfo.DisplayOrder = this.systemSettingsManagerService.asInt(this.ceSponsorSearchConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR_SEARCH.ONLINE_SERVICE_MENU_GROUP_ORDER)
    linkInfo.ProcessTypeId = LicensureOnlineServiceProcessTypeKeys.CeSponsorSearch;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];
    let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo, false);

    this.onlineServiceMenuService.addOnlineServiceMenuItemFromOnlineLink(
      onlineLink,
      this.ceSponsorSearchConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR_SEARCH.ONLINE_SERVICE_MENU_GROUP,
      this.ceSponsorSearchConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR_SEARCH.ONLINE_SERVICE_MENU_GROUP_ORDER,
      this.ceSponsorSearchConstantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR_SEARCH.ENABLED,
      true
    );
  }
}

export interface ICeSponsorSearchConstantsService extends CeSponsorSearchConstantsService {
}
export class CeSponsorSearchConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      CE_SPONSOR_SEARCH: {
        MAJOR_KEY: "CeSponsorSearch"
      }
    },

    SYSTEM_SETTING_KEYS: {
      CE_SPONSOR_SEARCH: {
        ONLINE_SERVICE_MENU_GROUP: "Feature.CeSponsorSearch.OnlineServiceMenu.Group",
        ONLINE_SERVICE_MENU_GROUP_ORDER: "Feature.CeSponsorSearch.OnlineServiceMenu.GroupOrder",
        CRITERIA_COMPONENT_NAME: "Feature.Online.CeSponsorSearch.Criteria.ComponentName",
        ENABLED: "Feature.Online.CeSponsorSearch.Enabled",

        SPONSOR_LABEL: "Feature.Online.CeSponsorSearch.SponsorLabel",
        SPONSORSHIP_LABEL: "Feature.Online.CeSponsorSearch.SponsorshipLabel",
        SEARCH_RESULTS_COMPONENT_NAME: "Feature.Online.CeSponsorSearch.Results.ComponentName",
        RESULTS_DETAIL_ITEMS_COMPONENT_NAMES: "Feature.Online.CeSponsorSearch.Results.Detail.Items.ComponentNames",
      },
    },

    ONLINE_SERVICE_SORT: {
      CE_SPONSOR_SEARCH: 2001
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), CeSponsorSearchConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), CeSponsorSearchConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  ONLINE_SERVICE_SORT = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).ONLINE_SERVICE_SORT), CeSponsorSearchConstantsService.Mergers.ONLINE_SERVICE_SORT);
}
