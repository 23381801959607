import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { ReachModelAwareComponent } from 'src/app/core/core.module';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, UtilitiesService } from 'src/app/core/index-services';
import { BagItemDto, IBagItemDto } from 'src/app/coreShared/core-shared.module';
import { ISupervisionPlanDto, ISupervisionVerificationDto } from 'src/app/licensureShared/licensure-shared.module';

@Component({
    selector: 'supervision-detailed-description',
    templateUrl: './supervision-detailed-description.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SupervisionDetailedDescriptionComponent),
            multi: true
        }
    ]
})
export class SupervisionDetailedDescriptionComponent extends ReachModelAwareComponent implements OnInit, OnChanges {
    private _subject: ISupervisionPlanDto | ISupervisionVerificationDto;
    public contentForm: FormGroup;
    private _isReadOnly: boolean;

    @Input()
    public set subject(subject: ISupervisionPlanDto | ISupervisionVerificationDto) {
        this._subject = subject;
    }
    public get subject(): ISupervisionPlanDto | ISupervisionVerificationDto {
        return this._subject;
    }

    @Input()
    public set isReadOnly(readOnly: boolean) {
        this._isReadOnly = readOnly;
    }

    public get isReadOnly(): boolean {
        return this._isReadOnly;
    }

    @Output()
    bagItemsChanged: EventEmitter<IBagItemDto> = new EventEmitter<IBagItemDto>();

    constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
        , private controlContainer: ControlContainer
        , private utilitiesService: UtilitiesService
        , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector) {
        super(reachModelConfigurationInjector);
        this.modelToForm();
    }

    protected modelToForm() {
        this.contentForm = new FormGroup({});
        let parent = this.controlContainer.control as FormGroup;
        parent.addControl('SupervisionDetailedDescription', this.contentForm);
    }

    protected formToModel(): void {
        this.subject.NarrativeOfPopulation = this.contentForm.get('NarrativeOfPopulation').value;
        this.subject.NarrativeOfModalities = this.contentForm.get('NarrativeOfModalities').value;
        this.subject.NarrativeOfProcess = this.contentForm.get('NarrativeOfProcess').value;
        this.updateBagItems();
    }

    ngOnInit(): void { }

    protected updateBagItems() {
        const bagItem = new BagItemDto();
        if (this.subject != null) {
            bagItem.Key = "detailed-description";
            bagItem.BagItemType = this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT;
            bagItem.Values = [];

            bagItem.Values.push(`Client population and the range of presenting issues/diagnoses ~|~ ${this.subject.NarrativeOfPopulation}`);
            bagItem.Values.push(`Clinical modalities commonly utilized ~|~ ${this.subject.NarrativeOfModalities}`);
            bagItem.Values.push(`Diagnostic process ~|~ ${this.subject.NarrativeOfProcess}`);
        }

        this.bagItemsChanged.emit(bagItem);
    }

    ngOnChanges(): void {
        if (!this.subject.IsNarrativeOnFile) {
            this.contentForm.addControl('NarrativeOfPopulation', new FormControl(this.subject.NarrativeOfPopulation, [Validators.required, Validators.maxLength(2000)]));
            this.contentForm.addControl('NarrativeOfModalities', new FormControl(this.subject.NarrativeOfModalities, [Validators.required, Validators.maxLength(2000)]));
            this.contentForm.addControl('NarrativeOfProcess', new FormControl(this.subject.NarrativeOfProcess, [Validators.required, Validators.maxLength(2000)]));
        } else {
            this.contentForm.addControl('NarrativeOfPopulation', new FormControl(this.subject.NarrativeOfPopulation, [Validators.maxLength(2000)]));
            this.contentForm.addControl('NarrativeOfModalities', new FormControl(this.subject.NarrativeOfModalities, [Validators.maxLength(2000)]));
            this.contentForm.addControl('NarrativeOfProcess', new FormControl(this.subject.NarrativeOfProcess, [Validators.maxLength(2000)]));
        }

        this.utilitiesService.enableDisable(this.contentForm, (this.subject != null));
        this.contentForm.valueChanges.subscribe(value => {
            this.formToModel();
        });
        this.updateBagItems();
    }

    onChange: any = () => { };
    onTouched: any = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
        this.contentForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void { this.onTouched = fn; }
    writeValue(value): void { }
    setDisabledState?(isDisabled: boolean): void { isDisabled ? this.contentForm.disable() : this.contentForm.enable(); }
}


