import { forkJoin, from, Observable, of, Subject } from "rxjs"
import { switchMap } from "rxjs/operators"

import * as _l from 'lodash-es';

/**
 * Executes all subscriber observer functions, then executes the specified wizard navigation behavior.
 */
export class WizardNavigationHandler<TArgType> {
  private wizardNavBehavior = (eventArgs: TArgType): Observable<TArgType> => { return of(eventArgs); }
  private _onNav$: Subject<TArgType> = new Subject<TArgType>();
  public onNav$ = this._onNav$.asObservable();
  public canBackExecute: () => boolean;
  public canForwardExecute: () => boolean;

  constructor(wizardNavBehavior: (eventArgs: TArgType) => Observable<TArgType>) {
    if (wizardNavBehavior) {
      this.wizardNavBehavior = wizardNavBehavior;
    }

    this.onNav$.subscribe(this.wizardNavBehavior);

    this.canBackExecute = this.defaultCanBackExecute;
    this.canForwardExecute = this.defaultCanForwardExecute;
  }

  public execute(eventArgs: TArgType): Observable<TArgType> {
    let response = this._onNav$.pipe(switchMap(r => {
      return this.wizardNavBehavior(eventArgs);
    }));

    this._onNav$.next(eventArgs);

    return response;
  }

  public defaultCanBackExecute: () => boolean = () => {
    return true;
  }

  public defaultCanForwardExecute: () => boolean = () => {
    return true;
  }
}

