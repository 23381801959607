import {  } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IEmploymentTypeDto } from './licensure-shared-dto';

export class EmploymentTypeDto extends BaseDto implements IEmploymentTypeDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
