import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthorizationConfigurationProviderService } from '../authorization-configuration-provider.service';
import { BootstrapperService } from '../bootstrapper.service';
import { DynamicContentConfigurationProviderService } from '../dynamic-content-configuration-provider.service';
import { SystemSettingsManagerService } from '../system-settings-manager.service';
import { UtilitiesService } from '../utilities.service';
import { BusyManagerService } from './../busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './../constants-provider.service';
import { DynamicContentManagerService } from './../dynamic-content-manager.service';
import { ReachApplicationService } from './../reach-application.service';
import { RouteConfiguration, RouteConfigurationProviderService } from './../route-configuration-provider.service';
import { UserManagerService } from './../user-manager.service';
import { RouteResolverService } from './route-resolver.service';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '../configuration/index-configuration-services';

@Injectable({ providedIn: 'root' })
export class SurveyRedirectRouteResolverService extends RouteResolverService<any> implements Resolve<any> {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private utilitiesService: UtilitiesService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
  ) {
    super(constantsService, userManagerService, busyManagerService, bootstrapperService, dynamicContentManagerService, reachApplicationService, router, routeConfigurationProviderService, dynamicContentConfigurationProviderService, authorizationConfigurationProviderService);
  }

  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false
      , this.dynamicContentConfigurationProviderService.getConfigurationData(false, null)
      , this.authorizationConfigurationProviderService.getConfigurationData(true));
  }

  override innerResolve(route: any, state: any) {
    super.innerResolve(route, state);

    const callbackUri: string = encodeURIComponent(`${this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.SURVEY_CALLBACK_URI)}action=${route.queryParams['action'] ?? "Landing"}&renewalId=${route.queryParams['renewalId'] ?? ""}`);
    const surveyUrl: string = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.SURVEY_URI);

    let formattedUrl = `${surveyUrl}${route.queryParams['mdhSurveyPath'] ?? ""}&license=${route.queryParams['licenseNumber'] ?? ""}&ext_redirect_link=${callbackUri}`;
    formattedUrl = this.defaultProviderConfigurationService.surveyRedirectRouteResolverServiceConfiguration.addParamsToRedirectUri(formattedUrl);
    
    window.location.href = formattedUrl;
  }
}
