import { IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from 'src/app/coreShared';

export interface IPreceptorVerificationRequestDto extends IValidatableDto
{
    LicenseNumber: number;
}
export class PreceptorVerificationRequestDto extends ValidatableDto implements IPreceptorVerificationRequestDto
{
    LicenseNumber: number;
}
export interface IPreceptorVerificationResponseDto extends IValidatableDto
{
    LicenseId: number;
    PreceptorName: string;
    PreceptorPeriod: string;
    LicenseNumber: number;
}
export class PreceptorVerificationResponseDto extends ValidatableDto implements IPreceptorVerificationResponseDto
{
    LicenseId: number;
    PreceptorName: string;
    PreceptorPeriod: string;
    LicenseNumber: number;
}