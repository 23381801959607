import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

import { IHttpResponseMessage, IChallengeInfoDto, IDomainAuditDto } from '@coreShared/core-shared.module';

@Injectable({
  providedIn: 'root'
})
export class DomainAuditService extends ReachHttpClientService {
  uri: string;

  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = `${this.defaultProviderConfigurationService.apiRootUri}/domainAudit`;
  }

  public getById(id) {
    let url = `${this.uri}/${id}`;
    return this.get(url).pipe(map((domainAudit) => {
      return domainAudit;
    }));
  }

  public save(domainAudit): Observable<IDomainAuditDto> {
      return this.put(this.uri, domainAudit);
  }
}
