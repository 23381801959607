import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { EntityInfoDto } from '@coreShared/dto-gen/entity-info-dto';
import { IApplicationInfoDto,
ILicenseInfoDto,
ILicensureEntityInfoDto } from './licensure-shared-dto';

export class LicensureEntityInfoDto extends EntityInfoDto implements IBaseDto, ILicensureEntityInfoDto {
	ActionsCount?: number;
	CeSponsorId?: number;
	SupervisorId?: number;
	EducationProgramApplicationId?: number;
	EducationProgramId?: number;
	StrongActionId?: number;
	StrongApplication: IApplicationInfoDto;
	StrongLicense: ILicenseInfoDto;
	StrongComplaint: any;

}
