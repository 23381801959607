<div class="p-fluid" [formGroup]="stepForm">
  <div class="p-grid p-formgrid">

    <!-- Checkboxes -->
    <div class="p-col-12">
      <checkbox-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey"></checkbox-content-items>
    </div>
    
    <!-- RadioContentItems -->
    <radio-content-items [majorKey]="majorKey" [minorKey]="stepMinorKey" [bag]="wizard.selectedStep.bag"></radio-content-items>

    <div class="p-col-12 p-mt-4" *ngIf="includeSignature">
      <page-summary-text [majorKey]="wizard.dynamicContentConfiguration.majorKey"
        [minorKey]="stepMinorKey + '.SignatureSummary'"></page-summary-text>
    </div>
    
    <div class="p-field p-col-12 p-sm-6" *ngIf="includeSignature">
      <label for="signature">{{friendlyNames.Signature}}</label>
      <input id="signature" type="text" formControlName="Signature" pInputText>
    </div>

    <div class="p-field p-col-12 p-sm-6 p-md-3 p-lg-2" *ngIf="includeSignature">
      <label for="signature-date">{{friendlyNames.SignatureDate}}</label>
      <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="signature-date"
        formControlName="SignatureDate" [showIcon]="false" yearNavigator="true" monthNavigator="true" [defaultDate]=""
        appendTo="body" baseZIndex="1">
      </p-calendar>
    </div>
  </div>
</div>
