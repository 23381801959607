import { IEntityAddressSearchResultItemDto } from './core-shared-dto';
import { SearchResultItemDto } from './search-result-item-dto';

export class EntityAddressSearchResultItemDto extends SearchResultItemDto implements IEntityAddressSearchResultItemDto {
	Id: number;
	EntityId: number;
	FunctionNumber: string;
	IsCurrent: boolean;
	IsPublic: boolean;
	IsMailing: boolean;
	Line1: string;
	Line2: string;
	Line3: string;
	City: string;
	State: string;
	Zip: string;
	County: string;
	Country: string;
	AddressType: string;
	AddressTypeId: number;
	AddressLocationType: string;
	CreatedDate: Date;
	CreatedBy: string;
	ModifiedDate: Date;
	ModifiedBy: string;
	AddressFull: string;

}
