import { Injectable, Injector } from '@angular/core';

/**
 * Represents an injectable provider item for the ReachInjectorService.
 */
export class ReachInjectorProviderItem {
    constructor(public provide: string, public useValue) {
    }
}

/**
 * Creates a private Injector based on the specified parent Injector and including the specified additional providers.
 * Sample use case: passing @Input items from a host component to a dynamic child component as DI parameters.
 */
@Injectable()
export class ReachInjectorService {
  private customProviders: ReachInjectorProviderItem[] = [];

  /**
   * Instantiates a new instance of ReachInjectorService based on the specified parent Injector.
   * @param parentInjector
   */
  constructor(private parentInjector: Injector) {}

  /**
   * Returns the dynamic Injector created off the specified parent Injector and the specified list of custom Providers.
   * @returns {}
   */
  public get injector() {
      return this.regenerateDataInjector();
  }

  /**
   * Adds the specified Injector custom Provider.
   * @param provide the token or class to be provided by the Injector.
   * @param the object/value being provided.
   */
  public addItem(provide: string, useValue) {
      this.customProviders.push(new ReachInjectorProviderItem(provide, useValue));
  }

  /**
   * Adds the specified Injector custom Providers.
   * @param item a list of ReachInjectorProviderItems with the custom provider information.
   */
  public addItems(items: ReachInjectorProviderItem[] = []) {
    this.customProviders.push(...items);
  }

  /**
   * Creates and returns a dynamic Injector based on the parent Injector and with the additional custom Providers.
   */
  private regenerateDataInjector() {
    let dynamicInjector: Injector = null;
    if (this.parentInjector && this.customProviders.length > 0) {
      let providerList = [];
      this.customProviders.forEach(i => {
        providerList.push({ provide: i.provide, useValue: i.useValue });
      });
      dynamicInjector = Injector.create({
        providers: providerList,
        parent: this.parentInjector
      });
    }

    return dynamicInjector;
  }
}
