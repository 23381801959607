import {
  IDomainCriminalBackgroundCheckDto,
  IDomainReasonForChangeDtoHost,
  IEntityAddressDto,
  IEntityCommentDto,
  IExtendedPropertyDto
} from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import {
  IContinuingEducationBasicDto,
  ILicenseDto,
  ILicenseStatusDto,
  ILicenseTypeDto,
  IRenewalDto
} from './licensure-shared-dto';

export class LicenseDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ILicenseDto {
  PicName: string;
  KeywordProfessionTypeId?: number;
  LicenseStatusConstants: any;
  FunctionTypeId?: number;
  FunctionNumber: string;
  ProfessionTypeCode: string;
  CommentSourceTypeId?: number;
  IncludeAllEntityComments: boolean;
  Comments: IEntityCommentDto[];
  AddressTypeId: number;
  EntityId?: number;
  LicenseTypeId: string;
  LicenseStatusId: string;
  StatusModifiedBy: string;
  LicenseStatusDate?: Date;
  GrantDate?: Date;
  ExpireDate?: Date;
  IsNotInWorkforce?: boolean;
  LicenseNumber: number;
  City: string;
  FacilityEntityId?: number;
  FacilityName: string;
  Program: string;
  FacilityLicenseeEntityId?: number;
  FacilityLicenseeName: string;
  DoingBusinessAs: string;
  Addresses: IEntityAddressDto[];
  EffectiveDateNext?: Date;
  ExpireDateNext?: Date;
  ExpireDateMonthBefore?: Date;
  CeReportingDateFrom?: Date;
  CeReportingDateTo?: Date;
  CeReportingDatePreviousPeriodFrom?: Date;
  CeReportingDatePreviousPeriodTo?: Date;
  RenewalFeeAmount?: number;
  RenewalLateFeeAmount?: number;
  RenewalFeeAdjustmentAmount?: number;
  RenewalTotalAmountDue?: number;
  AddressMailing: IEntityAddressDto;
  AddressPublic: IEntityAddressDto;
  ContinuingEducation: IContinuingEducationBasicDto[];
  InProcessRenewal: IRenewalDto;
  ActiveRenewal: IRenewalDto;
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  ModifiedBy: string;
  IsLicenseExpired: boolean;
  IsCmeDue: boolean;
  SupervisionCompleteDate?: Date;
  IsSuspendSupervisionDetail?: boolean;
  IsOpiate?: boolean;
  IsOutOfState?: boolean;
  CurrentApplicationNbr?: number;
  LicenseCardInactiveText: string;
  Status: ILicenseStatusDto;
  LicenseType: ILicenseTypeDto;
  LicenseSubtypeId?: number;
  IsCriminalBackgroundCheckRequired?: boolean;
  ReferenceValue: string;
  CriminalBackgroundChecks: IDomainCriminalBackgroundCheckDto[];
  CmeDueDate?: Date;
  ExtendedProperties: IExtendedPropertyDto[];
  DomainInspections: any[];
  RowVersion: number[];
  DomainKeywords: any[];
  ProfessionalInChargeManaged?: boolean
}
