<div class="p-grid p-jc-center" [formGroup]="contentForm">

  <div class="p-col-12 p-sm-10 p-md-8 p-lg-6 p-xl-4">
    <!-- Heading -->
    <div class="reach-page-header">
      Change Your Password
    </div>

    <!-- Summary Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="dynamicContentConfiguration.majorKey" minorKey="ChangePassword.SummaryTextBlock">
      </page-summary-text>
    </div>

    <!-- Body -->
    <div class="p-fluid">

      <!-- Username -->
      <div class="p-field">
        <label for="username">Username</label>
        <span class="p-ml-2">{{onlineUser.UserAccount.UserName}}</span>
      </div>

      <!-- New Password -->
      <div class="p-field">
        <label for="newPassword">New Password<field-help [majorKey]="dynamicContentConfiguration.majorKey"
            minorKey="ChangePassword.Password">
          </field-help></label>
        <div>
          <input id="newPassword" pInputText type="password" formControlName="NewPassword" />
        </div>
      </div>

      <!-- Confirm Password -->
      <div class="p-field">
        <label for="confirmPassword">Confirm Password</label>
        <div>
          <input id="confirmPassword" pInputText type="password" formControlName="ConfirmPassword" />
        </div>
      </div>

      <!-- ReCaptcha-->
      <div class="p-field">
        <reach-captcha formControlName="ReachCaptcha" #reCaptcha></reach-captcha>
      </div>

    </div>

    <!-- Buttons -->
    <div class="reach-bottom-actions-container">
      <div class="p-d-flex p-jc-end">

        <!-- Save -->
        <div class="p-pl-2">
          <button pButton pRipple type="button" label="Save" class="p-button-primary"
            [command-button]="saveCommand"></button>
        </div>

      </div>
    </div>

    <!-- Additional Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="dynamicContentConfiguration.majorKey" minorKey="ChangePassword.AdditionalHelpText">
      </page-summary-text>
    </div>

  </div>

</div>