import { IEntityAddressDto } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { ICeSponsorSearchResultItemDto } from './licensure-shared-dto';

export class CeSponsorSearchResultItemDto extends SearchResultItemDto implements ICeSponsorSearchResultItemDto {
	Id: number;
	ReferenceValue: string;
	EntityId: number;
	LastName: string;
	FirstName: string;
	ContactFirstName: string;
	ContactLastName: string;
	ContactPhone: string;
	ContactEmail: string;
	Status: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	StartDate?: Date;
	EndDate?: Date;
	Address: IEntityAddressDto;
	FullName: string;
	ContactFullName: string;

}
