import { Observable } from "rxjs";
import { ReachModelAwareComponentConfiguration } from "src/app/core/components/reachModelAware/reach-model-aware-component-configuration";
import { ConstantsService, SystemSettingsManagerService } from "src/app/core/index-services";
import { IAddressLocationTypeDto, ICountyDto, ILookupDto, IStateDto } from "src/app/coreShared/core-shared.module";
import { ILicensureConstantsProviderService } from "src/app/licensureCore/services/licensure-constants-provider.service";
import { IEmploymentPositionTitleDto, IEmploymentTypeDto, IEntityEmploymentListDto } from "src/app/licensureShared/licensure-shared.module";

export class EmploymentListConfiguration extends ReachModelAwareComponentConfiguration {

  isEmploymentVisible: boolean = true;
  isReadOnly: boolean = false;
  isEmployerColumnVisible: boolean = true;
  isPositionColumnVisible: boolean = true;
  isAddressColumnVisible: boolean = true;
  isStartDateColumnVisible: boolean = true;
  isEndDateColumnVisible: boolean = true;
  employmentTypeEnabled: boolean = false;
  positionPickListEnabled: boolean = false;
  employerAsFacilityLicenseIdEnabled: boolean = false;
  isAcknowledgmentRequired: boolean = false;
  isSectorRequired: boolean;
  isSettingRequired: boolean;
  isSectorEnabled: boolean;
  isSettingEnabled: boolean;
  employeeAsLicenseIdEnabled: boolean;

  possibleStates: IStateDto[];
  possibleCounties: ICountyDto[];
  possibleAddressLocationTypes: IAddressLocationTypeDto[];
  possiblePositionTitles: IEmploymentPositionTitleDto[];
  possibleEmploymentTypes: IEmploymentTypeDto[];
  possibleEmploymentSettings: ILookupDto[];
  possibleEmploymentSectors: ILookupDto[];

  save: (entityEmploymentList: IEntityEmploymentListDto) => Observable<IEntityEmploymentListDto> = null;

  constructor(private systemSettingsManagerService: SystemSettingsManagerService, private constantsService: ConstantsService) {
    super();

    // Setting based defaults, but can be overridden by the caller
    this.employmentTypeEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.TYPE_ENABLED);
    this.positionPickListEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYMENT_POSITION_PICKLIST_ENABLED);
    this.isPositionColumnVisible = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYMENT_POSITION_COLUMN_ENABLED);
    this.employerAsFacilityLicenseIdEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYER_AS_FACILITY_LICENSE_ID_ENABLED);
    this.isAcknowledgmentRequired = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYMENT_LIST_REQUIRE_ACKNOWLEDGMENT);
    this.isAddressColumnVisible = !this.employerAsFacilityLicenseIdEnabled;
    this.employeeAsLicenseIdEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.EMPLOYEE_AS_LICENSE_ID_ENABLED);

    this.isSectorEnabled = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SECTOR_DROPDOWN_ENABLED);
    this.isSettingEnabled = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SETTING_DROPDOWN_ENABLED);
    this.isSectorRequired = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SECTOR_DROPDOWN_REQUIRED);
    this.isSettingRequired = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SETTING_DROPDOWN_REQUIRED);
  }
}
