import { Type } from '@angular/core';
import { IDomainChecklistDtoHost, IDomainChecklistItemDto, IDomainFormDto, IDomainFormDtoHost, IDomainKeywordDtoHost, IDomainQuestionnaireDtoHost, IFileUploadDtoHost, IInvoiceDto, IOnlineUserDto, IOnlineUserSearchCriteriaDto, IProcessFeeDto, IStepDataDto, IWebServiceViewDto, StepDataDto } from '@coreShared/core-shared.module';
import * as _l from 'lodash-es';
import { IEntityEmploymentListDto } from 'src/app/licensureShared/licensure-shared.module';
import { INavigationActionEventArguments } from './wizard-event-args';

export abstract class WizardModel {
  public isDirty = false;
  public stepComponents: StepComponentItem[] = [];
  public criminalBackgroundCheck = null;
  abstract get ReferenceNumber(): string;
  public doBackExecuted: boolean = false;

  public ChecklistItemsWithDocuments: IDomainChecklistItemDto[] = []; // To allow ChecklistItemsWithDocuments to be added as stepData
  public DomainChecklistDtoHost: IDomainChecklistDtoHost; // A core-accessable means for a WizardModel to identify it's ChecklistDtoHost if it has one.
  public DomainKeywordDtoHost: IDomainKeywordDtoHost;

  constructor(public entity: IOnlineUserDto = null
    , public entityEmployment: IEntityEmploymentListDto = null
    , public onlineUser: IOnlineUserDto = null
    , public onlineUserSearchCriteria: IOnlineUserSearchCriteriaDto = null
    , public fileUploadHost: IFileUploadDtoHost = null
    , public invoice: IInvoiceDto = null
    , public domainFormHost: IDomainFormDtoHost = null
    , public domainQuestionnaireHost: IDomainQuestionnaireDtoHost = null
  ) { }

  public getDomainFormFromHost(): IDomainFormDto {
    return this?.domainFormHost?.DomainForms[0];
  }

  public getDomainFormFromHostByFormId(id: number): IDomainFormDto {
    return this?.domainFormHost?.DomainForms?.find(item => item.Id == id);
  }

  public getDomainQuestionnaireFromHost() {
    return this?.domainQuestionnaireHost?.DomainQuestionnaires.find(item => { return item.Id == this.domainQuestionnaireHost.StrongestQuestionnaire.Id });
  }

  /**
   * Creates a step data object that is later used to create WebServiceHistory.
   * @param eventArguments
   * @param fees
   * @param selectedStep
   * @param errorEntries
   * @returns
   */
  public createStepData(eventArguments: INavigationActionEventArguments, fees: IProcessFeeDto[], selectedStep: IWebServiceViewDto, errorEntries: string[]): IStepDataDto {
    let stepData = new StepDataDto();

    stepData.ChecklistItemsWithDocuments = [];
    this.ChecklistItemsWithDocuments.forEach(element => { stepData.ChecklistItemsWithDocuments.push(element); });
    this.ChecklistItemsWithDocuments = []; // Clear after use.

    stepData.Bag = (selectedStep as any).bag;
    stepData.Entity = (this.entity as any) || null;
    stepData.OnlineUserSearchCriteria = this.onlineUserSearchCriteria || null;
    stepData.Invoice = this.invoice || null;
    stepData.DomainQuestionnaireDto = this.getDomainQuestionnaireFromHost();
    this.assignCbcStepData(stepData);
    this.assignUserAccountStepData(stepData);
    stepData.Fees = fees;
    stepData.Action = eventArguments.action;

    // Remove properties that were put on the WizardStep on the client
    const exclusions: string[] = Object.keys(selectedStep).filter((propertyName: string) => {
      return propertyName[0] == propertyName[0].toLowerCase()
    });

    // bag is added on to the selected step
    const { ...selectedStepWithoutClientProperties } = selectedStep as any;
    exclusions.forEach(ex => {
      delete selectedStepWithoutClientProperties[ex];
    });

    stepData.Step = selectedStepWithoutClientProperties;
    stepData.Errors = errorEntries;

    return stepData;
  }

  /**
 * Custom step data logic surrounding UserAccount.
 * @param stepData
 */
  protected assignUserAccountStepData(stepData: StepDataDto): void {

    // Send a cloned copy of the user account with null password values
    if (this.onlineUser && this.onlineUser.UserAccount) {
      stepData.OnlineUserAccount = _l.clone(this.onlineUser.UserAccount);
      stepData.OnlineUserAccount.UserPassword = "***";
      stepData.OnlineUserAccount.ConfirmUserPassword = "***";
    }
  }

  /**
   * Custom step data logic surrounding CBC. TODO: Is this necessary?
   * @param stepData
   */
  protected assignCbcStepData(stepData: StepDataDto): void {
    if (this.criminalBackgroundCheck) {
      stepData.CriminalBackgroundCheckId = this.criminalBackgroundCheck?.Id || null;
    } else {
      (stepData as any).criminalBackgroundCheck = null;
    }
  }

  /**
  * Updates the stored values of model properties.
  */
  protected synchronize(): void {
  }

}

export class StepComponentItem {
  constructor(public component: Type<any>, public data: any = null) { }
}




