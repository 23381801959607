import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BusyManagerService, CheckboxContentService, Command, CommandService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DialogSettings, ListService, ReachScenarios, registerDynamicComponent, UtilitiesService, ValidationManagerService, WIZARD_INJECTOR_TOKEN, WizardInjector, WizardStepComponent } from '@core/core.module';
import { LicensureListService } from '@licensureCore/services/licensure-list.service';
import { IConvictionTypeDto, IEntityConvictionDto, IProfessionalProfileDto } from '@licensureShared/licensure-shared.module';
import * as _ from 'lodash-es';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, from, of } from 'rxjs';
import { ICountryDto, IStateDto } from 'src/app/coreShared/core-shared.module';
import { ProfessionalProfileService } from '../../services/professional-profile.service';
import { ProfileCriminalConvictionsEditorComponent } from './editor/profile-criminal-convictions-editor.component';

@Component({
  selector: 'profile-criminal-convictions',
  templateUrl: './profile-criminal-convictions.component.html',
  styleUrls: ['./profile-criminal-convictions.component.scss']
})
export class ProfileCriminalConvictionsComponent extends WizardStepComponent implements OnInit {

  readonly bagKey: string = "has_no_recent_convictions";
  enableHasNoRecentConvictionsRadioButtons: boolean;
  showEntityConvictions: boolean;
  noConvictionsMessage: string = "I certify that I have had no convictions on or after July 1, 2013.";
  yesConvictionsMessage: string = `I have been convicted of a felony or gross misdemeanor occurring on or after July 1, 2013 in any state or jurisdiction.`;
  presentEditorCommand: Command;
  removeItemCommand: Command;
  dialogSettings: DialogSettings = null;
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleConvictionTypes: IConvictionTypeDto[];
  professionalProfile: IProfessionalProfileDto;

  constructor(checkboxContentService: CheckboxContentService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , private listService: ListService
    , private licensureListService: LicensureListService
    , private busyManagerService: BusyManagerService
    , private commandService: CommandService
    , private utilitiesService: UtilitiesService
    , private confirmationService: ConfirmationService
    , private professionalProfileService: ProfessionalProfileService
  ) {
    super(constantsService, validationManagerService, wizardInjector, ProfileCriminalConvictionsComponent.name, checkboxContentService, elementRef);
    this.modelToForm();
  }

  ngOnInit(): void {
    this.friendlyNames.HasNoRecentConvictions = "Confirmation";
    this.loadLookupLists();
    this.initCommands();
  }

  isItemEditVisible(item: IEntityConvictionDto) {
    return this.canPresentEditorCommandExecute(item);
  }

  isItemDeleteVisible(item: IEntityConvictionDto) {
    return this.canRemoveItemCommandExecute(item);
  }

  protected modelToForm() {
    this.stepForm.addControl('HasNoRecentConvictions', new FormControl('', Validators.required));
    super.modelToForm();
  }

  protected formToModel = () => {
    this.validationManagerService.clearApplicationValidationErrors();
    this.EvaluateHasNoRecentConvictions()
    this.customValidate();
    this.updateBag();
  }

  protected loadLookupLists(): void {
    const doInit = async (): Promise<any> => {
      const responseCollection = await forkJoin([
        this.listService.getStates(),
        this.listService.getCountries(),
        this.licensureListService.getConvictionTypes()]).toPromise();

      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]) as IStateDto[];
      this.possibleCountries = (this.listService.filterInactiveItems(responseCollection[1]) as ICountryDto[]).filter(item => item.Description == "USA" || item.Description == "CANADA");
      this.possibleConvictionTypes = this.listService.filterInactiveItems(responseCollection[2]) as IConvictionTypeDto[];
      this.professionalProfile = this.wizard.model.professionalProfile;
      this.EvaluateHasNoRecentConvictions();
      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  protected initCommands(): void {
    this.presentEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  protected canPresentEditorCommandExecute = (item: IEntityConvictionDto): boolean => {
    return item == null || item.CreatedDate == null || item.CreatedDate > this.wizard.onlineServiceHistory.CreatedDate;
  }

  protected presentEditorCommandExecute = (selectedItem: IEntityConvictionDto) => {
    let okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    const initDialog = async (): Promise<any> => {
      let dialogModel = (selectedItem ? _.cloneDeep(selectedItem) : this.createDialogModel());
      let dialogTitle = selectedItem ? "Criminal Conviction" : "Criminal Conviction - {new}";
      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ProfileCriminalConvictionsEditorComponent, // Content type
        'ProfileCriminalConvictionsEditorComponent', // Content key
        dialogTitle, // Title
        dialogModel, // Model
        null, // OK command does not close dialog
        true); // Use model reference

      this.dialogSettings.initializationData.possibleCountries = this.possibleCountries;
      this.dialogSettings.initializationData.possibleStates = this.possibleStates;
      this.dialogSettings.initializationData.possibleConvictionTypes = this.possibleConvictionTypes;
      this.dialogSettings.okCommand = okCommand;
      this.dialogSettings.isOpen = true;
      return of(true).toPromise();
    }

    return from(initDialog());
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = async (selectedItem: IEntityConvictionDto) => {
    selectedItem.IsDirty = true;
    this.utilitiesService.addOrReplace(this.professionalProfile.EntityConvictions, selectedItem);
    this.professionalProfile.EntityConvictions = this.professionalProfile.EntityConvictions.filter(item => !item.IsDeleted);
    this.EvaluateHasNoRecentConvictions();
    this.customValidate();
  }

  protected canRemoveItemCommandExecute = (item: IEntityConvictionDto): boolean => {
    return item != null && (item.CreatedDate == null || item.CreatedDate > this.wizard.onlineServiceHistory.CreatedDate);
  }

  protected removeItemCommandExecute = (item: any) => {
    this.confirmationService.confirm({
      message: `${item.ConvictionType.Description} conviction (${item.CrimeDescription}) is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',
      accept: () => {

        const doSave = async (): Promise<IProfessionalProfileDto> => {
          item.IsDeleted = true;
          let idx = this.professionalProfile.EntityConvictions
            .findIndex(i => i.LocalId
              ? item.LocalId === i.LocalId
              : item.Id === i.Id);

          if (item.LocalId) {
            this.professionalProfile.EntityConvictions.splice(idx, 1,);
            this.professionalProfile.EntityConvictions = this.professionalProfile.EntityConvictions.filter(item => !item.IsDeleted);
            this.wizard.model.professionalProfile = this.professionalProfile;
            return Promise.resolve(this.professionalProfile);
          }
          else {
            let result = await this.professionalProfileService.save(this.professionalProfile).toPromise();
            this.professionalProfile = result;
            this.wizard.model.professionalProfile = result;
            return of(result).toPromise();
          }
        };

        doSave().then(() => {
          this.EvaluateHasNoRecentConvictions();
        });
      }
    });
  }

  protected EvaluateHasNoRecentConvictions(): void {
    let x = this.stepForm.get('HasNoRecentConvictions');
    let y = this.professionalProfile.EntityConvictions.some(item => !item.IsDeleted);
    this.showEntityConvictions = x.value === "false" || y;

    if (y) {
      // Stack Overflow protection do not delete. Required to disable the radio buttons validations.
      if (x.enabled) {
        this.utilitiesService.enableDisable(x, false, false);
      }
      this.enableHasNoRecentConvictionsRadioButtons = false;
    }
    else {
      // Stack Overflow protection do not delete.
      if (!x.enabled) {
        this.utilitiesService.enableDisable(x, true, false);
      }
      this.enableHasNoRecentConvictionsRadioButtons = true;
    }
  }

  protected customValidate(): void {
    let x = this.stepForm.get('HasNoRecentConvictions');
    let y = this.professionalProfile.EntityConvictions.some(item => !item.IsDeleted);
    x.value === "false" && !y
      ? x.setErrors({ errorMessage: 'Please provide criminal convictions details.' })
      : x.setErrors(null);
  }

  /**
   * Create a new object to be used as the dialog model.
   * @returns
   */
  private createDialogModel(): IEntityConvictionDto {
    return {
      Id: 0,
      IsNew: true,
      IsDeleted: false,
      LocalId: this.utilitiesService.guid(),
      EntityId: this.wizard.model.entityId,

    } as IEntityConvictionDto;
  }

  protected updateBag() {
    this.wizard.selectedStep.bag.addOrReplaceItem(this.bagKey, this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.enableHasNoRecentConvictionsRadioButtons ? this.stepForm.get('HasNoRecentConvictions').value : null, this.noConvictionsMessage, this.yesConvictionsMessage]); // Null will mean the checkbox was not visible to the user.
  }
}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, 'profileCriminalConvictions', ProfileCriminalConvictionsComponent, 'profile-criminal-convictions');
