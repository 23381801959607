<!-- Form -->
<div class="p-fluid" *ngIf="formReady" [formGroup]="contentForm">

    <!-- dForm -->
    <div class="p-grid p-formgrid" formGroupName="dForm">

        <!-- Header -->
        <div class="p-col-12 reach-section-header">Designated Address</div>
        <div class="p-col-12">
            <page-summary-text [majorKey]="'Shared.Content'"
                [minorKey]="'EntityAddressDbp.DesignatedAddress.SummaryTextBlock'">
            </page-summary-text>
        </div>

        <!-- Country -->
        <div class="p-field p-col-12 p-md-6">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Address</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
            <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- Line 3 -->
        <div class="p-field p-col-12">
            <input id="line3" type="text" formControlName="Line3" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-md-6 p-xl-3"
            [hidden]="!(dAddress?.Country == USA || dAddress?.Country == CANADA)">
            <label for="state">{{dAddress?.Country == CANADA ? PROVINCE : STATE}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
                [options]="dAddress?.Country == CANADA ? possibleProvinces : possibleStates" optionLabel="StateCode"
                dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-md-6 p-xl-3" [hidden]="!(dAddress?.Country == USA && dAddress?.State == MN)">
            <label for="county">County</label>
            <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
                optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

    </div>

    <!-- bForm -->
    <!-- Header -->
    <div class="reach-section-header">Business Address</div>
    <div>
        <page-summary-text [majorKey]="'Shared.Content'"
            [minorKey]="'EntityAddressDbp.BusinessAddress.SummaryTextBlock'">
        </page-summary-text>
    </div>

    <!-- IsNotInWorkforce Checkbox (not applicable for Organizational Licensees) -->
    <div class="p-field-checkbox" *ngIf="!businessSameAsDesignated && !isOrganization">
        <p-checkbox inputId="IsNotInWorkforce" formControlName="IsNotInWorkforce" binary="true"></p-checkbox>
        <label for="IsNotInWorkforce">I certify that I am not currently in the workforce and I don't have a business address related to my
            practice.</label>
    </div>

    <!-- IsBusinessSameAsDesignated Checkbox -->
    <div class="p-field-checkbox" *ngIf="businessSameAsDesignated || (bAddress && !bAddress?.IsDeleted)">
        <p-checkbox inputId="IsBusinessSameAsDesignated" formControlName="IsBusinessSameAsDesignated" binary="true">
        </p-checkbox>
        <label for="IsBusinessSameAsDesignated">Same as Designated address</label>
    </div>

    <div formGroupName="bForm" class="p-grid p-formgrid" *ngIf="bAddress && !bAddress?.IsDeleted">

        <!-- Country -->
        <div class="p-field p-col-12 p-md-6">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Business Name</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
          <label for="line2">Address</label>
          <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- Line 3 -->
        <div class="p-field p-col-12">
            <input id="line3" type="text" formControlName="Line3" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-md-6 p-xl-3"
            [hidden]="!(bAddress?.Country == USA || bAddress?.Country == CANADA)">
            <label for="state">{{bAddress?.Country == CANADA ? PROVINCE : STATE}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
                [options]="bAddress?.Country == CANADA ? possibleProvinces : possibleStates" optionLabel="StateCode"
                dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-md-6 p-xl-3" [hidden]="!(bAddress?.Country == USA && bAddress?.State == MN)">
            <label for="county">County</label>
            <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
                optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

    </div>

    <!-- Private Form -->
    <!-- Header -->
    <div class="reach-section-header">Private Address</div>
    <div>
        <page-summary-text [majorKey]="'Shared.Content'"
            [minorKey]="'EntityAddressDbp.PrivateAddress.SummaryTextBlock'">
        </page-summary-text>
    </div>

    <!-- IsPrivateSameAsDesignated Checkbox -->
    <div class="p-field-checkbox">
        <p-checkbox inputId="IsPrivateSameAsDesignated" formControlName="IsPrivateSameAsDesignated" binary="true">
        </p-checkbox>
        <label for="IsPrivateSameAsDesignated">Not Applicable</label>
    </div>

    <div class="p-grid p-formgrid" formGroupName="pForm" *ngIf="pAddress && !pAddress?.IsDeleted">

        <!-- Country -->
        <div class="p-field p-col-12 p-md-6">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- Line 1 -->
        <div class="p-field p-col-12">
            <label for="line1">Address</label>
            <input id="line1" type="text" formControlName="Line1" pInputText>
        </div>

        <!-- Line 2 -->
        <div class="p-field p-col-12">
            <input id="line2" type="text" formControlName="Line2" pInputText>
        </div>

        <!-- Line 3 -->
        <div class="p-field p-col-12">
            <input id="line3" type="text" formControlName="Line3" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="city">City</label>
            <input id="city" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-md-6 p-xl-3"
            [hidden]="!(pAddress?.Country == USA || pAddress?.Country == CANADA)">
            <label for="state">{{pAddress?.Country == CANADA ? PROVINCE : STATE}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
                [options]="pAddress?.Country == CANADA ? possibleProvinces : possibleStates" optionLabel="StateCode"
                dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- County -->
        <div class="p-field p-col-12 p-md-6 p-xl-3" [hidden]="!(pAddress?.Country == USA && pAddress?.State == MN)">
            <label for="county">County</label>
            <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
                optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Zip -->
        <div class="p-field p-col-12 p-md-6 p-xl-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" formControlName="Zip" pInputText>
        </div>

    </div>


</div>
