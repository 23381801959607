import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IApplicationTrainingDto,
ISpecialtyTrainingCheckListItemDto } from './licensure-shared-dto';

export class SpecialtyTrainingCheckListItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, ISpecialtyTrainingCheckListItemDto {
	ApplicationTraining: IApplicationTrainingDto;

}
