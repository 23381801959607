import { Component, Input, OnInit } from '@angular/core';
import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'checklist-comment',
  templateUrl: './checklist-comment.component.html',
  styleUrls: ['./checklist-comment.component.scss']
})
export class ChecklistCommentComponent implements OnInit {

  @Input() message: string;
  @Input() header: string = "Message from Board";

  presentCommentCommand: Command;

  constructor(private commandService: CommandService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.presentCommentCommand = this.commandService.create(this.canPresentCommentCommandExecute, this.presentCommentCommandExecute);
  }

  protected canPresentCommentCommandExecute = (): boolean => {
    return true;
  }

  protected presentCommentCommandExecute = () => {
    this.confirmationService.confirm({
      message: this.message,
      header: this.header,
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok'
    });
  }
}