import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { WizardStepComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';

export const UpdateEntityAssociatesComponentSelector: string = 'update-entity-associates';
export const UpdateEntityAssociatesComponentKey: string = 'updateEntityAssociates';

@Component({
  selector: UpdateEntityAssociatesComponentSelector,
  templateUrl: './update-entity-associates.component.html',
  styleUrls: ['./update-entity-associates.component.scss']
})
export class UpdateEntityAssociatesComponent extends WizardStepComponent implements OnInit {

  config: any;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,) {
    super(constantsService, validationManagerService, wizardInjector, UpdateEntityAssociatesComponent.name, checkboxContentService, elementRef);

    this.config = { associationTypeId: this.wizard.selectedStep?.Parameters?.["associationTypeId"], title: this.wizard.selectedStep?.Parameters?.["title"] }

    // Add the association type and title to the bag for the confirmation document template to use
    this.wizard.selectedStep.bag.addOrReplaceItem("associationTypeId", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.wizard.selectedStep?.Parameters?.["associationTypeId"]]);
    this.wizard.selectedStep.bag.addOrReplaceItem("title", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.wizard.selectedStep?.Parameters?.["title"]]);

    this.modelToForm();
  }
}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, UpdateEntityAssociatesComponentKey, UpdateEntityAssociatesComponent, UpdateEntityAssociatesComponentSelector);
registerDynamicComponent(ReachScenarios.ApplicationWizard, UpdateEntityAssociatesComponentKey, UpdateEntityAssociatesComponent, UpdateEntityAssociatesComponentSelector);