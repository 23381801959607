/**
 * Defines an event args for a navigation action operation.
 */
export interface INavigationActionEventArguments {
  /**
  * Description of the navigation action.
  */
  action: string;
}

/**
 * Defines an event args for a cancelable navigation action operation.
 */
export interface ICancelableNavigationActionEventArguments {
  /**
   * True if action Step was canceled.
   */
  cancel: boolean;
}

/**
 * Defines an event args for a navigation action operation that can be persisted to history.
 */
export interface ILogableNavigationActionEventArguments {
  /**
   * True if action Step should be persisted in history.
   */
  logAction: boolean;
}

/**
 * Tagging interface for Wizard Action event args.
 */
export interface IWizardActionEventArguments extends INavigationActionEventArguments, ILogableNavigationActionEventArguments {
}

/**
 * Tagging interface for cancelable Wizard Action event args.
 */
export interface ICancelableWizardActionEventArguments extends IWizardActionEventArguments, ICancelableNavigationActionEventArguments {
}

/**
 * Tagging interface for Wizard Action event args.
 */
export interface IWizardStepActionEventArguments {
  /**
   * Description of the navigation action.
   */
  action: string;
  /**
   * True if action Step was canceled.
   */
  cancel: boolean;
}

/**
 * Event Arguments class for the Wizard Back Navigation operation.
 */
export class ForwardEventArguments implements ICancelableWizardActionEventArguments {
  constructor(public action: string, public logAction: boolean = true, public cancel: boolean = false) {
  }
}

/**
 * Event Arguments class for the Wizard Back Navigation operation.
 */
export class BackEventArguments implements ICancelableWizardActionEventArguments {
  constructor(public action: string, public logAction: boolean = true, public cancel: boolean = false) {
  }
}

/**
 * Event Arguments class for the Wizard Finish Navigation operation.
 */
export class FinishEventArguments implements IWizardActionEventArguments {
  public cancel = false;
  constructor(public action: string, public logAction: boolean = true) {
  }
}

/**
 * Event Arguments class for the Wizard Cancel Navigation operation.
 */
export class CancelEventArguments implements IWizardActionEventArguments {
  constructor(public action: string, public logAction: boolean = true) {
  }
}

/**
 * Event Arguments class for the Step Forward Navigation operation.
 */
export class StepForwardEventArguments implements IWizardStepActionEventArguments {
  constructor(public action: string, public cancel: boolean = false) {
  }
}

/**
 * Event Arguments class for the Step Back Navigation operation.
 */
export class StepBackEventArguments implements IWizardStepActionEventArguments {
  constructor(public action: string, public cancel: boolean = false) {
  }
}

/**
 * Event Arguments class for the Add-to-cart operation.
 */
export class AddToCartAndContinueShoppingEventArguments implements IWizardActionEventArguments {
  constructor(public action: string, public logAction: boolean = true) {
  }
}
