import { ICredentialDto } from './core-shared-dto';
import { BaseDto } from './base-dto';
import { CredentialType } from './credential-type';

export class CredentialDto extends BaseDto implements ICredentialDto {
	CredentialType: number;


	public constructor(credentialType: CredentialType) { super(); this.CredentialType = credentialType; }

}
