import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CartModule } from '@core/features/cart/cart.module';
import { ReachAppFooterComponent } from '@coreComponents/reach-app/reach-app-footer/reach-app-footer.component';
import { ReachAppMenuComponent } from '@coreComponents/reach-app/reach-app-menu/reach-app-menu.component';
import { ReachAppTopbarComponent } from '@coreComponents/reach-app/reach-app-topbar/reach-app-topbar.component';
import { ReachAppComponent } from '@coreComponents/reach-app/reach-app.component';
import { LicensureCoreModule } from '@licensureCore/licensure-core.module';
import { NgxPrintModule } from 'ngx-print';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import {
  AppConfigService, AppDynamicModuleLoaderService,
  AppErrorComponent, AuthorizationHeaderInterceptor,
  CustomProviderDirective,
  LoginComponent,
  NoPageFoundComponent,
  ReachApplicationService,
  ReachErrorHandlerService,
  XClientIdHeaderInterceptor
} from './core/core.module';
import { AppHttpErrorInterceptor } from './core/services/interceptors/app-http-error.interceptor';
import { DocumentHistoryModule } from './core/features/documentHistory/document-history.module';

function ApplicationInitializer(appConfigService: AppConfigService, appDynamicModuleLoaderService: AppDynamicModuleLoaderService): () => Promise<any> {

  var doInit = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      appConfigService.loadAppConfig()
        .then(_ => {
          return appDynamicModuleLoaderService.load();
        }).then(() => {
          resolve(null);
        }).catch((exception) => {
          reject(exception);
        });
    });
  };

  return doInit;
}

@NgModule({
  declarations: [
    ReachAppComponent,
    ReachAppTopbarComponent,
    ReachAppMenuComponent,
    ReachAppFooterComponent,
    AppErrorComponent,
    LoginComponent,
    NoPageFoundComponent,
    CustomProviderDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    HttpClientModule,
    CartModule,
    DocumentHistoryModule,
    LicensureCoreModule,
    AppRoutingModule,
    RouterModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    DropdownModule,
    FormsModule,
    CardModule,
    PanelModule,
    MatStepperModule,
    MatIconModule,
    ToastModule,
    NgxPrintModule
  ],
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    ReachApplicationService,
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializer,
      deps: [AppConfigService, AppDynamicModuleLoaderService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ReachErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XClientIdHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpErrorInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [ReachAppComponent]
})
export class AppModule { }

