import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime } from "rxjs/operators";

import { CheckboxContentService, CheckboxContent } from '@coreServices/checkbox-content.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { FormValidationManagerService } from '@coreServices/form-validation-manager.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { Wizard, WizardInjector, WIZARD_INJECTOR_TOKEN } from '@coreModels/wizard';

/* DONT REMOVE: Required to disable auto-complete on this component */
import { Bag, ForwardEventArguments, ReachAutocompleteOffDirective, WizardModel } from '@core/core.module';

/**
 * Base class for wizard step components.
 */
@Component({
  template: ''
})
export class WizardStepComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  public wizard: Wizard;
  public stepForm: FormGroup;
  public friendlyNames: any = {};
  public validationScope: string = null;
  public currentDate: Date = new Date();
  protected componentInstanceId: string = null; // Id of this instance to aid in debugging
  protected valueChangesSubscriptions: Subscription = null;
  protected validationMessageClickedSubscription: Subscription = null;
  protected formValidationManagerService: FormValidationManagerService;
  public allowSynchronizeModel: boolean = true;

  // PROPERTIES
  public get stepMinorKey(): string { return this.wizard.selectedStep.MinorKey; }
  public get majorKey() { return (this.wizard && this.wizard.dynamicContentConfiguration) ? this.wizard.dynamicContentConfiguration.majorKey : ""; }

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    protected validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    @Inject(String) public formControlName: string,
    protected checkboxContentService: CheckboxContentService,
    protected elementRef: ElementRef) {

    this.componentInstanceId = this.guid();
    this.wizard = wizardInjector.wizard;
    this.validationScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.stepForm = new FormGroup({
      StepId: new FormControl(this.wizard.selectedIndex.toString()),
      StepName: new FormControl(this.formControlName)
    });

    // Create an instance of FormValidationManagerService.
    this.formValidationManagerService = new FormValidationManagerService(
      this.elementRef,
      this.friendlyNames,
      this.stepForm,
      this.validationManagerService,
      this.validationScope
    );
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called after Angular has fully initialized a component's view.
  */
  ngAfterViewInit(): void {
    if (this.allowSynchronizeModel && this.wizard.model.synchronize) this.wizard.model.synchronize();
  }

  /**
  * A lifecycle hook that is called after the default change detector has completed checking a component's view for changes.
  */
  ngAfterViewChecked(): void {
    // Add validation errors to the message service pool.
    this.formValidationManagerService.checkValidationErrors();
  }

  /**
  * A lifecycle hook that is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed.
  */
  ngOnDestroy(): void {

    if (this.valueChangesSubscriptions) {
      this.valueChangesSubscriptions.unsubscribe();
    }
    this.formValidationManagerService.unsubscribe();
    this.removeWizardStepForm();
  }


  /**
   * Gets the validation tooltip text for the specified formControlName.
   * @param the name of the target form control.
   * @returns {}
   */
  public getValidationTooltip(formControlName: string) {
    return this.validationManagerService.getValidationTooltipForControl(this.validationScope, formControlName);
  }

  /**
   * Maps the input wizard model to this instances stepForm FormGroup.
   */
  protected modelToForm() {
    this.addToParentForm();

    this.valueChangesSubscriptions = this.stepForm.valueChanges.subscribe(value => {
      this.onUpdateForm();
    });
  }

  /**
   * Maps this instance's stepForm FormGroup to the wizard model.
   */
  protected formToModel() {
  }

  /**
   * Adds the stepForm FormGroup for the current step to the wizardForm 'Steps' FormGroup.
   */
  protected addToParentForm() {
    let steps: FormGroup = this.getStepsControlFromWizard();
    steps.addControl(this.formControlName, this.stepForm);
  }

  /**
   * Removes the stepForm FormGroup for the current step from the wizardForm 'Steps' FormGroup.
   */
  protected removeWizardStepForm() {
    let steps: FormGroup = this.getStepsControlFromWizard();
    if (steps && steps.contains(this.formControlName)) {
      steps.removeControl(this.formControlName);
    }
  }

  /**
   * Gets the 'Steps' FromGroup from the WizardForm.
   */
  protected getStepsControlFromWizard() {
    let steps: FormGroup = (this.wizard.wizardForm.get('Steps') as FormGroup);
    return steps;
  }

  /**
   * Gets the stepForm FormGroup instance for this components Step from the WizardForm's Steps FormGroup.
   */
  protected getThisStepControlFromWizard() {
    let steps: FormGroup = this.getStepsControlFromWizard();
    if (steps) {
      const control = steps.get(this.formControlName);
      return control;
    }

    return null;
  }

  /**
   * Handler method for form changed event. Invoked whenever a value on one of the contentForm's
   * controls changes (after a user edit).
   */
  protected onUpdateForm() {
    // Map the form updates to the output model.
    this.formToModel();

    // Add validation errors to the message service pool.
    this.formValidationManagerService.checkValidationErrors();
  }

  /**
   * Create FormControl elements on the stepForm for each CheckboxContent item provided.
   * TODO: Similar logic exists in UtilitiesService createCheckboxControls. Consider refactoring.
   * @param checkboxContents
   * @returns
   */
  protected createCheckboxControls(checkboxContents: CheckboxContent[]) {

    console.log("TODO: Similar logic exists in UtilitiesService createCheckboxControls. Consider refactoring.");

    let output: any[] = [];
    if (!checkboxContents) return output;

    // IMPORTANT: Filter out checkbox contents from the original list
    // that don't have matching dynamic content.
    checkboxContents = checkboxContents.filter(item => this.checkboxContentService.verifiedDynamicContent(item));

    checkboxContents.forEach((element: CheckboxContent) => {

      let formControlName: string = element.friendlyName.replace(/\s/g, "");
      element.formControlName = formControlName;
      let formControl: FormControl = element.isRequired
        ? new FormControl(false, [Validators.required])
        : new FormControl(false);

      let item = {
        model: element,
        form: formControl,
        formControlName: formControlName
      };

      this.stepForm.addControl(formControlName, formControl);
      output.push(item);
      this.friendlyNames[formControlName] = element.friendlyName;
    });

    return output;
  }

  protected addCustomValidationFailureError(message: string, propertyName: string) {
    this.formValidationManagerService.addCustomValidationFailureError(message, propertyName);
  }

  public tooltip(key: string): string {
    return this.formValidationManagerService.tooltip(key);
  }

  protected guid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }
}

@Component({
  template: ''
})
export class CvaHostWizardStepComponent extends WizardStepComponent implements AfterViewChecked, AfterViewInit {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    protected validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    @Inject(String) public formControlName: string,
    protected checkboxContentService: CheckboxContentService,
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(constantsService, validationManagerService, wizardInjector, formControlName, checkboxContentService, elementRef);

    this.wizard.selectedStep.onStepForwardStart(this.onStepForwardStart);
    this.wizard.selectedStep.onStepBackStart(this.onStepBackwardStart);
  }

  protected onStepForwardStart = (e: ForwardEventArguments): Observable<boolean> => {
    this.updateBag(this.wizard.selectedStep.bag, this.wizard.model);
    return of(true);
  }

  protected onStepBackwardStart = (e: ForwardEventArguments): Observable<boolean> => {
    this.updateBag(this.wizard.selectedStep.bag, this.wizard.model);
    return of(true);
  }

  protected updateBag(bag: Bag, model: WizardModel) {

  }

  override ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
    super.ngAfterViewChecked();
  }
}
