import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IEntityTrainingChecklistItemDto,
IEntityTrainingDto } from './licensure-shared-dto';

export class EntityTrainingChecklistItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IEntityTrainingChecklistItemDto {
	EntityTraining: IEntityTrainingDto;

}
