import { Injectable, Inject } from "@angular/core";
import { ArgumentExceptionService } from "./argument-exception.service";
import { FunctionalItemKeysDto, IFunctionalItemKeysDto } from '@coreShared/core-shared.module';

@Injectable({
  providedIn: 'root'
})
export class FunctionTypeService {

  constructor(private argumentExceptionService: ArgumentExceptionService) { }

  public create(functionTypeId: number, functionNumber): IFunctionalItemKeysDto {

    if (!functionTypeId) {
      this.argumentExceptionService.create(functionTypeId);
    }

    if (!functionNumber) {
      this.argumentExceptionService.create(functionNumber);
    }

    let functionalItem = new FunctionalItemKeysDto();
    functionalItem.FunctionTypeId = functionTypeId;
    functionalItem.FunctionNumber = functionNumber;
    functionalItem.ProfessionTypeCode = '  ';
    functionalItem.Description = '';
    return functionalItem;
  }
}

