import { Injectable } from '@angular/core';
export class AuthorizationConfiguration {
  constructor(public allowAnonymous: boolean = false) { }
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationConfigurationProviderService {
  public getConfigurationData(allowAnonymous: boolean = false): AuthorizationConfiguration {
    return new AuthorizationConfiguration(allowAnonymous);
  }
}
