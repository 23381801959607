import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import {
  ILicenseVerificationDto,
  ILicenseVerificationRequestDto,
  IOnlineLicenseSearchResultItemDto
} from './licensure-shared-dto';

export class LicenseVerificationRequestDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ILicenseVerificationRequestDto {
  Verifications: ILicenseVerificationDto[];
  selectedLicenses: IOnlineLicenseSearchResultItemDto[];


}
