
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@core/components/reach-dialog/reach-dialog-content.component';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { ListService } from '@coreServices/list.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { forkJoin, from, of } from 'rxjs';
import { IAddressLocationTypeDto, ICountryDto, ICountyDto, IEntityAddressDto, IStateDto } from 'src/app/coreShared/core-shared.module';

const STATE: string = "State";
const PROVINCE: string = "Province";
const USA: string = "USA";
const CANADA: string = "CANADA";

@Component({
  selector: 'entity-address-editor',
  templateUrl: './entity-address-editor.component.html',
  styleUrls: ['./entity-address-editor.component.scss']
})
export class EntityAddressEditorComponent extends ReachDialogContentComponent<IEntityAddressDto> implements OnInit, IReachDialogContentComponent {

  enableMailingState: boolean = true;
  enableMailingCounty: boolean = true;
  mailingStateLabel: string;

  possibleLocationTypes: IAddressLocationTypeDto[];
  possibleCounties: ICountyDto[];
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleProvinces: IStateDto[];
  mailingStates: IStateDto[];

  contentForm: FormGroup;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    private listService: ListService,
    private busyManagerService: BusyManagerService,) {

    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  public override async ngOnInit(): Promise<void> {

    this.friendlyNames.Line1 = "Address";
    this.friendlyNames.LocationType = "Address Location Type";

    await super.ngOnInit();
  }

  protected override modelToForm(): void {

    this.contentForm = new FormGroup({

      Line1: new FormControl(this.outputModel.Line1 || '', [Validators.required, Validators.maxLength(60)]),
      Line2: new FormControl(this.outputModel.Line2 || '', [Validators.maxLength(60)]),
      Line3: new FormControl(this.outputModel.Line3 || '', [Validators.maxLength(60)]),
      City: new FormControl(this.outputModel.City || '', [Validators.required, Validators.maxLength(40)]),
      Zip: new FormControl(this.outputModel.Zip || '', [Validators.required, Validators.maxLength(10)]),
      IsPublic: new FormControl(this.outputModel.IsPublic || ''),
      IsMailing: new FormControl(this.outputModel.IsMailing || ''),

      Country: new FormControl(null),
      State: new FormControl(null, [Validators.required]),
      County: new FormControl({ value: null, disabled: true }, [Validators.required]),
      LocationType: new FormControl(null, [Validators.required]),
    });

    super.modelToForm();
  }

  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    // Standard fields
    this.outputModel.Line1 = this.contentForm.get('Line1').value;
    this.outputModel.Line2 = this.contentForm.get('Line2').value;
    this.outputModel.Line3 = this.contentForm.get('Line3').value;
    this.outputModel.City = this.contentForm.get('City').value;
    this.outputModel.Zip = this.contentForm.get('Zip').value;
    this.outputModel.IsPublic = this.contentForm.get('IsPublic').value;
    this.outputModel.IsMailing = this.contentForm.get('IsMailing').value;

    // Dropdowns
    this.outputModel.AddressLocationType = this.contentForm.get('LocationType').value;
    this.outputModel.State = this.contentForm.get('State').value?.StateCode ?? "";
    this.outputModel.Country = this.contentForm.get('Country').value?.Description ?? "";
    this.outputModel.CountyId = this.contentForm.get('County').value?.Id;

    super.formToModel();
  }

  protected override initializeEventHandlers(): void {

    super.initializeEventHandlers();

    this.contentForm.get('State').valueChanges.subscribe(x => {
      if (x?.StateCode != 'MN') {
        this.contentForm.get('County').disable();
        this.contentForm.get('County').setValue(null);
        this.enableMailingCounty = false;
      }
      else {
        this.enableMailingCounty = true;
        this.contentForm.get('County').enable();
      }
    });

    this.contentForm.get('Country').valueChanges.subscribe(x => {
      const selectedCountry = x?.Description ?? USA;

      // If the incoming value isn't changing, bail.
      if (!selectedCountry || this.outputModel.Country == selectedCountry) return;

      // Clear selected state and county.
      this.contentForm.get('State').setValue(null);
      this.contentForm.get('County').setValue(null);
      this.enableMailingCounty = false;

      // If 'OTHER' is selected...
      if (selectedCountry != USA && selectedCountry != CANADA) {

        // Hide state and county fields.
        this.enableMailingState = false;
        this.enableMailingCounty = false;
        this.contentForm.get('State').disable();
        this.contentForm.get('County').disable();

        return;
      }

      // Filter possible states collection.      
      this.enableMailingState = true;
      this.mailingStateLabel = selectedCountry == CANADA ? PROVINCE : STATE;
      this.mailingStates = selectedCountry == CANADA ? this.possibleProvinces : this.possibleStates;

      if (!this.enableMailingState) this.contentForm.get('State').disable();
      else this.contentForm.get('State').enable();
      if (!this.enableMailingCounty) this.contentForm.get('County').disable();
      else this.contentForm.get('County').enable();
    });
  }

  /**
  * Loads required lookup data.
  */
  protected override async loadLookupLists(): Promise<any> {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCounties(), this.listService.getAddressLocationTypes(), this.listService.getCountries()]).toPromise();
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == USA) as IStateDto[];
      this.possibleProvinces = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == CANADA) as IStateDto[];
      this.mailingStates = this.possibleStates;
      this.possibleCounties = this.listService.filterInactiveItems(responseCollection[1]) as ICountyDto[];
      this.possibleLocationTypes = this.listService.filterInactiveItems(responseCollection[2]) as IAddressLocationTypeDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[3]) as ICountryDto[];

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT).toPromise();

  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  protected override initDropdowns(): void {

    // Country
    if (!this.outputModel.Country) this.outputModel.Country = USA;
    this.contentForm.get('Country').setValue(this.possibleCountries.find(item => item.Description == this.outputModel.Country));

    // State
    this.mailingStateLabel = this.outputModel.Country == CANADA ? PROVINCE : STATE;
    this.mailingStates = this.outputModel.Country == CANADA ? this.possibleProvinces : this.possibleStates;
    this.enableMailingState = this.outputModel.Country == USA || this.outputModel.Country == CANADA;
    this.enableMailingCounty = this.outputModel.Country == USA && this.outputModel.State == 'MN';
    if (!this.enableMailingState) this.contentForm.get('State').disable();
    if (!this.enableMailingCounty) this.contentForm.get('County').disable();

    if (this.outputModel.State) this.contentForm.get('State').setValue(this.mailingStates.find(item => item.StateCode == this.outputModel.State));
    if (this.outputModel.CountyId) this.contentForm.get('County').setValue(this.possibleCounties.find(item => item.Id == this.outputModel.CountyId));
    if (this.outputModel.AddressLocationType) this.contentForm.get('LocationType').setValue(this.possibleLocationTypes.find(item => item.Id == this.outputModel.AddressLocationType.Id) ?? null);

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'EntityAddressEditorComponent', EntityAddressEditorComponent, 'entity-address-editor');
