<div>

    <!-- Table -->
    <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="activityList" [responsive]="true" responsiveLayout="stack">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <span class="p-text-left reach-list-header">Activity</span>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th width="40%">Activity</th>
                <th>Status</th>
                <th>Initiated</th>
                <th>Expected</th>
                <th>Completed</th>
                <th width="40"></th>
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
                <!-- Activity -->
                <td>
                    <span class="p-column-title">Activity</span>{{item.SubActivityTypeDescription | whenEmpty: '-'}}
                </td>
                <!-- Status -->
                <td>
                    <span class="p-column-title">Status</span>{{item.StatusDescription | whenEmpty: '-'}}
                </td>
                <!-- Initiated -->
                <td>
                    <span class="p-column-title">Initiated</span>{{item.StartDate | date:'MM/dd/yyyy' | whenEmpty:'-'}}
                </td>
                <!-- Expected -->
                <td>
                    <span class="p-column-title">Expected</span>{{item.ExpectedDate | date:'MM/dd/yyyy' |
                    whenEmpty:'-'}}
                </td>
                <!-- Completed -->
                <td>
                    <span class="p-column-title">Completed</span>{{item.EndDate | date:'MM/dd/yyyy' | whenEmpty:'-'}}
                </td>

                <!-- Buttons -->
                <td>
                    <span class="p-column-title">Details</span>
                    <span>
                        <button pButton pRipple class="p-button-text" icon="fa fa-chevron-right" [command-button]="activityDetailCommand" [command-parameter]="item" pTooltip="More details"></button>
                    </span>
                </td>
            </tr>

        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="6">
                    <span class="reach-text-muted"><em>No items</em></span>
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>