<div class="p-grid p-jc-center" [formGroup]="contentForm">

  <div class="p-col-12 p-md-10 p-xl-8">

    <!-- Heading -->
    <div class="reach-page-header">
      Change Your Security Questions
    </div>

    <!-- Summary Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="dynamicContentConfiguration.majorKey"
        minorKey="ChangeSecurityQuestions.SummaryTextBlock"></page-summary-text>
    </div>

    <!-- Body -->
    <div class="p-fluid" [formGroup]="contentForm">

      <!-- Username -->
      <div class="p-field">
        <label for="username">Username</label>
        <span class="p-ml-2">{{currentUser.UserAccount.UserName}}</span>
      </div>

      <!-- Security Question #1 -->
      <div class="p-grid p-nogutter">
        <!-- Question #1 -->
        <div class="p-field p-pr-xl-2 p-col-12 p-xl-6">
          <label for="SecurityQuestion1">Security Question #1</label>
          <p-dropdown inputId="question1" formControlName="SecurityQuestion1" [options]="possibleSecurityQuestions"
            appendto="" optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;">
          </p-dropdown>
        </div>

        <!-- Answer #1 -->
        <div class="p-field p-px-xl-2 p-col-12 p-xl-6">
          <label for="SecurityAnswer1">Security Answer #1</label>
          <input id="answer1" pInputText type="text" formControlName="SecurityAnswer1" />
        </div>
      </div>

      <!-- Security Question #2 -->
      <div class="p-grid p-nogutter">
        <!-- Question #2 -->
        <div class="p-field p-pr-xl-2 p-col-12 p-xl-6">
          <label for="SecurityQuestion2">Security Question #2</label>
          <p-dropdown inputId="question2" formControlName="SecurityQuestion2" [options]="possibleSecurityQuestions"
            appendto="" optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;">
          </p-dropdown>
        </div>

        <!-- Answer #2 -->
        <div class="p-field p-px-xl-2 p-col-12 p-xl-6">
          <label for="SecurityAnswer2">Security Answer #2</label>
          <input id="answer2" pInputText type="text" formControlName="SecurityAnswer2" />
        </div>
      </div>

      <!-- ReCaptcha-->
      <div class="p-field">
        <reach-captcha formControlName='ReachCaptcha'></reach-captcha>
      </div>

    </div>

    <!-- Buttons -->
    <div class="reach-bottom-actions-container">
      <div class="p-d-flex p-jc-end">

        <!-- Save -->
        <div class="p-pl-2">
          <button pButton pRipple type="button" label="Save" class="p-button-primary"
            [command-button]="saveCommand"></button>
        </div>

      </div>
    </div>


    <!-- Additional Help Text (dynamic content) -->
    <div>
      <page-summary-text [majorKey]="dynamicContentConfiguration.majorKey"
        minorKey="ChangeSecurityQuestions.AdditionalInfoTextBlock"></page-summary-text>
    </div>

  </div>

</div>