<div [formGroup]="contentForm">
    <div style="padding-bottom: .5rem;" *ngFor="let groupedRadioContent of groupedRadioContents">

        <!-- Heading -->
        <div class="reach-section-header">{{groupedRadioContent.groupName}}</div>

        <!-- Items -->
        <div *ngFor="let radioContent of groupedRadioContent.items" class="p-field-radiobutton">
            <p-radioButton formControlName="{{radioContent.groupName}}" [name]="radioContent.groupName"
                [value]="radioContent.minorKey"></p-radioButton>
            <label [for]="radioContent.groupName" [innerHtml]="radioContent.content"></label>
        </div>

    </div>
</div>