<!-- Form -->
<div class="p-grid p-formgrid p-fluid" [formGroup]="contentForm">

    <!-- Header -->
    <div class="p-col-12">
        <div class="reach-page-header">{{pageHeader}}</div>
    </div>

    <!-- Summary (dynamic content) -->
    <div class="p-col-12">
        <page-summary-text [majorKey]="dynamicContentConfiguration.majorKey"
            [minorKey]="'LicenseDuplicateCardWizardSelector.SummaryTextBlock'"></page-summary-text>
    </div>

    <!-- License -->
    <div class="p-field p-col-12 p-sm-6 p-xl-4">
        <label for="License">License</label>
        <p-dropdown inputId="License" formControlName="License" [options]="possibleLicenses" appendTo="body"
            optionLabel="DisplayName" dataKey="" [showClear]="true" placeholder="&nbsp;">
        </p-dropdown>
    </div>

    <!-- Buttons -->
    <div class="p-col-12">
        <div class="reach-bottom-actions-container">
            <div class="p-d-flex p-jc-end">
                <!-- Cancel -->
                <div>
                    <button pButton pRipple type="button" label="Cancel" class="p-button-secondary"
                        [command-button]="cancelCommand"></button>
                </div>
                <!-- Continue -->
                <div class="p-pl-2">
                    <button pButton pRipple type="button" label="Continue" [command-button]="startWizardCommand"
                        [command-blocks-invalid]="true"></button>
                </div>
            </div>
        </div>
    </div>

</div>