import { Injectable } from '@angular/core';
import * as _l from 'lodash-es';

import {
  ConstantsService
} from '@core/core.module';

@Injectable({
  providedIn: 'root'
})
export class LicensureConstantsProviderService extends ConstantsService {

  constructor() {
    super();
    console.log('LicensureConstantsProviderService constructor');
    ConstantsService.MergeSettings(this.SYSTEM_SETTING_KEYS, LicensureConstantsProviderService.LicensureMergers.SYSTEM_SETTING_KEYS);
  }

  public static LicensureMergers = {
    SYSTEM_SETTING_KEYS: {
      ENTITY_EMPLOYMENT: {
        ENABLED: 'Feature.EntityEmployment.Enabled',
        TYPE_ENABLED: 'Feature.EntityEmployment.Type.Enabled',
        EMPLOYMENT_LIST_REQUIRE_ACKNOWLEDGMENT: 'Feature.EntityEmployment.EmploymentList.RequireAcknowledgment',
        EMPLOYER_AS_FACILITY_LICENSE_ID_ENABLED: 'Feature.EntityEmployment.EmployerAsFacilityLicenseId.Enabled',
        EMPLOYEE_AS_LICENSE_ID_ENABLED: 'Feature.EntityEmployment.EmployeeAsLicenseId.Enabled',
        EMPLOYMENT_POSITION_PICKLIST_ENABLED: 'Feature.EntityEmployment.Position.Picklist.Enabled',
        EMPLOYMENT_POSITION_COLUMN_ENABLED: 'Feature.EntityEmployment.Position.Column.Enabled',
        ENTITY_EMPLOYMENT_TYPE_ENABLED: 'Feature.EntityEmployment.Type.Enabled',
        SETTING_DROPDOWN_ENABLED: 'Feature.EntityEmployment.Setting.Dropdown.Enabled',
        SETTING_DROPDOWN_REQUIRED: 'Feature.EntityEmployment.Setting.Dropdown.Required',
        SECTOR_DROPDOWN_ENABLED: 'Feature.EntityEmployment.Sector.Dropdown.Enabled',
        SECTOR_DROPDOWN_REQUIRED: 'Feature.EntityEmployment.Sector.Dropdown.Required',
      },
      REINSTATEMENT: {
        ENABLED: 'Feature.Reinstatement.Enabled'
      }
    }
  }

  LICENSE_STATUS_CHANGE_MECHANISMS = {
    APPLICATION: 1,
    RENEWAL: 2,
    REACTIVATION: 3,
    STATUS_CHANGE_APPLICATION: 4,
    BOARD_ACTION_ENTRY: 5,
    SCHEDULED_PROCESS: 6,
    RENEWAL_AND_STATUS_CHANGE: 8
  };

  LICENSE_SEARCH_DIRECTIVE_MODE = {
    INDIVIDUAL: 1,
    ORGANIZATION: 2
  };

  LETTER_TYPE = {
    EDUCATION_PROGRAM_APPLICATION: 11005,
    EDUCATION_PROGRAM_LETTER_OF_INTENT: 11006,
    EDUCATION_PROGRAM_COMPLIANCE: 11007,
  };

  CHECKLIST_TEMPLATE_ITEM_IDS = {
    APPLICATION_EXAM_RETAKE_FEE: 104,
    OCCUPATIONAL_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID: 130,
    APPLICATION_ECFMG_VERIFICATION_CHECKLIST_TEMPLATE_ITEM_ID: 157,
    OTHER_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID: 131,
    APPLICATION_EXAM_CHECKLIST_TEMPLATE_ITEM_ID: 140,
    APPLICATION_SPECIAL_ACCOMMODATIONS_QUESTIONS: 141,
    OTHER_LICENSE_CHECKLIST_TEMPLATE_ITEM_ID: 150,
    OTHER_EXAM_STATE_CHECKLIST_TEMPLATE_ITEM_ID: 151,
    OTHER_PRACTICE_STATE_CHECKLIST_TEMPLATE_ITEM_ID: 152,
    APPLICATION_CERTIFICATION_CHECKLIST_TEMPLATE_ITEM_ID: 155,
    RENEWAL_CERTIFICATION_CHECKLIST_TEMPLATE_ITEM_ID: 3155,
    APPLICATION_ENTITY_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID: 4342,
    RENEWAL_ENTITY_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID: 4343,
    PRACTICE_LOCATIONS_CHECKLIST_TEMPLATE_ITEM_ID: 180,
    SPECIALIZED_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID: 181,
    COURSEWORK_CHECKLIST_TEMPLATE_ITEM_ID: 191,
    APPLICATION_PRACTICE_QUESTIONS_TEMPLATE_ITEM_ID: 170,
    APPLICATION_PRACTICE_QUESTIONS_RETAKE_TEMPLATE_ITEM_ID: 175,
    LICENSE_RENEWAL_PRACTICE_QUESTIONS: 3170,
    LICENSE_RENEWAL_SUPERVISED_PRACTICE: 3190,
    LICENSE_STATUS_CHANGE_APPLICATION_PRACTICE_QUESTIONS: 2170,
    LICENSE_RE_ACTIVATION_PROFESSIONAL_PRACTICE: 2103,
    APPLICATION_TEMPORARY_PERMIT_CHECKLIST_ITEM_ID: 192,
    LICENSE_REACTIVATION_TEMPORARY_PERMIT_CHECKLIST_ITEM_ID: 2192,
    CE_AUDIT_QUESTIONS_TEMPLATE_ITEM_ID: 1010,

  };

  ENTITY_ASSOCIATION_DIRECTIVE_MODE = {
    ENTITY_ASSOCIATE_LIST: 1,
    LICENSE_NUMBER_SEARCH: 2
  };

  // TODO: MOVE ALL DISPLAY ORDER CONSTANTS TO THE SAME FILE
  ONLINE_SERVICE_SORT = {
    USER_REGISTRATION: 2010,
    FORGOT_PW: 2020,
    APPLY_FOR_A_LICENSE: 2028, // WEB GENERATED
    SEARCH_FOR_A_LICENSE: 2029, // WEB GENERATED
  };

  ENTITY_TRAINING = {
    STATUSES: {
      IN_PROGRESS: 1,
      COMPLETED: 2,
      NOT_COMPLETED: 3,
      NEW: 4,
      NOT_APPLICABLE: 9
    }
  };

  CERTIFICATION = {
    STATUSES: {
      ACTIVE: 1,
      EXPIRED: 2,
      WITHDRAWN: 3,
      NEW: 4,
      NOT_APPLICABLE: 9
    },
    EXPIRE_TYPES: {
      LIMITED: 1,
      LIFETIME: 2,
      MOC: 3,
    }
  }

  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), LicensureConstantsProviderService.LicensureMergers.SYSTEM_SETTING_KEYS);
}

export interface ILicensureConstantsProviderService extends LicensureConstantsProviderService { }
