import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { IHttpResponseContent } from '@coreShared/core-shared.module';
import { IAutoVerificationSubscriberDto, AutoVerificationSubscriberDto, IAutoVerificationSubscriberLicenseDto, AutoVerificationSubscriberLicenseDto } from '@licensureShared/licensure-shared.module';
import {
  ArgumentExceptionService,
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService,
  ReachHttpClientService,
  UtilitiesService,
  ValidationManagerService
} from '@core/core.module';

/**
 * Data service for API calls for License data.
 */
@Injectable({
  providedIn: 'root'
})
export class AutoVerificationSubscriberLicenseService extends ReachHttpClientService {
  uri: string = null;


  constructor(
    private argumentExceptionService: ArgumentExceptionService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    private utilitiesService: UtilitiesService,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );

    this.uri = utilitiesService.stringFormatArgs("{0}/licensure/autoVerificationSubscriberLicense", this.apiRootUri);
  }

  /**
 * Returns the subscriber license instance associated with the specified Id.
 * @param id the target AutoVerificationSubscriberLicense ID.
 */
  public getByLicenseId(id): Observable<AutoVerificationSubscriberLicenseDto> {

    // Must have an ID
    if (!id) {
      this.argumentExceptionService.create("id").log();
    }

    return this.get<AutoVerificationSubscriberLicenseDto>(`${this.uri}/${id}`);
  }

  public save(subscriberLicense: IAutoVerificationSubscriberLicenseDto) {

    // Must have a subscriber
    if (!subscriberLicense) {
      this.argumentExceptionService.create("subscriber").log();
    }

    if (subscriberLicense.Id) {
      return this.put<AutoVerificationSubscriberLicenseDto>(this.uri, subscriberLicense);
    } else {
      return this.post<AutoVerificationSubscriberLicenseDto>(this.uri, subscriberLicense);
    }
  }
}
