import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IApplicationTrainingDto,
ITrainingCodeDto } from './licensure-shared-dto';

export class ApplicationTrainingDto extends ValidatableDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IApplicationTrainingDto {
	ApplicationNumber: number;
	EntityId?: number;
	Facility: string;
	Address: string;
	City: string;
	State: string;
	Country: string;
	TraningCodeId: string;
	StartDate: string;
	EndDate: string;
	Specialty: string;
	Listing: string;
	Description: string;
	Phone: string;
	UnitNumber: string;
	LicensedAs: string;
	ReferenceName: string;
	ReferenceAddress: string;
	ReferenceCity: string;
	ReferenceState: string;
	ReferencePhone: string;
	Reference2Name: string;
	Reference2Address: string;
	Reference2City: string;
	Reference2State: string;
	Reference2Phone: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	TrainingCode: ITrainingCodeDto;
	DerivedDescription: string;
	IsFederalFacility?: boolean;

}
