import { IBaseDto,
IUserRightDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class UserRightDto extends BaseDto implements IBaseDto, IUserRightDto {
	Code: string;
	Description: string;
	DirectRoles: string;

}
