import { AppConfigService } from '@coreServices/app-config.service';
import { DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachScenarios, RegionManagerService } from 'src/app/core/core.module';
import { EmploymentListComponentKey } from '../../components/employmentList/employment-list.component';
import { EmploymentListConfiguration } from '../../components/employmentList/model/employment-list-configuration';
import { ProfileLandingRegionConfiguration, ProfileLandingRegionModel } from '../../landingPage/profile/profile-landing-region-model';

export class LicensureProviderConfigurationService extends DefaultProviderConfigurationService {

  constructor(appConfigService: AppConfigService, providerType: string = "licensureProvider", protected regionManagerService: RegionManagerService) {
    super(appConfigService, providerType);

    this.regionManagerService.registerRegionItemWithRegion("ProfileLanding", ReachScenarios.ProfileLanding, EmploymentListComponentKey, (model: ProfileLandingRegionModel) => model.EmploymentListModel, this.educationListConfigurationResolver);
  }

  // For override (see references)
  educationListConfigurationResolver(configuration: ProfileLandingRegionConfiguration): EmploymentListConfiguration { return configuration.EmploymentListConfiguration; };
}