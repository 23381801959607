<!-- PrimeNG Table -->
<p-table styleClass="p-datatable-sm" [value]="SupervisionPlans" [responsive]="true">

  <!-- Caption -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <div class="p-text-left reach-list-header">Supervision Plans</div>
      <div>
        <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="newPlanCommand"
          pTooltip="Create a Supervision Plan"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="isEmploymentEnabled" pSortableColumn="EmployerName">Agency / Position</th>
      <th *ngIf="isPracticeLocationEnabled" pSortableColumn="PracticeLocation">Practice Location</th>
      <th pSortableColumn="SupervisorFullNameList">Supervisor(s)</th>
      <th pSortableColumn="BeginDate">Start</th>
      <th pSortableColumn="EndDate" *ngIf="isEndDateEnabled">End</th>
      <th pSortableColumn="FormDescription">Type</th>
      <th pSortableColumn="ReceivedDate">Submitted</th>
      <th pSortableColumn="ReferenceValue">Plan</th>
      <th pSortableColumn="StatusDescription">Status</th>

      <th style="width: 6em;"></th>
    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>
      <td *ngIf="isEmploymentEnabled">
        <span class="p-column-title">Agency / Position</span>
        {{item.EmployerName | whenEmpty: "-"}} /
        {{item.PositionTitle | whenEmpty: "-"}}
      </td>
      <td *ngIf="isPracticeLocationEnabled">
        <span class="p-column-title">Practice Location</span>
        {{item.PracticeLocation | whenEmpty: "-"}}
      </td>
      <td>
        <span class="p-column-title">Supervisor(s)</span>
        {{item.SupervisorFullNameList | whenEmpty: "-"}}
      </td>
      <td><span class="p-column-title">Start</span>{{item.BeginDate | date:'MM/dd/yyyy' | whenEmpty : "-"}}</td>
      <td *ngIf="isEndDateEnabled"><span class="p-column-title">End</span>{{item.EndDate | date:'MM/dd/yyyy' |
        whenEmpty: "-"}}</td>
      <td><span class="p-column-title">Type</span>{{item.FormDescription | whenEmpty : "-"}}</td>
      <td><span class="p-column-title">Submitted</span>{{item.ReceivedDate | date:'MM/dd/yyyy' | whenEmpty : "-"}}</td>
      <td><span class="p-column-title">Plan</span>{{item.ReferenceValue | whenEmpty : "-"}}</td>
      <td><span class="p-column-title">Status</span>{{item.StatusDescription | whenEmpty : "-"}}</td>

      <!-- Buttons -->
      <td class="p-text-right">
        <span class="p-text-nowrap">
          <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="continuePlanWizardCommand"
            [command-parameter]="item" pTooltip="Edit/Submit this Supervision Plan"></button>
          <button pButton pRipple class="p-button-text" icon="fa fa-check-square-o"
            [command-button]="newVerificationCommand" [command-parameter]="item"
            pTooltip="Submit a Verification for this Plan"></button>
        </span>
      </td>

    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columnLength">
        <span class="reach-text-muted"><em>No entries.</em></span>
      </td>
    </tr>
  </ng-template>

</p-table>
