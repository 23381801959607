// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

// Core
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@coreComponents/reach-dialog/reach-dialog-content.component';
import { RangeModel } from '../../models/range-model';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { DatePipe } from '@angular/common';
import { ReachControlValidators } from '../../../validators/reach-control-validators';

export const RangeListEditorComponentSelector = 'range-list-editor';
export const RangeListEditorComponentKey = 'rangeListEditor';

@Component({
  selector: RangeListEditorComponentSelector,
  templateUrl: './range-list-editor.component.html',
  styleUrls: ['./range-list-editor.component.scss']
})
export class RangeListEditorComponent extends ReachDialogContentComponent<RangeModel> implements IReachDialogContentComponent {

  // FIELDS
  rangeModel: RangeModel;
  dataLoaded: boolean = false;

  // PROPERTIES
  public get yearRange() {
    let currentYear = (new Date()).getFullYear();
    let yearStart = currentYear - 120;
    let yearEnd = currentYear - 1;
    let fmt = `${yearStart}:${yearEnd}`;
    return fmt;
  }
  public get isDate() { return this.rangeModel && this.rangeModel.rangeType === 'Date'; }
  public get isNumber() { return this.rangeModel && this.rangeModel.rangeType === 'Integer'; }

  // CTOR
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    private formBuilder: FormBuilder,
    validationManagerService: ValidationManagerService,

    protected datepipe: DatePipe) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  override ngOnInit(): Promise<void> {

    this.friendlyNames.To = 'To';
    this.friendlyNames.From = 'From';

    return super.ngOnInit();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    this.rangeModel = this.outputModel as RangeModel;

    if (this.rangeModel.rangeType === 'Date') {

      this.contentForm = this.formBuilder.group({
        To: [this.rangeModel.to, [Validators.required, RangeListEditorComponent.validateTo(), ReachControlValidators.pastDateValidator('To')]],
        From: [this.rangeModel.from, [Validators.required, RangeListEditorComponent.validateFrom(), ReachControlValidators.pastDateValidator('From')]]
      });

      this.contentForm.addValidators(ReachControlValidators.dateRangeValidator(this.contentForm.get('To'), this.contentForm.get('From'), "To", "From"))
    }
    else {
      this.contentForm = this.formBuilder.group({
        To: [this.rangeModel.to, [Validators.required, Validators.min(1), Validators.max(120), RangeListEditorComponent.validateTo()]],
        From: [this.rangeModel.from, [Validators.required, Validators.min(1), Validators.max(120), RangeListEditorComponent.validateFrom()]]
      });
    }

    this.dataLoaded = true;
    super.modelToForm();
  }

  private static validateTo() {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!control.parent) return null;
      let fromValue = (control.parent as FormGroup).get('From').value;

      // Compare values
      if (control.value && fromValue && control.value < fromValue) {

        let datePipe = new DatePipe('en-US');

        return { errorMessage: `The specified 'To' value (${(control.value) instanceof Date ? datePipe.transform(control.value, 'MM/dd/yyyy') : control.value}) is less than the specified 'From' value (${(fromValue) instanceof Date ? datePipe.transform(fromValue, 'MM/dd/yyyy') : fromValue}) .` };
      }

      // Otherwise, the control passes validation.
      return null;
    }

  }

  private static validateFrom() {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!control.parent) return null;
      let toValue = (control.parent as FormGroup).get('To').value;

      // Compare values
      if (control.value && toValue && control.value > toValue) {

        let datePipe = new DatePipe('en-US');

        return { errorMessage: `The specified 'From' value (${(control.value) instanceof Date ? datePipe.transform(control.value, 'MM/dd/yyyy') : control.value}) exceeds the specified 'To' value (${(toValue) instanceof Date ? datePipe.transform(toValue, 'MM/dd/yyyy') : toValue}) .` };
      }

      // Otherwise, the control passes validation.
      return null;
    }

  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    if (this.rangeModel.rangeType === 'Date') {
      this.rangeModel.to = this.contentForm.get('To').value;
      this.rangeModel.from = this.contentForm.get('From').value;
    }
    else {
      this.rangeModel.to = this.contentForm.get('To').value;
      this.rangeModel.from = this.contentForm.get('From').value;
    }
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.DataRequestAdHocWizard, RangeListEditorComponentKey, RangeListEditorComponent, RangeListEditorComponentSelector);
registerDynamicComponent(ReachScenarios.Default, RangeListEditorComponentKey, RangeListEditorComponent, RangeListEditorComponentSelector);

