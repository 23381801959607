import { IBaseDto,
IExceptionDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ExceptionDto extends BaseDto implements IBaseDto, IExceptionDto {
	InnerLevel: number;
	Type: string;
	Message: string;
	TargetSite: string;
	HelpLink: string;
	Source: string;
	StackTraceInfo: string;

}
