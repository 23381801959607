import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Bag, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicContentManagerService, MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector, UtilitiesService } from 'src/app/core/index-services';
import { DynamicContentDto } from 'src/app/coreShared';
import { ReachModelAwareComponent } from '../../reachModelAware/reach-model-aware.component';

interface IGroupedRadioContent {
  groupName: string;
  items: any[];
}

/**
* To use this component, create DynamicContent in the format ${stepMinorKey}.Radio.x, with x being a unique identifier (typically 1, 2, 3 or One, Two, Three)
* Radio buttons will be grouped by "GroupName" AKA Group/Heading on the DynamicContentMaintenance view.
*/
@Component({
  selector: 'radio-content-items',
  templateUrl: './radio-content-items.component.html',
  styleUrls: ['./radio-content-items.component.scss']
})
export class RadioContentItemsComponent extends ReachModelAwareComponent implements OnInit {

  @Input() bag: Bag;
  @Input() defaultMinorKey: string;

  contentForm: FormGroup = new FormGroup({});
  deformattedRadioContents: any[] = [];
  groupedRadioContents: IGroupedRadioContent[] = [];

  constructor(@Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
    , protected controlContainer: ControlContainer
    , protected utilitiesService: UtilitiesService
    , protected dynamicContentManagerService: DynamicContentManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService) {
    super(reachModelConfigurationInjector);
  }

  ngOnInit(): void {
    
    const minorKeyBase = `${this.minorKey}.Radio.`;
    const parentFormGroup = this.controlContainer.control as FormGroup;
    parentFormGroup.addControl('RadioContentItems', this.contentForm); // Include this component on the parent component's form group for validating.

    this.dynamicContentManagerService.getEntries().sort((a, b) => a.DisplayOrder - b.DisplayOrder).forEach(dynamicContentDto => {

      if (dynamicContentDto.MajorKey == this.majorKey &&
        dynamicContentDto.MinorKey.startsWith(minorKeyBase) &&
        dynamicContentDto.CategoryId == this.constantsService.DYNAMIC_CONTENT_CATEGORIES.CONTENT &&
        dynamicContentDto.LocationTypeId === this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES.PAGE &&
        dynamicContentDto.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.TEXT) {

        var deformattedRadioContentItem =
        {
          content: dynamicContentDto.Content,
          minorKey: dynamicContentDto.MinorKey,//this.deriveRadioButtonValue(dynamicContentDto, minorKeyBase),
          groupName: dynamicContentDto.GroupName && dynamicContentDto.GroupName.length ? dynamicContentDto.GroupName : " "
        };

        if (!this.contentForm.get(deformattedRadioContentItem.groupName)) {
          this.contentForm.addControl(deformattedRadioContentItem.groupName, new FormControl(deformattedRadioContentItem.minorKey), { emitEvent: false });
        }

        // If handed a default minor key, attempt to select it automatically.
        if (deformattedRadioContentItem.minorKey === this.defaultMinorKey){
          this.contentForm.get(deformattedRadioContentItem.groupName).setValue(this.defaultMinorKey);
        }

        this.deformattedRadioContents.push(deformattedRadioContentItem);
      }

    });

    if (!this.deformattedRadioContents.length) return; // No DynamicContent configured.


    this.contentForm.valueChanges.subscribe(() => { this.formToModel(); });
    this.formToModel(); // Required in case no changes are made to the content form.

    this.createGroupedContent(); // Organize radio contents into groups based on their group names, adding a FormControl for each group.
  }

  protected formToModel(): void {

    this.deformattedRadioContents.forEach(radioContent => {

      this.bag.addOrReplaceItem(
        `${radioContent.groupName}-${radioContent.minorKey}`, // bagItemId
        this.constantsService.BAG_ITEM_TYPES.RADIO_BUTTON_CONTENT, // bagItemType
        [radioContent.groupName, (this.contentForm.get(radioContent.groupName).value == radioContent.minorKey) ? "true" : "false", radioContent.content]); // values

    });

  }

  protected createGroupedContent() {

    const uniqueGroupNames: string[] = Array.from(new Set(this.deformattedRadioContents.map(element => element.groupName)));
    uniqueGroupNames.forEach(groupName => {

      this.groupedRadioContents.push({ groupName: groupName, items: this.deformattedRadioContents.filter(element => element.groupName === groupName) } as IGroupedRadioContent);

    });
  }

  protected deriveRadioButtonValue(dynamicContentDto: DynamicContentDto, minorKeyBase: string): string {
    const startIndex: number = dynamicContentDto.MinorKey.indexOf(minorKeyBase) + minorKeyBase.length;
    let endIndex: number = dynamicContentDto.MinorKey.indexOf(".", startIndex);
    if (endIndex == -1) endIndex = undefined;

    return dynamicContentDto.MinorKey.substring(startIndex, endIndex);
  }
}