<!-- Grid -->
<div class="p-grid p-fluid" [formGroup]="contentForm" *ngIf="formDataIsInit">

    <!-- Location -->
    <div class="p-field p-col-12 p-sm-6">
        <label for="LocationType">Type</label>
        <p-dropdown *ngIf="dataLoaded" inputId="LocationType" formControlName="LocationType"
            [options]="possibleLocationTypes" optionLabel="Description" dataKey="Id" [showClear]="true"
            placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- Checkboxes -->
    <div class="p-col-12 p-sm-6 p-field-checkbox p-mt-sm-5 p-pt-sm-3">

        <!-- Is Public -->
        <p-checkbox inputId="IsPublic" formControlName="IsPublic" binary="true"></p-checkbox>
        <label for="IsPublic">Public</label>

        <!-- Is Mailing -->
        <p-checkbox inputId="IsMailing" class="p-ml-3" formControlName="IsMailing" binary="true"></p-checkbox>
        <label for="IsMailing">Mailing</label>

    </div>

    <!-- Country -->
    <div class="p-field p-col-12 p-sm-6">
        <label for="Country">Country</label>
        <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
            optionLabel="Description" dataKey="Description" [showClear]="false" placeholder="&nbsp;" baseZIndex="1"
            [virtualScroll]="true" itemSize="25">

            <!-- Selected Item -->
            <ng-template let-country pTemplate="selectedItem">
                <div class="p-fluid p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

            <!-- List Item -->
            <ng-template let-country pTemplate="item">
                <div class="p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

        </p-dropdown>
    </div>

    <!-- Line 1 -->
    <div class="p-field p-col-12 p-mb-0">
        <label for="line1">Address</label>
        <input id="line1" type="text" formControlName="Line1" pInputText>
    </div>

    <!-- Line 2 -->
    <div class="p-field p-col-12 p-mb-0">
        <input id="line2" type="text" formControlName="Line2" pInputText>
    </div>

    <!-- Line 3 -->
    <div class="p-field p-col-12">
        <input id="line3" type="text" formControlName="Line3" pInputText>
    </div>

    <!-- City -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="city">City</label>
        <input id="city" type="text" formControlName="City" pInputText>
    </div>

    <!-- State -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3" [hidden]="!enableMailingState">
        <label for="state">{{mailingStateLabel}}</label>
        <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="mailingStates"
            optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
            baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- County -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3" [hidden]="!enableMailingCounty">
        <label for="county">County</label>
        <p-dropdown *ngIf="dataLoaded" inputId="county" formControlName="County" [options]="possibleCounties"
            optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body"
            baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- Zip -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="zip">Zip</label>
        <input id="zip" type="text" formControlName="Zip" pInputText>
    </div>

</div>
