import { AfterContentInit, Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';

import { SummaryInfoSet } from '@coreModels/summary-component-info';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';

@Component({
  selector: 'reach-cardset',
  templateUrl: './reach-cardset.component.html',
  styleUrls: ['./reach-cardset.component.scss']
})
export class ReachCardsetComponent extends ReachModelAwareComponent implements OnInit, OnChanges, AfterContentInit {
  @Input()
  infoSet: SummaryInfoSet;

  constructor(@Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector) {
    super(reachModelConfigurationInjector);
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    super.onInputChanges(changes);
    this.infoSet.infos.sort((a, b) => a.tabOrder - b.tabOrder);
    this.infoSet.infos = this.infoSet.infos.sort((a, b) => a.tabOrder - b.tabOrder);
  }

  ngAfterContentInit() { }
}
