import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BusyManagerService, CheckboxContentService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ListService, ReachScenarios, registerDynamicComponent, ValidationManagerService, WIZARD_INJECTOR_TOKEN, WizardInjector, WizardStepComponent } from '@core/core.module';
import { forkJoin, from, of } from 'rxjs';
import { ICountryDto, ICountyDto, IStateDto } from 'src/app/coreShared/core-shared.module';

@Component({
  selector: 'profile-practice-addresses',
  templateUrl: './profile-practice-addresses.component.html',
  styleUrls: ['./profile-practice-addresses.component.scss']
})
export class ProfilePracticeAddressesComponent extends WizardStepComponent implements OnInit {

  possibleCounties: ICountyDto[];
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];

  public primaryForm: FormGroup;
  public secondaryForm: FormGroup;
  public presentPrimaryForm: boolean = true;
  public presentSecondaryForm: boolean = true;

  constructor(
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    private listService: ListService,
    private busyManagerService: BusyManagerService,
  ) {
    super(constantsService, validationManagerService, wizardInjector, ProfilePracticeAddressesComponent.name, checkboxContentService, elementRef);

    this.modelToForm();
  }

  ngOnInit(): void {
    this.friendlyNames.Line1 = 'Address';
    this.loadLookupLists();
  }

  protected modelToForm() {

    this.stepForm.addControl('HasNoPrimaryAddress', new FormControl(false));
    this.stepForm.addControl('HasNoSecondaryAddress', new FormControl(false));

    // Initialize the PRIMARY form.
    this.primaryForm = new FormGroup({});
    this.primaryForm.addControl('Facility', new FormControl("", [Validators.required]));
    this.primaryForm.addControl('Line1', new FormControl("", [Validators.required]));
    this.primaryForm.addControl('Line2', new FormControl(""));
    this.primaryForm.addControl('City', new FormControl("", [Validators.required]));
    this.primaryForm.addControl('Zip', new FormControl("", [Validators.required]));
    this.primaryForm.addControl('State', new FormControl(null, [Validators.required]));
    this.primaryForm.addControl('County', new FormControl(null, [Validators.required]));
    this.primaryForm.addControl('Country', new FormControl(null, [Validators.required]));
    this.primaryForm.addControl('Phone', new FormControl(null, [Validators.required]));

    this.stepForm.addControl('primaryForm', this.primaryForm);
    this.primaryForm.setParent(this.stepForm);

    // Initialize the SECONDARY form.
    this.secondaryForm = new FormGroup({});
    this.secondaryForm.addControl('Facility', new FormControl("", [Validators.required]));
    this.secondaryForm.addControl('Line1', new FormControl("", [Validators.required]));
    this.secondaryForm.addControl('Line2', new FormControl(""));
    this.secondaryForm.addControl('City', new FormControl("", [Validators.required]));
    this.secondaryForm.addControl('Zip', new FormControl("", [Validators.required]));
    this.secondaryForm.addControl('State', new FormControl(null, [Validators.required]));
    this.secondaryForm.addControl('County', new FormControl(null, [Validators.required]));
    this.secondaryForm.addControl('Country', new FormControl(null, [Validators.required]));
    this.secondaryForm.addControl('Phone', new FormControl(null, [Validators.required]));

    this.stepForm.addControl('secondaryForm', this.secondaryForm);
    this.secondaryForm.setParent(this.stepForm);

    this.initializeEventHandlers();

    super.modelToForm();
  }

  protected initializeEventHandlers(): void {
    this.stepForm.get("HasNoPrimaryAddress").valueChanges.subscribe(hasNoPrimaryAddress => {

      if (hasNoPrimaryAddress == true) {
        this.presentPrimaryForm = false;
        this.clearDisableForm(this.primaryForm);
      }
      else {
        this.presentPrimaryForm = true;
        this.enableForm(this.primaryForm);
      }

    });

    this.stepForm.get("HasNoSecondaryAddress").valueChanges.subscribe(hasNoSecondaryAddress => {
      if (hasNoSecondaryAddress == true) {
        this.presentSecondaryForm = false;
        this.clearDisableForm(this.secondaryForm);
      }
      else {
        this.presentSecondaryForm = true;
        this.enableForm(this.secondaryForm);
      }

    });
  }

  protected loadLookupLists(): void {

    const doInit = async (): Promise<any> => {
      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCounties(), this.listService.getCountries()]).toPromise();
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]) as IStateDto[];
      this.possibleCounties = this.listService.filterInactiveItems(responseCollection[1]) as ICountyDto[];
      this.possibleCountries = (this.listService.filterInactiveItems(responseCollection[2]) as ICountryDto[]).filter(item => item.Description == "USA" || item.Description == "CANADA");

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  private clearDisableForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(fieldName => {
      let formControl = formGroup.controls[fieldName];

      // Don't modify the formControl if it is a form group.      
      if (!(formControl as any).controls) {

        // This only happens if formControl is not a form group.
        formControl.setValue('');
        formControl.disable();
      }
    });
  }

  private enableForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(fieldName => {
      let formControl = formGroup.controls[fieldName];

      // Don't modify the formControl if it is a form group.      
      if (!(formControl as any).controls) {

        // This only happens if formControl is not a form group.
        formControl.enable();
      }
    });
  }

}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, 'profilePracticeAddresses', ProfilePracticeAddressesComponent, 'profile-practice-addresses');