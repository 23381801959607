import { Component, ElementRef, Inject } from '@angular/core';
import { CheckboxContentService, ConstantsService, CONSTANTS_SERVICE_TOKEN, ValidationManagerService, WizardInjector, WizardStepComponent, WIZARD_INJECTOR_TOKEN } from '@core/core.module';
import * as _l from 'lodash-es';

export const PaymentComponentSelector = 'payment';
export const PaymentComponentComponentKey = 'payment';

@Component({
  selector: PaymentComponentSelector,
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends WizardStepComponent {

  public adjustmentsExist: boolean = false;
  public get hasZeroBalance(): boolean { return this.wizard.onlineServiceHistory.Process.Fees.find(x => x.Amount > 0) == null; }

  constructor(
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector) {
    super(constantsService, validationManagerService, wizardInjector, PaymentComponent.name, checkboxContentService, elementRef);

    this.wizard.onlineServiceHistory.OmitFeeManagerCalculations = false;
    this.adjustmentsExist = !_l.isEqual(this.wizard.onlineServiceHistory.Process.Fees, this.wizard.onlineService.Fees);
  }
}
