import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IEducationHistorySearchResultItemDto } from './licensure-shared-dto';

export class EducationHistorySearchResultItemDto extends SearchResultItemDto implements IEducationHistorySearchResultItemDto {
	Id: number;
	ApplicationTypeCode: string;
	ApplicationTypeDescription: string;
	ApplicationId: number;
	MajorId?: number;
	MajorDescription: string;
	DegreeId?: number;
	DegreeDescription: string;
	DegreeCode: string;
	DegreeDate: string;
	SchoolName: string;
	City: string;
	State: string;
	Country: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DerivedDescription: string;

}
