import { Injectable } from '@angular/core';
import { Subject, Observable, EMPTY } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class MenuService {

  private menuSource = new Subject<string>();
  private resetSource = new Subject();
  private navMenuClick = new Subject();
  private navMenuButtonClick = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();
  navMenuClick$ = this.navMenuClick.asObservable();
  navMenuButtonClick$ = this.navMenuButtonClick.asObservable();

  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next();
  }

  /**
 * Raises the "menuService.navMenuClick" event.
 */
  public raiseNavMenuClick() {
    this.navMenuClick.next();
  }

  /**
* Raises the "menuService.navMenuButtonClick" event.
*/
  public raiseNavMenuButtonClick(args) {
      this.navMenuButtonClick.next(args);
  }
}
