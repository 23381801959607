import { IDomainReasonForChangeDtoHost,
IValidatableDto,
ValidationFailureDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ValidatableDto extends BaseDto implements IValidatableDto, IDomainReasonForChangeDtoHost {
	IsObjectGraphDirty: boolean;
	ChangeTracker: any;
	IsObjectGraphValid: boolean;
	HasErrors: boolean;
	AdditionalErrors: ValidationFailureDto[];
	Validator: any;
	ValidationMode: string;
	Errors: ValidationFailureDto[];
	IsValid: boolean;
	IsDirty: boolean;
	DomainId: number;
	DomainKeyId: number;
	TemporaryDomainKeyId: any;
	ReasonForChangePropertyNames: string[];
	DomainReasonForChangeItems: any[];


	public hasValidationErrors(): boolean { return ((this.Errors != null && this.Errors.length > 0) || (this.AdditionalErrors != null && this.AdditionalErrors.length > 0));}

}
