// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

import { DynamicContentDto, SearchResultListDto } from '@coreShared/core-shared.module';
import { stringify } from 'querystring';

@Injectable({ providedIn: 'root' })
export class DynamicContentService extends ReachHttpClientService {
  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }
  //DynamicContentDto

  public getByMajorKey(majorKey): Observable<SearchResultListDto<DynamicContentDto>> {
    let url = `${this.apiRootUri}/dynamiccontent${"?majorKey=" + majorKey}`;
    return this.get<SearchResultListDto<DynamicContentDto>>(url);
  }
}
