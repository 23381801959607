<!-- Dialog -->
<div *ngIf="formDataIsInit" class="p-fluid p-formgrid p-grid p-col-12" [formGroup]="contentForm">

  <!-- Email -->
  <div class="p-field p-col-12" *ngIf="publicEmailVisibile">
    <label for="Email">Email</label>
    <input id="Email" type="text" formControlName="Email" pInputText>
  </div>

  <!-- PrivateEmail -->
  <div class="p-field p-col-12" *ngIf="privateEmailVisibile">
    <label for="PrivateEmail">Private Email</label>
    <input id="PrivateEmail" type="text" formControlName="PrivateEmail" pInputText>
  </div>

</div>