import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, of, from } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachDialogContentComponent, IReachDialogContentComponent } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService, BusyManagerService, UtilitiesService } from 'src/app/core/index-services';
import { LicensureListService } from 'src/app/licensureCore/licensure-core.module';
import { ICertificationDto, ICertificationTypeDto } from 'src/app/licensureShared/licensure-shared.module';

@Component({
  selector: 'profile-certifications-editor',
  templateUrl: './profile-certifications-editor.component.html',
  styleUrls: ['./profile-certifications-editor.component.scss']
})
export class ProfileCertificationsEditorComponent extends ReachDialogContentComponent<ICertificationDto> implements OnInit, IReachDialogContentComponent {

  // FIELDS
  possibleSources: string[];
  possibleBoards: string[];
  possibleCertifications: ICertificationTypeDto[];
  filteredCertifications: ICertificationTypeDto[];

  // CTOR
  constructor(

    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,

    // Custom
    protected formBuilder: FormBuilder,
    protected busyManagerService: BusyManagerService,
    protected listService: LicensureListService,
    protected utilitiesService: UtilitiesService
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    // this.friendlyNames.EffectiveDate = "Effective Date";
    // this.friendlyNames.ExpireDate = "Expire Date";

    // Base.
    await super.ngOnInit();
  }

  override loadLookupLists(): Promise<any> {
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.      
      const responseCollection = await forkJoin([this.listService.getCertificationTypes()]).toPromise();

      this.possibleCertifications = responseCollection[0];
      this.possibleCertifications.forEach(c => { if (c.IsSubcertification && !(c.Description.indexOf("[Sub Certificate]") > -1)) c.Description = `[Sub Certificate] ${c.Description}`; })

      this.possibleSources = [...new Set(this.possibleCertifications.map(item => item.CertificationOrganization.CertificationSource.ShortName))]; // CertificationType.CertificationOrganization.CertificationSource.Description
      this.possibleBoards = [...new Set(this.possibleCertifications.map(item => item.CertificationOrganization.Description))]; // CertificationType.CertificationOrganization.Description

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    return this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT).toPromise();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    this.contentForm = this.formBuilder.group({
      Source: [null, [Validators.required]],
      Board: [null, [Validators.required]],
      Certification: [null, [Validators.required]],
      // EffectiveDate: [(this.outputModel.EffectiveDate ? new Date(this.outputModel.EffectiveDate) : null), [Validators.required, ReachControlValidators.pastDateValidator('Effective Date')]],
      // ExpireDate: [(this.outputModel.ExpireDate ? new Date(this.outputModel.ExpireDate) : null), [ReachControlValidators.pastDateValidator('Expire Date')]],
    });

    // this.contentForm.addValidators(ReachControlValidators.dateRangeValidator(this.contentForm.get('EffectiveDate'), this.contentForm.get('ExpireDate'), "Effective Date", "Expire Date" ))

    super.modelToForm();
  }

  dataLoaded: boolean = false;
  protected override initDropdowns(): void {

    // Dropdowns on this view are chained.
    // Disable dropdowns at the end of the chain.
    if (!this.outputModel.CertificationType) {
      this.contentForm.get('Source').setValue(this.possibleSources.find(item => item == "ABMS")); // Default ABMS
      this.utilitiesService.enableDisable(this.contentForm.get('Certification'), false);
    }

    // Populate all dropdowns if a CertificationType is selected.
    else {
      var x = this.outputModel.CertificationType.CertificationOrganization;
      this.contentForm.get('Source').setValue(this.possibleSources.find(item => item == x.CertificationSource.ShortName));
      this.contentForm.get('Board').setValue(this.possibleBoards.find(item => item == x.Description));
      this.contentForm.get('Certification').setValue(this.possibleCertifications.find(item => item.Id == this.outputModel.CertificationTypeId));
    }

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

  protected override initializeEventHandlers(): void {

    this.contentForm.get('Source').valueChanges.subscribe(x => {

      // Nothing is selected (or the field is cleard)
      if (!x) {
        this.utilitiesService.enableDisable(this.contentForm.get('Board'), false, true);
        this.utilitiesService.enableDisable(this.contentForm.get('Certification'), false, true);
        return;
      }
      else {
        // Filter possible boards
        this.possibleBoards = [...new Set(this.possibleCertifications.filter(item => item.CertificationOrganization.CertificationSource.ShortName == x).map(item => item.CertificationOrganization.Description))];
        this.utilitiesService.enableDisable(this.contentForm.get('Board'), true);
      }

    });

    this.contentForm.get('Board').valueChanges.subscribe(x => {

      // Nothing is selected (or the field is cleard)
      if (!x) {
        this.utilitiesService.enableDisable(this.contentForm.get('Certification'), false, true);
        return;
      }
      else {
        // Filter possible certifications.
        this.filteredCertifications = this.possibleCertifications.filter(item =>
          item.CertificationOrganization.CertificationSource.ShortName == this.contentForm.get('Source').value &&
          item.CertificationOrganization.Description == x);
        this.utilitiesService.enableDisable(this.contentForm.get('Certification'), true);
      }

    });

    super.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    var selectedCertificationType = this.contentForm.get('Certification').value;
    if (selectedCertificationType) {
      this.outputModel.CertificationType = selectedCertificationType;
      this.outputModel.CertificationTypeId = selectedCertificationType?.Id;
    }

    super.formToModel();
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'ProfileCertificationsEditorComponent', ProfileCertificationsEditorComponent, 'profile-certifications-editor');