import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';

import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';

@Component({
    selector: 'reach-app-footer',
    templateUrl: './reach-app-footer.component.html',
    styleUrls: ['./reach-app-footer.component.scss']
})
export class ReachAppFooterComponent {
  private applicationInitialized: Subscription;
  imagePath: string;
  public SYSTEM_SETTING_KEYS;
  public footerName: string;
  public agencyAddress: string;
  public agencyCity: string;
  public agencyState: string;
  public agencyZip: string;
  public agencyEmail: string;
  public agencyPhone: string;
  public footerLogoUrl: string;


  constructor(
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private reachApplicationService: ReachApplicationService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private sanitizer: DomSanitizer
  ) {
    this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
    this.imagePath = this.defaultProviderConfigurationService.customProviderAssetRoot;
    this.applicationInitialized = this.reachApplicationService.applicationInitialized$
      .subscribe(args => this.onApplicationInitialized(args));
  }

  ngOnInit() {
    this.doInit();
  }

  private doInit() {
    this.onApplicationInitialized(null);
  }

  /**
   * Handler for the "bootstrapper.applicationInitialized" event.
   * @param the event args.
   */
  private onApplicationInitialized(args) {
    if (this.reachApplicationService.isApplicationInInitialized) {
      this.footerName = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_FOOTER_NAME);
      this.footerLogoUrl = this.defaultProviderConfigurationService.coreAssetRoot + "/img/mn-logo-blue-footer.png";
      this.agencyAddress = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_ADDRESS);
      this.agencyCity = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_CITY);
      this.agencyState = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_STATE);
      this.agencyZip = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_ZIP);
      this.agencyEmail = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_EMAIL);
      this.agencyPhone = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_PHONE);
    }
  }
}
