import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, OnlineServiceRegistry, OnlineServiceRegistryItem, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfessionalProfileFeatureConfigurationService {
  private isInitialized = false;
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private professionalProfileConstantsService: IProfessionalProfileConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ProfessionalProfile);
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, ProfessionalProfileConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, ProfessionalProfileConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, ProfessionalProfileConstantsService.Mergers.FUNCTION_TYPES);

    this.professionalProfileConstantsService = this.constantsService as IProfessionalProfileConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/professionalProfile/professionalProfileWizard/professional-profile-wizard.module').then(m => m.ProfessionalProfileWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/professionalProfile/professionalProfileWizard/professional-profile-wizard.module').then(m => m.ProfessionalProfileWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public getWizard = (entityId: number, webServiceHistoryId: number, licenseTypeId: string) => {

    const functionType = this.functionTypeService.create(this.professionalProfileConstantsService.FUNCTION_TYPES.ENTITY, entityId);
    const wizardTags = this.wizardTagsService.create(licenseTypeId, null, null, null, null, null, null);
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, this.professionalProfileConstantsService.WEB_SERVICE_PROCESS_TYPES.PROFESSIONAL_PROFILE, wizardTags);

    const initializeWizard = async (): Promise<any> => {
      const wizard = await this.wizardService.getWizard(wizardSearchCriteria, functionType).toPromise();

      if (!wizard.onlineServiceHistory.Id) return await this.wizardService.startWizard(wizard, functionType, null).toPromise();
      else return wizard;
    };

    return from(initializeWizard());
  };

}

export class ProfessionalProfileConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: { PROFESSIONAL_PROFILE_WIZARD: 'ProfessionalProfile.Wizard', },
    WEB_SERVICE_PROCESS_TYPES: { PROFESSIONAL_PROFILE: LicensureOnlineServiceProcessTypeKeys.ProfessionalProfile },
    FUNCTION_TYPES: { APPLICATION: 1, },
  }

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), ProfessionalProfileConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), ProfessionalProfileConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), ProfessionalProfileConstantsService.Mergers.FUNCTION_TYPES);
}


export interface IProfessionalProfileConstantsService extends ProfessionalProfileConstantsService {
}
