// Framework
import { Component, Inject, Input, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { forkJoin, from, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Core
import { UtilitiesService } from '@coreServices/utilities.service';
import { IAddressLocationTypeDto, IAddressTypeDto, ICountryDto, ICountyDto, IEntityAddressDto, IEntityAddressDtoHost, IStateDto } from 'src/app/coreShared/core-shared.module';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ListService, UserManagerService, ValidationManagerService } from '../../index-services';

@Component({
  selector: 'entity-address-dbp',
  templateUrl: './entity-address-dbp.component.html',
  styleUrls: ['./entity-address-dbp.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAddressDbpComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EntityAddressDbpComponent),
      multi: true
    }
  ]
})
export class EntityAddressDbpComponent implements OnChanges, ControlValueAccessor {

  @Input()
  addressDtoHost: IEntityAddressDtoHost;
  @Input()
  friendlyNames: any; // The parent's friendlyNames collection that this question and others add to.

  // FIELDS
  public contentForm: FormGroup; // Designated Form
  public get dForm(): FormGroup { return this.contentForm.get('dForm') as FormGroup } // Designated Form
  public get bForm(): FormGroup { return this.contentForm.get('bForm') as FormGroup } // Business Form
  public get pForm(): FormGroup { return this.contentForm.get('pForm') as FormGroup } // Private Form
  public formReady: boolean; // Wait to render the HTML; Form fields are not built until after ngOnChanges has run.

  STATE: string = "State";
  PROVINCE: string = "Province";
  USA: string = "USA";
  CANADA: string = "CANADA";
  MN: string = "MN";

  possibleCounties: ICountyDto[];
  possibleAddressLocationTypes: IAddressLocationTypeDto[];
  possibleAddressTypes: IAddressTypeDto[];
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleProvinces: IStateDto[];

  public get dAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.IsPublic && address.IsCurrent && !address.IsDeleted); }
  public get bAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS && address.IsCurrent && !address.IsPublic); }
  public get pAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER && address.IsMailing && address.IsCurrent && !address.IsDeleted && !address.IsPublic); }

  public businessSameAsDesignated: boolean; // Used by HTML
  private privateSameAsDesignated: boolean;
  public isOrganization: boolean

  // CTOR
  constructor(

    // Custom
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private listService: ListService,
    private busyManagerService: BusyManagerService,
    private userManagerService: UserManagerService,
    protected validationManagerService: ValidationManagerService,
    private utilitiesService: UtilitiesService,
    private formBuilder: FormBuilder) {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  */
  ngOnChanges(): void {

    this.businessSameAsDesignated = this.dAddress && this.dAddress.AddressLocationType.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS;
    this.privateSameAsDesignated = this.dAddress && this.dAddress.IsMailing == true;
    this.isOrganization = !this.userManagerService.getCurrentPrincipal().user.IsIndividual;

    // Create a new address for any that are missing.
    if (!this.dAddress) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.OTHER, true));
    if (!this.businessSameAsDesignated && !((this.addressDtoHost as any).IsNotInWorkforce) && !this.bAddress) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS));
    if (!this.privateSameAsDesignated && !this.pAddress) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.OTHER, false, true));

    this.modelToForm(); // Initialize form.
    this.loadLookupLists();
  }

  private modelToForm() {

    this.contentForm = this.formBuilder.group({
      dForm: this.buildFormShared(this.dAddress),
      bForm: this.buildFormShared(this.bAddress),
      pForm: this.buildFormShared(this.pAddress),

      IsNotInWorkforce: [(this.addressDtoHost as any).IsNotInWorkforce],
      IsBusinessSameAsDesignated: [this.businessSameAsDesignated],
      IsPrivateSameAsDesignated: [this.privateSameAsDesignated],
    });

    this.initializeEventHandlers(); // Wire event handlers for the content form.
    this.formReady = true; // Forms are built. Now we can safely render our HTML.
  }

  protected initializeEventHandlers(): void {

    // Event handler for when this form is updated.
    this.dForm.valueChanges.pipe(debounceTime(10)).subscribe(() => this.sharedFormToModel(this.dForm, this.dAddress));
    this.bForm.valueChanges.pipe(debounceTime(10)).subscribe(() => this.sharedFormToModel(this.bForm, this.bAddress));
    this.pForm.valueChanges.pipe(debounceTime(10)).subscribe(() => this.sharedFormToModel(this.pForm, this.pAddress));

    // Country -> State -> County
    this.sharedInitializeEventHandlers(this.dAddress, this.dForm);
    this.sharedInitializeEventHandlers(this.bAddress, this.bForm);
    this.sharedInitializeEventHandlers(this.pAddress, this.pForm);

    // Checkboxes
    this.contentForm.get('IsBusinessSameAsDesignated').valueChanges.subscribe(x => this.sadAddressShared(x, true));
    this.contentForm.get('IsPrivateSameAsDesignated').valueChanges.subscribe(x => this.sadAddressShared(x, false));
    this.contentForm.get('IsNotInWorkforce').valueChanges.subscribe(x => this.isNotInWorkforceChanged(x));
  }

  protected buildFormShared(x: IEntityAddressDto): FormGroup {
    var formGroup = this.formBuilder.group({
      Line1: [x?.Line1, [Validators.required, Validators.maxLength(60)]],
      Line2: [x?.Line2, [Validators.maxLength(60)]],
      Line3: [x?.Line3, [Validators.maxLength(60)]],
      City: [x?.City, [Validators.required, Validators.maxLength(40)]],
      Zip: [x?.Zip, [Validators.required, Validators.maxLength(10)]],
      State: [null, [Validators.required]],
      Country: [null],
      County: [{ value: null, disabled: true }, [Validators.required]],
    });

    if (!x) formGroup.disable({ emitEvent: false }); // Disable the formgroup if the address is unset

    return formGroup;
  }

  /**
   * SAD: Same As Designated
   * @param newValue Value of SAD checkbox
   * @param isBusinessAddress True for business, false for private.
   */
  protected sadAddressShared(newValue: boolean, isBusinessAddress: boolean): void {

    var address: IEntityAddressDto = (isBusinessAddress) ? this.bAddress : this.pAddress;
    var form: FormGroup = (isBusinessAddress) ? this.bForm : this.pForm;

    if (newValue) { // SAD is being turned on
      address.IsDeleted = true;
      this.utilitiesService.enableDisable(form, false);

      if (isBusinessAddress) {
        this.dAddress.AddressLocationType = this.possibleAddressLocationTypes.find(item => item.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS);
        this.businessSameAsDesignated = true;
        this.contentForm.get('IsNotInWorkforce').disable({ emitEvent: false })
      }
      else {
        this.dAddress.IsMailing = true;
      }
    }
    else if (!newValue && address && address.IsDeleted) { // SAD is being turned off and there is a recently deleted address
      address.IsDeleted = false;
      this.utilitiesService.enableDisable(form, true);

      if (isBusinessAddress) {
        this.dAddress.AddressLocationType = this.possibleAddressLocationTypes.find(item => item.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER);
        this.businessSameAsDesignated = false;
        this.utilitiesService.enableDisable(this.contentForm.get('IsNotInWorkforce'), true);
      }
      else {
        this.dAddress.IsMailing = false;
      }
    }
    else if (!newValue && !address) { // SAD is being turned off and there is no recently deleted address (occurs when values were previously saved)

      this.utilitiesService.enableDisable(form, true);

      if (isBusinessAddress) {
        this.dAddress.AddressLocationType = this.possibleAddressLocationTypes.find(item => item.Id == this.constantsService.ADDRESS_LOCATION_TYPES.OTHER);
        this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS));
        this.businessSameAsDesignated = false;
        this.utilitiesService.enableDisable(this.contentForm.get('IsNotInWorkforce'), true);
      }
      else {
        this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.OTHER, false, true));

        // Added to default the dropdown to USA when the private address is added.
        form.get('Country').setValue(this.possibleCountries.find(item => item.Description == this.USA));
        this.dAddress.IsMailing = false;
      }
    }
  }

  /**
   * Business address specific logic surrounding IsNotInWorkforce FormControl.
   * @param newValue
   */
  protected isNotInWorkforceChanged(newValue: boolean): void {
    (this.addressDtoHost as any).IsNotInWorkforce = newValue; // Assign to host.

    if (newValue && this.bAddress) { // Remove business address and hide business address fields
      this.bAddress.IsDeleted = true;
      this.utilitiesService.enableDisable(this.bForm, false);
      this.contentForm.get('IsBusinessSameAsDesignated').disable({ emitEvent: false });
    }
    else if (!newValue && this.bAddress && this.bAddress.IsDeleted) { // Revive recently deleted business address and show business address fields
      this.bAddress.IsDeleted = false;
      this.utilitiesService.enableDisable(this.bForm, true);
      this.utilitiesService.enableDisable(this.contentForm.get('IsBusinessSameAsDesignated'), true);
    }
    else if (!newValue && !this.bAddress) {
      this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS));
      this.utilitiesService.enableDisable(this.bForm, true);
      this.utilitiesService.enableDisable(this.contentForm.get('IsBusinessSameAsDesignated'), true);
    }
  }

  protected sharedInitializeEventHandlers(address: IEntityAddressDto, formGroup: FormGroup): void {

    // Mark the form dirty when any form element is changed.
    formGroup.valueChanges.subscribe(() => { if (address) address.IsDirty = true; });

    // State
    formGroup.get('State').valueChanges.subscribe(x => {
      x?.StateCode == this.MN
        ? this.utilitiesService.enableDisable(formGroup.get('County'), true)
        : this.utilitiesService.enableDisable(formGroup.get('County'), false, true);
    });

    // Country
    formGroup.get('Country').valueChanges.subscribe(x => {

      // Check for enabled status of the country FormControl is intentional and necessary.
      // Else the state FormControl may not be disabled properly.
      formGroup.get('Country').enabled && (x?.Description == this.USA || !x?.Description || x?.Description == this.CANADA)
        ? this.utilitiesService.enableDisable(formGroup.get('State'), true)
        : this.utilitiesService.enableDisable(formGroup.get('State'), false, true);
    });
  }

  protected sharedFormToModel(formGroup: FormGroup, address: IEntityAddressDto): void {

    if (!this.dataLoaded || !address || address.IsDeleted) return;

    address.Line1 = formGroup.get('Line1').value;
    address.Line2 = formGroup.get('Line2').value;
    address.Line3 = formGroup.get('Line3').value;
    address.City = formGroup.get('City').value;
    address.State = formGroup.get('State').value?.StateCode ?? "";
    address.Country = formGroup.get('Country').value?.Description ?? "";
    address.Zip = formGroup.get('Zip').value;
    address.CountyId = formGroup.get('County').value?.Id;
  }

  private loadLookupLists(): void {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCounties(), this.listService.getAddressLocationTypes(), this.listService.getAddressTypes(), this.listService.getCountries()]).toPromise();
      this.possibleProvinces = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.CANADA) as IStateDto[];
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.USA) as IStateDto[];
      this.possibleCounties = this.listService.filterInactiveItems(responseCollection[1]) as ICountyDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[4]) as ICountryDto[];
      this.possibleAddressLocationTypes = this.listService.filterInactiveItems(responseCollection[2]) as IAddressLocationTypeDto[];
      this.possibleAddressTypes = this.listService.filterInactiveItems(responseCollection[3]) as IAddressTypeDto[];

      // Populate dropdown FormControl elements.
      this.initDropdowns();

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  dataLoaded: boolean = false;
  protected initDropdowns(): void {

    this.sharedInitDropdowns(this.dAddress, this.dForm);
    if (this.bAddress) this.sharedInitDropdowns(this.bAddress, this.bForm);
    if (this.pAddress) this.sharedInitDropdowns(this.pAddress, this.pForm);

    this.dataLoaded = true; // Allow UI elements to render.
  }

  protected sharedInitDropdowns(address: IEntityAddressDto, formGroup: FormGroup): void {

    if (!address.Country) address.Country = this.USA;
    formGroup.get('Country').setValue(this.possibleCountries.find(item => item.Description == address.Country));

    if (address.State) formGroup.get('State').setValue((address?.Country == this.CANADA ? this.possibleProvinces : this.possibleStates).find(item => item.StateCode == address.State));
    if (address.CountyId) formGroup.get('County').setValue(this.possibleCounties.find(item => item.Id == address.CountyId));
  }

  protected createAddress(addressLocationType: number, isPublic: boolean = false, isMailing: boolean = false): IEntityAddressDto {

    // Create a new EntityAddressDto.
    return {

      // Assign basic fields.
      Id: 0,
      EntityId: this.addressDtoHost.EntityId,
      ProfessionTypeCode: this.addressDtoHost.ProfessionTypeCode,
      IsCurrent: true,
      IsDeleted: false,
      IsNew: true,
      LocalId: this.utilitiesService.guid(),
      FunctionNumber: this.addressDtoHost.FunctionNumber,
      Country: this.USA,
      AddressType: { Id: this.addressDtoHost.AddressTypeId } as IAddressTypeDto,
      AddressLocationType: { Id: addressLocationType } as IAddressLocationTypeDto,
      IsPublic: isPublic,
      IsMailing: isMailing

    } as IEntityAddressDto;
  }

  validate(): ValidationErrors | null {
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames); // Combine errors from this component...
    return this.contentForm.valid ? null : errors; // ... and pass them to the parent.
  }

  // ==================================== CVA ====================================

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }
}
