import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { from } from 'rxjs';
import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService, LandingPageDetailRouteResolverService,
  LandingPageService, OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios, RouteInfoRegistry, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService, UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';
import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({
  providedIn: 'root'
})
export class CeAuditFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private ceAuditConstantsService: ICeAuditConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService
    , private landingPageService: LandingPageService
    , private router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private userManagerService: UserManagerService
    , private functionTypeService: FunctionTypeService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService,) {
    this.ceAuditConstantsService = this.constantsService as ICeAuditConstantsService;
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CeAudit);

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  public getWizard = (auditTypeId: number, domainAuditId: number, processTypeId: number, webServiceHistoryId: number) => {
    let functionType = this.functionTypeService.create(this.constantsService.FUNCTION_TYPES.DOMAIN_AUDIT, domainAuditId);
    let wizardTags = this.wizardTagsService.create(null, null, null, auditTypeId);
    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(
      webServiceHistoryId,
      this.ceAuditConstantsService.WEB_SERVICE_PROCESS_TYPES.ONLINE_CE_AUDIT_RECORDS_SUBMISSION,
      wizardTags);

    const initializeWizard = async (): Promise<any> => {

      let wizard = await this.wizardService.getWizard(wizardSearchCriteria, functionType).toPromise();
      if (!wizard.onlineServiceHistory.Id) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, CeAuditConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, CeAuditConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, CeAuditConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, CeAuditConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.CeAuditInfoDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeAuditInfoDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService },
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/ceAuditWizard/ce-audit-wizard.module').then(m => m.CeAuditWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/ceAuditWizard/ce-audit-wizard.module').then(m => m.CeAuditWizardModule)
      },
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addInfosToLandingPage(principal: Principal) {
    let isFeatureEnabled = true;

    if (!this.isInitialized) {
      this.initialize();
    }

    if (isFeatureEnabled &&
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).CeAudits &&
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).CeAudits.length > 0) {

      let configuration = new SummaryDetailConfiguration(
        LicensureLandingCategories.CeAuditInfo,
        LicensureLandingComponentKeys.CeAuditInfoSummaryLazy,
        LicensureLandingComponentKeys.CeAuditInfoDetailLazy,
        SummaryAggregationType.Singleton);
      let infos: SummaryComponentInfo[] = [];

      let auditInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "CE Audit", configuration);
      (principal.user.UserAccount.profile as ILicensureUserProfileInfoDto).CeAudits.forEach((ceAuditInfo, index: number) => {
        auditInfoBuilder.addItem(index
          , `${ceAuditInfo.TypeDescription} (${ceAuditInfo.LicenseTypeProfessionalSuffix ? ceAuditInfo.LicenseTypeProfessionalSuffix : ceAuditInfo.LicenseTypeCode})`
          , ceAuditInfo
          , this.ceAuditConstantsService.DOMAIN_IDS.DOMAIN_AUDIT);
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }
}

export class CeAuditConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      CE_AUDIT_SUBMIT_RECORDS_WIZARD: "CeAuditRecordsSubmission.Wizard"
    },

    FEATURE_CONSTANTS: {
      CE_AUDIT_SUBMIT_RECORDS_WIZARD: {
        PRACTICE_QUESTIONS_ANSWER_IDS: {
          YES: 1,
          NO: 2,
          UNANSWERED: 3,
          XBLANK: 4
        },
        DOMAIN_AUDIT_STATUSES: {
          NEW: 1,
          IN_PROCESS: 2,
          COMPLETED: 3,
          RECORDS_SUBMITTED: 6
        }
      }
    },

    VALIDATION_MODES: {
      CE_AUDIT_SUBMIT_RECORDS_WIZARD: {
        WEB_CE_AUDIT_SUBMIT_RECORDS_QUESTIONNAIRE: "Web.CeAuditSubmitRecords.Questionnaire",
        WEB_CE_AUDIT_SUBMIT_RECORDS_REVIEW: "Web.CeAuditSubmitRecords.Review"
      }
    },

    WEB_SERVICE_PROCESS_TYPES: {
      ONLINE_CE_AUDIT_RECORDS_SUBMISSION: LicensureOnlineServiceProcessTypeKeys.CeAudit,
    },
  };

  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), CeAuditConstantsService.Mergers.VALIDATION_MODES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), CeAuditConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), CeAuditConstantsService.Mergers.FEATURE_CONSTANTS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), CeAuditConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface ICeAuditConstantsService extends CeAuditConstantsService { }
