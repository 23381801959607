import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerDynamicComponent } from '@core/index-models';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { ErrorComponentKeys } from '../../../core/constants/error-component-constants';
import { LandingComponentKeys } from '../../../core/constants/landing-component-constants';
import { LoginComponentKeys } from '../../../core/constants/login-component-constants';
import { ReachScenarios } from '../../../core/constants/reach-scenarios';
import { RouteInfoRegistry } from '../../../core/models/route-registry';
import { CommandService } from '../../../core/services/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '../../../core/services/constants-provider.service';
import { UserManagerService } from '../../../core/services/user-manager.service';
import { BrowserDetectionService } from '../../index-services';
import { DynamicContentConfiguration } from '../../services/dynamic-content-configuration-provider.service';

export const AppErrorComponentSelector = 'app-error';

@Component({
  selector: AppErrorComponentSelector,
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss']
})
export class AppErrorComponent implements OnInit {
  public dynamicContentConfiguration: DynamicContentConfiguration;
  public agencyEmail: string;
  public agencyPhone: string;
  public BUSY_MANAGER_BUSY_TYPES: any;
  public SYSTEM_SETTING_KEYS: any;
  public referenceNumber: string;
  public error: string;
  public client: string;
  public dateTime: Date;

  constructor(private commandService: CommandService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private userManagerService: UserManagerService
    , private router: Router
    , private systemSettingsManagerService: SystemSettingsManagerService
    , private activatedRoute: ActivatedRoute
    , private ngZone: NgZone
    , private browserDetectionService: BrowserDetectionService) {
    this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
  }

  ngOnInit(): void {
    this.dateTime = new Date();
    this.BUSY_MANAGER_BUSY_TYPES = this.constantsService.BUSY_MANAGER_BUSY_TYPES;
    this.error = this.activatedRoute.snapshot.params["error"];
    this.referenceNumber = this.activatedRoute.snapshot.params["referenceNumber"];
    this.client = this.browserDetectionService.getBrowserVersion();
    this.activatedRoute.data.subscribe((data: { routeData: any }) => {
      if (data && data.routeData) this.dynamicContentConfiguration = data.routeData.dynamicContentConfiguration;
    });

    this.agencyEmail = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_EMAIL);
    this.agencyPhone = this.systemSettingsManagerService.asString(this.SYSTEM_SETTING_KEYS.AGENCY_PHONE);
  }

  homeCommand = this.commandService.createButtonCommand(() => {
    return true;
  }, () => {
    if (this.userManagerService.getCurrentPrincipal()) {
      this.ngZone.run(() => this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]));
    } else {
      this.ngZone.run(() => this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]));
    }
  });

}
registerDynamicComponent(ReachScenarios.Default, ErrorComponentKeys.Error, AppErrorComponent, AppErrorComponentSelector);
