import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { ISupervisionPlanFormDto,
ISupervisionVerificationFormDto } from './licensure-shared-dto';

export class SupervisionPlanFormDto extends LookupDto implements ISupervisionPlanFormDto {
	IsOnlineSupported: boolean;
	IsClinical: boolean;
	VerificationFormId?: number;
	VerificationForm: ISupervisionVerificationFormDto;

}
