import { Component, Inject, Injector, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentKeys, ReachScenarios } from '@core/index-constants';
import { GeneralInfo, OnlineServiceLinkSubscription, OnlineServiceProcessTypes, registerDynamicComponent } from '@core/index-models';
import {
  BusyManagerService, ConstantsService, CONSTANTS_SERVICE_TOKEN, MODEL_CONFIG_INJECTION_TOKEN, NotificationCenterManagerService,
  OnlineServiceLinkManagerService,
  ReachModelConfigurationInjector, SummaryInfoManagerService,
  UserManagerService,
  UtilitiesService
} from '@core/index-services';
import { LandingCardBaseComponent } from '../landingBase/card/landing-card-base.component';

export const GeneralLandingCardComponentSelector = 'general-landing-card';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: GeneralLandingCardComponentSelector,
  templateUrl: './general-landing-card.component.html',
  styleUrls: ['./general-landing-card.component.scss']
})
export class GeneralLandingCardComponent extends LandingCardBaseComponent implements OnInit {
  generalInfo: GeneralInfo;

  constructor(
    activatedRoute: ActivatedRoute,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    injector: Injector,
    notificationCenterManagerService: NotificationCenterManagerService,
    onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,
    router: Router,
    summaryInfoManagerService: SummaryInfoManagerService,
    userManagerService: UserManagerService,
    utilitiesService: UtilitiesService
  ) {
    super(
      activatedRoute,
      busyManagerService,
      constantsService,
      injector,
      notificationCenterManagerService,
      onlineServiceLinkManagerService,
      reachModelConfigurationInjector,
      router,
      summaryInfoManagerService,
      userManagerService,
      utilitiesService,
      constantsService.DOMAIN_IDS.GENERAL
    );
    this.title = "General";
    this.aggregateTitle = "General";
    this.domainId = this.constantsService.DOMAIN_IDS.PUBLIC;
    this.onlineServiceLinkSubscriptions.push(new OnlineServiceLinkSubscription(OnlineServiceProcessTypes.Any, () => { return this.constantsService.DOMAIN_KEY_IDS.DEFAULT; }));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.generalInfo = this.info.model;
  }
}

registerDynamicComponent(ReachScenarios.LandingPage, LandingComponentKeys.GeneralSummary, GeneralLandingCardComponent, GeneralLandingCardComponentSelector);
