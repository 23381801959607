// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from 'lodash-es';

// Core
import {
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  DialogDataInjector, DIALOG_DATA_INJECTOR_TOKEN,
  ReachScenarios,
  IReachDialogContentComponent,
  ReachDialogContentComponent,
  ValidationManagerService,
  registerDynamicComponent,
  ReachControlValidators
} from '@core/core.module';

// Shared
import { IConvictionTypeDto, IEntityConvictionDto } from 'src/app/licensureShared/licensure-shared.module';
import { IStateDto, ICountryDto } from '@coreShared/core-shared.module';

@Component({
  selector: 'profile-criminal-convictions-editor',
  templateUrl: './profile-criminal-convictions-editor.component.html',
  styleUrls: ['./profile-criminal-convictions-editor.component.scss']
})
export class ProfileCriminalConvictionsEditorComponent extends ReachDialogContentComponent<IEntityConvictionDto> implements OnInit, IReachDialogContentComponent {

  // FIELDS
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleProvinces: IStateDto[];
  possibleConvictionTypes: IConvictionTypeDto[];

  STATE: string = "State";
  PROVINCE: string = "Province";
  USA: string = "USA";
  CANADA: string = "CANADA";
  MN: string = "MN";

  // CTOR
  constructor(

    // For ReachDialogContentComponent
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,

    // Custom
  ) {

    // Super call.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    this.friendlyNames.ConvictionDate = "Conviction Date";
    this.friendlyNames.CrimeDescription = "Crime Description";
    this.friendlyNames.ConvictionType = "Conviction Type";

    // Base.
    await super.ngOnInit();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    // Initialize the content form.
    this.contentForm = new FormGroup({});
    this.contentForm.addControl('ConvictionType', new FormControl(null, [Validators.required]));
    this.contentForm.addControl('CrimeDescription', new FormControl(this.inputModel.CrimeDescription || "", [Validators.required, Validators.maxLength(80)]));
    let convictionDate: Date;
    if (this.inputModel.ConvictionDate) convictionDate = new Date(this.inputModel.ConvictionDate);
    else convictionDate = null;
    this.contentForm.addControl('ConvictionDate', new FormControl(convictionDate, [Validators.required, ReachControlValidators.pastDateValidator('Conviction Date')]));
    this.contentForm.addControl('City', new FormControl(this.inputModel.CourtCity || "", [Validators.required, Validators.maxLength(40)]));
    this.contentForm.addControl('State', new FormControl(null, [Validators.required]));
    this.contentForm.addControl('Country', new FormControl(null, [Validators.required]));
    this.contentForm.addControl('Sentence', new FormControl(this.inputModel.CourtSentence || "", [Validators.required]));

    // Unpack hostDialogData object.
    this.possibleCountries = this.hostDialogData.initializationData.possibleCountries;
    this.possibleProvinces = this.hostDialogData.initializationData.possibleStates.filter((item: IStateDto) => item.Country.toUpperCase() == this.CANADA) as IStateDto[];
    this.possibleStates = this.hostDialogData.initializationData.possibleStates.filter((item: IStateDto) => item.Country.toUpperCase() == this.USA) as IStateDto[];
    this.possibleConvictionTypes = this.hostDialogData.initializationData.possibleConvictionTypes;

    super.modelToForm();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {

    if (!this.dataLoaded) return;

    let conviction = this.outputModel;

    // Handle manual entry of form fields.
    conviction.ConvictionTypeId = this.contentForm.get('ConvictionType').value?.Id;
    if (conviction.ConvictionTypeId) conviction.ConvictionType = this.possibleConvictionTypes.find(item => item.Id == conviction.ConvictionTypeId);
    conviction.CrimeDescription = this.contentForm.get('CrimeDescription').value;
    conviction.ConvictionDate = this.contentForm.get('ConvictionDate').value;
    conviction.CourtCity = this.contentForm.get('City').value;
    conviction.CourtStateId = this.contentForm.get('State').value?.StateCode;
    conviction.CourtCountry = this.contentForm.get('Country').value?.Description;
    conviction.CourtSentence = this.contentForm.get('Sentence').value;

    super.formToModel();
  }

  /**
  * Initializes dropdowns. Occurs after necessary data is finished loading.
  */
  protected override initDropdowns(): void {

    if (this.outputModel.CourtCountry) this.contentForm.get('Country').setValue(this.possibleCountries.find(item => item.Description == this.outputModel.CourtCountry));
    if (this.outputModel.CourtStateId) this.contentForm.get('State').setValue((this.outputModel?.CourtCountry == this.CANADA ? this.possibleProvinces : this.possibleStates).find(item => item.StateCode == this.outputModel.CourtStateId));
    if (this.outputModel.ConvictionTypeId) this.contentForm.get('ConvictionType').setValue(this.possibleConvictionTypes.find(item => item.Id == this.outputModel.ConvictionTypeId));

    // Allow UI elements to render.
    this.dataLoaded = true;
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'ProfileCriminalConvictionsEditorComponent', ProfileCriminalConvictionsEditorComponent, 'profile-criminal-convictions-editor');
