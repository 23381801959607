import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { OnlineUserSearchCriteriaDto } from '@coreShared/dto-gen/online-user-search-criteria-dto';
import { IApplicationTypeDto,
IEducationProgramDto,
ILicenseTypeDto,
ILicensureOnlineUserSearchCriteriaDto } from './licensure-shared-dto';

export class LicensureOnlineUserSearchCriteriaDto extends OnlineUserSearchCriteriaDto implements IBaseDto, ILicensureOnlineUserSearchCriteriaDto {
	LicenseType: ILicenseTypeDto;
	ApplicationType: IApplicationTypeDto;
	EducationProgram: IEducationProgramDto;
	EducationProgramEntityId?: number;

}
