import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceLocatorService {

  constructor(
    private injector: Injector
  ) {
  }

  public getInstance(name) {
    try {
        let svc = this.injector.get(name);
        return svc;
    }
    catch(err) {
    }
    return null;
  }
}
