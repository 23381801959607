import { Injectable } from '@angular/core';

import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ArgumentExceptionService {

    constructor(private utilitiesService: UtilitiesService) { }

    public create(argument) {
      const msg = this.utilitiesService.stringFormatArgs('argument {0} must be valued', argument);
      return new ArgumentException(argument);
    }
}

export class ArgumentException {
  public log = null;

  constructor(argument, public message: string = '') {
    this.log = () => {
      // $log.error(this.message);
      throw this.message;
    };
  }
}
