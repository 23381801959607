import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService, DynamicRoutesService, FunctionTypeService, OnlineServiceRegistry, OnlineServiceRegistryItem, Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LicenseConstantsService } from "./license-feature-configuration.service";

@Injectable({ providedIn: 'root' })
export class LicenseRenewalFinalFeeFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private licenseRenewalFinalFeeConstantsService: ILicenseRenewalFinalFeeConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private functionTypeService: FunctionTypeService
    , private wizardTagsService: WizardTagsService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.LicenseRenewalFinalFee);
  }

  /* Called from FeatureManagerService */
  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, LicenseRenewalFinalFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, LicenseRenewalFinalFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.licenseRenewalFinalFeeConstantsService = this.constantsService as ILicenseRenewalFinalFeeConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseRenewalFinalFeeWizard/license-renewal-final-fee-wizard.module').then(m => m.LicenseRenewalFinalFeeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseRenewalFinalFeeWizard/license-renewal-final-fee-wizard.module').then(m => m.LicenseRenewalFinalFeeWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/licenseRenewalFinalFeeWizard/license-renewal-final-fee-wizard.module').then(m => m.LicenseRenewalFinalFeeWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  /**
  * Retrieves a wizard Observable based on provided parameters.
  * If `webServiceHistoryId` is provided, returns an Observable of the wizard obtained from the service.
  * Otherwise, initiates a new wizard using the provided parameters and returns its Observable.
  * @returns An Observable emitting a Wizard object.
  */
  getWizard(processTypeId: number, licenseId: number, licenseTypeId: string, licenseStatusId: string, licenseStatusIdTo: string, webServiceHistoryId: number, isOpiate?: boolean, isOutOfState?: boolean, licenseSubtypeId?: number): Observable<Wizard> {

    const functionType = this.functionTypeService.create((this.constantsService as LicenseConstantsService).FUNCTION_TYPES.LICENSE, licenseId);
    const wizardTags = this.wizardTagsService.create(licenseTypeId, null, licenseStatusId, null, null, null, licenseStatusIdTo, null, null, isOpiate, isOutOfState, licenseSubtypeId);
    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId, processTypeId, wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(switchMap((wizard: Wizard) => {
      if (wizard.onlineServiceHistory?.Id) return of(wizard);
      else return this.wizardService.startWizard(wizard, functionType, licenseTypeId);
    }))
  };
}

export class LicenseRenewalFinalFeeConstantsService extends ConstantsService {

  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: { LICENSE_RENEWAL_FINAL_FEE_WIZARD: 'License.Renewal.Final.Fee.Wizard', },
    WEB_SERVICE_PROCESS_TYPES: {
      LICENSE_RENEWAL_FINAL_FEE: LicensureOnlineServiceProcessTypeKeys.LicenseRenewalFinalFee,
      LICENSE_RENEWAL_LICENSE_FEE_FIRST_IN_GROUP: LicensureOnlineServiceProcessTypeKeys.LicenseRenewalFinalFeeFirstInGroup
    }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), LicenseRenewalFinalFeeConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), LicenseRenewalFinalFeeConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
}

export interface ILicenseRenewalFinalFeeConstantsService extends LicenseRenewalFinalFeeConstantsService {
}

