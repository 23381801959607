import { IDocumentDto,
IDomainReasonForChangeDtoHost,
IRelatedToBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeSponsorCourseStatusDto,
ICeSponsorshipScheduledCourseCreditDto,
ICeSponsorshipScheduledCourseDto } from './licensure-shared-dto';

export class CeSponsorshipScheduledCourseDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICeSponsorshipScheduledCourseDto {
	CeSponsorshipId?: number;
	CeSponsorCourseId?: number;
	Name: string;
	SubName: string;
	FullName: string;
	StartDate?: Date;
	EndDate?: Date;
	City: string;
	State: string;
	Comments: string;
	InactiveInd: boolean;
	LegacyMbceId?: number;
	StatusId?: number;
	Status: ICeSponsorCourseStatusDto;
	Credits: ICeSponsorshipScheduledCourseCreditDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	TotalCreditAmount?: number;
	EntityId?: number;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;

}
