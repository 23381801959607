import {
  CoreLandingTypes,
  ReachScenarios,
  registerDynamicComponent,
  registerRouteInfo,
  RouteInfoRegistry
} from '@core/core.module';
import { LicensureLandingComponentKeys, LicensureLandingTypes, SearchComponentKeys } from '@licensureCore/index-constants';

/**
 * Registers RouteInfo items for easy and lazy load landing page routes.
 * */
export class LicensureComponentRegistrationManager {
  private static _initialize = (() => {
    LicensureComponentRegistrationManager.initializeLicensureLazyComponents();
    LicensureComponentRegistrationManager.initializeLicensureLandingRoutes();
    LicensureComponentRegistrationManager.initializeSearchLazyComponents();
    LicensureComponentRegistrationManager.initializeSearchRoutes();
  })();

  /**
   * Register the landing components for lazy import (lazy module references or Ivy lazy component references).
   */
  private static initializeLicensureLazyComponents() {

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseSummaryLazy, null, null, null, import('@licensureCore/landingPage/associatedLicense/associated-license-summary-landing.module').then(mod => mod.AssociatedLicenseSummaryLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseListLazy, null, null, null, import('@licensureCore/landingPage/associatedLicense/associated-license-summary-landing.module').then(mod => mod.AssociatedLicenseSummaryLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ApplicationSummaryLazy, null, null, null, import('@licensureCore/landingPage/application/application-landing.module').then(mod => mod.ApplicationLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ApplicationDetailLazy, null, null, null, import('@licensureCore/landingPage/application/application-landing.module').then(mod => mod.ApplicationLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AutoVerificationSummaryLazy, null, null, null, import('@licensureCore/landingPage/autoVerification/auto-verification-landing.module').then(mod => mod.AutoVerificationLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AutoVerificationDetailLazy, null, null, null, import('@licensureCore/landingPage/autoVerification/auto-verification-landing.module').then(mod => mod.AutoVerificationLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeSponsorshipSummaryLazy, null, null, null, import('@licensureCore/landingPage/ceSponsorship/ce-sponsorship-landing.module').then(mod => mod.CeSponsorshipLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeSponsorshipDetailLazy, null, null, null, import('@licensureCore/landingPage/ceSponsorship/ce-sponsorship-landing.module').then(mod => mod.CeSponsorshipLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeAuditInfoSummaryLazy, null, null, null, import('@licensureCore/landingPage/ceAuditLanding/ceAudit-landing.module').then(mod => mod.CeAuditLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeAuditInfoDetailLazy, null, null, null, import('@licensureCore/landingPage/ceAuditLanding/ceAudit-landing.module').then(mod => mod.CeAuditLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.DataRequestSummaryLazy, null, null, null, import('@licensureCore/landingPage/dataRequest/data-request-landing.module').then(mod => mod.DataRequestLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.DataRequestDetailLazy, null, null, null, import('@licensureCore/landingPage/dataRequest/data-request-landing.module').then(mod => mod.DataRequestLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramSummaryLazy, null, null, null, import('@licensureCore/landingPage/educationProgramCompletion/education-program-landing.module').then(mod => mod.EducationProgramLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramDetailLazy, null, null, null, import('@licensureCore/landingPage/educationProgramCompletion/education-program-landing.module').then(mod => mod.EducationProgramLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramSummaryLazy, null, null, null, import('@licensureCore/landingPage/educationProgramCompletion/education-program-landing.module').then(mod => mod.EducationProgramLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramDetailLazy, null, null, null, import('@licensureCore/landingPage/educationProgramCompletion/education-program-landing.module').then(mod => mod.EducationProgramLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramApplicationSummaryLazy, null, null, null, import('@licensureCore/landingPage/educationProgramApplication/education-program-application-landing.module').then(mod => mod.EducationProgramApplicationLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy, null, null, null, import('@licensureCore/landingPage/educationProgramApplication/education-program-application-landing.module').then(mod => mod.EducationProgramApplicationLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseSummaryLazy, null, null, null, import('@licensureCore/landingPage/license/license-landing.module').then(mod => mod.LicenseLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseDetailLazy, null, null, null, import('@licensureCore/landingPage/license/license-landing.module').then(mod => mod.LicenseLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseDetailLazy, null, null, null, import('@licensureCore/landingPage/license/license-landing.module').then(mod => mod.LicenseLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseStatusChangeSummaryLazy, null, null, null, import('src/app/licensureCore/landingPage/licenseStatusChange/license-status-change-landing.module').then(mod => mod.LicenseStatusChangeLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy, null, null, null, import('src/app/licensureCore/landingPage/licenseStatusChange/license-status-change-landing.module').then(mod => mod.LicenseStatusChangeLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.OrganizationalPayorSummaryLazy, null, null, null, import('@licensureCore/landingPage/organizationalPayor/organizational-payor-landing.module').then(mod => mod.OrganizationalPayorLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.OrganizationalPayorDetailLazy, null, null, null, import('@licensureCore/landingPage/organizationalPayor/organizational-payor-landing.module').then(mod => mod.OrganizationalPayorLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ProfileSummaryLazy, null, null, null, import('@licensureCore/landingPage/profile/profile-landing.module').then(mod => mod.ProfileLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.ProfileDetailLazy, null, null, null, import('@licensureCore/landingPage/profile/profile-landing.module').then(mod => mod.ProfileLandingModule));

    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.SupervisionSummaryLazy, null, null, null, import('@licensureCore/landingPage/supervision/supervision-landing.module').then(mod => mod.SupervisionLandingModule));
    registerDynamicComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.SupervisionDetailLazy, null, null, null, import('@licensureCore/landingPage/supervision/supervision-landing.module').then(mod => mod.SupervisionLandingModule));

  }

  /**
   * Add licensure easy and lazy load RouteInfos to the RouteInfoRegistry.
   * */
  private static initializeLicensureLandingRoutes() {
    RouteInfoRegistry.pages = { ...CoreLandingTypes, ...LicensureLandingTypes };

    registerRouteInfo(LicensureLandingComponentKeys.AssociatedLicenseList, LicensureLandingComponentKeys.AssociatedLicenseList, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseList);
    registerRouteInfo(LicensureLandingComponentKeys.AssociatedLicenseListLazy, LicensureLandingComponentKeys.AssociatedLicenseListLazy, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseListLazy);


    registerRouteInfo(LicensureLandingComponentKeys.ApplicationDetail, LicensureLandingComponentKeys.ApplicationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.ApplicationDetail);
    registerRouteInfo(LicensureLandingComponentKeys.ApplicationDetailLazy, LicensureLandingComponentKeys.ApplicationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.ApplicationDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.AutoVerificationDetail, LicensureLandingComponentKeys.AutoVerificationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AutoVerificationDetail);
    registerRouteInfo(LicensureLandingComponentKeys.AutoVerificationDetailLazy, LicensureLandingComponentKeys.AutoVerificationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AutoVerificationDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.CeAuditInfoDetail, LicensureLandingComponentKeys.CeAuditInfoDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeAuditInfoDetail);
    registerRouteInfo(LicensureLandingComponentKeys.CeAuditInfoDetailLazy, LicensureLandingComponentKeys.CeAuditInfoDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeAuditInfoDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.CeSponsorshipDetail, LicensureLandingComponentKeys.CeSponsorshipDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeSponsorshipDetail);
    registerRouteInfo(LicensureLandingComponentKeys.CeSponsorshipDetailLazy, LicensureLandingComponentKeys.CeSponsorshipDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.CeSponsorshipDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.DataRequestDetail, LicensureLandingComponentKeys.DataRequestDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.DataRequestDetail);
    registerRouteInfo(LicensureLandingComponentKeys.DataRequestDetailLazy, LicensureLandingComponentKeys.DataRequestDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.DataRequestDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.EducationProgramDetail, LicensureLandingComponentKeys.EducationProgramDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramDetail);
    registerRouteInfo(LicensureLandingComponentKeys.EducationProgramDetailLazy, LicensureLandingComponentKeys.EducationProgramDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.EducationProgramApplicationDetail, LicensureLandingComponentKeys.EducationProgramApplicationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramApplicationDetail);
    registerRouteInfo(LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy, LicensureLandingComponentKeys.EducationProgramApplicationDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.LicenseDetail, LicensureLandingComponentKeys.LicenseDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseDetail);
    registerRouteInfo(LicensureLandingComponentKeys.LicenseDetailLazy, LicensureLandingComponentKeys.LicenseDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseDetailLazy);
    registerRouteInfo(LicensureLandingComponentKeys.AssociatedLicenseDetail, LicensureLandingComponentKeys.AssociatedLicenseDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseDetail);
    registerRouteInfo(LicensureLandingComponentKeys.AssociatedLicenseDetailLazy, LicensureLandingComponentKeys.AssociatedLicenseDetailLazy, ReachScenarios.LandingPage, LicensureLandingComponentKeys.AssociatedLicenseDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.LicenseStatusChangeDetail, LicensureLandingComponentKeys.LicenseStatusChangeDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseStatusChangeDetail);
    registerRouteInfo(LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy, LicensureLandingComponentKeys.LicenseStatusChangeDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.LicenseStatusChangeDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.OrganizationalPayorDetail, LicensureLandingComponentKeys.OrganizationalPayorDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.OrganizationalPayorDetail);
    registerRouteInfo(LicensureLandingComponentKeys.OrganizationalPayorDetailLazy, LicensureLandingComponentKeys.OrganizationalPayorDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.OrganizationalPayorDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.ProfileDetail, LicensureLandingComponentKeys.ProfileDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.ProfileDetail);
    registerRouteInfo(LicensureLandingComponentKeys.ProfileDetailLazy, LicensureLandingComponentKeys.ProfileDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.ProfileDetailLazy);

    registerRouteInfo(LicensureLandingComponentKeys.SupervisionDetail, LicensureLandingComponentKeys.SupervisionDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.SupervisionDetail);
    registerRouteInfo(LicensureLandingComponentKeys.SupervisionDetailLazy, LicensureLandingComponentKeys.SupervisionDetail, ReachScenarios.LandingPage, LicensureLandingComponentKeys.SupervisionDetailLazy);
  }

  private static initializeSearchLazyComponents() {
    registerDynamicComponent(ReachScenarios.OnlineEntitySearch, SearchComponentKeys.EntityLazy, null, null, null, import('@licensureCore/features/onlineEntitySearch/online-entity-search.module').then(mod => mod.OnlineEntitySearchModule));
    registerDynamicComponent(ReachScenarios.CeSponsorSearch, SearchComponentKeys.CeSponsorLazy, null, null, null, import('@licensureCore/features/ceSponsorSearch/services/ce-sponsor-search.module').then(mod => mod.CeSponsorSearchModule));
    registerDynamicComponent(ReachScenarios.CourseSearch, SearchComponentKeys.CourseLazy, null, null, null, import('@licensureCore/features/courseSearch/modules/course-search.module').then(mod => mod.CourseSearchModule));
  }

  private static initializeSearchRoutes() {
    registerRouteInfo(SearchComponentKeys.Entity, SearchComponentKeys.Entity, ReachScenarios.OnlineEntitySearch, SearchComponentKeys.Entity);
    registerRouteInfo(SearchComponentKeys.EntityLazy, SearchComponentKeys.Entity, ReachScenarios.OnlineEntitySearch, SearchComponentKeys.EntityLazy);
    registerRouteInfo(SearchComponentKeys.CeSponsor, SearchComponentKeys.CeSponsor, ReachScenarios.CeSponsorSearch, SearchComponentKeys.CeSponsor);
    registerRouteInfo(SearchComponentKeys.CeSponsorLazy, SearchComponentKeys.CeSponsor, ReachScenarios.CeSponsorSearch, SearchComponentKeys.CeSponsorLazy);
    registerRouteInfo(SearchComponentKeys.Course, SearchComponentKeys.Course, ReachScenarios.CourseSearch, SearchComponentKeys.Course);
    registerRouteInfo(SearchComponentKeys.CourseLazy, SearchComponentKeys.Course, ReachScenarios.CourseSearch, SearchComponentKeys.CourseLazy);
  }
}
