// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';

// Core
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@coreComponents/reach-dialog/reach-dialog-content.component';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

export const DynamicContentModalComponentSelector = 'dynamic-content-modal';
export const DynamicContentModalComponentKey = 'dynamicContentModal';

@Component({
  selector: DynamicContentModalComponentSelector,
  templateUrl: './dynamic-content-modal.component.html',
  styleUrls: ['./dynamic-content-modal.component.scss']
})
export class DynamicContentModalComponent extends ReachDialogContentComponent<any> implements IReachDialogContentComponent {

  // FIELDS
  dynamicContentModel: any;
  dataLoaded: boolean = false;

  // CTOR
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {
    this.dynamicContentModel = this.outputModel;
    this.dataLoaded = true;

    super.modelToForm();
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.Default, DynamicContentModalComponentKey, DynamicContentModalComponent, DynamicContentModalComponentSelector);
