import { Component, Input } from '@angular/core';

import { ReachDynamicComponentInfo } from '@coreServices/reach-component-factory.service';

@Component({
  selector: 'reach-container',
  templateUrl: './reach-container.component.html',
  styleUrls: ['./reach-container.component.scss']
})
export class ReachContainerComponent {
  @Input() componentInfo: ReachDynamicComponentInfo = null;

  constructor() { }

}
