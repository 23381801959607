import { IBaseDto,
IUserInfoDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class UserInfoDto extends BaseDto implements IBaseDto, IUserInfoDto {
	UserId: string;
	FirstName: string;
	LastName: string;
	Initials: string;
	Phone: string;
	Title: string;
	Email: string;
	InternalEmail: string;
	UnitId?: number;
	EntityId?: number;
	IsActive?: boolean;
	MiddleName: string;
	RightCodes: string[];
	FLName: string;
	FMLName: string;
	FMILName: string;

}
