import { IDocumentDto,
IRelatedToBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { IEducationProgramComplianceDocumentSubmissionDto } from './licensure-shared-dto';

export class EducationProgramComplianceDocumentSubmissionDto extends BaseDto implements IEducationProgramComplianceDocumentSubmissionDto {
	ComplianceId: number;
	EducationProgramId: number;
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	Documents: IDocumentDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	EntityId?: number;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;

}
