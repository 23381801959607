import { ICashMasterDto,
IDocumentDto,
IDomainAuditDto,
IDomainChecklistItemDto,
IDomainFormDto,
IDomainReasonForChangeDtoHost,
IRelatedToBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeResponseTypeDto,
IRenewalDto } from './licensure-shared-dto';

export class RenewalDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IRenewalDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	LicenseId: number;
	LicenseNumber: number;
	LicenseTypeCode: string;
	ReminderCorrespondenceId?: number;
	AmountDue?: number;
	AmountAdjustment: number;
	RenewalCompleteDate?: Date;
	IsIncomplete: boolean;
	IsCruReferred: boolean;
	IsCmeAudit?: boolean;
	CanDispense: string;
	CmeExtensionDate?: Date;
	IsCmeDue: boolean;
	CmeRequiredHoursMessage: string;
	CmeAuditCompleteDate?: Date;
	LicenseCardCorrespondenceId?: number;
	EffectiveDate?: Date;
	ExpireDate?: Date;
	IsWebRenewal: boolean;
	NotRenewingOnlineReasonId?: number;
	NotRenewingOnlineReasonOther: string;
	IsHold: boolean;
	HoldReason: string;
	SurveyStatusId?: number;
	TypeId: number;
	CeResponseTypeId?: number;
	CeHoursNeeded?: number;
	CeResponseDate?: Date;
	Level?: number;
	CeResponseType: ICeResponseTypeDto;
	SurveyStatus: any;
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	DomainAudit: IDomainAuditDto;
	Cash: ICashMasterDto[];
	HostHasIncompleteDomainForm?: boolean;
	DomainForms: IDomainFormDto[];
	DomainAudits: IDomainAuditDto[];
	EntityId?: number;
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];

}
