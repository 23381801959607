// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, Routes } from '@angular/router';
import { Observable } from "rxjs";
import { retry, catchError, tap, map } from "rxjs/operators";
import * as _ from 'underscore';
import * as _l from 'lodash-es';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { DynamicContentConfigurationProviderService } from '@coreServices/dynamic-content-configuration-provider.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';

/**
 * Service that supports the User Registration Wizard.
 */
@Injectable({
    providedIn: 'root'
})
export class LicenseCertificationFeatureConfigurationService {
    private isInitialized = false;
    private licenseCertificationConstantsService: ILicenseCertificationConstantsService;
    public directiveConfiguration = {
      licenseCertificationDirectiveConfiguration: {
      }
    };
    public featureRoot: string = null;
    public SUPPORTED_ROUTE_PATTERNS = {

    };
    public supportedLicenseTypes = [];

    constructor(
        @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
        @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService:
            DefaultProviderConfigurationService,
        private dynamicRoutesService: DynamicRoutesService,
        private onlineServiceMenuService: OnlineServiceMenuService,
        private router: Router
    ) {
        this.licenseCertificationConstantsService = this.constantsService as ILicenseCertificationConstantsService;
        this.featureRoot = this.defaultProviderConfigurationService.applicationRootUri + "/licenseCertification";
    }

    public initialize() {
        if (!this.isInitialized) {
            // configure constants
            this.configureConstants();

            // Decorate
            this.configureDecorators();

            // Routes
            this.configureRoutes();

            this.isInitialized = true;
        }
    }

    public licenseTypeSupportsCertification (licenseType) {
      if (!licenseType) {
        throw "licenseType is required";
      }

      if (this.supportedLicenseTypes.indexOf(licenseType) >= 0) {
        return true;
      }

      return false;
    }

    public canLicenseBeRenewed (licenseInfo) {
      if (!licenseInfo) {
        throw "licenseInfo is required";
      }

      // Not all license types support the certification feature so we allow renewal in those cases.
      if (!this.licenseTypeSupportsCertification(licenseInfo.LicenseType.Code)) {
        return true;
      }

      return true;
    }

    /**
   * Decorate the ConstantsService with UserRegistration-specific constants.
   */
    private configureConstants() {
      ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS,
          LicenseCertificationConstantsService.Mergers.FEATURE_CONSTANTS);
    }

    private configureDecorators() {
    }

    /**
     * Configure dynamic UserRegistration routes.
     * @param $routeProvider
     * @param routeConfigurationProvider
     * @param dynamicContentConfigurationProvider
     * @param authorizationConfigurationProvider
     * @param constantsServiceProvider
     */
    private configureRoutes() {
    }
}

/**
 * User Registration setting constants to merge into ConstantsService.
 */
export class LicenseCertificationConstantsService extends ConstantsService {
    public static Mergers = {
      FEATURE_CONSTANTS: {
        LICENSE_CERTIFICATION: {
          CERTIFICATION_STATUS: {
            ACTIVE: 1,
            EXPIRED: 2,
            WITHDRAWN: 3
          }
        }
      }
    };

    FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS),
      LicenseCertificationConstantsService.Mergers.FEATURE_CONSTANTS);
}

export interface ILicenseCertificationConstantsService extends LicenseCertificationConstantsService {
}
