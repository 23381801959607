import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachControlValidators, ReachDialogContentComponentV2 } from 'src/app/core/index-directives';
import { Command, DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, DialogSettings, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, DocumentService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { IEmploymentDto } from 'src/app/licensureShared/licensure-shared.module';
import { EmploymentListConfiguration } from '../../employmentList/model/employment-list-configuration';
import { LicenseSelectorFieldModel } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-model';
import { LicenseSelectorFieldConfiguration, LicenseSelectorFieldConfigurationSearchCriteria } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-configuration';

export const EmployeeListEditorComponentSelector: string = "employee-list-editor";
export const EmployeeListEditorComponentKey: string = "EmployeeListEditor";

@Component({
  selector: EmployeeListEditorComponentSelector,
  templateUrl: './employee-list-editor.component.html',
  styleUrls: ['./employee-list-editor.component.scss']
})
export class EmployeeListEditorComponent extends ReachDialogContentComponentV2<IEmploymentDto> implements OnInit {

  isLoaded: boolean;
  config: EmploymentListConfiguration;

  searchCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings;

  licenseSelectorComponentModel: LicenseSelectorFieldModel;
  licenseSelectorFieldConfig: LicenseSelectorFieldConfiguration;

  licenseSelectorFieldSelectionChanged(licenseSelectorFieldModel: LicenseSelectorFieldModel) {
    this.model.EntityId = licenseSelectorFieldModel.licenseeEntityId;
    this.model.EntityName = licenseSelectorFieldModel.selectedLicenseeName;
    if (this.model.IsNew) this.contentForm.get("Licensee").setValue(this.model.EntityId);
  }

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    public documentService: DocumentService,
    validationManagerService: ValidationManagerService,
    protected commandService: CommandService,
    protected utilitiesService: UtilitiesService) {

    super(constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.licenseSelectorFieldConfig = new LicenseSelectorFieldConfiguration("Licensee", "Select Licensee", new LicenseSelectorFieldConfigurationSearchCriteria("Individual", ["RP", "PT"]), false); // TODO: PHA STUFF IN LICENSURE
    this.licenseSelectorComponentModel = new LicenseSelectorFieldModel(null, null, this.model.EntityId);

    this.initDropdowns();
  }

  override modelToForm(): void {
    super.modelToForm();

    this.setFriendlyNames();
    this.config = this.dialogDataInjector.dialogData.initializationData.settings;

    if (this.model.IsNew) this.contentForm.addControl("Licensee", new FormControl(null, Validators.required));
    this.contentForm.addControl("BeginDate", new FormControl(this.model.BeginDate ? new Date(this.model.BeginDate) : null, [ReachControlValidators.pastDateValidator('Start Date'), Validators.required]));
    this.contentForm.addControl("EndDate", new FormControl(this.model.EndDate ? new Date(this.model.EndDate) : null, ReachControlValidators.pastDateValidator('End Date')));

    if (this.config.employmentTypeEnabled) this.contentForm.addControl('EmploymentType', new FormControl(null, [Validators.required]));
    if (this.config.isSettingEnabled) this.contentForm.addControl("EmploymentSetting", new FormControl(null, [Validators.required]));
    if (!this.config.isSettingRequired) this.contentForm.get("EmploymentSetting").clearValidators();
    if (this.config.isSectorEnabled) this.contentForm.addControl("EmploymentSector", new FormControl(null, [Validators.required]));
    if (!this.config.isSectorRequired) this.contentForm.get("EmploymentSector").clearValidators();
    this.config.positionPickListEnabled
      ? this.contentForm.addControl('PositionTitle', new FormControl(null, [Validators.required, Validators.maxLength(50)]))
      : this.contentForm.addControl('PositionTitle', new FormControl(this.model.PositionTitle, [Validators.required, Validators.maxLength(50)]));
  }

  override formToModel(): void {
    if (!this.isLoaded) return;

    if (this.config.positionPickListEnabled) {
      this.model.PositionTitleId = this.contentForm.get('PositionTitle').value?.Id
      this.model.EmploymentPositionTitle = this.config.possiblePositionTitles.find(i => i.Id === this.model.PositionTitleId);
    }
    else {
      this.model.PositionTitle = this.contentForm.get('PositionTitle').value;
    }

    if (this.config.employmentTypeEnabled) {
      this.model.TypeId = this.contentForm.get('EmploymentType').value?.Id;
      this.model.EmploymentType = this.config.possibleEmploymentTypes.find(i => i.Id === this.model.TypeId);
    }

    this.model.BeginDate = this.contentForm.get('BeginDate').value;
    this.model.EndDate = this.contentForm.get('EndDate').value;

    if (this.config.isSettingEnabled) this.model.SettingId = this.contentForm.get('EmploymentSetting').value?.Id;
    if (this.config.isSectorEnabled) this.model.SectorId = this.contentForm.get('EmploymentSector').value?.Id;
  }

  private setFriendlyNames() {
    this.friendlyNames.EmployerName = "Employer Name";
    this.friendlyNames.PositionTitle = "Position";
    this.friendlyNames.EmploymentType = "Type";
    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";
    this.friendlyNames.EmploymentSetting = "Setting";
    this.friendlyNames.EmploymentSector = "Sector";
  }

  initDropdowns(): void {

    if (this.config.positionPickListEnabled) {
      this.contentForm.get('PositionTitle').setValue(this.config.possiblePositionTitles.find(item => item.Id == this.model.PositionTitleId));
      this.config.possiblePositionTitles = this.config.possiblePositionTitles.filter(title => title.IsEditableOnline || title.IsAddableOnline);

      if (this.config.possiblePositionTitles.length == 1 && !this.model.PositionTitleId) this.contentForm.get('PositionTitle').setValue(this.config.possiblePositionTitles[0]);
      if (this.config.possiblePositionTitles.length == 0) console.log("There are no position titles that are allowed online.");
    }

    if (this.config.employmentTypeEnabled && this.model.TypeId) {
      this.contentForm.get('EmploymentType').setValue(this.config.possibleEmploymentTypes.find(item => item.Id == this.model.TypeId) ?? null);
    }

    if (this.config.isSettingEnabled && (this.model as IEmploymentDto).SettingId) this.contentForm.get('EmploymentSetting')?.setValue(this.config.possibleEmploymentSettings.find(item => item.Id == (this.model as IEmploymentDto).SettingId));
    if (this.config.isSectorEnabled && (this.model as IEmploymentDto).SectorId) this.contentForm.get('EmploymentSector')?.setValue(this.config.possibleEmploymentSectors.find(item => item.Id == (this.model as IEmploymentDto).SectorId));

    this.isLoaded = true;
  }
}

registerDynamicComponent(ReachScenarios.Default, EmployeeListEditorComponentKey, EmployeeListEditorComponent, EmployeeListEditorComponentSelector);
