import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Prime NG
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CaptchaModule } from 'primeng/captcha';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TreeTableModule } from 'primeng/treetable';

// Angular Material
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { CoreSharedModule } from '../coreShared/core-shared.module';
import { CoreRoutingModule } from './core-routing.module';

// Component imports
import * as idxDirectives from './index-directives';

export * from './index-directives';
export * from './index-models';
export * from './index-services';
export * from './index-constants';

@NgModule({
  declarations: [
    idxDirectives.ArraySortPipe,
    idxDirectives.BoolToCheckmarkGlyphPipe,
    idxDirectives.CartSummaryComponent,
    idxDirectives.ChangePasswordComponent,
    idxDirectives.PasswordTokenResetComponent,
    idxDirectives.ChangeSecurityQuestionsComponent,
    idxDirectives.CheckboxContentComponent,
    idxDirectives.ChecklistCommentComponent,
    idxDirectives.CommandButtonDirective,
    idxDirectives.DataRequestRequestHostComponent,
    idxDirectives.DisplayAddressPipe,
    idxDirectives.NewlineToLineBreakPipe,
    idxDirectives.DisplayCityStatePipe,
    idxDirectives.DisplayEntityNamePipe,
    idxDirectives.DisplayGenderPipe,
    idxDirectives.DisplayPhoneNumberPipe,
    idxDirectives.DisplaySsnPipe,
    idxDirectives.DomainChecklistItemComponent,
    idxDirectives.DomainChecklistItemSetComponent,
    idxDirectives.DomainIdentifierComponent,
    idxDirectives.DomainQuestionnaireComponent,
    idxDirectives.DomainQuestionnaireQuestionComponent,
    idxDirectives.DynamicContentModalComponent,
    idxDirectives.EntityAddressComponent,
    idxDirectives.EntityAddressFacilityComponent,
    idxDirectives.EntityAddressEditorComponent,
    idxDirectives.EntityAddressListComponent,
    idxDirectives.EntityAddressMbComponent,
    idxDirectives.EntityAddressFacilityComponent,
    idxDirectives.EntityEmailEditorComponent,
    idxDirectives.EntityPhoneNumberEditorComponent,
    idxDirectives.EntityPhoneNumberListComponent,
    idxDirectives.EntityWebsiteEditorComponent,
    idxDirectives.FilterListPipe,
    idxDirectives.FilterListImpurePipe,
    idxDirectives.FieldHelpComponent,
    idxDirectives.GeneralLandingCardComponent,
    idxDirectives.CartLandingCardComponent,
    idxDirectives.LandingCardHostComponent,
    idxDirectives.LandingPageComponent,
    idxDirectives.MailingAddressPipe,
    idxDirectives.MenuItemComponent,
    idxDirectives.MessageAlertComponent,
    idxDirectives.MessageSummaryComponent,
    idxDirectives.MessageToggleButtonComponent,
    idxDirectives.LandingDetailHeaderComponent,
    idxDirectives.LandingPageFileUploadComponent,
    idxDirectives.LandingPageFileUploadEditorComponent,
    idxDirectives.LandingPageEntityAddressComponent,
    idxDirectives.LandingPageEntityAddressEditorComponent,
    idxDirectives.OnlineServiceLinkComponent,
    idxDirectives.OnlineServiceMenuComponent,
    idxDirectives.PageSummaryTextComponent,
    idxDirectives.PickListComponent,
    idxDirectives.PickListEditorComponent,
    idxDirectives.QuestionnaireQuestionComponent,
    idxDirectives.RangeListComponent,
    idxDirectives.RangeListEditorComponent,
    idxDirectives.ReachAppHeaderComponent,
    idxDirectives.ReachAutocompleteOffDirective,
    idxDirectives.ReachBreadcrumbComponent,
    idxDirectives.ReachCaptchaComponent,
    idxDirectives.ReachCardsetComponent,
    idxDirectives.ReachContainerComponent,
    idxDirectives.ReachDialogComponent,
    idxDirectives.ReachDocumentDownloaderComponent,
    idxDirectives.ReachDynacardComponent,
    idxDirectives.ReachNavbarComponent,
    idxDirectives.ReachPrintHeaderComponent,
    idxDirectives.ReachRegionComponent,
    idxDirectives.SsnComponent,
    idxDirectives.TooltipDirective,
    idxDirectives.TruncatePipe,
    idxDirectives.WhenEmptyPipe,
    idxDirectives.WizardComponent,
    idxDirectives.WizardHelpComponent,
    idxDirectives.WizardStepDetailDirective,
    idxDirectives.WizardStepDetailDirective,
    idxDirectives.YesNoPipe,
    idxDirectives.YesNoDropdownComponent,
    idxDirectives.ChecklistItemListComponent,
    idxDirectives.InformationAcknowledgementComponent,
    idxDirectives.CheckboxContentItemsComponent,
    idxDirectives.FileUploadListComponent,
    idxDirectives.FileUploadEditorComponent,
    idxDirectives.FileUploadComponent,
    idxDirectives.OptionalFileUploadComponent,
    idxDirectives.ReviewComponent,
    idxDirectives.EntityAddressDbpComponent,
    idxDirectives.EntityAssociationComponent,
    idxDirectives.EntityAssociationEditorComponent,
    idxDirectives.ChecklistFileUploadButtonComponent,
    idxDirectives.TennessenComponent,
    idxDirectives.KeywordListComponent,
    idxDirectives.ReachDialogContentComponentV2,
    idxDirectives.SurveyCallbackComponent,
    idxDirectives.ChecklistFileUploadComponent,
    idxDirectives.ChecklistFileUploadItemsComponent,
    idxDirectives.ChecklistFileUploadItemsEditorComponent,
    idxDirectives.RadioContentItemsComponent,
    idxDirectives.KeywordInfoComponent,
    idxDirectives.RelatedActivityListComponent,
    idxDirectives.ActivityDetailComponent
  ],
  imports: [
    CommonModule,
    CoreSharedModule,
    CoreRoutingModule,
    DialogModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    ButtonModule,
    MenubarModule,
    MenuModule,
    PanelModule,
    MessageModule,
    MessagesModule,
    PanelMenuModule,
    TableModule,
    InputTextareaModule,
    ScrollPanelModule,
    CheckboxModule,
    MatStepperModule,
    MatIconModule,
    BlockUIModule,
    ToastModule,
    CalendarModule,
    InputTextModule,
    CaptchaModule,
    FileUploadModule,
    TooltipModule,
    InputMaskModule,
    InputNumberModule,
    BadgeModule,
    RadioButtonModule,
    DropdownModule,
    PasswordModule,
    DividerModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TreeTableModule
  ],
  providers: [idxDirectives.DisplayEntityNamePipe],
  exports: [
    TreeTableModule,
    AccordionModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CaptchaModule,
    CheckboxModule,
    DividerModule,
    DropdownModule,
    FileUploadModule,
    InputTextModule,
    MenubarModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    PanelMenuModule,
    PanelModule,
    PasswordModule,
    RadioButtonModule,
    TableModule,
    InputTextareaModule,
    ScrollPanelModule,
    MatStepperModule,
    MatIconModule,
    ToastModule,
    TooltipModule,
    InputMaskModule,
    InputNumberModule,
    BadgeModule,
    ConfirmDialogModule,
    MultiSelectModule,
    idxDirectives.ArraySortPipe,
    idxDirectives.BoolToCheckmarkGlyphPipe,
    idxDirectives.CartSummaryComponent,
    idxDirectives.ChangePasswordComponent,
    idxDirectives.PasswordTokenResetComponent,
    idxDirectives.ChangeSecurityQuestionsComponent,
    idxDirectives.CheckboxContentComponent,
    idxDirectives.ChecklistCommentComponent,
    idxDirectives.CommandButtonDirective,
    idxDirectives.DataRequestRequestHostComponent,
    idxDirectives.DisplayAddressPipe,
    idxDirectives.NewlineToLineBreakPipe,
    idxDirectives.DisplayCityStatePipe,
    idxDirectives.DisplayEntityNamePipe,
    idxDirectives.DisplayGenderPipe,
    idxDirectives.DisplayPhoneNumberPipe,
    idxDirectives.DisplaySsnPipe,
    idxDirectives.DomainChecklistItemComponent,
    idxDirectives.DomainChecklistItemSetComponent,
    idxDirectives.DomainIdentifierComponent,
    idxDirectives.DomainQuestionnaireComponent,
    idxDirectives.DomainQuestionnaireQuestionComponent,
    idxDirectives.DynamicContentModalComponent,
    idxDirectives.EntityAddressComponent,
    idxDirectives.EntityAddressFacilityComponent,
    idxDirectives.EntityAddressEditorComponent,
    idxDirectives.EntityAddressListComponent,
    idxDirectives.EntityAddressMbComponent,
    idxDirectives.EntityEmailEditorComponent,
    idxDirectives.EntityPhoneNumberEditorComponent,
    idxDirectives.EntityPhoneNumberListComponent,
    idxDirectives.EntityWebsiteEditorComponent,
    idxDirectives.FilterListPipe,
    idxDirectives.FilterListImpurePipe,
    idxDirectives.FieldHelpComponent,
    idxDirectives.GeneralLandingCardComponent,
    idxDirectives.CartLandingCardComponent,
    idxDirectives.LandingCardHostComponent,
    idxDirectives.LandingPageComponent,
    idxDirectives.LandingPageFileUploadComponent,
    idxDirectives.LandingPageFileUploadEditorComponent,
    idxDirectives.LandingPageEntityAddressComponent,
    idxDirectives.LandingPageEntityAddressEditorComponent,
    idxDirectives.MailingAddressPipe,
    idxDirectives.MenuItemComponent,
    idxDirectives.MessageAlertComponent,
    idxDirectives.MessageSummaryComponent,
    idxDirectives.MessageToggleButtonComponent,
    idxDirectives.LandingDetailHeaderComponent,
    idxDirectives.OnlineServiceLinkComponent,
    idxDirectives.OnlineServiceMenuComponent,
    idxDirectives.PageSummaryTextComponent,
    idxDirectives.PickListComponent,
    idxDirectives.PickListEditorComponent,
    idxDirectives.QuestionnaireQuestionComponent,
    idxDirectives.RangeListComponent,
    idxDirectives.RangeListEditorComponent,
    idxDirectives.ReachAppHeaderComponent,
    idxDirectives.ReachAutocompleteOffDirective,
    idxDirectives.ReachBreadcrumbComponent,
    idxDirectives.ReachCaptchaComponent,
    idxDirectives.ReachCardsetComponent,
    idxDirectives.ReachContainerComponent,
    idxDirectives.ReachDialogComponent,
    idxDirectives.ReachDocumentDownloaderComponent,
    idxDirectives.ReachDynacardComponent,
    idxDirectives.ReachNavbarComponent,
    idxDirectives.ReachPrintHeaderComponent,
    idxDirectives.ReachRegionComponent,
    idxDirectives.SsnComponent,
    idxDirectives.TooltipDirective,
    idxDirectives.TruncatePipe,
    idxDirectives.WhenEmptyPipe,
    idxDirectives.WizardComponent,
    idxDirectives.WizardHelpComponent,
    idxDirectives.WizardStepDetailDirective,
    idxDirectives.WizardStepDetailDirective,
    idxDirectives.YesNoPipe,
    idxDirectives.YesNoDropdownComponent,
    idxDirectives.ChecklistItemListComponent,
    idxDirectives.FileUploadListComponent,
    idxDirectives.FileUploadEditorComponent,
    idxDirectives.FileUploadComponent,
    idxDirectives.OptionalFileUploadComponent,
    idxDirectives.CheckboxContentItemsComponent,
    idxDirectives.ReviewComponent,
    idxDirectives.EntityAddressDbpComponent,
    idxDirectives.ChecklistFileUploadButtonComponent,
    idxDirectives.TennessenComponent,
    idxDirectives.KeywordListComponent,
    idxDirectives.ReachDialogContentComponentV2,
    idxDirectives.SurveyCallbackComponent,
    idxDirectives.ChecklistFileUploadComponent,
    idxDirectives.ChecklistFileUploadItemsComponent,
    idxDirectives.ChecklistFileUploadItemsEditorComponent,
    idxDirectives.RadioContentItemsComponent,
    idxDirectives.KeywordInfoComponent,
    idxDirectives.RelatedActivityListComponent,
    idxDirectives.ActivityDetailComponent
  ]
})
export class CoreModule { }
