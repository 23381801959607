import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { Command, OnlineServiceRegistry } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, DialogSettingsService, DynamicRoutesService, SystemSettingsManagerService, UserManagerService, UtilitiesService } from 'src/app/core/index-services';
import { ISupervisionFeatureConstantsService, LicensureOnlineServiceProcessTypeKeys, SupervisionFeatureConstantsService } from 'src/app/licensureCore/licensure-core.module';
import { ILicensureConstantsProviderService } from 'src/app/licensureCore/services/licensure-constants-provider.service';
import { IApplicationInfoDto, ILicenseInfoDto, ISupervisionPlanSearchResultItemDto } from 'src/app/licensureShared/licensure-shared.module';

export const SupervisionPlanListComponentSelector: string = "supervision-plan-list";
export const SupervisionPlanListComponentKey: string = "supervisionPlanList";

@Component({
  selector: SupervisionPlanListComponentSelector,
  templateUrl: './supervision-plan-list.component.html',
  styleUrls: ['./supervision-plan-list.component.scss']
})
export class SupervisionPlanListComponent implements OnInit {

  @Input()
  public SupervisionPlans: ISupervisionPlanSearchResultItemDto[];
  @Input()
  public LicenseInfo: ILicenseInfoDto;
  @Input()
  public ApplicationInfo: IApplicationInfoDto;

  public continuePlanWizardCommand: Command;
  public newPlanCommand: Command;
  public newVerificationCommand: Command;
  private supervisionConstants: SupervisionFeatureConstantsService;

  public isPracticeLocationEnabled: boolean;
  public isEmploymentEnabled: boolean;
  public isEndDateEnabled: boolean;

  public get subject(): ILicenseInfoDto | IApplicationInfoDto {
    return this.LicenseInfo ?? this.ApplicationInfo;
  }

  public get columnLength(): number {
    let columns = 10;

    if (!this.isPracticeLocationEnabled) columns--;
    if (!this.isEmploymentEnabled) columns--;
    if (!this.isEndDateEnabled) columns--;

    return columns
  }

  constructor(protected commandService: CommandService
    , protected userManagerService: UserManagerService
    , protected dialogSettingsService: DialogSettingsService
    , protected utilitiesService: UtilitiesService
    , protected systemSettingsManagerService: SystemSettingsManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , private router: Router, private dynamicRouteService: DynamicRoutesService) {

    this.isPracticeLocationEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_PLANS.PRACTICE_LOCATION_TEXT_ENABLED);
    this.isEmploymentEnabled = this.systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.ENABLED);
    this.isEndDateEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_PLANS.END_DATE_ENABLED);
  }

  ngOnInit(): void {
    this.supervisionConstants = this.constantsService as ISupervisionFeatureConstantsService;
    this.initCommands();
  }

  private initCommands() {
    this.newPlanCommand = this.commandService.create(this.canNewPlanCommandExecute, this.newPlanCommandExecute);
    this.continuePlanWizardCommand = this.commandService.create((item: ISupervisionPlanSearchResultItemDto) => this.canContinuePlanWizardCommandExecute(item), this.continuePlanWizardCommandExecute);
    this.newVerificationCommand = this.commandService.create((item: ISupervisionPlanSearchResultItemDto) => this.canNewVerificationCommandExecute(item), this.newVerificationCommandExecute);
  }

  protected canNewPlanCommandExecute = (): boolean => {
    return true;
  }

  protected newPlanCommandExecute = () => {
    let domainId = this.LicenseInfo ? this.constantsService.DOMAIN_IDS.LICENSE : this.constantsService.DOMAIN_IDS.APPLICATION;
    let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SuperviseePlan).selectorPathTemplate
      , domainId
      , this.subject.Id);

    return from(this.router.navigate(patternUrl));
  }

  protected canContinuePlanWizardCommandExecute = (item: ISupervisionPlanSearchResultItemDto): boolean => {
    if (this.systemSettingsManagerService.get(this.supervisionConstants.SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_PLANS.SUPERVISEE_ENABLED).Value !== "true") return false;
    return item.StatusId === this.supervisionConstants.FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_STATUS.STARTED;
  }

  protected continuePlanWizardCommandExecute = (item: ISupervisionPlanSearchResultItemDto) => {

    let domainId = this.LicenseInfo ? this.constantsService.DOMAIN_IDS.LICENSE : this.constantsService.DOMAIN_IDS.APPLICATION;
    let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SuperviseePlan).onlineServicePathTemplate
      , this.subject.EntityId
      , domainId
      , this.subject.Id
      , item.Id
      , item.WebServiceHistoryId);

    return from(this.router.navigate(patternUrl));
  }

  protected canNewVerificationCommandExecute = (item: ISupervisionPlanSearchResultItemDto): boolean => {
    if (this.systemSettingsManagerService.get(this.supervisionConstants.SYSTEM_SETTING_KEYS.SUPERVISION.ONLINE_PLANS.SUPERVISEE_ENABLED).Value !== "true")
      return false;

    return item.StatusId == this.supervisionConstants.FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_STATUS.SUBMITTED
      || item.StatusId == this.supervisionConstants.FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_STATUS.IN_PROCESS
      || item.StatusId == this.supervisionConstants.FEATURE_CONSTANTS.SUPERVISION.SUPERVISION_PLAN_STATUS.APPROVED;
  }

  protected newVerificationCommandExecute = (item: ISupervisionPlanSearchResultItemDto) => {
    let domainId = this.LicenseInfo ? this.constantsService.DOMAIN_IDS.LICENSE : this.constantsService.DOMAIN_IDS.APPLICATION;
    let patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisionVerification).selectorPathTemplate
      , this.subject.EntityId
      , domainId
      , this.subject.Id
      , item.Id);

    return from(this.router.navigate(patternUrl));
  }
}
