import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';
import { IChallengeInfoDto, IOnlineUserDto, IOnlineUserSearchCriteriaDto, OnlineUserDto } from '@coreShared/core-shared.module';

@Injectable({ providedIn: 'root' })
export class OnlineUserService extends ReachHttpClientService {

  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
  }

  public resetPassword(challenge: IChallengeInfoDto): Observable<IChallengeInfoDto> {
    return this.put<IChallengeInfoDto>(`${this.apiRootUri}/onlineUser/password`, challenge);
  }

  public changePassword(onlineUser: IOnlineUserDto, captchaResponse: string): Observable<IOnlineUserDto> {
    return this.put<OnlineUserDto>(`${this.apiRootUri}/onlineUser/changePassword?${"captcharesponse"}=${captchaResponse}`, onlineUser);
  }

  public changeSecurityQuestions(onlineUser: IOnlineUserDto, captchaResponse: string): Observable<IOnlineUserDto> {
    return this.put<OnlineUserDto>(`${this.apiRootUri}/onlineUser/SecurityQuestions?${"captcharesponse"}=${captchaResponse}`, onlineUser);
  }

  public findByPasswordVerificationToken(passwordVerificationToken: string): Observable<IOnlineUserDto> {
    return this.get<OnlineUserDto>(`${this.apiRootUri}/onlineUser/Search?passwordVerificationToken=${encodeURIComponent(passwordVerificationToken)}`);
  }

  public initializeUser(): Observable<IOnlineUserDto> {
    return this.get<OnlineUserDto>(`${this.apiRootUri}/onlineUser/Initialize`);
  }

  public initializeChallenge(onlineUser: IOnlineUserDto): Observable<IChallengeInfoDto> {
    return this.post<IChallengeInfoDto>(`${this.apiRootUri}/onlineUser/initialize/challenge`, onlineUser);
  }

  public challenge(challenge: IChallengeInfoDto): Observable<IChallengeInfoDto> {
    return this.post<IChallengeInfoDto>(`${this.apiRootUri}/onlineUser/challenge`, challenge);
  }

  public search(onlineUserSearchCriteria: IOnlineUserSearchCriteriaDto, captchaResponse: string): Observable<OnlineUserDto> {
    return this.post<OnlineUserDto>(`${this.apiRootUri}/onlineUser/search?${"captcharesponse"}=${captchaResponse}`, onlineUserSearchCriteria);
  }

  public save(user: OnlineUserDto, validationScope: string = null): Observable<OnlineUserDto> {
    if (!user) {
      throw this.argumentExceptionService.create("user").log();
    }

    if (user.Id) {
      return this.put<OnlineUserDto>(`${this.apiRootUri}/onlineUser`, user, validationScope);
    } else {
      return this.post<OnlineUserDto>(`${this.apiRootUri}/onlineUser`, user, validationScope);
    }
  }

  public getByEntityId(entityId: number): Observable<OnlineUserDto> {
    if (!entityId) {
      throw this.argumentExceptionService.create("id").log();
    }

    return this.get<OnlineUserDto>(`${this.apiRootUri}/onlineUser/entity/${entityId}`);
  }

  public getByUserName(userName: string, token: string): Observable<OnlineUserDto> {

    if (!userName) {
      throw this.argumentExceptionService.create("userName").log();
    }

    // If a token is supplied use it for auth.
    if (token) {
      return this.get<OnlineUserDto>(
        `${this.apiRootUri}/onlineUser/?userName=${encodeURIComponent(userName)}&token=${encodeURIComponent(token)}&X-Client-Identifier=B20FB678-DA63-43E4-846F-3A630E6306F7`);
    } else {
      return this.get<OnlineUserDto>(`${this.apiRootUri}/onlineUser/?userName=${encodeURIComponent(userName)}`);
    }
  }
}
