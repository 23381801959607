import { IBaseDto,
ISessionDto,
IUserRightDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class SessionDto extends BaseDto implements IBaseDto, ISessionDto {
	Initials: string;
	Token: string;
	UserId: string;
	UserName: string;
	EntityId: string;
	UserRights: IUserRightDto[];

}
