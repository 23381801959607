<div [hidden]="!isVisible">
  <p-messages [key]="messageScope" [severity]="severity" [enableService]="false">
    <ng-template pTemplate>
      <div>
        <span class="reach-notification-list-header">{{labelText}}</span>
        <ul>
          <li *ngFor="let msg of messageList" (click)="onMessageClicked($event, msg)">
            <span *ngIf="msg.summary">{{msg.summary}}: </span>{{msg.detail}}
            <i *ngIf="msg.data.errorType == serverValidationErrorType" class="tooltip-icon fa fa-question-circle text-info" [pTooltip]="itemTooltipContent"></i>
          </li>
        </ul>
      </div>
    </ng-template>
  </p-messages>
</div>
