import { BaseDto } from '@coreShared/dto-gen/base-dto';
import {
	IBaseDto,
	IEntityAddressSearchResultItemDto,
	IEntityPhoneInfoDto
} from '@coreShared/dto-gen/core-shared-dto';
import {
	ICertificationSearchResultItemDto,
	IEducationHistorySearchResultItemDto,
	ILicenseSearchResultItemDto,
	IOnlineEntitySearchResultItemDetailDto,
	IPublicActionInfoDto,
	IPublicSuperviseeSupervisorInfoDto,
	ISupervisorSearchResultItemDto
} from './licensure-shared-dto';

export class OnlineEntitySearchResultItemDetailDto extends BaseDto implements IBaseDto, IOnlineEntitySearchResultItemDetailDto {
	PublicEmail: string;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	Suffix: string;
	IsIndividual: boolean;
	DateOfBirth?: Date;
	Gender: string;
	CorrectiveActionCount: number;
	DisciplinaryActionCount: number;
	BusinessAddress: IEntityAddressSearchResultItemDto;
	DesignatedAddress: string;
	PublicPhone: IEntityPhoneInfoDto;
	Licenses: ILicenseSearchResultItemDto[];
	Certifications: ICertificationSearchResultItemDto[];
	Registrations: ILicenseSearchResultItemDto[];
	TemporaryPermits: ILicenseSearchResultItemDto[];
	PublicActions: IPublicActionInfoDto[];
	Education: IEducationHistorySearchResultItemDto[];
	EntityConvictions: any[];
	EntityTraining: any[];
	Supervisors: IPublicSuperviseeSupervisorInfoDto[];
	DomainKeywords: string;
	Supervisor: ISupervisorSearchResultItemDto;
}
