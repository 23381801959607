import { Component, ElementRef, Inject } from '@angular/core';

import * as _l from 'lodash-es';

import {
  CheckboxContentService,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  ValidationManagerService,
  WizardInjector,
  WIZARD_INJECTOR_TOKEN,
  ReachScenarios,
  registerDynamicComponent,
  BusyManagerService,
  ListService,
  DynamicContentManagerService,
  UtilitiesService,
  ReachApplicationService
} from '@core/core.module';
import { MilitaryStatusComponent, MilitaryStatusComponentSelector } from '../militaryStatus/military-status.component';

@Component({
  selector: MilitaryStatusComponentSelector,
  templateUrl: '../militaryStatus/military-status.component.html',
  styleUrls: ['./military-status-no-auto-apply.component.scss']
})
export class MilitaryStatusNoAutoApplyComponent extends MilitaryStatusComponent {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    protected listService: ListService,
    protected busyManagerService: BusyManagerService,
    protected dynamicContentManagerService: DynamicContentManagerService,
    protected utilitiesService: UtilitiesService,
    public reachApplicationService: ReachApplicationService,) {
    super(constantsService, validationManagerService, wizardInjector, checkboxContentService, elementRef, listService, busyManagerService, dynamicContentManagerService, utilitiesService, reachApplicationService);
  }

  override modelToForm(): void {

    this.isIgnoreAutoApply = true;

    super.modelToForm();
  }

}

registerDynamicComponent(ReachScenarios.ApplicationWizard, 'militaryStatusNoAutoApply', MilitaryStatusNoAutoApplyComponent, MilitaryStatusComponentSelector);
registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, 'militaryStatusNoAutoApply', MilitaryStatusNoAutoApplyComponent, MilitaryStatusComponentSelector);