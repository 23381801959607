import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, } from '@angular/forms';
import { noop } from 'rxjs';
import { IEntityAddressDtoHost } from 'src/app/coreShared/core-shared.module';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, SystemSettingsManagerService } from '../../index-services';

@Component({
  selector: 'entity-address',
  templateUrl: './entity-address.component.html',
  styleUrls: ['./entity-address.component.scss']
})
export class EntityAddressComponent implements OnInit, OnChanges {

  @Input() addressDtoHost: IEntityAddressDtoHost;
  @Input() enableNoBusinessAddressCheckbox: boolean = true;
  @Input() save: void = noop();

  public mailingAndBusinessMode: boolean;
  public dbpMode: boolean;
  public friendlyNames: any = {};
  public contentForm: FormGroup;

  constructor(
    private systemSettingsManagerService: SystemSettingsManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    private controlContainer: ControlContainer) {

    this.mailingAndBusinessMode = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.ENTITY_ADDRESS_DIRECTIVE_TYPE) == "mailingAndBusiness";
    this.dbpMode = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.ENTITY_ADDRESS_DIRECTIVE_TYPE) == "designatedBusinessPrivate";

    this.modelToForm();
  }

  modelToForm() { this.contentForm = new FormGroup({ AddressList: new FormControl() }); }

  ngOnInit(): void {
    (this.controlContainer.control as FormGroup).addControl('Address', this.contentForm);
    this.friendlyNames.AddressList = 'Address list';
  }

  ngOnChanges(): void { if (!this.addressDtoHost) throw ('An AddressDtoHost must be provided!'); }
}
