/**
 * Manages a collection of Accordion Tabs for an Accordion control.
 */
export class AccordionManager<TModel> {
  public modelArray: TModel[]
  public accordionActiveState: boolean[] = [];
  public isExpandAll: boolean = false;
  constructor() {
  }

  /**
   * Resets the AccordionManager with the specified array of model objects.
   * @param modelArray
   */
  public resetModel(modelArray: TModel[]) {
    this.clear();
    if (modelArray) {
      this.modelArray = modelArray;
      this.modelArray.forEach(m => { this.accordionActiveState.push(false); })
    }
  }

  /**
   * Expands/collapses (toggle) all of the accordion tabs.
   */
  public expandCollapseAll() {
    this.isExpandAll = !this.isExpandAll;
    this.accordionActiveState.forEach(s => s = this.isExpandAll);
  }

  /**
   * Toggles the active state of the accordion tab matching the specified index.
   * @param tabIndex the index of the target accordio tab.
   */
  toggleTab(tabIndex: number) {
    this.accordionActiveState[tabIndex] = !this.accordionActiveState[tabIndex];
  }

  /**
   * Clears the AccordionManager array of model objects.
   * @param modelArray
   */
  public clear() {
    this.modelArray = [];
  }

  /**
   *
   * Gets a value indicating whether the specified accordion tab is active.
   * @param tabIndex the index of the targeted accordion tab.
   */
  public isTabActive(tabIndex: number): boolean {
    return this.accordionActiveState[tabIndex];
  }
}

