import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayGender'
})
export class DisplayGenderPipe {
    transform(value: string): string {
        return value === "F" ? "Female" : value === "M" ? "Male" : "Other";
    }
}
