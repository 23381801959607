import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { DocumentRelatedToKeysDto, IDocumentDto, IDocumentHistoryItem, IDocumentHistorySearchCriteriaDto, IDocumentRelatedToKeysDto, IDocumentSearchCriteriaDto, IDomainChecklistItemDto, IFileUploadDtoHost, IFileUploadResponseDto } from '@coreShared/core-shared.module';
import { Observable, forkJoin, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UserManagerService } from './user-manager.service';
import { UtilitiesService } from './utilities.service';
import { ValidationManagerService } from './validation-manager.service';
import { DomainChecklistItemService } from "./domain-checklist-item.service";

@Injectable({ providedIn: 'root' })
export class DocumentService extends ReachHttpClientService {

  getHistory(searchCriteria: IDocumentHistorySearchCriteriaDto = {} as IDocumentHistorySearchCriteriaDto): Observable<IDocumentHistoryItem[]> {
    return this.post<IDocumentHistoryItem[]>(`${this.apiRootUri}/Document/GetHistory`, searchCriteria);
  }

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService,
    private argumentExceptionService: ArgumentExceptionService,
    private userManagerService: UserManagerService,
    private utilitiesService: UtilitiesService,
    private domainChecklistItemService: DomainChecklistItemService,
  ) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
  }

  public get uploadMultipleUrl(): string {
    return `${this.apiRootUri}/Document/UploadMultiple`;
  }

  public get searchUrl(): string {
    return `${this.apiRootUri}/Document/Search`;
  }

  public get uploadMaxFileSize(): number {
    return 26214400; // 25MB
  }

  /**
   * Download the document file associated with the specofied IDocumentDto.
   * @param document the IDocumentDto representing the target document.
   */
  public downloadDocument(document: IDocumentDto) {
    window.open(
      `${this.apiRootUri}/Document/file/${document.Id}?token=${encodeURIComponent(this.userManagerService.getCurrentPrincipal().token)}&X-Client-Identifier=B20FB678-DA63-43E4-846F-3A630E6306F7`,
      "Download");
  }

  public initialize(): Observable<IDocumentDto> {
    return this.get<IDocumentDto>(`${this.apiRootUri}/Document/Initialize`)
      .pipe(map(res => this.utilitiesService.withLocalId(res)));
  }

  /**
  * Upload new documents and put them back onto the IFileUploadDtoHost.
  */
  public async uploadDocuments(fileUploadDto: IFileUploadDtoHost): Promise<IFileUploadDtoHost> {

    if (fileUploadDto.Documents.filter(item => item.IsNew).length > 0) {
      const response = await this.postDocumentData(fileUploadDto.Documents.filter(item => item.IsNew)).toPromise();

      // Check validity of the response.
      (fileUploadDto as any).IsValid = response.IsValid;
      (fileUploadDto as any).AdditionalErrors = response.AdditionalErrors;
      if (!response.IsValid) return fileUploadDto;

      const newDocumentCollection = response.UploadedDocuments;
      const documentIds = newDocumentCollection.map(item => item.Id);

      fileUploadDto.Documents = newDocumentCollection.concat(fileUploadDto.Documents.filter(item => !item.IsNew))
      fileUploadDto.DocumentIds = documentIds;

      return fileUploadDto;
    }

    return fileUploadDto;

  }

  /**
   * Orchestrates the uploading of all documents associated with the given file upload DTO to the server.
   * Uploads documents from checklist items and the fileUploadDto in sequence using concatMap.
   * 
   * @param {IFileUploadDtoHost} fileUploadDto - The file upload DTO containing the documents to be uploaded.
   * @returns {Observable<IFileUploadDtoHost>} An observable emitting the updated file upload DTO once all documents are uploaded successfully.
   */
  uploadAllDocuments(fileUploadDto: IFileUploadDtoHost): Observable<IFileUploadDtoHost> {

    if (!fileUploadDto) return of(fileUploadDto);

    const firstObservable = this.uploadChecklistDocuments(fileUploadDto);
    const secondObservable = this.uploadHostDocuments(fileUploadDto);

    // Using concatMap to execute the observables IN SEQUENCE and return an Observable<IFileUploadDtoHost>
    return firstObservable.pipe(
      concatMap(() => {
        return secondObservable.pipe(
          map(secondResult => {
            return secondResult;
          })
        );
      })
    );
  }

  /**
  * Uploads host-related documents from the provided file upload DTO to the server.
  * 
  * @param {IFileUploadDtoHost} fileUploadDto - The file upload DTO containing host documents.
  * @returns {Observable<IFileUploadDtoHost>} An observable emitting the updated file upload DTO with host documents uploaded successfully.
  */
  uploadHostDocuments(fileUploadDto: IFileUploadDtoHost): Observable<IFileUploadDtoHost> {

    const noAction = of(fileUploadDto);

    return fileUploadDto.Documents.filter(item => item.IsNew).length
      ? this.postDocumentData(fileUploadDto.Documents.filter(item => item.IsNew)).pipe(
        map((response: IFileUploadResponseDto) => {

          // Check validity of the response.
          (fileUploadDto as any).IsValid = response.IsValid;
          (fileUploadDto as any).AdditionalErrors = response.AdditionalErrors;
          if (!response.IsValid) return fileUploadDto;

          fileUploadDto.Documents = response.UploadedDocuments.concat(fileUploadDto.Documents.filter(item => !item.IsNew))
          fileUploadDto.DocumentIds = response.UploadedDocuments.map(item => item.Id);

          return fileUploadDto;
        })
      )
      : noAction;
  }

  checklistUploadSingle(checklistItemDto: IDomainChecklistItemDto): Observable<IDomainChecklistItemDto> {

    if (!checklistItemDto.Documents.some(doc => doc.IsNew)) {
      console.log("file upload denied: no documents");
      return of(checklistItemDto);
    }

    if (!checklistItemDto.EntityId) {
      console.log("file upload denied: EntityID is null");
      return of(checklistItemDto);
    }

    const postDocuments = this.postDocumentData(checklistItemDto.Documents.filter(item => item.IsNew)) // map transforms each checklist item into an Observable
      .pipe(map((response: IFileUploadResponseDto) => {
        checklistItemDto.IsValid = response.IsValid;
        checklistItemDto.AdditionalErrors = response.AdditionalErrors;

        if (!response.IsValid) return checklistItemDto;

        checklistItemDto.Documents = response.UploadedDocuments.concat(checklistItemDto.Documents.filter(item => !item.IsNew))
        checklistItemDto.DocumentIds = response.UploadedDocuments.map(item => item.Id);

        return checklistItemDto;
      }));

    // Using concatMap to execute the observables IN SEQUENCE and return an Observable<IDomainChecklistItemDto>
    return postDocuments.pipe(
      concatMap(() => {
        return (this.domainChecklistItemService.fileUpload(checklistItemDto)).pipe(
          map(secondResult => {
            return secondResult;
          }));
      }));
  }

  /**
  * Uploads checklist-related documents from the provided file upload DTO to the server.
  * 
  * @param {IFileUploadDtoHost} fileUploadDto - The file upload DTO containing checklist-related documents.
  * @returns {Observable<IFileUploadDtoHost>} An observable emitting the updated file upload DTO with checklist documents uploaded successfully.
  */
  uploadChecklistDocuments(fileUploadDto: IFileUploadDtoHost): Observable<IFileUploadDtoHost> {

    const noAction = of(fileUploadDto);
    if (!(fileUploadDto as any).DomainChecklist || (fileUploadDto as any).DomainChecklist.length == 0) return noAction;

    const clisWithDocuments = ((fileUploadDto as any).DomainChecklist as IDomainChecklistItemDto[]).filter(cli => cli.Documents.some(doc => doc.IsNew));
    if (clisWithDocuments.length == 0) return noAction;

    const observables = clisWithDocuments.map(checklistItemDto => this.postDocumentData(checklistItemDto.Documents.filter(item => item.IsNew)) // map transforms each checklist item into an Observable
      .pipe(map((response: IFileUploadResponseDto) => {
        checklistItemDto.IsValid = response.IsValid;
        checklistItemDto.AdditionalErrors = response.AdditionalErrors;
        if (!response.IsValid) return checklistItemDto;

        checklistItemDto.Documents = response.UploadedDocuments.concat(checklistItemDto.Documents.filter(item => !item.IsNew))
        checklistItemDto.DocumentIds = response.UploadedDocuments.map(item => item.Id);

        return checklistItemDto;
      })
      )
    );

    return forkJoin(observables).pipe(map(() => fileUploadDto)); // forkJoin is used to wait for all observables in the observables array to complete. Then, the map operator is used to return the original fileUploadDto after all observables have completed.
  }

  /**
  * Posts the provided documents to the server and retrieves the upload response.
  * 
  * @param {IDocumentDto[]} documents - An array of document DTOs to be uploaded.
  * @returns {Observable<IFileUploadResponseDto>} An observable emitting the upload response containing information about the uploaded documents.
  */
  postDocumentData(documents: IDocumentDto[]): Observable<IFileUploadResponseDto> {
    const formData = new FormData();
    documents.forEach(element => {

      formData.append('file[]', element.RawFile, element.RawFile.name);
      formData.append(`DisplayName[${element.RawFile.name}]`, element.DisplayName);
      formData.append(`Description[${element.RawFile.name}]`, element.Description);

    });

    return this.post(this.uploadMultipleUrl, formData);
  }

  /**
   * Invokes a search for the documents matching the specified search criteria.
   * 
   * @param searchCriteria the IDocumentSearchCriteriaDto containing the search criteria to be matched.
   * @returns the array of IDocumentDtos matching the search criteria.
   */
  public search(searchCriteria: IDocumentSearchCriteriaDto): Observable<IDocumentDto[]> {
    return this.post<IDocumentDto[]>(this.searchUrl, searchCriteria).pipe(map(res => this.utilitiesService.withLocalId(res)));
  }

  /**
   * Creates a search criterion based on RelatedTo matching.
   * @param functionTypeId the id of the function type of the RelatedTo matching.
   * @param functionNumber the function number of the RelatedTo matching.
   * @param professionTypeCode the professional type code of the related to matching. Defaults to none.
   */
  public createRelatedToSearchCriterion(functionTypeId: number, functionNumber: string, professionTypeCode: string = '  '): IDocumentRelatedToKeysDto {

    if (!functionTypeId) {
      this.argumentExceptionService.create(functionTypeId);
    }

    if (!functionNumber) {
      this.argumentExceptionService.create(functionNumber);
    }

    let keysDto = new DocumentRelatedToKeysDto();
    keysDto.FunctionTypeId = functionTypeId;
    keysDto.FunctionNumber = functionNumber;
    keysDto.ProfessionTypeCode = professionTypeCode;
    keysDto.Description = '';
    return keysDto;
  }
}
