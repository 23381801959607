import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Command } from '@core/models/command';
import { CommandService } from '@coreServices/command.service';
import { from } from 'rxjs';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, SystemSettingsManagerService, UserManagerService } from 'src/app/core/index-services';
import { OnlineServiceRegistry } from 'src/app/core/models/online-service-registry';
import { LicensureOnlineServiceProcessTypeKeys } from 'src/app/licensureCore/licensure-core.module';
import { ILicenseInfoDto, ILicensureUserProfileInfoDto, ISupervisorDesignationDto } from 'src/app/licensureShared/licensure-shared.module';

export const SupervisorAttestationSummaryComponentSelector: string = "supervisor-attestation-summary";
export const SupervisorAttestationSummaryComponentKey: string = "supervisorAttestationSummary";

@Component({
  selector: SupervisorAttestationSummaryComponentSelector,
  templateUrl: './supervisor-attestation-summary.component.html'
})
export class SupervisorAttestationSummaryComponent implements OnInit {

  @Input() licenseInfo: ILicenseInfoDto;

  public colSpan: number;
  public designationFormEnabled: boolean;

  public presentAttestationCommand: Command;
  public currentSupervisorDesignations: ISupervisorDesignationDto[] = [];

  constructor(private router: Router
    , private dynamicRouteService: DynamicRoutesService
    , private userManagerService: UserManagerService
    , private commandService: CommandService
    , systemSettingsManagerService: SystemSettingsManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService) {

    this.designationFormEnabled = systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.SUPERVISION.DESIGNATION_FORM_ENABLED);
  }

  ngOnInit(): void {
    this.presentAttestationCommand = this.commandService.create(this.canPresentAttestationCommandExecute, this.presentAttestationCommandExecuted);
    this.currentSupervisorDesignations = (this.userManagerService.getCurrentPrincipal().user.UserAccount.profile as ILicensureUserProfileInfoDto).SupervisorDesignations;
    this.colSpan = this.designationFormEnabled ? 4 : 3;
  }

  protected canPresentAttestationCommandExecute = (): boolean => {
    return this.licenseInfo != null;
  }

  protected presentAttestationCommandExecuted = () => {
    const entityId = this.userManagerService.getCurrentPrincipal().user.UserAccount.EntityId;
    const patternUrl = this.dynamicRouteService.buildRoute(OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SupervisorAttestation).selectorPathTemplate, entityId, this.licenseInfo.LicenseType.Code);
    return from(this.router.navigate(patternUrl));
  }
}
