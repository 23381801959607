import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { IReachDialogContentComponent, ReachControlValidators, ReachDialogContentComponent } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, SystemSettingsManagerService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';
import { IAddressLocationTypeDto, ICountyDto, IEntityAddressDto, ILookupDto, IStateDto } from 'src/app/coreShared/core-shared.module';
import { LicenseSelectorFieldConfiguration, LicenseSelectorFieldConfigurationSearchCriteria } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-configuration';
import { LicenseSelectorFieldModel } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-model';
import { ILicensureConstantsProviderService } from 'src/app/licensureCore/services/licensure-constants-provider.service';
import { IEmploymentDto, IEmploymentPositionTitleDto, IEmploymentTypeDto } from 'src/app/licensureShared/licensure-shared.module';
import { EmploymentListConfiguration } from '../model/employment-list-configuration';

export const EmploymentListEditorComponentSelector: string = "employment-list-editor";
export const EmploymentListEditorComponentKey: string = "EmploymentListEditorComponent";
const UNEMPLOYED: string = "Unemployed";
const BLANK: string = "* blank *";

@Component({
  selector: EmploymentListEditorComponentSelector,
  templateUrl: './employment-list-editor.component.html',
  styleUrls: ['./employment-list-editor.component.scss']
})
/**
 * This component is used to edit a list of employment items. It is used in both wizard and non-wizard scenarios
 * including: profile landing page (as a region control), application and renewal wizards, professional profile update wizards.
 * Based on the system settings it maybe used for free form entry or from a licensed employer search dialog. Address information
 * is only captured in free form entry mode.
 */
export class EmploymentListEditorComponent extends ReachDialogContentComponent<IEmploymentDto> implements OnInit, IReachDialogContentComponent {
  public licenseSelectorFieldConfig: LicenseSelectorFieldConfiguration;
  public isSectorRequired: boolean;
  public isSettingRequired: boolean;
  public isSectorEnabled: boolean;
  public isSettingEnabled: boolean;

  public get Config(): EmploymentListConfiguration { return this.hostDialogData.initializationData.config; }
  public get possiblePositionTitles(): IEmploymentPositionTitleDto[] { return this.hostDialogData.initializationData.possiblePositionTitles }
  public get possibleEmploymentTypes(): IEmploymentTypeDto[] { return this.hostDialogData.initializationData.possibleEmploymentTypes }
  public get possibleStates(): IStateDto[] { return this.hostDialogData.initializationData.possibleStates }
  public get possibleCounties(): ICountyDto[] { return this.hostDialogData.initializationData.possibleCounties }
  public get possibleLocationTypes(): IAddressLocationTypeDto[] { return this.hostDialogData.initializationData.possibleLocationTypes }
  public get possibleEmploymentSettings(): ILookupDto[] { return this.hostDialogData.initializationData.possibleEmploymentSettings }
  public get possibleEmploymentSectors(): ILookupDto[] { return this.hostDialogData.initializationData.possibleEmploymentSectors }
  public get addressForm(): FormGroup { return this.contentForm.get('AddressForm') as FormGroup; }

  licenseSelectorComponentModel: LicenseSelectorFieldModel;
  enableMailingState: boolean = true;
  enableMailingCounty: boolean = true;

  constructor(changeDetectorRef: ChangeDetectorRef
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector
    , elementRef: ElementRef, validationManagerService: ValidationManagerService
    , protected formBuilder: FormBuilder
    , protected utilitiesService: UtilitiesService
    , systemSettingsManagerService: SystemSettingsManagerService) {
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);

    this.isSectorEnabled = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SECTOR_DROPDOWN_ENABLED);
    this.isSettingEnabled = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SETTING_DROPDOWN_ENABLED);

    this.isSectorRequired = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SECTOR_DROPDOWN_REQUIRED);
    this.isSettingRequired = systemSettingsManagerService.asBoolean((this.constantsService as ILicensureConstantsProviderService).SYSTEM_SETTING_KEYS.ENTITY_EMPLOYMENT.SETTING_DROPDOWN_REQUIRED);
  }

  public override async ngOnInit(): Promise<void> {

    this.friendlyNames.EmployerName = "Employer Name";
    this.friendlyNames.PositionTitle = "Position";
    this.friendlyNames.EmploymentType = "Type";
    this.friendlyNames.BeginDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";
    this.friendlyNames.EmploymentSetting = "Setting";
    this.friendlyNames.EmploymentSector = "Sector";
    this.friendlyNames.LocationType = "Location Type";

    await super.ngOnInit();
  }

  public isAddressFormPresent() {
    return this.contentForm?.get('AddressForm') != null;
  }

  private addAddressFormGroup(address: IEntityAddressDto): void {

    // Create the addressFormGroup (added based on configuration or manual entry requests)
    const addressFormGroup = this.formBuilder.group({
      Line1: [address?.Line1, [Validators.required, Validators.maxLength(60)]],
      Line2: [address?.Line2, [Validators.maxLength(60)]],
      Line3: [address?.Line3, [Validators.maxLength(60)]],
      City: [address?.City, [Validators.required, Validators.maxLength(40)]],
      Zip: [address?.Zip, [Validators.required, Validators.maxLength(10)]],
      State: [null, [Validators.required]],
      County: [{ value: null, disabled: true }, [Validators.required]],
      LocationType: [null, [Validators.required]],
    });

    this.contentForm.addControl('AddressForm', addressFormGroup);
  }

  private addAdditionalFieldsToContentForm() {
    this.contentForm.addControl('EmployerName', this.formBuilder.control(this.inputModel.EmployerName, [Validators.required, Validators.maxLength(50)]));
    this.contentForm.addControl('Unemployed', this.formBuilder.control(this.inputModel.EmployerName === UNEMPLOYED));
  }

  protected override modelToForm(): void {

    // All Variations of the form have these fields.
    this.contentForm = this.formBuilder.group({
      BeginDate: [this.inputModel.BeginDate ? new Date(this.inputModel.BeginDate) : null, [ReachControlValidators.pastDateValidator('Start Date'), Validators.required]],
      EndDate: [this.inputModel.EndDate ? new Date(this.inputModel.EndDate) : null, [ReachControlValidators.pastDateValidator('End Date')]],
    });

    // If configured to use the employer licensee id, then we need to use the license selector field.
    // When dealing with new items we show it, for edits we look at the EmployerLIcenseId to see if it was a manual add.
    if (this.Config.employerAsFacilityLicenseIdEnabled && (this.inputModel.Id == 0 || this.inputModel.EmployerLicenseId != null)) {
      this.licenseSelectorComponentModel = new LicenseSelectorFieldModel(this.inputModel.EmployerLicenseId, this.inputModel.EmployerName, 0, undefined, undefined, undefined, !this.inputModel.IsNew);
      this.licenseSelectorFieldConfig = new LicenseSelectorFieldConfiguration("Employer License Search", "Employer License", new LicenseSelectorFieldConfigurationSearchCriteria('Organization'));
    } else {
      var address = this.outputModel.Addresses[0];
      this.addAddressFormGroup(address);
      this.addAdditionalFieldsToContentForm();
    }

    if (this.Config.employmentTypeEnabled) {
      this.contentForm.addControl('EmploymentType', this.formBuilder.control(null, [Validators.required]));
    }

    this.Config.positionPickListEnabled
      ? this.contentForm.addControl('PositionTitle', this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]))
      : this.contentForm.addControl('PositionTitle', this.formBuilder.control(this.inputModel.PositionTitle, [Validators.required, Validators.maxLength(50)]));

    if (this.isSettingEnabled) {
      if (this.isSettingRequired) {
        this.contentForm.addControl("EmploymentSetting", this.formBuilder.control(null, [Validators.required]));
      } else {
        this.contentForm.addControl("EmploymentSetting", this.formBuilder.control(null));
      }
    }

    if (this.isSectorEnabled) {
      if (this.isSectorRequired) {
        this.contentForm.addControl("EmploymentSector", this.formBuilder.control(null, [Validators.required]))
      } else {
        this.contentForm.addControl("EmploymentSector", this.formBuilder.control(null));
      }
    }

    this.initDropdowns();

    this.utilitiesService.enableDisable(this.contentForm, this.outputModel.IsNew);
    this.utilitiesService.enableDisable(this.contentForm.get("EndDate"), true);

    super.modelToForm();
  }

  protected override initializeEventHandlers(): void {

    super.initializeEventHandlers();

    if (!this.Config.employerAsFacilityLicenseIdEnabled) {
      this.contentForm.get('Unemployed').valueChanges.subscribe(x => {
        if (x) {
          this.utilitiesService.enableDisable(this.addressForm, false, true)
          this.contentForm.get('EmployerName').setValue(UNEMPLOYED);
          this.addressForm.get('Line1').setValue(BLANK);
        }
        else {
          this.utilitiesService.enableDisable(this.addressForm, true, true);
          this.contentForm.get('EmployerName').setValue("");
        }
      });

      this.addressForm.get('State').valueChanges.subscribe(x => {
        if (x?.StateCode != 'MN') {
          this.addressForm.get('County').disable();
          this.addressForm.get('County').setValue(null);
          this.enableMailingCounty = false;
        }
        else {
          this.enableMailingCounty = true;
          this.addressForm.get('County').enable();
        }
      });

      if (this.contentForm.get('Unemployed').value == true) {
        this.utilitiesService.enableDisable(this.addressForm, false, true)
        this.contentForm.get('EmployerName').setValue(UNEMPLOYED);
        this.addressForm.get('Line1').setValue(BLANK);
      }
    }
  }

  protected override initDropdowns(): void {
    if (this.isAddressFormPresent()) {
      var address = this.outputModel.Addresses[0];
      var addressForm = this.contentForm.get("AddressForm");
      if (address.State) addressForm.get('State').setValue(this.possibleStates.find(item => item.StateCode == address.State), { emitEvent: addressForm.enabled });
      if (address.CountyId) addressForm.get('County').setValue(this.possibleCounties.find(item => item.Id == address.CountyId), { emitEvent: addressForm.enabled });
      if (address.AddressLocationType) addressForm.get('LocationType').setValue(this.possibleLocationTypes.find(item => item.Id == address.AddressLocationType.Id) ?? null);
    }

    if (this.Config.positionPickListEnabled) {
      this.contentForm.get('PositionTitle').setValue(this.possiblePositionTitles.find(item => item.Id == this.outputModel.PositionTitleId));
      this.hostDialogData.initializationData.possiblePositionTitles = this.hostDialogData.initializationData.possiblePositionTitles.filter(item => (this.outputModel.IsNew && item.IsAddableOnline) || (!this.outputModel.IsNew && item.IsEditableOnline));

      if (this.hostDialogData.initializationData.possiblePositionTitles.length == 1 && !this.outputModel.PositionTitleId) this.contentForm.get('PositionTitle').setValue(this.hostDialogData.initializationData.possiblePositionTitles[0]);
      if (this.hostDialogData.initializationData.possiblePositionTitles.length == 0) console.log("There are no position titles that are allowed online.");
    }

    if (this.Config.employmentTypeEnabled) {
      if (this.outputModel.TypeId) {
        this.contentForm.get('EmploymentType').setValue(this.possibleEmploymentTypes.find(item => item.Id == this.outputModel.TypeId) ?? null);
      }
    }

    if (this.isSettingEnabled && (this.outputModel as IEmploymentDto).SettingId) this.contentForm.get('EmploymentSetting')?.setValue(this.possibleEmploymentSettings.find(item => item.Id == (this.outputModel as IEmploymentDto).SettingId));
    if (this.isSectorEnabled && (this.outputModel as IEmploymentDto).SectorId) this.contentForm.get('EmploymentSector')?.setValue(this.possibleEmploymentSectors.find(item => item.Id == (this.outputModel as IEmploymentDto).SectorId));

    this.dataLoaded = true;
  }

  protected override formToModel(): void {
    if (!this.dataLoaded) return;

    if (this.isAddressFormPresent()) {
      this.outputModel.EmployerName = this.contentForm.get('EmployerName').value;
      var address = this.outputModel.Addresses[0]; // Assume this exists.
      address.Line1 = this.addressForm.get('Line1').value;
      address.Line2 = this.addressForm.get('Line2').value;
      address.Line3 = this.addressForm.get('Line3').value;
      address.City = this.addressForm.get('City').value;
      address.Zip = this.addressForm.get('Zip').value;
      address.AddressLocationType = this.addressForm.get('LocationType').value;
      address.State = this.addressForm.get('State').value?.StateCode ?? "";
      address.CountyId = this.addressForm.get('County').value?.Id;
    }

    if (this.Config.positionPickListEnabled) {
      this.outputModel.PositionTitleId = this.contentForm.get('PositionTitle').value?.Id
      this.outputModel.EmploymentPositionTitle = this.possiblePositionTitles.find(i => i.Id === this.outputModel.PositionTitleId);
    } else {
      this.outputModel.PositionTitle = this.contentForm.get('PositionTitle').value;
    }

    if (this.Config.employmentTypeEnabled) {
      this.outputModel.TypeId = this.contentForm.get('EmploymentType').value?.Id;
      this.outputModel.EmploymentType = this.possibleEmploymentTypes.find(i => i.Id === this.outputModel.TypeId);
    }

    this.outputModel.BeginDate = this.contentForm.get('BeginDate').value;
    this.outputModel.EndDate = this.contentForm.get('EndDate').value;

    if (this.isSettingEnabled) (this.outputModel as IEmploymentDto).SettingId = this.contentForm.get('EmploymentSetting').value?.Id;
    if (this.isSectorEnabled) (this.outputModel as IEmploymentDto).SectorId = this.contentForm.get('EmploymentSector').value?.Id;

    super.formToModel();
  }

  public licenseSelectorFieldSelectionChanged(licenseSelectorFieldModel: LicenseSelectorFieldModel) {
    this.outputModel.EmployerLicenseId = licenseSelectorFieldModel.licenseId;
    this.outputModel.EmployerName = licenseSelectorFieldModel.selectedLicenseeName;

    // When a manual entry is requested, we need to add the address form group if not already there.
    if (licenseSelectorFieldModel.manualEntryRequested && !this.isAddressFormPresent()) {
      this.addAddressFormGroup(null);
      this.addAdditionalFieldsToContentForm();
    }
  }
}

registerDynamicComponent(ReachScenarios.Default, EmploymentListEditorComponentKey, EmploymentListEditorComponent, EmploymentListEditorComponentSelector);
