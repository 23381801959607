<!-- Grid -->
<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- Dynamic Content -->
    <div class="p-col-12">
        <page-summary-text majorKey="Shared.Content" minorKey="SupervisorLookup.SummaryTextBlock"></page-summary-text>
    </div>

    <!-- Search Criteria -->
    <div class="p-grid p-formgrid">
        <!-- Last Name -->
        <div class="p-field p-col-12 p-sm-6">
            <label for="lastName">Last Name</label>
            <input id="lastName" type="text" formControlName="LastName" pInputText>
        </div>

        <!-- First Name -->
        <div class="p-field p-col-12 p-sm-6">
            <label for="firstName">First Name</label>
            <input id="firstName" type="text" formControlName="FirstName" pInputText>
        </div>

        <!-- License Number -->
        <div class="p-field p-col-12 p-sm-6">
            <label for="licenseReferenceValue">License Number</label>
            <input id="licenseReferenceValue" type="text" formControlName="LicenseReferenceValue" pInputText>
        </div>

        <!-- Buttons -->
        <div class="p-col-12 reach-bottom-actions-container">
            <div class="p-d-flex p-jc-end">
                <!-- Search -->
                <div class="p-pl-2 p-pt-2">
                    <button pButton pRipple type="button" label="Search" class="p-button-secondary"
                        [command-button]="searchCommand"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid p-formgrid">

        <p-table [value]="searchResults" dataKey="Id" selectionMode="single" (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="FullName">Name<p-sortIcon field="FullName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="ProfessionalSuffix">License Types<p-sortIcon field="ProfessionalSuffix">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="ReferenceValue">License Number<p-sortIcon field="ReferenceValue"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item">
                    <td>{{item.FullName | whenEmpty: '-'}}</td>
                    <td>{{item.ProfessionalSuffix}}</td>
                    <td>{{item.ReferenceValue}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>