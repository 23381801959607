import { Directive, Injector, Input, SimpleChanges } from '@angular/core';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachComponentFactoryService, ReachDynamicComponentInfo, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { ReachInjectorService } from '@coreServices/reach-injector.service';


/**
 * Reach base Component class with shared @Input-decorated properties.
 */
@Directive()
export class ReachModelAwareComponent  {
  @Input() model: any;
  @Input() config: any;
  @Input() majorKey: string;
  @Input() minorKey: string;

  constructor(protected reachModelConfigurationInjector: ReachModelConfigurationInjector) {
    this.onInputInjectorChanges();
  }

  /**
   * Checks for and maps injected input model and configuration data.
   */
  protected onInputInjectorChanges() {
    if (this.reachModelConfigurationInjector) {
      this.config = this.reachModelConfigurationInjector.configuration;
      this.model = this.reachModelConfigurationInjector.model;
      this.majorKey = this.reachModelConfigurationInjector.majorKey;
      this.minorKey = this.reachModelConfigurationInjector.minorKey;
      this.onAssertInputs();
    }
  }

  /**
   * Base method for initializing @Input-decorated properties shared by Reach components (model, config, majorKey, minorKey).
   * @param changes
   */
  protected onInputChanges(changes: SimpleChanges) {
    // This method is invoked whenever the @Input-decorated variables have value changes (as they are initialized higher up the stack).
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let change = changes[propName];
        switch (propName) {
          case 'majorKey':
            {
              this.majorKey = change.currentValue;
              break;
            }
          case 'minorKey':
            {
              this.minorKey = change.currentValue;
              break;
            }
          case 'model':
            {
              this.model = change.currentValue;
              break;
            }
          case 'config':
            {
              this.config = change.currentValue;
              break;
            }
        }
      }
    }
    this.onAssertInputs();
  }

  /**
   * Called after input changes. Override in subclasses to assert valid inputs (e.g., by using assert methods from this class).
   */
  protected onAssertInputs() { }

  /**
   * Asserts that a config exists on this instance.
   * @param validateType if true, the config object will be validated against the specified TConfigType.
   */
  protected assertConfig<TConfigType>(validateType: boolean) {
    if (!this.config) {
      throw "Config is required. Please verify a configuration object was passed to the directive.";
    }

    if (validateType && !(this.model as TConfigType)) {
      throw "Input configuration was not of the expected type.";
    }
  }

  /**
   * Asserts that a model exists on this instance.
   * @param validateType if true, the model object will be validated against the specified TConfigType.
   */
  protected assertModel<TModelType>(validateType: boolean) {
    if (!this.model) {
      throw "Model is required. Please verify a model was passed to the directive.";
    }

    if (validateType && !(this.model as TModelType)) {
      throw "Input model was not of the expected type.";
    }
  }

  /**
   * Asserts that a model and config exist on this instance.
   * @param validateModelType if true, the model object will be validated against the specified TModelType.
   * @param validateConfigType if true, the config object will be validated against the specified TConfigType.
   */
  protected assertModelAndConfig<TModelType, TConfigType>(validateModelType: boolean, validateConfigType: boolean) {
    this.assertConfig<TConfigType>(validateConfigType);
    this.assertModel<TModelType>(validateModelType);
  }

  public get accessModel() {
    return this.model;
  }

  public get accessConfiguration() {
    return this.config;
  }

  /**
   * Loads the dynamic content component. Supports this instance as a host of dynamic components.
   * @param scenarioKey the key that represents the scenario under which the component is loaded.
   * @param hostedComponentTypeKey the key representing the component type/name.
   * @param reachComponentFactoryService the component factory.
   * @param the injector of the parent component.
   */
  protected loadHostedDynamicComponent(scenarioKey: string, hostedComponentTypeKey: string, reachComponentFactoryService: ReachComponentFactoryService, parentInjector: Injector): ReachDynamicComponentInfo {
    const getInjector = (parentInjector: Injector) => {
      return reachComponentFactoryService.dynamicModelConfigurationInjector(MODEL_CONFIG_INJECTION_TOKEN, this.accessModel, this.accessConfiguration, parentInjector);
    }

    let injectorData = this.reachModelConfigurationInjector ? {
      injectorService: new ReachInjectorService(parentInjector),
      modelInjector: this.reachModelConfigurationInjector
    } : getInjector(parentInjector);

    let hostedComponentInfo = reachComponentFactoryService.dynamicComponentInfo(scenarioKey, hostedComponentTypeKey, injectorData.injectorService.injector);
    return hostedComponentInfo;
  }
}
