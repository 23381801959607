import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, Inject, Injector, OnInit, Optional } from '@angular/core';

import { Command, ButtonCommand, NavigationMenuCommand } from '@coreModels/command';
import { ReachComponentFactoryService, ReachDynamicComponentInfo, MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { ReachInjectorService, ReachInjectorProviderItem } from '@coreServices/reach-injector.service';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';

@Component({
  selector: 'reach-dynacard',
  templateUrl: './reach-dynacard.component.html',
  styleUrls: ['./reach-dynacard.component.scss']
})
export class ReachDynacardComponent extends ReachModelAwareComponent implements OnInit {
  @Input() validationScope: string = null;
  @Input() scenarioKey: string = null;
  @Input() contentComponentTypeKey: string = null;
  detailComponentInfo: ReachDynamicComponentInfo = null;
  @Input() title: string = "";
  @Input() subTitle: string = "";
  @Input() actionName: string = "";
  @Input() actionCommand: Command = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private reachComponentFactoryService: ReachComponentFactoryService,
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) reachModelConfigurationInjector: ReachModelConfigurationInjector,
  ) {
    super(reachModelConfigurationInjector);
  }

  ngOnInit(): void {
    this.loadDynamicContentComponent();
  }

  public get accessModel() {
    return this.model;
  }

  public get accessConfiguration() {
    return this.config;
  }

  /**
   * Loads the dynamic content component.
   */
  private loadDynamicContentComponent() {
    const getInjector = (parentInjector: Injector) => {
      return this.reachComponentFactoryService.dynamicModelConfigurationInjector(
        MODEL_CONFIG_INJECTION_TOKEN,
        this.accessModel,
        this.accessConfiguration,
        parentInjector);
    }

    let injectorData = this.reachModelConfigurationInjector ? {
        injectorService: new ReachInjectorService(this.injector),
        modelInjector: this.reachModelConfigurationInjector
      }
      : getInjector(this.injector);

    this.detailComponentInfo = this.reachComponentFactoryService.dynamicComponentInfo(
      this.scenarioKey,
      this.contentComponentTypeKey,
      injectorData.injectorService.injector
    );
  }
}
