<!-- Grid -->
<div class="p-fluid" [formGroup]="contentForm">

  <!-- Dynamic Content -->
  <div class="p-col-12">
    <page-summary-text majorKey="Shared.Content" minorKey="LicenseSelectorFieldEditor.SummaryTextBlock"></page-summary-text>
  </div>

  <!-- Search Criteria -->
  <div class="p-grid p-formgrid">
    <!-- Last Name -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="lastName">{{friendlyNames.LastName}}</label>
      <input id="lastName" type="text" formControlName="LastName" pInputText>
    </div>

    <!-- City -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="lastName">City</label>
      <input id="lastName" type="text" formControlName="City" pInputText>
    </div>

    <!-- License Type -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="LicenseType">License Type</label>
      <p-dropdown inputId="LicenseType" formControlName="LicenseType" [options]="possibleLicenseTypes"
        optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
      </p-dropdown>
    </div>

    <!-- License Number -->
    <div class="p-field p-col-12 p-sm-6">
      <label for="LicenseNumber">License Number</label>
      <input id="LicenseNumber" type="number" formControlName="LicenseNumber" pInputText>
    </div>

    <!-- Buttons -->
    <div class="p-col-12 reach-bottom-actions-container">
      <div class="p-d-flex p-jc-end">
        <!-- Search -->
        <div class="p-pl-2 p-pt-2">
          <button pButton pRipple type="button" label="Search" class="p-button-secondary"
            [command-button]="searchCommand" command-key-binding="Enter"></button>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid p-formgrid" style="margin-top: 1em;">

    <p-table [value]="searchResults" dataKey="LicenseId" selectionMode="single" (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect()">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="LastName">Last<p-sortIcon field="LastName"></p-sortIcon></th>
          <th pSortableColumn="FirstName">First<p-sortIcon field="FirstName"></p-sortIcon></th>
          <th pSortableColumn="LicenseType">City<p-sortIcon field="City">
            </p-sortIcon>
          </th>
          <th pSortableColumn="LicenseType">License Type<p-sortIcon field="LicenseType">
            </p-sortIcon>
          </th>
          <th pSortableColumn="ReferenceValue" class="p-text-right">License Number<p-sortIcon
              field="ReferenceValue"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
          <td>{{item.LastName | whenEmpty: '-'}}</td>
          <td>{{item.FirstName | whenEmpty: '-'}}</td>
          <td>{{item.City | whenEmpty: '-'}}</td>
          <td>{{item.LicenseTypeDescription}}</td>
          <td class="p-text-right">{{item.ReferenceValue}}</td>
        </tr>
      </ng-template>

      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="4">
            <span class="reach-text-muted"><em>No items to display.</em></span>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- License not found -->
    <div class="p-field-checkbox p-col-12 p-ai-start p-mt-3" *ngIf="config.enableLicenseNotFoundCheckbox">
      <p-checkbox inputId="binary" formControlName="LicenseNotFound" binary="true"></p-checkbox>
      <label class="p-pt-0" for="binary">License not found in the results. Allow me to manually enter the
        information.<field-help majorKey="Shared.Content" minorKey="LicenseSelectorFieldEditor.LicenseNotFound"></field-help>
      </label>
    </div>
  </div>
</div>
