<div class="reach-page-header">Activity: <span
        *ngIf="activity">{{activity.ActivitySubactivity.ActivitySubtype.Description | whenEmpty: '-'}}</span></div>

<!-- Grid -->
<div class="p-grid p-nogutter reach-landing-detail" *ngIf="activity">

    <!-- Information -->
    <div class="p-xl-9 p-grid">

        <!-- Type -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Type</label>
            <span class="p-col p-mb-0">{{activity.ActivitySubactivity.ActivitySubtype.Description}}</span>
        </div>
        <!-- Status -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Status</label>
            <span class="p-col p-mb-0">{{activity.Status.Description}}</span>
        </div>
        <!-- Start Date -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label
                class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">{{activity.ActivitySubactivity.ActivitySubtype.InitiatedDateLabel
                | whenEmpty: 'Initiated'}}</label>
            <span class="p-col p-mb-0">{{activity.StartDate | date:'MM/dd/yyyy' | whenEmpty:'-'}}</span>
        </div>
        <!-- Expected Date -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label
                class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">{{activity.ActivitySubactivity.ActivitySubtype.ExpectedDateLabel
                | whenEmpty: 'Expected'}}</label>
            <span class="p-col p-mb-0">{{activity.ExpectedDate | date:'MM/dd/yyyy' | whenEmpty:'-'}}</span>
        </div>
        <!-- Completed Date -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label
                class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">{{activity.ActivitySubactivity.ActivitySubtype.CompletedDateLabel
                | whenEmpty: 'Completed'}}</label>
            <span class="p-col p-mb-0">{{activity.EndDate | date:'MM/dd/yyyy' | whenEmpty:'-'}}</span>
        </div>
        <!-- Comment -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Comment</label>
            <span class="p-col p-mb-0">{{activity.OnlineComment}}</span>
        </div>

        <!-- Table -->
        <p-table styleClass="p-datatable-sm reach-responsive-table" [value]="historyItems" [responsive]="true"
            responsiveLayout="stack">

            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-jc-between">
                    <span class="p-text-left reach-list-header">Documents</span>
                </div>
            </ng-template>

            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 50%;">Name</th>
                    <th>Date</th>
                    <th class="p-text-right" style="width: 6em;">Download</th>
                </tr>
            </ng-template>

            <!-- Rows -->
            <ng-template pTemplate="body" let-item>
                <tr style="width: 100%;">

                    <!-- Document -->
                    <td style="width: 50%;">
                        <span class="p-column-title">Name</span>{{item.DocumentDisplayName | whenEmpty: '-'}}
                    </td>

                    <!-- Received -->
                    <td>
                        <span class="p-column-title">Date</span>{{item.CreatedDate | date:'MM/dd/yyyy' | whenEmpty:
                        '-'}}
                    </td>

                    <!-- Buttons -->
                    <td class="p-text-right">
                        <span class="p-text-nowrap">

                            <!-- Download -->
                            <button pButton pRipple class="p-button-text" [icon]="getIcon(item)"
                                [command-button]="downloadCommand" [command-parameter]="item"
                                [pTooltip]="getTooltip(item)"></button>
                        </span>
                    </td>

                </tr>

            </ng-template>

            <!-- Empty Message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="3">
                        <span class="reach-text-muted"><em>No items</em></span>
                    </td>
                </tr>
            </ng-template>

        </p-table>

        <!-- Checklist -->
        <checklist-item-list *ngIf="activity" [domainChecklistHost]="activity"></checklist-item-list>

    </div>

    <!-- Links -->
     <div class="p-lg-5 p-xl-4">
        <online-service-link caption="Actions" [model]="onlineServiceLinkModel"></online-service-link>
    </div> 

</div>
