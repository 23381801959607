import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { of } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { Command } from '@coreModels/command';
import { DialogData, DialogDataInjector } from '@coreModels/dialog-settings';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { FormValidationManagerService } from '@coreServices/form-validation-manager.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
export interface IReachDialogContentComponent {
  input: DialogData; // The input parameters from the host.
  defaultOkCommand: Command; // The default OK command defined by this component. Null by default. Overriden by host defined Command.
  defaultCancelCommand: Command; // The default Cancel command defined by this component. Null by default. Overriden by host defined Command.
}

/**
 * @deprecated This component is deprecated. Use ReachDialogContentComponentV2.
 * Defines the base class for dialog content area Components that interoperate with their ReachDialogComponent host.
 */
@Component({
  template: ''
})
export class ReachDialogContentComponent<TOutputModel> implements OnInit, AfterViewChecked, OnDestroy, IReachDialogContentComponent {
  public hostDialogData: DialogData; // The input DialogData with which to initialize this instance.
  protected formValidationManagerService: FormValidationManagerService;
  public configuration = null;
  public initializationData: any = {}; // Addition dialog initialization data
  public show: boolean = false;
  public contentForm: FormGroup = new FormGroup({}); // The FormGroup for this ReachDialogContent modal instance.
  public validationScope: string = null;
  public formDataIsInit: boolean = false;
  public friendlyNames: any = {};
  public outputModel: TOutputModel = null; // The output model generated by this dialog instance. User edits are mapped to this object from teh form.
  public defaultOkCommand: Command = null; // The default OK command defined by this component. Null by default. Overriden by host defined Command.
  public defaultCancelCommand: Command = null; // The default Cancel command defined by this component. Null by default. Overriden by host defined Command.

  // PROPERTIES
  public get input() { return this.hostDialogData; } // Gets input DialogData received from the host (via @Input or injection).
  public set input(dialogData: DialogData) { // Sets input DialogData received from the host (via @Input or injection).
    if (!dialogData) return;
    this.hostDialogData = dialogData;
    this.validationScope = dialogData.validationScope;
    this.configuration = this.hostDialogData.configuration;
    if (dialogData.configuration) { this.configuration = dialogData.configuration; }
  }
  public get inputModel(): any { return this.hostDialogData ? this.hostDialogData.inputModel : null; } // Gets the source model for initializing the dialog.

  // CTOR
  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    protected dialogDataInjector: DialogDataInjector,
    protected elementRef: ElementRef,
    protected validationManagerService: ValidationManagerService) {
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public async ngOnInit(): Promise<void> {
    this.dialogDataInjector.contentComponentInstance = this;
    this.validationScope = this.constantsService.VALIDATION_ERROR_SCOPES.DIALOG;
    this.input = this.dialogDataInjector.dialogData;

    this.formValidationManagerService = new FormValidationManagerService(
      this.elementRef,
      this.friendlyNames,
      this.contentForm,
      this.validationManagerService,
      this.validationScope
    );

    this.initCommands();
    this.mapInputToOutputModel();

    this.modelToForm();

    await this.loadLookupLists();
    this.hostDialogData.dialogForm.setControl('Content', this.contentForm);
    this.formValidationManagerService.setForm(this.contentForm);
    this.mapOutputToDialogData();

    // Add validation errors to the message service pool.
    this.formValidationManagerService.checkValidationErrors();

    this.formDataIsInit = true;

    // Populate dropdown FormControl elements.
    this.initDropdowns();
  }

  /**
  * A lifecycle hook that is called after the default change detector has completed checking a component's view for changes.
  */
  ngAfterViewChecked() {
    this.show = this.hostDialogData.isOpen && this.outputModel !== null;
  }

  /**
  * A lifecycle hook that is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed.
  */
  ngOnDestroy(): void {
    this.formValidationManagerService.unsubscribe();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm(): void {

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel(): void {
  }

  /**
  * Loads required lookup data.
  */
  protected loadLookupLists(): Promise<any> {
    return of(true).toPromise();
  }

  /**
  * Initializes commands for this instance.
  */
  protected initCommands(): void { }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {
    // When anything in this contentForm changes, update the model.
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.onUpdateForm();
    });
  }

  /**
  * Handler method for form changed event. Invoked whenever a value on one of the contentForm's
  * controls changes (after a user edit).
  */
  protected onUpdateForm() {

    // Map the form updates to the output model.
    this.formToModel();

    // Add validation errors to the message service pool.
    this.formValidationManagerService.checkValidationErrors();
  }

  /**
   * Default function that maps the input model (from the DialogData) to a new instance of the
   * output model. Default implementation simply assumes the output model IS the input model.
   * Override this in derived classes form custom mapping (e.g., where the output model type
   * differs from the input model type).
   */
  protected async mapInputToOutputModel(): Promise<any> {
    this.outputModel = this.inputModel;
    return of(this.outputModel).toPromise();
  }

  /**
  * Map the output artifacts of this instance to the host DialogData.
  */
  protected mapOutputToDialogData() {
    this.hostDialogData.dialogForm = this.contentForm;
    this.hostDialogData.outputModel = this.outputModel;
  }

  dataLoaded: boolean = false;
  protected initDropdowns(): void {
    // Called from ngOnInit() after lookup values are loaded.
    this.dataLoaded = true;
  }
}
