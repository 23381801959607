import { Component, OnInit, Inject, Input } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { BusyManagerService } from "../../services/busy-manager.service";
import { CommandService } from "../../services/command.service";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "../../services/constants-provider.service";
import { DialogSettings } from "@coreModels/dialog-settings";
import { EntityPhoneNumberEditorComponent } from "./editor/entity-phone-number-editor.component";
import { ListService } from "../../services/list.service";
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { SystemSettingsManagerService } from "../../services/system-settings-manager.service";
import { UtilitiesService } from "../../services/utilities.service";
import { IEntityPhoneDto, IPhoneTypeDto } from '@coreShared/core-shared.module';
import { ConfirmationService } from 'primeng/api';
import { Command } from '../../index-models';

@Component({
  selector: 'entity-phone-number-list',
  templateUrl: './entity-phone-number-list.component.html',
  styleUrls: ['./entity-phone-number-list.component.scss'],
})
export class EntityPhoneNumberListComponent implements OnInit {

  @Input()
  model: IEntityPhoneDto[];
  @Input()
  canCreate: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canDelete: boolean;
  @Input()
  entityId: number;
  @Input()
  save: () => Observable<any>;

  pageSummaryMajorKey: string = "Shared.Content";
  pageSummaryMinorKey: string = "Panel.PhoneNumbers";
  updatePhoneDialogSettings: DialogSettings = null;
  possiblePhoneTypes: IPhoneTypeDto[];

  isPrimaryEnabled: boolean = false;
  isPublicEnabled: boolean = false;
  isPublicLabel: string;

  // COMMANDS
  removeItemCommand: Command;
  presentItemEditorCommand: Command;
  okCommand: Command;

  // PROPERTIES
  public get filteredPhoneNumbers(): IEntityPhoneDto[] { return this.model?.filter(item => !item.IsDeleted); }
  public get columnCount() { return this.isPublicEnabled && this.isPrimaryEnabled ? 5 : this.isPublicEnabled && !this.isPrimaryEnabled ? 4 : !this.isPublicEnabled && this.isPrimaryEnabled ? 4 : 3; }

  // CTOR
  constructor(
    private busyManagerService: BusyManagerService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private listService: ListService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private utilitiesService: UtilitiesService,
    private confirmationService: ConfirmationService,
  ) {
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    // System settings
    this.isPublicEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_ONE_PUBLIC_PHONE_ENFORCEMENT_ENABLED);
    this.isPrimaryEnabled = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_ONE_PRIMARY_PHONE_ENFORCEMENT_ENABLED);
    this.isPublicLabel = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_PUBLIC_PHONE_LABEL);

    // Load lookup lists.
    this.loadLookupLists();

    // Initialize commands
    this.initCommands();
  }

  /**
  * Loads gender lookup dtos for use in the combobox.
  */
  private loadLookupLists(): void {

    // Define a function to be resolved by the busy manager service.
    const doInit = async (): Promise<any> => {

      // Perform loading and filter operations.
      const responseCollection = await forkJoin([this.listService.getPhoneTypes()]).toPromise();
      this.possiblePhoneTypes = this.listService.filterInactiveItems(responseCollection[0]) as IPhoneTypeDto[];

      // Exit.
      return of(true).toPromise();
    }

    // Display busy status while resolving.
    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  /**
  * Initializes commands for this instance.
  */
  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }


  // ==================================== COMMANDS ====================================

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (output: IEntityPhoneDto) => {

    output.IsDirty = true;
    this.model = this.utilitiesService.addOrReplace(this.model, output);

    if (this.save) this.save();
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (phoneNumber: IEntityPhoneDto) => {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doPresent = async (): Promise<any> => {

      // get address copy or new
      let selectedItem: IEntityPhoneDto = phoneNumber ?? { Id: 0, IsNew: true, IsDeleted: false, LocalId: this.utilitiesService.guid(), EntityId: this.entityId } as IEntityPhoneDto;
      let dialogTitle = phoneNumber ? "Phone Number" : "Phone Number - {new}";

      this.updatePhoneDialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        EntityPhoneNumberEditorComponent, // Content type
        'EntityPhoneNumberEditorComponent', // Content key
        dialogTitle, // Title
        selectedItem, // Model
      );

      this.updatePhoneDialogSettings.initializationData.possiblePhoneTypes = this.possiblePhoneTypes;
      this.updatePhoneDialogSettings.okCommand = this.okCommand;
      this.updatePhoneDialogSettings.isOpen = true;

      // Return empty promise (noop)
      return of(true).toPromise();
    }

    // Kick off the doCreateEducationItem() function defined above.
    return from(doPresent());

  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (item: any) => {

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `${item.PhoneType.Description} phone ${item.Number} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      // If accepted...
      accept: () => {

        // Flag for delete and save.
        item.IsDeleted = true;

        // Save.
        if (this.save) this.save();
      }

    });
  }

}
