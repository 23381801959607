import {  } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ICeSponsorshipStatusDto } from './licensure-shared-dto';

export class CeSponsorshipStatusDto extends BaseDto implements ICeSponsorshipStatusDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	Code: string;
	Heirarchy: number;

}
