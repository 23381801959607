import { IAnswerDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class AnswerDto extends LookupDto implements IAnswerDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
