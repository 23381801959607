import { IBaseDto,
IWebTransactionFeeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebTransactionFeeDto extends BaseDto implements IBaseDto, IWebTransactionFeeDto {
	SourceCode: string;
	Amount: number;
	TransactionId: number;
	ServiceHistoryId?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
