import { Inject, Injectable } from '@angular/core';
import { Observable, forkJoin, from, of } from "rxjs";

import {
  CONSTANTS_SERVICE_TOKEN,
  CartLandingConfigurationService, ConstantsService,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  FeatureManagerService,
  GeneralConfigurationService, InvoiceFeatureConfigurationService, InvoiceLandingConfigurationService,
  SharedConfigurationService,
  SystemSettingsManagerService
} from '@core/core.module';

import { CeSponsorSearchFeatureConfigurationService } from './ce-sponsor-search-feature-configuration.service';
import { CourseSearchFeatureConfigurationService } from './course-search-feature-configuration.service';
import {
  ApplicationFeatureConfigurationService, ApplicationFinalFeeWizardFeatureConfigurationService,
  AutoverificationConfigurationService,
  CeAuditFeatureConfigurationService,
  CeSponsorshipFeatureConfigurationService,
  ComplaintWizardFeatureConfigurationService,
  CriminalBackgroundCheckFeeWizardFeatureConfigurationService,
  DataRequestFeatureConfigurationService,
  DuplicateCardFeatureConfigurationService, EducationProgramApplicationFeatureConfigurationService, EducationProgramCompletionFeatureConfigurationService, ExamRetakeFeatureConfigurationService,
  JurisprudenceFeatureConfigurationService, LicenseCertificationFeatureConfigurationService, LicenseFeatureConfigurationService, LicenseReinstatementFeatureConfigurationService, LicenseRenewalFeatureConfigurationService, LicenseRenewalFinalFeeFeatureConfigurationService, LicenseRenewalIlcFeatureConfigurationService, LicenseStatusChangeFeatureConfigurationService, LicenseVerificationWizardFeatureConfigurationService, LicensureActivityFeatureConfigurationService, LicensureForgotPasswordFeatureConfigurationService, LicensureRegistrationWizardFeatureConfigurationService, OnlineEntitySearchFeatureConfigurationService,
  OrganizationalPayorConfigurationService, ProfessionalProfileFeatureConfigurationService, SecondLicenseCardFeatureConfigurationService,
  SupervisionFeatureConfigurationService
} from './index-config-services';
import { ProfileConfigurationService } from './profile-configuration.service';
import { DelegateAdministrationFeatureConfigurationService } from './delegate-administration-feature-configuration.service';

/**
 * Factory function for instantiating instances of LicensureFeatureManagerService.
 * @param container the SharedConfigurationService that holds sharable service instances.
 */
let licensureFeatureManagerServiceFactory = (
  container: SharedConfigurationService,
  activityFeatureConfigurationService: LicensureActivityFeatureConfigurationService,
  applicationFeatureConfigurationService: ApplicationFeatureConfigurationService,
  professionalProfileFeatureConfigurationService: ProfessionalProfileFeatureConfigurationService,
  applicationFinalFeeWizardFeatureConfigurationService: ApplicationFinalFeeWizardFeatureConfigurationService,
  autoverificationConfigurationService: AutoverificationConfigurationService,
  ceAuditFeatureConfigurationService: CeAuditFeatureConfigurationService,
  ceSponsorshipFeatureConfigurationService: CeSponsorshipFeatureConfigurationService,
  complaintWizardFeatureConfigurationService: ComplaintWizardFeatureConfigurationService,
  criminalBackgroundCheckFeeWizardFeatureConfigurationService: CriminalBackgroundCheckFeeWizardFeatureConfigurationService,
  dataRequestFeatureConfigurationService: DataRequestFeatureConfigurationService,
  duplicateCardFeatureConfigurationService: DuplicateCardFeatureConfigurationService,
  educationProgramCompletionFeatureConfigurationService: EducationProgramCompletionFeatureConfigurationService,
  educationProgramApplicationFeatureConfigurationService: EducationProgramApplicationFeatureConfigurationService,
  examRetakeFeatureConfigurationService: ExamRetakeFeatureConfigurationService,
  forgotPasswordFeatureConfigurationService: LicensureForgotPasswordFeatureConfigurationService,
  generalConfigurationService: GeneralConfigurationService,
  cartLandingConfigurationService: CartLandingConfigurationService,
  invoiceLandingConfigurationService: InvoiceLandingConfigurationService,
  invoiceFeatureConfigurationService: InvoiceFeatureConfigurationService,
  jurisprudenceFeatureConfigurationService: JurisprudenceFeatureConfigurationService,
  licenseCertificationFeatureConfigurationService: LicenseCertificationFeatureConfigurationService,
  licenseFeatureConfigurationService: LicenseFeatureConfigurationService,
  licenseRenewalFeatureConfigurationService: LicenseRenewalFeatureConfigurationService,
  licenseRenewalIlcFeatureConfigurationService: LicenseRenewalIlcFeatureConfigurationService,
  licenseRenewalFinalFeeFeatureConfigurationService: LicenseRenewalFinalFeeFeatureConfigurationService,
  licenseReinstatementWizardFeatureConfigurationService: LicenseReinstatementFeatureConfigurationService,
  licenseVerificationWizardFeatureConfigurationService: LicenseVerificationWizardFeatureConfigurationService,
  onlineEntitySearchFeatureConfigurationService: OnlineEntitySearchFeatureConfigurationService,
  ceSponsorSearchFeatureConfigurationService: CeSponsorSearchFeatureConfigurationService,
  courseSearchFeatureConfigurationService: CourseSearchFeatureConfigurationService,
  organizationalPayorConfigurationService: OrganizationalPayorConfigurationService,
  profileConfigurationService: ProfileConfigurationService,
  registrationWizardFeatureConfigurationService: LicensureRegistrationWizardFeatureConfigurationService,
  secondLicenseCardFeatureConfigurationService: SecondLicenseCardFeatureConfigurationService,
  supervisionFeatureConfigurationService: SupervisionFeatureConfigurationService,
  systemSettingsManagerService: SystemSettingsManagerService,
  licensestatuschangeFeatureConfigurationService: LicenseStatusChangeFeatureConfigurationService,
  delegateAdministrationFeatureConfigurationService: DelegateAdministrationFeatureConfigurationService
) => {
  let featureManagerService = new LicensureFeatureManagerService(
    activityFeatureConfigurationService,
    applicationFeatureConfigurationService,
    professionalProfileFeatureConfigurationService,
    applicationFinalFeeWizardFeatureConfigurationService,
    autoverificationConfigurationService,
    ceAuditFeatureConfigurationService,
    container.constantsService,
    container.defaultProvider,
    ceSponsorshipFeatureConfigurationService,
    complaintWizardFeatureConfigurationService,
    criminalBackgroundCheckFeeWizardFeatureConfigurationService,
    dataRequestFeatureConfigurationService,
    duplicateCardFeatureConfigurationService,
    educationProgramCompletionFeatureConfigurationService,
    educationProgramApplicationFeatureConfigurationService,
    examRetakeFeatureConfigurationService,
    forgotPasswordFeatureConfigurationService,
    generalConfigurationService,
    cartLandingConfigurationService,
    invoiceLandingConfigurationService,
    invoiceFeatureConfigurationService,
    jurisprudenceFeatureConfigurationService,
    licenseCertificationFeatureConfigurationService,
    licenseFeatureConfigurationService,
    licenseRenewalFeatureConfigurationService,
    licenseRenewalIlcFeatureConfigurationService,
    licenseRenewalFinalFeeFeatureConfigurationService,
    licenseReinstatementWizardFeatureConfigurationService,
    licenseVerificationWizardFeatureConfigurationService,
    onlineEntitySearchFeatureConfigurationService,
    ceSponsorSearchFeatureConfigurationService,
    courseSearchFeatureConfigurationService,
    organizationalPayorConfigurationService,
    profileConfigurationService,
    registrationWizardFeatureConfigurationService,
    secondLicenseCardFeatureConfigurationService,
    supervisionFeatureConfigurationService,
    systemSettingsManagerService,
    licensestatuschangeFeatureConfigurationService,
    delegateAdministrationFeatureConfigurationService);
  container.featureManagerService = featureManagerService;
  return featureManagerService;
};

@Injectable({
  providedIn: 'root',
  useFactory: licensureFeatureManagerServiceFactory,
  deps: [
    SharedConfigurationService,
    LicensureActivityFeatureConfigurationService,
    ApplicationFeatureConfigurationService,
    ProfessionalProfileFeatureConfigurationService,
    ApplicationFinalFeeWizardFeatureConfigurationService,
    AutoverificationConfigurationService,
    CeAuditFeatureConfigurationService,
    CeSponsorshipFeatureConfigurationService,
    ComplaintWizardFeatureConfigurationService,
    CriminalBackgroundCheckFeeWizardFeatureConfigurationService,
    DataRequestFeatureConfigurationService,
    DuplicateCardFeatureConfigurationService,
    EducationProgramCompletionFeatureConfigurationService,
    EducationProgramApplicationFeatureConfigurationService,
    ExamRetakeFeatureConfigurationService,
    LicensureForgotPasswordFeatureConfigurationService,
    GeneralConfigurationService,
    CartLandingConfigurationService,
    InvoiceLandingConfigurationService,
    InvoiceFeatureConfigurationService,
    JurisprudenceFeatureConfigurationService,
    LicenseCertificationFeatureConfigurationService,
    LicenseFeatureConfigurationService,
    LicenseRenewalFeatureConfigurationService,
    LicenseRenewalIlcFeatureConfigurationService,
    LicenseRenewalFinalFeeFeatureConfigurationService,
    LicenseReinstatementFeatureConfigurationService,
    LicenseVerificationWizardFeatureConfigurationService,
    OnlineEntitySearchFeatureConfigurationService,
    CeSponsorSearchFeatureConfigurationService,
    CourseSearchFeatureConfigurationService,
    OrganizationalPayorConfigurationService,
    ProfileConfigurationService,
    LicensureRegistrationWizardFeatureConfigurationService,
    SecondLicenseCardFeatureConfigurationService,
    SupervisionFeatureConfigurationService,
    SystemSettingsManagerService
  ]
})
export class LicensureFeatureManagerService extends FeatureManagerService {

  constructor(activityFeatureConfigurationService: LicensureActivityFeatureConfigurationService,
    protected applicationFeatureConfigurationService: ApplicationFeatureConfigurationService,
    protected professionalProfileFeatureConfigurationService: ProfessionalProfileFeatureConfigurationService,
    protected applicationFinalFeeWizardFeatureConfigurationService: ApplicationFinalFeeWizardFeatureConfigurationService,
    protected autoverificationConfigurationService: AutoverificationConfigurationService,
    protected ceAuditFeatureConfigurationService: CeAuditFeatureConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected ceSponsorshipFeatureConfigurationService: CeSponsorshipFeatureConfigurationService,
    protected complaintWizardFeatureConfigurationService: ComplaintWizardFeatureConfigurationService,
    protected criminalBackgroundCheckFeeWizardFeatureConfigurationService: CriminalBackgroundCheckFeeWizardFeatureConfigurationService,
    protected dataRequestFeatureConfigurationService: DataRequestFeatureConfigurationService,
    protected duplicateCardFeatureConfigurationService: DuplicateCardFeatureConfigurationService,
    protected educationProgramCompletionFeatureConfigurationService: EducationProgramCompletionFeatureConfigurationService,
    protected educationProgramApplicationFeatureConfigurationService: EducationProgramApplicationFeatureConfigurationService,
    protected examRetakeFeatureConfigurationService: ExamRetakeFeatureConfigurationService,
    forgotPasswordFeatureConfigurationService: LicensureForgotPasswordFeatureConfigurationService,
    generalConfigurationService: GeneralConfigurationService,
    cartLandingConfigurationService: CartLandingConfigurationService,
    invoiceLandingConfigurationService: InvoiceLandingConfigurationService,
    invoiceFeatureConfigurationService: InvoiceFeatureConfigurationService,
    protected jurisprudenceFeatureConfigurationService: JurisprudenceFeatureConfigurationService,
    protected licenseCertificationFeatureConfigurationService: LicenseCertificationFeatureConfigurationService,
    protected licenseFeatureConfigurationService: LicenseFeatureConfigurationService,
    protected licenseRenewalFeatureConfigurationService: LicenseRenewalFeatureConfigurationService,
    protected licenseRenewalIlcFeatureConfigurationService: LicenseRenewalIlcFeatureConfigurationService,
    protected licenseRenewalFinalFeeFeatureConfigurationService: LicenseRenewalFinalFeeFeatureConfigurationService,
    protected licenseReinstatementWizardFeatureConfigurationService: LicenseReinstatementFeatureConfigurationService,
    protected licenseVerificationWizardFeatureConfigurationService: LicenseVerificationWizardFeatureConfigurationService,
    protected onlineEntitySearchFeatureConfigurationService: OnlineEntitySearchFeatureConfigurationService,
    protected ceSponsorSearchFeatureConfigurationService: CeSponsorSearchFeatureConfigurationService,
    protected courseSearchFeatureConfigurationService: CourseSearchFeatureConfigurationService,
    protected organizationalPayorConfigurationService: OrganizationalPayorConfigurationService,
    protected profileConfigurationService: ProfileConfigurationService,
    registrationWizardFeatureConfigurationService: LicensureRegistrationWizardFeatureConfigurationService,
    protected secondLicenseCardFeatureConfigurationService: SecondLicenseCardFeatureConfigurationService,
    protected supervisionFeatureConfigurationService: SupervisionFeatureConfigurationService,
    systemSettingsManagerService: SystemSettingsManagerService,
    protected licenseStatusChangeFeatureConfigurationService: LicenseStatusChangeFeatureConfigurationService,
    protected delegateAdministrationFeatureConfigurationService: DelegateAdministrationFeatureConfigurationService
  ) {
    super(
      activityFeatureConfigurationService,
      constantsService,
      defaultProviderConfigurationService,
      generalConfigurationService,
      cartLandingConfigurationService,
      invoiceLandingConfigurationService,
      invoiceFeatureConfigurationService,
      forgotPasswordFeatureConfigurationService,
      registrationWizardFeatureConfigurationService,
      systemSettingsManagerService
    );
  }

  public initialize() {
    console.log('Initializing LicensureFeatureManagerService...');

    const doLicensureInit = async (): Promise<boolean> => {
      if (this.isProfileEnabled) {
        this.profileConfigurationService.initialize();
      }

      if (this.isCeAuditEnabled) {
        this.ceAuditFeatureConfigurationService.initialize();
      }

      if (this.isCeSponsorshipEnabled) {
        this.ceSponsorshipFeatureConfigurationService.initialize();
      }

      if (this.isLicenseRenewalEnabled) {
        this.licenseRenewalFeatureConfigurationService.initialize();
      }

      if (this.isLicenseRenewalIlcEnabled) {
        this.licenseRenewalIlcFeatureConfigurationService.initialize();
      }

      if (this.isLicenseRenewalFinalFeeEnabled) {
        this.licenseRenewalFinalFeeFeatureConfigurationService.initialize();
      }

      if (this.isLicenseCertificationFeatureEnabled) {
        this.licenseCertificationFeatureConfigurationService.initialize();
      }

      if (this.isApplicationWizardFeatureEnabled) {
        this.applicationFeatureConfigurationService.initialize();
      }

      if (this.isProfessionalProfileWizardFeatureEnabled) {
        this.professionalProfileFeatureConfigurationService.initialize();
      }

      if (this.isDuplicateCardFeatureEnabled || this.isDuplicateWallCertificateFeatureEnabled) {
        this.duplicateCardFeatureConfigurationService.initialize();
      }

      if (this.isSecondLicenseCardFeatureEnabled) {
        this.secondLicenseCardFeatureConfigurationService.initialize();
      }

      if (this.isEducationProgramEnabled) {
        this.educationProgramCompletionFeatureConfigurationService.initialize();
        this.educationProgramApplicationFeatureConfigurationService.initialize();
      }

      if (this.isOnlineEntitySearchEnabled) {
        this.onlineEntitySearchFeatureConfigurationService.initialize();
      }

      if (this.isCeSponsorSearchEnabled) {
        this.ceSponsorSearchFeatureConfigurationService.initialize();
      }

      if (this.isCourseSearchEnabled) {
        this.courseSearchFeatureConfigurationService.initialize();
      }

      if (this.isLicenseEnabled) {
        this.licenseFeatureConfigurationService.initialize();
      }

      if (this.isLicenseReinstatementEnabled) {
        this.licenseReinstatementWizardFeatureConfigurationService.initialize();
      }

      if (this.isExamRetakeEnabled) {
        this.examRetakeFeatureConfigurationService.initialize();
      }

      if (this.isAutoverificationCardFeatureEnabled) {
        this.autoverificationConfigurationService.initialize();
      }

      if (this.isOrganizationalPayorCardFeatureEnabled) {
        this.organizationalPayorConfigurationService.initialize();
      }

      if (this.isApplicationFinalFeeEnabled) {
        this.applicationFinalFeeWizardFeatureConfigurationService.initialize();
      }

      if (this.isComplaintEnabled) {
        this.complaintWizardFeatureConfigurationService.initialize();
      }

      if (this.isCriminalBackgroundCheckFeeEnabled) {
        this.criminalBackgroundCheckFeeWizardFeatureConfigurationService.initialize();
      }

      if (this.isDataRequestEnabled) {
        this.dataRequestFeatureConfigurationService.initialize();
      }

      if (this.isJurisprudenceEnabled) {
        this.jurisprudenceFeatureConfigurationService.initialize();
      }

      if (this.isLicenseVerificationEnabled) {
        this.licenseVerificationWizardFeatureConfigurationService.initialize();
      }

      if (this.isSupervisionEnabled) {
        this.supervisionFeatureConfigurationService.initialize();
      }

      if (this.isLicenseStatusChangeEnabled) {
        this.licenseStatusChangeFeatureConfigurationService.initialize();
      }

      if (this.isDelegateAdministrationEnabled) {
        this.delegateAdministrationFeatureConfigurationService.initialize();
      }

      return of(true).toPromise();
    };

    // Initialize core services
    const doCoreInit = (): Observable<boolean> => {
      return super.initialize();
    };
    // Initialize licensure and base core services
    const doInit = async (): Promise<boolean> => {
      await forkJoin([doCoreInit(), doLicensureInit()]).toPromise();
      return of(true).toPromise();
    };

    return from(doInit());
  }

  public get isCeAuditEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.CE_AUDIT.ONLINE.ENABLED);
  }

  public get isCeSponsorshipEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.CE_SPONSORSHIP.ENABLED);
  }

  public get isLicenseRenewalEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.ENABLED);
  }

  public get isLicenseRenewalIlcEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.ENABLED);
  }

  public get isLicenseRenewalFinalFeeEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RENEWAL.ENABLED);
  }

  public get isLicenseReinstatementEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RE_ACTIVATION.ENABLED);
  }

  public get isLicenseStatusChangeEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.STATUS_CHANGE.ENABLED);
  }

 public get isDelegateAdministrationEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.DELEGATE_ADMINISTRATION.ENABLED);
  }

  public get isExamRetakeEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.RE_ACTIVATION.ENABLED);
  }

  public get isLicenseCertificationFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.LICENSE_CERTIFICATION.ENABLED);
  }

  public get isApplicationWizardFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_WIZARD.ENABLED);
  }

  public get isProfessionalProfileWizardFeatureEnabled() {
    return true;
  }

  public get isDuplicateCardFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_CARD_ENABLED);
  }

  public get isDuplicateWallCertificateFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_DUPLICATE_WALL_CERTIFICATE_ENABLED);
  }

  public get isSecondLicenseCardFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.FEATURE_SECOND_LICENSE_CARD_ENABLED);
  }

  public get isAutoverificationCardFeatureEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.AUTO_VERIFICATION.ENABLED);
  }

  public get isEducationProgramEnabled() {
    return true;
  }

  public get isLicenseEnabled() {
    return true;
  }

  public get isProfileEnabled() {
    return true;
  }

  public get isApplicationFinalFeeEnabled() {
    return true;
  }

  public get isComplaintEnabled() { return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.DISCIPLINE.ONLINE_COMPLAINT.ENABLED); }

  public get isCriminalBackgroundCheckFeeEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.CRIMINAL_BACKGROUND_CHECK.FEE_WIZARD.ENABLED);
  }

  public get isDataRequestEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.DATA_REQUEST.ENABLED);
  }

  public get isJurisprudenceEnabled() {
    return true;
  }

  public get isLicenseVerificationEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.LICENSE_VERIFICATION.ENABLED);
  }

  public get isSupervisionEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.SUPERVISION.ENABLED);
  }

  public get isOrganizationalPayorCardFeatureEnabled() {
    return true;
  }

  public get isOnlineEntitySearchEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.LICENSE.ONLINE_ENTITY_SEARCH.ENABLED);
  }

  public get isCeSponsorSearchEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.CE_SPONSOR_SEARCH.ENABLED);
  }

  public get isCourseSearchEnabled() {
    return this.featureEnabled(this.constantsService.SYSTEM_SETTING_KEYS.COURSE_SEARCH.ENABLED);
  }
}
