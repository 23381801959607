import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { SearchCriteriaDto } from '@coreShared/dto-gen/search-criteria-dto';
import { ICeSponsorSearchCriteriaDto } from './licensure-shared-dto';

export class CeSponsorSearchCriteriaDto extends SearchCriteriaDto implements IDomainReasonForChangeDtoHost, ICeSponsorSearchCriteriaDto {
	LastName: string;
	FirstName: string;
	ContactLastName: string;
	ContactFirstName: string;
	ContactPhone: string;
	ContactEmail: string;
	City: string;
	Zip: string;
	StatusId?: number;
	CountyId?: number;
	EntityId?: number;

}
