import { IActivitySubactivityDocumentTypeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class ActivitySubactivityDocumentTypeDto extends BaseDto implements IActivitySubactivityDocumentTypeDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ActivitySubactivityId: number;
	Name: string;
	ProfessionType: string;

}
