// Angular
import { Injectable, Inject } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { from } from 'rxjs';
import * as _ from 'underscore';
import * as _l from 'lodash-es';

import {
  AuthGuard,
  ConstantsService,
  CONSTANTS_SERVICE_TOKEN,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceHistoryService,
  OnlineServiceMenuService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  SystemSettingsManagerService,
  UserManagerService,
  WizardService,
  WizardSearchCriteriaService,
  WizardTagsService
} from '@core/core.module';

import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";

/**
 * Responsible for tasks related to the SecondLicenseCard wizard.
 */
@Injectable({ providedIn: 'root' })
export class SecondLicenseCardFeatureConfigurationService {

  // Fields
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private secondLicenseCardConstantsService: ISecondLicenseCardConstantsService;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private onlineServiceMenuService: OnlineServiceMenuService,
    private onlineServiceHistoryService: OnlineServiceHistoryService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private userManagerService: UserManagerService,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService
  ) {
      this.secondLicenseCardConstantsService = this.constantsService as ISecondLicenseCardConstantsService;
      this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.SecondLicenseCard);
  }

  /**
   * Perform initialization tasks.
   * Called from feature-manager.service.ts
   */
  public initialize() {
    if (!this.isInitialized) {

      // Constants.
      this.configureConstants();

      // Routes.
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
   * Launch the wizard.
   * @param license
   */
  public presentSecondLicenseCardWizard(license = null, filterFunction = null) {
    // If a license is supplied, use it as the subject license
      let subjectLicense = license;
      if (!subjectLicense) {

        // if a license was not supplied we attempt to derive one from the profile
          let possibleSubjects = this.getLicensesThatSupportSecondLicenseCards(filterFunction);
        if (possibleSubjects) {
          if (possibleSubjects.length === 1) {
            subjectLicense = possibleSubjects[0];
          } else if (possibleSubjects.length > 1) {
            //this.router.navigate(this.supportedRoutePatterns.SelectorRoutePattern);
            this.router.navigate(['/', this.serviceRegistryItem.selectorPath]);
            return;
          }
        }
      }

      if (!subjectLicense) {
        throw "Unable to start service, please ensure a license has been supplied or one can be derived.";
      }

      // Navigate to the wizard.
      let patternUrl = this.dynamicRoutesService.buildRoute(
        this.serviceRegistryItem.onlineServicePathTemplate,
        subjectLicense.Id);
      this.router.navigate(patternUrl);
  }

  /**
   * Returns the set of licenses that support second license card issue. The licenses on the user profile are filtered by the specified filter-by-license-type
   * filter function, or a default function.
   * @param filterFunction
   */
  public getLicensesThatSupportSecondLicenseCards(filterFunction = null) {
    let currentPrincipal = this.userManagerService.getCurrentPrincipal();
    if (!currentPrincipal) {
      return null;
    }

    if (!filterFunction) {
      // Default filter function
      filterFunction = (license) => {
        return license.LicenseStatus.Id === "LA" ||
          license.LicenseStatus.Id === "LC" ||
          license.LicenseStatus.Id === "RS" ||
          license.LicenseStatus.Id === "VI";
      }
    }

    return _l.filter((currentPrincipal.user.UserAccount.profile as ILicensureUserProfileInfoDto).Licenses, filterFunction);
  }

  /**
 * Decorate the ConstantsService with Application-specific constants.
 */
  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS,
      SecondLicenseCardConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES,
      SecondLicenseCardConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.secondLicenseCardConstantsService = this.constantsService as ISecondLicenseCardConstantsService;
  }

  /**
   * Configure dynamic Application routes.
   */
  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import(
            'src/app/licensureCore/features/secondLicenseCard/second-license-card-wizard-selector.module')
          .then(m => m.SecondLicenseCardWizardSelectorModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
            loadChildren: () => import('src/app/licensureCore/features/secondLicenseCard/second-license-card-wizard.module')
                .then(m => m.SecondLicenseCardWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/licensureCore/features/secondLicenseCard/second-license-card-wizard.module')
            .then(m => m.SecondLicenseCardWizardModule)
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }
}

/**
 * SecondLicenseCard setting constants to merge into ConstantsService.
 */
export class SecondLicenseCardConstantsService extends ConstantsService {
  public static Mergers = {
        DYNAMIC_CONTENT_MAJOR_KEYS: {
          SECOND_LICENSE_CARD_WIZARD: 'License.SecondaryLicenseCard.Wizard',
        },

        WEB_SERVICE_PROCESS_TYPES: {
          SECOND_LICENSE_CARD: LicensureOnlineServiceProcessTypeKeys.SecondLicenseCard
        },
    };

    DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS),
      SecondLicenseCardConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES),
      SecondLicenseCardConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
}

export interface ISecondLicenseCardConstantsService extends SecondLicenseCardConstantsService {
}
