import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';

import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CommandService } from '@coreServices/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { LandingComponentKeys, LoginComponentKeys } from '@core/index-constants';
import { MenuItem } from '@coreModels/menu-item';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { Principal, RouteInfoRegistry } from '@core/index-models';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { DynamicContentManagerService } from 'src/app/core/index-services';
import { MenuGroup } from 'src/app/core/models/menu-group';
import { DynamicContentChangedEventArgument } from 'src/app/core/models/eventArgs/dynamic-content-changed-event-argument';

import * as _ from 'underscore';
import * as _l from 'lodash-es';


@Component({
  selector: 'reach-app-menu',
  templateUrl: './reach-app-menu.component.html',
  styleUrls: ['./reach-app-menu.component.scss'],
  animations: [
    trigger('submenu', [
      state('hidden', style({
        height: '0',
        overflow: 'hidden',
        opacity: 0,
      })),
      state('visible', style({
        height: '*',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ])
  ]
})
export class ReachAppMenuComponent implements OnInit, OnDestroy {

  private subscriptionLogin: Subscription;
  private subscriptionLogout: Subscription;
  private applicationInitialized: Subscription;
  private isAppInit: boolean = false;
  imagePath: string;
  public ASSETS;
  public VALIDATION_ERROR_SCOPES;
  public validationErrorScope: string;
  public notificationScope: string;
  public SYSTEM_SETTING_KEYS;
  public BUSY_MANAGER_BUSY_TYPES;
  public DYNAMIC_CONTENT_MAJOR_KEYS;
  public DYNAMIC_CONTENT_LOCATION_TYPES;
  public backgroundHeaderImageStyle: SafeStyle;
  public logoImage: string = "";
  public brand;
  public userDisplayName: string = null;
  public printLogo: string = "";
  public showBreadcrumbs = false;
  public majorHelpKey: string = "";
  public minorHelpKey: string = "Navbar.Help";
  public dynamicSystemMenuItems: MenuItem[] = [];

  // HELP MENU
  helpMenuItems: MenuItem[] = [];
  helpMenuGroups: MenuGroup[] = [];
  private dynamicContentChangedSubscription: Subscription;

  @Input() active: boolean;

  activeSubmenus: { [key: string]: boolean } = {};

  // PROPERTIES
  public get currentPrinciple(): Principal { return this.userManagerService.getCurrentPrincipal(); }

  constructor(
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private sanitizer: DomSanitizer,
    private onlineServiceMenuService: OnlineServiceMenuService,
    public reachApplicationService: ReachApplicationService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private userManagerService: UserManagerService,
    private dynamicContentManagerService: DynamicContentManagerService
  ) {
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit() {

    this.imagePath = this.defaultProviderConfigurationService.customProviderAssetRoot;
    this.validationErrorScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.notificationScope = this.constantsService.NOTIFICATION_SCOPES.APPLICATION;
    this.subscriptionLogin = this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.subscriptionLogout = this.userManagerService.logout$.subscribe(currentPrincipal => this.onUserLogout());
    this.applicationInitialized = this.reachApplicationService.applicationInitialized$.subscribe(args => this.onApplicationInitialized(args));
    this.applicationInitialized = this.onlineServiceMenuService.systemMenuChanged$.subscribe(args => this.onSystemMenuChanged(args));

    // Dynamic Content
    this.dynamicContentChangedSubscription = this.dynamicContentManagerService.contenthanged$.subscribe(
      (changedEventArgument: DynamicContentChangedEventArgument) => this.buildHelpMenu(changedEventArgument.dynamicContentEntries));

    this.doInit();
  }

  private buildHelpMenu(dynamicContentEntries) {
    this.helpMenuItems = [];
    this.helpMenuGroups = [];

    let dynamicHelpEntries = this.getDynamicHelpEntries(dynamicContentEntries);

    let groupedNavigationBarHelpItems = _l.groupBy(_l.sortBy(dynamicHelpEntries,
      (item) => {
        return item.DisplayOrder;
      }),
      (navBarHelpItem) => {
        return navBarHelpItem.GroupName;
      });

    _.map(_.keys(groupedNavigationBarHelpItems),
      (groupKey) => {
        var menuGroup = new MenuGroup(groupKey);

        // For each item in each group, we add the menu items with commands
        _.map(groupedNavigationBarHelpItems[groupKey],
          (item) => {

            if (item.PresentationTypeId === this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.MODAL) {
              menuGroup.addMenuItem(this.dynamicContentManagerService.createModalContentCommand(item));
            } else if (item.PresentationTypeId ===
              this.constantsService.DYNAMIC_CONTENT_PRESENTATION_TYPES.EXTERNAL_URL) {
              menuGroup.addMenuItem(this.dynamicContentManagerService.createExternalContentCommand(item));
            } else {
              throw "presentation type not supported in menuHelp directive.";
            }
          });

        this.helpMenuGroups.push(menuGroup);
      });

    this.helpMenuItems = _l.flatten(_.pluck(this.helpMenuGroups, "items"));
  }

  private getDynamicHelpEntries(dynamicContentEntries) {
    return _l.filter(dynamicContentEntries,
      (entry) => {
        return (entry.MajorKey === this.majorHelpKey) &&
          entry.Content.length > 0 &&
          entry.LocationTypeId === this.DYNAMIC_CONTENT_LOCATION_TYPES.NAVBAR_MENU && entry.MinorKey === this.minorHelpKey;
      });
  }

  /** 
  * A lifecycle hook that is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed.
  */
  ngOnDestroy(): void {
    this.subscriptionLogin.unsubscribe();
    this.subscriptionLogout.unsubscribe();
    this.applicationInitialized.unsubscribe();
  }

  public get isUserLoggedIn(): boolean {
    return this.userManagerService.isLoggedIn;
  }

  /**
   * Handle change in system menu items.
   * @param args
   */
  private onSystemMenuChanged(args) {
    this.getSystemMenuItems(args);
  }

  /**
   * Rebuild the collection of system menu items.
   * @param systemMenuItems
   */
  private getSystemMenuItems(systemMenuItems) {
    this.dynamicSystemMenuItems = [];
    systemMenuItems.forEach(c => {
      let menuItem = new MenuItem(c, false);
      this.dynamicSystemMenuItems.push(menuItem);
    });
  }

  /**
   * Initialize the component properties.
   */
  private doInit() {
    this.ASSETS = this.constantsService.ASSETS;
    this.VALIDATION_ERROR_SCOPES = this.constantsService.VALIDATION_ERROR_SCOPES;
    this.SYSTEM_SETTING_KEYS = this.constantsService.SYSTEM_SETTING_KEYS;
    this.BUSY_MANAGER_BUSY_TYPES = this.constantsService.BUSY_MANAGER_BUSY_TYPES;
    this.DYNAMIC_CONTENT_MAJOR_KEYS = this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS;
    this.DYNAMIC_CONTENT_LOCATION_TYPES = this.constantsService.DYNAMIC_CONTENT_LOCATION_TYPES;
    var backgroundHeaderImageUrl: string = this.defaultProviderConfigurationService.customProviderAssetRoot + "/headerBackgroundSlice.png";
    this.backgroundHeaderImageStyle = this.sanitizer.bypassSecurityTrustStyle('url(' + backgroundHeaderImageUrl + ')');

    // TODO:
    //this.logoImage = this.defaultProviderConfigurationService.customProviderAssetRoot + '/' + this.ASSETS.IMAGES.AGENCY_LOGO;
    this.logoImage = this.defaultProviderConfigurationService.coreAssetRoot + "/img/mn-logo-blue-footer.png";

    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();
    this.printLogo = this.defaultProviderConfigurationService.customProviderAssetRoot + "/logo_white_bg.png";
    this.majorHelpKey = this.DYNAMIC_CONTENT_MAJOR_KEYS.SHARED_CONTENT;

    if (this.isAppInit) {
      this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
    }
  }

  /**
   * Handles the "userManager.login" event.
   * @param event
   * @param argument
   */
  private onUserLogin(currentPrincipal) {
    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();
  }

  /**
   * Handles the "userManager.logout" event.
   * @param event
   * @param argument
   */
  private onUserLogout() {
    this.userDisplayName = "";
    this.getSystemMenuItems(this.onlineServiceMenuService.systemSettingCommands);
  };

  /**
   * Handler for the "bootstrapper.applicationInitialized" event.
   * @param the event args.
   */
  private onApplicationInitialized(isInitialized: boolean) {
    this.isAppInit = isInitialized;
    //  if (isInitialized) {
    //  this.brand = this.systemSettingsManagerService.get(this.SYSTEM_SETTING_KEYS.AGENCY_NAME).Value;
    //}
  }

  // Home Command - return to landing page if logged in; otherwise to the login page
  homeCommand = this.commandService.createButtonCommand(() => {
    return true;
  },
    () => {
      if (this.userManagerService.getCurrentPrincipal()) {
        // Navigate to landing page
        this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
      } else {
        // Navigate to login page
        this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
      }
    });


  // Login Command
  loginCommand = this.commandService.createButtonCommand(() => {
    return !this.userManagerService.getCurrentPrincipal();
  },
    () => {
      // Navigate to login page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    });

  // Logout Command
  logoutCommand = this.commandService.createButtonCommand(
    () => { return this.userManagerService.getCurrentPrincipal() != null; },
    () => {
      // Logout
      this.userManagerService.logout();

      // Navigate to login page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    }
  );

  passwordChangeCommand = this.commandService.createButtonCommand(() => {
    return this.userManagerService.getCurrentPrincipal() != null;
  },
    () => {
      // Navigate to pw page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Password).path]);
    });

  changeSecurityQuestionsCommand = this.commandService.createButtonCommand(() => {
    return this.userManagerService.getCurrentPrincipal() != null;
  },
    () => {
      // Navigate to pw page
      this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.SecurityQuestions).path]);
    });

  onlineServicesCommands = this.onlineServiceMenuService.commands;
}
