import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorComponentKeys } from '@coreConstants/error-component-constants';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { AppConfigService } from '@coreServices/app-config.service';
import { IncidentService } from '@coreServices/incident.service';
import { LoginComponentKeys } from '../index-constants';
import { BrowserDetectionService, UtilitiesService } from '../index-services';

@Injectable()
export class ReachErrorHandlerService implements ErrorHandler {

  private incidentService: IncidentService = null;
  private router: Router = null;
  private utilitiesService: UtilitiesService = null;
  private browserDetectionService: BrowserDetectionService = null;

  constructor(private appConfigService: AppConfigService
    , private injector: Injector
    , private zone: NgZone) {
  }

  handleError(error: Error | HttpErrorResponse) {
    console.error(error);

    let referenceNumber: string = null;
    let logIncident: boolean = this.appConfigService && this.appConfigService.clientProviderConfiguration.logIncidents;
    let incidentLoggingConfig = this.appConfigService && this.appConfigService.clientProviderConfiguration.incidentLoggingConfig;
    let errorHandlerConfig = this.appConfigService && this.appConfigService.clientProviderConfiguration.errorHandlerConfig;
    let redirectToError: boolean = true;
    let redirectToLogin: boolean = false;

    // Create a reference number for incident
    this.browserDetectionService = this.injector.get(BrowserDetectionService);
    this.utilitiesService = this.injector.get(UtilitiesService);
    referenceNumber = this.utilitiesService.guid();

    if (logIncident && this.incidentService == null) {
      try {
        this.incidentService = this.injector.get(IncidentService);
      }
      catch (ex) {
        logIncident = false;
      }
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status == HttpStatusCode.Unauthorized) {
        logIncident = incidentLoggingConfig.log401;
        redirectToLogin = true;
        redirectToError = false;
      }
    }
    else if (error instanceof Error) {
      if ((<Error>error).message.includes('401 Unauthorized')) {
        redirectToError = false;
        logIncident = incidentLoggingConfig.log401;
        redirectToLogin = true;
      }
      else if ((<Error>error).message.includes('Uncaught (in promise): Timeout')) {
        // This is to swallow captcha errors.
        redirectToError = false;
        logIncident = incidentLoggingConfig.logTimeout;
      }
      else if ((<Error>error).message.includes('0 Unknown Error')) {
        // This is to suppress 0 Unknown Error.
        redirectToError = !errorHandlerConfig.suppress0UnknownError;
        logIncident = incidentLoggingConfig.log0Unknown;
      }
    }

    if (logIncident) {
      try {
        this.incidentService.error(error, referenceNumber, this.browserDetectionService.getBrowserVersion());
      } catch (ex) {
        console.error('Failed to log incident.')
      }
    }

    if (this.router == null) {
      this.router = this.injector.get(Router);
    }

    if (redirectToError) {
      this.zone.run(() => this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(ErrorComponentKeys.Error).path, { referenceNumber: referenceNumber, error: error?.message }]));
    }
    else if (redirectToLogin) {
      let loginComponentRegistryItem = RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login);
      let loginPath = loginComponentRegistryItem.path;
      this.zone.run(() => {
        this.router.navigate(['/', loginPath]);
      });
    }
  }
}
