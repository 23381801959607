import { AfterContentInit, Component, ComponentFactoryResolver, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CheckboxContentService } from '@coreServices/checkbox-content.service';
import { CheckboxContentChangedEventArgument } from '@coreModels/eventArgs/checkbox-content-changed-event-arguments';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "@coreServices/constants-provider.service";
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { PageSummaryService } from '@coreServices/page-summary.service';
import { PageSummaryContentChangedEventArgument } from '@coreModels/eventArgs/page-summary-content-changed-event-argument';
import { ReachComponentFactoryService, ReachDynamicComponentInfo } from '@coreServices/reach-component-factory.service';
import { Wizard } from '@coreModels/wizard';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements AfterContentInit, OnDestroy, OnInit {
  protected checkboxContentChangedSubscription: Subscription;
  protected pageSummaryContentChangedSubscription: Subscription;
  protected selectedStepChangedSubscription: Subscription;
  public readonly busyTypes;
  public isContentValid = false;
  public setDetailComponentInfo: ReachDynamicComponentInfo;
  protected wizardComponentCategory: string;
  public routeData = null;
  public wizard: Wizard;
  public wizardForm: FormGroup = null;

  constructor(protected route: ActivatedRoute
    , protected busyManagerService: BusyManagerService
    , protected checkboxContentService: CheckboxContentService
    , protected componentFactoryResolver: ComponentFactoryResolver
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , protected formBuilder: FormBuilder
    , private injector: Injector
    , protected pageSummaryService: PageSummaryService
    , private reachComponentFactoryService: ReachComponentFactoryService
    , protected router: Router) {

    this.busyTypes = this.constantsService.BUSY_MANAGER_BUSY_TYPES;
    this.initializeSubscriptions();
    this.modelToForm();
  }

  public SelectedStepChanged(selectedIndex: number): void {
    this.wizard.setSelectedStep(selectedIndex);
    this.ScrollToSelectedIndex(selectedIndex);
  }

  protected ScrollToSelectedIndex(selectedIndex: number) {
    const element = this.injector.get(DOCUMENT).querySelector(`[ng-reflect-index='${selectedIndex}']`);
    if (element) setTimeout(() => { element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }); }, 1000);
  }

  ngOnInit(): void {
    this.initStep();
  }

  ngOnDestroy(): void {
    this.checkboxContentChangedSubscription.unsubscribe();
    this.pageSummaryContentChangedSubscription.unsubscribe();
    this.selectedStepChangedSubscription.unsubscribe();
  }

  ngAfterContentInit() { }
  protected modelToForm() {

    // Initialize wizardForm with single child control 'Step' that represents the dynamic content component for a Wizard Step.    
    this.wizardForm = this.formBuilder.group({
      Steps: this.formBuilder.group({}) // TODO: Could probably be removed...
    });

    // Pass the wizard form to the wizard.ts.
    this.wizard.wizardForm = this.wizardForm;

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // When anything in this contentForm changes....    
    this.wizardForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.wizard.checkContentValid();
    });
  }

  /**
  * Wires subscriptions for the content form.
  */
  protected initializeSubscriptions(): void {

    // Pull the wizard off the route data initialized by the route resolver on the path to this wizard.
    this.route.data.subscribe((data: { routeData: any }) => {
      if (data && data.routeData && data.routeData.dynamicContentConfiguration) {
        this.wizard = data.routeData.wizard;
        this.wizard.dynamicContentConfiguration = data.routeData.dynamicContentConfiguration;
        this.routeData = data.routeData;
      }
    });


    this.checkboxContentChangedSubscription = this.checkboxContentService.checkboxContentChanged$.subscribe(
      (changedEventArgument: CheckboxContentChangedEventArgument) => this.onCheckedChanged(changedEventArgument));
    this.pageSummaryContentChangedSubscription = this.pageSummaryService.pageSummaryContentChanged$.subscribe(
      (changedEventArgument: PageSummaryContentChangedEventArgument) => this.onContentChanged(changedEventArgument));
    this.route.queryParams.subscribe(p => this.onRouteQueryParamsChange(p));

  }

  private onCheckedChanged(args: CheckboxContentChangedEventArgument) {
    if (this.wizard && this.wizard.model && this.wizard.selectedStep && args.dynamicContentEntry) {
      this.wizard.selectedStep.bag.addOrReplaceItem(args.dynamicContentEntry.MinorKey,
        this.constantsService.BAG_ITEM_TYPES.CHECKBOX_CONTENT,
        [args.friendlyName, args.checked, args.dynamicContentEntry.Content]);
    }
  }

  private onContentChanged(args: PageSummaryContentChangedEventArgument) {
    if (this.wizard && this.wizard.model && this.wizard.selectedStep && args.dynamicContentEntry) {
      if (args.dynamicContentEntry.MinorKey.indexOf(".AdditionalHelpText") >= 0) {
        this.wizard.selectedStep.bag.addOrReplaceItem(args.dynamicContentEntry.MinorKey,
          this.constantsService.BAG_ITEM_TYPES.ADDITIONAL_HELP_TEXT,
          [args.dynamicContentEntry.Content]);
      } else if (args.dynamicContentEntry.MinorKey.indexOf(".SummaryTextBlock") >= 0) {
        this.wizard.selectedStep.bag.addOrReplaceItem(args.dynamicContentEntry.MinorKey,
          this.constantsService.BAG_ITEM_TYPES.PAGE_SUMMARY_TEXT,
          [args.dynamicContentEntry.Content]);
      } else {
        this.wizard.selectedStep.bag.addOrReplaceItem(args.dynamicContentEntry.MinorKey,
          this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT,
          [args.dynamicContentEntry.Content]);
      }
    }
  }

  private getStepNumberParam(): number {
    return this.route.queryParams["step"];
  }

  private setStepNumberParam(stepNumber: number) {
    this.route.queryParams["step"] = stepNumber;
  }

  private syncUrl(selectedIndex) {
    this.setStepNumberParam(selectedIndex + 1);
  }

  private onRouteQueryParamsChange(routeParams) {
    // The query params on the activatedRoute changed. Handle any change to the Step param.
    let queryStep = this.getStepNumberParam();
    if (queryStep && queryStep !== (this.wizard.selectedIndex + 1)) {
      this.syncUrl(this.wizard.selectedIndex);
    }
  }

  /**
   * Loads the custom wizard component into the content area.
   */
  private initStep() {
    if (!this.wizard) return;

    const loadDetailComponent = async (): Promise<any> => {
      this.setDetailComponentInfo = this.reachComponentFactoryService.dynamicComponentInfo(
        this.wizardComponentCategory,
        this.wizard.selectedStep.ComponentName,
        this.wizard.getInjector(this.injector).injector
      );
    };

    let suppliedStep = this.getStepNumberParam();
    if (!suppliedStep) {
      suppliedStep = this.wizard.selectedStep.StepOrder;
      this.setStepNumberParam(suppliedStep);
    }

    this.selectedStepChangedSubscription = this.wizard.selectedStepChanged$.subscribe(newStepIndex => loadDetailComponent());
    this.wizard.setSelectedStep(suppliedStep - 1);

  }
}
