import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ArgumentExceptionService } from './argument-exception.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { UserManagerService } from './user-manager.service';
import { ValidationManagerService } from './validation-manager.service';

import { CanCancelWebServiceResponseDto, WebServiceHistoryDto } from '@coreShared/core-shared.module';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OnlineServiceHistoryService extends ReachHttpClientService {
  public uri: string = null;

  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient
    , private userManagerService: UserManagerService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/webServiceHistory`;
  }

  public getById(id: number) {
    if (!id) {
      this.argumentExceptionService.create("id").log();
    }

    return this.get<WebServiceHistoryDto>(`${this.uri}/${id}`);
  }

  public getByFunctionalInformation(processId: number, functionTypeId: number, functionNumber: string): Observable<WebServiceHistoryDto> {
    return this.get<WebServiceHistoryDto>(`${this.uri}/processId/${processId}/functionTypeId/${functionTypeId}/functionNumber/${functionNumber}`);
  }

  public initialize() {
    return this.get<WebServiceHistoryDto>(`${this.uri}/Initialize`);
  }

  public save(webServiceHistory: WebServiceHistoryDto) {
    if (!webServiceHistory) {
      this.argumentExceptionService.create("webServiceHistory").log();
    }

    if (webServiceHistory.Id) {
      return this.put<WebServiceHistoryDto>(this.uri, webServiceHistory);
    } else {
      return this.post<WebServiceHistoryDto>(this.uri, webServiceHistory);
    }
  }

  public finish(webServiceHistory: WebServiceHistoryDto) {
    if (!webServiceHistory) {
      this.argumentExceptionService.create("webServiceHistory").log();
    }

    if (webServiceHistory.Id) {
      return this.post<WebServiceHistoryDto>(`${this.uri}/finish/${webServiceHistory.Id}`, null);
    }

    this.argumentExceptionService.create("webServiceHistory").log();
  }

  public createConfirmationDocument(webServiceHistoryId: number) {
    if (!webServiceHistoryId) {
      this.argumentExceptionService.create("webServiceHistoryId").log();
    }

    return this.get<WebServiceHistoryDto>(`${this.uri}/confirmation/${webServiceHistoryId}`);
  }

  public cancel(webServiceHistoryId: number) {
    if (!webServiceHistoryId) {
      this.argumentExceptionService.create("webServiceHistoryId").log();
    }

    return this.delete<WebServiceHistoryDto>(`${this.uri}/${webServiceHistoryId}`)
  }

  public canCancel(webServiceHistoryId: number) {
    if (!webServiceHistoryId) {
      this.argumentExceptionService.create("webServiceHistoryId").log();
    }

    let canCancelWebServiceRequest = {
      WebServiceHistoryId: webServiceHistoryId
    };

    return this.post<CanCancelWebServiceResponseDto>(`${this.uri}/cancancelwebservice`, canCancelWebServiceRequest);
  }

  public downloadConfirmationDocument(id: number) {
    var url = `${this.apiRootUri}/document/file/${id}?token=${encodeURIComponent(this.userManagerService.getCurrentPrincipal().token)}&X-Client-Identifier=B20FB678-DA63-43E4-846F-3A630E6306F7`;
    window.open(url, "Download");
  }
}
