import { IAddressInfoDto,
IBaseDto,
IEntityInfoDto,
IWebServiceHistoryInfoDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ICertificationSearchResultItemDto,
ILicenseInfoDto,
ILicenseStatusChangeApplicationDto,
ILicenseStatusDto,
ILicenseStatusTransitionDto,
ILicenseTypeDto } from './licensure-shared-dto';

export class LicenseInfoDto extends BaseDto implements IBaseDto, ILicenseInfoDto {
	AllowRenewOnline: boolean;
	HasInProcessRenewal: boolean;
	ReferenceValue: string;
	LicenseNumber: number;
	EntityId: number;
	LicenseType: ILicenseTypeDto;
	LicenseStatus: ILicenseStatusDto;
	StateOfPrincipleLicense: string;
	FacilityLabel: string;
	FacilityName: string;
	IsFacilityUsed: boolean;
	FacilityLicenseeName: string;
	DoingBusinessAs: string;
	PendingLicenseStatusChangeApplicationStatusTo: ILicenseStatusDto;
	PendingStatus: ILicenseStatusDto;
	PendingApprovedDate?: Date;
	PendingEffectiveDate?: Date;
	ExpireDate?: Date;
	GrantDate?: Date;
	CmeDueDate?: Date;
	CmeRequiredHoursMessage: string;
	LicenseStatusDate?: Date;
	EntityInfo: IEntityInfoDto;
	MailingAddress: IAddressInfoDto;
	PublicAddress: IAddressInfoDto;
	BusinessAddress: IAddressInfoDto;
	SupervisionCompleteDate?: Date;
	IsSuspendSupervisionDetail?: boolean;
	SupervisedPracticeHours: string;
	UnpaidCriminalBackgroundCheckId?: number;
	PossibleLicenseStatusTransitions: ILicenseStatusTransitionDto[];
	WebServiceHistoryInfoItems: IWebServiceHistoryInfoDto[];
	Certifications: ICertificationSearchResultItemDto[];
	InProcessLicenseStatusChangeApplication: ILicenseStatusChangeApplicationDto;

}
