import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { DomainChecklistItemDto } from '@coreShared/dto-gen/domain-checklist-item-dto';
import { IApplicationTrainingDto,
IPracticeLocationCheckListItemDto } from './licensure-shared-dto';

export class PracticeLocationCheckListItemDto extends DomainChecklistItemDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IPracticeLocationCheckListItemDto {
	ApplicationTraining: IApplicationTrainingDto;

}
