import { IBaseDto } from './core-shared-dto';

export class BaseDto implements IBaseDto {
	IsNew: boolean;
	IsDeleted: boolean;
	Id: number;
	LocalId: string;



	constructor() { this.LocalId = this.guid(); }


	public hasLocalId(): boolean { return this.LocalId ? true : false; }

	public resetLocalId(): void { this['LocalId' as any] = this.guid(); }

	private guid(): string { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => { let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); });}

}
