import { inject, InjectionToken } from '@angular/core';
import { of } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { CartManagerServiceConfiguration } from '../cart-manager.service';
import { SurveyRedirectRouteResolverConfiguration } from '../survey-redirect-route-resolver-configuration.service';
import { SharedConfigurationService } from './shared-configuration.service';

export const providerConfigurationServiceTokenName = 'PROVIDER_CONFIGURATION_SERVICE';
export const DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN = new InjectionToken<DefaultProviderConfigurationService>(providerConfigurationServiceTokenName, {
  providedIn: "root",
  factory: () => defProviderConfigFactory(inject(SharedConfigurationService))
});

const defProviderConfigFactory = (shared: SharedConfigurationService) => {
  return shared.defaultProvider;
};

export abstract class ProviderConfigurationService { }

export class DefaultProviderConfigurationService implements ProviderConfigurationService {
  private _apiRootUri: string;
  private _applicationRootUri: string;
  private isInitialized = false;
  private _cartManagerServiceConfiguration: CartManagerServiceConfiguration = new CartManagerServiceConfiguration();
  private _surveyRedirectRouteResolverServiceConfiguration: SurveyRedirectRouteResolverConfiguration = new SurveyRedirectRouteResolverConfiguration();

  constructor(protected appConfigService: AppConfigService, private _providerType: string = "defaultProvider") {
    this._apiRootUri = this.appConfigService.clientProviderConfiguration.apiRootUri;
    this._applicationRootUri = this.appConfigService.clientProviderConfiguration.applicationRootUri;
  }

  public get apiRootUri(): string {
    return this._apiRootUri;
  }

  public get applicationRootUri(): string {
    return this._applicationRootUri;
  }

  public get coreDirectivePath(): string {
    return this.applicationRootUri + "/app/core/components";
  }

  public get coreAssetRoot(): string {
    return this.applicationRootUri + "/assets";
  }

  public get cartManagerServiceConfiguration(): CartManagerServiceConfiguration {
    return this._cartManagerServiceConfiguration
  }

  public get surveyRedirectRouteResolverServiceConfiguration(): SurveyRedirectRouteResolverConfiguration {
    return this._surveyRedirectRouteResolverServiceConfiguration
  }

  public get defaultProviderRoot(): string {
    return this.applicationRootUri + "/app/core/services";
  }

  public get defaultProviderAssetRoot(): string {
    return this.applicationRootUri + "/assets";
  }

  public get customProviderRoot(): string {
    return this._applicationRootUri + "/app/board-providers/" + this.providerType;
  }

  public get customProviderAssetRoot(): string {
    return this.defaultProviderAssetRoot + '/' + this.providerType;
  }

  public get providerType(): string {
    return this._providerType;
  }

  public initialize() {
    if (this.isInitialized) {
      of(true);
    }

    this.isInitialized = true;
    return of(this.isInitialized);
  }
}

