import { inject, InjectionToken } from '@angular/core';
import * as _l from 'lodash-es';
import { SharedConfigurationService } from './configuration/shared-configuration.service';

export const constantsServiceTokenName = 'CONSTANTS_SERVICE';
export const CONSTANTS_SERVICE_TOKEN = new InjectionToken<ConstantsService>(constantsServiceTokenName, {
  providedIn: 'root',
  factory: () => constantsServiceFactory(inject(SharedConfigurationService))
});

const constantsServiceFactory = (shared: SharedConfigurationService) => {
  return shared.constantsService;
};

export class ConstantsService {

  constructor() {
    console.log('ConstantsService constructor');
  }

  // System constants is a general grouping for constants used for the core system.
  // This could be separated into a more discrete set of constants but at this time
  // it is not necessary
  SYSTEM_CONSTANTS = {
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    VALIDATION_FAILURE: 'VALIDATION_FAILURE',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    LOCAL_STORAGE_STORE_ID: 'principal',
    LOCAL_STORAGE_FOR_SURVEY_CALL_BACK: 'surveycallback',
    CLIENT_ID: 'B20FB678-DA63-43E4-846F-3A630E6306F7',
    TRUE_STRING: 'true',
    FALSE_STRING: 'false',
    NOT_FOUND: 'NOT_FOUND',
    NOT_FOUND_STATUS: 404,
    NOT_FOUND_TEXT: 'Not Found'
  };

  DOMAIN_IDENTIFIER_TYPES = {
    EIN: 1,
    MN_TAX_ID: 2,
    OWNER_SSN: 3,
  };

  DELEGATION_TOKEN_STATUSES = {
    NEW: 1,
    UNAPPLIED: 2,
    APPLIED: 3,
    CANCELLED: 4
  };

  SURVEY_STATUSES = {
    COMPLETE: 1,
    BYPASSED: 2,
    STARTED: 3,
  };

  // Represents the possible answer ids for domain questions
  DOMAIN_QUESTIONS = {
    ANSWER_IDS: {
      YES: 1,
      NO: 2,
      UNANSWERED: 3,
      XBLANK: 4,
      ANSWERED_LAST_YEAR: 5
    }
  };

  // File names for assests in the application, these constants are intended to be decorated
  ASSETS = {
    IMAGES: {
      AGENCY_LOGO: 'agencyLogo.png',
      USER_GENERIC: 'user.png'
    }
  };

  //ENTITY_ASSOCIATION_DIRECTIVE_MODE = {
  //    //ENTITY_ASSOCIATE_LIST: 1,
  //    //LICENSE_NUMBER_SEARCH: 2
  //};

  ENTITY_ASSOCIATION_TYPE = {
    EMPLOYEE: 2,
  };

  ONLINE_DISPLAY_ASPECT_STATUSES = {
    NORMAL: 51,
    GREEN: 52,
    YELLOW: 53,
    RED: 54,
    BLUE: 55,
    GRAY: 56
  };

  AUTO_VERIFICATION_SUBSCRIPTION_STATUSES = {
    NEW: 1,
    ACTIVE: 2,
    EXPIRED: 3
  };

  // Set of id's representing the supported domain items in the system
  DOMAIN_IDS = {
    CART: -4,
    DATA_REQUEST: -3,
    PROFILE: -2,
    GENERAL: -1,
    PUBLIC: 0,
    ENTITY: 1,
    INVOICE: 64,
    APPLICATION_EXAM: 7,
    APPLICATION_COURSEWORK: 21,
    APPLICATION_ECFMG: 63,
    DOMAIN_QUESTIONNAIRE: 11,
    DOMAIN_FORM: 47,
    DOMAIN_AUDIT: 20,
    LICENSE: 10,
    APPLICATION: 4
  };

  DOMAIN_KEY_IDS = {
    Cart: -2,
    DATA_REQUEST_AGGREGATE: -1,
    DEFAULT: 0
  }

  DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES = {
    OPEN: 30,
    COMPLETED: 31,
    NOT_APPLICABLE: 32,
    DENY: 33
  };

  DOMAIN_FORM_STATUSES = {
    NEW: 1,
    IN_PROCESS: 2
  };

  DOMAIN_FORM_QUESTION_TYPES = {
    MULTIPLE_CHOICE: 1,
    FREE_TEXT: 2
  };

  BAG_ITEM_TYPES = {
    CHECKBOX_CONTENT: 1,
    PAGE_SUMMARY_TEXT: 2,
    RADIO_BUTTON_CONTENT: 3,
    ADDITIONAL_HELP_TEXT: 4,
    CUSTOM_CONTENT: 5
  };

  CHALLENGE_TYPE = {
    SECURITY_QUESTIONS: 1,
    EMAIL: 2,
    PASSWORD_VERIFICATION_TOKEN: 3
  };

  SYSTEM_SETTING_KEYS = {

    PROFESSIONAL_NAME: { APPLIES_TO_INDIVIDUALS: "Feature.ProfessionalName.AppliesToIndividuals", APPLIES_TO_ORGANIZATIONS: "Feature.ProfessionalName.AppliesToOrganizations", },

    ENTITY: {
      PUBLIC_EMAIL: { REQUIRED: "Feature.Entity.PublicEmail.Required" },
      PRIVATE_EMAIL: { REQUIRED: "Feature.Entity.PrivateEmail.Required" },
    },

    LOGIN: {
      ENABLED: 'Feature.Online.Login.Enabled',
    },

    // Environment Setting Keys
    ENVIRONMENT: 'Environment',
    AGENCY_NAME: 'Agency.Name',
    AGENCY_FOOTER_NAME: 'Agency.Footer.Name',
    AGENCY_ADDRESS: 'Agency.Address',
    AGENCY_CITY: 'Agency.City',
    AGENCY_STATE: 'Agency.State',
    AGENCY_ZIP: 'Agency.Zip',
    AGENCY_PHONE: 'Agency.Phone',
    AGENCY_FAX: 'Agency.Fax',
    AGENCY_EMAIL: 'Agency.Email',
    UI_WEB_TITLE: 'UI.Web.Title',
    AGENCY_ABBREVIATION: 'Agency.Abbreviation',
    CAPTCHA_KEY: 'Captcha.Key',

    LICENSE_TAB: {
      SHOW_CE_DUE_DATE: 'Feature.LicenseTab.ShowCeDueDate',
      SHOW_CE_HOURS: 'Feature.LicenseTab.ShowCeHours',
    },
    FEATURE_ONLINE_ENTITY_SEARCH_URI: 'Feature.Online.EntitySearch.Uri',
    FEATURE_WEBSERVICEHISTORY_RESUMEATLASTSTEPINDEX: 'Feature.WebServiceHistory.ResumeAtLastStepIndex',
    FEATURE_CONTACT_INFORMATION_WEBSITE_ENABLED: 'Feature.Contact.Information.Website.Enabled',
    FEATURE_APPLICANT_INFO_SHOW_MINIMAL_BIRTH_INFO_ENABLED: 'Feature.Applicant.Info.Show.Minimal.Birth.Info.Enabled',
    FEATURE_APPLICANT_INFO_BIRTH_INFO_STATE_REQUIRED: 'Feature.Applicant.Info.Birth.Info.State.Required',
    FEATURE_APPLICANT_INFO_BIRTH_INFO_COUNTRY_REQUIRED: 'Feature.Applicant.Info.Birth.Info.Country.Required',
    FEATURE_APPLICANT_INFO_BIRTH_INFO_CITY_REQUIRED: 'Feature.Applicant.Info.Birth.Info.City.Required',
    FEATURE_MAILING_ADDRESS_IS_BUSINESS_ADDRESS_ENABLED: 'Feature.Mailing.Address.Is.Business.Address.Enabled',
    FEATURE_PUBLIC_PHONE_LABEL: 'Feature.Public.Phone.Label',
    FEATURE_ONE_PUBLIC_PHONE_ENFORCEMENT_ENABLED: 'Feature.One.Public.Phone.Enforcement.Enabled',
    FEATURE_ONE_PRIMARY_PHONE_ENFORCEMENT_ENABLED: 'Feature.One.Primary.Phone.Enforcement.Enabled',
    FEATURE_SCHOOL_OTHER_CHECKBOX_ENABLED: 'Feature.School.Other.Checkbox.Enabled',
    FEATURE_DUPLICATE_CARD_ENABLED: 'Feature.DuplicateCard.Enabled',
    FEATURE_DUPLICATE_WALL_CERTIFICATE_ENABLED: 'Feature.DuplicateWallCertificate.Enabled',
    FEATURE_DUPLICATE_CARD_LICENSE_TYPE_CODES: 'Feature.DuplicateCard.LicenseTypeCodes',
    FEATURE_DUPLICATE_CARD_LICENSE_STATUS_CODES: 'Feature.DuplicateCard.LicenseStatusCodes',
    FEATURE_DUPLICATE_WALL_CERTIFICATE_LICENSE_TYPE_CODES: 'Feature.DuplicateWallCertificate.LicenseTypeCodes',
    FEATURE_DUPLICATE_WALL_CERTIFICATE_LICENSE_STATUS_CODES: 'Feature.DuplicateWallCertificate.LicenseStatusCodes',
    FEATURE_SECOND_LICENSE_CARD_ENABLED: 'Feature.SecondaryLicenseCard.Enabled',
    FEATURE_DOMAINCHECKLIST_SUMMARY_DESCRIPTION_ENABLED: 'Feature.DomainChecklist.SummaryDescription.Enabled',
    ENTITY_ADDRESS_DIRECTIVE_TYPE: 'Feature.Entity.Address.Directive.Type',
    ENTITY_ADDRESS_DIRECTIVE_BUSINESS_ADDRESS_REQUIRED_RULE_ENABLED: 'Feature.License.BusinessAddressRequiredRule.Enabled',
    HOLDS: {
      ENABLED: 'Feature.Holds.Enabled'
    },
    ONLINE_LANDING: {
      APPLICATION: {
        CBC_CHECKLIST: {
          STATUS_ONLY: "Feature.OnlineLanding.Application.CbcChecklist.StatusOnly"
        },
        FILE_UPLOAD: {
          ENABLED: "Feature.OnlineLanding.Application.FileUpload.Enabled",
        },
      },
      LICENSE: {
        FILE_UPLOAD: { ENABLED: "Feature.OnlineLanding.License.FileUpload.Enabled", },
        CHECKLIST: { ENABLED: "Feature.OnlineLanding.License.Checklist.Enabled", },
        ADDRESS_EDITABLE: "Feature.OnlineLanding.License.Address.Directive.Editable",
        ORG_PHYSICAL_ADDRESS_LOCATION_TYPE_ID: "Feature.OnlineLanding.License.Address.Directive.OrgPhysicalAddressLocationTypeId"
      }
    },
    CE_SPONSOR_SEARCH: {
      ENABLED: "Feature.Online.CeSponsorSearch.Enabled",
    },
    COURSE_SEARCH: {
      ENABLED: "Feature.Online.CourseSearch.Enabled",
    },
    ONLINE_ENTITY_SEARCH: {
      EXPIRE_DATE_THIRD_PARTY_JURISDICTION_EXCLUDED :'Feature.OnlineEntitySearch.ExpireDate.ThirdPartyJurisdiction.Excluded',
      ISSUE_DATE_THIRD_PARTY_JURISDICTION_EXCLUDED :'Feature.OnlineEntitySearch.IssueDate.ThirdPartyJurisdiction.Excluded',
      PUBLIC_ACTIONS_THIRD_PARTY_JURISDICTION_EXCLUDED :'Feature.OnlineEntitySearch.PublicActions.ThirdPartyJurisdiction.Excluded',
      PUBLIC_EMAIL_VISIBLE: 'Feature.OnlineEntitySearch.PublicEmail.Visible',
      LICENSE_TITLE: "Feature.OnlineEntitySearch.LicenseTitle",
      CERTIFICATIONS: {
        VISIBLE: "Feature.OnlineEntitySearch.Certifications.Visible",
        SHOW_EXPIRED_CERTIFICATIONS_MESSAGE: "Feature.OnlineEntitySearch.Certifications.ShowExpiredCertificationsMessage"
      },
      LICENSE_TYPE_DISPLAY: {
        MODE: "Feature.OnlineEntitySearch.LicenseTypeDisplay.Mode",
        OPTIONS: {
          PROFESSIONAL_SUFFIX: "ProfessionalSuffix",
          DESCRIPTION: "Description"
        },
      },
      BIRTH_DATE_DISPLAY: {
        MODE: "Feature.OnlineEntitySearch.BirthDateDisplay.Mode",
        OPTIONS: {
          NONE: "NONE",
          YEAR_ONLY: "YEAR_ONLY",
          FULL: "FULL",
        },
      },
      GENDER: {
        VISIBLE: "Feature.OnlineEntitySearch.Gender.Visible"
      },
      ENTITY_NAME_DISPLAY: {
        MODE: "Feature.OnlineEntitySearch.EntityNameDisplay.Mode",
        OPTIONS: {
          FML: "FML",
          FMLS: "FMLS",
          FMIL: "FMIL",
          FMILS: "FMILS"
        },
      },
      DESIGNATED_ADDRESS: {
        VISIBLE: "Feature.OnlineEntitySearch.DesignatedAddress.Visible",
        LABEL: "Feature.OnlineEntitySearch.DesignatedAddress.Label",
        INDIVIDUAL: { MODE: "Feature.OnlineEntitySearch.DesignatedAddress.Individual.Mode" },
        ORGANIZATION: { MODE: "Feature.OnlineEntitySearch.DesignatedAddress.Organization.Mode" },
      },
      DESIGNATED_PHONE: {
        VISIBLE: "Feature.OnlineEntitySearch.DesignatedPhone.Visible",
        LABEL: "Feature.OnlineEntitySearch.DesignatedPhone.Label",
      },
    },
    INVOICE: {
      ENABLED: 'Feature.Online.Invoices.Enabled'
    },
    APPLICATION: {
      ENABLED: 'Feature.OnlineApplication.Enabled',
      COLLECT_SEPARATE_LICENSE_FEES: {
        ENABLED: 'Feature.OnlineApplication.CollectSeparateLicenseFees.Enabled',
        APPLICATION_STATUS: 'Feature.OnlineApplication.CollectSeparateLicenseFees.ApplicationStatus'
      },
      APPLICATION_CONTINUATION: {
        ENABLED: 'Feature.OnlineApplication.ApplicationContinuation.Enabled',
        APPLICATION_STATUS: 'Feature.OnlineApplication.ApplicationContinuation.ApplicationStatus'
      },
      APPLICATION_WIZARD: {

        APPLICANT_INFO: {

          TITLE_REQUIRED: "Feature.Applicant.Info.TitleRequired",
          SHOW_TITLE_AND_SUFFIX: "Feature.Applicant.Info.ShowTitleAndSuffix",

          GENDER: {
            ENABLED: 'Feature.ApplicationWizard.CollectApplicantGender'
          },
          ETHNIC_GROUP: {
            ENABLED: 'Feature.Entity.EthnicGroup.Enabled'
          },
          DRIVERS_LICENSE: {
            STATE_ENABLED: 'Feature.ApplicationWizard.CollectApplicantDriversLicenseState',
            NUMBER_ENABLED: 'Feature.ApplicationWizard.CollectApplicantDriversLicenseNumber'
          }

        },
        EDUCATION_DIRECTIVE_EDUCATION_TYPES: {
          OCCUPATIONAL_EDUCATION: "Licensure",
          OTHER_EDUCATION: "OtherEducation"
        },
        EDUCATION_DIRECTIVE_TYPE: "Feature.Education.Directive.Type",
        EDUCATION_DIRECTIVE_TYPE_OPTIONS: {
          LIST: "applicationEducationList",
          LIST_WITH_SEARCH: "applicationEducationListWithSearch"
        },

        ENABLED: 'Feature.ApplicationWizard.Enabled',
        WEB_NEW_APPLICATION_PRACTICE_QUESTIONS: "Web.NewApplication.PracticeQuestions",
      }
    },
    COURSE: {
      ONLINE_LOOKUP: {
        ENABLED: 'Feature.CourseLookup.Enabled'
      }
    },
    CONTACT_INFORMATION_PRIVATE_EMAIL_VISIBLE: 'Feature.ContactInformation.PrivateEmail.Visible',
    CONTACT_INFORMATION_PUBLIC_EMAIL_VISIBLE: 'Feature.ContactInformation.PublicEmail.Visible',
    CE_AUDIT: {
      ONLINE: {
        //ENABLED: 'Feature.DomainAudits.ContinuingEducation.Online.Enabled'
        ENABLED: 'Feature.DomainAudits.ContinuingEducation.Enabled'
      }
    },
    CE_SPONSOR: {
      ONLINE_LOOKUP: {
        ENABLED: 'Feature.CESponsorLookup.Enabled'
      }
    },
    DISCIPLINE: { ONLINE_COMPLAINT: { ENABLED: 'Feature.OnlineComplaint.Enabled' } },
    LICENSE: {
      ONLINE_LICENSE_SEARCH: {
        ENABLED: 'Feature.OnlineLicenseSearch.Enabled'
      },
      ONLINE_ENTITY_SEARCH: {
        ENABLED: 'Feature.OnlineEntitySearch.Enabled'
      },
      RENEWAL: {
        ENABLED: 'Feature.OnlineRenewal.Enabled',
        SURVEY_ENABLED: 'Feature.OnlineRenewal.SurveyEnabled',
        SURVEY_URI: 'Feature.OnlineRenewal.ExternalSurveyUri',
        SURVEY_CALLBACK_URI: 'Feature.OnlineRenewal.SurveyCompleteCallbackUri',
        RENEWAL_WIZARD: {
          ENABLED: 'Feature.LicenseRenewalWizard.Enabled'
        }
      },
      STATUS_CHANGE: {
        ENABLED: 'Feature.OnlineLicenseStatusChanges.Enabled'
      },
      DELEGATE_ADMINISTRATION: {
        ENABLED: 'Feature.DelegatedAdministration.Enabled'
      },
      RE_ACTIVATION: {
        ENABLED: 'Feature.OnlineLicenseReactivate.Enabled'
      },
      DUPLICATE_LICENSE_CARD: {
        ENABLED: 'Feature.DuplicateCard.Enabled'
      },
      LICENSE_VERIFICATION: {
        ENABLED: 'Feature.LicenseVerification.Enabled',
        EMAIL_REQUIRED: 'Feature.LicenseVerification.Online.EmailRequired'
      },
      LICENSE_CERTIFICATION: {
        ENABLED: 'Feature.License.Certification.Enabled',
        SHOW_STAFF_VERIFIED: 'Feature.License.Certification.ShowStaffVerified',
        SHOW_LICENSEE_VERIFIED: 'Feature.License.Certification.ShowLicenseeVerified',
      },
      TEMPORARY_PERMIT: {
        ENABLED: 'Feature.TemporaryPermit.Enabled',
        REACTIVATION_SUPPORT: {
          ENABLED: 'Feature.TemporaryPermit.ReactivationSupport.Enabled'
        },
        APPLICATION_SUPPORT: {
          ENABLED: 'Feature.TemporaryPermit.ApplicationSupport.Enabled'
        }
      },
      SECOND_LICENSE_CARD: {
        ENABLED: 'Feature.SecondaryLicenseCard.Enabled'
      }
    },
    SUPERVISION: {
      ENABLED: 'Feature.Supervision.Online.Enabled',
      LICENSE_TAB: { SUPERVISION_SUMMARY: { ENABLED: 'Feature.Supervision.LicenseTab.SupervisionSummary.Enabled' } },
      DESIGNATION_FORM_ENABLED: "Feature.Supervision.Designation.Form.Enabled",
      ONLINE_PLANS: {
        SUPERVISEE_ENABLED: "Feature.Supervision.OnlinePlans.Supervisee.Enabled",
        SUPERVISOR_ENABLED: "Feature.Supervision.OnlinePlans.Supervisor.Enabled",
        PRACTICE_LOCATION_TEXT_ENABLED: "Feature.Supervision.SupervisionPlan.PracticeLocationText.Enabled",
        HOURS_PER_WEEK_ENABLED: "Feature.Supervision.SupervisionPlan.HoursPerWeek.Enabled",
        END_DATE_ENABLED: "Feature.Supervision.SupervisionPlan.EndDate.Enabled"
      },
      ONLINE_VERIFICATIONS: {
        SUPERVISEE_ENABLED: "Feature.Supervision.OnlineVerifications.Supervisee.Enabled",
        SUPERVISOR_ENABLED: "Feature.Supervision.OnlineVerifications.Supervisor.Enabled",
        PRACTICE_LOCATION_TEXT_ENABLED: 'Feature.Supervision.SupervisionVerification.PracticeLocationText.Enabled'
      },
      SUPERVISORATTESTATION: {
        ENABLED: 'Feature.Supervision.OnlineAttestation.Enabled'
      }
    },
    DATA_REQUEST: {
      ENABLED: 'Feature.DataRequest.Enabled'
    },
    EXAM_RETAKE: {
      ENABLED: 'Feature.ExamRetake.Enabled'
    },
    CRIMINAL_BACKGROUND_CHECK: {
      FEE_WIZARD: {
        ENABLED: 'Feature.CriminalBackgroundCheck.FeeWizard.Enabled'
      }
    },
    AUTO_VERIFICATION: {
      ENABLED: 'Feature.AutoVerification.Enabled'
    },
    EDUCATION_PROGRAM_COMPLETION: {
      ENABLED: 'Feature.EducationProgramCompletion.Online.Enabled'
    },
    CE_SPONSORSHIP: {
      ENABLED: 'Feature.ContinuingEducationSponsorship.Enabled'
    },
    CE_BASIC: {
      ENABLED: 'Feature.ContinuingEducationBasic.Enabled'
    },
    MILITARY_ACTIVE_DUTY: {
      ENABLED: 'Feature.License.MilitaryActiveDuty.Enabled'
    },
    EDUCATION_PROGRAM: {
      EDUCATION_PROGRAM_COMPLETION_WIZARD: {
        ENABLED: 'Feature.EducationProgramCompletionWizard.Enabled'
      }
    },
    REGISTRATION_WIZARD: {
      ENABLED: 'Feature.RegistrationWizard.Enabled',
      OPTIONAL_SSN: 'Feature.Registration.OptionalSsn.Enabled'
    }
  };

  OPTION_VALUES = {
    EMPTY_OPTION_VALUE: '',
    OTHER_OPTION_VALUE: '- Other -'
  };


  ONLINE_ACCOUNT_TYPES = {
    PERSONAL: 1,
    EDUCATION_PROGRAM: 2
  };

  ONLINE_SERVICE_SORT = {
    USER_REGISTRATION: 2010,
    FORGOT_PW: 2020
  };

  ENTITY_GROUP_IDS = {
    SCHOOL: 50
  };

  VALIDATION_MODES = {
    WEB_PASSWORD_CHANGE: 'Web.ChangePassword',
    WEB_EMPLOYMENT_LIST: 'Web.EmploymentList',
    LADING_PAGE: {
      WEB_LICENSE_RENEWAL_CONTACT_INFO: 'Web.LicenseRenewal.ContactInfo'
    },
    PROFILE_LANDING_CONTACT_INFORMATION: 'Web.ProfileLanding.ContactInfo'
  };

  WIZARD_ACTIONS = {
    FORWARD: 'Forward',
    BACKWARD: 'Backward',
    STEP_FORWARD: 'Forward (Step)',
    STEP_BACKWORD: 'Backward (Step)',
    FINISH: 'Finish',
    CANCEL: 'Cancel',
    ADD_TO_CART_AND_CONTINUE_SHOPPING: 'Add to Cart and Continue'
  };

  BUSY_MANAGER_BUSY_TYPES = {
    COMMAND: 1,
    ROUTE_RESOLVE: 2,
    APPLICATION_INIT: 3,
    VIEW_INIT: 4,
    ROUTE_NAV: 5
  };

  WEB_SERVICE_PROCESS_TYPES = {
    // Note: these contants have been moved to their respective feature configuration services.
    //  MANUAL_FEES_ENTITY: 3, // Not used
    //MANUAL_FEES_APPLICATION: 4, // Not used
    //MANUAL_FEES_LICENSE: 5, // Not used
    //USER_REGISTRATION: 18, // Web2
    //FORGOT_PASSWORD: 19, // Web2
    //DATA_REQUEST_CANNED: 24,
    //DATA_REQUEST_AD_HOC: 25,
    //DATA_REQUEST_STATISTICAL: 26,
    //USER_REGISTRATION_CARD: OnlineServiceProcessTypeKeys.UserRegistration,
    //FORGOT_PASSWORD_CARD: OnlineServiceProcessTypeKeys.ForgotPassword,
    //ACTIVITY: OnlineServiceProcessTypeKeys.Activity,
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = {
    CART: 'Cart',
    SHARED_CONTENT: 'Shared.Content',
    LOGIN: 'Login',
    ERROR: 'Provider',
    LANDING: 'Landing',
    PASSWORD_CHANGE: 'ChangePassword',
    DATA_REQUEST: 'DataRequest',
    CHANGE_SECURITY_QUESTIONS: 'ChangeSecurityQuestions',
  };

  DYNAMIC_CONTENT_CATEGORIES = {
    HELP: 1,
    CONTENT: 2
  };

  DYNAMIC_CONTENT_LOCATION_TYPES = {
    PAGE: 1,
    NAVBAR_MENU: 2,
    WIZARD_MENU: 3,
    WIZARDPAGE_MENU: 4
  };

  DYNAMIC_CONTENT_PRESENTATION_TYPES = {
    TOOLTIP: 1,
    MODAL: 2,
    EXTERNAL_URL: 3,
    TEXT: 4
  };

  VALIDATION_ERROR_TYPES = {
    SERVER: 0,
    CLIENT: 1
  };

  VALIDATION_ERROR_SCOPES = {
    APPLICATION: "0",
    DIALOG: "10",
    TOAST: "20"
  };

  NOTIFICATION_SCOPES = {
    APPLICATION: "100",
    LANDING_CARD: "200",
    LANDING_DETAIL: "300"
  };

  FUNCTION_TYPES = {
    ACTIVITY: 3,
    HOLD_FILE: 4,
    CONTRACT: 6,
    CORRESPONDENCE: 7,
    CASH_MANAGEMENT: 8,
    ENTITY: 9,
    TEMPORARY_PERMIT: 11,
    TEMPORARY_REGISTRATION: 12,
    RESIDENCY_PERMIT: 13,
    COMPLAINT_GROUP: 14,
    EMPLOYMENT: 23,
    DOMAIN_FORM: 32,
    DOMAIN_AUDIT: 34,
    INVOICE: 45
  };

  ADDRESS_TYPES = {
    APPLICATION: 1,
    LICENSE: 2,
    ENTITY: 6,
    EMPLOYMENT: 18
  };

  ADDRESS_LOCATION_TYPES = {
    HOME: 1,
    BUSINESS: 2,
    HOME_AND_BUSINESS: 3,
    OTHER: 4,
    PHYSICAL: 5
  };

  FEATURE_CONSTANTS = {

  };

  SSN_MODES = {
    DEFAULT: 'DEFAULT',
    LAST_FOUR: 'LAST_FOUR'
  };

  CHECKLIST_TEMPLATE_ITEM_SET_IDS = {
    // Overridden in custom providers
  };

  DATA_REQUEST_SOURCES = {

  };

  CARD_DISPLAY_ORDER = {
    GENERAL: -9999,
    PROFILE: 9998,
    CART: 9999,
    INVOICE: 1010
  };

  public static MergeSettings(targetConstantsServiceProperty, sourceProperty) {
    _l.merge(targetConstantsServiceProperty, sourceProperty);
    return targetConstantsServiceProperty;
  }
}
