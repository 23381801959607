import { IBaseDto, IInformationFileDto, IInvoiceInfoDto, INotificationDto, IProfileSummaryInfoDto, IRequestedDataReportInfoDto, IUserProfileInfoDto, IWebCartDto, IWebLinkInfoDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class UserProfileInfoDto extends BaseDto implements IBaseDto, IUserProfileInfoDto {
	Cart: IWebCartDto;
	HasInformationFileHolds: boolean;
	UserAccountTypeId: number;
	IsIndividual: boolean;
	IsDirectNameChangeAllowed: boolean;
	ProfileSummaries: IProfileSummaryInfoDto[];
	DataRequests: IRequestedDataReportInfoDto[];
	InformationFileHolds: IInformationFileDto[];
	WebLinks: IWebLinkInfoDto[];
	Invoices: IInvoiceInfoDto[];
	Notifications: INotificationDto[];
}
