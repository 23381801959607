import { Injectable } from '@angular/core';
import { AuthorizationConfiguration } from './authorization-configuration-provider.service';
import { DynamicContentConfiguration } from './dynamic-content-configuration-provider.service';

export class RouteConfiguration {
  constructor(public requiresProfileRefresh: boolean
    , public dynamicContentConfiguration: DynamicContentConfiguration
    , public authorizationConfiguration: AuthorizationConfiguration) {
  }
}
@Injectable({
  providedIn: 'root'
})
export class RouteConfigurationProviderService {
  public getConfigurationData(requiresProfileRefresh: boolean, dynamicContentConfiguration: DynamicContentConfiguration, authorizationConfiguration: AuthorizationConfiguration): RouteConfiguration {
    return new RouteConfiguration(requiresProfileRefresh, dynamicContentConfiguration, authorizationConfiguration);
  }
}

