<div class="p-fluid p-formgrid p-grid p-col-12" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <!-- ConvictionType -->
    <div class="p-field p-col-12 p-sm-6 p-lg-3">
        <label for="convictiontype">Conviction Type</label>
        <p-dropdown *ngIf="dataLoaded" inputId="convictiontype" formControlName="ConvictionType"
            [options]="possibleConvictionTypes" optionLabel="Description" dataKey="Id" [showClear]="true"
            placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- Crime Description -->
    <div class="p-field p-col-12">
        <label for="CrimeDescription">Crime Description</label>
        <input id="CrimeDescription" type="text" formControlName="CrimeDescription" pInputText>
    </div>

    <!-- Conviction Date -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="ConvictionDate">Conviction Date</label>
        <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" id="ConvictionDate" formControlName="ConvictionDate"
            [showIcon]="false" yearNavigator="true" monthNavigator="true" defaultDate="" appendTo="body" baseZIndex="1">
        </p-calendar>
    </div>

    <!-- Header -->
    <div class="p-col-12 reach-section-header">Court of Jurisdiction</div>

    <!-- Country -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="Country">Country</label>
        <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
            optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;" baseZIndex="1">

            <!-- Selected Item -->
            <ng-template let-country pTemplate="selectedItem">
                <div class="p-fluid p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

            <!-- List Item -->
            <ng-template let-country pTemplate="item">
                <div class="p-grid p-nogutter">

                    <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                    </div>
                    <div class="p-col p-ml-2">{{country.Description}}</div>

                </div>
            </ng-template>

        </p-dropdown>
    </div>

    <!-- State -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="state">{{outputModel?.CourtCountry == CANADA ? PROVINCE : STATE}}</label>
        <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State"
            [options]="outputModel?.CourtCountry == CANADA ? possibleProvinces : possibleStates" optionLabel="StateCode"
            dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
        </p-dropdown>
    </div>

    <!-- City -->
    <div class="p-field p-col-12 p-sm-6 p-lg-4">
        <label for="city">City</label>
        <input id="city" type="text" formControlName="City" pInputText>
    </div>

    <!-- Sentence -->
    <div class="p-field p-col-12">
        <label for="Sentence">Sentence</label>
        <input id="Sentence" type="text" formControlName="Sentence" pInputText>
    </div>

</div>