import { IBagDto,
IDomainIdentifierDto,
IDomainReasonForChangeDtoHost,
IEntityAddressDto,
IEntityAssociateDto,
IEntityPhoneDto,
IOnlineUserDto,
IUserAccountDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class OnlineUserDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IOnlineUserDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	AddressTypeId: number;
	UserAccountId?: number;
	LastName: string;
	FirstName: string;
	MiddleName: string;
	Ssn: string;
	DriversLicenseState: string;
	BirthDate?: Date;
	Bag: IBagDto;
	UserAccount: IUserAccountDto;
	PhoneNumbers: IEntityPhoneDto[];
	Associates: IEntityAssociateDto[];
	AssociatedWith: IEntityAssociateDto[];
	IsIndividual: boolean;
	DriversLicenseNbr: string;
	BirthCounty: string;
	Email: string;
  OrganizationEmail: string;
	PrivateEmail: string;
	Addresses: IEntityAddressDto[];
	AddressMailing: IEntityAddressDto;
	AddressPublic: IEntityAddressDto;
	EthnicGroupId?: number;
	AlternateName: string;
	Gender: string;
	Suffix: string;
	BirthCity: string;
	BirthState: string;
	BirthCountry: string;
	Website: string;
	IsMilitary: boolean;
	MilitaryResponseTypeId?: number;
	MilitaryDischargeDate?: Date;
	UsePrimaryNameAsProfessionalName: boolean;
	SecondaryLastName: string;
	SecondaryMiddleName: string;
	SecondaryFirstName: string;
	SecondaryTitle: string;
	SecondarySuffix: string;
	EntityId?: number;
	Title: string;
	DomainIdentifiers: IDomainIdentifierDto[];

}
