<div>
  <!-- Professional Practice -->
  <!--<div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.LICENSE_RE_ACTIVATION_PROFESSIONAL_PRACTICE && checklistItem">-->
  <!--<professional-practice-checklist-item *ngIf="checklistItem" checklist-item="checklistItem"></professional-practice-checklist-item>-->
  <!--  -->
  <!--</div>-->
  <!-- Domain Questionnaire (170, 141, 3170, 2170, 175)-->
  <!--<div *ngIf="checklistItem
                      && checklistItem.ChecklistItemTemplate
                      && checklistItem.ChecklistItemTemplate.ChildDomainId  == constantsService.DOMAIN_IDS.DOMAIN_QUESTIONNAIRE">
    -->
  <!--<domain-questionnaire *ngIf="checklistItem" domain-questionnaire-host="checklistItem" filter-function="vmDomainChecklistItem.questionnaireFilterQuestionsFunction"></domain-questionnaire>-->
  <!-- </div>-->
  <!-- Application Practice Locations-->
  <!--<div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.PRACTICE_LOCATIONS_CHECKLIST_TEMPLATE_ITEM_ID">-->
  <!--<application-practice-location-list application="domainChecklistHost"></application-practice-location-list>-->
  <!--</div>-->
  <!-- Temporary Permit Application -->
  <!-- <div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_TEMPORARY_PERMIT_CHECKLIST_ITEM_ID && checklistItem">-->
  <!--<temporary-permit-checklist-item *ngIf="checklistItem
                                       && domainChecklistHost
                                       && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.LICENSE.TEMPORARY_PERMIT.ENABLED)
                                       && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.LICENSE.TEMPORARY_PERMIT.APPLICATION_SUPPORT.ENABLED)"
                                   checklist-item="checklistItem"
                                   domain-checklist-host="domainChecklistHost"></temporary-permit-checklist-item>-->
  <!--</div>-->
  <!-- Temporary Permit Reactivation -->
  <!--<div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.LICENSE_REACTIVATION_TEMPORARY_PERMIT_CHECKLIST_ITEM_ID && checklistItem">-->
  <!--<temporary-permit-checklist-item *ngIf="checklistItem
                                       && domainChecklistHost
                                       && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.LICENSE.TEMPORARY_PERMIT.ENABLED)
                                       && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.LICENSE.TEMPORARY_PERMIT.REACTIVATION_SUPPORT.ENABLED)"
                                  checklist-item="checklistItem"
                                  domain-checklist-host="domainChecklistHost"></temporary-permit-checklist-item>-->
  <!-- </div>-->
  <!-- Application Eduction-->
  <!-- <div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.OCCUPATIONAL_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID
          && domainChecklistHost
          && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_WIZARD.ENABLED)">
    -->
  <!-- TODO: JJS -->
  <!-- <application-education-checklist-item domain-checklist-host="domainChecklistHost"
    checklist-item-template-id="constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.OCCUPATIONAL_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID"
    directive-configuration="directiveConfiguration">
  </application-education-checklist-item> -->
  <!--</div>-->
  <!-- Other Application Eduction-->
  <!-- <div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.OTHER_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID
           && domainChecklistHost
           && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_WIZARD.ENABLED)">
  -->
  <!--<application-education-checklist-item domain-checklist-host="domainChecklistHost"
                                        checklist-item-template-id="constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.OTHER_EDUCATION_CHECKLIST_TEMPLATE_ITEM_ID"
                                        directive-configuration="directiveConfiguration">
  </application-education-checklist-item>-->
  <!-- </div>-->
  <!-- Yes/No Checklist Item-->
  <!--<div *ngIf="checklistItemTemplateId > 200 && checklistItemTemplateId  < 300">-->
  <!--<yes-no-checklist-item dynamic-content-configuration="dynamicContentConfiguration" domain-checklist-host="domainChecklistHost" checklist-item="checklistItem"></yes-no-checklist-item>-->
  <!--</div>-->
  <!-- Verification of License -->
  <!--<div *ngIf="checklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.OTHER_LICENSE_CHECKLIST_TEMPLATE_ITEM_ID">-->
  <!--<verification-of-license-checklist-item domain-checklist-host="domainChecklistHost"></verification-of-license-checklist-item>-->
  <!--</div>-->
  <!-- Multiple templates for (140) just to make the code easier to read -->
  <!-- Application Exam (140) -->
  <!--<div *ngIf="checklistItem
           && checklistItem.ChecklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_EXAM_CHECKLIST_TEMPLATE_ITEM_ID
           && domainChecklistHost
           && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.APPLICATION.APPLICATION_WIZARD.ENABLED)
           && !(domainChecklistHost.DomainChecklistItems | filter: {checklistItemTemplateId : constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_EXAM_RETAKE_FEE}).length">-->
  <!--<application-exam-checklist-item domain-checklist-host="domainChecklistHost"
                                   checklist-item="checklistItem"
                                   directive-configuration="directiveConfiguration">
  </application-exam-checklist-item>-->
  <!--</div>-->
  <!-- Application Exam Retake (140)-->
  <!--<div *ngIf="checklistItem
           && checklistItem.ChecklistItemTemplateId == constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_EXAM_CHECKLIST_TEMPLATE_ITEM_ID
           && domainChecklistHost
           && systemSettingsManagerService.asBoolean(constantsService.SYSTEM_SETTING_KEYS.EXAM_RETAKE.ENABLED)
           && (domainChecklistHost.DomainChecklistItems | filter: {checklistItemTemplateId : constantsService.CHECKLIST_TEMPLATE_ITEM_IDS.APPLICATION_EXAM_RETAKE_FEE}).length">-->
  <!--<application-exam-checklist-item domain-checklist-host="domainChecklistHost"
                                   checklist-item="checklistItem"
                                   directive-configuration="directiveConfiguration">
  </application-exam-checklist-item>-->
  <!--</div>-->
</div>
