import { Component, Injectable, Injector, Type } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";

import * as _l from 'lodash-es';

import { Command } from '@coreModels/command';
import { IReachDialogContentComponent } from '@coreComponents/reach-dialog/reach-dialog-content.component';
import { ReachInjectorService, ReachInjectorProviderItem } from '@coreServices/reach-injector.service';

export const DIALOG_DATA_INJECTOR_TOKEN: string = 'DIALOG_DATA_INJECTOR_TOKEN';

/**
 * Encapsulates the data values passed via the DialogSettings to dialog content components. Facilitates cloning of data model objects.
 */
export class DialogData {
  public outputModel = null;
  public dialogForm: FormGroup = null;
  public validationScope: string = null;

  constructor(public inputModel = null, public configuration = null, public initializationData: any = {}, public isOpen: boolean) {
  }

  public get isValid(): boolean {
    return this.dialogForm && this.dialogForm.valid;
  }
}

@Injectable()
export class DialogDataInjector {
    public dialogData: DialogData;
    public contentComponentInstance: IReachDialogContentComponent;
}

/**
 * Represents settings for a dialog instance.
 */
export class DialogSettings {
  /**
   * The function to invoke to open the dialog.
   */
  public open;

  /**
   * The Command to invoke when the dialog OK button is clicked.
   */
  public okCommand: Command;

  /**
  * The Command to invoke when the dialog Cancel button is clicked.
  */
  public cancelCommand: Command;

  /**
   * When true the Ok button is disabled.
   */
  public disableOkCommand: boolean = false;

  /**
   * The dialog display size overrides.
   */
  public size;

  /**
   * Sets/gets the Open/displayed state of the dialog.
   */
  public isOpen: boolean = false;

  /**
   * Sets/gets the instance of the rendered dialog Component.
   */
  public componentInstance = null;

  /**
   * Initialization data for the dialog. Lookup values for dropdowns, etc. Key-value pairs.
   */
  public initializationData: any = {};

  /**
   * Configuration settings for the dialog.
   */
  public configuration = null;

  /**
   * The message scope key to use for routing validation messages.
   */
  public validationScope: string = null;

  /**
   * Initializes a new instance of a DialogSettings representing a new modal dialog instance.
   * @param scenarioKey the metadata key associated with the scenario for which the dialog component is being created.
   * @param contentComponentType the Type of the Component that will be rendered in the main content area of the dialog.
   * @param  contentComponentTypeKey the metadata key associated with the content Component Type.
   * @param model the data model to bind to the dialog's (content component's) elements.
   * @param title the dialog title bar text.
   * @param okCommandDoesNotCloseDialog if true the dialog remains open after the OK button is clicked.
   * @param useModelReference  If true, the Dialog should use the (shared) model instance passed by the host, otherwise clone a private instance.
   * @param showValidationSummary if true this instance displays the validation summary component.
   */
  constructor(
    public dialogComponentInstance,
    public scenarioKey: string = "",
    public contentComponentType: Type<any> = null,
    public contentComponentTypeKey: string = "",
    public title: string = "",
    public model = null,
    public okCommandDoesNotCloseDialog: boolean = false,
    public useModelReference: boolean = false,
    public showValidationSummary: boolean = true) {
  }

  /**
   * Returns a custom Injector based on the specified parent injector with a DialogSettingsInjector that wraps this DialogSettings instance as a
   * dependency injection parameter.
   * @param parentInjector the parent Injector on which the custom Injector will be based.
   */
  public getInjector(parentInjector: Injector) {
    let injectorService = new ReachInjectorService(parentInjector);
    let dialogSettingsInjector = new DialogDataInjector();
    dialogSettingsInjector.dialogData = this.getDialogData();
    injectorService.addItem(DIALOG_DATA_INJECTOR_TOKEN, dialogSettingsInjector);
    return {
      injectorService: injectorService,
      dialogSettingsInjector: dialogSettingsInjector,
      dialogData: dialogSettingsInjector.dialogData
    };
  }

  /**
   * Returns a new instance of a ModalData object representing the data portion of this instance.
   */
  public getDialogData(): DialogData {
    let modelItem = this.model;
    if (!this.useModelReference) {
      modelItem = _l.cloneDeep(this.model);
    }

    return new DialogData(modelItem, this.configuration, this.initializationData, this.isOpen);
  }
}

