import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ChecklistFileUploadComponent, WizardStepComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, UserManagerService, ValidationManagerService } from 'src/app/core/index-services';
import { OnlineAssociatedLicenseSearchCriteria } from 'src/app/licensureShared';
import { LicenseService, OnlineAssociatedLicenseSearchService } from '../../licensure-core.module';

export const FacilityServiceInfoComponentSelector: string = "facility-service-info";
export const FacilityServiceInfoComponentKey: string = "facilityServiceInfo";

@Component({
  selector: FacilityServiceInfoComponentSelector,
  templateUrl: './facility-service-info.component.html',
  styleUrls: ['./facility-service-info.component.scss']
})
export class FacilityServiceInfoComponent extends WizardStepComponent implements OnInit {

  currentPrincipalName: string = "-";
  currentPrincipalEmail: string = "-";
  facilityLicenseeName: string = "-";
  accountInfo: string = "-";

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , validationManagerService: ValidationManagerService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , protected userManagerService: UserManagerService
    , protected licenseService: LicenseService
    , protected onlineAssociatedLicenseSearchService: OnlineAssociatedLicenseSearchService) {

    super(constantsService, validationManagerService, wizardInjector, ChecklistFileUploadComponent.name, checkboxContentService, elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const currentPrincipal = this.userManagerService.getCurrentPrincipal().user;
    if (currentPrincipal.LastName) this.currentPrincipalName = currentPrincipal.FirstName ? `${currentPrincipal.FirstName} ${currentPrincipal.LastName}` : currentPrincipal.LastName;
    if (currentPrincipal.Email) this.currentPrincipalEmail = currentPrincipal.Email;

    const searchCriteria = new OnlineAssociatedLicenseSearchCriteria();
    searchCriteria.LicenseId = this.wizard.model.license.Id;

    // Use the forkJoin operator from RxJS to run multiple observables in parallel and get their combined results.
    forkJoin([this.onlineAssociatedLicenseSearchService.search(searchCriteria), this.licenseService.getLicenseInfo(this.wizard.model.license.Id)])
      .subscribe(([associatedLicenseSearchResults, licenseInfoDto]) => {

        if (associatedLicenseSearchResults.Results.length) this.accountInfo = associatedLicenseSearchResults.Results.pop().AuthorizedUserRole;
        if (licenseInfoDto?.FacilityLicenseeName) this.facilityLicenseeName = licenseInfoDto.FacilityLicenseeName;
        
        this.wizard.selectedStep.bag.addOrReplaceItem("currentPrincipalName", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.currentPrincipalName]);
        this.wizard.selectedStep.bag.addOrReplaceItem("currentPrincipalEmail", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.currentPrincipalEmail]);
        this.wizard.selectedStep.bag.addOrReplaceItem("facilityLicenseeName", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.facilityLicenseeName]);
        this.wizard.selectedStep.bag.addOrReplaceItem("accountInfo", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.accountInfo]);

      });

  }
}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, FacilityServiceInfoComponentKey, FacilityServiceInfoComponent, FacilityServiceInfoComponentSelector);