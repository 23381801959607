import { Component, forwardRef, Input, Inject, OnInit, OnChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { debounceTime } from 'rxjs/operators';
import { SystemSettingsManagerService } from '../../services/system-settings-manager.service';
import { UtilitiesService } from '../../services/utilities.service';


@Component({
  selector: 'ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SsnComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SsnComponent),
      multi: true
    }
  ]
})
export class SsnComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Input
  @Input() mode: string;
  @Input() model;
  @Input() giveFocus: boolean = false;

  // Fields
  ssnModes: any;
  friendlyNames: any = {};
  dynamicLabel: string = "Social Security #";
  dynamicInputMask: string = "999-99-9999";
  dynamicRegExPattern: string = "characterPattern";
  isSsnOptional: boolean;

  // Forms
  contentForm: FormGroup;

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private utilitiesService: UtilitiesService,
    systemSettingsManagerService: SystemSettingsManagerService
  ) {

    // Declarations
    this.ssnModes = this.constantsService.SSN_MODES;
    this.isSsnOptional = systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.REGISTRATION_WIZARD.OPTIONAL_SSN);

    // Initialize form.
    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  */
  ngOnChanges(): void {

    // Dynamically set SSN control validators.
    this.setValidators();

    if (this.model?.Ssn) this.contentForm.get('Ssn').setValue(this.model.Ssn);
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {

    // Initialize the contentForm.
    this.contentForm = new FormGroup({
      Ssn: new FormControl(null, [Validators.required]),
      HasNoSsn: new FormControl(false),
    });

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel = () => {
    this.model.Ssn = this.contentForm.get('Ssn').value ?? '';
    this.model.Ssn = this.model.Ssn.replace('-', '').replace('-', '');
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {
    // When anything in this contentForm changes, update the model.
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });

    // No SSN
    this.contentForm.get("HasNoSsn").valueChanges.subscribe(x => {

      // If 'I have no SSN' is checked...
      if (x == true) {

        // ...clear and disable the SSN input.
        this.contentForm.get("Ssn").setValue(null);
        this.contentForm.get("Ssn").disable();
      }
      else {

        // ...otherwise activate the SSN input.
        this.contentForm.get("Ssn").enable();
      }
    });

  }

  /**
   * Dynamically set SSN control validators.
   */
  protected setValidators(): void {

    if (!this.mode) {
      this.mode = this.ssnModes.DEFAULT;
    }
    else if (!(this.mode === this.ssnModes.DEFAULT || this.mode === this.ssnModes.LAST_FOUR)) {
      throw "Invalid 'mode' attribute supplied to ssn directive. Valid modes are ('LAST_FOUR', 'DEFAULT')";
    }

    if (this.isLastFourMode) {
      this.dynamicLabel = "Last 4 of SSN";
      this.dynamicInputMask = "9999";
      this.dynamicRegExPattern = '[0-9]{4}';
    }
    else if (this.isDefaultMode) {
      this.dynamicLabel = "Social Security #";
      this.dynamicInputMask = "999-99-9999";
      this.dynamicRegExPattern = "(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)";
    }

    // IMPORTANT: Set the chosen regex pattern as a validator on the SSN control.
    this.contentForm.get('Ssn').clearValidators();
    this.contentForm.get('Ssn').setValidators([Validators.required, Validators.pattern(this.dynamicRegExPattern)]);
    this.contentForm.updateValueAndValidity();
  }

  public get isLastFourMode(): boolean {
    return this.mode === this.ssnModes.LAST_FOUR;
  }

  public get isDefaultMode(): boolean {
    return this.mode === this.ssnModes.DEFAULT;
  }

  public ssnControl(): AbstractControl {
    let control = this.contentForm.get('Ssn');
    return control;
  }

  public hasNoSsnControl(): AbstractControl {
    let control = this.contentForm.get('HasNoSsn');
    return control;
  }

  public get hasNoSsn() {
    return this.hasNoSsnControl().value;
  }

  public get hasNoSsnDisabled(): boolean {
    let ssnControl = this.ssnControl();
    let isDisabled = ssnControl.value && ssnControl.value.length > 0;
    return isDisabled;
  }

  public get hasNoSsnRequired(): boolean {
    let ssnControl = this.ssnControl();
    let isRequired = this.isSsnOptional && (!this.hasNoSsn && ssnControl.value === null);
    return isRequired;
  }

  // ==================================== CVA ====================================

  /**
  * Aggregates errors and passes them to the parent.
  * @param c The parent form control.
  */
  validate(c: AbstractControl): ValidationErrors | null {

    // Combine errors from this component...
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.contentForm.valid ? null : errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.contentForm.disable()
      this.contentForm.get("Ssn").setValue(null);
      this.contentForm.get("Ssn").disable();
    } else {
      this.contentForm.enable()
      this.contentForm.get("Ssn").enable();
    }
  }

}
