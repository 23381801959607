import {  } from '@coreShared/dto-gen/core-shared-dto';
import { LookupDto } from '@coreShared/dto-gen/lookup-dto';
import { ICertificationOrganizationDto,
ICertificationTypeDto,
ILicenseTypeDto } from './licensure-shared-dto';

export class CertificationTypeDto extends LookupDto implements ICertificationTypeDto {
	CertificationCode: string;
	LicenseType: ILicenseTypeDto;
	CertificationOrganizationId: number;
	CertificationOrganization: ICertificationOrganizationDto;
	IsSubcertification: boolean;
	SpecialtyPracticeId?: number;
	SpecialtyPractice: any;
	LicenseTypeCode: string;

}
