import { IBaseDto,
ISuffixDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class SuffixDto extends BaseDto implements IBaseDto, ISuffixDto {
	IsInactive: boolean;
	DisplayOrder: number;
	Suffix: string;

}
