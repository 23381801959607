import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { IActivitySubactivityDocumentTypeDto, IActivitySubactivityDto, IDocumentDto, IDocumentExtensionDto } from 'src/app/coreShared/core-shared.module';
import { ReachScenarios } from '../../index-constants';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from '../../index-models';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DocumentService, ListService, ValidationManagerService } from '../../index-services';
import { ReachDialogContentComponentV2 } from '../reach-dialog/reach-dialog-content-v2.component';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'file-upload-editor',
  templateUrl: './file-upload-editor.component.html',
  styleUrls: ['./file-upload-editor.component.scss']
})
export class FileUploadEditorComponent extends ReachDialogContentComponentV2<IDocumentDto> implements OnInit, AfterViewInit {

  uploadedFile: File;
  uploadAcceptHeaderInfo: string;

  @ViewChild('fileUploader') fileUploader: FileUpload;

  possibleDocumentTypes: IActivitySubactivityDocumentTypeDto[];
  possibleActivitySubactivities: IActivitySubactivityDto[];
  possibleDocumentExtensions: IDocumentExtensionDto[];

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    public documentService: DocumentService,
    validationManagerService: ValidationManagerService,
    private listService: ListService,
    private busyManagerService: BusyManagerService) {

    super(constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  ngAfterViewInit(): void {
    if (this.model.RawFile) {
      this.uploadedFile = this.model.RawFile;
      this.fileUploader._files.push(this.uploadedFile);
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.busyManagerService.resolve(
      forkJoin([this.listService.getActivitySubactivityDocumentTypes(), this.listService.getActivitySubactivities(), this.listService.getDocumentExtensions()])
        .pipe(map(([documentTypes, activitySubactivities, documentExtensions]) => {

          this.possibleDocumentTypes = this.listService.filterInactiveItems(documentTypes) as IActivitySubactivityDocumentTypeDto[];
          this.possibleActivitySubactivities = this.listService.filterInactiveItems(activitySubactivities) as IActivitySubactivityDto[];
          this.possibleDocumentExtensions = this.listService.filterInactiveItems(documentExtensions) as IDocumentExtensionDto[];
          this.uploadAcceptHeaderInfo = this.getAcceptedExtensionTypes();

          const initializationData = this.dialogDataInjector.dialogData.initializationData;

          const subactivityId = this.possibleActivitySubactivities.find(item => item.ActivityTypeId == initializationData.activityTypeId && item.ActivitySubtypeId == initializationData.activitySubtypeId)?.Id;
          if (!subactivityId) console.log(`No ActivitySubactivity is configured for ActivityType ${initializationData.activityTypeId} and ActivitySubtype ${initializationData.activitySubtypeId}`);

          this.possibleDocumentTypes = this.possibleDocumentTypes.filter(item => item.ActivitySubactivityId == subactivityId && (item.ProfessionType == "*" || item.ProfessionType == initializationData.professionTypeCode));
        })),
      this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  override modelToForm(): void {
    super.modelToForm();

    this.friendlyNames.OriginalFileName = 'Document';
    this.friendlyNames.DisplayName = 'Document Description';
    this.friendlyNames.DisplayName = 'Display Name';

    this.contentForm.addControl("OriginalFileName", new FormControl(this.model.OriginalFileName, [Validators.required, Validators.maxLength(256)]));
    this.contentForm.addControl("DisplayName", new FormControl(this.model.DisplayName, [Validators.required, Validators.maxLength(256)]));
    this.contentForm.addControl("Description", new FormControl(this.model.Description, [Validators.required, Validators.maxLength(2000)]));
  }

  override formToModel(): void {
    super.formToModel();

    this.model.OriginalFileName = this.contentForm?.get('OriginalFileName').value;
    this.model.DisplayName = this.contentForm?.get('DisplayName').value?.Name ?? this.contentForm?.get('DisplayName').value;
    this.model.Description = this.contentForm.get("Description").value;
  }

  // Assumption: possibleDocumentExtensions has a value.
  private getAcceptedExtensionTypes(): string {
    var x = [...new Set(this.possibleDocumentExtensions.filter(ext => ext.AllowOnlineUpload).map(ext => ext.Extension))]
    return x.join(",");
  }

  onUpload(event: UploadEvent) {
    this.uploadedFile = event.files[0];
    this.contentForm?.get('OriginalFileName').setValue(this.uploadedFile?.name); // Set values in the contentForm
    this.model.RawFile = this.uploadedFile; // Put the file on the IDocumentDto model    
  }

  isImage(): boolean {
    return /^image\//.test(this.uploadedFile?.type || "");
  }

  formatFileSize(bytes: number): string {
    const k = 1024;
    if (bytes < k) return `${bytes} B`;
    else if (bytes < Math.pow(k, 2)) return `${Math.round(bytes / k)} KB`;
    else if (bytes < Math.pow(k, 3)) return `${Math.round(bytes / Math.pow(k, 2))} MB`;
  }

}

registerDynamicComponent(ReachScenarios.Default, 'FileUploadEditorComponent', FileUploadEditorComponent, 'file-upload-editor');
