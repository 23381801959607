<p-table *ngIf="filteredChecklistItems.length" styleClass="p-datatable-sm reach-responsive-table" [value]="filteredChecklistItems" [responsive]="true"
    responsiveLayout="stack">

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th>Status</th>
            <th>Description</th>
            <th class="p-text-right">Upload</th>
        </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-item>
        <tr>
            <td> <span class="p-column-title">Status</span>{{item.Status.Description | whenEmpty: '-'}} </td>
            <td> <span class="p-column-title">Description</span>{{item.Summary | whenEmpty: '-'}} </td>

            <!-- Actions -->
            <td class="p-text-right">
                <span class="p-column-title">Upload</span>
                <button pButton pRipple class="p-button-text" [icon]="getIcon(item)" [command-button]="uploadCommand"
                    [command-parameter]="item" [pTooltip]="getToolTip(item)"></button>
            </td>

        </tr>

    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td colspan="3">
                <span class="reach-text-muted">No items to upload</span>
            </td>
        </tr>
    </ng-template>

</p-table>

<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>