import { IBagDto,
IBaseDto,
IDocumentDto,
IDomainChecklistItemDto,
IDomainQuestionnaireDto,
IEntityDto,
IInvoiceDto,
IOnlineUserSearchCriteriaDto,
IProcessFeeDto,
IStepDataDto,
IUserAccountDto,
IWebServiceViewDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class StepDataDto extends BaseDto implements IBaseDto, IStepDataDto {
	Action: string;
	Invoice: IInvoiceDto;
	Documents: IDocumentDto[];
	Entity: IEntityDto;
	CriminalBackgroundCheckId?: number;
	Step: IWebServiceViewDto;
	Errors: string[];
	Fees: IProcessFeeDto[];
	OnlineUserSearchCriteria: IOnlineUserSearchCriteriaDto;
	OnlineUserAccount: IUserAccountDto;
	DomainQuestionnaireDto: IDomainQuestionnaireDto;
	Bag: IBagDto;
	ChecklistItemsWithDocuments: IDomainChecklistItemDto[];
}
