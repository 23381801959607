import { Injectable } from '@angular/core';
import { WizardTags } from '@coreModels/wizard-tags';

@Injectable({
  providedIn: 'root'
})
export class WizardTagsService {
  public create(applicationOrLicenseTypeId: string = null
    , applicationBasisId: string = null
    , licenseStatusId: string = null
    , formTypeId: number = null
    , hasSupervisionPlan: boolean = null
    , sponsorshipTypeId: number = null
    , licenseStatusIdTo: string = null
    , processId: number = null
    , supervisorDesignationFormId: number = null
    , isOpiate: boolean = null
    , isOutOfState: boolean = null
    , licenseSubtypeId: number = null
    ) {

    return new WizardTags(applicationOrLicenseTypeId,
      applicationBasisId,
      licenseStatusId,
      formTypeId,
      hasSupervisionPlan,
      sponsorshipTypeId,
      licenseStatusIdTo,
      processId,
      supervisorDesignationFormId,
      isOpiate,
      isOutOfState,
      licenseSubtypeId
    );
  }
}

