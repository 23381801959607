import { Guid } from "guid-typescript";

/**
 * Entry object for the Busy Stack.
 */
export class BusyEntry {
    constructor(public id: Guid, public busyType: number) { }

    /**
     * Creates a new BusyEntry object of the specified BUSY_MANAGER_BUSY_TYPES.
     * @param busyType
     */
    public static create(busyType: number): BusyEntry {
        return new BusyEntry(Guid.create(), busyType);
    }
} 

/**
 * Event Args object for the Busy Event.
 */
export class BusyChanagedEventArgument {
  constructor(public busyStack: any[] = []) {
  }
}
