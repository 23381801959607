import { IDomainReasonForChangeDtoHost,
IEntityAssociateDto,
IEntityAssociateTypeDto,
IEntityInfoDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class EntityAssociateDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityAssociateDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	EntityId: number;
	AssociateEntityId: number;
	AssociateTypeId: number;
	Comment: string;
	BeginDate?: Date;
	EndDate?: Date;
	AssociateType: IEntityAssociateTypeDto;
	EntityInfo: IEntityInfoDto;
	AssociatedEntityInfo: IEntityInfoDto;

}
