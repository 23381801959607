// FRAMEWORK
import { Component, Input, OnChanges } from '@angular/core';
import { from, of } from "rxjs";

// LODASH
import * as _l from 'lodash-es';

// CORE
import { IDocumentDto, DocumentSearchCriteriaDto } from '@coreShared/core-shared.module';
import { ButtonCommand } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { DocumentService } from '@coreServices/document.service';

/**
 * Shared component that implements Document file download. Presents a pick list of IDocumentDto documents that match the specified/input-param
 * RelatedTo search criteria. Clicking document items in the list downloads them to the browser.
 * */
@Component({
  selector: 'reach-document-downloader',
  templateUrl: './reach-document-downloader.component.html',
  styleUrls: ['./reach-document-downloader.component.scss']
})
export class ReachDocumentDownloaderComponent implements OnChanges {
  documents: IDocumentDto[] = [];
  documentTargets: ButtonCommand[] = [];

  /**
   * Input parameter to the componet directive selector element that specifies the RelatedTo search criteria.
   */
  @Input() searchCriteria: DocumentSearchCriteriaDto = null;

  // CTOR
  constructor(
    private commandService: CommandService,
    private documentService: DocumentService
  ) { }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  */
  ngOnChanges(): void {

    // Initialize available documents.
    this.loadPossibleDocuments();
  }

  /**
   * Searches for documents to display using the documentService.
   * @returns 
   */
  private loadPossibleDocuments() {

    if (!this.searchCriteria) throw "Provide document service search criteria.";

    // Async function to be executed.
    const doSearch = async (): Promise<boolean> => {

      this.documents = await this.documentService.search(this.searchCriteria).toPromise();
      this.initCommands();

      return of(true).toPromise();
    };

    // Execution of async function.
    return from(doSearch());
  }

  /**
   * Initialize download commands.
   */
  private initCommands() {

    // Bail if no documents are available.
    if (!this.documents) return;

    this.documents.forEach((d: IDocumentDto) => {

      // Create a command...
      let downloadCommand = this.commandService.createButtonCommand(
        () => { return true; },
        () => {
          const doDownload = async (): Promise<boolean> => {
            this.documentService.downloadDocument(d);
            return of(true).toPromise();
          };

          return from(doDownload());
        },
        d.DisplayName
      );

      // ... and add it to the the collection.
      this.documentTargets.push(downloadCommand);
    });

  }
}
