import { Injectable, Inject } from "@angular/core";
import { CartService } from './cart.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { OnlineTransactionService } from './online-transaction.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { CanRemoveItemFromCartRequestDto, CanRemoveItemFromCartResponseDto, CanSubmitCartRequestDto, CanSubmitCartResponseDto, IWebCartDto, IWebCartItemDto, IWebServiceHistoryDto, IWebTransactionDto, WebCartDto, WebCartItemDto } from '@coreShared/core-shared.module';
import { Router } from "@angular/router";
import { LandingComponentKeys } from "../index-constants";
import { RouteInfoRegistry } from "../index-models";
import * as _ from 'underscore';
import { Observable, of } from "rxjs";

export class CartManagerServiceConfiguration {
  public afterCartSubmitHandler: (cart: IWebCartDto, cartSubmitResponse: IWebTransactionDto) => Observable<IWebTransactionDto> = (cart, cartSubmitResponse) => of(cartSubmitResponse);
}

@Injectable({
  providedIn: 'root'
})
export class CartManagerService {

  public get cart(): IWebCartDto {
    return this.userManagerService.getCurrentPrincipal()?.user?.UserAccount?.profile?.Cart
  }

  public set cart(val: IWebCartDto) {
    let x = this.userManagerService.getCurrentPrincipal().user.UserAccount.profile;
    if (x) x.Cart = val; // UserAccount.profile must exist.
  }

  public get cartEmpty(): boolean { return !this.cart || !_.any(this.cart.Items, (item: WebCartItemDto) => { return item.IsDeleted === false; }) }
  public get cartHasZeroBalance(): boolean { return this.cart && this.cart.ItemsTotal == 0; }

  constructor(private cartService: CartService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private onlineTransactionService: OnlineTransactionService
    , private userManagerService: UserManagerService, private router: Router,) {
  }

  /**
   * Create a new WebCartDto using the cartService.
   */
  public async createCart(): Promise<void> {
    //Avoid using async/await on setters please
    const c = await this.cartService.create(this.userManagerService.getCurrentPrincipal().user.UserAccount).toPromise();
    this.cart = c;
  }

  /**
   * Remove a single item from the cart.
   * @param item
   */
  public async removeCartItem(item: IWebCartItemDto): Promise<void> {
    //Avoid using async/await on setters please
    const c = await this.cartService.removeItem(item).toPromise();
    this.cart = c;

    // If the cart is empty, return to the landing page.
    if (!this.cart) this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);
  }

  /**
   * Create web transaction/continue to payment.
   */
  public async submitCart(): Promise<any> {

    let transactionResponse = await this.onlineTransactionService.createWebTransaction(this.cart).toPromise();
    await this.defaultProviderConfigurationService.cartManagerServiceConfiguration.afterCartSubmitHandler(this.cart, transactionResponse).toPromise();
    return transactionResponse;
  }

  public async prepareCart(): Promise<IWebCartDto> {
    if (!this.cart) {
      await this.createCart();
    }
    return Promise.resolve(this.cart);
  }

  public async addCartItem(item: IWebServiceHistoryDto, webCart: IWebCartDto = null): Promise<void> {
    let preparedCart: IWebCartDto = null;

    // If no cart already assigned we need to prepare one
    if (!this.cart) {
      preparedCart = webCart;
      if (!preparedCart) {
        preparedCart = await this.prepareCart();
        this.cart = preparedCart;
      }
    }

    if (!this.cart) {
      throw new Error(`Unable to prepare cart. At time of error webCart param was: ${webCart} and preparedCart was ${preparedCart}`)
    }

    let cartItem = {
      CartId: this.cart.Id,
      ServiceHistoryId: item.Id,
      ServiceHistoryItem: item
    } as IWebCartItemDto;

    //Avoid using async/await on setters please
    let c = await this.cartService.addItem(cartItem).toPromise();
    this.cart = c;
  }

  public async canRemoveItem(item: WebCartItemDto): Promise<CanRemoveItemFromCartResponseDto> {
    let request = new CanRemoveItemFromCartRequestDto();
    request.CartItemId = item.Id;
    request.CartId = item.CartId;
    return await this.cartService.canRemoveItem(request).toPromise();
  }

  public canSubmitCart(cart: IWebCartDto): Observable<CanSubmitCartResponseDto> {
    let request = new CanSubmitCartRequestDto();
    request.CartId = cart.Id;
    return this.cartService.canSubmitCart(request);
  }
}
