<div class="p-fluid p-formgrid p-grid" *ngIf="formDataIsInit" [formGroup]="contentForm">

  <!-- Type -->
  <div class="p-field p-col-12 p-sm-6 p-xl-4">
    <label for="Type">Type</label>
    <p-dropdown *ngIf="dataLoaded" inputId="Type" formControlName="Type" [options]="possibleTypes"
      optionLabel="Description" dataKey="Id" [showClear]="true" placeholder="&nbsp;" appendTo="body" baseZIndex="1">
    </p-dropdown>
  </div>

  <!-- Checkboxes -->
  <div class="p-col-12 p-sm-6 p-field-checkbox p-mt-sm-5 p-pt-sm-2">

    <!-- Is Primary -->
    <p-checkbox inputId="IsPrimary" formControlName="IsPrimary" binary="true" *ngIf="isPrimaryEnabled"></p-checkbox>
    <label class="p-mr-3" for="IsPrimary" *ngIf="isPrimaryEnabled">Primary</label>

    <!-- Is Public -->
    <p-checkbox inputId="IsPublic" formControlName="IsPublic" binary="true" *ngIf="isPublicEnabled">
    </p-checkbox>
    <label for="IsPublic" *ngIf="isPublicEnabled">{{isPublicLabel}}</label>

  </div>

  <!-- Number/Extension -->
  <div class="p-col-12 p-grid p-fluid p-nogutter">

    <!-- Number -->
    <div class="p-field p-col-8 p-sm-6 p-xl-4 p-pr-2">
      <label for="Number">Number</label>
      <p-inputMask inputId="Number" mask="(999) 999-9999" formControlName="Number" [autoClear]="false" *ngIf="standardNumber"></p-inputMask>
      <input id="Number" maxlength="20" type="text" formControlName="Number" *ngIf="!standardNumber" pInputText>

      <!-- Checkbox -->
      <div class="p-field-checkbox p-mt-3 p-mb-0">
        <p-checkbox inputId="IrregularNumber" formControlName="IrregularNumber" binary="true"></p-checkbox>
        <label for="IrregularNumber">I don't have a standard US phone number.<field-help majorKey="Shared.Content" minorKey="EntityPhone.IrregularNumber"></field-help></label>
      </div>

    </div>

    <!-- Extension -->
    <div class="p-field p-col-4 p-sm-2 p-pl-2">
      <label for="Extension">Extension</label>
      <input id="Extension" type="number" formControlName="Extension" pInputText>
    </div>

  </div>

</div>
