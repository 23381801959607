<div *ngIf="isEnabled">

  <!-- Heading (optional) -->
  <div *ngIf="displayHeading" class="reach-section-header">{{headingText}}</div>

  <!-- Checkbox -->
  <div class="p-field-checkbox p-d-flex" [formGroup]="checkboxContentForm">

    <p-checkbox [inputId]="entryIdAttributeValue" [formControl]="checkboxContentForm.controls['Check']" binary="true"
      [required]="isRequired">
    </p-checkbox>

    <label [for]="entryIdAttributeValue" style="flex-grow:1">

      <!-- Adorned display -->
      <div *ngIf="reachApplicationService.showDynamicContentKeyAdornments">
        <div class="p-d-flex p-jc-end">
          <span class="p-tag p-tag-warning">{{item.majorKey}} - {{item.minorKey}}</span>
        </div>
        <div class="reach-adornment-container">
          <span [innerHtml]="entryContent"></span>
        </div>
      </div>

      <!-- Normal display -->
      <div *ngIf="!reachApplicationService.showDynamicContentKeyAdornments">
        <span [innerHtml]="entryContent"></span>
      </div>

    </label>

  </div>
  
</div>