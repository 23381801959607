import { IDomainItemDto } from './core-shared-dto';
import { LookupDto } from './lookup-dto';

export class DomainItemDto extends LookupDto implements IDomainItemDto {
	ProfessionTypeId: number;
	HasInterface?: boolean;
	InterfaceName: string;
	IsChecklistVisible: boolean;

}
