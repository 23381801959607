import { IDocumentDto,
IDomainChecklistItemDto,
IDomainReasonForChangeDtoHost,
IRelatedToBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEducationProgramComplianceDto } from './licensure-shared-dto';

export class EducationProgramComplianceDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEducationProgramComplianceDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ProgramName: string;
	EducationProgramId: number;
	StatusId?: number;
	StatusModifiedBy: string;
	StatusModifiedDate?: Date;
	CompletedDate?: Date;
	EffectiveDate?: Date;
	IsProgramOrganizationChange?: boolean;
	ProgramOrganizationChangeType: string;
	DirectorFiveYearCnt?: number;
	IsDirectorAdminResp?: boolean;
	IsDirectorAssist?: boolean;
	IsDirectorAdminSupport?: boolean;
	DirectorResponse: string;
	IsFacultyOrientation?: boolean;
	IsFacultyMentoring?: boolean;
	FacultyDataResponse: string;
	IsSimulationFacultyCertified?: boolean;
	IsSimulationLabAccredited?: boolean;
	IsClinicalNursingCourse?: boolean;
	IsClinicalStudentRecord?: boolean;
	ClinicalLearningHours?: number;
	ClinicalSimulationHours?: number;
	IsClinicalOtherState?: boolean;
	ClinicalOtherStateText: string;
	StudentSlotsNewCnt?: number;
	AdmissionSlotsNewCnt?: number;
	AdmissionSlotsAvailableCnt?: number;
	AdmissionSlotsFilledCnt?: number;
	AdmissionNotAcceptedCnt?: number;
	AdmissionDataResponse: string;
	CompletedLpnNotHeldCnt?: number;
	CompletedLpnHeldCnt?: number;
	CompletedRnOnlyCnt?: number;
	CompletedRnLpnHeldCnt?: number;
	Status: any;
	DomainChecklist: IDomainChecklistItemDto[];
	HostStaffAssigned: string;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	Documents: IDocumentDto[];
	DocumentIds: number[];
	AdditionalRelatedToItems: IRelatedToBaseDto[];
	UnitId: number;
	ActivityTypeId: number;
	ActivitySubtypeId: number;
	ReviewStaffAssigned: string;
	ChecklistItemId?: number;
	EntityId?: number;

}
