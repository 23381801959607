import { IWebServiceHistoryInfoDto } from './core-shared-dto';

export class WebServiceHistoryInfoDto implements IWebServiceHistoryInfoDto {
	ProcessTypeId: number;
	WebServiceHistoryId: number;
	ServiceInProgress: boolean;
	HasProcessFees: boolean;
	FunctionTypeId?: number;
	FunctionNumber?: number;
	ProcessStatusCode: string;
	ProcessStatusCodeTo: string;
	IsCancellationAllowed: boolean;

}
