import { IDomainReasonForChangeDtoHost,
IInvoiceFeeDto,
IProcessFeeDto,
ISourceCodeDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class InvoiceFeeDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IInvoiceFeeDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	InvoiceId: number;
	SourceCode: string;
	SourceCodeItem: ISourceCodeDto;
	Amount: number;
	DisplayOrder: number;
	IsInactive: boolean;
	ProcessFee: IProcessFeeDto;

}
