import { Component, Inject, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import * as _l from 'lodash-es';

import { BusyManagerService } from '@coreServices/busy-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { ReachMessage } from '@coreModels/reach-message';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { SimpleChanges } from "node_modules/@angular/core/core";

@Component({
  selector: 'message-toggle-button',
  templateUrl: './message-toggle-button.component.html',
  styleUrls: ['./message-toggle-button.component.scss']
})
export class MessageToggleButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() messageScope: string;
  @Input() labelText: string;
  @Input() iconClass: string = "fa fa-bell";
  @Input() badgeSeverity: string = "danger";

  isVisible: boolean = false;
  messageCount: number = 0;
  toggleState: boolean = false;
  TooltipText: string = "Click to show messages";
  private subscriptionErrorsChanged: Subscription = null;
  private subscriptionToggleValidationRequested: Subscription = null;
  toggleValidationCommand: Command = null;
  private subscriptionMessageChanged: Subscription;

  constructor(
    private busyManagerService: BusyManagerService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private reachMessageService: ReachMessageService
  ) {
  }

  ngOnInit(): void {
    // Commands
    this.toggleValidationCommand = this.commandService.create(() => {
        return this.isVisible;
      },
      () => {
        this.toggleState = !this.toggleState;
        this.reachMessageService.setToggleState(this.messageScope, this.toggleState);
        if (this.toggleState) {
          this.TooltipText = "Click to collapse messages";
        } else {
          this.TooltipText = "Click to show messages";
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.messageScope && !this.subscriptionMessageChanged) {
      this.subscriptionMessageChanged = this.reachMessageService.get$(this.messageScope)
        .subscribe((arg: ReachMessage[]) => this.onMessageUpdates(arg));
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionMessageChanged) {
      this.subscriptionMessageChanged.unsubscribe();
    }
  }

  onMessageUpdates(messages: ReachMessage[]) {
    this.messageCount = messages.length;
    this.isVisible =
      this.messageCount >
      0; // && !this.busyManagerService.isBusy(this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }
}
