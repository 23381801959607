import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEntityConvictionDto,
IConvictionTypeDto } from './licensure-shared-dto';

export class EntityConvictionDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityConvictionDto {
	CrimeDescription: string;
	EntityId?: number;
	ConvictionTypeId?: number;
	ConvictionDate?: Date;
	CourtCity: string;
	CourtStateId: string;
	CourtCountyId?: number;
	CourtCountry: string;
	CourtSentence: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	ConvictionType: IConvictionTypeDto;

}
