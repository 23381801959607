import { IDomainReasonForChangeDtoHost,
IValidatableDto } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IApplicationInfoDto,
IDegreeDto,
IEducationDto,
IEducationProgramDto,
IEducationProgramTypeDto,
IExamTypeDto,
IMajorDto } from './licensure-shared-dto';

export class EducationDto extends ValidatableDto implements IValidatableDto, IDomainReasonForChangeDtoHost, IEducationDto {
	TemporaryId: any;
	ApplicationId: number;
	SchoolEntityId?: number;
	DegreeId?: number;
	MajorId?: number;
	EducationProgramId?: number;
	EducationProgramTypeId?: number;
	SchoolName: string;
	SchoolCity: string;
	SchoolState: string;
	SchoolCountry: string;
	StartDate: string;
	EndDate: string;
	DegreeDateDescription: string;
	DegreeDate?: Date;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	IsUnlisted: boolean;
	DerivedDescription: string;
	ProgramCode: string;
	Major: IMajorDto;
	Degree: IDegreeDto;
	EducationProgramType: IEducationProgramTypeDto;
	EducationProgram: IEducationProgramDto;
	ApplicationInfo: IApplicationInfoDto;
	ExamType: IExamTypeDto;

}
