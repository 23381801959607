import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { IDomainIdentifierTypeDto, IMilitaryResponseTypeDto, IOnlineUserDto, IUserAccountDto } from 'src/app/coreShared/core-shared.module';

/**
 * Reach custom validator methods for form control validation.
 */
export class ReachControlValidators {
  /**
   * Validates that the Date represented by the specified 'from' FormControl is earlier than the Date represented by the specified 'to' FormControl.
   * @param from the FormControl representing the start Date of the date range.
   * @param to the FormControl representing the end Date of the date range.
   */
  public static dateOrderValidator(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      let fDate = (f.value && Object.prototype.toString.call(f.value) === "[object Date]" && !isNaN(f.value)) ? f.value : new Date(f.value);
      let tDate = (t.value && Object.prototype.toString.call(t.value) === "[object Date]" && !isNaN(t.value)) ? t.value : new Date(t.value);
      if (f.value > t.value) {
        return {
          dateOrder:
          {
            message: `'${from}' must be earlier than or equal to '${to}'.`,
            startKey: from,
            endKey: to
          }
        };
      }
      return {};
    }
  }

  /**
   * Validates that the specified date is after the reference date.
   * @param dateToEvaluate the date to be evaluated.
   * @param referenceDate the reference date to validate against.
   * @example myFormGroup = this.fb.group({someDateControl: ['', ReachControlValidators.dateGreaterThan('2/3/2019')]});
   */
  public static dateGreaterThan(referenceDate: Date, message: string = null) {

    return (control: AbstractControl): { [key: string]: any } | null => {
      if ((referenceDate == null || control.value == null) || new Date(control.value) > new Date(referenceDate)) return null;
      return { errorMessage: message ?? "End date must be after Start date." };
    }
  }

  /**
   * Validates that the specified date is before the reference date.
   * @param dateToEvaluate the date to be evaluated.
   * @param referenceDate the reference date to validate against.
  * @example myFormGroup = this.fb.group({someDateControl: ['', ReachControlValidators.dateLessThan('2/3/2019')]});
   */
  public static dateLessThan(referenceDate: Date, message: string = null): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      if ((referenceDate == null || control.value == null) || new Date(control.value) < new Date(referenceDate)) return null;
      return { errorMessage: message ?? "End date must be after Start date." };
    }
  }

  /**
   * Validates that the specified number greater than the reference number.
   * @param numberToEvaluate the number to be evaluated.
   * @param compareToNumber the reference number to validate against.
    * @example myFormGroup = this.fb.group({someNumericControl: ['', ReachControlValidators.numberGreaterThan(42)]});
   */
  public static numberGreaterThan(compareToNumber) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var result = {
        isValid: true,
        errorMessage: ""
      }

      result.isValid = control.value > compareToNumber;
      if (!result.isValid) {
        result.errorMessage = "First number must be less than second number.";
      }

      return result;
    }
  }

  /**
   * Validates that the specified number less than the reference number.
   * @param numberToEvaluate the number to be evaluated.
   * @param compareToNumber the reference number to validate against.
    * @example myFormGroup = this.fb.group({someNumericControl: ['', ReachControlValidators.numberLessThan(42)]});
   */
  public static numberLessThan(compareToNumber) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var result = {
        isValid: true,
        errorMessage: ""
      }

      result.isValid = control.value < compareToNumber;
      if (!result.isValid) {
        result.errorMessage = "First number must be less than second number.";
      }

      return result;
    }
  }

  /**
   * Validates that the specified value represents a number.
   * @param value the value to be evaluated.
    * @example myFormGroup = this.fb.group({someNumericControl: ['', ReachControlValidators.validateNumericOnly]});
   */
  public static validateNumericOnly(control: AbstractControl) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    // Evaluate rule (ignore empty)
    if ((control.value != undefined) && (control.value != "") && !(/^\d+$/.test(control.value))) {
      result.isValid = false;
      result.errorMessage = `'${control.value}' must be numeric.`;
    }

    return result;
  }

  public static validateDomainIdentifier(domainIdentifierType: AbstractControl) {

    return (control: AbstractControl) => {

      if (!control.value || !domainIdentifierType?.value) return null;

      const identifierType: IDomainIdentifierTypeDto = domainIdentifierType.value as IDomainIdentifierTypeDto;
      const regex = new RegExp(identifierType.FormatRegex); // Convert string to regex object.

      if (!regex.test(control.value)) {
        return { errorMessage: `${identifierType.Description} is not in proper format. An example of proper format is ${identifierType.FormatMessage}` }
      }
    }
  }

  /**
   * Validates that the specified value represents a valid email uri.
   * @param value the value to be evaluated.
    * @example myFormGroup = this.fb.group({someEmailAddressControl: ['', ReachControlValidators.validateEmailAddress]});
   */
  public static validateEmailAddress(control: AbstractControl) {
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Evaluate rule (ignore empty)
    if (control.value && !emailRegex.test(control.value)) {
      let result = {
        email: `'${control.value}' is not a valid email address.`
      }
      return result;
    }

    return null;
  }

  /**
   * Validates that the specified value represents a decimal number.
   * @param value the value to be evaluated.
    * @example myFormGroup = this.fb.group({someNumericControl: ['', ReachControlValidators.validateDecimalOnly]});
   */
  public static validateDecimalOnly(control: AbstractControl) {
    var result = {
      isValid: true,
      errorMessage: ""
    }

    // Evaluate rule (ignore empty)
    if ((control.value != undefined) && (control.value != "") && !(/^\d*\.?\d*$/.test(control.value))) {
      result.isValid = false;
      result.errorMessage = "{0} must be a whole or decimal numeric value.";
    }

    return result;
  }

  /**
 * Validates that the specified value represents a valid email uri.
 * @param value the value to be evaluated.
  * @example myFormGroup = this.fb.group({someEmailAddressControl: ['', ReachControlValidators.validateEmailAddress]});
 */
  public static dateofBirthValidator(control: AbstractControl) {
    let usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

    //if (!control.value.match(usDatePattern))
    //    return { "usDate": true };

    let today = new Date();
    let maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (control.value >= maxDate) {
      let result = {
        dob: `The specified date of birth ('${control.value}') lies outside the valid range. Dates must be in the past.`
      }
      return result;
    }

    return null;
  }

  public static futureDateValidator(friendlyName: string) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control.value > new Date()) return { errorMessage: `${friendlyName} cannot be in the future.` };

      return null;
    }

  }

  public static futureDateOnlyValidator(friendlyName: string) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control.value != null) {
        let x = control.value;
        x.setHours(0, 0, 0, 0);

        let y = new Date();
        y.setHours(0, 0, 0, 0);

        if (x < y) return { errorMessage: `${friendlyName} cannot be in the past.` };
        return null;
      }
    }
  }

  public static pastDateValidator(friendlyName: string) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control?.value
        && control.value instanceof Date
        && control.value.getFullYear() < (new Date()).getFullYear() - 100) return { errorMessage: `${friendlyName} cannot be more than 100 years ago.` };

      return null;
    }
  }

  public static dateRangeValidator(startDate: AbstractControl, endDate: AbstractControl, startDateFriendlyName: string, endDateFriendlyName: string): ValidatorFn {

    return (): { [key: string]: any } | null => {
      return (!startDate.value || !endDate.value) || startDate.value < endDate.value
        ? null
        : { errorMessage: `${endDateFriendlyName} must be after ${startDateFriendlyName}` };
    }
  }

  public static dateRangeGreaterOfEqualToValidator(startDate: AbstractControl, endDate: AbstractControl, startDateFriendlyName: string, endDateFriendlyName: string, numberOfMonths: number): ValidatorFn {

    return (): { [key: string]: any } | null => {
      return (!startDate.value || !endDate.value)
        || this.monthDif(startDate.value, endDate.value) >= numberOfMonths
        ? null
        : { errorMessage: `${startDateFriendlyName} and ${endDateFriendlyName} must span ${numberOfMonths} or more months.` };
    }
  }

  // When the user moves forward, the IsMilitary flag on entity is updated, but only if
  // being cleared, not when being set. To set it, users must contact the board.
  public static activeMilitaryValidator(isCurrentlyActive: boolean) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      // If switching to be active military, the user must contact the board.
      if (!isCurrentlyActive && control.value == "true") {
        return { errorMessage: 'You must contact the board to go from an inactive military status to an active military status' };
      }

      // Otherwise, the control passes validation.
      return null;
    }

  }

  public static uniqueSecurityQuestionValidator(ua: IUserAccountDto) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control?.value?.Description == ua?.SecurityQuestion1) return { errorMessage: 'Security questions must be unique' };
      else return null;
    }

  }

  public static uniqueSecurityQuestionAnswerValidator(ua: IUserAccountDto) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control?.value == ua?.SecurityAnswer1) return { errorMessage: 'Security answers must be unique' };
      else return null;
    }

  }

  public static militaryResponseTypeValidator(entity: IOnlineUserDto, ignoreAutoApply: boolean = false) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      let responseType: IMilitaryResponseTypeDto = control.value;
      if (!responseType || !(responseType.IsMilitary && !entity.IsMilitary)) return null;

      if (ignoreAutoApply || (!ignoreAutoApply && !responseType.AutoApplyEntityMilitary)) return { errorMessage: 'You must contact the board to go from an inactive military status to an active military status' }

      return null;
    }

  }

  // Disallow xBlank responses to DomainQuestionnaireQuestions.
  public static xBlankValidator(xBlank: number) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      // Value cannot be xBlank.
      if (control.value?.Id == xBlank) {
        return { required: true } as any;
      }

      // Otherwise, the control passes validation.
      return null;
    }

  }

  /**
   * Compares the value of the target to own value.
   * @param target
   */
  public static passwordMatchValidator(target: FormControl) {

    return (control: AbstractControl): { [key: string]: any } | null => {

      // The control passes validation.
      if (control.value == target.value) {
        return null;

      }

      // Otherwise...
      return { passwordMatch: true } as any;
    }

  }

  public static monthDif(dateFrom: Date, dateTo: Date): number {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }
}


