import { IUsernamePasswordCredentialDto } from './core-shared-dto';
import { CredentialDto } from './credential-dto';
import { CredentialType } from './credential-type';

export class UsernamePasswordCredentialDto extends CredentialDto implements IUsernamePasswordCredentialDto {
	UserName: string;
	Password: string;


	public constructor(userName: string, password: string) { super(CredentialType.UsernamePassword); this.UserName = userName; this.Password = password; }

}
