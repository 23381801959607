<!-- Grid -->
<div class="p-fluid p-formgrid p-grid" [formGroup]="stepForm">

    <!-- Convictions -->
    <div class="p-field p-col-12" *ngIf="enableHasNoRecentConvictionsRadioButtons">

        <!-- Yes -->
        <div class="p-field-radiobutton">
            <p-radioButton name="HasNoRecentConvictions" value="true" formControlName="HasNoRecentConvictions"
                inputId="Yes">
            </p-radioButton>
            <label for="Yes">{{noConvictionsMessage}}</label>
        </div>

        <!-- No -->
        <div class="p-field-radiobutton">
            <p-radioButton name="HasNoRecentConvictions" value="false" formControlName="HasNoRecentConvictions"
                inputId="No">
            </p-radioButton>
            <label for="No">{{yesConvictionsMessage}}</label>
        </div>
    </div>

    <!-- PrimeNG Table -->
    <p-table styleClass="p-datatable-sm" [value]="professionalProfile?.EntityConvictions | filter: {IsDeleted : false}"
        [responsive]="true" *ngIf="showEntityConvictions">

        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between">
                <span class="p-text-left reach-list-header">Criminal Convictions</span>
                <div>
                    <button pButton pRipple class="p-button-text" icon="fa fa-plus"
                        [command-button]="presentEditorCommand" tool-tip="Add a new item"></button>
                </div>
            </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>Type</th>
                <th>Conviction Date</th>
                <th>Sentence</th>
                <th style="width: 8em;">
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Conviction Type -->
                <td>{{item.ConvictionType == null ? "-" : item.ConvictionType.Description}}</td>

                <!-- Conviction Date -->
                <td>{{item.ConvictionDate | date: 'MM/dd/yyyy' | whenEmpty : "-"}}</td>

                <!-- CourtSentence -->
                <td>{{item.CourtSentence | whenEmpty : "-"}}</td>

                <!-- Buttons -->
                <td class="p-text-right">
                    <span class="p-text-nowrap">
                        <!-- Edit -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-pencil"
                            [command-button]="presentEditorCommand" [command-parameter]="item"
                            tool-tip="Edit this item"
                            *ngIf="isItemEditVisible(item)"></button>

                        <!-- Delete -->
                        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o"
                            [command-button]="removeItemCommand" [command-parameter]="item"
                            tool-tip="Delete this item"
                            *ngIf="isItemDeleteVisible(item)"></button>
                    </span>
                </td>

            </tr>
        </ng-template>

        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="4">
                    <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
                </td>
            </tr>
        </ng-template>

    </p-table>

</div>

<!-- IMPORTANT -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
