import {  } from '@coreShared/dto-gen/core-shared-dto';
import { ILicenseStatusDto } from './licensure-shared-dto';

export class LicenseStatusDto implements ILicenseStatusDto {
	Id: string;
	IsOnlineRenewalAllowed: boolean;
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	IsNew: boolean;
	IsDeleted: boolean;

}
