import { Pipe } from '@angular/core';

const USA: string = "USA";

/**
 * Pipe transforms a Address object into a formatted city-state string.
 */
@Pipe({
  name: 'displayAddress'
})
export class DisplayAddressPipe {
  /**
   * Transforms the Address object passed in as the value param into a formatted city-state string.
   * @param value
   */
  transform(address): string {
    var formattedAddress = "";

    if (!address) {
      return "";
    }

    if (address.Line1) {
      formattedAddress += address.Line1;
    }

    if (address.Line2) {
      if (formattedAddress.length > 0) {
        formattedAddress += "<br/>";
      }

      formattedAddress += address.Line2;
    }

    if (address.Line3) {
      if (formattedAddress.length > 0) {
        formattedAddress += "<br/>";
      }

      formattedAddress += address.Line3;
    }

    if (formattedAddress.length > 0) {
      formattedAddress += "<br/>";
    }

    if (address.City) {
      formattedAddress += address.City;
    }

    if (address.State) {
      formattedAddress += ", " + address.State;
    }

    if (address.Zip) {
      formattedAddress += " " + address.Zip;
    }

    if (address.Country && address.Country != USA)
      formattedAddress += "<br/>" + address.Country;

    return formattedAddress;
  }
}
