
<div *ngIf="allEntries && allEntries.length !== 0" class="p-field">
  <i class="fa fa-question-circle" (click)="toggleHelp()"></i>
  <ul class="dropdown-menu dropdown-menu-right" role="menu" [hidden]="!showHelp" style="list-style: none;">
    <li *ngFor="let entry of allEntries | sort: 'displayOrder'">
      <a *ngIf="isExternalUrl(entry)" href="entry.content" target="_blank">{{entry.description}}</a>
      <a *ngIf="isModal(entry)" [command-button]="entry.command" [command-parameter]="entry.commandParam">{{entry.description}}</a>
    </li>
  </ul>
</div>
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>
