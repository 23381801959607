<!-- Header/Summary text -->
<landing-detail-header [title]="title" [notifications]="notifications"></landing-detail-header>

<!-- Grid -->
<div class="p-grid p-nogutter reach-landing-detail" *ngIf="dataLoaded">
    <div class="p-col-12 p-px-2">
        <page-summary-text [majorKey]="majorKey" [minorKey]="pageSummaryMinorKey"></page-summary-text>
    </div>

    <div class="p-lg-7 p-xl-8 p-grid">
        <!-- Reference # -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Reference #</label>
            <span class="p-col p-mb-0">{{invoiceDto.Id}}</span>
        </div>
        <!-- Description -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Description</label>
            <span class="p-col p-mb-0">{{invoiceDto.Description}}</span>
        </div>
        <!-- Amount -->
        <div class="p-col-12 p-field p-grid p-nogutter">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Amount</label>
            <span class="p-col p-mb-0">{{invoiceDto.Amount | currency}}</span>
        </div>
    </div>
    <p-table styleClass="p-datatable-sm" [value]="invoiceDto.Fees" [responsive]="true">

        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>

                <th width="70%">Invoice Details</th>
                <th width="30%" class="text-right">Amount</th>

            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-item>
            <tr>

                <!-- Information -->
                <td>{{item.SourceCodeItem.Description}}</td>

                <!-- Amount -->
                <td class="text-right">{{item.Amount | currency}}</td>

            </tr>
        </ng-template>

        <!-- Footer -->
        <ng-template pTemplate="footer" let-sale>
            <tr>

                <td><b>Total Amount</b></td>
                <td class="text-right"><b>{{invoiceDto.TotalFees | currency}}</b></td>

            </tr>
        </ng-template>

        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="5">
                    <span class="reach-text-muted"><em>No entries.</em></span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>