import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEntityHospitalPrivilegeDto } from './licensure-shared-dto';

export class EntityHospitalPrivilegeDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityHospitalPrivilegeDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	EntityId?: number;
	Facility: string;
	City: string;
	State: string;
	Privilege: string;

}
