import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { AuthGuard, CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicRoutesService, FunctionTypeService, LandingPageService, OnlineServiceLinkManagerService, OnlineServiceRegistry, Principal, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration, SummaryInfoManagerService, UserManagerService, Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService } from '@core/core.module';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import * as _l from 'lodash-es';
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { SummaryInfoBuilderService } from "src/app/core/services/summary-info-builder.service";
import { IProfileSummaryInfoDto } from "src/app/coreShared/core-shared.module";
import { IDelegationTokenSearchResultItemDto, ILicensureUserProfileInfoDto } from "src/app/licensureShared/licensure-shared.module";
import { IApplyTokenWizard } from "../../features/delegatedAdministration/components/apply-token-wizard/wizard/apply-token-wizard.component";
import { ICreateTokenWizard } from "../../features/delegatedAdministration/components/create-token-wizard/wizard/create-token-wizard.component";
import { LicensureLandingCategories, LicensureLandingComponentKeys } from "../../licensure-core.module";
import { IManagePicAccessWizard } from "../../features/delegatedAdministration/components/manage-pic-access-wizard/wizard/manage-pic-access-wizard.component";

@Injectable({
  providedIn: 'root'
})
export class DelegateAdministrationFeatureConfigurationService {

  private isInitialized = false;
  private delegatedAdministrationFeatureConstantsService: IDelegatedAdministrationFeatureConstantsService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private dynamicRoutesService: DynamicRoutesService,
    private router: Router,
    private wizardService: WizardService,
    private wizardSearchCriteriaService: WizardSearchCriteriaService,
    private wizardTagsService: WizardTagsService,
    private functionTypeService: FunctionTypeService,
    protected userManagerService: UserManagerService,
    protected landingPageService: LandingPageService,
    protected summaryInfoBuilderService: SummaryInfoBuilderService,
    protected summaryInfoManagerService: SummaryInfoManagerService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
  ) {
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.addInfosToLandingPage(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => { this.addInfosToLandingPage(currentPrincipal) });
    this.landingPageService.landingPageReconfig$.subscribe(() => { this.addInfosToLandingPage(this.userManagerService.getCurrentPrincipal()) });
  }

  public initialize() {
    this.delegatedAdministrationFeatureConstantsService = this.constantsService as IDelegatedAdministrationFeatureConstantsService;
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, DelegatedAdministrationFeatureConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, DelegatedAdministrationFeatureConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, DelegatedAdministrationFeatureConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.SYSTEM_SETTING_KEYS, DelegatedAdministrationFeatureConstantsService.Mergers.SYSTEM_SETTING_KEYS);
    ConstantsService.MergeSettings(this.constantsService.FEATURE_CONSTANTS, DelegatedAdministrationFeatureConstantsService.Mergers.FEATURE_CONSTANTS);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, DelegatedAdministrationFeatureConstantsService.Mergers.VALIDATION_MODES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, DelegatedAdministrationFeatureConstantsService.Mergers.DOMAIN_IDS);
    this.delegatedAdministrationFeatureConstantsService = this.constantsService as IDelegatedAdministrationFeatureConstantsService;
  }

  protected addInfosToLandingPage(principal: Principal): void {
    if (!this.isInitialized) return;

    const profile = principal?.user?.UserAccount?.profile as ILicensureUserProfileInfoDto;
    if (profile != null) {
      const profileSummariesInfos = profile?.ProfileSummaries?.filter(x => x.Type === 100);
      if (profileSummariesInfos && profileSummariesInfos.length > 0) {
        const infos: SummaryComponentInfo[] = [];
        const summaryDetailConfiguration = new SummaryDetailConfiguration(
          LicensureLandingCategories.AssociatedLicense,
          LicensureLandingComponentKeys.AssociatedLicenseSummaryLazy,
          LicensureLandingComponentKeys.AssociatedLicenseListLazy,
          SummaryAggregationType.Singleton
        );

        const profileSummaryInfoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "License Coordinator", summaryDetailConfiguration);
        profileSummariesInfos.forEach((profileSummaryInfo: IProfileSummaryInfoDto, index: number) => {
          profileSummaryInfoBuilder.addItem(
            index,
            profileSummaryInfo.Title,
            profileSummaryInfo,
            this.delegatedAdministrationFeatureConstantsService.DOMAIN_IDS.ENTITY
          );
        });

        this.summaryInfoManagerService.mergeInfos(infos);
      }
    }
  }

  public getCreateTokenWizard(): Observable<ICreateTokenWizard> {
    let functionType = this.functionTypeService.create(this.delegatedAdministrationFeatureConstantsService.FUNCTION_TYPES.DELEGATION_TOKEN, null);
    let wizardTags = this.wizardTagsService.create();

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(null
      , this.delegatedAdministrationFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.CREATE_DELEGATION_TOKEN
      , wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(
      switchMap((wizard: Wizard) => {
        return this.wizardService.startWizard(wizard, functionType, null);
      }),

      map((wizard: Wizard) => {
        return wizard as ICreateTokenWizard;
      }),

      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getManagePicAccessWizard(): Observable<IManagePicAccessWizard> {
    let functionType = this.functionTypeService.create(this.delegatedAdministrationFeatureConstantsService.FUNCTION_TYPES.LICENSE, null);
    let wizardTags = this.wizardTagsService.create();

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(null
      , this.delegatedAdministrationFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.MANAGE_PIC_ACCESS
      , wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(
      switchMap((wizard: Wizard) => {
        return this.wizardService.startWizard(wizard, functionType, null);
      }),

      map((wizard: Wizard) => {
        return wizard as ICreateTokenWizard;
      }),

      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getModifyDelegateWizard(): Observable<ICreateTokenWizard> {
    let functionType = this.functionTypeService.create(this.delegatedAdministrationFeatureConstantsService.FUNCTION_TYPES.DELEGATION_TOKEN, null);
    let wizardTags = this.wizardTagsService.create();

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(null
      , this.delegatedAdministrationFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.MODIFY_DELEGATE
      , wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(
      switchMap((wizard: Wizard) => {
        return this.wizardService.startWizard(wizard, functionType, null);
      }),

      map((wizard: Wizard) => {
        return wizard as ICreateTokenWizard;
      }),

      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getApplyTokenWizard(delegationTokenSearchResultItem: IDelegationTokenSearchResultItemDto): Observable<IApplyTokenWizard> {
    const functionType = this.functionTypeService.create(this.delegatedAdministrationFeatureConstantsService.FUNCTION_TYPES.DELEGATION_TOKEN, delegationTokenSearchResultItem?.Id);
    const wizardTags = this.wizardTagsService.create();

    const wizardSearchCriteria = this.wizardSearchCriteriaService.create(null
      , this.delegatedAdministrationFeatureConstantsService.WEB_SERVICE_PROCESS_TYPES.APPLY_DELEGATION_TOKEN
      , wizardTags);

    return this.wizardService.getWizard(wizardSearchCriteria).pipe(
      switchMap((wizard: Wizard) => {
        return this.wizardService.startWizard(wizard, functionType, null);
      }),

      map((wizard: Wizard) => {
        return wizard as IApplyTokenWizard;
      }),

      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: 'delegated-administration',
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/delegatedAdministration/modules/delegated-administration.module').then(m => m.DelegatedAdministrationModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.CreateDelegationToken).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/delegatedAdministration/modules/create-token-wizard.module').then(m => m.CreateTokenWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ModifyDelegate).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/delegatedAdministration/modules/modify-delegate-wizard.module').then(m => m.ModifyDelegateWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ApplyDelegationToken).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/delegatedAdministration/modules/apply-token-wizard.module').then(m => m.ApplyTokenWizardModule)
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.ManagePicAccess).onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/delegatedAdministration/modules/manage-pic-access-wizard.module').then(m => m.ManagePicAccessWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

}
export class DelegatedAdministrationFeatureConstantsService extends ConstantsService {
  public static Mergers = {
    SYSTEM_SETTING_KEYS: {
    },
    FEATURE_CONSTANTS: {
    },
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      DELEGATED_ADMINISTRATION: 'DelegatedAdministration',
      CREATE_DELEGATION_TOKEN_WIZARD: "CreateDelegationToken.Wizard",
      MODIFY_DELEGATE_WIZARD: "ModifyDelegate.Wizard",
      MANAGE_PIC_ACCESS_WIZARD: "ManagePicAccess.Wizard",
      APPLY_DELEGATION_TOKEN_WIZARD: "ApplyDelegationToken.Wizard",
    },
    WEB_SERVICE_PROCESS_TYPES: {
      CREATE_DELEGATION_TOKEN: LicensureOnlineServiceProcessTypeKeys.CreateDelegationToken,
      APPLY_DELEGATION_TOKEN: LicensureOnlineServiceProcessTypeKeys.ApplyDelegationToken,
      MODIFY_DELEGATE: LicensureOnlineServiceProcessTypeKeys.ModifyDelegate,
      MANAGE_PIC_ACCESS: LicensureOnlineServiceProcessTypeKeys.ManagePicAccess,
    },
    FUNCTION_TYPES: {
      DELEGATION_TOKEN: 48,
    },
    VALIDATION_MODES: {
    },
    DOMAIN_IDS: {
      DELEGATION_TOKEN: 71,
    },
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), DelegatedAdministrationFeatureConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), DelegatedAdministrationFeatureConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), DelegatedAdministrationFeatureConstantsService.Mergers.FUNCTION_TYPES);
  SYSTEM_SETTING_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).SYSTEM_SETTING_KEYS), DelegatedAdministrationFeatureConstantsService.Mergers.SYSTEM_SETTING_KEYS);
  FEATURE_CONSTANTS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FEATURE_CONSTANTS), DelegatedAdministrationFeatureConstantsService.Mergers.FEATURE_CONSTANTS);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), DelegatedAdministrationFeatureConstantsService.Mergers.VALIDATION_MODES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), DelegatedAdministrationFeatureConstantsService.Mergers.DOMAIN_IDS);
}
export interface IDelegatedAdministrationFeatureConstantsService extends DelegatedAdministrationFeatureConstantsService { }


