import { IDomainFormAnswerDto,
IDomainFormQuestionAnswerDto,
IDomainFormSectionQuestionDto,
IDomainReasonForChangeDtoHost } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class DomainFormQuestionAnswerDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IDomainFormQuestionAnswerDto {
	FormId: number;
	SectionQuestionId: number;
	AnswerId?: number;
	Question: IDomainFormSectionQuestionDto;
	Answer: IDomainFormAnswerDto;
	AnswerText: string;
	Explanation: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
