import { Injectable, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Subject, Subscription } from "rxjs";
import * as _ from 'underscore';
import { CommandService } from '@coreServices/command.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { LoginComponentKeys } from '@core/index-constants';
import { ReachApplicationDataChangeArgs } from '@coreModels/reach-application-data-change-args';
import { EntityAssociateDto, CredentialType } from '@coreShared/core-shared.module';

/**
 * Service for cross-application shared state. Replaces some Web2 Angular1 application-scope/rootscope state.
 */
@Injectable({ providedIn: 'root' })
export class ReachApplicationService implements OnInit {
  private _applicationInitialized$: Subject<boolean> = new Subject<boolean>();
  private _dataChanged$: Subject<ReachApplicationDataChangeArgs> = new Subject<ReachApplicationDataChangeArgs>();
  private _entityAssociationCreated$: Subject<EntityAssociateDto> = new Subject<EntityAssociateDto>();
  private _entityAssociationUpdate$: Subject<EntityAssociateDto> = new Subject<EntityAssociateDto>();
  private _entityAssociationDelete$: Subject<EntityAssociateDto> = new Subject<EntityAssociateDto>();

  // The event fired when the busy state changes.
  private dataMap: Map<string, any> = new Map<string, any>();
  public dataChanged$ = this._dataChanged$.asObservable();
  public entityAssociationCreated$ = this._entityAssociationCreated$.asObservable();
  public entityAssociationUpdate$ = this._entityAssociationUpdate$.asObservable();
  public entityAssociationDelete$ = this._entityAssociationDelete$.asObservable();
  public applicationInitialized$ = this._applicationInitialized$.asObservable();

  public isApplicationInInitialized = false;
  public showDynamicContentKeyAdornments = false;
  public userDisplayName: string = null;
  private subscriptionLogin: Subscription;
  private subscriptionLogout: Subscription;

  // Verona
  public readonly menuMode: string = 'horizontal';
  public get isMobile(): boolean {
    return window.innerWidth < 1025;
  }

  constructor(
    public commandService: CommandService,
    private userManagerService: UserManagerService,
    private router: Router
  ) {
    this.subscriptionLogin = this.userManagerService.login$
      .subscribe(currentPrincipal => this.onUserLogin(currentPrincipal));
    this.subscriptionLogout = this.userManagerService.logout$
      .subscribe(currentPrincipal => this.onUserLogout());

  }

  ngOnInit() {
    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();

  }

  /*
       * Handles the "userManager.login" event.
       * @param event
       * @param argument
       */
  private onUserLogin(currentPrincipal) {
    this.userDisplayName = this.userManagerService.getCurrentPrincipalDisplayName();
  }

  /**
   * Handles the "userManager.logout" event.
   * @param event
   * @param argument
   */
  private onUserLogout() {
    this.userDisplayName = "";
  };

  get isHorizontal() {
    return this.menuMode === 'horizontal';
  }

  get isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  public menuActive = false;
  public topbarMenuActive = false;
  public menuHoverActive: boolean;
  //activeTopbarItem: Element;
  //menuClick: boolean;
  //menuButtonClick: boolean;
  //topbarMenuButtonClick: boolean;



  /**
   * Returns the application value associated with the specified key.
   * @param key the application value key.
   */
  public get(key: string) {
    return this.dataMap.get(key);
  }

  /**
   * Sets/stores the specified application value and associates it with the specified key.
   * @param key the application value key.
   * @param data the application value.
   */
  public set(key: string, data: any) {
    let args = new ReachApplicationDataChangeArgs("SET");
    this.dataMap.set(key, data);
    args.changeItems.set(key, data);
    this._dataChanged$.next(args);
  }

  /**
   * Deletes the application value associated with the specified key.
   * @param key the application value key.
   */
  public delete(key: string) {
    let args = new ReachApplicationDataChangeArgs("DELETE");
    this.dataMap.delete(key);
    args.changeItems.set(key, null);
    this._dataChanged$.next(args);
  }

  /**
   * Returns true if the application value associated with the specified key exists.
   * @param key the application value key.
   */
  public exists(key: string): boolean {
    return this.dataMap.has(key);
  }

  /**
   * Deletes all stored application values.
   */
  public clear() {
    let args = new ReachApplicationDataChangeArgs("CLEAR_ALL");
    this.dataMap.clear();
    this._dataChanged$.next(args);
  }

  /**
   * Raises the "entityAssociation.create" event.
   */
  public raiseEntityAssociationCreate(args: EntityAssociateDto) {
    this._entityAssociationCreated$.next(args);
  }

  /**
   * Raises the "entityAssociation.create" event.
   */
  public raiseEntityAssociationUpdate(args: EntityAssociateDto) {
    this._entityAssociationUpdate$.next(args);
  }

  /**
   * Raises the "entityAssociation.create" event.
   */
  public raiseEntityAssociationDelete(args: EntityAssociateDto) {
    this._entityAssociationDelete$.next(args);
  }

  /**
 * Fires the "applicationInitialized" event to indicate Application state has been initialized.
 */
  public raiseApplicationInitializedEvent(isInitialized: boolean) {
    this.isApplicationInInitialized = isInitialized;
    this._applicationInitialized$.next(isInitialized);
  }

  // Logout Command
  public logoutCommand = this.commandService.createButtonCommand(
    () => { return this.userManagerService.getCurrentPrincipal() != null; },
    () => {
      // Logout
      this.userManagerService.logout();

      // Navigate to login page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    }
  );

  // Login Command
  public loginCommand = this.commandService.createButtonCommand(
    () => { return !this.userManagerService.getCurrentPrincipal(); },
    () => {
      // Navigate to login page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
    }
  );

  public passwordChangeCommand = this.commandService.createButtonCommand(() => {
    return this.userManagerService.getCurrentPrincipal() != null;
  },
    () => {
      // Navigate to pw page
      this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Password).path]);
    });

  public changeSecurityQuestionsCommand = this.commandService.createButtonCommand(() => {
    return this.userManagerService.getCurrentPrincipal() != null;
  }, () => {
    // Navigate to pw page
    this.router.navigate(['/' + RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.SecurityQuestions).path]);
  });

}
