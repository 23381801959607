import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonCommand, CanExecuteFunction, Command, NavigationMenuCommand } from '../models/command';
import { SystemSettingsManagerService } from './system-settings-manager.service';
import { UserManagerService } from './user-manager.service';

@Injectable({ providedIn: 'root' })
export class CommandService {

  constructor(private router: Router
    , private userManagerService: UserManagerService
    , private systemSettingsManagerService: SystemSettingsManagerService) {
  }

  public create(canExecute: CanExecuteFunction, execute: Function, commandText: string = null) {
    return new Command(this.userManagerService.getCurrentPrincipal(), canExecute, execute, commandText);
  }

  public createButtonCommand(canExecute: CanExecuteFunction, execute: Function, commandText: string = null) {
    return new ButtonCommand(this.userManagerService.getCurrentPrincipal(), canExecute, execute, commandText);
  }

  public createNavigationMenuCommand(text: string
    , route: () => string | string
    , featureEnabledSystemSettingKey: string
    , allowAnonymous: boolean
    , canExecuteFunction: Function
    , executeFunction: Function): NavigationMenuCommand {

    if (!text) {
      throw new Error("You must supply a text value");
    }

    if (!route && !executeFunction) {
      throw new Error("You must supply a route or an executeFunction");
    }

    let featureEnabled = false;
    if (featureEnabledSystemSettingKey) {
      featureEnabled = this.systemSettingsManagerService.asBoolean(featureEnabledSystemSettingKey);
    }

    const canNavigationMenuCommandExecuteFunction = () => {
      let hasSessionToken = this.userManagerService.hasSessionToken();
      if (canExecuteFunction && !canExecuteFunction()) {
        return false;
      }

      if (!featureEnabled) {
        return false;
      }

      if (allowAnonymous) {
        return true;
      }

      if (!allowAnonymous) {
        return hasSessionToken;
      }

      return false;
    }

    let routeUrl = typeof route === "function" ? route() : route;
    let self = this;

    const defaultExecuteFunction = () => {
      self.navigateCommandRoute(routeUrl);
    };

    // Allow consumers to pass a function if the want to manage it all.
    let commandExecuteFunction = executeFunction || defaultExecuteFunction;

    let command = new NavigationMenuCommand(this.userManagerService.getCurrentPrincipal(),
      canNavigationMenuCommandExecuteFunction,
      commandExecuteFunction,
      text,
      null,
      null,
      routeUrl);
    return command;
  }

  public navigateCommandRoute(routeUrl: string) {
    this.router.navigateByUrl(routeUrl);
  }
}
