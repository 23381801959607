/**
 * Selector component for duplicate/replacement license document services (Card and Wall Certificate).
 *
 * Route Parameters
 * ---------------
 * processTypeId - determines which of the two document types is being requested.
 * licenseId - Optional. If provided the selector view is bypassed and user is routed directly to the wizard since that is the purpose of this selector.
 * selectorTitle - The title of the selector
 */
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationConfigurationProviderService, BootstrapperService, BusyManagerService, CONSTANTS_SERVICE_TOKEN, Command, CommandService, ConstantsService, DefaultRouteResolverService, DynamicContentConfigurationProviderService, DynamicContentManagerService, DynamicRoutesService, LandingComponentKeys, LoginComponentKeys, OnlineServiceRegistry, ReachApplicationService, ReachScenarios, RouteConfiguration, RouteConfigurationProviderService, RouteInfoRegistry, UserManagerService, ValidationManagerService, registerDynamicComponent } from '@core/core.module';
import { DuplicateCardFeatureConfigurationService, IDuplicateCardConstantsService, LicensureOnlineServiceProcessTypeKeys } from '@licensureCore/licensure-core.module';
import { ILicenseDto, ILicenseInfoDto } from '@licensureShared/licensure-shared.module';
import { Observable, of } from 'rxjs';
import { debounceTime } from "rxjs/operators";

export const DuplicateCardSelectionComponentSelector: string = 'duplicate-card-wizard-selector';
export const DuplicateCardSelectionComponentKey: string = 'duplicateCardSelection';

@Component({
  selector: DuplicateCardSelectionComponentSelector,
  templateUrl: './duplicate-card-wizard-selector.component.html',
  styleUrls: ['./duplicate-card-wizard-selector.component.scss']
})
export class DuplicateCardWizardSelectorComponent implements OnInit {

  private friendlyNames: any = {};
  private validationScope;
  private routeData = null;

  public possibleLicenses: LicenseDtoWrapper[] = [];
  public cancelCommand: Command = null;
  public startWizardCommand: Command = null;
  public dynamicContentConfiguration = null;
  public contentForm: FormGroup;
  public pageHeader: string;

  constructor(
    private route: ActivatedRoute,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private duplicateCardFeatureConfigurationService: DuplicateCardFeatureConfigurationService,
    private router: Router,
    private userManagerService: UserManagerService,
    private validationManagerService: ValidationManagerService) {
    this.modelToForm();
  }

  ngOnInit(): void {
    this.validationScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;

    this.route.data.subscribe((data: { routeData: any }) => {
      if (data && data.routeData && data.routeData.dynamicContentConfiguration) {
        this.dynamicContentConfiguration = data.routeData.dynamicContentConfiguration;
        this.routeData = data.routeData;
      }
    });

    this.pageHeader = this.routeData.selectorTitle;

    this.initData();
    this.initCommands();
  }

  public licenseDescription(item): string {
    return `${item.LicenseType.Description} (${item.ReferenceValue})`;
  }

  private modelToForm() {

    this.contentForm = new FormGroup({ License: new FormControl(null, [Validators.required]) });

    this.checkValidationErrors();
    this.initializeEventHandlers();
  }

  private initData(): void {
    this.duplicateCardFeatureConfigurationService.getLicensesThatSupportDuplicateCards(null, this.routeData.processTypeId).forEach(element => {
      let item = new LicenseDtoWrapper();
      item.DisplayName = this.licenseDescription(element);
      item.License = element;
      this.possibleLicenses.push(item);
    });

    if (this.possibleLicenses.length == 1) this.contentForm.get('License').setValue(this.possibleLicenses[0]);
  }

  protected initializeEventHandlers(): void {
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => { this.checkValidationErrors(); });
  }

  private checkValidationErrors() {
    this.validationManagerService.addFormErrors(this.contentForm, this.validationScope, this.friendlyNames);
  }

  private initCommands() {
    this.startWizardCommand = this.commandService.create(this.canStartCommandExecute, this.startCommandExecute);
    this.cancelCommand = this.commandService.create(this.canCancelCommandExecute, this.cancelCommandExecute);
  }

  protected canStartCommandExecute = (): boolean => {
    return this.contentForm.get('License').value?.License;
  }

  protected startCommandExecute = () => {
    this.duplicateCardFeatureConfigurationService.presentDuplicateCardWizard(this.contentForm.get('License').value?.License, this.routeData.processTypeId);
  }

  protected canCancelCommandExecute = (): boolean => {
    return true;
  }

  protected cancelCommandExecute = () => {
    this.userManagerService.getCurrentPrincipal() ? this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]) : this.router.navigate(['/', RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Login).path]);
  }
}

registerDynamicComponent(ReachScenarios.DuplicateCardWizard, DuplicateCardSelectionComponentKey, DuplicateCardWizardSelectorComponent, DuplicateCardSelectionComponentSelector);

@Injectable({ providedIn: 'root' })
export class DuplcateCardSelectorWizardRouteResolver extends DefaultRouteResolverService {
  private duplicateCardConstantsService: IDuplicateCardConstantsService;

  constructor(
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    bootstrapperService: BootstrapperService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    dynamicContentManagerService: DynamicContentManagerService,
    private dynamicRoutesService: DynamicRoutesService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    userManagerService: UserManagerService
  ) {
    super(constantsService, userManagerService, busyManagerService, bootstrapperService, dynamicContentManagerService, reachApplicationService, router, routeConfigurationProviderService, dynamicContentConfigurationProviderService, authorizationConfigurationProviderService);
  }

  /**
   * Override in subclass to return the RouteConfiguration data to be used for this resolver.
   */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    this.duplicateCardConstantsService = this.constantsService as IDuplicateCardConstantsService;
    return this.routeConfigurationProviderService.getConfigurationData(
      false,
      this.dynamicContentConfigurationProviderService.getConfigurationData(true, this.duplicateCardConstantsService.DYNAMIC_CONTENT_MAJOR_KEYS.DUPLICATE_CARD_WIZARD),
      this.authorizationConfigurationProviderService.getConfigurationData(true));
  }

  protected contextSpecificResolveRouteData(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> { //: Observable<TClass> | Observable<never>

    if (route.params.licenseId && route.params.processTypeId){
      let onlineServiceTypeId = route.params.processTypeId == (this.constantsService as IDuplicateCardConstantsService).WEB_SERVICE_PROCESS_TYPES.DUPLICATE_WALL_CERTIFICATE
        ? LicensureOnlineServiceProcessTypeKeys.DuplicateWallCertificate
        : LicensureOnlineServiceProcessTypeKeys.DuplicateCard;
      let serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(onlineServiceTypeId);
      this.router.navigate(this.dynamicRoutesService.buildRoute(serviceRegistryItem.onlineServicePathTemplate, route.params.entityId, route.params.licenseId, route.params.processTypeId));
    }

    return this.getWizard(route);
  }

  private getWizard(route: ActivatedRouteSnapshot): Observable<any> {
    let licenseId: number = route.params.licenseId === "null" ? null : parseInt(route.params.licenseId);
    let processTypeId: number = route.params.processTypeId === "null" ? null : parseInt(route.params.processTypeId);
    let selectorTitle: string = route.params.selectorTitle === "null" ? null : route.params.selectorTitle;
    return of({ licenseId: licenseId, processTypeId: processTypeId, selectorTitle: selectorTitle });
  }

}

/**
 * Provides a type-safe means of including a composite description with each LicenseDto.
 */
class LicenseDtoWrapper {
  License: ILicenseInfoDto;
  DisplayName: string;
}
