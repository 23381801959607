import {
  ClassProvider, Compiler, ComponentFactory, Injectable, InjectionToken, Injector,
  NgModuleRef, Type
} from '@angular/core';
import { dynamicProviderModules } from '@boardProviders/dynamic-provider-modules';
import { AppConfigService } from './app-config.service';
import { BootstrapperService } from './bootstrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AppDynamicModuleLoaderService {
  public dynamicEntryComponent: any;
  public boardComponentFactory: ComponentFactory<any>;
  public dynamicModuleRef: NgModuleRef<any>;
  public dynamicModuleProviders: ClassProvider[];
  public dynamicProviderPromises = new Promise((resolve, reject) => {
    resolve(this.dynamicModuleProviders);
  });

  constructor(private parentInjector: Injector
    , private parentCompiler: Compiler
    , private appConfigService: AppConfigService) {
  }

  public load() {
    var moduleKey = this.appConfigService.clientProviderConfiguration.provider;
    if (!moduleKey) {
      throw Error("An invalid provider key was configured.");
    }
    return this.loadModuleByKey(moduleKey);
  }

  private loadModuleByKey(moduleKey: string) {
    if (!this.parentInjector) {
      throw Error("Invalid parent injector.");
    }

    if (!this.parentCompiler) {
      throw Error("Invalid parent compiler.");
    }

    return dynamicProviderModules[moduleKey].loadChildren()
      .then((customProviderModule: Type<unknown>) => {
        var factory = this.parentCompiler.compileModuleAsync(customProviderModule);
        var factoryWithComponents = this.parentCompiler.compileModuleAndAllComponentsSync(customProviderModule);
        this.boardComponentFactory = factoryWithComponents.componentFactories.find(e => e.selector === 'board-main');
        return factory;
      }).then(factory => {
        var modRef = factory.create(this.parentInjector);
        this.dynamicModuleRef = modRef.instance as unknown as NgModuleRef<any>;
        this.dynamicModuleProviders = factory.moduleType.ɵinj.providers;
        factory.moduleType.forRoot().providers.forEach((x: { useClass: any; useExisting: any; provide: any; }) => {
          if (x.useClass) {
            this.parentInjector.get(x.useClass);
          } else if (x.useExisting) {
            let toInject = { providers: [x] };
            Injector.create(toInject);
            if (x.provide instanceof InjectionToken) {
              this.parentInjector.get(x.useExisting);
            }
          }
        });

        factory.moduleType.InjectRoot(this.parentInjector);
        return this.dynamicModuleProviders;
      })
      .then(() => {
        let bootstrapperService = this.parentInjector.get(BootstrapperService);
        return bootstrapperService.bootstrapApplication().toPromise();
      });
  }
}
