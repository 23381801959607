import { Directive, Inject, Injector, OnDestroy, Optional } from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import { OnlineServiceLinkSubscription } from '@coreModels/online-service-link-subscription';
import { SummaryAggregationType, SummaryComponentInfo } from '@coreModels/summary-component-info';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { NotificationCenterManagerService } from '@coreServices/notification-center-manager.service';
import { OnlineServiceLinkManagerService, OnlineServiceLinkModel } from '@coreServices/online-service-link-manager.service';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { SummaryInfoManagerService } from '@coreServices/summary-info-manager.service';
import { UserManagerService } from "@coreServices/user-manager.service";
import { UtilitiesService } from "@coreServices/utilities.service";
import { INotificationDto } from '@coreShared/core-shared.module';
import * as _l from 'lodash-es';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';

@Directive()
export abstract class LandingCardBaseComponent extends ReachModelAwareComponent implements OnDestroy {
  public title: string;
  public subTitle: string;
  public aggregateTitle: string;
  public notifications: INotificationDto[];
  public info: SummaryComponentInfo = null;
  public onlineServiceLinkModel: OnlineServiceLinkModel = null;
  protected notificationMinorKeys: string[] = [];
  protected onlineServiceLinkSubscriptions: OnlineServiceLinkSubscription[] = [];

  /**
   * The scope key that defines the target of notification messages for this instance.
   */
  public notificationScope: string;

  constructor(protected activatedRoute: ActivatedRoute
    , protected busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService
    , protected injector: Injector
    , private notificationCenterManagerService: NotificationCenterManagerService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
    , protected router: Router
    , protected summaryInfoManagerService: SummaryInfoManagerService
    , protected userManagerService: UserManagerService
    , protected utilitiesService: UtilitiesService
    , public domainId: number
  ) {
    super(reachModelConfigurationInjector);
    this.info = this.model as SummaryComponentInfo;
    this.title = this.info.title;
    this.aggregateTitle = this.info.title;
    this.generateNotificationScopeKey();
  }

  ngOnInit(): void {
    this.getOnlineServiceLinksForCard();
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.clearNotifications();
  }

  public addCustomDetailUrlSegments(): string {
    return "";
  }

  public getDetailUrl(): string {
    let detailUrl = `/${this.info.configuration.detailRoute.path}`;
    let queryString = "";
    if (this.info.configuration.aggregationType === SummaryAggregationType.Singleton) {
      detailUrl += `/${this.info.model.Id}`;
    }

    detailUrl += this.addCustomDetailUrlSegments();

    if (this.majorKey && this.minorKey) {
      if (queryString.length > 0) {
        queryString += "&";
      }
      queryString += `majorKey='${this.majorKey}'&minorKey='${this.minorKey}'`;
    }

    if (queryString) {
      detailUrl += "?";
      detailUrl += queryString;
    }
    return detailUrl;
  }

  public onDetail() {
    this.router.navigateByUrl(this.getDetailUrl());
  }

  /**
   * Gets a value indicating whether this dialog instance should display a validation
   * error message-summary child component.
   * @returns true if enabled.
   */
  public get canShowNotificationSummary(): boolean {
    return true;
  }

  /**
   * Gets the domain key id for this instance.
   */
  public get domainKeyId(): number {
    return this.info && this.info.model ? this.info.model.Id : null;
  }

  /**
   * Gets the online service links to which this instance has subscribed.
   */
  protected getOnlineServiceLinksForCard() {
    this.onlineServiceLinkModel = this.onlineServiceLinkManagerService.getOnlineServiceLinksForSubscriptions(this.onlineServiceLinkSubscriptions);
  }

  private getNotifications() {
    const notificationFilter = (notification: INotificationDto): boolean => {
      let result: boolean = false;
      if (this.domainId && notification.DomainId == this.domainId && this.domainKeyId && notification.DomainKeyId == this.domainKeyId) {
        result = true;
      }

      if (notification.MinorKey && this.notificationMinorKeys.includes(notification.MinorKey)) {
        result = true;
      }

      return result;
    };

    let currentPrincipal = this.userManagerService.getCurrentPrincipal();
    if (currentPrincipal &&
      currentPrincipal.user &&
      currentPrincipal.user.UserAccount &&
      currentPrincipal.user.UserAccount.profile &&
      currentPrincipal.user.UserAccount.profile.Notifications) {
      let allNotifications = currentPrincipal.user.UserAccount.profile.Notifications;
      this.notifications = _l.filter(allNotifications, (i) => notificationFilter(i));
      this.notificationCenterManagerService.addNotifications(this.notifications, this.notificationScope);
    }
  }

  /**
 * Clears the validation messages associated with this dialog instance's scope.
 */
  private clearNotifications() {
    if (this.canShowNotificationSummary) {
      this.notificationCenterManagerService.clearNotificationMessages(this.notificationScope);
    }
  }

  /**
   * Generates a unique validation scope key to represent this dialog instance. Validation error messages
   * associated with this dialog instance will be routed with this key.
   */
  private generateNotificationScopeKey(): string {
    let cardKey = this.constantsService.NOTIFICATION_SCOPES.LANDING_CARD.toString();
    let instanceKey = (new Date()).valueOf().toString();
    let key = `${cardKey}_${instanceKey}`;
    this.notificationScope = key;
    return key;
  }
}

