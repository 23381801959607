<li class="nav-item dropdown">
  <a href="" class="nav-link dropdown-toggle" data-toggle="dropdown"><i class="fa fa-link"></i>&nbsp;Online Services</a>
  <ul class="dropdown-menu dropdown-menu-right" role="menu">
    <li [ngClass]="{'dropdown-header' : menuItem.isHeader, 'dropdown-item' : !menuItem.isHeader, 'd-none' : !menuItem.isVisible}" *ngFor="let menuItem of menuItems">
      <span *ngIf="menuItem.isHeader && menuItem.menuGroupName !== 'Public'"><i class="fa fa-pencil-square-o"></i>&nbsp;&nbsp;{{menuItem.menuGroupName}}</span>
      <span *ngIf="menuItem.isHeader && menuItem.menuGroupName === 'Public'"><i class="fa fa-search"></i>&nbsp;&nbsp;{{menuItem.menuGroupName}}</span>
      <a *ngIf="!menuItem.isHeader" [command-button]="menuItem.command">{{menuItem.commandText}}</a>
    </li>
  </ul>
</li>
