<div class="layout-menu-wrapper" [ngClass]="{'layout-menu-wrapper-active': menuActive}" (click)="onMenuClick()">
  <!-- Agency Name -->
  <div class="site-heading d-block d-md-none">
    <small>{{brand}}</small>
  </div>
  <!-- Menu -->
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <li menu-item *ngFor="let item of menuItems; let i = index;" [item]="item" [index]="i" [root]="true" [ngClass]="{'d-none': !item.visible}"></li>
      <li><message-toggle-button [messageScope]="notificationSummaryScope" labelText="Notification"></message-toggle-button></li>
      <li><message-toggle-button [messageScope]="validationSummaryScope" labelText="Validation"></message-toggle-button></li>
    </ul>
  </div>
</div>
