import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot
} from '@angular/router';
import { AuthorizationConfigurationProviderService } from '../authorization-configuration-provider.service';
import { BootstrapperService } from '../bootstrapper.service';
import { DynamicContentConfigurationProviderService } from '../dynamic-content-configuration-provider.service';
import { BusyManagerService } from './../busy-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from './../constants-provider.service';
import { DynamicContentManagerService } from './../dynamic-content-manager.service';
import { ReachApplicationService } from './../reach-application.service';
import { RouteConfigurationProviderService } from './../route-configuration-provider.service';
import { UserManagerService } from './../user-manager.service';
import { RouteResolverService } from './route-resolver.service';

/**
 * Login-specific route resolver class. These services pre-fetch data needed by the target component when the route is resolved during navigation. Generic type TClass is the type of route data returned in the observable from the resolve function.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginRouteResolverService extends RouteResolverService<any> implements Resolve<any> {
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService) {
    super(
      constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
   * Default implementation of the inner resolve. Override this in subclasses to perform route-specific resolution.
   */
  protected innerResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { //: Observable<any> | Observable<never>
    // Logout (clear token, if one exists), since we are navigating to the login page to re-login.
      this.userManagerService.logout();

    return super.innerResolve(route, state);
  }
}
