// Framework
import { ChangeDetectorRef, Component, ElementRef, Inject, AfterContentInit, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";

// LODASH
import * as _l from 'lodash-es';

// Core
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector } from '@coreModels/dialog-settings';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { IReachDialogContentComponent, ReachDialogContentComponent } from '@core/components/reach-dialog/reach-dialog-content.component';
import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

// Shared
import { IEntityDto } from '@coreShared/core-shared.module';
import { SystemSettingsManagerService } from 'src/app/core/index-services';

// Constants
export const EntityEmailEditorComponenttSelector: string = 'entity-email-editor';

@Component({
  selector: EntityEmailEditorComponenttSelector,
  templateUrl: './entity-email-editor.component.html',
  styleUrls: ['./entity-email-editor.component.scss']
})
export class EntityEmailEditorComponent extends ReachDialogContentComponent<IEntityDto> implements OnInit, IReachDialogContentComponent {

  privateEmailVisibile: boolean;
  publicEmailVisibile: boolean;

  // CTOR
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    
    private systemSettingsManagerService: SystemSettingsManagerService) {

    // Base.
    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override async ngOnInit(): Promise<void> {

    // FRIENDLY NAMES
    this.friendlyNames.emailAddress = "Email Address";

    this.privateEmailVisibile = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.CONTACT_INFORMATION_PRIVATE_EMAIL_VISIBLE);
    this.publicEmailVisibile = this.systemSettingsManagerService.asBoolean(this.constantsService.SYSTEM_SETTING_KEYS.CONTACT_INFORMATION_PUBLIC_EMAIL_VISIBLE);

    // Base.
    await super.ngOnInit();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected override modelToForm(): void {

    // Initialize the content form.
    this.contentForm = new FormGroup({});

    if (this.publicEmailVisibile) this.contentForm.addControl('Email', new FormControl(this.outputModel.Email || '', [Validators.email, Validators.maxLength(80)]));
    if (this.privateEmailVisibile) this.contentForm.addControl('PrivateEmail', new FormControl(this.outputModel.PrivateEmail, [Validators.email, Validators.maxLength(80)]));

    super.modelToForm();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected override formToModel(): void {
    if (this.publicEmailVisibile) this.outputModel.Email = this.contentForm.get('Email').value;
    if (this.privateEmailVisibile) this.outputModel.PrivateEmail = this.contentForm.get('PrivateEmail').value;

    super.formToModel();
  }
}

// Register this component.
registerDynamicComponent(ReachScenarios.Default, 'EntityEmailEditorComponent', EntityEmailEditorComponent, EntityEmailEditorComponenttSelector);
