import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule, FEATURE_MANAGER_SERVICE_TOKEN } from '@core/core.module';
import { LicensureSharedModule } from '@licensureShared/licensure-shared.module';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ApplicationOtherMembershipsComponent } from './application/applicationOtherMemberships/application-other-memberships.component';
import { ApplicationOtherMembershipsEditorComponent } from './application/applicationOtherMemberships/editor/application-other-memberships-editor.component';
import { EmploymentListEditorComponent } from './components/employmentList/editor/employment-list-editor.component';
import { EmploymentListComponent } from './components/employmentList/employment-list.component';
import { EntityAssociatedWithEditorComponent } from './components/entityAssociatedWith/editor/entity-associated-with-editor.component';
import { EntityAssociatedWithComponent } from './components/entityAssociatedWith/entity-associated-with.component';
import { EntityAssociatesEditorComponent } from './components/entityAssociates/editor/entity-associates-editor.component';
import { EntityAssociatesComponent } from './components/entityAssociates/entity-associates.component';
import { EmploymentComponent } from './components/steps/employment/employment.component';
import { MilitaryStatusComponent } from './components/steps/militaryStatus/military-status.component';
import { MilitaryStatusNoAutoApplyComponent } from './components/steps/militaryStatusNoAutoApply/military-status-no-auto-apply.component';
import { UpdateEntityAssociatedWithComponent } from './components/updateEntityAssociatedWith/update-entity-associated-with.component';
import { UpdateEntityAssociatesComponent } from './components/updateEntityAssociates/update-entity-associates.component';
import { CeAuditFileUploadComponent } from './features/ceAuditWizard/fileUpload/ce-audit-file-upload.component';
import { LicenseStatusSelectorComponent } from './features/licenseStatusSelector/license-status-selector.component';
import { LicenseeLookupComponent } from './features/licensee-lookup/licensee-lookup.component';
import { PreceptorshipComponent } from './features/preceptorship/preceptorship.component';
import { SchoolLookupComponent } from './features/schoolLookup/school-lookup.component';
import { SuperviseeVerificationListComponent } from './features/supervision/components/superviseeVerificationList/supervisee-verification-list.component';
import { SupervisionDetailedDescriptionComponent } from './features/supervision/components/supervisionDetailedDescription/supervision-detailed-description.component';
import { SupervisionPlanListComponent } from './features/supervision/components/supervisionPlanList/supervision-plan-list.component';
import { SupervisorListComponent } from './features/supervision/components/supervisor-list/supervisor-list.component';
import { SupervisorAttestationSummaryComponent } from './features/supervision/components/supervisorAttestationSummary/supervisor-attestation-summary.component';
import { SupervisorLookupComponent } from './features/supervision/components/supervisorLookup/supervisor-lookup.component';
import { SupervisorSelectorFieldComponent } from './features/supervision/components/supervisorSelectorField/supervisor-selector-field.component';
import { LicenseSelectorFieldComponent } from './licenseSelectorField/license-selector-field.component';
import { ProfileCertificationsEditorComponent } from './profile/profileCertifications/editor/profile-certifications-editor.component';
import { ProfileCertificationsComponent } from './profile/profileCertifications/profile-certifications.component';
import { ProfileCriminalConvictionsEditorComponent } from './profile/profileCriminalConvictions/editor/profile-criminal-convictions-editor.component';
import { ProfileCriminalConvictionsComponent } from './profile/profileCriminalConvictions/profile-criminal-convictions.component';
import { ProfileHospitalPrivilegesEditorComponent } from './profile/profileHospitalPrivileges/editor/profile-hospital-privileges-editor.component';
import { ProfileHospitalPrivilegesComponent } from './profile/profileHospitalPrivileges/profile-hospital-privileges.component';
import { ProfilePostgraduateTrainingEditorComponent } from './profile/profilePostgraduateTraining/editor/profile-postgraduate-training-editor.component';
import { ProfilePostgraduateTrainingComponent } from './profile/profilePostgraduateTraining/profile-postgraduate-training.component';
import { ProfilePracticeAddressesComponent } from './profile/profilePracticeAddresses/profile-practice-addresses.component';
import { ProfileWorkHistoryEditorComponent } from './profile/profileWorkHistory/editor/profile-work-history-editor.component';
import { ProfileWorkHistoryComponent } from './profile/profileWorkHistory/profile-work-history.component';
import { LicensureFeatureManagerService } from './services/index-services';
import { SupervisionSummaryComponent } from './features/supervision/components/supervisionSummary/supervision-summary.component';
import { ApplicationCourseworkComponent } from './application/application-coursework/application-coursework.component';
import { ApplicationOtherLicensesEditorComponent } from './application/applicationOtherLicenses/editor/application-other-licenses-editor.component';
import { FacilityServiceInfoComponent } from './components/facility-service-info/facility-service-info.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { EmployeeListEditorComponent } from './components/employee-list/editor/employee-list-editor.component';
import { LicenseSelectorFieldEditorComponent } from './licenseSelectorField/editor/license-selector-field-editor.component';
import { CsrsExemptionComponent } from './components/csrs-exemption/csrs-exemption.component';
export * from './constants/licensure-landing-component-constants';
export * from './index-constants';
export * from './index-directives';
export * from './models/index-models';
export { LicensureOnlineServiceProcessTypes } from './models/index-models';
export * from './services/configuration/index-config-services';
export * from './services/index-services';


@NgModule({
  declarations: [
    SupervisorLookupComponent,
    SupervisorSelectorFieldComponent,
    SupervisorAttestationSummaryComponent,
    SuperviseeVerificationListComponent,
    LicenseeLookupComponent,
    ProfilePracticeAddressesComponent,
    ProfilePostgraduateTrainingComponent,
    ProfileCertificationsComponent,
    ProfileCriminalConvictionsComponent,
    ProfileCriminalConvictionsEditorComponent,
    ProfilePostgraduateTrainingEditorComponent,
    LicenseSelectorFieldComponent,
    LicenseSelectorFieldEditorComponent,
    LicenseStatusSelectorComponent,
    SchoolLookupComponent,
    SupervisionDetailedDescriptionComponent,
    ApplicationOtherMembershipsComponent,
    ApplicationOtherMembershipsEditorComponent,
    MilitaryStatusComponent,
    MilitaryStatusNoAutoApplyComponent,
    ProfileCertificationsEditorComponent,
    CeAuditFileUploadComponent,
    ProfileHospitalPrivilegesComponent,
    ProfileHospitalPrivilegesEditorComponent,
    ProfileWorkHistoryComponent,
    ProfileWorkHistoryEditorComponent,
    ProfileWorkHistoryComponent,
    SupervisionPlanListComponent,
    SupervisorListComponent,
    EmploymentListComponent,
    EmploymentListEditorComponent,
    EmploymentComponent,
    EntityAssociatesComponent,
    EntityAssociatesEditorComponent,
    EntityAssociatedWithComponent,
    EntityAssociatedWithEditorComponent,
    UpdateEntityAssociatesComponent,
    UpdateEntityAssociatedWithComponent,
    PreceptorshipComponent,
    SupervisionSummaryComponent,
    ApplicationCourseworkComponent,
    ApplicationOtherLicensesEditorComponent,
    FacilityServiceInfoComponent,
    EmployeeListComponent,
    EmployeeListEditorComponent,
    CsrsExemptionComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    LicensureSharedModule
  ],
  providers: [
    { provide: FEATURE_MANAGER_SERVICE_TOKEN, useClass: LicensureFeatureManagerService }
  ],
  exports: [
    CoreModule,
    LicensureSharedModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    RadioButtonModule,
    TableModule,
    SupervisorLookupComponent,
    SupervisionDetailedDescriptionComponent,
    SupervisorSelectorFieldComponent,
    SupervisorAttestationSummaryComponent,
    SuperviseeVerificationListComponent,
    ProfilePracticeAddressesComponent,
    ProfilePostgraduateTrainingComponent,
    ProfileCertificationsComponent,
    ProfileCriminalConvictionsComponent,
    ProfileHospitalPrivilegesComponent,
    ProfileWorkHistoryComponent,
    LicenseStatusSelectorComponent,
    ApplicationOtherMembershipsComponent,
    ApplicationOtherMembershipsEditorComponent,
    MilitaryStatusComponent,
    MilitaryStatusNoAutoApplyComponent,
    CeAuditFileUploadComponent,
    SupervisionPlanListComponent,
    SupervisorListComponent,
    EmploymentListComponent,
    EmploymentListEditorComponent,
    EmploymentComponent,
    LicenseSelectorFieldComponent,
    LicenseSelectorFieldEditorComponent,
    UpdateEntityAssociatesComponent,
    PreceptorshipComponent,
    SupervisionSummaryComponent
  ]
})
export class LicensureCoreModule {
  static InjectRoot(injector: Injector) {
    let fms = injector.get(FEATURE_MANAGER_SERVICE_TOKEN);
  }
}
