import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LandingCategories, LandingComponentKeys } from '../../index-constants';
import { Principal, SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration } from '../../index-models';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DynamicRoutesService } from '../../index-services';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '../constants-provider.service';
import { LandingPageService } from '../landing-page.service';
import { OnlineServiceLinkManagerService } from '../online-service-link-manager.service';
import { OnlineServiceMenuService } from '../online-service-menu.service';
import { SummaryInfoBuilderService } from '../summary-info-builder.service';
import { SummaryInfoManagerService } from '../summary-info-manager.service';
import { UserManagerService } from '../user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CartLandingConfigurationService {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected userManagerService: UserManagerService,
    protected landingPageService: LandingPageService,
    protected summaryInfoBuilderService: SummaryInfoBuilderService,
    protected summaryInfoManagerService: SummaryInfoManagerService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected router: Router,
  ) {

    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserCartRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  /**
   * Prepare for SummaryComponentInfo object creation.
   */
  private isInitialized = false;
  public initialize(): void {

    if (!this.isInitialized) {
      this.isInitialized = true;
    }
  }

  /**
   * Create SummaryComponentInfo. This will be used on the CartLandingCardComponent.
   * @param principal 
   */
  private addInfosToLandingPage(principal: Principal) {

    // Prepare for SummaryComponentInfo object creation.
    if (!this.isInitialized) this.initialize();

    let infos: SummaryComponentInfo[] = [];
    if (
      principal &&
      principal.user.UserAccount) {
      let infoBuilder = this.summaryInfoBuilderService.createBuilder(
        infos,
        0,
        "Cart",
        new SummaryDetailConfiguration(
          LandingCategories.Cart,
          LandingComponentKeys.CartSummary,
          LandingComponentKeys.CartDetail,
          SummaryAggregationType.Singleton));

      infoBuilder.addItem(
        1,
        "Cart",
        principal.user,
        this.constantsService.DOMAIN_IDS.CART,
        this.constantsService.CARD_DISPLAY_ORDER.CART
      );

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserCartRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

}
