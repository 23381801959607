
/**
 * Enumerates the component types for Login pages.
 */
export enum LoginTypes {
  Login,
  Password,
  SecurityQuestions,
  ChangePassword,
  ChangeSecurityQuestions
}

/**
 * Component keys for Core Login components.
 */
export class LoginComponentKeys {
  public static readonly Login: string = LoginTypes[LoginTypes.Login];
  public static readonly Password: string = LoginTypes[LoginTypes.Password];
  public static readonly SecurityQuestions: string = LoginTypes[LoginTypes.SecurityQuestions];
}

