import { IEntityOwnerTypeDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class EntityOwnerTypeDto extends BaseDto implements IEntityOwnerTypeDto {
	Description: string;
	IsInactive: boolean;
	DisplayOrder: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
