// Angular
import { Injectable, Inject } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _ from 'underscore';
import * as _l from 'lodash-es';
import { from, Observable } from "rxjs";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN } from '@coreServices/configuration/default-provider-configuration.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { OnlineServiceRegistry, OnlineServiceRegistryItem } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';
import { FunctionTypeService } from "../function-type.service";
import { WizardTagsService } from "../wizard-tags.service";
import { WizardService } from "../wizard.service";
import { WizardSearchCriteriaService } from "../wizard-search-criteria.service";
import { AuthGuard } from "../../guards/auth.guard";
import { Wizard } from "../../models/wizard";
import { RouteInfoRegistry } from "../../index-models";
import { LandingComponentKeys, ReachScenarios } from "../../index-constants";

@Injectable({
  providedIn: 'root'
})
export class InvoiceFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private invoiceConstantsService: IInvoiceConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private functionTypeService: FunctionTypeService
    , private wizardTagsService: WizardTagsService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Invoice);
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureDecorators();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, InvoiceConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, InvoiceConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    this.invoiceConstantsService = this.constantsService as IInvoiceConstantsService;
  }

  private configureDecorators() {

  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/core/features/invoiceWizard/invoice-wizard.module').then(m => m.InvoiceWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/core/features/invoiceWizard/invoice-wizard.module').then(m => m.InvoiceWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/core/features/invoiceWizard/invoice-wizard.module').then(m => m.InvoiceWizardModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.InvoiceDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LandingComponentKeys.InvoiceDetailLazy),
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  getWizard(invoiceId: number, webServiceHistoryId: number): Observable<Wizard> {

    let functionType = this.functionTypeService.create(this.invoiceConstantsService.FUNCTION_TYPES.INVOICE, invoiceId);
    let wizardTags = this.wizardTagsService.create(null, null, null, null, null, null, null);

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId
      , this.invoiceConstantsService.WEB_SERVICE_PROCESS_TYPES.INVOICE
      , wizardTags);

    const initializeWizard = async (): Promise<any> => { 
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      } else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };
}

export class InvoiceConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      INVOICE_WIZARD_FEATURE: {
        INVOICE_WIZARD: "Invoice.Wizard",
      }
    },
    WEB_SERVICE_PROCESS_TYPES: {
      INVOICE: OnlineServiceProcessTypeKeys.Invoice,
    }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), InvoiceConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), InvoiceConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
}

export interface IInvoiceConstantsService extends InvoiceConstantsService { }
