import { Pipe } from '@angular/core';
import * as _ from 'lodash-es';

@Pipe({
  name: 'filter'
})
export class FilterListPipe {
  transform(collection: Object[], filterCriteria: Object): Object[] {

    if (!collection || !filterCriteria) {
      return collection;
    }

    let results = collection;

    // If we're given a function, use it!
    if (typeof filterCriteria === 'function') {
      results = _.filter(results, (e) =>{
        return filterCriteria(e);
      });
    }
    else {

      results = _.filter(results,
        (e) => {
          let isMatch = true;
          for (let [key, argValue] of Object.entries(filterCriteria)) {
            if (e[key] !== argValue) {
              isMatch = false;
            }
          }
          return isMatch;
        });

    }

    return results;

  }

}
