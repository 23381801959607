<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <div class="p-grid p-formgrid">

        <!-- Privilege -->
        <div class="p-field p-sm-12 p-lg-6">
            <label for="Privilege">Privilege</label>
            <input id="Privilege" type="text" formControlName="Privilege" pInputText>
        </div>

        <!-- Facility -->
        <div class="p-field p-sm-12 p-lg-6">
            <label for="Facility">Facility</label>
            <input id="Facility" type="text" formControlName="Facility" pInputText>
        </div>

        <!-- City -->
        <div class="p-field p-sm-6 p-lg-4">
            <label for="City">City</label>
            <input id="City" type="text" formControlName="City" pInputText>
        </div>

        <!-- State -->
        <div class="p-field p-sm-6 p-lg-2">
            <label for="state">State</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="possibleStates"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

    </div>

</div>