import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ReachScenarios } from 'src/app/core/index-constants';
import { registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from 'src/app/core/index-services';
import { ILicenseInfoDto, ILicenseStatusTransitionDto } from 'src/app/licensureShared/licensure-shared.module';
import { LicensureConstantsProviderService } from '../../licensure-core.module';

@Component({
  selector: 'license-status-selector',
  templateUrl: './license-status-selector.component.html',
  styleUrls: ['./license-status-selector.component.scss']
})
export class LicenseStatusSelectorComponent implements OnInit, OnChanges {

  // FIELDS
  contentForm: FormGroup;
  selectedLicenseStatusChangeMechanismId: number;

  // CONSTANTS
  readonly RENEWAL: number;
  readonly RENEWAL_STATUS_CHANGE: number;
  readonly STATUS_CHANGE_MECHANISM: number;
  readonly REACTIVATION_MECHANISM: number;

  // INPUTS
  @Input()
  licenseInfo: ILicenseInfoDto;
  @Input()
  possibleTransitions: ILicenseStatusTransitionDto[];
  @Input()
  selectedTransition: ILicenseStatusTransitionDto;
  @Output()
  selectedTransitionChange = new EventEmitter<ILicenseStatusTransitionDto>();

  // PROPERTIES
  public get enableRenewalMechanism(): boolean {
    return this.mechanismExists(this.RENEWAL);
  }
  public get enableRenewalStatusChangeMechanism(): boolean {
    return this.mechanismExists(this.RENEWAL_STATUS_CHANGE);
  }
  public get enableStatusChangeMechanism(): boolean {
    return this.mechanismExists(this.STATUS_CHANGE_MECHANISM);
  }
  public get enableReactivationMechanism(): boolean {
    return this.mechanismExists(this.REACTIVATION_MECHANISM);
  }
  public get additionalStatusChangeOptions(): ILicenseStatusTransitionDto[] {
    return this.possibleTransitions.filter(item => this.isAdditionalStatusChangeOption(item) == true);
  }
  public get statusChangeMechanismOptions(): ILicenseStatusTransitionDto[] {
    return this.possibleTransitions.filter(item => this.isStatusChangeMechanism(item) == true);
  }
  public get reactivationMechanismOptions(): ILicenseStatusTransitionDto[] {
    return this.possibleTransitions.filter(item => this.isReactivationMechanism(item) == true);
  }

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService) {

    // Constants initialization.
    this.RENEWAL = (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.RENEWAL;
    this.RENEWAL_STATUS_CHANGE = (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.RENEWAL_AND_STATUS_CHANGE;
    this.STATUS_CHANGE_MECHANISM = (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.STATUS_CHANGE_APPLICATION;
    this.REACTIVATION_MECHANISM = (this.constantsService as LicensureConstantsProviderService).LICENSE_STATUS_CHANGE_MECHANISMS.REACTIVATION;

    // Initialize form fields.
    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  */
  ngOnChanges(): void {
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {

    this.contentForm = new FormGroup({});

    this.contentForm.addControl('RenewalMechanism', new FormControl());
    this.contentForm.addControl('SelectedTransition', new FormControl());

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel = () => {

    let selectedMechanism = this.contentForm.get('RenewalMechanism').value;

    if (selectedMechanism == this.RENEWAL) this.selectedTransition = this.getTransitionByMechanismId(selectedMechanism);
    else this.selectedTransition = this.getTransitionById(this.contentForm.get('SelectedTransition').value?.Id);

    this.selectedTransitionChange.emit(this.selectedTransition);
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // When anything in this contentForm changes, update the model.    
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });

    this.contentForm.get('RenewalMechanism').valueChanges.subscribe(x => {

      if (x != this.RENEWAL_STATUS_CHANGE) {
        this.contentForm.get('SelectedTransition').setValue(null);
        this.contentForm.get('SelectedTransition').disable();
      }
      else {
        this.contentForm.get('SelectedTransition').enable();
        if (this.additionalStatusChangeOptions.length == 1) this.contentForm.get('SelectedTransition').setValue(this.additionalStatusChangeOptions[0]);
      }

    });

    // Immediately exercise event handlers and assign default value of Mechanism to RENEWAL (standard).
    this.contentForm.get('RenewalMechanism').setValue(this.RENEWAL);
    this.contentForm.get('SelectedTransition').setValue(null);
  }


  /**
   * Get an ILicenseStatusTransitionDto with a matching mechanismId
   * @param mechanismId 
   * @returns 
   */
  public getTransitionByMechanismId(mechanismId: number): ILicenseStatusTransitionDto {
    return this.possibleTransitions.find(item => item.LicenseStatusChangeMechanismId == mechanismId);
  }

  /**
   * Get an ILicenseStatusTransitionDto with a matching transitionId
   * @param transitionId 
   * @returns 
   */
  public getTransitionById(transitionId: number): ILicenseStatusTransitionDto {
    return this.possibleTransitions.find(item => item.Id == transitionId);
  }

  /**
   * Determine if a mechanism exists for the given ID.
   * @returns 
   */
  public mechanismExists(id: number): boolean {
    let x = this.possibleTransitions.find(item => item.LicenseStatusChangeMechanismId == id);
    if (x) return true;
    return false;
  }

  /**
   * Determine if the additional status transition should be visible.
   * @param val 
   * @returns 
   */
  public isAdditionalStatusChangeOption(val: any): boolean {
    return val.LicenseStatusChangeMechanismId as number == this.RENEWAL_STATUS_CHANGE;
  }

  /**
   * Determine if the additional status transition should be visible.
   * @param val 
   * @returns 
   */
  public isStatusChangeMechanism(val: any): boolean {
    return val.LicenseStatusChangeMechanismId as number == this.STATUS_CHANGE_MECHANISM;
  }

  /**
   * Determine if the additional status transition should be visible.
   * @param val 
   * @returns 
   */
  public isReactivationMechanism(val: any): boolean {
    return val.LicenseStatusChangeMechanismId as number == this.REACTIVATION_MECHANISM;
  }

}

// Register this component for dynamic loading by key match. 
registerDynamicComponent(ReachScenarios.Default, 'LicenseStatusSelectorComponent', LicenseStatusSelectorComponent, 'license-status-selector');