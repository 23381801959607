import { Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { noop, Observable, of } from 'rxjs';

import * as _l from 'lodash-es';
import * as _ from 'underscore';

import { Command } from '@coreModels/command';
import { CommandKeyBinding, CommandKeyBindingManagerService } from '@coreServices/command-key-binding-manager.service';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { SimpleChanges } from "node_modules/@angular/core/core";
import { BusyManagerService } from '../services/busy-manager.service';
import { CommandService } from '../services/command.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '../services/constants-provider.service';
import { tap } from 'rxjs/operators';
import { toUpper } from 'lodash';

@Directive({
  selector: '[command-button]'
})
export class CommandButtonDirective implements OnChanges, OnDestroy {
  @Input('command-button')
  command: Command;
  @Input('command-parameter')
  commandParameter: any;
  @Input('busy-type')
  busyType: number;
  @Input('command-key-binding')
  commandKeyBinding: any;
  @Input('command-blocks-invalid')
  commandBlocksInvalid: boolean;
  @Input('command-description')
  commandDescription: string;
  @Input('validation-scope')
  public validationScope = null;

  private buttonCommand: Command = null;
  private isExecuting: boolean = false;
  public commandConfiguration: CommandConfiguration = null;


  constructor(private el: ElementRef
    , private busyManagerService: BusyManagerService
    , private commandKeyBindingManagerService: CommandKeyBindingManagerService
    , private commandService: CommandService
    , @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private reachMessageService: ReachMessageService) {

  }

  @HostBinding('disabled')
  get disabled(): boolean {
    return this.isExecuting || !this.buttonCommand || !this.buttonCommand.enabled;
  };

  @HostListener('click')
  onClick() {
    if (this.buttonCommand && this.commandConfiguration && this.commandConfiguration.command.execute !== noop) {
      this.buttonCommand.execute();
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.commandConfiguration = new CommandConfiguration(this.command
      , this.commandParameter
      , this.busyType
      , this.commandKeyBinding
      , this.commandBlocksInvalid
      , this.validationScope ? this.validationScope : this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION
      , this.el.nativeElement.attributes
      , this.el
    );

    this.wrapCommand();
  }

  ngOnDestroy(): void {
    if (this.buttonCommand) {
      this.commandKeyBindingManagerService.remove(this.buttonCommand);
    }
  }

  public wrapCommand() {
    if (!this.commandConfiguration.command) {
      return;
    }

    // step 1: Create a new command based on the supplied command
    this.buttonCommand = this.commandService.createButtonCommand(() => {
      return this.commandConfiguration.command.canExecute(this.commandConfiguration.commandParameter);
    }, () => {
      if (this.commandConfiguration.command.canExecute(this.commandConfiguration.commandParameter)) {
        const executeCommand = (): Observable<any> => {

          if (this.commandConfiguration.commandBlocksInvalid) {
            var clientErrorsExist = _.any(_l.flatten(_.pluck(this.reachMessageService.get(this.commandConfiguration.validationScope), "data")), (data: any) => {
              return data && data.errorType === this.constantsService.VALIDATION_ERROR_TYPES.CLIENT;
            });

            if (clientErrorsExist) {
              this.reachMessageService.setToggleState(this.commandConfiguration.validationScope, true);
              return of(false);
            }
          }

          this.isExecuting = true;
          let exOp = this.commandConfiguration.command.execute(this.commandConfiguration.commandParameter);
          if (!exOp) {
            exOp = of(null);
          }

          return exOp;
        }

        this.busyManagerService.resolve(executeCommand(), this.commandConfiguration.busyType ? this.commandConfiguration.busyType : this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND, () => this.isExecuting = false);
      }
    }, this.commandConfiguration.command.commandText);

    // step 2: Look to see if there is a command key binding
    if (this.commandConfiguration.commandKeyBinding) {

      // Add key binding to CommandKeyBindingManager
      this.commandKeyBindingManagerService.add(new CommandKeyBinding(this.commandConfiguration.commandKeyBinding, this.buttonCommand));
    }

    this.buttonCommand.description = this.commandConfiguration.command.description;
  }
}

export class CommandConfiguration {
  constructor(public command: Command,
    public commandParameter: any,
    public busyType: number,
    public commandKeyBinding: string,
    public commandBlocksInvalid: boolean,
    public validationScope: string,
    public elementAttributes: any,
    public element: ElementRef) {
  }
}
