import { forwardRef, Inject, Input, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators, ValidationErrors } from "@angular/forms";

import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { SystemSettingsManagerService } from '@coreServices/system-settings-manager.service';

@Component({
  selector: 'reach-captcha',
  templateUrl: './reach-captcha.component.html',
  styleUrls: ['./reach-captcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReachCaptchaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReachCaptchaComponent),
      multi: true
    }
  ]
})
export class ReachCaptchaComponent implements OnChanges, OnInit, ControlValueAccessor {

  // FIELDS
  friendlyNames: string = 'Captcha';
  captchaPublicKey: string;
  contentForm: FormGroup;

  @ViewChild('reCaptcha') reCaptcha;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private systemSettingsManagerService: SystemSettingsManagerService
  ) {

    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
    this.captchaPublicKey = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.CAPTCHA_KEY);
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {

    this.contentForm = new FormGroup({
      Captcha: new FormControl('', [Validators.required, Validators.minLength(1)])
    });

  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  */
  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.isActive.currentValue) {
      this.resetCaptchaWidget();
    }
  }

  /**
   * Handler for Captcha response event. User has clicked the Captcha control and solved
   * the puzzle.
   * @param event the object containing the ReCaptcha response (event.response: string).
   */
  showResponse(event) {
    let capchaControl = this.contentForm.get('Captcha');
    capchaControl.setValue(event.response); // Assign the ReCaptcha response string to the form control.
  }

  /**
   * Handler for Captcha expiration event. Resets the FormGroup Captcha control
   * to null to invalidate the form and force the user to solve another ReCaptcha test.
   */
  public resetCaptchaResponse() {
    let capchaControl = this.contentForm.get('Captcha');
    capchaControl.setValue(null);
  }

  /**
   * Resets the Capcha widget.
   */
  public resetCaptchaWidget() {
    this.reCaptcha.reset();
  }

  // NG_VALIDATORS interface implementation.
  validate(c: AbstractControl): ValidationErrors | null {
    return this.contentForm.valid ? null : { required: false };
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
    value && this.contentForm.setValue(value, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }

}
