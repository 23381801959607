<div class="reach-page-header">Hmm...Something Seems to have Gone Wrong :(</div>
<!-- Gears -->
<div class="watermark-container-gears">
  <div class="watermark-gears">
    <span class="fa fa-gears"></span>
  </div>
</div>

<!-- Wrench -->
<div class="watermark-container-wrench">
  <div class="watermark-wrench">
    <span class="fa fa-wrench"></span>
  </div>
</div>

<!-- Grid -->
<div class="p-grid p-formgrid p-fluid">

  <div class="p-col-12">
    <p>An unexpected system error has occurred attempting to process your request. Sorry for the inconvenience.</p>
  </div>
  <div class="p-col-12">
    <p>
      Please try again. If you continue to experience problems <b>please capture this screen</b> and contact us at one
      of the following for assistance:
    </p>
    <ul>
      <li>
        <a href="mailto:{{agencyEmail}}" title="Board Email">{{agencyEmail}}</a>
      </li>
      <li>
        {{agencyPhone}}
      </li>
    </ul>
  </div>
  <div class="p-col-12">
    <p>
      Additional Support Information (please provide these details when contacting support):
    </p>
    <ul>
      <li *ngIf="referenceNumber">
        Reference: {{referenceNumber}}
      </li>
      <li *ngIf="dateTime">
        Date/Time: {{dateTime}}
      </li>
      <li *ngIf="client">
        Client: {{client}}
      </li>
      <li *ngIf="error">
        Error: {{error}}
      </li>
    </ul>
  </div>
  <div class="p-col-12">
    <p>
      <a [command-button]="homeCommand" command-description="NavbarHome" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND">
        <i class="fa fa-home"></i>
        <span class="p-pl-2">Return to Home</span>
      </a>
    </p>
  </div>
</div>
