<!-- Form -->
<div class="p-grid p-formgrid p-fluid" *ngIf="!cartManagerService.cartEmpty && dataLoaded" [formGroup]="cartForm">

  <!-- Header -->
  <div class="p-col-12">
    <div class="reach-page-header">
      <span class="fa fa-shopping-cart p-pr-1"></span>
      <span>Your Cart</span>
    </div>
  </div>

  <!-- Summary (dynamic content) -->
  <page-summary-text *ngIf="!cartManagerService.cartEmpty && !cartManagerService.cartHasZeroBalance" [majorKey]="'Cart'"
    [minorKey]="'Cart.SummaryTextBlock'" class="p-col-12 p-px-2"></page-summary-text>
  <page-summary-text *ngIf="!cartManagerService.cartEmpty && cartManagerService.cartHasZeroBalance" [majorKey]="'Cart'"
    [minorKey]="'Cart.SummaryTextBlock.ZeroBalance'" class="p-col-12 p-px-2"></page-summary-text>

  <!-- Cart Submit Messages -->
  <page-summary-text *ngIf="shouldDisplayCanCartSubmitSummary()" [majorKey]="canSubmitCartResponse.MajorKey"
    [minorKey]="canSubmitCartResponse.MinorKey" class="p-col-12 p-px-2"></page-summary-text>

  <div class="p-col-12" *ngIf="showMessageWithCheckboxAcknowledgement()">
    <checkbox-content-items [majorKey]="canSubmitCartResponse.MajorKey"
      [minorKey]="canSubmitCartResponse.MinorKey"></checkbox-content-items>
  </div>

  <!-- Page Content -->
  <div class="p-col-12">

    <!-- Table Caption -->
    <p-table responsiveLayout="scroll" styleClass="p-datatable-sm"
      [value]="cartManagerService.cart?.Items | filter: {IsDeleted:false}">
      <ng-template pTemplate="caption">
        <div class="reach-list-header">
          Items Ready for Payment
        </div>
      </ng-template>

      <!-- Column Headings -->
      <ng-template pTemplate="header">
        <tr>
          <th width="70%">Description</th>
          <th width="30%" class="p-text-right">Amount</th>
          <th width="0" style="width: 4em;">
            <!--Edit/Delete-->
          </th>
        </tr>
      </ng-template>

      <!-- Items -->
      <ng-template pTemplate="body" let-item>
        <tr>
          <!-- Item -->
          <td>{{item.ServiceHistoryItem.Description || item.ServiceHistoryItem.Process.Description}}</td>
          <!-- Amount -->
          <td class="p-text-right">{{item.Total | currency}}</td>
          <!-- Buttons -->
          <td class="p-text-right">
            <span class="p-text-nowrap">
              <!-- Delete -->
              <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeItemCommand"
                [command-parameter]="item" command-description="CartRemoveItem" tool-tip="Delete this item"></button>
            </span>
          </td>
        </tr>
      </ng-template>

      <!-- Footer -->
      <ng-template pTemplate="footer" let-sale>
        <!-- Processing Fees -->
        <tr *ngIf="cartManagerService.cart && cartManagerService.cart.CartFeesTotal > 0">
          <td>Processing Fee (Credit Card)</td>
          <td class="p-text-right">{{cartManagerService.cart.CartFeesTotal | currency}}</td>
          <td>
            <!--n/a-->
          </td>
        </tr>
        <tr *ngIf="cartManagerService.cart">
          <td>Grand Total</td>
          <td class="p-text-right">{{cartManagerService.cart.Total | currency}}</td>
          <td>
            <!--n/a-->
          </td>
        </tr>
      </ng-template>

      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="5">
            <span class="reach-text-muted"><em>Your cart is empty.</em></span>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>

  <!-- Additional Help (dynamic content) -->
  <page-summary-text *ngIf="!cartManagerService.cartEmpty && !cartManagerService.cartHasZeroBalance" [majorKey]="'Cart'"
    [minorKey]="'Cart.AdditionalHelpText'" class="p-col-12 p-px-2 p-mt-3"></page-summary-text>
  <page-summary-text *ngIf="!cartManagerService.cartEmpty && cartManagerService.cartHasZeroBalance" [majorKey]="'Cart'"
    [minorKey]="'Cart.AdditionalHelpText.ZeroBalance'" class="p-col-12 p-px-2 p-mt-3"></page-summary-text>

  <!-- Buttons -->
  <div class="p-col-12">
    <div class="reach-bottom-actions-container">
      <div class="p-d-flex p-jc-end">

        <!-- Proceed -->
        <div *ngIf="showProceedToPaymentButton()" class="p-mr-2">
          <button pButton pRipple type="button" class="p-button-secondary" [command-button]="proceedToPaymentCommand"
            command-description="ProceedToPayment">{{getProceedToPaymentLabel()}}</button>
        </div>

      </div>
    </div>
  </div>

</div>

<!-- CONFIRMATION DIALOG (global on reach-app.component) -->
