import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';

import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';

import { IChallengeInfoDto } from '@coreShared/core-shared.module';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends ReachHttpClientService {

  constructor(
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService:
    DefaultProviderConfigurationService,
    http: HttpClient,
    validationManagerService: ValidationManagerService
  ) {
    super(
      busyManagerService,
      constantsService,
      defaultProviderConfigurationService,
      http,
      validationManagerService
    );
  }

  sendPasswordVerificationEmail(c) {
      return this.post<IChallengeInfoDto>(`${this.apiRootUri}/email/PasswordVerificationToken`, c);
  }
}
