import { IEntityCommentDto,
IOnlineUserDto } from '@coreShared/dto-gen/core-shared-dto';
import { BaseDto } from '@coreShared/dto-gen/base-dto';
import { ICeSponsorInfoDto,
ICeSponsorshipInfoDto } from './licensure-shared-dto';

export class CeSponsorInfoDto extends BaseDto implements ICeSponsorInfoDto {
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;
	CommentSourceTypeId?: number;
	IncludeAllEntityComments: boolean;
	Comments: IEntityCommentDto[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	EntityId?: number;
	ReferenceValue: string;
	ContactFirstName: string;
	ContactLastName: string;
	ContactPhone: string;
	ContactEmail: string;
	ContactFullName: string;
	Sponsorships: ICeSponsorshipInfoDto[];
	CourseTemplates: any[];
	entity: IOnlineUserDto;


}
