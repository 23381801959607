import { Inject, Component, ElementRef, OnInit } from '@angular/core';
import { of, from, Observable } from 'rxjs';
import { ApplicationOtherMembershipsEditorComponent, LicensureConstantsProviderService } from '../../licensure-core.module';
import { ApplicationWizardModel } from '../models/application-wizard-models';
import { ConfirmationService } from 'primeng/api';
import * as _l from 'lodash-es';
import { ISpecialtyTrainingCheckListItemDto } from 'src/app/licensureShared/licensure-shared.module';

// Core
import {
  CheckboxContentService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  ValidationManagerService,
  WizardInjector,
  WIZARD_INJECTOR_TOKEN,
  WizardStepComponent,
  ReachScenarios,
  registerDynamicComponent,
  Command,
  CommandService,
  DialogSettings,
  DomainChecklistItemStatusTypeService,
  UtilitiesService,
  DomainChecklistItemService
} from '@core/core.module';
import { map } from 'rxjs/operators';
import { ApplicationOtherMembershipsEditorComponentComponentKey } from './editor/application-other-memberships-editor.component';

// Constants
export const ApplicationOtherMembershipsComponentSelector: string = 'application-other-memberships';
export const ApplicationOtherMembershipsComponentTypeKey: string = 'applicationOtherMemberships';

@Component({
  selector: ApplicationOtherMembershipsComponentSelector,
  templateUrl: './application-other-memberships.component.html',
  styleUrls: ['./application-other-memberships.component.scss']
})
export class ApplicationOtherMembershipsComponent extends WizardStepComponent implements OnInit {

  // COMMANDS
  removeItemCommand: Command;
  presentItemEditorCommand: Command;
  okCommand: Command;

  // FIELDS
  public dialogSettings: DialogSettings;
  protected templateId: number;

  // PROPERTIES
  public get listItems(): ISpecialtyTrainingCheckListItemDto[] { return this.wizard.model?.application?.DomainChecklist?.filter(item => item.ChecklistItemTemplateId === this.templateId && !this.domainChecklistItemStatusTypeService.hasAspectStatus(item, this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.NOT_APPLICABLE)) as ISpecialtyTrainingCheckListItemDto[]; }

  // CTOR.
  constructor(

    // Super
    checkboxContentService: CheckboxContentService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector,

    // Custom
    private commandService: CommandService,
    private domainChecklistItemStatusTypeService: DomainChecklistItemStatusTypeService,
    private domainChecklistItemService: DomainChecklistItemService,
    private utilitiesService: UtilitiesService,
    private confirmationService: ConfirmationService,) {

    // Mandatory super call.
    super(constantsService, validationManagerService, wizardInjector, ApplicationOtherMembershipsComponent.name, checkboxContentService, elementRef);

    // Initialize form fields.
    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  public override ngOnInit(): void {

    // Initializations.
    this.templateId = (this.constantsService as LicensureConstantsProviderService).CHECKLIST_TEMPLATE_ITEM_IDS.SPECIALIZED_TRAINING_CHECKLIST_TEMPLATE_ITEM_ID;

    // Allow wizard-step-component to perform base tasks.
    super.ngOnInit();

    // Initialize commands.
    this.initCommands();
  }

  /**
  * Initializes commands for this instance.
  */
  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  /**
  * Manages the usage of the DomainChecklistItemService in initializing a new checklistitem.
  */
  private createChecklistItem(): Observable<ISpecialtyTrainingCheckListItemDto> {
    return this.domainChecklistItemService.initializeItem((this.wizard.model as ApplicationWizardModel).application, this.templateId)
      .pipe(map(res => {
        (res.DomainChecklistItem as any).LocalId = this.utilitiesService.guid();
        return res.DomainChecklistItem as ISpecialtyTrainingCheckListItemDto;
      }));
  }

  // ==================================== COMMANDS ====================================

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (output: ISpecialtyTrainingCheckListItemDto) => {
    this.utilitiesService.addOrReplace(this.wizard.model.application.DomainChecklist, output);
  }

  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  protected presentEditorCommandExecute = (item: ISpecialtyTrainingCheckListItemDto) => {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doPresent = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        ApplicationOtherMembershipsEditorComponent, // Content type
        ApplicationOtherMembershipsEditorComponentComponentKey, // Content key
        item ? "Other Membership" : "Other Membership - {new}", // Title
        item ?? await this.createChecklistItem().toPromise(), // Model
      );
      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      // Return empty promise (noop)
      return of(true).toPromise();
    }

    return from(doPresent());

  }

  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  protected removeItemCommandExecute = (cli: ISpecialtyTrainingCheckListItemDto) => {

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `${cli.ApplicationTraining.Facility} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      // If accepted...
      accept: () => {

        // Find the index of the item to be removed.
        var idx = _l.findIndex(this.wizard.model.application.DomainChecklist, function (item) {
          return cli.LocalId
            ? item.LocalId === cli.LocalId
            : item.Id === cli.Id;
        });

        (cli.LocalId)
          ? this.wizard.model.application.DomainChecklist.splice(idx, 1)
          : this.domainChecklistItemStatusTypeService.setStatusForAspectStatus(cli, this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.NOT_APPLICABLE);
      }

    });

  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.ApplicationWizard, ApplicationOtherMembershipsComponentTypeKey, ApplicationOtherMembershipsComponent, ApplicationOtherMembershipsComponentSelector);