import { IBaseDto,
IDomainChecklistDtoHost,
IDomainChecklistItemDto,
IInitializeChecklistItemResponseDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class InitializeChecklistItemResponseDto extends BaseDto implements IBaseDto, IInitializeChecklistItemResponseDto {
	DomainChecklistItem: IDomainChecklistItemDto;
	DomainChecklistDtoHost: IDomainChecklistDtoHost;

}
