import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ILicenseVerificationDto } from './licensure-shared-dto';

export class LicenseVerificationDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ILicenseVerificationDto {
	LicenseeEntityId?: number;
	RecipientEntityId?: number;
	ProcessId?: number;
	LicenseType: string;
	RecipientName: string;
	RecipientAddressLine1: string;
	RecipientAddressLine2: string;
	RecipientAddressLine3: string;
	RecipientCity: string;
	RecipientState: string;
	RecipientZip: string;
	RecipientCountry: string;
	AddressFull: string;
	RecipientEmail: string;
	RecipientPhone: string;
	HasCorrectiveActionHistory: string;
	HasDisciplinaryActionHistory: string;
	ReferenceValue: string;
	StatusId?: number;
	LicenseId?: number;
	RequestorEntityId?: number;
	CashDetailId?: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	FunctionTypeId?: number;
	FunctionNumber: string;
	ProfessionTypeCode: string;

}
