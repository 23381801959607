import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IAutoVerificationSubscriberLicenseDto,
ILicenseDto } from './licensure-shared-dto';

export class AutoVerificationSubscriberLicenseDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IAutoVerificationSubscriberLicenseDto {
	LicenseId?: number;
	StartDate?: Date;
	EndDate?: Date;
	AutoVerificationSubscriberId: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	License: ILicenseDto;

}
