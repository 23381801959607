import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { DialogDataInjector } from '@coreModels/dialog-settings';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { FormValidationManagerService } from '@coreServices/form-validation-manager.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';
import { debounceTime } from "rxjs/operators";

@Component({ template: '' })
export class ReachDialogContentComponentV2<T> implements OnInit, OnDestroy {

  model: T;
  friendlyNames: any = {};
  contentForm: FormGroup = new FormGroup({});
  formValidator: FormValidationManagerService;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) public constantsService: ConstantsService,
    public dialogDataInjector: DialogDataInjector,
    public elementRef: ElementRef,
    public validationManagerService: ValidationManagerService) {
  }

  ngOnInit() {
    const dialogData = this.dialogDataInjector.dialogData;

    this.model = dialogData?.inputModel;
    dialogData.outputModel = this.model;

    this.formValidator = new FormValidationManagerService(this.elementRef, this.friendlyNames, this.contentForm, this.validationManagerService, dialogData.validationScope);
    this.formValidator.setForm(this.contentForm);
    this.formValidator.checkValidationErrors();

    this.modelToForm();
  }

  ngOnDestroy(): void {
    this.formValidator.unsubscribe();
  }

  modelToForm(): void {
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
      this.formValidator.checkValidationErrors();
    });
  }

  formToModel(): void {
  }
}