import { IBaseDto } from '@coreShared/dto-gen/core-shared-dto';
import { UserProfileInfoDto } from '@coreShared/dto-gen/user-profile-info-dto';
import { ICeDomainAuditInfoDto,
ICeSponsorInfoDto,
IEducationProgramApplicationInfoDto,
IOrganizationalPayorInfoDto,
IApplicationInfoDto,
IAutoVerificationSubscriberDto,
IEducationProgramApprovalListDto,
ILicenseInfoDto,
ILicensureUserProfileInfoDto,
ISupervisionPlanSearchResultItemDto,
ISupervisionVerificationSearchResultItemDto,
ISupervisorDesignationDto, 
ILicenseStatusChangeInfoDto} from './licensure-shared-dto';

export class LicensureUserProfileInfoDto extends UserProfileInfoDto implements IBaseDto, ILicensureUserProfileInfoDto {
	LicenseStatusChanges: ILicenseStatusChangeInfoDto[];
	Applications: IApplicationInfoDto[];
	Licenses: ILicenseInfoDto[];
	CeAudits: ICeDomainAuditInfoDto[];
	Sponsors: ICeSponsorInfoDto[];
	AutoVerificationSubscribers: IAutoVerificationSubscriberDto[];
	SupervisorDesignations: ISupervisorDesignationDto[];
	SuperviseeVerifications: ISupervisionVerificationSearchResultItemDto[];
	SupervisorVerifications: ISupervisionVerificationSearchResultItemDto[];
	SuperviseePlans: ISupervisionPlanSearchResultItemDto[];
	EducationProgramApprovals: IEducationProgramApprovalListDto[];
	EducationProgramApplications: IEducationProgramApplicationInfoDto[];
	SupervisorPlans: ISupervisionPlanSearchResultItemDto[];
	OrganizationalPayors: IOrganizationalPayorInfoDto[];
}
