<div class="p-fluid" *ngIf="formDataIsInit" [formGroup]="contentForm">

    <div class="p-grid p-formgrid">

        <!-- Country -->
        <div class="p-field p-col-12 p-sm-6 p-xl-4">
            <label for="Country">Country</label>
            <p-dropdown inputId="Country" formControlName="Country" [options]="possibleCountries" appendTo="body"
                optionLabel="Description" dataKey="Description" [showClear]="true" placeholder="&nbsp;" baseZIndex="1"
                [virtualScroll]="true" itemSize="25">

                <!-- Selected Item -->
                <ng-template let-country pTemplate="selectedItem">
                    <div class="p-fluid p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

                <!-- List Item -->
                <ng-template let-country pTemplate="item">
                    <div class="p-grid p-nogutter">

                        <div [class]="'flag-icon flag-icon-' + country?.AlphaTwo?.toLowerCase()" style="width:30;">
                        </div>
                        <div class="p-col p-ml-2">{{country.Description}}</div>

                    </div>
                </ng-template>

            </p-dropdown>
        </div>

        <!-- State -->
        <div class="p-field p-col-12 p-sm-4 p-md-2 p-xl-4" [hidden]="!stateVisible">
            <label for="state">{{stateLabel}}</label>
            <p-dropdown *ngIf="dataLoaded" inputId="state" formControlName="State" [options]="filteredPossibleStates"
                optionLabel="StateCode" dataKey="StateCode" [showClear]="true" placeholder="&nbsp;" appendTo="body"
                baseZIndex="1">
            </p-dropdown>
        </div>

        <!-- Checkboxes -->
        <div *ngIf="applicationBasis.IsOtherLicOriginalLicenseApplicable" class="p-col-4 p-sm-2 p-field-checkbox p-mt-sm-5 p-pt-sm-2">

            <!-- IsOriginalLicense -->
            <p-checkbox inputId="IsOriginalLicense" formControlName="IsOriginalLicense" binary="true"></p-checkbox>
            <label class="p-mr-3" for="IsOriginalLicense">Principal</label>

        </div>

    </div>

    <!-- Grid -->
    <div class="p-grid p-formgrid">

        <!-- License Type -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="Type">License Type</label>
            <input id="Type" type="text" formControlName="Type" pInputText>
        </div>

        <!-- License Number -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="Number">License Number</label>
            <input id="Number" type="text" formControlName="Number" pInputText>
        </div>

    </div>

    <!-- Grid -->
    <div class="p-grid p-formgrid">

        <!-- Issued -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="Issued">Issued</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="Issued" formControlName="Issued"
                appendTo="body" baseZIndex="1">
            </p-calendar>
        </div>

        <!-- Expiration -->
        <div class="p-field p-col-12 p-md-6 p-xl-4">
            <label for="Expiration">Expiration</label>
            <p-calendar placeholder="MM/DD/YYYY" [showOnFocus]="false" inputId="Expiration" formControlName="Expiration"
                appendTo="body" baseZIndex="1">
            </p-calendar>
        </div>

    </div>

</div>
