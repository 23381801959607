import {
  IBaseDto,
  IWebLinkInfoDto,
  IWebLinkSegment
} from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebLinkInfoDto extends BaseDto implements IBaseDto, IWebLinkInfoDto {
  AlertMessage: string;
  DisplayName: string;
  DisplayOrder: number;
  DomainId: number;
  DomainKeyId: number;
  ProcessTypeId: number;
  ProcessId?: number;
  ServiceId?: number;
  ServiceMajorKey: string;
  RouteParameters: IWebLinkSegment[];
  ScopeKeys: number[];
}
