import { IBaseDto,
ICanSubmitCartResponseDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class CanSubmitCartResponseDto extends BaseDto implements IBaseDto, ICanSubmitCartResponseDto {
	CartId: number;
	Result: boolean;
	TransactionExistsConfirmationDoesNotExist: boolean;
	TransactionExistsUnsuccessfulConfirmationExists: boolean;
	WebTransactionCreationStrategy: string;
	MajorKey: string;
	MinorKey: string;

}
