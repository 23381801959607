<div class="p-grid">

    <!-- Friendly Name -->
    <div class="p-field p-col-4">
      <label class="form-control-label" [textContent]="model.friendlyName"></label>
    </div>

    <!-- Content -->
    <div class="p-field p-col-6">
      <label class="form-control-static">{{model.toString(false) | whenEmpty : '- - -'}}</label>
    </div>

    <!-- Buttons -->
    <div class="p-text-right p-col">
      <span class="p-text-nowrap">

        <!-- Edit -->
        <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="presentItemEditorCommand" tool-tip="Add/Edit"></button>

        <!-- Delete -->
        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeItemCommand" tool-tip="Clear"></button>

      </span>
    </div>

</div>

<!-- DIALOG -->
<reach-dialog *ngIf="pickListSettings" [dialogSettings]="pickListSettings"></reach-dialog>