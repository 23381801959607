import { Component, forwardRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import * as _ from 'lodash-es';
import { ConfirmationService } from 'primeng/api';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDocumentDto, IDomainChecklistItemDto, IFileUploadDtoHost } from 'src/app/coreShared/core-shared.module';
import { ReachScenarios } from '../../index-constants';
import { Command, DialogSettings } from '../../index-models';
import { Bag, CommandService, CONSTANTS_SERVICE_TOKEN, ConstantsService, DocumentService, UtilitiesService, ValidationManagerService } from '../../index-services';
import { FileUploadEditorComponent } from '../fileUploadEditor/file-upload-editor.component';

export enum FileUploadListModeEnum {
  IsOptional = 1,
  Checklist = 2,
}

export const FileUploadListComponentSelector: string = 'file-upload-list';

@Component({
  selector: FileUploadListComponentSelector,
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadListComponent),
      multi: true
    }
  ]
})
export class FileUploadListComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() model: IFileUploadDtoHost;
  @Input() mode: number;
  @Input() bag: Bag;
  contentForm: FormGroup;
  friendlyNames: any = {};

  presentItemCreateCommand: Command;
  presentItemEditorCommand: Command;
  removeItemCommand: Command;
  okCommand: Command;
  dialogSettings: DialogSettings;

  public get documents(): IDocumentDto[] { return this.model?.Documents?.filter(item => !item.IsDeleted); }
  public get affirmationControl(): FormControl { return this.contentForm.get('Affirmation') as FormControl; }
  public get documentsRequiredValidation(): FormControl { return this.contentForm.get('documentsRequiredValidation') as FormControl; }  // Enabling this field triggers the validate() method
  public get optionalMode(): boolean { return this.mode == FileUploadListModeEnum.IsOptional }

  constructor(
    private confirmationService: ConfirmationService,
    private commandService: CommandService,
    private utilitiesService: UtilitiesService,
    private documentService: DocumentService,
    private formBuilder: FormBuilder,
    private validationManagerService: ValidationManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
  ) {
    this.modelToForm();
  }

  ngOnInit(): void {
    this.initCommands();
  }

  protected modelToForm() {
    this.contentForm = this.formBuilder.group({
      Affirmation: [null, [Validators.requiredTrue]], // Affirmation checkbox (only appears if mode == optional)
      documentsRequiredValidation: [null] // Enabling this field triggers the validate() method
    });

    this.contentForm.valueChanges.subscribe(() => { this.formToModel(); });
  }

  protected formToModel() {
    if (this.bag) this.bag.addOrReplaceItem("OptionalFileUploadCheckboxResponse", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.contentForm.get('Affirmation').value]);
  }

  private initCommands() {
    this.okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);
    this.presentItemCreateCommand = this.commandService.create(this.canPresentCreateCommandExecute, this.presentCreateCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  ngOnChanges(): void {

    if (this.optionalMode) {
      this.deployAffirmationControl();
    }
    else {
      this.affirmationControl.disable();
      this.documentsRequiredValidation.enable();
    }

    if (!this.model?.Documents) console.log("YOU HAVE NOT PROVIDED A MODEL TO THE FILE UPLOAD COMPONENT. ERRORS ARE SURE TO HAPPEN FROM THIS POINT ONWARD");
  }

  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  protected okCommandExecute = (item: IDocumentDto) => {
    item.IsDirty = true;
    item.CreatedDate = new Date(); // For viewing purposes before the item is saved.
    this.model.Documents = this.utilitiesService.addOrReplace(this.model.Documents, item);

    // If at least one document is required...
    if (!this.optionalMode) this.documentsRequiredValidation.enable();
    else this.deployAffirmationControl();
  }

  protected canPresentCreateCommandExecute = (item: IDocumentDto): boolean => {
    return true;
  }

  protected presentCreateCommandExecute = (): Observable<any> => {

    return this.createDialogModel().pipe(map((documentDto: IDocumentDto) => {
      
      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        FileUploadEditorComponent, // Content type
        'FileUploadEditorComponent', // Content key
        "Document - {new}", // Title
        documentDto, // Model
      );

      this.dialogSettings.initializationData.activityTypeId = this.model.ActivityTypeId;
      this.dialogSettings.initializationData.activitySubtypeId = this.model.ActivitySubtypeId;
      this.dialogSettings.initializationData.professionTypeCode = this.model.ProfessionTypeCode;

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

    }));
  }

  protected createDialogModel(): Observable<IDocumentDto> {
    return this.documentService.initialize().pipe(map((documentDto: IDocumentDto) => {

      if (this.mode == FileUploadListModeEnum.Checklist) documentDto.DisplayName = (this.model as IDomainChecklistItemDto).Summary;
      return documentDto;

    }));
  }

  protected canPresentEditorCommandExecute = (item: IDocumentDto): boolean => {
    return item?.LocalId != null;
  }

  protected presentEditorCommandExecute = (item: any) => {

    // Define a method to handle passing a model to the editor and opening the editor dialog.
    const doPresentEditor = async (): Promise<any> => {

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.Default, // Scenario key
        FileUploadEditorComponent, // Content type
        'FileUploadEditorComponent', // Content key
        "Document", // Title
        item  // Model
      );

      this.dialogSettings.initializationData.activityTypeId = this.model.ActivityTypeId;
      this.dialogSettings.initializationData.activitySubtypeId = this.model.ActivitySubtypeId;
      this.dialogSettings.initializationData.professionTypeCode = this.model.ProfessionTypeCode;

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;
      return of(true).toPromise();
    }

    return from(doPresentEditor());
  }

  protected canRemoveItemCommandExecute = (item: IDocumentDto): boolean => {
    return item?.LocalId != null;
  }

  protected removeItemCommandExecute = (itemToRemove: IDocumentDto) => {

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `'${itemToRemove.OriginalFileName}' is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      // If accepted...
      accept: () => {

        // Find the index of the item to be removed.
        var idx = _.findIndex(this.model.Documents, function (document: IDocumentDto) {
          return itemToRemove.LocalId
            ? itemToRemove.LocalId === document.LocalId
            : itemToRemove.Id === document.Id;
        });

        // Remove item if new, else mark as deleted.
        itemToRemove.LocalId
          ? this.model.Documents.splice(idx, 1)
          : itemToRemove.IsDeleted = true;

        // If at least one document is required...
        if (!this.optionalMode) this.documentsRequiredValidation.enable();
        else this.deployAffirmationControl();
      }

    });
  }

  /**
   * If no documents are selected, present the affirmation control.
   */
  protected deployAffirmationControl(): void {
    this.model?.Documents && this.model.Documents.filter(x => !x.IsDeleted).length != 0
      ? this.affirmationControl.disable()
      : this.affirmationControl.enable();
  }

  // ==================================== CVA ====================================

  /**
  * Aggregates errors and passes them to the parent.
  * @param c The parent form control.
  */
  validate(c: AbstractControl): ValidationErrors | null {

    // If at least one document is required...
    if (!this.optionalMode) {

      let contentFormError: any = null;
      if (this.model.Documents.filter(item => !item.IsDeleted).length == 0) {
        contentFormError = {
          errorMessage: 'At least one document is required.'
        }
      };
      this.model.Documents.forEach(element => {
        if (!element.IsNew) return;
        if (this.model.Documents.filter(item => item.OriginalFileName == element.OriginalFileName && !item.IsDeleted).length > 1) {
          contentFormError = { errorMessage: `Multiple copies of ${element.OriginalFileName} have been uploaded.` }
        }
      });

      // Set content form errors.
      this.contentForm.setErrors(contentFormError);
    }

    // Combine errors from this component...
    this.validationManagerService.clearApplicationValidationErrors();
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.contentForm.valid ? null : errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }

}
