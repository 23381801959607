<!-- Renewing User (current principal) -->
<p class="reach-section-header">Renewing User</p>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Name</label>
    <div class="breakWord">{{currentPrincipalName}}</div>
</div>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Account</label>
    <div class="breakWord">{{accountInfo}}</div>
</div>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Email</label>
    <div class="breakWord">{{currentPrincipalEmail}}</div>
</div>

<!-- License Being Renewed -->
<p class="reach-section-header">License Being Renewed</p>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">License #</label>
    <div class="breakWord">{{wizard.model.license.ReferenceValue}}</div>
</div>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Name</label>
    <div class="breakWord">{{wizard.model.entity.LastName || '-'}}</div>
</div>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Address</label>
    <div class="breakWord">{{this.wizard.model.license.AddressPublic.AddressFull}}</div>
</div>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Email</label>
    <div class="breakWord">{{wizard.model.entity.Email}}</div>
</div>

<!-- Facility Licensee -->
<p class="reach-section-header">Facility Licensee</p>
<div class="p-field p-mb-1" style="display: flex;">
    <label class="reach-col-fixed-100">Name</label>
    <div class="breakWord">{{facilityLicenseeName}}</div>
</div>