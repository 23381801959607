import { IDomainReasonForChangeDtoHost,
IValidatableListDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class ValidatableListDto<TItem, TSearchCriteria> extends ValidatableDto implements IDomainReasonForChangeDtoHost, IValidatableListDto<any,any> {
	SearchCriteria: TSearchCriteria;
	Items: TItem[];
	IsNew: boolean;

}
