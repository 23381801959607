<!-- Table -->
<p-table styleClass="p-datatable-sm reach-responsive-table" [value]="filteredAddresses" [responsive]="true"
  responsiveLayout="stack">

  <!-- Caption -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <span class="p-text-left reach-list-header">Addresses</span>
      <div>
        <button pButton pRipple class="p-button-text" icon="fa fa-plus" [command-button]="presentItemEditorCommand"
          tool-tip="Add a new item"></button>
      </div>
    </div>
  </ng-template>

  <!-- Header -->
  <ng-template pTemplate="header">
    <tr>
      <th>Location</th>
      <th>Address</th>
      <th class="p-text-center">Public</th>
      <th class="p-text-center">Mailing</th>
      <th style="width: 8em;">
        <!--Edit/Delete-->
      </th>
    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-item>
    <tr>

      <!-- Location -->
      <td>
        <div class="p-d-flex">
          <div class="p-column-title reach-col-fixed-100">Location</div>
          <div>{{item.AddressLocationType.Description | whenEmpty: '-'}}</div>
        </div>
      </td>

      <!-- Address -->
      <td>
        <div class="p-d-flex">
          <div class="p-column-title reach-col-fixed-100">Address</div>
          <span [innerHtml]="item | displayAddress | whenEmpty: '-'"></span>
        </div>
      </td>

      <!-- IsPublic (normal) -->
      <td class="p-d-none reach-d-md-table-cell p-text-center">
        <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
      </td>

      <!-- IsPublic (mobile) -->
      <td class="p-d-md-none p-sm-text-center">
        <div class="p-d-flex">
          <div class="p-column-title reach-col-fixed-100">Public</div>
          <span [innerHtml]="item.IsPublic | boolToCheckmarkGlyph"></span>
        </div>
      </td>

      <!-- Mailing -->
      <td class="p-d-none reach-d-md-table-cell p-text-center">
        <span class="p-column-title">Mailing</span>
        <span [innerHtml]="item.IsMailing | boolToCheckmarkGlyph"></span>
      </td>

      <!-- IsMailing (mobile) -->
      <td class="p-d-md-none p-sm-text-center">
        <div class="p-d-flex">
          <div class="p-column-title reach-col-fixed-100">Mailing</div>
          <span [innerHtml]="item.IsMailing | boolToCheckmarkGlyph"></span>
        </div>
      </td>

      <!-- Buttons -->
      <td class="p-text-right">

        <!-- Edit -->
        <button pButton pRipple class="p-button-text" icon="fa fa-pencil" [command-button]="presentItemEditorCommand"
          [command-parameter]="item" tool-tip="Edit this item"></button>

        <!-- Delete -->
        <button pButton pRipple class="p-button-text" icon="fa fa-trash-o" [command-button]="removeItemCommand"
          [command-parameter]="item" tool-tip="Delete this item"></button>
      </td>

    </tr>

  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="5">
        <span class="reach-text-muted"><em>No entries. Click (+) to add.</em></span>
      </td>
    </tr>
  </ng-template>

</p-table><br />

<!-- Content form -->
<div [formGroup]="contentForm">

  <!-- No Business Address -->
  <div class="p-field-checkbox" *ngIf="affirmationControl?.enabled">

    <p-checkbox inputId="HasNoBusinessAddress" formControlName="HasNoBusinessAddress" binary="true"></p-checkbox>
    <label for="HasNoBusinessAddress"><span>I do not have a business address because I am not currently in the
        workforce for this profession.</span></label>

  </div>

</div>

<!-- IMPORTANT: Editor Dialog -->
<reach-dialog *ngIf="dialogSettings" [dialogSettings]="dialogSettings"></reach-dialog>