<div class="p-fluid" [formGroup]="contentForm">

    <!-- STANDARD: Renewal Mechanism -->
    <div class="p-field-radiobutton" *ngIf="enableRenewalMechanism">
        <p-radioButton inputId="RenewalMechanism" formControlName="RenewalMechanism" [value]="RENEWAL">
        </p-radioButton>
        <label for="RenewalMechanism">Renew and maintain my current license status</label>
    </div>

    <!-- Renewal and Status Change Mechanism -->
    <div class="p-field-radiobutton" *ngIf="enableRenewalStatusChangeMechanism">
        <p-radioButton inputId="RenewalStatusChangeMechanism" formControlName="RenewalMechanism"
            [value]="RENEWAL_STATUS_CHANGE">
        </p-radioButton>
        <label for="RenewalStatusChangeMechanism">Change my license status during renewal</label>
    </div>

    <!-- Renewal and Status Change Mechanism -->
    <div class="p-field-radiobutton p-ml-3" *ngFor="let item of additionalStatusChangeOptions">

        <p-radioButton inputId="{{item.Id}}" formControlName="SelectedTransition" [value]="item">
        </p-radioButton>
        <label for="{{item.Id}}">{{item.StatusTo.Description}}</label>

    </div>

    <!-- Status Change Mechanism -->
    <div class="p-grid p-formgrid" *ngIf="enableStatusChangeMechanism">

        <!-- (READONLY) License -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">License</label>
            <span
                class="p-col p-mb-0">{{licenseInfo.LicenseType.ProfessionalSuffix}}{{licenseInfo.ReferenceValue}}</span>
        </div>

        <!-- (READONLY) Current Status -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Current Status</label>
            <span class="p-col p-mb-0">{{licenseInfo.LicenseStatus.Description}}</span>
        </div>

        <!-- Status To -->
        <div class="p-field-radiobutton" *ngFor="let item of statusChangeMechanismOptions">

            <p-radioButton inputId="{{item.Id}}" formControlName="SelectedTransition" [value]="item">
            </p-radioButton>
            <label for="{{item.Id}}">{{item.StatusTo.Description}}</label>

        </div>

    </div>

    <!-- Reactivation Mechanism -->
    <!-- One Transition Only -->
    <div class="p-grid p-formgrid" *ngIf="enableReactivationMechanism && possibleTransitions.length === 1">

        <!-- (READONLY) License -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">License</label>
            <span
                class="p-col p-mb-0">{{licenseInfo.LicenseType.ProfessionalSuffix}}{{licenseInfo.ReferenceValue}}</span>
        </div>

        <!-- (READONLY) Current Status -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Current Status</label>
            <span class="p-col p-mb-0">{{licenseInfo.LicenseStatus.Description}}</span>
        </div>

        <!-- (READONLY) Status To -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Change Status To</label>
            <span class="p-col p-mb-0">{{possibleTransitions[0].StatusTo.Description}}</span>
        </div>

    </div>

    <!-- Reactivation Mechanism -->
    <!-- More than one transition -->
    <div class="p-grid p-formgrid" *ngIf="enableReactivationMechanism && possibleTransitions.length > 1">

        <!-- (READONLY) License -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">License</label>
            <span
                class="p-col p-mb-0">{{licenseInfo.LicenseType.ProfessionalSuffix}}{{licenseInfo.ReferenceValue}}</span>
        </div>

        <!-- (READONLY) Current Status -->
        <div class="p-col-12 p-field">
            <label class="p-col-fixed reach-col-fixed-100 p-mb-0 p-as-start">Current Status</label>
            <span class="p-col p-mb-0">{{licenseInfo.LicenseStatus.Description}}</span>
        </div>

        <!-- Status To -->
        <div class="p-field-radiobutton" *ngFor="let item of reactivationMechanismOptions">

            <p-radioButton inputId="{{item.Id}}" formControlName="SelectedTransition" [value]="item">
            </p-radioButton>
            <label for="{{item.Id}}">{{item.StatusTo.Description}}</label>

        </div>

    </div>

</div>