import { Component, Inject, Input, OnChanges, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UtilitiesService } from '@coreServices/utilities.service';
import { forkJoin, from, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IAddressLocationTypeDto, IAddressTypeDto, ICountryDto, ICountyDto, IEntityAddressDto, IEntityAddressDtoHost, IStateDto } from 'src/app/coreShared/core-shared.module';
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, ConstantsService, ListService, ValidationManagerService } from '../../index-services';

@Component({
  selector: 'entity-address-mb',
  templateUrl: './entity-address-mb.component.html',
  styleUrls: ['./entity-address-mb.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAddressMbComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EntityAddressMbComponent),
      multi: true
    }
  ]
})
export class EntityAddressMbComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() addressDtoHost: IEntityAddressDtoHost;
  @Input() friendlyNames: any; // The parent's friendlyNames collection that this question and others add to.

  STATE: string = "State";
  PROVINCE: string = "Province";
  USA: string = "USA";
  CANADA: string = "CANADA";
  MN: string = "MN";

  mainForm: FormGroup;
  formReady: boolean;
  get mailingForm(): FormGroup { return this.mainForm.get('mailingForm') as FormGroup; }
  get businessForm(): FormGroup { return this.mainForm.get('businessForm') as FormGroup; }
  businessAddressId: number;

  possibleCounties: ICountyDto[];
  possibleAddressLocationTypes: IAddressLocationTypeDto[];
  possibleAddressTypes: IAddressTypeDto[];
  possibleCountries: ICountryDto[];
  possibleStates: IStateDto[];
  possibleProvinces: IStateDto[];

  get mailingAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.HOME && address.IsCurrent && !address.IsDeleted); }
  get businessAddress(): IEntityAddressDto { return this.addressDtoHost?.Addresses?.find(address => address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS && address.IsCurrent && !address.IsDeleted); }

  get mailingStateLabel(): string { return this.mailingForm.get("Country").value?.Description == this.CANADA ? this.PROVINCE : this.STATE; }
  get businessStateLabel(): string { return this.businessForm.get("Country").value?.Description == this.CANADA ? this.PROVINCE : this.STATE; }

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private listService: ListService,
    private busyManagerService: BusyManagerService,
    protected validationManagerService: ValidationManagerService,
    private utilitiesService: UtilitiesService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void { this.friendlyNames.Line1 = 'Address'; }

  ngOnChanges(): void {
    if (!this.mailingAddress) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.HOME));
    if (!this.businessAddress && !((this.addressDtoHost as any).IsNotInWorkforce)) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS));

    this.modelToForm();
    this.loadLookupLists();
  }

  private modelToForm() {
    this.businessAddressId = this.businessAddress?.Id;

    this.mainForm = this.formBuilder.group({
      IsNotInWorkforce: [],
      mailingForm: this.formBuilder.group({
        Line1: [this.mailingAddress?.Line1, [Validators.required, Validators.maxLength(60)]],
        Line2: [this.mailingAddress?.Line2, [Validators.maxLength(60)]],
        Line3: [this.mailingAddress?.Line3, [Validators.maxLength(60)]],
        City: [this.mailingAddress?.City, [Validators.required, Validators.maxLength(40)]],
        Zip: [this.mailingAddress?.Zip, [Validators.required, Validators.maxLength(10)]],
        State: [null, [Validators.required]],
        Country: [null],
        County: [{ value: null, disabled: true }],
      }),
      businessForm: this.formBuilder.group({
        Line1: [this.businessAddress?.Line1, [Validators.required, Validators.maxLength(60)]],
        Line2: [this.businessAddress?.Line2, [Validators.maxLength(60)]],
        Line3: [this.businessAddress?.Line3, [Validators.maxLength(60)]],
        City: [this.businessAddress?.City, [Validators.required, Validators.maxLength(40)]],
        Zip: [this.businessAddress?.Zip, [Validators.required, Validators.maxLength(10)]],
        State: [null, [Validators.required]],
        Country: [null],
        County: [{ value: null, disabled: true }],
      }),
    });

    this.initializeEventHandlers();
    this.mainForm.get('IsNotInWorkforce').setValue((this.addressDtoHost as any).IsNotInWorkforce);

    this.formReady = true;
  }

  protected formToModel = () => {

    if (!this.dataLoaded) return;

    (this.addressDtoHost as any).IsNotInWorkforce = this.mainForm.get('IsNotInWorkforce').value;
    this.sharedFormToModel(this.mailingAddress, this.mailingForm);
    if (this.businessAddress) this.sharedFormToModel(this.businessAddress, this.businessForm);
  }

  protected sharedFormToModel(address: IEntityAddressDto, formGroup: FormGroup): void {
    address.Line1 = formGroup.get('Line1').value;
    address.Line2 = formGroup.get('Line2').value;
    address.Line3 = formGroup.get('Line3').value;
    address.City = formGroup.get('City').value;
    address.State = formGroup.get('State').value?.StateCode ?? "";
    address.Country = formGroup.get('Country').value?.Description ?? "";
    address.Zip = formGroup.get('Zip').value;
    address.CountyId = formGroup.get('County').value?.Id;
  }

  protected initializeEventHandlers(): void {

    this.mainForm.valueChanges.pipe(debounceTime(10)).subscribe(() => { this.formToModel(); });

    this.sharedInitializeEventHandlers(this.mailingAddress, this.mailingForm);
    this.sharedInitializeEventHandlers(this.businessAddress, this.businessForm);

    this.mainForm.get('IsNotInWorkforce').valueChanges.subscribe(notInWorkforce => {

      if (notInWorkforce) {
        if (this.businessAddress) this.businessAddress.IsDeleted = true;
        this.utilitiesService.enableDisable(this.businessForm, false);
      }
      else if (this.businessAddressId != undefined) {
        this.addressDtoHost.Addresses.find(address => address.Id == this.businessAddressId && address.AddressLocationType?.Id == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS).IsDeleted = false;
        if (this.dataLoaded) this.sharedInitDropdowns(this.businessAddress, this.businessForm);
        this.utilitiesService.enableDisable(this.businessForm, true);
      }
      else {
        if (!this.businessAddress) this.utilitiesService.addOrReplace(this.addressDtoHost?.Addresses, this.createAddress(this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS));
        if (this.dataLoaded) this.sharedInitDropdowns(this.businessAddress, this.businessForm);
        this.utilitiesService.enableDisable(this.businessForm, true);
      }

    });
  }

  protected sharedInitializeEventHandlers(address: IEntityAddressDto, formGroup: FormGroup): void {

    formGroup.valueChanges.subscribe(() => { if (address) address.IsDirty = true; });

    formGroup.get('State').valueChanges.subscribe(s => {
      const x = s?.StateCode == this.MN && formGroup.get('State').enabled;
      this.utilitiesService.enableDisable(formGroup.get('County'), x, !x)
    });

    formGroup.get('Country').valueChanges.subscribe(c => {
      const selectedCountry = c?.Description ?? this.USA;
      const x = (selectedCountry == this.USA || selectedCountry == this.CANADA) && formGroup.get('Country').enabled;
      this.utilitiesService.enableDisable(formGroup.get('State'), x, !x)
    });
  }

  private loadLookupLists(): void {

    const doInit = async (): Promise<any> => {

      const responseCollection = await forkJoin([this.listService.getStates(), this.listService.getCounties(), this.listService.getAddressLocationTypes(), this.listService.getAddressTypes(), this.listService.getCountries()]).toPromise();
      this.possibleProvinces = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.CANADA) as IStateDto[];
      this.possibleStates = this.listService.filterInactiveItems(responseCollection[0]).filter((item: IStateDto) => item.Country.toUpperCase() == this.USA) as IStateDto[];
      this.possibleCounties = this.listService.filterInactiveItems(responseCollection[1]) as ICountyDto[];
      this.possibleCountries = this.listService.filterInactiveItems(responseCollection[4]) as ICountryDto[];
      this.possibleAddressLocationTypes = this.listService.filterInactiveItems(responseCollection[2]) as IAddressLocationTypeDto[];
      this.possibleAddressTypes = this.listService.filterInactiveItems(responseCollection[3]) as IAddressTypeDto[];

      this.initDropdowns();
      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  dataLoaded: boolean = false;
  protected initDropdowns(): void {

    this.sharedInitDropdowns(this.mailingAddress, this.mailingForm);
    if (this.businessAddress) this.sharedInitDropdowns(this.businessAddress, this.businessForm);

    this.dataLoaded = true;
  }

  protected sharedInitDropdowns(address: IEntityAddressDto, formGroup: FormGroup): void {
    if (!address.Country) address.Country = this.USA;

    const normalizedCountry = (address.Country || '').trim().toLowerCase();
    formGroup.get('Country').setValue(this.possibleCountries.find(item => item.Description.trim().toLowerCase() == normalizedCountry));

    const states = formGroup.get('Country').value?.Description.toLowerCase() == this.CANADA.toLocaleLowerCase() ? this.possibleProvinces : this.possibleStates;
    if (address.State) formGroup.get('State').setValue(states.find(item => item.StateCode == address.State));
    if (address.CountyId) formGroup.get('County').setValue(this.possibleCounties.find(item => item.Id == address.CountyId));
  }

  protected createAddress(addressLocationType: number): IEntityAddressDto {

    if (addressLocationType == this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS) this.businessAddressId = 0;

    const x = {
      Id: 0,
      EntityId: this.addressDtoHost.EntityId,
      ProfessionTypeCode: this.addressDtoHost.ProfessionTypeCode,
      IsCurrent: true,
      IsDeleted: false,
      IsNew: true,
      LocalId: this.utilitiesService.guid(),
      FunctionNumber: this.addressDtoHost.FunctionNumber,
      Country: this.USA,
      AddressType: { Id: this.constantsService.ADDRESS_TYPES.APPLICATION } as IAddressTypeDto,
      AddressLocationType: { Id: addressLocationType } as IAddressLocationTypeDto

    } as IEntityAddressDto;

    if (addressLocationType === this.constantsService.ADDRESS_LOCATION_TYPES.HOME) {
      x.IsMailing = true;
      x.IsPublic = true;
    }

    return x;
  }

  validate(): ValidationErrors | null { return this.mainForm.valid ? null : this.utilitiesService.aggregateCvaError(this.mainForm, this.friendlyNames); }

  // ==================================== CVA ====================================

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.mainForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void { this.onTouched = fn; }
  writeValue(value) { }
  setDisabledState?(isDisabled: boolean): void { isDisabled ? this.mainForm.disable() : this.mainForm.enable(); }
}
