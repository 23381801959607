// Framework
import { Component, forwardRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

// LODASH
import * as _l from 'lodash-es';

// Core
import { Command } from '@core/models/command';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { DialogSettings } from '@core/models/dialog-settings';
import { EntityAddressEditorComponent } from 'src/app/core/components/entity-address/entity-address-list/editor/entity-address-editor.component';
import { ReachScenarios } from '@core/constants/reach-scenarios';
import { UtilitiesService } from '@coreServices/utilities.service';
import { CommandService } from '@coreServices/command.service';

// Shared
import { AddressTypeDto, EntityAddressDto, IAddressLocationTypeDto, ICountyDto, IEntityAddressDto, IEntityAddressDtoHost, IStateDto } from '@coreShared/core-shared.module';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'entity-address-list',
  templateUrl: './entity-address-list.component.html',
  styleUrls: ['./entity-address-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAddressListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EntityAddressListComponent),
      multi: true
    }
  ]
})
export class EntityAddressListComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input()
  addressDtoHost: IEntityAddressDtoHost;
  @Input()
  enableNoBusinessAddressCheckbox: boolean = true;
  @Input()
  save: () => Observable<any>;

  // FIELDS
  presentItemEditorCommand: Command;
  dialogSettings: DialogSettings;
  removeItemCommand = null;
  contentForm: FormGroup;
  friendlyNames: any = {};

  // Lookup lists.
  possibleStates: IStateDto[] = [];
  possibleCounties: ICountyDto[] = [];
  possibleAddressLocationTypes: IAddressLocationTypeDto[] = [];

  // PROPERTIES
  public get enableNoBusinessAddress(): boolean { return !(this.businessAddress) && this.enableNoBusinessAddressCheckbox; }
  public get affirmationControl(): FormControl { return this.contentForm.get('HasNoBusinessAddress') as FormControl; }
  public get filteredAddresses(): EntityAddressDto[] { return _l.filter(this.addressDtoHost?.Addresses, (address: EntityAddressDto) => { return address.AddressType.Id == this.addressDtoHost.AddressTypeId && address.FunctionNumber == this.addressDtoHost.FunctionNumber && !address.IsDeleted; }); }
  public get businessAddress(): EntityAddressDto { return this.filteredAddresses?.find(item => item.AddressLocationType.Id === this.constantsService.ADDRESS_LOCATION_TYPES.BUSINESS || item.AddressLocationType.Id === this.constantsService.ADDRESS_LOCATION_TYPES.HOME_AND_BUSINESS); }
  public get isNotInWorkforce(): boolean { return (this.addressDtoHost as any).IsNotInWorkforce; }
  public set isNotInWorkforce(val: boolean) { (this.addressDtoHost as any).IsNotInWorkforce = val; }

  // CTOR
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private utilitiesService: UtilitiesService,
    private commandService: CommandService,
    private confirmationService: ConfirmationService,
  ) {

    // Initialize form fields.
    this.modelToForm();
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {

    // Initialize commands.
    this.initCommands();
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  */
  ngOnChanges() {

    // Assign value from the isNotInWorkforceHost.
    this.affirmationControl.setValue(this.isNotInWorkforce ?? false);

    // Handle enable/disable state of the affirmation control.
    this.deployAffirmationControl();
  }

  /**
  * Overrides the base class method to provide specified mapping from the input model (wizard model) to
  * the reactive stepform: FormGroup for this instance.
  */
  protected modelToForm() {

    // Friendly names.
    this.friendlyNames.HasNoBusinessAddress = 'Affirmation';

    // Initialize the content form.
    this.contentForm = new FormGroup({
      HasNoBusinessAddress: new FormControl(null, [Validators.requiredTrue])
    });

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel = () => {
    this.isNotInWorkforce = this.affirmationControl.value as boolean;
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {
    // When anything in this contentForm changes, update the model.
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });
  }

  /**
   * Create a new EntityAddressDto
   * @returns
   */
  public createNewAddress() {
    let address = new EntityAddressDto();

    address.Id = 0;
    address.IsNew = true;
    address.IsDeleted = false;
    address.resetLocalId();
    address.EntityId = this.addressDtoHost.EntityId;
    address.ProfessionTypeCode = this.addressDtoHost.ProfessionTypeCode || null;
    address.IsCurrent = true;
    address.FunctionNumber = this.addressDtoHost.FunctionNumber;
    let addressType = new AddressTypeDto();
    addressType.Id = this.addressDtoHost.AddressTypeId;
    address.AddressType = addressType;
    return address;
  }


  /**
  * Handles initialization of commands for this component.
  */
  protected initCommands(): void {

    // The email editor command.
    this.presentItemEditorCommand = this.commandService.create(this.canPresentEditorCommandExecute, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(this.canRemoveItemCommandExecute, this.removeItemCommandExecute);
  }

  /**
  * Allow the present item editor command to execute?
  */
  protected canPresentEditorCommandExecute = (): boolean => {
    return true;
  }

  /**
  * Open the editor dialog.
  */
  protected presentEditorCommandExecute = (item: IEntityAddressDto) => {

    // Define a custom OK command to be passed along to the dialog.
    let okCommand = this.commandService.create(this.canOkCommandExecute, this.okCommandExecute);

    // Use either the item that is selected in the list
    // OR a initialize a new one if none exists.
    let dialogModel = item ? _l.cloneDeep(item) : this.createNewAddress();
    let dialogTitle = item ? "Address" : "Address - {new}";

    // Create dialog settings.
    // Dialog settings represent a new modal dialog instance.
    this.dialogSettings = new DialogSettings(
      null, // Component instance
      ReachScenarios.Default, // Scenario key
      EntityAddressEditorComponent, // Content type
      'EntityAddressEditorComponent', // Content key
      dialogTitle, // Title
      dialogModel, // Model
      null, // OK command does not close dialog
      true); // Use model reference

    // Hand the editor the okCommand we created earlier.
    // Present the editor dialog.
    this.dialogSettings.okCommand = okCommand;
    this.dialogSettings.isOpen = true;

  }

  /**
  * Allow the present item editor command to execute?
  */
  protected canRemoveItemCommandExecute = (): boolean => {
    return true;
  }

  /**
  * Open the editor dialog.
  */
  protected removeItemCommandExecute = (item: IEntityAddressDto) => {

    // Use the PrimeNG confirmation dialog.
    this.confirmationService.confirm({
      message: `Address '${item.Line1}' is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      // If accepted...
      accept: () => {

        // Flag for delete and save.
        item.IsDeleted = true;

        // Handle enable/disable state of the affirmation control.
        this.deployAffirmationControl();

        // Save.
        if (this.save) this.save();

      }

    });

  }

  /**
  * Allow the ok command to execute?
  */
  protected canOkCommandExecute = (): boolean => {
    return true;
  }

  /**
   * The command called from the 'OK' button on the dialog.
   */
  protected okCommandExecute = (output: IEntityAddressDto) => {

    // Add or replace the incoming item in the collection.
    output.IsDirty = true;
    this.utilitiesService.addOrReplace(this.addressDtoHost.Addresses, output);

    // Save via the function passed in from the host component.
    if (this.save) this.save();

    // Handle enable/disable state of the affirmation control.
    this.deployAffirmationControl();
  }

  /**
   * Handle enable/disable state of the affirmation control.
   * The control should be disabled if there exists a business address.
   */
  protected deployAffirmationControl(): void {

    if (!this.enableNoBusinessAddressCheckbox || this.businessAddress) {
      this.affirmationControl.setValue(false);
      this.affirmationControl.disable();
    }
    else {
      this.affirmationControl.enable();
    }
  }

  // ==================================== CVA ====================================

  /**
  * Aggregates errors and passes them to the parent.
  * @param c The parent form control.
  */
  validate(): ValidationErrors | null {

    // Combine errors from this component...
    let errors = this.utilitiesService.aggregateCvaError(this.contentForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.contentForm.valid ? null : errors;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.contentForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contentForm.disable() : this.contentForm.enable();
  }
}

