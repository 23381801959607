import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';

import { registerDynamicComponent } from '@coreModels/reach-dynamic-component-registry';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';
import { ReachScenarios } from '@coreConstants/reach-scenarios';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';

export const DataRequestRequestHostComponentSelector = 'x-data-request-request-host';
export const DataRequestRequestHostComponentKey = 'dataRequestHost';

@Component({
  selector: 'x-data-request-request-host',
  templateUrl: './data-request-request-host.component.html',
  styleUrls: ['./data-request-request-host.component.scss']
})
export class DataRequestRequestHostComponent extends ReachModelAwareComponent implements OnInit, OnChanges {
  @Input() dataRequestRequest;
  constructor(
    @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector
  ) {
    // Super
    super(reachModelConfigurationInjector);
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes.
  */
  ngOnChanges(changes: SimpleChanges) {
    // This method is invoked whenever the @Input-decorated variables have value changes (as they are initialized higher up the stack).
    super.onInputChanges(changes);
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.DataRequestAdHocWizard, DataRequestRequestHostComponentKey, DataRequestRequestHostComponent, DataRequestRequestHostComponentSelector);
