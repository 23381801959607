// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { Subscription, from } from "rxjs";

import { LandingCategories, LandingComponentKeys } from '@core/index-constants';
import { ChangePasswordComponent, ChangePasswordRouteResolver } from '@coreComponents/changePassword/change-password.component';
import { ChangeSecurityQuestionsComponent, ChangeSecurityQuestionsRouteResolver } from '@coreComponents/changePassword/change-security-questions.component';
import { LoginComponentKeys } from '@coreConstants/login-component-constants';
import { GeneralInfo } from '@coreModels/general-info';
import { OnlineServiceRegistry, OnlineServiceRegistryItem } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';
import { Principal } from '@coreModels/principal';
import { RouteInfoRegistry } from '@coreModels/route-registry';
import { SummaryAggregationType, SummaryComponentInfo, SummaryDetailConfiguration } from '@coreModels/summary-component-info';
import { SummaryInfoBuilderService } from '@coreServices//summary-info-builder.service';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { DynamicRoutesService } from '@coreServices/dynamic-routes.service';
import { LandingPageService } from '@coreServices/landing-page.service';
import { OnlineServiceLinkManagerService } from '@coreServices/online-service-link-manager.service';
import { OnlineServiceMenuService } from '@coreServices/online-service-menu.service';
import { SummaryInfoManagerService } from '@coreServices/summary-info-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';

import { WebLinkInfoDto } from '@coreShared/core-shared.module';
import { DocumentHistoryComponent } from "../../features/documentHistory/document-history.component";
import { AuthGuard } from '../../guards/auth.guard';
import { DocumentHistoryRouteResolverService } from "../routeResolvers/document-history-route-resolver.service";

/**
 * Service that supports the shared features like the GeneralInfo card.
 */
@Injectable({
  providedIn: 'root'
})
export class GeneralConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private subscriptionLogin: Subscription;
  private subscriptionUserProfileRefreshed: Subscription;
  private subscriptionLandingPageReconfig: Subscription;
  private subscriptionLogout: Subscription;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) protected defaultProviderConfigurationService: DefaultProviderConfigurationService,
    protected dynamicRoutesService: DynamicRoutesService,
    protected landingPageService: LandingPageService,
    protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService,
    protected onlineServiceMenuService: OnlineServiceMenuService,
    protected router: Router,
    protected summaryInfoBuilderService: SummaryInfoBuilderService,
    protected summaryInfoManagerService: SummaryInfoManagerService,
    protected userManagerService: UserManagerService
  ) {
    this.subscriptionLogin = this.userManagerService.login$
      .subscribe(currentPrincipal => this.onUserLogin(currentPrincipal));
    this.subscriptionUserProfileRefreshed = this.userManagerService.userProfileRefreshed$
      .subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.subscriptionLandingPageReconfig =
      this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
    this.subscriptionLogout = this.userManagerService.logout$
      .subscribe(currentPrincipal => this.onUserLogout(currentPrincipal));
  }

  public initialize() {
    if (!this.isInitialized) {
      // configure constants
      this.configureConstants();

      // Decorate
      this.configureDecorators();

      // Routes
      this.configureRoutes();

      this.isInitialized = true;
    }
  }

  /**
 * Decorate the ConstantsService with Application-specific constants.
 */
  protected configureConstants() {

  }

  protected configureDecorators() {
  }

  /**
   * Configure dynamic Application routes.
   * @param $routeProvider
   * @param routeConfigurationProvider
   * @param dynamicContentConfigurationProvider
   * @param authorizationConfigurationProvider
   * @param constantsServiceProvider
   */
  protected configureRoutes() {
    const routes: Routes = [
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.DocumentHistory).onlineServicePath,
        canActivate: [AuthGuard],
        component: DocumentHistoryComponent,
        resolve: { routeData: DocumentHistoryRouteResolverService }
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Password).onlineServicePath,
        canActivate: [AuthGuard],
        component: ChangePasswordComponent,
        resolve: { routeData: ChangePasswordRouteResolver }
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Password).path,
        canActivate: [AuthGuard],
        component: ChangePasswordComponent,
        resolve: { routeData: ChangePasswordRouteResolver }
      },
      {
        path: OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.SecurityQuestions).onlineServicePath,
        canActivate: [AuthGuard],
        component: ChangeSecurityQuestionsComponent,
        resolve: { routeData: ChangeSecurityQuestionsRouteResolver }
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.SecurityQuestions).path,
        canActivate: [AuthGuard],
        component: ChangeSecurityQuestionsComponent,
        resolve: { routeData: ChangeSecurityQuestionsRouteResolver }
      }
    ];

    // Add the new routes to the routes service.
    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private addInfosToLandingPage(principal) {
    let infos: SummaryComponentInfo[] = [];
    if (!this.isInitialized) {
      this.initialize();
    }

    // Public
    let generalInfoBuilder = this.summaryInfoBuilderService.createBuilder(
      infos,
      0,
      "General",
      new SummaryDetailConfiguration(
        LandingCategories.General,
        LandingComponentKeys.GeneralSummary,
        LandingComponentKeys.GeneralDetail,
        SummaryAggregationType.Aggregate));

    generalInfoBuilder.addItem(
      1,
      "General",
      new GeneralInfo(),
      this.constantsService.DOMAIN_IDS.GENERAL,
      this.constantsService.CARD_DISPLAY_ORDER.GENERAL
    );

    this.summaryInfoManagerService.mergeInfos(infos);
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
    this.configurePasswordLinks();
  }

  private onUserLogout(principal: Principal) {
    this.configurePasswordLinks();
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  private configurePasswordLinks() {
    this.configurePasswordChangeLinks();
    this.configureSecurityQuestionChangeLinks();
  }

  private configurePasswordChangeLinks() {
    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Change your password";
    linkInfo.DisplayOrder = 100; // TODO
    linkInfo.ProcessTypeId = OnlineServiceProcessTypeKeys.Password;
    linkInfo.DomainId = 0;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];

    if (this.userManagerService.getCurrentPrincipal()) {

      // let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo); // Decided not to present this as a link at this time

      this.onlineServiceMenuService.addSystemMenuItem(
        linkInfo.DisplayName,
        () => {
          return this.userManagerService.getCurrentPrincipal() ? true : false;
        },
        () => { return from(this.router.navigate(["/", RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.Password).path])); }
      );
    }
    else {
      this.onlineServiceLinkManagerService.removeLink(linkInfo);
    }
  }

  private configureSecurityQuestionChangeLinks() {
    let linkInfo = new WebLinkInfoDto();
    linkInfo.DisplayName = "Change your security questions";
    linkInfo.DisplayOrder = 100; // TODO
    linkInfo.ProcessTypeId = OnlineServiceProcessTypeKeys.SecurityQuestions;
    linkInfo.DomainId = 0;
    linkInfo.DomainKeyId = 0;
    linkInfo.RouteParameters = [];

    if (this.userManagerService.getCurrentPrincipal()) {

      // let onlineLink = this.onlineServiceLinkManagerService.addLink(linkInfo); // Decided not to present this as a link at this time

      this.onlineServiceMenuService.addSystemMenuItem(
        linkInfo.DisplayName,
        () => {
          return this.userManagerService.getCurrentPrincipal() ? true : false;
        },
        () => { return from(this.router.navigate(["/", RouteInfoRegistry.getItemByRegistryTypeKey(LoginComponentKeys.SecurityQuestions).path])); }
      );
    }
    else {
      this.onlineServiceLinkManagerService.removeLink(linkInfo);
    }
  }
}

