import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEntityWorkHistoryDto } from './licensure-shared-dto';

export class EntityWorkHistoryDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEntityWorkHistoryDto {
	EntityId?: number;
	Facility: string;
	City: string;
	State: string;
	BusinessSetting: string;
	AverageHours?: number;
	StartDate?: Date;
	EndDate?: Date;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;

}
