import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ChecklistFileUploadComponent } from 'src/app/core/index-directives';
import { WIZARD_INJECTOR_TOKEN, WizardInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, CheckboxContentService, CommandService, ConstantsService, DomainChecklistItemService, DomainChecklistItemStatusTypeService, UtilitiesService, ValidationManagerService } from 'src/app/core/index-services';

export const CsrsExemptionComponentSelector: string = 'csrs-exemption';
export const CsrsExemptionComponentKey: string = 'CsrsExemption';

@Component({
  selector: CsrsExemptionComponentSelector,
  templateUrl: './csrs-exemption.component.html',
  styleUrls: ['./csrs-exemption.component.scss']
})
export class CsrsExemptionComponent extends ChecklistFileUploadComponent implements OnInit {

  exemptionDescription:string;

  constructor(checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , changeDetectorRef: ChangeDetectorRef
    , protected commandService: CommandService
    , protected utilitiesService: UtilitiesService
    , protected domainChecklistItemService: DomainChecklistItemService
    , protected statusTypeService: DomainChecklistItemStatusTypeService) {

    super(checkboxContentService, elementRef, validationManagerService, constantsService, wizardInjector, changeDetectorRef, commandService, utilitiesService, domainChecklistItemService, statusTypeService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.exemptionDescription = this.wizard.model.license.CsrsExemptionType?.Description;
  }

}

registerDynamicComponent(ReachScenarios.LicenseRenewalWizard, CsrsExemptionComponentKey, CsrsExemptionComponent, CsrsExemptionComponentSelector);