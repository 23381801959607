import { Inject, Injectable } from "@angular/core";
import {
  AuthorizationConfigurationProviderService,
  BootstrapperService,
  BusyManagerService,
  CONSTANTS_SERVICE_TOKEN, ConstantsService, DynamicContentConfigurationProviderService,
  DynamicContentManagerService,
  OnlineServiceProcessTypeKeys,
  OnlineServiceRegistry,
  ReachApplicationService, RouteConfiguration,
  RouteConfigurationProviderService,
  RouteResolverService,
  UserManagerService
} from '@core/core.module';
import { Resolve, Router } from "@angular/router";
import { LicenseService } from "./license.service";


@Injectable({ providedIn: 'root' })
export class SurveyCallbackRouteResolverService extends RouteResolverService<any> implements Resolve<any> {

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    protected licenseService: LicenseService) {
    super(constantsService, userManagerService, busyManagerService, bootstrapperService, dynamicContentManagerService, reachApplicationService, router, routeConfigurationProviderService, dynamicContentConfigurationProviderService, authorizationConfigurationProviderService);
  }

  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false,
      this.dynamicContentConfigurationProviderService.getConfigurationData(false, null),
      this.authorizationConfigurationProviderService.getConfigurationData(true));
  }

  override innerResolve(route: any, state: any) {
    super.innerResolve(route, state);

    this.licenseService.markSurveyComplete(route.queryParams['renewalId']).subscribe(() => {
      if (route.queryParams['action'] == 'Landing') this.router.navigate(['/', 'Landing']);
      else if (route.queryParams['action'] == 'Cart') this.router.navigate(['/', OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Cart).onlineServicePath]);
    });
  }
}
