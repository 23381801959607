<div class="layout-sidebar" [ngClass]="{'active': active}">
  <!-- Logo -->
  <a [routerLink]="['/']" class="logo">
    <img alt="logo" [src]="logoImage" style="width: 120px; height: 50px;">
  </a>
  <!-- Side Menu -->
  <div class="layout-menu">

    <!-- User Options -->
    <div class="reach-menu-category-header" *ngIf="currentPrinciple">
      <i class="fa fa-user p-mr-1"></i>
      <span class="p-pl-2">{{userDisplayName}}</span>
    </div>
    <div class="menu-items">

      <!-- Log Out -->
      <a *ngIf="reachApplicationService.logoutCommand.enabled" [command-button]="reachApplicationService.logoutCommand"
        command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
        [hidden]="!reachApplicationService.logoutCommand.enabled">
        <i class="fa fa-sign-out p-mr-1"></i>Log Out
      </a>

      <!-- Change Password -->
      <a *ngIf="reachApplicationService.passwordChangeCommand.enabled"
        [command-button]="reachApplicationService.passwordChangeCommand" command-description="NavbarPasswordChange"
        [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" [hidden]="!reachApplicationService.passwordChangeCommand.enabled">
        <i class="fa fa-gear p-mr-1"></i>
        Change Password
      </a>

      <!-- Change Security Questions -->
      <a *ngIf="reachApplicationService.changeSecurityQuestionsCommand.enabled"
        [command-button]="reachApplicationService.changeSecurityQuestionsCommand"
        command-description="NavbarSecurityQuestionsChange" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND"
        [hidden]="!reachApplicationService.changeSecurityQuestionsCommand.enabled">
        <i class="fa fa-gear p-mr-1"></i>
        Change Security Questions
      </a>
    </div>

    <!-- TODO: Build sections with just the links from each card??? -->
    <!-- <div class="reach-menu-category-header">General</div>
    <div class="menu-items">
      <a href="#" target="_blank" class="ng-tns-c38-1">Search for a Licensee</a>
      <a href="#" target="_blank" class="ng-tns-c38-1">Submit an Application for Licensure</a>
    </div> -->

    <!-- Help-->
    <div class="reach-menu-category-header">
      <i class="fa fa-info"></i>
      <span class="p-pl-2">Resources</span>
    </div>
    <div class="menu-items">

      <div *ngFor="let menuItem of helpMenuItems | sort:'DisplayOrder'" [ngClass]="{'dropdown-header' : menuItem.isHeader, 'dropdown-item' : !menuItem.isHeader}">
        <span *ngIf="menuItem.isHeader">
          <i class="fa fa-question"></i>&nbsp;&nbsp;{{menuItem.menuGroupName}}
        </span>
        <a *ngIf="!menuItem.isHeader && menuItem.command.enabled" [command-button]="menuItem.command">{{menuItem.command.commandText}}</a>
      </div>

    </div>


    <!-- <ul class="navbar-nav my-2 my-lg-0"> -->
    <!-- Log In/ Log Out -->
    <!-- <li class="nav-item">
          <a *ngIf="logoutCommand.enabled" [command-button]="logoutCommand" command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" [hidden]="!logoutCommand.enabled"><i class="fa fa-sign-out"></i>&nbsp;&nbsp;Log Out</a>
          <a *ngIf="loginCommand.enabled" [command-button]="loginCommand" command-description="NavbarLogout" [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND" [hidden]="!loginCommand.enabled"><i class="fa fa-sign-in"></i>&nbsp;&nbsp;Log In</a>
        </li> -->

    <!-- New Online Service Menu -->
    <!-- <online-service-menu></online-service-menu> -->


    <!-- Settings-->
    <!-- <li class="nav-item dropdown">
        <a href="" class="nav-link dropdown-toggle" data-toggle="dropdown"><i class="fa fa-gear"></i>&nbsp;Settings<b
            class="caret"></b></a>
        <ul class="dropdown-menu dropdown-menu-right" role="menu">
          <ng-container *ngFor="let menuItem of dynamicSystemMenuItems">
            <li *ngIf="menuItem.command.enabled">
              <a [command-button]="menuItem.command"
                [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND">{{menuItem.commandText}}</a>
            </li>
          </ng-container>
          <li [hidden]="!passwordChangeCommand.enabled" class="dropdown-divider"></li>
          <li [hidden]="!logoutCommand.enabled" class="dropdown-item"><a [command-button]="logoutCommand"
              [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND">Log out</a></li>
          <li [hidden]="!loginCommand.enabled" class="dropdown-item"><a [command-button]="loginCommand"
              [busy-type]="BUSY_MANAGER_BUSY_TYPES.COMMAND">Log in</a></li>
        </ul>
      </li> -->

    <!-- Cart-->
    <!-- <li class="nav-item">
        <cart-summary></cart-summary>
      </li> -->
    <!-- </ul> -->
  </div>
</div>