import {  } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { IOrganizationalPayorLicenseeSearchResultItemDto } from './licensure-shared-dto';

export class OrganizationalPayorLicenseeSearchResultItemDto extends SearchResultItemDto implements IOrganizationalPayorLicenseeSearchResultItemDto {
	OrganizationalPayorLicenseeId: number;
	EntityId: number;
	OrganizationalPayorId: number;
	IsPay: boolean;
	FirstName: string;
	LastName: string;
	LicenseType: string;
	LicenseNumber: number;
	BirthDate?: Date;
	ExpireDate?: Date;
	CmeDueDate?: Date;
	EffectiveFrom?: Date;
	EffectiveTo?: Date;

}
