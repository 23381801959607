import { IBaseDto,
INotificationDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class NotificationDto extends BaseDto implements IBaseDto, INotificationDto {
	Message: string;
	DomainId?: number;
	DomainKeyId?: number;
	MinorKey: string;

}
