import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import * as _l from 'lodash-es';
import { Observable, of } from 'rxjs';
import { IDomainChecklistItemDto, IFileUploadDtoHost } from 'src/app/coreShared/core-shared.module';
import { ReachScenarios } from '../../index-constants';
import { LandingPageFileUploadEditorComponent } from '../../index-directives';
import { Command, DialogSettings } from '../../index-models';
import { CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, DocumentService } from '../../index-services';
import { FileUploadListModeEnum } from '../fileUploadList/file-upload-list.component';

@Component({
  selector: 'checklist-file-upload-button',
  templateUrl: './checklist-file-upload-button.component.html'
})
export class ChecklistFileUploadButtonComponent implements OnInit, OnChanges {

  @Input() public domainChecklistItemDto: IDomainChecklistItemDto;
  @Input() public fileUploadDtoHost: IFileUploadDtoHost;

  public uploadCommand: Command;
  public okCommand: Command;
  public toolTip: string;
  public iconName: string;
  public dialogSettings: DialogSettings;
  public showIcon: boolean;

  constructor(private commandService: CommandService, @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService, protected documentService: DocumentService) {
  }

  public ngOnInit(): void {
    this.uploadCommand = this.commandService.create(this.canUploadCommandExecute, this.uploadCommandExecute);
    this.okCommand = this.commandService.create(() => true, this.okCommandExecute);
  }

  public ngOnChanges(): void {
    if (!this.domainChecklistItemDto) return;
    this.showIcon = this.domainChecklistItemDto.ChecklistItemTemplate.AllowOnlineUpload && !this.completeWithoutUpload(this.domainChecklistItemDto);
    this.toolTip = this.domainChecklistItemDto.UploadCorrespondenceId ? "Document submitted" : "Upload a document related to this checklist item";
    this.iconName = this.domainChecklistItemDto.UploadCorrespondenceId ? "fa fa-check" : "fa fa-upload";
  }

  protected completeWithoutUpload(cli: IDomainChecklistItemDto): boolean {
    var isCompleted: boolean = cli.Status.AspectStatuses.find(aspectStatus => aspectStatus.AspectStatusId == this.constantsService.DOMAIN_CHECKLIST_ITEM_STATUS_ASPECT_STATUSES.COMPLETED) != null;
    var noFileUpload = cli.UploadCorrespondenceId == null;
    return isCompleted && noFileUpload;
  }

  protected canUploadCommandExecute = (): boolean => {
    return !this.domainChecklistItemDto.UploadCorrespondenceId || this.domainChecklistItemDto.UploadCorrespondenceId == 0;
  }

  protected uploadCommandExecute = (): Observable<any> => {

    this.dialogSettings = new DialogSettings(
      null, // Component instance
      ReachScenarios.Default, // Scenario key
      LandingPageFileUploadEditorComponent, // Content type
      'LandingPageFileUploadEditorComponent', // Content key
      "Manage Document Uploads", // Title
      _l.cloneDeep(this.domainChecklistItemDto), // Model
      null, // OK command does not close dialog
      true); // Use model reference

    this.dialogSettings.okCommand = this.okCommand;
    this.dialogSettings.isOpen = true;
    this.dialogSettings.initializationData.fileUploadMode = FileUploadListModeEnum.Checklist;

    return of(true);
  }

  protected okCommandExecute = (output: IFileUploadDtoHost) => {
    this.domainChecklistItemDto.DocumentIds = output.DocumentIds;
    this.domainChecklistItemDto.Documents = output.Documents;

    this.documentService.checklistUploadSingle(this.domainChecklistItemDto).subscribe((updatedDomainChecklistItemDto: IDomainChecklistItemDto) => {
      this.domainChecklistItemDto = updatedDomainChecklistItemDto;
      this.iconName = this.domainChecklistItemDto.UploadCorrespondenceId ? "fa fa-check" : "fa fa-upload";
    });
  }
}
