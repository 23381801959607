import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  ArgumentExceptionService,
  BusyManagerService, ConstantsService, CONSTANTS_SERVICE_TOKEN, DefaultProviderConfigurationService, DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, ReachHttpClientService, UtilitiesService,
  ValidationManagerService
} from '@core/core.module';
import { IHttpResponseContent } from '@coreShared/core-shared.module';
import { CeSponsorshipDto, ICeSponsorshipDto } from '@licensureShared/licensure-shared.module';
import { from, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SponsorshipService extends ReachHttpClientService {
  uri: string;

  constructor(private argumentExceptionService: ArgumentExceptionService
    , busyManagerService: BusyManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
    , http: HttpClient, private utilitiesService: UtilitiesService
    , validationManagerService: ValidationManagerService) {
    super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
    this.uri = `${this.apiRootUri}/licensure/cesponsorship`;
  }

  public getById(id: number): Observable<ICeSponsorshipDto> {
    return this.get<CeSponsorshipDto>(`${this.uri}/${id}`);
  }

  public initialize(sponsorId: number): Observable<ICeSponsorshipDto> {
    return this.get<CeSponsorshipDto>(`${this.uri}/initialize`)
      .pipe(map((sponsorship: CeSponsorshipDto) => {
        sponsorship.CeSponsorId = sponsorId;
        return sponsorship;
      }));
  }

  public save(sponsorship: ICeSponsorshipDto): Observable<ICeSponsorshipDto> {

    const doSave = async (): Promise<ICeSponsorshipDto> => {
      if (sponsorship.Id) {
        return this.put<ICeSponsorshipDto>(this.uri, sponsorship).toPromise();
      } else {
        return this.post<ICeSponsorshipDto>(this.uri, sponsorship).toPromise();
      }
    };

    return from(doSave());
  }
}
