import { IBaseDto,
IWebServiceViewDto } from './core-shared-dto';
import { BaseDto } from './base-dto';

export class WebServiceViewDto extends BaseDto implements IBaseDto, IWebServiceViewDto {
	StepOrder: number;
	ShortTitle: string;
	LongTitle: string;
	IsVisible: boolean;
	IsSaved: boolean;
	ServiceId?: number;
	IsActive: boolean;
	FilePath: string;
	ComponentName: string;
	TemplatePath: string;
	Icon: string;
	MinorKey: string;
	Parameters: string[];
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;

}
