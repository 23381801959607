<div class="layout-footer reach-layout-footer">
  
  <div class="p-grid p-fluid">

    <!-- Logo and Agency Name -->
    <div class="p-col-12 p-xl-4">
      <img style="height: 15px" [src]="footerLogoUrl">
      <span class="p-text-left reach-agency-name">{{footerName}}</span>
    </div>

    <!-- Email and Phone -->
    <div class="p-col-12 p-xl-4">
      <div class="reach-footer-email">
        <span class="p-text-left reach-text-muted">
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </span>
        <span class="p-pl-2">
          <a href="mailto:{{agencyEmail}}" title="Board Email">{{agencyEmail}}</a>
        </span>
      </div>
      <div class="reach-footer-phone">
        <span class="p-text-left reach-text-muted">
          <i class="fa fa-phone" aria-hidden="true"></i>
        </span>
        <span class="p-pl-2">{{agencyPhone}}</span>
      </div>
    </div>

    <!-- Address -->
    <div class="p-col-12 p-xl-4">
      <span class="p-text-left">{{agencyAddress}}</span>
      <br />
      <span class="p-text-left">{{agencyCity}}, {{agencyState}} {{agencyZip}}</span>
    </div>

  </div>

</div>
