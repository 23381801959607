
import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';
import { from } from 'rxjs';

import {
  AuthGuard, ConstantsService, CONSTANTS_SERVICE_TOKEN, DynamicRoutesService,
  FunctionTypeService,
  LandingPageDetailRouteResolverService,
  LandingPageService,
  OnlineServiceHistoryService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Principal,
  ReachScenarios,
  RouteInfoRegistry,
  SummaryAggregationType,
  SummaryComponentInfo,
  SummaryDetailConfiguration,
  SummaryInfoBuilderService,
  SummaryInfoManagerService, UserManagerService, WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';

import { LicensureLandingCategories, LicensureLandingComponentKeys } from '@licensureCore/index-constants';
import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { WebServiceHistoryInfoDto } from "src/app/coreShared";
import { EducationProgramApplicationInfoDto } from "src/app/licensureShared";
import { IApplicationInfoDto } from "src/app/licensureShared/licensure-shared.module";

@Injectable({ providedIn: 'root' })
export class EducationProgramApplicationFeatureConfigurationService {

  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private educationProgramApplicationConstantsService: EducationProgramApplicationConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private onlineServiceHistoryService: OnlineServiceHistoryService
    , private router: Router
    , private summaryInfoBuilderService: SummaryInfoBuilderService
    , private summaryInfoManagerService: SummaryInfoManagerService
    , private userManagerService: UserManagerService
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService
    , private landingPageService: LandingPageService) {

    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.EducationProgramApplication);
    this.userManagerService.login$.subscribe(currentPrincipal => { if (currentPrincipal) this.onUserLogin(currentPrincipal) });
    this.userManagerService.userProfileRefreshed$.subscribe(currentPrincipal => this.onUserProfileRefreshed(currentPrincipal));
    this.landingPageService.landingPageReconfig$.subscribe(args => this.onLandingPageReconfig());
  }

  public initialize() {
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  public canContinueApplication(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo == null) {
      return false;
    }

    let webServiceHistoryInfoItem = this.getRelatedWebServiceHistoryInfoItem(applicationInfo);
    return webServiceHistoryInfoItem != null && webServiceHistoryInfoItem.ServiceInProgress;
  }

  public canCancelApplication(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo == null) {
      return false;
    }

    let webServiceHistoryInfoItem = this.getRelatedWebServiceHistoryInfoItem(applicationInfo);
    return webServiceHistoryInfoItem != null && webServiceHistoryInfoItem.ServiceInProgress && webServiceHistoryInfoItem.IsCancellationAllowed;
  }

  public cancelApplication(applicationInfo: IApplicationInfoDto) {
    if (!applicationInfo) {
      throw "applicationInfo must be supplied.";
    }

    return this.onlineServiceHistoryService.cancel(this.getRelatedWebServiceHistoryInfoItem(applicationInfo).WebServiceHistoryId);
  }

  public presentApplicationWizard(applicationInfo) {

    let webServiceHistoryInfoItem = _l.find(applicationInfo.WebServiceHistoryInfoItems,
      (item: WebServiceHistoryInfoDto) => {
        return item.ProcessTypeId === this.educationProgramApplicationConstantsService.WEB_SERVICE_PROCESS_TYPES.EDUCATION_PROGRAM_APPLICATION;
      });

    let webServiceHistoryId = null;
    if (webServiceHistoryInfoItem) {
      webServiceHistoryId = webServiceHistoryInfoItem.WebServiceHistoryId;
    }

    let selectorRequired = !webServiceHistoryId;
    if (selectorRequired) {
      let patternUrl = this.dynamicRoutesService.buildRoute(
        this.serviceRegistryItem.selectorPathTemplate,
        applicationInfo.EntityId,
        applicationInfo.Id,
        webServiceHistoryInfoItem.WebServiceHistoryId);
      return this.router.navigate(patternUrl);
    }
    else {
      let patternUrl = this.dynamicRoutesService.buildRoute(
        this.serviceRegistryItem.onlineServicePathTemplate,
        applicationInfo.Id,
        this.educationProgramApplicationConstantsService.WEB_SERVICE_PROCESS_TYPES.EDUCATION_PROGRAM_APPLICATION,
        webServiceHistoryId);
      this.router.navigate(patternUrl);
    }
  }

  public getWizard = (applicationId: number, webServiceHistoryId: number) => {
    let educationProgramApplicationConstantsService = this.constantsService as IEducationProgramApplicationConstantsService;
    let functionType = this.functionTypeService.create(educationProgramApplicationConstantsService.FUNCTION_TYPES.EDUCATION_PROGRAM_APPLICATION, applicationId);
    let wizardTags = this.wizardTagsService.create(null, null, null, null, null, null, null);

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId,
      this.educationProgramApplicationConstantsService.WEB_SERVICE_PROCESS_TYPES.EDUCATION_PROGRAM_APPLICATION,
      wizardTags);

    const initializeWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(initializeWizard());
  };

  public getRelatedWebServiceHistoryInfoItem(applicationInfo: IApplicationInfoDto) {
    if (applicationInfo != null) {
      return applicationInfo.WebServiceHistoryInfoItems.find((item: WebServiceHistoryInfoDto) => {
        return item.ProcessTypeId === this.educationProgramApplicationConstantsService.WEB_SERVICE_PROCESS_TYPES.EDUCATION_PROGRAM_APPLICATION;
      });
    }

    return null;
  }

  public canDownloadConfirmationDocument(applicationInfo: IApplicationInfoDto) {
    return applicationInfo != null && applicationInfo.OnlineConfirmationDocumentId > 0;
  }

  public downloadConfirmationDocument(applicationInfo: IApplicationInfoDto) {
    if (this.canDownloadConfirmationDocument(applicationInfo)) {
      this.onlineServiceHistoryService.downloadConfirmationDocument(applicationInfo.OnlineConfirmationDocumentId);
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, EducationProgramApplicationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, EducationProgramApplicationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_IDS, EducationProgramApplicationConstantsService.Mergers.DOMAIN_IDS);
    ConstantsService.MergeSettings(this.constantsService.FUNCTION_TYPES, EducationProgramApplicationConstantsService.Mergers.FUNCTION_TYPES);
    ConstantsService.MergeSettings(this.constantsService.ADDRESS_TYPES, EducationProgramApplicationConstantsService.Mergers.ADDRESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.VALIDATION_MODES, EducationProgramApplicationConstantsService.Mergers.VALIDATION_MODES);
    this.educationProgramApplicationConstantsService = this.constantsService as IEducationProgramApplicationConstantsService;
  }


  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/educationProgramApplicationWizard/education-program-application-wizard-selector.module').then(m => m.EducationProgramApplicationWizardSelectorModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/educationProgramApplicationWizard/education-program-application-wizard.module').then(m => m.EducationProgramApplicationWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/educationProgramApplicationWizard/education-program-application-wizard.module')
          .then(m => m.EducationProgramApplicationWizardModule)
      },
      {
        path: RouteInfoRegistry.getItemByRegistryTypeKey(LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy).path + "/:id",
        canActivate: [AuthGuard],
        loadChildren: () => RouteInfoRegistry.getLazyComponent(ReachScenarios.LandingPage, LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy),
        resolve: { routeConfiguration: LandingPageDetailRouteResolverService }
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  private onUserLogin(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onUserProfileRefreshed(principal: Principal) {
    this.addInfosToLandingPage(principal);
  }

  private onLandingPageReconfig() {
    let principal = this.userManagerService.getCurrentPrincipal();
    this.addInfosToLandingPage(principal);
  }

  private addInfosToLandingPage(principal) {
    let isFeatureEnabled = true;
    if (!this.isInitialized) {
      this.initialize();
    }

    if (isFeatureEnabled
      && principal?.user?.UserAccount?.profile?.EducationProgramApplications
      && principal.user.UserAccount.profile.EducationProgramApplications.length > 0) {

      let summaryDetailConfiguration = new SummaryDetailConfiguration(
        LicensureLandingCategories.EducationProgramApplication,
        LicensureLandingComponentKeys.EducationProgramApplicationSummaryLazy,
        LicensureLandingComponentKeys.EducationProgramApplicationDetailLazy,
        SummaryAggregationType.Singleton);

      let infos: SummaryComponentInfo[] = [];
      let infoBuilder = this.summaryInfoBuilderService.createBuilder(infos, 0, "Education Program Applications", summaryDetailConfiguration);

      principal.user.UserAccount.profile.EducationProgramApplications.forEach((educationProgramApplication: EducationProgramApplicationInfoDto, index: number) => {
        let programTitle = educationProgramApplication.EntityInfo.FullName;
        infoBuilder.addItem(index, programTitle, educationProgramApplication, this.educationProgramApplicationConstantsService.DOMAIN_IDS.EDUCATION_PROGRAM_APPLICATION);
      });

      this.summaryInfoManagerService.mergeInfos(infos);
    }
  }
}

export class EducationProgramApplicationConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      EDUCATION_PROGRAM_APPLICATION_WIZARD: "EducationProgramApplication.Wizard"
    },
    WEB_SERVICE_PROCESS_TYPES: {
      EDUCATION_PROGRAM_APPLICATION: LicensureOnlineServiceProcessTypeKeys.EducationProgramApplication
    },
    DOMAIN_IDS: {
      EDUCATION_PROGRAM_APPLICATION: 57
    },
    FUNCTION_TYPES: {
      EDUCATION_PROGRAM_APPLICATION: 38
    },
    ADDRESS_TYPES: {
      EDUCATION_PROGRAM_APPLICATION: 21
    },
    VALIDATION_MODES: {
      EDUCATION_PROGRAM_APPLICATION: {
        NEW_APPLICATION_CONTACT_INFO: 'Web.NewApplication.ContactInfo'
      }
    }
  };

  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), EducationProgramApplicationConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), EducationProgramApplicationConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DOMAIN_IDS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_IDS), EducationProgramApplicationConstantsService.Mergers.DOMAIN_IDS);
  FUNCTION_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).FUNCTION_TYPES), EducationProgramApplicationConstantsService.Mergers.FUNCTION_TYPES);
  ADDRESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).ADDRESS_TYPES), EducationProgramApplicationConstantsService.Mergers.ADDRESS_TYPES);
  VALIDATION_MODES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).VALIDATION_MODES), EducationProgramApplicationConstantsService.Mergers.VALIDATION_MODES);
}

export interface IEducationProgramApplicationConstantsService extends EducationProgramApplicationConstantsService { }
