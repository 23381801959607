import { ReachMenuItem } from './reach-menu-item';

export class ReachMenuGroup {
  constructor(public name: string, public items: ReachMenuItem[] = []) {
    let iconClass = name === "Public"
      ? "fa fa-search"
      : "fa fa-pencil-square-o";

    this.items.push(new ReachMenuItem({ isHeader: true, menuGroupName: name, isInvisibleWhenDisabled: true, icon: iconClass }));
  }

  public addMenuItem = (command) => {
    this.items.push(new ReachMenuItem({ commandModel: command, menuGroupName: this.name, isInvisibleWhenDisabled: true }));
  }
}
