import { Component, OnInit, Inject, ViewChild, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import * as _l from 'lodash-es';

import { CommandService } from "@coreServices/command.service";
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from "@coreServices/constants-provider.service";
import { OnlineUserService } from "@coreServices/online-user.service";
import { UserManagerService } from "@coreServices/user-manager.service";
import { ValidationManagerService } from "@coreServices/validation-manager.service";

import { IOnlineUserDto } from '@coreShared/core-shared.module';
import { LandingComponentKeys, LoginComponentKeys, ReachScenarios } from '@core/index-constants';
import { Command, RouteInfoRegistry, registerDynamicComponent } from '@core/index-models';
import { debounceTime } from 'rxjs/operators';
import { ReachControlValidators } from '../validators/reach-control-validators';
import { SystemSettingsManagerService } from '../../services/system-settings-manager.service';
import { DefaultRouteResolverService, AuthorizationConfigurationProviderService, BootstrapperService, BusyManagerService, DynamicContentConfigurationProviderService, DynamicContentManagerService, ReachApplicationService, RouteConfigurationProviderService, RouteConfiguration, IForgotPasswordConstantsService } from '../../index-services';

export const ChangePasswordComponentSelector = 'change-password';

@Component({
  selector: ChangePasswordComponentSelector,
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  // FIELDS
  friendlyNames: any = {};
  validationScope: string = null;
  contentForm: FormGroup;
  onlineUser: IOnlineUserDto = null;
  currentUser: IOnlineUserDto = null;
  recaptchaResponse;
  captchaPublicKey;
  captchaIsActive: boolean = true;
  dynamicContentConfiguration: any;

  // COMMANDS
  saveCommand: Command;

  // CTOR
  constructor(
    private commandService: CommandService,
    private router: Router,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private onlineUserService: OnlineUserService,
    private userManagerService: UserManagerService,
    private validationManagerService: ValidationManagerService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.modelToForm();
  }

  @ViewChild('reCaptcha') reCaptchaComponent;

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit() {

    // Get dynamicContentConfiguration.
    this.activatedRoute.data.subscribe((data: { routeData: any }) => {
      if (data && data.routeData) this.dynamicContentConfiguration = data.routeData.dynamicContentConfiguration;
    });

    this.validationScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.captchaPublicKey = this.systemSettingsManagerService.asString(this.constantsService.SYSTEM_SETTING_KEYS.CAPTCHA_KEY);

    // General initialization.
    this.onlineUser = _l.cloneDeep(this.userManagerService.getCurrentPrincipal().user) as IOnlineUserDto;

    // FriendlyNames
    this.friendlyNames.NewPassword = "New Password";
    this.friendlyNames.ConfirmPassword = "Confirm Password";
    this.friendlyNames.ReachCaptcha = "Captcha";

    // Initialize commands
    this.initCommands();

    // Initial validation.
    this.checkValidationErrors();
  }

  /**
  * Provide specified mapping from the input model to
  * the reactive FormGroup for this instance.
  */
  protected modelToForm() {

    // Construct contentForm.
    this.contentForm = new FormGroup({});
    this.contentForm.addControl('NewPassword', new FormControl(null, [Validators.required, Validators.maxLength(20)]));
    this.contentForm.addControl('ConfirmPassword', new FormControl(null, [Validators.required, Validators.maxLength(20), ReachControlValidators.passwordMatchValidator(this.contentForm.get('NewPassword') as FormControl)]));
    this.contentForm.addControl('ReachCaptcha', new FormControl('', [Validators.required]));

    // Wire event handlers for the content form.
    this.initializeEventHandlers();
  }

  /**
  * Retrieve data from the form and apply it to the model.
  */
  protected formToModel() {
    this.onlineUser.UserAccount.UserPassword = this.contentForm.get('NewPassword').value as string;
    this.onlineUser.UserAccount.ConfirmUserPassword = this.contentForm.get('ConfirmPassword').value as string;
    this.recaptchaResponse = this.contentForm.get('ReachCaptcha').value?.Captcha;
  }

  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {
    // When anything in this contentForm changes, update the model.    
    this.contentForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {

      // Collect data.
      this.formToModel();

      // Validate
      this.checkValidationErrors();
    });
  }

  /**
  * Initializes commands for this instance.
  */
  private initCommands() {

    // Save.
    this.saveCommand = this.commandService.create(this.canSaveCommandExecute, this.saveCommandExecute);

  }

  protected canSaveCommandExecute = (): boolean => {
    return true;
  }

  /**
  * Presents the editor for the selected item.
  */
  protected saveCommandExecute = () => {

    this.onlineUser.UserAccount.ValidationMode = this.constantsService.VALIDATION_MODES.WEB_PASSWORD_CHANGE;
    this.validationManagerService.clearServerValidationMessagesForKey(this.validationManagerService.validationScopeApplication);

    const doSave = async (): Promise<any> => {

      // Execute password change.
      await this.onlineUserService.changePassword(this.onlineUser, this.recaptchaResponse).toPromise();
      this.captchaIsActive = false;
      if (this.validationManagerService.hasServerApplicationValidationMessages) {
        this.reCaptchaComponent.resetCaptchaWidget();
        return of(false).toPromise();
      }
      else return this.router.navigate(["/" + RouteInfoRegistry.getItemByRegistryTypeKey(LandingComponentKeys.Landing).path]);

    };

    return from(doSave());

  }

  /**
   * Performs a check for form validation errors and writes them to the message pipeline.
   */
  private checkValidationErrors() {
    this.validationManagerService.addFormErrors(this.contentForm, this.validationScope, this.friendlyNames);
  }

}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.Default, LoginComponentKeys.Password, ChangePasswordComponent, ChangePasswordComponentSelector);

/**
 * The route resolver for the ChangePassword page.
 */
@Injectable({
  providedIn: 'root'
})
export class ChangePasswordRouteResolver extends DefaultRouteResolverService {

  constructor(
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    bootstrapperService: BootstrapperService,
    busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    dynamicContentManagerService: DynamicContentManagerService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    userManagerService: UserManagerService
  ) {
    super(
      constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
  * Override in subclass to return the RouteConfiguration data to be used for this resolver.
  */
  protected initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false,
      this.dynamicContentConfigurationProviderService.getConfigurationData(true,
        this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS.PASSWORD_CHANGE),
      this.authorizationConfigurationProviderService.getConfigurationData(false));
  }
}