import { Directive, Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { OnlineServiceLink } from '@coreModels/online-service-link';
import { OnlineServiceLinkSubscription } from '@coreModels/online-service-link-subscription';
import { SummaryComponentInfo, SummaryComponentType } from '@coreModels/summary-component-info';
import { AuthorizationConfigurationProviderService } from '@coreServices/authorization-configuration-provider.service';
import { BootstrapperService } from '@coreServices/bootstrapper.service';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { DynamicContentConfiguration, DynamicContentConfigurationProviderService } from '@coreServices/dynamic-content-configuration-provider.service';
import { DynamicContentManagerService } from '@coreServices/dynamic-content-manager.service';
import { LandingPageService } from '@coreServices/landing-page.service';
import { NotificationCenterManagerService } from '@coreServices/notification-center-manager.service';
import { OnlineServiceLinkManagerService, OnlineServiceLinkModel } from '@coreServices/online-service-link-manager.service';
import { ReachApplicationService } from '@coreServices/reach-application.service';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from '@coreServices/reach-component-factory.service';
import { RouteConfiguration, RouteConfigurationProviderService } from '@coreServices/route-configuration-provider.service';
import { DefaultRouteResolverService } from '@coreServices/routeResolvers/default-route-resolver.service';
import { SummaryInfoManagerService } from '@coreServices/summary-info-manager.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { INotificationDto } from '@coreShared/core-shared.module';
import { Observable, from, of } from 'rxjs';
import { ReachModelAwareComponent } from 'src/app/core/components/reachModelAware/reach-model-aware.component';

@Directive()
export class LandingDetailBaseComponent extends ReachModelAwareComponent {
  public mode: SummaryComponentType = SummaryComponentType.Card;
  public info: SummaryComponentInfo = null;
  public majorKey: string;
  public notifications: INotificationDto[] = [];
  public notificationTargetMinorKeys: string[] = [];
  public notificationScope: string;
  public domainKeyId: number;
  public onlineServiceLinks: OnlineServiceLink[] = [];
  public onlineServiceLinkModel: OnlineServiceLinkModel;
  public dataLoaded: boolean;

  protected onlineServiceLinkSubscriptions: OnlineServiceLinkSubscription[] = [];
  private dynamicContentConfiguration: DynamicContentConfiguration;

  constructor(protected constantsService: ConstantsService
    , protected notificationCenterManagerService: NotificationCenterManagerService
    , protected onlineServiceLinkManagerService: OnlineServiceLinkManagerService
    , @Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) reachModelConfigurationInjector: ReachModelConfigurationInjector
    , private route: ActivatedRoute
    , protected summaryInfoManagerService: SummaryInfoManagerService
    , public domainId: number) {
    super(reachModelConfigurationInjector);

    this.route.params.subscribe(params => {
      this.getRouteParameters(params);
    });

    this.route.data.subscribe((data: { routeConfiguration: any }) => {
      if (data && data.routeConfiguration && data.routeConfiguration.dynamicContentConfiguration) {
        this.dynamicContentConfiguration = data.routeConfiguration.dynamicContentConfiguration;
        this.majorKey = this.dynamicContentConfiguration.majorKey;
      }
    });

    this.generateNotificationScopeKey();
  }

  public getRouteParameters(params: Params): void {
    let domainKeyIdParam = params['id'];
    this.domainKeyId = parseInt(domainKeyIdParam);
  }

  public getSummaryInfo(domainId: number, domainKeyId: number): SummaryComponentInfo {
    return this.summaryInfoManagerService.getByDomainAndModelId(domainId, domainKeyId);
  }

  ngOnInit() {
    this.info = this.getSummaryInfo(this.domainId, this.domainKeyId);
    this.model = this.info ? this.info.model : null;
    this.getNotifications();
    this.getOnlineServiceLinksForCard();
  }

  public get isCardMode() {
    return this.mode === SummaryComponentType.Card;
  }

  protected generateNotificationScopeKey(): string {
    let cardKey = this.constantsService.NOTIFICATION_SCOPES.LANDING_DETAIL.toString();
    let instanceKey = (new Date()).valueOf().toString();
    let key = `${cardKey}_${instanceKey}`;
    this.notificationScope = key;
    return key;
  }

  protected getOnlineServiceLinksForCard() {
    this.onlineServiceLinkModel = this.onlineServiceLinkManagerService.getOnlineServiceLinksForSubscriptions(
      this.onlineServiceLinkSubscriptions
    );
  }

  private getNotifications() {
    this.notifications = this.notificationCenterManagerService.getNotificationsForScope(this.domainId, this.domainKeyId, this.notificationTargetMinorKeys, this.notificationScope);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LandingPageDetailRouteResolverService extends DefaultRouteResolverService {
  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    userManagerService: UserManagerService,
    busyManagerService: BusyManagerService,
    bootstrapperService: BootstrapperService,
    dynamicContentManagerService: DynamicContentManagerService,
    private landingPageService: LandingPageService,
    reachApplicationService: ReachApplicationService,
    router: Router,
    routeConfigurationProviderService: RouteConfigurationProviderService,
    dynamicContentConfigurationProviderService: DynamicContentConfigurationProviderService,
    authorizationConfigurationProviderService: AuthorizationConfigurationProviderService,
    private summaryInfoManagerService: SummaryInfoManagerService) {

    super(constantsService,
      userManagerService,
      busyManagerService,
      bootstrapperService,
      dynamicContentManagerService,
      reachApplicationService,
      router,
      routeConfigurationProviderService,
      dynamicContentConfigurationProviderService,
      authorizationConfigurationProviderService);
  }

  /**
   * Override in subclass to return the RouteConfiguration data to be used for this resolver.
   */
  protected override initializeRouteConfigurationData(): RouteConfiguration {
    return this.routeConfigurationProviderService.getConfigurationData(false
      , this.dynamicContentConfigurationProviderService.getConfigurationData(true, (this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS as any).LANDING)
      , this.authorizationConfigurationProviderService.getConfigurationData(true));
  }

  protected override innerResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Object> {
    const doResolve = async (): Promise<any> => {
      let routeData = await this.resolveRouteData(route, state).toPromise();
      await this.landingPageService.initialize().toPromise();
      await this.summaryInfoManagerService.setDisplayOrder().toPromise();
      return of(routeData).toPromise();
    };
    return from(doResolve());
  }
}
