import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { ICeCreditTypeDto,
ICeSponsorshipScheduledCourseCreditDto,
ICeStudyTypeDto } from './licensure-shared-dto';

export class CeSponsorshipScheduledCourseCreditDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICeSponsorshipScheduledCourseCreditDto {
	CeSponsorshipScheduledCourseId: number;
	CeCreditTypeId?: number;
	CeStudyTypeId?: number;
	CreditAmount?: number;
	IsInactive: boolean;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	CreditType: ICeCreditTypeDto;
	StudyType: ICeStudyTypeDto;

}
