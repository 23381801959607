import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import * as _l from 'lodash-es';
import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { UserManagerService } from '@coreServices/user-manager.service';
import { OnlineServiceRegistry } from '@coreModels/online-service-registry';
import { OnlineServiceProcessTypeKeys } from '@coreModels/online-service-registry-types';

@Component({
  selector: '[cart-summary]',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {
  tooltipText: string = "Click to show cart";
  presentCartCommand: Command;

  public get cart() { return (this.userManagerService.getCurrentPrincipal())?.user?.UserAccount?.profile?.Cart; }
  public get cartItemCount() { return (_l.filter(this.cart?.Items, (i) => { return !i.IsDeleted; }) as any[]).length; }
  public get isHidden(): boolean { return this.userManagerService.getCurrentPrincipal() && this.cartItemCount > 0 ? false : true; }

  constructor(private commandService: CommandService
    , private router: Router
    , private userManagerService: UserManagerService) {
  }

  ngOnInit(): void {
    this.initCommands();
  }

  protected initCommands(): void {
    this.presentCartCommand = this.commandService.create(this.canPresentCartCommandExecute, this.presentCartCommandExecute);
  }

  protected canPresentCartCommandExecute = (): boolean => {
    return true;
  }

  protected presentCartCommandExecute = () => {
    return from(this.router.navigate(['/', OnlineServiceRegistry.getItemByOnlineServiceTypeId(OnlineServiceProcessTypeKeys.Cart).onlineServicePath]));
  }
}
