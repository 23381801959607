import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BusyManagerService } from './busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from './constants-provider.service';
import { DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN, DefaultProviderConfigurationService } from '@coreServices/configuration/default-provider-configuration.service';
import { ReachHttpClientService } from './reach-http-client.service';
import { ValidationManagerService } from './validation-manager.service';
import { IInvoiceDto, InvoiceDto } from '@coreShared/core-shared.module';
import { UtilitiesService } from '../core.module';

@Injectable({ providedIn: 'root' })
export class InvoiceService extends ReachHttpClientService {
    uri: string = '';

    constructor(private utilitiesService: UtilitiesService
        , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
        , @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) defaultProviderConfigurationService: DefaultProviderConfigurationService
        , busyManagerService: BusyManagerService
        , http: HttpClient
        , validationManagerService: ValidationManagerService) {

        super(busyManagerService, constantsService, defaultProviderConfigurationService, http, validationManagerService);
        this.uri = `${this.apiRootUri}/Invoice`;
    }

    public getById(id: number): Observable<IInvoiceDto> {
        return this.get<InvoiceDto>(`${this.uri}/${id}`);
    }

    public save(invoice: IInvoiceDto): Observable<IInvoiceDto> {
        const doSave = async (): Promise<IInvoiceDto> => {
            let savedItem: IInvoiceDto;
            invoice?.Id != null
                ? savedItem = await this.put<InvoiceDto>(this.uri, invoice).toPromise()
                : savedItem = await this.post<InvoiceDto>(this.uri, invoice).toPromise();
            return savedItem;
        };

        return from(doSave());
    }
}
