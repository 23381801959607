import { Component, Inject, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import * as _l from 'lodash-es';

import { BusyManagerService } from '@coreServices/busy-manager.service';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { Command } from '@coreModels/command';
import { CommandService } from '@coreServices/command.service';
import { ReachMessage } from '@coreModels/reach-message';
import { ReachMessageService } from '@coreServices/reach-message.service';
import { SimpleChanges } from "node_modules/@angular/core/core";
import { INotificationDto } from '../../../coreShared/core-shared.module';

@Component({
  selector: 'message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.scss']
})
export class MessageAlertComponent implements OnInit, OnDestroy, OnChanges {
  @Input() messageScope: string;
  @Input() notifications: INotificationDto[];
  @Input()
  labelText: string;
  isVisible: boolean = false;
  messageCount: number = 0;
  tooltipText: string = "";
  escapeContent: boolean = false;

  constructor(
    private busyManagerService: BusyManagerService,
    private commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private reachMessageService: ReachMessageService
  ) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.notifications && this.notifications.length > 0) {
      this.isVisible = true;
      this.messageCount = this.notifications.length;

      this.tooltipText = '<ul>';
      this.notifications.forEach((m: INotificationDto) => {
        this.tooltipText += `<li>${m.Message}</li>`;
      });

      this.tooltipText += '</ul>';
    }
  }

  ngOnDestroy(): void {

  }
}
