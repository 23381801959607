import { Component, Inject, ViewChild, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from 'underscore';

import { UserDto } from '@coreShared/core-shared.module';

import {
  AppDynamicModuleLoaderService,
  AppConfigService,
  BusyManagerService,
  BusyEntry, BusyChanagedEventArgument,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  CustomProviderDirective,
  CustomProviderComponent,
  DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN,
  DefaultProviderConfigurationService,
  ReachApplicationService,
  ReachNavbarComponent,
  SharedConfigurationService,
  SystemSettingsManagerService,
  UserManagerService
} from '@core/core.module';
import { ReachAppTopbarComponent } from './reach-app-topbar/reach-app-topbar.component';


@Component({
  selector: 'reach-app-root',
  templateUrl: './reach-app.component.html',
  styleUrls: ['./reach-app.component.scss']
})
export class ReachAppComponent implements OnInit, OnDestroy, AfterViewInit {


  // <Reach>
  private subscriptionBusyChanged: Subscription;
  private applicationInitialized: Subscription;

  title: string = 'Loading...';
  currentUser: UserDto;
  providerName: string;
  customProviderAssetRoot: string;
  customProviderRoot: string;
  providerType: string;
  customProviderComponent: CustomProviderComponent;
  reachNavbarComponent: ReachNavbarComponent;
  showLoadingOverlay: boolean = true;
  isComponentInit: boolean = false;
  toasterErrorScope: string;
  notificationSummaryScope: string;
  validationSummaryScope: string;

  // </Reach>

  menuActive: boolean;

  public subscription: Subscription;
  public logoImage: string = "";

  constructor(
    private appConfigService: AppConfigService,
    private busyManagerService: BusyManagerService,
    private configurationService: SharedConfigurationService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    @Inject(DEFAULT_PROVIDER_CONFIGURATION_SERVICE_TOKEN) private defaultProviderConfigurationService: DefaultProviderConfigurationService,
    private dynamicModuleLoaderService: AppDynamicModuleLoaderService,
    public reachApplicationService: ReachApplicationService,
    private router: Router,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private titleService: Title,
    public userManagerService: UserManagerService
  ) {

  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit() {

    this.providerName = this.appConfigService.clientProviderConfiguration.provider;
    this.toasterErrorScope = this.constantsService.VALIDATION_ERROR_SCOPES.TOAST;
    this.validationSummaryScope = this.constantsService.VALIDATION_ERROR_SCOPES.APPLICATION;
    this.notificationSummaryScope = this.constantsService.NOTIFICATION_SCOPES.APPLICATION;
    this.subscriptionBusyChanged = this.busyManagerService.busyChanged$.subscribe(busyChangedArgs => this.onBusyChanged(busyChangedArgs));
    this.applicationInitialized = this.reachApplicationService.applicationInitialized$.subscribe(args => this.onApplicationInitialized(args));
    this.busyManagerService.watchForNavigation(this.router, this.constantsService.BUSY_MANAGER_BUSY_TYPES.ROUTE_NAV);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideMenu();
      }
    });

    this.logoImage = this.defaultProviderConfigurationService.coreAssetRoot + "/img/mn-logo-blue-footer.png";
  }

  /**
  * A lifecycle hook that is called after Angular has fully initialized a component's view.
  */
  ngAfterViewInit() {
    if (this.isAppInitialized && !this.isComponentInit) {
      this.onApplicationInitialized(new Object());
    }
  }

  // Listen for a key combination of Alt-V + Alt-A and toggle the display of dynamic content key adornments.
  // See also: page-summary-text.component and reach-application.service for their roles in the behavior.
  private isLastKeyAltV: boolean = false;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    // Toggle visibility of dynamic content tags (adornment) on all summary text blocks. This is helper functionality
    // for Board staff to see the dynamic content entry keys of the specific summary text block.
    if (this.isLastKeyAltV && event.altKey && event.key == 'a') {
      this.reachApplicationService.showDynamicContentKeyAdornments = !this.reachApplicationService.showDynamicContentKeyAdornments;
    }

    // Track Ald+V keypresses (first of two part sequence)
    this.isLastKeyAltV = event.altKey && event.key == 'v';
  }

  @ViewChild(CustomProviderDirective, { static: true })
  customProviderHost: CustomProviderDirective;



  onMenuButtonClick() {
    this.menuActive = true;
    this.addClass(document.body, 'blocked-scroll');
  }

  onMaskClick() {
    this.hideMenu();
  }

  hideMenu() {
    this.menuActive = false;
    this.removeClass(document.body, 'blocked-scroll');
  }

  addClass(element: any, className: string) {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  removeClass(element: any, className: string) {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // <Reach>

  loadCustomProviderComponent() {
    var boardComponentFactory = this.dynamicModuleLoaderService.boardComponentFactory;

    const viewContainerRef = this.customProviderHost.viewContainerRef;
    viewContainerRef.clear();
    var componentRef = viewContainerRef.createComponent(boardComponentFactory);
    this.customProviderComponent = <CustomProviderComponent>componentRef.instance;
    ////(<CustomProviderComponent>componentRef.instance).data = adItem.data;
    this.showLoadingOverlay = false;
  }

  /**
   * Returns true if the application state has been initialized.
   * @returns {} 
   */
  public get isAppInitialized() {
    return this.reachApplicationService.isApplicationInInitialized;
  }

  private initializeComponent() {
    this.customProviderAssetRoot = this.configurationService.defaultProvider.customProviderAssetRoot;
    this.customProviderRoot = this.configurationService.defaultProvider.customProviderRoot;
    this.providerType = this.configurationService.defaultProvider.providerType;
    this.loadCustomProviderComponent();
    this.setTitle();
  }

  /**
   * Resets the application component title. This is the value displayed as the label in a browser tab.
   */
  private setTitle() {
    if (this.isAppInitialized) {
      this.title = this.systemSettingsManagerService.get(this.constantsService.SYSTEM_SETTING_KEYS.UI_WEB_TITLE).Value as string;
    }

    this.titleService.setTitle(this.title);
  }

  /**
   * Handler for the "busyManager.busyChanged" event.
   * @param busyChangedEvent the event.
   * @param busyChangedEventArguments the BusyChanagedEventArgument.
   */
  private onBusyChanged(busyChangedEventArguments: BusyChanagedEventArgument) {
    this.showLoadingOverlay = _.find(busyChangedEventArguments.busyStack, (item: BusyEntry) => {
      return item.busyType === this.constantsService.BUSY_MANAGER_BUSY_TYPES.ROUTE_RESOLVE
        || item.busyType === this.constantsService.BUSY_MANAGER_BUSY_TYPES.COMMAND
        || item.busyType === this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT
        || item.busyType === this.constantsService.BUSY_MANAGER_BUSY_TYPES.ROUTE_NAV;
    }) != null;
  }

  /**
   * Handler for the "bootstrapper.applicationInitialized" event.
   * @param the event args.
   */
  private onApplicationInitialized(args) {
    if (args) {
      this.initializeComponent();
      this.setTitle();
      this.isComponentInit = true;
    }
  }

  // </Reach>
}
