import { ICashCreditCardDto,
ICashDetailDto,
ICashMasterDto,
IDomainReasonForChangeDtoHost,
IPaymentTypeDto,
IProcessDto,
ISearchResultItemDto } from './core-shared-dto';
import { ValidatableDto } from './validatable-dto';

export class CashMasterDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, ICashMasterDto {
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	DepositNumber: string;
	LineNumber?: number;
	LastName: string;
	MiddleName: string;
	FirstName: string;
	LicAppTypeCode: string;
	PaymentTypeCode: string;
	Amount: number;
	RevenueNumber: string;
	EntityId?: number;
	Payor: string;
	Notes: string;
	ProcessId?: number;
	RelatedCashMasterId?: number;
	TotalFees: number;
	TotalProcessRequiredFees: number;
	AutoApplyNewFees: boolean;
	Process: IProcessDto;
	RelatedDomainSearchResultItem: ISearchResultItemDto;
	CashCreditCard: ICashCreditCardDto;
	PaymentType: IPaymentTypeDto;
	RelatedCashMaster: ICashMasterDto;
	CashDetailEntries: ICashDetailDto[];
	ParentDomainId?: number;
	ParentDomainKeyId?: number;
	TemporaryParentDomainKeyId?: any;

}
