// Framework
import { ChangeDetectorRef, Component, OnInit, OnChanges, Input, forwardRef, Inject, AfterViewChecked } from '@angular/core';
import { AbstractControl, FormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidationErrors, FormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';

// LODASH
import * as _ from 'lodash-es';

// Core
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { ReachControlValidators } from '../validators/reach-control-validators';
import { UtilitiesService } from '@coreServices/utilities.service';
import { ValidationManagerService } from '@coreServices/validation-manager.service';

// Shared
import { QuestionItem, QuestionTypes } from './questionnaire';

/**
 * Represents a single question from a domain questionnaire.
 */
@Component({
  selector: 'questionnaire-question',
  templateUrl: './questionnaire-question.component.html',
  styleUrls: ['./questionnaire-question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionnaireQuestionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuestionnaireQuestionComponent),
      multi: true
    }
  ]
})
export class QuestionnaireQuestionComponent implements OnInit, OnChanges, AfterViewChecked, ControlValueAccessor {
  public questionTypes: typeof QuestionTypes = QuestionTypes;

  // Inputs.

  // Model (from parent)
  @Input()
  model: QuestionItem;

  // The parent's friendlyNames collection that this question and others add to.
  @Input()
  friendlyNames: any;

  // Forms
  questionForm: FormGroup; // The FormGroup for this ReachDialogContent modal instance.
  formReady: boolean;

  // Question Properties
  questionNumber: string;
  questionText: string;
  answerControlName: string;
  answerControlType: QuestionTypes;
  answerControl: AbstractControl;
  isQuestionNumberHidden: boolean = false;

  // CTOR
  constructor(
    private ref: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private utilitiesService: UtilitiesService,
    protected validationManagerService: ValidationManagerService) {
  }

  /**
   * A lifecycle hook that is called after the default change detector has completed checking a component's view for changes.
   */
  ngAfterViewChecked(): void {
  }

  /**
  * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. 
  * Define an ngOnInit() method to handle any additional initialization tasks.
  */
  ngOnInit(): void {
  }

  /**
  * A lifecycle hook that is called when any data-bound (@Input) property of a directive changes. 
  * 
  * SimpleChanges: A hashtable of changes represented by SimpleChange objects stored at the declared property name they belong to on a Directive or Component. 
  * This is the type passed to the ngOnChanges hook.
  */
  ngOnChanges(): void {
    this.questionNumber = `${this.model.questionNumber}`;
    this.questionText = this.model.questionText;
    this.answerControlName = this.model.key;
    this.answerControlType = this.model.questionType;
    this.isQuestionNumberHidden = this.model.isQuestionNumberHidden;

    // Friendly names.
    this.friendlyNames[this.model.key] = "Answer to question " + this.model.questionNumber;

    // Construct form.
    if (!this.formReady) this.modelToForm();
  }

  /**
  * Maps this instance's model to the FormGroup. test
  */
  protected modelToForm() {

    // Establish the content form.
    this.questionForm = new FormGroup({});

    // Initialize form.
    if (this.model.isRequired) this.questionForm.addControl(this.answerControlName, new FormControl({ value: null, disabled: this.model.isDisabled}, [Validators.required, ReachControlValidators.xBlankValidator(this.constantsService.DOMAIN_QUESTIONS.ANSWER_IDS.XBLANK)]));
    else this.questionForm.addControl(this.answerControlName, new FormControl({ value: null, disabled: this.model.isDisabled}));

    this.answerControl = this.questionForm.controls[this.answerControlName];
    if (this.model.isRequired) {
      this.answerControl.setValidators([Validators.required]);
    }
   
    // Watch for valueChanges events.
    // Avoid validating repeatedly before the form has finished loading.
    this.initializeEventHandlers();
    this.formReady = true;
  }


  /**
 * Maps this instance's stepForm FormGroup to the wizard model.
 */
  protected formToModel() {

    // The model must have a value.
    if (!this?.model) return;

    // Dropdowns.
    let selectedAnswer = this.answerControl.value;

    this.onChange(selectedAnswer);
  }



  /**
  * Wires event handlers for the content form.
  */
  protected initializeEventHandlers(): void {

    // When anything in this contentForm changes, call formToModel.
    this.questionForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.formToModel();
    });
  }

  /**
 * Aggregates errors and passes them to the parent.
 * @param c The parent form control.
 */
  validate(): ValidationErrors | null {

    // Avoid validating repeatedly before the form has finished loading.
    if (!this.formReady) return null;

    // Combine errors from this component...
    let errors = this.utilitiesService.aggregateCvaError(this.questionForm, this.friendlyNames);

    // ... and pass them to the parent.
    return this.questionForm.valid ? null : errors;
  }

  // ==================================== CVA ====================================
  onChange: any = () => { };
  onTouched: any = () => { };

  // ControlValueAccessor interface method to xmit changes to this instance's FormGroup back to the host through callback fn.
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.questionForm.valueChanges.subscribe(fn);
  }

  // ControlValueAccessor interface method to notify the host when this instance's data has changed.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method to allow the host to set values on this instance's FormGroup.
  writeValue(value) {
    //this.value = value;
    //if (this.value != null) {
    //  this.questionForm.get(this.answerControlName).setValue(value);
    //}
    this.questionForm.get(this.answerControlName).setValue(value, {emitEvent: false});
    this.ref.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.questionForm.disable() : this.questionForm.enable();
  }
}
