import { IDomainReasonForChangeDtoHost } from '@coreShared/dto-gen/core-shared-dto';
import { ValidatableDto } from '@coreShared/dto-gen/validatable-dto';
import { IEducationDto,
IEducationProgramApprovalListDto,
IEducationProgramDto } from './licensure-shared-dto';

export class EducationProgramApprovalListDto extends ValidatableDto implements IDomainReasonForChangeDtoHost, IEducationProgramApprovalListDto {
	EducationProgram: IEducationProgramDto;
	Education: IEducationDto[];

}
