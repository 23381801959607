import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that transforms an Entity object into a format name string.
 */
@Pipe({
    name: 'displayEntityName'
})
export class DisplayEntityNamePipe {
  // Transform function takes an Entity object as the input value param.
  transform(entity): string {
      if (entity) {
          if (entity.IsIndividual) {
        var formattedName = "";

        if (entity.FirstName) {
            formattedName += entity.FirstName.trim();
        }

        if (entity.MiddleName) {
            formattedName += (formattedName.length > 0 ? " " : "") + entity.MiddleName.trim();
        }

        formattedName += (formattedName.length > 0 ? " " : "") + entity.LastName.trim();

        return formattedName;
      } else {
              return entity.LastName;
      }
    }

    return "";
  }
}
