// FRAMEWORK
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { noop } from 'rxjs';

// LODASH
import * as _l from 'lodash-es';

// CORE
import {
  CheckboxContent,
  CheckboxContentService,
  ConstantsService, CONSTANTS_SERVICE_TOKEN,
  ReachScenarios,
  registerDynamicComponent,
  ValidationManagerService,
  WizardInjector,
  WIZARD_INJECTOR_TOKEN,
  WizardStepComponent,
  ReachControlValidators
} from '@core/core.module';

// SHARED
import { IEntityDto } from '@coreShared/core-shared.module';
import { EducationProgramApplicationDto } from '@licensureShared/licensure-shared.module';
import { EducationProgramApplicationWizardModel } from '../models/education-program-application-wizard-models';


export const DescriptiveDataComponentSelector: string = 'descriptive-data';
export const DescriptiveDataComponentKey: string = 'descriptiveData'; // Match to [dbo].[lookup_web_service_view].[component_name]

/**
 * User Program Contact wizard step detail component for registration account detail.
 */
@Component({
  selector: DescriptiveDataComponentSelector,
  templateUrl: './descriptive-data.component.html',
  styleUrls: ['./descriptive-data.component.scss']
})
export class DescriptiveDataComponent extends WizardStepComponent implements OnInit, AfterViewInit, OnDestroy {

  // FIELDS
  checkboxContentItems = [];
  checkboxContents: CheckboxContent[] = [];
  programDirectorEntity: IEntityDto;
  educationProgramApplication: EducationProgramApplicationDto;

  // PROPERTIES
  public get wizardModel(): EducationProgramApplicationWizardModel { return (this.wizard.model as EducationProgramApplicationWizardModel) }

  // CTOR
  constructor(
    checkboxContentService: CheckboxContentService,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector) {

    // BASE
    super(constantsService, validationManagerService, wizardInjector, DescriptiveDataComponent.name, checkboxContentService, elementRef);

    // Construct form.
    this.modelToForm();

    this.wizard.model.validationMode = null;
  }

  /**
   * OnInit Ng lifecycle event handler.
   */
  ngOnInit(): void {

    // Friendly names.
    this.friendlyNames.Name = 'Name';
    this.friendlyNames.Credentials = 'Acknowledgement';
    this.friendlyNames.Phone = 'Phone';
    this.friendlyNames.Email = 'Email Address';
    this.friendlyNames.PlannedBeginDate = 'Program Start Date';
    this.friendlyNames.PlannedFirstGradDate = 'Planned Graduation Date';
    this.friendlyNames.AdmittedStudentNbr = 'Admitted Number of Students';
    this.friendlyNames.TimesStudentAdmittedNbr = 'Times Students Admitted Each Year';
    this.friendlyNames.MaximumStudentEnrolledNbr = 'Maximum Number of Students Enrolled';
    this.friendlyNames.ProgramTermLength = 'Program Term Length';

    // Base.
    super.ngOnInit();
  }

  /**
   * AfterViewInit Ng lifecycle event handler.
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * OnDestroy Ng lifecycle event handler. Cleanup of validation errors, observable subscriptions, and anything else that needs to be disposed of when
   * this wizard step exited.
   */
  ngOnDestroy(): void { super.ngOnDestroy(); }

  /**
* Returns the year range format string for planned start date.
* @returns {} 
*/
  public get startDateYearRange() {
    let currentYear = (new Date()).getFullYear();
    let fmt = `${currentYear}:${currentYear + 3}`;
    return fmt;
  }

  public save = () => {
    return noop();
  }

  /**
   * Overrides the base class method to provide specified mapping from the input model (wizard model) to
   * the reactive stepForm: FormGroup for this instance.
   */
  protected modelToForm() {

    this.educationProgramApplication = this.wizardModel.educationProgramApplication as EducationProgramApplicationDto;
    this.programDirectorEntity = this.educationProgramApplication.DirectorEntity;
    let plannedBeginDate: Date = this.educationProgramApplication.PlannedBeginDate ? new Date(this.educationProgramApplication.PlannedBeginDate) : new Date();
    let plannedFirstGradDate: Date = this.educationProgramApplication.PlannedFirstGradDate ? new Date(this.educationProgramApplication.PlannedFirstGradDate) : new Date();
    // Construct form.
    this.stepForm.addControl('PlannedBeginDate', new FormControl(plannedBeginDate as Date, [Validators.required, ReachControlValidators.pastDateValidator('Planned Begin Date')]));
    this.stepForm.addControl('PlannedFirstGradDate', new FormControl(plannedFirstGradDate, [Validators.required, ReachControlValidators.pastDateValidator('Planned First Grad Date')]));
    this.stepForm.addControl('AdmittedStudentNbr', new FormControl(this.educationProgramApplication.AdmittedStudentNbr, [Validators.required]));
    this.stepForm.addControl('TimesStudentAdmittedNbr', new FormControl(this.educationProgramApplication.TimesStudentAdmittedNbr, [Validators.required]));
    this.stepForm.addControl('MaximumStudentEnrolledNbr', new FormControl(this.educationProgramApplication.MaximumStudentEnrolledNbr, [Validators.required]));
    this.stepForm.addControl('ProgramTermLength', new FormControl(this.educationProgramApplication.ProgramTermLength, [Validators.required]));

    // Base.
    super.modelToForm();
  }

  /**
 * Maps this instance's stepForm FormGroup to the wizard model.
 */
  protected formToModel() {
    this.educationProgramApplication.PlannedBeginDate = this.stepForm.get('PlannedBeginDate').value;
    this.educationProgramApplication.PlannedFirstGradDate = this.stepForm.get('PlannedFirstGradDate').value;
    this.educationProgramApplication.AdmittedStudentNbr = this.stepForm.get('AdmittedStudentNbr').value;
    this.educationProgramApplication.TimesStudentAdmittedNbr = this.stepForm.get('TimesStudentAdmittedNbr').value;
    this.educationProgramApplication.MaximumStudentEnrolledNbr = this.stepForm.get('MaximumStudentEnrolledNbr').value;
    this.educationProgramApplication.ProgramTermLength = this.stepForm.get('ProgramTermLength').value;
  }
}

// Register this component for dynamic loading by key match.
registerDynamicComponent(ReachScenarios.EducationProgramApplicationWizard, DescriptiveDataComponentKey, DescriptiveDataComponent, DescriptiveDataComponentSelector);

