import { Inject, Injectable } from '@angular/core';
import { BusyManagerService } from '@coreServices/busy-manager.service';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService } from '@coreServices/constants-provider.service';
import { ListService } from '@coreServices/list.service';
import { IDomainChecklistItemStatusDto } from '@coreShared/core-shared.module';
import * as _ from 'lodash-es';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DomainChecklistItemStatusTypeService {

  statusTypes: IDomainChecklistItemStatusDto[];

  constructor(
    protected listService: ListService,
    protected busyManagerService: BusyManagerService,
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
  ) {

    this.loadLookupLists();
  }

  private loadLookupLists() {

    const doInit = () => {

      const load = forkJoin([this.listService.getDomainChecklistItemStatusTypes()]).pipe(map(
        (responseCollection) => {
          this.statusTypes = this.listService.filterInactiveItems(responseCollection[0]) as IDomainChecklistItemStatusDto[];
        }));

      // Subscribe to the observable to make it 'hot'/executing, then pass the observable to the busyService to track.
      load.subscribe(res => { });

      return load;
    }

    this.busyManagerService.resolve(doInit(), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT);
  }

  /**
   * Get a domain checklist item status for a specified group id and aspect status id
   * @param groupId 
   * @param aspectStatusId 
   */
  public getByGroupAndAspectStatusId(groupId, aspectStatusId) {
    // Find the matching status
    var result = _.find(this.statusTypes, function (item) {
      // It's a match if any status relationships include the requested aspect status
      return item.StatusGroupId === groupId && _.some(item.AspectStatuses, function (aspectStatusRel) {
        return aspectStatusRel.AspectStatusId === aspectStatusId;
      });
    });

    return result;

    //throw new Error("Checklist item status not found matching (groupId=" + groupId + "; aspectStatusId=" + aspectStatusId + ").Checklist item status and aspect statuses must be set.");
  }

  /**
   * Determine if the checklist item has the specified aspect status
   * @param checklistItem 
   * @param aspectStatusId 
   */
  public hasAspectStatus(checklistItem, aspectStatusId) {
    // Ensure item has status and AspectStatuses
    if (checklistItem.Status === undefined || checklistItem.Status.AspectStatuses === undefined) {
      throw new Error("Invalid status information on checklist item (id=" + checklistItem.Id + "). Checklist item status and aspect statuses must be set.");
    }

    return _.some(checklistItem.Status.AspectStatuses, function (aspectStatusRel) {
      return aspectStatusRel.AspectStatusId === aspectStatusId;
    });
  };

  /**
   * Set the specified aspect status on the checklist item.
   * @param checklistItem 
   * @param aspectStatusId 
   */
  public setStatusForAspectStatus(checklistItem, aspectStatusId) {
    // Ensure item has status and AspectStatuses
    if (checklistItem.ChecklistItemTemplate.StatusGroupId === undefined) {
      throw new Error("Status group id on checklist item (id=" + checklistItem.Id + ") is required.");
    }

    checklistItem.Status = this.getByGroupAndAspectStatusId(checklistItem.ChecklistItemTemplate.StatusGroupId, aspectStatusId);
    checklistItem.StatusId = checklistItem.Status.Id;
  }
}
