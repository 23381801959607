import { IAddressInfoDto,
IEntityPhoneInfoDto } from '@coreShared/dto-gen/core-shared-dto';
import { SearchResultItemDto } from '@coreShared/dto-gen/search-result-item-dto';
import { ISupervisorDesignationInfoDto,
ISupervisorSearchResultItemDto } from './licensure-shared-dto';

export class SupervisorSearchResultItemDto extends SearchResultItemDto implements ISupervisorSearchResultItemDto {
	Id: number;
	EntityId: number;
	LastName: string;
	FirstName: string;
	MiddleName: string;
	FullName: string;
	LicenseTypeCode: string;
	LicenseNumber?: number;
	CredentialDescription: string;
	AllDesignations: string;
	ReferenceValue: string;
	ProfessionalSuffix: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedDate: Date;
	ModifiedBy: string;
	Email: string;
	PrivateEmail: string;
	PublicPhoneFull: string;
	PublicAddressFull: string;
	PublicPhone: IEntityPhoneInfoDto;
	PublicAddress: IAddressInfoDto;
	Designations: ISupervisorDesignationInfoDto[];

}
