
import { Component, ElementRef, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachControlValidators, ReachDialogContentComponentV2 } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IEntityAssociateDto, IEntityInfoDto } from 'src/app/coreShared/core-shared.module';
import { LicenseSelectorFieldConfiguration, LicenseSelectorFieldConfigurationSearchCriteria } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-configuration';
import { LicenseSelectorFieldModel } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-model';
import { LicensureEntityInfoDto } from 'src/app/licensureShared';

export const EntityAssociatedWithEditorComponentSelector: string = "entity-associated-with-editor";
export const EntityAssociatedWithEditorComponentKey: string = "EntityAssociatedWithEditorComponent";

@Component({
  selector: EntityAssociatedWithEditorComponentSelector,
  templateUrl: './entity-associated-with-editor.component.html',
  styleUrls: ['./entity-associated-with-editor.component.scss']
})
export class EntityAssociatedWithEditorComponent extends ReachDialogContentComponentV2<IEntityAssociateDto> {

  licenseSelectorFieldModel: LicenseSelectorFieldModel;
  licenseSelectorFieldConfig: LicenseSelectorFieldConfiguration;

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService) {

    super(constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.licenseSelectorFieldConfig = new LicenseSelectorFieldConfiguration("Associated With", "Associated With", new LicenseSelectorFieldConfigurationSearchCriteria("Organization"));
    if (this.model?.EntityInfo) this.licenseSelectorFieldModel = new LicenseSelectorFieldModel((this.model.EntityInfo as LicensureEntityInfoDto).StrongLicense?.Id, this.model.EntityInfo.FullName, this.model.EntityId, this.model.EntityInfo?.PublicAddressInfo?.City);
  }

  override modelToForm(): void {
    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";

    const startDate = this.model.BeginDate ? new Date(this.model.BeginDate) : null;
    const endDate = this.model.EndDate ? new Date(this.model.EndDate) : null;

    this.contentForm.addControl("StartDate", new FormControl(startDate, [ReachControlValidators.pastDateValidator("Start Date")]));
    this.contentForm.addControl("EndDate", new FormControl(endDate, [ReachControlValidators.pastDateValidator("End Date")]));
    this.contentForm.addControl("Comments", new FormControl(this.model.Comment, [Validators.maxLength(2000)]));
  }

  override formToModel(): void {
    this.model.Comment = this.contentForm.get('Comments').value;
    this.model.BeginDate = this.contentForm.get('StartDate').value;
    this.model.EndDate = this.contentForm.get('EndDate').value;
  }

  public licenseSelectionChanged(licenseSelectorFieldModel: LicenseSelectorFieldModel): void {
    this.model.EntityId = licenseSelectorFieldModel.licenseeEntityId;
    this.model.EntityInfo = { FullName: licenseSelectorFieldModel.selectedLicenseeName, PublicAddressInfo: { City: licenseSelectorFieldModel.city } } as IEntityInfoDto;
    this.model.IsDirty = true;
  }
}

registerDynamicComponent(ReachScenarios.ProfileLanding, EntityAssociatedWithEditorComponentKey, EntityAssociatedWithEditorComponent, EntityAssociatedWithEditorComponentSelector);