<div class="p-fluid" [formGroup]="stepForm">

  <!-- Director and Program Information -->
  <div class="p-grid p-formgrid">

    <!-- Director Information -->
    <div class="p-col-12 p-lg-6">

      <!-- Header -->
      <div class="reach-section-header">Director</div>

      <!-- Name -->
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed reach-col-fixed-100">Name</label>
        <span class="p-col-12 p-lg-6">{{directorName}}</span>
      </div>

      <!-- Credentials -->
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed reach-col-fixed-100">Credentials</label>
        <span class="p-col-12 p-lg-6">{{directorCredentials}}</span>
      </div>

      <!-- Phone -->
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed reach-col-fixed-100">Phone</label>
        <span class="p-col-12 p-lg-6">{{directorPrimaryPhoneNumber}}</span>
      </div>

      <!-- Email -->
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed reach-col-fixed-100">Email</label>
        <span class="p-col-12 p-lg-6">{{directorEmail}}</span>
      </div>

    </div>

    <!-- Program Information -->
    <div class="p-col-12 p-lg-6">

      <!-- Header -->
      <div class="reach-section-header">Program</div>

      <!-- Program Name -->
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed reach-col-fixed-100">Name</label>
        <span class="p-col-12 p-sm-6">{{programName}}</span>
      </div>

      <!-- Email -->
      <div class="p-field p-col-12 p-pl-0">
        <label for="Email" class="p-col-fixed reach-col-fixed-100">Email</label>
        <div class="p-col-12">
          <input id="Email" pInputText type="text" formControlName="Email">
        </div>
      </div>

    </div>

  </div>

  <!-- ADDRESS (List Mode) -->
  <entity-address-list [addressDtoHost]="educationProgramApplication" [enableNoBusinessAddressCheckbox]="false">
  </entity-address-list>

  <!-- Phone Number List -->
  <entity-phone-number-list [model]="programContactEntity.PhoneNumbers" [canCreate]="true" [canEdit]="true"
    [canDelete]="true" [entityId]="contactEntityId" [save]="save"></entity-phone-number-list>

  <!-- Affirmation Section -->
  <div class="p-grid p-formgrid">

    <div class="reach-section-header">Acknowledgement</div>

    <div class="p-col-12" *ngIf="hasCheckboxes">
      <checkbox-content *ngFor="let checkboxContent of checkboxContents"
        [formControlName]="checkboxContent.formControlName" [item]="checkboxContent.model"></checkbox-content>
    </div>

  </div>

</div>