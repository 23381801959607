// style notes: (https://github.com/johnpapa/angular-styleguide#style-y053)
import { Injectable, Inject } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { Subject, Observable } from "rxjs";

import * as _l from 'lodash-es';

import { SystemSettingsManagerService } from './system-settings-manager.service';
import { CommandService } from './command.service';
import { Command, NavigationMenuCommand } from '../models/command';
import { ConstantsService, CONSTANTS_SERVICE_TOKEN } from '@coreServices/constants-provider.service';
import { OnlineServiceLink } from '../models/online-service-link';

@Injectable({ providedIn: 'root' })
export class OnlineServiceMenuService {
  commands: Command[] = [];
  systemSettingCommands: Command[] = [];
  private _systemMenuChangedSubject = new Subject<any>();
  public systemMenuChanged$ = this._systemMenuChangedSubject.asObservable();

  constructor(
    @Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService,
    private systemSettingsManagerService: SystemSettingsManagerService,
    private commandService: CommandService,
    private router: Router) {
  }

  public getCommands(): Command[] {
    return this.commands;
  }

  public addOnlineServiceMenuItem(systemSettingsGroupKey,
    systemSettingGroupOrderKey: string,
    name: string,
    route,
    featureEnabledSystemSettingKey: string,
    allowAnonymous: boolean,
    canExecuteFunction: Function = null,
    executeFunction: Function = null) {
    var navigationMenuCommand: NavigationMenuCommand = this.commandService.createNavigationMenuCommand(name,
      route,
      featureEnabledSystemSettingKey,
      allowAnonymous,
      canExecuteFunction,
      executeFunction).commandBlocksWhenHolds();

    var groupNameSetting = this.systemSettingsManagerService.get(systemSettingsGroupKey);
    var groupOrderSetting = this.systemSettingsManagerService.get(systemSettingGroupOrderKey);

    navigationMenuCommand.menuGroupName = groupNameSetting != null ? groupNameSetting.Value : "Other";
    navigationMenuCommand.menuGroupOrder = groupOrderSetting != null ? parseInt(groupOrderSetting.Value) : 100;

    if (featureEnabledSystemSettingKey) {
        navigationMenuCommand.featureEnabledSystemSettingKey = featureEnabledSystemSettingKey;

        let existingIndex = _l.findIndex(this.commands,
          (cmd) => {
              return (cmd as NavigationMenuCommand).menuGroupName === navigationMenuCommand.menuGroupName &&
              cmd.commandText === navigationMenuCommand.commandText;
          });
        if (existingIndex > -1) {
          this.commands.splice(existingIndex, 1, navigationMenuCommand);
        } else {
          this.commands.push(navigationMenuCommand);
        }
    }
  }

  public addOnlineServiceMenuItemFromOnlineLink(
    onlineLink: OnlineServiceLink,
    systemSettingsGroupKey,
    systemSettingGroupOrderKey: string,
    featureEnabledSystemSettingsKey: string,
    allowAnnonymous: boolean
  ) {
    let routeUrl = onlineLink.selectorRoute.join('');
    this.addOnlineServiceMenuItem(
      systemSettingsGroupKey,
      systemSettingGroupOrderKey,
      onlineLink.webLinkInfo.DisplayName,
      routeUrl,
      featureEnabledSystemSettingsKey,
      allowAnnonymous,
      onlineLink.canExecute
    );
  }

  public addSystemMenuItem(
    name: string,
    canExecuteFunction: Function,
    executeFunction: Function) {

    let systemCommand = this.commandService.createButtonCommand(() => {
      return canExecuteFunction ? canExecuteFunction() : true;
    },
      () => {
        return executeFunction();
      });

    systemCommand.commandText = name;
    systemCommand.description = name;

    let existingIndex = _l.findIndex(this.systemSettingCommands,
      (cmd) => {
        return cmd.commandText === systemCommand.commandText;
      });
    if (existingIndex > -1) {
      this.systemSettingCommands.splice(existingIndex, 1, systemCommand);
    } else {
      this.systemSettingCommands.push(systemCommand);
    }

    this._systemMenuChangedSubject.next(this.systemSettingCommands);
  }
}
