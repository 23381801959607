import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { from, of } from "rxjs";
import { ReachScenarios } from "src/app/core/index-constants";
import { ReachModelAwareComponent } from "src/app/core/index-directives";
import { Command, DialogSettings, registerDynamicComponent } from "src/app/core/index-models";
import { BusyManagerService, CONSTANTS_SERVICE_TOKEN, CommandService, ConstantsService, ListService, REGION_MODEL_INJECTOR_TOKEN, ReachModelConfigurationInjector, UserManagerService, UtilitiesService } from "src/app/core/index-services";
import { IEntityAssociateDto, IEntityAssociateTypeDto } from "src/app/coreShared/core-shared.module";
import { EntityAssociatedWithModel } from "./EntityAssociatedWithModel";
import { EntityAssociatedWithEditorComponent } from "./editor/entity-associated-with-editor.component";

export const EntityAssociatedWithComponentSelector: string = "entity-associated-with";
export const EntityAssociatedWithComponentKey: string = "entityAssociatedWith";

@Component({
  selector: EntityAssociatedWithComponentSelector,
  templateUrl: './entity-associated-with.component.html',
  styleUrls: ['./entity-associated-with.component.scss']
})
export class EntityAssociatedWithComponent extends ReachModelAwareComponent implements OnInit {

  public get associateWithList(): IEntityAssociateDto[] { return ((this.model as EntityAssociatedWithModel)?.entity?.AssociatedWith || [] as IEntityAssociateDto[]).filter(item => !item.IsDeleted) }

  public possibleEntityAssociateTypes: IEntityAssociateTypeDto[];
  public associationTypeId: number;
  public title: string;
  public contentForm: FormGroup;
  public dialogSettings: DialogSettings;
  public isVisible: boolean;

  public okCommand: Command;
  public presentItemEditorCommand: Command;
  public removeItemCommand: Command;

  constructor(
    @Optional() @Inject(REGION_MODEL_INJECTOR_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,
    protected busyManagerService: BusyManagerService,
    protected commandService: CommandService,
    @Inject(CONSTANTS_SERVICE_TOKEN) protected constantsService: ConstantsService,
    protected listService: ListService,
    protected utilitiesService: UtilitiesService,
    protected confirmationService: ConfirmationService,
    protected userManagerService: UserManagerService,
  ) {
    super(reachModelConfigurationInjector);
  }

  protected configure() {
    this.isVisible = true;
  }

  ngOnInit(): void {
    this.title = this.config?.title ?? "Associated With";
    this.associationTypeId = this.config?.associationTypeId;
    this.configure(); // To be overriden where necessary (AdministratorOfRecord, DirectorOfRecord, BarberShopAssociation, etc.)
    this.initCommands();
    this.loadLookupLists();
  }

  private initCommands(): void {
    this.okCommand = this.commandService.create(() => true, this.okCommandExecute);
    this.presentItemEditorCommand = this.commandService.create(() => true, this.presentEditorCommandExecute);
    this.removeItemCommand = this.commandService.create(() => true, this.removeItemCommandExecute);
  }

  protected loadLookupLists(): void {
    const doInit = async (): Promise<any> => {
      this.possibleEntityAssociateTypes = await this.listService.getEntityAssociateTypes().toPromise();

      return of(true).toPromise();
    }

    this.busyManagerService.resolve(from(doInit()), this.constantsService.BUSY_MANAGER_BUSY_TYPES.VIEW_INIT); // Display busy status while resolving.
  }

  protected okCommandExecute = (entityAssociate: IEntityAssociateDto) => {
    entityAssociate.IsDirty = true;
    this.model.entity.AssociatedWith = this.utilitiesService.addOrReplace(this.associateWithList, entityAssociate);

    if (this.config?.save) this.config.save();
  }

  protected presentEditorCommandExecute = (item: any) => {
    const doPresent = async (): Promise<any> => {

      let selectedItem: IEntityAssociateDto = item ??
        {
          Id: 0,
          IsNew: true,
          IsDeleted: false,
          LocalId: this.utilitiesService.guid(),
          EntityId: this.userManagerService.getCurrentPrincipal().user.UserAccount.EntityId,
          EntityInfo: { PublicAddressInfo: { City: "" } } as any,
          AssociatedEntityInfo: { StrongLicense: {} } as any,
          AssociateEntityId: 0,
          AssociateTypeId: this.associationTypeId,
          AssociateType: this.possibleEntityAssociateTypes.find(type => type.Id == this.associationTypeId),
        } as IEntityAssociateDto;
      let dialogTitle = item ? `${this.title}` : `${this.title} - {new}`;

      this.dialogSettings = new DialogSettings(
        null, // Component instance
        ReachScenarios.ProfileLanding, // Scenario key
        EntityAssociatedWithEditorComponent, // Content type
        EntityAssociatedWithEditorComponent.name, // Content key
        dialogTitle, // Title
        selectedItem, // Model
      );

      this.dialogSettings.okCommand = this.okCommand;
      this.dialogSettings.isOpen = true;

      return of(true).toPromise();
    }

    return from(doPresent());
  }

  protected removeItemCommandExecute = (entityAssociate: IEntityAssociateDto) => {
    this.confirmationService.confirm({
      message: `${entityAssociate.EntityInfo.FullName} is about to be deleted.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Ok',

      accept: () => {
        entityAssociate.IsDeleted = true;
      }
    });
  }

}

registerDynamicComponent(ReachScenarios.ProfileLanding, EntityAssociatedWithComponentKey, EntityAssociatedWithComponent, EntityAssociatedWithComponentSelector);
