import { Component, Inject, Optional } from '@angular/core';
import { ReachScenarios } from 'src/app/core/index-constants';
import { ReachModelAwareComponent } from 'src/app/core/index-directives';
import { registerDynamicComponent } from 'src/app/core/index-models';
import { MODEL_CONFIG_INJECTION_TOKEN, ReachModelConfigurationInjector } from 'src/app/core/index-services';
import { OnlineEntitySearchResultDetailModel } from 'src/app/licensureCore/features/onlineEntitySearch/models/online-entity-search-result-detail-model';
import { IOnlineEntitySearchResultItemDetailDto } from 'src/app/licensureShared/licensure-shared.module';

export const KeywordListComponentSelector: string = "keyword-list";
export const KeywordListComponentKey: string = "keywordList";

@Component({
  selector: KeywordListComponentSelector,
  templateUrl: './keyword-list.component.html',
  styleUrls: ['./keyword-list.component.scss']
})
export class KeywordListComponent extends ReachModelAwareComponent {

  public get isVisible() { return this.hasData; }
  public get hasData(): boolean { return this.keywords && this.keywords != ""; }
  
  pageSummaryMajorKey: string = 'OnlineEntitySearch';
  pageSummaryMinorKey: string = 'KeywordList.SummaryText';
  
  keywords: string;

  constructor(@Optional() @Inject(MODEL_CONFIG_INJECTION_TOKEN) protected reachModelConfigurationInjector: ReachModelConfigurationInjector,) {
    super(reachModelConfigurationInjector);
    this.keywords = ((this.model as OnlineEntitySearchResultDetailModel).detailDto as IOnlineEntitySearchResultItemDetailDto).DomainKeywords;
  }

}

registerDynamicComponent(ReachScenarios.OnlineEntitySearch, KeywordListComponentKey, KeywordListComponent, KeywordListComponentSelector);