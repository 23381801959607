import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { WIZARD_INJECTOR_TOKEN, WizardInjector } from 'src/app/core/index-models';
import { Bag, CONSTANTS_SERVICE_TOKEN, CheckboxContentService, ConstantsService, ValidationManagerService, WizardModel } from 'src/app/core/index-services';
import { CvaHostWizardStepComponent } from '../../wizard/wizard-step.component';
export const TennessenComponentSelector: string = 'tennessen';
export const TennessenComponentKey: string = 'TennessenCore';
@Component({
  selector: TennessenComponentSelector,
  templateUrl: './tennessen.component.html',
  styleUrls: ['./tennessen.component.scss']
})
export class TennessenComponent extends CvaHostWizardStepComponent implements OnInit, AfterViewChecked {
  public signature: string;
  public signatureDate: Date;
  public includeSignature: boolean;

  constructor(checkboxContentService: CheckboxContentService
    , elementRef: ElementRef
    , validationManagerService: ValidationManagerService
    , @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService
    , @Inject(WIZARD_INJECTOR_TOKEN) wizardInjector: WizardInjector
    , changeDetectorRef: ChangeDetectorRef
  ) {
    super(constantsService, validationManagerService, wizardInjector, TennessenComponent.name, checkboxContentService, elementRef, changeDetectorRef);
    this.signatureDate = new Date();
    this.includeSignature = this.wizard.selectedStep?.Parameters?.["includeSignature"] === "true" ?? false;
    this.modelToForm();
  }

  ngOnInit(): void {
    if (this.includeSignature) {
      this.friendlyNames.Signature = 'Signature';
      this.friendlyNames.SignatureDate = 'Date';
    }
    super.ngOnInit();
    this.modelToForm()
    this.updateBag(this.wizard.selectedStep.bag, this.wizard.model);
  }

  protected modelToForm() {
    if (this.includeSignature) {
      this.stepForm.addControl('Signature', new FormControl(this.signature || '', [Validators.required, Validators.maxLength(80)]));
      this.stepForm.addControl('SignatureDate', new FormControl((this.signatureDate), [Validators.required]));
    }
    super.modelToForm();
  }

  protected formToModel(): void {
    this.signature = this.stepForm.get('Signature')?.value
    this.signatureDate = this.stepForm.get('SignatureDate')?.value;
    this.updateBag(this.wizard.selectedStep.bag, this.wizard.model);
  }

  protected updateBag(bag: Bag, model: WizardModel): void {
    if (this.includeSignature) {
      this.wizard.selectedStep.bag.addOrReplaceItem("signature", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.signature]);
      this.wizard.selectedStep.bag.addOrReplaceItem("signature_date", this.constantsService.BAG_ITEM_TYPES.CUSTOM_CONTENT, [this.signatureDate]);
    }
  }
}

// Register component for dynamic loading
// Example: Add the following with first parameter (specific scenario) in each wizard that requires this step
// registerDynamicComponent({Wizard Scenario Here}, TennessenComponentKey, TennessenComponent, TennessenComponentSelector);
