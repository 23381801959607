
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReachScenarios } from 'src/app/core/index-constants';
import { IReachDialogContentComponent, ReachControlValidators, ReachDialogContentComponent } from 'src/app/core/index-directives';
import { DIALOG_DATA_INJECTOR_TOKEN, DialogDataInjector, registerDynamicComponent } from 'src/app/core/index-models';
import { CONSTANTS_SERVICE_TOKEN, ConstantsService, ValidationManagerService } from 'src/app/core/index-services';
import { IEntityAssociateDto, IEntityInfoDto } from 'src/app/coreShared/core-shared.module';
import { LicenseSelectorFieldConfiguration, LicenseSelectorFieldConfigurationSearchCriteria } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-configuration';
import { LicenseSelectorFieldModel } from 'src/app/licensureCore/licenseSelectorField/license-selector-field-model';
import { LicensureEntityInfoDto } from 'src/app/licensureShared';

export const EntityAssociatesEditorComponentSelector: string = "entity-associates-editor";
export const EntityAssociatesEditorComponentKey: string = "EntityAssociatesEditorComponent";

@Component({
  selector: EntityAssociatesEditorComponentSelector,
  templateUrl: './entity-associates-editor.component.html',
  styleUrls: ['./entity-associates-editor.component.scss']
})
export class EntityAssociatesEditorComponent extends ReachDialogContentComponent<IEntityAssociateDto> implements OnInit, IReachDialogContentComponent {

  public licenseSelectorComponentModel: LicenseSelectorFieldModel;
  public licenseSelectorFieldConfig: LicenseSelectorFieldConfiguration;

  public licenseSelectorFieldSelectionChanged(licenseSelectorFieldModel: LicenseSelectorFieldModel) {
    this.outputModel.AssociateEntityId = licenseSelectorFieldModel.licenseeEntityId;
    this.outputModel.AssociatedEntityInfo = { FullName: licenseSelectorFieldModel.selectedLicenseeName } as IEntityInfoDto;
    this.outputModel.IsDirty = true;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Inject(CONSTANTS_SERVICE_TOKEN) constantsService: ConstantsService,
    @Inject(DIALOG_DATA_INJECTOR_TOKEN) dialogSettingsInjector: DialogDataInjector,
    elementRef: ElementRef,
    validationManagerService: ValidationManagerService,
    protected formBuilder: FormBuilder,) {

    super(changeDetectorRef, constantsService, dialogSettingsInjector, elementRef, validationManagerService);
  }

  public override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.friendlyNames.StartDate = "Start Date";
    this.friendlyNames.EndDate = "End Date";

    this.licenseSelectorFieldConfig = new LicenseSelectorFieldConfiguration("Associate", "Associate", new LicenseSelectorFieldConfigurationSearchCriteria("Individual"));
    if (this.outputModel?.AssociatedEntityInfo) this.licenseSelectorComponentModel = new LicenseSelectorFieldModel(
      (this.outputModel.AssociatedEntityInfo as LicensureEntityInfoDto).StrongLicense?.Id,
      this.outputModel.AssociatedEntityInfo.FullName,
      this.outputModel.AssociateEntityId);
  }

  protected override modelToForm(): void {
    const startDate = this.outputModel.BeginDate ? new Date(this.outputModel.BeginDate) : null;
    const endDate = this.outputModel.EndDate ? new Date(this.outputModel.EndDate) : null;

    this.contentForm = this.formBuilder.group({
      StartDate: [startDate, [ReachControlValidators.pastDateValidator('Start Date')]],
      EndDate: [endDate, [ReachControlValidators.pastDateValidator('End Date')]],
      Comments: [this.outputModel.Comment, [Validators.maxLength(2000)]]
    });

    super.modelToForm();
  }

  protected override formToModel(): void {
    this.outputModel.Comment = this.contentForm.get('Comments').value;
    this.outputModel.BeginDate = this.contentForm.get('StartDate').value;
    this.outputModel.EndDate = this.contentForm.get('EndDate').value;
  }

}

registerDynamicComponent(ReachScenarios.ProfileLanding, EntityAssociatesEditorComponentKey, EntityAssociatesEditorComponent, EntityAssociatesEditorComponentSelector);