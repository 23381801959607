import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public save(name, data) {
    if (data) {
      let stringData = JSON.stringify(data);
      localStorage.setItem(name, stringData);
    }
  }

  public retrieve(name) {
    let serializedItem = localStorage.getItem(name);
    if (serializedItem && serializedItem !== "[object Object]") {
      let deserialized = JSON.parse(serializedItem);
      return deserialized;
    }
    return null;
  }

  public removeItem(name) {
    localStorage.removeItem(name);
  }

  public clear() {
    localStorage.clear();
  }
}