import { Inject, Injectable } from "@angular/core";
import { Router, Routes } from '@angular/router';
import * as _l from 'lodash-es';

import {
  AuthGuard,
  CONSTANTS_SERVICE_TOKEN,
  ConstantsService,
  DynamicRoutesService,
  FunctionTypeService,
  OnlineServiceRegistry,
  OnlineServiceRegistryItem,
  Wizard, WizardSearchCriteriaService, WizardService, WizardTagsService
} from '@core/core.module';

import { LicensureOnlineServiceProcessTypeKeys } from '@licensureCoreModels/licensure-online-service-registry-types';
import { Observable, from } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JurisprudenceFeatureConfigurationService {
  private serviceRegistryItem: OnlineServiceRegistryItem;
  private isInitialized = false;
  private jurisprudenceConstantsService: IJurisprudenceConstantsService;

  constructor(@Inject(CONSTANTS_SERVICE_TOKEN) private constantsService: ConstantsService
    , private dynamicRoutesService: DynamicRoutesService
    , private router: Router
    , private wizardService: WizardService
    , private wizardSearchCriteriaService: WizardSearchCriteriaService
    , private wizardTagsService: WizardTagsService
    , private functionTypeService: FunctionTypeService
  ) {
    this.serviceRegistryItem = OnlineServiceRegistry.getItemByOnlineServiceTypeId(LicensureOnlineServiceProcessTypeKeys.Jurisprudence);
  }

  public initialize() {
    this.jurisprudenceConstantsService = this.constantsService as IJurisprudenceConstantsService;
    if (!this.isInitialized) {
      this.configureConstants();
      this.configureRoutes();
      this.isInitialized = true;
    }
  }

  private configureConstants() {
    ConstantsService.MergeSettings(this.constantsService.DYNAMIC_CONTENT_MAJOR_KEYS, JurisprudenceConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
    ConstantsService.MergeSettings(this.constantsService.WEB_SERVICE_PROCESS_TYPES, JurisprudenceConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
    ConstantsService.MergeSettings(this.constantsService.DOMAIN_FORM_STATUSES, JurisprudenceConstantsService.Mergers.DOMAIN_FORM_STATUSES);
    this.jurisprudenceConstantsService = this.constantsService as IJurisprudenceConstantsService;
  }

  private configureRoutes() {
    const routes: Routes = [
      {
        path: this.serviceRegistryItem.selectorPath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/jurisprudenceWizard/jurisprudence-wizard.module').then(m => m.JurisprudenceWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePath,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/jurisprudenceWizard/jurisprudence-wizard.module').then(m => m.JurisprudenceWizardModule)
      },
      {
        path: this.serviceRegistryItem.onlineServicePathTemplate,
        canActivate: [AuthGuard],
        loadChildren: () => import('@licensureCore/features/jurisprudenceWizard/jurisprudence-wizard.module').then(m => m.JurisprudenceWizardModule)
      }
    ];

    this.dynamicRoutesService.addDynamicRoutes(this.router, this.router.config, routes);
  }

  public getJurisprudenceExamWizard(domainFormId: number, webServiceHistoryId?: number): Observable<Wizard> {
    let functionType = this.functionTypeService.create(this.jurisprudenceConstantsService.FUNCTION_TYPES.DOMAIN_FORM, domainFormId);
    let wizardTags = this.wizardTagsService.create();

    let wizardSearchCriteria = this.wizardSearchCriteriaService.create(webServiceHistoryId
      , this.jurisprudenceConstantsService.WEB_SERVICE_PROCESS_TYPES.JURISPRUDENCE
      , wizardTags);

    const getWizard = async (): Promise<any> => {
      let wizard = await this.wizardService.getWizard(wizardSearchCriteria).toPromise();
      if (!webServiceHistoryId) {
        let startedWizard = await this.wizardService.startWizard(wizard, functionType, null).toPromise();
        return startedWizard;
      }
      else {
        return wizard;
      }
    };

    return from(getWizard());
  }
}

export class JurisprudenceConstantsService extends ConstantsService {
  public static Mergers = {
    DYNAMIC_CONTENT_MAJOR_KEYS: {
      JURISPRUDENCE_WIZARD: "JurisprudenceExam.Wizard",
    },
    WEB_SERVICE_PROCESS_TYPES: {
      JURISPRUDENCE: LicensureOnlineServiceProcessTypeKeys.Jurisprudence,
    },
    DOMAIN_FORM_STATUSES: {
      STARTED: 2,
      SUBMITTED: 3,
      FAILED: 4,
      PASSED: 5
    }
  };

  WEB_SERVICE_PROCESS_TYPES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).WEB_SERVICE_PROCESS_TYPES), JurisprudenceConstantsService.Mergers.WEB_SERVICE_PROCESS_TYPES);
  DYNAMIC_CONTENT_MAJOR_KEYS = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DYNAMIC_CONTENT_MAJOR_KEYS), JurisprudenceConstantsService.Mergers.DYNAMIC_CONTENT_MAJOR_KEYS);
  DOMAIN_FORM_STATUSES = ConstantsService.MergeSettings(_l.cloneDeep((new ConstantsService()).DOMAIN_FORM_STATUSES), JurisprudenceConstantsService.Mergers.DOMAIN_FORM_STATUSES);
}
export interface IJurisprudenceConstantsService extends JurisprudenceConstantsService { }
